<template>
  <div>
    <v-toolbar dark color="brandCyan" elevation="0" rounded="0">
      <v-toolbar-title>{{
        requestFunds ? "Budget Funding Request" : "Budget to Budget Transfer"
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetForm">
          {{ "Cancel" }}
          <v-icon class="ml-2 mb-1">mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div class="px-3 text-left">
      <transition :name="slideDirection" mode="out-in">
        <div v-if="currentSlide == keys.payment" class="px-4 text-left">
          <v-card-title class="word-break pl-0 mt-3" v-if="!requestFunds">
            Funding
            {{
              destinationBudget ? destinationBudget.budgetDisplayName : "budget"
            }}
            using Budget Transfer
          </v-card-title>
          <v-card-title class="word-break pl-0 mt-3" v-else>
            Requesting funds for
            {{
              destinationBudget ? destinationBudget.budgetDisplayName : "budget"
            }}
          </v-card-title>
          <!-- <v-card-subtitle class="pt-1 pl-0"
            >Current balance:
            {{ destinationBudget && destinationBudget.awardId == 1 ? "$" : ""
            }}{{
              destinationBudget && destinationBudget.formattedBudgetBalance
                ? formatCurrency(
                    truncateNum(destinationBudget.formattedBudgetBalance, 2)
                  )
                : "0.00"
            }}</v-card-subtitle
          >
          <v-card-subtitle
            class="pt-0 pl-0"
            v-if="destinationBudget && destinationBudget.budgetBalance > 0"
            >Balance after funding:
            {{ destinationBudget && destinationBudget.awardId == 1 ? "$" : ""
            }}{{
              formatCurrency(truncateNum(futureDestinationBudgetBalance, 2))
            }}</v-card-subtitle
          >
          <v-card-subtitle class="pt-0 pl-0"
            >{{
              requestFunds ? "Requested amount to fund:" : "Amount to fund:"
            }}
            {{ destinationBudget && destinationBudget.awardId == 1 ? "$" : ""
            }}{{
              amount ? formatCurrency(truncateNum(amount, 2)) : "0.00"
            }}</v-card-subtitle
          > -->

          <v-form v-model="form">
            <v-select
              v-if="
                !preloadSourceBudget ||
                  (preloadSourceBudget && preloadDestinationBudget)
              "
              :items="computedSourceBudgets"
              outlined
              attach
              :menu-props="{ offsetY: true }"
              label="Source Budget"
              item-text="displayNameWithBalance"
              item-value="budgetId"
              v-model="selectedSourceBudget"
              return-object
              :loading="loadingBudgets"
              class="mt-5"
              hide-details
              dense
              color="brandCyan"
              :rules="[v => !!v || 'A source budget is required']"
              no-data-text="No budgets available"
              @change="selectedDestinationBudget = null"
            ></v-select>
            <v-select
              v-if="!preloadDestinationBudget"
              :items="computedDestinationBudgets"
              outlined
              attach
              :menu-props="{ offsetY: true }"
              label="Destination Budget"
              item-text="displayNameWithBalance"
              item-value="budgetId"
              v-model="selectedDestinationBudget"
              :disabled="!sourceBudget"
              return-object
              class="mt-5"
              hide-details
              dense
              color="brandCyan"
              :rules="[v => !!v || 'A destination budget is required']"
              no-data-text="No child budgets availabale"
            ></v-select>
            <div class="mb-10"></div>

            <!-- <v-card-subtitle
              class="pt-4 pl-0"
              v-if="sourceBudget && amount && !requestFunds"
              >Source budget balance after funding:
              {{ futureSourceBudgetBalanceFormatted }}</v-card-subtitle
            > -->

            <!-- <v-text-field
              :label="requestFunds ? 'Amount to request' : 'Amount to transfer'"
              v-model="amount"
              type="number"
              outlined
              dense
              color="brandCyan"
              class="mt-6"
              @wheel="$event.target.blur()"
              :rules="[
                v => !!v || 'Amount is required',
                v =>
                  !!(v && parseFloat(v) >= 1) ||
                  (sourceBudget && sourceBudget.awardId == 1
                    ? 'Amount should be greater than $1'
                    : 'Amount should be greater than 1'),
                v =>
                  !!(
                    v &&
                    (!v.toString().includes('.') ||
                      (v.toString().includes('.') &&
                        v.toString().substring(v.toString().indexOf('.') + 1)
                          .length < 3))
                  ) || `Payment amount shouldn't be longer than 2 decimals`,
                v =>
                  !!(v && parseFloat(v) <= 999999.99) ||
                  'Amount should be smaller'
              ]"
              :hide-details="
                amount && futureSourceBudgetBalance < 0 && !requestFunds
                  ? true
                  : false
              "
            /> 
           <v-card-subtitle
              class="pt-1 pl-3 error--text text-caption"
              v-if="
                amount &&
                  sourceBudget &&
                  futureSourceBudgetBalance < 0 &&
                  !requestFunds
              "
              >Amount should be less than the source budget's
              balance</v-card-subtitle
            >
          -->

            <div class="d-flex justify-space-between">
              <p class="mb-0 mt-2 mr-3">
                {{ requestFunds ? "Amount to request" : "Amount to transfer" }}
              </p>
              <v-text-field
                placeholder="0"
                :prefix="
                  (this.destinationBudget &&
                    this.destinationBudget.awardId != 1) ||
                  (this.sourceBudget && this.sourceBudget.awardId != 1)
                    ? ''
                    : '$'
                "
                v-model="amount"
                type="number"
                color="brandCyan"
                outlined
                dense
                class=""
                style="max-width: 50%; width: 50%; min-width: 120px;"
                @wheel="$event.target.blur()"
                :rules="[
                  v => !!v || 'Amount is required',
                  v =>
                    !!(v && parseFloat(v) >= 1) ||
                    (sourceBudget && sourceBudget.awardId == 1
                      ? 'Amount should be greater than $1'
                      : 'Amount should be greater than 1'),
                  v =>
                    !!(
                      v &&
                      (!v.toString().includes('.') ||
                        (v.toString().includes('.') &&
                          v.toString().substring(v.toString().indexOf('.') + 1)
                            .length < 3))
                    ) || `Payment amount shouldn't be longer than 2 decimals`,
                  v =>
                    !!(v && parseFloat(v) <= 999999.99) ||
                    'Amount should be smaller'
                ]"
                :hide-details="
                  amount &&
                  sourceBudget &&
                  futureSourceBudgetBalance < 0 &&
                  !requestFunds
                    ? true
                    : false
                "
              />
            </div>
            <v-card-subtitle
              class="px-0 mx-0 pt-1 error--text text-caption text-right"
              v-if="
                amount &&
                  sourceBudget &&
                  futureSourceBudgetBalance < 0 &&
                  !requestFunds
              "
              >Amount should be less than the source budget's
              balance</v-card-subtitle
            >
            <div
              class="d-flex justify-space-between mt-2"
              v-if="sourceBudget && !requestFunds"
            >
              <p class="mb-0 mr-3">
                Current <strong>source</strong> rewards balance
              </p>
              <p class="mb-0">
                {{ currentSourceBudgetBalance }}
              </p>
            </div>
            <div class="d-flex justify-space-between mt-2">
              <p class="mb-0 mr-3">
                Current <strong>destination</strong> rewards balance
              </p>
              <p class="mb-0">
                {{ currentBudgetBalance }}
              </p>
            </div>
            <div class="d-flex full-width justify-end my-4">
              <v-divider style="max-width: 30%; min-width: 100px;" />
            </div>
            <div
              class="d-flex justify-space-between mt-2"
              v-if="sourceBudget && !requestFunds"
            >
              <p class="mb-0 mr-3">
                <strong>Source</strong> rewards balance after transfer
              </p>
              <p class="mb-0">
                {{ futureSourceBudgetBalanceFormatted }}
              </p>
            </div>
            <div class="d-flex justify-space-between mt-2">
              <p class="mb-0 mr-3">
                <strong>Destination</strong> rewards balance after transfer
              </p>
              <p class="mb-0 font-weight-bold">
                {{ futureBudgetBalance }}
              </p>
            </div>

            <div class="d-flex justify-center">
              <v-btn
                color="brandCyan"
                class="mb-5 mt-6 white--text"
                width="140"
                depressed
                :disabled="
                  !form || (futureSourceBudgetBalance < 0 && !requestFunds)
                "
                @click="createTransfer"
                >{{ requestFunds ? "Request" : "Transfer" }}</v-btn
              >
            </div>
          </v-form>
        </div>
        <v-row
          v-else-if="currentSlide == keys.loading"
          key="1"
          no-gutters
          class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
        >
          <v-card-title
            >Processing the
            {{ requestFunds ? "request" : "transfer" }}...</v-card-title
          >

          <Robin
            :showText="false"
            :width="120"
            :height="120"
            animation="flying"
            :loop="true"
          />
        </v-row>
        <v-row
          v-else-if="currentSlide == keys.success"
          key="2"
          no-gutters
          class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
        >
          <v-card-title class="my-4">All done!</v-card-title>
          <caption class="grey--text mt-n2" v-if="!requestFunds">
            The funds have been moved from the source budget into
            {{
              destinationBudget.budgetDisplayName
            }}
          </caption>
          <caption class="grey--text mt-n2" v-else>
            The funds have been requested for
            {{
              destinationBudget.budgetDisplayName
            }}
          </caption>

          <Robin
            :showText="false"
            :width="140"
            :height="140"
            animation="backflip"
            :loop="false"
          />

          <v-btn
            color="brandCyan"
            class="my-8 white--text"
            depressed
            width="140"
            @click="resetForm('reset')"
            >Done</v-btn
          >
        </v-row>
        <v-row
          v-else-if="currentSlide == keys.error"
          key="8"
          no-gutters
          class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
        >
          <v-card-title class="title-text word-break full-width">{{
            errorMessage
              ? errorMessage
              : requestFunds
              ? "There was an error requesting a funding transfer"
              : "There was an error transferring funds between budgets"
          }}</v-card-title>

          <p class="pl-4 full-width align-text-left">
            If you continue to have trouble, feel free to reach out to customer
            service via one of the following methods...
          </p>
          <p class="pl-4 align-text-left full-width">
            Email -
            <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
          </p>
          <p class="pl-4 align-text-left full-width">
            Phone (Toll Free) - <b>(855) 264-3329</b>
          </p>
          <div class="d-flex mt-4">
            <v-btn
              color="brandCyan"
              class="mr-4 white--text"
              width="150"
              depressed
              @click="(currentSlide = keys.payment), (errorMessage = null)"
              >Go back</v-btn
            >
            <v-btn
              color="brandCyan"
              depressed
              class="white--text"
              width="150"
              @click="resetForm"
              >Exit</v-btn
            >
          </div>
        </v-row>
      </transition>
    </div>
  </div>
</template>

<script>
import BudgetService from "@/services/BudgetService";
import WalletService from "@/services/WalletService";

import Robin from "@/components/Robin";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";

export default {
  name: "BudgetTransfer",
  props: {
    preloadSourceBudget: Object,
    preloadDestinationBudget: Object,
    budgets: Array,
    requestFunds: Boolean,
    preloadAmount: Number,
    preloadBudgetActivityId: Number
  },
  components: {
    Robin
  },
  data() {
    return {
      currentSlide: 0,
      keys: {
        payment: 0,
        loading: 1,
        success: 2,
        error: 3
      },
      slideDirection: "topic-left",
      form: null,
      amount: null,
      selectedSourceBudget: null,
      selectedDestinationBudget: null,
      loadingBudgets: false,
      errorMessage: null,
      parentBudgets: []
    };
  },
  created() {
    if (this.preloadAmount) this.amount = this.preloadAmount;

    // If both source and destination were sent, then we show the source and therefore want to set it as 'selected' in case they change it
    if (this.preloadSourceBudget && this.preloadDestinationBudget)
      this.selectedSourceBudget = this.preloadSourceBudget;

    // If we're requesting funds, we fetch the parents because the user can't see the parent
    if (this.requestFunds) this.getParentBudgets();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    resetForm(screen = null) {
      this.$emit("scrollToTop");
      this.$emit("reset", screen);
    },
    previousSlide() {
      this.resetForm();
    },
    getParentBudgets() {
      this.loadingBudgets = true;
      BudgetService.getParentBudgets({
        lite: 1,
        awardId: !this.destinationBudget
          ? undefined
          : this.destinationBudget.awardId
      })
        .then(response => {
          console.log("Got parent budgets");
          response = response.filter(
            x => x.status != "Deleted" && x.status != "Expired"
          );
          response.forEach(function(budget) {
            budget.displayName =
              budget.budgetDisplayName !== null
                ? budget.budgetDisplayName
                : budget.budgetName;

            budget.displayNameWithBalance = budget.displayName;
          });

          response = response.sort((a, b) => a.budgetId - b.budgetId);
          this.parentBudgets = response;

          this.loadingBudgets = false;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    createTransfer() {
      var startTime = new Date();
      this.currentSlide = this.keys.loading;
      // var bankId = this.bank.id;
      // console.log("Bank ", this.bank.bankId);
      if (!this.requestFunds) {
        var obj = {
          sourceBudgetId: this.sourceBudget.budgetId,
          destinationBudgetId: this.destinationBudget.budgetId,
          amount: truncateNumber(this.amount, 2)
        };
        if (this.preloadBudgetActivityId)
          obj.budgetActivityId = this.preloadBudgetActivityId;
        WalletService.createBudgetTransfer(obj).then(resp => {
          console.log("Got wallet resp: ", resp);
          // resp.forEach((x) => (x.name = x.bank_name + "..." + x.last4));
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff >= 1500) {
            timeDiff = 1500;
          }
          setTimeout(() => {
            if (resp.error) {
              this.currentSlide = this.keys.error;
              if (resp.error_code == 1070100) {
                this.errorMessage =
                  "The source budget didn't have enough funds to make the transfer";
              } else if (resp.error_code == 1070101) {
                this.errorMessage = "The amount entered was invalid";
              }
            } else {
              this.currentSlide = this.keys.success;
            }
          }, 1500 - timeDiff);
        });
      } else {
        WalletService.createBudgetFundingRequest({
          sourceBudgetId: this.sourceBudget.budgetId,
          destinationBudgetId: this.destinationBudget.budgetId,
          amount: truncateNumber(this.amount, 2)
        }).then(resp => {
          console.log("Got wallet resp: ", resp);
          // resp.forEach((x) => (x.name = x.bank_name + "..." + x.last4));
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff >= 1500) {
            timeDiff = 1500;
          }
          setTimeout(() => {
            if (resp.error) {
              this.currentSlide = this.keys.error;
              if (resp.error_code == 1070100) {
                this.errorMessage =
                  "The source budget didn't have enough funds to request the transfer";
              } else if (resp.error_code == 1070101) {
                this.errorMessage = "The amount entered was invalid";
              } else if (resp.error_code == 1070102) {
                this.errorMessage =
                  "The source and destination budgets were the same";
              }
            } else {
              this.currentSlide = this.keys.success;
            }
          }, 1500 - timeDiff);
        });
      }
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    // futureDestinationBudgetBalance() {
    //   if (
    //     !this.destinationBudget ||
    //     !this.destinationBudget.formattedBudgetBalance
    //   ) {
    //     var budgetBal = 0;
    //   } else {
    //     budgetBal = parseFloat(this.destinationBudget.formattedBudgetBalance);
    //   }
    //   if (!this.amount) {
    //     var amount = 0;
    //   } else {
    //     amount = parseFloat(this.amount);
    //   }
    //   return parseFloat(this.truncateNum(budgetBal + amount, 3)).toFixed(2);
    // },
    // futureSourceBudgetBalance() {
    //   if (!this.sourceBudget || !this.sourceBudget.formattedBudgetBalance) {
    //     var budgetBal = 0;
    //   } else {
    //     budgetBal = parseFloat(this.sourceBudget.formattedBudgetBalance);
    //   }
    //   if (!this.amount) {
    //     var amount = 0;
    //   } else {
    //     amount = parseFloat(this.amount);
    //   }
    //   return parseFloat(this.truncateNum(budgetBal - amount, 3)).toFixed(2);
    // },
    sourceBudget() {
      // This first if is in case a source was passed in (used when accepting budget requests) but the admin changes the source manually
      if (this.selectedSourceBudget && this.preloadSourceBudget)
        return this.selectedSourceBudget;
      if (this.preloadSourceBudget) return this.preloadSourceBudget;
      else return this.selectedSourceBudget;
    },
    destinationBudget() {
      if (this.preloadDestinationBudget) return this.preloadDestinationBudget;
      else return this.selectedDestinationBudget;
    },
    computedDestinationBudgets() {
      if (!this.sourceBudget) {
        return [];
      } else {
        // We have to get children of the selected budget
        var parents = [];
        parents = this.budgets
          .filter(x => x.allChildren.includes(this.sourceBudget.budgetId))
          .map(x => x.budgetId);

        var tempBudgets = this.budgets.filter(
          x =>
            x.status !== "Expired" &&
            x.awardId == this.sourceBudget.awardId &&
            x.budgetId !== this.sourceBudget.budgetId &&
            !x.masterBudget &&
            // Grabs the budget's parent
            // (x.budgetId == this.sourceBudget.parentBudgetId ||
            (parents.includes(x.budgetId) ||
              // If non cash then it grabs all
              this.sourceBudget.awardId !== 1 ||
              // Grabs the budget's children
              this.sourceBudget.allChildren.includes(x.budgetId))
        );
        let acctBalance = this.budgets.filter(
          x =>
            x.masterBudget &&
            // Filter for acct balance based on destination award
            x.awardId == this.sourceBudget.awardId &&
            // Filter out destination
            this.sourceBudget.budgetId !== x.budgetId
        );

        tempBudgets = acctBalance.concat(tempBudgets);
        // .filter((x) => x.permissions && x.permissions.owner);
        return tempBudgets;
      }
    },
    computedSourceBudgets() {
      // This is the most complicated piece.
      var parents = [];
      // If we have a destination and we're requesting funds, we just show parent budgets
      if (this.preloadDestinationBudget && this.requestFunds) {
        return this.visibleParentBudgets;
      } else if (this.preloadDestinationBudget) {
        // If preloading a dest, then we grab the parents of the destination
        parents = (this.budgets || [])
          .filter(x =>
            x.allChildren.includes(this.preloadDestinationBudget.budgetId)
          )
          .map(x => x.budgetId);
      }

      var tempBudgets = (this.budgets || []).filter(
        x =>
          !x.masterBudget &&
          x.status !== "Expired" &&
          // Match awardId
          (!this.preloadDestinationBudget ||
            x.awardId == this.preloadDestinationBudget.awardId) &&
          // Filter out the destinationBudget
          (!this.preloadDestinationBudget ||
            this.preloadDestinationBudget.budgetId !== x.budgetId) &&
          // Filter and grab the destination's parents if they exists
          (!this.preloadDestinationBudget ||
            parents.includes(x.budgetId) ||
            x.awardId != 1)
      );

      let acctBalance = (this.budgets || []).filter(
        x =>
          x.masterBudget &&
          // Filter for acct balance based on destination award
          (!this.preloadDestinationBudget ||
            x.awardId == this.preloadDestinationBudget.awardId) &&
          // Filter out destination
          (!this.preloadDestinationBudget ||
            this.preloadDestinationBudget.budgetId !== x.budgetId)
      );

      tempBudgets = acctBalance.concat(tempBudgets);
      // .filter((x) => x.permissions && x.permissions.owner);
      return tempBudgets.filter(x => x.permissions && x.permissions.owner);
    },
    visibleParentBudgets() {
      // we only care if we have a dest and requesting funds
      if (this.destinationBudget && this.requestFunds) {
        // Grab the master budget if it exists
        var acctBal = this.parentBudgets.find(
          x =>
            x.clientId == this.destinationBudget.clientId &&
            x.masterBudget &&
            x.awardId == this.destinationBudget.awardId
        );
        // Filter all budgets based on clientId, awardId, and if it's the parent of the dest
        let budgets = this.parentBudgets.filter(
          x =>
            x.clientId == this.destinationBudget.clientId &&
            !x.masterBudget &&
            x.awardId == this.destinationBudget.awardId &&
            x.budgetId == this.destinationBudget.parentBudgetId
        );

        budgets = budgets.filter(
          x => x.budgetId !== this.destinationBudget.budgetId
        );
        if (acctBal) {
          budgets.unshift(acctBal);
        }

        budgets = budgets.filter(
          x =>
            x.status !== "Expired" ||
            this.destinationBudget.parentBudgetId == x.budgetId
        );

        return budgets;
      }
      return [];
    },
    currentAmount() {
      let amt = currency(0);
      if (this.amount) amt = currency(this.amount);
      return amt.format({
        symbol:
          (this.destinationBudget && this.destinationBudget.awardId != 1) ||
          (this.sourceBudget && this.sourceBudget.awardId != 1)
            ? ""
            : "$ "
      });
    },
    currentBudgetBalance() {
      let balance = currency(0);
      if (this.destinationBudget && this.destinationBudget.budgetBalance)
        balance = currency(this.destinationBudget.budgetBalance);
      return balance.format({
        symbol:
          this.destinationBudget && this.destinationBudget.awardId != 1
            ? ""
            : "$ "
      });
    },
    currentSourceBudgetBalance() {
      let balance = currency(0);
      if (this.sourceBudget && this.sourceBudget.budgetBalance)
        balance = currency(this.sourceBudget.budgetBalance);
      return balance.format({
        symbol: this.sourceBudget && this.sourceBudget.awardId != 1 ? "" : "$ "
      });
    },
    futureBudgetBalance() {
      return currency(this.currentBudgetBalance)
        .add(this.currentAmount)
        .format({
          symbol:
            this.destinationBudget && this.destinationBudget.awardId != 1
              ? ""
              : "$ "
        });
    },
    futureSourceBudgetBalance() {
      return currency(this.currentSourceBudgetBalance).subtract(
        this.currentAmount
      ).value;
    },
    futureSourceBudgetBalanceFormatted() {
      return currency(this.futureSourceBudgetBalance).format({
        symbol: this.sourceBudget && this.sourceBudget.awardId != 1 ? "" : "$"
      });
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}
</style>

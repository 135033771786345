<template>
  <div>
    <div class="d-flex align-start mt-8">
      <vue-editor
        class="editor full-width"
        v-model="message"
        :editorToolbar="customToolbar"
        :editorOptions="quillOptions"
        ref="quillEditor"
        :disabled="disabled"
        :placeholder="placeholder || undefined"
      ></vue-editor>
      <v-icon
        dark
        v-if="largeVersion"
        class="border-radius pa-2 ml-4"
        tabindex="-1"
        :class="{
          'accent-background': message !== null && message.length > 0,
          'grey-background':
            message === null || (message !== null && message.length === 0)
        }"
        >mdi-pencil</v-icon
      >
    </div>
    <div class="d-flex align-start mt-8" v-if="!disabled && shortcodes">
      <v-expansion-panels accordion class="d-flex full-width">
        <v-expansion-panel>
          <v-expansion-panel-header>Personalization</v-expansion-panel-header>
          <v-expansion-panel-content class="shortcodes">
            <v-chip @click="insertShortcode('user.firstName')"
              >First Name</v-chip
            >
            <v-chip @click="insertShortcode('user.lastName')">Last Name</v-chip>
            <div v-if="programRelated">
              <v-chip @click="insertShortcode('program.name')"
                >Program Name</v-chip
              >
              <v-chip @click="insertShortcode('program.progressPercent')"
                >Percent Complete</v-chip
              >
              <v-chip @click="insertShortcode('program.progressPercentInverse')"
                >Percent Left To Go</v-chip
              >
              <v-chip @click="insertShortcode('program.progressToward')"
                >Progress Toward</v-chip
              >
              <v-chip @click="insertShortcode('program.userStatus')"
                >User's Program Status</v-chip
              >
              <v-chip
                v-if="programHasEndDate"
                @click="insertShortcode('program.daysRemaining')"
                >Program Days Remaining (x days)</v-chip
              >
              <v-chip
                v-if="programHasEndDate"
                @click="insertShortcode('program.hoursRemaining')"
                >Program Hours Remaining (x hours)</v-chip
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="border-radius pa-2 ml-4 mt-1"
            color="gray"
            v-on="on"
            v-bind="attrs"
            >mdi-help-circle</v-icon
          >
        </template>
        <p>
          Use these personalization tags to dynamically insert user data into
          the message
        </p>
      </v-tooltip>
      <!-- <v-icon
        dark
        hidden
        v-if="largeVersion"
        class="border-radius pa-2 ml-4"
        tabindex="-1"
        :class="{
          'accent-background': content !== null && content.length > 0,
          'grey-background':
            content === null || (content !== null && content.length === 0),
        }"
        >mdi-pencil</v-icon
      > -->
    </div>
  </div>
</template>

<script>
// import ActivityFeedCard from "@/components/ActivityFeedCard.vue";
// import GroupService from "../services/GroupService";
// import UserService from "../services/UserService";
// import CommunicationService from "../services/CommunicationService";
// import ProgramService from "../services/ProgramService.js";

import { VueEditor, Quill } from "vue2-editor";

Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

import { editorToolbar, editorOptions } from "@/shared_data/data";

export default {
  name: "TextEditor",
  components: {
    VueEditor
  },
  props: {
    //whether or not to show shortcodes, and if we do, do we show the program related ones
    shortcodes: {
      type: Boolean,
      default: true
    },
    programRelated: Boolean,
    programHasEndDate: {
      type: Boolean,
      default: true
    },
    editorContent: String,
    largeVersion: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      content: null,
      customToolbar: editorToolbar,
      quillOptions: editorOptions
    };
  },
  created() {
    console.log("Created");
    this.content = this.editorContent;
  },
  methods: {
    insertShortcode(code) {
      this.$refs.quillEditor.quill.insertText(
        this.$refs.quillEditor.quill.getSelection(true).index,
        "{{{" + code + "}}}"
      );
    }
  },
  computed: {
    message: {
      get: function() {
        return this.editorContent;
      },
      set: function(newValue) {
        this.$emit("update-message", newValue);
      }
    }
  },
  watch: {
    content: function() {
      this.$emit("content-change", this.content);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.shortcodes .v-chip {
  margin: 2px;
}
.quillWrapper.editor {
  max-height: 100%;
}
.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.border-radius {
  border-radius: 100%;
}
.grey-background {
  background-color: var(--v-lightGrey-base);
}
.editor {
  max-height: 40vh;
}
</style>

import { render, staticRenderFns } from "./MarqetaCardPINSuccess.vue?vue&type=template&id=4a058de1&scoped=true"
import script from "./MarqetaCardPINSuccess.vue?vue&type=script&lang=js"
export * from "./MarqetaCardPINSuccess.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a058de1",
  null
  
)

export default component.exports
<template>
  <v-card>
    <!-- <div class="d-flex justify-end pt-4 pb-3 pr-4">
      <v-btn icon large @click="closeWindow"><v-icon>mdi-close</v-icon></v-btn>
    </div> -->
    <v-card-text class="align-text-center pt-5">
      <v-img
        src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
        max-width="250"
        class="mx-auto"
      />
      <h2 class="my-6">
        <b>We're having trouble loading Whistle</b>
      </h2>

      <p class="text-left mx-7" v-if="$auth && $auth.errorType == 'clock'">
        Your computer's clock might be running too fast or too slow.
        <br />
        <br />
        Please try adjusting your computer's settings to automatically set the
        time & date, rather than manually setting it.
        <!-- Depending on your browser, you might be able to enable cookies for an
        individual site (app.wewhistle.com). <br /><br />If enabling cookies
        doesn't work, try another browser or contact us at
        <a href="mailto:help@wewhistle.com">help@wewhistle.com</a>. -->
      </p>
      <p class="text-left mx-7" v-else>
        Before retrying, make sure your browser's settings allow cookies,
        including third-party cookies.
        <br />
        <br />
        Depending on your browser, you might be able to enable cookies for an
        individual site (app.wewhistle.com). <br /><br />If enabling cookies
        doesn't work, try another browser or contact us at
        <a href="mailto:help@wewhistle.com">help@wewhistle.com</a>.
      </p>
      <v-btn
        depressed
        color="brand"
        width="140"
        class="mx-2 white--text"
        href="mailto:help@wewhistle.com"
        >Contact us</v-btn
      >
      <v-btn
        depressed
        color="brand"
        outlined
        width="140"
        class="mx-2"
        @click="refreshPage"
        >Refresh</v-btn
      >
      <p class="mt-2 mb-0 py-0">
        [{{ new Date() }}, loading: {{ $auth.loading ? "y" : "n" }}, status:
        {{ $auth.step || "no val" }}, auth:
        {{ $auth.isAuthenticated ? "y" : "n" }}, auth err:
        {{ $auth.error ? $auth.error : "none" }}, err:
        {{ errorString ? errorString : "none" }}
        <span v-if="$auth.isAuthenticated"
          >, user: {{ $auth.user.sub }}, email:
          {{
            userProfile && userProfile.businessEmail
              ? userProfile.businessEmail
              : userProfile && userProfile.businessPhone
              ? userProfile.businessPhone
              : "none"
          }}, token: {{ token ? token.length : "n" }}, perm:
          {{ permissions ? permissions.length : "n" }}, fail:
          {{ failedLogin ? "y" : "n" }}</span
        >]
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LoginErrorPopup",
  components: {},
  props: {},
  data() {
    return {
      newProgramForm: {
        menuStartDate: false,
        menuEndDate: false,
        valid: false,
        forceValidForm: false,
        editing: false
      },
      file: undefined
    };
  },
  mounted() {},
  methods: {
    refreshPage() {
      document.location.reload();
    },
    closeWindow() {
      this.$emit("close");
    }
  },
  computed: {
    ...mapState([
      "postItArray",
      "userProfile",
      "errorString",
      "permissions",
      "failedLogin",
      "token"
    ])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div class="full-height gradient-background" rounded="0">
    <div class="d-flex flex-column justify-space-between full-height">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="12" sm="6" md="5" lg="4" xl="4">
          <div
            class="white-background form-card"
            :class="{
              rounded: !$vuetify.breakpoint.xs,
              'mt-6': !$vuetify.breakpoint.xs
            }"
          >
            <transition :name="slideDirection" mode="out-in">
              <v-row
                v-if="currentSlide == keys.main"
                :key="keys.main"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <v-img
                  src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                  max-width="165"
                  class="mx-auto"
                />

                <v-form
                  v-if="!showSSOIsolated"
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 mt-6"
                >
                  <p class="text-h5 mb-2">Welcome!</p>
                  <p>Please log in to continue.</p>
                  <v-text-field
                    v-if="showEmail"
                    ref="emailField"
                    label="Email address or phone number"
                    v-model="key"
                    :disabled="lockEmailButton"
                    outlined
                    hide-details
                    color="brandCyan"
                    class="mx-6"
                    @keyup.enter="checkUserByEmail"
                  >
                    <template v-slot:append>
                      <v-icon
                        @click="unlockEmail"
                        :disabled="
                          submittingPasswordlessEmail ||
                            submittingPasswordlessSMS ||
                            submittingSSO ||
                            submittingAzure ||
                            submittingSetPassword ||
                            submittingLogin ||
                            submittingEmail
                        "
                        tabindex="-1"
                        class="email-lock"
                        >{{ lockEmailButton ? "mdi-lock" : "" }}</v-icon
                      >
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-if="showPassword"
                    ref="passwordField"
                    label="Password"
                    :type="showPasswordValue ? 'text' : 'password'"
                    v-model="password"
                    class="mt-4 mx-6"
                    outlined
                    hide-details
                    color="brandCyan"
                    @keyup.enter="loginUser"
                  >
                    <template v-slot:append>
                      <v-icon
                        @click="showPasswordValue = !showPasswordValue"
                        tabindex="-1"
                        >{{
                          showPasswordValue ? "mdi-eye" : "mdi-eye-off"
                        }}</v-icon
                      >
                    </template></v-text-field
                  >
                  <div class="text-left full-width mx-6 mt-2">
                    <a
                      v-if="showPassword"
                      class="brandCyan--text font-weight-bold"
                      @click="
                        {
                          currentSlide = keys.forgotPassword;
                          errorMessage = null;
                        }
                      "
                      >Forgot password?</a
                    >
                  </div>
                </v-form>
                <v-form
                  v-else
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 mt-6"
                >
                  <p class="text-h5 mb-2">Welcome!</p>
                  <p v-if="showPopupButton && !submittingSSO">
                    Please log in to continue.
                  </p>
                  <p v-else>Loading your log in experience...</p>

                  <div class="full-width mx-auto mt-8">
                    <v-btn
                      v-if="showPopupButton && !submittingSSO"
                      color="brandCyan"
                      depressed
                      class="white--text"
                      @click="loginWithGeneric3PA($route.params.provider)"
                      >Login via SSO</v-btn
                    >
                    <v-progress-circular
                      v-else
                      size="50"
                      indeterminate
                      color="brandCyan"
                    />
                  </div>
                </v-form>
                <v-btn
                  v-if="(checkEmailButton || showPassword) && !showSSOIsolated"
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="
                    if (checkEmailButton) {
                      checkUserByEmail();
                    } else if (loginButton) {
                      loginUser();
                    }
                  "
                  :disabled="
                    submittingPasswordlessEmail ||
                      submittingPasswordlessSMS ||
                      submittingSSO ||
                      submittingAzure ||
                      submittingSetPassword ||
                      (checkEmailButton && key
                        ? false
                        : loginButton && password
                        ? false
                        : true)
                  "
                  :loading="submittingEmail || submittingLogin"
                  >Continue</v-btn
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
                <v-btn
                  v-if="showSetPassword"
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  :disabled="
                    submittingPasswordlessEmail ||
                      submittingPasswordlessSMS ||
                      submittingSSO ||
                      submittingAzure ||
                      submittingEmail ||
                      submittingLogin
                  "
                  :loading="submittingSetPassword"
                  @click="setupPasswordUser"
                  >Set a Password</v-btn
                >
                <div
                  class="d-flex my-4 align-center justify-center full-width"
                  v-if="
                    (showPasswordlessEmail ||
                      showPasswordlessSMS ||
                      showAzure ||
                      showSSO) &&
                      (showPassword || showSetPassword)
                  "
                >
                  <div class="bar-line" />
                  <span class="mx-4">OR</span>
                  <div class="bar-line" />
                </div>
                <div
                  v-else-if="
                    showPasswordlessEmail ||
                      showPasswordlessSMS ||
                      showAzure ||
                      showSSO
                  "
                  class="my-4"
                ></div>
                <v-btn
                  v-if="showPasswordlessEmail"
                  color="brandCyan"
                  class="white--text"
                  width="250"
                  height="40"
                  depressed
                  @click="sendPasswordlessEmail"
                  :loading="submittingPasswordlessEmail"
                  :disabled="
                    submittingEmail ||
                      submittingLogin ||
                      submittingPasswordlessSMS ||
                      submittingSSO ||
                      submittingAzure ||
                      submittingSetPassword
                  "
                  >Send Code via Email</v-btn
                >
                <v-btn
                  v-if="showPasswordlessSMS"
                  color="brandCyan"
                  class="white--text"
                  :class="{ 'mt-6': showPasswordlessEmail }"
                  width="250"
                  height="40"
                  depressed
                  @click="sendPasswordlessSMS"
                  :loading="submittingPasswordlessSMS"
                  :disabled="
                    submittingEmail ||
                      submittingLogin ||
                      submittingPasswordlessEmail ||
                      submittingSSO ||
                      submittingAzure ||
                      submittingSetPassword
                  "
                  >Send Code via SMS</v-btn
                >
                <v-btn
                  v-if="showAzure"
                  color="brandCyan"
                  class="white--text"
                  :class="{
                    'mt-6': showPasswordlessEmail || showPasswordlessSMS
                  }"
                  width="250"
                  height="40"
                  depressed
                  @click="loginWithAzure"
                  :loading="submittingAzure"
                  :disabled="
                    submittingEmail ||
                      submittingLogin ||
                      submittingPasswordlessEmail ||
                      submittingPasswordlessSMS ||
                      submittingSetPassword ||
                      submittingSSO
                  "
                  >Sign in with Azure</v-btn
                >
                <v-btn
                  v-if="showSSO"
                  color="brandCyan"
                  class="white--text"
                  :class="{
                    'mt-6':
                      showPasswordlessEmail || showPasswordlessSMS || showAzure
                  }"
                  width="250"
                  height="40"
                  depressed
                  @click="loginWithGeneric3PA(ssoProvider)"
                  :loading="submittingSSO"
                  :disabled="
                    submittingEmail ||
                      submittingLogin ||
                      submittingPasswordlessEmail ||
                      submittingPasswordlessSMS ||
                      submittingSetPassword ||
                      submittingAzure
                  "
                  >{{ ssoButtonLabel }}</v-btn
                >
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.passwordlessEmail"
                :key="keys.passwordlessEmail"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div
                  class="d-flex justify-space-between align-center full-width"
                >
                  <v-btn icon @click="goToMainPage"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  >
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                  <v-btn icon class="hidden"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 text-center mt-6"
                >
                  <p>We just sent an email with the code to {{ email }}.</p>
                  <v-text-field
                    ref="passwordlessEmailCodeField"
                    label="Code"
                    v-model="passwordlessEmailCode"
                    :rules="[v => !!v || 'Your code is required']"
                    outlined
                    hide-details
                    color="brandCyan"
                    class="mx-6"
                    @keyup.enter="submitPasswordlessEmailCode"
                  />
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="submitPasswordlessEmailCode"
                  :disabled="!passwordlessEmailCode"
                  :loading="submittingPasswordlessEmail"
                  >Continue</v-btn
                >
                <a
                  class="mt-6 brandCyan--text font-weight-bold"
                  @click="goToMainPage"
                  >Did not get the code?</a
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.passwordlessSMS"
                :key="keys.passwordlessSMS"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div
                  class="d-flex justify-space-between align-center full-width"
                >
                  <v-btn icon @click="goToMainPage"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  >
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                  <v-btn icon class="hidden"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 text-center mt-6"
                >
                  <p>We just sent a code to {{ obscuredPhone }}.</p>
                  <v-text-field
                    ref="passwordlessSMSCodeField"
                    label="Code"
                    v-model="passwordlessSMSCode"
                    :rules="[v => !!v || 'Your code is required']"
                    outlined
                    hide-details
                    color="brandCyan"
                    class="mx-6"
                    @keyup.enter="submitPasswordlessSMSCode"
                  />
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="submitPasswordlessSMSCode"
                  :disabled="!passwordlessSMSCode"
                  :loading="submittingPasswordlessSMS"
                  >Continue</v-btn
                >
                <a
                  class="mt-6 brandCyan--text font-weight-bold"
                  @click="goToMainPage"
                  >Did not get the code?</a
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.forgotPassword"
                :key="keys.forgotPassword"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div
                  class="d-flex justify-space-between align-center full-width"
                >
                  <v-btn icon @click="currentSlide = keys.main"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  >
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                  <v-btn icon class="hidden"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 mt-6 text-center"
                >
                  <p class="text-h5">Forgot Your Password?</p>
                  <p>
                    Enter your email address and we will send you instructions
                    to reset your password.
                  </p>
                  <v-text-field
                    ref="email"
                    label="Email address"
                    v-model="email"
                    outlined
                    hide-details
                    color="brandCyan"
                    class="mx-6 mt-2"
                    @keyup.enter="sendPasswordResetEmail"
                  />
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="sendPasswordResetEmail"
                  :disabled="!email"
                  :loading="submittingPasswordReset"
                  >Continue</v-btn
                >
                <a
                  class="mt-6 brandCyan--text font-weight-bold"
                  @click="currentSlide = keys.main"
                  >Back to main page</a
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.forgotPasswordSuccess"
                :key="keys.forgotPasswordSuccess"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div
                  class="d-flex justify-space-between align-center full-width"
                >
                  <v-btn icon @click="currentSlide = keys.forgotPassword"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  >
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                  <v-btn icon class="hidden"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 mt-6 text-center"
                >
                  <div class="outer-circle dark-blue-background mx-auto d-flex">
                    <div
                      class="inner-circle d-flex justify-center align-center light-blue-background"
                    >
                      <v-icon color="white" class="reward-icon" x-large>
                        mdi-email-outline
                      </v-icon>
                    </div>
                  </div>
                  <p class="text-h5 mt-4">Check Your Email</p>
                  <p>
                    Please check the email address {{ email }} for instructions
                    to set your password.
                  </p>
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="currentSlide = keys.forgotPassword"
                  >Resend Email</v-btn
                >
                <a
                  class="mt-6 brandCyan--text font-weight-bold"
                  @click="currentSlide = keys.main"
                  >Back to main page</a
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.passwordSetupSuccess"
                :key="keys.passwordSetupSuccess"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div
                  class="d-flex justify-space-between align-center full-width"
                >
                  <v-btn icon @click="goToMainPage"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  >
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                  <v-btn icon class="hidden"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 mt-6 text-center"
                >
                  <div class="outer-circle dark-blue-background mx-auto d-flex">
                    <div
                      class="inner-circle d-flex justify-center align-center light-blue-background"
                    >
                      <v-icon color="white" class="reward-icon" x-large>
                        mdi-email-outline
                      </v-icon>
                    </div>
                  </div>
                  <p class="text-h5 mt-4">Check Your Email</p>
                  <p>
                    Please check the email address {{ email }} for instructions
                    to set your password.
                  </p>
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="goToMainPage"
                  >Back to main page</v-btn
                >
                <!-- <a
                  class="mt-6 brandCyan--text font-weight-bold"
                  @click="currentSlide = keys.main"
                  >Back to main page</a
                > -->
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.mfa"
                :key="keys.mfa"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div
                  class="d-flex justify-space-between align-center full-width"
                >
                  <v-btn icon @click="goToMainPage"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  >
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                  <v-btn icon class="hidden"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 text-center mt-6"
                >
                  <p>
                    We've sent a text message to
                    {{ mfaPhoneNumber || "you" }} for verification.
                  </p>
                  <v-text-field
                    ref="email"
                    label="Enter the 6 digit code"
                    v-model="mfaCode"
                    :rules="[v => !!v || 'Your code is required']"
                    outlined
                    hide-details
                    color="brandCyan"
                    class="mx-6"
                    @keyup.enter="submitMFACode"
                  />
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="submitMFACode"
                  :disabled="!mfaCode"
                  :loading="submittingMFACode"
                  >Continue</v-btn
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
                <div
                  class="d-flex my-4 align-center justify-center full-width"
                  v-if="showEmailMFA && errorMessage"
                >
                  <div class="bar-line" />
                  <span class="mx-4">OR</span>
                  <div class="bar-line" />
                </div>
                <v-btn
                  v-if="showEmailMFA && errorMessage"
                  color="brandCyan"
                  class="white--text"
                  width="250"
                  height="40"
                  depressed
                  @click="sendMFAEmail"
                  :loading="submittingMFAEmail"
                  >Verify via email</v-btn
                >
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.mfaEmail"
                :key="keys.mfaEmail"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div
                  class="d-flex justify-space-between align-center full-width"
                >
                  <v-btn icon @click="goToMainPage"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn
                  >
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                  <v-btn icon class="hidden"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 text-center mt-6"
                >
                  <p>
                    We've sent an email to
                    {{ email || "you" }} for verification.
                  </p>
                  <v-text-field
                    ref="email"
                    label="Enter the 6 digit code"
                    v-model="mfaCode"
                    :rules="[v => !!v || 'Your code is required']"
                    outlined
                    hide-details
                    color="brandCyan"
                    class="mx-6"
                    @keyup.enter="submitMFACode"
                  />
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="submitMFACode"
                  :disabled="!mfaCode"
                  :loading="submittingMFACode"
                  >Continue</v-btn
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.mfaRegister"
                :key="keys.mfaRegister"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div class="d-flex justify-center align-center full-width">
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                </div>
                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 text-center mt-6"
                >
                  <p class="text-h5">Secure Your Account</p>
                  <p class="mx-4">
                    Enter your phone number below. An SMS will be send to that
                    number with a code to enter on the next screen.
                  </p>
                  <!-- <v-text-field
                    ref="email"
                    label="Enter the 6 digit code"
                    v-model="mfaCode"
                    :rules="[(v) => !!v || 'Your code is required']"
                    outlined
                    dense
                    hide-details
                    color="brand"
                    class="mx-6"
                  /> -->
                  <vue-tel-input-vuetify
                    outlined
                    class="mx-6"
                    v-model="mfaPhoneNumber"
                    :value="mfaPhoneNumber"
                    label="Enter your phone number"
                    :preferredCountries="['US']"
                    defaultCountry="US"
                    :disabledFetchingCountry="true"
                    @validate="mfaPhoneValidation"
                    @input="updateMFAPhone"
                  ></vue-tel-input-vuetify>
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="registerMFA"
                  :disabled="!validMFAPhone"
                  :loading="submittingMFAVerification"
                  >Continue</v-btn
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
                <!-- <div
                  class="d-flex my-4 align-center justify-center full-width"
                  v-if="showEmailMFA && errorMessage"
                >
                  <div class="bar-line" />
                  <span class="mx-4">OR</span>
                  <div class="bar-line" />
                </div>
                <v-btn
                  v-if="showEmailMFA && errorMessage"
                  color="brand"
                  class="white--text"
                  width="250"
                  depressed
                  @click="sendEmailMFA"
                  :loading="submittingPasswordlessEmail"
                  >Send One-Time Email</v-btn
                > -->
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.mfaRegisterVerify"
                :key="keys.mfaRegisterVerify"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <div class="d-flex justify-center align-center full-width">
                  <v-img
                    src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                    max-width="165"
                  />
                </div>

                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 text-center mt-6"
                >
                  <p class="text-h5">Secure Your Account</p>
                  <p>
                    We've sent a text message to
                    {{ mfaPhoneNumber || "you" }} for verification.
                  </p>

                  <vue-tel-input-vuetify
                    disabled
                    outlined
                    class="mx-6"
                    v-model="mfaPhoneNumber"
                    :value="mfaPhoneNumber"
                    label="Enter your phone number"
                    :preferredCountries="['US']"
                    defaultCountry="US"
                    :disabledFetchingCountry="true"
                    @validate="mfaPhoneValidation"
                    @input="updateMFAPhone"
                  ></vue-tel-input-vuetify>
                  <v-text-field
                    ref="email"
                    label="Enter the 6 digit code"
                    v-model="mfaCode"
                    :rules="[v => !!v || 'Your code is required']"
                    outlined
                    hide-details
                    color="brandCyan"
                    class="mx-6"
                    @keyup.enter="submitMFACode"
                  />
                </v-form>
                <v-btn
                  width="210"
                  height="40"
                  color="brandCyan"
                  class="white--text mt-8"
                  depressed
                  @click="submitMFACode"
                  :disabled="!validMFAPhone"
                  :loading="submittingMFAVerification"
                  >Continue</v-btn
                >
                <p v-if="errorMessage" class="error--text mt-3 mb-0">
                  {{ errorMessage }}
                </p>
              </v-row>
            </transition>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { languages, countries } from "@/shared_data/data.js";

// import moment from "moment";
import { mapState } from "vuex";

import UserService from "@/services/UserService";
import OnboardingService from "@/services/OnboardingService";
// import TriggerService from "@/services/TriggerService";

export default {
  name: "Login",
  title: "Whistle | Log in",
  props: {},
  components: {},
  data() {
    return {
      currentSlide: 0,
      keys: {
        // First screen always. Shows main login
        main: 0,
        passwordlessEmail: 1,
        passwordlessSMS: 2,
        forgotPassword: 3,
        forgotPasswordSuccess: 4,
        mfa: 5,
        mfaRegister: 6,
        mfaRegisterVerify: 7,
        mfaEmail: 8,
        passwordSetupSuccess: 9
      },
      slideDirection: "topic-left",

      key: null,
      email: null,
      phone: null,
      password: null,
      passwordlessEmailCode: null,
      passwordlessSMSCode: null,
      mfaCode: null,
      mfaPhoneNumber: null, //
      mfaPhoneNumberFormatted: null,
      mfaToken: null,
      mfaOOBCode: null,
      emailMFAIdentifier: null,

      // Custom SSO variables
      ssoButtonLabel: "Login with SSO",
      ssoProvider: null,

      // Booleans for what text fields to show
      showEmail: true, //we hide once we know if user is going passwordless
      lockEmailButton: false, //used to lock email once the user enters one
      showPasswordValue: false, //used for the eye icon to show/hide pw
      showPassword: false, //If user is set up for user/pass
      showPasswordlessEmail: false, //If user is set up for passwordless
      showPasswordlessSMS: false, //If user is set up for passwordless
      showAzure: false,
      showSSO: false, //For Spirit
      showSSOIsolated: false, //For Spirit specific route
      showEmailMFA: false, //shown on MFA page
      showSetPassword: false, // shown if user is passwordless but no user/pass

      // Booleans for api submissions
      submittingEmail: false,
      submittingLogin: false,
      submittingSetPassword: false,
      submittingPasswordlessEmail: false,
      submittingPasswordlessSMS: false,
      submittingSSO: false,
      submittingAzure: false,
      submittingPasswordReset: false,
      submittingMFAText: false,
      submittingMFACode: false,
      submittingMFAVerification: false,
      submittingMFAEmail: false,
      errorMessage: null,

      // Booleans for when to show which buttons
      checkEmailButton: true,
      loginButton: false,

      // Form validators
      validMFAPhone: false,

      languages: languages,
      countries: countries,
      language: null,
      clientId: null,

      // v-form to validate
      validNewUserForm: false,
      // data fields to set up the new user.
      firstName: null,
      lastName: null,

      country: null,
      otherCountry: null,

      // Client object stored so we have their logo to display
      client: {},
      // Error handling booleans
      displayLoginButton: false,
      displayEditButton: false,
      displayEmailButton: false
      // Loading bool for when resending password reset
    };
  },
  created() {
    console.log("CREATED LOGIN");
    if (this.$auth && this.$auth.isAuthenticated) {
      console.log("Pushing route ", this.$route.query.redirect || "/");
      return this.$router.push(this.$route.query.redirect || "/");
    }

    if (this.$route.name == "spirit-login") {
      console.log("LOADING SPIRIT");
      this.showSSOIsolated = true;
      if (this.$auth.auth0ClientV2) this.loginWithAzure();
    } else if (this.$route.name == "okta-login") {
      console.log("LOADING OKTA");
      this.showSSOIsolated = true;
      if (this.$auth.auth0ClientV2) this.loginWithOkta();
    } else if (this.$route.name === "generic-3pa-login") {
      console.log(
        "LOADING GENERIC-3PA",
        "https://nehalk.medium.com/56-funny-code-comments-that-people-actually-wrote-6074215ab387"
      );
      this.showSSOIsolated = true;
      if (this.$auth.auth0ClientV2 && !this.showPopupButton)
        this.loginWithGeneric3PA(this.$route.params.provider);
    }

    if (this.$route.params.email) {
      this.key = this.email = this.$route.params.email;
      this.checkUserByEmail(this.$route.params.error);
    } else if (this.$route.params.phone) {
      this.key = this.phone = this.$route.params.phone;
      this.checkUserByEmail(this.$route.params.error);
    }
  },
  mounted() {
    console.log("MOUNTED LOGIN");
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }

    if (this.$refs.emailField) this.$refs.emailField.focus();

    document.addEventListener("backbutton", this.watchBackButton, false);
  },
  beforeDestroy() {
    document.removeEventListener("backbutton", this.watchBackButton);
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    watchBackButton() {
      // We want to reset a couple key variables
      console.log("Back button call!");
      this.currentSlide = this.keys.main;
      this.submittingEmail = false;
      this.submittingLogin = false;
    },
    unlockEmail() {
      // Called if the user has already fetched a user and wants to change the email
      console.log("Unlocking email / resetting");
      this.lockEmailButton = false;
      this.password = null;
      this.showPassword = false;
      this.showPasswordlessEmail = false;
      this.showPasswordlessSMS = false;
      this.showSetPassword = false;
      this.showAzure = false;
      this.showSSO = false;
      this.checkEmailButton = true;
      this.loginButton = false;
      this.errorMessage = null;
    },
    goToMainPage() {
      // this.lockEmailButton = false;
      this.currentSlide = this.keys.main;
      this.password = null;
      this.passwordlessEmailCode = null;
      this.passwordlessSMSCode = null;
      // this.showPassword = false;
      // this.showPasswordlessEmail = false;
      // this.checkEmailButton = true;
      // this.loginButton = false;
      this.errorMessage = null;
    },
    async checkUserByEmail(errorMessage = null) {
      try {
        this.submittingEmail = true;
        // If an error message was passed in from a magic link
        if (errorMessage && typeof errorMessage == "string")
          this.errorMessage = errorMessage;

        let user = await UserService.checkUserAuthByEmail(this.key);
        console.log("Auth response ", user);

        this.showPassword = user.passwordEnabled;
        this.showPasswordlessEmail = user.emailPasswordlessEnabled;
        this.showPasswordlessSMS =
          user.smsPasswordlessEnabled && user.businessPhone;
        this.showAzure = user.Client && user.Client.azureSSOEnabled;
        this.showSSO = !!(user.Client && user.Client.ssoProvider);
        if (this.showSSO) {
          this.ssoButtonLabel = user.Client.ssoButtonLabel;
          this.ssoProvider = user.Client.ssoProvider;
        }

        // We have our user so we we show/hide applicable fields
        if (
          !this.showPassword &&
          !this.showPasswordlessEmail &&
          !this.showPasswordlessSMS &&
          !this.showSSO &&
          !this.showAzure
        )
          throw "Not set up for any login method.";

        this.phone = user.businessPhone;
        this.email = user.businessEmail;
        // We want to show the optional "set a password" button
        // Only if they don't have a password set AND it's not just SSO
        this.showSetPassword =
          !this.showPassword &&
          !(
            !this.showPassword &&
            !this.showPasswordlessEmail &&
            !this.showPasswordlessSMS &&
            (this.showAzure || this.showSSO)
          ) &&
          // If they're just an sms user without an email, we don't let them set a password because we don't have an email for them
          !(
            this.showPasswordlessSMS &&
            !this.showPasswordlessEmail &&
            !this.passwordEnabled &&
            !user.businessEmail
          );
        this.checkEmailButton = false;
        this.lockEmailButton = true;

        // Here we auto-route them if only one option is enabled
        // if (!this.showPassword && this.showPasswordlessEmail && !this.showAzure) {
        //   // Auto route them to passwordless
        //   // console.log("Auto route to passwordless");
        //   // this.currentSlide = this.keys.passwordlessEmail;
        //   // this.sendPasswordlessEmail();
        // } else
        if (
          !this.showPassword &&
          !this.showPasswordlessEmail &&
          !this.showPasswordlessSMS &&
          !this.showSSO &&
          this.showAzure &&
          !this.showSetPassword
        ) {
          // Auto route to SSO
          console.log("Auto route to Azure");
          this.loginWithAzure();
        } else if (
          !this.showPassword &&
          !this.showPasswordlessEmail &&
          !this.showPasswordlessSMS &&
          !this.showAzure &&
          this.showSSO &&
          !this.showSetPassword
        ) {
          console.log("Auto route to SSO ", this.ssoProvider);
          this.loginWithGeneric3PA(this.ssoProvider);
        } else if (
          !this.showPassword &&
          !this.showPasswordlessEmail &&
          this.showPasswordlessSMS &&
          !this.showAzure &&
          !this.showSSO &&
          !this.showSetPassword
        ) {
          console.log("Auto route to send SMS code");
          this.sendPasswordlessSMS();
        } else {
          // this.checkEmailButton = false;
          this.loginButton = true;
          // this.lockEmailButton = true;
          // this.showSetPassword = false;
        }

        // If an error message was passed in from a magic link, then we don't wipe it here
        if (!(errorMessage && typeof errorMessage == "string"))
          this.errorMessage = null;

        this.$nextTick(() => {
          if (this.$refs.passwordField && this.showPassword)
            this.$refs.passwordField.focus();
        });
      } catch (err) {
        console.log("Error checking user by email ", err);
        if (typeof err == "string") this.errorMessage = err;
        else if (err && err.data && err.data.error_code == "1030004")
          this.errorMessage =
            "We couldn't find a user with that email or phone number.";
        else
          this.errorMessage =
            "A problem occurred while trying to log you in. Please try again or contact us.";
      } finally {
        this.submittingEmail = false;
      }
    },
    async loginUser() {
      try {
        this.submittingLogin = true;
        await this.$auth.loginV2(
          this.email,
          this.password,
          this.$route.params.appState
        );
        this.errorMessage = "Success";
        // this.errorMessage = null;
      } catch (err) {
        console.log("err", err);

        if (
          err.code &&
          err.code == "mfa_required" &&
          err.original &&
          err.original.response &&
          err.original.response.body &&
          err.original.response.body.mfa_token
        ) {
          this.mfaToken = err.original.response.body.mfa_token;
          this.sendMFAText();
        } else {
          this.password = null;
          if (err.description) this.errorMessage = err.description;
          else this.errorMessage = "An unknown error occurred.";
        }
      } finally {
        this.submittingLogin = false;
      }
    },
    async sendPasswordlessEmail() {
      try {
        this.submittingPasswordlessEmail = true;
        let res = await this.$auth.sendPasswordlessEmail(this.email);
        console.log("Passwordless res ", res);
        // this.errorMessage = "PW-less Success";
        this.currentSlide = this.keys.passwordlessEmail;
        this.errorMessage = null;

        // Set timeout so the page has time to transition to the next page
        setTimeout(() => {
          if (this.$refs.passwordlessEmailCodeField)
            this.$refs.passwordlessEmailCodeField.focus();
        }, 350);
      } catch (err) {
        console.log("err", err);
        this.password = null;
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingPasswordlessEmail = false;
      }
    },
    async submitPasswordlessEmailCode() {
      try {
        this.submittingPasswordlessEmail = true;
        let res = await this.$auth.submitPasswordlessEmailCode(
          this.email,
          this.passwordlessEmailCode,
          this.$route.params.appState
        );
        console.log("Passwordless res ", res);
        this.errorMessage = "PW-less Success";
        // this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        this.password = null;
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingPasswordlessEmail = false;
      }
    },
    async sendPasswordlessSMS() {
      try {
        this.submittingPasswordlessSMS = true;
        let res = await this.$auth.sendPasswordlessSMS(this.phone);
        console.log("Passwordless res ", res);
        // this.errorMessage = "PW-less Success";
        this.currentSlide = this.keys.passwordlessSMS;
        this.errorMessage = null;

        // Set timeout so the page has time to transition to the next page
        setTimeout(() => {
          if (this.$refs.passwordlessSMSCodeField)
            this.$refs.passwordlessSMSCodeField.focus();
        }, 350);
      } catch (err) {
        console.log("err", err);
        this.password = null;
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingPasswordlessSMS = false;
      }
    },
    async submitPasswordlessSMSCode() {
      try {
        this.submittingPasswordlessSMS = true;
        let res = await this.$auth.submitPasswordlessSMSCode(
          this.phone,
          this.passwordlessSMSCode,
          this.$route.params.appState
        );
        console.log("Passwordless res ", res);
        this.errorMessage = null;
        // this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        this.password = null;
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingPasswordlessSMS = false;
      }
    },
    async loginWithAzure() {
      try {
        this.submittingAzure = true;
        let res = await this.$auth.loginWithSAML(
          this.email,
          this.$route.params.appState
        );
        console.log("SAML res ", res);
        this.errorMessage = null;
        // this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        this.password = null;
        this.showSSOIsolated = false;
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingAzure = false;
      }
    },
    async loginWithOkta() {
      try {
        this.submittingSSO = true;
        let res = await this.$auth.loginWithOkta(
          this.email,
          this.$route.params.appState
        );
        console.log("SAML res ", res);
        this.errorMessage = null;
        // this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        this.password = null;
        this.showSSOIsolated = false;
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingSSO = false;
      }
    },
    async loginWithGeneric3PA(provider) {
      try {
        this.submittingSSO = true;
        let res = await this.$auth.loginWithGeneric3PA(
          provider,
          this.email,
          this.$route.params.appState ||
            (this.$route.query.redirect
              ? { targetUrl: this.$route.query.redirect }
              : null)
        );
        console.log("SAML res ", res);
        this.errorMessage = null;
        // this.errorMessage = null;
      } catch (err) {
        console.log("SAML err", err);
        this.password = null;
        this.showSSOIsolated = false;
        if (err.description) {
          if (err.description == "`state` does not match.")
            this.errorMessage =
              "Please disable third party cookies in your browser before logging into Whistle.";
          else this.errorMessage = err.description;
        } else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingSSO = false;
      }
    },
    async sendPasswordResetEmail() {
      // this.submittingPasswordReset = true;
      // UserService.resetPassword(this.email).then((x) => {
      //   console.log("Password reset email ", x);
      //   this.submittingPasswordReset = false;
      //   this.currentSlide = this.keys.forgotPasswordSuccess;
      // });

      try {
        this.submittingPasswordReset = true;
        let res = await UserService.resetPassword(this.email);
        console.log("Password reset email ", res);
        this.currentSlide = this.keys.forgotPasswordSuccess;
        this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingPasswordReset = false;
      }
    },
    async sendMFAText() {
      try {
        // this.currentSlide = this.keys.mfa;
        this.submittingMFAText = true;
        let res = await this.$auth.getMFAAuthenticators(this.mfaToken);
        console.log("MFA SMS res ", res);
        // Filter for active
        const activeAuthenticators = res.filter(x => x.active);
        const sms = activeAuthenticators.find(x => x.oob_channel == "sms");
        const email = activeAuthenticators.find(x => x.oob_channel == "email");
        this.showEmailMFA = email ? true : false;
        this.emailMFAIdentifier = email ? email.id : null;

        if (sms) {
          this.currentSlide = this.keys.mfa;
          this.mfaPhoneNumber = sms.name;
          // Now auto send the code
          const challengeRes = await this.$auth.challengeMFA(
            sms.id,
            this.mfaToken
          );
          console.log("Challenge res ", challengeRes);
          this.mfaOOBCode = challengeRes.oob_code;
        } else {
          // We need to sign them up for SMS MFA
          this.currentSlide = this.keys.mfaRegister;
        }
        this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        if (err.response && err.response.status == 429)
          this.errorMessage =
            "You have requested too many verification codes. Please try again in a few minutes.";
        else if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingMFAText = false;
      }
    },
    async registerMFA() {
      try {
        // this.currentSlide = this.keys.mfa;
        this.submittingMFAVerification = true;
        console.log("Registering phone num ", this.mfaPhoneNumberFormatted);
        let res = await this.$auth.registerMFA(
          this.mfaPhoneNumberFormatted,
          this.mfaToken
        );
        console.log("MFA Reigstration Res ", res);
        this.mfaOOBCode = res.oob_code;
        this.currentSlide = this.keys.mfaRegisterVerify;
        this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        if (err.response && err.response.status == 429)
          this.errorMessage =
            "You have requested too many verification codes. Please try again in a few minutes.";
        else if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingMFAVerification = false;
      }
    },
    async submitMFACode() {
      try {
        // this.currentSlide = this.keys.mfa;
        this.submittingMFACode = true;
        console.log(this.email);
        console.log(this.password);
        let res = await this.$auth.submitMFACode(
          // this.email,
          // this.password,
          this.mfaOOBCode,
          this.mfaCode,
          this.mfaToken
        );
        console.log("MFA SMS code response ", res);
        this.errorMessage = "Success";

        // this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingMFACode = false;
      }
    },
    async sendMFAEmail() {
      try {
        // this.currentSlide = this.keys.mfa;
        this.submittingMFAEmail = true;

        this.currentSlide = this.keys.mfaEmail;
        // Now auto send the code
        const challengeRes = await this.$auth.challengeMFA(
          this.emailMFAIdentifier,
          this.mfaToken
        );
        console.log("Challenge res ", challengeRes);
        this.mfaOOBCode = challengeRes.oob_code;

        this.errorMessage = null;
      } catch (err) {
        console.log("err", err);
        if (err.response && err.response.status == 429)
          this.errorMessage =
            "You have requested too many verification codes. Please try again in a few minutes.";
        else if (err.description) this.errorMessage = err.description;
        else this.errorMessage = "An unknown error occurred.";
      } finally {
        this.submittingMFAEmail = false;
      }
    },
    async setupPasswordUser() {
      try {
        this.submittingSetPassword = true;

        let user = await OnboardingService.setupPasswordForPasswordlessUser(
          this.email
        );
        console.log("PW setup response ", user);

        this.errorMessage = null;
        this.currentSlide = this.keys.passwordSetupSuccess;
        // Now we manually show the set password and assume a success
        this.showSetPassword = false;
        this.showPassword = true;
      } catch (err) {
        console.log("Error creating password user ", err);
        if (typeof err == "string") this.errorMessage = err;
        else if (err && err.data && err.data.error_code == "1030004")
          this.errorMessage =
            "We couldn't find a user with that email or phone number.";
        else
          this.errorMessage =
            "A problem occurred while trying to set up a password. Please try again or contact us.";
      } finally {
        this.submittingSetPassword = false;
      }
    },
    mfaPhoneValidation: function({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log(number);
      this.validMFAPhone = isValid;
      if (isValid) {
        this.mfaPhoneNumberFormatted = number.e164;
      }
      // if (isValid) {
      //   this.businessPhoneNumberValid = true;
      // } else {
      //   this.businessPhoneNumberValid = false;
      // }
    },
    updateMFAPhone: function() {
      if (!this.mfaPhoneNumber || !this.validMFAPhone) {
        this.mfaPhoneNumber = null;
        this.validMFAPhone = false;
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "marqeta", "permissions"]),
    obscuredPhone() {
      if (!this.phone) return;
      return "XXXXXX" + this.phone.substr(-4);
    },
    showPopupButton() {
      return (
        this.$route.name == "generic-3pa-login" &&
        this.$route.params.provider == "procore"
      );
    }
  },

  watch: {
    "$auth.loading": function(newVal) {
      if (newVal == false) {
        // We're done loading
        // We add this watcher because the login page is shown immediately if the user routes to the special route.
        // But the auth0 client might not be ready yet so we wait for the loading watcher
        if (this.$route.name == "spirit-login" && this.$auth.auth0ClientV2) {
          console.log("LOADING SPIRIT");
          this.showSSOIsolated = true;
          this.loginWithAzure();
        } else if (
          this.$route.name == "okta-login" &&
          this.$auth.auth0ClientV2
        ) {
          console.log("LOADING OKTA");
          this.showSSOIsolated = true;
          this.loginWithOkta();
        } else if (
          this.$route.name === "generic-3pa-login" &&
          this.$auth.auth0ClientV2
        ) {
          console.log(
            "LOADING GENERIC-3PA",
            "https://nehalk.medium.com/56-funny-code-comments-that-people-actually-wrote-6074215ab387"
          );
          this.showSSOIsolated = true;
          if (!this.showPopupButton)
            this.loginWithGeneric3PA(this.$route.params.provider);
        }
      }
    }
  }
};
</script>

<style scoped>
.gradient-background {
  /* background-image: url(https://storage.googleapis.com/whistle-dev-pictures/Logo/gradient.png); */
  background: linear-gradient(
      45deg,
      rgba(161, 215, 110, 1) 1%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}

.box-outline {
  border: solid;
  border-color: lightgray;
  border-width: 2px;
}

.icon-outer-ring {
  width: 110px;
  height: 110px;
}

.large-icon {
  transform: scale(1.5);
}

.icon-inner-ring {
  width: 94px;
  margin: 8px;
  height: 94px;
}

.language-selector {
  max-width: 250px;
}

.title-text {
  font-size: 1.5em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.progress-bar {
  width: calc(50% - 80px);
}

.mini-icon {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
}
.download-icon {
  min-width: 24px;
}

.form-card {
  background-color: white;
}

@media (max-width: 600px) {
  .form-card {
    width: 100vw;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    justify-content: center;
  }
}
@media (min-width: 601px) {
  .form-card {
    width: auto;
    padding: 0;
    margin: 0;
    justify-content: center;
    min-height: 70vh;
  }
}

.bar-line {
  height: 0.5px;
  border: solid 0.5px;
  border-color: lightgray;
  width: 40%;
}

.outer-circle {
  border-radius: 1000px;
  width: 125px;
  height: 125px;
  min-height: 125px;
  min-width: 125px;
  max-height: 125px;
  max-width: 125px;
}

.inner-circle {
  border-radius: 1000px;
  width: 109px;
  height: 109px;
  min-width: 109px;
  min-height: 109px;
  max-width: 109px;
  max-height: 109px;
  margin-top: 8px;
  margin-left: 8px;
}

.dark-blue-background {
  background-color: #97dffc;
}
.light-blue-background {
  background-color: #09acec;
}

.email-lock {
  pointer-events: auto !important;
}
</style>

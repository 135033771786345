import throttle from 'lodash.throttle';
/**
 * Throttle all functions on an object
 * @param {T} object 
 * @param {Parameters<import('lodash.throttle')>["2"] & { wait: number}} opts lodash.throttle options + opts.wait; opts.wait is 2000 (ms) by default
 * @returns {T}
 * @template T
 */
export default function throttled(object, opts) {
    return Object.fromEntries(Object.entries(object).map(([k, v]) => [k, v instanceof Function ? throttle(v, opts?.wait || 2000, {
        ...(opts || {

        })
    }) : v]))
}
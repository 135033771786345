var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-height",attrs:{"rounded":"0"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between full-height pt-6"},[_c('v-row',{staticClass:"full-width",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"7","md":"8","lg":"7","xl":"7"}},[_c('div',{staticClass:"rounded-lg py-5"},[_c('transition',{attrs:{"name":_vm.slideDirection,"mode":"out-in"}},[(_vm.survey !== null)?_c('v-card',{key:0,staticClass:"main-card text-left",attrs:{"elevation":"0","rounded":"2"}},[_c('div',{staticClass:"main-card-content"},[_c('Survey',{attrs:{"moduleId":_vm.survey.learningModuleId,"displayName":_vm.survey.displayName,"chapterId":_vm.survey.LearningChapters[_vm.currentChapterIndex]
                      .learningChapterId,"currentLessonCard":_vm.currentLesson,"completedStatus":_vm.surveyCompleted,"whistleToken":_vm.token.token,"email":_vm.email,"surveyClientId":_vm.survey.clientId,"isSurveyRepeatable":_vm.isSurveyRepeatable,"isConclusion":_vm.isConclusion},on:{"surveyCompleted":_vm.goToNextChapter,"update-completed":function($event){_vm.surveyCompleted = $event},"retakeSurvey":_vm.restartSurvey}})],1)]):_c('v-card',{key:1,staticClass:"main-card text-left",attrs:{"elevation":"0","rounded":"2"}},[_c('div',{staticClass:"main-card-content"},[_c('p',{staticClass:"brandCyan--text font-italic font-weight-bold mx-8"},[_vm._v(" Welcome ")]),_c('p',{staticClass:"text-h6 font-weight-bold mx-8 mt-10 email-text"},[_vm._v(" Please enter your email address. It's used to create a unique record for your survey answers. ")]),_c('v-form',{attrs:{"onSubmit":"return false;"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"mx-8 mt-8",attrs:{"disabled":_vm.loadingSurvey,"autofocus":"","label":"Email","color":"brandCyan","rules":[
                      v => !!v || 'An email is required',
                      v =>
                        !!(v && v.length < 255) ||
                        'The email should be shorter',
                      v =>
                        /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(
                          v
                        ) || 'Email must be valid',
                      v =>
                        !!(v && v.length < 256) || 'Email should be shorter'
                    ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.captureEmail.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('p',{staticClass:"grey--text mx-8 font-italic"},[_vm._v("Required")]),_c('div',{staticClass:"d-flex flex-column align-center mt-9"},[_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","rounded":"","color":"brandCyan","width":"120","loading":_vm.loadingSurvey,"disabled":!_vm.valid},on:{"click":_vm.captureEmail}},[_vm._v("Submit")]),_c('p',{staticClass:"red--text text-center mt-5"},[_vm._v(_vm._s(_vm.errorMessage))])],1)],1)])],1)],1)])],1),_c('v-row',{staticClass:"full-width mt-3",attrs:{"justify":"center","align":"start","no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"darkGrey--text"},[_vm._v(_vm._s((_vm.survey && _vm.survey.displayName) || "Survey")+" | Powered by "),_c('a',{staticClass:"font-weight-bold darkGrey--text cursor-pointer text-decoration-none",attrs:{"href":"https://wewhistle.com","target":"_blank"}},[_vm._v("Whistle")])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
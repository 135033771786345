var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 flex-column d-flex"},[_c('v-card',{staticClass:"quiz-card",attrs:{"rounded":"0","width":"100%","elevation":"0"}},[_c('v-card-title',{staticClass:"word-break px-0 font-weight-bold brand--text"},[_vm._v(" "+_vm._s(_vm.question.content)+" ")]),_c('p',{staticClass:"grey--text mb-1 font-italic"},[_vm._v(" Choose one or more responses from the answers below ")]),_c('v-divider'),_c('v-item-group',{class:_vm.answerSubmitted ? 'radio-group-disabled' : '',attrs:{"multiple":""},model:{value:(_vm.checkedAnswers),callback:function ($$v) {_vm.checkedAnswers=$$v},expression:"checkedAnswers"}},_vm._l((_vm.question.LearningQuizAnswers),function(answer){return _c('div',{key:answer.learningQuizAnswerId},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"answer-box d-flex align-center cursor-pointer my-1",class:{
              'light-grey-background': hover && !_vm.answerSubmitted,
              'light-red-background':
                _vm.answerSubmitted &&
                answer.correctness <= 0 &&
                _vm.checkedAnswers
                  .map((x) => x.learningQuizAnswerId)
                  .includes(answer.learningQuizAnswerId),
              'brand-pale-green-background':
                _vm.answerSubmitted &&
                _vm.correctAnswers
                  .map((x) => x.learningQuizAnswerId)
                  .includes(answer.learningQuizAnswerId),
            },on:{"click":function($event){return _vm.clickAnswer(answer)}}},[_c('div',{staticClass:"d-flex justify-center mx-n8"},[_c('v-icon',{staticClass:"correct-icon mr-8 ml-n6",class:{
                  hidden: !_vm.answerIconLogic(answer),
                },attrs:{"color":_vm.answerIconLogic(answer) == 'mdi-check-circle'
                    ? 'brandGreen'
                    : 'red'}},[_vm._v(_vm._s(_vm.answerIconLogic(answer) || "mdi-close"))]),_c('v-checkbox',{attrs:{"label":answer.content,"value":_vm.checkedAnswers.find((x) => x == answer),"color":_vm.answerSubmitted ? 'grey' : 'brandCyan',"readonly":_vm.answerSubmitted,"disabled":_vm.answerSubmitted},on:{"click":function($event){$event.stopPropagation();return _vm.clickAnswer(answer)}}})],1)])]}}],null,true)})],1)}),0),_c('v-divider'),_c('div',{staticClass:"d-flex flex-column align-center mt-10"},[(_vm.answerSubmitted)?_c('p',{staticClass:"text-center"},[_vm._v(" Shading indicates correctness, icons indicate which selections you got right ")]):_vm._e(),(_vm.answerSubmitted == false)?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":"","disabled":_vm.checkedAnswers == null || _vm.checkedAnswers.length == 0},on:{"click":_vm.checkQuestion}},[_vm._v(" "+_vm._s(_vm.computedSubmitButton)+" ")]):(
          _vm.questionIndex !== _vm.questionCount - 1 && _vm.answerSubmitted == true
        )?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":""},on:{"click":_vm.goToNextQuestion}},[_vm._v(" Next ")]):(
          _vm.questionIndex == _vm.questionCount - 1 &&
            _vm.answerSubmitted == true &&
            !_vm.isSurveyQuestion
        )?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":""},on:{"click":_vm.completeQuiz}},[_vm._v(" Complete ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-width"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.rewardActions.filter(
        x => !x.permission || _vm.permissions.includes(x.permission)
      )),function(action,index){return _c('v-col',{key:index,staticClass:"card-col",class:{
        'mt-n2': index == 0 || (index == 1 && !_vm.isMobile),
        'pr-3': !_vm.isMobile && index % 2 == 0,
        'pl-3': !_vm.isMobile && index % 2 == 1,
        'px-1': _vm.isMobile
      },attrs:{"cols":_vm.isMobile ? '12' : '6'}},[_c('v-card',{style:({ height: 'fit-content', 'min-height': '100%' }),attrs:{"elevation":"1","to":action.to},on:{"click":function($event){return _vm.loadAction(action)}}},[_c('div',{staticClass:"pa-3 d-flex flex-column justify-center"},[_c('div',{staticClass:"d-flex justify-space-between align-center pt-4 pb-2 mb-2 word-break card-header"},[_c('div',{staticClass:"d-flex align-center pr-2"},[_c('v-icon',{staticClass:"pa-2 rounded mr-4 brand-dark-green-background icon",class:action.backgroundColor,attrs:{"large":"","color":"white"}},[_vm._v(_vm._s(action.icon))]),_c('span',{domProps:{"innerHTML":_vm._s(action.headline)}})],1),_c('v-icon',[_vm._v("mdi-chevron-right")])],1),_c('span',{staticClass:"mb-2 text-left",domProps:{"innerHTML":_vm._s(action.body)}})])])],1)}),1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.annualRewardBlocker),callback:function ($$v) {_vm.annualRewardBlocker=$$v},expression:"annualRewardBlocker"}},[(_vm.annualRewardBlocker)?_c('PopupDialog',{attrs:{"icon":"mdi-alert","iconColor":"error","centerText":true,"title":"Looks like you don't have permission for this","subtitle":"Only users with the 'Client Admin' role can set up and manage these rewards.","button2Text":"Ok","button2Width":"140"},on:{"button2":function($event){_vm.annualRewardBlocker = false}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
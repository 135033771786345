<template>
  <div>
    <transition :name="slideDirection" mode="out-in">
      <div
        v-if="currentSlide == 0"
        :class="{
          'px-4': !isMobile || (isMobile && source == 'WALLET_GIVING')
        }"
      >
        <v-card-title class="word-break pl-0 mt-3" v-if="!givingMode">
          Add credit card info and the amount to transfer to
          {{
            budget && budget.budgetDisplayName
              ? `'${budget.budgetDisplayName}'`
              : "your rewards account"
          }}
          <!-- Funding {{ budget ? budget.budgetDisplayName : "budget" }} -->
        </v-card-title>
        <v-card-title class="word-break pl-0" v-else>
          Gift to
          {{
            (userProfile &&
              userProfile.Client &&
              userProfile.Client.clientName) ||
              "client"
          }}
        </v-card-title>
        <v-form v-model="form">
          <v-select
            :items="cards"
            outlined
            attach
            dense
            :menu-props="{ offsetY: true }"
            label="Saved Credit Cards"
            item-text="last4"
            color="brandCyan"
            return-object
            v-model="selectedCard"
            class="mt-5"
            hide-details
            v-if="cards.length > 1"
            :rules="[
              v =>
                !!(v && v.last4 && v.last4 != '0000') ||
                'A credit card is required'
            ]"
          >
            <template slot="item" slot-scope="data">
              {{ data.item.brand }} {{ data.item.last4 }}
            </template>
            <template slot="selection" slot-scope="data">
              {{ data.item.brand }} {{ data.item.last4 }}
            </template></v-select
          >

          <v-btn
            text
            small
            color="primary"
            class="mt-1 mb-2 px-1"
            v-if="cards.length > 1"
            @click="resetForm('manage')"
            >Manage my cards</v-btn
          >

          <h4
            v-if="
              fundingLimitValidation !== true ||
                (fundingLimit && fundingLimit.cooldownActive)
            "
          >
            <v-icon color="error" class="mr-2">mdi-alert</v-icon> Why can't I
            fund more right now?
          </h4>
          <p class="mt-2" v-if="fundingLimit && fundingLimit.cooldownActive">
            {{ fundingLimitValidation }} If you have questions, please contact
            us at
            <a class="text-decoration-none" href="mailto:help@wewhistle.com"
              >help@wewhistle.com</a
            >.
          </p>
          <p class="mt-2" v-else-if="fundingLimitValidation !== true">
            Whistle's banking partners require a business verification process
            to be completed before adding more funds to your budgets. This
            process can be completed on the
            <span
              @click="loadKYB"
              :class="{
                'primary--text': canCompleteKYB,
                'cursor-pointer': canCompleteKYB
              }"
              >Payment Admin page</span
            >
            by a Whistle Finance Admin. Your Whistle CSM can help you through
            this process, as well as answer any questions you have.
          </p>

          <v-text-field
            label="Name as it appears on the card"
            v-model="name"
            type="text"
            color="brandCyan"
            outlined
            dense
            class="mt-2"
            :rules="[v => !!v || 'A name is required']"
            v-if="selectedCard.last4 == 'Add new card' || cards.length < 2"
          >
            <v-text-field
              v-if="userProfile && !userProfile.businessEmail"
              label="Email address to send receipt to"
              v-model="email"
              outlined
              dense
              color="brandCyan"
              class="mt-2"
              :rules="[
                v => !!v || 'An email is required',
                v =>
                  !!(v && emailRegex.test(v)) ||
                  'The email does not appear valid'
              ]"
            />
          </v-text-field>

          <div :hidden="hideCCField" ref="card" id="stripe-script"></div>
          <p
            v-if="stripeErrorText && !hideCCField"
            class="text-caption error--text ml-3"
          >
            {{ stripeErrorText }}
          </p>
          <v-checkbox
            :label="
              givingMode
                ? 'Save card for future use'
                : 'Save card for ease of use'
            "
            v-model="saveCard"
            color="brandCyan"
            v-if="selectedCard.last4 == 'Add new card' || cards.length < 2"
          ></v-checkbox>
          <div class="d-flex justify-space-between">
            <p class="mb-0 mt-2 mr-3">
              {{ givingMode ? "Amount to give" : "Amount to transfer" }}
            </p>
            <v-text-field
              placeholder="0"
              prefix="$"
              v-model="amount"
              type="number"
              color="brandCyan"
              outlined
              dense
              class=""
              style="max-width: 50%; width: 50%; min-width: 120px;"
              @wheel="$event.target.blur()"
              :rules="[
                v => !!v || 'Amount is required',
                v =>
                  !!(v && parseFloat(v) >= 0.5) ||
                  'Amount should be greater than $0.50',
                v =>
                  !!(
                    v &&
                    (!v.toString().includes('.') ||
                      (v.toString().includes('.') &&
                        v.substring(v.indexOf('.') + 1).length < 3))
                  ) || `Payment amount shouldn't be longer than 2 decimals.`,
                v =>
                  !!(v && parseFloat(v) <= 25000) ||
                  'Amount should be smaller than 25k',
                fundingLimitValidation
              ]"
            />
          </div>
          <div class="d-flex justify-space-between" v-if="!givingMode">
            <p class="mb-0 mt-2 mr-3">
              Current rewards account balance
            </p>
            <p class="mb-0">
              {{ currentBudgetBalance }}
            </p>
          </div>
          <div v-if="!givingMode" class="d-flex full-width justify-end my-4">
            <v-divider style="max-width: 30%; min-width: 100px;" />
          </div>
          <div class="d-flex justify-space-between" v-if="!givingMode">
            <p class="mb-0 mr-3">
              Rewards account after transfer
            </p>
            <p class="mb-0 font-weight-bold">
              {{ futureBudgetBalance }}
            </p>
          </div>
          <div class="d-flex justify-space-between my-4" v-if="!givingMode">
            <p class="mb-0 mt-2 mr-3">
              Credit card transfer fee
            </p>
            <p class="mb-0">
              {{ calculatedFee }}
            </p>
          </div>
          <div v-if="!givingMode" class="d-flex full-width justify-end my-4">
            <v-divider style="max-width: 30%; min-width: 100px;" />
          </div>
          <div class="d-flex justify-space-between my-4" v-if="!givingMode">
            <p class="mb-0 mt-2 mr-3">
              Total amount to be charged to your card
            </p>
            <p class="mb-0 font-weight-bold">
              {{ calculatedTotalCharge }}
            </p>
          </div>

          <div class="d-flex justify-center">
            <v-btn
              color="brandCyan"
              depressed
              class="mt-2 mb-2 white--text"
              width="140"
              :disabled="!form || !!(stripeErrorText && !hideCCField)"
              @click="chargeCard"
              >{{ givingMode ? "Submit" : "Transfer" }}</v-btn
            >
          </div>
        </v-form>
      </div>
      <v-row
        v-else-if="currentSlide == 1"
        key="1"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <v-card-title>Processing the payment...</v-card-title>

        <Robin
          :showText="false"
          :width="120"
          :height="120"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == 2"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <v-card-title>All done!</v-card-title>
        <caption class="grey--text mt-0" v-if="givingMode">
          The payment was successful! We'll email you with a receipt.
        </caption>
        <caption class="grey--text mt-0" v-else>
          The payment was successful! Your budget will be funded momentarily.
          We'll email you with a receipt.
        </caption>

        <Robin
          :showText="false"
          :width="120"
          :height="120"
          animation="backflip"
          :loop="false"
        />

        <v-btn
          v-if="!givingMode"
          color="brandCyan"
          depressed
          class="mt-8 white--text"
          width="140"
          @click="resetForm('reset')"
          >Done</v-btn
        >
      </v-row>
      <v-row
        v-else-if="currentSlide == 3"
        key="8"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
      >
        <v-card-title class="title-text word-break full-width">{{
          errorMessage
            ? errorMessage
            : "There was an error processing the payment"
        }}</v-card-title>

        <p class="pl-4 full-width align-text-left">
          If you continue to have trouble, feel free to reach out to customer
          service via one of the following methods...
        </p>
        <p class="pl-4 align-text-left full-width">
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p class="pl-4 align-text-left full-width">
          Phone (Toll Free) - <b>(855) 264-3329</b>
        </p>
        <div class="d-flex mt-4">
          <v-btn
            color="brandCyan"
            depressed
            class="mr-4 white--text"
            width="150"
            @click="
              (currentSlide = 0), (errorMessage = null), loadStripeWidget(true)
            "
            >Go back</v-btn
          >
          <v-btn
            color="brandCyan"
            depressed
            class="white--text"
            width="150"
            @click="resetForm"
            >Exit</v-btn
          >
        </div>
      </v-row>
    </transition>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import Robin from "@/components/Robin";
import moment from "moment";
import currency from "currency.js";
import { emailRegex } from "@/shared_data/data.js";

// import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "StripeCCPayment",
  props: {
    budget: Object,
    cards: Array,
    fundingLimit: Object,
    canCompleteKYB: Boolean,
    source: String,
    givingMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Robin
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      form: null,
      amount: null,
      email: null, //Used for SMS only users
      name: null,
      bankId: null,
      errorMessage: null,
      saveCard: false,
      selectedCard: { id: -1, last4: "0000" },
      stripeErrorText: "A card is required",
      emailRegex
      // fee: 3.1,
    };
  },
  created() {
    console.log("Created stripe cc payment widget");
    //this.getStripeBanks();
  },
  mounted() {
    this.loadStripeWidget();
  },
  beforeDestroy() {},
  methods: {
    loadStripeWidget(delay) {
      // Delay is used because the stripe script in the DOM hasn't loaded in yet when the user clicks "Go Back"
      this.currentSlide = 0;
      // So we don't load the script multiple times
      if (!this.isScriptLoaded("//js.stripe.com/v3/")) {
        this.includeStripe(
          "js.stripe.com/v3/",
          function() {
            this.configureStripe(delay);
          }.bind(this)
        );
      } else {
        this.configureStripe(delay);
      }
    },
    isScriptLoaded(src) {
      return Boolean(document.querySelector('script[src="' + src + '"]'));
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe(delay = false) {
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY); //eslint-disable-line

      this.elements = this.stripe.elements();
      this.card = this.elements.create("card");
      this.card.on("change", e => {
        console.log("CARD CHANGE EVENT ", e);
        if (e.error) {
          this.stripeErrorText = e.error.message;
        } else if (e.empty) this.stripeErrorText = "A card is required";
        else if (!e.complete) this.stripeErrorText = "A card is required";
        else this.stripeErrorText = null;
      });

      if (delay) {
        var timeout = 300;
      } else {
        timeout = 0;
      }
      console.log(delay);
      setTimeout(() => {
        this.card.mount("#stripe-script");
      }, timeout);
    },
    async chargeCard() {
      // this.currentSlide = 3;
      //if they select a card from the dropdown, selected id will not be null
      var startTime = new Date();
      if (this.selectedCard.id != null && this.selectedCard.id != -1) {
        this.currentSlide = 1;
        PaymentService.createStripePayment(
          this.givingMode ? this.userProfile.clientId : this.budget.clientId,
          this.selectedCard.id, //token
          this.amount,
          currency(this.calculatedFee).value, //fee
          this.givingMode ? null : this.budget.budgetId, //budgetId
          this.selectedCard.brand + "..." + this.selectedCard.last4, // budget act description
          this.givingMode
            ? `Donate to ${
                this.userProfile && this.userProfile.Client
                  ? this.userProfile.Client.clientName
                  : " client."
              }`
            : "Funding Whistle Budget " +
                (this.budget.budgetDisplayName
                  ? this.budget.budgetDisplayName
                  : this.budget.budgetName) +
                " (inclusive of fees)", //receipt description
          this.email || this.userProfile.businessEmail, //email
          null, //authorization date
          null, //ip address
          "CREDIT_CARD",
          true,
          this.source,
          this.givingMode,
          this.magicLinkToken
        )
          .then(resp => {
            console.log("Got payment resp: ", resp);

            var timeDiff = moment().diff(moment(startTime));
            if (timeDiff >= 1500) timeDiff = 1500;
            setTimeout(() => {
              if (resp.error) {
                console.log("ERROR WITH STRIPE CC CHARGE Response", resp.error);
                if (resp.error.code == "card_declined") {
                  this.errorMessage = "The card submitted was declined";
                } else if (
                  typeof resp.error == "string" &&
                  resp.error.includes("cooldown")
                ) {
                  this.errorMessage =
                    "You have too many funding attempts. Please try again later";
                  this.$emit("get-funding-limit");
                }
                this.currentSlide = 3;
                return;
              }
              this.currentSlide = 2;
              this.getBudgets();
            }, 1500 - timeDiff);
          })
          .catch(error => {
            console.log("ERROR WITH STRIPE CC CHARGE", error);
            this.currentSlide = 3;
          });
      } else {
        this.createStripeToken()
          .then(token => {
            console.log("now charge the token");

            // Loading screen
            this.currentSlide = 1;

            if (this.saveCard) {
              console.log("Saving credit card");
              //var savedCC = await this.createStripeBank(token.token.id);
              this.createStripeBank(token.token.id)
                .then(resp => {
                  console.log(resp);
                  console.log("created...");
                  //console.log(savedCC);
                  console.log("now charge saved cc");

                  PaymentService.createStripePayment(
                    this.givingMode
                      ? this.userProfile.clientId
                      : this.budget.clientId,
                    resp.id,
                    this.amount,
                    currency(this.calculatedFee).value, //fee
                    this.givingMode ? null : this.budget.budgetId,
                    resp.brand + "..." + resp.last4,
                    this.givingMode
                      ? `Donate to ${
                          this.userProfile && this.userProfile.Client
                            ? this.userProfile.Client.clientName
                            : " client."
                        }`
                      : "Funding Whistle Budget " +
                          (this.budget.budgetDisplayName
                            ? this.budget.budgetDisplayName
                            : this.budget.budgetName) +
                          " (inclusive of fees)",
                    this.email || this.userProfile.businessEmail,
                    null, //authorization date
                    null, //ip address
                    "CREDIT_CARD", //type
                    true, //saved card
                    this.source, //source for platform activity
                    this.givingMode,
                    this.magicLinkToken
                  )
                    .then(resp => {
                      console.log("Got payment resp: ", resp);

                      var timeDiff = moment().diff(moment(startTime));
                      if (timeDiff >= 1500) timeDiff = 1500;

                      setTimeout(() => {
                        if (resp.error) {
                          console.log(
                            "ERROR WITH STRIPE CC CHARGE Response",
                            resp.error
                          );
                          if (resp.error.code == "card_declined") {
                            this.errorMessage =
                              "The card submitted was declined";
                          } else if (
                            typeof resp.error == "string" &&
                            resp.error.includes("cooldown")
                          ) {
                            this.errorMessage =
                              "You have too many funding attempts. Please try again later";
                            this.$emit("get-funding-limit");
                          }
                          this.currentSlide = 3;
                          return;
                        }
                        this.currentSlide = 2;
                        this.getBudgets();
                      }, 1500 - timeDiff);
                    })
                    .catch(error => {
                      console.log("ERROR WITH STRIPE CC CHARGE", error);
                      this.currentSlide = 3;
                    });
                })
                .catch(error => {
                  console.log("ERROR WITH STRIPE CC CHARGE", error);
                  this.currentSlide = 3;
                });
            } else {
              console.log("Charging card without saving");
              PaymentService.createStripePayment(
                this.givingMode
                  ? this.userProfile.clientId
                  : this.budget.clientId,
                token.token.id,
                this.amount,
                currency(this.calculatedFee).value, //fee
                this.givingMode ? null : this.budget.budgetId,
                "Credit Card Charge",
                this.givingMode
                  ? `Donate to ${
                      this.userProfile && this.userProfile.Client
                        ? this.userProfile.Client.clientName
                        : " client."
                    }`
                  : "Funding Whistle Budget " +
                      (this.budget.budgetDisplayName
                        ? this.budget.budgetDisplayName
                        : this.budget.budgetName) +
                      " (inclusive of fees)",
                this.email || this.userProfile.businessEmail,
                null, //authorization date
                null, //ip address
                "CREDIT_CARD", //type
                false, //saved card
                this.source, //source for platform activity
                this.givingMode,
                this.magicLinkToken
              )
                .then(resp => {
                  console.log("Got payment resp:: ", resp);

                  if (resp.error) {
                    console.log(
                      "ERROR WITH STRIPE CC CHARGE Response",
                      resp.error
                    );
                    if (resp.error.code == "card_declined") {
                      this.errorMessage = "The card submitted was declined";
                    } else if (
                      typeof resp.error == "string" &&
                      resp.error.includes("cooldown")
                    ) {
                      this.errorMessage =
                        "You have too many funding attempts. Please try again later";
                      this.$emit("get-funding-limit");
                    }
                    this.currentSlide = 3;
                  } else {
                    var timeDiff = moment().diff(moment(startTime));
                    if (timeDiff < 1500) {
                      setTimeout(() => {
                        this.currentSlide = 2;
                      }, 1500 - timeDiff);
                    } else {
                      this.currentSlide = 2;
                      this.getBudgets();
                    }
                  }
                })
                .catch(error => {
                  console.log("ERROR WITH STRIPE CC CHARGE", error);
                  this.currentSlide = 3;
                });
            }
          })
          .catch(error => {
            console.log("ERROR;:", error);
            this.currentSlide = 3;
          });
      }

      //var token = await this.createStripeToken();
      console.log("!!!!");
    },
    async createStripeToken() {
      console.log("Generating token");
      var result = await this.stripe.createToken(this.card, {
        name: this.name
      });

      if (result.error) {
        console.log("Error");
        //this.currentSlide += 3;
        throw result.error;
      } else {
        //this.createStripeBank(result.token.id);
        console.log(result.token.id);
        return result;
      }
    },
    async createStripeBank(token) {
      console.log("Creating bank source");
      console.log(token);

      var resp = await PaymentService.createStripeBankAccount(
        token,
        this.magicLinkToken
      );
      console.log("Got bank resp: ", resp);
      if (resp.error) {
        if (resp.error.code == "bank_account_exists")
          this.errorMessage =
            "Looks like this account is already linked in Whistle";
        else if (resp.error.code == "card_declined")
          this.errorMessage = "The card entered was declined. Please try again";
        else this.errorMessage = null;

        throw resp.error;
      } else {
        return resp;
      }
    },
    resetForm(screen = null) {
      this.$emit("scrollToTop");
      this.$emit("reset", screen);
      // this.$emit("get-cards");
    },
    previousSlide() {
      // this.$emit("scrollToTop");
      this.resetForm();
    },
    loadKYB() {
      if (this.canCompleteKYB)
        this.$router.push({
          name: "paymentadmin",
          params: {
            preload: "kyb",
            previousRoute: this.$route.name
          }
        });
    },
    getBudgets() {
      this.$emit("get-budgets");
    }
  },
  computed: {
    ...mapState(["userProfile", "permissions", "magicLinkToken"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    currentAmount() {
      let amt = currency(0);
      if (this.amount) amt = currency(this.amount);
      return amt.format({ symbol: "$ " });
    },
    currentBudgetBalance() {
      let balance = currency(0);
      if (this.budget && this.budget.budgetBalance)
        balance = currency(this.budget.budgetBalance);
      return balance.format({ symbol: "$ " });
    },
    futureBudgetBalance() {
      return currency(this.currentBudgetBalance)
        .add(this.currentAmount)
        .format({ symbol: "$ " });
    },
    calculatedFee() {
      var totalFee = currency(0);
      // var totalFee = ((parseFloat(this.amount) + 0.3) / 100) * 2.9;
      // console.log((parseFloat(this.amount) + 0.3) / 0.971 - this.amount);
      if (this.amount)
        totalFee = currency(this.currentAmount)
          .add(0.3)
          .divide(0.971)
          .subtract(this.currentAmount);
      // .add(0.3);
      // .subtract(this.amount);
      // .toFixed(2);

      return totalFee.format({ symbol: "$ " });
    },
    calculatedTotalCharge() {
      return currency(this.currentAmount)
        .add(this.calculatedFee)
        .format({ symbol: "$ " });
    },
    hideCCField() {
      // Hides if length > 2 because that means they have one card saved, and then we add an "Add a card" option
      if (this.cards.length < 2 || this.selectedCard.id == null) {
        return false;
      } else {
        return true;
      }
    },
    fundingLimitValidation() {
      if (this.fundingLimit && this.fundingLimit.cooldownActive) {
        if (this.fundingLimit.cooldownExpirationLocal)
          return `The maximum number of funding attempts in a short timespan has been reached. Please try again at any point after ${this.fundingLimit.cooldownExpirationLocal}.`;
        else
          return (
            "The maximum number of funding attempts in a short timespan has been reached. Please try again in " +
            this.fundingLimit.cooldownHoursRemaining +
            (this.fundingLimit.cooldownHoursRemaining == 1
              ? " hour."
              : " hours.")
          );
      } else if (
        // this.fundingLimit.canFundMore &&
        this.fundingLimit &&
        !this.fundingLimit.passedKYB &&
        this.amount &&
        this.amount > this.fundingLimit.allowedToFund
      ) {
        return (
          "The funding limit has been exceeded. You can fund up to " +
          currency(this.fundingLimit.allowedToFund).format()
        );
      }
      return true;
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

#stripe-script {
  border: 1px solid #999;
  padding: 10px;
  border-radius: 4px;
}

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}
</style>

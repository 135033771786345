<template>
  <div>
    <v-card-title v-if="!cardOnly">
      <v-row>
        <v-col
          class="d-flex flex-column align-start ml-2"
          sm="6"
          md="6"
          v-if="!token"
        >
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1"
            @click="previousSlide"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title>
    <h2 v-if="loading">Loading your virtual card...</h2>
    <p v-if="loading" class="grey--text mt-2">
      This should only take a few seconds.
    </p>
    <Robin
      v-if="loading"
      :showText="false"
      :width="140"
      :height="140"
      animation="babyDance"
      :loop="true"
      class="d-flex justify-center"
    />
    <h1 v-if="!loading && !cardOnly">Your Virtual Card</h1>
    <div id="marqeta-script"></div>
    <div id="marqeta-bootstrap-script"></div>
    <v-card
      class="mx-auto align-text-left py-2 px-2 virtual-card"
      :elevation="elevation ? 2 : 0"
      :color="skinCliqueCard ? '#1B1A30' : 'white'"
      :class="{
        'hidden shrink': loading,
        'my-4': !cardOnly,
        'card-border rounded-xl': !elevation
      }"
    >
      <div
        class="d-flex flex-column px-xs-6"
        :class="{
          'brand--text': !skinCliqueCard,
          'white--text': skinCliqueCard
        }"
      >
        <div
          v-if="helenCard || bcaCard || lucidworksCard || concordProjectsCard"
          class="d-flex justify-space-between"
        >
          <v-img
            v-if="helenCard"
            src="@/assets/Logo/helen.jpg"
            max-height="80"
            max-width="52"
            contain
            class="mb-1"
          />
          <v-img
            v-else-if="bcaCard"
            src="@/assets/Logo/bca.png"
            max-width="115"
            class="mb-2"
          />
          <v-img
            v-else-if="lucidworksCard"
            src="@/assets/Logo/lucidworks.png"
            max-width="195"
            contain
            class="mb-0"
          />
          <v-img
            v-else-if="concordProjectsCard"
            src="@/assets/Logo/concord-projects.png"
            max-width="135"
            contain
            class="mb-1"
          />

          <div class="d-flex flex-column align-end mr-3">
            <p class="customer-service-text mb-1 text-right">
              Customer service www.wewhistle.com/card-support
            </p>
            <p class="virtual-card-text"><b>Virtual Card</b></p>
          </div>
        </div>
        <div v-else>
          <p
            class="customer-service-text mb-1"
            :class="{
              'text-right mr-3':
                prolinkCard || skinCliqueCard || shannonCard || lucidworksCard
            }"
          >
            Customer service
            {{
              kennelwoodCard
                ? "kennelwood.com/rewards"
                : "www.wewhistle.com/card-support"
            }}
          </p>
          <div class="d-flex justify-space-between align-center mb-3">
            <v-img
              v-if="kennelwoodCard"
              src="@/assets/Logo/kennelwood.png"
              max-width="135"
            />
            <v-img
              v-else-if="prolinkCard"
              src="@/assets/Logo/prolink-blue.png"
              max-width="175"
              height="50"
              contain
              class="mb-2"
            />
            <v-img
              v-else-if="skinCliqueCard"
              src="@/assets/Logo/skin-clique.png"
              max-width="165"
              contain
              class="mb-0"
            />
            <v-img
              v-else-if="shannonCard"
              src="@/assets/Logo/shannon.png"
              max-width="155"
              contain
              class="mb-0"
            />
            <v-img
              v-else-if="lucidworksCard"
              src="@/assets/Logo/lucidworks.png"
              max-width="145"
              contain
              class="mb-0"
            />
            <v-img
              v-else
              src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
              max-width="125"
            />
            <p class="virtual-card-text mr-3"><b>Virtual Card</b></p>
          </div>
        </div>
        <p class="label-text mb-0"><b>CARDHOLDER NAME</b></p>
        <p class="card-text mb-1">
          <b>{{ name ? name : "Cardholder Name" }}</b>
        </p>
        <p class="label-text mb-0"><b>ACCOUNT NUMBER</b></p>
        <div class="pci-box d-flex">
          <div id="mq-card-pan" class="card-pan"></div>
          <div id="copy-pan-container" style="position: relative;">
            <v-icon
              alt="Copy card number"
              :color="skinCliqueCard ? 'white' : undefined"
              >mdi-content-copy</v-icon
            >
          </div>
        </div>
        <div class="d-flex">
          <div class="d-flex flex-column">
            <p class="label-text mb-0"><b>CVV2</b></p>
            <div class="pci-box-small d-flex">
              <div id="mq-card-cvv" class="card-cvv"></div>
              <div id="copy-cvv-container" style="position: relative;">
                <v-icon
                  alt="Copy CVV2"
                  :color="skinCliqueCard ? 'white' : undefined"
                  >mdi-content-copy</v-icon
                >
              </div>
            </div>
          </div>
          <div class="d-flex flex-column ml-4">
            <p class="label-text mb-0"><b>Valid Thru</b></p>
            <div class="pci-box-small d-flex">
              <div id="mq-card-exp" class="card-exp"></div>
              <div id="copy-exp-container" style="position: relative;">
                <v-icon
                  alt="Copy expiration date"
                  :color="skinCliqueCard ? 'white' : undefined"
                  >mdi-content-copy</v-icon
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex justify-space-between full-width px-1"
        :class="{
          'brand--text': !skinCliqueCard,
          'white--text': skinCliqueCard
        }"
      >
        <div class="d-flex flex-column justify-space-between full-width">
          <p class="label-text mb-1 ml-4"><b>LIMITED USE</b></p>
          <p class="fdic-text mb-1 mt-1 word-break">
            <b
              >This card is issued by Sutton Bank, Member FDIC, pursuant to a
              license from Visa U.S.A. Inc. The Whistle card is powered by
              Marqeta.</b
            >
          </p>
        </div>
        <div class="d-flex flex-column align-end">
          <p class="virtual-card-text mr-2 mb-1"><b>DEBIT</b></p>
          <v-img
            v-if="skinCliqueCard"
            src="@/assets/Wallet/visa_white.png"
            max-width="105"
            contain
          />
          <v-img
            v-else
            src="@/assets/Wallet/visa.png"
            max-width="105"
            contain
          />
        </div>
      </div>
    </v-card>
    <body></body>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import Robin from "@/components/Robin";

import postscribe from "postscribe";
import VueJwtDecode from "vue-jwt-decode";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "MarqetaDisplayCard",
  props: {
    token: String,
    cardOnly: Boolean,
    elevation: {
      type: Boolean,
      default: true
    },
    magicLink: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Robin
  },
  data() {
    return {
      loading: false,
      name: null,
      displaySmallVersion: false
    };
  },
  created() {
    this.displaySmallVersion = !!(window.innerWidth < 1200);
    this.getWidgetData();
    this.getMarqetaUser();
  },
  mounted() {
    if (
      process.env.VUE_APP_ENVIRONMENT == "dev" ||
      process.env.VUE_APP_ENVIRONMENT == "test"
    ) {
      var marqetaUrl =
        "https://widgets-sandbox.marqeta.com/marqetajs/1.1.0/marqeta.min.js";
    } else {
      marqetaUrl = "https://widgets.marqeta.com/marqetajs/1.1.0/marqeta.min.js";
    }
    postscribe(
      "#marqeta-script",
      `<script src="${marqetaUrl}" type="text/javascript"><\/script>` //eslint-disable-line
    );
  },
  beforeDestroy() {},
  methods: {
    getWidgetData() {
      var startTime = new Date();
      this.loading = true;
      const cardFontSize = this.displaySmallVersion ? "18px" : "1.4em";
      MarqetaService.getVirtualCardWidget(
        this.token,
        this.magicLink ? this.token : null,
        this.magicLinkToken
      ).then(x => {
        const textColor = this.skinCliqueCard ? "white" : "#1E2549";
        postscribe(
          "#marqeta-bootstrap-script",
          `<script>marqeta.bootstrap({
            "clientAccessToken": "${x.client_access_token}",
            "showPan": {
              "cardPan": {
                "domId": "mq-card-pan",
                "format": true,
                "styles": { span: {"font-family": "Avenir", "font-size": "${cardFontSize}", "color": "${textColor}", "font-weight": "500"} }  // A custom styles object
              },
              "copyCardPan": {
                "domId": "copy-pan-container",
                "mode": "transparent",
                "onCopySuccess": () => { console.log("Copied PAN!"); },
                "onCopyFailure": error => {
                  console.error(error);
                }
              },
              "cardExp": {
                "domId": "mq-card-exp",
                "format": true,
                "styles": { span: {"font-family": "Avenir", "font-size": "${cardFontSize}", "color": "${textColor}", "font-weight": "500"} }
              },
              "copyCardExp": {
                "domId": "copy-exp-container",
                "mode": "transparent",
                "onCopySuccess": () => { console.log("Copied expiration date!"); },
                "onCopyFailure": error => {
                  console.error(error);
                }
              },
              "cardCvv": {
                "domId": "mq-card-cvv",
                "styles": { span: {"font-family": "Avenir", "font-size": "${cardFontSize}", "color": "${textColor}", "font-weight": "500"} }
              },
              "copyCardCvv": {
                "domId": "copy-cvv-container",
                "mode": "transparent",
                "onCopySuccess": () => { console.log("Copied CVV2!"); },
                "onCopyFailure": error => {
                  console.error(error);
                }
              }
            },
            "callbackEvents": {
              "onSuccess": () => { console.log("Success!")
              },
              "onFailure": error => { console.error(error);
              }
            }
          }
          );<\/script>` //eslint-disable-line
        );

        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff < 2000) {
          setTimeout(() => {
            this.loading = false;
          }, 2000 - timeDiff);
        } else {
          this.loading = false;
        }
      });
    },
    getMarqetaUser() {
      console.log("Getting user info");
      MarqetaService.getUser(
        this.token,
        this.magicLink ? this.token : null,
        this.magicLinkToken
      )
        .then(user => {
          console.log("User response: ", user);
          this.name = (user.first_name + " " + user.last_name).toUpperCase();
        })
        .catch(error => {
          console.log("There was an error getting user: ", error);
          // this.currentSlide++;
        });
    },
    resetForm() {
      this.$emit("scrollToTop");
      this.$emit("reset");
      this.$emit("get-cards", "marqetaDisplayCard");
    },
    previousSlide() {
      this.$emit("scrollToTop");
      this.resetForm();
    }
  },
  computed: {
    ...mapState(["userProfile", "magicLinkToken"]),
    helenCard() {
      const clientId = 375;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    },
    kennelwoodCard() {
      const clientId = 516;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    },
    bcaCard() {
      const clientId = 495;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    },
    prolinkCard() {
      const clientId = 533;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    },
    skinCliqueCard() {
      const clientId = 513;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    },
    shannonCard() {
      const clientId = 557;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    },
    lucidworksCard() {
      const clientId = 365;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    },
    concordProjectsCard() {
      const clientId = 582;
      try {
        if (this.token) {
          let decoded = VueJwtDecode.decode(this.token);
          const metadata = decoded["https://whistle.com/metadata"];
          if (metadata && metadata.clientId == clientId) return true;
        }
      } catch (e) {
        console.log("Error decoding token ", e);
      }
      return this.userProfile && this.userProfile.clientId == clientId;
    }
  },
  watch: {}
};
</script>

<style scoped id="styles">
.virtual-card {
  max-width: 485px;
}

.virtual-card.shrink {
  height: 1px;
}
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

#copy-pan-container:hover > .v-icon {
  opacity: 0.5;
  cursor: pointer;
}

#copy-exp-container:hover > .v-icon {
  opacity: 0.5;
  cursor: pointer;
}

#copy-cvv-container:hover > .v-icon {
  opacity: 0.5;
  cursor: pointer;
}

.pci-box {
  height: 35px;
}
.pci-box-small {
  height: 25px;
}

.card-pan {
  width: 235px;
}
.card-exp {
  width: 75px;
}
.card-cvv {
  width: 55px;
}

.card-text {
  font-size: 1.35em;
}

.virtual-card-text {
  font-size: 1.3em;
}
.customer-service-text {
  font-size: 12px;
  font-weight: 600;
}
.label-text {
  font-size: 12px;
}
.fdic-text {
  font-size: 10px;
  line-height: 120%;
}

.card-border {
  border: solid 1px !important;
  border-color: lightgray !important;
  /* border-radius: 0; */
}

@media (max-width: 1200px) {
  .card-text {
    font-size: 18px;
  }
  .virtual-card-text {
    font-size: 16px;
  }
  .card-pan {
    width: 200px;
  }
  .card-exp {
    width: 65px;
  }
  .card-cvv {
    width: 45px;
  }
}
</style>

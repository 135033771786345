import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: {
    themes: {
      base: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#97dffc",
        accentDark: "#0aaceb",
        error: "#FF0294",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        brand: "#1E2549",
        brandPaleBlue: "#EEF8FC",
        grey: "#BABABA",
        lightGrey: "#EEEEEE",
        mediumGrey: "#939799",
        darkGrey: "#ffffff",
        brandGreen: "#9FCD63",
        brandPaleGreen: "#F4FFEB",
        brandLightGreen: "#d3f2b5",
        brandDarkGreen: "#549C2D",
        brandYellow: "#FFDA5D",
        brandPink: "#FFA2FF",
        brandLightCyan: "#E4F2F5",
        brandCyan: "#09ACEC",
        brandDarkCyan: "#00B0F3",
        brandPurple: "#858AE3",
        brandOrange: "#FFAB1F"
      },
      light: {
        primary: "#1976D2",
        secondary: "#424242",
        accent: "#97dffc",
        accentDark: "#0aaceb",
        error: "#FF0294",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        brand: "#1E2549",
        brandPaleBlue: "#EEF8FC",
        grey: "#BABABA",
        lightGrey: "#EEEEEE",
        mediumGrey: "#939799",
        darkGrey: "#666666",
        brandGreen: "#9FCD63",
        brandPaleGreen: "#F4FFEB",
        brandLightGreen: "#d3f2b5",
        brandDarkGreen: "#78A937",
        brandYellow: "#FFDA5D",
        brandPink: "#FFA2FF",
        brandLightCyan: "#E4F2F5",
        brandCyan: "#09ACEC",
        brandDarkCyan: "#00B0F3",
        brandPurple: "#858AE3",
        brandOrange: "#FFAB1F"
      }
    },
    options: {
      customProperties: true
    }
  }
});

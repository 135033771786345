<template>
  <div class="gradient-background py-5">
    <v-row justify="center"
      ><v-col cols="11" sm="11" md="5" lg="5" xl="5">
        <v-card class="content py-6" width="100%">
          <div class="d-flex align-center justify-space-between">
            <Robin
              animation="swayandbounce"
              :showText="false"
              :loop="true"
              :width="100"
              :height="100"
            />
            <div
              class="text mt-4 mx-auto"
              :class="{
                'text-left': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
              }"
            >
              <p>
                Uh oh, we had trouble loading your account. Please try logging
                in again.
              </p>

              <!-- <v-slide-y-transition>
            <span v-show="show">This should only take a few seconds.</span>
          </v-slide-y-transition> -->
            </div>
          </div>
          <p style="color: grey;" class="mb-2">
            If this continues, please contact us
          </p>
          <p class="" style="color: grey;">
            Email -
            <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
          </p>
          <p class="" style="color: grey;">
            Phone (Toll Free) - <b class="black--text">(855) 264-3329</b>
          </p>
          <v-btn color="primary" @click="loginOrLogout" class="mt-4"
            >Login</v-btn
          >
        </v-card></v-col
      ></v-row
    >
  </div>
</template>

<script>
// @ is an alias to /src
import Robin from "@/components/Robin.vue";

import { mapState } from "vuex";
export default {
  name: "LoginError",
  title: "Whistle | Error",
  components: {
    Robin,
  },
  created() {},
  methods: {
    loginOrLogout() {
      if (!this.$auth.isAuthenticated) {
        //Logs in
        this.$auth.loginWithEmbedded({
          connection: process.env.VUE_APP_ENVIRONMENT,
        });
      } else {
        //Logs out
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      }
    },
  },
  computed: {
    ...mapState(["permissions", "userProfile"]),
  },
};
</script>

<style scoped>
.content {
  margin-top: 15vh;
}
</style>

function numberWithCommas(x) {
  if (x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return x;
  }
}

function roundNumber(value, precision) {
  const power = Math.pow(10, precision);
  return Math.round(value * power + Number.EPSILON * power) / power;
}

function truncateNumber(value, precision) {
  // const parts = value.toString().split(".");
  // var precisionFactor = Math.pow(10, precision);
  // if (parts.length === 2) {
  //   console.log(parts[0]);
  //   console.log(parts[1]);
  //   if (parts[1].length == 1) {
  //     var part1 = parts[1].padEnd(2, "0");
  //   } else {
  //     part1 = parts[1];
  //   }
  //   var part2 = Math.round(part1 * precisionFactor) / precisionFactor;

  //   console.log(part2);
  //   part2 = String(part2).padStart(2, "0");
  //   console.log(part2);
  //   //var part2 = parts[1]
  //   var num = Number([parts[0], part2].join("."));
  // } else {
  //   num = Number(parts[0]);
  // }
  // return num.toFixed(precision);
  // Pads everything to the defined number of decimals points
  // return new Intl.NumberFormat("en-US", {
  //   minimumFractionDigits: precision,
  //   maximumFractionDigits: precision,
  // }).format(num);
  if (value === undefined || value === null) return 0;
  if (precision <= 0) {
    return Number(value.toString().split(".")[0]);
  }
  if (value.toString().startsWith(".")) value = "0" + value;
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + precision + "})?", "g");
  const a = value.toString().match(reg)[0];
  const dot = a.indexOf(".");
  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return a + "." + "0".repeat(precision);
  }
  const b = precision - (a.length - dot) + 1;
  return b > 0 ? a + "0".repeat(b) : a;
}

function formatEmailTemplate(
  template,
  url,
  button,
  icon,
  subject,
  subtitle,
  body,
  previewMode = false
) {
  // Url is the extension that is added to wewhistle.com
  var env = "app";
  if (process.env.VUE_APP_ENVIRONMENT == "dev") {
    env = "dev";
  } else if (process.env.VUE_APP_ENVIRONMENT == "test") {
    env = "test";
  }
  if (previewMode)
    template = template.replace(/height: 107px;/g, "height: 115px;");
  template = template.replace(/{{url}}/g, url);
  template = template.replace(/{{button}}/g, button);
  template = template.replace(/{{title}}/g, subject);
  template = template.replace(/{{subtitle}}/g, subtitle);
  template = template.replace(/{{body}}/g, body);
  template = template.replace(/{{env}}/g, env);
  template = template.replace(/{{icon}}/g, icon);
  return template;
}

function formatFileType(filepath) {
  if (filepath.toUpperCase().endsWith("PDF")) {
    return "PDF";
  } else if (
    filepath.toUpperCase().endsWith("DOC") ||
    filepath.toUpperCase().endsWith("DOCM") ||
    filepath.toUpperCase().endsWith("DOCX") ||
    filepath.toUpperCase().endsWith("DOT")
  ) {
    return "DOCX";
  } else if (filepath.toUpperCase().endsWith("CSV")) {
    return "CSV";
  } else if (
    filepath.toUpperCase().endsWith("XLA") ||
    filepath.toUpperCase().endsWith("XLAM") ||
    filepath.toUpperCase().endsWith("XLS") ||
    filepath.toUpperCase().endsWith("XLSM") ||
    filepath.toUpperCase().endsWith("XLSX") ||
    filepath.toUpperCase().endsWith("XLT") ||
    filepath.toUpperCase().endsWith("XLTX")
  ) {
    return "XLSX";
  } else if (
    filepath.toUpperCase().endsWith("PPSX") ||
    filepath.toUpperCase().endsWith("PPT") ||
    filepath.toUpperCase().endsWith("PPTM") ||
    filepath.toUpperCase().endsWith("PPTX")
  ) {
    return "PPTX";
  } else if (
    filepath.toUpperCase().endsWith("PNG") ||
    filepath.toUpperCase().endsWith("BMP") ||
    filepath.toUpperCase().endsWith("JPG") ||
    filepath.toUpperCase().endsWith("JPEG") ||
    filepath.toUpperCase().endsWith("GIF") ||
    filepath.toUpperCase().endsWith("TIFF") ||
    filepath.toUpperCase().endsWith("PSD") ||
    filepath.toUpperCase().endsWith("AI") ||
    filepath.toUpperCase().endsWith("RAW")
  ) {
    return "PNG";
  } else if (
    filepath.toUpperCase().endsWith("RTF") ||
    filepath.toUpperCase().endsWith("TXT") ||
    filepath.toUpperCase().endsWith("ODT") ||
    filepath.toUpperCase().endsWith("TEX") ||
    filepath.toUpperCase().endsWith("WPD")
  ) {
    if (filepath.toUpperCase().endsWith("PDF")) {
      return "PDF";
    } else if (
      filepath.toUpperCase().endsWith("DOC") ||
      filepath.toUpperCase().endsWith("DOCM") ||
      filepath.toUpperCase().endsWith("DOCX") ||
      filepath.toUpperCase().endsWith("DOT")
    ) {
      return "DOCX";
    } else if (filepath.toUpperCase().endsWith("CSV")) {
      return "CSV";
    } else if (
      filepath.toUpperCase().endsWith("XLA") ||
      filepath.toUpperCase().endsWith("XLAM") ||
      filepath.toUpperCase().endsWith("XLS") ||
      filepath.toUpperCase().endsWith("XLSM") ||
      filepath.toUpperCase().endsWith("XLSX") ||
      filepath.toUpperCase().endsWith("XLT") ||
      filepath.toUpperCase().endsWith("XLTX")
    ) {
      return "XLSX";
    } else if (
      filepath.toUpperCase().endsWith("PPSX") ||
      filepath.toUpperCase().endsWith("PPT") ||
      filepath.toUpperCase().endsWith("PPTM") ||
      filepath.toUpperCase().endsWith("PPTX")
    ) {
      return "PPTX";
    } else if (
      filepath.toUpperCase().endsWith("PNG") ||
      filepath.toUpperCase().endsWith("BMP") ||
      filepath.toUpperCase().endsWith("JPG") ||
      filepath.toUpperCase().endsWith("JPEG") ||
      filepath.toUpperCase().endsWith("GIF") ||
      filepath.toUpperCase().endsWith("TIFF") ||
      filepath.toUpperCase().endsWith("PSD") ||
      filepath.toUpperCase().endsWith("AI") ||
      filepath.toUpperCase().endsWith("RAW")
    ) {
      return "PNG";
    } else if (
      filepath.toUpperCase().endsWith("RTF") ||
      filepath.toUpperCase().endsWith("TXT") ||
      filepath.toUpperCase().endsWith("ODT") ||
      filepath.toUpperCase().endsWith("TEX") ||
      filepath.toUpperCase().endsWith("WPD")
    ) {
      return "TXT";
    }
  }
}

function getMessageBodyFromTemplate(body) {
  // The user's body is stored in the template using a custom div called custom-body
  // We have to find that, and then get the next <p> tag, which is where the user's actual content is
  // Then we grab everything until the closing p tag

  // The annoying thing is we have 3 different versions - template v1, template v1.5, and template v2
  // v2
  var tagIndex = body.indexOf(`id="custom-body"`);
  if (tagIndex != -1) {
    // Now we grab the start of the next tag (the user's actual text)
    var customTagBody = body.substring(tagIndex);
    var userTextTagIndex = customTagBody.indexOf("<");
    if (userTextTagIndex != -1) {
      // Then we grab the end of that tag so we can get rid of the tag itself
      var bodyWithPartialTag = customTagBody.substring(userTextTagIndex + 1);

      var endTagIndex = bodyWithPartialTag.indexOf(">");
      if (endTagIndex != -1) {
        // We filter for the custom body tag so we can extract just the body
        var tempBody = bodyWithPartialTag.substring(endTagIndex + 1);

        tempBody = tempBody.substring(0, tempBody.indexOf("</td>")).trim();
      } else {
        tempBody = customTagBody;
      }
    } else {
      tempBody = customTagBody;
    }
  } else {
    // v1.5
    // We have to do some backwards compatibility magic for templates that don't have this
    // <p style="margin: 0">
    tagIndex = body.indexOf(`<p style="margin: 0">`);
    if (tagIndex != -1) {
      bodyWithPartialTag = body.substring(tagIndex);
      endTagIndex = bodyWithPartialTag.indexOf(">");
      if (endTagIndex != -1) {
        // We filter for the custom body tag so we can extract just the body
        tempBody = bodyWithPartialTag.substring(endTagIndex + 1);
        if (tempBody.includes("</td>"))
          tempBody = tempBody.substring(0, tempBody.indexOf("</td>")).trim();
        else if (tempBody.endsWith("</p></p>"))
          tempBody = tempBody.substring(0, tempBody.lastIndexOf("</p>")).trim();
        // We need to trim the last </p> off
      } else {
        tempBody = bodyWithPartialTag;
      }
    } else {
      // Now last case is if we're on v1 template
      tagIndex = body.indexOf("id='custom-body'>");
      if (tagIndex != -1) {
        // We filter for the custom body tag so we can extract just the body
        tempBody = body.substring(endTagIndex + 17);
        tempBody = tempBody.substring(0, tempBody.indexOf("</td>")).trim();
      } else {
        tempBody = body;
      }
    }
  }
  return tempBody;
}

function debounce(fn, delay) {
  var timeoutID = null;
  return function() {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}

export {
  formatEmailTemplate,
  numberWithCommas,
  roundNumber,
  truncateNumber,
  formatFileType,
  getMessageBodyFromTemplate,
  debounce
};

<template>
  <v-card flat rounded="0">
    <v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title>Business Payment Account Setup</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetForm(true, false)">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <!-- <v-card-title>
      <v-row>
        <v-col class="d-flex flex-column align-start ml-2" sm="6" md="6">
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1"
            @click="previousSlide"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title> -->
    <v-row
      justify="center"
      v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
    >
      <v-card-title class="title-text word-break mt-7"
        >Looks like you're on a mobile device</v-card-title
      >
      <p class="word-break mx-8">
        We recommend completing this business registration form on a larger
        screen.
      </p>
      <v-img
        src="@/assets/Placeholder_Screens/Mobile.png"
        max-height="175"
        contain
      />
      <v-btn color="primary" class="mt-6" @click="resetForm(true, false)"
        >Go Back</v-btn
      >
    </v-row>
    <v-row justify="center" v-else-if="currentSlide != 5"
      ><v-col
        sm="12"
        md="4 "
        lg="4"
        xl="4"
        class="align-text-left darkGrey--text"
      >
        <div class="d-flex justify-center align-center mb-5 mt-10 hidden">
          <v-btn small fab elevation="0" color="mediumGrey"
            ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
          >
          <ProgressBar :progress="progress" class="progress-bar mx-12" />
          <v-btn small fab elevation="0" color="mediumGrey"
            ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
          >
        </div>
        <v-card-title class="title-text word-break ml-0 pl-0">
          Before you're ready for Whistle Payments, we need a few details about
          your company for compliance</v-card-title
        >

        <div class="mt-5" v-if="currentSlide < 3">
          <div
            class="d-flex align-center cursor-pointer"
            @click="displayWhyWeNeedThis = !displayWhyWeNeedThis"
          >
            <h3 class="mr-3">
              Why do we need this?
            </h3>
            <v-btn icon
              ><v-icon>{{
                displayWhyWeNeedThis ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon></v-btn
            >
          </div>
          <p class="pr-8 mt-2" v-if="displayWhyWeNeedThis">
            KYB is a regulatory requirement that requires businesses to verify
            other business entities to ensure that funds are not being misused
            by money launderers, corrupt business owners, or shadow
            shareholders.
          </p>
        </div>
        <div class="mt-5" v-if="currentSlide < 3">
          <div
            class="d-flex align-center cursor-pointer"
            @click="displayWhatWeDoWithInfo = !displayWhatWeDoWithInfo"
          >
            <h3 class="mr-3">
              What do we do with this information?
            </h3>
            <v-btn icon
              ><v-icon>{{
                displayWhatWeDoWithInfo ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon></v-btn
            >
          </div>
          <p class="pr-8 mt-2" v-if="displayWhatWeDoWithInfo">
            We use this information to fulfill our regulatory requirement and
            ensure our products are not being misused by money launderers. We
            know this information is sensitive and we treat it with our highest
            measure of data security.
          </p>
        </div>
        <div class="mt-5" v-if="currentSlide == 3">
          <div
            class="d-flex align-center cursor-pointer"
            @click="displayControlPersonInfo = !displayControlPersonInfo"
          >
            <h3 class="mr-3">
              Need more information on this page?
            </h3>
            <v-btn icon
              ><v-icon>{{
                displayControlPersonInfo ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon></v-btn
            >
          </div>
          <p class="pr-8 mt-2" v-if="displayControlPersonInfo">
            Information is needed on the "Controlling Person" within your
            company. A controlling person is an individual at your company with
            significant managerial control or influence (e.g., Chief Executive
            Officer, Chief Financial Officer, Managing Member, General Partner,
            etc.). We are required to collect this in order to verify your
            business.
          </p>
        </div>
        <div class="mt-5" v-if="currentSlide == 4">
          <div
            class="d-flex align-center cursor-pointer"
            @click="displayOwnerInfo = !displayOwnerInfo"
          >
            <h3 class="mr-3">
              Need more information on this page?
            </h3>
            <v-btn icon
              ><v-icon>{{
                displayOwnerInfo ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon></v-btn
            >
          </div>
          <p class="pr-8 mt-2" v-if="displayOwnerInfo">
            Any individual who owns 25% or more of your company is considered an
            owner with a significant stake. We are required to collect their
            personal information for your company's verification.
          </p>
        </div>
        <p class="pr-8 mt-4" v-if="currentSlide == 4"></p> </v-col
      ><v-col cols="12" sm="12" md="5" lg="5" xl="5">
        <div class="d-flex justify-center align-center mb-5 mt-10">
          <v-btn
            small
            fab
            elevation="0"
            color="mediumGrey"
            @click="previousSlide"
            :class="{
              hidden:
                currentSlide == 0 ||
                currentSlide == 6 ||
                currentSlide == 7 ||
                currentSlide == 8
            }"
            ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
          >
          <ProgressBar :progress="progress" class="progress-bar mx-12" />
          <v-btn
            small
            fab
            elevation="0"
            color="mediumGrey"
            @click="nextSlide"
            :disabled="disableNextButton"
            :class="{ hidden: currentSlide == 6 }"
            ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
          >
        </div>
        <transition :name="slideDirection" mode="out-in">
          <v-row
            v-if="currentSlide == 0"
            key="0"
            no-gutters
            class="pb-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
          >
            <v-card-title class="title-text word-break">
              Let's get started with your business name and federal tax ID
            </v-card-title>

            <v-form ref="form" v-model="validForm1" class="full-width px-4">
              <v-autocomplete
                :items="clients"
                label="Client"
                item-text="formattedName"
                item-value="clientId"
                v-model="clientId"
                class="mt-4 full-width"
                v-if="userProfile.clientId == 1"
                attach
                :menu-props="{ offsetY: true }"
              ></v-autocomplete>
              <v-text-field
                label="Legal name of business"
                v-model="business_name_legal"
                class="mt-4"
                :rules="[
                  v => !!v || 'Business name is required',
                  v =>
                    !!(v && v.length < 128) || 'Business name should be shorter'
                ]"
              />
              <v-text-field
                label="DBA (Doing Business As)"
                v-model="business_name_dba"
                class="mt-4"
                :rules="[
                  v => !!v || 'DBA is required',
                  v => !!(v && v.length < 128) || 'DBA should be shorter'
                ]"
              />
              <v-text-field
                label="Business Tax ID (EIN)"
                v-model="identifications[0].value"
                class="mt-4"
                :rules="[
                  v => !!v || 'Tax ID is required',
                  v =>
                    !!(v && !isNaN(v)) || 'Tax ID should only contain numbers',
                  v =>
                    !!(v && v.length == 9) || 'Tax ID should be 9 digits long'
                ]"
              />
              <div v-if="!userProfile.Client.completedMSA">
                <v-btn class="mt-6" text color="primary" @click="downloadMSA()">
                  Review the Whistle Payments Agreement
                </v-btn>

                <v-checkbox
                  v-model="acceptMSA"
                  label="I accept and digitally sign the Whistle Payments Agreement"
                  color="primary"
                  :input-value="true"
                  :rules="[v => !!v || 'Item is required']"
                  required
                ></v-checkbox>

                <v-text-field
                  label="Full name"
                  v-model="msa_full_name"
                  class="mt-4"
                  :rules="[v => !!v || 'Full Name is required']"
                />
                <v-text-field
                  label="Title at business"
                  v-model="msa_title"
                  class="mt-4"
                  :rules="[v => !!v || 'Title is required']"
                />
              </div>
            </v-form>

            <!-- <v-form v-model="acceptTermsValid" class="mt-4">
              <v-checkbox
                v-model="acceptTerms"
                label="I accept the terms and conditions"
                color="primary"
                :input-value="true"
                :rules="[(v) => !!v || 'Item is required']"
                required
              ></v-checkbox>
              <v-checkbox
                v-model="acceptPrivacy"
                label="I accept the privacy policy"
                color="primary"
                :input-value="true"
                :rules="[(v) => !!v || 'Item is required']"
                required
              ></v-checkbox>
            </v-form> -->
          </v-row>
          <v-row
            v-else-if="currentSlide == 1"
            key="1"
            no-gutters
            class="word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
          >
            <v-card-title class="title-text word-break">
              What is the main business address of your company? This can't be a
              PO Box
            </v-card-title>

            <v-form ref="form" v-model="validForm2" class="full-width px-4">
              <v-text-field
                label="Address"
                v-model="office_location.address1"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'Address is required',
                  v => !!(v && v.length < 256) || 'Address should be shorter'
                ]"
              />
              <v-text-field
                label="Apt, suite, unit, etc."
                v-model="office_location.address2"
                class="mt-4"
              />
              <v-text-field
                label="City"
                v-model="office_location.city"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'City is required',
                  v => !!(v && v.length < 40) || 'City should be shorter'
                ]"
              />
              <v-select
                label="State"
                :items="states"
                class="mt-4"
                v-model="office_location.state"
                item-value="abbreviation"
                item-text="name"
                :rules="[v => !!v || 'State is required']"
                attach
                :menu-props="{ top: true, offsetY: true }"
              />
              <v-text-field
                label="Zip Code"
                v-model="office_location.postal_code"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'Zip code is required',
                  v => !!(v && v.length < 20) || 'Zip code should be shorter'
                ]"
              />
              <!-- <v-select
                label="Country"
                :items="countries"
                v-model="office_location.country"
                item-value="code"
                class="mt-4"
                item-text="name"
                :rules="[(v) => !!v || 'Country is required']"
              />
              <v-text-field
                v-if="office_location.country == '00'"
                label="Other country (2 digit code)"
                v-model="office_location.otherCountry"
                class="mt-4"
                required
                :rules="[
                  (v) => !!v || 'Country is required',
                  (v) =>
                    !!(v && v.length == 2) ||
                    'Country code should be 2 characters',
                  (v) =>
                    !!(v && countryCodes.includes(v.toUpperCase())) ||
                    'Country code must be a valid country',
                ]"
              /> -->
            </v-form>
          </v-row>
          <v-row
            v-else-if="currentSlide == 2"
            key="2"
            no-gutters
            class=" word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
          >
            <v-card-title class="title-text word-break">
              How is the company registered with the government?
            </v-card-title>

            <v-form ref="form" v-model="validForm3" class="full-width px-4">
              <v-menu
                ref="establishedDateMenu"
                v-model="establishedDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                attach
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_established"
                    label="Date established"
                    readonly
                    class="mt-4"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => !!v || 'Date established is required']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="establishedDatePicker"
                  v-model="date_established"
                  :max="new Date().toISOString().substr(0, 10)"
                  min="1850-01-01"
                  @change="saveDateEstablished"
                ></v-date-picker>
              </v-menu>

              <v-select
                label="Incorporation type"
                :items="incorporationTypes"
                v-model="incorporation.incorporation_type"
                class="mt-4"
                item-value="value"
                item-text="label"
                :rules="[v => !!v || 'Incorporation type is required']"
                attach
                :menu-props="{ offsetY: true }"
              />
              <v-select
                label="State of Incorporation"
                :items="states"
                v-model="incorporation.state_of_incorporation"
                class="mt-4"
                item-value="abbreviation"
                item-text="name"
                :rules="[v => !!v || 'Incorporation state is required']"
                attach
                :menu-props="{ top: true, offsetY: true }"
              />
              <v-checkbox
                v-model="incorporation.is_public"
                class="mt-4"
                label="The company is publicly held"
              />
            </v-form>
          </v-row>
          <v-row
            v-else-if="currentSlide == 3"
            key="3"
            no-gutters
            class="word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
          >
            <v-card-title class="title-text word-break">
              Who has the most managerial control in your company? We'll need
              some information about them
            </v-card-title>

            <v-form ref="form" v-model="validForm4" class="full-width px-4">
              <v-text-field
                label="First name"
                v-model="proprietor_or_officer.first_name"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'First name is required',
                  v => !!(v && v.length > 1) || 'First name should be longer',
                  v => !!(v && v.length < 36) || 'First name should be shorter'
                ]"
              />
              <v-text-field
                label="Last name"
                v-model="proprietor_or_officer.last_name"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'Last name is required',
                  v => !!(v && v.length > 1) || 'Last name should be longer',
                  v => !!(v && v.length < 48) || 'Last name should be shorter'
                ]"
              />
              <v-menu
                ref="officerBirthdayMenu"
                v-model="officerBirthdayMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                attach
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="proprietor_or_officer.dob"
                    class="mt-4"
                    label="Date of birth"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => !!v || 'Date of birth is required']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="officerBirthdayPicker"
                  v-model="proprietor_or_officer.dob"
                  :max="eighteenYearsFromToday"
                  min="1940-01-01"
                  @change="saveOfficerBirthday"
                ></v-date-picker>
              </v-menu>
              <v-text-field
                label="Social Security Number"
                v-model="proprietor_or_officer.identification"
                class="mt-4"
                type="number"
                required
                :rules="[
                  v => !!v || 'SSN is required',
                  v => !!(v && !isNaN(v)) || 'SSN should only contain numbers',
                  v => !!(v && v.length == 9) || 'SSN should be 9 digits long'
                ]"
              />
              <v-text-field
                label="Home Address"
                v-model="proprietor_or_officer.home.address1"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'Address is required',
                  v => !!(v && v.length < 256) || 'Address should be shorter'
                ]"
              />
              <v-text-field
                label="Apt, suite, unit, etc."
                v-model="proprietor_or_officer.home.address2"
                class="mt-4"
              />
              <v-text-field
                label="City"
                v-model="proprietor_or_officer.home.city"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'City is required',
                  v => !!(v && v.length < 40) || 'City should be shorter'
                ]"
              />
              <v-select
                label="State"
                :items="states"
                v-model="proprietor_or_officer.home.state"
                class="mt-4"
                item-value="abbreviation"
                item-text="name"
                :rules="[v => !!v || 'State is required']"
                attach
                :menu-props="{ top: true, offsetY: true }"
              />
              <v-text-field
                label="Zip Code"
                v-model="proprietor_or_officer.home.postal_code"
                class="mt-4"
                required
                :rules="[
                  v => !!v || 'Zip code is required',
                  v => !!(v && v.length < 20) || 'Zip code should be shorter'
                ]"
              />
              <!-- <v-select
                label="Country"
                :items="countries"
                v-model="proprietor_or_officer.home.country"
                class="mt-4"
                item-value="code"
                item-text="name"
                :rules="[(v) => !!v || 'Country is required']"
              />
              <v-text-field
                v-if="proprietor_or_officer.home.country == '00'"
                label="Other country (2 digit code)"
                v-model="proprietor_or_officer.home.otherCountry"
                class="mt-4"
                required
                :rules="[
                  (v) => !!v || 'Country is required',
                  (v) =>
                    !!(v && v.length == 2) ||
                    'Country code should be 2 characters',
                  (v) =>
                    !!(v && countryCodes.includes(v.toUpperCase())) ||
                    'Country code must be a valid country',
                ]"
              /> -->
              <v-checkbox
                v-model="proprietor_is_beneficial_owner"
                @change="resetBeneficialOwner4"
                class="mt-4"
                label="This person owns 25% or more of the company"
              />
              <div class="d-flex flex-column full-width">
                <span>Does anyone else own 25% or more of the company?</span>
                <v-radio-group v-model="beneficialOwnerToggle">
                  <v-radio label="No" :value="false" class="mb-4"></v-radio>
                  <v-radio label="Yes" :value="true"></v-radio>
                </v-radio-group>
              </div>
            </v-form>
          </v-row>
          <v-row
            v-else-if="currentSlide == 4"
            key="4"
            no-gutters
            class="word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
          >
            <v-card-title class="title-text word-break">
              Due to regulations, we're required to collect information on
              anyone that owns 25% or more of the company
            </v-card-title>

            <v-expansion-panels>
              <v-expansion-panel key="1">
                <v-expansion-panel-header v-if="!proprietor_is_beneficial_owner"
                  >Beneficial Owner #1</v-expansion-panel-header
                >
                <v-expansion-panel-header v-else
                  >Beneficial Owner #2</v-expansion-panel-header
                >
                <v-expansion-panel-content
                  ><v-form
                    ref="form"
                    v-model="validForm5"
                    class="full-width px-4"
                  >
                    <v-text-field
                      label="First name"
                      v-model="beneficial_owner1.first_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'First name is required',
                        v =>
                          !!(v && v.length > 1) ||
                          'First name should be longer',
                        v =>
                          !!(v && v.length < 36) ||
                          'First name should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Last name"
                      v-model="beneficial_owner1.last_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Last name is required',
                        v =>
                          !!(v && v.length > 1) || 'Last name should be longer',
                        v =>
                          !!(v && v.length < 48) ||
                          'Last name should be shorter'
                      ]"
                    />
                    <v-menu
                      ref="owner1BirthdayMenu"
                      v-model="owner1BirthdayMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      attach
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="beneficial_owner1.dob"
                          class="mt-4"
                          label="Date of birth"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[v => !!v || 'Date of birth is required']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="owner1BirthdayPicker"
                        v-model="beneficial_owner1.dob"
                        :max="eighteenYearsFromToday"
                        min="1940-01-01"
                        @change="saveOwner1Birthday"
                      ></v-date-picker>
                    </v-menu>
                    <v-text-field
                      label="Social Security Number"
                      v-model="beneficial_owner1.ssn"
                      class="mt-4"
                      type="number"
                      required
                      :rules="[
                        v => !!v || 'SSN is required',
                        v =>
                          !!(v && !isNaN(v)) ||
                          'SSN should only contain numbers',
                        v =>
                          !!(v && v.length == 9) ||
                          'SSN should be 9 digits long'
                      ]"
                    />
                    <v-text-field
                      label="Home Address"
                      v-model="beneficial_owner1.home.address1"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Address is required',
                        v =>
                          !!(v && v.length < 256) || 'Address should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Apt, suite, unit, etc."
                      v-model="beneficial_owner1.home.address2"
                      class="mt-4"
                    />
                    <v-text-field
                      label="City"
                      v-model="beneficial_owner1.home.city"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'City is required',
                        v => !!(v && v.length < 40) || 'City should be shorter'
                      ]"
                    />
                    <v-select
                      label="State"
                      :items="states"
                      v-model="beneficial_owner1.home.state"
                      class="mt-4"
                      item-value="abbreviation"
                      item-text="name"
                      :rules="[v => !!v || 'State is required']"
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                    />
                    <v-text-field
                      label="Zip Code"
                      v-model="beneficial_owner1.home.postal_code"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Zip code is required',
                        v =>
                          !!(v && v.length < 20) || 'Zip code should be shorter'
                      ]"
                    />
                    <!-- <v-select
                      label="Country"
                      :items="countries"
                      v-model="beneficial_owner1.home.country"
                      class="mt-4"
                      item-value="code"
                      item-text="name"
                      :rules="[(v) => !!v || 'Country is required']"
                    />
                    <v-text-field
                      v-if="beneficial_owner1.home.country == '00'"
                      label="Other country (2 digit code)"
                      v-model="beneficial_owner1.home.otherCountry"
                      class="mt-4"
                      required
                      :rules="[
                        (v) => !!v || 'Country is required',
                        (v) =>
                          !!(v && v.length == 2) ||
                          'Country code should be 2 characters',
                        (v) =>
                          !!(v && countryCodes.includes(v.toUpperCase())) ||
                          'Country code must be a valid country',
                      ]"
                    />  -->
                  </v-form></v-expansion-panel-content
                ></v-expansion-panel
              >
              <v-expansion-panel key="2">
                <v-expansion-panel-header v-if="!proprietor_is_beneficial_owner"
                  >Beneficial Owner #2</v-expansion-panel-header
                >
                <v-expansion-panel-header v-else
                  >Beneficial Owner #3</v-expansion-panel-header
                >
                <v-expansion-panel-content
                  ><v-form
                    ref="form"
                    v-model="validForm6"
                    class="full-width px-4"
                  >
                    <v-text-field
                      label="First name"
                      v-model="beneficial_owner2.first_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'First name is required',
                        v =>
                          !!(v && v.length > 1) ||
                          'First name should be longer',
                        v =>
                          !!(v && v.length < 36) ||
                          'First name should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Last name"
                      v-model="beneficial_owner2.last_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Last name is required',
                        v =>
                          !!(v && v.length > 1) || 'Last name should be longer',
                        v =>
                          !!(v && v.length < 48) ||
                          'Last name should be shorter'
                      ]"
                    />
                    <v-menu
                      ref="owner2BirthdayMenu"
                      v-model="owner2BirthdayMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      attach
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="beneficial_owner2.dob"
                          class="mt-4"
                          label="Date of birth"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[v => !!v || 'Date of birth is required']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="owner2BirthdayPicker"
                        v-model="beneficial_owner2.dob"
                        :max="eighteenYearsFromToday"
                        min="1940-01-01"
                        @change="saveOwner2Birthday"
                      ></v-date-picker>
                    </v-menu>
                    <v-text-field
                      label="Social Security Number"
                      v-model="beneficial_owner2.ssn"
                      class="mt-4"
                      type="number"
                      required
                      :rules="[
                        v => !!v || 'SSN is required',
                        v =>
                          !!(v && !isNaN(v)) ||
                          'SSN should only contain numbers',
                        v =>
                          !!(v && v.length == 9) ||
                          'SSN should be 9 digits long'
                      ]"
                    />
                    <v-text-field
                      label="Home Address"
                      v-model="beneficial_owner2.home.address1"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Address is required',
                        v =>
                          !!(v && v.length < 256) || 'Address should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Apt, suite, unit, etc."
                      v-model="beneficial_owner2.home.address2"
                      class="mt-4"
                    />
                    <v-text-field
                      label="City"
                      v-model="beneficial_owner2.home.city"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'City is required',
                        v => !!(v && v.length < 40) || 'City should be shorter'
                      ]"
                    />
                    <v-select
                      label="State"
                      :items="states"
                      v-model="beneficial_owner2.home.state"
                      class="mt-4"
                      item-value="abbreviation"
                      item-text="name"
                      :rules="[v => !!v || 'State is required']"
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                    />
                    <v-text-field
                      label="Zip Code"
                      v-model="beneficial_owner2.home.postal_code"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Zip code is required',
                        v =>
                          !!(v && v.length < 20) || 'Zip code should be shorter'
                      ]"
                    />
                    <!-- <v-select
                      label="Country"
                      :items="countries"
                      v-model="beneficial_owner2.home.country"
                      class="mt-4"
                      item-value="code"
                      item-text="name"
                      :rules="[(v) => !!v || 'Country is required']"
                    />
                    <v-text-field
                      v-if="beneficial_owner2.home.country == '00'"
                      label="Other country (2 digit code)"
                      v-model="beneficial_owner2.home.otherCountry"
                      class="mt-4"
                      required
                      :rules="[
                        (v) => !!v || 'Country is required',
                        (v) =>
                          !!(v && v.length == 2) ||
                          'Country code should be 2 characters',
                        (v) =>
                          !!(v && countryCodes.includes(v.toUpperCase())) ||
                          'Country code must be a valid country',
                      ]"
                    />  -->
                  </v-form></v-expansion-panel-content
                ></v-expansion-panel
              >
              <v-expansion-panel key="3">
                <v-expansion-panel-header v-if="!proprietor_is_beneficial_owner"
                  >Beneficial Owner #3</v-expansion-panel-header
                >
                <v-expansion-panel-header v-else
                  >Beneficial Owner #4</v-expansion-panel-header
                >
                <v-expansion-panel-content
                  ><v-form
                    ref="form"
                    v-model="validForm7"
                    class="full-width px-4"
                  >
                    <v-text-field
                      label="First name"
                      v-model="beneficial_owner3.first_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'First name is required',
                        v =>
                          !!(v && v.length > 1) ||
                          'First name should be longer',
                        v =>
                          !!(v && v.length < 36) ||
                          'First name should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Last name"
                      v-model="beneficial_owner3.last_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Last name is required',
                        v =>
                          !!(v && v.length > 1) || 'Last name should be longer',
                        v =>
                          !!(v && v.length < 48) ||
                          'Last name should be shorter'
                      ]"
                    />
                    <v-menu
                      ref="owner3BirthdayMenu"
                      v-model="owner3BirthdayMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      attach
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="beneficial_owner3.dob"
                          class="mt-4"
                          label="Date of birth"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[v => !!v || 'Date of birth is required']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="owner3BirthdayPicker"
                        v-model="beneficial_owner3.dob"
                        :max="eighteenYearsFromToday"
                        min="1940-01-01"
                        @change="saveOwner3Birthday"
                      ></v-date-picker>
                    </v-menu>
                    <v-text-field
                      label="Social Security Number"
                      v-model="beneficial_owner3.ssn"
                      class="mt-4"
                      type="number"
                      required
                      :rules="[
                        v => !!v || 'SSN is required',
                        v =>
                          !!(v && !isNaN(v)) ||
                          'SSN should only contain numbers',
                        v =>
                          !!(v && v.length == 9) ||
                          'SSN should be 9 digits long'
                      ]"
                    />
                    <v-text-field
                      label="Home Address"
                      v-model="beneficial_owner3.home.address1"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Address is required',
                        v =>
                          !!(v && v.length < 256) || 'Address should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Apt, suite, unit, etc."
                      v-model="beneficial_owner3.home.address2"
                      class="mt-4"
                    />
                    <v-text-field
                      label="City"
                      v-model="beneficial_owner3.home.city"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'City is required',
                        v => !!(v && v.length < 40) || 'City should be shorter'
                      ]"
                    />
                    <v-select
                      label="State"
                      :items="states"
                      v-model="beneficial_owner3.home.state"
                      class="mt-4"
                      item-value="abbreviation"
                      item-text="name"
                      :rules="[v => !!v || 'State is required']"
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                    />
                    <v-text-field
                      label="Zip Code"
                      v-model="beneficial_owner3.home.postal_code"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Zip code is required',
                        v =>
                          !!(v && v.length < 20) || 'Zip code should be shorter'
                      ]"
                    />
                    <!-- <v-select
                      label="Country"
                      :items="countries"
                      v-model="beneficial_owner3.home.country"
                      class="mt-4"
                      item-value="code"
                      item-text="name"
                      :rules="[(v) => !!v || 'Country is required']"
                    />
                    <v-text-field
                      v-if="beneficial_owner3.home.country == '00'"
                      label="Other country (2 digit code)"
                      v-model="beneficial_owner3.home.otherCountry"
                      class="mt-4"
                      required
                      :rules="[
                        (v) => !!v || 'Country is required',
                        (v) =>
                          !!(v && v.length == 2) ||
                          'Country code should be 2 characters',
                        (v) =>
                          !!(v && countryCodes.includes(v.toUpperCase())) ||
                          'Country code must be a valid country',
                      ]"
                    />  -->
                  </v-form></v-expansion-panel-content
                ></v-expansion-panel
              >
              <v-expansion-panel key="4" v-if="!proprietor_is_beneficial_owner">
                <v-expansion-panel-header
                  >Beneficial Owner #4</v-expansion-panel-header
                >
                <v-expansion-panel-content
                  ><v-form
                    ref="form"
                    v-model="validForm8"
                    class="full-width px-4"
                  >
                    <v-text-field
                      label="First name"
                      v-model="beneficial_owner4.first_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'First name is required',
                        v =>
                          !!(v && v.length > 1) ||
                          'First name should be longer',
                        v =>
                          !!(v && v.length < 36) ||
                          'First name should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Last name"
                      v-model="beneficial_owner4.last_name"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Last name is required',
                        v =>
                          !!(v && v.length > 1) || 'Last name should be longer',
                        v =>
                          !!(v && v.length < 48) ||
                          'Last name should be shorter'
                      ]"
                    />
                    <v-menu
                      ref="owner4BirthdayMenu"
                      v-model="owner4BirthdayMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      attach
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="beneficial_owner4.dob"
                          class="mt-4"
                          label="Date of birth"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[v => !!v || 'Date of birth is required']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="owner4BirthdayPicker"
                        v-model="beneficial_owner4.dob"
                        :max="eighteenYearsFromToday"
                        min="1940-01-01"
                        @change="saveOwner4Birthday"
                      ></v-date-picker>
                    </v-menu>
                    <v-text-field
                      label="Social Security Number"
                      v-model="beneficial_owner4.ssn"
                      class="mt-4"
                      type="number"
                      required
                      :rules="[
                        v => !!v || 'SSN is required',
                        v =>
                          !!(v && !isNaN(v)) ||
                          'SSN should only contain numbers',
                        v =>
                          !!(v && v.length == 9) ||
                          'SSN should be 9 digits long'
                      ]"
                    />
                    <v-text-field
                      label="Home Address"
                      v-model="beneficial_owner4.home.address1"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Address is required',
                        v =>
                          !!(v && v.length < 256) || 'Address should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Apt, suite, unit, etc."
                      v-model="beneficial_owner4.home.address2"
                      class="mt-4"
                    />
                    <v-text-field
                      label="City"
                      v-model="beneficial_owner4.home.city"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'City is required',
                        v => !!(v && v.length < 40) || 'City should be shorter'
                      ]"
                    />
                    <v-select
                      label="State"
                      :items="states"
                      v-model="beneficial_owner4.home.state"
                      class="mt-4"
                      item-value="abbreviation"
                      item-text="name"
                      :rules="[v => !!v || 'State is required']"
                      attach
                      :menu-props="{ top: true, offsetY: true }"
                    />
                    <v-text-field
                      label="Zip Code"
                      v-model="beneficial_owner4.home.postal_code"
                      class="mt-4"
                      required
                      :rules="[
                        v => !!v || 'Zip code is required',
                        v =>
                          !!(v && v.length < 20) || 'Zip code should be shorter'
                      ]"
                    />
                    <!-- <v-select
                      label="Country"
                      :items="countries"
                      v-model="beneficial_owner4.home.country"
                      class="mt-4"
                      item-value="code"
                      item-text="name"
                      :rules="[(v) => !!v || 'Country is required']"
                    />
                    <v-text-field
                      v-if="beneficial_owner4.home.country == '00'"
                      label="Other country (2 digit code)"
                      v-model="beneficial_owner4.home.otherCountry"
                      class="mt-4"
                      required
                      :rules="[
                        (v) => !!v || 'Country is required',
                        (v) =>
                          !!(v && v.length == 2) ||
                          'Country code should be 2 characters',
                        (v) =>
                          !!(v && countryCodes.includes(v.toUpperCase())) ||
                          'Country code must be a valid country',
                      ]"
                    />  -->
                  </v-form></v-expansion-panel-content
                ></v-expansion-panel
              >
            </v-expansion-panels>
          </v-row>
          <v-row
            v-else-if="currentSlide == 5"
            key="5"
            no-gutters
            class="word-break px-5 d-flex flex-column align-center mb-8"
          >
            <v-card-title class="title-text word-break">
              Do you agree that the information provided is correct and
              truthful?
            </v-card-title>

            <v-form ref="form" v-model="validForm9" class="full-width px-4">
              <v-text-field
                label="Your First and Last Name"
                v-model="attester_name"
                class="mt-4"
                required
                :rules="[
                  v =>
                    !!(v && v.includes(' ')) ||
                    'First and last name is required',
                  v => !!(v && v.length < 256) || 'Your name should be shorter'
                ]"
              />
              <v-menu
                ref="attestationDateMenu"
                v-model="attestationDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                attach
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="attestation_date"
                    class="mt-4"
                    label="Date Completed"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => !!v || 'Date is required']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="attestationDatePicker"
                  v-model="attestation_date"
                  :max="eighteenYearsFromToday"
                  min="2000-01-01"
                  @change="saveAttestationDate"
                ></v-date-picker>
              </v-menu>
              <v-checkbox
                v-model="attestation_consent"
                class="mt-4"
                label="The information provided is correct and truthful?"
              />
            </v-form>
          </v-row>
          <v-row
            v-else-if="currentSlide == 6"
            key="6"
            no-gutters
            class="word-break px-5 d-flex flex-column align-center mb-8"
          >
            <v-card-title class="title-text word-break"
              >Processing...</v-card-title
            >
            <caption class="grey--text mt-2">
              This should only take a few seconds.
            </caption>
            <Robin
              class="mt-6"
              :showText="false"
              :width="140"
              :height="140"
              animation="inflatableDance"
              :loop="true"
            />
          </v-row>
          <v-row
            v-else-if="currentSlide == 7"
            key="7"
            no-gutters
            class="word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
          >
            <v-card-title class="title-text word-break full-width"
              >Congratulations!</v-card-title
            >
            <caption class="grey--text mt-2 pl-4 full-width align-text-left">
              Your business registration is complete
            </caption>
            <Robin
              class="mt-6"
              :showText="false"
              :width="140"
              :height="140"
              animation="backflip"
              :loop="false"
            />
          </v-row>
          <v-row
            v-else-if="currentSlide == 8"
            key="8"
            no-gutters
            class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
          >
            <v-card-title class="pl-0 word-break">
              {{
                vendorPendingError
                  ? "Looks like your organization's account needs a little extra time"
                  : "Looks like your organization's account needs some extra attention"
              }}
            </v-card-title>

            <p class="mt-4 full-width">
              {{
                vendorPendingError
                  ? "The business can sometimes take longer to go through the verification process - we'll be in touch with updates!"
                  : "Sometimes the registration process can take a little extra time."
              }}
            </p>

            <p>
              A Whistle Client Success Manager will reach out for any next
              steps, but feel free to reach out to us via one of the following
              methods for a status update.
            </p>
            <p class="full-width">
              Email -
              <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
            </p>
            <p class="full-width">Phone (Toll Free) - <b>(855) 264-3329</b></p>
            <!-- <p v-if="error && userProfile.clientId == 1">Error: {{ error }}</p> -->
            <v-card-title
              class="pl-0 full-width"
              v-if="
                error.business ||
                  error.officer ||
                  error.owner1 ||
                  error.owner2 ||
                  error.owner3 ||
                  error.owner4
              "
              >Verification Results</v-card-title
            >

            <div v-if="error && error.business" class="full-width">
              <p class="full-width">
                Business -
                <b>{{ error_outcomes[error.business.outcome] }}</b>
              </p>
              <div
                class="full-width"
                v-for="(result, i) in error.business.results"
                :key="i"
              >
                <p class="font-weight-bold mb-2">{{ result.description }}</p>

                <p v-html="result.solution"></p>
              </div>
              <v-divider class="mb-6" />
            </div>
            <div v-if="error && error.officer" class="full-width">
              <p class="full-width">
                Organization Officer -
                <b>{{ error_outcomes[error.officer.outcome] }}</b>
              </p>
              <div
                class="full-width"
                v-for="(result, i) in error.officer.results"
                :key="i"
              >
                <p class="font-weight-bold mb-2">{{ result.description }}</p>

                <p v-html="result.solution"></p>
              </div>
              <v-divider class="mb-6" />
            </div>
            <div
              v-if="error && error.owner1 && !isOwner1Empty"
              class="full-width"
            >
              <p class="full-width">
                Beneficial Owner
                {{ !proprietor_is_beneficial_owner ? "1" : "2" }} -
                <b>{{ error_outcomes[error.owner1.outcome] }}</b>
              </p>
              <div
                class="full-width"
                v-for="(result, i) in error.owner1.results"
                :key="i"
              >
                <p class="font-weight-bold mb-2">{{ result.description }}</p>

                <p v-html="result.solution"></p>
              </div>
              <v-divider class="mb-6" />
            </div>
            <div
              v-if="error && error.owner2 && !isOwner2Empty"
              class="full-width"
            >
              <p class="full-width">
                Beneficial Owner
                {{ !proprietor_is_beneficial_owner ? "2" : "3" }} -
                <b>{{ error_outcomes[error.owner2.outcome] }}</b>
              </p>
              <div
                class="full-width"
                v-for="(result, i) in error.owner2.results"
                :key="i"
              >
                <p class="font-weight-bold mb-2">{{ result.description }}</p>

                <p v-html="result.solution"></p>
              </div>
              <v-divider class="mb-6" />
            </div>
            <div
              v-if="error && error.owner3 && !isOwner3Empty"
              class="full-width"
            >
              <p class="full-width">
                Beneficial Owner
                {{ !proprietor_is_beneficial_owner ? "3" : "4" }} -
                <b>{{ error_outcomes[error.owner3.outcome] }}</b>
              </p>
              <div
                class="full-width"
                v-for="(result, i) in error.owner3.results"
                :key="i"
              >
                <p class="font-weight-bold mb-2">{{ result.description }}</p>

                <p v-html="result.solution"></p>
              </div>
              <v-divider class="mb-6" />
            </div>
            <div
              v-if="error && error.owner4 && !isOwner4Empty"
              class="full-width"
            >
              <p class="full-width">
                Beneficial Owner 4 -
                <b>{{ error_outcomes[error.owner4.outcome] }}</b>
              </p>
              <div
                class="full-width"
                v-for="(result, i) in error.owner4.results"
                :key="i"
              >
                <p class="font-weight-bold mb-2">{{ result.description }}</p>

                <p v-html="result.solution"></p>
              </div>
              <v-divider class="mb-6" />
            </div>
          </v-row>
        </transition>
        <v-row
          class="mb-10"
          align="center"
          justify="center"
          v-if="currentSlide != 6"
        >
          <v-col>
            <v-btn
              color="primary"
              :disabled="
                (currentSlide == 0 && !validForm1) ||
                  (currentSlide == 1 && !validForm2) ||
                  (currentSlide == 2 && !validForm3) ||
                  (currentSlide == 3 &&
                    (!validForm4 || beneficialOwnerToggle === null)) ||
                  (currentSlide == 4 &&
                    (beneficialOwnerToggle === null ||
                      (beneficialOwnerToggle == true &&
                        ((!validForm5 && !isOwner1Empty) ||
                          (!validForm6 && !isOwner2Empty) ||
                          (!validForm7 && !isOwner3Empty) ||
                          (!validForm8 &&
                            !proprietor_is_beneficial_owner &&
                            !isOwner4Empty))))) ||
                  (currentSlide == 5 && (!validForm9 || !attestation_consent))
              "
              class="pa-5"
              @click="nextSlide"
            >
              <!-- <v-icon class="mr-1" color="brandGreen"
                >mdi-arrow-right-drop-circle</v-icon
              > -->
              <span class="mt-1">{{
                currentSlide >= 7 ? "Done" : "Continue"
              }}</span></v-btn
            ></v-col
          >
        </v-row></v-col
      ></v-row
    >
    <v-row justify="center" v-else>
      <v-col
        cols="12"
        sm="12"
        md="4 "
        lg="4"
        xl="4"
        class="align-text-left darkGrey--text"
      >
        <div class="d-flex justify-center align-center mb-5 mt-10 hidden">
          <v-btn small fab elevation="0" color="mediumGrey"
            ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
          >
          <ProgressBar :progress="progress" class="progress-bar mx-12" />
          <v-btn small fab elevation="0" color="mediumGrey"
            ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
          >
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="5"
        lg="5"
        xl="5"
        class="align-text-left darkGrey--text"
      >
        <div class="d-flex justify-center align-center mb-5 mt-10">
          <v-btn
            small
            fab
            elevation="0"
            color="mediumGrey"
            @click="previousSlide"
            :class="{
              hidden:
                currentSlide == 0 ||
                currentSlide == 6 ||
                currentSlide == 7 ||
                currentSlide == 8
            }"
            ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
          >
          <ProgressBar :progress="progress" class="progress-bar mx-12" />
          <v-btn
            small
            fab
            elevation="0"
            color="mediumGrey"
            @click="nextSlide"
            :disabled="disableNextButton"
            :class="{ hidden: currentSlide == 6 }"
            ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
          >
        </div>
        <v-card-title class="title-text word-break">
          The last step is to confirm that all of the information provided is
          correct.
        </v-card-title></v-col
      ><v-col cols="12" sm="12" md="10" lg="10" xl="10">
        <v-divider />
        <transition :name="slideDirection" mode="out-in">
          <v-row no-gutters class="word-break px-5 mt-2 py-4 align-text-left">
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="mediumGrey--text"
                >
                  <p>Company Name</p>
                  <p>DBA</p>
                  <p>Tax ID</p>
                  <p>Company Address</p>
                  <p v-if="office_location.address2"><br /></p>
                  <p><br /></p>
                  <p>Date Established</p>
                  <p>Incorporation Type</p>
                  <p>State of Incorporation</p>
                  <p>Public Company</p>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <p>
                    <b>
                      {{ business_name_legal }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ business_name_dba }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ identifications[0].value }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ office_location.address1 }}
                    </b>
                  </p>
                  <p>
                    <b v-if="office_location.address2">
                      {{ office_location.address2 }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ office_location.city }}, {{ office_location.state }}
                      {{ office_location.postal_code }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ date_established }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{
                        incorporation.incorporation_type ==
                        "SOLE_PROPRIETORSHIP"
                          ? "Sole Proprietorship"
                          : incorporation.incorporation_type == "CORPORATION"
                          ? "Corporation"
                          : incorporation.incorporation_type == "PARTNERSHIP"
                          ? "Partnership"
                          : incorporation.incorporation_type == "COOPERATIVE"
                          ? "Cooperative"
                          : incorporation.incorporation_type == "OTHER"
                          ? "Other"
                          : incorporation.incorporation_type
                      }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ incorporation.state_of_incorporation }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ incorporation.is_public ? "Yes" : "No" }}
                    </b>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="mediumGrey--text"
                >
                  <p>Control Person</p>
                  <p>Date of Birth</p>
                  <p>Tax ID</p>
                  <p>Address</p>
                  <p><br /></p>
                  <p><br /></p>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <p>
                    <b>
                      {{
                        proprietor_or_officer.first_name +
                          " " +
                          proprietor_or_officer.last_name
                      }}
                    </b>
                  </p>
                  <p>
                    <b>
                      {{ proprietor_or_officer.dob }}
                    </b>
                  </p>
                  <p>
                    <b v-if="proprietor_or_officer.showSSN">
                      {{ proprietor_or_officer.identification
                      }}<v-icon
                        @click="proprietor_or_officer.showSSN = false"
                        class="ml-2"
                        >mdi-eye</v-icon
                      >
                    </b>
                    <b v-else-if="proprietor_or_officer.identification"
                      >*********<v-icon
                        @click="proprietor_or_officer.showSSN = true"
                        class="ml-2"
                        >mdi-eye-off</v-icon
                      ></b
                    >
                  </p>
                  <p>
                    <b>
                      {{ proprietor_or_officer.home.address1 }}
                    </b>
                  </p>
                  <p>
                    <b v-if="proprietor_or_officer.home.address2">
                      {{ proprietor_or_officer.home.address2 }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b>
                      {{ proprietor_or_officer.home.city }},
                      {{ proprietor_or_officer.home.state }}
                      {{ proprietor_or_officer.home.postal_code }}
                    </b>
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-row v-if="!isOwner1Empty">
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="mediumGrey--text"
                >
                  <p>
                    Beneficial Owner #{{
                      !proprietor_is_beneficial_owner ? "1" : "2"
                    }}
                  </p>
                  <p>Date of Birth</p>
                  <p>Tax ID</p>
                  <p>Address</p>
                  <p><br /></p>
                  <p><br /></p>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <p>
                    <b v-if="!isOwner1Empty">
                      {{
                        beneficial_owner1.first_name +
                          " " +
                          beneficial_owner1.last_name
                      }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner1.dob }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner1.showSSN">
                      {{ beneficial_owner1.ssn }}
                      <v-icon
                        @click="beneficial_owner1.showSSN = false"
                        class="ml-2"
                        >mdi-eye</v-icon
                      >
                    </b>
                    <b v-else-if="beneficial_owner1.ssn"
                      >*********
                      <v-icon
                        @click="beneficial_owner1.showSSN = true"
                        class="ml-2"
                        >mdi-eye-off</v-icon
                      ></b
                    >
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner1.home.address1 }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner1.home.address2">
                      {{ beneficial_owner1.home.address2 }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b v-if="!isOwner1Empty">
                      {{ beneficial_owner1.home.city }},
                      {{ beneficial_owner1.home.state }}
                      {{ beneficial_owner1.home.postal_code }}
                    </b>
                    <br v-else />
                  </p>
                </v-col>
              </v-row>

              <v-row v-if="!isOwner2Empty">
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="mediumGrey--text"
                >
                  <p>
                    Beneficial Owner #{{
                      !proprietor_is_beneficial_owner ? "2" : "3"
                    }}
                  </p>
                  <p>Date of Birth</p>
                  <p>Tax ID</p>
                  <p>Address</p>
                  <p><br /></p>
                  <p><br /></p>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <p>
                    <b v-if="!isOwner2Empty">
                      {{
                        beneficial_owner2.first_name +
                          " " +
                          beneficial_owner2.last_name
                      }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner2.dob }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner2.showSSN">
                      {{ beneficial_owner2.ssn }}
                      <v-icon
                        @click="beneficial_owner2.showSSN = false"
                        class="ml-2"
                        >mdi-eye</v-icon
                      >
                    </b>
                    <b v-else-if="beneficial_owner2.ssn"
                      >*********
                      <v-icon
                        @click="beneficial_owner2.showSSN = true"
                        class="ml-2"
                        >mdi-eye-off</v-icon
                      ></b
                    >
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner2.home.address1 }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner2.home.address2">
                      {{ beneficial_owner2.home.address2 }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b v-if="!isOwner2Empty">
                      {{ beneficial_owner2.home.city }},
                      {{ beneficial_owner2.home.state }}
                      {{ beneficial_owner2.home.postal_code }}
                    </b>
                    <br v-else />
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-row v-if="!isOwner3Empty">
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="mediumGrey--text"
                >
                  <p>
                    Beneficial Owner #{{
                      !proprietor_is_beneficial_owner ? "3" : "4"
                    }}
                  </p>
                  <p>Date of Birth</p>
                  <p>Tax ID</p>
                  <p>Address</p>
                  <p><br /></p>
                  <p><br /></p>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <p>
                    <b v-if="!isOwner3Empty">
                      {{
                        beneficial_owner3.first_name +
                          " " +
                          beneficial_owner3.last_name
                      }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner3.dob }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner3.showSSN">
                      {{ beneficial_owner3.ssn }}
                      <v-icon
                        @click="beneficial_owner3.showSSN = false"
                        class="ml-2"
                        >mdi-eye</v-icon
                      >
                    </b>
                    <b v-else-if="beneficial_owner3.ssn"
                      >*********
                      <v-icon
                        @click="beneficial_owner3.showSSN = true"
                        class="ml-2"
                        >mdi-eye-off</v-icon
                      ></b
                    >
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner3.home.address1 }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner3.home.address2">
                      {{ beneficial_owner3.home.address2 }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b v-if="!isOwner3Empty">
                      {{ beneficial_owner3.home.city }},
                      {{ beneficial_owner3.home.state }}
                      {{ beneficial_owner3.home.postal_code }}
                    </b>
                    <br v-else />
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="!isOwner4Empty">
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="mediumGrey--text"
                >
                  <p>
                    Beneficial Owner #{{
                      !proprietor_is_beneficial_owner ? "4" : "5"
                    }}
                  </p>
                  <p>Date of Birth</p>
                  <p>Tax ID</p>
                  <p>Address</p>
                  <p><br /></p>
                  <p><br /></p>
                </v-col>
                <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                  <p>
                    <b v-if="!isOwner4Empty">
                      {{
                        beneficial_owner4.first_name +
                          " " +
                          beneficial_owner4.last_name
                      }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner4.dob }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner4.showSSN">
                      {{ beneficial_owner4.ssn
                      }}<v-icon
                        @click="beneficial_owner4.showSSN = false"
                        class="ml-2"
                        >mdi-eye</v-icon
                      >
                    </b>
                    <b v-else-if="beneficial_owner4.ssn"
                      >*********<v-icon
                        @click="beneficial_owner4.showSSN = true"
                        class="ml-2"
                        >mdi-eye-off</v-icon
                      ></b
                    >
                  </p>
                  <p>
                    <b>
                      {{ beneficial_owner4.home.address1 }}
                    </b>
                  </p>
                  <p>
                    <b v-if="beneficial_owner4.home.address2">
                      {{ beneficial_owner4.home.address2 }}
                    </b>
                    <br v-else />
                  </p>
                  <p>
                    <b v-if="!isOwner4Empty">
                      {{ beneficial_owner4.home.city }},
                      {{ beneficial_owner4.home.state }}
                      {{ beneficial_owner4.home.postal_code }}
                    </b>
                    <br v-else />
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </transition>
        <v-divider />
        <div class="d-flex align-center">
          <span>I, </span>
          <v-form v-model="validForm9">
            <v-text-field
              label="First and Last Name"
              v-model="attester_name"
              class="mx-3"
              required
              :rules="[
                v =>
                  !!(v && v.includes(' ')) || 'First and last name is required',
                v => !!(v && v.length < 256) || 'Your name should be shorter'
              ]"
            />
          </v-form>
          <span> certify that all the information is truthful.</span>
          <v-checkbox v-model="attestation_consent" class="ml-4" />
        </div>
        <div class="d-flex align-center" v-if="displayTestingButtons">
          <v-checkbox
            v-model="testing.no_submission"
            label="Fail before KYB submission"
          />
          <v-checkbox
            v-model="testing.failure"
            label="Return KYB failures (succeeds on webhook call)"
            class="ml-4"
          />
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="darkGrey--text">
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="darkGrey--text">
        <v-row class="mb-10" align="center" justify="center">
          <v-col>
            <v-btn
              color="primary"
              :disabled="!validForm9 || !attestation_consent"
              class="pa-5 mx-auto"
              @click="nextSlide"
            >
              <!-- <v-icon class="mr-1" color="brandGreen"
                >mdi-arrow-right-drop-circle</v-icon
              > -->
              <span class="mt-1">{{ "Continue" }}</span></v-btn
            ></v-col
          >
        </v-row></v-col
      ></v-row
    >
    <!-- <v-dialog v-model="errorDialog.display" width="500"> -->
    <ErrorDialog
      v-if="errorDialog.display"
      :title="errorDialog.title"
      :subtitle="errorDialog.subtitle"
      :button1Text="errorDialog.button1Text"
      :button2Text="errorDialog.button2Text"
      @button1="errorDialog.display = false"
      @button2="
        (errorDialog.override = true),
          (errorDialog.display = false),
          nextSlide()
      "
      @close="errorDialog.display = false"
    />
  </v-card>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import OnboardingService from "../services/OnboardingService.js";
import UserService from "@/services/UserService";

import { states, countries, countryCodes } from "@/shared_data/data.js";
import ProgressBar from "@/components/ProgressBar";
import ErrorDialog from "@/components/ErrorDialog";
import Robin from "@/components/Robin";

import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "MarqetaBusinessCreation",
  props: {},
  components: {
    ProgressBar,
    Robin,
    ErrorDialog
  },
  data() {
    return {
      progress: null,
      clientId: null,
      currentSlide: 0,
      slideDirection: "topic-left",
      validForm1: false,
      validForm2: false,
      validForm3: false,
      validForm4: false,
      validForm5: false,
      validForm6: false,
      validForm7: false,
      validForm8: false,
      validForm9: false,
      acceptMSA: false,
      states: states,
      countries: countries,
      countryCodes: countryCodes,
      establishedDateMenu: false,
      officerBirthdayMenu: false,
      owner1BirthdayMenu: false,
      owner2BirthdayMenu: false,
      owner3BirthdayMenu: false,
      owner4BirthdayMenu: false,
      attestationDateMenu: false,
      beneficialOwnerToggle: null,
      // Used by the error component
      errorDialog: {
        display: false,
        override: false,
        title: null,
        subtitle: null,
        button1Text: null,
        button2Text: null
      },

      displayWhyWeNeedThis: false,
      displayWhatWeDoWithInfo: false,
      displayControlPersonInfo: false,
      displayOwnerInfo: false,
      incorporationTypes: [
        { value: "LLC", label: "LLC" },
        { value: "CORPORATION", label: "Corporation" },
        { value: "SOLE_PROPRIETORSHIP", label: "Sole Proprietorship" },
        { value: "PARTNERSHIP", label: "Partnership" },
        { value: "COOPERATIVE", label: "Cooperative" },
        { value: "OTHER", label: "Other" }
      ],

      business_name_legal: null,
      business_name_dba: null,
      msa_full_name: null,
      msa_title: null,
      identifications: [{ type: "BUSINESS_TAX_ID", value: null }],
      office_location: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
        otherCountry: null
      },
      date_established: null,
      incorporation: {
        is_public: false,
        incorporation_type: null,
        state_of_incorporation: null
      },
      proprietor_or_officer: {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        identification: null,
        showSSN: false
      },
      beneficial_owner1: {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      },
      beneficial_owner2: {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      },
      beneficial_owner3: {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      },
      beneficial_owner4: {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      },
      proprietor_is_beneficial_owner: false,
      attestation_consent: null,
      attester_name: null,
      attestation_date: null,
      error_outcomes: {
        VENDOR_PENDING: "Awaiting Results",
        PENDING: "Action Needed (See Below)",
        SUCCESS: "Passed",
        FAILURE: "Action Needed (See Below)"
      },
      error: {},
      vendorPendingError: false,
      testing: {
        failure: false,
        no_submission: false
      }
    };
  },
  created() {
    this.progress = ((1 / 7) * 100).toString();
    this.clientId = this.userProfile.clientId;
    this.attestation_date = moment()
      .local()
      .format("YYYY-MM-DD");
    this.business_name_legal = this.userProfile.Client.clientName;
    this.business_name_dba = this.userProfile.Client.clientName;
  },
  beforeDestroy() {},
  methods: {
    resetForm(leave, success = false) {
      this.business_name_legal = null;
      this.business_name_dba = null;
      this.identifications = [{ type: "BUSINESS_TAX_ID", value: null }];
      this.proprietor_or_officer = {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        identification: null,
        showSSN: false
      };
      this.beneficial_owner1 = {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      };
      this.beneficial_owner2 = {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      };
      this.beneficial_owner3 = {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      };
      this.beneficial_owner4 = {
        first_name: null,
        last_name: null,
        home: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          otherCountry: null
        },
        dob: null,
        ssn: null,
        showSSN: false
      };
      this.proprietor_is_beneficial_owner = false;
      this.attestation_consent = null;
      this.attester_name = null;
      this.attestation_date = null;
      this.error = {};
      this.vendorPendingError = false;

      if (this.$refs.form) {
        console.log("Resetting form");
        this.$refs.form.reset();
      }

      this.currentSlide = 0;

      if (leave) {
        this.scrollToTop();
        this.$emit("reset", success);
        this.$emit("get-cards");
      }
    },
    scrollToTop() {
      if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
        document.getElementsByClassName(
          "v-dialog v-dialog--active"
        )[0].scrollTop = 0;
      } else {
        const cardY = document.getElementById("progress-bar");
        const rect = cardY.getBoundingClientRect();
        console.log(rect.top);
        window.scrollTo(0, rect.top - 90);
      }
    },
    previousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();
      if (this.currentSlide == 0) {
        this.resetForm(true, false);
      } else if (
        this.currentSlide == 5 &&
        this.beneficialOwnerToggle === false
      ) {
        // If the user is on the review slide and goes back, then we skip the owner page
        this.currentSlide -= 2;
      } else if (this.currentSlide == 7) {
        // We go back 2 slides because 1 slide is the processing/loading screen
        this.currentSlide -= 2;
      } else {
        this.currentSlide -= 1;
      }
      this.progress = (((this.currentSlide + 1) / 7) * 100).toString();
    },
    nextSlide() {
      console.log("slide " + this.currentSlide);
      this.slideDirection = "topic-left";
      this.scrollToTop();
      this.currentSlide += 1;

      if (this.currentSlide == 1) {
        console.log("msa accept");
        //on first slide, record MSA acceptance
        this.userAcceptMSA();
      } else if (
        this.currentSlide == 4 &&
        this.identifications.length > 0 &&
        this.identifications[0].value ==
          this.proprietor_or_officer.identification &&
        !this.errorDialog.override
      ) {
        // If the ssn matches the ein then we throw an error but still let them pass if they want
        this.errorDialog.display = true;
        this.errorDialog.title =
          "Looks like Your Federal EIN matches the SSN entered";
        this.errorDialog.subtitle = "Is this correct?";
        this.errorDialog.button1Text = "Edit";
        this.errorDialog.button2Text = "Continue";
        this.currentSlide -= 1;
        // this.progress = (((this.currentSlide + 1) / 7) * 100).toString();
      } else if (
        this.currentSlide == 4 &&
        this.beneficialOwnerToggle === false
      ) {
        // If the business doesn't have any beneficial owners, then we skip a slide
        this.currentSlide += 1;
        // this.progress = (((this.currentSlide + 1) / 7) * 100).toString();
      } else if (this.currentSlide == 6) {
        // We are submitting api call
        this.createBusiness();
      } else if (this.currentSlide == 8 || this.currentSlide == 9) {
        this.resetForm(true, this.currentSlide == 8);
      }
      this.progress = (((this.currentSlide + 1) / 7) * 100).toString();
    },
    downloadMSA() {
      OnboardingService.downloadLegalDoc("msa_v1");
    },
    userAcceptMSA() {
      //save the acceptance to user supplemental
      if (this.acceptMSA === true) {
        var acceptanceString = "";
        var jsonAcceptance = {};
        var now = moment()
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");

        acceptanceString =
          "I, " +
          this.msa_full_name +
          ", " +
          this.msa_title +
          ", of " +
          this.business_name_legal +
          " accept Accepted Whistle_Payments_Agreement_v1_12.3.21.pdf on " +
          now;
        jsonAcceptance.full_name = this.msa_full_name;
        jsonAcceptance.title = this.msa_title;
        jsonAcceptance.legal_business_name = this.business_name_legal;
        jsonAcceptance.acceptanceDateTime = now;
        jsonAcceptance.agreementVersion =
          "Whistle_Payments_Agreement_v1_12.3.21.pdf";
        jsonAcceptance.digitalSignature = acceptanceString;

        var userSuppArray = [
          {
            userId: this.userProfile.userId,
            key: "Whistle_Payments_Agreement",
            value: JSON.stringify(jsonAcceptance),
            updatedBy: this.userProfile.userId
          }
        ];

        UserService.bulkUpdateUserSupplemental(userSuppArray).then(userSupp => {
          console.log(userSupp);
        });
      }
    },
    saveDateEstablished(date) {
      this.$refs.establishedDateMenu.save(date);
    },
    saveOfficerBirthday(date) {
      this.$refs.officerBirthdayMenu.save(date);
    },
    saveOwner1Birthday(date) {
      this.$refs.owner1BirthdayMenu.save(date);
    },
    saveOwner2Birthday(date) {
      this.$refs.owner2BirthdayMenu.save(date);
    },
    saveOwner3Birthday(date) {
      this.$refs.owner3BirthdayMenu.save(date);
    },
    saveOwner4Birthday(date) {
      this.$refs.owner4BirthdayMenu.save(date);
    },
    saveAttestationDate(date) {
      this.$refs.attestationDateMenu.save(date);
    },
    createBusiness() {
      console.log("Creating business");
      var body = {
        clientId: this.clientId,
        business_name_legal: this.business_name_legal,
        business_name_dba: this.business_name_dba,
        office_location: {
          address1: this.office_location.address1,
          address2: this.office_location.address2,
          city: this.office_location.city,
          state: this.office_location.state,
          postal_code: this.office_location.postal_code,
          country: "US"
          // this.office_location.country != "00"
          //   ? this.office_location.country
          //   : this.office_location.otherCountry,
        },
        date_established: this.date_established,
        incorporation: {
          is_public: this.incorporation.is_public,
          incorporation_type: this.incorporation.incorporation_type,
          state_of_incorporation: this.incorporation.state_of_incorporation
        },
        identifications: this.identifications,
        proprietor_or_officer: {
          first_name: this.proprietor_or_officer.first_name,
          last_name: this.proprietor_or_officer.last_name,
          home: {
            address1: this.proprietor_or_officer.home.address1,
            address2: this.proprietor_or_officer.home.address2,
            city: this.proprietor_or_officer.home.city,
            state: this.proprietor_or_officer.home.state,
            postal_code: this.proprietor_or_officer.home.postal_code,
            country: "US"
            // this.proprietor_or_officer.home.country != "00"
            //   ? this.proprietor_or_officer.home.country
            //   : this.proprietor_or_officer.home.otherCountry,
          },
          dob: this.proprietor_or_officer.dob,
          identifications: [
            { type: "SSN", value: this.proprietor_or_officer.identification }
          ]
        },
        proprietor_is_beneficial_owner: this.proprietor_is_beneficial_owner,
        attestation_consent: this.attestation_consent,
        attester_name: this.attester_name,
        attestation_date: this.attestation_date
      };
      if (!this.isOwner1Empty) {
        // Delete the object if nothing is entered
        body.beneficial_owner1 = {
          first_name: this.beneficial_owner1.first_name,
          last_name: this.beneficial_owner1.last_name,
          home: {
            address1: this.beneficial_owner1.home.address1,
            address2: this.beneficial_owner1.home.address2,
            city: this.beneficial_owner1.home.city,
            state: this.beneficial_owner1.home.state,
            postal_code: this.beneficial_owner1.home.postal_code,
            country: "US"
            // this.beneficial_owner1.home.country != "00"
            //   ? this.beneficial_owner1.home.country
            //   : this.beneficial_owner1.home.otherCountry,
          },
          dob: this.beneficial_owner1.dob,
          ssn: this.beneficial_owner1.ssn
        };
      }
      if (!this.isOwner2Empty) {
        // Delete the object if nothing is entered
        body.beneficial_owner2 = {
          first_name: this.beneficial_owner2.first_name,
          last_name: this.beneficial_owner2.last_name,
          home: {
            address1: this.beneficial_owner2.home.address1,
            address2: this.beneficial_owner2.home.address2,
            city: this.beneficial_owner2.home.city,
            state: this.beneficial_owner2.home.state,
            postal_code: this.beneficial_owner2.home.postal_code,
            country: "US"
            // this.beneficial_owner2.home.country != "00"
            //   ? this.beneficial_owner2.home.country
            //   : this.beneficial_owner2.home.otherCountry,
          },
          dob: this.beneficial_owner2.dob,
          ssn: this.beneficial_owner2.ssn
        };
      }
      if (!this.isOwner3Empty) {
        // Delete the object if nothing is entered
        body.beneficial_owner3 = {
          first_name: this.beneficial_owner3.first_name,
          last_name: this.beneficial_owner3.last_name,
          home: {
            address1: this.beneficial_owner3.home.address1,
            address2: this.beneficial_owner3.home.address2,
            city: this.beneficial_owner3.home.city,
            state: this.beneficial_owner3.home.state,
            postal_code: this.beneficial_owner3.home.postal_code,
            country: "US"
            // this.beneficial_owner3.home.country != "00"
            //   ? this.beneficial_owner3.home.country
            //   : this.beneficial_owner3.home.otherCountry,
          },
          dob: this.beneficial_owner3.dob,
          ssn: this.beneficial_owner3.ssn
        };
      }
      if (!this.isOwner4Empty) {
        // Delete the object if nothing is entered
        body.beneficial_owner4 = {
          first_name: this.beneficial_owner4.home.first_name,
          last_name: this.beneficial_owner4.home.last_name,
          home: {
            address1: this.beneficial_owner4.home.address1,
            address2: this.beneficial_owner4.home.address2,
            city: this.beneficial_owner4.home.city,
            state: this.beneficial_owner4.home.state,
            postal_code: this.beneficial_owner4.home.postal_code,
            country: "US"
            // this.beneficial_owner4.home.country != "00"
            //   ? this.beneficial_owner4.home.country
            //   : this.beneficial_owner4.home.otherCountry,
          },
          dob: this.beneficial_owner4.dob,
          ssn: this.beneficial_owner4.ssn
        };
      }
      // console.log(body);
      MarqetaService.createBusiness(
        body,
        !this.displayTestingButtons
          ? {}
          : {
              failure: this.testing.failure ? 1 : 0,
              no_submission: this.testing.no_submission ? 1 : 0
            }
      )
        .then(response => {
          console.log("KYB response: ", response);
          this.vendorPendingError = false;
          if (response.error_code) {
            this.currentSlide += 2;
            this.error = response;
          } else if (response.kyc) {
            var failedKYB = false;
            if (response.kyc.business && !response.kyc.business.passed) {
              failedKYB = true;
            }
            if (response.kyc.officer && !response.kyc.officer.passed) {
              failedKYB = true;
            }
            if (response.kyc.owner1 && !response.kyc.owner1.passed) {
              failedKYB = true;
            }
            if (response.kyc.owner2 && !response.kyc.owner2.passed) {
              failedKYB = true;
            }
            if (response.kyc.owner3 && !response.kyc.owner3.passed) {
              failedKYB = true;
            }
            if (response.kyc.owner4 && !response.kyc.owner4.passed) {
              failedKYB = true;
            }

            if (failedKYB) {
              // We consider the case where everything passes except vendor pending on the business, in that case we display a tweaked error
              if (
                !response.kyc.business.passed &&
                response.kyc.business.outcome == "VENDOR_PENDING" &&
                response.kyc.officer.passed &&
                response.kyc.owner1.passed &&
                response.kyc.owner2.passed &&
                response.kyc.owner3.passed &&
                response.kyc.owner4.passed
              ) {
                this.vendorPendingError = true;
              }
              this.currentSlide += 2;
              this.error = response.kyc;
            } else {
              this.currentSlide++;
            }
          } else {
            this.currentSlide++;
          }
        })
        .catch(error => {
          console.log("There was an error submitting KYB: ", error);
          this.currentSlide += 2;
          this.error = error;
        });
    },
    resetBeneficialOwner4(newVal) {
      if (newVal) {
        // The new value is true, so we reset beneficial owner 4 to null
        this.beneficial_owner4 = {
          first_name: null,
          last_name: null,
          home: {
            address1: null,
            address2: null,
            city: null,
            state: null,
            postal_code: null,
            country: null,
            otherCountry: null
          },
          dob: null,
          ssn: null
        };
      }
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "walletMenuScreen",
      "displayWalletMenu",
      "clients"
    ]),
    isOwner1Empty() {
      if (
        (this.beneficial_owner1.first_name == null ||
          this.beneficial_owner1.first_name.length == 0) &&
        (this.beneficial_owner1.last_name == null ||
          this.beneficial_owner1.last_name.length == 0) &&
        (this.beneficial_owner1.home.address1 == null ||
          this.beneficial_owner1.home.address1.length == 0) &&
        (this.beneficial_owner1.home.address2 == null ||
          this.beneficial_owner1.home.address2.length == 0) &&
        (this.beneficial_owner1.home.city == null ||
          this.beneficial_owner1.home.city.length == 0) &&
        (this.beneficial_owner1.home.postal_code == null ||
          this.beneficial_owner1.home.postal_code.length == 0) &&
        (this.beneficial_owner1.ssn == null ||
          this.beneficial_owner1.ssn.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isOwner2Empty() {
      if (
        (this.beneficial_owner2.first_name == null ||
          this.beneficial_owner2.first_name.length == 0) &&
        (this.beneficial_owner2.last_name == null ||
          this.beneficial_owner2.last_name.length == 0) &&
        (this.beneficial_owner2.home.address1 == null ||
          this.beneficial_owner2.home.address1.length == 0) &&
        (this.beneficial_owner2.home.address2 == null ||
          this.beneficial_owner2.home.address2.length == 0) &&
        (this.beneficial_owner2.home.city == null ||
          this.beneficial_owner2.home.city.length == 0) &&
        (this.beneficial_owner2.home.postal_code == null ||
          this.beneficial_owner2.home.postal_code.length == 0) &&
        (this.beneficial_owner2.ssn == null ||
          this.beneficial_owner2.ssn.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isOwner3Empty() {
      if (
        (this.beneficial_owner3.first_name == null ||
          this.beneficial_owner3.first_name.length == 0) &&
        (this.beneficial_owner3.last_name == null ||
          this.beneficial_owner3.last_name.length == 0) &&
        (this.beneficial_owner3.home.address1 == null ||
          this.beneficial_owner3.home.address1.length == 0) &&
        (this.beneficial_owner3.home.address2 == null ||
          this.beneficial_owner3.home.address2.length == 0) &&
        (this.beneficial_owner3.home.city == null ||
          this.beneficial_owner3.home.city.length == 0) &&
        (this.beneficial_owner3.home.postal_code == null ||
          this.beneficial_owner3.home.postal_code.length == 0) &&
        (this.beneficial_owner3.ssn == null ||
          this.beneficial_owner3.ssn.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isOwner4Empty() {
      if (
        (this.beneficial_owner4.first_name == null ||
          this.beneficial_owner4.first_name.length == 0) &&
        (this.beneficial_owner4.last_name == null ||
          this.beneficial_owner4.last_name.length == 0) &&
        (this.beneficial_owner4.home.address1 == null ||
          this.beneficial_owner4.home.address1.length == 0) &&
        (this.beneficial_owner4.home.address2 == null ||
          this.beneficial_owner4.home.address2.length == 0) &&
        (this.beneficial_owner4.home.city == null ||
          this.beneficial_owner4.home.city.length == 0) &&
        (this.beneficial_owner4.home.postal_code == null ||
          this.beneficial_owner4.home.postal_code.length == 0) &&
        (this.beneficial_owner4.ssn == null ||
          this.beneficial_owner4.ssn.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    disableNextButton() {
      return (
        (this.currentSlide == 0 && !this.validForm1) ||
        (this.currentSlide == 1 && !this.validForm2) ||
        (this.currentSlide == 2 && !this.validForm3) ||
        (this.currentSlide == 3 &&
          (!this.validForm4 || this.beneficialOwnerToggle === null)) ||
        (this.currentSlide == 4 &&
          (this.beneficialOwnerToggle === null ||
            (this.beneficialOwnerToggle === true &&
              ((!this.validForm5 && !this.isOwner1Empty) ||
                (!this.validForm6 && !this.isOwner2Empty) ||
                (!this.validForm7 && !this.isOwner3Empty) ||
                (!this.validForm8 &&
                  !this.proprietor_is_beneficial_owner &&
                  !this.isOwner4Empty))))) ||
        (this.currentSlide == 5 &&
          (!this.validForm9 || !this.attestation_consent))
      );
    },
    eighteenYearsFromToday() {
      return moment()
        .local()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    },
    displayTestingButtons() {
      return !!(
        // this.userProfile &&
        // this.userProfile.clientId == 1 &&
        (process.env.VUE_APP_ENVIRONMENT == "dev")
      );
    }
  },
  watch: {
    establishedDateMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.establishedDatePicker.activePicker = "YEAR";
        });
    },
    officerBirthdayMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.officerBirthdayPicker.activePicker = "YEAR";
        });
    },
    owner1BirthdayMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.owner1BirthdayPicker.activePicker = "YEAR";
        });
    },
    owner2BirthdayMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.owner2BirthdayPicker.activePicker = "YEAR";
        });
    },
    owner3BirthdayMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.owner3BirthdayPicker.activePicker = "YEAR";
        });
    },
    owner4BirthdayMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.owner4BirthdayPicker.activePicker = "YEAR";
        });
    },
    attestationDateMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.attestationDatePicker.activePicker = "YEAR";
        });
    },
    displayWalletMenu(val) {
      if (val && this.walletMenuScreen == "business") {
        this.resetForm(false, false);
      }
    }
  }
};
</script>

<style scoped>
.title-text {
  font-size: 1.5em;
}
.card-text {
  font-size: 1.2em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.progress-bar {
  width: calc(50% - 60px);
}
</style>

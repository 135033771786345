<template>
  <div class="full-height whistle-gradient-45">
    <div class="d-flex flex-column justify-space-between full-height container">
      <v-row justify="center" align="center" class="full-width" no-gutters
        ><v-col
          cols="12"
          sm="6"
          md="5"
          lg="5"
          xl="5"
          class="d-flex flex-column align-center justify-center white-background rounded"
        >
          <!-- <v-container class="d-flex flex-column align-center justify-center body"> -->
          <v-row
            class="py-6 word-break px-6 flex-column align-center text-center"
          >
            <!-- <v-card rounded="0" width="100%" class="mt-10 py-3 px-5 card-height"> -->
            <v-container v-if="!showSuccess">
              <v-row class="d-flex flex-column align-center" justify="center">
                <v-img
                  class="logo mt-4 mb-6"
                  alt="Whistle logo"
                  src="../assets/Logo/whistle-logo-gradient-clean-v2.png"
                  contain
                />
                <span class="header-text grey--text">
                  Would you like to unsubscribe from Whistle emails?
                </span>
                <v-form
                  v-model="emailValid"
                  onSubmit="return false;"
                  class="mt-10 form"
                >
                  <v-text-field
                    v-model="emailAddress"
                    label="Email Address"
                    :rules="[
                      (v) => (v && emailRegex.test(v)) || 'Email must be valid',
                    ]"
                    outlined
                    @keyup.enter="
                      if (emailValid) {
                        validateEmail();
                      } else {
                        () => {};
                      }
                    "
                  ></v-text-field>
                </v-form>
                <span class="red--text" v-if="showEmailError"
                  >Whoops! We couldn't find this email in our system.</span
                >
                <v-btn
                  class="mt-5 white--text"
                  color="brandCyan"
                  depressed
                  @click="validateEmail"
                  :disabled="!emailValid"
                  :loading="waitingForEmail"
                  >Submit</v-btn
                >
                <p class="grey--text mt-12 mb-6 mx-4">
                  We're sad to see you go! You can always log into Whistle to
                  view your profile and subscribe again.
                </p>
              </v-row>
            </v-container>
            <v-container
              v-else
              class="d-flex flex-column align-center justify-space-between px-8 pb-8 full-height"
            >
              <div class="d-flex flex-column align-center">
                <v-img
                  class="logo mt-4 mb-6"
                  alt="Whistle logo"
                  src="../assets/Logo/whistle-logo-gradient-clean-v2.png"
                  contain
                />
                <p class="text-h5 grey--text mt-5">
                  You're all set!
                </p>
                <v-icon
                  class="my-10 pa-3 brand-green-background circle-border-radius"
                  color="white"
                  x-large
                  >mdi-check</v-icon
                >
              </div>

              <v-btn color="brandCyan" class="white--text" to="/" depressed
                >Go Home</v-btn
              >
            </v-container>
            <!-- </v-card> -->
          </v-row>

          <!-- <v-img
          class="logo my-4"
          alt="Whistle logo"
          src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
          contain
        /> -->
          <!-- </v-container> -->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserService from "@/services/UserService";

import { emailRegex } from "@/shared_data/data.js";

export default {
  name: "Unsubscribe",
  title: "Whistle | Unsubscribe",
  components: {},
  data() {
    return {
      keyStep: 1,
      showSuccess: false,
      emailValid: false,
      emailAddress: null,
      waitingForEmail: false,
      showEmailError: false,
      emailRegex,
    };
  },
  created() {
    if (this.$auth) {
      this.emailAddress = this.userProfile.businessEmail;
    }
  },
  methods: {
    validateEmail() {
      this.waitingForEmail = true;
      UserService.unsubscribeFromCommPreference(
        "email",
        this.emailAddress
      ).then((response) => {
        this.waitingForEmail = false;
        console.log(response);
        if (response.noMatch !== undefined) {
          console.log("User entered email that was not found in the database.");
          this.showEmailError = true;
        } else {
          this.showSuccess = true;
        }
      });
    },
  },
  computed: {
    ...mapState(["userProfile"]),
  },
};
</script>

<style scoped>
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.body {
  height: 100%; /* or any height you want */
  overflow-y: auto;
}
.logo-text {
  color: var(--v-brand-base);
}

.slide-leave-active {
  transition: 0.4s;
}
.slide-enter-active {
  transition: 0.6s;
}
.slide-enter {
  transform: translate(200%, 0);
}
.slide-leave-to {
  transform: translate(-200%, 0);
}

.show-enter-active,
.show-leave-enter {
  transform: translateX(0);
  transition: all 0.5s linear;
}
.show-enter,
.show-leave-to {
  transform: translateX(100%);
}

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.blue-btn {
  background-color: var(--v-accent-base);
}
.grey-btn {
  background-color: var(--v-lightGrey-base);
}
.header-text {
  font-size: x-large;
}
.footer-text {
  font-size: small;
}
.form {
  width: 75%;
}
.slider {
  width: 90%;
}
@media (max-width: 400px) {
  .logo {
    width: 100px;
    height: auto;
  }
}
@media (min-width: 401px) and (max-width: 1024px) {
  .logo {
    width: 100px;
    height: auto;
  }
}
@media (min-width: 1025px) and (max-width: 2000px) {
  .logo {
    width: 150px;
    height: auto;
  }
}
@media (min-width: 2001px) {
  .logo {
    width: 250px;
    height: auto;
  }
}
.bottom-logo {
  position: absolute;
  bottom: 10px;
}

@media (max-width: 1439px) {
  .robin {
    width: 120px;
    height: auto;
  }
  .text {
    font-size: large;
  }
}
@media (min-width: 1440px) and (max-width: 2000px) {
  .robin {
    width: 200px;
    height: auto;
  }
  .text {
    font-size: x-large;
  }
}
@media (min-width: 2001px) {
  .robin {
    width: 250px;
    height: auto;
  }
  .text {
    font-size: xx-large;
  }
}
.loading-screen-content {
  margin-top: 15vh;
}

.v-stepper__header {
  background-color: none;
}

.card-height {
  min-height: 450px;
  height: calc(100% - 100px);
}
</style>

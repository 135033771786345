<template>
  <div>
    <v-card elevation="0" rounded="0">
      <v-toolbar dark flat color="brandCyan" rounded="0" width="100%">
        <div>
          <v-toolbar-title>Create a progress metric</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="close">
            <span class="mt-1">Cancel</span>
          </v-btn>
          <v-btn
            v-if="editingMetric"
            dark
            text
            :disabled="!validForm"
            @click="updateMetric(false)"
          >
            <span class="mt-1">Update Metric</span>
          </v-btn>
          <v-btn dark text :disabled="!isValid" @click="createNewMetric">
            <span class="mt-1">Add Metric</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-row no-gutters justify="center" full-width no-wrap class="mb-2">
        <v-col cols="10" class="d-flex flex-column text-left mx-0 my-0">
          <!-- <v-form v-model="metric.valid"> -->
          <!-- <p class="mb-0 mt-6 mediumGrey--text text-body-2">
            Note: per our platform terms, metrics that measure sales are not
            allowed. If you create a sales based metric, it will be removed from
            the platform.
          </p> -->
          <!-- We could use this an manually focus it when clicking add metric if false -->
          <!-- <v-checkbox
            class="ml-0 mr-auto mt-0 mb-0"
            v-model="metric.agreement"
            :rules="[rules.agree]"
            label="I understand"
            :style="{ 'width': '100%' }"
          > -->
          <!-- This checkbox starts off in an invalid state to draw attention to the agreement -->
          <!-- <v-checkbox
            class="ml-0 mr-auto mt-0 mb-0"
            v-model="metric.agreement"
            hide-details
            :rules="[rules.agree]"
          >
            <template v-slot:label>
              <label class="my-auto mediumGrey--text text-body-2"
                >I understand</label
              >
            </template>
          </v-checkbox>
          <div
            :style="metric.agreement === true ? { visibility: 'hidden' } : {}"
            class="mt-1 px-3 error--text mb-4 v-messages__message v-messages"
          >
            Acknowledge that you understand the metric creation terms
          </div> -->
          <v-form v-model="validForm" class="mt-6">
            <v-autocomplete
              v-if="userProfile.clientId == 1 && !metric.clientId"
              v-model="metric.clientId"
              :items="clients"
              item-text="formattedName"
              item-value="clientId"
              label="Client*"
              class="mt-1"
              outlined
              attach
              :menu-props="{ offsetY: true }"
            ></v-autocomplete>
            <v-text-field
              v-model="metric.metricName"
              outlined
              label="Metric Name"
              :placeholder="computedNamePlaceholder"
              :rules="[rules.empty, rules.longString]"
              class="mt-1"
              persistent-placeholder
            ></v-text-field>
            <v-textarea
              v-model="metric.description"
              outlined
              label="Description"
              :placeholder="computedDescriptionPlaceholder"
              :rules="[rules.extraLongString]"
              persistent-placeholder
            >
            </v-textarea>
            <v-select
              v-model="metric.dataType"
              outlined
              label="Metric Type"
              :items="dataTypes"
              item-value="value"
              item-text="label"
              :disabled="editingMetric"
            ></v-select>
            <v-text-field
              v-model="metric.unitPlural"
              outlined
              label="What are we measuring? (e.g. miles, calls, high-fives)"
              :placeholder="computedUnitPlaceholder"
              :rules="[rules.longString]"
              persistent-placeholder
            ></v-text-field>
            <v-text-field
              v-model="metric.verb"
              outlined
              :placeholder="computedVerbPlaceholder"
              :rules="[rules.longString]"
              persistent-placeholder
            >
              <template v-slot:label>
                <label class="v-label"
                  >Verb when used in a sentence (e.g. You've <b>biked</b> 2 of 5
                  miles)</label
                >
              </template>
            </v-text-field>
            <div v-if="displayOperatorSelect">
              <v-select
                v-model="metric.defaultOperator"
                outlined
                label="Default Operator"
                :items="computedOperators"
                item-value="value"
                item-text="label"
                class="mb-1"
                hide-details
                :disabled="editingMetric"
              ></v-select>
              <p class="text-caption mediumGrey--text my-0 ml-3">
                E.g miles biked must be <b>greater than or equal to</b> 5 to be
                "achieved"
              </p>
            </div>
            <v-switch
              label="Participant can self report"
              v-model="metric.selfAssess"
              :hide-details="true"
              class="mt-5"
            ></v-switch>
            <!-- </v-form> -->
            <div class="d-flex justify-center mt-5 mb-3 text-caption">
              <v-btn
                text
                color="primary"
                class="mx-auto text-body-1 text-none"
                @click="previewMetric"
                :disabled="!metric.metricName || metric.metricName.trim() == ''"
              >
                Preview what this metric will look like in a program</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
      <div class="mt-2 mx-12 text-left"></div>
    </v-card>
    <v-dialog v-model="showPreviewDialog" persistent width="500">
      <v-card
        rounded="0"
        class="d-flex justify-center flex-column pa-6 text-left"
      >
        <v-btn
          fab
          dark
          small
          color="brand"
          class="ml-auto mr-0 floating-button"
          @click="showPreviewDialog = false"
          ><v-icon dark>mdi-close</v-icon></v-btn
        >
        <ProgramDetailsMetricCard
          :currentCard="previewCard"
          v-if="showPreviewDialog"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="displayUniversalWarning" persistent width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <p class="word-break align-text-left exit-warning-text">
            It looks like you're trying to edit a universal metric.
            <br />
            Changes will be reflected across all programs using this metric, is
            that okay?
          </p>
        </div>
        <!-- <Robin
          :showText="false"
          :width="160"
          :height="160"
          animation="idleBlink"
          :loop="true"
          class="mt-2 mx-auto"
        /> -->
        <!-- </div> -->

        <v-card-actions
          class="mx-12"
          :class="isMobile ? 'd-flex flex-column' : ''"
        >
          <v-btn color="primary" @click="close" outlined width="130"
            >Go Back</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            @click="updateMetric(true)"
            width="130"
            :class="isMobile ? 'mt-4' : ''"
            >Continue</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLoadingDialog" persistent width="500">
      <v-card
        v-if="!displayError"
        rounded="0"
        class="d-flex justify-center flex-column pa-6"
      >
        <div class="full-width">
          <span class="header-text word-break font-weight-bold mb-5">{{
            preloadedMetric != null
              ? "Updating your metric"
              : "Creating your metric"
          }}</span>
        </div>
        <Robin
          :showText="false"
          :width="160"
          :height="160"
          animation="inflatableDance"
          :loop="true"
          class="mt-2 mx-auto"
        />
        <!-- </div> -->
      </v-card>
      <v-card v-else rounded="0" class="d-flex justify-center flex-column pa-6">
        <span class="header-text word-break font-weight-bold mb-5"
          >Looks like we had some trouble
          {{ preloadedMetric != null ? " updating" : " creating" }}
          your metric. Please try again and see if that solves the
          problem.</span
        >
        <p class="full-width">
          If this continues, feel free to reach out to customer service via one
          of the following methods...
        </p>
        <p class="pl-4 full-width">
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p class="pl-4 full-width">Phone (Toll Free) - <b>(855) 264-3329</b></p>
        <v-btn
          depressed
          color="primary"
          dark
          class="my-5 mx-auto"
          @click="
            showLoadingDialog = false;
            displayError = false;
          "
          >Go Back</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService";

import Robin from "@/components/Robin";

import ProgramDetailsMetricCard from "@/components/ProgramDetailsMetricCard.vue";

import { mapState } from "vuex";
import moment from "moment";

function initialState() {
  return {
    // clientId: null,
    validForm: true,
    metricId: null,
    metric: {
      clientId: null,
      // agreement: false,
      metricName: null,
      description: null,
      dataType: "INTEGER",
      // "STRING"
      // "INTEGER"
      // "DECIMAL"
      // "BOOLEAN"
      // Not supporting "DATE" or "TIMESTAMP" for now
      unitPlural: null,
      verb: null,
      defaultOperator: "GREATER_THAN_EQUAL",
      // "EQUAL"
      // "NOT_EQUAL"
      // "GREATER_THAN"
      // "GREATER_THAN_EQUAL"
      // "LESS_THAN"
      // "LESS_THAN_EQUAL"
      selfAssess: false
    },
    dataTypes: [
      {
        value: "INTEGER",
        label: "Whole Number"
      },
      {
        value: "DECIMAL",
        label: "Number"
      },
      {
        value: "STRING",
        label: "Text"
      },
      {
        value: "BOOLEAN",
        label: "Checkbox"
      }
    ],
    operators: [
      {
        value: "EQUAL",
        label: "Equal to"
      },
      {
        value: "NOT_EQUAL",
        label: "Not equal to"
      },
      {
        value: "GREATER_THAN",
        label: "Greater than"
      },
      {
        value: "GREATER_THAN_EQUAL",
        label: "Greater than or equal to"
      },
      {
        value: "LESS_THAN",
        label: "Less than"
      },
      {
        value: "LESS_THAN_EQUAL",
        label: "Less than or equal to"
      }
    ],
    rules: {
      integer: value =>
        !value ||
        (value && Number.isInteger(Number(value))) ||
        "Must be whole number",
      empty: value => (value && value.trim() !== "") || "A value is required",
      longString: value =>
        !value || (value && value.length < 256) || "Value is too long",
      extraLongString: value =>
        !value || (value && value.length < 65535) || "Value is too long",
      agree: value =>
        (value && value == true) ||
        "Acknowledge that you understand the metric creation terms"
    },
    showLoadingDialog: false,
    displayError: false,
    displayUniversalWarning: false,
    showPreviewDialog: false,
    previewCard: null
  };
}

export default {
  name: "MetricCreatorWidget",
  components: {
    // LoadingDialog,
    Robin,
    ProgramDetailsMetricCard
  },
  props: {
    clientId: Number,
    preloadedMetric: Object
  },
  data() {
    return initialState();
  },

  created() {
    console.log("Preloaded metric");
    console.log(this.preloadedMetric);
    if (this.preloadedMetric != null) {
      console.log("Preloading metric");
      this.metric = {
        clientId: this.preloadedMetric.clientId,
        // agreement: this.preloadedMetric.agreement,
        metricName: this.preloadedMetric.metricName,
        description: this.preloadedMetric.description,
        dataType: this.preloadedMetric.dataType
          ? this.preloadedMetric.dataType
          : "INTEGER",
        // "STRING"
        // "INTEGER"
        // "DECIMAL"
        // "BOOLEAN"
        // Not supporting "DATE" or "TIMESTAMP" for now
        unitPlural: this.preloadedMetric.unitPlural,
        verb: this.preloadedMetric.verb,
        defaultOperator: this.preloadedMetric.defaultOperator
          ? this.preloadedMetric.defaultOperator
          : "GREATER_THAN_EQUAL",
        // "EQUAL"
        // "NOT_EQUAL"
        // "GREATER_THAN"
        // "GREATER_THAN_EQUAL"
        // "LESS_THAN"
        // "LESS_THAN_EQUAL"
        selfAssess:
          this.preloadedMetric.selfAssess != null
            ? this.preloadedMetric.selfAssess
            : false
      };
      // this.clientId = this.preloadedMetric.clientId;
      this.metricId = this.preloadedMetric.metricId;
    } else {
      if (this.clientId !== undefined && this.clientId !== null)
        this.metric.clientId = this.clientId;
    }
  },
  mounted() {
    console.log("Mounted");
  },
  methods: {
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    close() {
      this.resetAllData();
      this.$emit("close");
    },
    previewMetric() {
      console.log("previewing metric");
      console.log(this.metric);
      let goalLabel =
        // metric.dataType == "STRING"
        //  ? `<p>${rule.displayName} should ${
        //    rule.operator == "NOT_EQUAL"
        //  ? "not be equal to"
        //  : "be equal to"
        //    } <b>${rule.value}</b></p>`
        //  :
        `You've
        ${this.metric.verb ? this.metric.verb.toLowerCase() : "entered"}
        <b>0</b> out
        of
        <b>5</b>
        ${this.metric.unitPlural || "units"}`;

      console.log("goal label");
      console.log(goalLabel);

      let str =
        this.metric.dataType == "STRING"
          ? this.metric.metricName
          : "Total " +
            (this.metric.unitPlural
              ? this.metric.unitPlural.toLowerCase()
              : "units") +
            " " +
            (this.metric.verb ? this.metric.verb.toLowerCase() : "entered");

      console.log("string");
      console.log(str);

      let card = {
        MetricDefinition: {
          dataType: this.metric.dataType,
          defaultOperator: this.metric.defaultOperator,
          description: this.metric.description,
          metricId: null,
          metricName: this.metric.metricName,
          prefixSymbol: null,
          selfAssess: true,
          status: "Preview",
          type: "PROGRAM_LEVEL",
          unitPlural: ["INTEGER", "DECIMAL"].includes(this.metric.dataType)
            ? this.metric.unitPlural
            : null,
          unitSingular: this.metric.unitSingular,
          uploadType: "CUMULATIVE",
          verb: this.metric.verb
        },
        displayName: this.metric.metricName,
        goalLabel: ["INTEGER", "DECIMAL"].includes(this.metric.dataType)
          ? goalLabel
          : "Enter your progress",
        inputLabel:
          str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase(),
        operator: this.metric.defaultOperator,
        progressPercent: 0,
        progressString: "0 of 5",
        progressValue: null,
        ruleId: null,
        value: 5
      };
      this.previewCard = card;
      this.showPreviewDialog = true;
    },
    formatMetric(metric) {
      metric.id = metric.metricId
        ? metric.metricId.toString()
        : this.preloadedMetric != null
        ? this.preloadedMetric.metricId.toString()
        : null;
      metric.displayName = metric.metricName
        ? metric.metricName
        : "Untitled progress metric";
      metric.calculateProgress = ["INTEGER", "DECIMAL"].includes(
        this.metric.dataType
      )
        ? true
        : false;
      metric.context = "MetricDefinitions";
      metric.contextResourceId = metric.metricId
        ? metric.metricId.toString()
        : this.preloadedMetric != null
        ? this.preloadedMetric.metricId.toString()
        : null;
      metric.contextColumn = "metricValue";
      metric.dataType = this.metric.dataType;
      metric.defaultOperator = this.metric.defaultOperator;
      metric.label = metric.metricName;
      metric.showNumberOptions =
        this.metric.calculateProgress && this.metric.dataType !== "BOOLEAN";
      metric.icon =
        this.metric.dataType == "BOOLEAN"
          ? "mdi-checkbox-marked-outline"
          : "mdi-finance";
      metric.iconColor = "brand-cyan";
      metric.displayGrabber = true;
      metric.showNumberOptions =
        metric.calculateProgress && this.metric.dataType !== "BOOLEAN";
      console.log("Formatted metric");
      console.log(metric);
      return metric;
    },
    async createNewMetric() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating Program Metric";
      this.loadingMessageLine1 = "Creating the metric.";
      this.loadingMessageLine2 = "This shouldn't take long.";
      var metric = {
        clientId: this.clientId,
        metricName: this.metric.metricName,
        description: this.metric.description,
        unitPlural: this.metric.unitPlural,
        verb: this.metric.verb,
        type: "PROGRAM_LEVEL",
        status: "Active",
        calculateProgress: ["INTEGER", "DECIMAL"].includes(this.metric.dataType)
          ? true
          : false,
        uploadType: "CUMULATIVE",
        dataType: this.metric.dataType,
        defaultOperator: ["INTEGER", "DECIMAL"].includes(this.metric.dataType)
          ? this.metric.defaultOperator
          : "EQUAL",
        selfAssess: this.metric.selfAssess
      };
      console.log("Creating metric");
      console.log(metric);
      const startTime = new Date();
      let metricRes = await ProgramService.createMetric(metric).catch(error => {
        let timeDiff = moment().diff(moment(startTime));
        // if time diff is longer than 1500, set it to 1500
        if (timeDiff >= 1500) {
          timeDiff = 1500;
        }
        setTimeout(() => {
          console.log("error creating metric");
          console.log(error);
          this.displayError = true;
        }, 1500 - timeDiff);
      });
      let timeDiff = moment().diff(moment(startTime));
      // if time diff is longer than 1500, set it to 1500
      if (timeDiff >= 1500) {
        timeDiff = 1500;
      }
      setTimeout(() => {
        console.log("Created metric");
        console.log(metricRes);
        if (!metricRes.metricId) {
          this.displayError = true;
        } else {
          let formattedMetric = this.formatMetric(metricRes);
          this.$emit("get-metrics", formattedMetric, false);
          this.showLoadingDialog = false;
          this.close();
        }
      }, 1500 - timeDiff);
    },
    async updateMetric(override = false) {
      console.log("updating metric, override", override);
      // If this.clientId (preloaded metric's client id) is 0, pop up warning
      if (this.clientId == 0 && override == false) {
        this.displayUniversalWarning = true;
        return;
      } else {
        this.displayUniversalWarning = false;
        this.showLoadingDialog = true;
        var metric = {
          // Don't want to update anything besides the display properties (no logic)
          metricName: this.metric.metricName,
          description: this.metric.description,
          unitPlural: this.metric.unitPlural,
          verb: this.metric.verb,
          status: "Active",
          selfAssess: this.metric.selfAssess,
          clientId: this.preloadedMetric.clientId
        };
        console.log("Updating metric");
        const startTime = new Date();
        let metricRes = await ProgramService.updateMetric(
          this.metricId,
          metric
        ).catch(error => {
          let timeDiff = moment().diff(moment(startTime));
          // if time diff is longer than 1500, set it to 1500
          if (timeDiff >= 1500) {
            timeDiff = 1500;
          }
          setTimeout(() => {
            console.log("error updating metric");
            console.log(error);
            this.displayError = true;
          }, 1500 - timeDiff);
        });
        let timeDiff = moment().diff(moment(startTime));
        // if time diff is longer than 1500, set it to 1500
        if (timeDiff >= 1500) {
          timeDiff = 1500;
        }
        setTimeout(() => {
          console.log("updated metric");
          console.log(metricRes);
          if (!metricRes.success) {
            this.displayError = true;
          } else {
            let formattedMetric = this.formatMetric(metric);
            this.$emit("get-metrics", formattedMetric, true);
            this.showLoadingDialog = false;
            this.close();
          }
        }, 1500 - timeDiff);
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    displayOperatorSelect() {
      if (["INTEGER", "DECIMAL", "STRING"].includes(this.metric.dataType)) {
        return true;
      } else {
        return false;
      }
    },
    // displayAgreementWarning() {
    //   if (this.metric.agreement != true) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    isValid() {
      let valid = false;
      if (
        // this.metric.agreement == true &&
        this.metric.metricName &&
        this.metric.metricName.trim() != ""
      ) {
        valid = true;
      }
      return valid;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    computedNamePlaceholder() {
      switch (this.metric.dataType) {
        case "STRING":
          return "Team Member Lunch";
        case "BOOLEAN":
          return "Completed Form I-9";
        case "INTEGER":
        case "DECIMAL":
          return "Miles Biked";
        default:
          return "Miles Biked";
      }
    },
    computedDescriptionPlaceholder() {
      switch (this.metric.dataType) {
        case "STRING":
          return "The name of the team member you took to lunch";
        case "BOOLEAN":
          return "Provided proof of eligability documents to manager";
        case "INTEGER":
        case "DECIMAL":
          return "This can be miles biked outdoors or on a stationary bike. Motorcycles don't count!";
        default:
          return "This can be miles biked outdoors or on a stationary bike. Motorcycles don't count!";
      }
    },
    computedUnitPlaceholder() {
      switch (this.metric.dataType) {
        case "STRING":
          return "team member";
        case "BOOLEAN":
          return "verification";
        case "INTEGER":
        case "DECIMAL":
          return "miles";
        default:
          return "miles";
      }
    },
    computedVerbPlaceholder() {
      switch (this.metric.dataType) {
        case "STRING":
          return "taken";
        case "BOOLEAN":
          return "completed";
        case "INTEGER":
        case "DECIMAL":
          return "biked";
        default:
          return "biked";
      }
    },
    computedOperators() {
      if (this.metric.dataType == "STRING") {
        return [
          {
            value: "EQUAL",
            label: "Equal to"
          },
          {
            value: "NOT_EQUAL",
            label: "Not equal to"
          }
        ];
      } else {
        return [
          {
            value: "EQUAL",
            label: "Equal to"
          },
          {
            value: "NOT_EQUAL",
            label: "Not equal to"
          },
          {
            value: "GREATER_THAN",
            label: "Greater than"
          },
          {
            value: "GREATER_THAN_EQUAL",
            label: "Greater than or equal to"
          },
          {
            value: "LESS_THAN",
            label: "Less than"
          },
          {
            value: "LESS_THAN_EQUAL",
            label: "Less than or equal to"
          }
        ];
      }
    },
    editingMetric() {
      if (this.preloadedMetric != null) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    // Watches dataType, if it gets changed but the operator doesn't we default it to equal
    "metric.dataType"(newValue) {
      if (newValue == "STRING") {
        if (
          this.metric.defaultOperator != "EQUAL" &&
          this.metric.defaultOperator != "NOT_EQUAL"
        ) {
          this.metric.defaultOperator = "EQUAL";
        }
      }
    }
  }
};
</script>

<style scoped>
.input-box {
  width: 60%;
  max-width: 300px;
}

/* Used to cap the height of the main content card so it scrolls after */
.main-content-card {
  overflow-y: scroll;
  min-height: 500px;
  max-height: 80vh;
}
/* For metric preview card */
.input-field {
  min-width: 190px;
  width: 50%;
  max-width: 425px;
}

.floating-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

.header-text {
  font-size: x-large;
}
</style>

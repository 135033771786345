var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-width"},[(_vm.screen == 'home')?_c('v-toolbar',{attrs:{"dark":"","color":"brandCyan","elevation":"0","rounded":"0"}},[_c('v-toolbar-title',[_vm._v("Add funds to your account ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.reset(false)}}},[_vm._v(" Close "),_c('v-icon',{staticClass:"ml-2 mb-1"},[_vm._v("mdi-close-circle-outline")])],1)],1)],1):_vm._e(),(_vm.screen == 'home')?_c('div',{staticClass:"full-width px-3"},[(_vm.displayBudgetTransfer && _vm.visibleBudgets.length > 0)?_c('v-card',{staticClass:"my-3 py-3 px-2 funding-card",attrs:{"width":"100%","ripple":false,"elevation":"0"},on:{"click":function($event){_vm.screen = 'budget-transfer'}}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"start"}},[_c('v-col',{attrs:{"cols":"2","sm":"1","md":"1","lg":"1","xl":"1"}},[_c('v-icon',{staticClass:"mx-2 border-radius wallet-icon brand-cyan-background"},[_vm._v("mdi-swap-horizontal")])],1),_c('v-col',{staticClass:"text-left px-3",attrs:{"cols":"10","sm":"11","md":"11","lg":"11","xl":"11"}},[_c('p',{staticClass:"mt-1 mb-2"},[_c('strong',[_vm._v("Transfer from a different budget")])]),_vm._v(" Instant "),_c('br'),_vm._v("No fee")])],1)],1):_vm._e(),(
        _vm.computedFundingLimit &&
          (!_vm.computedFundingLimit.canFundMore ||
            _vm.computedFundingLimit.cooldownActive) &&
          _vm.destinationBudget &&
          _vm.destinationBudget.awardId == 1
      )?_c('h4',{staticClass:"text-left ml-2",class:{ 'mt-4': !(_vm.displayBudgetTransfer && _vm.visibleBudgets.length) }},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-alert")]),_vm._v(" Why can't I fund more right now? ")],1):_vm._e(),(
        _vm.computedFundingLimit &&
          _vm.computedFundingLimit.cooldownActive &&
          _vm.destinationBudget &&
          _vm.destinationBudget.awardId == 1
      )?_c('p',{staticClass:"mt-2 ml-2 text-left"},[_vm._v(" The maximum number of funding attempts in a short timespan has been reached. Please try again "+_vm._s(_vm.computedFundingLimit.cooldownExpirationLocal ? `at any point after ${_vm.computedFundingLimit.cooldownExpirationLocal}` : "later")+". If you have questions, please contact us at "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"mailto:help@wewhistle.com"}},[_vm._v("help@wewhistle.com")]),_vm._v(". ")]):(
        _vm.computedFundingLimit &&
          !_vm.computedFundingLimit.canFundMore &&
          _vm.destinationBudget &&
          _vm.destinationBudget.awardId == 1
      )?_c('p',{staticClass:"mt-2 ml-2 text-left"},[_vm._v(" Whistle's banking partners require a business verification process to be completed before adding more funds to your budgets. This process can be completed on the "),_c('span',{class:{
          'primary--text': _vm.canCompleteKYB,
          'cursor-pointer': _vm.canCompleteKYB
        },on:{"click":_vm.loadKYB}},[_vm._v("Payment Admin page")]),_vm._v(" by a Whistle Finance Admin ")]):_vm._e(),(_vm.destinationBudget && _vm.destinationBudget.awardId == 1)?_c('v-card',{staticClass:"my-3 py-3 px-2 funding-card",attrs:{"width":"100%","ripple":false,"elevation":"0","disabled":_vm.computedFundingLimit &&
          (!_vm.computedFundingLimit.canFundMore ||
            _vm.computedFundingLimit.cooldownActive)},on:{"click":function($event){_vm.screen = 'checkout'}}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"start"}},[_c('v-col',{attrs:{"cols":"2","sm":"1","md":"1","lg":"1","xl":"1"}},[_c('v-icon',{staticClass:"mx-2 border-radius wallet-icon brand-cyan-background"},[_vm._v("mdi-credit-card")])],1),_c('v-col',{staticClass:"text-left px-3",attrs:{"cols":"10","sm":"11","md":"11","lg":"11","xl":"11"}},[_c('p',{staticClass:"mt-1 mb-2"},[_c('strong',[_vm._v("Credit card transfer")])]),_vm._v(" Instant "),_c('br'),_vm._v("2.9% + 30 cent fee")])],1)],1):_vm._e(),(_vm.destinationBudget && _vm.destinationBudget.awardId == 1)?_c('v-card',{staticClass:"my-3 py-3 px-2 funding-card",attrs:{"width":"100%","ripple":false,"elevation":"0","disabled":_vm.computedFundingLimit &&
          (!_vm.computedFundingLimit.canFundMore ||
            _vm.computedFundingLimit.cooldownActive)},on:{"click":function($event){_vm.screen = 'ach'}}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"start"}},[_c('v-col',{attrs:{"cols":"2","sm":"1","md":"1","lg":"1","xl":"1"}},[_c('v-icon',{staticClass:"mx-2 border-radius wallet-icon brand-cyan-background"},[_vm._v("mdi-bank")])],1),_c('v-col',{staticClass:"text-left px-3",attrs:{"cols":"10","sm":"11","md":"11","lg":"11","xl":"11"}},[_c('p',{staticClass:"mt-1 mb-2"},[_c('strong',[_vm._v("ACH bank transfer")])]),_vm._v(" 3 to 5 business days "),_c('br'),_vm._v("No fee")])],1)],1):_vm._e(),(
        _vm.destinationBudget &&
          ((_vm.userProfile.clientId == 1 &&
            _vm.permissions.includes('wallets:manualfund:budget')) ||
            _vm.destinationBudget.awardId != 1)
      )?_c('v-card',{staticClass:"my-3 py-3 px-2 white-card-background funding-card",attrs:{"width":"100%","ripple":false,"elevation":"0"},on:{"click":function($event){_vm.showBudgetFundMenu = true}}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"start"}},[_c('v-col',{attrs:{"cols":"2","sm":"1","md":"1","lg":"1","xl":"1"}},[_c('v-icon',{staticClass:"mx-2 border-radius wallet-icon brand-cyan-background"},[_vm._v("mdi-cash-refund")])],1),_c('v-col',{staticClass:"text-left px-3",attrs:{"cols":"10","sm":"11","md":"11","lg":"11","xl":"11"}},[_c('p',{staticClass:"mt-1 mb-2"},[_c('strong',[_vm._v("Manually fund "+_vm._s(_vm.destinationBudget.awardId == 1 ? "budget" : "non-cash budget"))])]),_vm._v(" Instant "),_c('br'),_vm._v("No fee")])],1),(_vm.showBudgetFundMenu)?_c('v-row',{staticClass:"text-left pl-3"},[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_c('v-form',{attrs:{"onSubmit":"return false;"},model:{value:(_vm.fundForm),callback:function ($$v) {_vm.fundForm=$$v},expression:"fundForm"}},[_c('v-text-field',{staticClass:"mt-6",attrs:{"label":_vm.destinationBudget.awardId == 1
                  ? 'Amount to transfer (in $)'
                  : 'Amount to transfer',"type":"number","color":"brandCyan","outlined":"","rules":[
                v => !!v || 'Amount is required',
                v =>
                  !!(v && parseFloat(v) >= 0) ||
                  (_vm.destinationBudget.awardId == 1
                    ? 'Amount should be greater than $0'
                    : 'Amount should be greater than 0'),
                v =>
                  !!(
                    v &&
                    (!v.toString().includes('.') ||
                      (_vm.destinationBudget.awardId == 1 &&
                        v.toString().includes('.') &&
                        v.substring(v.indexOf('.') + 1).length < 3))
                  ) ||
                  (_vm.destinationBudget.awardId == 1
                    ? `Payment amount shouldn't be longer than 2 decimals.`
                    : 'Payment amount should be a whole number'),
                v =>
                  !!(v && parseFloat(v) <= 999999.99) ||
                  'Amount should be smaller'
              ]},on:{"wheel":function($event){return $event.target.blur()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fundBudget.apply(null, arguments)}},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mb-5 mt-6 white--text",attrs:{"color":"brandCyan","depressed":"","width":"140","disabled":!_vm.fundForm || _vm.budgetFundingLoading,"loading":_vm.budgetFundingLoading},on:{"click":_vm.fundBudget}},[_vm._v("Fund")])],1)],1)],1):_vm._e()],1):_vm._e()],1):(_vm.screen == 'ach')?_c('StripeManageACH',{attrs:{"budget":_vm.destinationBudget,"preloadMenu":_vm.preloadMenu,"fundingLimit":_vm.computedFundingLimit,"canCompleteKYB":_vm.canCompleteKYB,"source":_vm.source},on:{"reset":function($event){if ($event == 'reset') _vm.reset(true);
      else _vm.screen = 'home';},"load-budget-activity":_vm.loadBudgetActivity,"get-budgets":_vm.getBudgets,"get-funding-limit":_vm.refreshFundingLimit}}):(_vm.screen == 'checkout')?_c('StripeManageCC',{attrs:{"budget":_vm.destinationBudget,"fundingLimit":_vm.computedFundingLimit,"canCompleteKYB":_vm.canCompleteKYB,"source":_vm.source,"displayHeaders":true},on:{"reset":function($event){if ($event == 'reset') _vm.reset();
      else _vm.screen = 'home';},"get-budgets":_vm.getBudgets,"get-funding-limit":_vm.refreshFundingLimit}}):(_vm.screen == 'budget-transfer')?_c('BudgetTransfer',{attrs:{"preloadDestinationBudget":_vm.destinationBudget,"preloadSourceBudget":_vm.sourceBudget,"preloadAmount":_vm.preloadAmount,"preloadBudgetActivityId":_vm.preloadBudgetActivityId,"budgets":_vm.visibleBudgets,"requestFunds":_vm.requestFunds},on:{"reset":function($event){if (_vm.preloadMenu && _vm.preloadMenu == 'budget-transfer') {
        _vm.reset();
      } else if ($event == 'reset') {
        _vm.reset();
      } else {
        _vm.screen = 'home';
      }}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
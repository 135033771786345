<template>
  <div class="full-height">
    <div
      width="100%"
      rounded="0"
      class="full-height"
      id="manager"
      :class="!isMobile ? 'd-flex flex-column' : ''"
    >
      <!-- Header card/row -->
      <v-card
        elevation="0"
        class="d-flex justify-space-between mt-3 py-4 mb-5"
        :class="{
          'flex-column': $vuetify.breakpoint.xs,
          'mx-6': !isMobile,
          'align-center': !$vuetify.breakpoint.xs
        }"
      >
        <div
          class="d-flex pa-0"
          :style="
            $vuetify.breakpoint.width <= 1035 ? { 'max-width': '330px' } : {}
          "
        >
          <v-icon
            :large="!isMobile"
            dark
            class="pa-2 ml-3 mr-4 my-2 brand-orange-background border-radius icon"
            >mdi-email</v-icon
          >
          <p
            class="font-weight-black word-break brand-text mb-auto mt-auto bold-font page-title text-left"
          >
            Communications
          </p>
        </div>
        <div
          v-if="!isMobile"
          class="py-auto flex-grow-1"
          :class="
            $vuetify.breakpoint.md
              ? 'px-8'
              : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'px-12'
              : 'px-auto'
          "
        >
          <!------------ Search Bar -------------->
          <v-text-field
            outlined
            placeholder="Search"
            single-line
            dense
            hide-details
            v-model="debounceSearch"
            class="shrink mx-auto"
            append-icon="mdi-magnify"
            :style="{ 'max-width': '450px' }"
            clearable
            color="brandCyan"
          ></v-text-field>
          <!------------ End Search Bar -------------->
        </div>
        <div
          :class="
            $vuetify.breakpoint.xs
              ? 'px-0'
              : $vuetify.breakpoint.md
              ? 'px-8 flex-grow-1'
              : $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
              ? 'px-12 flex-grow-1'
              : 'px-8 flex-grow-1'
          "
          :style="
            $vuetify.breakpoint.xs
              ? { height: 'fit-content', 'max-width': '430px' }
              : { height: 'fit-content', 'max-width': '440px' }
          "
          v-if="userProfile.clientId === 1"
        >
          <ClientDropdown
            v-if="userProfile && userProfile.clientId == 1"
            class="pa-1 my-auto full-width"
          ></ClientDropdown>
        </div>
      </v-card>
      <!-- Options row -->
      <v-row
        class="d-flex justify-space-between flex-grow-1"
        :style="isMobile ? {} : { 'max-height': '60%' }"
        :class="{
          'flex-column': isMobile,
          'px-6': !isMobile
        }"
      >
        <v-col :cols="isMobile ? '12' : '4'" class="card-col">
          <v-card
            :style="{ height: 'fit-content' }"
            class="mb-3"
            elevation="0"
            @click="loadWizard"
          >
            <div class="pa-3 d-flex flex-column justify-center">
              <div
                class="d-flex justify-space-between align-center pt-4 pb-2 mb-2 word-break card-header"
              >
                <div class="d-flex align-center pr-2">
                  <v-icon
                    large
                    color="white"
                    class="pa-2 rounded mr-4 brand-orange-background icon"
                    >mdi-newspaper-variant-outline</v-icon
                  >
                  <span class="text-left" v-html="wizardButtonLabel"></span>
                </div>
                <v-icon>mdi-chevron-right</v-icon>
              </div>
            </div>
          </v-card>
          <v-card
            elevation="0"
            class="text-left d-flex flex-column justify-start py-2"
            :class="isMobile ? 'full-width mb-3' : 'mb-10'"
          >
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              color="white"
              depressed
              :class="viewFilter == keys.spot ? 'highlighted-button' : ''"
              @click="handleViewChange(keys.spot)"
            >
              <div>
                <v-icon class="mr-2">mdi-format-list-text</v-icon>Spot
                communications
              </div>
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider class="mx-1" />
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == keys.news ? 'highlighted-button' : ''"
              @click="handleViewChange(keys.news)"
            >
              <div>
                <v-icon class="mr-2">mdi-newspaper-variant-outline</v-icon>News
                articles
              </div>
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider class="mx-1" />
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == keys.alerts ? 'highlighted-button' : ''"
              @click="handleViewChange(keys.alerts)"
            >
              <div>
                <v-icon class="mr-2">mdi-alert</v-icon>Alert communications
              </div>
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider class="mx-1" />
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == keys.program ? 'highlighted-button' : ''"
              @click="handleViewChange(keys.program)"
            >
              <div>
                <v-icon class="mr-2">mdi-star</v-icon>Program communications
              </div>
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
            <v-divider class="mx-1" />
            <v-btn
              class="mx-3 d-flex justify-space-between view-button"
              depressed
              color="white"
              :class="viewFilter == keys.library ? 'highlighted-button' : ''"
              @click="handleViewChange(keys.library)"
            >
              <div>
                <v-icon class="mr-2">mdi-book</v-icon>Communication library
              </div>
              <v-icon large color="grey">mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
        </v-col>
        <v-col :cols="isMobile ? '12' : '8'" class="card-col">
          <v-card
            class="text-left d-flex flex-column justify-space-between py-3"
            :style="{ 'min-height': '100%' }"
            :class="isMobile ? 'full-width' : ''"
            elevation="0"
          >
            <div>
              <MessageTable
                :key="viewFilter"
                :search="search"
                :sourcePage="messageTableSource"
                :displayInfo="true"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import ClientDropdown from "@/components/ClientDropdown.vue";
import MessageTable from "@/components/data-table/Messages.vue";

import { mapState } from "vuex";

import { debounce } from "@/shared_data/functions";

export default {
  name: "CommunicationAdminV2",
  title: "Communications | Whistle",
  components: {
    ClientDropdown,
    MessageTable
  },
  props: {
    preload: {
      type: Number,
      defualt: null
    },
    // Used if we came from somewhere else and immediately loaded the payments console
    previousRoute: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      keys: {
        spot: 1,
        news: 2,
        alerts: 3,
        // system: 3,
        program: 4,
        library: 5
      },
      viewFilter: 1,
      search: null,
      debounceSearch: null
    };
  },
  created() {
    if (this.$route.query.tab || this.preload) {
      const tab = this.$route.query.tab || this.preload;
      if (Number(tab) == tab) this.viewFilter = Number(tab);
      else
        this.viewFilter =
          tab == "program"
            ? this.keys.program
            : tab == "news"
            ? this.keys.news
            : tab == "alerts"
            ? this.keys.alerts
            : tab == "library"
            ? this.keys.library
            : this.keys.spot;
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    handleViewChange(index) {
      this.viewFilter = index;
    },
    loadWizard() {
      this.$router.push({
        name: "communicationwizard",
        params: { previousRouteProps: { preload: this.viewFilter } },
        query: {
          previousRoute: this.$route.name,
          context: this.messageContext,
          template: this.viewFilter == this.keys.library ? 1 : 0
        }
      });
    }
  },
  computed: {
    ...mapState(["userProfile", "permissions", "clients", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    computedHeaders() {
      return [
        {
          text: "Id",
          value: "programId",
          width: "10%",
          align:
            this.userProfile && this.userProfile.clientId == 1
              ? "start"
              : " d-none"
        },
        {
          text: "Program Name",
          value: "displayName",
          width: "50%"
        },
        {
          text: "Status",
          value: "status",
          width: "40%"
        },
        { text: "Details", value: "data-table-expand", width: "10%" }
      ];
    },
    messageContext() {
      if (this.viewFilter == this.keys.news) return "news";
      else if (this.viewFilter == this.keys.program) return "program";
      else if (this.viewFilter == this.keys.alerts) return "alerts";
      return "general";
    },
    messageTableSource() {
      if (this.viewFilter == this.keys.spot) return "spot-communications";
      else if (this.viewFilter == this.keys.news) return "news-communications";
      else if (this.viewFilter == this.keys.library)
        return "library-communications";
      else if (this.viewFilter == this.keys.program)
        return "program-communications";
      else if (this.viewFilter == this.keys.alerts)
        return "alert-communications";
      return "system-communications";
    },
    wizardButtonLabel() {
      let text;
      if (this.viewFilter == this.keys.spot) text = "spot communication";
      else if (this.viewFilter == this.keys.news) text = "news article";
      else if (this.viewFilter == this.keys.library) text = "template";
      else if (this.viewFilter == this.keys.program)
        text = "program communication";
      else if (this.viewFilter == this.keys.alerts) text = "alert";
      else text = "system communication";

      return `Create a new <span class="font-weight-bold">${text}</span>`;
    }
  },
  watch: {
    debounceSearch: debounce(function(newVal) {
      this.search = newVal;
    }, 500)
  }
};
</script>

<style scoped>
.border-radius {
  border-radius: 5px;
}

/* Hides gray background when clicking v-card */
.v-card--link:before {
  background: none;
}

.card-header {
  /* max-height: 60px; */
  height: fit-content;
}

.icon {
  min-width: 52px;
  max-width: 52px;
  width: 52px;
  min-height: 52px;
  max-height: 52px;
  height: 52px;
}

.page-title {
  font-size: 1.7em;
}
@media (max-width: 960px) {
  .icon {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
  }

  .page-title {
    font-size: 1.2em;
  }
}
/* Changes the default padding of all cols that have a card in them in the payment manager div */
#manager .card-col.col {
  padding-top: 8px;
  padding-bottom: 8px;
}

#manager .v-card {
  border-radius: 10px;
  padding-right: 12px;
  padding-left: 12px;
}

.view-button {
  line-height: 1.5;
  border-radius: 50px;
  height: 40px;
  margin-bottom: 16px;
  margin-top: 10px;
  padding: 0 0 0 16px !important;
}

.highlighted-button {
  background: #eeeeee !important;
}

.view-button >>> .v-btn__content {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.5;
  font-size: 16px;
}

.bold-font {
  font-family: "Roboto", sans-serif !important;
}
</style>

<template>
  <div class="mt-4 flex-column d-flex">
    <v-card rounded="0" width="100%" elevation="0" class="quiz-card">
      <v-card-title class="word-break px-0 font-weight-bold brand--text">
        <!-- #{{ questionIndex + 1 }} - -->
        {{ question.content }}
      </v-card-title>
      <p class="grey--text mb-1 font-italic">
        Choose one or more responses from the answers below
      </p>
      <v-divider />
      <!-- <v-radio-group
        v-model="checkedAnswers"
        multiple
        :class="answerSubmitted ? 'radio-group-disabled' : ''"
      > -->
      <v-item-group
        multiple
        v-model="checkedAnswers"
        :class="answerSubmitted ? 'radio-group-disabled' : ''"
      >
        <div
          v-for="answer in question.LearningQuizAnswers"
          :key="answer.learningQuizAnswerId"
        >
          <v-hover v-slot="{ hover }">
            <div
              class="answer-box d-flex align-center cursor-pointer my-1"
              :class="{
                'light-grey-background': hover && !answerSubmitted,
                'light-red-background':
                  answerSubmitted &&
                  answer.correctness <= 0 &&
                  checkedAnswers
                    .map((x) => x.learningQuizAnswerId)
                    .includes(answer.learningQuizAnswerId),
                'brand-pale-green-background':
                  answerSubmitted &&
                  correctAnswers
                    .map((x) => x.learningQuizAnswerId)
                    .includes(answer.learningQuizAnswerId),
              }"
              @click="clickAnswer(answer)"
            >
              <!-- -->
              <!-- <div
                class="d-flex justify-center"
                v-if="answerSubmitted && answer.correctness > 0"
              >
                <v-icon color="brandGreen" class="correct-icon"
                  >mdi-check-circle</v-icon
                >
                <span class="ml-2">{{ answer.content }}</span>
              </div> -->
              <div class="d-flex justify-center mx-n8">
                <v-icon
                  :class="{
                    hidden: !answerIconLogic(answer),
                  }"
                  :color="
                    answerIconLogic(answer) == 'mdi-check-circle'
                      ? 'brandGreen'
                      : 'red'
                  "
                  class="correct-icon mr-8 ml-n6"
                  >{{ answerIconLogic(answer) || "mdi-close" }}</v-icon
                >
                <!-- <v-icon
                  :class="{
                    hidden: !(
                      answerSubmitted &&
                      ((checkedAnswers
                        .map((x) => x.learningQuizAnswerId)
                        .includes(answer.learningQuizAnswerId) &&
                        !correctAnswers
                          .map((x) => x.learningQuizAnswerId)
                          .includes(answer.learningQuizAnswerId)) ||
                        (!checkedAnswers
                          .map((x) => x.learningQuizAnswerId)
                          .includes(answer.learningQuizAnswerId) &&
                          correctAnswers
                            .map((x) => x.learningQuizAnswerId)
                            .includes(answer.learningQuizAnswerId)))
                    ),
                  }"
                  color="red"
                  class="correct-icon mr-8 ml-n6"
                  >mdi-close</v-icon
                > -->
                <v-checkbox
                  :label="answer.content"
                  :value="checkedAnswers.find((x) => x == answer)"
                  :color="answerSubmitted ? 'grey' : 'brandCyan'"
                  :readonly="answerSubmitted"
                  :disabled="answerSubmitted"
                  @click.stop="clickAnswer(answer)"
                />
                <!-- <v-radio
                  :label="answer.content"
                  :value="answer"
                  :color="answerSubmitted ? 'grey' : 'brandCyan'"
                  :readonly="answerSubmitted"
                  :disabled="answerSubmitted"
                ></v-radio> -->
              </div>
            </div>
          </v-hover>
        </div>
      </v-item-group>
      <!-- </v-radio-group> -->
      <v-divider />
      <!-- <div
        v-for="(answer, index) in question.LearningQuizAnswers"
        elevation="0"
        :key="answer.learningQuizAnswerId"
      >
        <div
          :for="answer.learningQuizAnswerId"
          class="rounded-card d-flex px-5 py-4 align-center"
          @click="answerQuestion(answer)"
          :class="{
            'cursor-pointer': !answerSubmitted,
            'light-purple-background':
              (!answerSubmitted &&
                checkedAnswerIds.includes(answer.learningQuizAnswerId)) ||
              (answerSubmitted &&
                noCorrectAnswersMarked &&
                checkedAnswer &&
                checkedAnswer.learningQuizAnswerId ==
                  answer.learningQuizAnswerId),
            'brand-pale-green-background':
              answerSubmitted && answer.correctness > 0,
            'light-red-background':
              answerSubmitted &&
              !noCorrectAnswersMarked &&
              answer.correctness <= 0 &&
              checkedAnswerIds.includes(answer.learningQuizAnswerId),
          }"
        >
          <div
            class="letter-circle d-flex align-center justify-center light-grey-background circle-border-radius mr-4"
            :class="{
              'light-grey-background': !answerSubmitted,
              'purple-background':
                (!answerSubmitted &&
                  checkedAnswerIds.includes(answer.learningQuizAnswerId)) ||
                (answerSubmitted &&
                  noCorrectAnswersMarked &&
                  checkedAnswer &&
                  checkedAnswer.learningQuizAnswerId ==
                    answer.learningQuizAnswerId),
              'brand-green-background':
                answerSubmitted && answer.correctness > 0,
              'red-background':
                answerSubmitted &&
                !noCorrectAnswersMarked &&
                answer.correctness <= 0 &&
                checkedAnswerIds.includes(answer.learningQuizAnswerId),
            }"
          >
            <b class="white--text">{{ alphabet[index] }}</b>
          </div>
          {{ answer.content }}
        </div>
      </div> -->

      <div class="d-flex flex-column align-center mt-10">
        <p v-if="answerSubmitted" class="text-center">
          Shading indicates correctness, icons indicate which selections you got
          right
        </p>
        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          width="120"
          depressed
          @click="checkQuestion"
          v-if="answerSubmitted == false"
          :disabled="checkedAnswers == null || checkedAnswers.length == 0"
        >
          {{ computedSubmitButton }}
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          width="120"
          depressed
          @click="goToNextQuestion"
          v-else-if="
            questionIndex !== questionCount - 1 && answerSubmitted == true
          "
        >
          Next
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          width="120"
          depressed
          @click="completeQuiz"
          v-else-if="
            questionIndex == questionCount - 1 &&
              answerSubmitted == true &&
              !isSurveyQuestion
          "
        >
          Complete
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { alphabetLowerCase } from "@/shared_data/data";
import LearningService from "@/services/LearningService";

export default {
  name: "QuizQuestionSelectAllV2",
  props: {
    question: Object,
    questionIndex: Number,
    questionCount: Number,
    completedStatus: Boolean,
    learningCardAssociationId: Number,
    isSurveyQuestion: Boolean,
    surveyClientId: Number,
    whistleToken: String,
    enteredEmail: String,
    context: String,
  },
  components: {},
  data() {
    return {
      alphabet: alphabetLowerCase,
      selected: "",
      checkedAnswers: [],
      // checkedAnswerIds: [],
      answerKey: null,
      chapterList: null,
      currentQuestion: null,
      incorrectAnswers: [],
      incorrectCount: 0,
      correctCount: 0,
      checkedAnswer: null,
      answerSubmitted: false,
    };
  },
  created() {},
  updated() {},
  mounted() {},
  beforeMount() {},
  methods: {
    clickAnswer(answer) {
      if (this.answerSubmitted) return;
      var index = this.checkedAnswers.findIndex(
        (x) => x.learningQuizAnswerId == answer.learningQuizAnswerId
      );
      if (index != -1) {
        this.checkedAnswers.splice(index, 1);
      } else {
        this.checkedAnswers.push(answer);
      }
    },
    checkQuestion() {
      // get the user's answer
      var selectedAnswers = this.checkedAnswers.map(
        (x) => x.learningQuizAnswerId
      );
      // check this question's answer
      // Switched from find to filter in case there are multiple correct answers
      var correctAnswers = this.question.LearningQuizAnswers.filter(
        (ans) => ans.correctness > 0
      );
      correctAnswers = correctAnswers.map((x) => x.learningQuizAnswerId);
      //console.log(answer);

      var answerValues = this.checkedAnswers.sort().map((x) => x.content);

      // compare correct answer with user's answer
      this.answerSubmitted = true;
      var correctness = null;
      if (
        this.noCorrectAnswersMarked ||
        selectedAnswers.sort().join(",") === correctAnswers.sort().join(",")
      ) {
        console.log("Got question correct ", this.correctCount);
        this.correctCount++;
        correctness = 1;
      } else {
        console.log("Wrong! ", this.correctCount);
        console.log(
          selectedAnswers.sort().join(",") +
            "  -  " +
            correctAnswers.sort().join(",")
        );
        this.incorrectCount++;
        correctness = 0;
      }

      //build question response
      console.log("build question response...");
      var quizQuestionResponse = {
        clientId:
          this.userProfile && this.userProfile.clientId != null
            ? this.userProfile.clientId
            : this.surveyClientId,
        learningCardId: this.question.learningCardId,
        learningQuizQuestionId: this.question.learningQuizQuestionId,
        learningCardAssociationId: this.learningCardAssociationId,
        learningQuizAnswerId: "[" + selectedAnswers.sort().join(", ") + "]",
        value: answerValues.join(", "),
        correctness: this.isSurveyQuestion ? null : correctness,
        userId: this.whistleToken != null ? null : this.$auth.user.sub,
      };

      if (this.whistleToken !== null) {
        quizQuestionResponse.externalUserId = this.enteredEmail;
      }

      console.log(quizQuestionResponse);
      console.log(this.checkedAnswer);

      LearningService.createQuizResponse(
        [quizQuestionResponse],
        this.whistleToken
      );
      //if this is a survey question, we don't want to have to click Submit, and then Next, one button should do both
      //so we call the function for next question or complte quiz inside of the submit button
      if (this.isSurveyQuestion == true || this.noCorrectAnswersMarked) {
        this.goToNextQuestion();
      }

      console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(this.checkedAnswer);
    },
    // answerQuestion(answer) {
    //   console.log(answer.learningQuizAnswerId);
    //   if (!this.answerSubmitted) {
    //     //this has already been selected, so we need to deselect it and remove from arrays
    //     if (this.checkedAnswerIds.includes(answer.learningQuizAnswerId)) {
    //       this.checkedAnswerIds = this.checkedAnswerIds.filter(function(item) {
    //         return item != answer.learningQuizAnswerId;
    //       });
    //       this.checkedAnswers = this.checkedAnswers.filter(function(item) {
    //         return item.learningQuizAnswerId != answer.learningQuizAnswerId;
    //       });
    //     } else {
    //       //else, add it to arrays
    //       this.checkedAnswers.push(answer);
    //       this.checkedAnswerIds.push(answer.learningQuizAnswerId);
    //     }

    //     console.log(this.checkedAnswerIds);
    //   }
    // },
    goToNextQuestion() {
      // If we're not on the last question, move on and reset the flags for answering a question
      if (this.questionIndex !== this.questionCount - 1) {
        this.checkedAnswers = [];
        // this.checkedAnswerIds = [];
        this.answerSubmitted = false;
        this.checkedAnswer = null;
        this.$emit("next-question", this.correctCount);
        this.correctCount = 0;
      } else {
        this.completeQuiz();
      }
    },
    completeQuiz() {
      // Emits a notification so the quiz can submit
      this.$emit("complete-quiz", this.correctCount);
    },
    answerIconLogic(answer) {
      // For select all it can be confusing.
      // We want to show answers highlighted based on their actual correctness
      // This logic is for the check/x icons to the left of the answers
      // This will tell the user if their answer was right/wrong relative to the correctness of the answer
      // e.g. it's wrong if they didn't select an answer that was actually correct
      // OR it could be wrong if the user selected an answer that was not marked as correct
      // The only time we show the check mark is if they actually checked an answer and the answer was correct.
      if (
        this.answerSubmitted &&
        this.checkedAnswers
          .map((x) => x.learningQuizAnswerId)
          .includes(answer.learningQuizAnswerId) &&
        this.correctAnswers
          .map((x) => x.learningQuizAnswerId)
          .includes(answer.learningQuizAnswerId)
      )
        return "mdi-check-circle";
      else if (
        this.answerSubmitted &&
        ((this.checkedAnswers
          .map((x) => x.learningQuizAnswerId)
          .includes(answer.learningQuizAnswerId) &&
          !this.correctAnswers
            .map((x) => x.learningQuizAnswerId)
            .includes(answer.learningQuizAnswerId)) ||
          (!this.checkedAnswers
            .map((x) => x.learningQuizAnswerId)
            .includes(answer.learningQuizAnswerId) &&
            this.correctAnswers
              .map((x) => x.learningQuizAnswerId)
              .includes(answer.learningQuizAnswerId)))
      )
        return "mdi-close-circle";
      return null;
    },
  },
  computed: {
    ...mapState(["userProfile"]),
    correctAnswers() {
      if (!this.question || !this.question.LearningQuizAnswers) return [];
      return this.question.LearningQuizAnswers.filter(
        (ans) => ans.correctness > 0 && ans.status == "Active"
      );
    },
    noCorrectAnswersMarked() {
      if (
        this.question &&
        this.question.LearningQuizAnswers.filter((ans) => ans.correctness > 0)
          .length == 0
      ) {
        return true;
      }
      return false;
    },
    computedSubmitButton: function() {
      var buttonText = "Submit";
      if (this.isSurveyQuestion) {
        buttonText = "Next";
      }
      return buttonText;
    },
    inProgram() {
      // used to change styling if we're in learning or surveys versus in programs
      return !(this.context == "survey" || this.context == "learning");
    },
  },
};
</script>

<style scoped>
.rounded-card {
  margin: 10px;
  border-radius: 50px;
  border: 1px solid;
  border-color: lightgrey;
}

.grey-background {
  background-color: var(--v-grey-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.brand-pale-green-background {
  background-color: var(--v-brandPaleGreen-base);
}
.red-background {
  background-color: #f44336;
}
.light-red-background {
  background-color: #fcdeda;
}
.purple-background {
  background-color: #818ade;
}
.light-purple-background {
  background-color: #e9e9f5;
}
.accent-background {
  background-color: var(--v-accent-base);
}

.selected-background {
  background-color: var(--v-blue-base);
}
.correct-background {
  color: var(--v-green-base);
}

.nextQ {
  padding-top: 10px;
}

.v-progress-linear {
  display: block;
  width: 100px;
  margin: 0 auto;
  text-align: justify-center;
}

.retakeButten {
  margin-bottom: 15px;
}
.wrong {
  background-color: red;
}
.correct {
  background-color: green;
}

.letter-circle {
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  min-height: 35px;
  max-height: 35px;
}

/* Container for individual answer box */
.answer-box {
  min-height: 60px;
  /* height: 60px; */
  width: 100%;
  padding: 0 20px 0 20px;
}

.correct-icon {
  transform: scale(1.1);
}

.radio-group-disabled {
  pointer-events: none !important;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hierarchy !== null)?_c('v-container',{staticClass:"full-height max-width pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-end",style:({ width: '100%' })},[(_vm.showVisibility)?_c('div',{staticClass:"px-4 ml-7 d-flex align-baseline"},[_c('p',{staticClass:"pr-2"},[_vm._v("Rule visibility:")]),_c('v-select',{staticClass:"pt-0 mt-0",style:({ width: '200px' }),attrs:{"hide-details":"","items":_vm.visibilityOptions,"item-text":"label","item-value":"value","label":"Rule visibility","single-line":""},model:{value:(_vm.visibilityOption),callback:function ($$v) {_vm.visibilityOption=$$v},expression:"visibilityOption"}})],1):_vm._e(),(_vm.showExitButton || _vm.showSaveButton)?_c('div',{staticClass:"d-flex justify-end mr-4 mb-4"},[(_vm.showExitButton)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.closeDialog}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-close")]),_vm._v("Exit")],1):_vm._e(),(_vm.showSaveButton)?_c('v-btn',{attrs:{"text":"","color":"primary","disabled":_vm.disableSaveButton},on:{"click":_vm.saveRules}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-content-save")]),_vm._v("Save")],1):_vm._e()],1):_vm._e()]),(!_vm.hideTextFields)?_c('div',{staticClass:"d-flex align-center mb-4 mx-3"},[_c('v-text-field',{ref:"nameInput",staticClass:"pa-1",attrs:{"outlined":"","hide-details":"","disabled":_vm.readOnly,"label":_vm.context == 'Programs'
          ? 'New qualifying rule name'
          : 'New complex group rule name'},model:{value:(_vm.ruleName),callback:function ($$v) {_vm.ruleName=$$v},expression:"ruleName"}}),_c('v-icon',{staticClass:"circle-border-radius pa-2 ml-4 cursor-pointer",class:{
        'accent-background': _vm.ruleName,
        'grey-background': !_vm.ruleName
      },attrs:{"dark":"","tabindex":"-1"},on:{"click":_vm.focusOnName}},[_vm._v("mdi-pencil")])],1):_vm._e(),(!_vm.hideTextFields)?_c('div',{staticClass:"d-flex align-center mb-4 mx-3"},[_c('v-textarea',{ref:"descriptionInput",staticClass:"pa-1",attrs:{"outlined":"","hide-details":"","rows":"3","disabled":_vm.readOnly,"label":_vm.context == 'Programs'
          ? 'A brief description of how to participate in this program'
          : 'A brief description of who is in this complex group'},model:{value:(_vm.ruleDescription),callback:function ($$v) {_vm.ruleDescription=$$v},expression:"ruleDescription"}}),_c('v-icon',{staticClass:"circle-border-radius pa-2 ml-4 cursor-pointer",class:{
        'accent-background': _vm.ruleDescription,
        'grey-background': !_vm.ruleDescription
      },attrs:{"dark":"","tabindex":"-1"},on:{"click":_vm.focusOnDescription}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('RuleComponentV2',{attrs:{"item":_vm.hierarchy,"logicalOperators":_vm.logicalOperators,"contextOptions":_vm.contextOptions,"contexts":_vm.contextArray,"operators":_vm.operators,"context":_vm.context,"hideChip":true,"readOnly":_vm.readOnly,"clientId":_vm.clientId,"includeSubClients":_vm.includeSubClients}}),_c('span',[_vm._v("Estimated Participants: "),_c('b',[_vm._v(_vm._s(_vm.estimatedParticipants === null ? "-" : _vm.estimatedParticipants))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mb-1",attrs:{"small":"","depressed":"","icon":"","loading":_vm.loadingEstimatedParticipants,"disabled":_vm.disableEstimatedParticipants},on:{"click":_vm.refreshEstimate}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,3245442567)},[_c('span',[_vm._v("Refresh estimate")])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
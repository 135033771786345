<template>
  <v-card flat>
    <v-toolbar dark color="brandCyan" rounded="0" elevation="0">
      <v-toolbar-title>
        {{ editingPreset ? "Update a preset" : "Add a preset" }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetForm(true)">
          <span>Cancel</span>
        </v-btn>
        <v-btn
          color="white"
          text
          @click="
            if (editingPreset) {
              updatePreset();
            } else {
              createPreset();
            }
          "
          :disabled="disableSaveButton"
        >
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-container>
      <v-card-text>
        <v-form ref="form" v-model="form.valid">
          <div class="ful-width d-flex justify-center align-center mb-4">
            <v-card-title>
              Select an emoji:
            </v-card-title>
            <EmojiPicker
              :bottom="true"
              :top="false"
              :left="true"
              :class="{}"
              :buttonWidth="!!values.emoji ? 40 : undefined"
              :buttonColor="!!values.emoji ? 'white' : 'black'"
              :placeholderEmoji="values.emoji"
              @select="values.emoji = $event"
            />
          </div>
          <v-autocomplete
            class="full-width"
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client*"
            v-model="values.clientId"
            v-if="userProfile.clientId === 1 && !editingPreset && !clientId"
          ></v-autocomplete>

          <v-text-field
            v-model="values.displayName"
            :rules="formRules.stringRequired(20)"
            label="Display name*"
            outlined
            color="brandCyan"
          ></v-text-field>
          <v-text-field
            v-model="values.amount"
            label="Amount*"
            outlined
            type="number"
            color="brandCyan"
            :rules="formRules.amount"
          ></v-text-field>
          <!-- <v-text-field
            v-model="values.unitSingular"
            :rules="formRules.stringRequired(255)"
            label="Units*"
            outlined
            color="brandCyan"
          ></v-text-field> -->
          <!-- <v-text-field
            v-model="values.classification"
            :rules="formRules.stringRequired(60)"
            label="Classification*"
            outlined
            color="brandCyan"
          ></v-text-field> -->
          <!-- <v-textarea
            label="Memo"
            placeholder="Daniel, I am consistently impressed by your patience with every customer interaction that you face. Your ability to create space for their needs to be heard and calm approach to problem solving allow us to remain a trusted partner to our customers."
            outlined
            v-model="values.defaultMemo"
            :rules="formRules.stringOptional(255)"
            color="brandCyan"
          ></v-textarea> -->
          <!-- <v-checkbox
            color="brandCyan"
            label="Include in Payment Wizard"
            v-model="values.paymentWizard"
          /> -->
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
        <v-tooltip
          top
          :disabled="!disableDelete"
          v-if="editingPreset && permissions.includes('badges:delete:badge')"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                color="red"
                text
                @click="deleteBadge"
                :disabled="disableDelete"
              >
                Delete
              </v-btn>
            </div>
          </template>
          <span>A behavior is currently tied to this badge</span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-actions> -->
    </v-container>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
    <ErrorDialog
      v-if="errorDialog.display"
      :title="errorDialog.title"
      :subtitle="errorDialog.subtitle"
      button2Text="Ok"
      @button2="errorDialog.display = false"
      @close="errorDialog.display = false"
    />
  </v-card>
</template>

<script>
import LoadingDialog from "@/components/loadingDialog.vue";
import ErrorDialog from "@/components/ErrorDialog.vue";
import EmojiPicker from "@/components/EmojiPicker.vue";

import WalletService from "@/services/WalletService.js";

import { formRules } from "@/shared_data/data";

import { mapState } from "vuex";

export default {
  name: "PaymentPresetCreator",
  components: {
    LoadingDialog,
    EmojiPicker,
    ErrorDialog
  },
  props: {
    editingPreset: {
      type: Boolean,
      default: false
    },
    editPresetObject: Object
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      form: {
        menuStartDate: false,
        menuExpirationDate: false,
        expDateDisabled: false,
        expDateCheckBox: false,
        valid: false,
        forceValidForm: false
      },
      formRules,
      values: {
        clientId: null,
        paymentPresetId: null,
        displayName: null,
        classification: null,
        priority: null,
        amount: null,
        defaultMemo: null,
        unitSingular: null,
        emoji: null,
        paymentWizard: false
      },
      errorDialog: {
        display: false,
        title: null,
        subtitle: null
      }
    };
  },
  mounted() {
    // if (!this.editingPreset) this.resetForm(false);
    if (this.editPresetObject) {
      // Sleep 50 ms because Vue hasn't loaded the new form ref yet
      //   setTimeout(() => this.resetForm(false), 50);
      // } else {
      this.values = JSON.parse(JSON.stringify(this.editPresetObject));
      // this.values.paymentWizard = !!this.values.iconId;

      // if (!this.editingPreset) delete this.values.clientId;
    }
  },
  created() {},
  beforeDestroy() {},
  methods: {
    async createPreset() {
      try {
        this.showLoadingDialog = true;
        this.loadingHeaderMessage = "Creating preset";
        this.loadingMessageLine1 = "This should just take a minute.";
        this.loadingMessageLine2 = null;

        //set up badge object
        var returnObj = {
          clientId: this.clientId,
          displayName: this.values.displayName,
          classification: this.values.classification,
          amount: this.values.amount,
          emoji: this.values.emoji,
          unitSingular: this.values.displayName, //this.values.unitSingular ||
          priority: this.values.priority,
          defaultMemo: this.values.defaultMemo,
          iconId: 15
        };
        // if (this.values.clientId !== null) {
        //   returnObj.clientId = this.values.clientId;
        // }
        // if (this.values.paymentWizard) returnObj.iconId = 15;
        if (this.source) returnObj.source = this.source;

        console.log("Preset create (before): ", returnObj);
        const result = await WalletService.createPaymentPresetV2(returnObj, {});
        console.log("preset created", result);
        this.showLoadingDialog = false;

        if (!result.length) throw "Classification error";
        // this.resetForm();
        this.$emit("refreshPresets");
        this.$emit("close");
      } catch (e) {
        console.log("Error creating payment", e);
        this.errorDialog.display = true;
        this.errorDialog.title = "We couldn't create your preset";
        this.errorDialog.subtitle = "Please try again or contact us";
      } finally {
        this.showLoadingDialog = false;
      }
    },
    async updatePreset() {
      try {
        this.showLoadingDialog = true;
        this.loadingHeaderMessage = "Updating preset";
        this.loadingMessageLine1 = "This should just take a minute.";
        this.loadingMessageLine2 = null;

        //set up badge object
        var returnObj = {
          paymentPresetId: this.values.paymentPresetId,
          clientId: this.clientId,
          displayName: this.values.displayName,
          classification: this.values.classification,
          amount: this.values.amount,
          emoji: this.values.emoji,
          unitSingular: this.values.displayName // this.values.unitSingular ||
          // defaultMemo: this.values.defaultMemo
        };
        // if (this.values.clientId !== null) {
        //   returnObj.clientId = this.values.clientId;
        // }
        // if (this.values.paymentWizard) returnObj.iconId = 15;
        // else returnObj.iconId = null;
        if (this.source) returnObj.source = this.source;

        console.log("Preset update (before): ", returnObj);
        const result = await WalletService.updatePaymentPresetV2(
          this.values.paymentPresetId,
          returnObj
        );
        console.log({ result });

        console.log("preset updated");
        this.showLoadingDialog = false;
        // this.resetForm();
        this.$emit("refreshPresets");
        this.$emit("close");
      } catch (e) {
        console.log("Error updating payment", e);
        this.errorDialog.display = true;
        this.errorDialog.title = "We couldn't update your preset";
        this.errorDialog.subtitle = "Please try again or contact us";
      } finally {
        this.showLoadingDialog = false;
      }
    },
    // deleteBadge() {
    //   this.showLoadingDialog = true;
    //   this.loadingHeaderMessage = "Deleting badge";
    //   this.loadingMessageLine1 = "This should just take a minute.";
    //   this.loadingMessageLine2 = "";
    //   BadgeService.deleteBadge(this.values.badgeId)
    //     .then(response => {
    //       console.log(response);
    //       this.showLoadingDialog = false;
    //       if (response) {
    //         console.log("Badge deleted");
    //         this.resetForm();
    //         this.$emit("refreshBadges", null);
    //       } else {
    //         console.log("Error updating badge");
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       this.showLoadingDialog = false;
    //     });
    // },

    resetForm(closeForm = true) {
      console.log("reset badge form");
      this.form.forceValidForm = false;
      this.form.showLoadingDialog = false;
      // this.form.editing = false;

      this.$refs.form.reset();

      if (this.values.clientId == null) {
        if (this.clientId) {
          this.values.clientId = this.clientId;
        } else {
          this.values.clientId = this.userProfile.clientId;
        }
      }

      if (closeForm == true) {
        this.$emit("close");
      }
    }
  },
  computed: {
    ...mapState(["clients", "userProfile", "permissions", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableSaveButton() {
      return !(this.form.valid && this.values.emoji);
    },
    disableDelete() {
      var currentBadge = this.values.badgeId;
      var index = this.badges.findIndex(x => x.badgeId === currentBadge);
      if (index !== -1 || this.values.clientId === 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

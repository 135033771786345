<template>
  <v-card elevation="0" rounded="0">
    <v-toolbar dark flat color="brandCyan" rounded="0" width="100%">
      <div>
        <v-toolbar-title>Add a resource</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close">
          <span class="mt-1">Close</span>
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div v-if="activePage == 0">
      <v-row no-gutters justify="center" full-width no-wrap class="mb-2">
        <v-col cols="10" class="d-flex flex-column text-left mx-0 my-0">
          <div class="mt-10 d-flex justify-space-between align-center">
            <p class="mb-0 mediumGrey--text">Available Resources</p>
            <div class="d-flex">
              <v-btn icon color="primary" @click="showHideSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
              <v-menu
                v-model="sort.menu"
                offset-y
                left
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex">
                    <v-btn
                      text
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      >Sort</v-btn
                    >
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="option in sort.options"
                    :key="option.id"
                    @click="updateSortBy(option)"
                    :class="{
                      'light-grey-background': option.values.includes(
                        sort.value
                      )
                    }"
                  >
                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>{{ option.label }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon
                      :class="{ hidden: !option.values.includes(sort.value) }"
                      ><v-icon>{{
                        sort.value && sort.value.includes("asc")
                          ? "mdi-arrow-up"
                          : "mdi-arrow-down"
                      }}</v-icon></v-list-item-icon
                    >
                    <!-- </template> -->
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-text-field
            v-if="dialog.search"
            ref="search"
            outlined
            placeholder="Search"
            prepend-icon="mdi-magnify"
            single-line
            dense
            hide-details
            v-model="data.search"
            class="mb-5 mt-3"
          ></v-text-field>
          <!-- <div class="tab-group-container text-center mt-2">
          <v-btn
            depressed
            @click="changeTab(0)"
            :color="activeTab == 0 ? 'white' : 'lightGrey'"
            class="px-5 mr-1 tab-button"
            :class="{
              'active-tab-button': activeTab == 0,
            }"
            label
          >
            Learning
          </v-btn>
          <v-btn
            depressed
            @click="changeTab(1)"
            :color="activeTab == 1 ? 'white' : 'lightGrey'"
            class="px-5 ml-1 tab-button"
            :class="{
              'active-tab-button': activeTab == 1,
            }"
            label
          >
            Surveys
          </v-btn>
        </div> -->
        </v-col>
      </v-row>
      <div class="mt-2 mx-12 text-left mb-6">
        <div
          class="d-flex justify-center"
          v-if="loadingResources || loading.resources"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            class="mx-auto"
          ></v-progress-circular>
        </div>
        <div
          class="d-flex justify-center my-3"
          v-if="!loadingResources && !loading.resources"
        >
          <v-btn text color="primary" class="mx-auto" @click="loadContentForm">
            <v-icon small class="add-resource-button mr-2" color="white"
              >mdi-plus</v-icon
            >New resource</v-btn
          >
        </div>
        <div v-for="resource in visibleResources" :key="resource.id">
          <v-hover v-slot="{ hover }">
            <div
              class="mx-0 px-0 py-1 cursor-pointer"
              @click="addToProgram(resource)"
            >
              <!-- <v-divider /> -->
              <div class="d-flex align-start">
                <v-icon
                  class="resource-icons"
                  :class="
                    hover
                      ? 'primary-background'
                      : `${resource.iconColor}-background`
                  "
                  color="white"
                  >{{ hover ? "mdi-plus" : resource.icon }}</v-icon
                >

                <!-- <div class="ml-2 px-3 text-overflow-clip resource-box">
                  <span>{{ resource.displayName }}</span>
                </div> -->
                <div
                  class="ml-2 px-3 text-overflow-clip resource-box d-flex flex-column"
                  :style="hover ? '' : 'height: 35px;'"
                >
                  <div class="d-flex justify-space-between">
                    <span class="text-overflow-clip">{{
                      resource.displayName
                    }}</span>
                    <v-btn
                      icon
                      @click.stop.native="downloadContent(resource)"
                      class="mt-n2"
                    >
                      <v-icon color="brand">mdi-download</v-icon>
                    </v-btn>
                  </div>
                  <p class="mb-1 mediumGrey--text">
                    Created {{ resource.createdAtFormatted }}
                  </p>
                  <p class="mb-1 text-overflow-wrap">
                    Description:
                    {{
                      resource.description && resource.description != "null"
                        ? resource.description
                        : "N/A"
                    }}
                  </p>
                </div>
              </div>

              <!-- <template v-slot:actions>
            <v-icon small>$expand</v-icon>
          </template> -->
            </div>
          </v-hover>
        </div>
        <!-- <div class="d-flex justify-center mt-3 mb-6">
          <v-btn text color="primary" class="mx-auto" @click="loadContentForm">
            <v-icon small class="add-resource-button mr-2" color="white"
              >mdi-plus</v-icon
            >Upload a resource</v-btn
          >
        </div> -->
      </div>
    </div>
    <div v-else-if="activePage == 1">
      <v-row justify="center">
        <v-col class="d-flex flex-column mt-3" cols="12" sm="9" md="9" lg="9">
          <v-form ref="contentForm" v-model="contentForm.valid" class="px-3">
            <v-menu max-width="260" offset-x offset-y absolute>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="mb-4"
                  @drop.prevent="dragAndDropFileChanged"
                  @dragover.prevent
                  :loading="contentForm.selectingFileFlag"
                  ><v-icon dark class="mr-2">mdi-file</v-icon>Upload File</v-btn
                >
              </template>
              <v-list>
                <input
                  id="uploader"
                  ref="uploader"
                  class="d-none"
                  type="file"
                  @change="fileChanged"
                  @blur="fileChanged"
                />
                <v-list-item @click="uploadFile">
                  <v-list-item-title class="primary--text align-text-left"
                    ><v-icon class="mr-2" color="black">mdi-upload</v-icon
                    >Upload from computer</v-list-item-title
                  >
                </v-list-item>

                <v-list-item @click="removeFile">
                  <v-list-item-title class="primary--text align-text-left"
                    ><v-icon class="mr-2" color="black">mdi-delete</v-icon
                    >Remove file</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <div
              class="d-flex align-center justify-center mb-8"
              v-if="content.fileName"
            >
              <span class="font-weight-bold text-left">{{
                content.fileName
              }}</span>
              <v-btn text @click="removeFile" icon class="mx-5">
                <v-icon
                  color="white"
                  class="pa-4 light-grey-background circle-border-radius"
                  >mdi-close</v-icon
                >
              </v-btn>
            </div>

            <v-text-field
              class="pa-1"
              v-model="content.name"
              :rules="[v => !!v || 'Content name is required']"
              label="Name*"
              required
            ></v-text-field>
            <v-text-field
              class="pa-1"
              v-model="content.description"
              label="Description"
            ></v-text-field>
            <v-select
              label="Category*"
              :items="categories"
              item-text="name"
              item-value="value"
              v-model="content.category"
              :rules="[v => !!v || 'Category is required']"
            ></v-select>
            <div class="d-flex justify-center mt-3 mb-6">
              <v-btn
                depressed
                :outlined="!contentForm.valid || content.fileData === null"
                :disabled="!contentForm.valid || content.fileData === null"
                color="primary"
                @click="createContent"
              >
                Create resource</v-btn
              >
            </div>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="activePage == 2" class="d-flex justify-center">
      <RobinAnimation
        :height="125"
        :width="125"
        header="Uploading resource"
        message="Depending on the file size this could take a second"
        :largeText="false"
        :showText="true"
        animation="flying"
        class="mt-7"
        :loop="true"
      ></RobinAnimation>
    </div>
  </v-card>
</template>

<script>
import ContentService from "@/services/ContentService";

import RobinAnimation from "./Robin";

import { formatFileType } from "@/shared_data/functions.js";

import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "ProgramResourceWidget",
  components: {
    // LoadingDialog,
    RobinAnimation
  },
  props: {
    clientId: Number,
    resources: Array,
    loadingResources: Boolean,
    activeProgram: Boolean,
    context: {
      type: String,
      default: "program"
    }
  },
  data() {
    return {
      data: {
        resources: [],
        search: null
      },
      dialog: {
        search: false
      },
      loading: {
        resources: false
      },
      sort: {
        menu: false,
        value: "create-date-desc",
        options: [
          {
            label: "Name",
            key: "name",
            values: ["name-asc", "name-desc"]
          },
          {
            label: "Created",
            key: "create-date",
            values: ["create-date-asc", "create-date-desc"]
          }
        ]
      },
      activePage: 0,

      content: {
        name: null,
        description: null,
        category: "GENERAL_UPLOAD",
        file: null,
        fileData: null,
        fileName: null
      },
      contentForm: {
        valid: false,
        selectingFileFlag: false
      },
      categories: [
        {
          name: "General file upload",
          value: "GENERAL_UPLOAD"
        },
        {
          name: "Program Information",
          value: "PROGRAM_INFO"
        },
        {
          name: "Other",
          value: "OTHER"
        }
      ]
    };
  },

  created() {
    // We pass in props and fetch resources if not passed in
    if (!this.resources) this.getContent();
  },
  mounted() {},
  methods: {
    getContent(preloadContentId = null) {
      this.loading.resources = true;
      ContentService.getAllContent()
        .then(response => {
          var resources = response
            .filter(
              resource =>
                resource.status !== "Deleted" &&
                (resource.clientId == 0 ||
                  resource.clientId == this.clientId) &&
                ![
                  "LEARNING_MODULE",
                  "LEARNING_CARD",
                  "LEARNING_CARD_CONTENT",
                  "SURVEY",
                  "badge",
                  "BADGE"
                ].includes(resource.category)
            )
            .map(x => {
              var obj = x;
              obj.id = x.contentId.toString();
              obj.displayName = x.name;
              obj.context = "Content";
              obj.contextResourceId = x.contentId.toString();
              obj.contextColumn = "metricValue";
              obj.fileType = formatFileType(x.filePath);
              obj.label = x.name;
              obj.icon =
                obj.fileType == "PDF"
                  ? "mdi-file-pdf-box"
                  : obj.fileType == "DOCX"
                  ? "mdi-file-word"
                  : obj.fileType == "XLSX"
                  ? "mdi-file-excel"
                  : obj.fileType == "PPTX"
                  ? "mdi-file-powerpoint"
                  : obj.fileType == "PNG"
                  ? "mdi-file-image"
                  : obj.fileType == "CSV"
                  ? "mdi-file-delimited"
                  : "mdi-file";
              obj.iconColor = "brand";
              obj.displayGrabber = false;

              obj.createdAtFormatted = moment(obj.createdAt)
                .utc()
                .format("MMM DD, YYYY");
              return obj;
            });
          console.log("Content (within widget): ", resources);
          this.data.resources = resources;

          this.loading.resources = false;

          if (preloadContentId && typeof preloadContentId == "number") {
            const foundContent = resources.find(
              x => x.contentId == preloadContentId
            );
            if (foundContent) this.addToProgram(foundContent);
          }
        })
        .catch(error => {
          console.log("Error getting resources ", error);
          this.loading.resources = false;
        });
    },
    createContent() {
      console.log("Creating content");

      var obj = {
        name: this.content.name,
        description: this.content.description,
        category: this.content.category,
        file: this.content.fileData,
        type: "programs"
      };
      if (this.clientId !== null) {
        console.log("attaching clientId");
        obj.clientId = this.clientId;
      } else {
        console.log("no clientId");
      }

      this.activePage = 2;
      console.log("Creating content, ", obj);
      ContentService.createContent(obj)
        .then(response => {
          console.log("Content created", response);
          if (this.context == "learning") {
            this.addToProgram(response);
            this.$emit("get-content");
          } else if (this.resources)
            this.$emit("get-content", response.contentId);
          else this.getContent(response.contentId);
          this.activePage = 0;
        })
        .catch(error => {
          console.log("Error uploading content ", error);
          this.activePage = 1;
        });
    },
    downloadContent(item) {
      ContentService.downloadContent(item.contentId, item.filePath);
    },
    resetContentForm() {
      this.content = {
        name: null,
        description: null,
        category: "GENERAL_UPLOAD",
        file: null,
        fileData: null,
        fileName: null
      };
      this.contentForm = {
        valid: false,
        selectingFileFlag: false
      };
    },
    loadContentForm() {
      this.activePage = 1;
      this.resetContentForm();
    },
    close() {
      if (this.activePage == 1) {
        this.resetContentForm();
        this.activePage = 0;
        return;
      }
      this.$emit("close");
    },
    addToProgram(resource) {
      // if (this.activeProgram) return;
      console.log("Adding resource to program ", resource);
      this.$emit("add-to-program", resource);
    },
    uploadFile() {
      // this.contentForm.selectingFileFlag = true;
      // window.addEventListener(
      //   "focus",
      //   () => {
      //     this.contentForm.selectingFileFlag = false;
      //   },
      //   { once: true }
      // );

      this.$refs.uploader.click();
    },
    fileChanged(e) {
      if (e.target.files.length > 0) {
        this.content.fileData = e.target.files[0];
        this.content.fileName = e.target.files[0].name;
        this.content.file = URL.createObjectURL(e.target.files[0]);
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
      }
    },
    dragAndDropFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        this.content.fileData = e.dataTransfer.files[0];
        this.content.fileName = e.dataTransfer.files[0].name;
        this.content.file = URL.createObjectURL(e.dataTransfer.files[0]);
      }
    },
    removeFile() {
      this.content.fileData = null;
      this.content.file = null;
      this.content.fileName = null;
    },
    showHideSearch() {
      this.dialog.search = !this.dialog.search;

      this.$nextTick(() => {
        if (this.$refs.search) this.$refs.search.focus();
      });
    },
    updateSortBy(option) {
      console.log("updating sortby ", option);

      var sortOption = this.sort.options.find(x =>
        x.values.includes(this.sort.value)
      );
      // If they're picking an option that's already selected, then they're just changing the sort order.
      if (sortOption == option) {
        var asc = this.sort.value.includes("asc");
        this.sort.value = option.key + "-" + (asc ? "desc" : "asc");
      } else {
        this.sort.value = option.key + "-asc";
        // this.replaceQuery(option.key + "-asc", null);
      }
    },
    timeDiffMilliseconds(x) {
      // The time between now and the create date, in milliseconds
      const now = moment();
      const end = moment(x.createdAt);
      // console.log(now, end);
      // console.log('milliseconds', end.diff(now));
      return end.diff(now);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    visibleResources() {
      let resources = (this.resources || this.data.resources).slice();
      if (this.sort.value) {
        if (this.sort.value.includes("create-date")) {
          resources = resources
            .filter(x => x.createdAt)
            .sort((a, b) => {
              if (isNaN(this.timeDiffMilliseconds(a))) {
                return 1;
              } else if (isNaN(this.timeDiffMilliseconds(b))) {
                return -1;
              }
              return this.sort.value.includes("asc")
                ? this.timeDiffMilliseconds(a) - this.timeDiffMilliseconds(b)
                : this.timeDiffMilliseconds(b) - this.timeDiffMilliseconds(a);
            });
          // .append(modulesToDisplay.filter((x) => !x.createdAt));
        } else {
          // console.log("sorting by name");
          // Sort by name
          resources = resources.sort((a, b) => {
            return this.sort.value.includes("asc")
              ? a.displayName.localeCompare(b.displayName)
              : b.displayName.localeCompare(a.displayName);
          });
        }
      }
      if (!this.data.search) {
        return resources;
      }
      resources = resources.filter(x =>
        x.displayName.toLowerCase().includes(this.data.search.toLowerCase())
      );
      return resources;
    }
  }
};
</script>

<style scoped>
/* Used for the table of resources */
.resource-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
}

/* Class for the boxes around each resource */
.resource-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  /* height: 35px; */
  padding-top: 7px;
}

/* Used for the small plus button for uploading new content */
.add-resource-button {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: var(--v-brand-base);
}
</style>

<template>
  <div class="container-fluid mt-4">
    <v-row justify="center">
      <v-col cols="4">
        <v-autocomplete
          label="Section / Milestone"
          :items="sections"
          item-text="label"
          v-model="section"
          outlined
          return-object
        />
        <v-text-field
          v-model="companyId"
          label="Company Id"
          outlined
        ></v-text-field>
        <p class="mb-1">-- User Details --</p>
        <p class="text-caption">Note: Required before submitting events</p>
        <v-text-field
          v-model="user.id"
          label="Procore User Id"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="user.email"
          label="Email"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="user.name"
          label="User Name"
          outlined
        ></v-text-field>
        <v-btn
          :disabled="!companyId || !section"
          color="brandCyan"
          class="white--text"
          depressed
          @click="getMilestones"
          :loading="loadingMilestones"
          >Load Steps</v-btn
        >
        <p v-if="errorMessage" class="error--text mt-2">{{ errorMessage }}</p>
      </v-col>
      <v-col cols="6" class="px-4">
        <!-- <v-form @submit.prevent="createEvent" class="overflow-auto"> -->
        <div
          v-for="step in milestonesComputed"
          :key="`${step.milestoneTaskId}-${step.section}-${step.taskId}`"
          class="d-flex flex-column justify-start align-start"
        >
          <v-progress-circular
            v-if="
              loadingEvents.includes(
                `${step.milestoneTaskId}-${step.section}-${step.taskId}`
              )
            "
            class="my-4"
            indeterminate
            :size="25"
            :width="3"
            color="brandCyan"
          />
          <v-checkbox
            v-else
            v-model="step.completed"
            :label="step.name"
            color="brandCyan"
            @change="createEvent(step)"
          ></v-checkbox>
          <p class="text-left ml-8 text-caption">
            {{ step.description }}
          </p>
          <p v-if="step.payoutAmount" class="text-left ml-8 text-caption">
            Payout: ${{ step.payoutAmount }}<br />Memo: {{ step.payoutMemo }}
          </p>
          <p
            v-if="step.messageSubject && step.messageBody"
            class="text-left ml-8 text-caption"
          >
            Message: {{ step.messageSubject }}<br />Body:
            {{ step.messageBody }}
          </p>
        </div>

        <!-- <v-select
            label="Resource"
            item-text="text"
            item-value="value"
            v-model="input.backfill.resource"
          /> -->
        <!-- <pre class="text-left">
              {{ input.backfill }}
            </pre> -->
        <!-- <v-btn type="submit"> Backfill! </v-btn>
          <details
            v-for="(response, i) in data.responses"
            :key="i"
            class="text-left"
            :class="
              response.status >= 200 && response.status < 300
                ? 'green--text text--darken-3'
                : 'red--text text--darken-3'
            "
          >
            <summary>Click to view response #{{ i + 1 }}</summary>
            <pre class="grey lighten-2 overflow-auto">{{ response }}</pre>
          </details>
          <template v-if="data.responses.length">
            <div class="green--text" v-if="AllGood">All Good!</div>
            <div v-else>Ruh roh!</div>
          </template> -->
        <!-- </v-form> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import RobinService from "@/services/RobinService";
// import axios from "axios";
// import throttled from "../../util/throttled";
// import fmtRes from "../../util/fmtRes";
import { mapState } from "vuex";
// import moment from "moment";
import ProcoreService from "@/services/ProcoreService";
// import { debounce } from "@/shared_data/functions";

export default {
  name: "ProcoreKickoff",
  props: {},
  components: {},
  watch: {
    companyId: function(n, v) {
      // Clears milestones if company changes
      this.milestones = [];
      this.loadingEvents = [];
      // if (!this.companyId || !this.section) return
      // this.getMilestones();
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "token"]),
    milestonesComputed() {
      if (!this.section?.value || !Array.isArray(this.milestones)) return [];
      return this.milestones.filter(
        x => x.milestoneTaskId == this.section.value
      );
    }
  },
  async mounted() {
    // process.env.VUE_APP_PROCORE_URL
    // const statusResult = await axios.get(
    //   process.env.VUE_APP_PROCORE_URL + "/status/client-company/9",
    //   {
    //     headers: {
    //       Authorization: "Bearer " + this.$store.state.token,
    //     },
    //   }
    // );
    // console.log("Status result:", statusResult);
  },

  data() {
    return {
      sections: [
        { label: "Account", route: "account", value: "account_milestone" },
        { label: "Project", route: "project", value: "project_milestone" },
        { label: "Tool", route: "tool", value: "tool_milestone" }
      ],
      section: null,
      companyId: null,
      user: {
        email: null,
        name: null,
        id: null
      },
      errorMessage: null,
      loadingMilestones: false,
      loadingEvents: [],
      milestones: [],
      data: {
        companies: undefined,
        projects: undefined,
        responses: []
      },
      cache: {},
      input: {
        backfill: {
          dates: [
            new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
            new Date()
          ].map(s => s.toISOString()),
          resource: "Images",
          projects: [],
          companyId: null
        }
      }
    };
  },
  created() {
    this.user.email = this.userProfile.businessEmail;
    this.user.name =
      this.userProfile.displayName ||
      this.userProfile.firstName + " " + this.userProfile.lastName;
  },
  beforeDestroy() {},
  methods: {
    async getMilestones() {
      this.loadingMilestones = true;
      this.errorMessage = null;
      try {
        const res = await ProcoreService.getMilestones({
          companyId: this.companyId
        });
        this.milestones = res.result.rows;
      } catch (e) {
        console.error("Failed to get milestones", e);
        this.errorMessage = "Failure to get milestones & progress";
      } finally {
        this.loadingMilestones = false;
      }
    },
    async createEvent(e) {
      const key = `${e?.milestoneTaskId}-${e?.section}-${e?.taskId}`;
      try {
        console.log("Create event", e);
        this.loadingEvents.push(key);
        const event = {
          data: {
            company_id: this.companyId,
            task_id: e.taskId,
            user: {
              login: this.user.email,
              id: this.user.id,
              name: this.user.name
            }
          },
          completed: e.completed
        };
        await ProcoreService.createEvent(this.section.route, event);
      } catch (error) {
        console.error("Failed to get milestones", error);
        this.errorMessage = `Error submitting task '${
          e.name
        }' - ${error?.detail || "No error details provided"}`;
      } finally {
        this.loadingEvents = this.loadingEvents.filter(x => x != key);
      }
    }

    // async finchConnected(result) {
    // },
    // ...throttled({
    //   async backfill() {
    //     const cfg = this.input.backfill;
    //     let date = moment(cfg.dates[0]);
    //     const end = moment(cfg.dates[1]),
    //       _dates = [];
    //     while (date.diff(end, "days") < 0) {
    //       _dates.push(date.format("YYYY-MM-DD"));
    //       date = date.add(12, "weeks").add(6, "days");
    //     }
    //     _dates.push(end.format("YYYY-MM-DD"));
    //     console.log("_Dates:", _dates);
    //     const responses = [];

    //     if (!(cfg.project instanceof Array)) cfg.project = [cfg.project];
    //     for (const project of cfg.projects) {
    //       console.log("Project", project);
    //       const dates = _dates.slice();
    //       while (dates.length > 1) {
    //         const start = dates.shift();
    //         const end = dates[0];
    //         console.log("POSTING");
    //         const res = await axios.post(
    //           process.env.VUE_APP_PROCORE_URL +
    //             `/methods/v2/backfill/${this.input.backfill.resource}`,
    //           {
    //             start,
    //             end,
    //             company: cfg.companyId,
    //             project
    //           },
    //           {
    //             headers: {
    //               Authorization: "Bearer " + this.$store.state.token
    //             },
    //             validateStatus: () => true
    //           }
    //         );
    //         responses.push(fmtRes(res, false));
    //       }
    //     }
    //     console.log(responses, responses.length);
    //     this.data.responses = responses;
    //   }
    // })
  }
};
</script>

<style scoped>
.client-dropdown {
  width: 350px;
}
</style>
<style lang="css" scoped>
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.table >>> .v-data-table-header {
  background-color: white;
}
</style>

<template>
  <div>
    <v-card-title>
      <v-row>
        <v-col
          class="d-flex flex-column align-start ml-2"
          cols="6"
          sm="6"
          md="6"
          :class="{ hidden: currentSlide >= 3 }"
        >
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1"
            @click="previousSlide"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title>
    <transition :name="slideDirection" mode="out-in">
      <v-row
        v-if="currentSlide == 0"
        key="0"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>There are a few choices to make about your award</h2>
        <v-form ref="form" v-model="validOrderForm" class="full-width px-4">
          <h3 class="align-text-left mt-3">
            {{ award ? "Award: " + award.displayName : null }}
          </h3>
          <v-select
            label="Item Selection"
            :items="award.supplementAwardInfo.physicalItems"
            v-model="sku"
            item-value="sku"
            item-text="name"
            :rules="[v => !!v || 'Item selection is required']"
          />
          <v-select
            label="Quantity"
            :items="quantityArray"
            v-model="quantity"
            :rules="[v => !!v || 'A quantity is required']"
          />
          <v-select
            v-if="selectedSku.length > 0"
            label="Item Selection"
            :items="selectedSku"
            v-model="itemOption"
            item-value="sku"
            item-text="name"
            :rules="[v => !!v || 'Item selection is required']"
          />
          <h3 class="align-text-left mt-3">Available Items:</h3>
          <div
            v-if="
              award &&
                award.supplementAwardInfo &&
                award.supplementAwardInfo.physicalItems
            "
          >
            <div
              v-for="item in award.supplementAwardInfo.physicalItems"
              :key="item.sku"
              class="d-flex mt-4"
            >
              <v-img
                v-if="item.image"
                :src="item.image"
                :aspect-ratio="1 / 1"
                width="150"
                max-width="150"
                height="150"
                class="image-border"
              />
              <v-img
                v-else
                src="@/assets/placeholder_image.png"
                :aspect-ratio="1 / 1"
                width="150"
                max-width="150"
                height="150"
                class="image-border"
              />
              <div class="d-flex flex-column ml-4 align-start">
                <h3>{{ item.name }}</h3>
                <caption class="grey--text">
                  SKU
                  {{
                    item.sku
                  }}
                </caption>
                <p class="mt-4">{{ item.description }}</p>
              </div>
            </div>
          </div>
        </v-form>
      </v-row>
      <v-row
        v-if="currentSlide == 1"
        key="1"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Let's get all the details for your award</h2>
        <caption class="grey--text mt-2">
          We'll need your name and contact info
        </caption>
        <v-form ref="form" v-model="validNameForm" class="full-width px-4">
          <v-text-field
            ref="firstName"
            label="First name"
            v-model="firstName"
            class="mt-4"
            :rules="[
              v => !!v || 'First name is required',
              v => !!(v && v.length < 255) || 'First name should be shorter'
            ]"
          />
          <v-text-field
            ref="lastName"
            label="Last name"
            v-model="lastName"
            class="mt-4"
            :rules="[
              v => !!v || 'Last name is required',
              v => !!(v && v.length < 255) || 'Last name should be shorter'
            ]"
          />
          <v-text-field
            ref="lastName"
            label="Contact email"
            v-model="email"
            class="mt-4"
            :rules="[
              v => !!v || 'Email is required',
              v => !!(v && v.length < 255) || 'Email should be shorter',
              v => (v && emailRegex.test(v)) || 'Email should be valid'
            ]"
          />
          <vue-tel-input-vuetify
            class="mt-4"
            v-model="phone"
            :value="phone"
            label="Phone number (xxx-xxx-xxxx)"
            placeholder="xxx-xxx-xxxx"
            :preferredCountries="['US']"
            :defaultCountry="phoneCountryCode.code"
            :disabledFetchingCountry="true"
            v-on:country-changed="phoneCountryCodeChanged"
            @validate="phoneValidation"
          ></vue-tel-input-vuetify>
        </v-form>
      </v-row>
      <v-row
        v-else-if="currentSlide == 2"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Where are we sending your award?</h2>
        <caption class="grey--text mt-2">
          Let's get your address
        </caption>

        <v-form ref="form" v-model="validAddressForm" class="full-width px-4">
          <v-text-field
            ref="address1"
            label="Address"
            v-model="address1"
            class="mt-4"
            required
            :rules="[
              v => !!v || 'Address is required',
              v => !!(v && v.length < 256) || 'Address should be shorter'
            ]"
          />
          <v-text-field
            label="Apt, suite, unit, etc."
            v-model="address2"
            class="mt-4"
          />
          <v-text-field
            label="City"
            v-model="city"
            class="mt-4"
            required
            :rules="[
              v => !!v || 'City is required',
              v => !!(v && v.length < 40) || 'City should be shorter'
            ]"
          />
          <v-select
            label="State"
            :items="states"
            v-model="state"
            item-value="abbreviation"
            item-text="name"
            :rules="[v => !!v || 'State is required']"
          />
          <v-text-field
            label="Zip Code"
            v-model="postalCode"
            class="mt-4"
            required
            :rules="[
              v => !!v || 'Zip code is required',
              v => !!(v && v.length < 10) || 'Zip code should be shorter'
            ]"
          />
          <v-select
            label="Country"
            :items="countries"
            v-model="country"
            item-value="code"
            item-text="name"
            :rules="[v => !!v || 'Country is required']"
          />
          <v-text-field
            v-if="country == '00'"
            label="Other country (2 digit code)"
            v-model="otherCountry"
            class="mt-4"
            required
            :rules="[
              v => !!v || 'Country is required',
              v =>
                !!(v && v.length == 2) || 'Country code should be 2 characters',
              v =>
                !!(v && countryCodes.includes(v.toUpperCase())) ||
                'Country code must be a valid country'
            ]"
          />
        </v-form>
      </v-row>
      <v-row
        v-else-if="currentSlide == 3"
        key="3"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Processing...</h2>
        <caption class="grey--text mt-2">
          This should only take a few seconds.
        </caption>

        <lottie
          class="mt-6"
          :options="lottie.lottieOptions"
          :height="140"
          :width="140"
          v-on:animCreated="handleLottieAnimation"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == 4"
        key="4"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>CONGRATULATIONS!</h2>
        <caption class="grey--text mt-2">
          Your new order has been placed and will be shipping soon!
        </caption>

        <lottie
          class="mt-6"
          :options="lottie.lottieOptions"
          :height="140"
          :width="140"
          v-on:animCreated="handleLottieAnimation"
        />
      </v-row>
    </transition>
    <v-row
      class="mb-10"
      align="center"
      justify="center"
      v-if="currentSlide != 3"
    >
      <v-col>
        <v-btn
          outlined
          rounded
          :disabled="
            (currentSlide == 1 && (!validNameForm || !phoneNumberValid)) ||
              (currentSlide == 2 && !validAddressForm) ||
              (currentSlide == 0 &&
                (!quantity || !sku || (selectedSku.length > 0 && !itemOption)))
          "
          class="py-5"
          @click="nextSlide"
          ><v-icon class="mr-1" color="brandGreen"
            >mdi-arrow-right-drop-circle</v-icon
          ><span class="mt-1">{{
            currentSlide != 4 ? "Next" : "Done"
          }}</span></v-btn
        ></v-col
      >
    </v-row>
  </div>
</template>

<script>
import {
  states,
  countries,
  countryCodes,
  emailRegex
} from "@/shared_data/data.js";
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Flying.json";

import { mapState } from "vuex";
import BudgetService from "../services/BudgetService";
import UserService from "../services/UserService";

export default {
  name: "AwardRedemption",
  props: {
    awards: Array,
    assetCode: String
  },
  components: {
    Lottie
  },
  data() {
    return {
      lottie: {
        lottieOptions: {
          animationData: animationData.default,
          loop: true,
          autoplay: true
        },
        anim: null
      },
      currentSlide: 0,
      slideDirection: "topic-left",
      validOrderForm: false,
      validNameForm: false,
      validAddressForm: false,
      states,
      countries,
      countryCodes,
      emailRegex,

      quantity: null,
      sku: null,
      itemOption: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      formattedPhone: null,
      phoneCountryCode: { code: null, value: null },
      phoneNumberValid: false,
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
      otherCountry: null
    };
  },
  created() {
    this.firstName = this.userProfile.firstName;
    this.lastName = this.userProfile.lastName;
    this.email = this.userProfile.personalEmail;

    this.address1 = this.userProfile.homeAddress;
    this.address2 = this.userProfile.homeAddress2;

    this.sku = null;
    this.quantity = null;
    this.itemOption = null;
    this.city = this.userProfile.homeCity;
    this.state = this.userProfile.homeState;
    this.postalCode = this.userProfile.homePostalCode;
    if (
      this.userProfile.businessCountry == "US" ||
      this.userProfile.businessCountry == "GB"
    ) {
      this.country = this.userProfile.businessCountry;
    } else {
      this.country = "00";
      this.otherCountry = this.userProfile.businessCountry;
    }

    this.phoneCountryCode.code = this.userProfile.homeCountry;

    if (
      this.userProfile.personalPhone !== null &&
      this.userProfile.personalPhone.includes("+")
    ) {
      var personalPhone = this.userProfile.personalPhone.substring(
        this.userProfile.personalPhone.indexOf("-") + 1
      );
      // this.user.formattedBusinessPhone =
    } else {
      personalPhone = this.userProfile.personalPhone;
    }

    this.phone = personalPhone;
  },
  beforeDestroy() {},
  methods: {
    handleLottieAnimation: function(anim) {
      this.lottie.anim = anim;
    },
    resetForm() {
      this.quantity = null;
      this.sku = null;
      this.itemOption = null;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.phone = null;
      this.formattedPhone = null;
      this.address1 = null;
      this.address2 = null;
      this.city = null;
      this.state = null;
      this.country = null;
      this.otherCountry = null;
      this.postalCode = null;
      this.phoneCountryCode = {
        code: null,
        value: null
      };
      if (this.currentSlide <= 3) {
        this.$refs.form.reset();
      }
      this.$emit("reset");
      this.currentSlide = 0;
    },
    previousSlide() {
      this.$emit("scrollToTop");
      if (this.currentSlide == 0) {
        this.resetForm();
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      this.$emit("scrollToTop");
      if (this.currentSlide == 0 && this.selectedRequiresShipping == false) {
        this.currentSlide += 3;
      } else {
        this.currentSlide += 1;
      }
      if (this.currentSlide == 3) {
        // We are submitting api call
        this.submitOrder();
      } else if (this.currentSlide == 5) {
        this.resetForm();
      }
    },
    phoneValidation({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log("Phone valid " + isValid, number);
      if (isValid) {
        this.formattedPhone = number.e164; //.rfc3966.replace("tel:", "");
        this.phoneNumberValid = true;
      } else {
        this.phoneNumberValid = false;
      }
    },
    phoneCountryCodeChanged(country) {
      this.phoneCountryCode.code = country.iso2;
      this.phoneCountryCode.value = country.dialCode;
    },
    submitOrder() {
      var awardOrderObj = {
        clientId: this.userProfile.clientId,
        userId: this.userProfile.userId,
        awardId: this.award.awardId,
        awardFulfillmentPartnerId: this.award.supplementAwardInfo.physicalItems.find(
          x => x.sku == this.sku
        ).awardFulfillmentPartnerId,
        sku: this.sku,
        selectedOption: this.itemOption,
        quantity: this.quantity,
        firstName: this.firstName,
        lastName: this.lastName,
        contactEmail: this.email,
        contactPhone: this.formattedPhone,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        country: this.country != "00" ? this.country : this.otherCountry,
        orderDate: new Date(),
        shipDate: null,
        trackingNumber: null,
        orderStatus: "Ordered",
        comment: this.lastName,
        createdBy: this.userProfile.userId,
        updatedBy: this.userProfile.userId
      };

      var userObj = {
        clientId: this.userProfile.clientId,
        userId: this.userProfile.userId,
        personalPhone: this.formattedPhone,
        personalEmail: this.email,
        homeAddress: this.address1,
        homeAddress2: this.address2,
        homeCity: this.city,
        homeState: this.state,
        homePostalCode: this.postalCode,
        homeCountry: this.country != "00" ? this.country : this.otherCountry
      };
      console.log(awardOrderObj);
      BudgetService.createAwardOrder(awardOrderObj).then(orderResp => {
        console.log("Award Order response: ", orderResp);
        UserService.updateUser(
          this.userProfile.userId,
          userObj,
          this.magicLinkToken
        ).then(userResp => {
          console.log("User update response: ", userResp);
          this.$store.dispatch("setUser", this.userProfile.userId);
          this.$emit("update-user");
          this.currentSlide++;
        });
      });
    }
  },
  computed: {
    ...mapState(["userProfile", "balances", "magicLinkToken"]),
    award() {
      if (this.assetCode) {
        var award = this.awards.find(x => x.stellarAssetCode == this.assetCode);
        if (award) {
          return award;
        }
      }
      return null;
    },
    quantityArray() {
      if (this.assetCode) {
        var balance = this.balances.find(x => x.asset_code == this.assetCode);
        if (balance) {
          return Array.from(
            { length: parseInt(balance.balance) },
            (_, i) => i + 1
          );
        }
      }
      return [];
    },
    selectedSku() {
      if (this.award && this.sku) {
        console.log("Test1: ", this.award);
        if (
          this.award.supplementAwardInfo &&
          this.award.supplementAwardInfo.physicalItems
        ) {
          var item = this.award.supplementAwardInfo.physicalItems.find(
            x => x.sku == this.sku
          );
          console.log("Test2");
          if (item) {
            return item.options;
          }
        }
      }
      return [];
    },
    selectedRequiresShipping() {
      if (this.award && this.sku) {
        console.log("Test1: ", this.award);
        if (
          this.award.supplementAwardInfo &&
          this.award.supplementAwardInfo.physicalItems
        ) {
          var item = this.award.supplementAwardInfo.physicalItems.find(
            x => x.sku == this.sku
          );
          console.log("Test2:");
          console.log(item);
          if (item) {
            return item.requireShippingInfo;
          }
        }
      }
      return [];
    }
  }
};
</script>

<style scoped>
.card-text {
  font-size: 1.2em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.image-border {
  border: 1px solid;
  border-color: grey;
}
</style>

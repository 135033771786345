import axios from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_RULES_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-rules"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-rules" : "/rules-service"
    }`;

class RulesService {
  getRuleGroups(ruleGroupId = null) {
    return axios
      .get(baseUrl + "/rules" + (ruleGroupId !== null ? "/" + ruleGroupId : ""))
      .then(response => {
        return response.data.ruleGroup;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getRuleGroupsforGroups() {
    return axios
      .get(baseUrl + "/rules/groupRules")
      .then(response => {
        return response.data.ruleGroup;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getRuleGroupsFromContext(context, type, id) {
    return axios
      .get(baseUrl + "/rules/" + context + "/" + type + "/" + id)
      .then(response => {
        return response.data.ruleGroup;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  estimateRuleGroup(context, rules, clientId) {
    return axios
      .post(baseUrl + "/rules/estimate", {
        context: context,
        rules: rules,
        clientId: clientId
      })
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        throw error;
      });
  }
  deleteByContext(context, type, idArray) {
    var pairs = idArray.map(function(value) {
      return "id=" + encodeURIComponent(value);
    });
    var query_string = pairs.join("&");
    return axios
      .delete(baseUrl + "/rules/" + context + "/" + type + "/" + query_string)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
}

export default new RulesService();

<template>
  <div class="full-height" rounded="0">
    <div class="d-flex flex-column justify-space-between full-height pt-6">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="12" sm="7" md="8" lg="7" xl="7">
          <div class="rounded-lg py-5">
            <transition :name="slideDirection" mode="out-in">
              <v-card
                v-if="survey !== null"
                :key="0"
                class="main-card text-left"
                elevation="0"
                rounded="2"
              >
                <div class="main-card-content">
                  <Survey
                    :moduleId="survey.learningModuleId"
                    :displayName="survey.displayName"
                    :chapterId="
                      survey.LearningChapters[currentChapterIndex]
                        .learningChapterId
                    "
                    :currentLessonCard="currentLesson"
                    :completedStatus="surveyCompleted"
                    :whistleToken="token.token"
                    :email="email"
                    :surveyClientId="survey.clientId"
                    @surveyCompleted="goToNextChapter"
                    @update-completed="surveyCompleted = $event"
                    :isSurveyRepeatable="isSurveyRepeatable"
                    @retakeSurvey="restartSurvey"
                    :isConclusion="isConclusion"
                  /></div
              ></v-card>
              <v-card
                v-else
                :key="1"
                class="main-card text-left"
                elevation="0"
                rounded="2"
              >
                <div class="main-card-content">
                  <p class="brandCyan--text font-italic font-weight-bold mx-8">
                    Welcome
                  </p>
                  <p class="text-h6 font-weight-bold mx-8 mt-10 email-text">
                    Please enter your email address. It's used to create a
                    unique record for your survey answers.
                  </p>
                  <v-form v-model="valid" onSubmit="return false;">
                    <v-text-field
                      v-model="email"
                      :disabled="loadingSurvey"
                      autofocus
                      label="Email"
                      color="brandCyan"
                      @keydown.enter="captureEmail"
                      class="mx-8 mt-8"
                      :rules="[
                        v => !!v || 'An email is required',
                        v =>
                          !!(v && v.length < 255) ||
                          'The email should be shorter',
                        v =>
                          /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(
                            v
                          ) || 'Email must be valid',
                        v =>
                          !!(v && v.length < 256) || 'Email should be shorter'
                      ]"
                    ></v-text-field>
                  </v-form>
                  <p class="grey--text mx-8 font-italic">Required</p>
                  <div class="d-flex flex-column align-center mt-9">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      width="120"
                      :loading="loadingSurvey"
                      @click="captureEmail"
                      :disabled="!valid"
                      >Submit</v-btn
                    >
                    <p class="red--text text-center mt-5">{{ errorMessage }}</p>
                  </div>
                </div></v-card
              >
            </transition>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="start" class="full-width mt-3" no-gutters
        ><v-col>
          <span class="darkGrey--text"
            >{{ (survey && survey.displayName) || "Survey" }} | Powered by
            <a
              class="font-weight-bold darkGrey--text cursor-pointer text-decoration-none"
              href="https://wewhistle.com"
              target="_blank"
              >Whistle</a
            ></span
          >
        </v-col></v-row
      >
    </div>
  </div>
</template>

<script>
import Survey from "../components/SurveyV2.vue";

import TokenService from "@/services/TokenService";
import LearningService from "@/services/LearningService";

import { mapState } from "vuex";
import moment from "moment";
import confetti from "canvas-confetti";

export default {
  name: "SurveysPublicV2",
  title: "Whistle | Surveys",
  components: {
    Survey
  },
  props: {},
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      surveyId: null,
      // Entire object
      survey: null,
      email: null,
      token: null,
      valid: false,
      loadingSurvey: false,
      forcedLinearProgress: null,
      // First incomplete chapter
      currentChapterIndex: null,
      // currentLesson is the strinified version of the first incomplete card
      currentLesson: null,
      // First incomplete lesson
      currentLessonIndex: null,
      surveyCompleted: null,
      errorMessage: null,
      currentSlide: 0,
      keys: {
        // First screen always. Shows email slide
        email: 0,
        // When saving agreements
        loading: 1,
        // Intro to card screen
        success: 3,
        // general error screen
        error: 4
      },
      slideDirection: "topic-left"
    };
  },
  mounted() {
    this.surveyId = this.$route.params.surveyId;
  },
  beforeDestroy() {},
  methods: {
    captureEmail() {
      // Clearing error message in case of invalid email
      if (!this.valid || this.loadingSurvey) return;
      this.errorMessage = null;
      this.getSurveyAndToken();
    },
    getSurveyAndToken() {
      this.loadingSurvey = true;
      console.log("getting survey and token!", this.email);

      // Clearing error message JUST in case!
      this.errorMessage = null;
      LearningService.getPublicSurveyAndToken(this.surveyId, this.email)
        .then(response => {
          console.log("WOOT RESPONSE AHEAD!");
          console.log(response);

          // this is checking for an empty response
          if (Object.values(response).length > 0) {
            const survey = JSON.parse(JSON.stringify(response.data.module));
            const token = JSON.parse(JSON.stringify(response.data.token));
            this.token = token;
            this.survey = survey;
            console.log("SURVEY!!!!", this.survey);

            if (this.survey.LearningChapters.length >= 1) {
              // This boolean will flip once we parse the chapters/cards and we find the first instance of something not completed. Then we set that as our starting point.
              // TODO: Not sure about this logic and how it will be for surveys
              var completeBool = false;
              this.survey.LearningChapters.forEach((chapter, chapterIndex) => {
                // looping through cards (Q's) in chapter
                chapter.LearningCards.forEach((card, cardIndex) => {
                  // iterating through cards, executes first incomplete card (Q)
                  if (
                    (!card.latestLearningLog ||
                      (card.latestLearningLog &&
                        !card.latestLearningLog.completed)) &&
                    !completeBool
                  ) {
                    this.currentChapterIndex = chapterIndex;
                    this.currentLessonIndex = cardIndex;
                    // copy of card object
                    this.currentLesson = JSON.parse(JSON.stringify(card));
                    console.log(
                      "!!!! Found card that isn't completed !!!!",
                      card
                    );

                    // completed is probably false since that was the condition of the block
                    this.surveyCompleted = card.latestLearningLog
                      ? card.latestLearningLog.completed
                      : false;
                    // stops loop after first incomplete card
                    completeBool = true;
                  }

                  // Finally, we check if completeBool was never flipped, aka they finished the entire thing. If so, we set the current chpt and card index to last
                  // We only check this if we've cycled through all chapters and cards and are at the end
                  if (
                    !completeBool &&
                    chapterIndex == this.survey.LearningChapters.length - 1 &&
                    cardIndex == chapter.LearningCards.length - 1
                  ) {
                    console.log(
                      "Entire survey is complete",
                      this.survey.LearningChapters[chapterIndex]
                    );
                    this.currentChapterIndex = chapterIndex;

                    if (
                      this.survey.LearningChapters[chapterIndex].LearningCards
                        .length > 0
                    ) {
                      this.currentLessonIndex = cardIndex;
                      this.currentLesson = JSON.parse(
                        JSON.stringify(
                          this.survey.LearningChapters[chapterIndex]
                            .LearningCards[cardIndex]
                        )
                      );
                      console.log(this.currentLesson);
                      this.surveyCompleted = true;
                    }
                  }
                });
              });
            }
          } else {
            // TODO: handle errors, this one is probably a weird error if we made it here
            console.log("!!!!ERROR!!!!!");
            this.errorMessage = "Something went wrong, please try again.";
          }
        })
        .catch(err => {
          console.log("Error when fetching survey ", err);
          let errorCode = err.data ? err.data.error_code : "";
          let code = Number(errorCode.slice(-2));
          switch (code) {
            case 11:
              this.errorMessage = "Invalid email address, please try again.";
              this.email = null;
              break;
            case 12:
              this.errorMessage =
                "You'll need to sign in to your Whistle account to take this survey.";
              break;
            case 13:
              this.errorMessage =
                "Whoops, we're having trouble finding that survey.";
              break;
            case 14:
              this.errorMessage =
                "It looks like you've already taken this survey.";
              break;
            default:
              this.errorMessage = "Something went wrong. Please try again.";
          }
        })
        .finally(() => {
          this.loadingSurvey = false;
        });
    },
    isTokenValid() {
      if (
        moment(this.token.createdAt).add(this.token.tokenTTL, "seconds") <
        moment().utc()
      ) {
        return false;
      } else {
        return true;
      }
    },
    getNewToken() {
      console.log("!!!!!!!In public survey, getting new token!!!!!!!");
      let tokenArr = [];
      let body = {
        clientId: this.token.clientId,
        externalUserId: this.token.externalUserId,
        ipAddress: null,
        context: this.token.context,
        contextResourceId: this.token.contextResourceId,
        tokenTTL: this.token.tokenTTL,
        numUses: this.token.numUses,
        createdBy: this.token.createdBy,
        updatedBy: this.token.createdAt
      };
      return TokenService.createToken(body).then(res => {
        tokenArr = res;
        let sortedArr = tokenArr.sort((a, b) => {
          return moment(a.createdAt).diff(b.createdAt);
        });
        let latestToken = sortedArr[tokenArr.length - 1];
        return latestToken;
      });
    },
    goToNextChapter() {
      // Is next chapter last?
      // This banks on all surveys having a conclusion
      let nextChapter = this.currentChapterIndex + 1;
      // is next index less than survey length - 1 (last chapter index)
      if (nextChapter < this.survey.LearningChapters.length - 1) {
        // post to learning log before incrimenting current chapter
        this.postSurveyLearningLog(false, []);
        // move to next chapter
        this.currentChapterIndex = this.currentChapterIndex + 1;
        // current card object
        let currentCard = this.survey.LearningChapters[this.currentChapterIndex]
          .LearningCards[0];
        // reassign currentLesson to first card in learning chapters
        this.currentLesson = JSON.parse(JSON.stringify(currentCard));
      } else {
        // we must be at the end! move to next chapter - conclusion card!
        // Post log for current chapter completion
        console.log("Posting learning log for quiz x 2");
        var logs = this.postSurveyLearningLog(true, []);

        // then incriment and post log for conclusion completion
        this.currentChapterIndex = this.currentChapterIndex + 1;
        // current card object
        let currentCard = this.survey.LearningChapters[this.currentChapterIndex]
          .LearningCards[0];
        // reassign currentLesson to first card in learning chapters
        this.currentLesson = JSON.parse(JSON.stringify(currentCard));
        console.log("The end!");
        this.postSurveyLearningLog(false, logs);

        console.log("completed survey");
        this.surveyCompleted = true;

        // Now we show confetti!
        this.playConfetti();
      }
    },
    // This accepts a boolean to tell the functino if we return the log array rather than inserting
    // This way we can compile an array rather than inserting individually.
    // The extraLogs are the previous array of logs passed in.
    async postSurveyLearningLog(returnLog = false, extraLogs = []) {
      if (this.isTokenValid() === false) {
        let newToken = await this.getNewToken();
        this.token = newToken;
      }
      console.log("Posting Survey Learning Log!!");
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      var body = {
        externalUserId: this.token.externalUserId,
        userId: this.userProfile ? this.userProfile.userId : null,
        clientId: this.token.clientId,
        learningCardId: this.currentLesson.learningCardId,
        learningCardAssociationId: this.currentLesson.learningCardAssociationId,
        completed: true,
        completedDate: dateTime,
        learnerRating: null,
        learnerConfidence: null,
        score: null
      };

      console.log("-------------this is the new log-------------");
      console.log(body);

      // we must await the Promise result here since we call it kind of recursively
      var logs = await extraLogs;
      logs.push(body);

      if (!returnLog) {
        console.log("Inserting " + logs.length + " learning logs.");
        LearningService.createLearningLog(logs, [], this.token.token);
      } else {
        return logs;
      }
    },
    restartSurvey() {
      console.log("restarting survey!!!");
      // reset data
      this.surveyCompleted = false;
      this.currentChapterIndex = 0;
      this.currentLessonIndex = 0;

      // with a public survey, the data objects look a little weird
      // each learningCard has a .LearningCard obj as well, but survey fetches the card itself with the id
      this.currentLesson = JSON.parse(
        JSON.stringify(
          this.survey.LearningChapters[this.currentChapterIndex].LearningCards[
            this.currentLessonIndex
          ]
        )
      );
      console.log(this.currentLesson);
      console.log("reset current lesson");
    },
    async playConfetti(type = "normal") {
      console.log("play confetti! type:" + type);
      // We're in the last chapter, play confetti

      const confettiColors = Array();
      //blues
      confettiColors[0] = ["#0dace9", "#0394da", "#56c4f9", "#3fe6ff"];
      //greens
      confettiColors[1] = ["#a1d86f", "#69c69d", "#65c733", "#0fe283"];

      //purples
      confettiColors[2] = ["#720fe2", "#9440f2", "#8822df", "#6a029f"];

      //pinks
      confettiColors[3] = ["#c101f1", "#e627f7", "#c500a2", "#ec4ed0"];

      //yellow/orange
      confettiColors[4] = ["#f1cc00", "#ffda6b", "#fac129", "#ffdc1b"];

      //rainbow
      confettiColors[5] = [
        "#f1cc00",
        "#e627f7",
        "#6a029f",
        "#a1d86f",
        "#3fe6ff"
      ];

      if (type != "special") {
        var rando = Math.floor(Math.random() * (confettiColors.length - 2));
        console.log(rando);
        var randColor = confettiColors[rando];
      } else {
        randColor = confettiColors[5];
      }

      confetti({
        particleCount: 300,
        zIndex: 999,
        spread: 70,
        scalar: 1.13,
        colors: randColor,
        origin: { y: 0.9, x: 0.56 }
      });
      confetti({
        particleCount: 120,
        spread: 200,
        decay: 0.91,
        scalar: 0.8,
        zIndex: 999,
        colors: randColor,
        origin: { y: 0.8, x: 0.52 }
      });
      confetti({
        particleCount: 150,
        spread: 120,
        startVelocity: 45,
        scalar: 0.8,
        zIndex: 999,
        colors: randColor,
        origin: { y: 0.6, x: 0.54 }
      });

      if (type == "special") {
        await new Promise(resolve => setTimeout(resolve, 300));

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[0],
          origin: { y: 0.3, x: 0.2 }
        });

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[1],
          origin: { y: 0.2, x: 0.8 }
        });
        await new Promise(resolve => setTimeout(resolve, 200));

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[1],
          origin: { y: 0.74, x: 0.2 }
        });

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[0],
          origin: { y: 0.74, x: 0.8 }
        });
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "postItArray"]),
    isSurveyRepeatable() {
      if (!this.survey || !this.survey.LearningChapters) return false;
      let repeatBool = false;
      // loop through all cards and if any are repeatable flip boolean!!
      // TODO: check if this is an okay way to handle things
      for (let i = 0; i < this.survey.LearningChapters.length; i++) {
        let currentCh = this.survey.LearningChapters[i];
        for (let j = 0; j < currentCh.LearningCards.length; j++) {
          if (currentCh.LearningCards[j].repeatable == true) {
            repeatBool = true;
            break;
          }
        }
      }
      return repeatBool;
    },
    isConclusion() {
      // surveys have one chapter and a conclusion chapter
      // if chIndex is 1, it's the conclusion
      return this.currentChapterIndex === 1;
    }
  }
};
</script>

<style scoped>
/* Controls styling for the card component in 
the main container. Shows survey card */
.main-card {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px 10px 10px 10px !important;
}

/* Inner content for main card so it has smaller margins when on mobile*/
.main-card-content {
  min-width: 60%;
  height: 100%;
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media screen and (min-width: 960px) {
  .main-card-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 451px) {
  .main-card-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 450px) {
  .main-card-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* Reduces the line height of the text asking to enter email address */
.email-text {
  line-height: 1.3em;
}
</style>

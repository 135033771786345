<template>
  <div>
    <v-card-title class="d-flex justify-space-between align-start">
      <div
        class="d-flex flex-column align-start ml-2"
        sm="4"
        md="4"
        :class="{ hidden: currentSlide == 1 }"
      >
        <v-btn
          fab
          dark
          small
          elevation="0"
          class="pa-2 mb-1 ml-1"
          @click="previousSlide"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span>Back</span>
      </div>
      <b class="mt-1 card-text">Expense Report</b>
      <v-btn fab small elevation="0" class="pa-2 mb-1 ml-1 hidden"> </v-btn>
    </v-card-title>
    <transition :name="slideDirection" mode="out-in">
      <v-row
        v-if="currentSlide == 0"
        key="0"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <div class="d-flex justify-space-between align-center full-width">
          <div class="d-flex align-center">
            <span
              class="mr-3 error-background per-diem-alert white--text pt-1"
              >{{ perDiems.length }}</span
            >
            reports to complete
          </div>
          <v-btn
            color="primary"
            :disabled="validRulesArray.includes(false)"
            @click="updatePerDiems(-1)"
            >Accept all</v-btn
          >
        </div>
        <div
          class="mt-6 align-text-left full-width d-flex flex-column align-center"
          v-for="(rule, i) in perDiems"
          :key="rule.paymentRuleId"
        >
          <b class="card-text full-width"> {{ rule.startDateFormatted }}</b>

          <v-form ref="form" v-model="rule.validForm" class="full-width px-4">
            <v-text-field
              class="mt-4"
              v-model="rule.spent"
              disabled
              label="Amount Spent"
            ></v-text-field>
            <v-text-field
              class="mt-4"
              v-model="rule.location"
              label="Location"
              :rules="[
                (v) => !!v || 'Location is required',
                (v) => !!(v && v.length < 256) || 'Location should be shorter',
              ]"
            ></v-text-field>
            <v-text-field
              class="mt-4"
              v-model="rule.purpose"
              label="Purpose"
              :rules="[
                (v) => !!v || 'Purpose is required',
                (v) => !!(v && v.length < 256) || 'Purpose should be shorter',
              ]"
            ></v-text-field>
          </v-form>
          <v-btn
            color="primary"
            class="mx-auto"
            :disabled="!validRulesArray[i]"
            @click="updatePerDiems(i)"
            >Accept & Submit</v-btn
          >
        </div>
      </v-row>
      <v-row
        v-else-if="currentSlide == 1"
        key="1"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Processing...</h2>
        <caption class="grey--text mt-2">
          This should only take a few seconds.
        </caption>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == 2"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>ALL DONE!</h2>
        <caption class="grey--text mt-2">
          Your per diems have been submitted!
        </caption>
        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="backflip"
          :loop="false"
        />
      </v-row>
    </transition>
    <v-row
      class="mb-10"
      align="center"
      justify="center"
      v-if="currentSlide == 2"
    >
      <v-col>
        <v-btn class="py-5" @click="nextSlide" color="primary">
          Done
        </v-btn></v-col
      >
    </v-row>
  </div>
</template>

<script>
import Robin from "@/components/Robin";

import moment from "moment";
import { mapState } from "vuex";
import WalletService from "../services/WalletService";

export default {
  name: "PerDiemReports",
  props: {},
  components: {
    Robin,
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      dialogEmailError: false,
      rules: [],
    };
  },
  created() {
    this.rules = JSON.parse(
      JSON.stringify(
        this.userProfile.PaymentRules.filter((x) => x.displayInPerDiemReports)
      )
    );
  },
  beforeDestroy() {},
  methods: {
    updatePerDiems(index) {
      var startTime = new Date();
      this.currentSlide = 1;

      if (index == -1) {
        var paymentRules = this.perDiems;
      } else {
        paymentRules = [this.perDiems[index]];
      }

      paymentRules = paymentRules.map((x) => {
        x.updatedBy = this.userProfile.userId;
        x.signoffDate = new Date();
        delete x.updatedAt;

        return x;
      });
      console.log("Payment rule: ", paymentRules);

      // WalletService;
      WalletService.createPaymentRulesBulk(paymentRules)
        .then((rules) => {
          console.log("Response: ", rules);
          // We check the time elapsed since sending the request. If the user has been waiting less than 1.5 seconds, we show the animation so it doesn't flash on the screen
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff < 1500) {
            setTimeout(() => {
              // Now we remove those from the per diems they need to sign for. If there's none then we show success
              if (index != -1) {
                this.rules.splice(index, 1);
              } else {
                this.rules = [];
              }
              if (this.rules.length == 0) {
                this.currentSlide = 2;
              } else {
                this.currentSlide = 0;
              }
              this.$emit("get-wallet");
            }, 1500 - timeDiff);
          } else {
            if (index != -1) {
              this.rules.splice(index, 1);
            } else {
              this.rules = [];
            }
            if (this.rules.length == 0) {
              this.currentSlide = 2;
            } else {
              this.currentSlide = 0;
            }
            this.$emit("get-wallet");
          }
        })
        .catch((error) => {
          console.log("There was an error getting user: ", error);
          this.currentSlide++;
        });
    },
    resetForm() {
      // if (this.currentSlide <= 3) {
      //   this.$refs.form.reset();
      // }

      this.$emit("get-wallet");
      this.$emit("scrollToTop");
      this.$emit("reset");
      this.currentSlide = 0;
    },
    previousSlide() {
      this.$emit("scrollToTop");
      if (this.currentSlide == 0 || this.currentSlide == 2) {
        this.resetForm();
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      this.$emit("scrollToTop");
      this.currentSlide += 1;
      if (this.currentSlide == 3) {
        this.resetForm();
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "displayWalletMenu", "walletMenuScreen"]),
    perDiems() {
      // var rules =
      // JSON.parse(
      //   JSON.stringify(

      //   )
      // );
      this.rules.forEach((rule) => {
        rule.startDateFormatted = moment(rule.startDate)
          .local()
          .format("dddd, MMMM Do, YYYY");
      });
      return this.rules;
    },
    validRulesArray: {
      cache: false,
      get() {
        var validArray = [];
        this.perDiems.forEach((rule) => {
          if (rule.purpose && rule.location) {
            validArray.push(true);
          } else {
            validArray.push(false);
          }
        });
        return validArray;
      },
    },
  },
  watch: {
    // displayWalletMenu(val) {
    //   console.log(val);
    //   if (val && this.walletMenuScreen == "update-user") {
    //     this.getMarqetaUser();
    //   }
    // },
  },
};
</script>

<style scoped>
.card-text {
  font-size: 1.2em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}
.per-diem-alert {
  border-radius: 50px;
  width: 31px;
  height: 30px;
}
.error-background {
  background: var(--v-error-base);
}
</style>

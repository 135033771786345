<template>
  <v-navigation-drawer
    v-if="display"
    v-model="display"
    temporary
    fixed
    right
    width="500"
  >
    <v-toolbar dark color="brandOrange" rounded="0" class="" flat>
      <v-toolbar-title>{{ formattedTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="display = false">
          Close
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div
      :class="{ 'px-8 mt-15': method != 'email' || loading || errorMessage }"
    >
      <div class="d-flex flex-column align-center" v-if="loading">
        <p>Loading message preview...</p>
        <v-progress-circular
          color="brandCyan"
          indeterminate
          :size="55"
          :width="4"
        ></v-progress-circular>
      </div>
      <p v-else-if="errorMessage" class="error--text">{{ errorMessage }}</p>
      <div v-else-if="content" class="d-flex justify-center">
        <div v-if="method == 'activity'" class="full-width text-center">
          <ActivityFeedCard
            :key="content.messageLogId"
            :id="content.contextResourceId"
            :messageId="content.messageId"
            :icon="content.icon"
            :iconColor="content.iconColor"
            :context="content.context"
            :subcontext="content.subcontext"
            :title="content.cardTitle"
            :date="content.date"
            :messageTitle="content.messageSubject"
            :messageSubtitle="content.messageShortBody"
            :messageBody="content.messageBody"
            :messageSuccessImage="content.successImage"
            :image="content.imageURL"
            :linkText="content.linkText"
            :object="content"
            :messageTitleFlag="content.titleFlag"
            :messageSubtitleFlag="content.subtitleFlag"
            :messageIconFlag="content.messageIconFlag"
            :robinFlag="false"
            :imageFlag="content.displayImageInCardPreview"
            :progressFlag="content.progressFlag"
            :likedByUser="content.likedByUser"
            :linkButtonFlag="true"
            :fullMessage="content.context == 'recognition'"
            :comments="content.comments"
            :reactions="content.reactions"
            :values="content.values"
            :recipients="content.recipients"
            :privateRecognition="content.privateRecognition"
            :newMessage="!content.readOnDashboard"
            @create-comment="content.comments.push($event)"
          />
        </div>
        <p
          v-else
          v-html="content"
          class="mb-0 text-left"
          :class="{ email: method == 'email' }"
        ></p>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import CommunicationService from "@/services/CommunicationService";

import ActivityFeedCard from "@/components/ActivityFeedCardV2.vue";

export default {
  name: "MessagePreviewDrawer",
  components: { ActivityFeedCard },
  props: {
    method: {
      type: String,
      default: "sms"
    },
    messageSubject: String,
    messageShortBody: String,
    messageBody: String,
    context: String,
    subcontext: String,
    imageURL: String,
    contextResourceId: Number,
    clientId: Number,

    centerText: {
      type: Boolean,
      default: false
    },
    button1Width: {
      type: String,
      default: "170"
    },
    button2Width: {
      type: String,
      default: "170"
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: true,
      loading: false,
      errorMessage: null,
      content: null
    };
  },
  created() {
    console.log("Created MessagePreviewDrawer");
    this.previewMessage();
  },

  methods: {
    async previewMessage() {
      try {
        this.loading = true;
        this.errorMessage = null;
        let body = {
          messageSubject: this.messageSubject,
          messageShortBody: this.messageShortBody,
          messageBody: this.messageBody,
          contextResourceId: this.contextResourceId,
          context: this.context,
          subcontext: this.subcontext,
          clientId: this.clientId,
          imageURL: this.imageURL
        };
        let response = await CommunicationService.previewMessage(
          this.method,
          body
        );

        console.log("Message preview response ", response);
        this.content = response;
      } catch (err) {
        console.log("Error previewing ", err);
        this.errorMessage = "There was an issue previewing the message";
      } finally {
        this.loading = false;
      }
    },
    clickButton1() {
      this.$emit("button1");
    },
    clickButton2() {
      this.$emit("button2");
    }
  },
  computed: {
    formattedTitle() {
      let start;
      if (this.method == "push") start = "Whistle App";
      else if (this.method == "email") start = "Email";
      else if (this.method == "activity") start = "Activity";
      else start = "Text";
      return `${start} Message Preview`;
    }
  },
  watch: {
    display: function(newVal) {
      if (!newVal) this.$emit("close");
    }
  }
};
</script>

<style>
/* Stops them from clicking any links in the email HTML */
.email {
  pointer-events: none !important;
}
</style>

<template>
  <div class="full-height" style="background: #fff;">
    <v-row class="py-4 mx-0" no-gutters>
      <v-col cols="12" sm="12" md="4">
        <v-row no-gutters class="mb-3 mx-2">
          <h2 class="mb-3 word-break text-left">
            {{ userProfile.firstName }}'s Wallet
            <!-- <v-icon class="ml-1" size="large"
              >mdi-information-variant-circle-outline</v-icon
            > -->
          </h2>
          <MarqetaWidgetManager
            ref="marqeta-widget"
            :previousRoute="previousRoute"
            @scroll-top="scrollToTop"
          />
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <v-row no-gutters class="mx-2 mb-2 d-flex justify-space-between">
          <!-- <div class="full-width d-flex align-center"> -->
          <div class="d-flex text-left">
            <h2
              class="cursor-pointer"
              :class="{
                'grey--text': tab != keys.myRewards,
                'ml-6': !isMobile
              }"
              @click="tab = keys.myRewards"
            >
              My rewards
            </h2>
            <h2
              v-if="adminView"
              class="mx-16 cursor-pointer"
              :class="{ 'grey--text': tab != keys.rewardsSent }"
              @click="tab = keys.rewardsSent"
            >
              Rewards sent
            </h2>
          </div>
          <div class="d-flex align-start">
            <v-checkbox
              v-if="tab == keys.rewardsSent && displayOtherRewardsCheckbox"
              label="Show rewards sent by others"
              v-model="display.admin"
              hide-details
              class="mt-1 mr-1"
              color="brandCyan"
            ></v-checkbox>
            <v-btn icon @click="toggleSearch"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
            <v-expand-x-transition>
              <v-text-field
                v-if="display.search"
                v-model="search.transactions"
                ref="search"
                label="Search"
                outlined
                dense
                hide-details
                color="brandCyan"
                style="width: 160px; max-width: 220px;"
              ></v-text-field>
            </v-expand-x-transition>
          </div>
          <!-- </div> -->
        </v-row>
        <v-row no-gutters class="mx-2">
          <!-- <div class="text-left full-width"> -->
          <TransactionLog
            v-if="tab == keys.myRewards"
            class="full-width mt-1"
            :key="2"
            :search="search.transactions"
            @trigger-confetti="triggerConfetti"
          />
          <RewardsSentLog
            v-else-if="tab == keys.rewardsSent"
            class="full-width mt-1"
            :key="3"
            :search="search.transactions"
            :adminView="display.admin"
            @trigger-confetti="triggerConfetti"
          />
          <!-- </div> -->
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="display.popups"
      width="600"
      persistent
      content-class="elevation-0 mx-3 px-0 recognition-popup-dialog"
      class=""
    >
      <div
        class="d-flex align-center justify-center px-1 full-height"
        v-if="display.popups"
      >
        <!-- <v-btn
          v-if="!$vuetify.breakpoint.xs"
          icon
          :class="{ hidden: currentPopupIndex == 0 }"
          @click="dismissMessage(currentPopup.messageLogId, false, true, false)"
          ><v-icon color="white" x-large>mdi-chevron-left</v-icon></v-btn
        > -->
        <div class="d-flex flex-column align-center full-height">
          <div
            class="d-flex justify-space-between align-center full-width mb-5"
          >
            <v-btn
              fab
              small
              class="elevation-0 hidden"
              color="grey"
              @click="
                dismissMessage(currentPopup.messageLogId, true, false, false)
              "
              ><v-icon color="white" dark>mdi-close</v-icon></v-btn
            >
            <div class="d-flex align-center">
              <v-btn
                icon
                small
                :class="{ hidden: currentPopupIndex == 0 }"
                class="mr-1"
                @click="
                  dismissMessage(currentPopup.messageLogId, false, true, false)
                "
                ><v-icon color="white" x-large>mdi-chevron-left</v-icon></v-btn
              >
              <div
                class="white-background pl-2 pr-3 py-1 rounded d-flex align-center"
                v-if="popups.length > 1 || $vuetify.breakpoint.xs"
              >
                <h3 class=" black--text text-lg font-italic mx-1 mt-1">
                  {{ currentPopupIndex + 1 }} of {{ popups.length }}
                </h3>
              </div>
              <v-btn
                icon
                small
                :class="{ hidden: currentPopupIndex >= popups.length - 1 }"
                class="ml-1"
                @click="
                  dismissMessage(currentPopup.messageLogId, false, false, true)
                "
                ><v-icon color="white" x-large>mdi-chevron-right</v-icon></v-btn
              >
            </div>
            <v-btn
              fab
              x-small
              class="elevation-0"
              color="grey"
              @click="
                dismissMessage(currentPopup.messageLogId, true, false, false)
              "
              ><v-icon color="white" dark>mdi-close</v-icon></v-btn
            >
          </div>
          <!-- <h3
              v-if="popups.length > 1"
              class="text-right white--text text-lg font-italic pt-3 pr-3"
            >
              {{ currentPopupIndex + 1 }} of {{ popups.length }}
            </h3> -->
          <ActivityFeedCard
            v-if="currentPopup"
            :elevation="0"
            width="400"
            maxWidth="calc(100vw - 40px)"
            :key="currentPopup.messageLogId"
            :id="currentPopup.contextResourceId"
            :messageId="currentPopup.messageId"
            :icon="currentPopup.icon"
            :iconColor="currentPopup.iconColor"
            :context="currentPopup.context"
            :subcontext="currentPopup.subcontext"
            :title="currentPopup.cardTitle"
            :date="currentPopup.date"
            :messageTitle="currentPopup.messageSubject"
            :messageSubtitle="currentPopup.messageShortBody"
            :messageBody="currentPopup.messageBody"
            :messageSuccessImage="currentPopup.successImage"
            :image="currentPopup.imageURL"
            :linkText="currentPopup.linkText"
            :object="currentPopup"
            :messageTitleFlag="currentPopup.titleFlag"
            :messageSubtitleFlag="currentPopup.subtitleFlag"
            :messageIconFlag="currentPopup.messageIconFlag"
            :robinFlag="false"
            :imageFlag="currentPopup.displayImageInCardPreview"
            :likedByUser="currentPopup.likedByUser"
            :linkButtonFlag="true"
            :popup="true"
            :fullMessage="currentPopup.context == 'recognition'"
            :comments="currentPopup.comments"
            :reactions="currentPopup.reactions"
            :values="currentPopup.values"
            :celebration="currentPopup.celebrationEffect"
            :recipients="currentPopup.recipients"
            :privateRecognition="currentPopup.privateRecognition"
            @create-comment="createMessageComment($event, true)"
            @remove-comment="
              removeMessageComment($event, currentPopup.messageId, true)
            "
            @create-reaction="
              createMessageReaction(currentPopup.messageId, $event, true)
            "
            @remove-reaction="
              removeMessageReaction(currentPopup.messageId, $event, true)
            "
            class="activity-card-popup"
            :class="{
              'card-stack': popups.length > 1 && popups.length <= 2,
              'card-stack-multiple': popups.length > 2
            }"
          />
        </div>
        <!-- <v-btn
          v-if="!$vuetify.breakpoint.xs"
          icon
          :class="{ hidden: currentPopupIndex >= popups.length - 1 }"
          @click="dismissMessage(currentPopup.messageLogId, false, false, true)"
          ><v-icon color="white" x-large>mdi-chevron-right</v-icon></v-btn
        > -->
      </div></v-dialog
    >
  </div>
</template>

<script>
// @ is an alias to /src
import CommunicationService from "@/services/CommunicationService";
// import MarqetaService from "@/services/MarqetaService";
// import WalletService from "@/services/WalletService";

import MarqetaWidgetManager from "@/components/wallet/MarqetaWidgetManagerV2.vue";
import TransactionLog from "@/components/wallet/TransactionLog.vue";
import RewardsSentLog from "@/components/wallet/RewardsSentLog.vue";
import ActivityFeedCard from "@/components/ActivityFeedCardV2.vue";

// import Robin from "@/components/Robin.vue";

// import moment from "moment";
import { mapState } from "vuex";
import momentTz from "moment-timezone";
import confetti from "canvas-confetti";

export default {
  name: "WalletV3",
  title: "Wallet | Whistle",
  components: {
    MarqetaWidgetManager,
    TransactionLog,
    RewardsSentLog,
    ActivityFeedCard
    // Robin
  },
  props: { initialPageIndexToLoad: Number, previousRoute: String },
  data() {
    return {
      // v2
      tab: 0,
      keys: {
        myRewards: 0,
        rewardsSent: 1
      },
      search: {
        transactions: null
      },
      display: {
        search: false,
        admin: false,
        popups: false
      },
      popups: [],
      currentPopupIndex: 0
    };
  },
  created() {
    if (this.$auth) {
      if (
        this.initialPageIndexToLoad !== undefined &&
        this.initialPageIndexToLoad !== null &&
        !isNaN(this.initialPageIndexToLoad)
      ) {
        this.tab = this.initialPageIndexToLoad;
      } else {
        this.tab = 0;
      }
      // }
      // this.refreshWallet();
      // setTimeout(() => {
      //   if (this.$refs["marqeta-widget"]) {
      //     this.$refs["marqeta-widget"].getCards("walletV3");
      //   } else console.log("No MQ widget in created");
      // }, 100);
    }

    if (this.magicLinkToken) this.getRecognitionPopupMessages();
  },
  mounted() {
    //play confetti!
    // this.refreshWallet(false);
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-62px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },

  methods: {
    refreshWallet(refreshBudgets = true) {
      // We use a boolean because
      if (refreshBudgets)
        if (this.$refs["marqeta-widget"]) {
          this.$refs["marqeta-widget"].getBudgets();
          this.$refs["marqeta-widget"].getBudgetFundingStatuses();
        } else console.log("No MQ widget in mounted");

      this.$store.dispatch("refreshWallet");
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    triggerConfetti(date) {
      this.$store.dispatch("setConfettiPlayed", date);
      console.log(this.$store.state.confettiPlayed);

      confetti({
        particleCount: 300,
        spread: 70,
        scalar: 1.13,
        origin: { y: 0.95, x: 0.56 }
      });
      confetti({
        particleCount: 200,
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { y: 0.85, x: 0.52 }
      });
      confetti({
        particleCount: 100,
        spread: 120,
        startVelocity: 45,
        scalar: 0.8,
        origin: { y: 0.99, x: 0.54 }
      });
    },
    toggleSearch() {
      this.display.search = !this.display.search;
      if (this.display.search) {
        // this.$nextTick(() => {
        setTimeout(() => {
          if (this.$refs.search) this.$refs.search.focus();
        }, 250);
      }
    },
    async getRecognitionPopupMessages() {
      try {
        // let page = this.messageOptions.page;
        // // We fetch a page ahead. To do this, on page 1 we fetch both page 1 & 2 at the same time. Then we're a page ahead the rest of the time
        // let limit = this.listViewLength; //page == 1 ? 20 : 10;
        // let offset = page == 1 ? 0 : (page - 1) * limit;
        // let currentContexts = this.computedFilterString;
        // console.log({
        //   // filter: filter,
        //   limit: limit,
        //   offset: offset
        // });
        const messages = await CommunicationService.getPopupMessages(
          {
            // filter: filter,
            // limit: limit,
            // offset: offset,
            // sort: "sendDate DESC"
          },
          this.magicLinkToken
        );
        // Ensures that if there are multiple api requests at once, that we're only setting data for the most recent
        // if (this.computedFilterString !== currentContexts) {
        //   console.log("Ignoring request ", currentContexts);
        //   return;
        // }
        console.log("Popups ", messages);
        this.popups = messages.rows.map(msg => {
          msg.date = momentTz(msg.sendDate)
            .local()
            .format("MMM D - h:mm A");
          return msg;
        });

        if (this.popups.length) {
          this.currentPopupIndex = 0;
          this.display.popups = true;
        }

        // this.messageOptions.count = messages.result.count;
        // if (reset == true) this.messageOptions.total = messageArr.length;
        // else this.messageOptions.total += messageArr.length;
        // if (reset == true) {
        //   this.messages = messageArr;
        // } else {
        //   // this.$set(this, "messages", this.messages.concat(messageArr));
        //   this.messages.push(...messageArr);
        // }
      } catch (error) {
        console.log("error getting popup messages ", error);
      }
    },
    async createMessageComment(obj) {
      console.log("Create message comment", obj);

      try {
        let comment = await CommunicationService.createMessageComment(
          {
            messageId: obj.messageId,
            comment: obj.comment
          },
          this.magicLinkToken
        );
        console.log({ comment });
        if (obj.callback && comment[0]) obj.callback(comment[0]);
      } catch (e) {
        console.log("error creating comment ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "createMessageComment";
        }
        obj.callback();
      }
    },
    async removeMessageComment(commentId) {
      try {
        await CommunicationService.deleteMessageComment(
          commentId,
          this.magicLinkToken
        );
      } catch (e) {
        console.log("error deleting comment ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "removeMessageComment";
        }
      }
    },
    async createMessageReaction(messageId, emoji) {
      try {
        await CommunicationService.createMessageReaction(
          {
            messageId,
            reaction: emoji
          },
          this.magicLinkToken
        );
      } catch (e) {
        console.log("error creating reaction ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "createMessageReaction";
        }
      }
    },
    async removeMessageReaction(messageId, emoji) {
      try {
        await CommunicationService.removeMessageReaction(
          messageId,
          {
            reaction: emoji
          },
          this.magicLinkToken
        );
      } catch (e) {
        console.log("error removing reaction ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "removeMessageReaction";
        }
      }
    },
    async dismissMessage(
      messageLogId,
      close = false,
      back = false,
      next = false
    ) {
      try {
        console.log("Dismissing message", { messageLogId, close, back, next });
        CommunicationService.updatePopupMessageAsRead(
          messageLogId,
          this.magicLinkToken
        );
        if (close) this.display.popups = false;
        else if (back) this.currentPopupIndex--;
        else if (next) this.currentPopupIndex++;
      } catch (e) {
        console.log("error dismissing ", e);
        if (this.devEnvironment) {
          this.devError = true;
          this.devErrorType = "dismissMessage";
        }
      }
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "roles",
      "permissions",
      "displayWalletMenu",
      "walletMenuScreen",
      "magicLinkToken"
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isTest() {
      return process.env.VUE_APP_ENVIRONMENT == "test";
    },
    cobrandedCardLogo() {
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom2 &&
        this.userProfile.Client.internalCustom2.startsWith("https")
      )
        return this.userProfile.Client.internalCustom2;
      return null;
    },
    adminView() {
      // Permissions for if they can send payments
      let paymentAdminRoles = [
        "20PaymentsAdminV2",
        "20Payments+GeneralAdminV2",
        "30FinanceAdminV2",
        "40ClientAdminV2"
        // "98WhistleFinanceAdminV2"
      ];
      return (
        this.permissions.includes("wallets:create:cashpayment") ||
        this.roles.find(x => paymentAdminRoles.includes(x))
      );
    },
    displayOtherRewardsCheckbox() {
      let adminRoles = ["30FinanceAdminV2", "40ClientAdminV2"];
      return this.roles.find(x => adminRoles.includes(x));
    },
    currentPopup() {
      if (this.currentPopupIndex == null) return null;
      return this.popups[this.currentPopupIndex];
    }
  },
  watch: {
    // displayWalletMenu(val) {
    //   if (!val) {
    //     // If the drawer closed while we're on the wallet, refresh
    //     // if (this.$refs["marqeta-widget"]) {
    //     //   this.$refs["marqeta-widget"].getCards(
    //     //     "walletV3 displayWalletMenu watcher"
    //     //   );
    //     // }
    //     this.refreshWallet("walletV3 displayWalletMenu watcher");
    //   }
    // }
  }
};
</script>

<style scoped>
/* change flex to make footer margin-top auto work to push to bottom */
.v-tabs {
  flex: 0 1 auto;
}

.balance {
  font-size: 1.35em;
}
.balance.in-process {
  color: #999;
}
.border-radius {
  border-radius: 5px;
}

.dispute-button {
  width: 36px;
}

.txn-table {
  /* border: solid 1px;
  border-color: lightgray; */
  border-radius: 20px;
  /* border-radius: 0px; */
}

.txn-table >>> .v-data-table__wrapper {
  border-radius: 20px 0 0 0;
  padding-top: 10px;
}

.txn-table >>> .v-data-table-header {
  background-color: white;
}

.budget-select {
  font-size: 0.875rem;
  min-width: 160px;
}

.robin-animation >>> .robin {
  justify-content: center;
}
</style>

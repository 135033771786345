<template>
  <div>
    <v-toolbar
      dark
      color="brandCyan"
      rounded="0"
      elevation="0"
      v-if="!givingMode || displayHeaders"
    >
      <v-toolbar-title>{{ computedTitleBarText }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetForm">
          {{ givingMode ? "Close" : screen != "manage" ? "Cancel" : "Back" }}
          <v-icon class="ml-2 mb-1">mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div v-if="screen == 'manage'" :class="{ 'px-3': !isMobile }">
      <v-card-title
        v-if="cards.length > 0"
        class="pt-3 pb-0 pl-1 align-text-left"
      >
        <v-btn v-if="!displayHeaders" icon @click="resetForm" class="mt-n1"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >Saved Credit Cards
      </v-card-title>

      <v-card
        v-for="card in cards"
        :key="card.id"
        width="100%"
        elevation="0"
        class="my-3 py-3 px-2 cards"
        @click="
          if (!card.id) {
            resetForm('home');
          } else {
            null;
          }
        "
      >
        <v-row
          no-gutters
          class="d-flex justify-space-between full-width align-center"
        >
          <div v-if="card.id != null" class="d-flex align-center">
            <v-icon class="mx-2 border-radius wallet-icon"
              >mdi-credit-card</v-icon
            >
            <strong class="ml-1">{{ card.brand }} ...{{ card.last4 }}</strong>
          </div>

          <div v-else class="d-flex align-center">
            <v-icon class="mx-2 border-radius wallet-icon"
              >mdi-credit-card</v-icon
            >
            <strong class="ml-1">{{ card.last4 }}</strong>
          </div>

          <div class="d-flex">
            <v-menu max-width="260" offset-y v-if="card.id != null">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon text v-on="on" v-bind="attrs"
                  ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item
                  @click="(dialogDeleteBank = true), (bankToDelete = card.id)"
                >
                  <v-list-item-title class="align-text-left"
                    ><v-icon color="red" class="mr-2">mdi-delete</v-icon
                    >Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </v-card>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        size="50"
        class="my-6"
      ></v-progress-circular>
    </div>
    <div :class="{ 'px-3': !isMobile }" v-if="screen == 'home'">
      <div v-if="error" class="pt-4 px-4">
        <h2>We had trouble loading your account</h2>
        <p class="mt-2">
          Please try refreshing the page and if it continues, please
          <a href="mailto:help@wewhistle.com">contact us</a>.
        </p>
        <!-- <div class="full-width d-flex justify-center">
          <Robin
            :height="145"
            :width="145"
            :largeText="true"
            :showText="false"
            animation="discoPointing"
            class="my-4"
            :loop="true"
          ></Robin>
        </div> -->
      </div>
      <div v-else-if="loading" class="pt-4">
        <v-card-title class="word-break"
          >Loading any credit cards on file...</v-card-title
        >
        <!-- <h3>This will just take a sec!</h3> -->
        <div class="full-width d-flex justify-center">
          <Robin
            :height="125"
            :width="125"
            :largeText="true"
            :showText="false"
            animation="discoPointing"
            class="my-2"
            :loop="true"
          ></Robin>
        </div>
      </div>

      <div v-else class="align-text-left">
        <StripeCCPayment
          :budget="budget"
          :cards="cards"
          @reset="resetForm"
          @get-budgets="getBudgets"
          @get-funding-limit="getFundingLimit"
          :fundingLimit="fundingLimit"
          :canCompleteKYB="canCompleteKYB"
          :source="source"
          :givingMode="givingMode"
        >
        </StripeCCPayment>
      </div>
    </div>

    <!-- Deletion confirmation dialog -->
    <v-dialog v-model="dialogDeleteBank" :persistent="deletingBank" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Are you sure you want to delete this credit card?
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <v-btn
            color="brandCyan"
            @click="dialogDeleteBank = false"
            outlined
            width="130"
            :disabled="deletingBank"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            color="brandCyan"
            class="white--text"
            depressed
            @click="deleteStripeBank"
            width="130"
            :loading="deletingBank"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";

import StripeCCPayment from "@/components/stripe/StripeCCPayment.vue";
import Robin from "@/components/Robin.vue";
//import LoadingDialog from "@/components/loadingDialog.vue";
//import moment from "moment";
import { mapState } from "vuex";
import moment from "moment";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

export default {
  name: "StripeManageCC",
  props: {
    budget: Object,
    fundingLimit: Object,
    canCompleteKYB: Boolean,
    source: String,
    givingMode: {
      type: Boolean,
      default: false
    },
    displayHeaders: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Robin,
    StripeCCPayment
    // LoadingDialog,
  },
  data() {
    return {
      // Loading vars
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      cards: [],

      // Main data
      screen: "home",
      loading: false,
      error: false,
      dialogDeleteBank: false,
      deletingBank: false,
      bankToDelete: null
    };
  },
  created() {
    console.log("Created stripe checkout widget");
    this.createStripeCustomer();

    // this.getWidgetData();
    // this.getMarqetaUser();
    // this.getStripeBanks();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    createStripeCustomer() {
      // When ACH get's created we check if we need to create their Stripe customer
      if (!this.userProfile.FundingAccounts.find(x => x.type == "STRIPE")) {
        this.loadingStartTime = new Date();
        this.loading = true;
        console.log("Creating customer");
        PaymentService.createStripeCustomer(
          this.userProfile.firstName + " " + this.userProfile.lastName,
          this.userProfile.businessEmail,
          this.userProfile.businessPhone,
          this.magicLinkToken
        ).then(resp => {
          console.log("Got customer resp: ", resp);
          // Now add this to the user's funding accounts
          this.userProfile.FundingAccounts.push(resp);
          this.getStripeBanks();
        });
      } else {
        this.loading = true;
        this.loadingStartTime = new Date();
        this.getStripeBanks();
      }
    },

    autoRouteUser() {
      if (this.banks.filter(x => x.status == "verified").length == 0) {
        this.screen = "manage";
      }
      // Removing for now because once a user verifies their account, we take them immediately to spend that money
      else if (this.screen == "manual") {
        // This way after a user verifies their account, it doesn't route them to the payments
        this.screen = "manage";
      } else {
        this.screen = "home";
      }
    },
    resetForm(screen = null) {
      if (screen && typeof screen == "string") {
        if (screen == "reset") return this.$emit("reset", "reset");
        return (this.screen = screen);
      }
      if (this.screen == "home") {
        this.$emit("reset");
      } else if (this.screen == "manage") {
        this.screen = "home";
      } else {
        this.screen = "home";
      }
    },
    getStripeBanks() {
      this.loading = true;
      // When ACH get's created we check if we need to create their Stripe customer
      if (
        this.userProfile.FundingAccounts.filter(x => x.type == "STRIPE")
          .length > 0
      ) {
        console.log("Getting CCs");
        // This brings up a spinning circle while fetching in case they're actively looking at their banks.
        // This is especially the case after adding via Plaid since they never leave this screen
        //if (this.screen == "manage") this.loading = true;
        PaymentService.getStripeBanks("card", this.magicLinkToken)
          .then(resp => {
            this.loading = false;
            console.log("Got banks resp: ", resp);
            resp.forEach(x => (x.name = x.bank_name + "..." + x.last4));
            this.cards = resp;
            this.cards.unshift({ last4: "Add new card", id: null });
            // Used so Robin shows up for at least 1.5 seconds
            var timeDiff = moment().diff(moment(this.loadingStartTime));
            console.log("we done");
            if (timeDiff > 1500) timeDiff = 1500;
            setTimeout(() => {
              this.loading = false;
              //if (route) this.autoRouteUser();
            }, 1500 - timeDiff);
          })
          .catch(err => {
            console.log("Error getting Stripe banks ", err);
            this.error = true;
          });
      }
    },
    deleteStripeBank() {
      console.log("Deleting cc");
      this.deletingBank = true;

      // this.showLoadingDialog = true;
      // this.dialogDeleteBank = false;
      // this.loadingHeaderMessage = "Deleting bank account";
      // this.loadingMessageLine1 = "Please wait...";
      var startTime = new Date();
      PaymentService.deleteStripeBankAccount(
        this.bankToDelete,
        this.magicLinkToken
      ).then(resp => {
        console.log("Delete banks resp: ", resp);
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff < 1500) {
          setTimeout(() => {
            this.getStripeBanks(false);
            this.dialogDeleteBank = false;
            this.deletingBank = false;
            this.bankToDelete = null;
          }, 1500 - timeDiff);
        } else {
          this.getStripeBanks(false);
          this.dialogDeleteBank = false;
          this.deletingBank = false;
          this.bankToDelete = null;
        }
      });
    },
    previousSlide() {
      this.$emit("scrollToTop");
      this.resetForm();
    },
    getBudgets() {
      this.$emit("get-budgets");
    },
    getFundingLimit() {
      this.$emit("get-funding-limit");
    }
  },
  computed: {
    ...mapState(["userProfile", "magicLinkToken"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    computedTitleBarText() {
      var text = null;
      switch (this.screen) {
        case "home":
          // code block
          if (this.givingMode) text = "Donations";
          else text = "Credit Card Transfer";
          break;
        case "manage":
          // code block
          text = "Manage Cards";
          break;
        case "manual":
          // code block
          text = "Link Account";
          break;
        case "verify":
          // code block
          text = "Verify Account";
          break;
        default:
          // code block
          text = "Credit Card Transfer";
      }
      return text;
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}

.cards {
  border: 1px lightgrey solid;
}
</style>

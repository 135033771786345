<template>
  <div>
    <v-card
      class="my-3 py-3 px-2 banks"
      width="100%"
      elevation="0"
      :disabled="true"
      v-if="!linkToken"
    >
      <v-row no-gutters class="d-flex full-width align-center">
        <v-icon class="mx-2 border-radius wallet-icon">mdi-bank</v-icon>
        <strong class="ml-1">Link Bank via Plaid</strong>
      </v-row>
      <v-row class="align-text-left pl-3">
        <!-- <v-col sm="12" md="12"> Plaid support coming soon! </v-col> -->
        <v-col sm="12" md="12">
          Connect your bank account instantly via Plaid!
        </v-col>
      </v-row>
    </v-card>
    <div class="accounts-actions" v-if="linkToken">
      <plaid-link
        ref="plaid"
        v-bind="{
          env: env,
          webhook: webhook,
          clientName: clientName,
          onSuccess,
          onExit,
          receivedRedirectUri: redirectUri,
          token: linkToken
        }"
      >
        <template slot="button">
          <v-card
            v-if="!hideButton"
            class="my-3 py-3 px-2 banks"
            width="100%"
            elevation="0"
            @click="openPlaid"
            :disabled="!linkToken"
          >
            <v-row no-gutters class="d-flex full-width align-center">
              <v-icon class="mx-2 border-radius wallet-icon">mdi-bank</v-icon>
              <strong class="ml-1">Link Bank via Plaid</strong>
            </v-row>
            <v-row class="align-text-left pl-3">
              <!-- <v-col sm="12" md="12"> Plaid support coming soon! </v-col> -->
              <v-col sm="12" md="12">
                Connect your bank account instantly via Plaid!
              </v-col>
            </v-row>
          </v-card>
        </template>
      </plaid-link>
    </div>
    <!-- Confirmation dialog -->
    <v-dialog v-model="dialogError" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            {{
              error ? error : "There was an error linking your bank account."
            }}
          </v-card-title>

          <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-4 mr-2"
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="dialogExitMessage = false"
                >
                  <v-icon color="grey">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Back to program builder</span>
            </v-tooltip> -->
        </div>

        <v-card-actions class="mx-12">
          <v-spacer />
          <v-btn
            color="brandCyan"
            depressed
            class="white--text"
            @click="dialogError = false"
            width="130"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import PlaidLink from "vue-plaid-link";

// import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Plaid",
  props: {
    token: String,
    hideButton: Boolean
  },
  components: {
    PlaidLink
  },
  data() {
    return {
      loading: false,
      name: null,
      linkToken: null,
      env: null,
      clientName: null,
      webhook: null,
      redirect: false,
      dialogError: false,
      error: null
    };
  },
  created() {
    this.getLinkToken();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    openPlaid() {
      // We wait for the ref to be mounted
      var delay = 30;
      if (!this.$refs.plaid) delay = 100;
      setTimeout(() => {
        this.$refs.plaid.handleOnClick();
      }, delay);
    },
    onExit(err) {
      console.log("Plaid onExit called - Error: ", err);
      // Just in case, we clear the oauth state query
      let query = Object.assign({}, this.$route.query);
      if (query.oauth_state_id) {
        delete query.oauth_state_id;
        this.$router.replace({ query });
      }
      if (err != null && err.error_code === "INVALID_LINK_TOKEN") {
        this.getLinkToken();
      }
    },
    onSuccess(public_token, metadata) {
      console.log("onSuccess called: ", public_token);
      console.log("Metadata: ", metadata);

      var accountId = null;
      switch (metadata.accounts.length) {
        case 0:
          // Select Account is disabled: https://dashboard.plaid.com/link/account-select
          break;
        case 1:
          console.log(
            "Customer-selected account ID: " + metadata.accounts[0].id
          );
          accountId = metadata.accounts[0].id;
          break;
        default:
          // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
          break;
      }

      if (accountId) {
        PaymentService.storePlaidBankToken(public_token, accountId).then(
          plaidRes => {
            console.log(
              "Response from assigning Bank token to Stripe: ",
              plaidRes
            );

            if (
              plaidRes.error &&
              plaidRes.error.raw &&
              plaidRes.error.raw.code == "bank_account_exists"
            ) {
              this.dialogError = true;
              this.error =
                "The bank account selected is already linked to your account.";
            } else if (plaidRes.error) {
              this.dialogError = true;
              this.error = plaidRes.error;
            } else {
              console.log("Success with Plaid - refreshing");
              this.$emit("get-banks");
              this.$emit("route", "home");
            }
            // Removes the state query that Plaid sent us
            let query = Object.assign({}, this.$route.query);
            if (query.oauth_state_id) {
              delete query.oauth_state_id;
              this.$router.replace({ query });
            }
          }
        );
      } else {
        this.dialogError = true;
      }
    },
    async getLinkToken() {
      // We include the href if it's a special case to send them to payment admin. This means they came from the payment wizard
      PaymentService.getPlaidLinkToken(
        window.location.href.includes("oauth_state_id"),
        window.location.href.includes("paymentadmin")
          ? window.location.href
          : null
      ).then(token => {
        console.log("Got token: ", token.link_token);
        this.linkToken = token.link_token;
        this.env = token.environment;
        this.clientName = token.client_name;
        this.webhook = token.webhook;

        // getLinkToken gets called when created. And we check if the Plaid Oauth query was included.
        // If so, then we programatically open Plaid because an existing link token was returned to us to reuse
        let query = Object.assign({}, this.$route.query);
        if (query.oauth_state_id) {
          this.openPlaid();
        }
      });
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    redirectUri() {
      // This checks for the query sent in the redirect from plaid OAuth
      if (window.location.href.includes("oauth_state_id")) {
        return window.location.href;
      }
      return null;
    },
    devEnvironment() {
      return !!(
        process.env.VUE_APP_ENVIRONMENT == "dev" ||
        process.env.VUE_APP_ENVIRONMENT == "test"
      );
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}

.banks {
  border: 1px lightgrey solid;
}
</style>

<template>
  <div class="robin d-flex align-start">
    <!-- <v-img
      class="logo"
      alt="Robin"
      src="@/assets/robin/robin2.png"
      :width="width"
      max-width="80"
      :height="height"
    /> -->
    <div>
      <lottie
        v-if="displayRobin"
        :options="lottieOptions"
        :height="height"
        :width="width"
        v-on:animCreated="handleAnimation"
      />
    </div>
    <div class="d-flex flex-column" v-if="showText">
      <b
        v-if="header"
        class="robinSpeech no-border align-text-left ml-2 primary--text header-text d-flex align-center"
        >{{ header }}</b
      >
      <span
        class="robinSpeech align-text-left ml-2 d-flex align-center"
        :class="{
          'white-background': color === 'white',
          'brand-pale-blue-background': color === 'brandPaleBlue',
          'brand-light-green-background': color === 'brandLightGreen',
          'accent-border': borderColor === 'accent',
          'brand-green-border': borderColor === 'brandGreen',
          'no-border': !borderColor,
          'large-text': largeText,
          'small-text': !largeText,
        }"
        color="blue"
        >{{ message }}</span
      >
    </div>
  </div>
</template>

<script>
import Lottie from "vue-lottie/src/lottie.vue";
import * as babyDance from "@/assets/robin/Baby_Dance.json";
import * as backflip from "@/assets/robin/Backflip.json";
import * as chirping from "@/assets/robin/Chirping.json";
import * as curious from "@/assets/robin/Curious.json";
import * as discoPointing from "@/assets/robin/Disco_Pointing.json";
import * as eating from "@/assets/robin/Eating.json";
import * as egypt from "@/assets/robin/Egypt.json";
import * as flossing from "@/assets/robin/Flossing.json";
import * as fluttering from "@/assets/robin/Fluttering.json";
import * as flying from "@/assets/robin/Flying.json";
import * as hello from "@/assets/robin/Hello.json";
import * as idleBlink from "@/assets/robin/IdleBlink.json";
import * as inflatableDance from "@/assets/robin/Inflatable_Dance.json";
import * as moonwalk from "@/assets/robin/Moonwalk.json";
import * as ohYeah from "@/assets/robin/Oh_Yeah.json";
import * as swayAndBounce from "@/assets/robin/Sway_and_Bounce.json";
import * as theCarlton from "@/assets/robin/The_Carlton.json";
import * as train from "@/assets/robin/Train.json";

export default {
  name: "RobinAnimation",

  props: {
    message: String,
    header: String,
    width: Number,
    height: Number,
    borderColor: String,
    color: String,
    largeText: Boolean,
    showText: Boolean,
    animation: String,
    loop: Boolean,
  },
  components: {
    Lottie,
  },
  data() {
    return {
      // lottieOptions: {
      //   animationData: animationData.default,
      //   loop: true,
      //   autoplay: true,
      // },
      anim: null,
      animationSpeed: 1,
      displayRobin: true,
    };
  },
  created() {},
  updated() {},

  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
  },
  computed: {
    lottieOptions: {
      cache: false,
      get: function() {
        console.log(this.loop);
        if (this.loop) {
          var loop = true;
        } else {
          loop = false;
        }
        if (this.animation) {
          var anim = this.animation.toLowerCase();
          if (anim == "babydance") {
            return {
              animationData: babyDance.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "backflip") {
            return {
              animationData: backflip.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "chirping") {
            return {
              animationData: chirping.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "curious") {
            return {
              animationData: curious.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "discopointing") {
            return {
              animationData: discoPointing.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "eating") {
            return {
              animationData: eating.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "egypt") {
            return {
              animationData: egypt.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "flossing") {
            return {
              animationData: flossing.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "fluttering") {
            return {
              animationData: fluttering.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "flying") {
            return {
              animationData: flying.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "hello") {
            return {
              animationData: hello.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "idleblink") {
            return {
              animationData: idleBlink.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "inflatabledance") {
            return {
              animationData: inflatableDance.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "moonwalk") {
            return {
              animationData: moonwalk.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "ohyeah") {
            return {
              animationData: ohYeah.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "swayandbounce") {
            return {
              animationData: swayAndBounce.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "thecarlton") {
            return {
              animationData: theCarlton.default,
              loop: loop,
              autoplay: true,
            };
          } else if (anim == "train") {
            return {
              animationData: train.default,
              loop: loop,
              autoplay: true,
            };
          }
        }
        return {
          animationData: idleBlink.default,
          loop: true,
          autoplay: true,
        };
      },
    },
  },
  watch: {
    animation: function() {
      // remove the Lottie component from the DOM
      this.displayRobin = false;
      this.$nextTick(() => {
        // add Lottie component in DOM
        this.displayRobin = true;
      });
    },
  },
};
</script>

<style scoped>
.container.robin {
  /* position: relative; */
}
.robinSpeech {
  /* color: #ffffff; */
  border-radius: 8px;

  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  /* position: absolute; */
  text-transform: initial;
  width: auto;
  height: auto;
  opacity: 1;
  pointer-events: none;

  border: 3px solid;
  /* border-right: 13px solid; */

  top: 30px;
  left: 110px;
}

/* .robinSpeech::before {
  content: " ";
  position: absolute;
  width: 0px;
  height: 0;
  left: -22px;
  top: 6px;
  border: 8px solid;
  border-right: 13px solid;
  border-color: transparent var(--v-accent-base) transparent transparent;
} */

.white-background {
  background: white;
}

.brand-light-green-background {
  background: var(--v-brandLightGreen-base);
}

.brand-pale-blue-background {
  background: var(--v-brandPaleBlue-base);
}

.brand-green-border {
  border-color: var(--v-brandGreen-base);
}

.accent-border {
  border-color: var(--v-accent-base);
}
.no-border {
  border-color: transparent;
  /* opacity: 0; */
}

.header-text {
  font-size: 19px;
}
.large-text {
  font-size: 18px;
}
.small-text {
  font-size: 14px;
}
</style>

<template>
  <div
    :style="`width: ${width}`"
    class="d-flex justify-space-between align-center"
  >
    <v-progress-linear
      :value="computedValue"
      :color="color"
      :height="height"
      class="pill-nav mr-6"
      rounded
    >
      <!-- <div style="left: 0; width: 20%; height: 40px; background: red;"></div> -->
      <div
        class="d-flex justify-space-between align-center"
        :style="`width: ${width}`"
      >
        <template v-for="(item, index) in steps">
          <div
            @click="
              (!item.active ||
                (typeof item.active === 'function'
                  ? item.active()
                  : item.active)) &&
                setStep(item.step || index)
            "
            :key="index"
            class="pill-item"
            :style="`width: 100%;`"
            :class="{ 'active-pill': step == (item.step || index) }"
          >
            <v-icon color="white" :small="$vuetify.breakpoint.xs"
              >mdi-{{ item.icon }}</v-icon
            >
          </div>
        </template>
      </div>
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  model: {
    prop: "step"
  },
  props: {
    step: Number,
    color: {
      type: String,
      default: "brandCyan"
    },
    steps: Array,
    height: {
      type: Number,
      default: 40
    },
    width: {
      type: String,
      default: "200px"
    },
    value: {
      type: Number
    }
  },

  methods: {
    setStep(i) {
      this.$emit("input", i);
    }
  },
  computed: {
    computedValue() {
      if (this.value !== undefined) return this.value;

      const idx = this.steps.findIndex(x => x?.step == this.step);
      // console.log("computedValue PillNav", {
      //   idx,
      //   step: this.step,
      //   numSteps: this.steps.length
      // });
      if (idx === -1) return this.step * (100 / this.steps.length);
      return ((idx + 1) / this.steps.length) * 100;
    }
  }
};
</script>

<style scoped>
.pill-nav {
  /* background: var(--v-grey-base); */
  /* background: var(--v-brandDarkGreen-base); */
  /* opacity: 0.3; */
  width: calc(100% - 20px);
  /* min-height: 40px; */
  /* max-height: 40px; */
  border-radius: 999px;
  /* padding: 0 20px 0 20px; */
}
.pill-nav >>> .pill-item {
  border-radius: 999px;
  /* min-width: 60px; */
  /* min-height: 40px; */
  /* max-height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1 !important;
}
.pill-nav >>> .active-pill {
  /* border-radius: 999px; */
  /* background: var(--v-brandGreen-base); */
}
</style>

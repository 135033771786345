<template>
  <div class="mx-8">
    <p class="text-h5">{{ card.displayName }}</p>
    <!-- <p class="mt-10 font-weight-bold brand--text">
      {{ card.description }}
    </p> -->
    <v-divider />
    <div class="my-8 d-flex flex-column justify-center align-center mx-6">
      <div class="d-flex">
        <v-img
          v-if="card.badgeSignedUrl"
          :src="card.badgeSignedUrl"
          aspect-ratio="1"
          width="125"
        />
        <div v-else class="outer-circle ">
          <div class="inner-circle d-flex justify-center align-center">
            <v-icon color="white" class="reward-icon" x-large>{{
              card.context == "Reward"
                ? "mdi-credit-card-outline"
                : "mdi-medal-outline"
            }}</v-icon>
          </div>
        </div>
        <div class="word-wrap mx-8">{{ card.description }}</div>
      </div>
    </div>
    <v-divider />
    <div class="d-flex justify-center mt-10">
      <v-btn depressed color="brand" class="white--text" @click="loadNextCard"
        >{{ nextCard ? "Continue" : "Exit" }}
      </v-btn>
      <!-- nextCard
            ? "Continue"
            : userProfile &&
              userProfile.Client &&
              userProfile.Client.displayWalletPage &&
              card.context == "Reward"
            ? "See my wallet"
            : "Exit" -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

// import moment from "moment";

export default {
  name: "ProgramDetailsRewardCard",
  props: {
    currentCard: Object,
    nextCard: Object
  },
  components: {},
  data() {
    return {
      inputValue: null
    };
  },
  created() {},
  updated() {},
  mounted() {},

  beforeMount() {},
  methods: {
    loadNextCard() {
      if (!this.nextCard) {
        console.log("Calling leaveProgram");
        this.$emit("leaveProgram");
      } else {
        this.$emit(
          "nextCard",
          this.userProfile &&
            this.userProfile.Client &&
            this.userProfile.Client.displayWalletPage &&
            this.card.context == "Reward"
            ? "wallet"
            : null,
          "userProfile"
        );
      }
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    card: {
      get: function() {
        return this.currentCard;
      },
      set: function(newVal) {
        this.$emit("update-card", newVal);
      }
    }
    // computedInputLabel() {
    //   if (!this.card || !this.card.MetricDefinition) {
    //     return null;
    //   } else if (this.card.MetricDefinition.dataType == "STRING")
    //     return this.card.displayName;
    //   // else if (this.card.MetricDefinition.unitPlural) {
    //   //   return (
    //   //     "Total " +
    //   //     this.card.MetricDefinition.unitPlural +
    //   //     " " +
    //   //     (this.card.MetricDefinition.verb ||save "entered")
    //   //   );
    //   // } else {
    //   //   return this.card.MetricDefinition.metricName;
    //   // }
    //   var str =
    //     (this.card.MetricDefinition.uploadType == "CUMULATIVE"
    //       ? ""
    //       : "Total ") +
    //     (this.card.MetricDefinition.unitPlural
    //       ? this.card.MetricDefinition.unitPlural.toLowerCase()
    //       : this.card.MetricDefinition.dataType == "DATE" ||
    //         this.card.MetricDefinition.dataType == "TIMESTAMP"
    //       ? "date"
    //       : "units") +
    //     " " +
    //     (this.card.MetricDefinition.verb
    //       ? this.card.MetricDefinition.verb.toLowerCase()
    //       : "entered");
    //   return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
    // },
    // computedDateGoal() {
    //   if (
    //     !this.card.MetricDefinition ||
    //     !["DATE", "TIMESTAMP"].includes(this.card.MetricDefinition.dataType)
    //   )
    //     return null;
    //   else if (!this.card.value || !moment(this.card.value).isValid())
    //     return null;

    //   if (this.card.MetricDefinition.dataType == "DATE")
    //     return moment(this.card.value).format("MMMM D, YYYY");
    //   else return moment(this.card.value).format("MMMM D, YYYY h:mm a");
    // },
  }
};
</script>

<style scoped>
.outer-circle {
  border-radius: 1000px;
  background-color: #97dffc;
  width: 125px;
  height: 125px;
  min-height: 125px;
  min-width: 125px;
  max-height: 125px;
  max-width: 125px;
}

.inner-circle {
  background-color: #09acec;
  border-radius: 1000px;
  width: 109px;
  height: 109px;
  min-width: 109px;
  min-height: 109px;
  max-width: 109px;
  max-height: 109px;
  margin-top: 8px;
  margin-left: 8px;
}

.reward-icon {
  transform: scale(1.7);
}
</style>

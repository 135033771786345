import { getInstance } from "./index.js";
import store from "../store/index.js";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    console.log("Inside auth guard. Is auth? ", authService.isAuthenticated);
    // await store.dispatch("isLoggedIn");
    if (authService.isAuthenticated) {
      // console.log("Auth guard ", to);
      // if (to.query.redirect == "/procore" || to.name == "procore")
      //   return next({ path: "/procore" });
      var check = "vue:read:" + to.name.toLowerCase();
      if (
        store.state.permissions.includes(check) ||
        (store.state.magicLinkToken &&
          (check == "vue:read:wallet" ||
            check == "vue:read:paymentwizard" ||
            check == "vue:read:activity"))
      ) {
        // If someone outside of Whistle is trying to navigate to program admin or builder, reroute
        // if (to.name == "programadmin" || to.name == "programbuilder") {
        //   if (store.state.userProfile.clientId == 1) {
        //     return next();
        //   } else {
        //     return next({ path: "/" });
        //   }
        // } else {
        return next();
        // }
      } else {
        return next({ path: "/" });
      }
    }

    // Otherwise, log in
    console.log("We're logging in via redirect with auth guard", to.fullPath);
    if (to.fullPath.includes("login-error")) var url = "/";
    else url = to.fullPath;
    console.log("We're logging in via redirect with auth guard", url);
    authService.loginWithEmbedded({ appState: { targetUrl: url } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      console.log("Loading set to false inside authGuard");
      return fn();
    }
  });
};

<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-calculator-variant</v-icon
        >
        <v-card-title> {{ computedPageHeader }} </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-row class="my-2 ml-3 mr-2">
        <v-col sm="12" md="3">
          <!-- <h2 class="align-text-left mb-3">
            Set up awards to use within your budgets and programs
          </h2>
          <v-divider></v-divider> -->
          <div
            class="my-4 align-text-right d-flex flex-column align-end justify-center"
          >
            <v-autocomplete
              outlined
              dense
              class="pa-1 mb-2 full-width"
              hide-details
              :items="clients"
              item-text="formattedName"
              item-value="clientId"
              label="Client"
              v-model="clientId"
              @change="refreshClientId"
              v-if="userProfile.clientId === 1 && clients.length > 0"
            ></v-autocomplete>
            <span class="mr-3 mb-1" v-if="selected.length === 1"
              >{{ selected.length }} Award Selected</span
            >
            <span class="mr-3 mb-1" v-else
              >{{ selected.length }} Awards Selected</span
            >
            <v-btn
              v-if="permissions.includes('awards:read:award')"
              text
              small
              color="primary"
              class="mt-3"
              @click="loadFulfillmentPartnersWidget"
              >Manage Fulfillment Partners</v-btn
            >
          </div>
          <v-divider></v-divider>

          <v-card
            rounded="0"
            elevation="2"
            class="mt-4"
            color="brandPaleBlue"
            v-show="!postItArray.includes('awards-1')"
          >
            <v-card-title class="d-flex justify-space-between">
              <v-icon color="grey">mdi-lightbulb-outline</v-icon>
              <v-btn icon
                ><v-icon
                  color="grey"
                  @click="$store.dispatch('setPostItArray', 'awards-1')"
                  >mdi-close</v-icon
                ></v-btn
              >
            </v-card-title>
            <v-card-text class="align-text-left">
              <span
                >Awards are used within budgets to designate what sort of reward
                any participants might receive by completing a program.
              </span>
              <br />
              <span
                >They can be as simple as cash that can be distributed to a
                participant's Whistle Card!</span
              >
              <br />
              <br />
              <span
                >Learn more about the budgets and awards in the
                <a @click="openFAQ">Whistle FAQ.</a></span
              >
              <!-- <br />
              <br />
              - <span class="selectable-blue-text">Budgets</span>
              <br />
              - <span class="selectable-blue-text">Awards</span> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="9">
          <div class="d-flex justify-space-between align-center mr-2 mb-3">
            <div class="d-flex">
              <div
                class="budget-button"
                v-if="permissions.includes('vue:read:budgets')"
              >
                <v-btn
                  text
                  color="primary"
                  class="mr-2"
                  to="/budgets"
                  active-class="no-active"
                  >Budgets</v-btn
                >
              </div>
              <v-btn
                text
                class="mr-2"
                color="primary"
                to="/budgets/awards"
                active-class="no-active"
                >Awards</v-btn
              >
              <v-btn
                v-if="permissions.includes('vue:read:badges')"
                text
                color="primary"
                to="/budgets/badges"
                active-class="no-active"
                >Badges</v-btn
              >
            </div>
            <v-text-field
              outlined
              placeholder="Search"
              prepend-icon="mdi-magnify"
              clearable
              single-line
              dense
              hide-details
              v-model="awardTableSearch"
              class="shrink"
            ></v-text-field>
            <v-btn
              v-if="permissions.includes('awards:create:award')"
              color="brandCyan"
              class="white--text mr-3"
              depressed
              @click="loadAwardBuilder(null, false)"
              >Add award</v-btn
            >
          </div>
          <div>
            <v-data-table
              v-model="selected"
              :headers="columns"
              :items="visibleAwards"
              item-key="awardId"
              :items-per-page="10"
              :search="awardTableSearch"
              class="elevation-0 award-table"
              :class="{
                'cursor-pointer': permissions.includes('awards:create:award')
              }"
              :loading="loadingAwardTable"
              loading-text="Loading... Please wait"
              no-data-text="No awards found"
              no-results-text="No awards found"
              @click:row="loadEditAwardForm"
            >
              <template v-slot:[`item.displayName`]="{ item }">
                <span
                  :class="{
                    'selectable-blue-text': permissions.includes(
                      'awards:create:award'
                    )
                  }"
                  >{{ item.displayName }}</span
                >
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- Drawer used for creating and updating awards -->
    <v-navigation-drawer
      v-model="showAwardDialog"
      temporary
      fixed
      right
      width="500"
    >
      <AwardCreatorWidget
        v-if="showAwardDialog"
        :awardFulfillmentPartners="visibileFulfillmentPartners"
        @getPartners="getAwardFulfillmentPartners"
        @close="showAwardDialog = false"
        @refreshAwards="getAwards"
        :editingAward="awardForm.editing"
        :editAwardObject="award"
        :clientId="clientId"
        source="AWARDS"
    /></v-navigation-drawer>
    <!-- Drawer used for managing award fulfillment partners -->
    <v-navigation-drawer
      v-model="displayFulfillmentPartnersWidget"
      temporary
      fixed
      right
      width="500"
    >
      <FulfillmentPartnerManager
        v-if="displayFulfillmentPartnersWidget"
        :fulfillmentPartners="visibileFulfillmentPartners"
        @get-partners="getAwardFulfillmentPartners"
        @reset="displayFulfillmentPartnersWidget = false"
    /></v-navigation-drawer>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import BudgetService from "../services/BudgetService.js";
import LoadingDialog from "@/components/loadingDialog.vue";
import FulfillmentPartnerManager from "@/components/FulfillmentPartnerManager.vue";
import AwardCreatorWidget from "@/components/AwardCreatorWidget.vue";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Awards",
  title: "Whistle | Awards",
  components: {
    LoadingDialog,
    FulfillmentPartnerManager,
    AwardCreatorWidget
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingAwardTable: true,
      awardTableSearch: null,
      selected: [],
      budgets: [],
      awards: [],
      awardFulfillmentPartners: [],
      columns: [
        { text: "Name", value: "displayName", width: "40%" },
        // { text: "Value", value: "awardValue", width: "10%" },
        { text: "Description", value: "awardDescription", width: "40%" },
        { text: "Category", value: "category", width: "20%" }
        // { text: "Status", value: "status", width: "15%" }
        // { text: "Expiration Date", value: "expirationDate", width: "20%" },
      ],
      categories: ["Cash", "Travel", "Physical Award", "Other"],
      showAwardDialog: false,
      awardForm: {
        menuStartDate: false,
        menuExpirationDate: false,
        valid: false,
        editing: false
      },
      award: {
        clientId: null,
        awardId: null,
        awardName: null,
        awardDisplayName: null,
        awardDescription: null,
        awardValue: null,
        unitSingular: null,
        unitPlural: null,
        category: null,
        expirationDate: null,
        physicalAwards: [],
        // Used to display awards in the form. Then if they save/cancel, we overwrite the normal physicalAwards array
        physicalAwardsTemp: []
      },
      displayFulfillmentPartnersWidget: false
    };
  },

  created() {
    if (this.$auth) {
      this.getAwards();
      // this.getParentBudgets();
      this.getAwardFulfillmentPartners();
    }

    // this.clientId = this.userProfile.clientId;
  },
  methods: {
    getAwards() {
      BudgetService.getAwards()
        .then(response => {
          console.log("Awards: ", response);
          //Prune the returned awards for the data we need for the table
          var awardsPruned = [];
          response.forEach(function(award) {
            if (award.status !== "Deleted") {
              if (award.expirationDate) {
                var formattedExpDate = moment(
                  award.expirationDate,
                  "YYYY-MM-DD hh:mm:ss"
                ).format("MMM Do YYYY");
              }
              awardsPruned.push({
                clientId: award.clientId,
                displayName: award.awardDisplayName || award.awardName,
                awardDescription: award.awardDescription,
                awardValue: award.awardValue,
                status: award.status,
                category: award.category,
                expirationDate: formattedExpDate,
                awardId: award.awardId,
                name: award.awardDisplayName || award.awardName,
                id: award.awardId
              });
            }
          });
          this.awards = awardsPruned;
          // this.$store.dispatch("setSearchArray", awardsPruned);
          this.loadingAwardTable = false;
        })
        .catch(error => {
          console.log("Error!" + error);
          this.loadingAwardTable = false;
        });
    },
    getParentBudgets() {
      BudgetService.getParentBudgets()
        .then(response => {
          console.log("Got parent budgets");
          response = response.filter(x => x.status != "Deleted");
          this.budgets = response;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    getAwardFulfillmentPartners() {
      BudgetService.getAwardFulfillmentPartners()
        .then(response => {
          console.log("Award Fulfillment Partners: ", response);

          this.awardFulfillmentPartners = response;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    resetAwardForm() {
      this.showAwardDialog = false;
      this.awardForm.showLoadingDialog = false;
      this.awardForm.editing = false;
      this.$refs.awardForm.reset();
      this.awardForm.page = 0;
    },
    loadEditAwardForm(awardId) {
      if (
        !this.permissions.includes("awards:create:award") ||
        (awardId.awardId && awardId.clientId == 0)
      ) {
        return;
      }
      console.log("Editing " + awardId);
      // Can be called when initially routing to page with just an awardId
      // Or can be called from the table, in which an entire object is passed
      if (typeof awardId == "object") {
        awardId = awardId.awardId;
      }
      this.awardForm.editing = true;
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Loading the selected award";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      BudgetService.getAward(awardId)
        .then(award => {
          console.log(award);
          var editObj = {};
          if (award.expirationDate !== null) {
            var formattedEndDate = moment(
              award.expirationDate,
              "YYYY-MM-DD hh:mm:ss"
            ).format("YYYY-MM-DD");
          } else {
            formattedEndDate = null;
          }
          editObj.clientId = award.clientId;
          editObj.awardId = award.awardId;
          editObj.awardName = award.awardName;
          editObj.awardDisplayName = award.awardDisplayName;

          editObj.awardDescription = award.awardDescription;
          editObj.expirationDate = formattedEndDate;
          editObj.awardValue = award.awardValue;
          editObj.unitSingular = award.unitSingular;
          editObj.unitPlural = award.unitPlural;
          editObj.category = award.category;

          editObj.physicalAwards = award.supplementAwardInfo
            ? award.supplementAwardInfo.physicalItems
              ? award.supplementAwardInfo.physicalItems
              : []
            : [];

          this.loadAwardBuilder(editObj, true);
          this.awardForm.valid = true;

          this.showLoadingDialog = false;
        })
        .catch(error => {
          this.showLoadingDialog = false;
          this.resetAwardForm();
          console.log("Error fetching award! ", error);
        });
    },
    openFAQ() {
      // Opens up FreskDesk in a new tab
      window.open("https://whistle.freshdesk.com/support/home", "_blank");
    },
    loadAwardBuilder(award, editing) {
      this.showAwardDialog = true;
      this.award = award;
      this.awardForm.editing = editing;
    },

    loadFulfillmentPartnersWidget() {
      this.displayFulfillmentPartnersWidget = true;
    },
    refreshClientId() {
      this.selected = [];
    }
  },
  computed: {
    ...mapState([
      "clients",
      "userProfile",
      "postItArray",
      "permissions",
      "globalClientId"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    visibleAwards() {
      if (this.clientId !== null) {
        return this.awards.filter(x => x.clientId == this.clientId);
      }
      return [];
    },
    visibileFulfillmentPartners() {
      if (this.clientId !== null) {
        return this.awardFulfillmentPartners.filter(
          x => x.clientId == this.clientId
        );
      }
      return [];
    },
    computedPageHeader() {
      var title = "";
      if (this.permissions.includes("vue:read:budgets")) {
        title = title.concat("Budgets, ");
      }
      if (this.permissions.includes("vue:read:awards")) {
        title = title.concat("Awards, ");
      }
      if (this.permissions.includes("vue:read:badges")) {
        title = title.concat("Badges, ");
      }
      if (title.endsWith(", ")) {
        title = title.substring(0, title.length - 2);
      }
      return title;
    }
  }
};
</script>

<style scoped>
.v-data-table-header {
  background-color: var(--v-lightGrey-base);
}

#template-download-button {
  margin-right: 3%;
  /* margin-bottom: 3%; */
}
.v-stepper__step__step.primary {
  background-color: red !important;
  border-color: red !important;
}
.dialog {
  z-index: inherit;
}

.budget-button .v-btn--active.no-active::before {
  opacity: 0 !important;
}

.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}

.outlined {
  border: 1px solid;
  /* border-left: 4px; */
  border-width: 1px 1px 0px 1px;
  border-color: var(--v-grey-base);
}
.outlined-bottom {
  border-width: 1px 1px 1px 1px;
}

.award-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.award-table >>> .v-data-table-header {
  background-color: white;
}
</style>

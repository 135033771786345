var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.linkToken)?_c('v-card',{staticClass:"my-3 py-3 px-2 banks",attrs:{"width":"100%","elevation":"0","disabled":true}},[_c('v-row',{staticClass:"d-flex full-width align-center",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mx-2 border-radius wallet-icon"},[_vm._v("mdi-bank")]),_c('strong',{staticClass:"ml-1"},[_vm._v("Link Bank via Plaid")])],1),_c('v-row',{staticClass:"align-text-left pl-3"},[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_vm._v(" Connect your bank account instantly via Plaid! ")])],1)],1):_vm._e(),(_vm.linkToken)?_c('div',{staticClass:"accounts-actions"},[_c('plaid-link',_vm._b({ref:"plaid"},'plaid-link',{
        env: _vm.env,
        webhook: _vm.webhook,
        clientName: _vm.clientName,
        onSuccess: _vm.onSuccess,
        onExit: _vm.onExit,
        receivedRedirectUri: _vm.redirectUri,
        token: _vm.linkToken
      },false),[_c('template',{slot:"button"},[(!_vm.hideButton)?_c('v-card',{staticClass:"my-3 py-3 px-2 banks",attrs:{"width":"100%","elevation":"0","disabled":!_vm.linkToken},on:{"click":_vm.openPlaid}},[_c('v-row',{staticClass:"d-flex full-width align-center",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mx-2 border-radius wallet-icon"},[_vm._v("mdi-bank")]),_c('strong',{staticClass:"ml-1"},[_vm._v("Link Bank via Plaid")])],1),_c('v-row',{staticClass:"align-text-left pl-3"},[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_vm._v(" Connect your bank account instantly via Plaid! ")])],1)],1):_vm._e()],1)],2)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogError),callback:function ($$v) {_vm.dialogError=$$v},expression:"dialogError"}},[_c('v-card',{staticClass:"d-flex justify-center flex-column pa-6",attrs:{"rounded":"0"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mx-2 mb-5"},[_c('v-icon',{staticClass:"exit-warning-icon mr-4",attrs:{"color":"error","x-large":""}},[_vm._v("mdi-alert")]),_c('v-card-title',{staticClass:"word-break align-text-left exit-warning-text"},[_vm._v(" "+_vm._s(_vm.error ? _vm.error : "There was an error linking your bank account.")+" ")])],1),_c('v-card-actions',{staticClass:"mx-12"},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"color":"brandCyan","depressed":"","width":"130"},on:{"click":function($event){_vm.dialogError = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="flex-column d-flex">
    <v-container v-if="moduleId" class="pa-0">
      <v-card rounded="0" elevation="0" width="100%" class="pb-4 px-8">
        <!-- <v-card-title class="pb-1">{{ displayName }}</v-card-title> -->
        <div class="flex-column d-flex">
          <!-- skeleton loader -->
          <div class="" v-if="loadingSurvey">
            <v-skeleton-loader
              type="card-heading, list-item-three-line"
            ></v-skeleton-loader>
          </div>
          <v-card
            rounded="0"
            width="1000"
            elevation="0"
            v-else-if="
              errorMessage ||
                (!quizCompleted &&
                  !(isConclusion && currentLearningCard) &&
                  !questionList[questionIndex])
            "
          >
            <!-- Div to display conclusion card content if it has content -->
            <p class="brandCyan--text font-weight-bold font-italic">
              Whoops!
            </p>
            <div>
              <p class="text-h6 font-weight-bold mt-13 email-text">
                Something went wrong
              </p>
              <div class="mt-9" v-if="errorMessage">{{ errorMessage }}</div>
              <div
                class="mt-9"
                v-else-if="!quizCompleted && questionList[questionIndex]"
              >
                We had trouble finding your survey questions.
              </div>
            </div>

            <div>
              <div class="d-flex justify-center align-center mt-10">
                <div class="outer-circle dark-red-background">
                  <div
                    class="inner-circle light-red-background d-flex justify-center align-center"
                  >
                    <v-icon color="white" class="reward-icon" x-large>
                      mdi-cancel
                    </v-icon>
                  </div>
                </div>
              </div>
              <v-divider class="my-10" />
              <div class="d-flex justify-center align-center full-width">
                <v-btn
                  color="brandCyan"
                  depressed
                  rounded
                  @click="getQuestions"
                  class="my-2 white--text"
                  width="180"
                  >Try Again</v-btn
                >
              </div>
            </div>
          </v-card>
          <!-- QUIZ Goes Here By Q type -->
          <!-- if quiz is incomplete and we have questions to ask -->
          <v-card
            rounded="0"
            elevation="0"
            v-else-if="!quizCompleted && questionList[questionIndex]"
          >
            <p class="brandCyan--text font-weight-bold font-italic">
              {{ questionIndex + 1 }} of {{ questionList.length }}
            </p>
            <QuizQuestionMultipleChoice
              v-if="
                questionList[questionIndex].type == 'MULTIPLE_CHOICE' &&
                  questionList.length > 0
              "
              :question="questionList[questionIndex]"
              :questionIndex="questionIndex"
              :questionCount="questionList.length"
              :learningCardAssociationId="
                currentLessonCard.learningCardAssociationId
              "
              :isSurveyQuestion="true"
              :surveyClientId="surveyClientId"
              :whistleToken="whistleToken"
              :enteredEmail="email"
              context="survey"
              @next-question="goToNextQuestion"
              @complete-quiz="completeQuiz"
            />
            <QuizQuestionSelectAll
              v-if="
                questionList[questionIndex] &&
                  questionList[questionIndex].type == 'SELECT_ALL'
              "
              :question="questionList[questionIndex]"
              :questionIndex="questionIndex"
              :questionCount="questionList.length"
              :learningCardAssociationId="
                currentLessonCard.learningCardAssociationId
              "
              :isSurveyQuestion="true"
              :surveyClientId="surveyClientId"
              :whistleToken="whistleToken"
              :enteredEmail="email"
              context="survey"
              @next-question="goToNextQuestion"
              @complete-quiz="completeQuiz"
            />
            <QuizQuestionScale
              v-else-if="
                questionList[questionIndex].type == 'SCALE' &&
                  questionList.length > 0
              "
              :question="questionList[questionIndex]"
              :questionIndex="questionIndex"
              :questionCount="questionList.length"
              :learningCardAssociationId="
                currentLessonCard.learningCardAssociationId
              "
              :isSurveyQuestion="true"
              :surveyClientId="surveyClientId"
              :whistleToken="whistleToken"
              :enteredEmail="email"
              context="survey"
              @next-question="goToNextQuestion"
              @complete-quiz="completeQuiz"
            />
            <QuizQuestionLikert
              v-else-if="
                questionList[questionIndex].type == 'LIKERT' &&
                  questionList.length > 0
              "
              :question="questionList[questionIndex]"
              :questionIndex="questionIndex"
              :questionCount="questionList.length"
              :learningCardAssociationId="
                currentLessonCard.learningCardAssociationId
              "
              :isSurveyQuestion="true"
              :surveyClientId="surveyClientId"
              :whistleToken="whistleToken"
              :enteredEmail="email"
              context="survey"
              @next-question="goToNextQuestion"
              @complete-quiz="completeQuiz"
            />
            <QuizQuestionShortAnswer
              v-else-if="
                questionList[questionIndex].type == 'SHORT_ANSWER' &&
                  questionList.length > 0
              "
              :question="questionList[questionIndex]"
              :questionIndex="questionIndex"
              :questionCount="questionList.length"
              :learningCardAssociationId="
                currentLessonCard.learningCardAssociationId
              "
              :isSurveyQuestion="true"
              :surveyClientId="surveyClientId"
              :whistleToken="whistleToken"
              :enteredEmail="email"
              context="survey"
              @next-question="goToNextQuestion"
              @complete-quiz="completeQuiz"
            />
          </v-card>
          <v-card rounded="0" width="1000" elevation="0" v-else>
            <!-- Div to display conclusion card content if it has content -->
            <p class="brandCyan--text font-weight-bold font-italic">
              Well done!
            </p>
            <div>
              <p
                class="text-h6 font-weight-bold mt-13 email-text"
                v-if="
                  quizCompleted &&
                    currentLearningCard &&
                    currentLearningCard.displayName
                "
              >
                {{ currentLearningCard.displayName }}
              </p>
              <p v-else class="text-h6 font-weight-bold mt-13 email-text">
                You have finished the
                {{
                  displayName
                    ? displayName +
                      (displayName.toLowerCase().includes("survey")
                        ? ""
                        : " survey")
                    : "survey"
                }}
              </p>
              <div
                v-if="
                  quizCompleted &&
                    currentLearningCard &&
                    (currentLearningCard.type == null ||
                      !['quiz', 'survey', 'assessment'].includes(
                        currentLearningCard.type
                      )) &&
                    currentLearningCard.content &&
                    currentLearningCard.content != null
                "
                class="mt-9"
                v-html="currentLearningCard.content"
              />
              <!-- Saftey check for a blank conclusion card, PublicSurveys start on survey card -->
              <div v-else-if="quizCompleted">
                <p>
                  You've already taken this survey, head to the Programs page
                  for more great content.
                </p>
              </div>
            </div>

            <div v-if="quizCompleted">
              <LearningMediaDisplay
                :card="currentLearningCard"
                context="survey"
                class="mt-10"
              />

              <v-divider class="my-10" />
              <div
                class="d-flex justify-center align-center full-width"
                :class="$vuetify.breakpoint.name === 'xs' ? 'flex-column' : ''"
              >
                <v-btn
                  v-if="!whistleToken"
                  depressed
                  rounded
                  color="brandCyan"
                  @click="$emit('exit')"
                  class="my-2 mx-5 white--text"
                  width="180"
                  >Go to Programs</v-btn
                >
                <v-btn
                  color="brandCyan"
                  depressed
                  rounded
                  v-if="isSurveyRepeatable == true"
                  @click="retakeSurvey"
                  class="my-2 white--text"
                  width="180"
                  >Take Again</v-btn
                >
              </div>
            </div>
          </v-card>
          <LoadingDialog
            :showDialog="!questionList"
            :header="loadingHeaderMessage"
            :line1="loadingMessageLine1"
            :line2="loadingMessageLine2"
          ></LoadingDialog>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

import LearningService from "@/services/LearningService";

import LoadingDialog from "@/components/loadingDialog.vue";
import QuizQuestionShortAnswer from "@/components/QuizQuestionShortAnswerV2";
import QuizQuestionMultipleChoice from "@/components/QuizQuestionMultipleChoiceV2";
import QuizQuestionSelectAll from "@/components/QuizQuestionSelectAllV2";
import QuizQuestionScale from "@/components/QuizQuestionScaleV2";
import QuizQuestionLikert from "@/components/QuizQuestionLikertV2";
import LearningMediaDisplay from "@/components/LearningMediaDisplay";

export default {
  name: "SurveyV2",
  props: {
    moduleId: Number,
    chapterId: Number,
    currentLessonCard: Object,
    completedStatus: Boolean,
    displayName: String,
    surveyClientId: Number,
    whistleToken: String,
    email: String,
    isSurveyRepeatable: Boolean,
    isConclusion: Boolean
  },
  components: {
    LoadingDialog,
    QuizQuestionShortAnswer,
    QuizQuestionMultipleChoice,
    QuizQuestionSelectAll,

    QuizQuestionScale,
    QuizQuestionLikert,
    LearningMediaDisplay
    // RobinAnimation,
  },
  watch: {
    currentLessonCard() {
      this.getQuestions();
    }
    // completedStatus: function(value) {
    //   console.log("Completed status updated ", value);
    //   this.quizCompleted = value;
    // },
  },
  data() {
    return {
      loadingSurvey: false,
      loadingHeaderMessage: "Loading Survey",
      loadingMessageLine1: "Please wait",
      loadingMessageLine2: null,
      // Array of question objects
      questionList: [],
      selected: "",
      questionCount: 0,
      chapterList: null,
      questionIndex: 0,
      currentQuestion: null,
      currentLearningCard: null,
      // quizCompleted: null,
      // if they click retake we flip this so that we not to start at latest log
      activelyRetaking: false,

      // Holds error message
      errorMessage: null
    };
  },
  created() {
    // Get card questions from learning service
    // console.log("Inside survey chapter component");
    // this.getQuestions()

    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  updated() {},
  mounted() {
    // Using mounted because it will re-call get questions when the props change (we go to next chapter)
    console.log("Inside survey chapter component");
    this.getQuestions();
  },

  beforeMount() {
    // this.getQuestions()
  },
  methods: {
    async getQuestions() {
      this.loadingSurvey = true;
      // get array of questions
      // Getting list of questions with learningCardId

      // if this is the conclusion, just set currentLearningCard to the card
      // no need to fetch questions
      this.errorMessage = null;
      try {
        if (this.isConclusion == true) {
          console.log("We are loading conclusion card", this.currentLessonCard);
          // public survey data objects have a nested LearningCard property
          // if (this.whistleToken) {
          //   this.currentLearningCard = this.currentLessonCard.LearningCard;
          // } else {
          this.currentLearningCard = this.currentLessonCard;
          // }
          this.loadingSurvey = false;

          // If for some reason they don't have the conclusion marked as completed, then we self heal and save now
          if (!this.currentLearningCard.completed) {
            this.$emit("saveConclusionLog");
          }
          return;
        } else {
          // resetting to clear end text
          this.currentLearningCard = null;
        }

        var qCard = await LearningService.getLearningCard(
          this.currentLessonCard.learningCardId,
          null,
          this.whistleToken
        );
        console.log("INSIDE SURVEY COMPONENT");
        console.log(qCard);
        this.currentLearningCard = qCard;
        // We have to filter any quiz responses by the learning card association id
        // qCard does not have a learning card association id so we will use the learningCardId from the card prop
        console.log(
          "Filtering responses for card ",
          this.currentLearningCard.learningCardId
        );
        qCard.LearningQuizQuestions.forEach(question => {
          question.LearningQuizResponses = question.LearningQuizResponses.filter(
            x =>
              x.learningCardAssocationId ==
              this.currentLessonCard.learningCardAssocationId
          );
          // Sort in descending order so we can grab the latest attempt
          question.LearningQuizResponses.sort(
            (q1, q2) => q2.attempt - q1.attempt
          );
        });
        this.questionList = qCard.LearningQuizQuestions;
        this.questionList.sort((q1, q2) => q1.order - q2.order);
        this.questionCount = this.questionList.length;
        var quizCompleted = false;
        // if the card has a latest learning log and it is not null, the score and completeion status are assigned and the survey is no longer loading
        // AND they are not actively retaking the survey
        if (
          this.currentLessonCard.latestLearningLog &&
          this.currentLessonCard.latestLearningLog != null &&
          !this.activelyRetaking
        ) {
          quizCompleted = this.currentLessonCard.latestLearningLog.completed;
          console.log("!!!!!!!!!!");
          console.log(quizCompleted);
          this.loadingSurvey = false;
        } else if (this.whistleToken !== undefined && !this.activelyRetaking) {
          // this is a public survey, it won't have a currentLessonCard.latestLearningLog but
          // it may have been previously completed, we have to check
          quizCompleted =
            // this.currentLessonCard.LearningCard.LearningLogs.length > 0
            this.currentLessonCard.LearningLogs.length > 0 ? true : false;
        }
        // Quiz completed is probably false here since there is not a latest learning log
        this.quizCompleted = quizCompleted;
        // Now we need to check if some of the questions have been answered and start from there!
        // Only if the card has no random questions and we arent actively retaking, otherwise we start at the beginning
        if (
          !quizCompleted &&
          this.currentLearningCard.numRandomQuestions == null &&
          !this.activelyRetaking
        ) {
          // If it hasn't been completed yet, then we load the quiz with the first question they haven't
          console.log(this.questionList);
          var loadQuizIndex = null;
          this.questionList.forEach((x, i) => {
            // We only care about questions until we get to the question we need to load the quiz in on
            // loadQuizIndex is null until we find the latest unanswered question
            if (loadQuizIndex === null) {
              if (x.LearningQuizResponses.length == 0) {
                // We found an unanswered question
                console.log("load quiz index", i);
                loadQuizIndex = i;
              }
              // else {
              //   // We have responses and we haven't found the index to load the quiz on yet, so we check the correctness
              //   if (
              //     x.LearningQuizResponses[0].correctness === null ||
              //     x.LearningQuizResponses[0].correctness === 1
              //   ) {
              //     this.correctCount++;
              //   }
              // }
            }
          });
          if (loadQuizIndex == null) {
            loadQuizIndex = 0;
          }
          this.questionIndex = loadQuizIndex;
          console.log("Loading quiz on index: ", loadQuizIndex);
        } else if (this.activelyRetaking == true) {
          // They have taken the survey once and want to retake it
          // iterate through and look for first question with less responses than before it
          // the number of responses the first question has
          let qCount = this.questionList[0].LearningQuizResponses.length;
          let startIndex = 0;
          // console.log('first q responses ', qCount);
          // this.questionIndex = 0;
          for (let i = 1; i < this.questionList.length; i++) {
            if (this.questionList[i].LearningQuizResponses.length < qCount) {
              // set the start index to i, because we found a question with less responses than the one before
              qCount = this.questionList[i].LearningQuizResponses.length;
              startIndex = i;
              // console.log('found start ', startIndex);
              break;
            }
          }
          this.questionIndex = startIndex;
          console.log("starting on index", startIndex);
        }
        // console.log('question index: ', this.questionIndex);
        this.loadingSurvey = false;
        console.log(this.quizCompleted);
      } catch (err) {
        console.log("Error getting survey ", err);
        this.loadingSurvey = false;
        this.errorMessage = "Please try again.";
      }
    },
    goToNextQuestion() {
      // goes to next question
      this.questionIndex += 1;
      console.log("goToNextQuestion in Survey");
    },
    // for the complete button
    completeQuiz() {
      console.log("complete!!!!");
      // emitting the completed event to parent to submit to learning log
      this.$emit("surveyCompleted");
    },
    retakeSurvey() {
      console.log("retake survey!!");
      this.quizCompleted = false;
      this.activelyRetaking = true;
      this.$emit("retakeSurvey");
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    quizCompleted: {
      get: function() {
        return this.completedStatus;
      },
      set: function(newVal) {
        this.$emit("update-completed", newVal);
      }
    }
  }
};

Array.prototype.equals = function(array) {
  // if the other array is a falsy value, return
  if (!array) return false;

  // compare lengths - can save a lot of time
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    // Check if we have nested arrays
    if (this[i] instanceof Array && array[i] instanceof Array) {
      // recurse into the nested arrays
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};
</script>

<style scoped>
.outer-circle {
  border-radius: 1000px;
  width: 125px;
  height: 125px;
  min-height: 125px;
  min-width: 125px;
  max-height: 125px;
  max-width: 125px;
}

.inner-circle {
  border-radius: 1000px;
  width: 109px;
  height: 109px;
  min-width: 109px;
  min-height: 109px;
  max-width: 109px;
  max-height: 109px;
  margin-top: 8px;
  margin-left: 8px;
}

.reward-icon {
  transform: scale(1.7);
}

.dark-red-background {
  background-color: #f90000;
}
.light-red-background {
  background-color: #f66;
}

.dark-blue-background {
  background-color: #97dffc;
}
.light-blue-background {
  background-color: #09acec;
}
</style>

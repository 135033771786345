<template>
  <div class="pb-0 full-width">
    <v-card
      :elevation="item.depth !== 1 ? 3 : 0"
      rounded="0"
      :class="{
        'my-2': item.depth !== 1,
        'mx-5': item.depth !== 1,
        card: item.depth !== 1,
      }"
    >
      <div class="d-flex flex-column py-1 align-center mx-2" v-if="item.rule">
        <div class="d-flex justify-space-between align-center full-width mx-2">
          <v-select
            label="Categories"
            :items="contexts"
            item-text="label"
            item-value="value"
            :disabled="readOnly"
            v-model="item.context"
            class="category-selector mr-3"
            @change="contextChanged"
          ></v-select>
          <v-btn
            text
            @click="clickDeleteButton(item.id)"
            icon
            class="mx-2"
            v-if="!readOnly"
          >
            <v-icon
              color="white"
              class="pa-4 grey-background circle-border-radius"
              >mdi-trash-can-outline</v-icon
            >
          </v-btn>
        </div>
        <div class="d-flex align-center full-width mx-2">
          <v-select
            v-if="item.context"
            label="Options"
            :items="filteredContexts"
            item-text="label"
            item-value="contextResourceId"
            :disabled="readOnly"
            v-model="item.contextResourceId"
            class="context-selector mr-3"
            @change="contextColumnChanged"
          ></v-select>
          <v-select
            :items="filteredOperators"
            label="Operator"
            item-text="label"
            item-value="value"
            :disabled="readOnly"
            v-model="item.operator"
            class="operator-selector mr-3"
            @change="operatorChanged"
          ></v-select>

          <v-text-field
            v-if="
              (!item.operator ||
                !['IS_NULL', 'IS_NOT_NULL'].includes(item.operator)) &&
                (item.dataType == 'STRING' ||
                  item.dataType == 'INTEGER' ||
                  item.dataType == 'DECIMAL' ||
                  item.dataType == 'NUMBER')
            "
            :disabled="readOnly"
            v-model="item.value"
            :label="
              item.context != 'LearningModules'
                ? 'Enter a value'
                : 'Percent Complete'
            "
            :type="
              item.dataType == 'INTEGER' ||
              item.dataType == 'DECIMAL' ||
              item.context == 'LearningModules' ||
              (item.context == 'Users' &&
                (item.contextColumn == 'roleStartDate' ||
                  item.contextColumn == 'jobStartDate'))
                ? 'number'
                : 'text'
            "
            class="value-field"
            @keypress.native="isNumber($event)"
          ></v-text-field>
          <v-select
            v-else-if="
              (!item.operator ||
                !['IS_NULL', 'IS_NOT_NULL'].includes(item.operator)) &&
                item.dataType == 'SELECTOR'
            "
            :items="selectableValues"
            label="Select a Value"
            item-text="label"
            item-value="value"
            :disabled="readOnly"
            v-model="item.value"
            class="value-field"
          ></v-select>
          <v-checkbox
            v-else-if="
              (!item.operator ||
                !['IS_NULL', 'IS_NOT_NULL'].includes(item.operator)) &&
                item.dataType == 'BOOLEAN'
            "
            :disabled="readOnly"
            label="True"
            v-model="item.value"
          ></v-checkbox>
          <!-- <v-date-picker
           
            v-model="item.value"
          ></v-date-picker> -->
          <v-menu
            v-else-if="
              (!item.operator ||
                !['IS_NULL', 'IS_NOT_NULL'].includes(item.operator)) &&
                item.dataType === 'DATE'
            "
            :disabled="readOnly"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            attach
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="item.value"
                label="Date"
                append-icon="mdi-calendar"
                readonly
                :disabled="readOnly"
                v-bind="attrs"
                v-on="on"
                :rules="[(v) => !!v || 'Date is required']"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="item.value"
              :disabled="readOnly"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>
          <input
            class="timestamp-input"
            type="datetime-local"
            :disabled="readOnly"
            v-else-if="
              (!item.operator ||
                !['IS_NULL', 'IS_NOT_NULL'].includes(item.operator)) &&
                item.dataType === 'TIMESTAMP'
            "
            v-model="item.value"
          />
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-center justify-space-between mb-6 pl-4">
          <div class="d-flex pt-4 align-center">
            <span class="mr-3">Match</span>
            <v-select
              outlined
              :items="logicalOperators"
              item-text="label"
              item-value="value"
              :disabled="readOnly"
              v-model="item.operator"
              hide-details
              dense
              class="and-or-selector mx-2 pa-0"
            ></v-select>
            <span class="ml-3"> of the following rules:</span>
          </div>
          <v-btn
            text
            @click="clickDeleteButton(item.id)"
            v-if="item.depth > 1 && !readOnly"
            icon
            class="mr-2"
          >
            <v-icon
              color="white"
              class="pa-4 grey-background circle-border-radius"
              >mdi-trash-can-outline</v-icon
            >
          </v-btn>
        </div>
        <!-- This is where the recursive component goes -->
        <div v-for="(itemRecursive, i) in item.group" :key="itemRecursive.id">
          <RuleComponentV2
            :item="itemRecursive"
            :logicalOperators="logicalOperators"
            :contextOptions="contextOptions"
            :contexts="contexts"
            :operators="operators"
            :context="context"
            @remove="removeFromGroup"
            :hideChip="i === item.group.length - 1 ? true : false"
            :operator="item.operator"
            :readOnly="readOnly"
            :clientId="clientId"
            :includeSubClients="includeSubClients"
          ></RuleComponentV2>
        </div>
        <div class="d-flex py-4 pl-3">
          <v-btn
            text
            class="mr-4 py-7 grey--text"
            @click="addGroup"
            v-if="item.depth <= 2 && !readOnly"
            ><v-icon
              color="white"
              class="mr-1 pa-2 accent-background circle-border-radius"
              >mdi-plus</v-icon
            ><span
              v-if="
                item.group.length > 0 &&
                  item.group.filter((x) => !x.rule).length > 0
              "
              >Add another collection of rules</span
            >
            <span v-else>Add a collection of rules</span></v-btn
          >
          <v-btn text class="py-7 grey--text" @click="addRule" v-if="!readOnly"
            ><v-icon
              color="white"
              class="mr-2 pa-2 accent-background circle-border-radius"
              >mdi-plus</v-icon
            ><span
              v-if="
                item.group.length > 0 &&
                  item.group.filter((x) => x.rule).length > 0 &&
                  context == 'Programs'
              "
              >Add another qualifying rule</span
            ><span
              v-else-if="
                item.group.length > 0 &&
                  item.group.filter((x) => x.rule).length == 0 &&
                  context == 'Programs'
              "
              >Add a qualifying rule</span
            ><span
              v-else-if="
                item.group.length > 0 &&
                  item.group.filter((x) => x.rule).length > 0
              "
              >Add another rule</span
            ><span v-else>Add a rule</span></v-btn
          >
        </div>
      </div>
    </v-card>
    <div v-if="!hideChip" class="my-4 d-flex justify-start align-center ml-4">
      <b class="primary--text">{{ operator }}</b>
    </div>
  </div>
</template>

<script>
import UserService from "../services/UserService.js";

export default {
  name: "RuleComponentV2",
  components: {},
  props: {
    logicalOperators: Array,
    contextOptions: Array,
    contexts: Array,
    context: String,
    operators: Array,
    item: Object,
    hideChip: Boolean,
    operator: String,
    readOnly: Boolean,
    clientId: Number,
    includeSubClients: Boolean,
  },
  data() {
    return {
      selectableValues: [],
      contextResourceId: null,
      dateMenu: false,
    };
  },
  created() {
    // If this component gets preloaded with a User related item, then we fetch all values and preload their selected value
    if (
      this.item.context &&
      // this.item.context == "Users" &&
      this.item.contextColumn &&
      // this.item.contextColumn != "roleStartDate" &&
      // this.item.contextColumn != "jobStartDate" &&
      this.item.contextResourceId
    ) {
      this.contextColumnChanged(this.item.contextResourceId, this.item.value);
    } else if (this.item.contextResourceId) {
      this.contextResourceId = this.item.contextResourceId;
    }
  },
  beforeDestroy() {},
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
    addRule() {
      this.item.group.map((x) => {
        x.lastInGroup = false;
        return x;
      });
      this.item.group.push({
        rule: true,
        id: Date.now(),
        // selectedContext: null,
        context: null,
        contextResourceId: null,
        contextColumn: null,
        operator: null,
        value: null,
        lastInGroup: true,
        dataType: "STRING",
      });
    },
    addGroup() {
      this.item.group.map((x) => {
        x.lastInGroup = false;
        return x;
      });
      this.item.group.push({
        rule: false,
        id: Date.now(),
        depth: this.item.depth + 1,
        operator: "AND",
        group: [],
        lastInGroup: true,
      });
    },
    clickDeleteButton(id) {
      this.$emit("remove", id);
    },
    removeFromGroup(id) {
      var index = this.item.group.findIndex((x) => x.id === id);
      this.item.group.splice(index, 1);

      //If they removed the last item, then we change the lastInGroup flag for the new last one
      if (this.item.group.length > 0) {
        this.item.group[this.item.group.length - 1].lastInGroup = true;
      }
    },
    operatorChanged(op) {
      // Wipes value if operator is null
      // console.log("Operator changed ", op);
      if (["IS_NULL", "IS_NOT_NULL"].includes(op)) {
        this.item.value = "null";
        console.log("Wiping user value due to null operator");
      }
    },
    contextColumnChanged(id, preloadValue = null) {
      //Here we are passed the object, we need to find it within the array and assign all columns
      console.log("Context column changed");
      if (id !== null) {
        var obj = this.filteredContexts.find((x) => id == x.id);
        this.selectableValues = [];

        if (obj) {
          this.contextResourceId = obj.contextResourceId;
          this.item.contextResourceId = obj.contextResourceId;
          this.item.contextColumn = obj.contextColumn;
          this.item.value = preloadValue;
          this.item.dataType = obj.dataType;

          //Now we fire off a call to get distinct values for whatever column we have
          if (
            // obj.context === "Users" &&
            // obj.contextColumn != "roleStartDate" &&
            // obj.contextColumn != "jobStartDate"
            obj.dataType == "SELECTOR"
          ) {
            console.log(this.includeSubClients);
            UserService.getValuesForUserColumn(
              obj.contextColumn,
              this.clientId,
              this.includeSubClients
            ).then((values) => {
              console.log(values);
              var trimmedValues = values.map((x) => {
                if (Object.values(x)[0] == null) {
                  return "null";
                } else {
                  return Object.values(x)[0];
                }
              });
              trimmedValues = trimmedValues.filter((x) => x != "Deleted");
              this.selectableValues = trimmedValues;
              console.log(trimmedValues);
            });
          }
        }
      }
    },
    contextChanged() {
      //Here we are passed the object, we need to find it within the array and assign all columns
      console.log("Context changed: ", this.item.context);
      if (this.item.context !== null) {
        this.item.contextResourceId = null;
        this.item.contextColumn = null;
        this.item.value = null;
      }
    },
    isNumber(evt) {
      if (this.item.dataType !== "INTEGER") {
        return true;
      }
      console.log(evt);
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
  },
  computed: {
    filteredOperators() {
      console.log("Operators: ", this.operators);
      console.log(this.item);
      if (
        (this.item.dataType &&
          (this.item.dataType == "NUMBER" ||
            this.item.dataType == "INTEGER" ||
            this.item.dataType == "DECIMAL")) ||
        (this.item.context !== "Users" &&
          this.filteredContexts.find((x) => x.id == this.contextResourceId) &&
          this.filteredContexts.find((x) => x.id == this.contextResourceId)
            .showNumberOptions) ||
        // We also show number operators if we're dealing with job/role start date
        (this.item.context == "Users" &&
          (this.item.contextColumn == "roleStartDate" ||
            this.item.contextColumn == "jobStartDate"))
      ) {
        return this.operators;
      } else {
        return this.operators.filter((x) => !x.number);
      }
    },
    filteredContexts() {
      if (this.item.context) {
        console.log("Context: ", this.item.context);
        return this.contextOptions.filter(
          (x) => x.context == this.item.context
        );
      }
      return [];
    },
  },
  watch: {
    contextOptions: function(newVal) {
      console.log("Updated context options", newVal);
      if (
        this.item.context &&
        // this.item.context == "Users" &&
        this.item.contextColumn &&
        // this.item.contextColumn != "roleStartDate" &&
        // this.item.contextColumn != "jobStartDate" &&
        this.item.contextResourceId
      ) {
        this.contextColumnChanged(this.item.contextResourceId, this.item.value);
      } else if (this.item.contextResourceId) {
        this.contextResourceId = this.item.contextResourceId;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.and-or-selector {
  max-width: 100px;
}

.context-selector {
  width: 50%;
}

.category-selector {
  width: 40%;
  max-width: 300px;
}

.operator-selector {
  width: 20%;
}

.value-field {
  width: 25%;
}

.grey-background {
  background-color: var(--v-lightGrey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.card {
  border: 1px solid;
  border-color: var(--v-grey-base);
}

.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 16px 0px;
  /* color: rgba(0, 0, 0, 0.38); */
}
</style>

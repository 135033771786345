<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :color="color"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        :color="color"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        readonly
        v-bind="attrs"
        v-on="on"
        :outlined="outlined"
        :dense="dense"
        :clearable="clearable"
        :hide-details="hideDetails"
        :style="textFieldStyle"
        :class="textFieldClass"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu = false"
    ></v-date-picker> </v-menu
></template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: {},
    color: {
      type: String,
      default: "brandCyan"
    },
    label: {
      type: String
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: "mdi-calendar"
    },
    appendIcon: {
      type: String,
      default: "mdi-calendar"
    },
    textFieldStyle: {
      type: String
    },
    textFieldClass: {
      type: String
    }
  },
  data() {
    return {
      menu: false
    };
  },
  methods: {},
  computed: {
    date: {
      get: function() {
        return this.value;
      },
      set: function(newVal) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

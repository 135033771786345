<template>
  <div class="my-2 ml-3 mr-2">
    <v-card flat class="my-2 ml-3 mr-2">
      <v-row>
        <v-col sm="12" md="3">
          <h2 class="align-text-left mb-3">Know Your Business Manager</h2>
          <v-divider></v-divider>
          <p class="align-text-left mt-3">
            Welcome to Whistle's KYB manager.
          </p>
          <p class="align-text-left">
            Here you can view the status of Whistle clients and update logs as
            their KYB case progresses.
          </p>
        </v-col>
        <v-col sm="12" md="9">
          <div class="d-flex justify-space-between align-center mr-2 mb-3">
            <v-btn text color="primary" to="accountManagement">Clients</v-btn>
            <v-text-field
              outlined
              placeholder="Search"
              prepend-icon="mdi-magnify"
              clearable
              single-line
              dense
              hide-details
              v-model="search"
              class="shrink"
            ></v-text-field>
          </div>
          <div>
            <div v-if="loading" class="d-flex flex-column align-center">
              <Robin
                :height="100"
                :width="100"
                :showText="false"
                animation="egypt"
                :loop="true"
              ></Robin>
              <h2>Loading the businesses</h2>
              <h3>This will just take a sec!</h3>
            </div>
            <v-expansion-panels v-model="panel" v-if="!loading">
              <v-expansion-panel
                v-for="client in computedBusinesses"
                :key="client.clientId"
              >
                <v-expansion-panel-header>
                  <div class="d-flex justify-space-between align-center pr-8">
                    <h3>
                      {{ client.clientId }}:
                      {{
                        (client.Client && client.Client.clientName) ||
                          "Unnamed Item"
                      }}
                    </h3>

                    <div class="d-flex align-center">
                      <v-btn
                        color="primary"
                        class="mr-4"
                        @click.native.stop="newLog(client.clientId)"
                        >+ New Log</v-btn
                      >
                      <h3>
                        {{ client.BusinessLogs.length }}
                        {{ client.BusinessLogs.length == 1 ? "log" : "logs" }}
                      </h3>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="align-text-left">
                  <p>
                    Business Token: <b>{{ client.businessToken }}</b>
                  </p>
                  <b v-if="client.User" class="mb-2">Contact Info:</b>
                  <p v-if="client.User" class="ml-4 mb-1">
                    Name:
                    <b>{{
                      client.User.firstName + " " + client.User.lastName
                    }}</b>
                  </p>
                  <p v-if="client.User" class="ml-4 mb-1">
                    Work Email: <b>{{ client.User.businessEmail }}</b>
                  </p>
                  <p v-if="client.User" class="ml-4 mb-1">
                    Personal Email: <b>{{ client.User.personalEmail }}</b>
                  </p>
                  <p v-if="client.User" class="ml-4 mb-1">
                    Work Phone: <b>{{ client.User.businessPhone }}</b>
                  </p>
                  <p v-if="client.User" class="ml-4 mb-1">
                    Personal Phone: <b>{{ client.User.personalPhone }}</b>
                  </p>
                  <b>KYB Status: </b>
                  <ul class="mb-3">
                    <li>
                      Business:
                      {{
                        client.businessPassedKYC
                          ? "Passed"
                          : client.status == "Limited"
                          ? "Not started"
                          : "Pending"
                      }}
                    </li>
                    <li>
                      Officer:
                      {{
                        client.officerPassedKYC
                          ? "Passed"
                          : client.status == "Limited"
                          ? "Not started"
                          : "Pending"
                      }}
                    </li>
                    <li>
                      Owner 1:
                      {{
                        client.owner1PassedKYC
                          ? "Passed"
                          : client.status == "Limited"
                          ? "Not started"
                          : "Pending"
                      }}
                    </li>
                    <li>
                      Owner 2:
                      {{
                        client.owner2PassedKYC
                          ? "Passed"
                          : client.status == "Limited"
                          ? "Not started"
                          : "Pending"
                      }}
                    </li>
                    <li>
                      Owner 3:
                      {{
                        client.owner3PassedKYC
                          ? "Passed"
                          : client.status == "Limited"
                          ? "Not started"
                          : "Pending"
                      }}
                    </li>
                    <li>
                      Owner 4:
                      {{
                        client.owner4PassedKYC
                          ? "Passed"
                          : client.status == "Limited"
                          ? "Not started"
                          : "Pending"
                      }}
                    </li>
                  </ul>
                  <b v-if="client.kybResults && client.kybResults.kyb"
                    >Original KYB Results:</b
                  >
                  <ul v-if="client.kybResults && client.kybResults.kyb">
                    <li
                      v-if="
                        client.kybResults.kyb.find(
                          x => x.component == 'BUSINESS'
                        )
                      "
                    >
                      <p class="mb-0">Business:</p>
                      <ul>
                        <li>
                          Outcome:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BUSINESS"
                            ).outcome
                          }}
                        </li>
                        <li>
                          Reference Id:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BUSINESS"
                            ).reference_id
                          }}
                        </li>
                        <li>
                          Outcome Reasons:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BUSINESS"
                            ).outcome_reasons
                          }}
                        </li>
                      </ul>
                    </li>
                    <li
                      v-if="
                        client.kybResults.kyb.find(
                          x => x.component == 'PROPRIETOR'
                        )
                      "
                    >
                      <p class="mb-0">Officer:</p>
                      <ul>
                        <li>
                          Outcome:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "PROPRIETOR"
                            ).outcome
                          }}
                        </li>
                        <li>
                          Reference Id:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "PROPRIETOR"
                            ).reference_id
                          }}
                        </li>
                        <li>
                          Outcome Reasons:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "PROPRIETOR"
                            ).outcome_reasons
                          }}
                        </li>
                      </ul>
                    </li>
                    <li
                      v-if="
                        client.kybResults.kyb.find(
                          x => x.component == 'BENEFICIAL_OWNER1'
                        )
                      "
                    >
                      <p class="mb-0">Owner 1:</p>
                      <ul>
                        <li>
                          Outcome:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER1"
                            ).outcome
                          }}
                        </li>
                        <li>
                          Reference Id:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER1"
                            ).reference_id
                          }}
                        </li>
                        <li>
                          Outcome Reasons:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER1"
                            ).outcome_reasons
                          }}
                        </li>
                      </ul>
                    </li>
                    <li
                      v-if="
                        client.kybResults.kyb.find(
                          x => x.component == 'BENEFICIAL_OWNER2'
                        )
                      "
                    >
                      <p class="mb-0">Owner 2:</p>
                      <ul>
                        <li>
                          Outcome:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER2"
                            ).outcome
                          }}
                        </li>
                        <li>
                          Reference Id:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER2"
                            ).reference_id
                          }}
                        </li>
                        <li>
                          Outcome Reasons:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER2"
                            ).outcome_reasons
                          }}
                        </li>
                      </ul>
                    </li>
                    <li
                      v-if="
                        client.kybResults.kyb.find(
                          x => x.component == 'BENEFICIAL_OWNER3'
                        )
                      "
                    >
                      <p class="mb-0">Owner 3:</p>
                      <ul>
                        <li>
                          Outcome:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER3"
                            ).outcome
                          }}
                        </li>
                        <li>
                          Reference Id:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER3"
                            ).reference_id
                          }}
                        </li>
                        <li>
                          Outcome Reasons:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER3"
                            ).outcome_reasons
                          }}
                        </li>
                      </ul>
                    </li>
                    <li
                      v-if="
                        client.kybResults.kyb.find(
                          x => x.component == 'BENEFICIAL_OWNER4'
                        )
                      "
                    >
                      <p class="mb-0">Owner 4:</p>
                      <ul>
                        <li>
                          Outcome:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER4"
                            ).outcome
                          }}
                        </li>
                        <li>
                          Reference Id:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER4"
                            ).reference_id
                          }}
                        </li>
                        <li>
                          Outcome Reasons:
                          {{
                            client.kybResults.kyb.find(
                              x => x.component == "BENEFICIAL_OWNER4"
                            ).outcome_reasons
                          }}
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <p class="my-4"><b>Logs: (Newest to Oldest)</b></p>
                  <div
                    v-for="log in client.BusinessLogs"
                    :key="log.businessLogId"
                  >
                    <div class="d-flex align-center">
                      <span>Log Date: {{ log.updatedAt }}</span>
                      <v-btn icon @click="editLog(log)"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </div>
                    <p class="mt-2">{{ log.comment }}</p>
                    <b v-if="log.rawObject">Raw Object from Webhook:</b>
                    <p v-if="log.rawObject">{{ log.rawObject }}</p>
                    <v-divider class="py-3" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- Nav drawer for adding/editing logs -->
    <v-navigation-drawer
      v-model="dialogLogForm"
      temporary
      fixed
      right
      width="500"
    >
      <v-card flat>
        <v-toolbar dark color="primary" rounded="0">
          <v-toolbar-title v-if="log.businessLogId == undefined"
            >Create New Log</v-toolbar-title
          >
          <v-toolbar-title v-else>Update Log</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="resetForm">
              <span>Close</span>
            </v-btn>
            <v-btn
              dark
              text
              @click="createBusinessLog"
              :disabled="!log.valid"
              v-if="log.businessLogId == undefined"
            >
              Create
            </v-btn>
            <v-btn
              dark
              text
              @click="updateBusinessLog"
              :disabled="!log.valid"
              v-else
              >Update</v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text>
          <v-row justify="center">
            <v-col
              class="d-flex flex-column mt-3"
              cols="12"
              sm="9"
              md="9"
              lg="9"
            >
              <v-form ref="logForm" v-model="log.valid">
                <v-textarea
                  outlined
                  class="pa-1"
                  v-model="log.comment"
                  :rules="[v => !!v || 'A message is required']"
                  label="Comment*"
                  rows="6"
                ></v-textarea>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import UserService from "@/services/UserService";
import LoadingDialog from "@/components/loadingDialog.vue";
import Robin from "@/components/Robin.vue";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "MarqetaKYBManager",
  props: {},
  components: {
    LoadingDialog,
    Robin
  },
  data() {
    return {
      clientId: null,
      panel: null,
      businesses: [],
      users: [],
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,

      loading: false,
      search: null,
      dialogLogForm: false,
      log: {
        clientId: null,
        comment: null,
        businessLogId: null,
        valid: false
      }
    };
  },
  created() {
    this.getBusinesses();
  },
  beforeDestroy() {},
  methods: {
    getBusinesses() {
      this.loading = true;
      var startTime = new Date();
      MarqetaService.getBusinesses().then(clients => {
        console.log("Clients: ", clients);
        clients.forEach(client => {
          var foundClient = this.clients.find(
            x => x.clientId == client.clientId
          );
          if (foundClient) {
            client.Client = foundClient;
          } else {
            client.Client = null;
          }
          // Sorts logs newest to oldest
          client.BusinessLogs.sort((a, b) =>
            moment(a.updatedAt).isBefore(moment(b.updatedAt)) ? 1 : -1
          );
          client.BusinessLogs.forEach(log => {
            log.updatedAt = moment(log.updatedAt)
              .local()
              .format("YYYY-MM-DD h:mm:ss a");
          });
        });
        this.businesses = clients;

        // Then we pass off to go fetch the names of the users that created these businesses
        this.getBusinessUsers();
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff < 1500) {
          setTimeout(() => {
            this.loading = false;
          }, 1500 - timeDiff);
        } else {
          this.loading = false;
        }
      });
    },
    async getBusinessUsers() {
      let users = this.businesses
        .filter(x => x.createdBy)
        .map(x => x.createdBy);
      if (!users.length) return;
      try {
        let response = await UserService.getUsersV2({
          filter: `status != 'Deleted' && userId.isIn(${JSON.stringify(
            users
          )})`,
          limit: users.length,
          extract:
            "userId,firstName,lastName,businessEmail,personalEmail,businessPhone,personalPhone"
        });
        console.log("Got users", response.result.count);
        this.users = response.result.rows;
      } catch (err) {
        console.log("Error getting business owners ", err);
      }
    },
    createBusinessLog() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating New Log";
      this.loadingMessageLine1 = "Give us just a moment!";
      MarqetaService.createBusinessLog(this.log).then(resp => {
        console.log("Log response: ", resp);
        this.showLoadingDialog = false;
        this.resetForm();
        this.getBusinesses();
      });
    },
    updateBusinessLog() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Updating Your Log";
      this.loadingMessageLine1 = "Give us just a moment!";
      MarqetaService.updateBusinessLog(this.log.businessLogId, this.log).then(
        resp => {
          console.log("Log response: ", resp);
          this.showLoadingDialog = false;
          this.resetForm();
          this.getBusinesses();
        }
      );
    },
    editLog(oldLog) {
      this.dialogLogForm = true;
      this.log = {
        clientId: oldLog.clientId,
        businessLogId: oldLog.businessLogId,
        comment: oldLog.comment
      };
    },
    newLog(clientId) {
      this.log = {
        clientId: clientId,
        comment: null
      };
      this.dialogLogForm = true;
    },
    resetForm() {
      this.log = {
        clientId: null,
        businessLogId: null,
        comment: null,
        valid: false
      };
      this.dialogLogForm = false;
    }
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    computedBusinesses() {
      var businesses = this.businesses;
      if (this.search)
        businesses = businesses.filter(
          b =>
            b.Client &&
            b.Client.clientName
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      businesses.forEach(business => {
        var user = this.users.find(x => x.userId == business.createdBy);
        if (user) {
          business.User = user;
        } else {
          business.User = null;
        }
      });
      return businesses;
    }
  }
};
</script>

<style scoped></style>
<style lang="css" scoped></style>

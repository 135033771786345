<template>
  <div class="full-width">
    <div class="d-flex justify-space-between align-center mx-2 mb-4">
      <v-btn text color="primary" @click="close"
        ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
      >
      <v-text-field
        outlined
        placeholder="Search"
        prepend-icon="mdi-magnify"
        clearable
        single-line
        dense
        hide-details
        v-model="search"
        class="shrink"
      ></v-text-field>
    </div>
    <v-data-table
      :headers="budgetColumns"
      :items="budgetActivity"
      :search="search"
      item-key="budgetActivityId"
      :items-per-page="10"
      class="elevation-0 full-width budget-table"
      loading-text="Loading... Please wait"
      no-data-text="No funding activity found"
    >
      <template v-slot:[`item.amount`]="{ item }">
        <span
          ><span v-if="item.awardId == 1">$</span
          >{{
            formatCurrency(truncateNum(item.amount ? item.amount : 0, 2))
          }}</span
        >
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <span>{{ item.description }}{{ item.giving ? " (Gift)" : "" }}</span>
      </template>
      <template v-slot:[`item.formattedStatus`]="{ item }">
        <div class="d-flex align-center">
          <v-icon
            v-if="item.formattedStatus == 'Complete'"
            class="mr-2"
            color="success"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon
            v-else-if="item.formattedStatus == 'Pending'"
            class="mr-2"
            color="orange"
            >mdi-clock-outline</v-icon
          >
          <v-icon
            v-else-if="item.formattedStatus == 'Request Pending'"
            class="mr-2"
            color="orange"
            >mdi-account-clock</v-icon
          >

          <span>{{ item.formattedStatus }}</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import Robin from "@/components/Robin";

import moment from "moment";
import { mapState } from "vuex";
import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

export default {
  name: "BudgetActivityManager",
  props: { budgets: Array, clientId: Number, budgetId: Number },
  components: {
    // Robin,
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      search: null,
      budgetColumns: [
        { text: "Date", value: "createdAtFormatted", width: "15%" },
        { text: "Budget", value: "budgetName", width: "20%" },
        { text: "User", value: "User.displayName", width: "20%" },
        { text: "Description", value: "description", width: "20%" },
        { text: "Amount", value: "amount", width: "15%" },
        {
          text: "Status",
          value: "formattedStatus",
          width: "15%",
          align: "center"
        }
      ]
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    close() {
      this.$emit("close");
    }
  },
  computed: {
    ...mapState(["userProfile", "displayWalletMenu", "walletMenuScreen"]),
    budgetActivity() {
      if (!this.budgets) {
        return [];
      }
      var budgetActivity = [];
      this.budgets
        .filter(
          x =>
            x.clientId == this.clientId &&
            (!this.budgetId || x.budgetId == this.budgetId)
        )
        .forEach(budget => {
          budgetActivity = budgetActivity.concat(budget.BudgetActivities);
        });

      return budgetActivity
        .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1))
        .filter(activity => activity.displayInFundingActivity)
        .map(activity => {
          if (
            (!activity.stripeStatus && activity.type == "MANUAL") ||
            activity.stripeStatus == "succeeded"
          ) {
            activity.formattedStripeStatus = "Complete";
          } else if (
            (!activity.stripeStatus && activity.type == "BUDGET_TO_BUDGET") ||
            activity.stripeStatus == "succeeded"
          ) {
            activity.formattedStripeStatus = "Complete";
          } else if (activity.stripeStatus == "pending") {
            activity.formattedStripeStatus = "Pending";
          } else if (activity.status == "Requested")
            activity.formattedStripeStatus = "Request Pending";
          else if (activity.status == "Declined")
            activity.formattedStripeStatus = "Request Declined";
          else activity.formattedStripeStatus = activity.stripeStatus;

          if (activity.type == "CREDIT_CARD") {
            activity.type = "CC";
          } else if (activity.type == "ACH") {
            activity.type = "ACH";
          } else if (activity.type == "MANUAL") {
            activity.type = "Whistle";
          } else if (activity.type == "BUDGET_TO_BUDGET") {
            activity.type = "Budget Transfer";
          }

          activity.createdAtFormatted = moment(activity.createdAt)
            .utc()
            .local()
            .format("MMM DD, YYYY");
          return activity;
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
.budget-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.budget-table >>> .v-data-table-header {
  background-color: white;
}
</style>

<template>
  <div>
    <v-toolbar dark color="brandCyan" elevation="0" rounded="0">
      <v-toolbar-title>{{ computedTitleBarText }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetForm">
          {{
            screen == "manage" &&
            banks.filter(x => x.status == "verified").length > 0
              ? "Back"
              : "Cancel"
          }}
          <v-icon class="ml-2 mb-1">mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div class="px-3" v-if="screen == 'home'">
      <div v-if="error" class="pt-4 px-4">
        <h2>We had trouble loading your account</h2>
        <p class="mt-2">
          Please try refreshing the page and if it continues, please
          <a href="mailto:help@wewhistle.com">contact us</a>.
        </p>
      </div>

      <div v-else-if="loading" class="pt-4">
        <v-card-title class="word-break"
          >Loading any bank accounts connected...</v-card-title
        >
        <div class="full-width d-flex justify-center">
          <Robin
            :height="125"
            :width="125"
            :largeText="true"
            :showText="false"
            animation="discoPointing"
            class="my-2"
            :loop="true"
          ></Robin>
        </div>
      </div>
      <div v-else class="text-left">
        <StripeACHPayment
          v-if="!loading && screen == 'home'"
          :budget="budget"
          :banks="banks"
          :fundingLimit="fundingLimit"
          :canCompleteKYB="canCompleteKYB"
          :source="source"
          :givingMode="givingMode"
          @reset="resetForm"
          @load-budget-activity="loadBudgetActivity"
          @get-budgets="getBudgets"
          @get-funding-limit="getFundingLimit"
        />
      </div>
    </div>
    <div v-else-if="screen == 'manage'" class="px-3">
      <v-card-title v-if="banks.length > 0" class="pt-3 pb-0 pl-1 text-left">
        Accounts
      </v-card-title>

      <v-card
        v-for="bank in banks"
        :key="bank.id"
        width="100%"
        elevation="0"
        class="my-3 py-3 px-2 banks"
      >
        <v-row
          no-gutters
          class="d-flex justify-space-between full-width align-center"
        >
          <div class="d-flex align-center">
            <v-icon class="mx-2 border-radius wallet-icon">mdi-bank</v-icon>
            <strong class="ml-1"
              >{{ bank.bank_name }} ...{{ bank.last4 }}</strong
            >
          </div>
          <div class="d-flex">
            <v-btn
              v-if="
                bank.status != 'verified' &&
                  bank.status != 'verification_failed'
              "
              color="primary"
              text
              @click="
                (screen = 'verify'),
                  (bankIdSelected = bank.id),
                  (customerId = bank.customer)
              "
              >Verify</v-btn
            >
            <v-tooltip top v-else-if="bank.status == 'verification_failed'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="error"
                  >mdi-alert</v-icon
                >
              </template>
              <span
                >Due to the number of failures, please contact us to complete
                verification</span
              >
            </v-tooltip>

            <v-menu max-width="260" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon text v-on="on" v-bind="attrs"
                  ><v-icon>mdi-dots-horizontal</v-icon></v-btn
                >
              </template>
              <v-list>
                <v-list-item
                  @click="(dialogDeleteBank = true), (bankToDelete = bank.id)"
                >
                  <v-list-item-title class="text-left"
                    ><v-icon color="red" class="mr-2">mdi-delete</v-icon
                    >Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
      </v-card>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        size="50"
        class="my-6"
      ></v-progress-circular>
      <v-card
        width="100%"
        elevation="0"
        class="mt-5 banks"
        v-if="displayACHWhitelist && banks.length > 0"
      >
        <v-card-title class="d-flex justify-space-between">
          <div class="d-flex">
            <v-icon color="error" class="mr-2">mdi-alert</v-icon>Add Whistle to
            your safety list
          </div>
          <v-btn icon @click="displayACHWhitelist = false"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-card-text class="text-left"
          >If you have ACH filtering or blocking set up, please allow
          transactions from Stripe Payments Company or one of the two ACH IDs:
          1800948598 or 4270465600</v-card-text
        >
      </v-card>
      <v-card-title class="pt-5 pl-1 pb-0 text-left">
        Link New Account
      </v-card-title>
      <Plaid
        ref="plaidComponent"
        :preloadMenu="preloadMenu"
        :hideButton="false"
        @route="autoRouteUser"
        @get-banks="getStripeBanks(false)"
      />
      <v-card
        width="100%"
        class="my-3 py-3 px-2 banks"
        elevation="0"
        @click="screen = 'manual'"
      >
        <v-row no-gutters class="d-flex full-width align-center">
          <v-icon class="mx-2 border-radius wallet-icon">mdi-bank</v-icon>
          <strong class="ml-1">Manually Link Bank</strong>
        </v-row>
        <v-row class="text-left pl-3">
          <v-col sm="12" md="12">
            Enter your bank's routing & account number. 2 amounts will be
            deposited into your account for account verification.
          </v-col>
        </v-row>
      </v-card>
    </div>
    <StripeBankAccount
      v-else-if="screen == 'manual'"
      @reset="getStripeBanks(true)"
    />
    <StripeVerifyBank
      v-else-if="screen == 'verify'"
      :bankId="bankIdSelected"
      :customerId="customerId"
      @reset="getStripeBanks(true)"
    />
    <!-- Deletion confirmation dialog -->
    <v-dialog v-model="dialogDeleteBank" :persistent="deletingBank" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break text-left exit-warning-text">
            Are you sure you want to delete this bank?
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <v-btn
            color="brandCyan"
            @click="dialogDeleteBank = false"
            outlined
            width="130"
            :disabled="deletingBank"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            color="brandCyan"
            depressed
            class="white--text"
            @click="deleteStripeBank"
            width="130"
            :loading="deletingBank"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      v-if="showLoadingDialog"
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import Plaid from "@/components/Plaid";
import StripeBankAccount from "@/components/stripe/StripeBankAccount";
import StripeACHPayment from "@/components/stripe/StripeACHPayment";
import StripeVerifyBank from "@/components/stripe/StripeVerifyBank";
import Robin from "@/components/Robin.vue";
import LoadingDialog from "@/components/loadingDialog.vue";
import moment from "moment";
import { mapState } from "vuex";
import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

export default {
  name: "StripeManageACH",
  props: {
    budget: Object,
    preloadMenu: String,
    fundingLimit: Object,
    canCompleteKYB: Boolean,
    source: String,
    givingMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Robin,
    LoadingDialog,
    Plaid,
    StripeBankAccount,
    StripeVerifyBank,
    StripeACHPayment
  },
  data() {
    return {
      // Loading vars
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,

      // dialogs
      dialogDeleteBank: false,

      // Main data
      screen: "home",
      loading: false,
      error: false,
      loadingStartTime: null,
      name: null,
      linkToken: null,
      env: null,
      clientName: null,
      webhook: null,
      stripe: null,
      banks: [],
      bankIdSelected: null,
      customerId: null,
      bankToDelete: null,
      deletingBank: false,
      displayACHWhitelist: true
    };
  },
  created() {
    console.log("Created stripe bank widget");
    this.createStripeCustomer();
    if (this.preloadMenu && this.preloadMenu.toUpperCase() == "ACH") {
      this.screen = "manage";
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    createStripeCustomer() {
      // When ACH get's created we check if we need to create their Stripe customer
      if (!this.userProfile.FundingAccounts.find(x => x.type == "STRIPE")) {
        this.loadingStartTime = new Date();
        this.loading = true;
        console.log("Creating customer");
        PaymentService.createStripeCustomer(
          this.userProfile.firstName + " " + this.userProfile.lastName,
          this.userProfile.businessEmail,
          this.userProfile.businessPhone,
          this.magicLinkToken
        ).then(resp => {
          console.log("Got customer resp: ", resp);
          // Now add this to the user's funding accounts
          this.userProfile.FundingAccounts.push(resp);
          this.getStripeBanks();
        });
      } else {
        this.loading = true;
        this.loadingStartTime = new Date();
        this.getStripeBanks();
      }
    },
    getStripeBanks(route = true) {
      // When ACH get's created we check if we need to create their Stripe customer
      if (
        this.userProfile.FundingAccounts.filter(x => x.type == "STRIPE")
          .length > 0
      ) {
        console.log("Getting banks");
        // This brings up a spinning circle while fetching in case they're actively looking at their banks.
        // This is especially the case after adding via Plaid since they never leave this screen
        if (this.screen == "manage") this.loading = true;
        PaymentService.getStripeBanks("bank_account", this.magicLinkToken)
          .then(resp => {
            console.log("Got banks resp: ", resp);
            resp.forEach(x => (x.name = x.bank_name + "..." + x.last4));
            this.banks = resp;
            // Used so Robin shows up for at least 1.5 seconds
            var timeDiff = moment().diff(moment(this.loadingStartTime));
            if (timeDiff < 1500) {
              setTimeout(() => {
                this.loading = false;
                if (route) this.autoRouteUser();
              }, 1500 - timeDiff);
            } else {
              this.loading = false;
              if (route) this.autoRouteUser();
            }
          })
          .catch(err => {
            console.log("Error getting Stripe banks ", err);
            this.error = true;
          });
      }
    },
    deleteStripeBank() {
      // When ACH get's created we check if we need to create their Stripe customer
      console.log("Deleting bank");
      this.deletingBank = true;

      // this.showLoadingDialog = true;
      // this.dialogDeleteBank = false;
      // this.loadingHeaderMessage = "Deleting bank account";
      // this.loadingMessageLine1 = "Please wait...";
      var startTime = new Date();
      PaymentService.deleteStripeBankAccount(
        this.bankToDelete,
        this.magicLinkToken
      ).then(resp => {
        console.log("Delete banks resp: ", resp);
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff < 1500) {
          setTimeout(() => {
            this.getStripeBanks(false);
            this.dialogDeleteBank = false;
            this.deletingBank = false;
            this.bankToDelete = null;
          }, 1500 - timeDiff);
        } else {
          this.getStripeBanks(false);
          this.dialogDeleteBank = false;
          this.deletingBank = false;
          this.bankToDelete = null;
        }
      });
    },
    autoRouteUser(route) {
      if (route && typeof route == "string") {
        return (this.screen = route);
      }
      if (this.preloadMenu && this.preloadMenu.toUpperCase() == "ACH") {
        this.screen = "manage";
        // var delay = 0;
        // if (!this.$refs.plaidComponent) delay = 100;
        // setTimeout(() => {
        // }, delay);
      } else if (this.banks.filter(x => x.status == "verified").length == 0) {
        return (this.screen = "manage");
      }
      // Removing for now because once a user verifies their account, we take them immediately to spend that money
      else if (this.screen == "manual") {
        // This way after a user verifies their account, it doesn't route them to the payments
        this.screen = "manage";
      } else {
        this.screen = "home";
      }
    },
    resetForm(screen = null) {
      if (screen && typeof screen == "string") {
        // Screen == reset when we're completely done and so we skip the home screen
        if (screen == "reset") return this.$emit("reset", "reset");
        return (this.screen = screen);
      }
      if (this.screen == "home") {
        this.$emit("reset");
      } else if (this.screen == "verify" || this.screen == "manual") {
        this.screen = "manage";
      } else {
        // We have to check if they have valid banks, therefore take them to payments. OTherwise reset
        if (this.banks.filter(x => x.status == "verified").length == 0) {
          this.$emit("reset");
        } else {
          this.screen = "home";
        }
      }

      // this.$emit("get-cards");
    },
    previousSlide() {
      this.$emit("scrollToTop");
      this.resetForm();
    },
    loadBudgetActivity() {
      this.$emit("load-budget-activity");
    },
    getBudgets() {
      this.$emit("get-budgets");
    },
    getFundingLimit() {
      this.$emit("get-funding-limit");
    }
  },
  computed: {
    ...mapState(["userProfile", "magicLinkToken"]),

    computedTitleBarText() {
      var text = null;
      switch (this.screen) {
        case "home":
          // code block
          text = "ACH Bank Transfer";
          break;
        case "manage":
          // code block
          text = "Manage Accounts";
          break;
        case "manual":
          // code block
          text = "Link Bank";
          break;
        case "verify":
          // code block
          text = "Verify Account";
          break;
        default:
          // code block
          text = "ACH Bank Transfer";
      }
      return text;
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}

.banks {
  border: 1px lightgrey solid;
}
</style>

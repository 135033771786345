<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start align-center" width="100%">
        <v-icon
          large
          dark
          class="pa-2 ml-3 my-2 brand-green-background border-radius"
          >mdi-view-grid-plus</v-icon
        >
        <!-- <v-card-title class="font-weight-bold"> Learning Library </v-card-title> -->
        <v-card-title> Learning Library </v-card-title>
      </div>
      <v-divider class="mx-4" />

      <v-row class="my-2 ml-3 mr-2" justify="space-around">
        <v-col sm="12" md="3">
          <v-autocomplete
            outlined
            dense
            class="mt-4 pa-1 mb-6 client-select"
            hide-details
            :items="clientArr"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            v-model="clientId"
            @change="changeClients"
            v-if="userProfile.clientId === 1 && clients.length > 0"
            :disabled="loadingModules === true"
          >
          </v-autocomplete>
          <div
            class="d-flex align-self-end justify-space-between align-center mt-2"
            v-if="loadingTags || availableTags.length > 0"
          >
            <p class="font-weight-black grey--text text-start mb-0">
              Module Keywords
            </p>
            <v-tooltip right v-if="selectedTags.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-auto mr-3"
                  @click="selectedTags = []"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </template>
              <span>Clear selected keywords</span>
            </v-tooltip>
            <!-- collapse button for chip group div -->
            <!-- <div class="align-self-start">
              <v-btn icon @click="toggleChipPanel" v-if="panelOpen === true">
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>
              <v-btn icon @click="toggleChipPanel" v-else-if="panelOpen === false">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </div> -->
          </div>
          <!-- tag div -->
          <div>
            <div v-if="loadingTags === true">
              <v-chip-group column>
                <v-skeleton-loader type="chip" class="ma-2"></v-skeleton-loader>
                <v-skeleton-loader type="chip" class="ma-2"></v-skeleton-loader>
                <v-skeleton-loader type="chip" class="ma-2"></v-skeleton-loader>
                <v-skeleton-loader type="chip" class="ma-2"></v-skeleton-loader>
                <v-skeleton-loader type="chip" class="ma-2"></v-skeleton-loader>
              </v-chip-group>
            </div>
            <!-- expandable panel for tag chips -->
            <div
              class="chip-panel d-flex flex-column"
              v-else-if="availableTags.length > 0"
            >
              <!-- <div class="align-self-end">
                <v-btn icon @click="toggleChipPanel" v-if="panelOpen === true">
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn icon @click="toggleChipPanel" v-else-if="panelOpen === false">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </div> -->
              <div :class="showMore ? 'expanded-tag-div tag-div' : 'tag-div'">
                <div class="chip-group">
                  <v-chip-group column>
                    <div ref="chipGroup">
                      <v-chip
                        v-for="tag in availableTags"
                        :key="tag.tagDefinitionId"
                        :color="isTagSelected(tag) ? 'primary' : ''"
                        :text-color="isTagSelected(tag) ? 'white' : ''"
                        @click="selectTag(tag)"
                        ><span class="text-truncate">{{
                          tag.TagDefinition.name
                        }}</span></v-chip
                      >
                    </div>
                  </v-chip-group>
                </div>
              </div>
              <v-btn
                v-if="showMore == false && displayShowMore == true"
                color="primary"
                @click="showMore = true"
                class="ml-auto"
                text
              >
                Show more
                <v-icon color="mediumGrey">mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn
                v-else-if="displayShowMore == true && showMore == true"
                color="primary"
                @click="showMore = false"
                class="ml-auto"
                text
              >
                Show less
                <v-icon color="mediumGrey">mdi-chevron-up</v-icon>
              </v-btn>
            </div>
          </div>
          <v-divider
            class="mb-5"
            v-if="loadingTags || availableTags.length > 0"
          ></v-divider>
          <p
            v-if="selectedTags.length > 0"
            class="font-weight-black text-start"
          >
            <span v-if="computedVisibleLearning.length > 1"
              >{{ computedVisibleLearning.length }} modules meet these
              criteria</span
            >
            <span v-else-if="computedVisibleLearning.length == 1"
              >{{ computedVisibleLearning.length }} module meets these
              criteria</span
            >
          </p>
          <v-divider v-if="selectedTags.length > 0" class="mb-5"></v-divider>
          <div
            class="my-4 align-text-right d-flex flex-column align-end justify-center"
          >
            <span class="mr-3 mb-1"><b>Popular links</b></span>
            <v-btn text small color="primary" @click="loadBadges"
              >View my badges</v-btn
            >
            <v-btn
              text
              small
              color="primary"
              @click="openWallet"
              v-if="
                userProfile &&
                  userProfile.Client &&
                  userProfile.Client.displayWalletPage
              "
              >Go to wallet</v-btn
            >
          </div>
        </v-col>

        <!------------ Modules View ------------->
        <v-col sm="12" md="9" lg="8">
          <div class="d-flex justify-space-between align-center mr-2 mb-3">
            <!-- <v-btn text color="primary" class="mr-2" v-bind="attrs" v-on="on"
              >Filter</v-btn
            > -->
            <div class="d-flex">
              <v-menu
                v-model="filters.showMenu"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex">
                    <v-btn
                      text
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      >Filter</v-btn
                    >
                    <!-- <v-btn
                    text
                    color="primary"
                    class="mr-2"
                    @click="sortAsc = !sortAsc"
                    ><v-icon v-if="sortAsc" class="mr-2">mdi-arrow-up</v-icon
                    ><v-icon v-else class="mr-2">mdi-arrow-down</v-icon
                    >Sort</v-btn
                  > -->
                  </div>
                </template>
                <v-list>
                  <v-list-item>
                    <!-- <template v-slot:default="{ active }"> -->
                    <v-list-item-action>
                      <v-checkbox
                        v-model="filters.incomplete"
                        @click="toggleFilter"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>Incomplete Courses</v-list-item-title>
                    </v-list-item-content>
                    <!-- </template> -->
                  </v-list-item>

                  <v-list-item>
                    <!-- <template v-slot:default="{ active }"> -->
                    <v-list-item-action>
                      <v-checkbox
                        v-model="filters.complete"
                        @click="toggleFilter"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-icon class="mr-3">
                      <v-icon>mdi-trophy-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>Completed Courses</v-list-item-title>
                    </v-list-item-content>
                    <!-- </template> -->
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu
                v-model="sortMenu"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex">
                    <v-btn
                      text
                      color="primary"
                      class="mr-2"
                      v-bind="attrs"
                      v-on="on"
                      >Sort</v-btn
                    >
                    <!-- <v-btn
                    text
                    color="primary"
                    class="mr-2"
                    @click="sortAsc = !sortAsc"
                    ><v-icon v-if="sortAsc" class="mr-2">mdi-arrow-up</v-icon
                    ><v-icon v-else class="mr-2">mdi-arrow-down</v-icon
                    >Sort</v-btn
                  > -->
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="option in sortOptions"
                    :key="option.key"
                    @click="toggleSort(option.key)"
                    :class="{
                      'light-grey-background': sortBy.key == option.key,
                    }"
                  >
                    <v-list-item-content class="align-text-left">
                      <v-list-item-title>{{ option.label }}</v-list-item-title>
                    </v-list-item-content>
                    <!-- </template> -->
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <!------------ Search Bar -------------->
            <div class="d-flex">
              <v-text-field
                outlined
                placeholder="Search"
                single-line
                dense
                hide-details
                v-model="search"
                @keyup="searchModules"
                @input="searchModules"
                class="shrink mr-2"
              ></v-text-field>
              <v-btn icon @click="searchModules(null)"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </div>
            <!------------ End Search Bar -------------->
          </div>
          <v-divider />

          <!----------------- Learning Modules ------------------->
          <!-- <v-progress-circular
            :rotate="270"
            :size="120"
            :width="8"
            indeterminate
            color="primary"
            v-if="loadingModules"
            class="mt-8"
            ><span class="black--text"> Loading Modules...</span>
          </v-progress-circular>
          <v-container v-else-if="!computedVisibleLearning.length">
            <h3>You have no learning courses yet. Check back soon!</h3>
          </v-container> -->
          <v-container
            class="d-flex full-width flex-row justify-space-around flex-wrap"
            v-if="loadingModules"
          >
            <div class="d-flex flex-column module-skeleton">
              <v-skeleton-loader type="image, article"></v-skeleton-loader>
            </div>
            <div class="d-flex flex-column module-skeleton">
              <v-skeleton-loader type="image, article"></v-skeleton-loader>
            </div>
            <div class="d-flex flex-column module-skeleton">
              <v-skeleton-loader type="image, article"></v-skeleton-loader>
            </div>
            <div class="d-flex flex-column module-skeleton">
              <v-skeleton-loader type="image, article"></v-skeleton-loader>
            </div>
            <div class="d-flex flex-column module-skeleton">
              <v-skeleton-loader type="image, article"></v-skeleton-loader>
            </div>
            <div class="d-flex flex-column module-skeleton">
              <v-skeleton-loader type="image, article"></v-skeleton-loader>
            </div>
          </v-container>
          <v-container
            v-else-if="
              computedVisibleLearning.length === 0 &&
                !filters.complete &&
                !filters.incomplete
            "
          >
            <b class="word-wrap">Looks like no filters are selected</b>
            <lottie
              :options="lottieOptions"
              :height="150"
              :width="150"
              v-on:animCreated="handleAnimation"
              class="mt-5"
            />
          </v-container>
          <v-container
            v-else-if="computedVisibleLearning.length === 0 && search"
          >
            <b class="word-wrap">Looks like no results were found</b>
            <lottie
              :options="lottieOptions"
              :height="150"
              :width="150"
              v-on:animCreated="handleAnimation"
              class="mt-5"
            />
          </v-container>
          <v-container v-else-if="computedVisibleLearning.length === 0">
            <b class="word-wrap"
              >Looks like you are just getting started so there are no learning
              courses listed yet</b
            >
            <v-img
              src="@/assets/Placeholder_Screens/Learning.png"
              width="40%"
              min-width="350"
              class="mx-auto"
            />
          </v-container>

          <v-container
            class="d-flex flex-row flex-wrap align-content-space-between justify-flex-start card-container"
            v-else
          >
            <!----
            @click="loadCourse(learning)"
            ---->
            <!-- <v-col cols="6"> -->
            <!-- Div used as a v-for and arranged in columns because it has a v-divider beneath each program -->
            <v-card
              v-for="learning in computedVisibleLearning"
              :key="learning.learningModuleId"
              class="d-flex flex-column my-3 mx-2 cursor-pointer module-card"
              rounded="0"
              elevation="4"
              @click="loadCourse(learning)"
            >
              <!-- Div arranged as column -->
              <div
                class="d-flex flex-column my-3 justify-start align-center full-height"
              >
                <div class="img-div">
                  <v-img
                    v-if="learning.imageUrlSigned != null"
                    :src="learning.imageUrlSigned"
                    :aspect-ratio="16 / 9"
                    class="img mx-auto"
                  />
                  <v-img
                    v-else
                    src="@/assets/placeholder_image.png"
                    :aspect-ratio="16 / 9"
                    class="img mx-auto"
                  />
                </div>
                <!-- Div used to arrange title and description as column -->
                <div
                  class="flex-column align-text-center mt-3 mx-auto justify-space-around d-flex flex-grow-1 card-details-div"
                >
                  <div>
                    <h3 class="word-break font-weight-black mb-2">
                      {{ learning.displayName }}
                    </h3>
                    <div
                      class="align-text-left mx-3 text-caption"
                      v-html="trimDescription(learning.description)"
                    ></div>
                  </div>
                  <!-- <div class="d-flex flex-column">
                    <span>
                      {{ learning.completedChapterCount }} of
                      {{ learning.chapterCount }}
                      <span v-if="learning.chapterCount == 1">chapter</span
                      ><span v-else>chapters</span> complete <br />~
                      {{
                        (learning.chapterCount -
                          learning.completedChapterCount) *
                          5
                      }}
                      minutes remaining</span
                    >
                    
                    <span class="mediumGrey--text"
                      >{{ learning.formattedDate }}
                    </span>
                  </div> -->
                  <div
                    class="mt-2 d-flex flex-row space-evenly justify-start flex-wrap"
                    max-width="80%"
                  >
                    <!-- Module tag chips -->
                    <v-chip-group
                      column
                      v-if="learning.LearningModuleTags.length > 0"
                    >
                      <v-chip
                        :class="isTagSelected(tag) ? 'font-weight-black' : ''"
                        v-for="tag in learning.LearningModuleTags"
                        :key="tag.learningModuleTagId"
                        :color="isTagSelected(tag) ? 'primary' : ''"
                        :text-color="isTagSelected(tag) ? 'white' : ''"
                        v-on:click.stop="selectTag(tag)"
                      >
                        <span class="text-truncate">{{
                          tag.TagDefinition.name
                        }}</span>
                      </v-chip>
                    </v-chip-group>
                  </div>
                </div>
                <!-- Div to display progress bar or badge if applicable -->
                <div
                  v-if="
                    (learning.Badge != null && learning.Badge.imageUrl) ||
                      learning.incentiveAward !== null ||
                      (Number(learning.percentComplete) >= 0 &&
                        Number(learning.percentComplete) <= 100)
                  "
                  class="progress-bar-div"
                >
                  <v-divider class="mx-auto mb-5"></v-divider>
                  <div class="mb-1">
                    <ProgressBar
                      class="progress-bar mb-2"
                      :progress="String(learning.percentComplete)"
                      v-if="
                        Number(learning.percentComplete) > 0 &&
                          Number(learning.percentComplete) < 100
                      "
                    />
                    <!-- if the percent complete is 0 or 100 && incentiveAward -->
                    <div v-else-if="learning.incentiveAward !== null">
                      <p
                        class="ma-1 font-weight-bold primary--text"
                        v-if="Number(learning.percentComplete) <= 0"
                      >
                        Start module to earn
                        <span>{{
                          learning.incentiveAward.awardDisplayName
                        }}</span>
                      </p>
                      <p
                        class="ma-1 font-weight-bold primary--text completed-text"
                        v-else-if="Number(learning.percentComplete) >= 100"
                      >
                        You earned
                        <span>{{
                          learning.incentiveAward.awardDisplayName
                        }}</span>
                      </p>
                    </div>
                    <!-- if the percent complete is 0 or 100 && badge -->
                    <div
                      v-else-if="
                        learning.Badge !== null && learning.Badge.imageUrl
                      "
                    >
                      <div class="d-flex align-center">
                        <v-img
                          :src="learning.Badge.imageUrl"
                          :aspect-ratio="1 / 1"
                          width="60"
                          min-width="60"
                          max-width="60"
                          class="mx-1"
                        />
                        <v-icon
                          dark
                          color="brandGreen"
                          class="complete-check"
                          v-if="Number(learning.percentComplete) >= 100"
                          >mdi-check-circle</v-icon
                        >

                        <p
                          class="ma-1 font-weight-bold primary--text"
                          v-if="Number(learning.percentComplete) <= 0"
                        >
                          Start module to earn the
                          <span>{{ learning.Badge.displayName }}</span> badge!
                        </p>
                        <p
                          class="ma-1 font-weight-bold primary--text completed-text"
                          v-else-if="Number(learning.percentComplete) >= 100"
                        >
                          You earned the
                          <span>{{ learning.Badge.displayName }}</span> badge!
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <p
                        class="ma-1 font-weight-bold primary--text"
                        v-if="Number(learning.percentComplete) <= 0"
                      >
                        Start module
                      </p>
                      <p
                        class="ma-1 font-weight-bold primary--text completed-text"
                        v-else-if="Number(learning.percentComplete) >= 100"
                      >
                        Complete
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
            <!-- </v-col> -->
          </v-container>

          <v-container>
            <!-- <div id="example">
              <button v-on:click="getModulesById()">FF</button><br>
              <button v-on:click="getModules">PRINT</button>
            </div> -->
          </v-container>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import Robin from "@/components/Robin.vue";
import ProgressBar from "@/components/ProgressBar.vue";
// import LearningCardProgress from "@/components/LearningCardProgress.vue";
// import fz from "fuzzaldrin-plus";

import { mapState } from "vuex";
import LearningService from "../services/LearningService";

import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Curious.json";
// import moment from "moment";
// import fz from "fuzzaldrin-plus";

export default {
  name: "Learning",
  title: "Whistle | Learning",
  components: {
    // Robin,
    // LearningCardProgress,
    ProgressBar,
    Lottie,
  },
  data() {
    return {
      page: 0,
      size: 5,
      search: null,
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      sortOptions: [
        { key: "NAME_A_Z", label: "Name: A to Z" },
        { key: "NAME_Z_A", label: "Name: Z to A" },
        {
          key: "PROGRESS_LOW_HIGH",
          label: "Progress: Low to High",
        },
        {
          key: "PROGRESS_HIGH_LOW",
          label: "Progress: High to Low",
        },
      ],
      sortBy: { key: "NAME_A_Z", label: "Name A to Z" },
      sortMenu: false,
      loadingModules: false,
      filters: {
        showMenu: false,
        complete: true,
        incomplete: true,
      },
      modules: [],
      cancelRequestToggle: 0,
      selectedTags: [],
      loadingTags: true,
      availableTags: [],
      sorting: false,
      panelOpen: false,
      clientArr: null,
      clientId: null,
      showMore: false,
      displayShowMore: false,
    };
  },
  created() {
    if (this.$auth) {
      this.clientId = this.userProfile.clientId;
      this.getModules(
        this.page,
        this.size,
        this.search,
        this.computedFilterParameter,
        this.sortBy.key,
        this.cancelRequestToggle + 1
      );
    }

    this.unwatch = this.$store.watch(
      (state) => {
        return state.loadItemFromSearch; // could also put a Getter here
      },
      (learningModuleId) => {
        console.log(`User clicked item in search bar`);
        console.log(learningModuleId);
        if (learningModuleId !== null) {
          this.loadProgramById(learningModuleId);
          //We wipe the ID storede in the store after we load it up
          this.$store.dispatch("setLoadItemFromSearch", null);
        }
      },
      //Optional Deep if you need it
      {
        deep: true,
      }
    );
    // adds an ALL -1 client to the array of clients
    this.formatClientArr();
    // this.getAvailableTags();
  },
  methods: {
    // Everything is pretty much self explanatory except toggleKey
    // ToggleKey is an integer meant to be used to cancel any recursive calls that had been executed but then the user entered new input that began a new series of calls
    // Everytime the user calls getModules, they increment toggleKey so we know if we're on the right run or now. If we're on an old run, we stop
    async getModules(page, size, search, filter, sort, toggleKey) {
      console.log("Calling get modules page " + page, toggleKey);
      console.log("client id", this.computedClientIdQuery);
      var array = [];
      // reset any selected tag since the array is populated by the modules
      // this.selectedTags = [];
      // this.loadingModules = true;
      this.loadingTags = true;
      // We only set this to true if it's our first time calling
      if (page == 0) {
        // if page === 0, toggle key == 1
        this.loadingModules = true;
        // We're starting a fresh request so we reset the toggle
        this.cancelRequestToggle = toggleKey;
      }

      if (this.cancelRequestToggle != toggleKey) {
        console.log("CANCELING FIRST REQUEST");
      } else {
        console.log("Still in the same request so we continue");
        var response = await LearningService.getAllModules(
          page,
          size,
          search,
          filter,
          sort,
          this.computedClientIdQuery
        );
        console.log(response);
        var modules = response.modules;
        var pageInfo = response.page_info;
        // console.log("MODULES--------------------");
        // console.log(modules);
        // console.log("page info");
        // console.log(pageInfo);
        if (modules && modules.length > 0) {
          // console.log(modules[0].learningModuleId);
          this.loadingModules = false;
          // this.getAvailableTags();
          modules
            .filter((x) => x.displayInLibrary != false)
            .forEach((module) => {
              // console.log(module);
              if (
                module.percentComplete == undefined ||
                module.percentComplete == null
              ) {
                module.percentComplete = 0;
              } else {
                module.percentComplete *= 100;
              }
              // if (module.percentComplete !== 1) {

              // var percent = Math.random().toFixed(2);
              // var goal = (Math.random() * 50).toFixed(0);
              // module.progressPercent = parseInt(percent * 100);
              // module.goal = parseInt(goal);
              // module.progressValue = parseInt((percent * module.goal).toFixed(0));

              // if (module.name == "Cintas Chemical Cleaning") {
              //   module.goal = 3;
              //   module.progressValue = 1;
              //   module.progressPercent = 33;
              // }
              // if (module.percentComplete >= 100) {
              array.push(module);
              // } else {
              //   incompleteLearning.push(module);
              // }
            });
          // this.getAvailableTags();
        } else {
          this.loadingModules = false;
          // this.getAvailableTags();
        }
      }
      // We have to check if the request was canceled while we were sending the request
      if (this.cancelRequestToggle == toggleKey) {
        if (page == 0) {
          this.modules = array;
        } else {
          this.modules = this.modules.concat(array);
        }
      } else {
        console.log("CANCELING FIRST REQUEST");
      }

      // Now we call the function again with a new page if we heard anything back
      // We also check if we received less than the size indicated. That means we're at the end
      if (
        pageInfo &&
        pageInfo.next_page &&
        this.cancelRequestToggle == toggleKey
      ) {
        var newPage = page + 1;
        console.log(newPage);
        this.getModules(newPage, size, search, filter, sort, toggleKey);
      } else if (pageInfo && pageInfo.next_page === false) {
        // this is the last page, we can get available tags now
        console.log("getting module tags");
        this.getAvailableTags();
        // this.loadingTags = false;
      }
      // this.incompleteLearning = incompleteLearning;
    },
    loadCourse(learning) {
      console.log("Loading Learning Course");
      this.$router.push({
        name: "learningdetails",
        params: {
          learningId: learning.learningModuleId.toString(),
        },
      });
    },
    printCourseId(learning) {
      console.log(learning.learningModuleId);
    },
    loadBadgePage() {
      this.$router.push({
        name: "profile",
        params: { initialPageIndexToLoad: 2 },
      });
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    searchModules(event) {
      // Event is either a key press event OR null if they clicked the button manually OR if they empty the search field
      // Function called with input and keyup. Keyup detects when enter is pressed.
      // Input detects everything else and doesn't pass in an event, so we only search if they hit enter or there is no event
      // This is so we're not triggering this function and searching twice for every key press
      if (
        (event && event.key == "Enter") ||
        (!event &&
          (this.search ||
            !this.search ||
            (this.search != null && this.search.length == 0)))
      ) {
        this.getModules(
          this.page,
          this.size,
          this.search,
          this.computedFilterParameter,
          this.sortBy.key,
          this.cancelRequestToggle + 1
        );
      }
    },
    toggleFilter() {
      // Called when a user changed a filter
      if (this.computedFilterParameter) {
        // If neither filters are checked, we save an api call and just display nothing
        this.getModules(
          this.page,
          this.size,
          this.search,
          this.computedFilterParameter,
          this.sortBy.key,
          this.cancelRequestToggle + 1
        );
      }
    },
    toggleSort(key) {
      this.sortBy.key = key;
      // this flag is used to switch off the sorting by number of matching modules
      this.sorting = true;
      // We only call if they selected an A to Z filter. Otherwise the progress sorting is done in the computed property
      if (key != "PROGRESS_LOW_HIGH" && key != "PROGRESS_HIGH_LOW") {
        // Called when a user changed a filter
        this.getModules(
          this.page,
          this.size,
          this.search,
          this.computedFilterParameter,
          this.sortBy.key,
          this.cancelRequestToggle + 1
        );
      }
    },
    openWallet() {
      this.$store.dispatch("setDisplayWalletMenu", true);
    },
    loadBadges() {
      this.$router.push({
        name: "profile",
        params: { initialPageIndexToLoad: 2 },
      });
    },
    selectTag(tag) {
      let tagIndex = this.selectedTags.findIndex((items) => {
        // the tag being clicked on to compare
        let tagAName = tag.TagDefinition.name.toLowerCase().trim();
        // the tag from the selected tags array we are comparing with
        let tagBName = items.TagDefinition.name.toLowerCase().trim();
        return tagAName === tagBName;
      });
      if (tagIndex === -1) {
        this.selectedTags.push(tag);
      } else {
        this.selectedTags.splice(tagIndex, 1);
      }
      // console.log(this.selectedTags);
    },
    isTagSelected(tag) {
      return this.selectedTags.some((tagsInArr) => {
        // tags from selectedTags array we're comparing with
        let tagAName = tagsInArr.TagDefinition.name.toLowerCase().trim();
        // tag we are wanting to know if tag is selected
        let tagBName = tag.TagDefinition.name.toLowerCase().trim();
        return tagAName === tagBName;
      });
    },
    // loops through available modules and pushes unique tags to an array
    getAvailableTags() {
      let tags = [...this.selectedTags];
      // this.loadingTags = true;
      // console.log('LOADING TAGS');
      if (this.computedVisibleLearning.length === 0) {
        this.loadingTags = false;
        this.availableTags = [];
        return;
        // this.availableTags = tags;
      } else {
        for (let i = 0; i < this.computedVisibleLearning.length; i++) {
          // if it has a tags array
          let currentLearning = this.computedVisibleLearning[i];
          if (
            currentLearning.LearningModuleTags &&
            currentLearning.LearningModuleTags.length > 0
          ) {
            // loop through moudule's tags
            for (
              let j = 0;
              j < currentLearning.LearningModuleTags.length;
              j++
            ) {
              let currentTag = currentLearning.LearningModuleTags[j];
              // to lowercase, trim
              // compare
              // and the tag is not in the tags array already push
              // if it's in the array, true, else false
              if (
                !tags.some((tag) => {
                  let tagAName = tag.TagDefinition.name.toLowerCase().trim();
                  let tagBName = currentTag.TagDefinition.name
                    .toLowerCase()
                    .trim();
                  return tagAName === tagBName;
                })
              ) {
                // if it's false, push it
                tags.push(
                  this.computedVisibleLearning[i].LearningModuleTags[j]
                );
                // console.log(tags);
              }
            }
          }
        }
        // this.loadingTags = false;
        // this.availableTags = tags;
      }
      // console.log('DONE LOADING TAGS');
      this.availableTags = tags;
      this.loadingTags = false;
      if (this.availableTags.length > 0) {
        // Waits for tag cloud to render then checks size, if more than 325 high returns true
        this.$nextTick(() => {
          this.displayShowMore = this.computeShowMore();
        });
      }
      return;
    },
    trimDescription(description) {
      // console.log(typeof description);
      if (description.length < 150) {
        return description;
      } else {
        let start = description.indexOf("<p>");
        start += 3;
        let end = description.indexOf("</p>");
        // console.log('slicing string', start, end);
        if (end > 150) {
          end = 150;
        }
        // console.log(description.slice(start, end));
        return `<p>${description.slice(start, end)}...</p>`;
      }
    },
    countModuleTags() {
      // loop through learning modules
      for (let i = 0; i < this.modules.length; i++) {
        let count = 0;
        // loop through each learning tag
        for (let j = 0; j < this.modules[i].LearningModuleTags.length; j++) {
          if (this.isTagSelected(this.modules[i].LearningModuleTags[j])) {
            count++;
          }
        }
        this.modules[i].numActiveTags = count;
      }
    },
    toggleChipPanel() {
      console.log("toggleChipPanel!!!!");
      this.panelOpen = !this.panelOpen;
    },
    formatClientArr() {
      if (this.userProfile.clientId === 1) {
        let allClients = {
          clientId: -1,
          clientName: "All Clients",
          formattedName: "All Clients",
        };
        this.clientArr = [allClients, ...this.clients];
        console.log(this.clientArr);
      }
    },
    // client select handler
    changeClients() {
      console.log("change clients", this.cancelRequestToggle + 1);
      this.getModules(
        this.page,
        this.size,
        this.search,
        this.computedFilterParameter,
        this.sortBy.key,
        this.cancelRequestToggle + 1
      );
    },
    computeShowMore() {
      var elem = this.$refs.chipGroup;
      console.log("elem");
      console.log(elem);
      if (elem) {
        console.log(elem.offsetHeight, elem.scrollHeight);
        return Number(elem.offsetHeight) > 325;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    computedRobinMessage() {
      var learning = this.modules.filter((x) => x.percentComplete < 100);
      if (learning.length > 0) {
        learning.sort((a, b) => b.percentComplete - a.percentComplete);
        var percentRemaining = (100 - learning[0].percentComplete).toFixed(0);
        if (percentRemaining == 100) {
          return (
            "You haven't started the " +
            learning[0].displayName +
            " module yet!"
          );
        } else if (percentRemaining > 50) {
          return (
            "You are " +
            (100 - percentRemaining) +
            "% through the " +
            learning[0].displayName +
            " module."
          );
        } else if (percentRemaining < 15) {
          return (
            "You are FLYING through the " +
            learning[0].displayName +
            " module. Only " +
            percentRemaining +
            "% to go!"
          );
        } else {
          return (
            "You are only " +
            percentRemaining +
            "% away from finishing the " +
            learning[0].displayName +
            " module!"
          );
        }
      } else if (learning.length > 0) {
        return "You should retake a learning module to refresh your memory!";
      }

      return "Looks like you don't have any new modules to complete";
    },
    computedVisibleLearning() {
      console.log("Running");
      var learning = this.modules;
      // if (this.filters.incomplete) {
      // learning = learning.concat(this.incompleteLearning);
      // }
      // if we aren't sorting high to low, we made an api call and can filter our modules
      // if there are selected tags
      // if ((!this.sortBy.key == "PROGRESS_LOW_HIGH" || !this.sortBy.key == "PROGRESS_HIGH_LOW") && this.selectedTags.length > 0) {
      //   learning = learning.filter((module) => this.selectedTags.some((selectedTag) => module.LearningModuleTags.some((moduleTag) => moduleTag.tagDefinitionId === selectedTag.tagDefinitionId)));
      // } else
      if (this.sortBy.key == "PROGRESS_LOW_HIGH") {
        // if we had selected tags, use those to filter
        if (this.selectedTags.length > 0) {
          learning = learning.filter((module) =>
            this.selectedTags.some((selectedTag) =>
              module.LearningModuleTags.some(
                (moduleTag) =>
                  moduleTag.TagDefinition.name.toLowerCase().trim() ===
                  selectedTag.TagDefinition.name.toLowerCase().trim()
              )
            )
          );
        }
        learning.sort((a, b) => a.percentComplete - b.percentComplete);
      } else if (this.sortBy.key == "PROGRESS_HIGH_LOW") {
        if (this.selectedTags.length > 0) {
          learning = learning.filter((module) =>
            this.selectedTags.some((selectedTag) =>
              module.LearningModuleTags.some(
                (moduleTag) =>
                  // moduleTag.tagDefinitionId === selectedTag.tagDefinitionId
                  moduleTag.TagDefinition.name.toLowerCase().trim() ===
                  selectedTag.TagDefinition.name.toLowerCase().trim()
              )
            )
          );
        }
        learning.sort((a, b) => b.percentComplete - a.percentComplete);
      } else if (this.selectedTags.length > 0) {
        if (this.sorting === false) {
          learning = learning.filter((module) =>
            this.selectedTags.some((selectedTag) =>
              module.LearningModuleTags.some(
                (moduleTag) =>
                  // moduleTag.tagDefinitionId === selectedTag.tagDefinitionId
                  moduleTag.TagDefinition.name.toLowerCase().trim() ===
                  selectedTag.TagDefinition.name.toLowerCase().trim()
              )
            )
          );
          this.countModuleTags();
          learning.sort((a, b) => {
            return a.numActiveTags < b.numActiveTags // does A have less tags than B??
              ? 1 // yes: 1
              : a.numActiveTags === b.numActiveTags // no: does A have the same tags as B??
              ? a.displayName > b.displayName // yes: does a come before b alphabetically??
                ? 1 // yes: 1
                : -1 // no: -1
              : -1; // no, A has more tags than B, -1
          });
        } else {
          learning = learning.filter((module) =>
            this.selectedTags.some((selectedTag) =>
              module.LearningModuleTags.some(
                (moduleTag) =>
                  // moduleTag.tagDefinitionId === selectedTag.tagDefinitionId
                  moduleTag.TagDefinition.name.toLowerCase().trim() ===
                  selectedTag.TagDefinition.name.toLowerCase().trim()
              )
            )
          );
        }
      }

      // Don't show anything if there are no filters checked
      if (!this.computedFilterParameter) {
        learning = [];
      }
      //If there is no search, return learning here
      // if (
      //   this.search === null ||
      //   this.search == "" ||
      //   this.search === undefined ||
      //   this.search === "null" ||
      //   this.search.replace(/\s+/g, "").length === 0
      // ) {
      //   if (this.sortBy != null) {
      //     if (this.sortBy.id == 3) {
      //       learning.sort((a, b) => a.percentComplete - b.percentComplete);
      //     } else if (this.sortBy.id == 4) {
      //       learning.sort((a, b) => b.percentComplete - a.percentComplete);
      //     } else if (this.sortBy.id == 1) {
      //       learning.sort((a, b) => a.displayName.localeCompare(b.displayName));
      //     } else if (this.sortBy.id == 2) {
      //       learning.sort((a, b) => b.displayName.localeCompare(a.displayName));
      //     }
      //   } else {
      //     learning.sort((a, b) => b.displayName - a.displayName);
      //   }

      //   return learning;
      // }

      // const preparedQuery = fz.prepareQuery(this.search);
      // const scores = {};

      // learning = learning
      //   .map((item) => {
      //     //learning page
      //     var scorableFields = [
      //       item.learningModuleId,
      //       item.externalId,
      //       item.name,
      //       item.displayName,
      //       item.description,
      //       item.startDate,
      //       item.formattedDate,
      //       item.percentComplete,
      //       item.progressValue,
      //     ];
      //     scorableFields = scorableFields.map((toScore) =>
      //       fz.score(toScore, this.search, { preparedQuery })
      //     );

      //     scores[item.learningModuleId] = Math.max(...scorableFields);
      //     return item;
      //   })
      //   .filter((item) => scores[item.learningModuleId] > 1)
      //   .sort(
      //     (a, b) => scores[b.learningModuleId] - scores[a.learningModuleId]
      //   );
      return learning;
    },
    computedFilterParameter() {
      // Used to automatically calculate param used to fetch modules
      if (!this.filters.complete && !this.filters.incomplete) {
        return null;
      } else {
        var string = "";
        if (this.filters.complete) {
          string += "complete";
        }
        if (this.filters.incomplete) {
          // Adding a comma if we already added to the string to separate the values
          if (this.filters.complete) {
            string += ",";
          }
          string += "incomplete";
        }
        return string;
      }
    },
    computedClientIdQuery() {
      // if clientId == -1 : ALL return null
      if (this.clientId === -1) {
        // selected the ALL option, return all modules
        return null;
        // else if whistle and clientId == null return 1 so we only get whistle modules
      } else if (this.userProfile.clientId === 1 && this.clientId === null) {
        // not selected a client, return whistle modules only
        return 1;
        // else if whislte and clientId !== null return clientId
      } else if (this.userProfile.clientId === 1 && this.clientId !== null) {
        // Whistle user has selected a client to filter
        return this.clientId;
      } else {
        // else if not whistle return userProfile.clientId
        return this.userProfile.clientId;
      }
    },
  },
};
</script>

<style scoped>
.img {
  border-radius: 5px;
  border: 1px solid;
  border-color: var(--v-lightGrey-base);
  width: 100%;
}

.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.border-radius {
  border-radius: 5px;
}

/* .progress-card {
  width: 300px;
} */

/* .skeloton-progress-circle {
  transform: scale(2.4);
} */
/* 
.card-container {
  column-gap: 1vw; 
} */

.module-card {
  width: 30%;
  height: auto;
  max-height: 650px;
}

.img-div {
  /* min-height: 180px; */
  width: 95%;
}

.progress-bar-div {
  width: 95%;
  margin: 0px auto;
}

.progress-bar {
  width: 70%;
  margin: 0px auto;
}

.progress-bar >>> .v-progress-linear {
  transition: none;
}

.card-details-div {
  width: 95%;
}

.html-content >>> p {
  margin-bottom: 0;
}

.module-skeleton {
  width: 30%;
}

.v-application .completed-text {
  color: var(--v-brandGreen-base) !important;
}

.complete-check {
  position: absolute;
  left: 58px;
  bottom: 20px;
  background: #fff;
  border-radius: 30px;
}

.client-select {
  max-width: 275px;
}

.tag-div {
  max-height: 325px;
  overflow: hidden;
  /* transition: all 300ms linear; */
}

.tag-div.expanded-tag-div {
  max-height: 1000px;
  transition: all 500ms linear;
  overflow: scroll;
}

/* md screen breakpoint, cards have 50% width */
@media only screen and (max-width: 1000px) {
  .module-card {
    width: 46%;
  }
}

/* phone breakpoint */
@media only screen and (max-width: 700px) {
  /* .linear-progress-bar-div {
    width: 60%;
    margin: 0 auto;
  } */
  /* .linear-progress-bar {
    border-radius: 10px;
  } */
  /* .xs-screen-learning-title {
    align-self: start;
  } */

  .module-card {
    width: 99%;
  }

  .module-skeleton {
    width: 95%;
  }

  .tag-div {
    max-height: 125px;
    overflow: hidden;
    /* transition: all 300ms linear; */
  }
}
</style>

<template>
  <v-card elevation="0" rounded="0">
    <v-toolbar dark flat color="brandCyan" rounded="0" width="100%">
      <div>
        <v-toolbar-title
          >Add a {{ moduleLanguage.toLowerCase() }}</v-toolbar-title
        >
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close">
          <span class="mt-1">Close</span>
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row no-gutters justify="center" full-width no-wrap class="mb-2">
      <v-col cols="10" class="d-flex flex-column text-left mx-0 my-0">
        <p class="mb-0 mt-10 mediumGrey--text">Module Type</p>
        <div class="d-flex justify-space-between align-center">
          <div class="tab-group-container text-center mt-2">
            <v-btn
              depressed
              @click="changeTab(0)"
              :color="activeTab == 0 ? 'white' : 'lightGrey'"
              class="px-5 mr-1 tab-button"
              :class="{
                'active-tab-button': activeTab == 0
              }"
              label
            >
              Learning
            </v-btn>
            <v-btn
              depressed
              @click="changeTab(1)"
              :color="activeTab == 1 ? 'white' : 'lightGrey'"
              class="px-5 ml-1 tab-button"
              :class="{
                'active-tab-button': activeTab == 1
              }"
              label
            >
              Surveys
            </v-btn>
          </div>
          <div class="d-flex">
            <v-btn
              icon
              color="primary"
              :disabled="loading.lockRefresh"
              :loading="
                (activeTab == 0 && (loadingModules || loading.modules)) ||
                  (activeTab == 1 && (loadingSurveys || loading.surveys))
              "
              @click="
                if (activeTab == 0) {
                  getLearningModules();
                } else {
                  getSurveyModules();
                }
              "
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
            <v-btn icon color="primary" @click="dialog.search = !dialog.search"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
            <v-menu
              v-model="sort.menu"
              offset-y
              left
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex">
                  <v-btn
                    text
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    >Sort</v-btn
                  >
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-for="option in sort.options"
                  :key="option.id"
                  @click="updateSortBy(option)"
                  :class="{
                    'light-grey-background': option.values.includes(sort.value)
                  }"
                >
                  <v-list-item-content class="align-text-left">
                    <v-list-item-title>{{ option.label }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon
                    :class="{ hidden: !option.values.includes(sort.value) }"
                    ><v-icon>{{
                      sort.value && sort.value.includes("asc")
                        ? "mdi-arrow-up"
                        : "mdi-arrow-down"
                    }}</v-icon></v-list-item-icon
                  >
                  <!-- </template> -->
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="mt-6 mx-12 text-left">
      <div
        class="d-flex justify-center"
        v-if="
          (activeTab == 0 && (loadingModules || loading.modules)) ||
            (activeTab == 1 && (loadingSurveys || loading.surveys))
        "
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="mx-auto"
        ></v-progress-circular>
      </div>
      <v-text-field
        v-if="dialog.search"
        outlined
        placeholder="Search"
        prepend-icon="mdi-magnify"
        single-line
        dense
        hide-details
        v-model="data.search"
        class="mb-5"
      ></v-text-field>
      <div class="d-flex justify-center mt-2">
        <v-btn
          v-if="
            activeTab == 0 &&
              permissions.includes('learning:create:module') &&
              permissions.includes('vue:read:learningwizard')
          "
          text
          color="primary"
          class="mx-auto my-3"
          @click="loadLearningWizard(null)"
        >
          <v-icon small class="add-module-button mr-2" color="white"
            >mdi-plus</v-icon
          >New {{ moduleLanguage }}</v-btn
        >
      </div>
      <v-expansion-panels class="mb-5" flat v-model="openPanel">
        <v-expansion-panel
          v-for="module in visibleModules"
          :key="`${activeTab}-${module.learningModuleId}`"
        >
          <v-expansion-panel-header class="mx-0 px-0 py-0 module-header">
            <v-hover v-slot="{ hover }">
              <div
                class="d-flex align-center"
                :style="
                  `width: ${moduleNameWidth}px; max-width: ${moduleNameWidth}px;`
                "
                @click.stop="addToProgram(module)"
              >
                <v-icon
                  class="module-icons"
                  :class="{
                    'primary-background': hover,
                    'brand-background': !hover
                  }"
                  color="white"
                  >{{
                    successModules.includes(module.learningModuleId)
                      ? "mdi-check"
                      : hover
                      ? "mdi-plus"
                      : module.type == "Learning"
                      ? "mdi-school"
                      : "mdi-comment-question-outline"
                  }}</v-icon
                >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      class="ml-2 mr-n2"
                      :class="module.status == 'Draft' ? '' : 'hidden'"
                      color="error"
                      >mdi-exclamation</v-icon
                    >
                  </template>
                  This {{ moduleLanguage.toLowerCase() }} is in Draft mode.
                  Until published, it will be hidden from the program
                </v-tooltip>
                <div
                  class="d-flex flex-column ml-4"
                  :title="module.displayName || 'Unknown module'"
                >
                  <div
                    class="learning-display-na1me"
                    :style="
                      `width: calc(${moduleNameWidth}px - 75px); max-width: calc(${moduleNameWidth}px - 75px);`
                    "
                  >
                    <div class="primary--text text-overflow-clip">
                      {{ module.displayName }}
                    </div>
                  </div>
                  <span class="mediumGrey--text text-caption"
                    >{{ moduleLanguage }} created
                    {{ module.createdAtFormatted }}</span
                  >
                </div>
              </div>
            </v-hover>

            <template v-slot:actions>
              <v-icon small>$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p
              v-if="
                activeTab == 0 &&
                  permissions.includes('learning:create:module') &&
                  permissions.includes('vue:read:learningwizard')
              "
              class="primary--text mb-0 d-flex align-center cursor-pointer"
              @click="
                loadLearningWizard(module.learningModuleId, module.clientId)
              "
            >
              <v-icon small class="mr-1 mb-1" color="primary"
                >mdi-pencil</v-icon
              >
              Edit {{ moduleLanguage.toLowerCase() }}
            </p>
            <p class="mb-0">
              Chapters and cards in this {{ moduleLanguage.toLowerCase() }}
            </p>
            <v-expansion-panels class="mt-2">
              <v-expansion-panel
                v-for="chapter in module.LearningChapters"
                :key="chapter.learningChapterId"
              >
                <v-expansion-panel-header class="mx-0 pl-0 pr-4 py-0">
                  <div class="d-flex align-center learning-display-name">
                    <div
                      class="ml-4 text-overflow-clip font-weight-bold "
                      :title="chapter.displayName || 'Unknown chapter'"
                    >
                      {{ chapter.displayName }}
                    </div>
                  </div>
                  <template v-slot:actions>
                    <v-icon small>$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0">
                  <div
                    v-for="card in chapter.LearningCards"
                    :key="card.learningCardId"
                    class="my-1"
                  >
                    <div class="d-flex align-center">
                      <v-icon
                        class="module-icons brand-background"
                        color="white"
                        >{{
                          ["quiz", "survey", "assessment"].includes(card.type)
                            ? "mdi-format-list-checkbox"
                            : "mdi-school"
                        }}</v-icon
                      >
                      <div
                        class="ml-2 text-overflow-clip learning-card-row"
                        :title="card.displayName || 'Unknown card'"
                      >
                        {{ card.displayName }}
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-flex justify-center mt-2">
              <v-btn
                text
                color="primary"
                class="mx-auto"
                @click="addToProgram(module)"
              >
                <v-icon small class="add-module-button mr-2" color="white"
                  >mdi-plus</v-icon
                >Add to program</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- Dialog when coming from program wizard to close tab -->
    <v-dialog
      v-model="dialog.learningWizard"
      width="500"
      persistent
      class="dialog"
    >
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="brandCyan" x-large class="exit-warning-icon mr-4"
            >mdi-school</v-icon
          >

          <v-card-title class="word-break text-left exit-warning-text">
            Once you're finished in the learning wizard, come back here to
            continue editing your program.
          </v-card-title>
        </div>
        <v-card-text class="text-left"
          >If the learning wizard does not automatically open in a new tab, you
          can follow this link in a new tab:
          <a>{{ learningWizardUrl }}</a></v-card-text
        >

        <v-card-actions class="mx-12 d-flex justify-center">
          <v-btn
            color="brandCyan"
            depressed
            outlined
            width="170"
            @click="closeLearningWizard"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import LoadingDialog from "@/components/loadingDialog.vue";
import LearningService from "../services/LearningService";

// import RobinAnimation from "./Robin";

import { mapState } from "vuex";
import moment from "moment";
// import fz from "fuzzaldrin-plus";

export default {
  name: "ProgramLearningModuleWidget",
  components: {
    // LoadingDialog,
    // RobinAnimation,
  },
  props: {
    clientId: Number,
    modules: Array,
    surveys: Array,
    type: String,
    loadingModules: Boolean,
    loadingSurveys: Boolean,
    activeProgram: Boolean
  },
  data() {
    return {
      moduleType: "Learning",
      data: {
        modules: [],
        surveys: [],
        search: null
      },
      dialog: {
        search: null,
        learningWizard: false,
        learningWizardModuleId: null,
        learningWizardClientId: null
      },
      sort: {
        menu: false,
        value: "create-date-desc",
        options: [
          {
            label: "Name",
            key: "name",
            values: ["name-asc", "name-desc"]
          },
          {
            label: "Created",
            key: "create-date",
            values: ["create-date-asc", "create-date-desc"]
          }
        ]
      },
      loading: {
        surveys: false,
        modules: false,
        lockRefresh: false
      },
      activeTab: 0,

      successModules: [],
      openPanel: null,
      moduleNameWidth: 300
    };
  },

  created() {
    // We pass in props and fetch modules or surveys if not passed in
    if (this.type && ["Learning", "Survey"].includes(this.type))
      this.moduleType = this.type;
    if (!this.modules) {
      this.getLearningModules();
    } else {
      this.modules.forEach(module => this.removeModuleTags(module));
    }
    if (!this.surveys) {
      this.getSurveyModules();
    } else {
      this.surveys.forEach(module => this.removeModuleTags(module));
    }

    // Set active tab
    if (this.moduleType == "Survey") this.activeTab = 1;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onScreenResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onScreenResize);
  },
  methods: {
    onScreenResize(e) {
      console.log("Learning module screen resize! ", e);
      const collection = document.querySelector(".module-header");
      if (collection) {
        const width = collection.offsetWidth,
          height = collection.offsetHeight;
        console.log({ height, width });
        this.moduleNameWidth = width - 40;
      }
    },
    getLearningModules() {
      this.loading.lockRefresh = true;
      // Unlocks refresh after 5 seconds
      setTimeout(() => {
        this.loading.lockRefresh = false;
      }, 5000);
      if (this.modules) return this.$emit("get-modules");
      this.loading.modules = true;
      LearningService.getModulesAdmin("Learning", this.clientId)
        .then(response => {
          console.log("Learning Modules: ", response);
          var modules = response.modules
            .filter(
              module =>
                module.status !== "Deleted" &&
                (module.clientId == 0 || module.clientId == this.clientId)
            )
            .map(x => {
              var obj = x;
              obj.id = x.learningModuleId.toString();
              obj.context = "LearningModules";
              obj.contextResourceId = x.learningModuleId.toString();
              obj.contextColumn = "metricValue";
              obj.label =
                "Module Progress" +
                (x.displayName
                  ? x.displayName.substring(0, 30)
                  : x.name
                  ? x.name.substring(0, 30)
                  : "Unnamed module");
              obj.dataType = "INTEGER";
              obj.showNumberOptions = true;
              obj.createdAtFormatted = moment(obj.createdAt)
                .utc()
                .format("MMM DD, YYYY");
              obj.icon = "mdi-school";
              obj.iconColor = "brand";
              obj.displayGrabber = true;
              this.removeModuleTags(obj);
              return obj;
            });
          //Adds header because this array will be used to select metrics for a payoutRule
          this.data.modules = modules;

          this.loading.modules = false;
        })
        .catch(error => {
          console.log("Error getting learning modules", error);
          this.loading.modules = false;
        });
    },
    getSurveyModules() {
      if (this.surveys) return this.$emit("get-surveys");
      this.loading.surveys = true;
      LearningService.getModulesAdmin("Survey", this.clientId)
        .then(response => {
          console.log("Surveys: ", response);
          var modules = response.modules
            .filter(
              module =>
                module.status !== "Deleted" &&
                (module.clientId == 0 || module.clientId == this.clientId)
            )
            .map(x => {
              var obj = x;
              obj.id = x.learningModuleId.toString();
              obj.context = "LearningModules";
              obj.contextResourceId = x.learningModuleId.toString();
              obj.contextColumn = "metricValue";
              obj.label =
                "Survey Progress: " +
                (x.displayName
                  ? x.displayName.substring(0, 30)
                  : x.name
                  ? x.name.substring(0, 30)
                  : "Unnamed module");
              obj.dataType = "INTEGER";
              obj.showNumberOptions = true;
              obj.createdAtFormatted = moment(obj.createdAt)
                .utc()
                .format("MMM DD, YYYY");
              obj.icon = "mdi-comment-question-outline";
              obj.iconColor = "brand";
              obj.displayGrabber = true;
              this.removeModuleTags(obj);
              return obj;
            });
          //Adds header because this array will be used to select metrics for a payoutRule
          this.data.surveys = modules;

          this.loading.surveys = false;
        })
        .catch(error => {
          console.log("Error get surveys ", error);
          this.loading.surveys = false;
        });
    },
    changeTab(slide) {
      // We only do something if they click on the other option
      if (slide != this.activeTab) {
        this.activeTab = slide;

        // Here we have to reset the selected array and other settings
        // We also need to add the users back to the original array
        if (slide == 0) {
          // The user switched away from groups, so add groups back
          this.moduleType = "Learning";
        } else {
          this.moduleType = "Survey";
        }

        this.openPanel = null;
        // this.slidePeople.selected = [];
        document.getElementsByClassName(
          "v-dialog v-dialog--active"
        )[0].scrollTop = 0;
      }
    },
    close() {
      this.$emit("close");
    },
    addToProgram(module) {
      console.log("Adding module to program ", module);
      this.$emit("add-to-program", module);
      this.successModules.push(module.learningModuleId);

      setTimeout(() => {
        // After 3 seconds we remove the module so the success state goes away
        this.successModules = this.successModules.filter(
          x => x != module.learningModuleId
        );
      }, 3000);
    },
    loadLearningWizard(moduleId = null, clientId = null) {
      if (moduleId != null) this.dialog.learningWizardModuleId = moduleId;
      if (clientId != null) this.dialog.learningWizardClientId = clientId;
      this.dialog.learningWizard = true;
      window.open(this.learningWizardUrl, "_blank");
    },
    closeLearningWizard() {
      this.dialog.learningWizard = false;
      this.dialog.learningWizardModuleId = null;
      this.dialog.learningWizardClientId = null;
      this.getLearningModules();
    },
    updateSortBy(option) {
      console.log("updating sortby ", option);

      var sortOption = this.sort.options.find(x =>
        x.values.includes(this.sort.value)
      );
      // If they're picking an option that's already selected, then they're just changing the sort order.
      if (sortOption == option) {
        var asc = this.sort.value.includes("asc");
        this.sort.value = option.key + "-" + (asc ? "desc" : "asc");
      } else {
        this.sort.value = option.key + "-asc";
        // this.replaceQuery(option.key + "-asc", null);
      }
    },
    timeDiffMilliseconds(module) {
      // The time between now and the create date, in milliseconds
      const now = moment();
      const end = moment(module.createdAt);
      // console.log(now, end);
      // console.log('milliseconds', end.diff(now));
      return end.diff(now);
    },
    removeModuleTags(module) {
      const tempDescription = module.description.replace(
        /<\/?[^>]+(>|$)/g,
        " "
      );
      module.descriptionNoTags = tempDescription.replace(/&nbsp;/g, " ");
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"]),
    moduleLanguage() {
      if (this.moduleType == "Survey") return "Survey";
      return "Learning module";
    },
    visibleModules() {
      // console.log("Running");
      let modulesToDisplay;
      if (this.surveys && this.moduleType == "Survey") {
        modulesToDisplay = this.surveys;
      } else if (this.modules && this.moduleType == "Learning") {
        modulesToDisplay = this.modules;
      } else if (this.moduleType == "Survey") {
        modulesToDisplay = this.data.surveys;
      } else {
        modulesToDisplay = this.data.modules;
      }

      // console.log("modules");
      // console.log(modulesToDisplay);

      if (this.sort.value) {
        if (this.sort.value.includes("create-date")) {
          modulesToDisplay = modulesToDisplay
            .filter(x => x.createdAt)
            .sort((a, b) => {
              if (isNaN(this.timeDiffMilliseconds(a))) {
                return 1;
              } else if (isNaN(this.timeDiffMilliseconds(b))) {
                return -1;
              }
              return this.sort.value.includes("asc")
                ? this.timeDiffMilliseconds(a) - this.timeDiffMilliseconds(b)
                : this.timeDiffMilliseconds(b) - this.timeDiffMilliseconds(a);
            });
          // .append(modulesToDisplay.filter((x) => !x.createdAt));
        } else {
          // console.log("sorting by name");
          // Sort by name
          modulesToDisplay = modulesToDisplay.sort((a, b) => {
            return this.sort.value.includes("asc")
              ? a.displayName.localeCompare(b.displayName)
              : b.displayName.localeCompare(a.displayName);
          });
        }
      }
      if (!this.data.search) {
        return modulesToDisplay;
      }
      // console.log("in search");
      modulesToDisplay = modulesToDisplay.filter(x =>
        x.displayName.toLowerCase().includes(this.data.search.toLowerCase())
      );
      // const preparedQuery = fz.prepareQuery(this.data.search);
      // // console.log(preparedQuery);
      // const scores = {};
      // modulesToDisplay = modulesToDisplay
      //   .map(item => {
      //     let scorableFields = [item.displayName, item.descriptionNoTags];
      //     scorableFields = scorableFields.map(toScore => {
      //       return fz.score(toScore, this.data.search, { preparedQuery });
      //     });
      //     scores[item.learningModuleId] = Math.max(...scorableFields);
      //     return item;
      //   })
      //   .filter(item => scores[item.learningModuleId] > 1);
      // if (this.sort.value == null) {
      //   modulesToDisplay = modulesToDisplay.sort(
      //     (a, b) => scores[b.learningModuleId] - scores[a.learningModuleId]
      //   );
      // }
      return modulesToDisplay;
    },
    learningWizardUrl() {
      var env =
        process.env.VUE_APP_ENVIRONMENT == "dev"
          ? "dev"
          : process.env.VUE_APP_ENVIRONMENT == "test"
          ? "test"
          : "app";
      return `https://${env}.wewhistle.com/learning-wizard?source=program_wizard&clientId=${
        this.dialog.learningWizardClientId != null
          ? this.dialog.learningWizardClientId
          : this.clientId
      }${
        this.dialog.learningWizardModuleId
          ? `&learningModuleId=${this.dialog.learningWizardModuleId}`
          : ""
      }`;
    }
  }
};
</script>

<style scoped>
/* Used for the tab buttons at the top */
.tab-group-container {
  padding: 5px 5px;
  border-radius: 7px;
  width: 230px;
  background-color: var(--v-lightGrey-base);
}
.tab-button {
  width: 100px;
  text-align: center;
  text-transform: none !important;
  letter-spacing: 0em;
}
.active-tab-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}

/* Used for the table of modules */
.module-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
}

/* Used to clip the learning module name */
.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Used to give gray background to learning cards in previews */
.learning-card-row {
  border-radius: 5px;
  background: var(--v-lightGrey-base);
  width: 100%;
  height: 35px;
  padding-top: 6px;
  padding-left: 12px;
}

/* Used for the small plus button after expanding a module to add it to the program */
.add-module-button {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: var(--v-brand-base);
}

/* Used to cap length of learning module display names */
.learning-display-name {
  /* width: 325px; */
  width: calc(100% - 40px);
  max-width: calc(100% - 40px);
}

/* Hides the gray click effect when adding a module or survey */
.v-expansion-panel-header::before {
  opacity: 0 !important;
}
</style>

<template>
  <v-card
    :elevation="elevation"
    :ripple="false"
    :width="width"
    :max-width="maxWidth"
    class="d-flex flex-column justify-space-between rounded-lg popup-window"
    :class="{
      'activity-card': !popup,
      'activity-card-popup': popup
    }"
  >
    <div>
      <div class="d-flex text-align-left pa-3 align-start">
        <!-- :class="{ 'align-start': date !== null, 'align-center': date === null }" -->
        <v-icon
          v-if="!popup"
          dark
          class="border-radius card-icon"
          :class="{
            'dark-blue-background': iconColor === 'dark-blue',
            'grey-background': iconColor === 'grey',
            'accent-background': iconColor === 'accent',
            'error-background': iconColor === 'error',
            'brand-background': iconColor === 'brand',
            'brand-yellow-background':
              iconColor === 'brandYellow' || iconColor === 'brand-yellow',
            'brand-green-background':
              iconColor === 'brandGreen' || iconColor === 'brand-green',
            'brand-dark-green-background':
              iconColor === 'brandDarkGreen' ||
              iconColor === 'brand-dark-green',
            'brand-cyan-background':
              iconColor === 'brandCyan' ||
              iconColor === 'brand-cyan' ||
              iconColor === 'brand-cyan-background',
            'medium-grey-background': iconColor === 'medium-grey-background',
            'primary-background': iconColor === 'primary'
          }"
          >{{ icon }}</v-icon
        >
        <div
          class="d-flex justify-space-between full-width align-text-left ml-3"
        >
          <span v-if="title" class="word-break text-body-1"> {{ title }}</span>
          <div v-if="newMessage && !popup" class="new-message-dot"></div>
          <!-- <v-tooltip top v-else-if="title">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="word-break text-body-1">{{
                title.substring(0, 24) + ".."
              }}</span>
            </template>
            <span>{{ title }}</span>
          </v-tooltip> -->
          <!-- <span class="pa-0 text-caption mediumGrey--text">{{ date }}</span> -->
        </div>
      </div>
      <v-divider class="mx-2" />
    </div>
    <v-card-text
      class="word-break d-flex justify-start full-height pb-0 px-2 flex-column main-rec-content"
      :class="{ 'pt-0': fullMessage && values.length }"
    >
      <div
        v-if="fullMessage && values.length"
        id="value-container"
        class="d-flex flex-wrap ma-2"
      >
        <span
          class="brandCyan--text company-value-text"
          v-for="value in values"
          :key="value.companyValueId"
          >{{ value.displayName }}</span
        >
        <!-- <v-chip-group column
          >
          <v-chip
            :small="$vuetify.breakpoint.xs"
            v-for="value in values"
            class="long-chips text-left black--text"
            :key="value.companyValueId"
            :ripple="false"
            outlined
            color="brandCyan"
            ><span>{{ value.displayName }}</span></v-chip
          >
          </v-chip-group
        > -->
      </div>
      <h2 v-if="messageTitleFlag && !fullMessage" class="my-1">
        {{ messageTitle }}
      </h2>
      <p v-if="messageSubtitleFlag && !fullMessage" class="mb-1 mt-3">
        {{ messageSubtitle }}
      </p>
      <!-- Recognition image, so we don't want to force it into an aspect ratio -->
      <v-img
        :lazy-src="image"
        :src="image"
        v-if="fullMessage && image && image.length > 0"
        contain
        width="100%"
        max-width="450"
        class="border-radius mx-auto"
      ></v-img>
      <v-img
        :lazy-src="image"
        :src="image"
        v-else-if="
          (imageFlag || expandFullMessage) && image && image.length > 0
        "
        :aspect-ratio="16 / 9"
        width="100%"
        max-width="450"
        class="border-radius mx-auto"
      ></v-img>
      <v-btn
        v-if="
          !fullMessage && messageBody && !expandFullMessage && linkButtonFlag
        "
        text
        class="linkToResource mx-auto"
        color="primary"
        @click="toggleFullMessage"
        >{{ linkText }}</v-btn
      >
      <p
        v-if="(expandFullMessage || fullMessage) && messageBody"
        v-html="messageBody"
        class="mt-3 black--text text-left px-2"
      ></p>
      <v-btn
        v-if="
          !fullMessage && messageBody && expandFullMessage && linkButtonFlag
        "
        text
        class="linkToResource mx-auto"
        color="primary"
        @click="toggleFullMessage"
        >Show less</v-btn
      >
      <div class="d-flex justify-space-between text-left pb-1">
        <span class="ml-2 pa-0 text-caption mediumGrey--text"
          >{{ date }} <span v-if="devEnvironment">({{ messageId }})</span></span
        >
        <div class="d-flex justify-center">
          <v-tooltip top open-delay="100" max-width="250"
            ><template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                v-if="privateRecognition"
                class="mr-1"
                >mdi-eye-lock-outline</v-icon
              > </template
            ><span v-if="recipients.length > 1"
              >Only you, other recipients, and the sender can see this</span
            >
            <span v-else>Only you and the sender can see this</span>
          </v-tooltip>
          <div
            @click="viewRecipients"
            class="cursor-pointer"
            v-if="recipients.length > 1"
          >
            <v-icon>mdi-account</v-icon>{{ recipients.length }}
          </div>
        </div>
      </div>
    </v-card-text>
    <v-divider v-if="linkButtonFlag && !fullMessage" class="mx-2" />
    <!-- <v-card-actions class="py-0" v-if="linkButtonFlag && !fullMessage">
      <v-spacer />
      <v-btn text class="linkToResource" color="primary" @click="doAction">{{
        linkText
      }}</v-btn>
    </v-card-actions> -->
    <!-- Reactions and comments -->
    <v-divider class="mx-2" />
    <!-- <div class="d-flex flex-wrap ma-2"> -->
    <v-chip-group active-class="primary--text" class="mx-2" column>
      <div
        v-for="(item, index) in visibleReactions"
        :key="item.emoji"
        class="lottie-container"
      >
        <div
          v-if="
            display.lottieSparkleEffect &&
              index == display.lottieSparkleEffectIndex
          "
          class="lottie-sparkle-effect"
        >
          <LottiePlayer
            animation="sparkle"
            :height="150"
            :width="150"
            :speed="1.3"
            :loop="false"
          />
        </div>
        <v-tooltip top open-delay="300" max-width="250"
          ><template v-slot:activator="{ on, attrs }">
            <v-chip
              v-on="on"
              v-bind="attrs"
              :small="$vuetify.breakpoint.xs"
              outlined
              @click="toggleEmoji(item, index)"
              density="compact"
              :color="item.hasLiked ? 'brandCyan' : 'grey'"
              style="height: 30px;"
            >
              <!-- <div class="lottie-container"> -->
              <!-- <div
                  v-if="
                    display.lottieSparkleEffect &&
                      index == display.lottieSparkleEffectIndex
                  "
                  class="lottie-sparkle-effect"
                >
                  <LottiePlayer
                    animation="sparkle"
                    :height="120"
                    :width="120"
                    :speed="1.3"
                    :loop="false"
                  />
                </div> -->
              <span class="mr-1" style="margin-top: 2px;">{{
                item.emoji
              }}</span>
              <!-- </div> -->
              <span class="" style="margin-top: 2px;">{{
                item.count
              }}</span></v-chip
            > </template
          ><span class="break-word">
            {{
              (item.hasLiked ? "You" : "") +
                (item.hasLiked && item.hoverLabel ? ", " : "") +
                (item.hoverLabel || "")
            }}</span
          ></v-tooltip
        >
        <!-- <v-chip
          :small="$vuetify.breakpoint.xs"
          outlined
          :title="
            (item.hasLiked ? 'You' : '') +
              (item.hasLiked && item.hoverLabel ? ', ' : '') +
              (item.hoverLabel || '')
          "
          @click="toggleEmoji(item)"
          density="compact"
          :color="item.hasLiked ? 'brandCyan' : 'grey'"
          style="height: 30px;"
          ><span class="mr-1" style="margin-top: 2px;">{{ item.emoji }}</span>
          <span class="" style="margin-top: 2px;">{{
            item.count
          }}</span></v-chip
        > -->
      </div>
    </v-chip-group>
    <!-- comments -->
    <div
      class="text-right full-width pr-2 brandCyan--text cursor-pointer"
      v-if="visibleComments.length"
      @click="toggleComments"
    >
      <v-icon color="brandCyan">{{
        showComments ? "mdi-chevron-down" : "mdi-chevron-up"
      }}</v-icon>
      <span
        >{{ visibleComments.length > 5 ? "5+" : visibleComments.length }}
        {{ visibleComments.length == 1 ? "comment" : "comments" }}</span
      >
    </div>
    <v-expand-transition>
      <div
        id="comments"
        class="d-flex flex-column align-start text-left mx-2"
        v-if="showComments"
      >
        <div
          v-for="comment in visibleComments"
          :key="comment.messageCommentId"
          class="mb-1 full-width"
          :class="{ 'text-right': comment.userId == userProfile.userId }"
        >
          <p class="my-0 py-0">{{ comment.name }}</p>
          <v-hover v-slot="{ hover }">
            <div
              class="d-flex align-center"
              :class="{ 'justify-end': comment.userId == userProfile.userId }"
            >
              <v-btn
                icon
                v-if="hover && comment.userId == userProfile.userId"
                small
                class="ml-1"
                @click="deleteComment(comment.messageCommentId)"
              >
                <v-icon color="red">mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-chip
                class="word-break long-chips "
                :title="comment.formattedCreatedAt || comment.createdAt"
                :class="{
                  'white--text': comment.userId == userProfile.userId,
                  'bubble-animated':
                    comment.userId == userProfile.userId && comment.animate
                }"
                :color="
                  comment.userId == userProfile.userId
                    ? 'brandDarkCyan'
                    : undefined
                "
                >{{ comment.comment }}</v-chip
              >
            </div>
          </v-hover>
        </div>
      </div>
    </v-expand-transition>

    <!-- new comment section -->
    <div class="d-flex justify-space-between align-center mx-1 my-2">
      <EmojiPicker :top="popup" :bottom="!popup" @select="insertEmoji" />
      <!-- <v-menu
        offset-y
        :top="popup"
        :bottom="!popup"
        attach
        :close-on-content-click="false"
        v-model="showMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="emoji-invoker1" icon v-on="on" v-bind="attrs"
            ><v-icon>mdi-emoticon-outline</v-icon>
          </v-btn>
        </template>
        <v-card v-if="showMenu">
          <Picker
            :data="emojiIndex"
            :native="true"
            :showSearch="true"
            :showPreview="false"
            @select="insertEmoji"
          />
        </v-card>
      </v-menu> -->

      <v-textarea
        v-model="newComment"
        hide-details
        outlined
        rounded
        dense
        auto-grow
        rows="1"
        class="py-0 my-0"
        placeholder="Comment..."
        color="brandCyan"
        @keydown.enter.prevent
        @keyup.enter="insertComment"
      ></v-textarea>
      <v-btn
        fab
        color="success"
        x-small
        class="elevation-0 ml-1"
        :disabled="!newComment"
        :loading="submittingComment"
        @click="insertComment"
        ><v-icon color="white">mdi-chevron-right</v-icon></v-btn
      >
    </div>
    <!-- </div> -->
    <v-dialog v-model="showUnderConstruction" width="400">
      <!-- <v-card class="py-4">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <h2>{{ header }}</h2> -->

      <v-img src="@/assets/Under_Construction.png" max-width="400" />
      <!-- </v-card-text>
      </v-card> -->
    </v-dialog>
    <v-dialog
      v-model="dialog.recipients"
      width="500"
      content-class="mx-3 px-0"
      class="mx-0"
    >
      <v-card class="pb-4" style="min-height: 200px;">
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-left">Recipients</v-card-title>
          <v-btn icon @click="dialog.recipients = false" class="mr-3"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <v-divider class="mx-2 mb-4" />
        <p
          v-for="user in recipients"
          :key="user.userId"
          :class="{ 'font-weight-bold': user.userId == userProfile.userId }"
          class="mx-2"
        >
          {{ user.displayName }}
        </p></v-card
      ></v-dialog
    >
  </v-card>
</template>

<script>
// import data from "emoji-mart-vue-fast/data/all.json";
// import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
// import "emoji-mart-vue-fast/css/emoji-mart.css";
// Create emoji data index.
// We can change it (for example, filter by category) before passing to the component.
// let emojiIndex = new EmojiIndex(data);
import confetti from "canvas-confetti";
import moment from "moment";

import LottiePlayer from "@/components/LottiePlayer.vue";
import EmojiPicker from "@/components/EmojiPicker.vue";

import { mapState } from "vuex";

export default {
  name: "ActivityFeedCardV2",
  props: {
    elevation: {
      type: Number,
      default: 3
    },
    width: {
      type: String,
      default: undefined
    },
    maxWidth: {
      type: String,
      default: undefined
    },
    id: Number,
    messageId: Number,
    context: String,
    subcontext: String,
    object: Object,
    icon: String,
    iconColor: String,
    title: String,
    date: String,
    messageTitle: String,
    messageSubtitle: String,
    messageBody: String,
    image: String,
    linkText: {
      type: String,
      default: "Read the update"
    },

    //Flags to indicate what to show in the card
    messageTitleFlag: Boolean,
    messageSubtitleFlag: Boolean,
    imageFlag: Boolean,
    messageIconFlag: Boolean,
    linkButtonFlag: Boolean,
    previewFlag: Boolean,
    fullMessage: { type: Boolean, default: false },
    popup: { type: Boolean, default: false },
    likedByUser: {
      type: Boolean,
      default: false
    },
    privateRecognition: { type: Boolean, default: false },
    newMessage: { type: Boolean, default: true },
    reactions: {
      type: Array,
      default: () => [
        // { emoji: "👽", count: 10, hasLiked: false },
        // { emoji: "🥒", count: 209, hasLiked: false },
        // { emoji: "😇", count: 5, hasLiked: false }
        // { emoji: "😅", count: 2, hasLiked: false },
        // { emoji: "🥰", count: 1000, hasLiked: false },
        // { emoji: "😍", count: 1000, hasLiked: false },
        // { emoji: "🤪", count: 5, hasLiked: true }
      ]
    },
    comments: {
      type: Array,
      default: () => [
        {
          messageCommentId: 1,
          name: "Daniel Conwell",
          comment: "Another one!"
        },
        {
          messageCommentId: 2,
          name: "Andrew Hrdlicka",
          comment: "Have you ever played rugby?"
        },
        {
          messageCommentId: 3,
          name: "Drew Carter",
          comment: "Call me asparagus!"
        },
        {
          messageCommentId: 4,
          name: "Chris Dornfeld",
          comment: "I call it Chandalier!"
        }
        // {
        //   messageCommentId: 5,
        //   name: "Chris Dornfeld",
        //   comment:
        //     "I call it Chandalier! What even are we doing here? Wow here kjlasdf asdf;kljasdfl; l;kasdf"
        // }
      ]
    },
    values: { type: Array, default: () => [] },
    recipients: { type: Array, default: () => [] },
    celebration: {
      type: String,
      default: null
    }
  },
  components: {
    // Picker,
    EmojiPicker,
    LottiePlayer
  },
  data() {
    return {
      // emojiIndex,
      showUnderConstruction: false,
      showMenu: false,
      newComment: null,
      emojiSearch: null,
      showComments: false,
      submittingComment: false,
      x: 0,
      y: 0,
      dialog: { recipients: false },
      display: {
        lottieSparkleEffect: false,
        v: null
      },
      animatedComments: [],
      expandFullMessage: false
    };
  },
  mounted() {
    if (this.celebration == "CONFETTI") {
      confetti({
        particleCount: 300,
        spread: 70,
        scalar: 1.13,
        origin: { y: 0.95, x: 0.36 },
        zIndex: 20001
      });
      setTimeout(() => {
        confetti({
          particleCount: 200,
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
          origin: { y: 0.85, x: 0.72 },
          zIndex: 20001
        });
      }, 400);
      setTimeout(() => {
        confetti({
          particleCount: 100,
          spread: 120,
          startVelocity: 45,
          scalar: 0.8,
          origin: { y: 0.99, x: 0.54 },
          zIndex: 20001
        });
      }, 700);
    }
  },
  methods: {
    showEmojiMenu(e) {
      console.log(e);
      this.x = e.clientX;
      this.y = e.clientY;
    },
    show(e, clickEvent) {
      console.log("event", e);
      console.log("clieck ", clickEvent);
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      // clickEvent(e);
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    insertEmoji(emoji) {
      // const emoji = e.native;
      console.log("Inserting emoji ", emoji);
      let existingEmojiIndex = this.visibleReactions.findIndex(
        x => x.emoji == emoji
      );
      if (existingEmojiIndex !== -1) {
        let existingEmoji = this.visibleReactions[existingEmojiIndex];
        if (existingEmoji && existingEmoji.hasLiked) {
          existingEmoji.count = existingEmoji.count - 1;
          existingEmoji.hasLiked = false;
          this.$emit("remove-reaction", emoji);
        } else if (existingEmoji && !existingEmoji.hasLiked) {
          this.display.lottieSparkleEffect = true;
          this.display.lottieSparkleEffectIndex = existingEmojiIndex;
          existingEmoji.count = existingEmoji.count + 1;
          existingEmoji.hasLiked = true;
          this.$emit("create-reaction", emoji);
          setTimeout(() => {
            //  Return early if the index doesn't match. That means they have already clicked another reaction
            if (this.display.lottieSparkleEffectIndex != existingEmojiIndex)
              return;
            this.display.lottieSparkleEffect = false;
            this.display.lottieSparkleEffectIndex = null;
          }, 1000);
        }
      } else {
        let futureIndex = this.visibleReactions.length;
        this.display.lottieSparkleEffect = true;
        this.display.lottieSparkleEffectIndex = futureIndex;
        this.$emit("create-reaction", emoji);
        this.reactions.push({ emoji, count: 1, hasLiked: true });
        setTimeout(() => {
          //  Return early if the index doesn't match. That means they have already clicked another reaction
          if (this.display.lottieSparkleEffectIndex != futureIndex) return;
          this.display.lottieSparkleEffect = false;
          this.display.lottieSparkleEffectIndex = null;
        }, 1000);
      }
      this.showMenu = false;
    },
    toggleEmoji(item, index) {
      if (item.hasLiked) {
        item.hasLiked = false;
        item.count--;
        this.$emit("remove-reaction", item.emoji);
      } else {
        this.display.lottieSparkleEffect = true;
        this.display.lottieSparkleEffectIndex = index;
        item.hasLiked = true;
        item.count++;
        this.$emit("create-reaction", item.emoji);
        setTimeout(() => {
          //  Return early if the index doesn't match. That means they have already clicked another reaction
          if (this.display.lottieSparkleEffectIndex != index) return;
          this.display.lottieSparkleEffect = false;
          this.display.lottieSparkleEffectIndex = null;
        }, 1000);
      }
    },
    toggleComments() {
      // If they close the comment drawer, then we mark everything so it doesn't reanimate if opened again
      const val = !this.showComments;
      if (val == false) this.animatedComments = [];
      //   this.comments = this.comments.map(x => {
      //     x.animate = false;
      //     return x;
      //   });
      this.showComments = val;
    },
    insertComment() {
      if (this.submittingComment || !this.newComment)
        return console.log("Ignoring enter key for comment");
      console.log("Inserting comment ", this.newComment);
      this.submittingComment = true;
      this.$emit("create-comment", {
        comment: this.newComment,
        messageId: this.messageId,
        callback: response => {
          console.log("Got response from create-comment emit", response);
          if (response) {
            this.comments.push({
              ...response,
              name: `${this.userProfile.firstName} ${this.userProfile.lastName}`
              // animate: true
              // messageCommentId: Date.now(),
              // name: `${this.userProfile.firstName} ${this.userProfile.lastName}`,
              // comment: this.newComment
            });
            this.animatedComments.push(response.messageCommentId);
            this.newComment = null;
            if (!this.showComments) this.showComments = true;
          }
          this.submittingComment = false;
        }
      });
    },
    deleteComment(id) {
      console.log("Deleting comment ", id);
      this.$emit("remove-comment", id);
      const index = this.comments.findIndex(x => x.messageCommentId == id);
      if (index !== -1) this.comments.splice(index, 1);
      // this.comments.push({
      //   messageCommentId: Date.now(),
      //   name: `${this.userProfile.firstName} ${this.userProfile.lastName}`,
      //   comment: this.newComment
      // });
      // this.newComment = null;
      // if (!this.showComments) this.showComments = true;
    },
    toggleFullMessage() {
      this.expandFullMessage = !this.expandFullMessage;
    },
    doAction() {
      if (this.context !== null && this.previewFlag != true) {
        //We can do the action, otherwise, the button may just be a preview card
        //Likewise, we check the preview flag, and if it's true, don't do this
        if (
          this.context === "news" ||
          this.context === "general" ||
          this.context === "alert" ||
          this.context === "alerts" ||
          (this.context == "program" &&
            this.subcontext &&
            this.subcontext.startsWith("payment_cash") &&
            this.subcontext.includes("procore"))
        ) {
          //We are on the communications screen and editing news
          // this.$emit("action", this.id);
          console.log(this.id);
          if (this.id == -1) {
            this.$router.push({ name: "learning" });
          } else {
            this.$router.push({
              name: "newsArticle",
              params: {
                messageId: this.object.id.toString(),
                article: this.object
              }
            });
          }
        } else if (this.context === "wallet") {
          //We are on the communications screen and editing news
          // this.$emit("action", this.id);
          console.log(this.id);
          if (this.id == -1) {
            this.$router.push({ name: "wallet" });
          } else {
            this.$router.push({
              name: "newsArticle",
              params: {
                messageId: this.object.id.toString(),
                article: this.object
              }
            });
          }
        } else if (this.context == "learning") {
          this.$router.push({
            name: "learningdetails",
            params: {
              learningId: this.id.toString()
            }
          });
        } else if (this.context == "program") {
          console.log(this.id);
          if (this.id == -1) {
            this.$router.push({ name: "learning" });
          } else {
            this.$router.push({
              name: "programdetails",
              params: {
                programPreloaded: null,
                programId: this.id.toString(),
                sourcePage: "activity"
              }
            });
          }
        }
      }
    },
    viewRecipients() {
      this.dialog.recipients = true;
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    // likedMessage: {
    //   get: function() {
    //     return this.likedByUser;
    //   },
    //   set: function(newVal) {
    //     this.$emit("liked-message", newVal);
    //   }
    // },
    computedCardColor() {
      if (this.context === "program") {
        return "accent";
      } else {
        return "brandGreen";
      }
    },
    devEnvironment: () => process.env.VUE_APP_ENVIRONMENT == "dev",
    visibleReactions() {
      return this.reactions.filter(x => x.count > 0);
    },
    visibleComments() {
      return this.comments.map(x => {
        x.formattedCreatedAt = moment(x.createdAt).format(
          "MMM DD, YYYY h:mm a"
        );
        x.animate = !!this.animatedComments.find(y => y == x.messageCommentId);
        return x;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.brand-background {
  background-color: var(--v-brand-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.brand-dark-green-background {
  background-color: var(--v-brandDarkGreen-base);
}
.brand-cyan-background {
  background-color: var(--v-brandCyan-base);
}
.brand-yellow-background {
  background-color: var(--v-brandYellow-base);
}
.primary-background {
  background-color: var(--v-primary-base);
}
.medium-grey-background {
  background-color: var(--v-mediumGrey-base);
}
.border-radius {
  border-radius: 5px;
}
.green-background {
  background-color: var(--v-brandPaleGreen-base);
}
.brand-pale-blue-background {
  background-color: var(--v-brandPaleBlue-base);
}

/* Wraps the text of comment bubbles */
.long-chips {
  height: auto !important;
  min-height: 32px;
}
.long-chips >>> .v-chip__content {
  padding: 8px 0 8px 0;
  max-width: 100%;
  height: auto;
  min-height: 32px;
  white-space: pre-wrap;
}

/* .comment-bubble:hover {
  background-color: #e0e0e0 !important;
} */

.main-rec-content {
  height: auto;
  overflow: visible;
}

/* Used to cause scroll effect if card is longer than screen height */
.activity-card-popup {
  /* top bar where close button is 90ish pixels */
  /* height: 100%; */
  overflow-y: scroll;
}

/* Used to cap size of icon on activity card */
.card-icon {
  min-width: 40px;
  max-width: 40px;
  padding: 8px 0px 8px 0px;
}

.company-value-text {
  font-size: 12px;
  margin: 2px 4px 2px 4px;
}

.lottie-container {
  position: relative;
}
.lottie-sparkle-effect {
  position: absolute;
  top: -58px;
  left: -58px;
  /* z-index: 1; */
  pointer-events: none;
}

/* Define the keyframes for the animation */
@keyframes stretchSquash {
  0% {
    transform: scaleX(0); /* Start with no width */
    transform-origin: right center;
  }
  50% {
    transform: scaleX(1.1); /* Stretch to 150% width */
    transform-origin: right center;
  }
  100% {
    transform: scaleX(1); /* Squash back to full width */
    transform-origin: right center;
  }
}

/* Apply the animation to the bubble element */
.bubble-animated {
  animation: stretchSquash 0.5s ease-out; /* Adjust the duration and easing as needed */
}

/* Ensure the transform origin is set for smooth animation */
.bubble-animated span {
  transform-origin: right center;
}

.new-message-dot {
  background-color: var(--v-brandCyan-base);
  border-radius: 100%;
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  /* position: absolute; */
  /* top: -6px;
  right: -6px; */
  /* border-radius: 50%; */
  /* display: inline-block; */
}
/* .message-dot-container {
  position: relative;
} */

.popup-window::-webkit-scrollbar {
  /* Height adjusts as the page grows longer */
  height: auto;
  width: 10px;
}

.popup-window::-webkit-scrollbar-track {
  /* Makes track a dark grey but opaque */
  background: rgba(255, 255, 255, 0.2);
}

.popup-window::-webkit-scrollbar-thumb {
  /* Grabber is white, rounded, with a slight shadow */
  background: lightgrey;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.popup-window::-webkit-scrollbar-corner {
  background: lightgrey;
}
</style>

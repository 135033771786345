<template>
  <v-card>
    <v-card-text class="align-text-center pt-5">
      <v-img
        src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
        max-width="250"
        class="mx-auto"
      />
      <h2 class="my-6">
        <b>Your session has expired</b>
      </h2>

      <p class="text-center mx-7 mb-6">
        Please log in to continue using Whistle.
      </p>

      <!-- <v-btn
        depressed
        color="brand"
        width="140"
        class="mx-2 white--text"
        href="mailto:help@wewhistle.com"
        >Contact us</v-btn
      > -->
      <v-btn
        depressed
        color="brand"
        width="140"
        class="mx-2 white--text"
        @click="refreshPage"
        >Log In</v-btn
      >
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MagicLinkExpirationPopup",
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    refreshPage() {
      // this.$store.dispatch("setMagicLinkTokenExpirationWarning", false);
      document.location.reload();
    }
  },
  computed: {
    ...mapState([])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div class="robin d-flex align-start">
    <lottie
      v-if="displayRobin"
      :options="lottieOptions"
      :height="height"
      :width="width"
      v-on:animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import Lottie from "vue-lottie/src/lottie.vue";
import * as particles from "@/assets/LottieAnimations/particle.json";
import * as stars from "@/assets/LottieAnimations/stars.json";
import * as poof from "@/assets/LottieAnimations/poof.json";
import * as sparkle from "@/assets/LottieAnimations/sparkle.json";

export default {
  name: "LottiePlayer",

  props: {
    message: String,
    header: String,
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 50
    },
    animation: {
      type: String,
      default: "particles"
    },
    loop: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    speed: {
      type: Number,
      default: 1
    }
  },
  components: {
    Lottie
  },
  data() {
    return {
      // lottieOptions: {
      //   animationData: animationData.default,
      //   loop: true,
      //   autoplay: true,
      // },
      anim: null,
      displayRobin: true
    };
  },
  created() {},
  updated() {},

  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
      this.anim.setSpeed(this.speed);
    }
  },
  computed: {
    lottieOptions: {
      cache: false,
      get: function() {
        let obj = {
          loop: this.loop,
          autoplay: this.autoplay,
          animationSpeed: 5,
          speed: 5
        };
        var anim = this.animation.toLowerCase();
        if (anim == "particles") {
          obj.animationData = particles.default;
        } else if (anim == "stars") {
          obj.animationData = stars.default;
        } else if (anim == "poof") {
          obj.animationData = poof.default;
        } else if (anim == "sparkle") {
          obj.animationData = sparkle.default;
        }

        return obj;
      }
    }
  },
  watch: {
    animation: function() {
      // remove the Lottie component from the DOM
      this.displayRobin = false;
      this.$nextTick(() => {
        // add Lottie component in DOM
        this.displayRobin = true;
      });
    }
  }
};
</script>

<style scoped></style>

<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="brandDarkGreen" rounded="0" elevation="0">
      <!-- <v-toolbar-items> -->
      <v-btn dark icon @click="close">
        <v-icon large>mdi-close-circle</v-icon>
      </v-btn>
      <!-- </v-toolbar-items> -->
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-flex align-center"
        ><v-icon class="mr-2 mt-n1">mdi-gift-outline</v-icon>Choose a reward
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden">
        <v-btn dark text @click="close">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div class="mt-5 preset-container">
      <v-card class="preset-selector-box" width="75%" elevation="0">
        <EmojiPicker
          :bottom="true"
          :top="false"
          :class="{
            'mr-5 ml-n1': !!customRewardEmoji,
            'mr-6': !customRewardEmoji
          }"
          :buttonWidth="!!customRewardEmoji ? 40 : undefined"
          :buttonColor="!!customRewardEmoji ? 'white' : 'black'"
          :placeholderEmoji="customRewardEmoji"
          @select="selectCustomEmoji"
        />
        <!-- <span class="preset-emoji mr-6"
                  >{{
                 
                  customEmoji }}</span
                > -->
        <div class="d-flex flex-column ml-3 text-left">
          <h3 class="font-weight-bold mb-0">
            Custom amount
          </h3>
          <div class="d-flex">
            <v-form v-model="customAmountForm" onSubmit="return false;">
              <v-text-field
                dense
                v-model="customRewardAmount"
                placeholder="0.00"
                :prefix="cashAward ? '$' : ''"
                color="brandDarkGreen"
                type="number"
                :rules="rules.amount"
              ></v-text-field>
            </v-form>
            <v-btn
              rounded
              depressed
              color="brandCyan"
              small
              @click="selectCustomReward"
              :disabled="!customAmountForm"
              class="mt-1 ml-1 white--text"
              >Add</v-btn
            >
          </div>
        </div></v-card
      >
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          :size="35"
          :width="3"
          color="brandCyan"
        ></v-progress-circular>
      </div>
      <v-hover
        v-for="preset in presets"
        :key="preset.paymentPresetId"
        v-slot="{ hover }"
      >
        <v-card
          class="preset-selector-box"
          width="75%"
          elevation="0"
          @click="selectPreset(preset)"
        >
          <span class="preset-selector-emoji" :class="{ wiggle: hover }">{{
            preset.emoji
          }}</span>
          <div class="d-flex flex-column text-left">
            <h3 class="font-weight-bold mb-0">
              {{ preset.displayName }}
            </h3>
            <span class="">{{ formatPresetAmount(preset.amount) }}</span>
          </div></v-card
        >
      </v-hover>
    </div>
    <!-- <div class="giphy-search-grid mx-6 mt-5" v-if="giphy.search"></div> -->
  </v-card>
</template>

<script>
import { mapState } from "vuex";

import currency from "currency.js";

import { formRules } from "@/shared_data/data";
import EmojiPicker from "@/components/EmojiPicker.vue";

export default {
  name: "RewardDrawer",
  components: { EmojiPicker },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    cashAward: {
      type: Boolean,
      default: true
    },
    presets: {
      type: Array,
      default: () => []
    },
    customEmoji: {
      type: String,
      default: null
    },
    customAmount: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      customAmountForm: true,
      rules: formRules
    };
  },
  created() {},
  destroyed() {},
  methods: {
    close() {
      this.$emit("close");
    },
    formatPresetAmount(amt) {
      // This will format large amounts as 1k or 1.5m, etc
      if (amt > 999.99)
        return (
          (this.cashAward ? "$" : "") +
          Intl.NumberFormat("en", { notation: "compact" }).format(
            currency(amt).value
          )
        );
      else return currency(amt).format({ symbol: this.cashAward ? "$" : "" });
      // return ).format();
    },
    selectCustomEmoji(emoji) {
      this.customRewardEmoji = emoji;
    },
    selectPreset(e) {
      this.$emit("select-reward", e);
    },
    selectCustomReward() {
      this.$emit("select-custom-reward");
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"]),
    customRewardEmoji: {
      get: function() {
        return this.customEmoji;
      },
      set: function(newVal) {
        this.$emit("update-custom-emoji", newVal);
      }
    },
    customRewardAmount: {
      get: function() {
        return this.customAmount;
      },
      set: function(newVal) {
        this.$emit("update-custom-amount", newVal);
      }
    }
  },
  watch: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(10deg);
  }
  25% {
    -ms-transform: rotate(-10deg);
  }
  50% {
    -ms-transform: rotate(5deg);
  }
  75% {
    -ms-transform: rotate(-5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}
@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
  /* 0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  } */
}
.wiggle {
  display: inline-block;
  animation-name: wiggle;
  -webkit-animation-name: wiggle;
  -ms-animation-name: wiggle;
  animation-duration: 1s;
  -ms-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
}
</style>

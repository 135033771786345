var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-height whistle-gradient-45"},[_c('div',{staticClass:"d-flex flex-column justify-space-between full-height container"},[_c('v-row',{staticClass:"full-width",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-column align-center justify-center white-background rounded",attrs:{"cols":"12","sm":"6","md":"5","lg":"5","xl":"5"}},[_c('v-row',{staticClass:"py-6 word-break px-6 flex-column align-center text-center"},[(!_vm.showSuccess)?_c('v-container',[_c('v-row',{staticClass:"d-flex flex-column align-center",attrs:{"justify":"center"}},[_c('v-img',{staticClass:"logo mt-4 mb-6",attrs:{"alt":"Whistle logo","src":require("../assets/Logo/whistle-logo-gradient-clean-v2.png"),"contain":""}}),_c('span',{staticClass:"header-text grey--text"},[_vm._v(" Would you like to unsubscribe from Whistle emails? ")]),_c('v-form',{staticClass:"mt-10 form",attrs:{"onSubmit":"return false;"},model:{value:(_vm.emailValid),callback:function ($$v) {_vm.emailValid=$$v},expression:"emailValid"}},[_c('v-text-field',{attrs:{"label":"Email Address","rules":[
                    (v) => (v && _vm.emailRegex.test(v)) || 'Email must be valid',
                  ],"outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if (_vm.emailValid) {
                      _vm.validateEmail();
                    } else {
                      () => {};
                    }}},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}})],1),(_vm.showEmailError)?_c('span',{staticClass:"red--text"},[_vm._v("Whoops! We couldn't find this email in our system.")]):_vm._e(),_c('v-btn',{staticClass:"mt-5 white--text",attrs:{"color":"brandCyan","depressed":"","disabled":!_vm.emailValid,"loading":_vm.waitingForEmail},on:{"click":_vm.validateEmail}},[_vm._v("Submit")]),_c('p',{staticClass:"grey--text mt-12 mb-6 mx-4"},[_vm._v(" We're sad to see you go! You can always log into Whistle to view your profile and subscribe again. ")])],1)],1):_c('v-container',{staticClass:"d-flex flex-column align-center justify-space-between px-8 pb-8 full-height"},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-img',{staticClass:"logo mt-4 mb-6",attrs:{"alt":"Whistle logo","src":require("../assets/Logo/whistle-logo-gradient-clean-v2.png"),"contain":""}}),_c('p',{staticClass:"text-h5 grey--text mt-5"},[_vm._v(" You're all set! ")]),_c('v-icon',{staticClass:"my-10 pa-3 brand-green-background circle-border-radius",attrs:{"color":"white","x-large":""}},[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"brandCyan","to":"/","depressed":""}},[_vm._v("Go Home")])],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
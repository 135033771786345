<template>
  <div>
    <v-progress-linear
      :value="progress"
      color="brandGreen"
      height="22"
      class="bar"
    ></v-progress-linear>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProgressBar",
  props: {
    progress: String,
  },
  data() {
    return {};
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {},
  computed: {
    ...mapState([]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bar {
  width: 100%;
  border-radius: 20px;
}
</style>

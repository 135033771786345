<template>
  <v-card
    rounded="0"
    elevation="4"
    :ripple="false"
    class="d-flex flex-column justify-space-between"
    :class="{
      preview: previewFlag,
      'green-background': robinFlag && context !== 'program',
      'brand-pale-blue-background': robinFlag && context === 'program'
    }"
    @click="doAction"
  >
    <div>
      <div
        class="d-flex text-align-left pa-3"
        :class="{ 'align-start': date !== null, 'align-center': date === null }"
      >
        <v-icon
          large
          dark
          class="pa-2 border-radius"
          :class="{
            'dark-blue-background': iconColor === 'dark-blue',
            'grey-background': iconColor === 'grey',
            'accent-background': iconColor === 'accent',
            'error-background': iconColor === 'error',
            'brand-background': iconColor === 'brand',
            'brand-yellow-background':
              iconColor === 'brandYellow' || iconColor === 'brand-yellow',
            'brand-green-background':
              iconColor === 'brandGreen' || iconColor === 'brand-green',
            'brand-dark-green-background':
              iconColor === 'brandDarkGreen' ||
              iconColor === 'brand-dark-green',
            'brand-cyan-background':
              iconColor === 'brandCyan' ||
              iconColor === 'brand-cyan' ||
              iconColor === 'brand-cyan-background',
            'primary-background': iconColor === 'primary'
          }"
          >{{ icon }}</v-icon
        >
        <div class="d-flex flex-column align-text-left ml-2">
          <span
            v-if="title && title.length < 25"
            class="word-break text-body-1"
            >{{ title }}</span
          >

          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="word-break text-body-1">{{
                title.substring(0, 24) + ".."
              }}</span>
            </template>
            <span>{{ title }}</span>
          </v-tooltip>
          <span class="pa-0 text-caption mediumGrey--text">{{ date }}</span>
        </div>
      </div>
      <v-divider class="mx-2" />
    </div>
    <v-card-text
      class="word-break d-flex justify-start full-height pb-2 px-2"
      :class="{ 'flex-column': progressType !== 'large' }"
    >
      <div v-if="progressType !== 'large'">
        <h2 v-if="messageTitleFlag" class="my-1">{{ messageTitle }}</h2>
        <!-- <v-icon
          color="white"
          x-large
          class="pa-5 mt-2 grey-background round-border"
          v-if="messageIconFlag"
          >{{ messageIcon }}</v-icon
        > -->
        <!-- <v-img
          v-if="messageIconFlag && messageSuccessImage === 'Trophy_Badge.png'"
          src="@/assets/Demo/Trophy_Badge.png"
          max-height="70"
          contain
          class="mt-2"
        />
        <v-img
          v-else-if="
            messageIconFlag && messageSuccessImage === 'Job_Well_Done.jpg'
          "
          src="@/assets/Demo/Job_Well_Done.jpg"
          max-height="90"
          max-width="100"
          contain
          class="mt-2 mx-auto border-radius"
        />
        <v-img
          v-else-if="
            messageIconFlag && messageSuccessImage === 'Level_3_Award.png'
          "
          src="@/assets/Demo/Level_3_Award.png"
          max-height="90"
          max-width="100"
          contain
          class="mt-2 mx-auto border-radius"
        /> -->
        <p v-if="messageSubtitleFlag" class="mt-3 mb-1">
          {{ messageSubtitle }}
        </p>
        <Robin
          v-if="robinFlag && robinMessage != null"
          :height="60"
          :width="60"
          :message="robinMessage"
          color="white"
          :borderColor="computedCardColor"
          :showText="true"
          :loop="true"
        ></Robin>
        <v-img
          :lazy-src="image"
          :src="image"
          v-if="imageFlag && image && image.length > 0"
          :aspect-ratio="16 / 9"
          width="100%"
          class="border-radius"
        ></v-img>
        <v-img
          v-else-if="imageFlag && imagePlaceholderFlag"
          :src="image"
          :key="image"
          :aspect-ratio="16 / 9"
          width="100%"
          class="border-radius"
        >
          <template v-slot:placeholder>
            <div class="light-grey-background full-height full-width"></div>
          </template>
        </v-img>
        <div
          class="d-flex justify-space-between progress-bar mt-2"
          v-if="progressFlag && progressType === 'bars'"
        >
          <span
            >Progress to goal: <b>{{ progressPercent }}%</b>
          </span>
          <span
            >Goal: <b>{{ programGoal }} sales</b></span
          >
        </div>
        <v-progress-linear
          v-if="progressFlag && progressType === 'bars'"
          :value="progressPercent"
          color="brand"
          height="13"
          class="mb-2"
        >
        </v-progress-linear>
        <div
          class="donut mx-auto mt-3"
          v-if="progressFlag && progressType === 'donuts'"
        >
          <v-progress-circular
            :rotate="270"
            :size="100"
            :width="12"
            :value="progressPercent"
            color="accent"
            class="inner-donut"
          >
            <div class="dark-blue-background donut-interior round-border"></div>
            <span class="white--text donut-text">{{ progressPercent }}%</span>
          </v-progress-circular>
        </div>
      </div>
      <div v-else class="d-flex py-9">
        <v-row>
          <v-col class="d-flex justify-center align-center">
            <h2
              v-if="messageTitleFlag"
              class="align-text-left large-card-title brand--text"
            >
              {{ messageTitle }}
            </h2>
          </v-col>
          <v-col>
            <div class="d-flex align-center">
              <div class="donut-container mr-2">
                <v-progress-circular
                  :rotate="270"
                  :size="150"
                  :width="22"
                  :value="progressPercent"
                  color="accent"
                >
                  <div
                    class="light-grey-background jumbo-donut round-border"
                  ></div>
                  <div class="d-flex flex-column">
                    <!-- <v-img
                      src="@/assets/Demo/Cintas_ChemBadge.png"
                      :max-width="60"
                      :height="60"
                      contain
                      class="mx-2 mb-1"
                    /> -->
                    <span class="brand--text donut-text"
                      >{{ progressPercent }}%</span
                    >
                  </div>
                </v-progress-circular>
              </div>
              <div
                class="d-flex flex-column align-start ml-5 large-progress-text"
              >
                <span
                  >Goal: <b>{{ programGoal }} chapters</b></span
                >
                <div class="d-flex align-center">
                  <div class="pa-2 mr-2 accent-background"></div>
                  <div class="d-flex flex-column align-start align-text-left">
                    <span>Progress</span>
                    <b>{{ progressValue }} of {{ programGoal }} chapters</b>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div class="pa-2 mr-2 brand-green-background"></div>
                  <div class="d-flex flex-column align-start align-text-left">
                    <span>Time remaining</span><b>7 days 12:47</b>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <div>
      <v-divider
        class="mx-2"
        :class="{ 'mb-2': !linkButtonFlag }"
        v-if="progressType !== 'large'"
      />
      <v-card-actions
        class="py-0"
        :class="{
          'd-flex': progressType === 'large',
          'justify-center': progressType === 'large'
        }"
        v-if="linkButtonFlag"
      >
        <v-btn
          icon
          v-if="displayHeart"
          @click.stop="likedMessage = !likedMessage"
          ><v-icon :color="likedMessage ? 'red' : 'grey'">{{
            likedMessage ? "mdi-heart" : "mdi-heart-outline"
          }}</v-icon></v-btn
        >
        <v-spacer v-if="progressType !== 'large'" />
        <v-btn
          text
          class="linkToResource"
          color="primary"
          @click="doAction"
          :disabled="disabled"
          >{{ linkText }}</v-btn
        >
      </v-card-actions>
    </div>
    <v-dialog v-model="showUnderConstruction" width="400">
      <!-- <v-card class="py-4">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <h2>{{ header }}</h2> -->

      <v-img src="@/assets/Under_Construction.png" max-width="400" />
      <!-- </v-card-text>
      </v-card> -->
    </v-dialog>
  </v-card>
</template>

<script>
import Robin from "@/components/Robin.vue";
export default {
  name: "ActivityFeedCard",
  props: {
    id: Number,
    context: String,
    subcontext: String,
    object: Object,
    icon: String,
    iconColor: String,
    title: String,
    date: String,
    messageTitle: String,
    messageSubtitle: String,
    image: String,
    robinMessage: String,
    progressType: String,
    progressValue: String,
    progressPercent: String,
    programGoal: String,
    messageSuccessImage: String,
    linkText: String,

    //Flags to indicate what to show in the card
    messageTitleFlag: Boolean,
    messageSubtitleFlag: Boolean,
    robinFlag: Boolean,
    imageFlag: Boolean,
    imagePlaceholderFlag: Boolean,
    progressFlag: Boolean,
    messageIconFlag: Boolean,
    linkButtonFlag: Boolean,
    actionFlag: Boolean,
    previewFlag: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    displayHeart: {
      type: Boolean,
      default: false
    },
    likedByUser: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Robin
  },
  data() {
    return {
      showUnderConstruction: false
    };
  },
  mounted() {},
  methods: {
    doAction() {
      if (this.context !== null && this.previewFlag != true) {
        //We can do the action, otherwise, the button may just be a preview card
        //Likewise, we check the preview flag, and if it's true, don't do this
        if (
          (this.context === "news" ||
            this.context === "general" ||
            this.context === "alert" ||
            this.context === "alerts" ||
            (this.context == "program" &&
              this.subcontext &&
              this.subcontext.startsWith("payment_cash") &&
              this.subcontext.includes("procore"))) &&
          this.actionFlag
        ) {
          //We are on the communications screen and editing news
          // this.$emit("action", this.id);
          console.log(this.id);
          if (this.id == -1) {
            this.$router.push({ name: "learning" });
          } else {
            this.$router.push({
              name: "newsArticle",
              params: {
                messageId: this.object.id.toString(),
                article: this.object
              }
            });
          }
        } else if (this.context === "wallet" && this.actionFlag) {
          //We are on the communications screen and editing news
          // this.$emit("action", this.id);
          console.log(this.id);
          if (this.id == -1) {
            this.$router.push({ name: "wallet" });
          } else {
            this.$router.push({
              name: "newsArticle",
              params: {
                messageId: this.object.id.toString(),
                article: this.object
              }
            });
          }
        } else if (this.context == "learning" && this.actionFlag) {
          this.$router.push({
            name: "learningdetails",
            params: {
              learningId: this.id.toString()
            }
          });
        } else if (this.context == "program" && this.actionFlag) {
          console.log(this.id);
          if (this.id == -1) {
            this.$router.push({ name: "programs" });
          } else {
            this.$router.push({
              name: "programdetails",
              params: {
                programPreloaded: null,
                programId: this.id.toString(),
                sourcePage: "activity"
              }
            });
          }
        } else if (this.actionFlag) {
          this.showUnderConstruction = true;
        }
      }
    }
  },
  computed: {
    likedMessage: {
      get: function() {
        return this.likedByUser;
      },
      set: function(newVal) {
        this.$emit("liked-message", newVal);
      }
    },
    computedCardColor() {
      if (this.context === "program") {
        return "accent";
      } else {
        return "brandGreen";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.preview {
  cursor: default;
}
.preview .linkToResource:hover {
  background: #fffffe;
  cursor: default;
}

.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.brand-background {
  background-color: var(--v-brand-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.brand-dark-green-background {
  background-color: var(--v-brandDarkGreen-base);
}
.brand-cyan-background {
  background-color: var(--v-brandCyan-base);
}
.brand-yellow-background {
  background-color: var(--v-brandYellow-base);
}
.primary-background {
  background-color: var(--v-primary-base);
}
.border-radius {
  border-radius: 5px;
}
.green-background {
  background-color: var(--v-brandPaleGreen-base);
}
.brand-pale-blue-background {
  background-color: var(--v-brandPaleBlue-base);
}
.card-title {
  /* width: 175px; */
}
.round-border {
  border-radius: 100%;
}
.progress-bar {
  min-width: 200px;
  max-width: 270px;
}
.donut {
  position: relative;
  /* width: 100%; */
  width: 120px;
  height: 120px;
}
.donut-interior {
  padding: 38px;
  z-index: inherit;
  position: absolute;
}
.donut-text {
  /* Puts the text one step above the dark blue background */
  z-index: 1;
  font-size: 20px;
}
.large-card-title {
  width: 70%;
  font-size: 25px;
  line-height: 1.2em;
}
.large-progress-text {
  font-size: 18px;
}
.jumbo-donut {
  padding: 53px;
  z-index: inherit;
  position: absolute;
}
</style>

<template>
  <div class="mt-4 flex-column d-flex">
    <v-card rounded="0" width="100%" elevation="0" class="quiz-card">
      <v-card-title class="word-break px-0 font-weight-bold brand--text">
        <!-- #{{ questionIndex + 1 }} - -->
        {{ question.content }}
      </v-card-title>
      <p class="grey--text mb-1 font-italic">
        Choose a response from the answers below
      </p>
      <v-divider />

      <v-radio-group
        row
        v-model="value"
        class="full-width justify-space-between py-2"
        :class="isMobile ? '' : 'px-4'"
      >
        <div
          class="d-flex flex-column align-center text-center px-2 likert-button"
        >
          <v-radio
            color="red"
            class="strongly-disagree"
            @Change="selectedRadio == true"
            value="stronglyDisagree"
            :min="lowerBound"
          >
          </v-radio>
          <div class="mt-1">
            Strongly<br />
            Disagree
          </div>
        </div>
        <div
          class="d-flex flex-column align-center text-center px-2 likert-button"
        >
          <v-radio
            color="orange lighten-1"
            class="disagree"
            @Change="selectedRadio == true"
            value="disagree"
          >
          </v-radio>
          <div class="mt-1">
            Disagree
          </div>
        </div>
        <div
          class="d-flex flex-column align-center text-center px-2 likert-button"
        >
          <v-radio
            color="yellow darken-1"
            class="neutral"
            @Change="selectedRadio == true"
            value="neutral"
          >
          </v-radio>
          <div class="mt-1">
            Neutral
          </div>
        </div>
        <div
          class="d-flex flex-column align-center text-center px-2 likert-button"
        >
          <v-radio
            color="#BFE078"
            class="agree"
            @Change="selectedRadio == true"
            value="agree"
          >
          </v-radio>
          <div class="mt-1">
            Agree
          </div>
        </div>
        <div
          class="d-flex flex-column justify-center align-center text-center px-2 likert-button"
        >
          <v-radio
            color="green"
            class="strongly-agree"
            @Change="selectedRadio == true"
            value="stronglyAgree"
            :max="upperBound"
          >
          </v-radio>
          <div class="mt-1">Strongly<br />Agree</div>
        </div>
      </v-radio-group>

      <v-divider />
      <div class="d-flex justify-center mt-10">
        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          depressed
          @click="checkQuestion"
          v-if="selectedRadio == false"
          :disabled="value == null"
          width="120"
        >
          {{ computedSubmitButton }}
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          depressed
          @click="completeQuiz"
          width="120"
          v-else-if="
            questionIndex == questionCount - 1 &&
              selectedRadio == true &&
              !isSurveyQuestion
          "
        >
          Complete
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LearningService from "@/services/LearningService";

export default {
  name: "QuizQuestionLikertV2",
  props: {
    question: Object,
    questionIndex: Number,
    questionCount: Number,
    completedStatus: Boolean,
    learningCardAssociationId: Number,
    isSurveyQuestion: Boolean,
    surveyClientId: Number,
    whistleToken: String,
    enteredEmail: String,
    context: String,
  },
  components: {},
  data() {
    return {
      selectedAnswer: null,
      answerKey: null,
      chapterList: null,
      currentQuestion: null,
      lowerBound: 1,
      upperBound: 5,
      value: null,
      selectedRadio: false,
    };
  },
  created() {},
  updated() {},
  mounted() {},

  beforeMount() {},
  methods: {
    checkQuestion() {
      this.selectedRadio = true;

      //build question response
      console.log("build question response...");
      var quizQuestionResponse = {
        clientId:
          this.userProfile && this.userProfile.clientId != null
            ? this.userProfile.clientId
            : this.surveyClientId,
        learningCardId: this.question.learningCardId,
        learningQuizQuestionId: this.question.learningQuizQuestionId,
        learningCardAssociationId: this.learningCardAssociationId,
        value: this.value,
        userId: this.whistleToken != null ? null : this.$auth.user.sub,
      };

      if (this.whistleToken !== null) {
        quizQuestionResponse.externalUserId = this.enteredEmail;
      }

      console.log(quizQuestionResponse);
      console.log(this.value);

      LearningService.createQuizResponse(
        [quizQuestionResponse],
        this.whistleToken
      );
      //if this is a survey question, we don't want to have to click Submit, and then Next, one button should do both
      //so we call the function for next question or complte quiz inside of the submit button
      if (this.isSurveyQuestion == true) {
        if (this.questionIndex !== this.questionCount - 1) {
          this.goToNextQuestion();
        } else {
          this.completeQuiz();
        }
      } else {
        this.goToNextQuestion();
      }

      console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(this.value);

      //this.goToNextQuestion();
    },
    goToNextQuestion() {
      // If we're not on the last question, move on and reset the flags for answering a question
      if (this.questionIndex !== this.questionCount - 1) {
        this.value = null;
        this.selectedRadio = false;
        this.$emit("next-question", true);
      }
    },
    completeQuiz() {
      // Emits a notification so the quiz can submit
      this.$emit("complete-quiz", true);
    },
  },
  computed: {
    ...mapState(["userProfile"]),
    noCorrectAnswersMarked() {
      return (
        this.question &&
        this.question.LearningQuizAnswers.filter((ans) => ans.correctness > 0)
          .length == 0
      );
    },
    computedSubmitButton: function() {
      return this.isSurveyQuestion ? "Next" : "Submit";
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    inProgram() {
      // used to change styling if we're in learning or surveys versus in programs
      return !(this.context == "survey" || this.context == "learning");
    },
  },
};
</script>

<style scoped>
/* Classes for formatting radio buttons with different colors */
.strongly-disagree >>> .v-icon {
  color: red;
  border-color: red;
}
.strongly-disagree {
  display: flex;
  justify-content: center !important;
}
.strongly-disagree >>> .v-input--selection-controls__input {
  margin: 0px;
}

.v-radio.strongly-disagree.theme--light {
  margin: 0px;
}

.disagree >>> .v-icon {
  color: #ffa726;
  border-color: #ffa726;
}
.v-radio.disagree.theme--light {
  margin: 0px;
}
.disagree >>> .v-input--selection-controls__input {
  margin: 0px;
}

.neutral >>> .v-icon {
  color: #ffd54f;
  border-color: #ffd54f;
}
.v-radio.neutral.theme--light {
  margin: 0px;
}
.neutral >>> .v-input--selection-controls__input {
  margin: 0px;
}

.agree >>> .v-icon {
  color: #bfe078;
  border-color: #bfe078;
}
.v-radio.agree.theme--light {
  margin: 0px;
}
.agree >>> .v-input--selection-controls__input {
  margin: 0px;
}

.strongly-agree >>> .v-icon {
  color: #4caf50;
  border-color: #4caf50;
}
.v-radio.strong-agree.theme--light {
  margin: 0px;
}
.strongly-agree >>> .v-input--selection-controls__input {
  margin: 0px;
  margin-left: 16px !important;
}

/* Spacing for each likert button */
.likert-button {
  width: 20%;
  font-size: 12px;
}
</style>

<template>
  <div class="">
    <p class="text-h5 mx-8">{{ card.displayName }}</p>
    <div
      class="mx-8 pa-0 mt-6 text-left "
      v-if="
        computedDateSummary &&
          !card.displayLeaderboard &&
          !card.displayResources
      "
    >
      {{ computedDateSummary }}
    </div>
    <v-img
      v-if="card.displayImage"
      :src="card.imageURL"
      contain
      width="80%"
      height="auto"
      class="mx-auto mt-4"
    />
    <Leaderboard
      v-else-if="card.displayLeaderboard"
      :programId="programId"
      :useSmallVersion="false"
      :version="2"
      class="mt-8 mx-auto"
    />
    <div v-else-if="card.displayResources" class="mx-8 mt-6">
      <v-data-table
        :headers="[
          {
            text: '',
            align: 'start',
            value: 'icon',
            sortable: false,
            width: '7%'
          },

          {
            text: 'Name',
            value: 'Content.name',
            width: '30%',
            sortable: false
          },
          {
            text: 'Description',
            value: 'Content.description',
            width: '50%',
            sortable: false
          }
        ]"
        :items="!card.ProgramAttachments ? [] : card.ProgramAttachments"
        item-key="learningModuleAttachmentId"
        :items-per-page="10"
        class="elevation-0 cursor-pointer mt-6 box-border rounded-0 resource-table"
        hide-default-footer
        @click:row="downloadResource"
      >
        <template v-slot:[`item.icon`]="{ item }">
          <v-progress-circular
            indeterminate
            color="brand"
            size="23"
            width="2"
            v-if="downloadResources.includes(item.programAttachmentId)"
          />
          <v-icon v-else color="brand" @click.stop="downloadResource(item)"
            >mdi-download</v-icon
          >
        </template>
        <template v-slot:[`item.fileType`]="{ item }">
          <v-icon color="red" x-large v-if="item.Content.fileType === 'PDF'"
            >mdi-file-pdf-box</v-icon
          >
          <v-icon
            color="blue"
            x-large
            v-else-if="item.Content.fileType === 'DOCX'"
            >mdi-file-word</v-icon
          >
          <v-icon
            color="green"
            x-large
            v-else-if="item.Content.fileType === 'XLSX'"
            >mdi-file-excel</v-icon
          >
          <v-icon
            color="red"
            x-large
            v-else-if="item.Content.fileType === 'PPTX'"
            >mdi-file-powerpoint</v-icon
          >
          <v-icon
            color="blue"
            x-large
            v-else-if="item.Content.fileType === 'PNG'"
            >mdi-file-image</v-icon
          >
          <v-icon
            color="green"
            x-large
            v-else-if="item.Content.fileType === 'CSV'"
            >mdi-file-delimited</v-icon
          >
          <v-icon color="blue" x-large v-else>mdi-file</v-icon>
        </template>
        <template v-slot:[`item.Content.name`]="{ item }">
          <div class="d-flex flex-column my-3">
            <b>{{ item.Content.name }}</b>
            <span class="mediumGrey--text"
              >{{ item.Content.fileExtension }} File |
              {{ item.Content.fileSize }}</span
            >
          </div>
        </template>
        <template v-slot:no-data>
          <span>No resources attached to this program</span>
        </template>
      </v-data-table>
    </div>
    <div
      class="mx-8 pa-0 mt-6 text-left ql-editor"
      v-html="card.description"
      v-if="card.description"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

import ContentService from "@/services/ContentService";

import Leaderboard from "@/components/Leaderboard";

import { formatFileType } from "@/shared_data/functions.js";

export default {
  name: "ProgramDetailsOverviewCard",
  props: {
    programId: String,
    currentCard: Object
  },
  components: { Leaderboard },
  data() {
    return {
      selectedAnswer: null,
      answerKey: null,
      chapterList: null,
      currentQuestion: null,
      lowerBound: 1,
      upperBound: 5,
      value: null,
      selectedRadio: false,
      column: 5,
      downloadResources: []
    };
  },
  created() {
    // if (!this.card.learnerRating) this.card.learnerRating = 1;
    this.refreshCard();
  },
  updated() {},
  mounted() {},

  beforeMount() {},
  methods: {
    goToNextQuestion() {
      // If we're not on the last question, move on and reset the flags for answering a question
      if (this.questionIndex !== this.questionCount - 1) {
        this.value = null;
        this.selectedRadio = false;
        this.$emit("next-question", true);
      }
    },
    completeQuiz() {
      // Emits a notification so the quiz can submit
      this.$emit("complete-quiz", true);
    },
    updateLearnerRating(starIndex, emptyStar) {
      console.log(starIndex);
      console.log(emptyStar);
      var oldScore = this.card.learnerRating || 0;
      if (emptyStar) {
        // We are increasing our learner rating because they clicked an empty star
        var newScore = (oldScore += starIndex);
        console.log(
          "Storing new learner confidence: old - " +
            oldScore +
            ", new - " +
            newScore
        );
      } else {
        // We are decreasing score since they clicked a filled star
        if (oldScore == starIndex) {
          // They clicked their current rating so we wipe it
          newScore = 0;
        } else {
          newScore = starIndex;
        }
        console.log(
          "Updating learner confidence: old - " +
            oldScore +
            ", new - " +
            newScore
        );
      }
      this.card.learnerRating = newScore;
    },
    loadNextCard(postLog) {
      this.$emit("nextCard", postLog);
    },
    async downloadResource(item) {
      console.log(item);
      this.downloadResources.push(item.programAttachmentId);
      await ContentService.downloadContent(
        item.contentId,
        item.Content.filePath
      );
      let index = this.downloadResources.findIndex(
        x => x == item.programAttachmentId
      );
      if (index != -1) this.downloadResources.splice(index, 1);
    },
    refreshCard() {
      // This gets run in place of created() because sometimes the component doesn't get refreshed / re-rendered if we switch from learning card to learning card
      if (
        this.card &&
        this.card.ProgramAttachments &&
        this.card.ProgramAttachments.length
      ) {
        this.card.ProgramAttachments.map(x => {
          var filepath = x.Content.filePath;
          x.Content.fileType = formatFileType(filepath);
          x.Content.fileExtension =
            filepath.lastIndexOf(".") != -1
              ? filepath.toUpperCase().substring(filepath.lastIndexOf(".") + 1)
              : "Unknown";
        });
      }
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    card: {
      get: function() {
        return this.currentCard;
      },
      set: function(newVal) {
        this.$emit("update-card", newVal);
      }
    },
    prettyEndDate() {
      if (!this.card.endDate) return null;
      return moment(this.card.endDate).format("LLL");
    },
    prettyStartDate() {
      return moment(this.card.startDate).format("LLL");
    },
    computedDateSummary() {
      let today = moment(new Date());
      if (moment(today).isBefore(this.card.startDate)) {
        return "This program starts " + this.prettyStartDate;
      } else if (!this.prettyEndDate) {
        return "This program started " + this.prettyStartDate;
      } else if (moment(today).isAfter(this.card.endDate)) {
        return "This program ended " + this.prettyEndDate;
      } else {
        return "This program ends " + this.prettyEndDate;
      }
    }
  },
  watch: {
    currentCard: function() {
      this.refreshCard();
    }
  }
};
</script>

<style scoped>
p {
  font-size: 12px;
}

/* Used for the border around the learner rating */
.box-border {
  border: solid 1px;
  border-color: lightgray;
}

/* Shrinks text of learner box */
.learner-rating {
  font-size: 14px;
}

.resource-table >>> .v-data-table-header {
  background-color: white;
}
</style>

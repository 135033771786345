<template>
  <div>
    <v-toolbar dark color="#349E03" rounded="0">
      <v-btn dark icon large @click="reset" class="ml-1">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-flex align-center font-weight-bold"
        ><v-icon v-if="!isMobile" class="mr-3">mdi-cash-fast</v-icon>Process a
        payout file</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        dark
        icon
        large
        @click="reset"
        class="ml-1 hidden"
        v-if="userProfile.clientId != 1"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
      <v-autocomplete
        v-else
        outlined
        dense
        hide-details
        :items="clients"
        item-text="formattedName"
        item-value="clientId"
        label="Client"
        style="max-width: 250px;"
        v-model="clientId"
        @change="refreshClientId"
        :disabled="
          slideKey > keys.review || slideKey == keys.submittingValidation
        "
      ></v-autocomplete>
    </v-toolbar>
    <v-row
      class="full-height mt-6 pb-6"
      justify="center"
      align="center"
      height="100%"
    >
      <v-col
        cols="11"
        sm="11"
        md="4"
        lg="4"
        xl="4"
        class="main-card rounded-lg px-5 d-flex flex-column justify-space-between"
      >
        <div>
          <div class="d-flex justify-space-between align-center mt-10">
            <v-progress-linear
              :value="
                slideKey <= keys.review
                  ? ((slideKey % 20) + 1) * 25
                  : progressBarValue
              "
              :color="processingFile ? 'brandDarkGreen' : 'brandGreen'"
              height="40"
              class="pill-nav mr-6"
            >
              <!-- <div style="left: 0; width: 20%; height: 40px; background: red;"></div> -->
              <div class="d-flex justify-space-between align-center">
                <div
                  @click="goToSlide(keys.budget)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.budget }"
                >
                  <v-icon color="white">mdi-wallet</v-icon>
                </div>
                <div
                  @click="goToSlide(keys.memo)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.memo }"
                >
                  <v-icon color="white">mdi-message-text-outline</v-icon>
                </div>
                <div
                  @click="goToSlide(keys.csv)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{ 'active-pill': slideKey == keys.csv }"
                >
                  <v-icon color="white">mdi-upload</v-icon>
                </div>
                <div
                  @click="goToSlide(keys.review)"
                  class="pill-item"
                  :style="`width: calc(${navigationItemWidth}px / 4);`"
                  :class="{
                    'active-pill':
                      slideKey == keys.review ||
                      slideKey == keys.submittingValidation
                  }"
                >
                  <v-progress-circular
                    color="white"
                    indeterminate
                    :size="25"
                    :width="4"
                    v-if="slideKey == keys.submittingValidation"
                  ></v-progress-circular>
                  <v-icon color="white" v-else>mdi-rocket-launch</v-icon>
                </div>
              </div>
            </v-progress-linear>
            <v-btn
              :width="isMobile ? 90 : 110"
              rounded
              depressed
              :color="keys.review == slideKey ? 'brandGreen' : 'brandCyan'"
              class="white--text font-weight-bold"
              @click="goToNextSlide"
              :disabled="disableContinueButton"
            >
              <v-icon color="white" class="mr-1" v-if="slideKey == keys.sent"
                >mdi-checkbox-marked-circle</v-icon
              >
              <v-icon
                color="white"
                class="mr-1"
                v-else-if="!isMobile && slideKey != keys.review"
                >mdi-arrow-right-circle</v-icon
              >
              <span v-if="slideKey !== keys.sent" style="margin-top:2px;">{{
                slideKey == keys.sent
                  ? "Close"
                  : slideKey == keys.review
                  ? "Ready"
                  : "Next"
              }}</span>
            </v-btn>
          </div>
          <transition :name="slideDirection" mode="out-in">
            <div
              v-if="slideKey === keys.budget"
              :key="keys.budget"
              class="full-width text-left"
            >
              <p class="text-h6 font-weight-bold mb-5 mt-12">
                Select your budget and wallet category
              </p>
              <span class="text-caption ml-2">Deducted from</span>
              <v-select
                outlined
                dense
                attach
                :menu-props="{ bottom: true, offsetY: true }"
                :items="visibleBudgets"
                :loading="loadingBudgets"
                v-model="budgetId"
                item-value="budgetId"
                item-text="displayNameWithBalance"
                :no-data-text="
                  loadingBudgets ? 'Loading your budgets' : 'No budgets found'
                "
                :rules="[v => !!v || 'Budget is required']"
                @blur="sendBudgetIdTrigger"
              >
                <template v-slot:item="{ item }">
                  <div
                    class="d-flex align-center justify-space-between full-width"
                  >
                    <span class="budget-name-container text-overflow-clip">
                      {{ item.budgetDisplayName }}
                    </span>
                    <span
                      ><span v-if="item.awardId == 1">$ </span
                      >{{ formatCurrency(item.budgetBalance) }}
                      <span v-if="item.awardId != 1 && item.Award">{{
                        item.budgetBalance == 1 && item.Award.unitSingular
                          ? item.Award.unitSingular
                          : item.budgetBalance != 1 && item.Award.unitPlural
                          ? item.Award.unitPlural
                          : ""
                      }}</span></span
                    >
                  </div>
                </template></v-select
              >

              <span class="text-caption ml-2">Deposited to</span>
              <div
                class="mx-auto d-flex align-center selected-category category-container full-width"
                @click="displayCategoryDrawer = true"
              >
                <v-icon
                  class="side-nav-icons ml-2 mr-2"
                  color="white"
                  :style="{ 'background-color': '#349E03' }"
                  >{{ categoryOptions[selectedCategoryIndex].icon }}</v-icon
                >
                <div
                  class="d-flex justify-start align-baseline font-weight-bold brand--text"
                >
                  <p class="ml-2 my-auto">
                    {{ categoryOptions[selectedCategoryIndex].label }}
                  </p>
                </div>
                <v-icon large class="ml-auto mr-2">mdi-chevron-right</v-icon>
              </div>
            </div>
            <div
              v-else-if="slideKey === keys.memo"
              :key="keys.memo"
              class="full-width text-left"
            >
              <p class="text-h6 font-weight-bold mb-5 mt-12">
                Add a note?
                <span class="font-weight-regular"
                  >It's optional, but recommended.</span
                >
              </p>
              <v-textarea
                label="Payment memo"
                outlined
                v-model="memo"
                :rules="[
                  v =>
                    !!(!v || (v && v.length < 255)) ||
                    'Your note should be shorter'
                ]"
              ></v-textarea>
            </div>
            <div
              v-else-if="slideKey === keys.csv"
              :key="keys.csv"
              class="full-width text-left"
            >
              <div>
                <p class="text-h6 font-weight-bold mb-5 mt-12">
                  Upload your CSV file
                </p>
                <div
                  class="d-flex flex-column align-center"
                  @drop.prevent="dragAndDropFileChanged"
                  @dragover.prevent
                >
                  <v-btn
                    rounded
                    depressed
                    color="brandCyan"
                    width="210"
                    class="white--text font-weight-bold mt-12 mx-auto"
                    :loading="selectingFileFlag"
                    @click="uploadFile"
                    @drop.prevent="dragAndDropFileChanged"
                    @dragover.prevent
                    ><v-icon color="white" class="mr-1" v-if="!isMobile"
                      >mdi-upload</v-icon
                    ><span style="margin-top:2px;"
                      >Upload your file</span
                    ></v-btn
                  >
                  <input
                    id="uploader"
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept=".csv"
                    @change="fileChanged"
                    @blur="fileChanged"
                  />
                  <p class="mt-2">{{ fileName }}</p>
                </div>
              </div>
            </div>
            <div
              v-else-if="slideKey === keys.submittingValidation"
              :key="keys.submittingValidation"
              class="full-width text-left"
            >
              <div>
                <p class="text-h6 font-weight-bold mb-5 mt-12">
                  Checking your CSV for errors...
                </p>
                <div class="d-flex flex-column align-center"></div>
              </div>
            </div>
            <div
              v-else-if="slideKey === keys.review"
              :key="keys.review"
              class="full-width text-left"
            >
              <div>
                <p class="text-h6 font-weight-bold mb-5 mt-12">
                  Does this look right?
                </p>
                <div class="d-flex justify-space-between align-center">
                  <p class="mb-0">
                    Payments for
                    <span class="brandCyan--text font-weight-bold">{{
                      fileResults.length
                    }}</span>
                    {{ fileResults.length == 1 ? "person" : "people" }}
                  </p>
                  <v-chip
                    @click="displayFileResultDrawer = true"
                    :color="fileErrors.length ? 'error' : 'grey'"
                    class="white--text font-weight-bold px-4"
                    width="130"
                    >{{
                      fileErrors.length
                        ? `FIX ${fileErrors.length} ${
                            fileErrors.length == 1 ? "ERROR" : "ERRORS"
                          }`
                        : "NO ERRORS"
                    }}</v-chip
                  >
                </div>
                <p class="mb-1">
                  Total payment of
                  <span class="font-weight-bold ml-1">
                    {{ selectedBudget && selectedBudget.awardId == 1 ? "$" : ""
                    }}{{ formatCurrency(totalBeingSpent) }}</span
                  >
                </p>
                <p class="mb-2">
                  Budget balance of
                  <span
                    class="font-weight-bold ml-1"
                    :class="{
                      'error--text': amountRemainingInBudget < 0
                    }"
                  >
                    {{ selectedBudget && selectedBudget.awardId == 1 ? "$" : ""
                    }}{{
                      selectedBudget && selectedBudget.budgetBalance
                        ? formatCurrency(selectedBudget.budgetBalance)
                        : 0
                    }}</span
                  >
                </p>
                <p class="darkGrey--text text-sm">
                  Note: Payout files don't support external users at this time
                </p>
                <p class="text-caption mb-0 grey--text mt-3">Payment memo</p>
                <v-textarea
                  outlined
                  v-model="memo"
                  :rules="[
                    v =>
                      !!(!v || (v && v.length < 255)) ||
                      'Your note should be shorter'
                  ]"
                ></v-textarea>
                <p class="text-caption mb-0 grey--text mt-3 mb-2">
                  Payment details
                </p>
                <div
                  class="d-flex justify-space-between full-width mb-2"
                  v-if="selectedBudget"
                >
                  <span>Payment source:</span>
                  <span class="text-right font-weight-bold">{{
                    selectedBudget.displayName
                  }}</span>
                </div>
                <div
                  class="d-flex justify-space-between full-width mb-5"
                  v-if="selectedCategoryIndex != null"
                >
                  <span>Payment destination:</span>
                  <span class="text-right font-weight-bold">{{
                    categoryOptions[selectedCategoryIndex].label
                  }}</span>
                </div>
              </div>
            </div>
            <div
              v-else-if="slideKey == keys.submitting"
              :key="keys.submitting"
              class="full-width text-left"
            >
              <div>
                <p class="text-h6 font-weight-bold mb-5 mt-12">
                  Processing payments...
                </p>
                <div class="d-flex flex-column align-center"></div>
              </div>
            </div>
            <div
              v-else-if="slideKey == keys.sent"
              :key="keys.sent"
              class="full-width text-left pt-12"
            >
              <p class="text-h6 word-break font-weight-bold mb-3">
                You just made someone's day better!
              </p>
              <!-- <p class="full-width">
                Your payments are being processed. You will be notified once
                completed.
              </p> -->
              <div class="d-flex justify-center mt-6">
                <Robin
                  :height="120"
                  :width="140"
                  class=""
                  :loop="false"
                  animation="backflip"
                />
              </div>
              <div class="d-flex justify-center mt-12 mb-5">
                <v-btn
                  depressed
                  rounded
                  color="brandCyan"
                  class="white--text"
                  @click="reset"
                  width="95"
                  ><span>Close</span></v-btn
                >
              </div>
            </div>
            <div
              v-else-if="slideKey == keys.error"
              :key="keys.error"
              class="full-width text-left pt-12"
            >
              <p class="text-h6 word-break font-weight-bold mb-3">
                We had some trouble sending your payment. <br />Please check
                your CSV and try again.
              </p>
              <p class="full-width">
                If this problem continues, feel free to reach out to customer
                service via one of the following methods...
              </p>
              <p class="pl-4 full-width">
                Email -
                <b
                  ><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b
                >
              </p>
              <p class="pl-4 full-width">
                Phone (Toll Free) - <b>(855) 264-3329</b>
              </p>

              <div class="d-flex justify-center my-5">
                <v-btn
                  depressed
                  rounded
                  color="brandCyan"
                  class="white--text"
                  @click="slideKey = keys.csv"
                  ><v-icon class="mr-1">mdi-chevron-left</v-icon
                  ><span style="margin-top:1px;">Go back</span></v-btn
                >
              </div>
            </div>
          </transition>
        </div>
        <div
          v-if="slideKey < keys.review"
          class="mt-2 d-flex flex-column align-center"
        >
          <p v-if="slideKey == keys.csv" class="font-weight-bold text-h6">
            Do you need a template?
          </p>
          <v-btn
            text
            color="brandCyan"
            class="text-none"
            @click="downloadPayoutTemplate"
            :loading="loadingPayoutTemplate"
            ><v-icon class="mr-2">mdi-download</v-icon>Download payout
            template</v-btn
          >
        </div>
        <div
          v-else-if="slideKey == keys.review"
          class="mt-2 d-flex flex-column align-center"
        >
          <v-btn
            rounded
            depressed
            color="brandCyan"
            class="white--text"
            @click="submitPayment"
            :disabled="disableContinueButton"
          >
            Send payment</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <!-- Error dialog -->
    <v-dialog v-model="dialogError" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-center align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break text-left exit-warning-text">
            There was an error parsing your file
          </v-card-title>
        </div>

        <p>{{ errorText }}</p>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            @click="(slideKey = keys.csv), (dialogError = false)"
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay v-if="displayCategoryDrawer || displayFileResultDrawer" />
    <v-navigation-drawer
      v-if="displayCategoryDrawer"
      v-model="displayCategoryDrawer"
      temporary
      fixed
      right
      width="500"
    >
      <v-toolbar dark color="#349E03" rounded="0" class="mb-15">
        <v-toolbar-title>Select a payment category</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="displayCategoryDrawer = false">
            Close
            <v-icon class="ml-2">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div
        class="mx-auto mt-2 d-flex align-center category-container"
        :style="{ 'max-height': '40px', width: '80%' }"
        v-for="(option, index) in categoryOptions"
        :key="option.label"
        @click="selectOneTimeCategory(index)"
        @mouseover="previewCategoryDescription(index)"
        @mouseleave="resetCategoryDescriptionPreview"
      >
        <v-icon
          class="side-nav-icons mr-2"
          color="white"
          :style="{ 'background-color': '#349E03' }"
          >{{ option.icon }}</v-icon
        >
        <div
          class="d-flex justify-start align-baseline category-div font-weight-bold brand--text"
          :class="
            selectedCategoryIndex == index ? 'selected-category-background' : ''
          "
        >
          <p class="ml-2 my-auto">{{ option.label }}</p>
        </div>
      </div>
      <p
        class="mb-2 mt-6 text-body-2 text-left grey--text"
        :style="{ 'margin-left': '50px' }"
      >
        Category description and restrictions
      </p>
      <v-divider
        :style="{ 'margin-left': '50px', 'margin-right': '50px' }"
      ></v-divider>
      <p class="mt-2 mx-auto text-body-2" :style="{ width: '80%' }">
        {{ computedOneTimeCategoryDescription }}
      </p>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="displayFileResultDrawer"
      v-if="displayFileResultDrawer"
      temporary
      fixed
      right
      width="600"
    >
      <v-toolbar
        dark
        color="#349E03"
        rounded="0"
        v-if="displayFileResultDrawer"
      >
        <v-btn
          dark
          icon
          large
          @click="displayFileResultDrawer = false"
          class="ml-1"
        >
          <v-icon>{{
            fileErrors.length == 0
              ? "mdi-checkbox-marked-circle"
              : "mdi-close-circle"
          }}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title class="font-weight-bold"
          >Payout file details</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn dark icon large @click="reset" class="ml-1 hidden">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="text-left mx-4 mt-10 mb-6" v-if="displayFileResultDrawer">
        <p class="ml-8 mb-0">{{ fileName }}</p>
        <div class="d-flex justify-space-between ml-8 mr-4">
          <p class="font-weight-bold">
            {{ fileResults.length }} records
            <span :class="{ 'error--text': fileErrors.length > 0 }"
              >with {{ fileErrors.length }}
              {{ fileErrors.length == 1 ? "error" : "errors" }}</span
            >
          </p>
          <p class="font-weight-bold">
            {{ selectedBudget && selectedBudget.awardId == 1 ? "$" : ""
            }}{{ formatCurrency(totalBeingSpent) }}
          </p>
        </div>
        <div class="d-flex justify-space-between ml-8 mr-4">
          <p class="font-weight-bold">
            Budget balance
          </p>
          <p
            class="font-weight-bold"
            :class="{
              'error--text': amountRemainingInBudget < 0
            }"
          >
            {{ selectedBudget && selectedBudget.awardId == 1 ? "$" : ""
            }}{{
              selectedBudget && selectedBudget.budgetBalance
                ? formatCurrency(selectedBudget.budgetBalance)
                : 0
            }}
          </p>
        </div>

        <div class="d-flex justify-space-between mr-4 ml-8">
          <div class="name-field">Name</div>
          <div class="email-field">Email</div>
          <div class="amount-field text-right">
            {{ $vuetify.breakpoint.xs ? "Amt" : "Payment" }}
          </div>
        </div>
        <v-divider class="mx-2" />
        <div
          class="mr-4 mt-2 d-flex align-center"
          :style="{ 'max-height': '40px' }"
          v-for="(row, index) in fileResults"
          :key="index"
        >
          <v-tooltip top :disabled="!row.emailError && !row.amountError">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                color="error"
                :class="{ hidden: !(row.emailError || row.amountError) }"
                class="mr-2"
                @click="
                  if (row.amountError) {
                    editingResultIndex = index;
                    editingResultType = 'amount';
                  } else if (row.emailError) {
                    editingResultIndex = index;
                    editingResultType = 'email';
                  }
                "
                >mdi-alert</v-icon
              >
            </template>
            <span>{{
              row.emailError
                ? "The user could not be found"
                : "The amount should be a positive number"
            }}</span>
          </v-tooltip>
          <div class="d-flex align-center justify-space-between full-width">
            <span
              class="name-field"
              :class="{
                'error--text': row.emailError
              }"
              >{{ row.lastName }}{{ row.lastName && row.firstName ? ", " : "-"
              }}{{ row.firstName }}</span
            >
            <div class="email-field d-flex align-center ">
              <div
                v-if="
                  editingResultIndex == index && editingResultType == 'email'
                "
                class="d-flex align-center"
              >
                <v-text-field v-model="row.businessEmail" hide-details dense />
                <v-progress-circular
                  v-if="editingResultAwait.includes(index)"
                  size="20"
                  width="3"
                  indeterminate
                  class="ml-1 mt-1"
                />
                <v-icon
                  small
                  v-else
                  class="mt-1"
                  @click="saveEditedEmail(row, index)"
                  >mdi-content-save</v-icon
                >
              </div>
              <div v-else>
                <v-hover v-slot="{ hover }">
                  <span
                    class="cursor-pointer"
                    @click="
                      editingResultIndex = index;
                      editingResultType = 'email';
                    "
                    :class="{
                      'error--text': row.emailError
                    }"
                  >
                    <v-icon
                      v-if="hover"
                      small
                      @click="
                        editingResultIndex = index;
                        editingResultType = 'email';
                      "
                      >mdi-pencil</v-icon
                    >
                    {{ row.businessEmail || "-" }}
                  </span>
                </v-hover>
              </div>
            </div>
            <div
              class="amount-field text-right d-flex align-center justify-end"
            >
              <div
                v-if="
                  editingResultIndex == index && editingResultType == 'amount'
                "
                class="d-flex align-center"
              >
                <v-text-field
                  v-model="row.amount"
                  hide-details
                  dense
                  type="number"
                  @wheel="$event.target.blur()"
                />
                <v-icon small class="mt-1" @click="saveEditedAmount(row, index)"
                  >mdi-content-save</v-icon
                >
              </div>
              <div v-else>
                <v-hover v-slot="{ hover }">
                  <span
                    class="cursor-pointer"
                    @click="
                      editingResultIndex = index;
                      editingResultType = 'amount';
                    "
                    :class="{
                      'error--text':
                        !row.amount || row.amount <= 0 || row.amountError
                    }"
                    >{{
                      !row.amountError &&
                      selectedBudget &&
                      selectedBudget.awardId == 1
                        ? "$"
                        : ""
                    }}{{ row.amount }}
                    <v-icon
                      v-if="hover"
                      small
                      @click="
                        editingResultIndex = index;
                        editingResultType = 'amount';
                      "
                      >mdi-pencil</v-icon
                    ></span
                  >
                </v-hover>
              </div>
            </div>
          </div>
          <!-- <v-icon
            class="side-nav-icons mr-2"
            color="white"
            :style="{ 'background-color': '#349E03' }"
            >{{ option.icon }}</v-icon
          >
          <div
            class="d-flex justify-start align-baseline category-div font-weight-bold brand--text"
            :class="
              slideOneTimeAmount.selectedCategoryIndex == index
                ? 'selected-category-background'
                : ''
            "
          >
            <p class="ml-2 my-auto">{{ option.label }}</p>
          </div> -->
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import WalletService from "@/services/WalletService";
import TriggerService from "@/services/TriggerService";
import UserService from "@/services/UserService";

import { truncateNumber } from "@/shared_data/functions";

import Robin from "@/components/Robin";

import currency from "currency.js";
import { mapState } from "vuex";

function initialState() {
  return {
    slideKey: 20,
    dialogConfirmation: false,
    dialogError: false,
    errorText: null,
    slideDirection: "topic-left",
    keys: {
      budget: 20,
      memo: 21,
      csv: 22,
      submittingValidation: 23,
      review: 24,
      submitting: 25,
      // send: 101,
      sent: 102,
      error: 103
    },
    progressBarValue: 0,
    budgetPlatformActivityCounter: 0,
    budgetId: null,
    selectedCategoryIndex: 0,
    displayCategoryDrawer: false,
    categoryOptions: [
      {
        // Warning: If you change this label, you need to change the reference in the template. It relies on this label to know to render the expiration toggle
        label: "General expenses",
        icon: "mdi-wallet",
        description:
          "These funds belong to the recipient and they can spend them however they see fit. This should be used when sending bonuses.",
        mccDescription: null,
        mccGroupDefinitionId: null
      },
      {
        label: "Meal",
        icon: "mdi-food-outline",
        description:
          "Meal funds are intended to be used for buying a meal at a restaurant, fast food, or grocery store, and are restricted to these kinds of transactions.",
        mccDescription: "Meals",
        mccGroupDefinitionId: 1
      },
      {
        label: "Coffee",
        icon: "mdi-coffee-outline",
        description:
          "Coffee funds are intended to be used for buying a coffee or snack at a restaurant, fast food, or grocery store, and are restricted to these kinds of transactions.",
        mccDescription: "Meals",
        mccGroupDefinitionId: 1
      },
      {
        label: "Office supplies",
        icon: "mdi-paperclip",
        description:
          "These funds are intended to be used to buy office supplies.",
        mccDescription: "Office supplies",
        mccGroupDefinitionId: 4
      },
      {
        label: "Wellness",
        icon: "mdi-run",
        description:
          "These funds are intended to be used by the employee to help them live a healthier life, whether that be by paying for a gym membership or mental health services.",
        mccDescription: null,
        mccGroupDefinitionId: null
      },
      {
        label: "Culture",
        icon: "mdi-emoticon-happy-outline",
        description:
          "Culture funds are to be used at the employees discretion on anything benefitting the team or organization.",
        mccDescription: null,
        mccGroupDefinitionId: null
      },
      {
        label: "Travel",
        icon: "mdi-train-car",
        description:
          "Travel funds can only be used for transportation (including air, car services or rental, public transportation), hotels and lodging, gas stations and restaurants.",
        mccDescription: "Travel",
        mccGroupDefinitionId: 2
      },
      {
        label: "Philanthropy",
        icon: "mdi-charity",
        description:
          "These funds can be used to democratize corporate giving, by enabling employees to make charitable donations.",
        mccDescription: null,
        mccGroupDefinitionId: null
      },
      {
        label: "Business expense",
        icon: "mdi-briefcase-outline",
        description:
          "This category of funds is for general business expenses and is treated like a corporate credit limit.",
        mccDescription: null,
        mccGroupDefinitionId: null
      }
    ],
    categoryDescriptionPreview: null,
    navigationItemWidth: 200,
    loadingPayoutTemplate: false,
    memo: null,
    selectingFileFlag: false,
    fileData: null,
    fileName: null,
    file: null,
    validatingFile: false,
    processingFile: false,
    fileResults: [],
    displayFileResultDrawer: false,
    editingResultIndex: null,
    editingResultType: null,
    editingResultAwait: []
  };
}

export default {
  name: "PaymentCSVUpload",
  components: {
    Robin
  },
  props: {
    budgets: Array,
    loadingBudgets: Boolean
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created payment wizard");
    if (this.$auth) {
      // this.getUsers();
      // this.clientId = this.userProfile.clientId;

      this.preloadBudget();
    }

    this.createPlatformActivity("OPENED_PAYMENTS_CSV");
    window.addEventListener("resize", this.refreshScreenSize);
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
    this.refreshScreenSize();
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }

    window.removeEventListener("resize", this.refreshScreenSize);
  },
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return currency(amount, { separator: ",", symbol: "" }).format();
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    reset() {
      this.resetAllData();
      this.$emit("reset");
      this.$emit("get-budgets");
    },
    getBudgets() {
      this.$emit("get-budgets");
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    refreshScreenSize() {
      setTimeout(() => {
        if (document.getElementsByClassName("pill-nav").length > 0) {
          this.navigationItemWidth = document.getElementsByClassName(
            "pill-nav"
          )[0].offsetWidth;
        } else {
          this.navigationItemWidth = 200;
        }
        console.log("Nav width ", this.navigationItemWidth);
      }, 30);
    },
    goToSlide(index) {
      // Figures out what step we're going into so we can insert the proper platform activity
      var platformActivityEvent = null;
      // if (this.slideKey == this.keys.people)
      //   platformActivityEvent = "SELECTED_PAYMENT_RECIPIENTS";
      // else if (this.slideKey == this.keys.details) {
      //   platformActivityEvent = "ADDED_PAYMENT_MEMO";
      // } else if (this.slideKey == this.keys.amount)
      //   platformActivityEvent = "SELECTED_PAYMENT_AMOUNT";
      // else if (this.slideKey == this.keys.expiration)
      // platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";
      // this.disableContinueButton
      // We have to block if the user has already sent payment
      if (
        this.slideKey >= 1 &&
        this.slideKey < this.keys.submitting &&
        !this.validatingFile
      ) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();

      if (this.slideKey == this.keys.csv) {
        this.validateCSV();
      } else if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.submitPayment();
      } else if (this.slideKey == this.keys.sent) {
        this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        // if (this.slideKey == this.keys.people) {
        //   platformActivityEvent = "SELECTED_PAYMENT_RECIPIENTS";
        // } else if (this.slideKey == this.keys.details)
        //   platformActivityEvent = "ADDED_PAYMENT_MEMO";
        // else if (this.slideKey == this.keys.amount)
        //   platformActivityEvent = "SELECTED_PAYMENT_AMOUNT";
        // else if (this.slideKey == this.keys.expiration)
        //   platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // if (
        //   this.route[routeIndex + 1] == this.keys.mccCustom &&
        //   this.slideProvisional.selectedPresets.length > 0
        // ) {
        //   // We can skip custom MCC page
        //   routeIndex++;
        // }
        this.slideKey = this.route[routeIndex + 1];

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      this.slideKey = this.route[routeIndex - counter];
    },
    uploadFile() {
      this.selectingFileFlag = true;
      window.addEventListener(
        "focus",
        () => {
          this.selectingFileFlag = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    fileChanged(e) {
      if (e.target.files.length > 0) {
        console.log(e.target.files[0]);
        this.fileData = e.target.files[0];
        this.fileName = e.target.files[0].name;
        this.file = URL.createObjectURL(e.target.files[0]);
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.selectingFileFlag = false;
      }
    },
    dragAndDropFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        console.log(e.dataTransfer.files[0]);
        // this.$set(
        //   this.content,
        //   "fileData",
        //   URL.createObjectURL(e.dataTransfer.files[0])
        // );
        this.fileData = e.dataTransfer.files[0];
        this.fileName = e.dataTransfer.files[0].name;
        this.file = URL.createObjectURL(e.dataTransfer.files[0]);
        this.selectingFileFlag = false;
        // this.$set(this.newBadge, "imageFile", e.dataTransfer.files[0]);
      }
    },
    validateCSV() {
      this.validatingFile = true;
      this.slideKey = this.keys.submittingValidation;
      var object = {
        file: this.fileData,
        description: this.memo,
        sourceUserId: this.userProfile.userId,
        source: "PAYMENTS_CSV_WIZARD",
        budgetId: this.budgetId
      };

      console.log(object);
      // Display loading screen
      // this.dialogConfirmation = false;
      // this.slideKey = this.keys.send;
      WalletService.validatePayoutFile(object)
        .then(x => {
          console.log(x);
          this.fileResults = x.result.rows;
        })
        .catch(error => {
          console.log("Error validating file! ", error);
          this.dialogError = true;
          this.errorText =
            "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
          if (error.error_code && error.error) {
            if (error.error.includes("Empty file"))
              this.errorText = "The attached file was empty.";
            else if (error.error_code == "1070009")
              this.errorText = "No file was attached.";
            else if (error.error_code == "1070100")
              this.errorText = "The selected budget has insufficient funds.";
          }
        })
        .finally(() => {
          console.log("Routing to review");
          this.validatingFile = false;
          this.slideKey = this.keys.review;
        });
    },
    async submitPayment() {
      // Show loading bar
      console.log("Submitting payment! ", this.keys.submitting);
      this.slideKey = this.keys.submitting;
      this.processingFile = true;
      const progressIncrement = 2;
      const sleepDur = 100;
      this.loadBarSleeper(progressIncrement, sleepDur, false);

      // setTimeout(() => {
      //   this.processingFile = false;
      //   this.loadBarSleeper(progressIncrement, sleepDur, true);
      // }, 12000);

      try {
        // Let's prep the array
        var paymentsArray = this.fileResults
          .filter(x => x.userId)
          .map(x => {
            var amount = currency(x.amount).value;

            return {
              userId: x.userId,
              amount: amount.toString(),
              budgetId: this.budgetId,
              awardId: this.selectedBudget.awardId,
              programId: null,
              ruleGroupId: null,
              clientId: this.clientId
            };
          });

        var object = {
          users: paymentsArray,
          description: this.memo,
          sourceUserId: this.userProfile.userId,
          source: "PAYMENTS_CSV"
        };

        console.log(object);
        // Display loading screen
        this.dialogConfirmation = false;
        var response = await WalletService.sendPaymentBulk(object);
        this.processingFile = false;
        this.loadBarSleeper(progressIncrement, sleepDur, true);
        console.log(response);
        if (typeof response == "string") {
          if (response.startsWith("The sum of all payouts")) {
            this.dialogError = true;
            this.errorText =
              "The total payment sum is more than the selected budget's balance.";
          }
        } else if (response.error) {
          this.slideKey = this.keys.error;
        } else {
          // Display success screen
          this.slideKey = this.keys.sent;
        }
        // })
      } catch (error) {
        console.log("Error sending payment! ", error);
        this.dialogError = true;
        this.errorText =
          "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      }
    },
    loadBarSleeper(incr, dur, override = false) {
      setTimeout(() => {
        console.log("Incrementing progress", this.progressBarValue);
        if (!this.processingFile || override) {
          // We just want to skip to the end
          this.progressBarValue = 100;
        } else {
          this.progressBarValue += incr;
          if (this.progressBarValue < 90) this.loadBarSleeper(incr, dur);
        }
      }, dur);
    },
    refreshClientId(clientId) {
      this.clientId = clientId;

      this.slideKey = this.keys.budget;
      this.budgetId = null;
      this.fileResults = [];
      this.fileData = null;
      this.file = null;
      this.fileName = null;
      this.selectingFileFlag = false;

      this.preloadBudget();
    },
    preloadBudget() {
      // Preload the master budget
      let clientBudgets = this.budgets.filter(
        x =>
          x.clientId == this.clientId &&
          x.awardId == 1 &&
          x.status == "Active" &&
          (Number(x.allocatedBalance) > 0 || Number(x.budgetBalance) > 0)
      );
      var acctBalance = clientBudgets.find(x => x.masterBudget);
      var budgetWithBalance = clientBudgets.find(
        x => Number(x.budgetBalance) > 0
      );
      if (clientBudgets.length == 1 && !this.budgetId)
        this.budgetId = clientBudgets[0].budgetId;
      else if (
        acctBalance &&
        !this.budgetId &&
        Number(acctBalance.budgetBalance) > 0
      ) {
        //  console.log("Found acct balance ", acctBalance);
        this.budgetId = acctBalance.budgetId;
      } else if (budgetWithBalance && !this.budgetId)
        this.budgetId = budgetWithBalance.budgetId;
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "PAYMENTS_CSV_WIZARD",
        category: "PAYMENTS",
        date: new Date()
      };
      TriggerService.createActivity(obj)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    sendBudgetIdTrigger() {
      // We want to fire off a platform activity for when they update the payment memo, but this can be done on any screen.
      // So this gets called when the payment memo field loses focus
      // We also want to make sure this only gets sent once.
      if (this.budgetId && this.budgetPlatformActivityCounter == 0) {
        this.createPlatformActivity("SELECTED_PAYMENT_BUDGET");
        this.budgetPlatformActivityCounter++;
      }
    },
    selectOneTimeCategory(index) {
      console.log(index);
      this.selectedCategoryIndex = index;
    },
    previewCategoryDescription(index) {
      this.categoryDescriptionPreview = this.categoryOptions[index].description;
    },
    resetCategoryDescriptionPreview() {
      this.categoryDescriptionPreview = null;
    },
    async downloadPayoutTemplate() {
      this.loadingPayoutTemplate = true;
      await WalletService.downloadTemplate();
      this.loadingPayoutTemplate = false;
    },
    saveEditedAmount(row) {
      this.editingResultIndex = null;
      this.editingResultType = null;
      console.log(row.amount);
      if (!row.amount) row.amount = 0;
      else row.amount = currency(row.amount);

      if (!isNaN(row.amount) && row.amount > 0) row.amountError = false;
      else row.amountError = true;
    },
    async saveEditedEmail(row, index) {
      // We must check if the new email exists
      console.log(row.businessEmail);
      this.editingResultAwait.push(index);
      setTimeout(async () => {
        let response = await UserService.getUsersV2({
          filter: `status == 'Active' && businessEmail == '${row.businessEmail}' && clientId == ${this.clientId}`,
          limit: 1
        });
        // Filter out any instances of this index
        this.editingResultAwait = this.editingResultAwait.filter(
          x => x != index
        );
        console.log("USER RESPONSE ", response);
        if (
          response &&
          response.result &&
          response.result.rows &&
          response.result.rows.length > 0
        ) {
          // We found a user!
          row.emailError = false;
          row.firstName = response.result.rows[0].firstName;
          row.lastName = response.result.rows[0].lastName;
          row.userId = response.result.rows[0].userId;
        } else {
          row.firstName = null;
          row.lastName = null;
          row.emailError = true;
          row.userId = null;
        }
        // If they've already started editing something else, then we don't touch the index
        if (
          this.editingResultIndex == index &&
          this.editingResultType == "email"
        ) {
          this.editingResultIndex = null;
          this.editingResultType = null;
        }
      }, 2000);
      // if (!row.amount) row.amount = 0;
      // else row.amount = currency(row.amount);

      // if (row.businessEmail)
      // else row.emailError = true;
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    visibleBudgets() {
      // var awardId = null;
      const budgetArr = this.budgets.filter(x => {
        return (
          x.awardId == 1 &&
          x.status == "Active" &&
          x.clientId == this.clientId &&
          (Number(x.allocatedBalance) > 0 || Number(x.budgetBalance) > 0)
        );
      });
      return budgetArr;
    },
    selectedBudget: {
      cache: false,
      get: function() {
        if (this.budgetId !== null) {
          var budget = this.budgets.find(x => x.budgetId == this.budgetId);

          return budget;
        }
        return null;
      }
    },
    totalBeingSpent() {
      var sumSpent = 0;

      this.fileResults.forEach(row => {
        if (row.amount) {
          sumSpent = currency(row.amount).add(sumSpent);
        }
      });

      console.log(sumSpent);

      return currency(sumSpent).value;
    },
    amountRemainingInBudget() {
      if (this.selectedBudget && this.selectedBudget.budgetBalance) {
        // const parts = parseFloat(this.selectedBudget.budgetBalance)
        //   .toString()
        //   .split(".");
        // var balance = 0;
        // if (parts.length === 2) {
        //   balance = Number([parts[0], parts[1].slice(0, 2)].join("."));
        // } else {
        //   balance = Number(parts[0]);
        // }
        let balance = currency(this.selectedBudget.budgetBalance);
        // Let's truncate the floating num after 2 decimal places
        return balance.subtract(this.totalBeingSpent).value; //truncateNumber(balance - this.totalBeingSpent, 2);

        // console.log(this.selectedBudget);
        // return 100;
      } else {
        return 0;
      }
    },
    computedAmountRules() {
      var rules = [
        v =>
          !!(v && parseFloat(v) > 0) || "Payment amount must be greater than 0"
      ];
      // if (this.slideDetails.paymentType !== "NON_CASH") {
      rules.push(
        v =>
          !!(
            v &&
            (!v.toString().includes(".") ||
              (v.toString().includes(".") &&
                v.substring(v.indexOf(".") + 1).length < 3))
          ) || `Payment amount shouldn't be longer than 2 decimals.`
      );

      return rules;
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        return [
          this.keys.budget,
          this.keys.memo,
          this.keys.csv,
          this.keys.submittingValidation,
          this.keys.review,
          this.keys.submitting,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (
            page == this.keys.budget &&
            this.budgetId &&
            this.selectedBudget &&
            this.selectedBudget.budgetBalance > 0 &&
            this.selectedCategoryIndex != null
          ) {
            array.push(true);
          } else if (page == this.keys.memo) {
            array.push(true);
          } else if (page == this.keys.csv && this.file) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            this.fileErrors.length == 0 &&
            this.amountRemainingInBudget >= 0
          ) {
            array.push(true);
          } else if (page == this.keys.submitting) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    computedNote() {
      if (!this.memo) return null;
      let arr = [];
      if (this.memo) {
        this.memo
          .split("\n")
          .forEach(item => arr.push(`<p>${item.trim()}</p>`));
      }
      return arr.join("");
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    computedOneTimeCategoryDescription: {
      cache: false,
      get: function() {
        if (this.categoryDescriptionPreview == null) {
          return this.categoryOptions[this.selectedCategoryIndex].description;
        } else {
          return this.categoryDescriptionPreview;
        }
      }
    },
    computedOneTimeCategory() {
      return this.categoryOptions[this.selectedCategoryIndex];
    },
    fileErrors() {
      return this.fileResults.filter(x => x.amountError || x.emailError);
    }
  },
  watch: {
    budgets: function(newVal, oldVal) {
      if (oldVal.length == 0) {
        console.log("Got budget watcher", oldVal);

        this.preloadBudget();
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.error-background {
  background-color: var(--v-error-base);
}

.header-text {
  font-size: x-large;
}

.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

.people-group-button {
  padding: 5px 5px;
  border-radius: 7px;
  width: 200px;
  background-color: var(--v-lightGrey-base);
}
.audience-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 105px;
  width: 105px;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mini-icon {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
}

/* Adds grey background to search box */
.search-field >>> .v-input__slot {
  /* border-style: none !important;
  border-radius: 2px !important; */
  background: var(--v-lightGrey-base) !important;
}

.amount-text-field >>> .v-input__slot {
  max-width: 125px;
  width: 125px;
}

.amount-text-field >>> .v-text-field__details {
  padding-left: 4px;
}

.amount-table {
  padding-top: 1px;
  border: solid 1px;
  border-color: lightgray;
  border-radius: 4px;
}

.amount-table >>> .v-data-table-header {
  background-color: white;
}
.side-nav-bold-text {
  color: var(--v-brand-base);
  font-weight: bold;
}
.side-nav-text {
  color: var(--v-brand-base);
}
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
.side-nav-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding-top: 7px;
}
.side-nav-panel {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding-top: 7px;
}
.budget-select >>> .v-list-item {
  font-size: 0.8125rem;
}
.category-div {
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
}
.selected-category {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 5px;
  height: 45px;
  cursor: pointer;
}
.category-container {
  cursor: pointer;
}
.category-container:hover .category-div {
  background-color: #e8e8ec;
}
.selected-category-background {
  background-color: #e8e8ec;
}
.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  /* color: rgba(0, 0, 0, 0.38); */
}
.rounded-border {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.selected-people-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.input-error {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  border: 2px solid var(--v-error-base) !important;
}

#note {
  background: var(--v-lightGrey-base);
  padding: 10px 10px;
  margin-left: 24px;
  width: calc(100% - 24px);
  border-radius: 6px;
}

/* outline used for the main content card */
.main-card {
  border: solid 1px;
  border-color: lightgray;
  min-height: calc(100vh - 120px);
}

/* Used for the pill nav at the top of the main card */
.pill-nav {
  /* background: var(--v-grey-base); */
  /* background: var(--v-brandDarkGreen-base); */
  /* opacity: 0.3; */
  width: calc(100% - 20px);
  min-height: 40px;
  max-height: 40px;
  border-radius: 999px;
  /* padding: 0 20px 0 20px; */
}
.pill-nav >>> .pill-item {
  border-radius: 999px;
  /* min-width: 60px; */
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1 !important;
}
.pill-nav >>> .active-pill {
  /* border-radius: 999px; */
  /* background: var(--v-brandGreen-base); */
}

.budget-name-container {
  width: calc(100% - 120px);
}

.name-field {
  width: calc(((100% - 28px) / 12) * 4);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.email-field {
  width: calc(((100% - 28px) / 12) * 6);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.amount-field {
  width: calc(((100% - 28px) / 12) * 2);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.progress-bar {
  width: 70%;
  min-width: 220px;
  max-width: 600px;
}

/* progress bar text */
.progress-bar >>> .v-progress-linear__content {
  justify-content: flex-start;
  margin-left: 15px;
}

.progress-bar >>> .v-progress-linear__determinate {
  border-radius: 0 9999px 9999px 0;
}

.progress-bar >>> .v-progress-linear__background {
  left: 0 !important;
  width: 100% !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAnalytics from "vue-analytics";
import store from "./store";
import titleMixin from "./mixins/titleMixin";

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the  Auth0 plugin here
import { Auth0Plugin } from "./auth";

import vuetify from "./plugins/vuetify";

//Imports component for telephone numbers
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
// import VueAuthImage from "vue-auth-image";

// Used for mobile detection to link to app store
import VueMobileDetection from "vue-mobile-detection";

// Loading screen for window.open
// const open = window.open.bind(window);
// window.open = function (url, name, features) {
//   /**
//    * @type {Window}
//    */
//   const w = open('about:blank', name, features);
//   w.document.body.innerHTML = `<div style='
//   background: linear-gradient(45deg,#a1d76e 1%,#0aaceb),#fff;
//   background-size: 100% 100%,100%;
//   background-repeat: no-repeat;
//   background-position: bottom,top;
//           color: white;
//           font-family: Avenir,Helvetica,Arial,sans-serif;
//           width: 100vw;
//           height: 100vh;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//         '><strong style='
//           flex: 0 0 120px;'><em>Loading...</em></strong></div>`
//   w.document.body.style.margin = '0'
//   w.location = url;

//   return w;
// }
console.log(process.env.VUE_APP_GOOGLE_ANALYTICS + " analytics");

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  router
});

//used for google analytics and event tagging
import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_TAG_MANAGER
    }
  },
  router
);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  store,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(VueTelInputVuetify, {
  vuetify
});

import VideoPlayer from "vue-video-player";
require("./css/video-js.css");
require("vue-video-player/src/custom-theme.css");
// import VideoPlayer from "@videojs-player/vue";
// import "video.js/dist/video-js.css";
// const hls = require("videojs-contrib-hls");
// Vue.use(hls);
Vue.use(VideoPlayer);

Vue.use(VueMobileDetection);

// Vue.use(VueAuthImage);

Vue.mixin(titleMixin);
Vue.config.productionTip = false;

if (process.env.VUE_APP_ENVIRONMENT == "prod") Vue.config.devtools = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

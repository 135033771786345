<template>
  <div class="my-2 ml-3 mr-2">
    <v-card flat class="my-2 ml-3 mr-2">
      <v-row>
        <v-col sm="12" md="3">
          <h2 class="align-text-left mb-3">Preset Manager</h2>
          <v-divider></v-divider>
          <div class="align-text-left mt-3">
            <p>
              Create & manage the presets you can use in the payment wizard and
              recognition wizard.
            </p>
            <p>Drag any items to re-order how they appear in the wizards.</p>
            <ClientDropdown
              v-if="userProfile && userProfile.clientId == 1"
              @change="getPaymentPresets"
              class="mb-3"
            ></ClientDropdown>
            <div class="d-flex justify-center pb-2">
              <v-btn
                color="brandCyan"
                depressed
                class="white--text "
                @click="createPreset"
                >Create Preset</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <!-- <v-tabs v-model="tab" color="brandCyan" centered>
            <v-tab :value="1">Payment Wizard</v-tab>
            <v-tab :value="2">Recognition Wizard</v-tab>
          </v-tabs> -->
          <!-- v-model="selected"
            :headers="columns"
            :items="hierarchies"
            item-key="hierarchyId"
            show-select
            :items-per-page="10"
            class="elevation-0 row-pointer hierarchy-table"
            :loading="loadingHierarchyTable"
            loading-text="Loading... Please wait"
            :search="tableSearch"
            @click:row="editHierarchy" -->
          <!-- <div class="d-flex justify-start pb-2">
            <v-btn
              color="brandCyan"
              depressed
              class="white--text"
              @click="createPreset"
              >Create Preset</v-btn
            >
          </div> -->
          <div v-if="loading.presets">
            <v-progress-circular
              color="brandCyan"
              indeterminate
              :size="40"
              :width="3"
            ></v-progress-circular>
          </div>
          <div v-else-if="!presets || !presets.length">
            <p>No presets found</p>
          </div>
          <div v-else class=" py-2 px-4">
            <draggable
              :list="presets"
              :group="`presets`"
              handle=".preset-selector-box"
              @change="updatePresetOrder"
            >
              <!-- handle=".answer-handle" -->

              <v-card
                v-for="preset in presets"
                :key="preset.paymentPresetId"
                elevation="0"
                width="50%"
                min-width="250"
                max-width="400"
                class="preset-selector-box d-flex justify-space-between align-center clear-fab-button presets"
              >
                <div class="d-flex align-center">
                  <!-- <div class="d-flex  align-center pa-2"> -->
                  <span class="preset-selector-emoji ">{{ preset.emoji }}</span>
                  <div class="d-flex flex-column text-left">
                    <h3 class="font-weight-bold mb-0">
                      {{ preset.displayName }}
                    </h3>
                    <span class="">{{ formatCurrency(preset.amount) }}</span>
                  </div>
                </div>
                <v-btn icon color="brandCyan" @click="editPreset(preset)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <!-- </div> -->
                <!-- <v-divider color="darkGrey" v-if="index != presets.length - 1" /> -->
                <v-btn
                  fab
                  color="grey"
                  x-small
                  dark
                  class="elevation-0 clear-fab"
                  @click.stop="disablePreset(preset)"
                  ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                >
              </v-card>

              <!-- <v-btn icon color="red" @click="disablePreset(preset)"
                  ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                > -->
            </draggable>
          </div>
          <!-- <v-data-table
            :loading="loading.presets"
            :key="state"
            :items="presets"
            :headers="columns"
            no-data-text="No presets found"
            class="table"
          >
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ formatCurrency(item.amount) }}</span>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn icon color="brandCyan" @click="editPreset(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
          </v-data-table> -->
        </v-col>
      </v-row>
    </v-card>

    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
      :displayRobin="false"
    ></LoadingDialog>
    <v-navigation-drawer
      v-model="display.creator"
      temporary
      fixed
      right
      width="500"
    >
      <PaymentPresetCreator
        v-if="display.creator"
        @close="display.creator = false"
        @refreshPresets="getPaymentPresets"
        :editingPreset="editingPreset"
        :editPresetObject="editPresetObject"
    /></v-navigation-drawer>
  </div>
</template>

<script>
import WalletService from "@/services/WalletService";

import LoadingDialog from "@/components/loadingDialog.vue";
import ClientDropdown from "@/components/ClientDropdown.vue";
import PaymentPresetCreator from "@/components/account-management/PaymentPresetCreator.vue";

import currency from "currency.js";
import draggable from "vuedraggable";
import { mapState } from "vuex";
export default {
  name: "PaymentPresetManager",
  props: {},
  components: {
    LoadingDialog,
    draggable,
    PaymentPresetCreator,
    ClientDropdown
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loading: {
        presets: false,
        creating: false
      },
      display: {
        creator: false
      },
      presets: [],
      editingPreset: false,
      editPresetObject: null,
      state: 0,

      columns: [
        {
          text: "Emoji",
          align: "start",
          // sortable: true,
          value: "emoji"
          // width: "70%",
        },
        {
          text: "Amount",
          align: "start",
          value: "amount"
          // width: "70%",
        },

        { text: "Edit", value: "edit", width: "7%", sortable: false }
        // { text: "Remove", value: "remove", width: "7%", sortable: false }

        // {
        //   text: "Status",
        //   value: "statusInitial",
        //   align: "center",
        //   width: "12%",
        // },
        // {
        //   text: "Quantity",
        //   value: "HierarchyRelationships.length",
        //   width: "15%",
        // },
      ]
    };
  },
  created() {
    this.getPaymentPresets();
  },
  beforeDestroy() {},
  methods: {
    formatCurrency(amt) {
      return currency(amt).format();
    },
    async getPaymentPresets() {
      try {
        this.loading.presets = true;

        let results = await WalletService.getPaymentPresetsV2(
          {
            screen: "account-management",
            clientId: this.clientId
            // filter: `clientId.isIn([0,${this.clientId}]) && status != 'Deleted' && emoji != null`,
            // limit: 50,
            // expand: "Icon",
            // leftJoin: "Icon"
          },
          this.magicLinkToken
        );
        console.log("Got payment presets ", results.result.rows);
        this.presets = results.result.rows.filter(x => x.status != "Disabled");
      } catch (err) {
        console.log("Error with payment presets ", err);
      } finally {
        this.loading.presets = false;
      }
    },
    createPreset() {
      this.display.creator = true;
      this.editPresetObject = null;
      this.editingPreset = false;
    },
    editPreset(item) {
      console.log("Edit preset ", item);
      this.display.creator = true;
      this.editPresetObject = item;
      // we don't let them edit an existing universal preset
      this.editingPreset = item.clientId != 0;
    },
    async updatePresetOrder() {
      let newRecords = false;
      const presets = this.presets.map((x, i) => {
        if (x.clientId === 0) {
          // We have to duplicate
          newRecords = true;
          return {
            ...x,
            paymentPresetId: undefined,
            clientId: this.clientId,
            priority: this.presets.length - i
          };
        }
        return {
          clientId: x.clientId,
          paymentPresetId: x.paymentPresetId,
          classification: x.classification,
          displayName: x.displayName,
          unitSingular: x.displayName,
          amount: x.amount,
          priority: this.presets.length - i
        };
      });
      console.log("Updating preset order", presets);
      const result = await WalletService.createPaymentPresetV2(presets, {
        updateOnDuplicate: "priority,createdAt,updatedBy"
      });
      console.log({ result });

      if (newRecords) this.getPaymentPresets();
    },
    async disablePreset(item) {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting preset";
      this.loadingMessageLine1 = "Please wait...";
      if (item.clientId == 0) {
        const result = await WalletService.createPaymentPresetV2({
          ...item,
          clientId: this.clientId,
          status: "Disabled",
          paymentPresetId: undefined
        });
      } else {
        const result = await WalletService.updatePaymentPresetV2(
          item.paymentPresetId,
          {
            clientId: item.clientId,
            status: "Disabled"
          }
        );
      }
      this.showLoadingDialog = false;
      this.getPaymentPresets();
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "token", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    }
  }
};
</script>

<style scoped>
.client-dropdown {
  width: 350px;
}
</style>
<style lang="css" scoped>
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.table >>> .v-data-table-header {
  background-color: white;
}

.clear-fab-container {
  position: relative;
}
.clear-fab {
  position: absolute;
  /* top: calc(-4px * 8 + -16px);
  right: calc(-4px * 6 + -16px); */
  top: calc(-4px * 8 + 18px);
  right: calc(-4px * 6 + 8px);
}

.presets {
  margin-left: inherit !important;
  margin-right: inherit !important;
}
</style>

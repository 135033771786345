<template>
  <div class="fill-height">
    <v-card width="100%" rounded="0" flat height="100%">
      <div class="d-flex justify-center pt-10">
        <Robin
          :height="130"
          :width="130"
          :largeText="true"
          :showText="false"
          animation="curious"
          :loop="true"
        ></Robin>
      </div>
      <p class="text-h5 mt-5">
        We had trouble finding the page you're looking for.
      </p>
      <v-btn class="mt-3" width="150" color="primary" depressed @click="goHome"
        >Go Home</v-btn
      >
    </v-card>
  </div>
</template>

<script>
import Robin from "@/components/Robin.vue";
export default {
  name: "PageNotFound",
  title: "Whistle | Page Not Found",
  components: { Robin },
  data() {
    return {};
  },
  created() {},
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
  computed: {},
};
</script>

<style scoped>
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.body {
  height: 100%; /* or any height you want */
  overflow-y: auto;
}
.logo-text {
  color: var(--v-brand-base);
}

.slide-leave-active {
  transition: 0.4s;
}
.slide-enter-active {
  transition: 0.6s;
}
.slide-enter {
  transform: translate(200%, 0);
}
.slide-leave-to {
  transform: translate(-200%, 0);
}

.show-enter-active,
.show-leave-enter {
  transform: translateX(0);
  transition: all 0.5s linear;
}
.show-enter,
.show-leave-to {
  transform: translateX(100%);
}

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.blue-btn {
  background-color: var(--v-accent-base);
}
.grey-btn {
  background-color: var(--v-lightGrey-base);
}
.header-text {
  font-size: x-large;
}
.footer-text {
  font-size: small;
}
.form {
  width: 75%;
}
.slider {
  width: 90%;
}

.bottom-logo {
  position: absolute;
  bottom: 10px;
}

.loading-screen-content {
  margin-top: 15vh;
}

.card-height {
  min-height: 450px;
  height: calc(100% - 100px);
}
</style>

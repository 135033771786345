<template>
  <div class="full-height gradient-background" rounded="0">
    <div class="d-flex flex-column justify-space-between full-height">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="12" sm="6" md="5" lg="4" xl="4">
          <div
            class="white-background form-card"
            :class="{
              rounded: !$vuetify.breakpoint.xs,
              'mt-6': !$vuetify.breakpoint.xs
            }"
          >
            <transition :name="slideDirection" mode="out-in">
              <v-row
                :key="keys.main"
                no-gutters
                class="py-9 word-break px-5 d-flex flex-column align-center mb-8 text-center"
              >
                <v-img
                  src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                  max-width="165"
                  class="mx-auto"
                />

                <v-form
                  ref="form"
                  onSubmit="return false;"
                  class="full-width px-4 mt-6"
                >
                  <p class="text-h5 mb-2">Welcome!</p>
                  <p>Loading your Whistle account...</p>

                  <div class="full-width mx-auto mt-8">
                    <v-progress-circular
                      size="50"
                      indeterminate
                      color="brandCyan"
                    />
                  </div>
                </v-form>
              </v-row>
            </transition>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "LoginMagicLink",
  title: "Whistle | Log in",
  props: {},
  components: {},
  data() {
    return {
      currentSlide: 0,
      keys: {
        // First screen always. Shows main login
        main: 0
      },
      slideDirection: "topic-left",

      // Booleans for when to show which buttons
      loginButton: false,

      clientId: null,

      // Client object stored so we have their logo to display
      client: {}
    };
  },
  created() {
    // We want to
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }

    if (this.$refs.emailField) this.$refs.emailField.focus();
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {},
  computed: {
    ...mapState(["userProfile", "marqeta", "permissions"])
  },

  watch: {}
};
</script>

<style scoped>
.gradient-background {
  /* background-image: url(https://storage.googleapis.com/whistle-dev-pictures/Logo/gradient.png); */
  background: linear-gradient(
      45deg,
      rgba(161, 215, 110, 1) 1%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}

.box-outline {
  border: solid;
  border-color: lightgray;
  border-width: 2px;
}

.icon-outer-ring {
  width: 110px;
  height: 110px;
}

.large-icon {
  transform: scale(1.5);
}

.icon-inner-ring {
  width: 94px;
  margin: 8px;
  height: 94px;
}

.language-selector {
  max-width: 250px;
}

.title-text {
  font-size: 1.5em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.progress-bar {
  width: calc(50% - 80px);
}

.mini-icon {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
}
.download-icon {
  min-width: 24px;
}

.form-card {
  background-color: white;
}

@media (max-width: 600px) {
  .form-card {
    width: 100vw;
    min-height: 100vh;
    padding: 0;
    margin: 0;
    justify-content: center;
  }
}
@media (min-width: 601px) {
  .form-card {
    width: auto;
    padding: 0;
    margin: 0;
    justify-content: center;
    min-height: 70vh;
  }
}

.bar-line {
  height: 0.5px;
  border: solid 0.5px;
  border-color: lightgray;
  width: 40%;
}

.outer-circle {
  border-radius: 1000px;
  width: 125px;
  height: 125px;
  min-height: 125px;
  min-width: 125px;
  max-height: 125px;
  max-width: 125px;
}

.inner-circle {
  border-radius: 1000px;
  width: 109px;
  height: 109px;
  min-width: 109px;
  min-height: 109px;
  max-width: 109px;
  max-height: 109px;
  margin-top: 8px;
  margin-left: 8px;
}

.dark-blue-background {
  background-color: #97dffc;
}
.light-blue-background {
  background-color: #09acec;
}
</style>

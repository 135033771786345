<template>
  <div>
    <!-- <v-card-title>
      <v-row>
        <v-col
          class="d-flex flex-column align-start ml-5"
          sm="6"
          md="6"
          @click="previousSlide"
        >
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1"
            @click="previousSlide"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title> -->
    <div id="stripe-script"></div>
    <div id="stripe-script1"></div>
    <div id="stripe-token-script"></div>
    <transition :name="slideDirection" mode="out-in">
      <v-form
        v-if="currentSlide == 0"
        ref="form"
        v-model="validForm"
        class="full-width px-4"
      >
        <!-- <v-text-field
          ref="amount"
          label="Amount to fund"
          v-model="amount"
          outlined
          type="number"
          class="mt-4"
          :rules="[
            (v) => !!v || 'Amount is required',
            (v) => !!(v && parseFloat(v) > 0) || 'Amount should be positive',
            (v) =>
              !!(
                v &&
                (!v.toString().includes('.') ||
                  (v.toString().includes('.') &&
                    v.substring(v.indexOf('.') + 1).length < 3))
              ) || `Amount shouldn't be longer than 2 decimals.`,
          ]"
        /> -->
        <v-text-field
          ref="routingNumber"
          label="Bank Routing Number"
          v-model="routingNumber"
          outlined
          dense
          color="brandCyan"
          class="mt-5"
          :rules="[
            v => !!v || 'Routing number is required',
            v => !!(v && v.length == 9) || 'Routing number should be 9 digits'
          ]"
        />
        <v-text-field
          ref="accountNumber"
          label="Bank Account Number"
          v-model="accountNumber"
          outlined
          dense
          color="brandCyan"
          class="mt-2"
          :rules="[v => !!v || 'Account number is required']"
          v-if="!showDevAccountOptions"
        />
        <v-select
          :items="accountOptions"
          item-value="value"
          item-text="label"
          v-model="accountNumber"
          label="Test Account Numbers"
          v-else
          outlined
          dense
          class="mt-2"
          color="brandCyan"
        />
        <v-text-field
          ref="name"
          label="Account Holder Name"
          v-model="name"
          outlined
          dense
          color="brandCyan"
          class="mt-2"
          :rules="[
            v => !!v || 'Account name is required',
            v => !!(v && v.length < 256) || 'Account name should be shorter'
          ]"
        />
        <v-select
          label="Country"
          class="mt-2"
          :items="countries"
          v-model="country"
          outlined
          dense
          color="brandCyan"
          item-value="code"
          item-text="name"
          :rules="[v => !!v || 'Country is required']"
        />
        <v-text-field
          v-if="country == '00'"
          label="Other country (2 digit code)"
          v-model="otherCountry"
          outlined
          dense
          color="brandCyan"
          class="mt-2"
          required
          :rules="[
            v => !!v || 'Country is required',
            v =>
              !!(v && v.length == 2) || 'Country code should be 2 characters',
            v =>
              !!(v && countryCodes.includes(v.toUpperCase())) ||
              'Country code must be a valid country'
          ]"
        />
        <p class="red--text text-left" v-if="country && country != 'US'">
          Whistle only accepts US bank accounts at the moment. We apologize for
          any inconvenience this may cause.
        </p>
        <v-radio-group v-model="accountType" class="ml-2">
          <caption class="full-width align-text-left">
            What type of bank account is it?
          </caption>
          <v-radio
            label="Personal"
            value="individual"
            class="mb-5 mt-4"
            color="brandCyan"
          ></v-radio>
          <v-radio
            label="Company/Business"
            value="company"
            color="brandCyan"
          ></v-radio>
        </v-radio-group>
        <v-btn
          color="brandCyan"
          depressed
          class="mb-8 white--text"
          width="150"
          :disabled="!validForm || !accountType || country != 'US'"
          @click="createStripeToken"
          >Submit</v-btn
        >
      </v-form>
      <v-row
        v-else-if="currentSlide == 1"
        key="1"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <v-card-title>Processing...</v-card-title>
        <Robin
          :showText="false"
          :width="130"
          :height="130"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == 2"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <v-card-title>All done!</v-card-title>
        <caption class="grey--text mt-2">
          Your Bank account has been linked!
        </caption>

        <Robin
          :showText="false"
          :width="130"
          :height="130"
          animation="backflip"
          :loop="false"
        />
        <caption class="grey--text mt-2">
          Within the next few days you will have 2 small deposits in your
          account used for verification purposes before you can use your account
          with Whistle.
        </caption>
        <v-btn
          color="brandCyan"
          depressed
          class="mt-4 white--text"
          width="140"
          @click="resetForm"
          >Done</v-btn
        >
      </v-row>
      <v-row
        v-else-if="currentSlide == 3"
        key="8"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
      >
        <v-card-title class="title-text word-break full-width">{{
          errorMessage
            ? errorMessage
            : "There was an error setting up your bank account"
        }}</v-card-title>

        <p class="pl-4 full-width align-text-left">
          If you continue to have trouble, feel free to reach out to customer
          service via one of the following methods...
        </p>
        <p class="pl-4 align-text-left full-width">
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p class="pl-4 align-text-left full-width">
          Phone (Toll Free) - <b>(855) 264-3329</b>
        </p>
        <div class="d-flex mt-4">
          <v-btn
            color="brandCyan"
            depressed
            class="mr-4 white--text"
            width="150"
            @click="(currentSlide = 0), (errorMessage = null)"
            >Update Details</v-btn
          >
          <v-btn
            color="brandCyan"
            class="white--text"
            depressed
            width="150"
            @click="resetForm"
            >Done</v-btn
          >
        </div>
      </v-row>
    </transition>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import { countries, countryCodes } from "@/shared_data/data.js";
import Robin from "@/components/Robin";

// import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "StripeBankAccount",
  props: {
    token: String
  },
  components: {
    Robin
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      stripe: null,
      validForm: null,
      amount: null,
      routingNumber: null,
      accountNumber: null,
      accountOptions: [
        { label: "Success", value: "000123456789" },
        { label: "Failure on use", value: "000111111116" },
        { label: "Account closed", value: "000111111113" },
        { label: "Insufficient Funds", value: "000222222227" },
        { label: "Debit not authorized", value: "000333333335" },
        { label: "Invalid currency", value: "000444444440" }
      ],
      name: null,
      accountType: null,
      country: "US",
      otherCountry: null,
      authorization: false,
      countries: countries,
      countryCodes: countryCodes,
      errorMessage: null
    };
  },
  created() {
    console.log("Created stripe bank widget");
  },
  mounted() {
    // So we don't load the script multiple times
    if (!this.isScriptLoaded("//js.stripe.com/v3/")) {
      this.includeStripe(
        "js.stripe.com/v3/",
        function() {
          this.configureStripe();
        }.bind(this)
      );
    } else {
      this.configureStripe();
    }
  },
  beforeDestroy() {},
  methods: {
    isScriptLoaded(src) {
      return Boolean(document.querySelector('script[src="' + src + '"]'));
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY); //eslint-disable-line

      this.elements = this.stripe.elements();
      // this.card = this.elements.create("card");

      // this.card.mount("#stripe-script");
    },
    createStripeToken() {
      console.log("Generating token");
      this.stripe
        .createToken("bank_account", {
          country: this.country == "00" ? this.otherCountry : this.country,
          currency: "usd",
          routing_number:
            process.env.VUE_APP_ENVIRONMENT != "prod"
              ? "110000000"
              : this.routingNumber,
          account_number: this.accountNumber,
          account_holder_name: this.name,
          account_holder_type: this.accountType
        })
        .then(result => {
          // Handle result.error or result.token
          console.log("Stripe result: ", result);
          if (result.error) {
            console.log("Error");
            this.currentSlide += 3;
          } else {
            this.createStripeBank(result.token.id);
          }
        });
    },
    createStripeBank(token) {
      this.currentSlide++;
      console.log("Creating bank source");
      PaymentService.createStripeBankAccount(token, this.magicLinkToken).then(
        resp => {
          console.log("Got bank resp: ", resp);
          if (resp.error) {
            this.currentSlide += 2;
            if (resp.error.code == "bank_account_exists")
              this.errorMessage =
                "Looks like this account is already linked in Whistle";
            else this.errorMessage = null;
          } else {
            this.currentSlide++;
          }
        }
      );
    },
    onSuccess(public_token, metadata) {
      console.log("Success: ", public_token);
      PaymentService.sendPlaidPublicToken(public_token).then(publicRes => {
        console.log("Got public access token response: ", publicRes);
        console.log("Public Token: " + public_token);
        switch (metadata.accounts.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            console.log(
              "Customer-selected account ID: " + metadata.accounts[0].id
            );
            break;
          default:
            // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
            break;
        }
      });
    },
    resetForm() {
      this.$emit("scrollToTop");
      this.$emit("reset");
      // this.$emit("get-cards");
    },
    previousSlide() {
      // this.$emit("scrollToTop");
      this.resetForm();
    }
  },
  computed: {
    ...mapState(["userProfile", "magicLinkToken"]),
    showDevAccountOptions() {
      if (process.env.VUE_APP_ENVIRONMENT != "prod") {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}
</style>

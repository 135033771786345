<template>
  <v-card flat>
    <v-toolbar dark color="brand" rounded="0" elevation="0">
      <v-btn dark icon @click="closeWindow">
        <v-icon large>mdi-close-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>New Group</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider />

    <v-card-actions
      class="pb-8 d-flex justify-center"
      v-if="showGroupTypeSelector"
    >
      <v-row justify="center" class="mt-4">
        <v-btn
          color="brandCyan"
          height="80"
          outlined
          width="400"
          @click="createQRGroupSelected()"
        >
          <v-icon class="ml-4 mr-8" left large>mdi-qrcode-scan</v-icon>

          <div class="mt-4  mb-4">
            <h3 class="black--text text-left">Create QR Code Group</h3>
            <div class="black--text text-left subtitle word-wrap word-break">
              Generate a QR code users can <br />scan to join
            </div>
          </div>
          <v-spacer></v-spacer>
        </v-btn>

        <v-btn
          class="mt-8"
          color="brandCyan"
          height="80"
          outlined
          width="400"
          @click="createSimpleGroupSelected()"
        >
          <v-icon class="ml-4 mr-8" left large
            >mdi-account-filter-outline</v-icon
          >
          <div>
            <h3 class="black--text text-left">Create Simple Group</h3>
            <div class="black--text text-left subtitle">
              Create a group with simple attributes
            </div>
          </div>
          <v-spacer></v-spacer>
        </v-btn>
      </v-row>
    </v-card-actions>
    <!-- Dialog used for displaying loading message. -->
    <!--If showQrCodeGroupform-->
    <QRGroupCreatorWidget
      v-if="showQrCodeGroupForm"
      :source="source"
      :version="3"
      :clientId="clientId"
      @close="closeWindow"
      @get-groups="getGroups($event)"
    ></QRGroupCreatorWidget>
    <GroupCreatorWidget
      v-if="showSimpleGroupForm"
      :source="source"
      :version="3"
      :clientId="clientId"
      @close="closeWindow"
      @get-groups="getGroups($event)"
    />
  </v-card>
</template>

<script>
//   import GroupService from "../../services/GroupService";
// import RulesService from "../services/RulesService";
// import LearningService from "../services/LearningService";
// import ProgramService from "../services/ProgramService";
//   import LoadingDialog from "@/components/loadingDialog.vue";
// import RuleBuilder from "@/components/RuleBuilderV3.vue";
import { mapState } from "vuex";

import GroupCreatorWidget from "@/components/groups/GroupCreatorWidgetSimplified";
import QRGroupCreatorWidget from "@/components/groups/QRGroupCreatorWidget";
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Eating.json";
import UserService from "../../services/UserService";
import { GiphyFetch } from "@giphy/js-fetch-api";

export default {
  name: "GroupTypeSelectorWidget",
  components: {
    //   LoadingDialog,
    // RuleBuilder,
    //Lottie
    GroupCreatorWidget,
    QRGroupCreatorWidget
  },
  props: { clientId: Number, source: String, version: Number },
  data() {
    return {
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },
      anim: null,
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      showSimpleGroupForm: false,
      showQrCodeGroupForm: false,
      groupName: null,
      hasSubClients: false,
      includeSubClients: false,
      selectedUserColumn: null,
      displaySimpleGroupResults: false,
      showRobinAnimation: false,
      showAttributeTable: false,
      columnValues: [],
      metrics: [],
      modules: [],
      complexGroupForm: {
        valid: false,
        dialogRuleBuilder: false,
        ruleBuilderTrigger: false
      },
      complexGroup: {
        groupDisplayName: null,
        includeSubClients: false,
        rules: null,
        ruleName: null,
        ruleDescription: null,
        estimatedParticipants: 0
      },
      attributesForNewGroups: []
    };
  },
  created() {
    // this.getLearningModules();
    // this.getMetrics();
    if (!this.magicLinkToken)
      UserService.getClientsByClient(this.clientId)
        .then(response => {
          if (response.length > 0) {
            this.hasSubClients = true;
          }
        })
        .catch(error => {
          console.log("Error getting subclients", error.response || error);
        });
  },
  mounted() {},
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    createQRGroupSelected() {
      this.showQrCodeGroupForm = true;
      this.showSimpleGroupForm = false;
    },
    createSimpleGroupSelected() {
      this.showSimpleGroupForm = true;
      this.showQrCodeGroupForm = false;
    },
    closeWindow() {
      this.showSimpleGroupForm = false;
      this.showQrCodeGroupForm = false;
      this.$emit("close");
    },
    getGroups(prop) {
      console.log("get groups", prop);
      this.$emit("get-groups", prop);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "magicLinkToken"]),
    showGroupTypeSelector() {
      return !this.showSimpleGroupForm && !this.showQrCodeGroupForm;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subtitle {
  font-size: 12px;
}
</style>

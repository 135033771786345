<template>
  <v-container fluid>
    <v-row justify="space-between" class="mr-9 mb-3 mt-4">
      <div class="d-flex align-center">
        <v-btn icon @click="closeForm" class="ml-5"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <v-autocomplete
          v-if="userProfile.clientId == 1"
          v-model="clientId"
          :items="clients"
          item-text="formattedName"
          item-value="clientId"
          label="Client*"
          class="ml-5"
          style="width: 300px;"
          hide-details
          outlined
          attach
          :menu-props="{ offsetY: true }"
          dense
          @change="refreshClientId"
        ></v-autocomplete>
      </div>
      <v-btn color="brandCyan" depressed @click="loadNewMetricForm"
        ><v-icon color="white" w>mdi-plus</v-icon></v-btn
      >
    </v-row>
    <v-row>
      <v-col md="8">
        <div class="px-6 d-flex justify-center align-center " v-if="loading">
          <v-progress-circular
            indeterminate
            color="brandCyan"
            size="40"
            width="3"
            class="mr-3"
          />
          Loading... please wait
        </div>
        <v-btn
          v-else-if="visibleMetrics.length == 0"
          color="brandCyan"
          class="white--text"
          depressed
          @click="loadNewMetricForm"
          >Create Metric</v-btn
        >
        <v-data-table
          :headers="metricTableColumns"
          :items="visibleMetrics"
          v-if="visibleMetrics.length > 0"
          class="metric-table"
        >
          <template v-slot:[`item.dataType`]="{ item }">
            {{
              dataTypeOptions.find(x => x.value == item.dataType)
                ? dataTypeOptions.find(x => x.value == item.dataType).text
                : item.dataType
            }}
          </template>
          <template v-slot:[`item.icons`]="{ item }">
            <div class="d-flex justify-end mr-7">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    fab
                    small
                    color="white"
                    class="ml-4 my-2 accent-background py-2"
                    v-on="on"
                    v-bind="attrs"
                    @click="loadEditMetricForm(item)"
                  >
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    fab
                    small
                    color="white"
                    class="ml-4 my-2 accent-background py-2"
                    v-on="on"
                    v-bind="attrs"
                    @click="prepareToDeleteMetric(item)"
                  >
                    <v-icon color="white">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>

                <span>Delete</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            <div class="d-flex justify-end mr-7">
              {{ formatCreateDate(item.updatedAt) }}
            </div>
          </template>
        </v-data-table>
        <!-- <v-card
          v-for="metric in visibleMetrics"
          :key="metric.metricId"
          class="mb-3 pb-3 ml-3"
          width="90%"
          ><v-card-title>
            {{ metric.metricName }}
          </v-card-title>
          <v-card-text class="align-text-left pb-0">
            Created {{ formatCreateDate(metric.createdAt) }}
          </v-card-text>
          <v-card-text class="align-text-left">
            Data Upload Type: {{ formatUploadType(metric.uploadType) }}
          </v-card-text>
          <v-row>
            <v-col md="6">
              <v-card-text class="align-text-left">
                {{ metric.description }}
              </v-card-text>
            </v-col>
            <v-col md="6">
              <v-card-text class="align-text-right">
                <v-btn text @click="loadEditMetricForm(metric)">Edit </v-btn>|
                <v-btn text @click="prepareToDeleteMetric(metric)">
                  Delete</v-btn
                >
              </v-card-text>
            </v-col>
          </v-row>
        </v-card> -->
      </v-col>
      <v-col md="4">
        <v-row><v-card-title>Progress Metrics</v-card-title></v-row>
        <v-row>
          <v-card-subtitle class="align-text-left"
            >Progress metrics are what you use to measure progress and success
            in a program! Feel free to reuse these across any of your programs.
            <br />
            <br />
            <!-- The "Data Upload Type" indicates the kind of metric data you will be
            uploading to track program progress.
            <br />
            <br />
            For instance, when tracking sales for a program, if you are
            uploading cumulative sales data, your "Data Upload Type" will be
            "Cumulative".
            <br />
            e.g: I had previously made 5 sales that were already uploaded to
            Whistle. This week I make another 7 sales. The program admin would
            upload "12" this week.
            <br />
            If you are uploading a specific week of sales data (or for any range
            of time), your "Data Upload Type" will be "Incremental"
            <br />
            <br /> -->
            Some examples of program metrics include: outbound calls, a check to
            see if employees have completed their onboarding paperwork, or
            tracking positive reviews.</v-card-subtitle
          ></v-row
        >
      </v-col>
    </v-row>
    <!-- Delete confirmation Dialog -->
    <v-dialog
      v-model="confirmDelete"
      width="440px"
      :persistent="deletingMetric"
    >
      <v-card>
        <v-card-title
          class="brand-cyan-background white--text py-4 text-left word-break"
        >
          {{
            deletingMetricError
              ? "Error Deleting Metric"
              : "Metric Deletion Confirmation"
          }}
        </v-card-title>
        <p class="my-6 px-3" v-if="!deletingMetricError">
          Are you sure you want to delete '{{ deleteMetric.metricName }}'? This
          action can't be undone.
        </p>
        <div class="my-6 px-3" v-else>
          <span v-if="deletingMetricPrograms.length"
            >We found programs attached to this metric. Please remove this
            metric from the programs below.</span
          >
          <span v-else
            >There was an issue deleting this metric. Please try again or
            <a
              href="mailto:help@wewhistle.com?subject=Problems while deleting metrics"
              >contact us</a
            >
            if the problem continues.</span
          >
          <p></p>
          <p
            v-for="error in deletingMetricPrograms"
            :key="`${error.programId}`"
            class="mb-2"
          >
            Program '<span class="font-weight-bold">{{
              error.displayName || error.programId
            }}</span
            >'
          </p>
        </div>
        <v-card-actions class="pt-3 pb-7 d-flex justify-center align-center">
          <v-btn
            color="brandCyan"
            outlined
            width="140"
            @click="cancelDelete"
            :disabled="deletingMetric"
          >
            {{ deletingMetricError ? "Close" : "Cancel" }}
          </v-btn>
          <!-- <v-spacer></v-spacer> -->

          <v-btn
            v-if="!deletingMetricError"
            color="red"
            outlined
            width="140"
            @click="confirmDeleteMetric"
            :loading="deletingMetric"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-model="newMetricDialog"
      temporary
      fixed
      right
      width="500"
    >
      <MetricCreatorWidget
        @close="closeMetricCreator"
        @get-metrics="getMetrics"
        :clientId="clientId"
        source="programMetricWidget"
        :preloadedMetric="metricModel"
        v-if="newMetricDialog == true"
      />
    </v-navigation-drawer>
    <v-overlay v-if="newMetricDialog" />

    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import ProgramService from "../services/ProgramService.js";
import LoadingDialog from "../components/loadingDialog.vue";
import MetricCreatorWidget from "@/components/MetricCreatorWidget.vue";

export default {
  name: "MetricDefinitions",
  components: {
    LoadingDialog,
    MetricCreatorWidget
  },
  props: {
    metrics: Array,
    loading: Boolean
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      metricDialog: false,
      newMetricDialog: false,
      deletingMetric: false,
      deletingMetricError: false,
      deletingMetricPrograms: [],
      // clientId: null,
      metricsCardFormat: [],
      metricTypes: [
        {
          name: "Transactional",
          value: "TRANSACTIONAL"
        },
        {
          name: "Program Level",
          value: "PROGRAM_LEVEL"
        }
      ],
      uploadTypes: [
        {
          name: "Incremental",
          value: "INCREMENTAL"
        },
        {
          name: "Cumulative",
          value: "CUMULATIVE"
        }
      ],
      operators: [
        { label: "=", value: "EQUAL" },
        { label: "≠", value: "NOT_EQUAL" },
        { label: ">", value: "GREATER_THAN" },
        { label: "≥", value: "GREATER_THAN_EQUAL" },
        { label: "<", value: "LESS_THAN" },
        { label: "≤", value: "LESS_THAN_EQUAL" }
      ],
      metricModel: {},
      metricBlank: {
        clientId: null,
        metricId: null,
        metricName: null,
        description: null,
        type: null,
        unitSingular: null,
        unitPlural: null,
        verb: null,
        calculateProgress: false,
        defaultOperator: "GREATER_THAN_EQUAL",
        dataType: "STRING",
        selfAssess: false,
        uploadType: null,
        definition1: null,
        definition2: null,
        definition3: null,
        definition4: null,
        definition5: null,
        definition6: null,
        definition7: null,
        definition8: null,
        definition9: null,
        definition10: null,
        definition11: null,
        definition12: null,
        definition13: null,
        definition14: null,
        definition15: null
      },
      definitionCounter: 1,
      editMode: false,
      confirmDelete: false,
      deleteMetric: { metricId: null, metricName: null },
      metricForm: {
        valid: false
      },
      dataTypeOptions: [
        { text: "Text", value: "STRING" },
        { text: "Whole Number", value: "INTEGER" },
        { text: "Number", value: "DECIMAL" },
        { text: "Checkbox", value: "BOOLEAN" },
        { text: "Date Only", value: "DATE" },
        { text: "Date/Time", value: "Timestamp" }
      ],
      metricTableColumns: [
        {
          text: "Metric Name",
          align: "start",
          sortable: true,
          value: "metricName",
          width: "30%"
        },
        { text: "Type", value: "dataType", width: "20%" },
        { text: "Verb", value: "verb", width: "10%" },
        { text: "Noun", value: "unitPlural", width: "10%", align: "center" },
        // { text: "Updated", value: "updatedAt", width: "20%" },
        { text: "", value: "icons", width: "10%", sortable: false }
      ]
    };
  },
  mounted() {
    // this.clientId = this.userProfile.clientId;
  },
  methods: {
    getMetrics() {
      this.$emit("get-metrics");
    },
    refreshClientId() {
      this.$emit("update-client");
    },
    formatUploadType(type) {
      if (type) {
        var returnType =
          type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
      } else {
        returnType = type;
      }

      return returnType;
    },
    formatCreateDate(thisDate) {
      var returnDate = moment(thisDate)
        .local()
        .format("MMMM Do YYYY, h:mm:ss a");
      return returnDate;
    },
    loadNewMetricForm() {
      this.newMetricDialog = true;
      console.log("clearmodel");

      this.metricModel = null;
      console.log(this.metricModel);
      // this.metricModel.clientId = this.clientId;
      // this.editMode = false;
      // this.metricModel.defaultOperator = "GREATER_THAN_EQUAL";
      // this.metricModel.dataType = "STRING";
      // this.newMetricDialog = true;
    },
    loadEditMetricForm(thisMetric) {
      console.log(thisMetric);
      let loadMetric = JSON.parse(JSON.stringify(thisMetric));

      loadMetric.agreement = true;
      loadMetric.uploadType = loadMetric.uploadType.toUpperCase();
      //count how many definitions this metric has
      for (var i = 1; i < 15; i++) {
        if (loadMetric["definition" + i]) {
          this.definitionCounter++;
        }
      }
      this.editMode = true;
      this.newMetricDialog = true;
      this.metricModel = loadMetric;
    },
    resetMetricForm() {
      this.metricModel = this.metricBlank;
      this.definitionCounter = 1;
      this.showLoadingDialog = false;
      this.metricDialog = false;
    },
    closeMetricCreator() {
      this.newMetricDialog = false;
    },
    createNewMetric() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating Program Metric";
      this.loadingMessageLine1 = "Creating the metric.";
      this.loadingMessageLine2 = "This shouldn't take long.";
      var metric = {
        clientId: this.metricModel.clientId,
        metricName: this.metricModel.metricName,
        description: this.metricModel.description,
        unitSingular: this.metricModel.unitSingular,
        unitPlural: this.metricModel.unitPlural,
        verb: this.metricModel.verb,
        prefixSymbol: this.metricModel.prefixSymbol,
        type: this.metricModel.type,
        status: "Active",
        calculateProgress: this.metricModel.calculateProgress,
        uploadType: this.metricModel.uploadType,
        dataType: this.metricModel.dataType,
        defaultOperator: this.metricModel.defaultOperator,
        definition1: this.metricModel.definition1,
        definition2: this.metricModel.definition2,
        definition3: this.metricModel.definition3,
        definition4: this.metricModel.definition4,
        definition5: this.metricModel.definition5,
        definition6: this.metricModel.definition6,
        definition7: this.metricModel.definition7,
        definition8: this.metricModel.definition8,
        definition9: this.metricModel.definition9,
        definition10: this.metricModel.definition10,
        definition11: this.metricModel.definition11,
        definition12: this.metricModel.definition12,
        definition13: this.metricModel.definition13,
        definition14: this.metricModel.definition14,
        definition15: this.metricModel.definition15,
        selfAssess: this.metricModel.selfAssess
      };
      ProgramService.createMetric(metric).then(response => {
        console.log(response);
        //This will notify the parent component to refresh their metrics;
        this.$emit("get-metrics");
        this.resetMetricForm();
      });
    },
    updateMetric() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Editing Program Metric";
      this.loadingMessageLine1 = "Saving your Metric changes.";
      this.loadingMessageLine2 = "This shouldn't take long.";
      var metric = {
        clientId: this.metricModel.clientId,
        metricName: this.metricModel.metricName,
        metricId: this.metricModel.metricId,
        description: this.metricModel.description,
        unitSingular: this.metricModel.unitSingular,
        unitPlural: this.metricModel.unitPlural,
        verb: this.metricModel.verb,
        prefixSymbol: this.metricModel.prefixSymbol,
        type: this.metricModel.type,
        calculateProgress: this.metricModel.calculateProgress,
        status: "Active",
        uploadType: this.metricModel.uploadType,
        dataType: this.metricModel.dataType,
        defaultOperator: this.metricModel.defaultOperator,
        definition1: this.metricModel.definition1,
        definition2: this.metricModel.definition2,
        definition3: this.metricModel.definition3,
        definition4: this.metricModel.definition4,
        definition5: this.metricModel.definition5,
        definition6: this.metricModel.definition6,
        definition7: this.metricModel.definition7,
        definition8: this.metricModel.definition8,
        definition9: this.metricModel.definition9,
        definition10: this.metricModel.definition10,
        definition11: this.metricModel.definition11,
        definition12: this.metricModel.definition12,
        definition13: this.metricModel.definition13,
        definition14: this.metricModel.definition14,
        definition15: this.metricModel.definition15,
        selfAssess: this.metricModel.selfAssess
      };
      ProgramService.updateMetric(metric.metricId, metric).then(response => {
        console.log(response);
        //This will notify the parent component to refresh their metrics;
        this.$emit("get-metrics");
        this.resetMetricForm();
      });
    },
    prepareToDeleteMetric(thisMetric) {
      this.confirmDelete = true;
      this.deleteMetric.metricId = thisMetric.metricId;
      this.deleteMetric.metricName = thisMetric.metricName;
      this.deletingMetricError = false;
      this.deletingMetricPrograms = [];
    },
    cancelDelete() {
      this.confirmDelete = false;
      this.deleteMetric.metricId = false;
      this.deleteMetric.metricName = false;
      this.deletingMetricError = false;
      this.deletingMetricPrograms = [];
    },
    confirmDeleteMetric() {
      this.deletingMetric = true;
      ProgramService.deleteMetricV2(this.deleteMetric.metricId)
        .then(response => {
          console.log("Metric deletion response ", response);
          this.cancelDelete();
          //This will notify the parent component to refresh their metrics;
          this.$emit("get-metrics");
        })
        .catch(err => {
          console.log("Error deleting metric", err);
          this.deletingMetricError = true;
          if (err.data && err.data.length) {
            this.deletingMetricPrograms = err.data;
          }
        })
        .finally(() => {
          this.deletingMetric = false;
        });
    },
    removeMetricDefinition(id) {
      //This uses the id to delete the selected definition and shift anything above it down
      var varName = "this.metricModel.definition" + id;
      var nextVar = null;
      eval(`${varName} = null`);
      for (var i = id; i < 15; i++) {
        varName = "this.metricModel.definition" + i;
        nextVar = "this.metricModel.definition" + (i + 1);
        eval(`${varName} = ${nextVar}`);
        eval(`${nextVar} = null`);
      }
      this.definitionCounter--;
    },
    closeForm() {
      this.$emit("close");
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    visibleMetrics() {
      return this.metrics.filter(
        x => x.clientId == this.clientId
        // ||
        // (this.userProfile.clientId == 1 && x.clientId == 0)
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-image {
  margin: 0 0 0 12px;
}
.progress-switch .v-label {
  visibility: hidden;
}

.metric-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.metric-table >>> .v-data-table-header {
  background-color: white;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('p',{staticClass:"text-h5 mx-8"},[_vm._v(_vm._s(_vm.card.displayName))]),(
      _vm.computedDateSummary &&
        !_vm.card.displayLeaderboard &&
        !_vm.card.displayResources
    )?_c('div',{staticClass:"mx-8 pa-0 mt-6 text-left"},[_vm._v(" "+_vm._s(_vm.computedDateSummary)+" ")]):_vm._e(),(_vm.card.displayImage)?_c('v-img',{staticClass:"mx-auto mt-4",attrs:{"src":_vm.card.imageURL,"contain":"","width":"80%","height":"auto"}}):(_vm.card.displayLeaderboard)?_c('Leaderboard',{staticClass:"mt-8 mx-auto",attrs:{"programId":_vm.programId,"useSmallVersion":false,"version":2}}):(_vm.card.displayResources)?_c('div',{staticClass:"mx-8 mt-6"},[_c('v-data-table',{staticClass:"elevation-0 cursor-pointer mt-6 box-border rounded-0 resource-table",attrs:{"headers":[
        {
          text: '',
          align: 'start',
          value: 'icon',
          sortable: false,
          width: '7%'
        },

        {
          text: 'Name',
          value: 'Content.name',
          width: '30%',
          sortable: false
        },
        {
          text: 'Description',
          value: 'Content.description',
          width: '50%',
          sortable: false
        }
      ],"items":!_vm.card.ProgramAttachments ? [] : _vm.card.ProgramAttachments,"item-key":"learningModuleAttachmentId","items-per-page":10,"hide-default-footer":""},on:{"click:row":_vm.downloadResource},scopedSlots:_vm._u([{key:`item.icon`,fn:function({ item }){return [(_vm.downloadResources.includes(item.programAttachmentId))?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"brand","size":"23","width":"2"}}):_c('v-icon',{attrs:{"color":"brand"},on:{"click":function($event){$event.stopPropagation();return _vm.downloadResource(item)}}},[_vm._v("mdi-download")])]}},{key:`item.fileType`,fn:function({ item }){return [(item.Content.fileType === 'PDF')?_c('v-icon',{attrs:{"color":"red","x-large":""}},[_vm._v("mdi-file-pdf-box")]):(item.Content.fileType === 'DOCX')?_c('v-icon',{attrs:{"color":"blue","x-large":""}},[_vm._v("mdi-file-word")]):(item.Content.fileType === 'XLSX')?_c('v-icon',{attrs:{"color":"green","x-large":""}},[_vm._v("mdi-file-excel")]):(item.Content.fileType === 'PPTX')?_c('v-icon',{attrs:{"color":"red","x-large":""}},[_vm._v("mdi-file-powerpoint")]):(item.Content.fileType === 'PNG')?_c('v-icon',{attrs:{"color":"blue","x-large":""}},[_vm._v("mdi-file-image")]):(item.Content.fileType === 'CSV')?_c('v-icon',{attrs:{"color":"green","x-large":""}},[_vm._v("mdi-file-delimited")]):_c('v-icon',{attrs:{"color":"blue","x-large":""}},[_vm._v("mdi-file")])]}},{key:`item.Content.name`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column my-3"},[_c('b',[_vm._v(_vm._s(item.Content.name))]),_c('span',{staticClass:"mediumGrey--text"},[_vm._v(_vm._s(item.Content.fileExtension)+" File | "+_vm._s(item.Content.fileSize))])])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No resources attached to this program")])]},proxy:true}],null,true)})],1):_vm._e(),(_vm.card.description)?_c('div',{staticClass:"mx-8 pa-0 mt-6 text-left ql-editor",domProps:{"innerHTML":_vm._s(_vm.card.description)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!-- <div> -->
  <!-- <v-navigation-drawer
    app
    permanent
    clipped
    expand-on-hover
    update:mini-variant="expanded"
  > -->
  <v-list dense>
    <v-list-item
      v-for="item in computedParticipantItems"
      :key="item.title"
      :to="item.route"
      :class="item.class"
    >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
        <div
          class="activity-unread-dot"
          :class="{ hidden: item.title != 'Activity' || !activity.unread }"
        ></div>
      </v-list-item-icon>

      <v-list-item-content class="text-left">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
      <!-- v-if="item.title == 'Activity' && activity.unread" -->
      <v-list-item-icon>
        <span
          class="activity-unread"
          :class="{ hidden: item.title != 'Activity' || !activity.unread }"
        >
          {{ activity.unread }}
        </span>
      </v-list-item-icon>
    </v-list-item>
    <v-divider v-if="!$store.state.impersonate"></v-divider>

    <!-- <v-list v-if="!$store.state.impersonate" dense> -->
    <div v-if="!$store.state.impersonate">
      <v-list-item
        v-for="item in computedAdminItems"
        :key="item.title"
        :to="item.route"
        :class="item.class"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
          <div class="activity-unread-dot hidden"></div>
        </v-list-item-icon>

        <v-list-item-content class="text-left">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item
        @click="showMobilePopup"
        v-if="
          userProfile &&
            userProfile.Client &&
            userProfile.Client.displayMobilePopup
        "
      >
        <v-list-item-icon>
          <v-icon color="brandGreen">mdi-cellphone-arrow-down</v-icon>
          <div class="activity-unread-dot hidden"></div>
        </v-list-item-icon>

        <v-list-item-content class="text-left">
          <v-list-item-title>Download Mobile App</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-divider></v-divider> -->

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon color="indigo">mdi-account-circle</v-icon>
          <div class="activity-unread-dot hidden"></div>
        </v-list-item-icon>

        <v-list-item-content class="text-left">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <!-- </v-list> -->
  </v-list>
  <!-- </div> -->
  <!-- </v-navigation-drawer> -->
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "navDrawer",
  props: {
    msg: String
  },
  data() {
    return {
      participantItems: [
        {
          title: "Claim a new sale",
          icon: "mdi-sync",
          route: "/coming-soon",
          permission: "vue:read:claim",
          desktopOnly: false
        },
        // {
        //   title: "Robin",
        //   icon: "mdi-bird",
        //   route: "/robin",
        //   permission: "vue:read:robin",
        //   desktopOnly: false,
        // },

        {
          title: "Activity",
          icon: "mdi-format-list-text",
          route: "/activity",
          permission: "vue:read:activity",
          desktopOnly: false
        },
        {
          title: "Programs",
          icon: "mdi-star",
          route: "/programs",
          permission: "vue:read:programs",
          desktopOnly: false
        },
        {
          title: "Wallet",
          icon: "mdi-wallet",
          route: "/wallet",
          permission: "vue:read:wallet",
          desktopOnly: false
        },
        {
          title: "Library",
          icon: "mdi-view-grid-plus",
          route: "/learning",
          permission: "vue:read:learning",
          desktopOnly: false
        }
        // {
        //   title: "News",
        //   icon: "mdi-newspaper-variant",
        //   route: "/news",
        //   permission: "vue:read:news",
        // },
        // {
        //   title: "News",
        //   icon: "mdi-newspaper-variant-outline",
        //   route: "/news",
        //   permission: "vue:read:news",
        // },
      ]
    };
  },
  created() {},
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    showMobilePopup() {
      this.$emit("show-mobile");
    }
  },
  computed: {
    ...mapState(["userProfile", "permissions", "magicLinkToken", "activity"]),
    computedParticipantItems: function() {
      if (this.magicLinkToken) return [];
      var newArray = [];

      this.participantItems.forEach(function(item) {
        if (this.permissions.includes(item.permission)) {
          if (this.userProfile && this.userProfile.Client) {
            if (
              item.route == "/activity" &&
              this.userProfile.Client.displayActivityPage
            )
              newArray.push(item);
            else if (
              item.route == "/wallet" &&
              this.userProfile.Client.displayWalletPage
            )
              newArray.push(item);
            else if (
              item.route == "/programs" &&
              this.userProfile.Client.displayProgramPage
            )
              newArray.push(item);
            else if (
              item.route == "/learning" &&
              this.userProfile.Client.displayLibraryPage
            )
              newArray.push(item);
          } else newArray.push(item);
        }
      }, this);
      return newArray;
    },
    adminItems() {
      if (this.magicLinkToken) return [];
      var arr = [
        {
          id: 2,
          title: "Program Management",
          icon: "mdi-file-star",
          route: "/programadmin",
          permission: "vue:read:programadmin",
          class: "desktop-only",
          hideXS: true,
          hideSM: true
        },
        {
          id: 5,
          title: "Rewards Management",
          icon: "mdi-wallet-plus",
          route: "/paymentadmin",
          permission: "vue:read:paymentadmin",
          class: "desktop-only",
          hideXS: false,
          hideSM: false
        },
        // {
        //   id: 0,
        //   title: "Learning Admin",
        //   icon: "mdi-school",
        //   route: "/learningadmin",
        //   permission: "vue:read:learningadmin",
        //   class: "desktop-only",
        //   hideXS: true,
        //   hideSM: true
        // },
        // {
        //   id: 1,
        //   title: "Survey Admin",
        //   icon: "mdi-comment-question",
        //   route: "/surveyadmin",
        //   permission: "vue:read:learningadmin",
        //   class: "desktop-only",
        //   hideXS: true,
        //   hideSM: true
        // },

        {
          id: 10,
          title: "Update Progress",
          icon: "mdi-checkbox-marked-outline",
          route: "/progress",
          permission: "vue:read:progress",
          class: "desktop-only",
          hideXS: false,
          hideSM: false
        },
        {
          id: 3,
          title: "Budgets, Awards, Badges",
          icon: "mdi-calculator-variant",
          route: "/budgets",
          permission: "vue:read:budgets",
          class: "desktop-only",
          hideXS: true,
          hideSM: true
        },
        {
          id: 4,
          title: "People and Groups",
          icon: "mdi-account-multiple",
          route: "/people",
          permission: "vue:read:people",
          hideXS: false,
          hideSM: false
        },
        // {
        //   title: "Permissions",
        //   icon: "mdi-eye-settings-outline",
        //   route: "/coming-soon",
        //   permission: "vue:read:investorDashboard",
        //   class: "desktop-only",
        // },
        {
          id: 6,
          title: "Reporting",
          icon: "mdi-chart-bar-stacked",
          route: "/reporting",
          permission: "vue:read:reporting",
          class: "desktop-only",
          hideXS: true,
          hideSM: false
        },
        {
          id: 7,
          title: "Communications",
          icon: "mdi-email",
          route: "/communications",
          permission: "vue:read:communications",
          hideXS: true,
          hideSM: true
        },
        // {
        //   id: 8,
        //   title: "Investor Dashboard",
        //   icon: "mdi-pizza",
        //   route: "/investorDashboard",
        //   permission: "vue:read:investordashboard",
        //   hideXS: true,
        //   hideSM: true,
        // },
        {
          id: 9,
          title: "Account Management",
          icon: "mdi-cog-outline",
          route: "/accountManagement",
          permission: "vue:read:accountmanagement",
          hideXS: true,
          hideSM: true
        }
      ];

      // Now let's build the computed name for budgets based on their permissions
      var budgetIndex = arr.findIndex(x => x.id == 3);
      if (budgetIndex != -1) {
        var title = "";
        if (this.permissions.includes("vue:read:budgets")) {
          title = title.concat("Budgets, ");
        }
        if (this.permissions.includes("vue:read:awards")) {
          title = title.concat("Awards, ");

          if (!this.permissions.includes("vue:read:budgets")) {
            // We have to change the route and permission
            arr[budgetIndex].permission = "vue:read:awards";
            arr[budgetIndex].route = "/budgets/awards";
          }
        }
        if (this.permissions.includes("vue:read:badges")) {
          title = title.concat("Badges, ");

          if (
            !this.permissions.includes("vue:read:budgets") &&
            !this.permissions.includes("vue:read:awards")
          ) {
            // We have to change the route and permission
            arr[budgetIndex].permission = "vue:read:badges";
            arr[budgetIndex].route = "/budgets/badges";
          }
        }
        if (title.endsWith(", ")) {
          title = title.substring(0, title.length - 2);
        }
        arr[budgetIndex].title = title;
      }

      // Now let's build the computed name for people/groups based on their permissions
      var peopleIndex = arr.findIndex(x => x.id == 4);
      if (peopleIndex != -1) {
        title = "";
        if (this.permissions.includes("vue:read:people")) {
          title = title.concat("People");
        }
        if (this.permissions.includes("vue:read:groups")) {
          if (!this.permissions.includes("vue:read:people")) {
            // We have to change the route and permission
            arr[peopleIndex].permission = "vue:read:groups";
            arr[peopleIndex].route = "/people/groups";
          } else {
            title = title.concat(" and ");
          }
          title = title.concat("Groups");
        }

        arr[peopleIndex].title = title;
      }
      return arr;
    },
    computedAdminItems: function() {
      var newArray = [];

      this.adminItems.forEach(function(item) {
        if (this.permissions.includes(item.permission)) {
          // if (item.route == "/programadmin") {
          //   if (this.userProfile.clientId == 1) {
          //     // We only push the program admin if user is Whistle
          //     newArray.push(item);
          //   }
          // } else {
          newArray.push(item);
          // }
        }
      }, this);

      if (this.$vuetify.breakpoint.xs) {
        newArray = newArray.filter(x => !x.hideXS);
      } else if (this.$vuetify.breakpoint.sm) {
        newArray = newArray.filter(x => !x.hideSM);
      }

      return newArray;
    }
  }
};
</script>

<style>
.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
  height: 24px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.v-list.v-list--dense {
  padding: 0;
}

.activity-unread {
  border-radius: 5px;
  background-color: var(--v-brandCyan-base);
  color: white;
  padding: 2px 8px 1px 8px;
  font-size: 14px;
}

.activity-unread-dot {
  background-color: var(--v-brandCyan-base);
  border-radius: 100%;
  min-width: 7px;
  min-height: 7px;
  max-width: 7px;
  max-height: 7px;
  margin-top: 7px;
  margin-left: 2px;
}
</style>

<template>
  <div>
    <transition :name="slideDirection" mode="out-in">
      <div
        v-if="currentSlide == keys.payment"
        :class="{
          'px-4': !isMobile || (isMobile && source == 'WALLET_GIVING')
        }"
      >
        <v-card-title class="word-break pl-0 mt-3" v-if="!givingMode">
          Add bank info and the amount to transfer to
          {{
            budget && budget.budgetDisplayName
              ? `'${budget.budgetDisplayName}'`
              : "your rewards account"
          }}
          <!-- Funding {{ budget ? budget.budgetDisplayName : "budget" }} -->
        </v-card-title>
        <!-- <v-card-subtitle
          v-if="
            budget.BudgetActivities &&
              budget.BudgetActivities.filter(
                x =>
                  x.type == 'ACH' &&
                  x.stripeStatus == 'pending' &&
                  x.status == 'Active'
              ).length > 0
          "
          class="pl-0"
          ><v-icon color="error">mdi-alert</v-icon>There is a
          <a
            @click="loadBudgetActivity"
            :class="{ 'black--text': $route.name !== 'budgets' }"
            >pending ACH transfer</a
          >
          for this budget</v-card-subtitle
        >
        <v-card-subtitle class="pt-1 pl-0"
          >Current balance: ${{
            budget && budget.formattedBudgetBalance
              ? formatCurrency(truncateNum(budget.formattedBudgetBalance, 2))
              : "0.00"
          }}</v-card-subtitle
        >
        <v-card-subtitle
          class="pt-0 pl-0"
          v-if="budget && budget.budgetBalance > 0"
          >Balance after funding: ${{
            formatCurrency(truncateNum(futureBudgetBalance, 2))
          }}</v-card-subtitle
        >
        <v-card-subtitle class="pt-0 pl-0"
          >Amount to fund: ${{
            amount ? formatCurrency(truncateNum(amount, 2)) : "0.00"
          }}</v-card-subtitle
        >
        <v-card-subtitle class="pt-0 pl-0"
          >Transaction fee: ${{
            formatCurrency(truncateNum(fee, 2))
          }}</v-card-subtitle
        > -->
        <v-form v-model="form">
          <v-select
            :items="banks.filter(x => x.status == 'verified')"
            outlined
            attach
            dense
            color="brandCyan"
            :menu-props="{ offsetY: true }"
            label="Saved Bank Account"
            item-text="name"
            return-object
            v-model="bank"
            class="mt-5"
            hide-details
            :rules="[v => !!v || 'A bank account is required']"
          ></v-select>
          <v-btn
            text
            small
            color="primary"
            class="mt-1 mb-2 px-1"
            @click="resetForm('manage')"
            >Manage my accounts</v-btn
          >
          <h4
            v-if="
              fundingLimitValidation !== true ||
                (fundingLimit && fundingLimit.cooldownActive)
            "
          >
            <v-icon color="error" class="mr-2">mdi-alert</v-icon>Why can't I
            fund more right now?
          </h4>
          <p class="mt-2" v-if="fundingLimit && fundingLimit.cooldownActive">
            {{ fundingLimitValidation }} If you have questions, please contact
            us at
            <a class="text-decoration-none" href="mailto:help@wewhistle.com"
              >help@wewhistle.com</a
            >.
          </p>
          <p class="mt-2" v-else-if="fundingLimitValidation !== true">
            Whistle's banking partners require a business verification process
            to be completed before adding more funds to your budgets. This
            process can be completed on the
            <span
              @click="loadKYB"
              :class="{
                'primary--text': canCompleteKYB,
                'cursor-pointer': canCompleteKYB
              }"
              >Payment Admin page</span
            >
            by a Whistle Finance Admin. Your Whistle CSM can help you through
            this process, as well as answer any questions you have.
          </p>
          <v-text-field
            v-if="userProfile && !userProfile.businessEmail"
            label="Email address to send receipt to"
            v-model="email"
            outlined
            dense
            color="brandCyan"
            class="mt-2"
            :rules="[
              v => !!v || 'An email is required',
              v =>
                !!(v && emailRegex.test(v)) || 'The email does not appear valid'
            ]"
          />
          <div class="d-flex justify-space-between">
            <p class="mb-0 mt-2 mr-3">
              {{ givingMode ? "Amount to give" : "Amount to transfer" }}
            </p>
            <v-text-field
              placeholder="0"
              prefix="$"
              v-model="amount"
              type="number"
              color="brandCyan"
              outlined
              dense
              class=""
              style="max-width: 50%; width: 50%; min-width: 120px;"
              @wheel="$event.target.blur()"
              :rules="[
                v => !!v || 'Amount is required',
                v =>
                  !!(v && parseFloat(v) >= 0.5) ||
                  'Amount should be greater than $0.50',
                v =>
                  !!(
                    v &&
                    (!v.toString().includes('.') ||
                      (v.toString().includes('.') &&
                        v.substring(v.indexOf('.') + 1).length < 3))
                  ) || `Payment amount shouldn't be longer than 2 decimals.`,
                v =>
                  !!(v && parseFloat(v) <= 25000) ||
                  'Amount should be smaller than 25k',
                fundingLimitValidation
              ]"
            />
          </div>
          <div class="d-flex justify-space-between" v-if="!givingMode">
            <p class="mb-0 mt-2 mr-3">
              Current rewards account balance
            </p>
            <p class="mb-0">
              {{ currentBudgetBalance }}
            </p>
          </div>
          <div v-if="!givingMode" class="d-flex full-width justify-end my-4">
            <v-divider style="max-width: 30%; min-width: 100px;" />
          </div>
          <div class="d-flex justify-space-between" v-if="!givingMode">
            <p class="mb-0 mr-3">
              Rewards account after transfer
            </p>
            <p class="mb-0 font-weight-bold">
              {{ futureBudgetBalance }}
            </p>
          </div>
          <div class="d-flex justify-space-between my-4" v-if="!givingMode">
            <p class="mb-0 mt-2 mr-3">
              ACH transfer fee
            </p>
            <p class="mb-0">
              {{ fee }}
            </p>
          </div>
          <div v-if="!givingMode" class="d-flex full-width justify-end my-4">
            <v-divider style="max-width: 30%; min-width: 100px;" />
          </div>
          <div class="d-flex justify-space-between my-4" v-if="!givingMode">
            <p class="mb-0 mt-2 mr-3">
              Total amount to be withdrawn via ACH
            </p>
            <p class="mb-0 font-weight-bold">
              {{ calculatedTotalCharge }}
            </p>
          </div>
          <!-- <v-text-field
            label="Amount to transfer"
            v-model="amount"
            type="number"
            outlined
            :class="!(userProfile && !userProfile.businessEmail) ? 'mt-6' : ''"
            @wheel="$event.target.blur()"
            :rules="[
              v => !!v || 'Amount is required',
              v =>
                !!(v && parseFloat(v) >= 0.5) ||
                'Amount should be greater than $0.50',
              v =>
                !!(
                  v &&
                  (!v.toString().includes('.') ||
                    (v.toString().includes('.') &&
                      v.substring(v.indexOf('.') + 1).length < 3))
                ) || `Payment amount shouldn't be longer than 2 decimals.`,
              v =>
                !!(v && parseFloat(v) <= 999999.99) ||
                'Amount should be smaller',
              fundingLimitValidation
            ]"
          /> -->

          <v-checkbox
            v-model="authorization"
            @change="logAuthDate"
            color="brandCyan"
            label="I authorize Whistle Systems to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits."
          ></v-checkbox>
          <span class="darkGrey--text"
            >We recommend saving a copy of this for your records. You will also
            receive an emailed receipt.</span
          >
          <div class="d-flex justify-center">
            <v-btn
              color="brandCyan"
              class="mb-5 mt-6 white--text"
              width="140"
              depressed
              :disabled="!form || !authorization"
              @click="createStripePayment"
              >Transfer</v-btn
            >
          </div>
        </v-form>
      </div>
      <v-row
        v-else-if="currentSlide == keys.loading"
        key="1"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <v-card-title>Processing the payment...</v-card-title>
        <Robin
          :showText="false"
          :width="120"
          :height="120"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == keys.success"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <v-card-title>All done!</v-card-title>
        <caption class=" mt-2 align-text-left">
          The ACH transfer has started. This can take up to 5 business days. You
          will receive an email confirmation once the transfer is complete.
        </caption>
        <p class="mt-2 full-width align-text-left">
          If you need to contact us about your ACH transfer, we recommend one of
          the following methods.
        </p>
        <p class="pl-4 align-text-left full-width">
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p class="pl-4 align-text-left full-width">
          Phone (Toll Free) - <b>(855) 264-3329</b>
        </p>

        <!-- <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="backflip"
          :loop="false"
        /> -->

        <v-btn
          color="brandCyan"
          class="my-8 white--text"
          depressed
          width="140"
          @click="resetForm('reset')"
          >Done</v-btn
        >
      </v-row>
      <v-row
        v-else-if="currentSlide == keys.error"
        key="8"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
      >
        <v-card-title class="title-text word-break full-width">{{
          errorMessage
            ? errorMessage
            : "There was an error creating the ACH bank transfer"
        }}</v-card-title>

        <p class="pl-4 full-width align-text-left">
          If you continue to have trouble, feel free to reach out to customer
          service via one of the following methods...
        </p>
        <p class="pl-4 align-text-left full-width">
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p class="pl-4 align-text-left full-width">
          Phone (Toll Free) - <b>(855) 264-3329</b>
        </p>
        <div class="d-flex mt-4">
          <v-btn
            color="brandCyan"
            class="mr-4 white--text"
            depressed
            width="150"
            @click="(currentSlide = keys.payment), (errorMessage = null)"
            >Go back</v-btn
          >
          <v-btn
            color="brandCyan"
            depressed
            class="white--text"
            width="150"
            @click="resetForm"
            >Exit</v-btn
          >
        </div>
      </v-row>
    </transition>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
import UserService from "@/services/UserService";

import Robin from "@/components/Robin";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";
import { emailRegex } from "@/shared_data/data.js";

import { mapState } from "vuex";
import moment from "moment";
import currency from "currency.js";

export default {
  name: "StripeACHPayment",
  props: {
    budget: Object,
    banks: Array,
    fundingLimit: Object,
    canCompleteKYB: Boolean,
    source: String,
    givingMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Robin
  },
  data() {
    return {
      currentSlide: 0,
      keys: {
        payment: 0,
        loading: 1,
        success: 2,
        error: 3
      },
      slideDirection: "topic-left",
      form: null,
      amount: null,
      email: null, //only used for SMS users
      bank: null,
      authorization: false,
      authorizationDate: null,
      ip: null,
      errorMessage: null,
      emailRegex
    };
  },
  created() {
    console.log("Created stripe ach payment widget");
    this.getIpAddress();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    resetForm(screen = null) {
      this.$emit("scrollToTop");
      this.$emit("reset", screen);
      // this.$emit("get-cards");
    },
    previousSlide() {
      // this.$emit("scrollToTop");
      this.resetForm();
    },
    logAuthDate() {
      this.authorizationDate = new Date();
    },
    loadBudgetActivity() {
      this.$emit("load-budget-activity");
    },
    createStripePayment() {
      var startTime = new Date();
      this.currentSlide = this.keys.loading;
      // var bankId = this.bank.id;
      // console.log("Bank ", this.bank.bankId);
      PaymentService.createStripePayment(
        this.budget.clientId,
        this.bank.id, //token
        this.amount,
        this.fee,
        this.budget.budgetId,
        this.bank.name, //description for budget activity
        this.givingMode
          ? `Donate to ${
              this.userProfile && this.userProfile.Client
                ? this.userProfile.Client.clientName
                : " client."
            }`
          : "Funding Whistle Budget '" +
              (this.budget.budgetDisplayName
                ? this.budget.budgetDisplayName
                : this.budget.budgetName) +
              "'" +
              (this.fee > 0 ? " (inclusive of fees)" : ""), //receiptDescription
        this.email || this.userProfile.businessEmail, //email
        this.authorizationDate, //auth date
        this.ip, // IP
        "ACH", //type
        true, //using saved card token?
        this.source, // source for platform activity,
        this.givingMode, //giving boolean
        this.magicLinkToken
      ).then(resp => {
        console.log("Got payment resp: ", resp);
        // resp.forEach((x) => (x.name = x.bank_name + "..." + x.last4));
        // this.banks = resp;
        var timeDiff = moment().diff(moment(startTime));
        if (timeDiff > 1500) timeDiff = 1500;
        setTimeout(() => {
          if (resp.error) {
            if (
              typeof resp.error == "string" &&
              resp.error.includes("cooldown")
            ) {
              this.errorMessage =
                "You have too many funding attempts. Please try again later";
              this.$emit("get-funding-limit");
            }
            this.currentSlide = this.keys.error;
          } else if (resp.status == "failed") {
            this.errorMessage = "The ACH Bank Transfer failed";
            this.currentSlide = this.keys.error;
          } else {
            this.currentSlide = this.keys.success;
            this.getBudgets();
          }
        }, 1500 - timeDiff);
      });
    },
    getIpAddress() {
      UserService.getUserIpAddress().then(resp => {
        console.log("Got IP resp: ", resp);
        this.ip = resp.ip;
      });
    },
    loadKYB() {
      if (this.canCompleteKYB)
        this.$router.push({
          name: "paymentadmin",
          params: {
            preload: "kyb",
            previousRoute: this.$route.name
          }
        });
    },
    getBudgets() {
      this.$emit("get-budgets");
    }
  },
  computed: {
    ...mapState(["userProfile", "permissions", "magicLinkToken"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    currentAmount() {
      let amt = currency(0);
      if (this.amount) amt = currency(this.amount);
      return amt.format({ symbol: "$ " });
    },
    currentBudgetBalance() {
      let balance = currency(0);
      if (this.budget && this.budget.budgetBalance)
        balance = currency(this.budget.budgetBalance);
      return balance.format({ symbol: "$ " });
    },
    futureBudgetBalance() {
      return currency(this.currentBudgetBalance)
        .add(this.currentAmount)
        .format({ symbol: "$ " });
    },
    fee() {
      return currency(0).format({ symbol: "$ " });
    },
    calculatedTotalCharge() {
      return currency(this.currentAmount)
        .add(this.fee)
        .format({ symbol: "$ " });
    },
    fundingLimitValidation() {
      if (this.fundingLimit && this.fundingLimit.cooldownActive) {
        if (this.fundingLimit.cooldownExpirationLocal)
          return `The maximum number of funding attempts in a short timespan has been reached. Please try again at any point after ${this.fundingLimit.cooldownExpirationLocal}.`;
        else
          return (
            "The maximum number of funding attempts in a short timespan has been reached. Please try again in " +
            this.fundingLimit.cooldownHoursRemaining +
            (this.fundingLimit.cooldownHoursRemaining == 1
              ? " hour."
              : " hours.")
          );
      } else if (
        // this.fundingLimit.canFundMore &&
        this.fundingLimit &&
        !this.fundingLimit.passedKYB &&
        this.amount &&
        this.amount > this.fundingLimit.allowedToFund
      ) {
        return (
          "The funding limit has been exceeded. You can fund up to $" +
          numberWithCommas(truncateNumber(this.fundingLimit.allowedToFund, 2))
        );
      }
      return true;
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}
</style>

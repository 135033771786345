<template>
  <v-card elevation="0" rounded="0" flat>
    <MarqetaDisplayCard
      :token="token"
      :cardOnly="cardOnly"
      :elevation="elevation"
      :magicLink="magicLink"
    />
  </v-card>
</template>

<script>
import MarqetaDisplayCard from "@/components/wallet/MarqetaDisplayCard.vue";

export default {
  name: "MarqetaVirtualCardHosted",

  props: {},
  components: {
    MarqetaDisplayCard
  },
  data() {
    return {
      token: null,
      cardOnly: false,
      elevation: false
    };
  },
  created() {
    this.token = this.$route.query.token;
    this.cardOnly = !!(
      this.$route.query.cardOnly &&
      (this.$route.query.cardOnly == "true" ||
        this.$route.query.cardOnly == "1")
    );
    this.elevation = !(
      this.$route.query.elevation && this.$route.query.elevation == "0"
    );
    this.magicLink =
      this.$route.query[`x-whistle-oauth`] &&
      this.$route.query[`x-whistle-oauth`] == "1";

    window.divNode = window.document.createElement("div");
    window.divNode.setAttribute("id", "tempFreshworks");
    window.divNode.innerHTML =
      "<style>#launcher-frame { right: -105px !important; }</style>";
    window.document.body.appendChild(window.divNode);
  },
  destroyed() {
    window.divNode.parentNode.removeChild(window.divNode);
  },
  updated() {},

  methods: {},
  computed: {}
};
</script>

<style scoped></style>

<template>
  <div class="full-width">
    <div class="d-flex justify-space-between align-center mx-2 mb-4">
      <v-btn text color="brandCyan" @click="close"
        ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
      >
      <v-text-field
        outlined
        placeholder="Search"
        prepend-icon="mdi-magnify"
        clearable
        single-line
        dense
        hide-details
        v-model="table.debounceSearch"
        class="shrink"
        color="brandCyan"
      ></v-text-field>
    </div>
    <v-data-table
      :headers="budgetColumns"
      :items="table.rows"
      :search="table.search"
      item-key="budgetActivityId"
      :items-per-page="table.itemsPerPage"
      :footer-props="table.footerProps"
      :options.sync="table.options"
      :server-items-length="table.total"
      :loading="table.loading"
      must-sort
      sort-by="createdAt"
      sort-desc
      class="elevation-0 full-width budget-table"
      loading-text="Loading funding requests..."
      no-data-text="No requests found"
      color="brandCyan"
    >
      <template v-slot:[`item.amount`]="{ item }">
        <span>{{
          formatCurrency(
            item.amount,
            item.awardId === 1 || item?.Budget?.awardId === 1
          )
        }}</span>
      </template>
      <!-- <template v-slot:[`item.formattedStatus`]="{ item }">
        <div class="d-flex align-center">
          <v-icon
            v-if="item.formattedStatus == 'Complete'"
            class="mr-2"
            color="success"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon
            v-else-if="item.formattedStatus == 'Pending'"
            class="mr-2"
            color="orange"
            >mdi-clock-outline</v-icon
          >
          <v-icon
            v-else-if="item.formattedStatus == 'Request Pending'"
            class="mr-2"
            color="orange"
            >mdi-account-clock</v-icon
          >

          <span>{{ item.formattedStatus }}</span>
        </div>
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center">
          <v-btn
            width="100"
            class="mr-2"
            outlined
            color="red"
            :disabled="loadingRequestId !== null"
            :loading="loadingRequestId == item.budgetActivityId"
            @click="
              declineRequest(
                item.budgetActivityId,
                item.budgetId,
                item.sourceBudgetId
              )
            "
            >Decline</v-btn
          >
          <v-btn
            width="100"
            color="primary"
            depressed
            :disabled="loadingRequestId !== null"
            @click="loadBudgetTransfer(item)"
            >Accept</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import Robin from "@/components/Robin";
import BudgetService from "@/services/BudgetService";
import WalletService from "@/services/WalletService";

import { debounce } from "@/shared_data/functions";

import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";

export default {
  name: "BudgetActivityRequests",
  props: { budgets: Array, clientId: Number },
  components: {
    // Robin,
  },
  data() {
    return {
      loadingRequestId: null,
      budgetColumns: [
        { text: "Date", value: "createdAt", width: "15%" },
        { text: "Budget", value: "Budget.budgetDisplayName", width: "20%" },
        // { text: "Type", value: "type", width: "10%" },
        { text: "Description", value: "description", width: "30%" },
        { text: "Amount", value: "amount", width: "15%" },
        {
          text: "",
          value: "actions",
          width: "25%",
          align: "center",
          sortable: false
        }
      ],
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        rows: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestSort: null
      }
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    async getFundingRequests(reset = true) {
      try {
        this.table.loading = true;
        if (reset) {
          this.table.options.page = 1;
          this.table.rows = [];
          this.table.total = 0;
        }
        let options = this.table.options;
        let page = this.table.options.page;
        let limit = options.itemsPerPage; //page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        let search = this.table.search;
        let sort =
          (options?.sortBy?.[0] || "createDate") +
          (options?.sortDesc?.[0] === false ? " ASC" : " DESC");
        this.table.latestSort = sort;

        let params = {
          clientId: this.clientId,
          offset,
          limit,
          search,
          sort
        };

        const response = await BudgetService.getBudgetActivityRequestsV2(
          params,
          false
        );
        if (
          // this.userFilterValue != filterValue ||
          sort != this.table.latestSort ||
          this.clientId != params.clientId ||
          page != this.table.options.page ||
          this.table.search != params.search ||
          limit != this.table.options.itemsPerPage
        ) {
          console.log("Ignoring response", {
            // filter: this.userFilterValue != filterValue,
            sort: sort != this.table.latestSort,
            clientId: this.clientId != params.clientId,
            search: this.table.search != params.search
          });
          return;
        }
        console.log("Funding requests", response);
        this.table.total = response.result.count;
        this.$emit("update-total", this.table.total);
        this.table.rows = response.result.rows.map(x => {
          x.createdAt = moment(x.createdAt)
            .utc()
            .local()
            .format("MMM DD, YYYY");
          return x;
        });
        this.table.loading = false;
      } catch (e) {
        console.log("Error getting budget funding requests", e);
        this.table.loading = false;
      }
    },
    formatCurrency(amount, cash = false) {
      return currency(amount, { symbol: cash ? "$" : "" }).format();
    },
    close() {
      this.$emit("close");
    },
    declineRequest(id, budgetId, sourceId) {
      this.loadingRequestId = id;
      // we want to mark it as declined and then we want to avoid refreshing all budgets again and again
      // So we locally update the variable
      WalletService.updateBudgetFundingRequest(id, "Declined").then(resp => {
        console.log("Got response from updating activity: ", resp);
        this.loadingRequestId = null;
        this.getFundingRequests(false);
      });
    },
    loadBudgetTransfer(activity) {
      // we have to find the source and destination budgets to send
      // We have to find the source and destination
      var destination = this.budgets.find(x => x.budgetId == activity.budgetId);
      var source = this.budgets.find(
        x => x.budgetId == activity.sourceBudgetId
      );
      this.$emit("load-budget-transfer", {
        source: source,
        destination: destination,
        amount: activity.amount,
        budgetActivityId: activity.budgetActivityId
      });
    }
  },
  computed: {
    ...mapState([])
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }

        this.getFundingRequests(reset);
      },
      deep: true
    },
    clientId: {
      handler() {
        console.log("Clientid watcher");
        this.getFundingRequests(true);
      }
    },
    "table.debounceSearch": debounce(function(newVal) {
      this.table.search = newVal;
      this.getFundingRequests(true);
    }, 500)
  }
};
</script>

<style scoped>
.budget-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.budget-table >>> .v-data-table-header {
  background-color: white;
}
</style>

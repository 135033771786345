<template>
  <div>
    <v-toolbar dark color="primary" rounded="0" flat>
      <v-toolbar-title>Send Payment </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="reset">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div
      class="d-flex justify-center full-height"
      justify="center"
      height="100%"
    >
      <v-col
        v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
        md="3"
        lg="3"
        class="d-flex flex-column align-start full-height mr-6 text-left word-break"
      >
        <v-btn
          text
          color="primary"
          class="mt-4"
          :class="{ hidden: !(slideKey > 1 && slideKey < keys.send) }"
          @click="goToPreviousSlide"
          ><v-icon>mdi-chevron-left</v-icon>Back</v-btn
        >
        <!-- <p class="grey--text mt-8">Activity card preview</p> -->

        <div class="d-flex mt-16">
          <span class="mt-2 mr-2 darkGrey--text">Selected:</span>
          <v-chip class="ma-1" @click="goToSlide(keys.people)">
            <v-icon class="mr-2">mdi-account-multiple</v-icon>
            <v-progress-circular
              indeterminate
              :size="20"
              :width="2"
              color="brandCyan"
              class="mr-1"
              v-if="loading.distinctUsers"
            ></v-progress-circular>
            <span v-else
              >{{ paymentAudienceCount }} recipient{{
                paymentAudienceCount == 1 ? "" : "s"
              }}{{
                slidePeople.activeGroup == 1
                  ? " in " + slidePeople.selected.length
                  : ""
              }}
              {{
                slidePeople.activeGroup == 1
                  ? slidePeople.selected.length == 1
                    ? "group"
                    : "groups"
                  : ""
              }}</span
            >
          </v-chip>
        </div>
        <p class="darkGrey--text mt-8">
          Payment Memo: <b>{{ slideDetails.description }}</b>
        </p>
        <p class="darkGrey--text mt-5">
          Payment Type:
          <b>{{
            slideDetails.paymentType
              ? slideDetails.paymentType[0].toUpperCase() +
                slideDetails.paymentType
                  .substring(1)
                  .replace("_", " ")
                  .toLowerCase()
              : ""
          }}</b>
        </p>
        <!-- <p class="darkGrey--text mt-5">
          Classification:
          <b>{{
            slideClassification.classification
              ? slideClassification.classification == "Other"
                ? slideClassification.classificationText
                : slideClassification.classification
              : ""
          }}</b>
        </p> -->
        <p
          class="mt-5 darkGrey--text"
          v-if="slideDetails.paymentType == 'PER_DIEM'"
        >
          Amount per person per day:
          <b> ${{ formatCurrency(slidePerDiem.amount || 0) }} </b>
        </p>
        <p
          class="mt-5 darkGrey--text"
          v-if="slideDetails.paymentType == 'PROVISIONAL'"
        >
          Amount per payment:
          <b> ${{ formatCurrency(slideProvisional.amount || 0) }} </b>
        </p>
        <p
          class="mt-5 darkGrey--text"
          v-if="slideDetails.paymentType == 'PROVISIONAL'"
        >
          Total number of payments:
          <b> {{ scheduledPaymentDates.length }} </b>
        </p>
        <p class="darkGrey--text mt-5">
          Total amount to be paid:
          <b
            >{{ totalBeingSpentFormatted }} {{ awardUnits(totalBeingSpent) }}</b
          >
        </p>
        <p
          class="darkGrey--text mt-5"
          v-if="
            slideDetails.paymentType == 'PROVISIONAL' &&
              !slideProvisional.customMCCToggle
          "
        >
          Number of payment restriction presets:
          <b>
            {{
              slideProvisional.selectedPresets
                .map(x => x.mccGroupDefinitionId)
                .includes(-1)
                ? 0
                : slideProvisional.selectedPresets.length
            }}</b
          >
        </p>
        <p
          class="darkGrey--text mt-5"
          v-else-if="
            slideDetails.paymentType == 'PROVISIONAL' &&
              slideProvisional.customMCCToggle
          "
        >
          Number of payment restriction:
          <b> {{ slideProvisional.selectedMCC.length }}</b>
        </p>
        <p v-if="selectedBudget" class="darkGrey--text mb-1 mt-5">Budget:</p>
        <v-card width="100%" v-if="selectedBudget" class="text-left pb-4">
          <v-card-title>{{ selectedBudget.displayName }}</v-card-title>
          <v-card-text class="font-weight-bold pb-0"
            >Current Balance: {{ budgetBalanceFormatted }}
            {{ awardUnits(budgetBalance) }}</v-card-text
          >
          <v-card-text class="font-weight-bold pb-0" v-if="totalBeingSpent > 0"
            >Remaining after payment:
            {{ amountRemainingInBudgetFormatted }}
            {{ awardUnits(amountRemainingInBudget) }}</v-card-text
          >
        </v-card>
      </v-col>
      <v-col md="6" kg="6" class=" d-flex flex-column align-start">
        <v-container class="mb-2 d-flex align-center justify-space-between">
          <div class="d-flex">
            <!-- First two bubbles are there for all types -->
            <div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                :class="{
                  'accent-background': slideKey >= 1,
                  'grey-background': 1 > slideKey
                }"
                @click="goToSlide(keys.people)"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.details)"
                :class="{
                  'accent-background': slideKey >= 2,
                  'grey-background': 2 > slideKey
                }"
              ></div>
            </div>
            <!-- Types for comp and noncash (standard payments) -->
            <div
              v-if="
                slideDetails.paymentType == 'COMPENSATION' ||
                  slideDetails.paymentType == 'NON_CASH' ||
                  slideDetails.paymentType == null
              "
            >
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.amount)"
                :class="{
                  'accent-background': slideKey >= 3,
                  'grey-background': 3 > slideKey
                }"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.review)"
                :class="{
                  'accent-background': slideKey >= keys.review,
                  'grey-background': keys.review > slideKey
                }"
              ></div>
            </div>
            <!-- Buttons for per diems -->
            <div v-if="slideDetails.paymentType == 'PER_DIEM'">
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.perDiemDetails)"
                :class="{
                  'accent-background': slideKey >= keys.perDiemDetails,
                  'grey-background': keys.perDiemDetails > slideKey
                }"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
                @click="goToSlide(keys.perDiemDate)"
                :class="{
                  'accent-background': slideKey >= keys.perDiemDate,
                  'grey-background': keys.perDiemDate > slideKey
                }"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
                @click="goToSlide(keys.review)"
                :class="{
                  'accent-background': slideKey >= keys.review,
                  'grey-background': keys.review > slideKey
                }"
              ></div>
            </div>
            <!-- Types for comp and noncash (standard payments) -->
            <div v-if="slideDetails.paymentType == 'PROVISIONAL'">
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.provisionalAmount)"
                :class="{
                  'accent-background': slideKey >= keys.provisionalAmount,
                  'grey-background': keys.provisionalAmount > slideKey
                }"
              ></div>
              <div
                v-if="slideProvisional.customMCCToggle"
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.mccCustom)"
                :class="{
                  'accent-background': slideKey >= keys.mccCustom,
                  'grey-background': keys.mccCustom > slideKey
                }"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.provisionalSchedule)"
                :class="{
                  'accent-background': slideKey >= keys.provisionalSchedule,
                  'grey-background': keys.provisionalSchedule > slideKey
                }"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.expiration)"
                :class="{
                  'accent-background': slideKey >= keys.expiration,
                  'grey-background': keys.expiration > slideKey
                }"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.classify)"
                :class="{
                  'accent-background': slideKey >= keys.classify,
                  'grey-background': keys.classify > slideKey
                }"
              ></div>
              <div
                class="mr-2 pa-3 rounded-circle d-inline-block cursor-pointer"
                @click="goToSlide(keys.review)"
                :class="{
                  'accent-background': slideKey >= keys.review,
                  'grey-background': keys.review > slideKey
                }"
              ></div>
            </div>
          </div>
          <v-btn
            color="primary"
            @click="goToNextSlide"
            :disabled="disableContinueButton"
            >{{
              route[route.findIndex(x => x == slideKey) + 1] == keys.review
                ? "Review"
                : slideKey == keys.review
                ? "Send"
                : slideKey == keys.send || slideKey == keys.sent
                ? "Done"
                : "Continue"
            }}</v-btn
          >
        </v-container>
        <transition :name="slideDirection" mode="out-in">
          <div v-if="slideKey === keys.people" key="0" class="full-width">
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's start with who you want to send a payment to</span
              >
            </div>
            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slidePeople.valid"
                class="full-width"
              >
                <!-- <div class="d-flex align-center">
                <v-combobox
                  v-model="slidePeople.selected"
                  :items="slidePeople.users"
                  item-value="userId"
                  item-text="displayName"
                  label="To:"
                  multiple
                  chips
                  deletable-chips
                  @keydown.enter="$event.target.blur()"
                  @keypress.enter="$event.target.blur()"
                  @keyup.enter="$event.target.blur()"
                ></v-combobox>
              </div> -->
                <div class="d-flex justify-space-between align-center">
                  <v-btn
                    :text="$vuetify.breakpoint.xs ? false : true"
                    :icon="$vuetify.breakpoint.xs ? true : false"
                    color="primary"
                    class="hidden"
                    ><v-icon>mdi-plus</v-icon
                    ><span v-if="!$vuetify.breakpoint.xs"
                      >Create
                      {{
                        slidePeople.activeGroup == 0 ? "User" : "Group"
                      }}</span
                    ></v-btn
                  >

                  <div class="people-group-button-oval">
                    <v-chip
                      @click="changeAudience(0)"
                      :color="
                        slidePeople.activeGroup == 0 ? 'white' : 'lightGrey'
                      "
                      class="px-4 audience-button"
                      :class="{
                        'audience-button': slidePeople.activeGroup == 0
                      }"
                    >
                      People
                    </v-chip>
                    <v-chip
                      @click="changeAudience(1)"
                      :color="
                        slidePeople.activeGroup == 1 ? 'white' : 'lightGrey'
                      "
                      class="px-4"
                      :class="{
                        'audience-button': slidePeople.activeGroup == 1
                      }"
                    >
                      Groups
                    </v-chip>
                  </div>
                  <v-btn
                    :class="{
                      hidden: !(
                        (slidePeople.activeGroup == 0 &&
                          permissions.includes('users:create:user')) ||
                        (slidePeople.activeGroup == 1 &&
                          permissions.includes('groups:create:group'))
                      )
                    }"
                    :text="$vuetify.breakpoint.xs ? false : true"
                    :icon="$vuetify.breakpoint.xs ? true : false"
                    color="primary"
                    @click="
                      slidePeople.activeGroup == 0
                        ? (slidePeople.displayCreateUser = true)
                        : (slidePeople.displayCreateGroup = true)
                    "
                    ><v-icon>mdi-plus</v-icon
                    ><span v-if="!$vuetify.breakpoint.xs"
                      >Create
                      {{
                        slidePeople.activeGroup == 0 ? "User" : "Group"
                      }}</span
                    ></v-btn
                  >
                </div>
                <v-autocomplete
                  :items="clients"
                  item-text="formattedName"
                  item-value="clientId"
                  label="Client*"
                  v-model="clientId"
                  v-if="userProfile.clientId === 1"
                  attach
                  :menu-props="{ offsetY: true }"
                  @change="updateClientId"
                ></v-autocomplete>
                <v-text-field
                  label="Search"
                  v-model="slidePeople.debounceSearch"
                >
                  <template v-slot:append-outer> </template>
                </v-text-field>
                <div class="d-flex">
                  <span
                    class="mt-2 mr-2 darkGrey--text"
                    v-if="slidePeople.selected.length > 0"
                    >To:</span
                  >
                  <div class="d-flex align-center flex-wrap">
                    <div
                      v-for="(user, index) in slidePeople.selected"
                      :key="user.userId"
                    >
                      <v-chip
                        class="ma-1"
                        close
                        @click:close="removeUserFromSelected(index)"
                        >{{ user.displayName }}</v-chip
                      >
                    </div>
                  </div>
                </div>
                <div class="text-left mt-2 ">
                  <span class="darkGrey--text">Directory:</span>
                  <div v-if="slidePeople.activeGroup == 0">
                    <UserTable
                      ref="user-table"
                      :selected="slidePeople.selected"
                      :search="slidePeople.search"
                      @select-item="addUserToSelected($event, false)"
                    />
                    <!-- <v-data-table
                      :key="1"
                      v-model="slidePeople.selected"
                      :headers="audienceHeaders"
                      :items="visibleAudience"
                      :options.sync="userTable.options"
                      :server-items-length="userTable.total"
                      :search="slidePeople.search"
                      item-key="userId"
                      :items-per-page="userTable.itemsPerPage"
                      class="elevation-0 full-width mt-3 cursor-pointer"
                      :loading="slidePeople.loadingUsers"
                      loading-text="Loading... Please wait"
                      hide-default-header
                      no-data-text="No people available"
                      no-results-text="No people found"
                      @click:row="addUserToSelected($event, false)"
                      mobile-breakpoint="0"
                      :footer-props="userTable.footerProps"
                    >
                      <template v-slot:[`item.info`]="{ item }">
                        <v-btn icon @click.stop="previewItem(item)"
                          ><v-icon color="grey"
                            >mdi-information-outline</v-icon
                          ></v-btn
                        >
                      </template>
                      <template v-slot:[`item.select`]="{ item }">
                        <v-simple-checkbox
                          color="brandCyan"
                          @click.stop="addUserToSelected(item, false)"
                          :ripple="false"
                          :value="
                            slidePeople.selected.find(
                              x => x.userId == item.userId
                            )
                              ? true
                              : false
                          "
                        />
                      </template>
                    </v-data-table> -->
                  </div>
                  <div v-else>
                    <GroupTable
                      ref="group-table"
                      :selected="slidePeople.selected"
                      :search="slidePeople.search"
                      @select-item="addUserToSelected($event, false)"
                      @update-item="updateSelectedItem"
                    />
                    <!-- <v-data-table
                      :key="2"
                      v-model="slidePeople.selected"
                      :headers="audienceHeaders"
                      :items="visibleAudience"
                      :options.sync="groupTable.options"
                      :server-items-length="groupTable.total"
                      :search="slidePeople.search"
                      item-key="groupId"
                      :items-per-page="groupTable.itemsPerPage"
                      class="elevation-0 full-width mt-3 cursor-pointer"
                      :loading="groupTable.loading"
                      loading-text="Loading... Please wait"
                      hide-default-header
                      no-data-text="No groups available"
                      no-results-text="No groups found"
                      @click:row="addUserToSelected($event, false)"
                      mobile-breakpoint="0"
                      :footer-props="groupTable.footerProps"
                    >
                      <template v-slot:[`item.numUsers`]="{ item }">
                        <v-icon class="mr-2">mdi-account-multiple</v-icon>
                        <span class="mr-5">{{
                          ((item && item.GroupAssignments) || []).length
                        }}</span>

                      </template>
                      <template v-slot:[`item.info`]="{ item }">
                        <v-btn icon @click.stop="previewItem(item)"
                          ><v-icon color="grey"
                            >mdi-information-outline</v-icon
                          ></v-btn
                        >
                      </template>
                      <template v-slot:[`item.select`]="{ item }">
                        <v-simple-checkbox
                          color="brandCyan"
                          @click.stop="addUserToSelected(item, false)"
                          :ripple="false"
                          :value="
                            slidePeople.selected.find(
                              x => x.groupId == item.groupId
                            )
                              ? true
                              : false
                          "
                        />
                      </template>
                    </v-data-table> -->
                  </div>
                </div>
              </v-form>
            </v-card>
            <v-bottom-sheet v-model="slidePeople.infoSheet" inset width="500">
              <v-sheet class="text-center" height="475px">
                <!-- <v-btn
                class="mt-6"
                text
                color="error"
                @click="slidePeople.infoSheet = !slidePeople.infoSheet"
              >
                close
              </v-btn> -->
                <div v-if="slidePeople.selectedItem" class="pt-4">
                  <div class="d-flex align-center justify-space-between mx-6">
                    <v-btn fab color="lightGrey" small class="hidden"
                      ><v-icon>mdi-chevron-down</v-icon></v-btn
                    >
                    <v-card-title>
                      <b> {{ slidePeople.selectedItem.displayName }}</b>
                    </v-card-title>
                    <v-btn
                      fab
                      color="mediumGrey"
                      small
                      elevation="0"
                      @click="slidePeople.infoSheet = !slidePeople.infoSheet"
                      ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                    >
                  </div>
                  <div>
                    <v-img
                      v-bind:src="slidePeople.selectedItem.profilePicUrl"
                      v-if="slidePeople.selectedItem.profilePicUrl"
                      height="110"
                      width="110"
                      max-height="110"
                      max-width="110"
                      class="circle-border-radius profile-picture mx-auto"
                    />
                    <v-icon
                      v-else
                      x-large
                      class="profile-picture circle-border-radius icon-image"
                      >mdi-account</v-icon
                    >

                    <p class="mb-1 mt-4">
                      {{
                        slidePeople.selectedItem.jobTitle || "Unknown Job Title"
                      }}
                    </p>
                    <p class="mb-1">
                      {{
                        slidePeople.selectedItem.organization ||
                          "Unknown Organization"
                      }}
                    </p>
                    <p>
                      {{
                        slidePeople.selectedItem.businessCity
                          ? slidePeople.selectedItem.businessCity
                          : ""
                      }}{{
                        slidePeople.selectedItem.businessCity &&
                        slidePeople.selectedItem.businessState
                          ? ", "
                          : !slidePeople.selectedItem.businessCity &&
                            !slidePeople.selectedItem.businessState
                          ? "Unknown Location"
                          : ""
                      }}
                      {{
                        slidePeople.selectedItem.businessState
                          ? slidePeople.selectedItem.businessState
                          : ""
                      }}
                    </p>
                    <v-divider class="mx-7" />
                    <div class="d-flex ml-6 mt-6">
                      <p class="darkGrey--text mb-2">
                        Email:
                        <b class="black--text">{{
                          slidePeople.selectedItem.businessEmail
                            ? slidePeople.selectedItem.businessEmail
                            : "-"
                        }}</b>
                      </p>
                    </div>
                    <div class="d-flex ml-6">
                      <p class="darkGrey--text">
                        Phone:
                        <b class="black--text">{{
                          slidePeople.selectedItem.businessPhone
                            ? slidePeople.selectedItem.businessPhone
                            : "-"
                        }}</b>
                      </p>
                    </div>
                  </div>
                  <!-- <div v-else>
                    <div class="d-flex align-center justify-center">
                      <p class="mb-0">
                        {{
                          slidePeople.selectedItem.groupType + " group " ||
                            "Unknown Group Type "
                        }}
                      </p>
                      <v-icon x-large class="ml-2">mdi-account</v-icon>
                      <h2>
                        {{
                          slidePeople.selectedItem.GroupAssignments
                            ? slidePeople.selectedItem.GroupAssignments.length
                            : 0
                        }}
                      </h2>
                    </div>
                    <v-virtual-scroll
                      height="250"
                      class="mt-2 mb-5"
                      item-height="45"
                      :items="slidePeople.selectedItem.GroupAssignments"
                    >
                      <template v-slot:default="{ item }">
                        <v-list-item :key="item.groupAssignmentId">
                          <v-list-item-content
                            class="text-left ml-2 mt-2 text-overflow-clip"
                          >
                            <span>{{ item.User.displayName }}</span>
                          </v-list-item-content>
                          <v-list-item-content
                            class="text-left mt-2 text-overflow-clip"
                          >
                            <span>{{
                              item.User.businessEmail || item.User.businessPhone
                            }}</span></v-list-item-content
                          >
                        </v-list-item>
                        <v-divider class="mx-5" />
                      </template>
                    </v-virtual-scroll>
                  </div> -->
                  <v-btn
                    color="primary"
                    rounded
                    width="90"
                    @click="addUserToSelected(slidePeople.selectedItem, true)"
                    >Pay</v-btn
                  >
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </div>
          <div v-else-if="slideKey === keys.details" key="2" class="full-width">
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's go over the details of the payment</span
              >
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideDetails.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  outlined
                  hide-details
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->
                <v-card-title class="mt-4 pl-0"
                  >What type of payment do you want to send?</v-card-title
                >
                <v-radio-group
                  v-model="slideDetails.paymentType"
                  row
                  hide-details
                  @change="resetPaymentType"
                >
                  <v-row>
                    <v-col>
                      <v-radio
                        label="Compensation"
                        value="COMPENSATION"
                      ></v-radio>
                    </v-col>
                    <v-col>
                      <v-radio label="Per Diem" value="PER_DIEM"></v-radio
                    ></v-col>
                  </v-row>
                </v-radio-group>
                <v-radio-group
                  v-model="slideDetails.paymentType"
                  row
                  hide-details
                  class="mb-4"
                  @change="resetPaymentType"
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Provisional" value="PROVISIONAL"></v-radio
                    ></v-col>
                    <!-- <v-col>
                      <v-radio
                        label="Reimbursement"
                        value="REIMBURSEMENT"
                        disabled
                      ></v-radio
                    ></v-col>
                  -->
                    <v-col>
                      <v-radio label="Non-cash" value="NON_CASH"></v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
                <!-- <v-radio-group
                  v-model="slideDetails.paymentType"
                  row
                  hide-details
                  class="mb-4"
                  @change="resetpaymentType"
                >
                  <v-row>
                    <v-col>
                      <v-radio label="Non-cash" value="NON_CASH"></v-radio
                    ></v-col>
                  
                  </v-row>
                </v-radio-group> -->
                <v-form ref="slideDetailsForm">
                  <span
                    class="text-left"
                    v-if="slideDetails.paymentType == 'NON_CASH'"
                    >Choose the award you want to send</span
                  >
                  <v-select
                    v-if="slideDetails.paymentType == 'NON_CASH'"
                    label="Award"
                    outlined
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    class="mt-2"
                    :items="visibleAwards"
                    v-model="slideDetails.awardId"
                    item-value="awardId"
                    item-text="awardDisplayName"
                    no-data-text="No awards found"
                    :rules="[v => !!v || 'Award is required']"
                  />
                  <span
                    class="text-left"
                    v-if="
                      slideDetails.paymentType &&
                        ((slideDetails.paymentType == 'NON_CASH' &&
                          slideDetails.awardId) ||
                          slideDetails.paymentType != 'NON_CASH')
                    "
                    >Choose a budget to pay from</span
                  >
                  <v-select
                    v-if="
                      slideDetails.paymentType &&
                        ((slideDetails.paymentType == 'NON_CASH' &&
                          slideDetails.awardId) ||
                          slideDetails.paymentType != 'NON_CASH')
                    "
                    label="Budget"
                    outlined
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    class="mt-2"
                    :items="visibleBudgets"
                    v-model="slideDetails.budgetId"
                    item-value="budgetId"
                    item-text="displayNameWithBalance"
                    :no-data-text="
                      slideDetails.paymentType != 'NON_CASH'
                        ? 'No budgets found'
                        : 'No budgets found for this award'
                    "
                    :rules="[v => !!v || 'Budget is required']"
                  />
                </v-form>
              </v-form>
            </v-card>
          </div>
          <div v-else-if="slideKey === keys.amount" key="3" class="full-width">
            <div class="text-left mb-5">
              <span class="header-text">How much do you want to send?</span>
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideAmount.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->
                <!-- <v-radio-group
                  v-model="slideAmount.amountToggle"
                  column
                  hide-details
                > -->
                <div class="d-flex align-center">
                  <!-- <v-radio
                    :label="
                      selectedBudget && selectedBudget.awardId == 1
                        ? 'Send each person $'
                        : 'Send each person'
                    "
                    :value="true"
                  ></v-radio> -->
                  <p class="mt-2 darkGrey--text">
                    Send each person
                    {{
                      selectedBudget && selectedBudget.awardId == 1 ? "$" : ""
                    }}
                  </p>
                  <v-text-field
                    :placeholder="
                      selectedBudget && selectedBudget.awardId == 1
                        ? '50.00'
                        : '50'
                    "
                    class="mt-0 pt-0 ml-2 mt-3"
                    type="number"
                    :hide-details="false"
                    v-model="slideAmount.amount"
                    :rules="computedAmountRules"
                  ></v-text-field>
                </div>
                <!-- <v-radio
                    v-if="paymentAudienceCount != 1"
                    label="Send different amounts to each person"
                    :value="1"
                  ></v-radio> -->
                <!-- </v-radio-group> -->

                <p class="red--text mt-4" v-if="amountRemainingInBudget < 0">
                  The total amount entered is more than your selected budget.
                  (Budget balance
                  {{ budgetBalanceFormatted }}
                  {{ awardUnits(budgetBalance) }})
                </p>
                <div class="d-flex justify-end full-width my-5">
                  <v-btn
                    color="primary"
                    @click="goToNextSlide"
                    :disabled="disableContinueButton"
                    >Review</v-btn
                  >
                </div>
                <!-- <v-data-table
                  v-if="slideAmount.amountToggle == 1"
                  :headers="slideAmount.headers"
                  :items="slideAmount.usersWithAmounts"
                  item-key="userId"
                  :items-per-page="10"
                  class="elevation-0 full-width mt-3 cursor-pointer"
                  :no-data-text="
                    slidePeople.activeGroup == 0
                      ? 'No people found'
                      : 'No groups found'
                  "
                  mobile-breakpoint="0"
                >
                  <template v-slot:[`item.amount`]="{ item, index }">
                    <v-text-field
                      :placeholder="
                        selectedBudget && selectedBudget.awardId == 1
                          ? '50.00'
                          : '50'
                      "
                      class="mt-0 pt-0 ml-2 mt-3"
                      type="number"
                      :hide-details="false"
                      v-model="item.amount"
                      @input="updateUserAmount(item, index)"
                      @change="updateUserAmount(item, index)"
                      :rules="computedAmountRules"
                    ></v-text-field>
                  </template>
                </v-data-table> -->
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === keys.provisionalAmount"
            key="13"
            class="full-width"
          >
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's go over the details of the payment</span
              >
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideAmount.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->

                <v-text-field
                  label="Amount to send"
                  :placeholder="
                    selectedBudget && selectedBudget.awardId == 1
                      ? '$25.00'
                      : '25'
                  "
                  class="mt-8"
                  type="number"
                  :hide-details="false"
                  v-model="slideProvisional.amount"
                  outlined
                  :rules="computedAmountRules"
                ></v-text-field>

                <p class="red--text mt-4" v-if="amountRemainingInBudget < 0">
                  The total amount entered is more than your selected budget.
                  (Budget balance
                  {{ budgetBalanceFormatted }}
                  {{ awardUnits(budgetBalance) }})
                </p>
                <p class="header-text mb-1">How can this payment be used?</p>
                <p class="darkGrey--text mb-1">Choose a preset</p>
                <div class="d-flex flex-wrap">
                  <v-chip
                    class="ma-1"
                    v-for="preset in slideProvisional.presets"
                    :key="preset.mccGroupDefinitionId"
                    @click="
                      if (preset.mccGroupDefinitionId == -1) {
                        addOrRemoveMCCPreset(preset);
                      } else {
                        previewItem(preset);
                      }
                    "
                    :color="
                      slideProvisional.selectedPresets.find(
                        x =>
                          x.mccGroupDefinitionId == preset.mccGroupDefinitionId
                      )
                        ? 'primary'
                        : null
                    "
                  >
                    {{ preset.displayName ? preset.displayName : preset.name }}
                  </v-chip>
                </div>
                <p class="darkGrey--text mt-6 mb-1">Or create custom rules</p>
                <v-chip
                  class="ma-1"
                  @click="
                    (slideProvisional.customMCCToggle = !slideProvisional.customMCCToggle),
                      (slideProvisional.selectedPresets = [])
                  "
                  :color="slideProvisional.customMCCToggle ? 'primary' : null"
                >
                  Custom
                </v-chip>
                <div class="d-flex justify-end full-width my-5">
                  <v-btn
                    color="primary"
                    @click="goToNextSlide"
                    :disabled="disableContinueButton"
                    >Continue</v-btn
                  >
                </div>
                <!-- <v-data-table
                  v-if="slideAmount.amountToggle == 1"
                  :headers="slideAmount.headers"
                  :items="slideAmount.usersWithAmounts"
                  item-key="userId"
                  :items-per-page="10"
                  class="elevation-0 full-width mt-3 cursor-pointer"
                  :no-data-text="
                    slidePeople.activeGroup == 0
                      ? 'No people found'
                      : 'No groups found'
                  "
                  mobile-breakpoint="0"
                >
                  <template v-slot:[`item.amount`]="{ item, index }">
                    <v-text-field
                      :placeholder="
                        selectedBudget && selectedBudget.awardId == 1
                          ? '50.00'
                          : '50'
                      "
                      class="mt-0 pt-0 ml-2 mt-3"
                      type="number"
                      :hide-details="false"
                      v-model="item.amount"
                      @input="updateUserAmount(item, index)"
                      @change="updateUserAmount(item, index)"
                      :rules="computedAmountRules"
                    ></v-text-field>
                  </template>
                </v-data-table> -->
              </v-form>
            </v-card>
            <v-bottom-sheet
              v-model="slideProvisional.infoSheet"
              inset
              width="500"
            >
              <v-sheet class="text-center" height="475px">
                <!-- <v-btn
                class="mt-6"
                text
                color="error"
                @click="slidePeople.infoSheet = !slidePeople.infoSheet"
              >
                close
              </v-btn> -->
                <div v-if="slideProvisional.selectedGroup" class="pt-4">
                  <div class="d-flex align-center justify-space-between mx-6">
                    <v-btn fab color="lightGrey" small class="hidden"
                      ><v-icon>mdi-chevron-down</v-icon></v-btn
                    >
                    <v-card-title>
                      <b> {{ slideProvisional.selectedGroup.name }}</b>
                    </v-card-title>
                    <v-btn
                      fab
                      color="mediumGrey"
                      small
                      elevation="0"
                      @click="
                        slideProvisional.infoSheet = !slideProvisional.infoSheet
                      "
                      ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                    >
                  </div>
                  <div>
                    <div class="d-flex align-center justify-center">
                      <h3>
                        {{
                          slideProvisional.selectedGroup.MCCGroupAssignments
                            ? slideProvisional.selectedGroup.MCCGroupAssignments
                                .length
                            : 0
                        }}
                        categories
                      </h3>
                    </div>
                    <v-virtual-scroll
                      height="250"
                      class="mt-2 mb-5"
                      item-height="45"
                      :items="
                        slideProvisional.selectedGroup.MCCGroupAssignments
                      "
                    >
                      <template v-slot:default="{ item }">
                        <v-list-item :key="item.mccGroupAssignmentId">
                          <v-list-item-content
                            class="text-left ml-2 mt-2 text-overflow-clip"
                          >
                            <span
                              v-if="
                                (item.MCC.displayName
                                  ? item.MCC.displayName
                                  : item.MCC.name
                                ).length < 55
                              "
                              class="text-left"
                              >{{
                                item.MCC.displayName
                                  ? item.MCC.displayName
                                  : item.MCC.name
                              }}</span
                            >
                            <v-tooltip top v-else>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="text-left"
                                  >{{
                                    (item.MCC.displayName
                                      ? item.MCC.displayName
                                      : item.MCC.name
                                    ).substring(0, 55) + ".."
                                  }}</span
                                >
                              </template>
                              <span>{{
                                item.MCC.displayName
                                  ? item.MCC.displayName
                                  : item.MCC.name
                              }}</span>
                            </v-tooltip>
                          </v-list-item-content>
                          <!-- <v-list-item-content
                            class="text-left mt-2 text-overflow-clip"
                          >
                            <span>{{
                              item.User.businessEmail
                            }}</span></v-list-item-content
                          > -->
                        </v-list-item>
                        <v-divider class="mx-5" />
                      </template>
                    </v-virtual-scroll>
                  </div>
                  <!-- <v-btn
                  
                    color="primary"
                    outlined
                    rounded
                    width="90"
                    @click="
                      addOrRemoveMCCPreset(slideProvisional.selectedGroup)
                    "
                    class="mr-4"
                    >Edit</v-btn
                  > -->
                  <v-btn
                    color="primary"
                    rounded
                    width="100"
                    @click="
                      addOrRemoveMCCPreset(slideProvisional.selectedGroup)
                    "
                    >{{
                      !slideProvisional.selectedPresets.find(
                        x =>
                          x.mccGroupDefinitionId ==
                          slideProvisional.selectedGroup.mccGroupDefinitionId
                      )
                        ? "Add Filter"
                        : "Remove"
                    }}</v-btn
                  >
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </div>
          <div
            v-else-if="slideKey === keys.mccCustom"
            key="14"
            class="full-width"
          >
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's go over the details of the payment</span
              >
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideAmount.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->

                <p class="header-text mt-4 mb-1">Select merchant categories</p>

                <v-text-field
                  outlined
                  hide-details
                  label="Search"
                  v-model="slideProvisional.search"
                >
                  <template v-slot:append-outer> </template>
                </v-text-field>

                <v-data-table
                  v-model="slideProvisional.selectedMCC"
                  :headers="slideProvisional.mccHeaders"
                  :items="slideProvisional.mcc"
                  :search="slideProvisional.search"
                  item-key="mccId"
                  :items-per-page="10"
                  class="elevation-0 full-width cursor-pointer mt-3"
                  :loading="slideProvisional.loadingMCC"
                  loading-text="Loading... Please wait"
                  no-data-text="No categories found"
                  show-select
                  sort-by="popularity"
                  :sort-desc="true"
                  @click:row="addOrRemoveMCC"
                  mobile-breakpoint="0"
                >
                </v-data-table>
                <v-text-field
                  class="mt-2"
                  label="Save as a preset?"
                  placeholder="Gas Stations"
                  outlined
                  v-model="slideProvisional.presetName"
                ></v-text-field>
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === keys.provisionalSchedule"
            key="15"
            class="full-width"
          >
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's go over the details of the payment</span
              >
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideAmount.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->

                <p class="header-text mb-1 mt-4">
                  Should this payment be recurring?
                </p>
                <p class="mb-1 darkGrey--text">
                  (You can cancel future payments any time)
                </p>
                <v-radio-group
                  v-model="slideProvisional.recurringToggle"
                  row
                  hide-details
                  class="mb-4"
                >
                  <v-radio label="Yes" :value="true"></v-radio>

                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>

                <div v-if="slideProvisional.recurringToggle === true">
                  <p class="header-text mb-1">
                    Choose the cadence:
                  </p>
                  <v-radio-group
                    v-model="slideProvisional.cadence"
                    row
                    hide-details
                  >
                    <v-radio label="Daily" value="DAILY"></v-radio>

                    <v-radio label="Weekly" value="WEEKLY"></v-radio>
                    <v-radio label="Monthly" value="MONTHLY"></v-radio>
                  </v-radio-group>
                </div>
                <div
                  v-if="
                    slideProvisional.recurringToggle === false ||
                      (slideProvisional.recurringToggle === true &&
                        slideProvisional.cadence)
                  "
                >
                  <v-menu
                    v-model="slideProvisional.startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    offset-y
                    attach
                    top
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="slideProvisional.startDate"
                        :label="
                          slideProvisional.recurringToggle === false
                            ? 'Payment Date'
                            : 'First Payment Date'
                        "
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-8"
                        :rules="dateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="slideProvisional.startDate"
                      no-title
                      scrollable
                      @input="slideProvisional.startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <!-- <v-menu
                    v-model="slideProvisional.startDateMenu"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    offset-y
                    attach
                    top
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="slideProvisional.startDate"
                        :label="
                          slideProvisional.recurringToggle === false
                            ? 'Payment Date'
                            : 'First Payment Date'
                        "
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-8"
                        :rules="dateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="slideProvisional.startDate"
                    ></v-date-picker>
                  </v-menu> -->
                  <p v-if="slideProvisional.recurringToggle">
                    {{ formattedProvisionalDateString }}
                  </p>
                  <v-menu
                    v-if="slideProvisional.recurringToggle"
                    v-model="slideProvisional.endDateMenu"
                    :close-on-content-click="true"
                    :nudge-right="40"
                    offset-y
                    attach
                    top
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="slideProvisional.endDate"
                        label="Final Payment Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-8"
                        :rules="dateRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="slideProvisional.endDate"
                      no-title
                      scrollable
                      @input="slideProvisional.endDateMenu = false"
                      :show-current="slideProvisional.startDate"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === keys.expiration"
            key="16"
            class="full-width"
          >
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's go over the details of the payment</span
              >
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideAmount.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->

                <p class="header-text mt-4 mb-1">
                  Does this payment expire?
                </p>
                <p class="mb-1 darkGrey--text">
                  Unused funds are pulled back to the budget upon expiration
                </p>
                <v-radio-group
                  v-model="slideProvisional.expirationToggle"
                  row
                  hide-details
                  class="mb-4"
                >
                  <v-radio label="Yes" :value="true"></v-radio>

                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
                <div v-if="slideProvisional.expirationToggle">
                  <p
                    class="mb-1 darkGrey--text"
                    v-if="slideProvisional.recurringToggle"
                  >
                    Recurring payments will expire immediately before the next
                    payment is sent
                  </p>
                  <p
                    class="mb-1 darkGrey--text"
                    v-else-if="slideDetails.paymentType == 'PER_DIEM'"
                  >
                    Per diem payments will expire immediately at the end of the
                    day
                  </p>
                  <div v-else>
                    <p class="mb-1 header-text">
                      When do you want this payment to expire?
                    </p>
                    <v-menu
                      v-model="slideProvisional.expDateMenu"
                      :close-on-content-click="true"
                      :nudge-right="40"
                      offset-y
                      attach
                      top
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="slideProvisional.expirationDate"
                          label="Expiration Date"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-2"
                          :rules="expirationDateRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="slideProvisional.expirationDate"
                        no-title
                        scrollable
                        @input="slideProvisional.expDateMenu = false"
                        :show-current="slideProvisional.startDate"
                      ></v-date-picker>
                    </v-menu>
                    <p class="mb-1 ">
                      NOTE: This payment will expire at the beginning of the day
                      selected. <br />e.g. if you want a payment to expire
                      Friday night, select Saturday.
                    </p>
                  </div>
                </div>
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === keys.classify"
            key="17"
            class="full-width"
          >
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's go over the details of the payment</span
              >
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideClassification.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->

                <p class="header-text mt-4 mb-1">
                  How should we classify this for reporting?
                </p>
                <p class="mb-1 darkGrey--text">
                  This classification will show up in reports and exports making
                  it easier to import to your accounting software.
                </p>
                <v-select
                  label="Classification"
                  outlined
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                  class="mt-5"
                  :items="slideClassification.classificationArray"
                  v-model="slideClassification.classification"
                  :rules="[v => !!v || 'Classification is required']"
                />
                <v-text-field
                  v-if="slideClassification.classification == 'Other'"
                  v-model="slideClassification.classificationText"
                  :rules="[v => !!v || 'Classification is required']"
                  label="Classification"
                  placeholder="Travel"
                  outlined
                ></v-text-field>
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === keys.perDiemDetails"
            key="7"
            class="full-width"
          >
            <div class="text-left mb-5">
              <span class="header-text">Trip Details</span>
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePerDiemForm"
                v-model="slidePerDiem.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->
                <p class="header-text mt-4 mb-1">
                  Enter the details of this per diem
                </p>
                <v-text-field
                  label="Travel City"
                  hide-details
                  outlined
                  v-model="slidePerDiem.location"
                  class="my-3"
                ></v-text-field>

                <v-text-field
                  label="Business Purpose"
                  hide-details
                  outlined
                  v-model="slidePerDiem.purpose"
                  class="my-3"
                ></v-text-field>

                <!-- <div class="d-flex align-center"> -->
                <v-text-field
                  placeholder="100.00"
                  label="Per diem amount per day ($)"
                  class="my-3"
                  outlined
                  type="number"
                  :hide-details="false"
                  v-model="slidePerDiem.amount"
                  :rules="computedAmountRules"
                ></v-text-field>
                <!-- </div> -->
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === keys.perDiemDate"
            key="8"
            class="full-width"
          >
            <div class="text-left mb-5">
              <span class="header-text">Trip Dates</span>
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePerDiemForm"
                v-model="slidePerDiem.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <!-- <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    {{ paymentAudienceCount }}
                    {{ paymentAudienceCount == 1 ? "Payee" : "Payees" }}
                    Selected
                  </v-chip>
                </div> -->
                <p class="header-text mt-4 mb-1">
                  What's the schedule of this per diem?
                </p>

                <v-switch
                  v-model="slidePerDiem.consecutive"
                  class="mt-7 mb-2 mr-9"
                  outlined
                  label="Consecutive days"
                  hide-details
                  @click="clearDates()"
                ></v-switch>
                <v-menu
                  v-model="slidePerDiem.dateMenu"
                  attach
                  top
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="dateDisplayText"
                      :label="slidePerDiem.consecutive ? 'Date Range' : 'Dates'"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="mt-4"
                      :rules="dateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :multiple="!slidePerDiem.consecutive"
                    :range="slidePerDiem.consecutive"
                    v-model="slidePerDiem.dates"
                  ></v-date-picker>
                </v-menu>
              </v-form>
            </v-card>
          </div>
          <div key="4" v-else-if="slideKey === keys.review" class="full-width">
            <div class="text-left mb-5">
              <span class="header-text"
                >Let's review your payment before sending</span
              >
            </div>

            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="3"
              width="100%"
            >
              <v-form
                ref="slidePeopleForm"
                v-model="slideReview.valid"
                class="full-width text-left"
              >
                <v-text-field
                  label="Payment Memo"
                  hide-details
                  outlined
                  v-model="slideDetails.description"
                  @blur="sendPaymentMemoTrigger"
                ></v-text-field>
                <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">To:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.people)">
                    <v-icon class="mr-2">mdi-account-multiple</v-icon>
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      :width="2"
                      color="brandCyan"
                      class="mr-1"
                      v-if="loading.distinctUsers"
                    ></v-progress-circular>
                    <span v-else
                      >{{ paymentAudienceCount }} recipient{{
                        paymentAudienceCount == 1 ? "" : "s"
                      }}
                      selected</span
                    >
                  </v-chip>
                </div>
                <div class="d-flex mt-4">
                  <span class="mt-2 mr-2 darkGrey--text">Budget:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.details)">
                    {{ selectedBudget.displayName }}
                  </v-chip>
                </div>

                <div
                  class="d-flex mt-4"
                  v-if="slideDetails.paymentType == 'PER_DIEM'"
                >
                  <span class="mt-2 mr-2 darkGrey--text">Total days:</span>
                  <v-chip class="ma-1" @click="goToSlide(keys.perDiemDate)">
                    {{
                      scheduledPaymentDates ? scheduledPaymentDates.length : 0
                    }}
                  </v-chip>
                </div>
                <div
                  class="d-flex mt-4"
                  v-if="slideDetails.paymentType == 'COMPENSATION'"
                >
                  <span class="mt-2 mr-2 darkGrey--text"
                    >Amount per person:</span
                  >

                  <v-chip class="ma-1" @click="goToSlide(keys.amount)">
                    <span v-if="selectedBudget && selectedBudget.awardId == 1"
                      >$</span
                    >{{ formatCurrency(slideAmount.amount) }}
                    {{ awardUnits(slideAmount.amount) }}
                  </v-chip>
                </div>
                <div
                  class="d-flex mt-4"
                  v-if="slideDetails.paymentType == 'PER_DIEM'"
                >
                  <span class="mt-2 mr-2 darkGrey--text"
                    >Amount per person per day:</span
                  >
                  <v-chip class="ma-1" @click="goToSlide(keys.perDiemDetails)">
                    ${{ formatCurrency(slidePerDiem.amount) }}
                  </v-chip>
                </div>
                <div
                  class="d-flex mt-4"
                  v-if="slideDetails.paymentType == 'PROVISIONAL'"
                >
                  <span class="mt-2 mr-2 darkGrey--text"
                    >Amount per payment:</span
                  >
                  <v-chip
                    class="ma-1"
                    @click="goToSlide(keys.provisionalAmount)"
                  >
                    ${{ formatCurrency(slideProvisional.amount) }}
                  </v-chip>
                </div>
                <div
                  v-if="
                    slideDetails.paymentType == 'PROVISIONAL' &&
                      slideProvisional.startDate &&
                      slideProvisional.endDate &&
                      slideProvisional.cadence
                  "
                >
                  <div class="mt-4 d-flex">
                    <span class="mt-2 mr-2 darkGrey--text">{{
                      slideProvisional.cadence == "DAILY"
                        ? "Recurs daily for "
                        : slideProvisional.cadence == "WEEKLY"
                        ? "Recurs weekly on "
                        : "Recurs monthly on the "
                    }}</span>
                    <v-chip
                      class="ma-1"
                      @click="goToSlide(keys.provisionalSchedule)"
                    >
                      {{
                        formatDateCadence(
                          slideProvisional.startDate,
                          slideProvisional.cadence
                        )
                      }}
                    </v-chip>
                  </div>
                  <div class="mt-4 d-flex">
                    <span class="mt-2 mr-2 darkGrey--text">
                      {{
                        scheduledPaymentDates.length == 1 ? "Date: " : "From:"
                      }}
                    </span>
                    <v-chip
                      class="ma-1"
                      @click="goToSlide(keys.provisionalSchedule)"
                    >
                      {{
                        scheduledPaymentDates.length == 1
                          ? formatDate(slideProvisional.startDate)
                          : formatDate(slideProvisional.startDate) +
                            " to " +
                            formatDate(slideProvisional.endDate)
                      }}
                    </v-chip>
                  </div>
                </div>
                <div
                  class="d-flex mt-4"
                  v-else-if="slideDetails.paymentType == 'PROVISIONAL'"
                >
                  <span class="mt-2 mr-2 darkGrey--text"
                    >Total number of payments:</span
                  >
                  <v-chip
                    class="ma-1"
                    @click="goToSlide(keys.provisionalSchedule)"
                  >
                    {{ scheduledPaymentDates.length }}
                  </v-chip>
                </div>
                <div class="d-flex mt-4" v-if="route.includes(keys.expiration)">
                  <span class="mt-2 mr-2 darkGrey--text">Expires: </span>
                  <v-chip class="ma-1" @click="goToSlide(keys.expiration)">
                    {{
                      slideProvisional.expirationToggle
                        ? slideProvisional.expirationDate &&
                          !slideProvisional.recurringToggle
                          ? slideProvisional.expirationDate
                          : "Yes"
                        : "No"
                    }}
                  </v-chip>
                </div>
                <div
                  class="d-flex mt-4"
                  v-if="
                    slideDetails.paymentType == 'PROVISIONAL' &&
                      distinctMCCs.length > 0
                  "
                >
                  <span class="mt-2 mr-2 darkGrey--text">Restricted to:</span>
                  <div class="d-flex flex-wrap mcc-container">
                    <v-chip
                      v-for="mcc in distinctMCCs"
                      :key="mcc.mccId"
                      class="ma-1"
                      @click="loadCustomMCCPage"
                    >
                      <span
                        v-if="
                          (mcc.displayName ? mcc.displayName : mcc.name)
                            .length < 35
                        "
                      >
                        {{ mcc.displayName }}
                      </span>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="text-left">{{
                            (mcc.displayName
                              ? mcc.displayName
                              : mcc.name
                            ).substring(0, 35) + ".."
                          }}</span>
                        </template>
                        <span>{{
                          mcc.displayName ? mcc.displayName : mcc.name
                        }}</span>
                      </v-tooltip>
                    </v-chip>
                  </div>
                </div>
                <!-- amount per person if people get different amounts: -->
                <!-- <div
                  v-if="
                    slideDetails.paymentType == 'COMPENSATION' &&
                      slideAmount.amountToggle == 1
                  "
                  class="d-flex flex-column mt-4"
                >
                  <span class="darkGrey--text">Payees:</span>

                  <v-card
                    v-for="user in slideAmount.usersWithAmounts"
                    flat
                    rounded="0"
                    :key="user.userId"
                  >
                    <v-chip class="ma-1" @click="goToSlide(3)">
                      <span class="mr-1">
                        {{ user.displayName + ": " }}
                      </span>
                      <span v-if="selectedBudget && selectedBudget.awardId == 1"
                        >$</span
                      >{{ formatCurrency(user.amount) }}
                      {{ awardUnits(truncateNum(user.amount, 2)) }}
                    </v-chip>
                    <br />
                  </v-card>
                </div> -->

                <h4 class="mt-6 mb-3">
                  <b
                    >Total to be spent:
                    <span v-if="selectedBudget && selectedBudget.awardId == 1"
                      >$</span
                    >{{ formatCurrency(totalBeingSpent) }}
                    {{ awardUnits(totalBeingSpent) }}</b
                  >
                </h4>
                <span
                  >Budget remaining:
                  {{ amountRemainingInBudgetFormatted }}
                  {{ awardUnits(amountRemainingInBudget) }}</span
                >
                <p class="red--text mt-4" v-if="amountRemainingInBudget < 0">
                  The total amount entered is more than your selected budget.
                  (Budget balance
                  {{ budgetBalanceFormatted }}
                  {{ awardUnits(budgetBalance) }})
                </p>
                <div class="d-flex justify-end full-width my-5">
                  <v-btn
                    color="primary"
                    @click="goToNextSlide"
                    :disabled="disableContinueButton"
                    >Send</v-btn
                  >
                </div>
              </v-form>
            </v-card>
          </div>
          <div key="5" v-else-if="slideKey === keys.send" class="full-width">
            <div class="text-left d-flex flex-column align-center">
              <div class="full-width">
                <span
                  v-if="
                    slideDetails.paymentType == 'PER_DIEM' ||
                      slideDetails.paymentType == 'PROVISIONAL'
                  "
                  class="header-text"
                  >Scheduling your payments</span
                >
                <span v-else class="header-text"
                  >Sending out your payments</span
                >
                <p class="grey--text mt-2 full-width text-left">
                  Depending on the number of recipients this could take a little
                  while.
                </p>
              </div>
              <Robin
                :showText="false"
                :width="160"
                :height="160"
                animation="inflatableDance"
                :loop="true"
                class="mt-2"
              />
            </div>
          </div>

          <div key="6" v-else-if="slideKey === keys.sent" class="full-width">
            <div class="text-left d-flex flex-column align-center">
              <div class="full-width">
                <span
                  class="header-text"
                  v-if="
                    slideDetails.paymentType == 'PER_DIEM' ||
                      slideDetails.paymentType == 'PROVISIONAL'
                  "
                  >Your payment has been scheduled!</span
                >
                <span v-else class="header-text"
                  >All done! Your payment will be going out shortly</span
                >
                <p class="grey--text mt-2 full-width text-left">
                  {{
                    slideDetails.paymentType == "PER_DIEM" ||
                    slideDetails.paymentType == "PROVISIONAL"
                      ? ""
                      : "Once the payment is sent, all recipients will receive a notification."
                  }}

                  <br
                    v-if="
                      !['PER_DIEM', 'PROVISIONAL'].includes(
                        slideDetails.paymentType
                      )
                    "
                  />
                  {{
                    slideDetails.paymentType == "PER_DIEM" ||
                    slideDetails.paymentType == "PROVISIONAL"
                      ? "All recipients will receive notification of their payment based on the scheduled dates."
                      : ""
                  }}
                </p>
              </div>
              <Robin
                :showText="false"
                :width="160"
                :height="160"
                animation="backflip"
                :loop="false"
                class="mt-2"
              />
            </div>
          </div>
        </transition>
      </v-col>
    </div>

    <!-- Confirmation dialog -->
    <v-dialog persistent v-model="dialogConfirmation" width="400">
      <v-card rounded="0" class="d-flex justify-center flex-column ">
        <div></div>
        <v-card-title class="word-break mx-auto font-weight-bold">
          Confirm Payment
        </v-card-title>
        <p
          class="darkGrey--text mt-4 word-break px-5"
          v-if="slideDetails.paymentType == 'PER_DIEM'"
        >
          Sending
          <span class="primary--text"
            >${{ formatCurrency(slidePerDiem.amount) }}</span
          >
          per day to
          <span class="primary--text">{{ paymentAudienceCount }}</span>
          {{ paymentAudienceCount == 1 ? "recipient" : "recipients" }}
          <span class="primary--text"
            >for
            {{
              slidePerDiem.dates && scheduledPaymentDates
                ? scheduledPaymentDates.length
                : 0
            }}
            {{
              slidePerDiem.dates && slidePerDiem.dates.length == 1
                ? "day"
                : "days"
            }}</span
          >
        </p>
        <p
          class="darkGrey--text mt-4 word-break px-5"
          v-else-if="slideDetails.paymentType == 'PROVISIONAL'"
        >
          <span
            v-if="
              !(
                slideProvisional.startDate &&
                slideProvisional.endDate &&
                slideProvisional.cadence
              )
            "
            >Sending
            {{
              scheduledPaymentDates.length +
                (scheduledPaymentDates.length == 1
                  ? " payment of "
                  : " payments of ")
            }}
            <span class="primary--text"
              >${{ formatCurrency(slideProvisional.amount) }}</span
            >
            to
            <span class="primary--text">{{ paymentAudienceCount }}</span>
            {{ paymentAudienceCount == 1 ? "recipient" : "recipients" }}</span
          >

          <span v-else>{{ formattedProvisionalDateString }}</span>

          <!-- for
          <span class="primary--text">
            {{ scheduledPaymentDates ? scheduledPaymentDates.length : 0 }}
            {{
              scheduledPaymentDates && scheduledPaymentDates.length == 1
                ? "day"
                : "days"
            }}</span
          > -->
        </p>

        <p class="darkGrey--text mt-4 word-break px-5" v-else>
          Sending
          <span class="primary--text">
            <span v-if="selectedBudget && selectedBudget.awardId == 1">$</span
            >{{ formatCurrency(slideAmount.amount) }}
            {{ awardUnits(slideAmount.amount) }}</span
          >
          per person to
          <span class="primary--text">{{ paymentAudienceCount }}</span>
          {{ paymentAudienceCount == 1 ? "recipient" : "recipients" }}
        </p>
        <!-- <p class="darkGrey--text mt-4 word-break px-5" v-else>
          Sending different amounts per person to
          <span class="primary--text">{{ paymentAudienceCount }}</span>
          {{ paymentAudienceCount == 1 ? "recipient" : "recipients" }}
        </p> -->
        <p
          class="darkGrey--text mt-4"
          v-if="
            slideDetails.paymentType != 'PER_DIEM' &&
              slideDetails.paymentType != 'PROVISIONAL'
          "
        >
          <span v-if="selectedBudget && selectedBudget.awardId == 1">$</span
          >{{ formatCurrency(slideAmount.amount) }} x
          {{ paymentAudienceCount }} =
          <span v-if="selectedBudget && selectedBudget.awardId == 1">$</span
          >{{ formatCurrency(totalBeingSpent) }}
          {{ awardUnits(totalBeingSpent) }}
        </p>
        <p
          class="darkGrey--text mt-4"
          v-else-if="slideDetails.paymentType == 'PROVISIONAL'"
        >
          <span v-if="selectedBudget && selectedBudget.awardId == 1">$</span
          >{{ formatCurrency(slideProvisional.amount) }} x
          {{
            `${paymentAudienceCount} ` +
              (paymentAudienceCount == 1 ? "person" : "people")
          }}
          x
          {{
            `${scheduledPaymentDates.length} ` +
              (scheduledPaymentDates.length == 1 ? "payment" : "payments")
          }}
          = <span v-if="selectedBudget && selectedBudget.awardId == 1">$</span
          >{{ formatCurrency(totalBeingSpent) }}
          {{ awardUnits(totalBeingSpent) }}
        </p>
        <!-- <div class="darkGrey--text mt-4" v-else>
          <v-card
            v-for="user in slideAmount.usersWithAmounts"
            flat
            rounded="0"
            :key="user.userId"
          >
            <v-chip class="ma-1">
              <span class="mr-1">
                {{ user.displayName + ": " }}
              </span>
              <span v-if="selectedBudget && selectedBudget.awardId == 1">$</span
              >{{ formatCurrency(user.amount || slidePerDiem.amount) }}
              {{ awardUnits(user.amount || slidePerDiem.amount) }}
            </v-chip>
            <br />
          </v-card>
        </div>  -->

        <v-card-title class="mx-auto darkGrey--text mt-4"
          >Total to be spent:
          <span
            class="ml-1"
            v-if="selectedBudget && selectedBudget.awardId == 1"
          >
            $</span
          >
          {{ formatCurrency(totalBeingSpent) }}
          {{ awardUnits(totalBeingSpent) }}</v-card-title
        >
        <v-card-actions class="mt-6 mb-2">
          <v-btn color="grey" dark depressed @click="dialogConfirmation = false"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn color="primary" depressed @click="completionStep"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Error dialog -->
    <v-dialog v-model="dialogError" persistent width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-center align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title
            class="word-break text-left exit-warning-text mx-0 px-0"
          >
            There was an error sending your payment
          </v-card-title>
        </div>

        <p>{{ errorText }}</p>
        <p
          v-for="user in annualPaymentCapErrorArray"
          :key="user.userId"
          class="mb-1"
        >
          {{ user.formattedOutput }}
        </p>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto"
            color="primary"
            depressed
            @click="
              (slideKey = 1),
                (dialogError = false),
                (annualPaymentCapErrorArray = [])
            "
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- User creator widget -->
    <v-navigation-drawer
      v-model="slidePeople.displayCreateUser"
      temporary
      fixed
      right
      width="500"
    >
      <UserCreatorWidgetSimple
        v-if="slidePeople.displayCreateUser"
        @close="slidePeople.displayCreateUser = false"
        @get-users="getUsers"
        :clientId="clientId"
        :editingUser="false"
        :editUserObject="{}"
        source="PAYMENTS_CONSOLE"
      ></UserCreatorWidgetSimple>
    </v-navigation-drawer>
    <!-- Group creator widget -->
    <v-navigation-drawer
      v-model="slidePeople.displayCreateGroup"
      temporary
      fixed
      right
      width="500"
    >
      <GroupTypeSelector
        v-if="slidePeople.displayCreateGroup"
        @get-groups="getGroups($event, true)"
        @close="slidePeople.displayCreateGroup = false"
        :clientId="clientId"
        :version="3"
        source="PAYMENTS_CONSOLE"
      />
    </v-navigation-drawer>
    <v-overlay
      v-if="slidePeople.displayCreateGroup || slidePeople.displayCreateUser"
    />
  </div>
</template>

<script>
import GroupService from "@/services/GroupService";
import UserService from "@/services/UserService";
import WalletService from "../services/WalletService";
import BudgetService from "../services/BudgetService";
import TriggerService from "../services/TriggerService";

import Robin from "@/components/Robin";
import GroupTable from "@/components/data-table/Groups";
import UserTable from "@/components/data-table/Users";
import UserCreatorWidgetSimple from "@/components/UserCreatorWidgetSimpleV2.vue";
import GroupTypeSelector from "./groups/GroupTypeSelector.vue";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import momentTz from "moment-timezone";
import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";

function initialState() {
  return {
    slideKey: 1,
    dialogConfirmation: false,
    dialogError: false,
    errorText: null,
    annualPaymentCapErrorArray: [],
    slideDirection: "topic-left",
    keys: {
      people: 1,
      details: 2,
      perDiemDetails: 3,
      perDiemDate: 4,
      amount: 5,
      provisionalAmount: 6,
      mccCustom: 7,
      provisionalSchedule: 8,
      expiration: 9,
      classify: 10,
      review: 100,
      send: 101,
      sent: 102
    },
    slidePeople: {
      valid: false,
      users: [],
      groups: [],
      selected: [],
      search: null,
      debounceSearch: null,
      loadingUsers: false,
      loadingGroups: false,
      activeGroup: 0,
      infoSheet: null,
      selectedItem: null,
      groupColumns: [
        { text: "Name", value: "User.displayName", width: "40%" },
        { text: "Email", value: "User.businessEmail", width: "50%" }
      ],
      displayCreateUser: false,
      displayCreateGroup: false
    },
    slideDetails: {
      valid: false,
      description: null,
      memoPlatformActivityCounter: 0,
      paymentType: null,
      budgetId: null,
      awardId: null
    },
    slideAmount: {
      valid: false,
      amount: null,
      // amountToggle: null,
      headers: [
        { text: "Payee", value: "displayName", width: "60%" },
        { text: "Amount", value: "amount", width: "40%" }
      ]
      // usersWithAmounts: []
    },
    slideReview: {
      valid: false
    },
    slidePerDiem: {
      valid: false,
      location: null,
      purpose: null,
      amount: null,
      consecutive: null,
      dates: []
    },
    slideProvisional: {
      loadingMCC: false,
      loadingMCCGroups: false,
      presets: [],
      selectedPresets: [],
      presetName: null,
      mccHeaders: [
        { text: "Category Name", value: "displayName", width: "80%" },
        { text: "Popularity", value: "popularity", width: "20%" }
      ],
      mcc: [],
      selectedMCC: [],
      customMCCToggle: null,
      search: null,
      amount: null,
      infoSheet: false,
      selectedGroup: null,
      recurringToggle: null,
      cadence: null,
      startDateMenu: false,
      startDate: null,
      endDateMenu: false,
      endDate: null,
      expirationToggle: null,
      expDateMenu: false,
      expirationDate: null
    },
    slideClassification: {
      classificationArray: [
        "None",
        "Meals and Entertainment",
        "Travel",
        "Rent Expense",
        "Utilities",
        "Office Supplies",
        "Advertising and Marketing",
        "Dues and Subscriptions",
        "Other"
      ],
      classification: "None",
      classificationText: null,
      valid: false
    },
    userTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      users: [],
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null,
      columns: [
        { text: "Name", value: "displayName", width: "40%" },
        { text: "Email", value: "businessEmail", width: "50%" },
        { text: "", value: "add" }
      ]
    },
    groupTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      users: [],
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null,
      columns: [
        { text: "Name", value: "displayName", width: "40%" },
        { text: "Email", value: "businessEmail", width: "50%" },
        { text: "", value: "add" }
      ]
    },
    awards: [],
    loading: {
      distinctUsers: false
    },
    values: {
      distinctUsers: 0
    }
  };
}

export default {
  name: "PaymentsConsole",
  components: {
    Robin,
    UserCreatorWidgetSimple,
    GroupTypeSelector,
    GroupTable,
    UserTable
  },
  props: {
    budgets: Array
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    // this.clientId = this.userProfile.clientId;
    // this.getGroupsV2();
    // this.getUsersV2();
    this.getAwards();
    this.getMCCGroups();
    this.getMCCs();

    this.createPlatformActivity("OPENED_PAYMENTS_CONSOLE");
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    reset() {
      // this.dialogConfirmation = false;
      // this.canEditDate = true;
      this.resetAllData();
      this.$emit("reset");
      this.$emit("get-budgets");
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    goToSlide(index) {
      // this.disableContinueButton

      // Figures out what step we're leaving so we can insert the proper platform activity
      const oldIndex = this.slideKey;
      var platformActivityEvent = null;
      if (this.slideKey == this.keys.people && this.paymentAudienceCount > 0)
        platformActivityEvent = "SELECTED_PAYMENT_RECIPIENTS";
      else if (this.slideKey == this.keys.details && this.slideDetails.budgetId)
        platformActivityEvent = "SELECTED_PAYMENT_BUDGET";
      else if (this.slideKey == this.keys.amount && this.slideAmount.amount)
        platformActivityEvent = "SELECTED_PAYMENT_AMOUNT";
      else if (
        this.slideKey == this.keys.perDiemDetails &&
        this.slidePerDiem.amount
      )
        platformActivityEvent = "SELECTED_PER_DIEM_AMOUNT";
      else if (
        this.slideKey == this.keys.perDiemDate &&
        this.slidePerDiem.dates.length > 0
      )
        platformActivityEvent = "SELECTED_PER_DIEM_DATES";
      else if (
        this.slideKey == this.keys.expiration &&
        this.slideProvisional.expirationDate
      )
        platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";

      // We have to block if the user has already sent payment
      if (this.slideKey >= 1 && this.slideKey < this.keys.send) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (oldIndex == this.keys.people) this.getDistinctUsersFromGroups();
          // Only insert if we actually navigate them away
          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      const oldIndex = this.slideKey;

      if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.dialogConfirmation = true;
      } else if (this.slideKey == this.keys.sent) {
        this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        if (this.slideKey == this.keys.people)
          platformActivityEvent = "SELECTED_PAYMENT_RECIPIENTS";
        else if (this.slideKey == this.keys.details)
          platformActivityEvent = "SELECTED_PAYMENT_BUDGET";
        else if (this.slideKey == this.keys.amount)
          platformActivityEvent = "SELECTED_PAYMENT_AMOUNT";
        else if (this.slideKey == this.keys.perDiemDetails)
          platformActivityEvent = "SELECTED_PER_DIEM_AMOUNT";
        else if (this.slideKey == this.keys.perDiemDate)
          platformActivityEvent = "SELECTED_PER_DIEM_DATES";
        else if (this.slideKey == this.keys.expiration)
          platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";

        // We establish the route depending on what payment type
        // if (
        //   this.slideDetails.paymentType == "COMPENSATION" ||
        //   this.slideDetails.paymentType == "NON_CASH" ||
        //   this.slideDetails.paymentType == null
        // ) {
        //   if (this.slideKey == this.keys.details) {
        // Exceptions for each case
        // this.prepUserAmountArray();
        // If they've selected one person, then we auto fill the amountToggle
        // if (this.paymentAudienceCount == 1) {
        // this.slideAmount.amountToggle = 0;
        // }
        // }
        // }

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        this.slideKey = this.route[routeIndex + 1];

        if (oldIndex == this.keys.people) this.getDistinctUsersFromGroups();

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      // We skip the mccCustom if
      // if (
      //   this.slideDetails.paymentType == "PROVISIONAL" &&
      //   this.route[routeIndex - counter] == this.keys.mccCustom &&
      //   this.slideProvisional.selectedPresets.length > 0
      // ) {
      //   counter = 2;
      // }
      this.slideKey = this.route[routeIndex - counter];

      // if (
      //   this.slideKey == this.keys.review &&
      //   this.slideDetails.paymentType == "PER_DIEM"
      // ) {
      //   var i = this.keys.perDiemDate;
      // } else if (
      //   this.slideKey == this.keys.review &&
      //   this.slideDetails.paymentType != "PER_DIEM"
      // ) {
      //   i = this.keys.amount;
      // } else if (
      //   this.slideKey == this.keys.perDiemDetails &&
      //   this.slideDetails.paymentType == "PER_DIEM"
      // ) {
      //   i = this.keys.details;
      // } else {
      //   i = this.slideKey - 1;
      // }

      // this.slideKey = i;
    },
    focusOnTitle() {
      this.$refs.titleInput.focus();
    },
    async getDistinctUsersFromGroups() {
      console.log("getDistinctUsersFromGroups()");
      // assign to slidePeople.selectedItem.GroupAssignments
      const groups = this.slidePeople.selected
        .filter(x => x.groupId)
        .map(x => x.groupId);
      if (!groups.length) return;
      this.loading.distinctUsers = true;
      try {
        const response = await GroupService.getDistinctUsersFromGroupsV2(
          groups,
          [],
          this.magicLinkToken
        );

        this.values.distinctUsers = response;
        console.log("New distinct user count", this.values.distinctUsers);
      } catch (error) {
        console.log("Error getting distinct number of users ", error);

        this.values.distinctUsers = 0;
      } finally {
        this.loading.distinctUsers = false;
      }
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.slidePeople.search = undefined;
        this.slidePeople.debounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - payment console",
            groupId
          );
      });
    },
    getUsers(userId) {
      // Uses the group table widget
      if (this.$refs["user-table"])
        this.$refs["user-table"].getUsers(
          true,
          "getUsers - payment console",
          userId
        );
    },
    getAwards() {
      BudgetService.getAwards()
        .then(response => {
          console.log("Awards: ", response);

          this.awards = response;
        })
        .catch(error => {
          console.log("Error fetching awards ", error);
        });
    },
    getMCCGroups() {
      this.slideProvisional.loadingMCCGroups = true;
      WalletService.getMCCGroups()
        .then(response => {
          console.log("MCC GROUPS ", response);
          response = response.filter(
            x => x.preset && [0, this.clientId].includes(x.clientId)
          );
          response.unshift({
            mccGroupDefinitionId: -1,
            name: "No Restrictions"
          });
          this.slideProvisional.presets = response;
          this.slideProvisional.loadingMCCGroups = false;
        })
        .catch(error => {
          console.log("Error fetching mcc groups ", error);
        });
    },
    getMCCs() {
      this.slideProvisional.loadingMCC = false;
      WalletService.getMCCs()
        .then(response => {
          response.forEach(x => {
            if (!x.displayName) {
              x.displayName = x.name;
            }
          });
          this.slideProvisional.mcc = response.filter(
            x => x.status == "Active"
          );
          this.slideProvisional.loadingMCC = false;
        })
        .catch(error => {
          console.log("Error fetching mccs ", error);
        });
    },
    removeUserFromSelected(index) {
      // var user = this.slidePeople.selected[index];
      this.slidePeople.selected.splice(index, 1);

      // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
      this.values.distinctUsers = 0;
    },
    addUserToSelected(user, dontRemove = false) {
      // this.slidePeople.selected.push(user);
      this.slidePeople.infoSheet = false;
      if (this.slidePeople.activeGroup == 0) {
        let idx = this.slidePeople.selected.findIndex(
          x => x.userId == user.userId
        );
        if (idx == -1) this.slidePeople.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);
        // // Then we find the user in the main array and mark as display = false;
        // var userIndex = this.slidePeople.users.findIndex(
        //   (x) => x.userId == user.userId
        // );
        // if (userIndex !== -1) {
        //   this.slidePeople.users[userIndex].display = false;
        // }
      } else {
        let idx = this.slidePeople.selected.findIndex(
          x => x.groupId == user.groupId
        );
        if (idx == -1) this.slidePeople.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);
        // Then we find the group in the main array and mark as display = false;
        // var groupIndex = this.slidePeople.groups.findIndex(
        //   (x) => x.groupId == user.groupId
        // );
        // if (groupIndex !== -1) {
        //   this.slidePeople.groups[groupIndex].display = false;
        // }
        // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
        this.values.distinctUsers = 0;
      }
    },
    updateSelectedItem(e) {
      if (!e.groupId) console.log("no group id");

      this.slidePeople.selected.forEach(o => {
        if (o.groupId == e.groupId) {
          console.log("UPDATING SELECTED NUM USERS ", e.numUsers);
          o.numUsers = e.numUsers || 0;
          // this.getDistinctUsersFromGroups();
        }
      });
    },
    changeAudience(slide) {
      // We only do something if they click on the other option
      if (slide != this.slidePeople.activeGroup) {
        const oldTab = this.slidePeople.activeGroup;
        const oldSearchValue = this.slidePeople.search;

        this.slidePeople.activeGroup = slide;
        this.slidePeople.search = null;
        this.slidePeople.debounceSearch = null;
        // Here we have to reset the selected array and other settings
        // We also need to add the users back to the original array
        // if (slide == 0) {
        //   // The user switched away from groups, so add groups back
        //   this.slidePeople.groups.forEach((x) => {
        //     x.display = true;
        //   });
        // } else {
        //   this.slidePeople.users.forEach((x) => {
        //     x.display = true;
        //   });
        // }
        // If we're leaving users and there was a search value, we refetch users
        // if (oldTab == 0 && oldSearchValue) this.getUsersV2(true);

        this.slidePeople.selected = [];
        document.getElementsByClassName(
          "v-dialog v-dialog--active"
        )[0].scrollTop = 0;
      }
    },
    previewItem(item) {
      if (item.userId) {
        console.log("Previewing user", item);
        this.slidePeople.selectedItem = item;
        this.slidePeople.infoSheet = true;
      } else if (item.groupId) {
        console.log("Previewing group", item);
        this.slidePeople.selectedItem = item;
        this.slidePeople.infoSheet = true;
      } else if (item.mccGroupDefinitionId) {
        console.log("Previewing mcc group", item);
        // We need to sort MCC's by priority
        item.MCCGroupAssignments = item.MCCGroupAssignments.sort(
          (a, b) => b.MCC.popularity - a.MCC.popularity
        );
        this.slideProvisional.selectedGroup = item;
        this.slideProvisional.infoSheet = true;
      }
    },
    completionStep() {
      this.submitPaymentV2();
    },
    // createPaymentRules() {
    //   console.log("build rule object");
    //   var tz = momentTz.tz.guess();
    //   var convertedDates = this.scheduledPaymentDates.map(x =>
    //     momentTz(x)
    //       .tz(tz)
    //       .utc()
    //       .format("YYYY-MM-DD HH:mm:ss.sss")
    //   );
    //   console.log(convertedDates);
    //   //create paymentRule object
    //   var paymentRule = {
    //     clientId: this.clientId,
    //     budgetId: this.slideDetails.budgetId,
    //     description: this.slideDetails.description,
    //     amount:
    //       this.slideDetails.paymentType == "PER_DIEM"
    //         ? this.slidePerDiem.amount
    //         : this.slideProvisional.amount,
    //     paymentType: "PROVISIONAL",
    //     classification:
    //       this.slideDetails.paymentType == "PER_DIEM"
    //         ? "Per Diem"
    //         : this.slideClassification.classification == "Other"
    //         ? this.slideClassification.classificationText
    //         : this.slideClassification.classification == "None"
    //         ? null
    //         : this.slideClassification.classification,
    //     dates: convertedDates,
    //     startDate: this.slideProvisional.startDate,
    //     endDate: this.slideProvisional.expirationDate
    //       ? momentTz(this.slideProvisional.expirationDate)
    //           .tz(tz)
    //           .utc()
    //           .format("YYYY-MM-DD HH:mm:ss.sss")
    //       : momentTz(this.slideProvisional.endDate)
    //           .tz(tz)
    //           .utc()
    //           .format("YYYY-MM-DD HH:mm:ss.sss"),
    //     frequency:
    //       this.slideDetails.paymentType == "PER_DIEM"
    //         ? "DAILY"
    //         : this.slideProvisional.recurringToggle
    //         ? this.slideProvisional.cadence
    //         : "ONE_TIME",
    //     clawback: this.slideProvisional.expirationToggle,
    //     requiresUserSignoff:
    //       this.slideDetails.paymentType == "PER_DIEM" ? true : false,
    //     purpose: this.slidePerDiem.purpose,
    //     location: this.slidePerDiem.location,
    //     audience: {
    //       userId: [],
    //       groupId: []
    //     },
    //     mid: [],
    //     mcc: []
    //   };

    //   if (
    //     this.slideProvisional.selectedPresets.length > 0 &&
    //     !this.slideProvisional.selectedPresets
    //       .map(x => x.mccGroupDefinitionId)
    //       .includes(-1)
    //   ) {
    //     paymentRule.mccGroup = {
    //       presets: this.slideProvisional.selectedPresets.map(
    //         x => x.mccGroupDefinitionId
    //       )
    //     };
    //   }

    //   if (this.slideProvisional.selectedMCC.length > 0) {
    //     if (paymentRule.mccGroup) {
    //       paymentRule.mccGroup.mcc = this.slideProvisional.selectedMCC.map(
    //         x => x.mccId
    //       );
    //       paymentRule.mccGroup.name = this.slideProvisional.presetName;
    //       paymentRule.mccGroup.savePreset = this.slideProvisional.presetName
    //         ? true
    //         : false;
    //     } else {
    //       paymentRule.mccGroup = {
    //         mcc: this.slideProvisional.selectedMCC.map(x => x.mccId),
    //         name: this.slideProvisional.presetName,
    //         savePreset: this.slideProvisional.presetName ? true : false
    //       };
    //     }
    //   }

    //   //figure out which kind of audience we're dealing with and assign it to the object appropriately
    //   if (this.slidePeople.selected[0].groupId) {
    //     //must be a group eh?
    //     paymentRule.audience.groupId = this.slidePeople.selected.map(
    //       x => x.groupId
    //     );
    //   } else {
    //     //must be individual users
    //     paymentRule.audience.userId = this.slidePeople.selected.map(
    //       x => x.userId
    //     );
    //   }

    //   console.log(paymentRule);
    //   this.dialogConfirmation = false;
    //   this.slideKey = this.keys.send;

    //   WalletService.createPaymentRule(paymentRule)
    //     .then(x => {
    //       console.log("Payment rule response ", x);
    //       if (x.error || !x.parentPaymentRule) {
    //         this.dialogError = true;
    //         this.errorText =
    //           "An error occurred. Please try again and contact your Whistle representative if this issue persists.";
    //       } else {
    //         // Display success screen
    //         this.slideKey = this.keys.sent;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("Error sending payment! ", error);
    //       this.dialogError = true;
    //       this.errorText =
    //         "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
    //     });
    // },
    // submitPayment() {
    //   // Let's prep the array
    //   var paymentsArray = this.slideAmount.usersWithAmounts;
    //   paymentsArray = paymentsArray.map(x => {
    //     // if (this.slideAmount.amountToggle == 0) {
    //     var amount = currency(this.slideAmount.amount).value;
    //     // parseFloat(
    //     //   truncateNumber(this.slideAmount.amount, 3)
    //     // ).toFixed(2);
    //     // } else {
    //     //   amount = currency(x.amount).value;
    //     //   // parseFloat(truncateNumber(x.amount, 3)).toFixed(2);
    //     // }
    //     return {
    //       userId: x.userId,
    //       amount: amount.toString(),
    //       budgetId: this.slideDetails.budgetId,
    //       awardId: this.selectedBudget.awardId,
    //       programId: null,
    //       ruleGroupId: null,
    //       clientId: this.clientId
    //     };
    //   });

    //   var object = {
    //     users: paymentsArray,
    //     description: this.slideDetails.description,
    //     sourceUserId: this.userProfile.userId,
    //     source: "PAYMENTS_CONSOLE"
    //   };

    //   console.log(object);
    //   // Display loading screen
    //   this.dialogConfirmation = false;
    //   this.slideKey = this.keys.send;
    //   WalletService.sendPaymentBulk(object)
    //     .then(x => {
    //       console.log(x);
    //       if (typeof x == "string") {
    //         if (x.startsWith("The sum of all payouts")) {
    //           this.dialogError = true;
    //           this.errorText =
    //             "The total payment sum is more than the selected budget's balance.";
    //         }
    //       } else {
    //         // Display success screen
    //         this.slideKey = this.keys.sent;
    //       }
    //     })
    //     .catch(error => {
    //       console.log("Error sending payment! ", error);
    //       this.dialogError = true;
    //       this.errorText =
    //         "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
    //     });
    // },
    submitPaymentV2() {
      // Let's prep the array
      // var paymentsArray = this.slideAmount.usersWithAmounts;
      // paymentsArray = paymentsArray.map(x => {
      //   // if (this.slideAmount.amountToggle == 0) {
      //   var amount = currency(this.slideAmount.amount).value;
      //   // parseFloat(
      //   //   truncateNumber(this.slideAmount.amount, 3)
      //   // ).toFixed(2);
      //   // } else {
      //   //   amount = currency(x.amount).value;
      //   //   // parseFloat(truncateNumber(x.amount, 3)).toFixed(2);
      //   // }
      //   return {
      //     userId: x.userId,
      //     amount: amount.toString(),
      //     budgetId: this.slideDetails.budgetId,
      //     awardId: this.selectedBudget.awardId,
      //     programId: null,
      //     ruleGroupId: null,
      //     clientId: this.clientId
      //   };
      // });

      const defaultPayment = !(
        this.slideDetails.paymentType == "PER_DIEM" ||
        this.slideDetails.paymentType == "PROVISIONAL"
      );

      const perDiemPayment = !!(this.slideDetails.paymentType == "PER_DIEM");

      var object = {
        clientId: this.clientId,
        budgetId: this.slideDetails.budgetId,
        amount: currency(
          defaultPayment
            ? this.slideAmount.amount
            : perDiemPayment
            ? this.slidePerDiem.amount
            : this.slideProvisional.amount
        ),
        paymentType: defaultPayment ? "DEFAULT" : "PROVISIONAL",
        description: this.slideDetails.description,
        purpose: perDiemPayment
          ? this.slidePerDiem.purpose
          : this.slideDetails.description,
        classification: perDiemPayment
          ? "Per Diem"
          : defaultPayment
          ? null
          : this.slideClassification.classification == "Other"
          ? this.slideClassification.classificationText
          : this.slideClassification.classification == "None"
          ? null
          : this.slideClassification.classification,
        // audience: {
        //   userId: !defaultPayment
        //     ? []
        //     : this.slideAmount.usersWithAmounts.map(x => x.userId),
        //   groupId: []
        // },
        audience: {
          userId: this.slidePeople.selected
            .filter(x => x.userId)
            .map(x => x.userId),
          groupId: this.slidePeople.selected
            .filter(x => x.groupId)
            .map(x => x.groupId)
        },
        frequency: perDiemPayment
          ? "DAILY"
          : !defaultPayment && this.slideProvisional.recurringToggle
          ? this.slideProvisional.cadence
          : "ONE_TIME",
        sourceUserId: this.userProfile.userId,
        source: "PAYMENTS_CONSOLE"
      };

      if (!defaultPayment) {
        object.clawback = this.slideProvisional.expirationToggle;
        object.requiresUserSignoff = perDiemPayment;
        object.purpose = this.slidePerDiem.purpose;
        object.location = this.slidePerDiem.location;

        if (!perDiemPayment) {
          // Provisional or consecutive per diem
          // if ()
          object.startDate = moment(this.slideProvisional.startDate)
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ");

          if (
            this.slideProvisional.expirationDate ||
            this.slideProvisional.endDate
          ) {
            object.endDate = moment(
              this.slideProvisional.expirationDate ||
                this.slideProvisional.endDate
            )
              .utc()
              .format("YYYY-MM-DD HH:mm:ssZ");
          }

          if (
            this.slideProvisional.selectedPresets.length > 0 &&
            !this.slideProvisional.selectedPresets
              .map(x => x.mccGroupDefinitionId)
              .includes(-1)
          ) {
            object.mccGroup = {
              presets: this.slideProvisional.selectedPresets.map(
                x => x.mccGroupDefinitionId
              )
            };
          }

          if (this.slideProvisional.selectedMCC.length > 0) {
            if (!object.mccGroup) object.mccGroup = {};
            object.mccGroup.mcc = this.slideProvisional.selectedMCC.map(
              x => x.mccId
            );
            object.mccGroup.name = this.slideProvisional.presetName;
            object.mccGroup.savePreset = this.slideProvisional.presetName
              ? true
              : false;
          }
        } else if (this.slidePerDiem.consecutive) {
          object.startDate = moment(this.scheduledPaymentDates[0])
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ");

          object.endDate = moment(
            this.scheduledPaymentDates[this.scheduledPaymentDates.length - 1]
          )
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ");
        } else
          object.dates = this.scheduledPaymentDates.map(x =>
            moment(x)
              .utc()
              .format("YYYY-MM-DD HH:mm:ssZ")
          );

        // if (
        //   this.slidePeople.selected[0] &&
        //   this.slidePeople.selected[0].groupId
        // )
        //   object.audience.groupId = this.slidePeople.selected.map(
        //     x => x.groupId
        //   );
        // else
        //   object.audience.userId = this.slidePeople.selected.map(x => x.userId);
      }

      // if (this.slideDetails.paymentType == "NON_CASH") {
      //   object.description = object.purpose = null;
      // }

      console.log(object);
      // Display loading screen
      this.dialogConfirmation = false;
      this.slideKey = this.keys.send;
      WalletService.createPaymentV2(object)
        .then(x => {
          console.log(x);
          // if (typeof x == "string") {
          //   if (x.startsWith("The sum of all payouts")) {
          //     this.dialogError = true;
          //     this.errorText =
          //       "The total payment sum is more than the selected budget's balance.";
          //   }
          // } else {
          // Display success screen
          this.slideKey = this.keys.sent;
          // }
        })
        .catch(error => {
          console.log("Error sending payment! ", error);

          if (error && error.error && typeof error.error == "string") {
            if (
              error.error.startsWith("Budget does not have enough ") ||
              error.error_code == "1070100"
            ) {
              this.dialogError = true;
              this.errorText =
                "The total payment amount is more than the selected budget's balance.";
              return;
            } else if (
              error.error_code == "1070127" ||
              error.error_code == "1070128"
            ) {
              this.dialogError = true;
              if (error.error_code == "1070127")
                this.errorText =
                  "The users below cannot receive any more payments for the year.";
              else
                this.errorText =
                  "The users below cannot receive the full payment. The amounts each user is capped at are listed below.";
              this.annualPaymentCapErrorArray = error.data || [];
              return;
            }
          }
          this.dialogError = true;
          this.errorText =
            "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
        });
    },
    clearDates() {
      this.slidePerDiem.dates = [];

      //reset the datepicker title as well
      var pickerTitle = document.getElementsByClassName(
        "v-picker__title__btn--active"
      );
      if (pickerTitle.length > 0) pickerTitle[0].innerHTML = "0 selected";
    },
    resetPaymentType() {
      //if we switched to non cash, null out payment memo
      // if (this.slideDetails.paymentType == "NON_CASH") {
      //   this.slideDetails.description = null;
      // }

      // If we're switching between cash options, we dont' reset
      if (
        !(
          this.selectedBudget &&
          this.selectedBudget.awardId == 1 &&
          this.slideDetails.paymentType &&
          this.slideDetails.paymentType != "NON_CASH"
        )
      ) {
        console.log("Resetting paymentType");
        if (this.$refs.slideDetailsForm) {
          this.$refs.slideDetailsForm.reset();
        } else {
          this.slideDetails.budgetId = null;
          this.slideDetails.awardId = null;
        }
        this.slideAmount.amount = null;
        // this.slideAmount.amountToggle = 0;
        // this.slideAmount.usersWithAmounts.forEach(x => {
        //   x.amount = null;
        // });
      }
    },
    awardUnits(value) {
      if (this.selectedAward && this.selectedAward.awardId != 1) {
        var singular = "unit";
        var plural = "units";
        if (this.selectedAward.unitPlural)
          plural = this.selectedAward.unitPlural;
        if (this.selectedAward.unitSingular)
          singular = this.selectedAward.unitSingular;
        if (parseFloat(value) == 1) {
          return singular;
        } else {
          return plural;
        }
      }
      return null;
    },
    updateClientId(clientId) {
      this.resetAllData();
      this.clientId = clientId;
      // this.getGroupsV2(true);
      // this.getUsersV2(true);
      this.getAwards();
      this.getMCCGroups();
      this.getMCCs();
    },
    addOrRemoveMCCPreset(item) {
      this.slideProvisional.customMCCToggle = false;
      var existingGroup = this.slideProvisional.selectedPresets.findIndex(
        x => item.mccGroupDefinitionId == x.mccGroupDefinitionId
      );
      if (existingGroup !== -1) {
        // Need to remove
        this.slideProvisional.selectedPresets.splice(existingGroup, 1);
      } else {
        if (item.mccGroupDefinitionId == -1) {
          this.slideProvisional.selectedPresets = [item];
        } else {
          var unrestrictedIndex = this.slideProvisional.selectedPresets.findIndex(
            x => -1 == x.mccGroupDefinitionId
          );
          if (unrestrictedIndex != -1) {
            this.slideProvisional.selectedPresets.splice(unrestrictedIndex, 1);
          }
          this.slideProvisional.selectedPresets.push(item);
        }
      }
      this.slideProvisional.infoSheet = false;
      this.slideProvisional.selectedGroup = null;
    },
    addOrRemoveMCC(item) {
      var existingMCC = this.slideProvisional.selectedMCC.findIndex(
        x => item.mccId == x.mccId
      );
      if (existingMCC !== -1) {
        // Need to remove
        this.slideProvisional.selectedMCC.splice(existingMCC, 1);
      } else {
        this.slideProvisional.selectedMCC.push(item);
      }
    },
    loadCustomMCCPage() {
      this.slideProvisional.selectedMCC = this.distinctMCCs;
      this.slideProvisional.selectedPresets = [];
      this.slideProvisional.customMCCToggle = true;
      this.slideKey = this.keys.mccCustom;
    },
    formatDateCadence(date, cadence) {
      if (cadence == "DAILY") {
        return (
          this.scheduledPaymentDates.length +
          (this.scheduledPaymentDates.length == 1 ? " day" : " days")
        );
      } else if (cadence == "WEEKLY") {
        return (
          momentTz(date)
            .startOf("day")
            .format("dddd[s]") +
          " for " +
          this.scheduledPaymentDates.length +
          (this.scheduledPaymentDates.length == 1 ? " week" : " weeks")
        );
      } else {
        return (
          momentTz(date)
            .startOf("day")
            .format("Do") +
          " for " +
          this.scheduledPaymentDates.length +
          (this.scheduledPaymentDates.length == 1 ? " month" : " months")
        );
      }
    },
    formatDate(date, formatString = "MMM Do, YYYY") {
      return moment(date).format(formatString);
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "PAYMENTS_CONSOLE",
        category: "PAYMENTS",
        date: new Date()
      };
      TriggerService.createActivity(obj)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    sendPaymentMemoTrigger() {
      // We want to fire off a platform activity for when they update the payment memo, but this can be done on any screen.
      // So this gets called when the payment memo field loses focus
      // We also want to make sure this only gets sent once.
      if (
        this.slideDetails.description &&
        this.slideDetails.memoPlatformActivityCounter == 0
      ) {
        this.createPlatformActivity("ADDED_PAYMENT_MEMO");
        this.slideDetails.memoPlatformActivityCounter++;
      }
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "permissions",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
        // if (
        //   this.slideKey === this.keys.people &&
        //   this.slidePeople.selected.length > 0 &&
        //   this.paymentAudienceCount > 0
        // ) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.details &&
        //   this.slideDetails.paymentType &&
        //   this.slideDetails.budgetId !== null
        // ) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.amount &&
        //   this.slideAmount.amountToggle !== null &&
        //   this.amountRemainingInBudget >= 0 &&
        //   ((this.slideAmount.amountToggle == 0 &&
        //     this.slideAmount.amount !== null &&
        //     parseFloat(this.slideAmount.amount) > 0 &&
        //     ((this.slideDetails.paymentType == "NON_CASH" &&
        //       !this.slideAmount.amount.toString().includes(".")) ||
        //       (this.slideDetails.paymentType != "NON_CASH" &&
        //         (!this.slideAmount.amount.toString().includes(".") ||
        //           (this.slideAmount.amount.toString().includes(".") &&
        //             this.slideAmount.amount.substring(
        //               this.slideAmount.amount.indexOf(".") + 1
        //             ).length < 3))))) ||
        //     (this.slideAmount.amountToggle == 1 &&
        //       this.slideAmount.usersWithAmounts.length > 0 &&
        //       this.amountArray.find((x) => x === null) === undefined &&
        //       this.amountArray.find((x) => parseFloat(x) <= 0) === undefined &&
        //       this.amountArray.find(
        //         (x) =>
        //           x !== null &&
        //           ((this.slideDetails.paymentType != "NON_CASH" &&
        //             x.toString().includes(".") &&
        //             x.substring(x.indexOf(".") + 1).length > 2) ||
        //             (this.slideDetails.paymentType == "NON_CASH" &&
        //               x.toString().includes(".")))
        //       ) === undefined))
        // ) {
        //   return false;
        // } else if (this.slideKey == this.keys.review) {
        //   if (
        //     this.amountRemainingInBudget < 0 ||
        //     !this.slideDetails.description
        //   ) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // } else if (this.slideKey == this.keys.sent) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.perDiemDetails &&
        //   this.slidePerDiem.city &&
        //   this.slidePerDiem.purpose &&
        //   this.slidePerDiem.amount !== null &&
        //   parseFloat(this.slidePerDiem.amount) > 0 &&
        //   (!this.slidePerDiem.amount.toString().includes(".") ||
        //     (this.slidePerDiem.amount.toString().includes(".") &&
        //       this.slidePerDiem.amount.substring(
        //         this.slidePerDiem.amount.indexOf(".") + 1
        //       ).length < 3))
        // ) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.perDiemDate &&
        //   this.slidePerDiem.dates &&
        //   this.slidePerDiem.dates.length > 0
        // ) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.provisionalAmount &&
        //   this.slideProvisional.amount !== null &&
        //   this.amountRemainingInBudget >= 0 &&
        //   parseFloat(this.slideProvisional.amount) > 0 &&
        //   (!this.slideProvisional.amount.toString().includes(".") ||
        //     (this.slideProvisional.amount.toString().includes(".") &&
        //       this.slideProvisional.amount.substring(
        //         this.slideProvisional.amount.indexOf(".") + 1
        //       ).length < 3)) &&
        //   // Logic to check if they selected presets
        //   (this.slideProvisional.selectedPresets.length > 0 ||
        //     this.slideProvisional.customMCCToggle)
        // ) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.provisionalSchedule &&
        //   this.slideProvisional.startDate &&
        //   ((this.slideProvisional.recurringToggle === false &&
        //     this.dateRules.includes(true)) ||
        //     (this.slideProvisional.recurringToggle === true &&
        //       this.slideProvisional.cadence &&
        //       this.slideProvisional.endDate &&
        //       this.dateRules.includes(true)))
        // ) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.expiration &&
        //   (this.slideProvisional.expirationToggle === false ||
        //     (this.slideProvisional.expirationToggle === true &&
        //       (this.slideProvisional.recurringToggle === true ||
        //         (this.slideProvisional.expirationDate &&
        //           this.expirationDateRules.includes(true)))))
        // ) {
        //   return false;
        // } else if (
        //   this.slideKey == this.keys.classify &&
        //   this.slideClassification.valid
        // ) {
        //   return false;
        // } else {
        //   return true;
        // }
      }
    },
    dateRules() {
      var isValid = true;
      var today = new momentTz().local().format("YYYY-MM-DD");
      if (this.slideDetails.paymentType == "PROVISIONAL") {
        if (this.slideProvisional.startDate) {
          var formattedStartDate = new momentTz(this.slideProvisional.startDate)
            .local()
            .format("YYYY-MM-DD");

          if (formattedStartDate < today) {
            return ["Start date should be in the future"];
          }
        }
        if (
          this.slideProvisional.endDate &&
          this.slideProvisional.recurringToggle === true
        ) {
          var formattedEndDate = new momentTz(this.slideProvisional.endDate)
            .local()
            .format("YYYY-MM-DD");
          console.log("End date rule");
          if (formattedEndDate < today) {
            return ["End date should be in the future"];
          } else if (
            this.slideProvisional.startDate &&
            formattedEndDate <= formattedStartDate
          ) {
            return ["End date should be later than the start date"];
          }
        }

        // return [
        //   () =>
        //     !!(formattedStartDate <= formattedEndDate) ||
        //     "Start date must be earlier than end date",
        // ];
      } else {
        // var formattedStartDate = new momentTz(this.slidePerDiem.startDate);
        // //   .local()
        // //   .format("YYYY-MM-DD");
        // // var formattedEndDate = new momentTz(this.learningModule.endDate)
        // //   .local()
        // //   .format("YYYY-MM-DD");
        // // return [
        // //   () =>
        // //     !!(formattedStartDate <= formattedEndDate) ||
        // //     "Start date must be earlier than end date",
        // // ];

        var formattedDate = null;

        if (this.scheduledPaymentDates) {
          this.scheduledPaymentDates.forEach(function(date) {
            formattedDate = new momentTz(date).local().format("YYYY-MM-DD");
            console.log(formattedDate);

            if (formattedDate < today) {
              isValid = false;
            }
          });
        }
      }
      return [isValid];
    },
    expirationDateRules() {
      var isValid = true;
      if (this.slideDetails.paymentType == "PROVISIONAL") {
        if (
          this.slideProvisional.startDate &&
          this.slideProvisional.expirationDate
        ) {
          var formattedStartDate = new momentTz(this.slideProvisional.startDate)
            .local()
            .format("YYYY-MM-DD");

          var formattedExpDate = new momentTz(
            this.slideProvisional.expirationDate
          )
            .local()
            .format("YYYY-MM-DD");
          if (formattedExpDate <= formattedStartDate) {
            return ["Expiration date should be later than the payment date"];
          }
        }
      }
      return [isValid];
    },
    scheduledPaymentDates() {
      // Arranges an array of all payment dates depending on what they set (provisional daily, weekly, monthly, per diem)
      console.log("calc date");
      if (this.slideDetails.paymentType == "PROVISIONAL") {
        if (
          !this.slideProvisional.cadence ||
          !this.slideProvisional.startDate ||
          !this.slideProvisional.endDate ||
          this.slideProvisional.recurringToggle === false
        ) {
          if (
            this.slideProvisional.startDate &&
            this.slideProvisional.recurringToggle === false
          ) {
            return [this.slideProvisional.startDate];
          }
          return [];
        } else if (this.dateRules.includes(true)) {
          var start = moment(this.slideProvisional.startDate, "YYYY-MM-DD")
            .local()
            .startOf("day");
          var end = moment(this.slideProvisional.endDate, "YYYY-MM-DD")
            .local()
            .startOf("day");
          var dates = [];
          var numDates = 0;
          if (this.slideProvisional.cadence == "DAILY") {
            console.log("Daily provisional");
            numDates = Math.ceil(moment.duration(end.diff(start) + 1).asDays());
            dates = [...Array(numDates).keys()].map(i =>
              start.clone().add(i, "d")
            );
          } else if (this.slideProvisional.cadence == "WEEKLY") {
            console.log("Weekly provisional");
            numDates = Math.ceil(
              moment.duration(end.diff(start) + 1).asWeeks()
            );
            dates = [...Array(numDates).keys()].map(i =>
              start.clone().add(i, "w")
            );
          } else {
            console.log("Monthly provisional");
            // console.log();
            // console.log(moment.duration(end.diff(start) + 1).as("months"));
            // numDates = Math.ceil(
            //   moment.duration(end.diff(start) + 1).asMonths()
            // );
            // dates = [...Array(numDates).keys()].map((i) =>
            //   start.clone().add(i, "M")
            // );
            dates.push(start);
            while (moment(end).diff(start, "month") > 0) {
              const currentEnd = moment(
                moment(start).add(1, "month"),
                "YYYY-MM-DD"
              )
                .utc()
                .startOf("day");

              dates.push(currentEnd);

              start = currentEnd;
            }
          }
          return dates;
        }
      } else {
        var pickerTitle = document.getElementsByClassName(
          "v-picker__title__btn--active"
        );
        if (this.slidePerDiem.consecutive) {
          console.log("consec'");

          const sortedDates = this.slidePerDiem.dates
            .slice()
            .sort((a, b) => (moment(b).isBefore(moment(a)) ? 1 : -1));
          //enumerate dates between and return arrray of all dates
          if (sortedDates && sortedDates.length > 0) {
            console.log(sortedDates[0]);
            var startDate = moment(sortedDates[0], "YYYY-MM-DD")
              .local()
              .startOf("day");

            dates = [];
            if (sortedDates.length > 1) {
              var endDate = moment(sortedDates[1], "YYYY-MM-DD")
                .local()
                .startOf("day");

              //console.log("---");
              //console.log(startDate);
              //console.log(endDate);

              // var now = startDate.clone();

              //console.log("===");

              //console.log("start cmpare");
              numDates = Math.ceil(
                moment.duration(endDate.diff(startDate) + 1).asDays()
              );
              // Now we have to get the distinct dates and put them in an array
              dates = [...Array(numDates).keys()].map(i =>
                startDate
                  .clone()
                  .add(i, "d")
                  .local()
              );

              // jQuery("v-picker__title__btn--active").html(
              //   dates.length + " selected!"
              // );
              if (pickerTitle.length > 0)
                pickerTitle[0].innerHTML = dates.length + " selected";
            } else {
              dates = [startDate];
            }
            return dates;
          } else {
            return null;
          }
        } else {
          //in the non-consecutive date picker, the array already contains all dates
          if (pickerTitle.length > 0)
            pickerTitle[0].innerHTML =
              this.slidePerDiem.dates.length + " selected";

          return this.slidePerDiem.dates;
        }
      }
      return [];
    },
    formattedProvisionalDateString() {
      if (
        this.slideProvisional.startDate &&
        this.slideProvisional.endDate &&
        this.slideProvisional.cadence
      ) {
        var formatted = "Your payment will occur ";
        if (this.slideProvisional.cadence == "DAILY")
          formatted +=
            "daily for " +
            this.scheduledPaymentDates.length +
            (this.scheduledPaymentDates.length == 1 ? " day" : " days");
        else if (this.slideProvisional.cadence == "WEEKLY")
          formatted +=
            "weekly on " +
            momentTz(this.slideProvisional.startDate)
              .startOf("day")
              .format("dddd[s]") +
            " for " +
            this.scheduledPaymentDates.length +
            (this.scheduledPaymentDates.length == 1 ? " week" : " weeks");
        else
          formatted +=
            "monthly on the " +
            momentTz(this.slideProvisional.startDate)
              .startOf("day")
              .format("Do") +
            " for " +
            this.scheduledPaymentDates.length +
            (this.scheduledPaymentDates.length == 1 ? " month" : " months");
        return formatted;
      }
      return " ";
    },
    dateDisplayText() {
      if (
        this.slidePerDiem.consecutive &&
        this.slidePerDiem.dates &&
        this.slidePerDiem.dates.length > 0
      ) {
        return this.slidePerDiem.dates.join("~");
      } else {
        return this.slidePerDiem.dates;
      }
    },

    // amountArray() {
    //   // We map the amount and return it so we can easily check in the disableContinueButton if all amounts are valid
    //   return this.slideAmount.usersWithAmounts.map(x => x.amount);
    // },
    // peopleInSelectedGroups() {
    //   if (this.slidePeople.activeGroup == 1) {
    //     // We have to get distinct users in these groups
    //     var users = [];
    //     for (var i = 0; i < this.slidePeople.selected.length; i++) {
    //       users = users.concat(
    //         this.slidePeople.selected[i].GroupAssignments.map(x => x.userId)
    //       );
    //     }
    //     users = [...new Set(users)];
    //     return users;
    //   } else {
    //     return this.slidePeople.selected;
    //   }
    // },
    paymentAudienceCount() {
      console.log("Resetting paymentAudienceCount", {
        tab: this.slidePeople.activeGroup,
        loading: this.loading.distinctUsers,
        value: this.values.distinctUsers
      });
      // On the user table
      if (this.slidePeople.activeGroup == 0)
        return this.slidePeople.selected.length;
      // Loading so we just make it wait
      else if (this.loading.distinctUsers) return 0;
      // Return the cached value if we have it
      else if (this.values.distinctUsers) return this.values.distinctUsers;

      let count = 0;
      var arr = [];

      this.slidePeople.selected.forEach(item => {
        if (item.groupId && item.numUsers) count += item.numUsers;
        else if (item.groupId && item.GroupAssignments)
          arr = arr.concat(item.GroupAssignments.map(x => x.userId));
        else if (item.userId) arr.push(item.userId);
      });

      if (count != 0) return count;
      return [...new Set(arr)].length;
    },
    visibleAudience() {
      // if (this.slidePeople.activeGroup == 1) {
      //   let startingIndex = this.groupTable.options.page - 1 || 0;
      //   let itemsPerPage =
      //     (this.groupTable.options && this.groupTable.options.itemsPerPage) ||
      //     this.groupTable.itemsPerPage;
      //   return this.slidePeople.groups
      //     .slice()
      //     .slice(
      //       startingIndex * itemsPerPage,
      //       startingIndex * itemsPerPage + itemsPerPage
      //     )
      //     .filter(x => x !== undefined);
      // }
      let startingIndex = this.userTable.options.page - 1 || 0;
      let itemsPerPage =
        (this.userTable.options && this.userTable.options.itemsPerPage) ||
        this.userTable.itemsPerPage;
      return this.slidePeople.users
        .slice()
        .slice(
          startingIndex * itemsPerPage,
          startingIndex * itemsPerPage + itemsPerPage
        )
        .filter(x => x !== undefined);
      // return this.slidePeople.users; //.filter((x) => x.display);
    },
    visibleAwards() {
      return this.awards.filter(x => this.clientId == x.clientId);
    },
    visibleBudgets() {
      var awardId = 1;
      if (
        this.slideDetails.paymentType == "NON_CASH" &&
        this.slideDetails.awardId
      ) {
        awardId = this.slideDetails.awardId;
      }
      return this.budgets.filter(
        x =>
          x.awardId == awardId &&
          x.clientId == this.clientId &&
          x.budgetBalance > 0
      );
    },
    selectedBudget() {
      if (this.slideDetails.budgetId !== null) {
        var budget = this.budgets.find(
          x => x.budgetId == this.slideDetails.budgetId
        );

        return budget;
      }
      return null;
    },
    selectedAward() {
      if (this.selectedBudget) {
        return this.selectedBudget.Award;
      }
      return null;
    },
    totalBeingSpent() {
      var sumSpent = currency(0);
      //perdiem
      if (this.slideDetails.paymentType == "PER_DIEM") {
        console.log("calc perdiem amount");
        if (this.slidePerDiem.amount && this.scheduledPaymentDates) {
          sumSpent = currency(this.slidePerDiem.amount)
            .multiply(this.paymentAudienceCount)
            .multiply(this.scheduledPaymentDates.length);
          // this.paymentAudienceCount *
          // this.scheduledPaymentDates.length;
        }
      } else if (this.slideDetails.paymentType == "PROVISIONAL") {
        if (this.slideProvisional.amount && this.scheduledPaymentDates) {
          sumSpent = currency(this.slideProvisional.amount)
            .multiply(this.paymentAudienceCount)
            .multiply(this.scheduledPaymentDates.length);
          // (parseInt(100 * parseFloat(this.slideProvisional.amount)) *
          //   this.paymentAudienceCount *
          //   this.scheduledPaymentDates.length) /
          // 100;
        }
      } else {
        //Not perdiem, spot bonus:
        // if (this.slideAmount.amountToggle == 0) {
        if (this.slideAmount.amount) {
          sumSpent = currency(this.slideAmount.amount).multiply(
            this.paymentAudienceCount
          );
        }
        // } else {
        //   this.amountArray.forEach(amount => {
        //     if (amount) {
        //       sumSpent = sumSpent.add(amount);
        //     }
        //   });
        // }
      }
      console.log("Sum spent ", sumSpent.value);
      return sumSpent.value;
    },
    totalBeingSpentFormatted() {
      return currency(this.totalBeingSpent, {
        separator: ",",
        symbol: this.slideDetails.paymentType != "NON_CASH" ? "$" : ""
      }).format();
      // currency(amount, { separator: ",", symbol: "" }).format();
    },
    budgetBalance() {
      if (this.selectedBudget && this.selectedBudget.budgetBalance)
        return currency(this.selectedBudget.budgetBalance).value;
      else return 0;
    },
    budgetBalanceFormatted() {
      return currency(this.budgetBalance, {
        separator: ",",
        symbol:
          this.selectedBudget && this.selectedBudget.awardId == 1 ? "$" : ""
      }).format();
    },
    amountRemainingInBudget() {
      return currency(this.budgetBalance).subtract(this.totalBeingSpent).value;
    },
    amountRemainingInBudgetFormatted() {
      return currency(this.amountRemainingInBudget, {
        separator: ",",
        symbol:
          this.selectedBudget && this.selectedBudget.awardId == 1 ? "$" : ""
      }).format();
    },
    audienceHeaders() {
      if (this.slidePeople.activeGroup == 1) {
        return [
          {
            align: "start",
            sortable: false,
            value: "select",
            width: "35px"
          },
          {
            align: "start",
            sortable: true,
            value: "groupDisplayName"
          },
          {
            align: "start",
            sortable: true,
            value: "numUsers",
            width: "50%"
          },

          {
            value: "info",
            width: "100px",
            align: "end",
            sortable: false
          }
        ];
      }
      return [
        {
          align: "start",
          sortable: false,
          value: "select",
          width: "35px"
        },
        {
          align: "start",
          sortable: true,
          value: "displayName",
          width: "90%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessEmail",
          width: "0%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessPhone",
          width: "0%"
        },
        {
          value: "info",
          width: "10%",
          align: "end",
          sortable: false
        }
      ];
    },
    computedAmountRules() {
      var rules = [
        v =>
          !!(v && parseFloat(v) > 0) || "Payment amount must be greater than 0"
      ];
      if (this.selectedBudget)
        rules.push(
          v =>
            !!(
              v &&
              (!v.toString().includes(".") ||
                (v.toString().includes(".") &&
                  v.substring(v.indexOf(".") + 1).length < 3))
            ) || `Payment amount shouldn't be longer than 2 decimals.`
        );
      // } else if (this.selectedBudget) {
      //   rules.push(
      //     (v) =>
      //       !!(v && !v.toString().includes(".")) ||
      //       `Payment amount shouldn't contain a decimal.`
      //   );
      // }
      return rules;
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        if (
          this.slideDetails.paymentType == "COMPENSATION" ||
          this.slideDetails.paymentType == "NON_CASH" ||
          this.slideDetails.paymentType == null
        ) {
          return [
            this.keys.people,
            this.keys.details,
            this.keys.amount,
            this.keys.review,
            this.keys.send,
            this.keys.sent
          ];
        } else if (this.slideDetails.paymentType == "PER_DIEM") {
          return [
            this.keys.people,
            this.keys.details,
            this.keys.perDiemDetails,
            this.keys.perDiemDate,
            this.keys.expiration,
            this.keys.review,
            this.keys.send,
            this.keys.sent
          ];
        } else {
          if (this.slideProvisional.customMCCToggle) {
            return [
              this.keys.people,
              this.keys.details,
              this.keys.provisionalAmount,
              this.keys.mccCustom,
              this.keys.provisionalSchedule,
              this.keys.expiration,
              this.keys.classify,
              this.keys.review,
              this.keys.send,
              this.keys.sent
            ];
          }
          return [
            this.keys.people,
            this.keys.details,
            this.keys.provisionalAmount,

            this.keys.provisionalSchedule,
            this.keys.expiration,
            this.keys.classify,
            this.keys.review,
            this.keys.send,
            this.keys.sent
          ];
        }
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (
            page == this.keys.people &&
            this.slidePeople.selected.length > 0 &&
            // Commenting out so we can just fetch groups and block before the last page
            this.paymentAudienceCount > 0
          ) {
            array.push(true);
          } else if (
            page == this.keys.details &&
            this.slideDetails.paymentType &&
            this.slideDetails.budgetId !== null
          ) {
            array.push(true);
          } else if (
            page == this.keys.amount &&
            // this.slideAmount.amountToggle !== null &&
            this.amountRemainingInBudget >= 0 &&
            // (
            // this.slideAmount.amountToggle == 0 &&
            this.slideAmount.amount !== null &&
            parseFloat(this.slideAmount.amount) > 0 &&
            (!this.slideAmount.amount.toString().includes(".") ||
              (this.slideAmount.amount.toString().includes(".") &&
                this.slideAmount.amount.substring(
                  this.slideAmount.amount.indexOf(".") + 1
                ).length < 3))
            // ||
            // (this.slideAmount.amountToggle == 1 &&
            //   this.slideAmount.usersWithAmounts.length > 0 &&
            //   this.amountArray.find(x => x === null) === undefined &&
            //   this.amountArray.find(x => parseFloat(x) <= 0) === undefined &&
            //   this.amountArray.find(
            //     x =>
            //       x !== null &&
            //       x.toString().includes(".") &&
            //       x.substring(x.indexOf(".") + 1).length > 2
            //   ) === undefined))
          ) {
            array.push(true);
          } else if (
            page == this.keys.perDiemDetails &&
            this.slidePerDiem.location &&
            this.slidePerDiem.purpose &&
            this.slidePerDiem.amount !== null &&
            parseFloat(this.slidePerDiem.amount) > 0 &&
            (!this.slidePerDiem.amount.toString().includes(".") ||
              (this.slidePerDiem.amount.toString().includes(".") &&
                this.slidePerDiem.amount.substring(
                  this.slidePerDiem.amount.indexOf(".") + 1
                ).length < 3))
          ) {
            array.push(true);
          } else if (
            page == this.keys.perDiemDate &&
            this.slidePerDiem.dates.length > 0 &&
            this.dateRules.includes(true)
          ) {
            array.push(true);
          } else if (
            page == this.keys.provisionalAmount &&
            this.slideProvisional.amount !== null &&
            this.amountRemainingInBudget >= 0 &&
            parseFloat(this.slideProvisional.amount) > 0 &&
            (!this.slideProvisional.amount.toString().includes(".") ||
              (this.slideProvisional.amount.toString().includes(".") &&
                this.slideProvisional.amount.substring(
                  this.slideProvisional.amount.indexOf(".") + 1
                ).length < 3)) &&
            // Logic to check if they selected presets
            (this.slideProvisional.selectedPresets.length > 0 ||
              this.slideProvisional.customMCCToggle)
          ) {
            array.push(true);
          } else if (
            page == this.keys.mccCustom &&
            this.slideProvisional.selectedMCC.length > 0
          ) {
            array.push(true);
          } else if (
            page == this.keys.provisionalSchedule &&
            this.slideProvisional.startDate &&
            ((this.slideProvisional.recurringToggle === false &&
              this.dateRules.includes(true)) ||
              (this.slideProvisional.recurringToggle === true &&
                this.slideProvisional.cadence &&
                this.slideProvisional.endDate &&
                this.dateRules.includes(true)))
          ) {
            array.push(true);
          } else if (
            page == this.keys.expiration &&
            (this.slideProvisional.expirationToggle === false ||
              // If per diem then it's recurring by default
              (this.slideDetails.paymentType == "PER_DIEM" &&
                this.slideProvisional.expirationToggle === true) ||
              (this.slideProvisional.expirationToggle === true &&
                (this.slideProvisional.recurringToggle === true ||
                  (this.slideProvisional.expirationDate &&
                    this.expirationDateRules.includes(true)))))
          ) {
            array.push(true);
          } else if (
            page == this.keys.classify &&
            this.slideClassification.valid
          ) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            this.slideDetails.paymentType == "NON_CASH" &&
            this.amountRemainingInBudget >= 0
          ) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            this.slideDetails.paymentType != "NON_CASH" &&
            this.amountRemainingInBudget >= 0 &&
            this.slideDetails.description &&
            !this.loading.distinctUsers
          ) {
            array.push(true);
          } else if (page == this.keys.send) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        console.log(array);
        return array;
      }
    },
    distinctMCCs() {
      var mcc = [];
      if (this.slideProvisional.selectedPresets.length > 0) {
        this.slideProvisional.selectedPresets.forEach(group => {
          if (group.MCCGroupAssignments) {
            mcc = mcc.concat(group.MCCGroupAssignments.map(x => x.MCC));
          }
        });
      } else if (this.slideProvisional.selectedMCC.length > 0) {
        mcc = this.slideProvisional.selectedMCC;
      }
      mcc = [...new Set(mcc)];
      return mcc;
    }
  },
  watch: {
    "slidePeople.debounceSearch": debounce(function(newVal) {
      this.slidePeople.search = newVal;
      // if (this.slidePeople.activeGroup == 0) this.getUsersV2(true);
      // else this.getGroupsV2(true);
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.primary-text {
  color: var(--v-primary-base);
}
.border-radius {
  border-radius: 100%;
}
.header-text {
  font-size: x-large;
}
.small-text {
  font-size: small;
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.confirmation-page-card {
  width: 90%;
}
.people-group-button-oval {
  padding: 5px 5px;
  border-radius: 40px;
  min-width: 130px;
  width: 180px;
  background-color: var(--v-lightGrey-base);
}
.audience-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 105px;
  width: 105px;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mcc-container {
  max-width: calc(100% - 87px);
}
</style>

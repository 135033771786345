<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :key="1"
          :hide-default-header="hideDefaultHeader"
          :headers="headers"
          :items="visibleItems"
          :items-per-page="table.itemsPerPage"
          :footer-props="table.footerProps"
          :options.sync="table.options"
          :server-items-length="table.total"
          :show-select="false"
          :loading="table.loading"
          single-select
          loading-text="loading"
          must-sort
          sort-by="programId"
          sort-desc
          no-data-text="No programs found"
          no-results-text="No programs found"
          item-key="programId"
          id="table"
          class="table cursor-pointer"
          color="brandCyan"
          @click:row="selectItem($event, false)"
        >
          <template v-slot:[`item.displayName`]="{ item }">
            <span class="word-break">{{ item.displayName }}</span>
          </template>
          <template v-slot:[`item.info`]="{ item }">
            <v-btn icon @click.stop="previewItem(item)"
              ><v-icon color="grey">mdi-dots-horizontal</v-icon></v-btn
            >
          </template>

          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
        </v-data-table>
        <v-bottom-sheet v-model="dialog.preview" inset width="500">
          <v-sheet class="text-center" :height="'375px'" v-if="item">
            <div class="pt-4">
              <div class="d-flex align-center justify-space-between mx-6">
                <v-btn fab color="lightGrey" small class="hidden"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
                <v-card-title class="word-break">
                  <b> {{ item.displayName }}</b>
                </v-card-title>
                <v-btn
                  fab
                  color="mediumGrey"
                  small
                  depressed
                  elevation="0"
                  class="ignore-click"
                  @click="dialog.preview = !dialog.preview"
                  ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                >
              </div>
              <div>
                <v-img
                  v-bind:src="item.imageURL"
                  v-if="item.imageURL"
                  width="150"
                  max-width="150"
                  :aspect-ratio="16 / 9"
                  class="box-border-global mx-auto"
                />
                <p class="mb-1 mt-4">Start date: {{ item.startDate }}</p>
                <p class="mb-1" v-if="item.endDate">
                  End date: {{ item.endDate }}
                </p>
                <p class="mb-1" v-if="item.rollingProgramLength">
                  Program length: {{ item.rollingProgramLength }}
                  {{ item.rollingProgramLength == 1 ? "day" : "days" }}
                </p>
                <!-- <v-divider class="mx-7" />
                <div class="d-flex ml-6 mt-6">
                  <p class="darkGrey--text mb-2">
                    Email:
                    <b class="black--text">{{
                      item.businessEmail ? item.businessEmail : "-"
                    }}</b>
                  </p>
                </div>
                <div class="d-flex ml-6">
                  <p class="darkGrey--text">
                    Phone:
                    <b class="black--text">{{
                      item.businessPhone ? item.businessPhone : "-"
                    }}</b>
                  </p>
                </div> -->
              </div>
              <v-btn
                v-if="item && item.programId"
                color="brandCyan"
                rounded
                depressed
                width="90"
                @click="selectItem(item, true)"
                class="mt-4 white--text ignore-click"
                >Select</v-btn
              >
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProgramService from "@/services/ProgramService";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "ProgramTable",
  props: {
    selected: {
      type: Array
    },
    search: {
      type: String
    },
    sourcePage: {
      type: String,
      default: "spot-communications"
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    },
    displayEmail: {
      type: Boolean,
      default: false
    },
    displayInfo: {
      type: Boolean,
      default: true
    },
    external: {
      type: Boolean,
      default: true
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      loading: {
        assignments: false,
        analytics: []
      },
      dialog: {
        preview: false
      },
      item: null,
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        items: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null,
        expanded: []
      }
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getItems(reset = false, source = null, preload = null) {
      try {
        // First, if they have a preloaded Id and it's an object, we add that now just in case the api result gets dismissed
        let alreadyPreloaded = false;
        if (preload && typeof preload == "object") {
          alreadyPreloaded = true;
          this.selectItem(preload, true);
        }
        console.log("Getting programs from ", source);
        console.log({ reset, source, preload });
        this.table.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let search = this.search ? this.search.replace(/'/g, "\\'") : null;
        let filter = `status.isIn(['Active', 'Scheduled']) && (type == null || type != 'procore') && clientId = ${this.clientId}`;
        if (search) {
          // If search we need to search for every field that's in the table
          filter += ` && (displayName.like('%${search}%') || status.like('%${search}%') || programId = '${search}')`;
        }
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        let sort = "programId DESC";
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          console.log("Sort ", options);
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          sort = column + " " + type;
        }
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          filter,
          offset,
          limit,
          sort,
          clientId: this.clientId,
          screen: this.sourcePage,
          source: this.sourcePage,
          search: this.search,
          expand: "ProgramCache"
        };
        let response = await ProgramService.getProgramsV2(params);

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          // filter != this.table.latestFilter ||
          this.search != params.search ||
          sort != this.table.latestSort ||
          page != this.table.options.page ||
          limit != this.table.options.itemsPerPage ||
          this.clientId != params.clientId
        ) {
          console.log("Ignoring API response");
          return;
        }

        console.log("Got programs ", response);
        this.table.total = response.result.count;
        let rows = response.result.rows.map(r => {
          if (r.startDate)
            r.startDate = moment(r.startDate).format("MMM D, YYYY h:mm a");
          if (r.endDate)
            r.endDate = moment(r.endDate).format("MMM D, YYYY h:mm a");
          return r;
        });

        this.table.items = rows;
        this.table.loading = false;
      } catch (err) {
        console.log("Error getting programs ", err);
        this.table.loading = false;
      }
    },
    selectItem(x, preventRemoval = false) {
      console.log("Selecting item ", x);
      this.$emit("select-item", {
        ...x,
        // Added because we can only pass one param in
        dontRemove: preventRemoval ? true : undefined
      });
      this.dialog.preview = false;
    },
    previewItem(item) {
      console.log("Previewing ", item);
      this.item = item;
      this.dialog.preview = true;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleItems() {
      let array = this.table.items || [];

      array = array.filter(x => x.status != "Deleted");

      if (this.table.loading) return [];
      return array;
    },
    headers() {
      return [
        {
          text: "Title",
          align: "start",
          sortable: true,
          value: "displayName",
          width: "25%"
        },
        // {
        //   text: "Status",
        //   align: "start",
        //   sortable: true,
        //   value: "status",
        //   width: "20%"
        // },
        this.displayInfo
          ? {
              value: "info",
              width: "100px",
              align: "end",
              sortable: false
            }
          : undefined
        // this.displayInfo
        //   ? { text: "Details", value: "data-table-expand", width: "10%" }
        //   : undefined
      ].filter(Boolean);
    }
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }

        if (apiCall) this.getItems(reset, "watcher");
      },
      deep: true
    },
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getItems(true, "client watcher");
      }
    },
    search: function() {
      this.getItems(true, "search watcher");
    }
  }
};
</script>

<style scoped>
/* Removes box shadow from table expansion */
#table >>> .v-data-table__expanded__content {
  box-shadow: none;
}

/* Hover and background for expanded buttons */
.preview-button {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
}
.preview-button:hover {
  background-color: #eee;
}

/* Caps width of page view bar */
.page-views-bar {
  min-width: 225px;
  max-width: 330px;
}
</style>

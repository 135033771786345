import {
  main as axios,
  oauth as oauthAxios,
  noAuth as noAuthAxios
} from "../axios";

class MarqetaService {
  getUser(
    passedToken = null,
    passedMagicLinkToken = null,
    magicLinkToken = null,
    params = {},
    v2 = false
  ) {
    let opt =
      passedToken || passedMagicLinkToken
        ? {
            headers: {
              authorization: "Bearer " + (passedMagicLinkToken || passedToken)
            },
            params
          }
        : { params };
    if (passedMagicLinkToken && opt) opt.headers["x-whistle-oauth"] = 1;

    return (passedToken || passedMagicLinkToken
      ? noAuthAxios
      : magicLinkToken
      ? oauthAxios
      : axios
    )
      .get(process.env.VUE_APP_MARQETA_URL + "/user", opt)
      .then(response => {
        console.log(response.data.user);
        return response.data.user;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        if (v2 === true)
          throw error?.response?.data || error?.response || error;
        return error.response.data;
      });
  }
  getBalance(magicLinkToken = null, params = {}) {
    let opt = { params };

    return (magicLinkToken ? oauthAxios : axios)
      .get(process.env.VUE_APP_MARQETA_URL + "/balance", opt)
      .then(response => {
        console.log(response.data.balance);
        return response.data.balance;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        throw error?.response?.data || error?.response || error;
      });
  }
  createUser(body, throwError = false, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(process.env.VUE_APP_MARQETA_URL + "/user", body)
      .then(response => {
        console.log(response.data.user);
        return response.data.user;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        if (throwError) throw error.response.data;
        else return error.response.data;
      });
  }
  updateUser(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .put(process.env.VUE_APP_MARQETA_URL + "/user", body)
      .then(response => {
        console.log(response.data.user);
        return response.data.user;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        return error.response.data;
      });
  }
  createBusiness(body, params = {}) {
    return axios
      .post(process.env.VUE_APP_MARQETA_URL + "/business", body, { params })
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response);
        return error.response.data;
      });
  }
  getCards(
    magicLinkToken = null,
    adminUserId = null,
    adminClientId = null,
    throwError = false
  ) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(
        process.env.VUE_APP_MARQETA_URL +
          `/cards${
            adminUserId && adminClientId
              ? `/admin/${adminClientId}/${adminUserId}`
              : ""
          }`,
        { withCredentials: true }
      )
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log(
          "Marqeta error: ",
          error.response && error.response.data ? error.response.data : error
        );
        if (throwError) throw error.response.data;
        return error.response.data;
      });
  }
  issueCard(type, body, magicLinkToken = null) {
    return (
      (magicLinkToken ? oauthAxios : axios)
        .post(process.env.VUE_APP_MARQETA_URL + "/cards/" + type, body)
        // .post(process.env.VUE_APP_MARQETA_URL + "/card/", body)
        .then(response => {
          console.log(response.data);
          return response.data;
        })
        .catch(error => {
          console.log("Marqeta error: ", error.response);
          return error.response.data;
        })
    );
  }
  replaceCard(cardType, replacementType, body, magicLinkToken = null) {
    return (
      (magicLinkToken ? oauthAxios : axios)
        .post(
          process.env.VUE_APP_MARQETA_URL +
            "/cards/replace/" +
            replacementType +
            "/" +
            cardType,
          body
        )
        // .post(process.env.VUE_APP_MARQETA_URL + "/card/", body)
        .then(response => {
          console.log(response.data);
          return response.data;
        })
        .catch(error => {
          console.log("Marqeta error: ", error.response);
          return error.response.data;
        })
    );
  }
  cancelCard(cardType, replacementType, magicLinkToken = null) {
    return (
      (magicLinkToken ? oauthAxios : axios)
        .post(
          `${process.env.VUE_APP_MARQETA_URL}/cards/cancel/${replacementType}/${cardType}`,
          {}
        )
        // .post(process.env.VUE_APP_MARQETA_URL + "/card/", body)
        .then(response => {
          console.log(response.data);
          return response.data;
        })
        .catch(error => {
          console.log("Marqeta error: ", error.response);
          return error.response.data;
        })
    );
  }
  lockCard(token, body) {
    return axios
      .post(process.env.VUE_APP_MARQETA_URL + `/cards/lock/${token}`, body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "MQ Lock error: ",
          error.response && error.response.data ? error.response.data : error
        );
        throw error.response.data;
      });
  }
  getVirtualCardWidget(
    passedToken = null,
    passedMagicLinkToken = null,
    magicLinkToken = null
  ) {
    //the app has to use a hosted virtual card page, and pass in the user token. In this case, we have to set the token in the header
    let opt =
      passedToken || passedMagicLinkToken
        ? {
            headers: {
              authorization: "Bearer " + (passedMagicLinkToken || passedToken)
            }
          }
        : undefined;
    if (passedMagicLinkToken && opt) opt.headers["x-whistle-oauth"] = 1;

    return (passedToken || passedMagicLinkToken
      ? noAuthAxios
      : magicLinkToken
      ? oauthAxios
      : axios
    )
      .get(process.env.VUE_APP_MARQETA_URL + "/cards/PCI/virtual", opt)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response);
        return error.response.data;
      });
  }
  getCardActivationWidget(passedToken = null, magicLinkToken = null) {
    //the app has to use a hosted virtual card page, and pass in the user token. In this case, we have to set the token in the header
    let opt = passedToken
      ? {
          headers: {
            authorization: "Bearer " + passedToken
          }
        }
      : undefined;

    return (passedToken ? noAuthAxios : magicLinkToken ? oauthAxios : axios)
      .get(process.env.VUE_APP_MARQETA_URL + "/cards/PCI/activate", opt)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response);
        return error.response.data;
      });
  }
  getCardPINWidget(cardType, passedToken = null, magicLinkToken = null) {
    //the app has to use a hosted virtual card page, and pass in the user token. In this case, we have to set the token in the header
    let opt = passedToken
      ? {
          headers: {
            authorization: "Bearer " + passedToken
          }
        }
      : undefined;

    return (passedToken ? noAuthAxios : magicLinkToken ? oauthAxios : axios)
      .get(process.env.VUE_APP_MARQETA_URL + "/cards/PCI/pin/" + cardType, opt)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response);
        return error.response.data;
      });
  }
  getBusinesses() {
    return axios
      .get(process.env.VUE_APP_MARQETA_URL + "/business")
      .then(response => {
        console.log(response.data.business);
        return response.data.business;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        return error.response.data;
      });
  }
  createBusinessLog(body) {
    return axios
      .post(process.env.VUE_APP_MARQETA_URL + "/kyc/businessLog", body)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        return error.response.data;
      });
  }
  updateBusinessLog(logId, body) {
    return axios
      .patch(
        process.env.VUE_APP_MARQETA_URL + "/kyc/businessLog/" + logId,
        body
      )
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        return error.response.data;
      });
  }
  createDispute(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(process.env.VUE_APP_MARQETA_URL + "/dispute", body)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Dispute error");
        throw error?.response?.data || error?.response || error;
      });
  }
  getDisputes() {
    return axios
      .get(process.env.VUE_APP_MARQETA_URL + "/dispute")
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response.data);
        return error.response.data;
      });
  }
  simulateTransaction(type, body) {
    return axios
      .post(process.env.VUE_APP_MARQETA_URL + "/transaction/" + type, body)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response);
        return error.response.data;
      });
  }

  resetBusiness(clientId) {
    return axios
      .delete(process.env.VUE_APP_MARQETA_URL + "/business/reset/" + clientId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response);
        return error.response.data;
      });
  }
  setupPaymentAccount(body) {
    return axios
      .post(process.env.VUE_APP_MARQETA_URL + "/business/setup", body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Marqeta error: ", error.response || error);
        throw error.response ? error.response.data || error : error;
      });
  }
}

export default new MarqetaService();

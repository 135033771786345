<template>
  <div class="mx-8">
    <p class="text-h5">{{ card.displayName }}</p>
    <p class="mt-10 font-weight-bold brand--text">
      {{ card.MetricDefinition.description }}
    </p>
    <v-divider />
    <div
      v-if="!displayResults && !displayError"
      class="my-8 d-flex flex-column justify-center align-center input-field mx-auto"
    >
      <p v-if="card.goalLabel" v-html="card.goalLabel"></p>
      <!-- <p
        v-else-if="
          !['BOOLEAN', 'TIMESTAMP', 'DATE', 'STRING'].includes(
            card.MetricDefinition.dataType
          )
        "
      >
        You've
        {{
          card.MetricDefinition && card.MetricDefinition.verb
            ? card.MetricDefinition.verb.toLowerCase()
            : "entered"
        }}
        <span class="font-weight-bold">{{ card.progressValue || 0 }}</span> out
        of
        <span class="font-weight-bold">{{ card.value }}</span>
        {{
          card.value == 1 && card.MetricDefinition.unitSingular
            ? card.MetricDefinition.unitSingular
            : card.value == 1
            ? "unit"
            : card.MetricDefinition.unitPlural || "units"
        }}
      </p> -->

      <p
        v-else-if="
          ['TIMESTAMP', 'DATE'].includes(card.MetricDefinition.dataType)
        "
      >
        Target:
        <span class="font-weight-bold">{{ computedDateGoal || "N/A" }}</span>
      </p>
      <!-- <p v-else-if="card.MetricDefinition.dataType == 'STRING'">
        {{ card.displayName }} should
        {{ card.operator == "NOT_EQUAL" ? "not be equal to" : "be equal to" }}
        <span class="font-weight-bold">"{{ card.value }}"</span>
      </p> -->
      <p
        v-if="!card.MetricDefinition.selfAssess"
        class="self-assess text-center mb-0"
      >
        Your manager will update this metric as you progress
      </p>
      <div v-else>
        <v-form v-model="valid" onSubmit="return false;">
          <v-text-field
            :label="card.inputLabel"
            hide-details
            dense
            outlined
            :rules="[rules.emptyString, rules.longString]"
            v-model="inputValue"
            @keyup.enter="updateMetric"
            v-if="card.MetricDefinition.dataType === 'STRING'"
          >
            <template v-slot:prepend-inner>
              <!-- SYMPBOL -->
              <div
                :style="{ 'margin-top': '4px' }"
                v-if="
                  card.MetricDefinition &&
                    card.MetricDefinition.prefixSymbol != null
                "
              >
                {{ card.MetricDefinition.prefixSymbol }}
              </div>
            </template>
            <template v-slot:append>
              <!-- UNITS -->
              <div
                :style="{ 'margin-top': '4px' }"
                v-if="
                  card.MetricDefinition &&
                    card.MetricDefinition.unitPlural != null
                "
              >
                {{ card.MetricDefinition.unitPlural }}
              </div>
              <div
                :style="{ 'margin-top': '4px' }"
                v-else-if="
                  card.MetricDefinition &&
                    card.MetricDefinition.unitSingular != null
                "
              >
                {{ card.MetricDefinition.unitSingular }}
              </div>
            </template>
          </v-text-field>
          <v-text-field
            :label="card.inputLabel"
            hide-details
            dense
            outlined
            :rules="
              card.MetricDefinition.dataType === 'INTEGER'
                ? [rules.emptyString, rules.integer, rules.longString]
                : [rules.emptyString, rules.longString]
            "
            v-model="inputValue"
            v-else-if="
              card.MetricDefinition.dataType === 'INTEGER' ||
                card.MetricDefinition.dataType === 'DECIMAL'
            "
            @keyup.enter="updateMetric"
            type="number"
          >
            <template v-slot:prepend-inner>
              <!-- SYMPBOL -->
              <div
                :style="{ 'margin-top': '4px' }"
                v-if="
                  card.MetricDefinition &&
                    card.MetricDefinition.prefixSymbol != null
                "
              >
                {{ card.MetricDefinition.prefixSymbol }}
              </div>
            </template>
            <template v-slot:append>
              <!-- UNITS -->
              <div
                :style="{ 'margin-top': '4px' }"
                v-if="
                  card.MetricDefinition &&
                    card.MetricDefinition.unitPlural != null
                "
              >
                {{ card.MetricDefinition.unitPlural }}
              </div>
              <div
                :style="{ 'margin-top': '4px' }"
                v-else-if="
                  card.MetricDefinition &&
                    card.MetricDefinition.unitSingular != null
                "
              >
                {{ card.MetricDefinition.unitSingular }}
              </div>
            </template>
          </v-text-field>
          <div
            v-else-if="
              card.MetricDefinition.dataType === 'DATE' ||
                card.MetricDefinition.dataType === 'TIMESTAMP'
            "
          >
            <p class="ma-0 pb-0 text-caption font-weight-regular date-label">
              <span>{{ card.inputLabel }}</span>
            </p>
            <input
              class="timestamp-input mt-1"
              :type="
                card.MetricDefinition.dataType === 'DATE'
                  ? 'date'
                  : 'datetime-local'
              "
              v-model="inputValue"
              @keyup.enter="updateMetric"
            />
          </div>
          <div v-else-if="card.MetricDefinition.dataType == 'BOOLEAN'">
            <!-- <p :style="{ 'text-align': 'start' }" class="pl-3 pt-2 my-0">
        This will mark {{ card.MetricDefinition.metricName }} as completed. This
        action cannot be undone.
      </p> -->
            <!-- <div> -->
            <v-checkbox
              v-model="inputValue"
              :true-value="'1'"
              :false-value="'0'"
              :label="
                card.MetricDefinition.verb
                  ? card.MetricDefinition.verb.substring(0, 1).toUpperCase() +
                    card.MetricDefinition.verb.substring(1).toLowerCase()
                  : 'Completed'
              "
              hide-details
              class="pa-0 ma-0"
            >
            </v-checkbox>
            <!-- </div> -->
          </div>
        </v-form>
      </div>
    </div>
    <div v-else class="my-8 d-flex  justify-center align-center mx-auto">
      <div
        class="outer-circle"
        :class="{
          'dark-red-background': displayError,
          'dark-blue-background': !displayError
        }"
      >
        <div
          class="inner-circle d-flex justify-center align-center"
          :class="{
            'light-red-background': displayError,
            'light-blue-background': !displayError
          }"
        >
          <v-icon color="white" class="reward-icon" x-large>{{
            displayError ? "mdi-close" : "mdi-check"
          }}</v-icon>
        </div>
      </div>
      <div class="word-wrap mx-8">
        {{
          displayError
            ? "Uh oh, we had some trouble sending your response"
            : "Success! Your response was sent and processed"
        }}
      </div>
    </div>
    <v-divider />
    <div
      class="d-flex justify-center mt-10"
      :class="{
        'flex-column align-center': isMobile
      }"
    >
      <v-btn
        v-if="
          card.MetricDefinition.dataType != 'BOOLEAN' &&
            card.MetricDefinition.selfAssess &&
            (displayResults || displayError)
        "
        outlined
        color="brand"
        width="140"
        :class="{ 'mr-5': !isMobile, 'mb-5': isMobile }"
        @click="resetForm"
        >{{ displayError ? "Try again" : "Enter more" }}</v-btn
      >
      <v-btn
        @click="
          displayResults || displayError
            ? loadNextCard()
            : card.MetricDefinition.selfAssess
            ? updateMetric()
            : nextCard && !nextCard.locked
            ? loadNextCard()
            : () => {
                console.log('no action');
              }
        "
        depressed
        color="brand"
        width="140"
        class="white--text"
        :loading="submitting"
        :disabled="
          (!displayResults &&
            !displayError &&
            card.MetricDefinition.selfAssess &&
            (inputValue == null || !valid)) ||
            card.MetricDefinition.status == 'Preview' ||
            ((displayResults || displayError) && nextCardIsLocked) ||
            (!card.MetricDefinition.selfAssess && nextCardIsLocked)
        "
        >{{
          !card.MetricDefinition.selfAssess || displayResults || displayError
            ? "Next"
            : "Submit"
        }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ProgramService from "@/services/ProgramService.js";

import moment from "moment";

export default {
  name: "ProgramDetailsMetricCard",
  props: {
    programId: Number,
    currentCard: Object,
    nextCard: Object,
    nextCardIsLocked: Boolean
  },
  components: {},
  data() {
    return {
      valid: false,
      inputValue: null,
      submitting: false,
      displayResults: true,
      displayError: false,
      rules: {
        integer: value =>
          (value && Number.isInteger(Number(value))) || "Must be whole number",
        emptyString: value =>
          (value && value.trim() !== "") || "Cannot be empty",
        longString: value =>
          (value && value.length < 256) || "Value is too long"
      }
    };
  },
  created() {
    this.resetForm();
  },
  updated() {},
  mounted() {},

  beforeMount() {},
  methods: {
    resetForm() {
      if (
        this.card &&
        this.card.MetricDefinition &&
        this.card.MetricDefinition.uploadType == "CUMULATIVE"
      ) {
        this.inputValue = this.card.progressValue;
        console.log("PRELOADING METRICS VALUE ", this.card.progressValue);
      } else {
        this.inputValue = null;
      }

      this.displayError = false;
      this.displayResults = false;
    },
    loadNextCard() {
      this.$emit("nextCard");
    },
    updateMetric() {
      console.log(this.rules.integer(this.inputValue));
      console.log(this.rules.longString(this.inputValue));
      console.log(this.rules.emptyString(this.inputValue));
      if (
        this.rules.emptyString(this.inputValue) !== true ||
        this.rules.longString(this.inputValue) !== true ||
        (this.card.MetricDefinition.dataType == "INTEGER" &&
          this.rules.integer(this.inputValue) !== true)
      )
        return;
      console.log("Updating ");
      this.submitting = true;
      const metric = this.card.MetricDefinition;
      // console.log('updating metric', metric);
      const metricId = metric.metricId;
      // Format metric value
      const metricValue =
        metric.dataType == "TIMESTAMP"
          ? moment(this.inputValue)
              // .utc()
              .format("YYYY-MM-DD HH:mm:ss")
          : metric.dataType == "DATE"
          ? moment(this.inputValue).format("YYYY-MM-DD")
          : this.inputValue;
      const body = {
        clientId: this.userProfile.clientId,
        metricId: metricId,
        metricValue: metricValue,
        date: moment()
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ")
      };
      console.log(body);
      // When updating the metric value here, the computed runs
      // this.updateMetricValue(metricId, metric.ruleId, metricValue);

      ProgramService.postSelfAssessMetric(metricId, body, this.programId)
        .then(res => {
          console.log(res);
          // If this is cumulative, then we can take the most recent value and locally update our data
          // if (
          //   this.card &&
          //   this.card.MetricDefinition &&
          //   this.card.MetricDefinition.uploadType == "CUMULATIVE"
          // ) {
          //   this.card.progressValue = metricValue;
          //   this.submitting = false;
          //   // Now we want to move onto the next card
          //   this.loadNextCard();
          // } else {
          // this.submitting will be set to false inside updateMetricValue
          console.log("Need to refresh data for incremental metric");
          // TO DO: Link to new progress updater endpoint
          this.updateMetricValue(this.card.programElementId);
          // }
        })
        .catch(error => {
          this.errorMessage = true;
          this.submitting = false;
          console.log(error);
          this.displayError = true;
        });
    },
    updateMetricValue(elementId) {
      console.log("updating metric value!", elementId);
      // Call get programs/byUser/userId/programId
      ProgramService.getProgressForProgramElement(elementId)
        .then(response => {
          console.log("got updated program progress", response);

          const updatedVal = response.find(
            x => x.programElementId == elementId
          );
          // const programRule = program.ProgramRules.find(
          //   rule => rule.programElementId == elementId
          // );
          // console.log(index, value);
          if (!updatedVal) {
            console.log("No program rule found");
            return;
          }
          console.log(updatedVal);
          // Set progress value
          this.card.progressValue = updatedVal.userValue;
          // Set progress string
          // this.card.progressString = programRule.progressString;
          // set progress percent
          this.card.progressPercent = updatedVal.progressPercent;
          // set the 'you've entered x out of x units'
          this.card.goalLabel = updatedVal.goalLabel;

          // // set green checkmark
          // console.log("updated metric value", this.card);
        })
        .catch(err => {
          console.log("error getting updated program", err);
        })
        .finally(() => {
          this.submitting = false;
          this.inputValue = null;
          this.displayResults = true;
          // Now we want to move onto the next card
          // this.loadNextCard();
        });
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    card: {
      get: function() {
        return this.currentCard;
      },
      set: function(newVal) {
        this.$emit("update-card", newVal);
      }
    },
    // computedInputLabel() {
    //   if (!this.card || !this.card.MetricDefinition) {
    //     return null;
    //   } else if (this.card.MetricDefinition.dataType == "STRING")
    //     return this.card.displayName;
    //   // else if (this.card.MetricDefinition.unitPlural) {
    //   //   return (
    //   //     "Total " +
    //   //     this.card.MetricDefinition.unitPlural +
    //   //     " " +
    //   //     (this.card.MetricDefinition.verb || "entered")
    //   //   );
    //   // } else {
    //   //   return this.card.MetricDefinition.metricName;
    //   // }
    //   var str =
    //     (this.card.MetricDefinition.uploadType == "CUMULATIVE"
    //       ? ""
    //       : "Total ") +
    //     (this.card.MetricDefinition.unitPlural
    //       ? this.card.MetricDefinition.unitPlural.toLowerCase()
    //       : this.card.MetricDefinition.dataType == "DATE" ||
    //         this.card.MetricDefinition.dataType == "TIMESTAMP"
    //       ? "date"
    //       : "units") +
    //     " " +
    //     (this.card.MetricDefinition.verb
    //       ? this.card.MetricDefinition.verb.toLowerCase()
    //       : "entered");
    //   return str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase();
    // },
    computedDateGoal() {
      if (
        !this.card.MetricDefinition ||
        !["DATE", "TIMESTAMP"].includes(this.card.MetricDefinition.dataType)
      )
        return null;
      else if (!this.card.value || !moment(this.card.value).isValid())
        return null;

      if (this.card.MetricDefinition.dataType == "DATE")
        return moment(this.card.value).format("MMMM D, YYYY");
      else return moment(this.card.value).format("MMMM D, YYYY h:mm a");
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },
  watch: {
    currentCard: function(newVal, oldVal) {
      console.log("Current card changed ", newVal);
      console.log(oldVal);
      this.resetForm();
    }
  }
};
</script>

<style scoped>
.input-field {
  min-width: 190px;
  width: 50%;
  max-width: 425px;
}

.self-assess {
  font-size: 14px;
}

.outer-circle {
  border-radius: 1000px;
  width: 125px;
  height: 125px;
  min-height: 125px;
  min-width: 125px;
  max-height: 125px;
  max-width: 125px;
}

.inner-circle {
  border-radius: 1000px;
  width: 109px;
  height: 109px;
  min-width: 109px;
  min-height: 109px;
  max-width: 109px;
  max-height: 109px;
  margin-top: 8px;
  margin-left: 8px;
}

.dark-blue-background {
  background-color: #97dffc;
}
.light-blue-background {
  background-color: #09acec;
}
.dark-red-background {
  background-color: #f90000;
}
.light-red-background {
  background-color: #f66;
}
</style>

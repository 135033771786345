<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title
        >Recatagorize Transaction</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeWindow">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

function initialState() {
  return {
    
  };
}

export default {
  name: "RecatagorizeTransactionDrawer",
  components: {
    
  },
  props: {
    transaction: Object,
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    
  },
  mounted() {},
  methods: {
    closeWindow() {
      // this.$refs.budgetForm.reset();

      this.$emit("close");
    },
  },
  computed: {
    // User profile.paymentRules is where we find their balances
    ...mapState(["userProfile", "clients", "permissions"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_RECOGNITION_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-recognition"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE
        ? "/microservice-recognition"
        : "/recognition-service"
    }`;

class RecognitionService {
  getCompanyValues(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/company-value", {
        params: params
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting company values: ", error.response || error);
        throw error.response || error;
      });
  }

  createRecognition(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/v2/recognition", body)
      .then(response => {
        console.log("POST Recognition response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error when creating recognition", error.response);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  }
}

export default new RecognitionService();

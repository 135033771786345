import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

var states = [
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },

  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },

  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },

  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },

  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
];

var countries = [
  { name: "United States", code: "US" },
  { name: "United Kingdom", code: "GB" },
  { name: "Other", code: "00" }
];

var allCountries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas (the)", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia (Plurinational State of)", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory (the)", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands (the)", code: "KY" },
  { name: "Central African Republic (the)", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands (the)", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros (the)", code: "KM" },
  { name: "Congo (the Democratic Republic of the)", code: "CD" },
  { name: "Congo (the)", code: "CG" },
  { name: "Cook Islands (the)", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic (the)", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
  { name: "Faroe Islands (the)", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories (the)", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia (the)", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and McDonald Islands", code: "HM" },
  { name: "Holy See (the)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran (Islamic Republic of)", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea (the Democratic People's Republic of)", code: "KP" },
  { name: "Korea (the Republic of)", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic (the)", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands (the)", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia (Federated States of)", code: "FM" },
  { name: "Moldova (the Republic of)", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands (the)", code: "NL" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger (the)", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands (the)", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines (the)", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Republic of North Macedonia", code: "MK" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation (the)", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Réunion", code: "RE" },
  { name: "Saint Barthélemy", code: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Martin (French part)", code: "MF" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Sint Maarten (Dutch part)", code: "SX" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan (the)", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan (Province of China)", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands (the)", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates (the)", code: "AE" },
  {
    name: "United Kingdom of Great Britain and Northern Ireland (the)",
    code: "GB"
  },
  { name: "United States Minor Outlying Islands (the)", code: "UM" },
  { name: "United States of America", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands (British)", code: "VG" },
  { name: "Virgin Islands (U.S.)", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" }
];

var countryCodes = [
  "AF",
  "AX",
  "AL",
  "DZ",
  "AS",
  "AD",
  "AO",
  "AI",
  "AQ",
  "AG",
  "AR",
  "AM",
  "AW",
  "AU",
  "AT",
  "AZ",
  "BS",
  "BH",
  "BD",
  "BB",
  "BY",
  "BE",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BQ",
  "BA",
  "BW",
  "BV",
  "BR",
  "IO",
  "BN",
  "BG",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CX",
  "CC",
  "CO",
  "KM",
  "CD",
  "CG",
  "CK",
  "CR",
  "HR",
  "CU",
  "CW",
  "CY",
  "CZ",
  "CI",
  "DK",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "EE",
  "SZ",
  "ET",
  "FK",
  "FO",
  "FJ",
  "FI",
  "FR",
  "GF",
  "PF",
  "TF",
  "GA",
  "GM",
  "GE",
  "DE",
  "GH",
  "GI",
  "GR",
  "GL",
  "GD",
  "GP",
  "GU",
  "GT",
  "GG",
  "GN",
  "GW",
  "GY",
  "HT",
  "HM",
  "VA",
  "HN",
  "HK",
  "HU",
  "IS",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IE",
  "IM",
  "IL",
  "IT",
  "JM",
  "JP",
  "JE",
  "JO",
  "KZ",
  "KE",
  "KI",
  "KP",
  "KR",
  "KW",
  "KG",
  "LA",
  "LV",
  "LB",
  "LS",
  "LR",
  "LY",
  "LI",
  "LT",
  "LU",
  "MO",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MT",
  "MH",
  "MQ",
  "MR",
  "MU",
  "YT",
  "MX",
  "FM",
  "MD",
  "MC",
  "MN",
  "ME",
  "MS",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NL",
  "NC",
  "NZ",
  "NI",
  "NE",
  "NG",
  "NU",
  "NF",
  "MP",
  "NO",
  "OM",
  "PK",
  "PW",
  "PS",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "PN",
  "PL",
  "PT",
  "PR",
  "QA",
  "MK",
  "RO",
  "RU",
  "RW",
  "RE",
  "BL",
  "SH",
  "KN",
  "LC",
  "MF",
  "PM",
  "VC",
  "WS",
  "SM",
  "ST",
  "SA",
  "SN",
  "RS",
  "SC",
  "SL",
  "SG",
  "SX",
  "SK",
  "SI",
  "SB",
  "SO",
  "ZA",
  "GS",
  "SS",
  "ES",
  "LK",
  "SD",
  "SR",
  "SJ",
  "SE",
  "CH",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TK",
  "TO",
  "TT",
  "TN",
  "TR",
  "TM",
  "TC",
  "TV",
  "UG",
  "UA",
  "AE",
  "GB",
  "UM",
  "US",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "VG",
  "VI",
  "WF",
  "EH",
  "YE",
  "ZM",
  "ZW"
];

var languages = [
  {
    name: "English",
    code: "eng"
  },
  {
    name: "French",
    code: "fra"
  },
  {
    name: "German",
    code: "deu"
  },
  {
    name: "Japanese",
    code: "jpn"
  },
  {
    name: "Spanish",
    code: "spa"
  }
];

var hours = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

var minutes = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55"
];

var amPm = ["AM", "PM"];

var alphabetUpperCase = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z"
];

var alphabetLowerCase = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z"
];

var editorToolbar = [
  [
    {
      header: [false, 1, 2, 3, 4, 5, 6]
    }
  ],
  ["bold", "italic", "underline", "strike"],
  [
    {
      align: ""
    },
    {
      align: "center"
    },
    {
      align: "right"
    },
    {
      align: "justify"
    }
  ],
  [
    {
      list: "ordered"
    },
    {
      list: "bullet"
    }
  ],
  [
    {
      color: []
    },
    {
      background: []
    }
  ], // dropdown with defaults from theme
  ["link"],
  ["clean"]
];

var editorToolbarWithImages = [
  [
    {
      header: [false, 1, 2, 3, 4, 5, 6]
    }
  ],
  ["bold", "italic", "underline", "strike"],
  [
    {
      align: ""
    },
    {
      align: "center"
    },
    {
      align: "right"
    },
    {
      align: "justify"
    }
  ],
  [
    {
      list: "ordered"
    },
    {
      list: "bullet"
    }
  ],
  [
    {
      color: []
    },
    {
      background: []
    }
  ], // dropdown with defaults from theme
  [
    "link",
    "image"
    // , "video"
  ],
  ["clean"]
];

const editorFormats = [
  "background",
  "bold",
  "color",
  "font",
  // "code",
  "italic",
  "link",
  "size",
  "strike",
  // "script",
  "underline",
  // "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction"
  // "code-block",
  // "formula"
  // 'image'
  // 'video'
];

const editorOptions = { formats: editorFormats };

const industries = [
  { name: "Agriculture", value: "AGRICULTURE" },
  { name: "Commerce", value: "COMMERCE" },
  { name: "Technology / IT", value: "TECHNOLOGY" },
  { name: "Manufacturing", value: "MANUFACTURING" },
  { name: "Financial Services", value: "FINANCIAL_SERVICES" },
  { name: "Restaurants / Food / Drink", value: "FOOD" },
  { name: "Retail", value: "RETAIL" },
  { name: "Health Services", value: "HEALTH" },
  { name: "Construction", value: "CONSTRUCTION" },
  { name: "Education", value: "EDUCATION" },
  { name: "Hotels / Tourism", value: "TOURISM" },
  { name: "Media", value: "MEDIA" },
  { name: "Telecommunications", value: "TELECOMMUNICATIONS" },
  { name: "Transportation", value: "TRANSPORTATION" },
  { name: "Public Service", value: "PUBLIC_SERVICE" },
  { name: "Professional Services", value: "PROFESSIONAL_SERVICES" },
  { name: "Other", value: "OTHER" }
];

//eslint-disable-next-line
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const formRules = {
  amount: [
    v => !!(v && parseFloat(v) > 0) || "Amount must be greater than 0",
    v =>
      !!(
        v &&
        (!v.toString().includes(".") ||
          (v.toString().includes(".") &&
            v.toString().substring(v.toString().indexOf(".") + 1).length < 3))
      ) || `Amount shouldn't be longer than 2 decimals.`
  ],
  optionalAmount: allowZero => [
    v =>
      !!(
        v === null ||
        v === undefined ||
        v == "" ||
        (allowZero && v == 0) ||
        (v &&
          ((!allowZero && parseFloat(v) > 0) ||
            (allowZero && parseFloat(v) >= 0)))
      ) || "Amount must be greater than 0",
    v =>
      !!(
        !v ||
        (v &&
          (!v.toString().includes(".") ||
            (v.toString().includes(".") &&
              v.toString().substring(v.toString().indexOf(".") + 1).length <
                3)))
      ) || `Amount shouldn't be longer than 2 decimals.`
  ],
  recurring: [
    v => !!(v && parseFloat(v) > 0) || "# of payments must be greater than 0",
    v =>
      !!(v && !v.toString().includes(".")) ||
      "# of payments must be a whole number"
  ],
  string255Optional: [
    v => !!(!v || (v && v.length < 256)) || "Your text should be shorter"
  ],
  stringRequired: len => [
    v => !!v || "A value is required",
    v => !!(v && v.length <= len) || "Your text should be shorter"
  ],
  stringOptional: len => [
    v => !!(!v || (v && v.length <= len)) || "Your text should be shorter"
  ],
  emailRequired: len => [
    v => !!v || "Email is required",
    v => !!(v && v.length <= len) || "Email should be shorter",
    v => !!(v && emailRegex.test(v)) || "Email should be valid"
  ],
  phoneRequired: [
    v => !!v || "Phone is required",
    v => {
      const errorText = "Phone number should be valid";
      if (!v) return errorText;
      let isValid = isValidPhoneNumber(v, "US");
      return isValid || errorText;
    }
  ]
};

const userColumns = [
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Job Title", value: "jobTitle" },
  { label: "Birthday", value: "birthday" },
  { label: "Job Start Date", value: "jobStartDate" },
  { label: "Role Start Date", value: "roleStartDate" },
  { label: "Organization", value: "organization" },
  { label: "Business Email", value: "businessEmail" },
  { label: "Business Phone", value: "businessPhone" },
  { label: "Personal Email", value: "personalEmail" },
  { label: "Personal Phone", value: "personalPhone" },
  { label: "Business Address", value: "businessAddress" },
  { label: "Business City", value: "businessCity" },
  { label: "Business State", value: "businessState" },
  { label: "Business Zip Code", value: "businessPostalCode" },
  { label: "Business Country", value: "businessCountry" },
  { label: "Home Address", value: "homeAddress" },
  { label: "Home City", value: "homeCity" },
  { label: "Home State", value: "homeState" },
  { label: "Home Zip Code", value: "homePostalCode" },
  { label: "Home Country", value: "homeCountry" },
  { label: "User ID at Client", value: "userIdAtClient" },
  { label: "Custom Field 1", value: "token1" },
  { label: "Custom Field 2", value: "token2" },
  { label: "Custom Field 3", value: "token3" }
];

const paymentUseCategories = [
  // {
  //   // Warning: If you change this label, you need to change the reference in the template. It relies on this label to know to render the expiration toggle
  //   label: "General expenses",
  //   icon: "mdi-wallet",
  //   description:
  //     "These funds belong to the recipient and they can spend them however they see fit. This should be used when sending bonuses.",
  //   mccDescription: null,
  //   mccGroupDefinitionId: null
  // },
  {
    label: "Meal",
    icon: "mdi-food-outline",
    description:
      "Meal funds are intended to be used for buying a meal at a restaurant, fast food, or grocery store, and are restricted to these kinds of transactions.",
    mccDescription: "Meals",
    mccGroupDefinitionId: 1
  },
  {
    label: "Coffee",
    icon: "mdi-coffee-outline",
    description:
      "Coffee funds are intended to be used for buying a coffee or snack at a restaurant, fast food, or grocery store, and are restricted to these kinds of transactions.",
    mccDescription: "Meals",
    mccGroupDefinitionId: 1
  },
  {
    label: "Office supplies",
    icon: "mdi-paperclip",
    description: "These funds are intended to be used to buy office supplies.",
    mccDescription: "Office supplies",
    mccGroupDefinitionId: 4
  },
  {
    label: "Wellness",
    icon: "mdi-run",
    description:
      "These funds are intended to be used by the employee to help them live a healthier life, whether that be by paying for a gym membership or mental health services.",
    mccDescription: null,
    mccGroupDefinitionId: null
  },
  {
    label: "Culture",
    icon: "mdi-emoticon-happy-outline",
    description:
      "Culture funds are to be used at the employees discretion on anything benefitting the team or organization.",
    mccDescription: null,
    mccGroupDefinitionId: null
  },
  {
    label: "Travel",
    icon: "mdi-train-car",
    description:
      "Travel funds can only be used for transportation (including air, car services or rental, public transportation), hotels and lodging, gas stations and restaurants.",
    mccDescription: "Travel",
    mccGroupDefinitionId: 2
  },
  {
    label: "Philanthropy",
    icon: "mdi-charity",
    description:
      "These funds can be used to democratize corporate giving, by enabling employees to make charitable donations.",
    mccDescription: null,
    mccGroupDefinitionId: null
  },
  {
    label: "Business expense",
    icon: "mdi-briefcase-outline",
    description:
      "This category of funds is for general business expenses and is treated like a corporate credit limit.",
    mccDescription: null,
    mccGroupDefinitionId: null
  }
];

export {
  states,
  countries,
  allCountries,
  countryCodes,
  languages,
  hours,
  minutes,
  amPm,
  alphabetUpperCase,
  alphabetLowerCase,
  editorToolbar,
  editorToolbarWithImages,
  editorFormats,
  editorOptions,
  industries,
  emailRegex,
  formRules,
  userColumns,
  paymentUseCategories
};

import { render, staticRenderFns } from "./RewardsSentLog.vue?vue&type=template&id=4cdeae36&scoped=true"
import script from "./RewardsSentLog.vue?vue&type=script&lang=js"
export * from "./RewardsSentLog.vue?vue&type=script&lang=js"
import style0 from "./RewardsSentLog.vue?vue&type=style&index=0&id=4cdeae36&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdeae36",
  null
  
)

export default component.exports
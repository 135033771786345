<template>
  <div class="full-height gradient-background" rounded="0">
    <div class="d-flex flex-column justify-space-between full-height pt-6">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="12" sm="6" md="5" lg="4" xl="4">
          <div
            class="box-outline rounded-lg py-10 px-2"
            :class="{ 'mx-3': $vuetify.breakpoint.xs }"
          >
            <transition :name="slideDirection" mode="out-in">
              <div
                class="text-center"
                v-if="currentSlide == keys.agreements"
                :key="keys.agreements"
              >
                <p class=" text-h6 word-break title-text mt-7">
                  First and last step!
                </p>
                <p class="pt-3">
                  Who doesn't love terms and conditions...
                </p>

                <div
                  class="d-flex flex-column align-start mb-8 mt-6 checkboxes mx-auto"
                >
                  <div class="d-flex align-center text-left my-2">
                    <v-checkbox
                      color="brandCyan"
                      v-model="termsCheckbox"
                      hide-details
                      class="mt-0 pt-0"
                    />
                    <span
                      >I agree to the
                      <a
                        @click="downloadFile('privacy_gdpr')"
                        :style="
                          filesDownloading.includes('privacy_gdpr')
                            ? 'cursor: wait !important;'
                            : ''
                        "
                        >privacy policy</a
                      >
                      and
                      <a
                        @click="downloadFile('terms')"
                        :style="
                          filesDownloading.includes('terms')
                            ? 'cursor: wait !important;'
                            : ''
                        "
                        >terms and conditions</a
                      ></span
                    >
                  </div>
                  <div class="d-flex align-center text-left my-2">
                    <v-checkbox
                      color="brandCyan"
                      v-model="chaCheckbox"
                      hide-details
                      class="mt-0 pt-0"
                    />
                    <span
                      >I agree to the
                      <a
                        @click="
                          downloadWhistleCardFile('cardholder_agreement.pdf')
                        "
                        :style="
                          filesDownloading.includes('cardholder_agreement.pdf')
                            ? 'cursor: wait !important;'
                            : ''
                        "
                        >Cardholder Agreement</a
                      >
                    </span>
                  </div>
                  <div class="d-flex align-center text-left my-2">
                    <v-checkbox
                      color="brandCyan"
                      v-model="issuerPolicyCheckbox"
                      hide-details
                      class="mt-0 pt-0"
                    />
                    <span
                      >I agree to the
                      <a
                        @click="
                          downloadWhistleCardFile(
                            'issuing_bank_privacy_policy.pdf'
                          )
                        "
                        :style="
                          filesDownloading.includes(
                            'issuing_bank_privacy_policy.pdf'
                          )
                            ? 'cursor: wait !important;'
                            : ''
                        "
                        >Bank Privacy Policy</a
                      >
                    </span>
                  </div>
                  <div class="d-flex align-center text-left my-2">
                    <v-checkbox
                      color="brandCyan"
                      v-model="esignCheckbox"
                      hide-details
                      class="mt-0 pt-0"
                    />
                    <span
                      >I agree to the
                      <a
                        @click="downloadWhistleCardFile('esign_agreement.pdf')"
                        :style="
                          filesDownloading.includes('esign_agreement.pdf')
                            ? 'cursor: wait !important;'
                            : ''
                        "
                        >eSign Agreement</a
                      >
                    </span>
                  </div>
                </div>
                <v-form
                  v-model="validForm"
                  ref="nameForm"
                  onSubmit="return false;"
                >
                  <v-text-field
                    ref="firstName"
                    label="First name"
                    v-model="firstName"
                    class="mt-4 name-field mx-auto"
                    outlined
                    dense
                    :rules="[
                      v => !!v || 'First name is required',
                      v =>
                        !!(v && v.length < 40) || 'First name should be shorter'
                    ]"
                    @keyup.enter="createUserAndCard(false)"
                  />
                  <v-text-field
                    ref="lastName"
                    label="Last name"
                    v-model="lastName"
                    class="mt-1 name-field mx-auto"
                    outlined
                    dense
                    :rules="[
                      v => !!v || 'Last name is required',
                      v =>
                        !!(v && v.length < 40) || 'Last name should be shorter'
                    ]"
                    @keyup.enter="createUserAndCard(false)"
                  />
                </v-form>
                <v-btn
                  color="brandCyan"
                  rounded
                  depressed
                  :disabled="
                    !(
                      termsCheckbox &&
                      chaCheckbox &&
                      issuerPolicyCheckbox &&
                      esignCheckbox &&
                      validForm
                    )
                  "
                  width="170"
                  class="pa-3 mt-8 white--text"
                  @click="createUserAndCard(false)"
                  ><span>{{ "Get rewarded" }}</span></v-btn
                >
                <v-divider class="mx-12 mt-10" />
                <p class="mt-6 mx-14">
                  If you need additional help,
                  <a
                    href="mailto:help@wewhistle.com"
                    class="text-decoration-none"
                    >contact us</a
                  >
                  or visit our
                  <a
                    @click="downloadWhistleCardFile('faq.pdf')"
                    :style="
                      filesDownloading.includes('faq.pdf')
                        ? 'cursor: wait !important;'
                        : ''
                    "
                    >Frequently Asked Questions</a
                  >
                </p>
              </div>
              <div
                class="text-center d-flex flex-column justify-space-between align-center loading-box"
                v-else-if="currentSlide == keys.loading"
                :key="keys.loading"
              >
                <v-progress-linear
                  :value="loadingProgress"
                  color="brandDarkGreen"
                  height="32"
                  class="progress-bar rounded-pill mt-7"
                >
                  <strong class="white--text text-left">{{
                    loadingText
                  }}</strong></v-progress-linear
                >
                <div>
                  <v-btn
                    color="brandGreen"
                    rounded
                    depressed
                    width="170"
                    class="pa-3 mt-8 white--text"
                    @click="
                      if (receivedAPIResponse) {
                        loadWhistleApp();
                      }
                    "
                    ><span>{{
                      !receivedAPIResponse ? "Processing" : "Done!"
                    }}</span></v-btn
                  >
                  <v-divider class="mx-12 mt-10" />
                  <p class="mt-6 mx-14">
                    If you need additional help,
                    <a
                      href="mailto:help@wewhistle.com"
                      class="text-decoration-none"
                      >contact us</a
                    >
                    or visit our
                    <a
                      @click="downloadWhistleCardFile('faq.pdf')"
                      :style="
                        filesDownloading.includes('faq.pdf')
                          ? 'cursor: wait !important;'
                          : ''
                      "
                      >Frequently Asked Questions</a
                    >
                  </p>
                </div>
              </div>
              <div
                class="text-center d-flex flex-column justify-space-between align-center loading-box"
                v-else-if="currentSlide == keys.error"
                :key="keys.error"
              >
                <div>
                  <p class=" text-h6 word-break title-text mt-7">
                    Uh oh, we ran into some trouble!
                  </p>
                  <p class="pt-3" v-if="allowUserToContinueAfterError">
                    Despite the problem, you can still continue to Whistle!
                  </p>
                  <p class="pt-3" v-else>
                    Feel free to
                    <a
                      href="mailto:help@wewhistle.com"
                      class="text-decoration-none"
                      >contact us</a
                    >
                    if the problem continues.
                  </p>
                </div>
                <div>
                  <v-btn
                    color="brandGreen"
                    rounded
                    depressed
                    width="170"
                    class="pa-3 mt-8 white--text"
                    @click="
                      if (allowUserToContinueAfterError) {
                        loadWhistleApp();
                      } else {
                        createUserAndCard(true);
                      }
                    "
                    ><span>{{
                      allowUserToContinueAfterError
                        ? "Go to Whistle"
                        : "Try Again"
                    }}</span></v-btn
                  >
                  <v-divider class="mx-12 mt-10" />
                  <p class="mt-6 mx-14">
                    If you need additional help,
                    <a
                      href="mailto:help@wewhistle.com"
                      class="text-decoration-none"
                      >contact us</a
                    >
                    or visit our
                    <a
                      @click="downloadWhistleCardFile('faq.pdf')"
                      :style="
                        filesDownloading.includes('faq.pdf')
                          ? 'cursor: wait !important;'
                          : ''
                      "
                      >Frequently Asked Questions</a
                    >
                  </p>
                </div>
              </div>
            </transition>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" class="full-width mt-3" no-gutters
        ><v-col
          ><p
            v-if="$auth && $auth.isAuthenticated"
            class="white--text font-weight-bold cursor-pointer"
            @click="loginOrLogout"
          >
            Log out
          </p>
          <span class="white--text font-weight-bold"
            >Whistle Systems, Inc.</span
          >
        </v-col></v-row
      >
    </div>
  </div>
</template>

<script>
import { languages, countries } from "@/shared_data/data.js";

import moment from "moment";
import { mapState } from "vuex";

import UserService from "@/services/UserService";
import TriggerService from "@/services/TriggerService";
import OnboardingService from "@/services/OnboardingService";
import MarqetaService from "@/services/MarqetaService";

export default {
  name: "ActivationV3Payments",
  title: "Whistle | Setup",
  props: {},
  components: {},
  data() {
    return {
      currentSlide: 0,
      keys: {
        // First screen always. Shows agreements
        agreements: 0,
        // When saving agreements
        loading: 1,
        // Intro to card screen
        success: 3,
        // general error screen
        error: 4
      },
      slideDirection: "topic-left",

      errorMessage: null,

      languages: languages,
      countries: countries,
      language: null,
      clientId: null,

      // Checkboxes and stored IDs
      termsCheckbox: false,
      termsSupplementalId: null,
      privacySupplementalId: null,
      chaCheckbox: false,
      chaSupplementalId: null,
      issuerPolicyCheckbox: false,
      issuerSupplementalId: null,
      esignCheckbox: false,
      esignSupplementalId: null,

      // Array to store list of files we're waiting to download
      filesDownloading: [],

      // data fields to set up the new user.
      firstName: null,
      lastName: null,

      // Loading progress bar
      loadingProgress: 0,
      loadingText: "Loading funds",
      receivedAPIResponse: false,

      // Error handler to allow user to continue or not
      allowUserToContinueAfterError: false,

      dialogCardRequest: false,

      email: null,
      country: null,
      otherCountry: null,

      // Loading bool for when resending password reset
      sendingResetEmail: false,

      validForm: false
    };
  },
  created() {
    if (this.userProfile && this.userProfile.firstName) {
      this.firstName = this.userProfile.firstName;
      this.lastName = this.userProfile.lastName;
    }

    if (this.$auth.isAuthenticated) {
      // Preload terms and privacy supplemental data
      if (this.userProfile && this.userProfile.UserSupplementals) {
        var userSupp = this.userProfile.UserSupplementals;
        var terms = userSupp.find(
          x => x.key === "Terms and Conditions Agreement"
        );
        this.termsSupplementalId = terms ? terms.userSupplementalId : null;

        var privacy = userSupp.find(x => x.key === "Privacy Policy Agreement");
        this.privacySupplementalId = privacy
          ? privacy.userSupplementalId
          : null;

        var cha = userSupp.find(x => x.key === "Cardholder Agreement");
        this.chaSupplementalId = cha ? cha.userSupplementalId : null;

        var issuerPrivacy = userSupp.find(
          x => x.key === "Issuer Privacy Policy Agreement"
        );
        this.issuerSupplementalId = issuerPrivacy
          ? issuerPrivacy.userSupplementalId
          : null;

        var esign = userSupp.find(x => x.key === "eSign Agreement");
        this.esignSupplementalId = esign ? esign.userSupplementalId : null;
      }

      if (
        this.currentSlide == this.keys.agreements &&
        this.userProfile.UserSupplementals.length == 0
      ) {
        this.insertActivity("FIRST_LOGIN");
      }
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }

    // Set a timeout so the form has aa moment to set itself up and then we validate
    // setTimeout(() => {
    if (this.$refs.nameForm) this.$refs.nameForm.validate();
    // }, 500);
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    loginOrLogout() {
      if (!this.$auth.isAuthenticated) {
        //Logs in
        this.$auth.loginWithEmbedded({
          connection: process.env.VUE_APP_ENVIRONMENT
        });
      } else {
        //Logs out
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    },
    insertActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "USER_ONBOARDING",
        category: "PLATFORM",
        date: new Date()
      };
      TriggerService.createActivity(obj, this.magicLinkToken)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },

    async downloadFile(name) {
      this.filesDownloading.push(name);
      await OnboardingService.downloadLegalDoc(name);
      var index = this.filesDownloading.findIndex(x => x == name);
      if (index != -1) this.filesDownloading.splice(index, 1);
    },
    async downloadWhistleCardFile(fileName) {
      this.filesDownloading.push(fileName);
      await UserService.downloadCardProductFile(fileName, this.magicLinkToken);
      var index = this.filesDownloading.findIndex(x => x == fileName);
      if (index != -1) this.filesDownloading.splice(index, 1);
    },
    async createUserAndCard(isRetry = false) {
      if (
        // for a retry we allow through
        !isRetry &&
        !(
          this.termsCheckbox &&
          this.chaCheckbox &&
          this.issuerPolicyCheckbox &&
          this.esignCheckbox &&
          this.validForm
        )
      )
        return;
      this.receivedAPIResponse = false;
      this.currentSlide = this.keys.loading;
      this.launchLoadingText();
      // We let them through if we stored their agreements but it errored on Marqeta
      var letUserIntoApp = false;
      try {
        let userSuppArray = [
          {
            userSupplementalId: this.termsSupplementalId,
            userId: this.userProfile.userId,
            key: "Terms and Conditions Agreement",
            value: moment().utc(),
            updatedBy: this.userProfile.userId
          },
          {
            userSupplementalId: this.privacySupplementalId,
            userId: this.userProfile.userId,
            key: "Privacy Policy Agreement",
            value: moment().utc(),
            updatedBy: this.userProfile.userId
          },
          {
            userSupplementalId: this.chaSupplementalId,
            userId: this.userProfile.userId,
            key: "Cardholder Agreement",
            value: moment().utc(),
            updatedBy: this.userProfile.userId
          },
          {
            userSupplementalId: this.issuerSupplementalId,
            userId: this.userProfile.userId,
            key: "Issuer Privacy Policy Agreement",
            value: moment().utc(),
            updatedBy: this.userProfile.userId
          },
          {
            userSupplementalId: this.esignSupplementalId,
            userId: this.userProfile.userId,
            key: "eSign Agreement",
            value: moment().utc(),
            updatedBy: this.userProfile.userId
          }
        ];
        console.log("Updating/Creating User Supplemental: ", userSuppArray);
        await UserService.bulkUpdateUserSupplemental(
          userSuppArray,
          this.magicLinkToken
        );
        // Now that we've stored the agreements, we can let the user into the app regardless of Marqeta errors
        letUserIntoApp = true;

        // Only parse if the user has passed KYB
        if (this.hasPassedKYB) {
          const userBody = {
            // country: "US",
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.userProfile.businessEmail
              ? this.userProfile.businessEmail
              : null,
            phone: !this.userProfile.businessEmail
              ? this.userProfile.businessPhone
              : null,
            metadata: {
              clientId: this.userProfile.clientId,
              clientName:
                this.userProfile && this.userProfile.Client
                  ? this.userProfile.Client.clientName
                  : "Unknown",
              userId: this.userProfile.userId
            }
          };

          console.log("Submitting user: ", userBody);
          let userResponse = await MarqetaService.createUser(
            userBody,
            true,
            this.magicLinkToken
          );
          console.log("User response: ", userResponse);

          //Sleep for 2 seconds so the user's balance has time to be loaded
          // await new Promise(function(resolve) {
          //   setTimeout(async () => {
          let cardResponse = await MarqetaService.issueCard(
            "virtual",
            {},
            this.magicLinkToken
          );
          console.log("Card response: ", cardResponse);
          // resolve(cardResponse);
          // }, 2000);
          // });
        }
        // throw {};
      } catch (err) {
        console.log("Error creating marqeta user or card ", err);
        // If user put in an email that already exists, send them back to slide 1
        // if (err.error_code == 1190022) {
        //   this.dialogEmailError = true;
        // } else {
        this.currentSlide = this.keys.error;
        // }
        this.allowUserToContinueAfterError = letUserIntoApp;
      } finally {
        if (letUserIntoApp) {
          await UserService.updateUser(
            this.userProfile.userId,
            {
              firstLogin: false,
              updatedBy: this.userProfile.userId,
              firstName: this.firstName,
              lastName: this.lastName,
              displayName: this.firstName + " " + this.lastName
            },
            this.magicLinkToken
          );
        }
        this.receivedAPIResponse = true;

        // If we're not on the error screen, auto-route them
        // Progress must also be at 100, otherwise we'll wait for the launchLoadingText function to route them
        if (
          this.currentSlide != this.keys.error &&
          this.loadingProgress == 100
        ) {
          this.launchLoadingText(true);
        } else if (this.currentSlide != this.keys.error) {
          // Safeguard so the user max waits 4 seconds
          setTimeout(() => {
            this.launchLoadingText(true);
          }, 4000);
        }
      }
    },
    launchLoadingText(skipToFinalStep = false) {
      var self = this;
      var sleepLength = 2000;
      var loopCount = 0;
      let text = [
        "Loading funds",
        "Emptying the piggy bank",
        "Checking the sofa",
        "Oh, it was in the mattress!"
      ];
      let progressIncrement = 100 / text.length;

      self.loadingProgress = skipToFinalStep ? 100 : 0;
      self.loadingText = skipToFinalStep ? text[text.length - 1] : text[0];

      loadingTextSleeper(skipToFinalStep ? text.length - 1 : 0);
      function loadingTextSleeper(idx, finalLoop = false) {
        if (
          self.currentSlide == self.keys.error ||
          !self.userProfile.firstLogin
        )
          return;
        var randSleep = Math.random() * 500;
        console.log("Sleeping ", sleepLength + randSleep);
        setTimeout(
          () => {
            if (
              self.currentSlide == self.keys.error ||
              !self.userProfile.firstLogin
            )
              return;
            // Note:
            // If we've already got an API response, then we fill the progress bar, let them wait a sec, and then load the app
            if (self.receivedAPIResponse || skipToFinalStep) {
              self.loadingProgress = 100;
              self.loadingText = text[text.length - 1];
              idx = text.length;
              // Once we hit 100% and got an API response, we show the final message for a split sec so they can read
              setTimeout(() => {
                self.loadWhistleApp();
              }, 800);
            } else if (text[idx] && !finalLoop) {
              self.loadingText = text[idx];
              self.loadingProgress += progressIncrement;
            }

            // If we're on the last item and haven't gotten a response yet, we loop max 5 times while waiting
            if (
              idx == text.length - 2 &&
              !self.receivedAPIResponse &&
              loopCount < 5
            ) {
              loadingTextSleeper(idx, true);
            } else if (idx + 1 < text.length) {
              loadingTextSleeper(idx + 1);
            }
          },
          skipToFinalStep ? 0 : sleepLength + randSleep
        );
      }
    },
    loadWhistleApp() {
      console.log("Magic link route ", this.magicLinkRoute);
      if (this.magicLinkToken && this.magicLinkRoute == "/activity") {
        if (this.$route.path != this.magicLinkRoute)
          this.$router.push(this.magicLinkRoute);
      } else if (this.$route.name != "wallet" && this.$route.name != "procore")
        this.$router.push({
          name: "wallet"
        });
      this.$store.state.userProfile.firstLogin = false;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "marqeta",
      "permissions",
      "magicLinkToken",
      "magicLinkRoute"
    ]),
    hasPassedKYB() {
      return this.marqeta && this.marqeta.canRegister;
    },
    showPaymentsMessaging() {
      if (
        (this.userProfile &&
          this.userProfile.Client &&
          this.userProfile.Client.onboardingLanguage &&
          this.userProfile.Client.onboardingLanguage
            .toLowerCase()
            .includes("payment")) ||
        (this.client &&
          this.client.onboardingLanguage &&
          this.client.onboardingLanguage.toLowerCase().includes("payment"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },

  watch: {}
};
</script>

<style scoped>
/* Gradient for page */
.gradient-background {
  background: linear-gradient(
      90deg,
      rgba(161, 215, 110, 1) 15%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}

/* Card box */
.box-outline {
  border: solid;
  border-color: lightgray;
  border-width: 1px;
  background-color: white;
}

/* Min height requirement for loading screen */
.loading-box {
  min-height: 70vh;
}

/* Title for card */
.title-text {
  font-size: 1.6em !important;
}

/* First and last name text boxes */
.name-field {
  width: 50%;
  min-width: 175px;
}

/* Caps width of checkboxes so they're vertically aligned */
.checkboxes {
  width: 60%;
  min-width: 280px;
  max-width: 380px;
}

.progress-bar {
  width: 70%;
  min-width: 220px;
  max-width: 600px;
}

/* progress bar text */
.progress-bar >>> .v-progress-linear__content {
  justify-content: flex-start;
  margin-left: 15px;
}

.progress-bar >>> .v-progress-linear__determinate {
  border-radius: 0 9999px 9999px 0;
}

.progress-bar >>> .v-progress-linear__background {
  left: 0 !important;
  width: 100% !important;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}
</style>

<template>
  <div class="fill-height">
    <!-- <meta
      name="apple-itunes-app"
      content="app-id=1553360870, app-clip-bundle-id=com.wewhistle.whistle.Clip, app-clip-display=card"
    /> -->
    <v-card width="100%" rounded="0" flat height="100%">
      <div class="pt-10 px-3 word-break">
        <p>
          The Android Instant App should automatically load within a few
          seconds.
        </p>
        <p>If it doesn't load, try refreshing the page</p>
        <!-- <Robin
          :height="130"
          :width="130"
          :largeText="true"
          :showText="false"
          animation="eating"
          :loop="true"
        ></Robin> -->
      </div>
      <div class="d-flex flex-wrap justify-center">
        <!-- <v-btn
          class="mt-3 mx-2 white--text"
          width="150"
          color="brandCyan"
          depressed
          @click="openInstantApp"
          >Open</v-btn
        > -->
        <v-btn
          class="mt-3 mx-2 white--text"
          width="170"
          color="brandCyan"
          depressed
          @click="goHome"
          >Back to Wallet</v-btn
        >
      </div>
    </v-card>
  </div>
</template>

<script>
import JwtDecode from "vue-jwt-decode";
import { mapState } from "vuex";
// import Robin from "@/components/Robin.vue";
export default {
  name: "Instant App",
  title: "Android Instant App | Whistle",
  // components: { Robin },
  data() {
    return {};
  },
  created() {
    // Check if the current URL matches a specific string
    //not doing the href if because we're inside the appclip.vue now
    // Create a new meta tag element

    try {
      const token = this.$route.query.token;
      if (!token) this.$router.push("/wallet");

      const decodedToken = JwtDecode.decode(token);
      if (decodedToken.sub != this.userProfile.userId) {
        console.log("ERROR USER ID DOES NOT MATCH FOR APP CLIP");
        this.$router.push("/wallet");
      }
    } catch (e) {
      console.log("ERROR PARSING TOKEN FOR APP CLIP PAGE ", e);
      this.$router.push("/wallet");
    }
  },
  methods: {
    goHome() {
      this.$router.push("/wallet");
    },
    openInstantApp() {
      let url =
        process.env.VUE_APP_INSTANT_APP_URL +
        "&token=" +
        this.$route.query.token +
        "&magic-link=" +
        this.$route.query["magic-link"];
      window.open(url, "_blank");
    }
  },
  computed: { ...mapState(["userProfile"]) }
};
</script>

<style scoped>
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}

.body {
  height: 100%;
  /* or any height you want */
  overflow-y: auto;
}

.logo-text {
  color: var(--v-brand-base);
}

.slide-leave-active {
  transition: 0.4s;
}

.slide-enter-active {
  transition: 0.6s;
}

.slide-enter {
  transform: translate(200%, 0);
}

.slide-leave-to {
  transform: translate(-200%, 0);
}

.show-enter-active,
.show-leave-enter {
  transform: translateX(0);
  transition: all 0.5s linear;
}

.show-enter,
.show-leave-to {
  transform: translateX(100%);
}

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.blue-btn {
  background-color: var(--v-accent-base);
}

.grey-btn {
  background-color: var(--v-lightGrey-base);
}

.header-text {
  font-size: x-large;
}

.footer-text {
  font-size: small;
}

.form {
  width: 75%;
}

.slider {
  width: 90%;
}

.bottom-logo {
  position: absolute;
  bottom: 10px;
}

.loading-screen-content {
  margin-top: 15vh;
}

.card-height {
  min-height: 450px;
  height: calc(100% - 100px);
}
</style>

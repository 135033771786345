<template>
  <div class="home">
    <div class="d-flex flex-column align-center">
      <v-img
        class="logo"
        alt="Whistle logo"
        src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
        contain
      />

      <!-- Check that the SDK client is not currently loading before accessing is methods -->
      <div v-if="!$auth.loading">
        <v-btn
          @click="loginOrLogout"
          color="primary"
          class="px-6 button"
          :small="$vuetify.breakpoint.smAndDown"
          :medium="$vuetify.breakpoint.md"
          :x-large="$vuetify.breakpoint.xl"
        >
          <b v-if="!$auth.isAuthenticated">Log in</b>
          <b v-else>Log out</b>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import SlackService from "@/services/SlackService";
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { mapState } from "vuex";
export default {
  name: "Home",
  title: "Whistle | Home",
  //components: {
  //HelloWorld
  //},
  created() {
    if (this.$auth.isAuthenticated) {
      // If we have a magic link route, we send them there
      if (this.magicLinkToken && this.magicLinkRoute) {
        this.$router.push(this.magicLinkRoute);
        return;
      }
      // If they have a slack correlationId stored in Vuex, then we grab that, POST it, and then delete it
      if (this.slackWorkspaceCorrelationId) {
        this.storeSlackAssociation();
      }

      //Route to activity
      if (this.userProfile.Client.initialRouteOnLoad != null) {
        var route = this.userProfile.Client.initialRouteOnLoad;
      } else {
        route = "activity";
      }

      if (this.permissions.includes("vue:read:" + route)) {
        this.$router.push({ name: route });
      } else {
        this.$router.push({ name: "activity" });
      }
    } else {
      console.log("Calling logout from Home component");
      this.loginOrLogout();
    }
  },
  methods: {
    loginOrLogout() {
      if (!this.$auth.isAuthenticated) {
        //Logs in
        this.$auth.loginWithEmbedded({
          connection: process.env.VUE_APP_ENVIRONMENT
        });
      } else {
        //Logs out
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    },
    async storeSlackAssociation() {
      // If they have a slack correlationId stored in Vuex, then we grab that, POST it, and then delete it
      console.log("Storing slack assocation with Client");
      await SlackService.storeSlackWorkspaceAssociation(
        this.slackWorkspaceCorrelationId
      );
      this.$store.dispatch("setSlackWorkspaceCorrelationId", null);
    }
  },
  computed: {
    ...mapState([
      "permissions",
      "userProfile",
      "slackWorkspaceCorrelationId",
      "magicLinkToken",
      "magicLinkRoute"
    ])
  }
};
</script>

<style scoped>
.home {
  /* background-color: white; */
  background: linear-gradient(to bottom, white, 80%, var(--v-brandGreen-base));
  width: 100vw;
  height: 100%;
}
@media (max-width: 400px) {
  .logo {
    width: 200px;
    height: auto;
  }
}
@media (min-width: 401px) and (max-width: 2000px) {
  .logo {
    width: 250px;
    height: auto;
  }
}
@media (min-width: 2000px) {
  .logo {
    width: 450px;
    height: auto;
  }
}
.logo {
  margin-top: 15vh;
}
.button {
  margin-top: 10vh;
}
</style>

<template>
  <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
    <div
      class="d-flex align-center mx-2 mb-2"
      :class="{ 'justify-start': !centerText, 'justify-center': centerText }"
    >
      <v-icon :color="iconColor" x-large class="exit-warning-icon mr-2">{{
        icon
      }}</v-icon>
      <v-card-title class="word-break align-text-left exit-warning-text">
        {{ title }}
      </v-card-title>
    </div>
    <v-card-subtitle class="word-break mb-3" v-if="subtitle">{{
      subtitle
    }}</v-card-subtitle>
    <v-card-subtitle class="word-break mb-3 error--text" v-if="error">{{
      error
    }}</v-card-subtitle>
    <div
      class="d-flex justify-center align-center flex-wrap"
      :class="{
        'flex-column': $vuetify.breakpoint.xs
      }"
    >
      <v-btn
        color="brandCyan"
        @click="clickButton1"
        outlined
        depressed
        :width="button1Width"
        :loading="button1Loading"
        :disabled="button2Loading"
        class="white--text mb-4 mx-3"
        v-if="button1Text"
        >{{ button1Text }}</v-btn
      >
      <!-- <v-spacer /> -->
      <v-btn
        color="brandCyan"
        class="white--text mb-4 mx-3"
        @click="clickButton2"
        :width="button2Width"
        :loading="button2Loading"
        :disabled="button1Loading"
        depressed
        v-if="button2Text"
        >{{ button2Text }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
export default {
  name: "PopupDialog",
  components: {},
  props: {
    icon: {
      type: String,
      default: "mdi-alert"
    },
    iconColor: {
      type: String,
      default: "error"
    },
    title: String,
    subtitle: String,
    error: String,
    button1Text: String,
    button2Text: String,
    button1Loading: Boolean,
    button2Loading: Boolean,
    centerText: {
      type: Boolean,
      default: false
    },
    button1Width: {
      type: String,
      default: "170"
    },
    button2Width: {
      type: String,
      default: "170"
    }
  },
  data() {
    return {};
  },
  created() {},

  methods: {
    clickButton1() {
      this.$emit("button1");
    },
    clickButton2() {
      this.$emit("button2");
    }
  },
  computed: {}
};
</script>

<style></style>

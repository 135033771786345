<template>
  <div v-if="hasPermission">
    <chat-agent
      :class="['agent-window', fab ? 'active' : '']"
      height="614px"
      width="384px"
      @dismissed="fab = false"
    />
    <v-speed-dial
      class="clickthrough polite"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
      fixed
    >
      <template v-slot:activator>
        <v-btn
          @click.stop="open"
          :class="
            !fab
              ? [
                  active ? 'active' : '',
                  'blue',
                  'widget',
                  'align-center',
                  'd-flex',
                  'justify-start',
                  'pa-2',
                  'rounded-pill'
                ]
              : ['blue', 'active']
          "
          dark
          x-large
          :tile="!fab"
        >
          <template v-if="!fab">
            <v-avatar class="pa-2 white mr-2" size="32px">
              <v-img contain src="@/assets/robin/Curious.png" height="32px" />
            </v-avatar>
            <p class="subtitle-1 my-5">Hi! Can I help?</p>
          </template>
          <v-icon v-else>mdi-close</v-icon>
        </v-btn>
      </template>
      <!-- <v-btn
        fab
        dark
        small
        color="green"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="indigo"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn> -->
    </v-speed-dial>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ChatAgent from "../vertex-ai/Container.vue";
export default {
  components: {
    ChatAgent
  },
  created() {
    setTimeout(() => {
      this.active = true;
    }, 500);
    setTimeout(() => {
      this.active = false;
    }, 3500);
  },
  computed: {
    ...mapState(["userProfile", "permissions"]),
    hasPermission() {
      // console.log("state", this.$store.state);
      return (this.permissions || [])?.includes("vue:read:chatbot");
    }
  },
  props: {
    direction: String,
    fling: Boolean,
    hover: Boolean,
    tabs: Boolean,
    top: Boolean,
    right: Boolean,
    bottom: Boolean,
    left: Boolean,
    transition: String
  },
  data() {
    return {
      fab: false,
      active: false
    };
  },
  methods: {
    open() {
      this.fab = !this.fab;
    }
  }
};
</script>
<style scoped>
.widget {
  right: -176px;
  width: 192px;
  text-transform: none !important;
  transition: 0.3s;
}
.agent-window {
  position: fixed;
  right: -448px;
  opacity: 0;
  display: none;
  bottom: 96px;
  z-index: 98;
  transition: 0.3s;
}
.agent-window.active {
  right: 96px;
  opacity: 1;
  display: block;
}
.widget:hover {
  right: -32px;
}
.widget.active {
  right: -32px;
}

@media screen and (height < 720px), (width < 720px) {
  .agent-window {
    position: fixed;
    right: 0px !important;
    opacity: 0;
    bottom: 0px;
    z-index: 98;
    transition: 0.3s;
  }
  .polite .active {
    display: none;
  }
}
</style>

import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_PAYMENT_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-payments"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-payments" : "/payments-service"
    }`;

class PaymentService {
  // issuePhysicalCard(userId, body) {
  //   return axios
  //     .post(
  //       baseUrl + "/issuePhysicalCard/" + userId,
  //       body
  //     )
  //     .then((response) => {
  //       console.log(response.data.card);
  //       return response.data.card;
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //       return error.response.data;
  //     });
  // }
  // canIssuePhysicalCard(userId) {
  //   return axios
  //     .get(
  //       baseUrl +
  //         "/stripe/canIssueCard/physical/" +
  //         userId
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       console.log(error.response.data);
  //       return error.response.data;
  //     });
  // }

  getPlaidLinkToken(getExistingToken, redirectUri = null) {
    return axios
      .post(baseUrl + "/plaid/linkToken?existing_token=" + getExistingToken, {
        redirectUri: redirectUri
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }

  sendPlaidPublicToken(token) {
    return axios
      .post(baseUrl + "/plaid/publicToken", {
        public_token: token
      })
      .then(response => {
        console.log("Public token res: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }

  storePlaidBankToken(publicToken, accountId) {
    return axios
      .post(baseUrl + "/plaid/account/select", {
        public_token: publicToken,
        account_id: accountId
      })
      .then(response => {
        console.log("Plaid bank token response: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }

  createStripeCustomer(name, email, phone, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/stripe/customer", {
        name,
        email,
        phone
      })
      .then(response => {
        console.log("Stripe customer res: ", response.data.fundingAccount);
        return response.data.fundingAccount;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }
  createStripeBankAccount(token, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/stripe/bank", {
        stripeToken: token
      })
      .then(response => {
        console.log("Stripe bank res: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }
  deleteStripeBankAccount(bankId, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .delete(baseUrl + "/stripe/bank", {
        data: { bankId: bankId }
      })
      .then(response => {
        console.log("Stripe bank res: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }
  verifyStripeBankAccount(bankId, customerId, amounts, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/stripe/bank/verify", {
        bankId: bankId,
        customerId: customerId,
        amounts: amounts
      })
      .then(response => {
        console.log("Stripe bank res: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }
  getStripeBanks(type = "bank_account", magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/stripe/bank/" + type)
      .then(response => {
        console.log("Stripe bank res: ", response.data.banks);
        return response.data.banks;
      })
      .catch(error => {
        console.log("Error getting banks ", error.response.data);
        throw error.response.data;
      });
  }
  createStripePayment(
    clientId,
    token,
    amount,
    fee,
    budgetId,
    description,
    receiptDescription,
    email,
    authorizationDate,
    ipAddress,
    type = "ach",
    saved = false, //is it a saved CC token?
    source = null, // used for platform activity so we know where in the app they're loading funds in
    givingMode = false,
    magicLinkToken = null
  ) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(`${baseUrl}/stripe/payment/${givingMode ? "giving/" : ""}${type}`, {
        clientId: clientId,
        token: token,
        amount: amount,
        fee: fee,
        type: type,
        description: description,
        budgetId: budgetId,
        email: email,
        receiptDescription: receiptDescription,
        authorizationDate: authorizationDate,
        ipAddress: ipAddress,
        saved: saved,
        source: source
      })
      .then(response => {
        console.log("Stripe bank res: ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log(error.response.data);
        return error.response.data;
      });
  }
}

export default new PaymentService();

<template>
  <v-card
    rounded="0"
    class="full-height"
    :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'pb-12' : ''"
  >
    <div
      id="header"
      class="d-flex justify-space-between align-center mr-5"
      width="100%"
    >
      <div class="d-flex align-center">
        <v-btn icon x-large class="ml-1" to="/programadmin"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-icon
          large
          dark
          class="pa-2 ml-3 my-2 brand-green-background border-radius brain-icon"
          >mdi-checkbox-marked-outline</v-icon
        >
        <v-card-title> Update Progress </v-card-title>
      </div>
      <v-autocomplete
        outlined
        dense
        class="pa-1 mb-2"
        style="min-width: 300px; max-width: 500px; "
        hide-details
        :items="clients"
        item-text="formattedName"
        item-value="clientId"
        label="Client"
        v-model="clientId"
        @change="refreshClientId"
        v-if="userProfile.clientId === 1 && clients.length > 0"
        :disabled="slideKey > keys.programsTable"
      ></v-autocomplete>
    </div>
    <v-divider class="mx-4" />
    <!-- The Div that contains the component -->
    <div class="d-flex justify-center" justify="center" height="100%">
      <!-- The metric UI Card -->
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="7"
        xl="7"
        class=" d-flex flex-column align-start"
      >
        <v-container class="mb-2 d-flex align-right justify-end">
          <!-- Navigation buttons, for now comment out -->
          <!-- <div class="d-flex">
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              :class="{
                'accent-background': slideKey >= 1,
                'grey-background': 1 > slideKey,
              }"
              @click="goToSlide(1)"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(2)"
              :class="{
                'accent-background': slideKey >= 2,
                'grey-background': 2 > slideKey,
              }"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(3)"
              v-if="
                slideDetails.classification == 'COMPENSATION' ||
                  slideDetails.classification == 'NON_CASH' ||
                  slideDetails.classification == null
              "
              :class="{
                'accent-background': slideKey >= 3,
                'grey-background': 3 > slideKey,
              }"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(keys.review)"
              v-if="
                slideDetails.classification == 'COMPENSATION' ||
                  slideDetails.classification == 'NON_CASH' ||
                  slideDetails.classification == null
              "
              :class="{
                'accent-background': slideKey >= keys.review,
                'grey-background': keys.review > slideKey,
              }"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(keys.perDiemDetails)"
              v-if="slideDetails.classification == 'PER_DIEM'"
              :class="{
                'accent-background': slideKey >= keys.perDiemDetails,
                'grey-background': keys.perDiemDetails > slideKey,
              }"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(keys.perDiemDate)"
              v-if="slideDetails.classification == 'PER_DIEM'"
              :class="{
                'accent-background': slideKey >= keys.perDiemDate,
                'grey-background': keys.perDiemDate > slideKey,
              }"
            ></div>
            <div
              class="mr-2 pa-3 rounded-circle d-inline-block nav-btn cursor-pointer"
              @click="goToSlide(keys.review)"
              v-if="slideDetails.classification == 'PER_DIEM'"
              :class="{
                'accent-background': slideKey >= keys.review,
                'grey-background': keys.review > slideKey,
              }"
            ></div>
          </div> -->
          <v-btn
            v-if="slideKey !== 1 && slideKey !== 2"
            text
            color="primary"
            class="align-self-start mr-auto px-0"
            @click="goBack"
            width="90"
            :disabled="[1, 2, 7].includes(slideKey)"
          >
            <v-icon>mdi-chevron-left</v-icon>
            Back
          </v-btn>
          <!-- Continue button -->
          <!-- IF on 1 (People Table) or 5 (Program People Table) -->
          <v-btn
            v-if="[1, 2, 5].includes(slideKey)"
            :style="{
              visibility: slideKey === keys.programsTable ? 'hidden' : 'visible'
            }"
            color="primary"
            @click="goToNextSlide"
            :disabled="disableContinueButtonV2"
            :loading="
              [1, 5].includes(slideKey) ? loadingLatestMetricValues : false
            "
            >Continue</v-btn
          >
          <!-- <v-btn
            v-else-if=""
            color="primary"
            @click="goToNextSlide"
            :disabled="disableContinueButton"
            >Continue</v-btn
          > -->
        </v-container>
        <transition :name="slideDirection" mode="out-in">
          <!-- First Slide: Select Users or A Program -->
          <!-- <div v-if="slideKey === keys.peopleTable || slideKey === 2" key="0" class="full-width"> -->
          <div key="0" class="full-width">
            <!-- Hide but keep space on slide 2 (programs table) -->
            <div class="align-text-left mb-5">
              <span
                class="header-text"
                v-if="
                  slideKey === keys.peopleTable ||
                    slideKey === keys.programPeople
                "
                >Choose people to update progress for</span
              >
              <span class="header-text" v-if="slideKey === keys.programsTable"
                >Choose a program to update progress for</span
              >
              <span
                class="header-text"
                v-if="slideKey === keys.individualMetrics"
                >Update progress for {{ selectedAudience[0].displayName }}</span
              >
              <span
                class="header-text"
                v-else-if="slideKey === keys.metricsSelection"
                >Update progress for {{ selectedAudience.length }} people</span
              >
            </div>
            <v-card
              class="d-flex flex-column align-start full-width"
              :class="
                slideKey === keys.programMetricsTable && $vuetify.breakpoint.xs
                  ? 'px-0 py-5'
                  : 'pa-5'
              "
              rounded="0"
              elevation="3"
              width="100%"
            >
              <!-- Form to choose people (1), programs (2), or people in program (5) -->
              <v-form
                class="full-width"
                v-if="
                  slideKey === keys.peopleTable ||
                    slideKey === keys.programsTable ||
                    slideKey === keys.programPeople
                "
              >
                <!-- flex-column on screens less than 1090px -->
                <v-row
                  class="d-flex align-center"
                  :class="
                    $vuetify.breakpoint.width < 1090
                      ? 'flex-column-reverse'
                      : ''
                  "
                >
                  <v-col cols="4"></v-col>
                  <v-col
                    cols="4"
                    class="d-flex justify-center"
                    :class="$vuetify.breakpoint.width < 1090 ? 'pt-0' : ''"
                  >
                    <div
                      v-if="
                        slideKey === keys.peopleTable ||
                          slideKey === keys.programsTable
                      "
                      class="people-group-button-oval d-flex"
                    >
                      <v-chip
                        @click="goToSlideV2(2)"
                        :color="
                          slideKey === keys.programsTable
                            ? 'white'
                            : 'lightGrey'
                        "
                        class="px-4 ml-1"
                        :class="{
                          'audience-button': slideKey === keys.programsTable
                        }"
                      >
                        Programs
                      </v-chip>
                      <v-chip
                        @click="goToSlideV2(1)"
                        :color="
                          slideKey === keys.peopleTable ? 'white' : 'lightGrey'
                        "
                        class="px-4 audience-button mr-1"
                        :class="{
                          'audience-button': slideKey === keys.peopleTable
                        }"
                      >
                        People
                      </v-chip>
                    </div>
                  </v-col>
                  <!-- Align button on right less than 1090px -->
                  <v-col
                    cols="4"
                    class="d-flex flex-start"
                    :class="
                      $vuetify.breakpoint.width < 1090
                        ? 'ml-auto mr-4 pb-0'
                        : ''
                    "
                  >
                    <v-btn
                      :text="$vuetify.breakpoint.xs ? false : true"
                      :icon="$vuetify.breakpoint.xs ? true : false"
                      color="primary"
                      @click="displayCreateUser = true"
                      v-if="
                        (slideKey === keys.peopleTable ||
                          slideKey === keys.programsTable) &&
                          permissions.includes('users:create:user')
                      "
                      :style="{
                        visibility:
                          slideKey === keys.programsTable ? 'hidden' : 'visible'
                      }"
                      ><v-icon>mdi-plus</v-icon><span>Create User</span></v-btn
                    >
                  </v-col>
                </v-row>
                <!-- </div> -->
                <v-text-field
                  v-if="slideKey == keys.peopleTable"
                  label="Search"
                  v-model="userTable.debounceSearch"
                >
                  <template v-slot:append-outer> </template>
                </v-text-field>
                <v-text-field v-else label="Search" v-model="search">
                  <template v-slot:append-outer> </template>
                </v-text-field>
                <!-- This div should only be there if we are selecting people (slide 1) -->
                <div class="d-flex" v-if="slideKey === keys.peopleTable">
                  <span
                    class="mt-2 mr-2 darkGrey--text"
                    v-if="selectedAudience.length > 0"
                    >Selected:</span
                  >
                  <div class="d-flex align-center flex-wrap">
                    <div
                      v-for="(user, index) in selectedAudience"
                      :key="user.userId"
                    >
                      <v-chip
                        class="ma-1"
                        close
                        @click:close="removeUserFromSelectedV2(index)"
                        >{{ user.displayName }}</v-chip
                      >
                    </div>
                  </div>
                </div>
                <!-- Selected people in program -->
                <div class="d-flex" v-if="slideKey === keys.programPeople">
                  <span
                    class="mt-2 mr-2 darkGrey--text"
                    v-if="selectedAudience.length > 0"
                    >Selected:</span
                  >
                  <div
                    v-if="slideProgramPeople.selectAllPeople === false"
                    class="d-flex align-center flex-wrap"
                  >
                    <div
                      v-for="(user, index) in selectedAudience"
                      :key="user.userId"
                    >
                      <v-chip
                        class="ma-1"
                        close
                        @click:close="removeUserFromSelectedV2(index)"
                        >{{ user.displayName }}</v-chip
                      >
                    </div>
                  </div>
                  <div v-else>
                    <v-chip
                      class="ma-1"
                      close
                      @click:close="removeAllPeopleFromProgram"
                      >All Users in Program</v-chip
                    >
                  </div>
                </div>
                <div class="align-text-left mt-2 ">
                  <div class="d-flex justify-space-between align-baseline">
                    <span class="darkGrey--text">Directory:</span>
                    <v-checkbox
                      v-model="slideProgramPeople.selectAllPeople"
                      v-if="slideKey === keys.programPeople"
                      @click="toggleSelectAllPeopleInProgram"
                      label="Select All"
                      class="px-4 py-0 my-0"
                      :disabled="computedLoadingTable"
                    ></v-checkbox>
                  </div>
                  <div v-if="slideKey == keys.peopleTable">
                    <UserTable
                      ref="user-table"
                      :selected="selectedAudience"
                      :search="search"
                      @select-item="addToSelectedV2($event, true)"
                    />
                    <!-- <v-data-table
                      show-select
                      v-model="selectedAudience"
                      :headers="audienceHeaders"
                      :items="visibleAudience"
                      :search="search"
                      :options.sync="userTable.options"
                      :server-items-length="userTable.total"
                      item-key="userId"
                      :items-per-page="10"
                      class="elevation-0 full-width mt-3 cursor-pointer"
                      :class="
                        slideProgramPeople.selectAllPeople === true
                          ? 'disabled-data-table'
                          : ''
                      "
                      :loading="computedLoadingTable"
                      loading-text="Loading... Please wait"
                      hide-default-header
                      no-data-text="No people found"
                      @click:row="addToSelectedV2($event)"
                      :footer-props="userTable.footerProps"
                      :mobile-breakpoint="0"
                    >
                      <template v-slot:[`item.info`]="{ item }">
                        <v-btn
                          icon
                          @click.stop="previewItem(item)"
                          :disabled="
                            slideProgramPeople.selectAllPeople === true
                          "
                          ><v-icon color="grey"
                            >mdi-information-outline</v-icon
                          ></v-btn
                        >
                      </template>
                    </v-data-table> -->
                  </div>
                  <v-data-table
                    v-else-if="slideKey == keys.programPeople"
                    :headers="audienceHeaders"
                    :items="visibleAudience"
                    :search="search"
                    item-key="userId"
                    :items-per-page="10"
                    class="elevation-0 full-width mt-3 cursor-pointer"
                    :class="
                      slideProgramPeople.selectAllPeople === true
                        ? 'disabled-data-table'
                        : ''
                    "
                    :loading="computedLoadingTable"
                    loading-text="Loading... Please wait"
                    hide-default-header
                    no-data-text="No people found"
                    @click:row="addToSelectedV2($event)"
                    :mobile-breakpoint="0"
                  >
                    <template v-slot:[`item.info`]="{ item }">
                      <v-btn
                        icon
                        @click.stop="previewItem(item)"
                        :disabled="slideProgramPeople.selectAllPeople === true"
                        ><v-icon color="grey"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      >
                    </template>
                  </v-data-table>
                  <v-data-table
                    v-else-if="slideKey == keys.programsTable"
                    :headers="audienceHeaders"
                    :items="visibleAudience"
                    :search="search"
                    item-key="programId"
                    :items-per-page="10"
                    class="elevation-0 full-width mt-3 cursor-pointer"
                    :class="
                      slideProgramPeople.selectAllPeople === true
                        ? 'disabled-data-table'
                        : ''
                    "
                    :loading="computedLoadingTable"
                    loading-text="Loading... Please wait"
                    hide-default-header
                    :no-data-text="
                      slideKey === keys.peopleTable
                        ? 'No people found'
                        : 'No programs found'
                    "
                    @click:row="
                      if (slideKey === keys.programsTable) {
                        slideProgramsTable.selectedItem = $event;
                        goToSlideV2(5);
                      } else addToSelectedV2($event);
                    "
                    :mobile-breakpoint="0"
                  >
                    <template v-slot:[`item.info`]="{ item }">
                      <v-btn
                        icon
                        @click.stop="previewItem(item)"
                        :disabled="slideProgramPeople.selectAllPeople === true"
                        ><v-icon color="grey"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      >
                    </template>
                  </v-data-table>
                  <v-data-table
                    v-else-if="
                      slideKey == keys.individualMetrics ||
                        slideKey == keys.metricSelection ||
                        slideKey == keys.programMetricsTable
                    "
                    :headers="audienceHeaders"
                    :items="visibleAudience"
                    :search="search"
                    item-key="metricId"
                    :items-per-page="10"
                    class="elevation-0 full-width mt-3 cursor-pointer"
                    :class="
                      slideProgramPeople.selectAllPeople === true
                        ? 'disabled-data-table'
                        : ''
                    "
                    :loading="computedLoadingTable"
                    loading-text="Loading... Please wait"
                    hide-default-header
                    :no-data-text="
                      slideKey === keys.peopleTable
                        ? 'No people found'
                        : 'No programs found'
                    "
                    @click:row="
                      if (slideKey === keys.programsTable) {
                        slideProgramsTable.selectedItem = $event;
                        goToSlideV2(5);
                      } else addToSelectedV2($event);
                    "
                    :mobile-breakpoint="0"
                  >
                    <template v-slot:[`item.info`]="{ item }">
                      <v-btn
                        icon
                        @click.stop="previewItem(item)"
                        :disabled="slideProgramPeople.selectAllPeople === true"
                        ><v-icon color="grey"
                          >mdi-information-outline</v-icon
                        ></v-btn
                      >
                    </template>
                  </v-data-table>
                </div>
              </v-form>
              <!-- Individual metric form -->
              <v-form
                class="full-width"
                v-else-if="slideKey === keys.individualMetrics"
              >
                <div class="align-text-left mt-n2 d-flex flex-column">
                  <v-text-field
                    label="Search"
                    v-model="search"
                    :style="{ width: '50%', marginLeft: 'auto' }"
                    hide-details
                    class="my-0 pb-0 pt-2"
                  >
                    <template v-slot:append-outer> </template>
                  </v-text-field>
                  <v-data-table
                    :headers="audienceHeaders"
                    :items="visibleAudience"
                    :search="search"
                    :item-key="computedItemKey"
                    :items-per-page="10"
                    class="elevation-0 full-width mt-3 cursor-pointer"
                    :loading="computedLoadingTable"
                    loading-text="Loading... Please wait"
                    no-data-text="Looks like you haven't created any properties that you can update progress for yet"
                    mobile-breakpoint="600"
                  >
                    <template v-slot:[`item.metricValue`]="{ item }">
                      <!-- <v-text-field v-model="item.metricValue"></v-text-field> -->
                      <v-checkbox
                        label="Completed"
                        v-model="item.metricValue"
                        v-if="item.dataType === 'BOOLEAN'"
                        @change="metricChangeHandler(item)"
                      ></v-checkbox>
                      <v-text-field
                        label="New value"
                        hide-details
                        dense
                        outlined
                        class="metric-field"
                        :rules="[rules.emptyString, rules.longString]"
                        v-model="item.metricValue"
                        v-else-if="item.dataType === 'STRING'"
                        @input="metricChangeHandler(item)"
                      ></v-text-field>
                      <v-text-field
                        label="New value"
                        hide-details
                        dense
                        outlined
                        class="metric-field"
                        :rules="
                          item.dataType === 'INTEGER' ? [rules.integer] : []
                        "
                        v-model="item.metricValue"
                        v-else-if="
                          item.dataType === 'INTEGER' ||
                            item.dataType === 'DECIMAL'
                        "
                        type="number"
                        @input="metricChangeHandler(item)"
                      ></v-text-field>
                      <input
                        class="timestamp-input"
                        type="date"
                        v-model="item.metricValue"
                        v-else-if="item.dataType === 'DATE'"
                        @input="metricChangeHandler(item)"
                      />
                      <input
                        class="timestamp-input"
                        type="datetime-local"
                        v-model="item.metricValue"
                        v-else-if="item.dataType === 'TIMESTAMP'"
                        @input="metricChangeHandler(item)"
                      />
                    </template>
                    <template v-slot:[`item.metricName`]="{ item }">
                      <p class="mt-2 mb-0">{{ item.metricName }}</p>
                      <p class="text-caption text--secondary mb-2 mt-0">
                        {{ item.description }}
                      </p>
                    </template>
                    <template v-slot:[`item.latestMetricValue`]="{ item }">
                      <p>
                        {{
                          item.latestMetricValue !== undefined &&
                          item.latestMetricValue.metricValue !== undefined
                            ? formatMetricValue(
                                item.dataType,
                                item.latestMetricValue.metricValue
                              )
                            : "---"
                        }}
                      </p>
                    </template>
                  </v-data-table>
                  <div class="d-flex full-width">
                    <v-btn
                      color="primary"
                      class="ml-auto mr-0"
                      @click="updateMetric"
                      :disabled="disableContinueButtonV2"
                      >Save Progress</v-btn
                    >
                  </div>
                </div>
              </v-form>
              <!-- Program Metric Form! -->
              <v-form
                class="full-width"
                v-else-if="slideKey === keys.programMetricsTable"
              >
                <div class="align-text-left mt-2 d-flex flex-column">
                  <p v-if="visibleAudience.length === 0">
                    This program only has learning modules, which you are unable
                    to update.
                  </p>
                  <v-expansion-panels class="my-5" multiple>
                    <v-expansion-panel
                      v-for="item in visibleAudience"
                      :key="item.metricId"
                    >
                      <v-expansion-panel-header class="d-flex align-center">
                        <div class="metric-name">
                          <p class="text-subtitle-1 my-0 word-break">
                            {{ item.metricName }}
                          </p>
                        </div>
                        <div class="metric-description">
                          <p class="text-caption my-0 word-break">
                            {{ item.description }}
                          </p>
                        </div>
                      </v-expansion-panel-header>
                      <!-- <tr v-for="user in item.users" :key="user.userId">
                          <td :colspan="headers.length">
                            {{ user.displayName }}
                          </td>
                        </tr> -->
                      <!-- <th colspan="2">User</th>
                        <th>Metric Value</th>
                        <th>Last Completed</th> -->
                      <v-expansion-panel-content
                        class="full-width program-metric-table"
                      >
                        <!-- <td :colspan="3" class="px-0"> -->
                        <v-simple-table>
                          <template v-slot:default>
                            <thead class="full-width">
                              <tr>
                                <th :style="{ width: '40%' }">User</th>
                                <th>New Value</th>
                                <th>Most Recent Value</th>
                              </tr>
                            </thead>
                            <tbody class="full-width">
                              <tr
                                v-for="user in item.audience"
                                :key="user.userId"
                              >
                                <td :style="{ width: '40%' }">
                                  {{ user.displayName }}
                                </td>
                                <td v-if="item.dataType === 'BOOLEAN'">
                                  <!-- <template v-slot:[`user.metricValue`]="{ value }"> -->
                                  <v-checkbox
                                    label="Completed"
                                    v-model="user.metricValue"
                                    @change="
                                      programMetricChangeHandler(item, user)
                                    "
                                  ></v-checkbox>
                                  <!-- </template> -->
                                </td>
                                <td v-else-if="item.dataType === 'STRING'">
                                  <v-text-field
                                    label="New value"
                                    hide-details
                                    dense
                                    outlined
                                    class="metric-field"
                                    :rules="[
                                      rules.emptyString,
                                      rules.longString
                                    ]"
                                    v-model="user.metricValue"
                                    @input="
                                      programMetricChangeHandler(item, user)
                                    "
                                  ></v-text-field>
                                </td>
                                <td
                                  v-else-if="
                                    item.dataType === 'INTEGER' ||
                                      item.dataType === 'DECIMAL'
                                  "
                                >
                                  <v-text-field
                                    label="New value"
                                    hide-details
                                    dense
                                    outlined
                                    class="metric-field"
                                    type="number"
                                    :rules="
                                      item.dataType === 'INTEGER'
                                        ? [rules.integer]
                                        : []
                                    "
                                    v-model="user.metricValue"
                                    @input="
                                      programMetricChangeHandler(item, user)
                                    "
                                  ></v-text-field>
                                </td>
                                <td v-else-if="item.dataType === 'DATE'">
                                  <!-- <v-date-picker
                                    label="New value"
                                    class="metric-field"
                                    v-model="user.metricValue"
                                  ></v-date-picker> -->
                                  <input
                                    class="timestamp-input"
                                    type="date"
                                    v-model="user.metricValue"
                                    @change="
                                      programMetricChangeHandler(item, user)
                                    "
                                  />
                                </td>
                                <td v-else-if="item.dataType === 'TIMESTAMP'">
                                  <input
                                    class="timestamp-input"
                                    type="datetime-local"
                                    v-model="user.metricValue"
                                    @change="
                                      programMetricChangeHandler(item, user)
                                    "
                                  />
                                </td>
                                <td>
                                  {{
                                    user.latestMetricValue !== undefined &&
                                    user.latestMetricValue.metricValue !==
                                      undefined
                                      ? formatMetricValue(
                                          item.dataType,
                                          user.latestMetricValue.metricValue
                                        )
                                      : "---"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                        <!-- </td> -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn
                    color="primary"
                    class="ml-auto"
                    :class="$vuetify.breakpoint.xs ? 'mr-5' : ''"
                    @click="updateMetric"
                    :disabled="disableContinueButtonV2"
                    >Save Progress</v-btn
                  >
                </div>
              </v-form>
              <!-- V-select for multiple people metrics selection -->
              <v-form
                class="full-width"
                v-else-if="slideKey === keys.metricSelection"
              >
                <div class="align-text-left mt-2">
                  <v-chip v-if="selectedAudience.length > 1"
                    >{{ selectedAudience.length }} People Selected</v-chip
                  >
                  <v-chip v-else-if="selectedAudience.length === 1"
                    >{{ selectedAudience.length }} Person Selected</v-chip
                  >
                  <p
                    v-if="!loadingMetrics && clientMetrics.length === 0"
                    class="mt-4"
                  >
                    We couldn't find any properties to update, go to Program
                    Admin to create properties for your organization
                  </p>
                  <v-progress-linear
                    v-if="loadingMetrics === true"
                    indeterminate
                    color="primary"
                    class="mt-4"
                  ></v-progress-linear>
                  <v-expansion-panels
                    v-else
                    multiple
                    flat
                    class="mt-4"
                    :class="$vuetify.breakpoint.xs ? '' : 'px-4'"
                    v-model="slideMetricSelection.openMetrics"
                  >
                    <v-expansion-panel
                      v-for="(metric, i) in metricsToUpdate"
                      :key="metric.metricDefinition.metricId"
                      class="outlined"
                      :class="
                        i === metricsToUpdate.length - 1 ||
                        slideMetricSelection.openMetrics.includes(i) ||
                        slideMetricSelection.openMetrics.includes(i + 1)
                          ? 'outlined-bottom'
                          : ''
                      "
                    >
                      <v-expansion-panel-header
                        :disabled="
                          metric.latestValues === undefined ||
                            metric.latestValues === null ||
                            metric.latestValues.length === 0
                        "
                        :class="$vuetify.breakpoint.xs ? 'pl-0 pr-1' : ''"
                      >
                        <v-container
                          class="d-flex fluid justify-start align-items-center py-0"
                        >
                          <div
                            class="my-auto mr-2"
                            :style="{ width: '45%', 'min-width': '45%' }"
                          >
                            <p class="my-auto word-break">
                              {{ metric.metricDefinition.metricName }}
                            </p>
                          </div>
                          <!-- <p class="my-auto">{{ formatMetricValue(metric.metricDefinition.dataType, metric.value) }}</p> -->
                          <v-checkbox
                            label="Completed"
                            v-model="metric.value"
                            v-if="
                              metric.metricDefinition.dataType === 'BOOLEAN'
                            "
                            hide-details
                            class="my-auto pt-0"
                            @click.native.stop
                          ></v-checkbox>
                          <v-text-field
                            label="New value"
                            hide-details
                            dense
                            outlined
                            class="my-auto"
                            :rules="[rules.emptyString, rules.longString]"
                            v-model="metric.value"
                            v-else-if="
                              metric.metricDefinition.dataType === 'STRING'
                            "
                            @click.native.stop
                          ></v-text-field>
                          <v-text-field
                            label="New value"
                            hide-details
                            dense
                            outlined
                            class="my-auto"
                            type="number"
                            :rules="
                              metric.metricDefinition.dataType === 'INTEGER'
                                ? [rules.integer]
                                : []
                            "
                            v-model="metric.value"
                            v-else-if="
                              metric.metricDefinition.dataType === 'INTEGER' ||
                                metric.metricDefinition.dataType === 'DECIMAL'
                            "
                            @click.native.stop
                          ></v-text-field>
                          <input
                            class="timestamp-input my-auto"
                            :style="{ width: '100%' }"
                            type="date"
                            v-model="metric.value"
                            v-else-if="
                              metric.metricDefinition.dataType === 'DATE'
                            "
                            @click.stop
                          />
                          <input
                            class="timestamp-input my-auto"
                            :style="{ width: '100%' }"
                            type="datetime-local"
                            v-model="metric.value"
                            v-else-if="
                              metric.metricDefinition.dataType === 'TIMESTAMP'
                            "
                            @click.stop
                          />
                        </v-container>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              @click.stop="removeMetricFromSelected(i)"
                              v-bind="attrs"
                              v-on="on"
                              color="red"
                              class="mr-5 ml-auto"
                              >mdi-close</v-icon
                            >
                          </template>
                          <span>Clear progress</span>
                        </v-tooltip>
                        <!-- Disable button if no latestValues -->
                        <template v-slot:actions>
                          <v-icon
                            :style="
                              metric.latestValues === undefined ||
                              metric.latestValues === null ||
                              metric.latestValues.length === 0
                                ? { visibility: 'hidden' }
                                : { visibility: 'visible' }
                            "
                            >$expand</v-icon
                          >
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        class="latest-metric-table"
                        :class="$vuetify.breakpoint.xs ? 'px-0' : ''"
                      >
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>User</th>
                                <th>New Value</th>
                                <th>Most Recent Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="user in metric.latestValues"
                                :key="user.userId"
                              >
                                <td>{{ user.displayName }}</td>
                                <td>
                                  {{
                                    formatMetricValue(
                                      metric.metricDefinition.dataType,
                                      user.latestMetrics[
                                        metric.metricDefinition.metricId
                                      ].metricValue
                                    )
                                  }}
                                </td>
                                <td>
                                  {{
                                    formattedDate(
                                      user.latestMetrics[
                                        metric.metricDefinition.metricId
                                      ].date
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- </v-expansion-panels> -->
                  </v-expansion-panels>
                  <!-- Last panel has the select, description, and sub panel -->
                  <!-- <v-expansion-panel> -->
                  <!-- Metric Select -->
                  <!-- <v-expansion-panel-header
                        :disabled="
                          usersWithLatestMetrics === null ||
                            usersWithLatestMetrics.length === 0
                        "
                        :class="
                          usersWithLatestMetrics === null ||
                          usersWithLatestMetrics.length === 0
                            ? 'cursor-default'
                            : ''"
                      > -->
                  <div class="full-width d-flex flex-column mt-4">
                    <v-autocomplete
                      :items="visibleAudience"
                      item-text="metricName"
                      return-object
                      label="Choose progress to update"
                      outlined
                      @change="selectMetric"
                      @click.native.stop
                      v-model="
                        slideMetricSelection.selectedMetric.metricDefinition
                      "
                      hide-details
                      :style="{ width: '100%' }"
                      class="d-inline-block mt-2"
                      :class="$vuetify.breakpoint.xs ? '' : 'px-4'"
                      :loading="computedLoadingTable"
                    ></v-autocomplete>
                    <div
                      class="full-width"
                      :class="$vuetify.breakpoint.xs ? '' : 'px-4'"
                    >
                      <div
                        v-if="
                          slideMetricSelection.selectedMetric
                            .metricDefinition !== null &&
                            slideMetricSelection.selectedMetric.metricDefinition
                              .description !== null
                        "
                        class="mb-2 mt-1"
                      >
                        <span class="text-caption text--secondary">{{
                          slideMetricSelection.selectedMetric.metricDefinition
                            .description
                        }}</span>
                      </div>
                      <!-- Inputs change by selectedMetric's value -->
                      <div
                        v-if="
                          slideMetricSelection.selectedMetric
                            .metricDefinition !== null
                        "
                      >
                        <v-checkbox
                          v-if="
                            slideMetricSelection.selectedMetric
                              .metricDefinition !== null &&
                              slideMetricSelection.selectedMetric
                                .metricDefinition.dataType === 'BOOLEAN'
                          "
                          label="Completed"
                          v-model="slideMetricSelection.selectedMetric.value"
                          hide-details
                        ></v-checkbox>
                        <v-text-field
                          v-else-if="
                            slideMetricSelection.selectedMetric
                              .metricDefinition !== null &&
                              slideMetricSelection.selectedMetric
                                .metricDefinition.dataType === 'STRING'
                          "
                          outlined
                          v-model="slideMetricSelection.selectedMetric.value"
                          class="metric-field"
                          :style="{ width: '100%' }"
                          :rules="[rules.emptyString, rules.longString]"
                          hide-details
                        ></v-text-field>
                        <v-text-field
                          v-else-if="
                            slideMetricSelection.selectedMetric
                              .metricDefinition !== null &&
                              (slideMetricSelection.selectedMetric
                                .metricDefinition.dataType === 'INTEGER' ||
                                slideMetricSelection.selectedMetric
                                  .metricDefinition.dataType === 'DECIMAL')
                          "
                          outlined
                          v-model.number="
                            slideMetricSelection.selectedMetric.value
                          "
                          class="metric-field"
                          :style="{ width: '100%' }"
                          type="number"
                          :rules="
                            slideMetricSelection.selectedMetric.metricDefinition
                              .dataType === 'INTEGER'
                              ? [rules.integer]
                              : []
                          "
                          hide-details
                        ></v-text-field>
                        <!-- <v-date-picker
                          v-else-if="
                            slideMetricSelection.selectedMetric.metricDefinition
                              .dataType === 'DATE'
                          "
                          v-model="slideMetricSelection.selectedMetric.value"
                        ></v-date-picker> -->
                        <input
                          v-else-if="
                            slideMetricSelection.selectedMetric.metricDefinition
                              .dataType === 'DATE'
                          "
                          class="timestamp-input"
                          :style="{ width: '100%' }"
                          type="date"
                          v-model="slideMetricSelection.selectedMetric.value"
                        />
                        <input
                          class="timestamp-input"
                          :style="{ width: '100%' }"
                          type="datetime-local"
                          v-else-if="
                            slideMetricSelection.selectedMetric.metricDefinition
                              .dataType === 'TIMESTAMP'
                          "
                          v-model="slideMetricSelection.selectedMetric.value"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Makes the icon grey if no usersWithLatestMetrics or no selected metric -->
                  <!-- <template v-slot:actions>
                          <v-icon
                            class="mb-auto expand-arrow d-none"
                            :disabled="
                              usersWithLatestMetrics === null ||
                                usersWithLatestMetrics.length === 0"
                            >$expand</v-icon
                          >
                        </template> -->
                  <!-- </v-expansion-panel-header> -->
                  <!-- Last completed values for selected users -->
                  <!-- <v-expansion-panel-content> -->
                  <div>
                    <!-- Child panel to show the table of users/values -->
                    <v-expansion-panels
                      :class="$vuetify.breakpoint.xs ? 'full-width' : ''"
                      flat
                      v-model="slideMetricSelection.showCurrentValues"
                    >
                      <v-expansion-panel
                        v-if="
                          slideMetricSelection.selectedMetric
                            .metricDefinition !== null &&
                            usersWithLatestMetrics !== null &&
                            usersWithLatestMetrics.length > 0
                        "
                      >
                        <v-expansion-panel-header
                          id="currentValues"
                          :class="$vuetify.breakpoint.xs ? 'px-0' : ''"
                        >
                          View Current User Values
                          <template v-slot:actions>
                            <v-icon color="primary" class="mr-auto ml-0">
                              {{
                                slideMetricSelection.showCurrentValues === 0
                                  ? "mdi-minus"
                                  : "mdi-plus"
                              }}
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                          class="latest-metric-table"
                          :class="$vuetify.breakpoint.xs ? 'px-0' : ''"
                        >
                          <v-simple-table
                            v-if="
                              usersWithLatestMetrics !== null &&
                                usersWithLatestMetrics.length > 0
                            "
                            class="mt-n2"
                            dense
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th>User</th>
                                  <th>New Value</th>
                                  <th>Most Recent Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="user in usersWithLatestMetrics"
                                  :key="user.userId"
                                >
                                  <td>{{ user.displayName }}</td>
                                  <td>
                                    {{
                                      formatMetricValue(
                                        slideMetricSelection.selectedMetric
                                          .metricDefinition.dataType,
                                        user.latestMetrics[
                                          slideMetricSelection.selectedMetric
                                            .metricDefinition.metricId
                                        ].metricValue
                                      )
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      formattedDate(
                                        user.latestMetrics[
                                          slideMetricSelection.selectedMetric
                                            .metricDefinition.metricId
                                        ].date
                                      )
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <!-- </v-expansion-panel-content>
                    </v-expansion-panel>

                  </v-expansion-panels> -->
                  <!-- BUTTON -->
                  <div class="full-width d-flex">
                    <v-btn
                      text
                      color="primary"
                      class="mx-auto my-4"
                      @click="addAnotherMetric"
                      :disabled="
                        slideMetricSelection.selectedMetric.value === null ||
                          String(
                            slideMetricSelection.selectedMetric.value
                          ).trim() === ''
                      "
                    >
                      <v-icon :style="{ marginBottom: '3px' }">mdi-plus</v-icon>
                      Update Another
                    </v-btn>
                  </div>
                  <!-- V-for with metrics to update -->
                  <!-- <div
                    v-if="metricsToUpdate.length > 0"
                    class="px-5 full-width"
                  > -->
                  <!-- <p class="text-caption my-1">Metrics to update:</p> -->
                  <!-- <div
                      v-for="metric in metricsToUpdate"
                      :key="metric.metricId"
                      class="d-flex flex-column"
                    > -->
                  <!-- <div class="d-flex flex-column" :style="{ width: '70%' }"> -->
                  <!-- <div class="d-flex align-baseline">
                        <p class="my-0 text-subtitle-1">{{ metric.metricDefinition.metricName }}</p>
                        <p
                          class="my-0 text-subtitle-1 ml-auto"
                          :style="{
                            'min-width': '25%',
                            'max-width': '50%',
                            'justify-self': 'flex-end'
                          }"
                        >
                          {{
                            metric.metricDefinition.dataType !== "BOOLEAN"
                              ? metric.value
                              : metric.value === true
                              ? "Completed"
                              : "Incomplete"
                          }}
                        </p>
                      </div>
                      <p class="mt-0 text-caption grey--text word-break">
                        {{ metric.metricDefinition.description }}
                      </p>
                   </div>
                  </div> -->
                  <div class="full-width d-flex">
                    <v-btn
                      color="primary"
                      class="ml-auto mr-0"
                      @click="updateMetric"
                      :disabled="disableContinueButtonV2"
                      >Save Progress</v-btn
                    >
                  </div>
                </div>
              </v-form>
              <div
                v-else-if="slideKey === keys.robin"
                class="d-flex flex-column align-items-center full-width"
              >
                <div
                  v-if="failureMessage !== true"
                  class="d-flex flex-column align-items-center"
                >
                  <h2>AWESOME!</h2>
                  <p class="mt-2 grey--text text-caption">
                    The updated progress has been saved
                  </p>
                  <RobinAnimation
                    animation="train"
                    :width="150"
                    class="mb-10 mx-auto robin-animation"
                  />
                </div>
                <div v-else-if="failureMessage === true">
                  <v-card-title class="title-text word-break full-width"
                    >Looks like something went wrong</v-card-title
                  >

                  <p class="pl-4 full-width align-text-left">
                    If the problem continues, feel free to reach out to customer
                    service via one of the following methods...
                  </p>
                  <p class="pl-4 align-text-left full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 align-text-left full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>
                </div>
                <div class="d-flex mx-auto">
                  <v-btn
                    color="primary"
                    @click="leavePage"
                    outlined
                    class="mr-5"
                    >Done</v-btn
                  >
                  <v-btn color="primary" @click="startOver">{{
                    successMessage === true ? "Update Another" : "Try Again"
                  }}</v-btn>
                </div>
              </div>
            </v-card>
            <v-bottom-sheet v-model="displayInfoSheet" inset width="500">
              <v-sheet class="text-center" height="475px">
                <!-- Pop up for People info sheet -->
                <div
                  v-if="
                    (slideKey === keys.peopleTable &&
                      computedPreviewItem !== null) ||
                      (slideKey === keys.programPeople &&
                        computedPreviewItem !== null)
                  "
                  class="pt-4"
                >
                  <div class="d-flex align-center justify-space-between mx-6">
                    <v-btn fab color="lightGrey" small class="hidden"
                      ><v-icon>mdi-chevron-down</v-icon></v-btn
                    >
                    <v-card-title>
                      <b> {{ computedPreviewItem.displayName }}</b>
                    </v-card-title>
                    <v-btn
                      fab
                      color="mediumGrey"
                      small
                      elevation="0"
                      @click="displayInfoSheet = !displayInfoSheet"
                      ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                    >
                  </div>
                  <!-- <div v-if="slidePeopleOrPrograms.view == 1"> -->
                  <div>
                    <v-img
                      v-bind:src="computedPreviewItem.profilePicUrl"
                      v-if="computedPreviewItem.profilePicUrl"
                      height="110"
                      width="110"
                      max-height="110"
                      max-width="110"
                      class="circle-border-radius profile-picture mx-auto"
                    />
                    <v-icon
                      v-else
                      x-large
                      class="profile-picture circle-border-radius icon-image"
                      >mdi-account</v-icon
                    >

                    <p class="mb-1 mt-4">
                      {{ computedPreviewItem.jobTitle || "Unknown Job Title" }}
                    </p>
                    <p class="mb-1">
                      {{
                        computedPreviewItem.organization ||
                          "Unknown Organization"
                      }}
                    </p>
                    <p>
                      {{
                        computedPreviewItem.businessCity
                          ? computedPreviewItem.businessCity
                          : ""
                      }}{{
                        computedPreviewItem.businessCity &&
                        computedPreviewItem.businessState
                          ? ", "
                          : !computedPreviewItem.businessCity &&
                            !computedPreviewItem.businessState
                          ? "Unknown Location"
                          : ""
                      }}
                      {{
                        computedPreviewItem.businessState
                          ? computedPreviewItem.businessState
                          : ""
                      }}
                    </p>
                    <v-divider class="mx-7" />
                    <div class="d-flex ml-6 mt-6">
                      <p class="darkGrey--text mb-2">
                        Email:
                        <b class="black--text">{{
                          computedPreviewItem.businessEmail
                            ? computedPreviewItem.businessEmail
                            : "-"
                        }}</b>
                      </p>
                    </div>
                    <div class="d-flex ml-6">
                      <p class="darkGrey--text">
                        Phone:
                        <b class="black--text">{{
                          computedPreviewItem.businessPhone
                            ? computedPreviewItem.businessPhone
                            : "-"
                        }}</b>
                      </p>
                    </div>
                  </div>
                  <v-btn
                    color="primary"
                    rounded
                    width="170"
                    @click="addToSelectedV2(computedPreviewItem)"
                    >Update Progress</v-btn
                  >
                </div>
                <!-- Pop up for Programs info sheet -->
                <div
                  v-else-if="
                    slideKey === keys.programsTable &&
                      computedPreviewItem !== null
                  "
                  class="pt-4"
                >
                  <div class="d-flex align-center justify-space-between mx-6">
                    <v-btn fab color="lightGrey" small class="hidden"
                      ><v-icon>mdi-chevron-down</v-icon></v-btn
                    >
                    <v-card-title class="word-break">
                      <b> {{ computedPreviewItem.displayName }}</b>
                    </v-card-title>
                    <v-btn
                      fab
                      color="mediumGrey"
                      small
                      elevation="0"
                      @click="displayInfoSheet = !displayInfoSheet"
                      ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                    >
                  </div>
                  <div class="d-flex align-center justify-center">
                    <v-img
                      v-if="
                        computedPreviewItem.imageURL !== null ||
                          (computedPreviewItem.imageURL &&
                            computedPreviewItem.imageURL.trim() !== '')
                      "
                      v-bind:src="computedPreviewItem.imageURL"
                      height="110"
                      width="110"
                      max-height="110"
                      max-width="110"
                      class="circle-border-radius profile-picture mx-auto"
                    />
                    <v-icon x-large v-else class="ml-2">mdi-star</v-icon>
                  </div>
                  <!-- <p
                    class="mt-4 overflow"
                    v-html="slidePeopleOrPrograms.selectedItem.description"
                  ></p> -->
                  <p class="mt-4 mx-2 overflow">
                    {{ computedPreviewItem.descriptionNoTags }}
                  </p>
                  <v-divider class="mx-7 mb-4" />
                  <div>
                    <p
                      class="darkGrey--text mb-4"
                      v-if="computedPreviewItem.startDate !== null"
                    >
                      Start Date:
                      <b class="black--text">
                        {{ formattedDate(computedPreviewItem.startDate) }}
                      </b>
                    </p>
                    <p
                      class="darkGrey--text mb-4"
                      v-if="computedPreviewItem.rollingProgramLength !== null"
                    >
                      Rolling Program Length:
                      <b class="black--text">
                        {{ computedPreviewItem.rollingProgramLength }}
                        days
                      </b>
                    </p>
                    <p
                      class="darkGrey--text mb-4"
                      v-if="
                        computedPreviewItem.endDate !== null &&
                          computedPreviewItem.rollingProgramLength === null
                      "
                    >
                      End Date:
                      <b class="black--text">
                        {{ formattedDate(computedPreviewItem.startDate) }}
                      </b>
                    </p>
                  </div>
                  <v-btn
                    color="primary"
                    rounded
                    width="170"
                    @click="goToSlideV2(5)"
                    >Update Progress</v-btn
                  >
                  <!-- </div> -->
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </div>
        </transition>
      </v-col>
    </div>
    <!-- User creator widget -->
    <!-- TODO: This will stay but tweak probably -->
    <v-navigation-drawer
      v-model="displayCreateUser"
      temporary
      fixed
      right
      width="500"
    >
      <UserCreatorWidgetSimple
        v-if="displayCreateUser"
        @close="displayCreateUser = false"
        @get-users="getUsers"
        :clientId="clientId"
        source="METRIC_UI"
      ></UserCreatorWidgetSimple>
    </v-navigation-drawer>
    <!-- Group creator widget -->
    <!-- <v-navigation-drawer
      v-model="displayCreateGroup"
      temporary
      fixed
      right
      width="500"
    >
      <GroupCreatorWidget
        v-if="displayCreateGroup"
        @get-groups="getGroups"
        @close="displayCreateGroup = false"
        @preload-group="preloadGroup"
        :clientId="clientId"
        source="METRIC_UI"
      />
    </v-navigation-drawer> -->
    <v-overlay v-if="displayCreateGroup || displayCreateUser" />
    <!-- <v-dialog v-model="robinLoading">
      <v-card width="200">
        <RobinAnimation
          animation="idleBlink"
          :width="100"
          class="mb-10 mx-auto robin-animation"
        />
      </v-card>
    </v-dialog> -->
    <LoadingDialog
      :showDialog="loadingDialog"
      header="Updating Progress"
      line1="This should just take a minute"
    >
    </LoadingDialog>
  </v-card>
</template>

<script>
import GroupService from "@/services/GroupService";
import UserService from "@/services/UserService";
import ProgramService from "@/services/ProgramService";
// import WalletService from "../services/WalletService";
// import { truncateNumber, numberWithCommas } from "@/shared_data/functions";
// import { numberWithCommas } from "@/shared_data/functions";
import RobinAnimation from "@/components/Robin";
// import momentTz from "moment-timezone";
import moment from "moment";
import UserCreatorWidgetSimple from "@/components/UserCreatorWidgetSimpleV2.vue";
import UserTable from "../components/data-table/Users.vue";

import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";
import LoadingDialog from "../components/loadingDialog.vue";
// import BudgetService from "../services/BudgetService";

function initialState() {
  return {
    // 1 - People table
    // 2 - Programs table
    // 3 - Individual metrics
    // 4 - Multiple user metrics
    // 5 - People in program
    // 6 - Metrics in program
    // 7 - Robin
    // keeping
    // clientId: null,
    clientMetrics: [],
    loadingMetrics: false,
    loadingLatestMetricValues: false,
    search: null,
    slideKey: 2,
    slideDirection: "topic-left",
    successMessage: false,
    failureMessage: false,
    // Flag for if they've touched any inputs for disableContinueButton
    inputChange: false,
    // new keys
    keys: {
      // All the client's people
      peopleTable: 1, // Has choose metrics button
      // All the client's programs
      programsTable: 2, // Has continue
      // Table of metrics for an individual
      individualMetrics: 3, // Has update metrics button
      // Select with metrics for more than one person
      metricSelection: 4, // Has update metrics button
      // All the People in a program
      programPeople: 5, // Has choose metrics button
      // Metrics in a program
      programMetricsTable: 6, // Has update Metrics button
      // Robin!
      robin: 7
    },
    // The final selected program
    selectedProgram: {
      loadingUsers: false,
      metrics: [],
      metricsToUpdate: [],
      program: null,
      programAudience: []
    },
    selectedAudience: [],
    metricsToUpdate: [],
    // All the client's people
    // Slide 1
    slidePeopleTable: {
      loadingUsers: false,
      selectedItem: null,
      // selectedUsers: [],
      users: []
      // search: null,
    },
    // All the client's programs
    // Slide 2
    slideProgramsTable: {
      loadingPrograms: false,
      programs: [],
      selectedItem: null
      // search: null,
    },
    // For one user, all the users metrics
    // Slide 3
    slideIndividualMetrics: {
      metrics: [],
      selectedMetric: null
    },
    // Select of client's metrics
    // Slide 4
    slideMetricSelection: {
      selectedMetric: {
        metricDefinition: null,
        value: null
      },
      showCurrentValues: null,
      // array of open metric indexes
      openMetrics: [],
      selectedMetricOpen: 0
    },
    // All the people in a program
    // Slide 5
    slideProgramPeople: {
      loadingUsers: false,
      selectAllPeople: false,
      selectedItem: []
    },
    // Metrics for Program
    // Slide 6
    slideProgramMetricsTable: {
      loadingMetrics: false
    },
    loadingDialog: false,
    rules: {
      integer: value =>
        (value && Number.isInteger(Number(value))) || "Must be whole number",
      emptyString: value => (value && value.trim() !== "") || "Cannot be empty",
      longString: value => (value && value.length < 256) || "Value is too long"
    },
    displayInfoSheet: false,
    displayCreateGroup: false,
    displayCreateUser: false,
    userTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      users: [],
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null
    }
  };
}

export default {
  name: "MetricUI",
  title: "Whistle | Progress",
  components: {
    // Robin,
    UserCreatorWidgetSimple,
    // GroupCreatorWidget,
    RobinAnimation,
    LoadingDialog,
    UserTable
  },
  props: {
    // budgets: Array,
    preloadedProgram: Object,
    programId: Number
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    if (this.$auth) {
      // this.clientId = this.userProfile.clientId;
      // this.getGroups();
      // this.getUsersV2(true);
      this.getPrograms(this.programId);
      this.getMetricsForClient();
      // this.getAwards();
      if (this.programId) {
        console.log("preloaded program by ID", this.programId);
        // this.selectedProgram.program = this.preloadedProgram;
        // this.preloadProgram();
      } else if (
        this.preloadedProgram !== null &&
        this.preloadedProgram !== undefined
      ) {
        // We are coming from Program Admin with a program
        console.log("preloaded program", this.preloadedProgram);
        this.selectedProgram.program = this.preloadedProgram;
        this.preloadProgram();
      }
    }
  },
  beforeDestroy() {},
  // watch : {
  //   slidePeople: {
  //     deep: true,
  //     handler() {
  //       this.tableLoading
  //     }
  //   }
  // },
  methods: {
    preloadProgram() {
      this.slideKey = this.keys.programPeople;
      this.getProgramAudience();
    },
    goToSlideV2(index) {
      this.search = null;
      this.userTable.debounceSearch = null;
      if (this.slideKey === this.keys.peopleTable) {
        if (index === 2) {
          // Moving from People to Program table!
          // Clear selected item
          this.slidePeopleTable.selectedItem = null;
          this.slideKey = index;
        }
      } else if (this.slideKey === this.keys.programsTable) {
        if (index === 1) {
          // Going from Programs to People
          // Clear selected item
          this.slideProgramsTable.selectedItem = null;
          this.slideKey = index;
        } else if (index === 5) {
          // On programs table, we've selected our program!!
          // Clear program audience and selected audience
          this.selectedProgram.programAudience = [];
          this.selectedAudience = [];
          // Have selected a program, get people and go to slide 5!
          // Set the program
          this.selectedProgram.program = this.slideProgramsTable.selectedItem;
          this.getProgramAudience();
          if (this.displayInfoSheet === true) {
            this.displayInfoSheet = false;
          }
          this.slideKey = this.keys.programPeople;
        }
      } else if (index === 7) {
        this.slideKey = index;
      }
    },
    async goToNextSlide() {
      this.slideDirection = "topic-left";
      this.search = null;
      this.userTable.debounceSearch = null;
      const currentSlide = this.slideKey;
      if (currentSlide === this.keys.peopleTable) {
        // People table
        // Set selected audience with a clean copy of the array
        // this.selectedAudience = JSON.parse(
        //   JSON.stringify(this.selectedAudience)
        // );
        if (this.selectedAudience.length === 1) {
          // We have just one user selected
          // Copy client metrics to slideIndividualMetrics metrics array
          this.slideIndividualMetrics.metrics = JSON.parse(
            JSON.stringify(this.clientMetrics)
          );
          // Get latest metrics for all metrics
          // It's an object with an object for each userId we send
          const latestMetrics = await this.getLatestMetrics(
            this.selectedAudience,
            this.slideIndividualMetrics.metrics
          );
          console.log("latest metrics", latestMetrics);
          // Attach a metricValue property to hold new metric value
          this.slideIndividualMetrics.metrics.forEach(metric => {
            console.log(metric.metricId);
            // If we get an error getting latest metrics, it'll return -1
            if (latestMetrics !== -1) {
              // This is the individual metrics route, we need to find the object for the selected user
              const userMetrics =
                latestMetrics[this.selectedAudience[0].userId];
              console.log(userMetrics);

              // If the metric has a latest metric value, attach it to the metric
              if (userMetrics && userMetrics[metric.metricId] !== undefined) {
                console.log(
                  "latest metric value",
                  userMetrics[metric.metricId]
                );
                console.log("foumd latest metric value");
                metric.latestMetricValue = userMetrics[metric.metricId];
                // precheck check boxes that are true, otherwise attach null placeholder value
                if (
                  (metric.dataType === "BOOLEAN" &&
                    userMetrics[metric.metricId].metricValue == 1) ||
                  userMetrics[metric.metricId].metricValue == true
                ) {
                  metric.metricValue = userMetrics[metric.metricId].metricValue;
                } else {
                  metric.metricValue = null;
                }
                console.log(metric);
              }
            } else {
              metric.metricValue = null;
            }
          });
          // Go to individual metrics select slide
          this.slideKey = this.keys.individualMetrics;
        } else {
          // Copy metrics array to slideMetricSelection.metrics
          // this.slideMetricSelection.metrics = JSON.parse(
          //   JSON.stringify(this.clientMetrics)
          // );
          // console.log('setting slideMetricSelection.metrics ', this.slideMetricSelection.metrics);
          // Get latest metrics
          const latestMetrics = await this.getLatestMetrics(
            this.selectedAudience,
            this.clientMetrics
          );
          console.log("latest metrics", latestMetrics);
          // If we get an error getting latest metrics, it'll return -1
          if (latestMetrics !== -1) {
            // Loop through audience, if latestMetrics[user.userId] !== undefined, attach
            this.selectedAudience.forEach(user => {
              if (latestMetrics[user.userId] !== undefined) {
                user.latestMetrics = latestMetrics[user.userId];
              }
            });
          }
          // Go to slide 4
          this.slideKey = this.keys.metricSelection;
        }
      } else if (currentSlide === this.keys.programsTable) {
        // Program table
        this.slideKey = this.keys.programPeople;
      } else if (currentSlide === this.keys.programPeople) {
        // People in program
        // Attach property to hold the metric value
        this.selectedAudience.forEach(user => {
          user.metricValue = null;
        });
        // Get program metrics
        this.selectedProgram.metrics = this.getProgramMetrics();
        console.log("calling get latest metrics");
        const programMetrics = await this.getLatestMetrics(
          this.selectedAudience,
          this.selectedProgram.metrics
        );
        // Attach user array to each metric
        console.log("formatting program metrics array");
        this.selectedProgram.metrics.forEach(metric => {
          console.log(programMetrics);
          // give each user a metricValue + metric.metricId property set to null
          metric.audience = JSON.parse(JSON.stringify(this.selectedAudience));
          // If we get an error getting latest metrics, it'll return -1
          if (programMetrics !== -1) {
            // Loop through metric audience and attach latest metric value if it exists
            metric.audience.forEach(user => {
              console.log("metric", metric);
              console.log("program metrics", programMetrics);
              if (
                programMetrics[user.userId] &&
                programMetrics[user.userId][metric.metricId]
              ) {
                // We have a metric value!
                user.latestMetricValue =
                  programMetrics[user.userId][metric.metricId];
                // Precheck checkboxes that are complete
                if (
                  metric.dataType === "BOOLEAN" &&
                  (programMetrics[user.userId][metric.metricId].metricValue ==
                    1 ||
                    programMetrics[user.userId][metric.metricId].metricValue ==
                      true)
                ) {
                  user.metricValue =
                    programMetrics[user.userId][metric.metricId].metricValue;
                }
              }
            });
          }
        });
        this.slideKey = this.keys.programMetricsTable;
      }
    },
    startOver() {
      // After updating, this can be called from Update Another button
      // this.slidePeopleTable.users.forEach((user) => {
      //   user.display = true;
      // });
      this.clientMetrics.forEach(metric => {
        metric.display = true;
      });
      let clientId = this.clientId;
      // Temporarily store the data that comes from API calls
      const clientMetrics = this.clientMetrics;
      const slidePeopleTableUsers = this.slidePeopleTable.users;
      // const slideProgramPeopleUsers = this.slideProgramPeople.users;
      const slideProgramTablePrograms = this.slideProgramsTable.programs;
      // Reset to initial state
      Object.assign(this.$data, initialState());
      // Reassign API data
      this.clientMetrics = clientMetrics;
      this.slidePeopleTable.users = slidePeopleTableUsers;
      // this.slideProgramPeople.users = slideProgramPeopleUsers;
      this.slideProgramsTable.programs = slideProgramTablePrograms;
      this.slideKey = this.keys.programsTable;
      this.clientId = clientId;
      // if (this.userTable.options) {
      //   console.log("Setting table page!!!!");
      //   this.userTable.options.page = 1;
      // } else console.log("Not setting table page");
    },
    goBack() {
      // clear search
      this.search = null;
      this.userTable.debounceSearch = null;
      // 1 and 2 can't go back
      if (
        this.slideKey === this.keys.individualMetrics ||
        this.slideKey === this.keys.metricSelection
      ) {
        // On individual metric table, go back to people table
        // Clear metrics to update
        // this.selectedAudience = [];
        this.clientMetrics.forEach(metric => {
          metric.display = true;
        });
        this.metricsToUpdate = [];
        this.slidePeopleTable.selectedItem = null;
        this.slideKey = this.keys.peopleTable;
      } else if (this.slideKey === this.keys.programPeople) {
        // On program people table, go to programs
        // Clear selected audience, selected program, program audience
        this.selectedAudience = [];
        this.slideProgramPeople.selectAllPeople = false;
        // Set all users to display true
        // this.slidePeopleTable.users.forEach((user) => {
        //   user.display = true;
        // });
        this.selectedProgram.program = null;
        this.selectedProgram.programAudience = [];
        // clear selected program
        this.slideProgramsTable.selectedItem = null;
        this.slideKey = this.keys.programsTable;
      } else if (this.slideKey === this.keys.programMetricsTable) {
        // On program metrics, go back to slide 5 (program people)
        // Clear metrics to update array and selected audience
        // this.selectedAudience = [];
        this.metricsToUpdate = [];
        this.slideKey = this.keys.programPeople;
      }
    },
    // getUsers() {
    //   this.slidePeopleTable.loadingUsers = true;
    //   UserService.getUsers(`?lite=true&clientId=${this.clientId}`)
    //     .then(response => {
    //       console.log("Users: ", response);
    //       var usersArray = response.filter(
    //         user => user.status !== "Deleted" && user.clientId === this.clientId
    //       );
    //       usersArray.map(x => {
    //         x.displayName = x.displayName
    //           ? x.displayName
    //           : x.firstName + " " + x.lastName;
    //         x.display = true;
    //       });
    //       console.log("user array ", usersArray);
    //       this.slidePeopleTable.users = usersArray;
    //       console.log(this.slidePeopleTable.users);
    //       // this.slide2.groups = groupsArray;
    //       this.slidePeopleTable.loadingUsers = false;
    //       // if (preloadUserId) {
    //       //   var foundUser = usersArray.find((x) => x.userId == preloadUserId);
    //       //   if (foundUser) {
    //       //     this.addUserToSelected(foundUser);
    //       //   }
    //       // }
    //     })
    //     .catch(error => {
    //       this.slidePeopleTable.loadingUsers = false;
    //       console.log("Error!" + error.response);
    //     });
    // },
    getUsers(userId) {
      // Uses the group table widget
      if (this.$refs["user-table"])
        this.$refs["user-table"].getUsers(true, "getUsers - metric ui", userId);
    },
    getPrograms(preloadProgramId) {
      this.slideProgramsTable.loadingPrograms = true;
      ProgramService.getProgramsAdminV2("metric_ui", this.clientId)
        .then(response => {
          console.log("programs: ", response);
          // TODO: put filter back in!
          let programArr = response.rows
            .filter(
              item =>
                item.status != "Deleted" &&
                item.status != "Archived" &&
                item.status != "Incomplete" &&
                item.status != "Preview" &&
                // item.nonLearningMetrics === true &&
                item.clientId != 0
            )
            .map(program => {
              const tempDescription = program.description.replace(
                /<\/?[^>]+(>|$)/g,
                " "
              );
              program.descriptionNoTags = tempDescription.replace(
                /&nbsp;/g,
                " "
              );
              program.display = true;
              return program;
            });
          this.slideProgramsTable.loadingPrograms = false;
          this.slideProgramsTable.programs = programArr;

          // if a preloaded programId was passed in, then we load that
          if (preloadProgramId) {
            const foundProgram = this.slideProgramsTable.programs.find(
              x => x.programId == preloadProgramId
            );
            if (foundProgram) {
              console.log("preloaded program", this.foundProgram);
              this.selectedProgram.program = foundProgram;
              this.preloadProgram();
            }
          }
        })
        .catch(error => {
          console.log("Error!" + error.response);
          this.slideProgramsTable.loadingPrograms = false;
        });
    },
    getProgramAudience() {
      // Get people in program and set to this.slideProgramPeople.users
      this.selectedProgram.loadingUsers = true;
      const program = this.selectedProgram.program;
      let audience = [];
      // let programGroups = [];
      // const groupIds = program.audience.groups;
      // Groups is an array of ids
      const pairs = program.ProgramGroups.map(val => {
        return "id=" + encodeURIComponent(val.groupId);
      });
      console.log(pairs);
      if (!pairs.length) {
        console.log("No groups to get.");
        this.selectedProgram.loadingUsers = false;
        return;
      }
      const query_string = pairs.join("&");
      console.log("getting groups!");
      GroupService.getGroup(query_string, `?clientId=${this.clientId}`)
        .then(res => {
          console.log(res);
          res.data.groupRule.forEach(rule => {
            // Push the rule (this is the group object)
            // Add a display property
            rule.display = true;
            // programGroups.push(rule);
            // iterate through the GroupAssignments and push each user to audience
            rule.GroupAssignments.forEach(assignment => {
              // If we haven't already added them to the audience array
              if (!audience.find(x => x.userId == assignment.userId)) {
                assignment.User.display = true;
                if (
                  assignment.User.displayName === null ||
                  assignment.User.displayName === ""
                ) {
                  assignment.User.displayName =
                    assignment.User.firstName + " " + assignment.User.lastName;
                }
                audience.push(assignment.User);
              }
            });
          });
          // console.log(programGroups);
          this.selectedProgram.programAudience = audience;
          // this.slideProgramPeople.groups = programGroups;
          this.selectedProgram.loadingUsers = false;
        })
        .catch(err => {
          console.log(err);
          this.selectedProgram.loadingUsers = false;
        });
    },
    getMetricsForClient() {
      this.loadingMetrics = true;
      ProgramService.getMetrics(`?clientId=${this.clientId}`)
        .then(res => {
          console.log("got metrics for client");
          console.log(res);
          const metrics = res.filter(metric => {
            return metric.status === "Active";
          });
          metrics.forEach(metric => {
            // attach display property
            metric.display = true;
          });
          this.clientMetrics = metrics;
          console.log(this.clientMetrics);
          this.loadingMetrics = false;
        })
        .catch(error => {
          this.loadingMetrics = false;
          console.log(error);
        });
    },
    async getLatestMetrics(users, metrics) {
      this.loadingLatestMetricValues = true;
      // Takes a an array of users and an array of metrics
      // The endpoint expects an object with a users array of userIds and a metrics array of metricIds
      // Loop through both arguments and get ids
      const userIds = users.map(x => {
        return x.userId;
      });
      const metricIds = metrics.map(x => {
        return x.metricId;
      });
      console.log("user ids", userIds);
      console.log("metric ids", metricIds);
      const body = {
        users: userIds,
        metrics: metricIds
      };
      console.log("getting latest metric values!!");
      return await ProgramService.getLatestMetricValues(body)
        .then(res => {
          this.loadingLatestMetricValues = false;
          console.log("got latest metric values", res);
          if (res.programMetric) {
            return res.programMetric;
          } else {
            return -1;
          }
        })
        .catch(error => {
          this.loadingLatestMetricValues = false;
          console.log(error);
        });
    },
    formatMetricValue(dataType, value) {
      switch (dataType) {
        case "BOOLEAN":
          return value == 1 || value == "true" || value == true
            ? "Complete"
            : "Incomplete";
        case "INTEGER":
        case "DECIMAL":
        case "STRING":
          return value;
        case "DATE":
          return moment(value).format("MM-DD-YYYY");
        case "TIMESTAMP":
          return moment(value).format("DD MM YYYY hh:mm:ss", true);
      }
    },
    removeUserFromSelectedV2(index) {
      let itemArr;
      let selectedArr;
      if (this.slideKey === this.keys.peopleTable) {
        itemArr = this.slidePeopleTable.users;
        selectedArr = this.selectedAudience;
      } else if (this.slideKey === this.keys.programPeople) {
        itemArr = this.selectedProgram.programAudience;
        selectedArr = this.selectedAudience;
      }

      const user = selectedArr[index];
      console.log(user);
      selectedArr.splice(index, 1);
      if (user && user.userId && this.slideKey != this.keys.peopleTable) {
        const userIndex = itemArr.findIndex(x => {
          return x.userId === user.userId;
        });
        if (userIndex !== -1) {
          itemArr[userIndex].display = true;
        }
      }
    },
    addToSelectedV2(item, removeIfMatch = false) {
      // We only wipe if we're not on the people page because if we null the search, then we're making a new api call which could feel slow
      // So we'll let the user wipe the search
      if (this.slideKey !== this.keys.peopleTable) {
        this.search = null;
        this.userTable.debounceSearch = null;
      }
      if (this.slideKey === this.keys.peopleTable) {
        // On people table, push to selected audience
        let idx = this.selectedAudience.findIndex(x => x.userId == item.userId);
        if (idx == -1) this.selectedAudience.push(item);
        else if (removeIfMatch) this.removeUserFromSelectedV2(idx);
        // if (!this.selectedAudience.find(x => x.userId == item.userId))
        //   this.selectedAudience.push(item);
        // else if (removeIfMatch) this.removeUserFromSelectedV2()
        // Find user in user array and turn display off
        // const userIndex = this.slidePeopleTable.users.findIndex((x) => {
        //   return x.userId === item.userId;
        // });
        // if (userIndex !== -1) {
        //   this.slidePeopleTable.users[userIndex].display = false;
        // }
        if (this.displayInfoSheet === true) {
          this.displayInfoSheet = false;
        }
      } else if (this.slideKey === this.keys.programsTable) {
        // On program table, set the slideProgramsTable.selectedItem to the preview item
        this.slideProgramsTable.selectedItem = item;
        // Pop up display info sheet
        this.displayInfoSheet = true;
      } else if (this.slideKey === this.keys.programPeople) {
        // On program people table
        // If selectAllPeople is true, do nothing
        if (this.slideProgramPeople.selectAllPeople !== true) {
          this.selectedAudience.push(item);
          console.log("Selected audience ", this.selectedAudience);
          const userIndex = this.selectedProgram.programAudience.findIndex(
            x => {
              return x.userId === item.userId;
            }
          );
          console.log("found user at ", userIndex);
          console.log(this.selectedProgram.programAudience[userIndex]);
          this.selectedProgram.programAudience[userIndex].display = false;
          console.log("marked as hidden");
          if (this.displayInfoSheet === true) {
            this.displayInfoSheet = false;
          }
          console.log("we done with this fn");
        } else {
          console.log("all people selected!");
          return;
        }
      }
    },
    // To be called from the chip @close
    removeAllPeopleFromProgram() {
      this.selectedAudience = [];
      this.slideProgramPeople.selectAllPeople = false;
      // go through program audience and turn display to true
      this.selectedProgram.programAudience.forEach(user => {
        user.display = true;
      });
    },
    // To be called from the select all checkbox
    toggleSelectAllPeopleInProgram() {
      // If we haven't selected all people, select all
      if (this.slideProgramPeople.selectAllPeople === true) {
        this.selectedAudience = JSON.parse(
          JSON.stringify(this.selectedProgram.programAudience)
        );
      } else {
        this.selectedAudience = [];
        // Ensure all users display
        this.selectedProgram.programAudience.forEach(user => {
          user.display = true;
        });
      }
    },
    // Sets the appropriate selected item to slide data
    previewItem(item) {
      switch (this.slideKey) {
        case 1:
          this.slidePeopleTable.selectedItem = item;
          break;
        case 2:
          this.slideProgramsTable.selectedItem = item;
          break;
        case 5:
          this.slideProgramPeople.selectedItem = item;
          break;
      }
      this.displayInfoSheet = true;
    },
    // For metric select, clears selectedMetric.value when selected metric changes
    selectMetric() {
      if (this.slideKey === this.keys.metricSelection) {
        this.slideMetricSelection.selectedMetric.value = null;
        // Find the index of the selection panel (0) in the open expansion panel
        const zeroIndex = this.slideMetricSelection.openMetrics.indexOf(0);
        console.log("index of select panel ", zeroIndex);
        // If it's open, removing it from the array closes it
        // This prevents it from getting stuck open when we change to a metric that
        // Doesn't have previous values and it's disabled
        if (zeroIndex !== -1) {
          this.slideMetricSelection.openMetrics.splice(zeroIndex, 1);
        }
      }
    },
    // To be called from individual metric selection slide
    // Checks if metric is in metricsToUpdate, if not pushes, if so, updates or splices out if null
    metricChangeHandler(item) {
      console.log(item);
      // Check if metric is in metricsToUpdate array
      const index = this.metricsToUpdate.findIndex(
        x => x.metricId === item.metricId
      );
      if (index === -1) {
        if (
          item.metricValue === null ||
          String(item.metricValue).trim() === ""
        ) {
          // We dont want to push an empty value to the array
          return;
        } else {
          console.log("pushing to metrics to update");
          // Metric is not in metrics to update yet!
          this.metricsToUpdate.push({
            // Format like we need for the update metric endpoint
            metricId: item.metricId,
            clientId: this.clientId,
            metricValue:
              item.dataType !== "TIMESTAMP"
                ? item.metricValue
                : moment(item.metricValue).format("YYYY-MM-DD kk:mm:ss"),
            userId: this.selectedAudience[0].userId,
            date: moment()
              .utc()
              .format("YYYY-MM-DD HH:mm:ssZ")
          });
        }
      } else {
        // Metric is already in metrics to update
        if (
          item.metricValue === null ||
          String(item.metricValue).trim() === ""
        ) {
          // Metric is in metrics to update, but it's null so we splice it out
          this.metricsToUpdate.splice(index, 1);
        } else {
          console.log("updating metric, ", this.metricsToUpdate[index]);
          // Update metric with new value
          this.metricsToUpdate[index].metricValue = item.metricValue;
          console.log("updated metric ", this.metricsToUpdate[index]);
        }
      }
    },
    // To be called from program metric slide since the data structure is different
    // Metrics have an array of users, each user has a metric value
    programMetricChangeHandler(metric, user) {
      console.log(metric, user);
      // Check if metric for user is in metricsToUpdate array
      const index = this.metricsToUpdate.findIndex(
        x => x.metricId === metric.metricId && x.userId === user.userId
      );
      if (index === -1) {
        if (
          user.metricValue === null ||
          String(user.metricValue).trim() === ""
        ) {
          // We dont want to push an empty value to the array
          return;
        } else {
          console.log("pushing to metrics to update");
          // Metric is not in metrics to update yet!
          this.metricsToUpdate.push({
            // Format like we need for the update metric endpoint
            metricId: metric.metricId,
            clientId: this.clientId,
            metricValue:
              metric.dataType !== "TIMESTAMP"
                ? user.metricValue
                : moment(user.metricValue).format("YYYY-MM-DD kk:mm:ss"),
            userId: user.userId,
            date: moment()
              .utc()
              .format("YYYY-MM-DD HH:mm:ssZ")
          });
        }
      } else {
        // Metric is already in metrics to update
        if (
          user.metricValue === null ||
          String(user.metricValue).trim() === ""
        ) {
          // Metric is in metrics to update, but it's null so we splice it out
          this.metricsToUpdate.splice(index, 1);
        } else {
          console.log("updating metric, ", this.metricsToUpdate[index]);
          // Update metric with new value
          this.metricsToUpdate[index].metricValue = user.metricValue;
          console.log("updated metric ", this.metricsToUpdate[index]);
        }
      }
    },
    // To be called by all routes!
    updateMetric() {
      console.log("updating metrics!");
      this.loadingDialog = true;
      let metricsToUpdate = [];
      // If we are updating individual metrics or program metrics,
      // we copy the metrics to update array
      if (
        this.slideKey === this.keys.individualMetrics ||
        this.slideKey === this.keys.programMetricsTable
      ) {
        metricsToUpdate = JSON.parse(JSON.stringify(this.metricsToUpdate));
      } else if (this.slideKey === this.keys.metricSelection) {
        // Metric selection form
        let metricArr = [];
        // We might have metricsToUpdate and/or a selected metric
        // If they clicked update another metric they'll be here
        if (this.metricsToUpdate.length > 0) {
          // Set metricArr to the metricsToUpdate data array
          metricArr = JSON.parse(JSON.stringify(this.metricsToUpdate));
        }
        console.log("metrics to update", metricArr);
        // Look at the selected metric and see if it has a value
        // It might not have gotten pushed to metrics to update
        if (
          this.slideMetricSelection.selectedMetric.metricDefinition !== null &&
          this.slideMetricSelection.selectedMetric.value !== null
        ) {
          // Push the metric to the metricsToUpdate array
          metricArr.push(
            JSON.parse(JSON.stringify(this.slideMetricSelection.selectedMetric))
          );
        }
        console.log("final metric arr before loop", metricArr);
        // Loop over the metrics array, push formatted metric to metricsToUpdate
        for (let i = 0; i < metricArr.length; i++) {
          const metric = metricArr[i];
          // Loop over the selectedAudience array, for each user, push formatted metric to update
          for (let j = 0; j < this.selectedAudience.length; j++) {
            const user = this.selectedAudience[j];
            metricsToUpdate.push({
              clientId: this.clientId,
              metricId: metric.metricDefinition.metricId,
              userId: user.userId,
              metricValue:
                metric.metricDefinition.dataType !== "TIMESTAMP"
                  ? metric.value
                  : moment(metric.value).format("YYYY-MM-DD kk:mm:ss"),
              date: moment()
                .utc()
                .format("YYYY-MM-DD HH:mm:ssZ")
            });
          }
        }
        console.log("metric arr", metricsToUpdate);
      }
      console.log("metrics to update ", metricsToUpdate);
      // Capture start time
      const startTime = new Date();
      ProgramService.postMetricBulk(metricsToUpdate)
        .then(res => {
          console.log("posted metrics ", res);
          // Capture time since request
          let timeDiff = moment().diff(moment(startTime));
          // if time diff is longer than 1500, set it to 1500
          if (timeDiff >= 1500) {
            timeDiff = 1500;
          }
          setTimeout(() => {
            if (res.status && res.status === 200) {
              this.successMessage = true;
            } else {
              this.failureMessage = true;
            }
            this.loadingDialog = false;
            this.goToSlideV2(7);
          }, 1500 - timeDiff);
        })
        .catch(error => {
          console.log(error);
          this.loadingDialog = false;
          this.failureMessage = true;
          this.goToSlideV2(7);
        });
    },
    addAnotherMetric() {
      // Push selected metric to metricsToUpdate
      console.log(this.slideMetricSelection.selectedMetric);
      // Attach latest values array to the selected metric
      // To populate the expansion panel table
      this.slideMetricSelection.selectedMetric.latestValues = JSON.parse(
        JSON.stringify(this.usersWithLatestMetrics)
      );
      this.metricsToUpdate.push(
        JSON.parse(JSON.stringify(this.slideMetricSelection.selectedMetric))
      );
      console.log("metrics to update:", this.metricsToUpdate);
      // Remove metric from visible list
      const metricIndex = this.clientMetrics.findIndex(metric => {
        return (
          metric.metricId ===
          this.slideMetricSelection.selectedMetric.metricDefinition.metricId
        );
      });
      console.log("metric index", metricIndex);
      this.clientMetrics[metricIndex].display = false;
      console.log(this.clientMetrics[metricIndex]);
      // Find the index of the selection panel (0) in the open expansion panel
      const zeroIndex = this.slideMetricSelection.openMetrics.indexOf(0);
      console.log("index of select panel ", zeroIndex);
      // If it's open, removing it from the array closes it
      // This prevents it from getting stuck open when we change to a metric that
      // Doesn't have previous values and it's disabled
      if (zeroIndex !== -1) {
        this.slideMetricSelection.openMetrics.splice(zeroIndex, 1);
      }
      // Clear selected metric
      this.slideMetricSelection.selectedMetric = {
        metricDefinition: null,
        value: null
      };
    },
    removeMetricFromSelected(index) {
      const metricIdAtIndex = this.metricsToUpdate[index]
        ? this.metricsToUpdate[index].metricId
        : null;
      this.metricsToUpdate.splice(index, 1);
      if (metricIdAtIndex !== undefined && metricIdAtIndex !== null) {
        const metricIndex = this.clientMetrics.findIndex(metric => {
          return metric.metricId === metricIdAtIndex;
        });
        console.log("metric index", metricIndex);
        if (metricIndex != -1) this.clientMetrics[metricIndex].display = true;
      }
    },
    leavePage() {
      this.$router.push({
        name: "programadmin"
      });
    },
    formattedDate(date) {
      if (date !== null) {
        return moment(date)
          .local()
          .format("MMM DD, YYYY");
      } else {
        return null;
      }
    },
    getProgramMetrics() {
      console.log("getting metrics");
      // Programs have a program element array with metrics attached
      let program = this.selectedProgram.program;
      let metrics = [];
      console.log(program.ProgramElements);
      program.ProgramElements.forEach(el => {
        if (
          el.MetricDefinition &&
          !metrics.map(x => x.metricId).includes(el.metricId)
        ) {
          metrics.push(el.MetricDefinition);
        }
      });
      console.log("metrics array", metrics);
      let filteredMetrics = [...new Set(metrics)];
      console.log("filtered metrics", filteredMetrics);
      return filteredMetrics;
    },
    metricClickHandler() {
      console.log("click");
    },
    refreshClientId(clientId) {
      Object.assign(this.$data, initialState());
      this.clientId = clientId;
      // this.getUsersV2();
      this.getPrograms();
      this.getMetricsForClient();
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    route: {
      cache: false,
      get: function() {
        if (this.selectedProgram.program === null) {
          // People route!
          if (this.slideKey === 2) {
            // We're choosing a program
            return [
              this.keys.programsTable,
              this.keys.programPeople,
              this.keys.programMetricsTable,
              this.keys.robin
            ];
          } else if (this.selectedAudience.length > 1) {
            // Metric selection route
            return [
              this.keys.peopleTable,
              this.keys.metricSelection,
              this.keys.robin
            ];
          } else {
            // Individual metric route
            return [
              this.keys.peopleTable,
              this.keys.individualMetrics,
              this.keys.robin
            ];
          }
        } else {
          // Program route!
          return [
            this.keys.programsTable,
            this.keys.programPeople,
            this.keys.programMetricsTable,
            this.keys.robin
          ];
        }
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        // returns an array with a boolean for if each slide is valid
        const array = [];
        this.route.forEach(page => {
          // 1 - PeopleTable - at least one person is selected
          // 2 - ProgramsTable - No validation, must select a program
          // 3 - IndividualMetrics - Input change is true, metrics to update is greater than 0
          // 4 - MetricSelection - Metrics to update length is greater than 0 or selected metric has a value
          // 5 - ProgramPeople - selected audience length is greater than 0
          // 6 - ProgramMetricsTable - Input change is true
          // 7 - Robin
          if (
            page === this.keys.peopleTable &&
            this.selectedAudience.length > 0
          ) {
            array.push(true);
          } else if (page === this.keys.programsTable) {
            array.push(true);
          } else if (
            page === this.keys.individualMetrics &&
            this.metricsToUpdate.length > 0
          ) {
            array.push(true);
          } else if (
            page === this.keys.metricSelection &&
            (this.metricsToUpdate.length > 0 ||
              (this.slideMetricSelection.selectedMetric.value !== null &&
                String(
                  this.slideMetricSelection.selectedMetric.value
                ).trim() !== ""))
          ) {
            array.push(true);
          } else if (
            page === this.keys.programPeople &&
            this.selectedAudience.length > 0
          ) {
            array.push(true);
          } else if (
            page === this.keys.programMetricsTable &&
            this.metricsToUpdate.length > 0
          ) {
            array.push(true);
          } else if (page === this.keys.robin) {
            array.push(true);
          } else {
            array.push(false);
          }
        });
        return array;
      }
    },
    disableContinueButtonV2: {
      cache: false,
      get: function() {
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    visibleAudience: {
      cache: false,
      get: function() {
        // if (this.slideKey === this.keys.peopleTable) {
        //   // People table
        //   var startingIndex = this.userTable.options.page - 1 || 0;
        //   let itemsPerPage =
        //     (this.userTable.options && this.userTable.options.itemsPerPage) ||
        //     this.userTable.itemsPerPage;
        //   return this.slidePeopleTable.users
        //     .slice()
        //     .slice(
        //       startingIndex * itemsPerPage,
        //       startingIndex * itemsPerPage + itemsPerPage
        //     )
        //     .filter(x => x !== undefined);
        // } else
        if (this.slideKey === this.keys.programsTable) {
          // Program table
          return this.slideProgramsTable.programs;
        } else if (this.slideKey === this.keys.individualMetrics) {
          // Individual metric table
          return this.slideIndividualMetrics.metrics;
        } else if (
          this.slideKey === this.keys.metricSelection &&
          this.loadingMetrics === false
        ) {
          // Metrics for multiple people, only metrics that have display === true
          return this.clientMetrics.filter(x => {
            return x.display === true;
          });
          // return this.clientMetrics;
        } else if (this.slideKey === this.keys.programPeople) {
          // People in program
          console.log(
            "Refiltering visible audience for slide 5 ",
            this.selectedProgram.programAudience.filter(x => x.display == true)
              .length
          );
          console.log(this.computedItemKey);
          console.log();
          return this.selectedProgram.programAudience.filter(
            x => x.display == true
          );
        } else if (this.slideKey === this.keys.programMetricsTable) {
          return this.selectedProgram.metrics;
        } else {
          // TODO: The rest of the slides
          return null;
        }
        // }
      }
    },
    usersWithLatestMetrics: {
      cache: false,
      get: function() {
        // We only need this array for slide 4
        if (this.slideKey !== 4) {
          return null;
        } else if (
          this.slideMetricSelection.selectedMetric.metricDefinition !== null
        ) {
          // Filter the visible audience for users with latestMetrics array
          // AND a latest metric object for the selectedMetric
          return this.selectedAudience.filter(user => {
            return (
              user.latestMetrics &&
              user.latestMetrics[
                this.slideMetricSelection.selectedMetric.metricDefinition
                  .metricId
              ]
            );
          });
        } else {
          return null;
        }
      }
    },
    audienceHeaders() {
      let headers;
      const peopleHeaders = [
        {
          align: "start",
          sortable: true,
          value: "displayName",
          width: "90%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessEmail",
          width: "0%"
        },
        {
          align: " d-none",
          sortable: true,
          value: "businessPhone",
          width: "0%"
        },
        {
          value: "info",
          width: "10%",
          align: "end",
          sortable: false
        }
      ];

      const programHeaders = [
        {
          align: "start",
          sortable: true,
          value: "displayName"
        },
        {
          align: "start",
          sortable: true,
          value: "numUsers",
          width: "50%"
        },

        {
          value: "info",
          width: "100px",
          align: "end",
          sortable: false
        }
      ];

      const metricHeaders = [
        {
          align: "start",
          sortable: true,
          value: "name",
          text: "Metric"
        },
        {
          align: "start",
          sortable: false,
          value: "description"
        }
      ];

      const individualMetricHeaders = [
        {
          align: "d-none",
          value: "metricValue",
          sortable: false,
          text: "Value"
        },
        {
          align: "start",
          value: "metricName",
          sortable: true,
          text: "Metric Name"
        },
        {
          align: "start",
          value: "latestMetricValue",
          text: "Latest Value"
        }
      ];

      const individualMetricHeadersMobile = [
        {
          align: "start",
          value: "metricName",
          sortable: true,
          text: "Metric Name"
        },
        {
          align: "d-none",
          value: "metricValue",
          sortable: false,
          text: "Value"
        }
      ];

      if (
        this.slideKey === this.keys.peopleTable ||
        this.slideKey === this.keys.programPeople
      ) {
        // People table
        headers = peopleHeaders;
      } else if (this.slideKey === this.keys.programsTable) {
        // Program table
        headers = programHeaders;
      } else if (
        this.slideKey === this.keys.individualMetrics &&
        this.$vuetify.breakpoint.name !== "xs"
      ) {
        headers = individualMetricHeaders;
      } else if (
        this.slideKey === this.keys.individualMetrics &&
        this.$vuetify.breakpoint.name === "xs"
      ) {
        headers = individualMetricHeadersMobile;
      } else {
        // TODO: The rest of the slides!
        // Metrics
        headers = metricHeaders;
      }
      return headers;
    },
    computedLoadingTable: {
      cache: false,
      get: function() {
        let verdict;
        if (this.slideKey === this.keys.peopleTable) {
          // People or programs table
          verdict = this.slidePeopleTable.loadingUsers;
        } else if (this.slideKey === this.keys.programsTable) {
          verdict = this.slideProgramsTable.loadingPrograms;
        } else if (this.slideKey === this.keys.individualMetrics) {
          verdict = this.loadingMetrics;
        } else if (this.slideKey === this.keys.metricSelection) {
          verdict = this.loadingMetrics;
        } else if (this.slideKey === this.keys.programPeople) {
          verdict = this.selectedProgram.loadingUsers;
        } else if (this.slideKey === this.keys.programMetricsTable) {
          verdict = this.slideProgramMetricsTable.loadingMetrics;
        }
        return verdict;
      }
    },
    computedItemKey() {
      let key;
      switch (this.slideKey) {
        case 1: // People table
        case 5: // People in program
          key = "userId";
          break;
        case 2: // Programs table
          key = "programId";
          break;
        case 3: // Individual metrics
        case 4: // Multiple people metrics
        case 6: // Program metrics
          key = "metricId";
          break;
      }
      console.log("Current slide key ", this.slideKey);
      console.log("New item key ", key);
      return key;
    },
    computedPreviewItem() {
      let item = null;
      if (this.slideKey === this.keys.peopleTable) {
        item = this.slidePeopleTable.selectedItem;
      } else if (this.slideKey === this.keys.programsTable) {
        item = this.slideProgramsTable.selectedItem;
      } else if (this.slideKey === this.keys.programPeople) {
        item = this.slideProgramPeople.selectedItem;
      }
      return item;
    }
  },

  watch: {
    slideKey: function() {
      console.log("slide key change ", this.slideKey);
    },
    "userTable.debounceSearch": debounce(function(newVal) {
      this.search = newVal;
      // if (this.slideKey == this.keys.peopleTable) this.getUsersV2(true);
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}
.border-radius {
  border-radius: 10px;
}
.header-text {
  font-size: x-large;
}
.people-group-button-oval {
  padding: 5px 5px;
  border-radius: 40px;
  width: 180px;
  background-color: var(--v-lightGrey-base);
}
.audience-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 105px;
  width: 105px;
}

.text-overflow-clip {
  text-overflow: ellipsis;
}

.metric-field {
  width: 70%;
}

.robin-animation >>> .robin {
  justify-content: center;
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 16px 0px;
  min-width: 235px;
  /* color: rgba(0, 0, 0, 0.38); */
}
.metric-name {
  width: 40%;
}
.metric-description {
  width: 60%;
}
.full-width {
  box-sizing: border-box;
  width: 100%;
}
.disabled-data-table >>> tr {
  color: grey;
  cursor: default;
}
.disabled-data-table >>> tr:hover {
  background-color: #fff !important;
}
.outlined {
  border: 1px solid;
  /* border-left: 4px; */
  border-width: 1px 1px 0px 1px;
  border-color: var(--v-grey-base);
}
.outlined-bottom {
  border-width: 1px 1px 1px 1px;
}
#currentValues {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
}
#currentValues >>> div {
  margin-left: 2px;
  margin-right: auto;
}
.cursor-default {
  cursor: default;
}
/* We stop the click from bubbling up and opening the panel, 
but then it stays grey so turn it opaque  */
.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before {
  opacity: 0;
}
/* Manually aligning and centering the metric select's expand arrow */
.expand-arrow {
  position: absolute !important;
  top: 40px;
  right: 24px;
}
@media only screen and (max-width: 1264px) {
  .metric-field {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) {
  .metric-field {
    width: 100%;
  }
  .theme--light.v-data-table >>> .v-data-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .latest-metric-table >>> .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .program-metric-table >>> .v-expansion-panel-content__wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

<template>
  <div class="full-width">
    <v-toolbar
      dark
      color="brandCyan"
      elevation="0"
      rounded="0"
      v-if="screen == 'home'"
    >
      <v-toolbar-title>Add funds to your account </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="reset(false)">
          Close
          <v-icon class="ml-2 mb-1">mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div class="full-width px-3" v-if="screen == 'home'">
      <v-card
        v-if="displayBudgetTransfer && visibleBudgets.length > 0"
        width="100%"
        class="my-3 py-3 px-2 funding-card"
        :ripple="false"
        elevation="0"
        @click="screen = 'budget-transfer'"
      >
        <v-row no-gutters class="" justify="start" align="start">
          <v-col cols="2" sm="1" md="1" lg="1" xl="1">
            <v-icon class="mx-2 border-radius wallet-icon brand-cyan-background"
              >mdi-swap-horizontal</v-icon
            >
          </v-col>
          <v-col
            cols="10"
            sm="11"
            md="11"
            lg="11"
            xl="11"
            class="text-left px-3"
          >
            <p class="mt-1 mb-2">
              <strong>Transfer from a different budget</strong>
            </p>
            Instant <br />No fee</v-col
          >
        </v-row>
        <!-- <v-row no-gutters class="d-flex full-width align-center">
          <v-icon class="mx-2 border-radius wallet-icon brand-cyan-background"
            >mdi-swap-horizontal</v-icon
          >
          <strong class="ml-1">Transfer from a different budget</strong>
        </v-row>
        <v-row class="text-left pl-3">
          <v-col sm="12" md="12"> No fee </v-col>
        </v-row> -->
      </v-card>
      <h4
        v-if="
          computedFundingLimit &&
            (!computedFundingLimit.canFundMore ||
              computedFundingLimit.cooldownActive) &&
            destinationBudget &&
            destinationBudget.awardId == 1
        "
        class="text-left ml-2"
        :class="{ 'mt-4': !(displayBudgetTransfer && visibleBudgets.length) }"
      >
        <v-icon color="error" class="mr-2">mdi-alert</v-icon> Why can't I fund
        more right now?
      </h4>
      <p
        class="mt-2 ml-2 text-left"
        v-if="
          computedFundingLimit &&
            computedFundingLimit.cooldownActive &&
            destinationBudget &&
            destinationBudget.awardId == 1
        "
      >
        The maximum number of funding attempts in a short timespan has been
        reached. Please try again
        {{
          computedFundingLimit.cooldownExpirationLocal
            ? `at any point after
        ${computedFundingLimit.cooldownExpirationLocal}`
            : "later"
        }}. If you have questions, please contact us at
        <a class="text-decoration-none" href="mailto:help@wewhistle.com"
          >help@wewhistle.com</a
        >.
      </p>
      <p
        class="mt-2 ml-2 text-left"
        v-else-if="
          computedFundingLimit &&
            !computedFundingLimit.canFundMore &&
            destinationBudget &&
            destinationBudget.awardId == 1
        "
      >
        Whistle's banking partners require a business verification process to be
        completed before adding more funds to your budgets. This process can be
        completed on the
        <span
          @click="loadKYB"
          :class="{
            'primary--text': canCompleteKYB,
            'cursor-pointer': canCompleteKYB
          }"
          >Payment Admin page</span
        >
        by a Whistle Finance Admin
      </p>
      <v-card
        width="100%"
        class="my-3 py-3 px-2 funding-card"
        @click="screen = 'checkout'"
        :ripple="false"
        elevation="0"
        :disabled="
          computedFundingLimit &&
            (!computedFundingLimit.canFundMore ||
              computedFundingLimit.cooldownActive)
        "
        v-if="destinationBudget && destinationBudget.awardId == 1"
      >
        <v-row no-gutters class="" justify="start" align="start">
          <v-col cols="2" sm="1" md="1" lg="1" xl="1">
            <v-icon class="mx-2 border-radius wallet-icon brand-cyan-background"
              >mdi-credit-card</v-icon
            >
          </v-col>
          <v-col
            cols="10"
            sm="11"
            md="11"
            lg="11"
            xl="11"
            class="text-left px-3"
          >
            <p class="mt-1 mb-2">
              <strong>Credit card transfer</strong>
            </p>
            Instant <br />2.9% + 30 cent fee</v-col
          >
        </v-row>
        <!-- <v-row no-gutters class="d-flex full-width align-center">
          <v-icon class="mx-2 border-radius wallet-icon brand-cyan-background"
            >mdi-credit-card</v-icon
          >
          <strong class="ml-1">Credit Card Transfer</strong>
        </v-row>
        <v-row class="text-left pl-3">
          <v-col sm="12" md="12"> Instant <br />2.9% + 30 cent fee </v-col>
        </v-row> -->
      </v-card>

      <v-card
        width="100%"
        class="my-3 py-3 px-2 funding-card"
        @click="screen = 'ach'"
        :ripple="false"
        elevation="0"
        :disabled="
          computedFundingLimit &&
            (!computedFundingLimit.canFundMore ||
              computedFundingLimit.cooldownActive)
        "
        v-if="destinationBudget && destinationBudget.awardId == 1"
      >
        <v-row no-gutters class="" justify="start" align="start">
          <v-col cols="2" sm="1" md="1" lg="1" xl="1">
            <v-icon class="mx-2 border-radius wallet-icon brand-cyan-background"
              >mdi-bank</v-icon
            >
          </v-col>
          <v-col
            cols="10"
            sm="11"
            md="11"
            lg="11"
            xl="11"
            class="text-left px-3"
          >
            <p class="mt-1 mb-2">
              <strong>ACH bank transfer</strong>
            </p>
            3 to 5 business days <br />No fee</v-col
          >
        </v-row>
        <!-- <v-row class="text-left pl-3">
          <v-col sm="12" md="12"> 3 to 5 business days <br />No fee </v-col>
        </v-row> -->
      </v-card>
      <!-- <v-card width="100%" class="my-3 py-3 px-2">
        <v-row no-gutters class="d-flex full-width align-center">
          <v-icon class="mx-2 border-radius wallet-icon wire-background"
            >mdi-swap-horizontal</v-icon
          >
          <strong class="ml-1">Bank Wire Transfer</strong>
        </v-row>
        <v-row class="text-left pl-3">
          <v-col sm="12" md="12">
            No fee (your bank may charge their own fee)
          </v-col>
        </v-row>
      </v-card> -->
      <v-card
        v-if="
          destinationBudget &&
            ((userProfile.clientId == 1 &&
              permissions.includes('wallets:manualfund:budget')) ||
              destinationBudget.awardId != 1)
        "
        width="100%"
        class="my-3 py-3 px-2 white-card-background funding-card"
        @click="showBudgetFundMenu = true"
        :ripple="false"
        elevation="0"
      >
        <!-- <v-row no-gutters class="d-flex full-width align-center">
          <v-icon class="mx-2 border-radius wallet-icon brand-cyan-background"
            >mdi-cash-refund</v-icon
          >
          <strong class="ml-1">Manually fund budget</strong>
        </v-row>
        <v-row class="text-left pl-3">
          <v-col sm="12" md="12">
            Manually load funds into
            {{
              destinationBudget.awardId == 1
                ? "a budget."
                : "a non-cash budget."
            }}
          </v-col>
        </v-row> -->
        <v-row no-gutters class="" justify="start" align="start">
          <v-col cols="2" sm="1" md="1" lg="1" xl="1">
            <v-icon class="mx-2 border-radius wallet-icon brand-cyan-background"
              >mdi-cash-refund</v-icon
            >
          </v-col>
          <v-col
            cols="10"
            sm="11"
            md="11"
            lg="11"
            xl="11"
            class="text-left px-3"
          >
            <p class="mt-1 mb-2">
              <strong
                >Manually fund
                {{
                  destinationBudget.awardId == 1 ? "budget" : "non-cash budget"
                }}</strong
              >
            </p>
            Instant <br />No fee</v-col
          >
        </v-row>
        <v-row class="text-left pl-3" v-if="showBudgetFundMenu">
          <v-col sm="12" md="12">
            <v-form v-model="fundForm" onSubmit="return false;">
              <v-text-field
                :label="
                  destinationBudget.awardId == 1
                    ? 'Amount to transfer (in $)'
                    : 'Amount to transfer'
                "
                v-model="amount"
                type="number"
                color="brandCyan"
                outlined
                class="mt-6"
                @wheel="$event.target.blur()"
                @keyup.enter="fundBudget"
                :rules="[
                  v => !!v || 'Amount is required',
                  v =>
                    !!(v && parseFloat(v) >= 0) ||
                    (destinationBudget.awardId == 1
                      ? 'Amount should be greater than $0'
                      : 'Amount should be greater than 0'),
                  v =>
                    !!(
                      v &&
                      (!v.toString().includes('.') ||
                        (destinationBudget.awardId == 1 &&
                          v.toString().includes('.') &&
                          v.substring(v.indexOf('.') + 1).length < 3))
                    ) ||
                    (destinationBudget.awardId == 1
                      ? `Payment amount shouldn't be longer than 2 decimals.`
                      : 'Payment amount should be a whole number'),
                  v =>
                    !!(v && parseFloat(v) <= 999999.99) ||
                    'Amount should be smaller'
                ]"
              />
            </v-form>
            <div class="d-flex justify-center">
              <v-btn
                color="brandCyan"
                class="mb-5 mt-6 white--text"
                depressed
                width="140"
                :disabled="!fundForm || budgetFundingLoading"
                :loading="budgetFundingLoading"
                @click="fundBudget"
                >Fund</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <StripeManageACH
      v-else-if="screen == 'ach'"
      @reset="
        if ($event == 'reset') reset(true);
        else screen = 'home';
      "
      @load-budget-activity="loadBudgetActivity"
      @get-budgets="getBudgets"
      @get-funding-limit="refreshFundingLimit"
      :budget="destinationBudget"
      :preloadMenu="preloadMenu"
      :fundingLimit="computedFundingLimit"
      :canCompleteKYB="canCompleteKYB"
      :source="source"
    />

    <StripeManageCC
      v-else-if="screen == 'checkout'"
      @reset="
        if ($event == 'reset') reset();
        else screen = 'home';
      "
      @get-budgets="getBudgets"
      @get-funding-limit="refreshFundingLimit"
      :budget="destinationBudget"
      :fundingLimit="computedFundingLimit"
      :canCompleteKYB="canCompleteKYB"
      :source="source"
      :displayHeaders="true"
    />
    <BudgetTransfer
      v-else-if="screen == 'budget-transfer'"
      @reset="
        if (preloadMenu && preloadMenu == 'budget-transfer') {
          reset();
        } else if ($event == 'reset') {
          reset();
        } else {
          screen = 'home';
        }
      "
      :preloadDestinationBudget="destinationBudget"
      :preloadSourceBudget="sourceBudget"
      :preloadAmount="preloadAmount"
      :preloadBudgetActivityId="preloadBudgetActivityId"
      :budgets="visibleBudgets"
      :requestFunds="requestFunds"
    />
  </div>
</template>

<script>
import WalletService from "@/services/WalletService.js";
import BudgetService from "@/services/BudgetService.js";

import StripeManageACH from "@/components/stripe/StripeManageACH.vue";
import StripeManageCC from "@/components/stripe/StripeManageCC.vue";
import BudgetTransfer from "@/components/BudgetTransfer.vue";

import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "StripePaymentMethodsManager",
  props: {
    sourceBudget: Object,
    destinationBudget: Object,
    budgets: Array,
    preloadMenu: String,
    preloadAmount: Number,
    preloadBudgetActivityId: Number,
    clientId: Number,
    requestFunds: Boolean,
    fundingLimit: Object,
    source: String,
    displayBudgetTransfer: {
      type: Boolean,
      default: true
    }
  },
  components: {
    // MarqetaBusinessCreation,
    StripeManageACH,
    StripeManageCC,
    BudgetTransfer
  },
  data() {
    return {
      screen: "home",
      budgetFundingLoading: false,
      showBudgetFundMenu: false,
      amount: null,
      fundForm: false,
      fundingArray: []
    };
  },
  created() {
    if (this.preloadMenu) {
      if (this.preloadMenu == "ACH") this.screen = "ach";
      else if (this.preloadMenu == "budget-transfer")
        this.screen = "budget-transfer";
    }
    if (!this.fundingLimit) {
      this.getBudgetFundingStatuses();
    }
  },
  beforeDestroy() {},
  methods: {
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    reset(success = false) {
      this.$emit("reset", success);
    },
    getBudgets() {
      console.log("Getting budgets (payment method manager)");
      this.$emit("get-budgets");
    },
    fundBudget() {
      if (!this.fundForm || this.budgetFundingLoading)
        return console.log("Canceling enter input");
      this.budgetFundingLoading = true;

      var amount = this.amount;
      WalletService.fundBudget(
        this.destinationBudget.budgetId,
        amount,
        this.source
      )
        .then(budget => {
          console.log("Funding response ", budget);
          this.budgetFundingLoading = false;
          this.$emit("get-budgets");
          this.$emit("reset");
        })
        .catch(error => {
          this.budgetFundingLoading = false;
          console.log("Error funding budget! ", error);
        });
    },
    loadBudgetActivity() {
      this.$emit("load-budget-activity");
    },
    refreshFundingLimit() {
      if (this.fundingLimit) this.$emit("get-funding-limit");
      else this.getBudgetFundingStatuses(true);
    },
    getBudgetFundingStatuses() {
      // this is used to get the client's balance on how much they have funded if they're using Whistle's KYB status
      // So we can cap them at 1,000
      this.loadingBudgetTable = true;
      BudgetService.getBudgetFundingStatuses(this.magicLinkToken)
        .then(response => {
          console.log("KYB funding response ", response);

          this.fundingArray = response.map(x => {
            if (x.cooldownExpiration)
              x.cooldownExpirationLocal = moment(x.cooldownExpiration)
                .local()
                .format("MMM Do h:mm a");
            return x;
          });
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    loadKYB() {
      if (this.canCompleteKYB)
        this.$router.push({
          name: "paymentadmin",
          params: {
            preload: "kyb",
            previousRoute: this.$route.name
          }
        });
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "marqeta",
      "walletMenuScreen",
      "displayWalletMenu",
      "magicLinkToken"
    ]),
    walletScreen: {
      get: function() {
        return this.walletMenuScreen;
      },
      set: function(newValue) {
        this.$store.dispatch("setWalletMenuScreen", newValue);
      }
    },
    showPlaidWidget() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      return true;
    },
    visibleBudgets() {
      return this.budgets.filter(
        x =>
          x.clientId ==
          (this.clientId ? this.clientId : this.userProfile.clientId)
      );
    },
    computedFundingLimit() {
      if (this.fundingLimit) return this.fundingLimit;

      return this.fundingArray.find(
        x =>
          x.clientId ==
          (this.clientId ? this.clientId : this.userProfile.clientId)
      );
    },
    canCompleteKYB() {
      return (
        this.permissions.includes("vue:read:paymentadmin") &&
        this.permissions.includes("marqeta:create:business") &&
        !this.marqeta.processingKYB &&
        !this.marqeta.completedKYB
      );
    }
  },
  watch: {}
};
</script>

<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.budget-transfer-background {
  background: #75d5e4;
}
.wallet-icon {
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}
.error-background {
  background: var(--v-error-base);
}
.white-background {
  background: white;
  background-color: white;
  transform: scale(2);
}
.per-diem-alert {
  border-radius: 50px;
  width: 31px;
  height: 29px;
}

/* .white-card-background {
  background: white !important;
} */

.white-card-background.v-card--link:before {
  background: none;
}

.funding-card {
  border: 1px lightgrey solid;
}

/* .white-card-background .v-card--link:focus::before {
  opacity: 0;
} */
</style>

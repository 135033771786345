import axios from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_REPORT_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-gcp-token"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-gcp-token" : "/gcp-token-service"
    }`;

class ReportService {
  getReports() {
    return axios
      .get(baseUrl + "/reports")
      .then(response => {
        return response.data.report;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createReport(report) {
    return axios
      .post(baseUrl + "/initReport", report)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getToken(
    reportId,
    userId,
    parameterColumn = null,
    parameterValue = null,
    Client
  ) {
    if (parameterColumn && parameterValue) {
      var thisParam = {
        name: parameterColumn,
        value: parameterValue
      };
    } else {
      thisParam = {};
    }

    var request = {
      clientId: Client.clientId,
      clientName: Client.clientName,
      systemTenantId: Client.systemTenantId,
      requestingPlatform: "Whistle App",
      reportId: reportId,
      systemRole: Client.systemTenantId,
      userId: userId,
      params: [thisParam]
    };

    return axios
      .post(baseUrl + "/getToken", request)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error! " + error);
        return error;
      });
  }

  getAllTokens(userId, params, Client) {
    // if (parameterColumn && parameterValue) {
    //   var thisParam = {
    //     name: parameterColumn,
    //     value: parameterValue,
    //   };
    // } else {
    //   thisParam = {};
    // }

    var request = {
      clientId: Client.clientId,
      clientName: Client.clientName,
      systemTenantId: Client.systemTenantId,
      requestingPlatform: "Whistle App",
      systemRole: Client.systemTenantId,
      userId: userId,
      params: params
    };

    return axios
      .post(baseUrl + "/getAllTokens", request)
      .then(response => {
        console.log(response.data);
        return response.data.data.tokens;
      })
      .catch(error => {
        console.log("Error!" + error);
        return error;
      });
  }
}

export default new ReportService();

import axios from "../axios";

const baseUrl =
  process.env.VUE_APP_GKE == "2"
    ? process.env.VUE_APP_WHISTLE_API.replace(
        "MICROSERVICE",
        "integration-slack-service"
      )
    : process.env.VUE_APP_GKE
    ? `${process.env.VUE_APP_WHISTLE_API}/integration-slack-service`
    : process.env.VUE_APP_SLACK_URL;

class SlackService {
  storeSlackWorkspaceAssociation(correlationId) {
    console.log(
      `${baseUrl}/correlate/workspace?correlationId=${correlationId}`
    );
    return axios
      .post(`${baseUrl}/correlate/workspace?correlationId=${correlationId}`)
      .then(response => {
        console.log("Slack association response ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error posting slack association ", error);
        return error;
      });
  }
  storeSlackUserAssociation(correlationId) {
    console.log(`${baseUrl}/correlate/user?correlationId=${correlationId}`);
    return axios
      .post(`${baseUrl}/correlate/user?correlationId=${correlationId}`)
      .then(response => {
        console.log("Slack association response ", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error posting slack association ", error);
        throw error;
      });
  }
}

export default new SlackService();

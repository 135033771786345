/**
 * 
 * @param {import('axios').AxiosResponse<T>} result 
 * @param {boolean} [asJson=true]
 * @returns 
 * @template T
 */
export default function fmtRes(result, asJson = true) {
    const data = {
      status: result.status,
      statusText: result.statusText,
      headers: result.headers,
      data: result.data,
      query: result.query,
      config: result.config
    }
    return asJson ? JSON.stringify(data, null, 4) : data;
  
  };
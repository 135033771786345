export default [
    {
        "id": "aap_isolved",
        "display_name": "AAP isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/aapIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/aapLogo.png",
        "mfa_required": false,
        "primary_color": "#0fa049",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "abacus_isolved",
        "display_name": "Abacus HCM isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/abacusPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/abacusPayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#aebd38",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "absolute_isolved",
        "display_name": "Absolute Payroll isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/absoluteIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/absoluteLogo.png",
        "mfa_required": false,
        "primary_color": "#0865a7",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "accountantsworld",
        "display_name": "AccountantsWorld",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/accountantsworldIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/accountantsworldLogo.png",
        "mfa_required": false,
        "primary_color": "#da6b36",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "accupay_isolved",
        "display_name": "Accupay isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/accupayIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/accupayLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "ace_isolved",
        "display_name": "Ace Workforce Technologies isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/aceIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/aceLogo.png",
        "mfa_required": false,
        "primary_color": "#007bff",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "adams_keegan",
        "display_name": "Adams Keegan",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/adamskeeganIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/adamskeeganLogo.png",
        "mfa_required": false,
        "primary_color": "#0a1d36",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "adp_comprehensive",
        "display_name": "ADP Comprehensive Services",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/adpcompIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/adpcompLogo.png",
        "mfa_required": false,
        "primary_color": "#ca231b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "adp_run",
        "display_name": "Run Powered by ADP",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/adpIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/adpLogo.svg",
        "mfa_required": false,
        "primary_color": "#cf261d",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "adp_teampay",
        "display_name": "Teampay by ADP Run",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/adpIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/adpLogo.svg",
        "mfa_required": false,
        "primary_color": "#cf261d",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "adp_totalsource",
        "display_name": "ADP TotalSource",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/adpTotalSourceIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/adpTotalSourceLogo.png",
        "mfa_required": false,
        "primary_color": "#1a8099",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "adp_workforce_now",
        "display_name": "ADP Workforce Now",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/adpIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/adpLogo.png",
        "mfa_required": false,
        "primary_color": "#cf261d",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "advantage_payroll_services",
        "display_name": "Advantage Payroll Services",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/advantagePayrollServicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/advantagePayrollServicesLogo.png",
        "mfa_required": false,
        "primary_color": "#a01101",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "affiliated_hr_payroll_services_evolution",
        "display_name": "Affiliated HR Payroll Services Evolution",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/affiliatedHRPayrollServicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/affiliatedHRPayrollServicesLogo.png",
        "mfa_required": false,
        "primary_color": "#068fbe",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "affiliated_hr_payroll_services_isolved",
        "display_name": "Affiliated HR Payroll Services isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/affiliatedHRPayrollServicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/affiliatedHRPayrollServicesLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "ahola",
        "display_name": "Ahola",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/aholaIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/aholaLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "alcott_hr",
        "display_name": "Alcott HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/alcottHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/alcottHRLogo.png",
        "mfa_required": false,
        "primary_color": "#86BE5B",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "alphastaff",
        "display_name": "AlphaStaff",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/alphastaffIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/alphastaffLogo.png",
        "mfa_required": false,
        "primary_color": "#ff6501",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "amplify_hr",
        "display_name": "Amplify HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/amplifyIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/amplifyLogo.png",
        "mfa_required": false,
        "primary_color": "#00406c",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "apdata",
        "display_name": "Apdata",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/apdataIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/apdataLogo.png",
        "mfa_required": false,
        "primary_color": "#dd8038",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "apex",
        "display_name": "Apex",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/apexIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/apexLogo.png",
        "mfa_required": false,
        "primary_color": "#4694F6",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "aps_payroll",
        "display_name": "APS Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/apsPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/apsPayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#248cb8",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "asset_hr",
        "display_name": "Asset HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/assetHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/assetHRLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "asure_software",
        "display_name": "Asure Software",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/asureSoftwareIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/asureSoftwareLogo.png",
        "mfa_required": false,
        "primary_color": "#068fbe",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "balance_point",
        "display_name": "Balance Point",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/balancePointIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/balancePointLogo.png",
        "mfa_required": false,
        "primary_color": "#068fbe",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "bambee",
        "display_name": "Bambee",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bambeeIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bambeeLogo.png",
        "mfa_required": false,
        "primary_color": "#4F2D78",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "bamboo_hr",
        "display_name": "BambooHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bambooHrIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bambooHrLogo.svg",
        "mfa_required": false,
        "primary_color": "#88c140",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "baron_isolved",
        "display_name": "Baron Payroll isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/baronIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/baronLogo.png",
        "mfa_required": false,
        "primary_color": "#a51d44",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "basic",
        "display_name": "BASIC",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/basicIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/basicLogo.png",
        "mfa_required": false,
        "primary_color": "#2c4356",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "bbcs",
        "display_name": "BBCS Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bbcsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bbcsLogo.png",
        "mfa_required": false,
        "primary_color": "#0155a3",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "bbsi",
        "display_name": "BBSI",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bbsiIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bbsiLogo.svg",
        "mfa_required": false,
        "primary_color": "#285276",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "bcn_services",
        "display_name": "BCN Services",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bcnservicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bcnservicesLogo.png",
        "mfa_required": false,
        "primary_color": "#081f57",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "benecare",
        "display_name": "Bene-Care",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/benecareIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/benecareLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "big_fish_employer_services",
        "display_name": "Big Fish Employer Services",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bigFishEmployerServicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bigFishEmployerServicesLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "bizchecks_payroll",
        "display_name": "Bizchecks Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bizchecksPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bizchecksPayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "bob",
        "display_name": "Bob (HiBob)",
        "products": [
            "company",
            "directory",
            "individual",
            "employment"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/bobIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/bobLogo.png",
        "mfa_required": false,
        "primary_color": "#e42c51",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "business_online_payroll",
        "display_name": "Business Online Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/businessOnlinePayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/businessOnlinePayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#686867",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "c2",
        "display_name": "C2",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/c2Icon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/c2Logo.png",
        "mfa_required": false,
        "primary_color": "#0993c5",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "ceridian_dayforce",
        "display_name": "Ceridian Dayforce",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/ceridianDayforceIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/ceridianDayforceLogo.svg",
        "mfa_required": false,
        "primary_color": "#3067bd",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "ceridian_powerpay",
        "display_name": "Ceridian Powerpay",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/ceridianPowerpayIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/ceridianPowerpayLogo.svg",
        "mfa_required": false,
        "primary_color": "#182f73",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "cezannehr",
        "display_name": "Cezanne HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/cezannehrIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/cezannehrLogo.png",
        "mfa_required": false,
        "primary_color": "#c6003b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "charlie_hr",
        "display_name": "CharlieHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/charlieHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/charlieHRLogo.png",
        "mfa_required": false,
        "primary_color": "#519ddc",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "clickup",
        "display_name": "ClickUp",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/clickupIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/clickupLogo.png",
        "mfa_required": false,
        "primary_color": "#7869e6",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "coadvantage",
        "display_name": "CoAdvantage",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/coAdvantageIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/coAdvantageLogo.png",
        "mfa_required": false,
        "primary_color": "#83b621",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "coastal_payroll",
        "display_name": "Coastal Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/coastalPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/coastalPayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "cognos_hr",
        "display_name": "Cognos HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/cognoshrIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/cognoshrLogo.png",
        "mfa_required": false,
        "primary_color": "#95d5ae",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "collage",
        "display_name": "Collage",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/collageIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/collageLogo.png",
        "mfa_required": false,
        "primary_color": "#6986ff",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "commonwealth_payroll_hr",
        "display_name": "Commonwealth Payroll & HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/commonwealthPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/commonwealthPayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "connectpay",
        "display_name": "ConnectPay",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/connectpayIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/connectpayLogo.png",
        "mfa_required": false,
        "primary_color": "#6ab244",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "cpm_isolved",
        "display_name": "CPM Employer Services isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/cpmIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/cpmLogo.png",
        "mfa_required": false,
        "primary_color": "#ad2624",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "creative_business_resources",
        "display_name": "Creative Business Resources",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/creativebusinessIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/creativebusinessLogo.png",
        "mfa_required": false,
        "primary_color": "#104A87",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "crescent_payroll_solutions",
        "display_name": "Crescent Payroll Solutions",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/crescentPayrollSolutionsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/crescentPayrollSolutionsLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "csc_paymaster",
        "display_name": "CSC Paymaster",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/cscPaymasterIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/cscPaymasterLogo.png",
        "mfa_required": false,
        "primary_color": "#273667",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "darwinbox",
        "display_name": "Darwinbox",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/darwinboxIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/darwinboxLogo.png",
        "mfa_required": false,
        "primary_color": "#3982f7",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "decent",
        "display_name": "Decent",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/decentIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/decentLogo.png",
        "mfa_required": false,
        "primary_color": "#df6563",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "deel",
        "display_name": "Deel",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/deelIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/deelLogo.svg",
        "mfa_required": false,
        "primary_color": "#274291",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "deltek",
        "display_name": "Deltek",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/deltekIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/deltekLogo.png",
        "mfa_required": false,
        "primary_color": "#0a2241",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "deluxe",
        "display_name": "Deluxe",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/deluxeIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/deluxeLogo.svg",
        "mfa_required": false,
        "primary_color": "#030f0c",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "dm_payroll_solutions",
        "display_name": "DM Payroll Solutions",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/dmpayrollsolutionsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/dmpayrollsolutionsLogo.png",
        "mfa_required": false,
        "primary_color": "#265388",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "dominion_payroll",
        "display_name": "Dominion Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/dominionPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/dominionPayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "doyle_hcm",
        "display_name": "Doyle HCM",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/doylehcmIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/doylehcmLogo.png",
        "mfa_required": false,
        "primary_color": "#528f49",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "eddyhr",
        "display_name": "Eddy",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/eddyhrIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/eddyhrLogo.png",
        "mfa_required": false,
        "primary_color": "#387ee5",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "emplicity",
        "display_name": "Emplicity",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/emplicityIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/emplicityLogo.jpeg",
        "mfa_required": false,
        "primary_color": "#618fc8",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "employdrive",
        "display_name": "Employdrive",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/employdriveIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/employdriveLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "employer_flexible",
        "display_name": "Employer Flexible",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/employerflexibleIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/employerflexibleLogo.png",
        "mfa_required": false,
        "primary_color": "#992e2f",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "empower_hr",
        "display_name": "EmPower HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/empowerhrIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/empowerhrLogo.png",
        "mfa_required": false,
        "primary_color": "#223c6a",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "engage_peo",
        "display_name": "Engage PEO",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/engagepeoIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/engagepeoLogo.svg",
        "mfa_required": false,
        "primary_color": "#f6c64d",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "esc",
        "display_name": "Employer Services Corporation (ESC)",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/escIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/escLogo.png",
        "mfa_required": false,
        "primary_color": "#23215f",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "everee",
        "display_name": "Everee",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/evereeIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/evereeLogo.png",
        "mfa_required": false,
        "primary_color": "#66c3ec",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "evolution_payroll_services",
        "display_name": "Evolution Payroll Services",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/evolutionpayrollservicesIcon.webp",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/evolutionpayrollservicesLogo.jpeg",
        "mfa_required": false,
        "primary_color": "#b9d253",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "excel_resources",
        "display_name": "Excel Resources",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/excelresourcesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/excelresourcesLogo.png",
        "mfa_required": false,
        "primary_color": "#8218bc",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "extensis_hr",
        "display_name": "ExtensisHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/extensisHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/extensisHRLogo.png",
        "mfa_required": false,
        "primary_color": "#df635c",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "flock",
        "display_name": "Flock",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/flockIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/flockLogo.svg",
        "mfa_required": false,
        "primary_color": "#2f659e",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "freshteam",
        "display_name": "Freshteam",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/freshteamIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/freshteamLogo.png",
        "mfa_required": false,
        "primary_color": "#4327ac",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "fullstack_peo",
        "display_name": "FullStack PEO",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/fullstackpeoIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/fullstackpeoLogo.png",
        "mfa_required": false,
        "primary_color": "#6bb2e0",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "gigwage",
        "display_name": "Gig Wage",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/gigwageIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/gigwageLogo.png",
        "mfa_required": false,
        "primary_color": "#7ddec2",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "gna_partners",
        "display_name": "G&A Partners",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/gnapartnersIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/gnapartnersLogo.svg",
        "mfa_required": false,
        "primary_color": "#b62936",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "goco",
        "display_name": "GoCo",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/gocoIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/gocoLogo.svg",
        "mfa_required": false,
        "primary_color": "#56a2fc",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "greenhouse",
        "display_name": "Greenhouse",
        "products": [
            "jobs",
            "candidates",
            "applications",
            "offers"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/greenhouseIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/greenhouseLogo.png",
        "mfa_required": false,
        "primary_color": "#24a47f",
        "manual": false,
        "category": "ats"
    },
    {
        "id": "greyt_hr",
        "display_name": "greytHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/greythrIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/greythrLogo.svg",
        "mfa_required": false,
        "primary_color": "#408eb7",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "gtm_payroll_services_evolution",
        "display_name": "GTM Payroll Services Evolution",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/gtmPayrollServicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/gtmPayrollServicesLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "gtm_payroll_services_isolved",
        "display_name": "GTM Payroll Services isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/gtmPayrollServicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/gtmPayrollServicesLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "gusto",
        "display_name": "Gusto",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/gustoIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/gustoLogo.svg",
        "mfa_required": true,
        "primary_color": "#f45d47",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "heartland",
        "display_name": "Heartland",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/heartlandIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/heartlandLogo.svg",
        "mfa_required": false,
        "primary_color": "#d0352f",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "helpside",
        "display_name": "Helpside",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/helpsideIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/helpsideLogo.png",
        "mfa_required": false,
        "primary_color": "#ee501e",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "highflyer_hr",
        "display_name": "Highflyer HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/highflyerHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/highflyerHRLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "homebase",
        "display_name": "Homebase",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/homebaseIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/homebaseLogo.png",
        "mfa_required": false,
        "primary_color": "#8B47A5",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "horizonpayroll",
        "display_name": "Horizon Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/horizonpayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/horizonpayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#0072F4",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "horizons",
        "display_name": "Horizons",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/horizonsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/horizonsLogo.png",
        "mfa_required": false,
        "primary_color": "#112f3c",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "hrcloud",
        "display_name": "HR Cloud",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/hrcloudIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/hrcloudLogo.png",
        "mfa_required": false,
        "primary_color": "#00bcc2",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "hro",
        "display_name": "HRO",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/hroIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/hroLogo.jpeg",
        "mfa_required": false,
        "primary_color": "#1f3160",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "hrone",
        "display_name": "HROne",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/hroneIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/hroneLogo.svg",
        "mfa_required": false,
        "primary_color": "#2b5ca0",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "hubstaff",
        "display_name": "Hubstaff",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/hubstaffIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/hubstaffLogo.svg",
        "mfa_required": false,
        "primary_color": "#0cdfa3",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "humaans",
        "display_name": "Humaans",
        "products": [
            "company",
            "directory",
            "individual",
            "employment"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/humaansIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/humaansLogo.svg",
        "mfa_required": false,
        "primary_color": "#eb615c",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "humi",
        "display_name": "Humi",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/humiIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/humiLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "infiniti_hr",
        "display_name": "INFINITI HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/infinitihrIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/infinitihrLogo.png",
        "mfa_required": false,
        "primary_color": "#29602e",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "infor",
        "display_name": "Infor",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/inforIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/inforLogo.png",
        "mfa_required": false,
        "primary_color": "#bc3431",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "insperity",
        "display_name": "Insperity",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/insperityIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/insperityLogo.svg",
        "mfa_required": true,
        "primary_color": "#439639",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "iris_hr",
        "display_name": "Iris HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/irishrIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/irishrLogo.png",
        "mfa_required": false,
        "primary_color": "#79b7e4",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "isolved",
        "display_name": "isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/isolvedIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/isolvedLogo.svg",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "justworks",
        "display_name": "Justworks",
        "products": [
            "benefits",
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/justworksIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/justworksLogo.svg",
        "mfa_required": true,
        "primary_color": "#39b6e9",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "kenjo",
        "display_name": "Kenjo",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/kenjoIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/kenjoLogo.svg",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "keystone_isolved",
        "display_name": "Keystone Payroll isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/keystoneIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/keystoneLogo.png",
        "mfa_required": false,
        "primary_color": "#53aed7",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "lever",
        "display_name": "Lever",
        "products": [
            "jobs",
            "candidates",
            "applications",
            "offers"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/leverIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/leverLogo.png",
        "mfa_required": false,
        "primary_color": "#262933",
        "manual": false,
        "category": "ats"
    },
    {
        "id": "mp",
        "display_name": "mp",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/mpIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/mpLogo.svg",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "mpay",
        "display_name": "MPAY",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/mpayIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/mpayLogo.jpeg",
        "mfa_required": false,
        "primary_color": "#0c254b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "multiplier",
        "display_name": "Multiplier",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/multiplierIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/multiplierLogo.png",
        "mfa_required": false,
        "primary_color": "#001a59",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "namely",
        "display_name": "Namely",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/namelyIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/namelyLogo.svg",
        "mfa_required": false,
        "primary_color": "#0099ff",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "natural_hr",
        "display_name": "NaturalHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/naturalHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/naturalHRLogo.png",
        "mfa_required": false,
        "primary_color": "#91b467",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "netchex",
        "display_name": "Netchex",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/netchexIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/netchexLogo.png",
        "mfa_required": false,
        "primary_color": "#3b8c66",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "newtek",
        "display_name": "Newtek",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/newtekIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/newtekLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "nextep",
        "display_name": "Nextep",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/nextepIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/nextepLogo.svg",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "nvision",
        "display_name": "nVision",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/nvisionIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/nvisionLogo.jpeg",
        "mfa_required": false,
        "primary_color": "#215DA7",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "oasis",
        "display_name": "Oasis",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/oasisIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/oasisLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "obsidianhr_isolved",
        "display_name": "Obsidian HR isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/obsidianhrIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/obsidianhrLogo.png",
        "mfa_required": false,
        "primary_color": "#162549",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "odoo",
        "display_name": "odoo",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/odooIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/odooLogo.png",
        "mfa_required": false,
        "primary_color": "#65415c",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "onesource_payroll",
        "display_name": "OneSource Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/onesourcepayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/onesourcepayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#0277a2",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "onpay",
        "display_name": "OnPay",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/onpayIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/onpayLogo.svg",
        "mfa_required": false,
        "primary_color": "#2580d8",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "opolis",
        "display_name": "Opolis",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/opolisIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/opolisLogo.png",
        "mfa_required": false,
        "primary_color": "#e86345",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "oracle_fusion",
        "display_name": "Oracle Fusion",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/oraclefusionIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/oraclefusionLogo.jpeg",
        "mfa_required": false,
        "primary_color": "#fd000e",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "oracle_peoplesoft",
        "display_name": "Oracle PeopleSoft",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/oraclePeopleSoftIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/oraclePeopleSoftLogo.png",
        "mfa_required": false,
        "primary_color": "#df3122",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "panther",
        "display_name": "Panther",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/pantherIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/pantherLogo.svg",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "paragon_payroll_isolved",
        "display_name": "Paragon Payroll isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paragonPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paragonPayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#f7ce22",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "patriot",
        "display_name": "Patriot",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/patriotIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/patriotLogo.svg",
        "mfa_required": false,
        "primary_color": "#e05d05",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "pay_usa_inc",
        "display_name": "PayUSA",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payUSAIncIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payUSAIncLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "paychex_flex",
        "display_name": "Paychex Flex",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paychexFlexIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paychexFlexLogo.svg",
        "mfa_required": false,
        "primary_color": "#004b8d",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "paycom",
        "display_name": "Paycom",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paycomIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paycomLogo.png",
        "mfa_required": true,
        "primary_color": "#006242",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "paycor",
        "display_name": "Paycor",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paycorIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paycorLogo.svg",
        "mfa_required": false,
        "primary_color": "#f57a20",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payday_workforce_solutions",
        "display_name": "Payday Workforce Solutions",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paydayWorkforceSolutionsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paydayWorkforceSolutionsLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payfit",
        "display_name": "PayFit",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payfitIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payfitLogo.svg",
        "mfa_required": false,
        "primary_color": "#1164d9",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "paylocity",
        "display_name": "Paylocity",
        "products": [
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits",
            "company"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paylocityIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paylocityLogo.svg",
        "mfa_required": true,
        "primary_color": "#ff6611",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "paynw",
        "display_name": "PayNorthwest",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paynwIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paynwLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "paypro_hcs_isolved",
        "display_name": "PayPro HCS isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payproHCSIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payproHCSLogo.png",
        "mfa_required": false,
        "primary_color": "#080c67",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payroll_network",
        "display_name": "Payroll Network",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollNetworkIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollNetworkLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payroll_office_of_america",
        "display_name": "Payroll Office of America",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollofficeofamericaIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollofficeofamericaLogo.png",
        "mfa_required": false,
        "primary_color": "#1a4375",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payroll_plus_hcm_evolution",
        "display_name": "Payroll Plus HCM Evolution",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollPlusHCMIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollPlusHCMLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payroll_plus_hcm_isolved",
        "display_name": "Payroll Plus HCM isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollPlusHCMIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollPlusHCMLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payroll_resource_group",
        "display_name": "Payroll Resource Group",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/PRGIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/PRGLogo.png",
        "mfa_required": false,
        "primary_color": "#FFBC5A",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "payroll_solutions",
        "display_name": "Payroll Solutions",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollsolutionsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/payrollsolutionsLogo.png",
        "mfa_required": false,
        "primary_color": "#3064ae",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "paytime",
        "display_name": "Paytime",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/paytimeIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/paytimeLogo.png",
        "mfa_required": false,
        "primary_color": "#9ac455",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "pcs_hcm",
        "display_name": "PCS HCM",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/pcsHCMIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/pcsHCMLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "people_lease",
        "display_name": "People Lease",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/peopleleaseIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/peopleleaseLogo.webp",
        "mfa_required": false,
        "primary_color": "#b52a44",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "peopleforce",
        "display_name": "PeopleForce",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/peopleforceIcon.avif",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/peopleforceLogo.webp",
        "mfa_required": false,
        "primary_color": "#3478bd",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "peoplehum",
        "display_name": "PeopleHum",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/peoplehumIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/peoplehumLogo.svg",
        "mfa_required": false,
        "primary_color": "#50a6d1",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "personio",
        "display_name": "Personio",
        "products": [
            "directory",
            "individual",
            "employment"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/personioIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/personioLogo.svg",
        "mfa_required": false,
        "primary_color": "#010000",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "platinum_group",
        "display_name": "Platinum Group",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/platinumGroupIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/platinumGroupLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "prestige_peo",
        "display_name": "PrestigePEO",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/prestigepeoIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/prestigepeoLogo.svg",
        "mfa_required": false,
        "primary_color": "#e3b847",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "primepay",
        "display_name": "PrimePay",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/primepayIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/primepayLogo.svg",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "prism_hr",
        "display_name": "PrismHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/prismHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/prismHRLogo.svg",
        "mfa_required": false,
        "primary_color": "#e14852",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "proliant",
        "display_name": "Proliant",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/proliantIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/proliantLogo.png",
        "mfa_required": false,
        "primary_color": "#26475e",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "propel_hr",
        "display_name": "PropelHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/propelHrIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/propelHrLogo.svg",
        "mfa_required": false,
        "primary_color": "#f8e44c",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "prosoftware",
        "display_name": "ProSoftware",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/prosoftwareIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/prosoftwareLogo.png",
        "mfa_required": false,
        "primary_color": "#d00700",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "proxus_hr",
        "display_name": "Proxus HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/proxusHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/proxusHRLogo.png",
        "mfa_required": false,
        "primary_color": "#4e1c4e",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "quality_payroll_benefits",
        "display_name": "Quality Payroll and Benefits",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/qualitypayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/qualitypayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#A62825",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "quickbooks",
        "display_name": "QuickBooks Online Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/quickbooksIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/quickbooksLogo.svg",
        "mfa_required": true,
        "primary_color": "#329d28",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "remote",
        "display_name": "Remote",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/remoteIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/remoteLogo.svg",
        "mfa_required": false,
        "primary_color": "#6b48d4",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "resourcing_edge",
        "display_name": "Resourcing Edge",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/resourcingEdgeIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/resourcingEdgeLogo.png",
        "mfa_required": false,
        "primary_color": "#df7d3a",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "rippling",
        "display_name": "Rippling",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/ripplingIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/ripplingLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "rmi_peo",
        "display_name": "RMI PEO",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/rmipeoIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/rmipeoLogo.png",
        "mfa_required": false,
        "primary_color": "#5d0f31",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "sage_hr",
        "display_name": "Sage HR",
        "products": [
            "directory",
            "individual",
            "employment",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/sageHrIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/sageHrLogo.svg",
        "mfa_required": false,
        "primary_color": "#00d639",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "sage_payroll",
        "display_name": "Sage Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/sagePayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/sagePayrollLogo.svg",
        "mfa_required": false,
        "primary_color": "#41a940",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "sapling",
        "display_name": "Sapling",
        "products": [
            "directory",
            "individual",
            "employment"
        ],
        "assisted_products": [],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/saplingIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/saplingLogo.svg",
        "mfa_required": false,
        "primary_color": "#4524cd",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "savant_hcm_evolution",
        "display_name": "Savant HCM Evolution",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/savantHCMEvolutionIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/savantHCMEvolutionLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "sequoia_one",
        "display_name": "Sequoia One",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/sequoiaIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/sequoiaLogo.png",
        "mfa_required": true,
        "primary_color": "#8bcf24",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "sheakley",
        "display_name": "Sheakley",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/sheakleyIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/sheakleyLogo.svg",
        "mfa_required": false,
        "primary_color": "#58ada4",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "simploy",
        "display_name": "Simploy",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/simployIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/simployLogo.png",
        "mfa_required": false,
        "primary_color": "#4aa35a",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "skuad",
        "display_name": "Skuad",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/skuadIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/skuadLogo.svg",
        "mfa_required": false,
        "primary_color": "#5eca8c",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "solution_services",
        "display_name": "Solution Services",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/solutionservicesIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/solutionservicesLogo.png",
        "mfa_required": false,
        "primary_color": "#4664aa",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "sourceone",
        "display_name": "SourceOne",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/sourceoneIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/sourceoneLogo.png",
        "mfa_required": false,
        "primary_color": "#c85e36",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "southeastpersonnel",
        "display_name": "Southeast Personnel Leasing",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/southeastpersonnelIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/southeastpersonnelLogo.png",
        "mfa_required": false,
        "primary_color": "#fc9912",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "square_payroll",
        "display_name": "Square Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/squareIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/squareLogo.svg",
        "mfa_required": true,
        "primary_color": "#000000",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "strategic_payroll_solutions_isolved",
        "display_name": "Strategic Payroll Solutions isolved",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/strategicPayrollSolutionsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/strategicPayrollSolutionsLogo.png",
        "mfa_required": false,
        "primary_color": "#1728b5",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "successfactors",
        "display_name": "SAP SuccessFactors",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/successfactorsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/successfactorsLogo.svg",
        "mfa_required": false,
        "primary_color": "#007db8",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "suite_people",
        "display_name": "SuitePeople (NetSuite)",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/suitePeopleIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/suitePeopleLogo.svg",
        "mfa_required": false,
        "primary_color": "#df3122",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "surepayroll",
        "display_name": "SurePayroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/surepayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/surepayrollLogo.png",
        "mfa_required": false,
        "primary_color": "#8fab45",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "sync_hr",
        "display_name": "SyncHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/syncHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/syncHRLogo.svg",
        "mfa_required": false,
        "primary_color": "#0060c7",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "teamworks",
        "display_name": "Teamworks Group",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/teamworksIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/teamworksLogo.png",
        "mfa_required": false,
        "primary_color": "#5ea9eb",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "the_payroll_company",
        "display_name": "The Payroll Company",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/thePayrollCompanyIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/thePayrollCompanyLogo.png",
        "mfa_required": false,
        "primary_color": "#000000",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "thread_hcm",
        "display_name": "Thread HCM",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/threadhcmIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/threadhcmLogo.png",
        "mfa_required": false,
        "primary_color": "#c7d45f",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "toast_payroll",
        "display_name": "Toast Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/toastPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/toastPayrollLogo.svg",
        "mfa_required": false,
        "primary_color": "#f15022",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "trakstar",
        "display_name": "Trakstar",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/trakstarIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/trakstarLogo.svg",
        "mfa_required": false,
        "primary_color": "#798ef5",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "trinet",
        "display_name": "TriNet",
        "products": [
            "benefits",
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/trinetIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/trinetLogo.svg",
        "mfa_required": true,
        "primary_color": "#f47b29",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "ukg_ready",
        "display_name": "UKG Ready",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/ukgreadyIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/ukgreadyLogo.svg",
        "mfa_required": false,
        "primary_color": "#204e4e",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "ulti_pro",
        "display_name": "UltiPro (UKG Pro)",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/ultiproIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/ultiproLogo.svg",
        "mfa_required": false,
        "primary_color": "#4fa046",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "uzio",
        "display_name": "Uzio",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/uzioIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/uzioLogo.png",
        "mfa_required": false,
        "primary_color": "#e79746",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "velocity_global",
        "display_name": "Velocity Global",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/velocityglobalIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/velocityglobalLogo.jpeg",
        "mfa_required": false,
        "primary_color": "#609dd5",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "vensure_hr",
        "display_name": "VensureHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/vensureHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/vensureHRLogo.svg",
        "mfa_required": false,
        "primary_color": "#db480a",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "venture_workforce",
        "display_name": "Venture Workforce",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/ventureworkforceIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/ventureworkforceLogo.png",
        "mfa_required": false,
        "primary_color": "#533C1F",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "vfficient",
        "display_name": "Vfficient",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/vfficientIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/vfficientLogo.png",
        "mfa_required": false,
        "primary_color": "#e16f37",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "viewpoint_hr_management_spectrum",
        "display_name": "Viewpoint HR Management Spectrum",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/viewpointHRManagementSpectrumIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/viewpointHRManagementSpectrumLogo.svg",
        "mfa_required": false,
        "primary_color": "#37a0e0",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "viewpoint_hr_management_vista",
        "display_name": "Viewpoint HR Management Vista",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/viewpointHRManagementVistaIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/viewpointHRManagementVistaLogo.svg",
        "mfa_required": false,
        "primary_color": "#37a0e0",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "viventium",
        "display_name": "Viventium",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/viventiumIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/viventiumLogo.png",
        "mfa_required": false,
        "primary_color": "#774492",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "wagepoint",
        "display_name": "Wagepoint",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/wagepointIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/wagepointLogo.svg",
        "mfa_required": false,
        "primary_color": "#4fb488",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "wave",
        "display_name": "Wave",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/waveIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/waveLogo.svg",
        "mfa_required": true,
        "primary_color": "#001b65",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "web_hr",
        "display_name": "WebHR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/webHRIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/webHRLogo.svg",
        "mfa_required": false,
        "primary_color": "#0c64ae",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "whirks",
        "display_name": "Whirks",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/whirksIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/whirksLogo.png",
        "mfa_required": false,
        "primary_color": "#fbb03b",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "workday",
        "display_name": "Workday",
        "products": [
            "benefits",
            "directory",
            "individual",
            "employment"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/workdayIcon.svg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/workdayLogo.svg",
        "mfa_required": false,
        "primary_color": "#0875e1",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "workforce_junction",
        "display_name": "Workforce Junction",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/workforcejunctionIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/workforcejunctionLogo.png",
        "mfa_required": false,
        "primary_color": "#003254",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "worklio",
        "display_name": "Worklio",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/worklioIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/worklioLogo.svg",
        "mfa_required": false,
        "primary_color": "#e39936",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "workplace_hcm",
        "display_name": "Workplace HCM",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/workplacehcmIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/workplacehcmLogo.png",
        "mfa_required": false,
        "primary_color": "#df6541",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "workzoom",
        "display_name": "Workzoom",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/workzoomIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/workzoomLogo.png",
        "mfa_required": false,
        "primary_color": "#bac865",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "wurk",
        "display_name": "wurk",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/wurkIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/wurkLogo.svg",
        "mfa_required": false,
        "primary_color": "#9978a5",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "xenium_hr",
        "display_name": "Xenium HR",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/xeniumhrIcon.jpeg",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/xeniumhrLogo.svg",
        "mfa_required": false,
        "primary_color": "#e9b742",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "xero",
        "display_name": "Xero",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/xeroIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/xeroLogo.svg",
        "mfa_required": false,
        "primary_color": "#028dde",
        "manual": true,
        "category": "hris"
    },
    {
        "id": "zenefits",
        "display_name": "Zenefits",
        "products": [
            "benefits",
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement"
        ],
        "assisted_products": [
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/zenefitsIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/zenefitsLogo.svg",
        "mfa_required": true,
        "primary_color": "#f55442",
        "manual": false,
        "category": "hris"
    },
    {
        "id": "zoho_payroll",
        "display_name": "Zoho Payroll",
        "products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "assisted_products": [
            "company",
            "directory",
            "individual",
            "employment",
            "payment",
            "pay_statement",
            "benefits"
        ],
        "icon": "https://finch-logos.s3.us-west-2.amazonaws.com/zohoPayrollIcon.png",
        "logo": "https://finch-logos.s3.us-west-2.amazonaws.com/zohoPayrollLogo.svg",
        "mfa_required": false,
        "primary_color": "#f55d49",
        "manual": true,
        "category": "hris"
    }
]
import { main as axios } from "../axios";

// axios.interceptors.request.use(
//   async (config) => {
//     let token = await store.dispatch("isLoggedIn");
//     if (token) {
//       config.headers.common["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
const baseUrl =
  // "http://127.0.0.1:9010";
  process.env.VUE_APP_DEV_LOCAL
    ? process.env.VUE_APP_PROCORE_URL
    : process.env.VUE_APP_GKE == "2"
    ? process.env.VUE_APP_WHISTLE_API.replace(
        "MICROSERVICE",
        "microservice-user"
      )
    : `${process.env.VUE_APP_WHISTLE_API}/integration-procore`;

class ProcoreService {
  getMilestones(params) {
    return axios
      .get(`${baseUrl}/methods/events/get-milestones`, { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error getting procore milestones ",
          error.response || error
        );
        throw error.response ? error.response.data || error.response : error;
      });
  } //getMilestones

  createEvent(section, data) {
    return axios
      .post(`${baseUrl}/procore/events/onboarding/${section}`, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error creating procore event ", error.response || error);
        throw error.response ? error.response.data || error.response : error;
      });
  } //getMilestones
}

export default new ProcoreService();

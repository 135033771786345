<template>
  <v-card elevation="0" rounded="0">
    <v-toolbar dark flat color="brandCyan" rounded="0" width="100%">
      <div>
        <v-toolbar-title>Add a {{ tabLanguage.toLowerCase() }}</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close">
          <span class="mt-1">Close</span>
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row no-gutters justify="center" full-width no-wrap class="mb-2">
      <v-col cols="10" class="d-flex flex-column text-left mx-0 my-0">
        <p class="mb-0 mt-10 mediumGrey--text">
          Reward Type
        </p>
        <div class="d-flex justify-space-between align-center">
          <div class="tab-group-container text-center mt-2">
            <v-btn
              depressed
              @click="changeTab(0)"
              :color="activeTab == 0 ? 'white' : 'lightGrey'"
              class="px-5 mr-1 tab-button"
              :class="{
                'active-tab-button': activeTab == 0
              }"
              label
            >
              Badge
            </v-btn>
            <v-btn
              depressed
              @click="changeTab(1)"
              :color="activeTab == 1 ? 'white' : 'lightGrey'"
              class="px-5 ml-1 tab-button"
              :class="{
                'active-tab-button': activeTab == 1
              }"
              label
            >
              Payment
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-btn
              icon
              color="primary"
              @click="dialog.search = !dialog.search"
              v-if="activeTab == 0"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
            <v-menu
              v-if="activeTab == 0"
              v-model="sort.menu"
              offset-y
              left
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex">
                  <v-btn
                    text
                    color="primary"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    >Sort</v-btn
                  >
                </div>
              </template>
              <v-list>
                <v-list-item
                  v-for="option in sort.options"
                  :key="option.id"
                  @click="updateSortBy(option)"
                  :class="{
                    'light-grey-background': option.values.includes(sort.value)
                  }"
                >
                  <v-list-item-content class="align-text-left">
                    <v-list-item-title>{{ option.label }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon
                    :class="{ hidden: !option.values.includes(sort.value) }"
                    ><v-icon>{{
                      sort.value && sort.value.includes("asc")
                        ? "mdi-arrow-up"
                        : "mdi-arrow-down"
                    }}</v-icon></v-list-item-icon
                  >
                  <!-- </template> -->
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="mt-6 mx-12 text-left">
      <div v-if="activeTab == 0">
        <div
          class="d-flex justify-center"
          v-if="loadingBadges || loading.badges"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            class="mx-auto"
          ></v-progress-circular>
        </div>
        <v-text-field
          v-if="dialog.search && activeTab == 0"
          outlined
          placeholder="Search"
          prepend-icon="mdi-magnify"
          single-line
          dense
          hide-details
          v-model="data.search"
          class="mb-5"
        ></v-text-field>
        <div v-for="badge in visibleItems" :key="badge.id">
          <v-hover v-slot="{ hover }">
            <div
              class="mx-0 px-0 py-1 cursor-pointer"
              @click="addRewardToProgramV2(badge)"
            >
              <!-- <v-divider /> -->
              <div class="d-flex align-start">
                <v-img
                  v-if="badge.imageUrl && !hover"
                  :src="badge.imageUrl"
                  class="badge-icons"
                />
                <v-icon
                  v-else
                  class="badge-icons"
                  :class="
                    hover
                      ? 'primary-background'
                      : `${badge.iconColor}-background`
                  "
                  color="white"
                  >{{ hover ? "mdi-plus" : badge.icon }}</v-icon
                >

                <div
                  class="ml-2 px-3 text-overflow-clip element-box d-flex flex-column"
                  :style="hover ? '' : 'height: 35px;'"
                >
                  <span class="">{{ badge.displayName }}</span>
                  <!-- <p class="mb-1 mediumGrey--text">
                    Created {{ badge.createdAt }}
                  </p> -->
                  <p class="mb-1 text-overflow-wrap">
                    Description: {{ badge.description }}
                  </p>
                </div>
              </div>
            </div>
          </v-hover>
        </div>
        <div class="d-flex justify-center mt-3 mb-6">
          <v-btn
            text
            color="primary"
            class="mx-auto"
            @click="dialog.newBadge = true"
          >
            <v-icon small class="create-badge-button mr-2" color="white"
              >mdi-plus</v-icon
            >Create a badge</v-btn
          >
        </div>
      </div>
      <div v-else-if="activeTab == 1">
        <div
          class="d-flex justify-space-between mediumGrey--text"
          style="padding-right: 40px;"
        >
          <span>Select a budget to fund this payment</span>
          <span v-if="budgetId">Balance</span>
        </div>
        <v-select
          label="Budget"
          outlined
          dense
          attach
          :menu-props="{ bottom: true, offsetY: true }"
          class="mt-2"
          :items="visibleItems"
          v-model="budgetId"
          item-value="budgetId"
          item-text="displayNameWithBalance"
          no-data-text="
           No budgets found
           
          "
          :rules="[v => !!v || 'Budget is required']"
          :loading="loadingBudgets || loading.budgets"
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center justify-space-between full-width">
              <span class="budget-name-container text-overflow-clip">
                {{ item.budgetDisplayName }}
              </span>
              <span
                ><span v-if="item.awardId == 1">$ </span
                >{{ formatCurrency(item.budgetBalance) }}
                <span v-if="item.awardId != 1 && item.Award">{{
                  item.budgetBalance == 1 && item.Award.unitSingular
                    ? item.Award.unitSingular
                    : item.budgetBalance != 1 && item.Award.unitPlural
                    ? item.Award.unitPlural
                    : ""
                }}</span></span
              >
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="d-flex align-center justify-space-between full-width">
              <span class="budget-name-container text-overflow-clip">
                {{ item.budgetDisplayName }}
              </span>
              <span
                ><span v-if="item.awardId == 1">$ </span
                >{{ formatCurrency(item.budgetBalance) }}
                <span v-if="item.awardId != 1 && item.Award">{{
                  item.budgetBalance == 1 && item.Award.unitSingular
                    ? item.Award.unitSingular
                    : item.budgetBalance != 1 && item.Award.unitPlural
                    ? item.Award.unitPlural
                    : ""
                }}</span></span
              >
            </div>
          </template>
        </v-select>
        <p
          class="primary--text text-right cursor-pointer"
          v-if="budgetId && selectedBudget"
          @click="dialog.fundingWidget = true"
        >
          <v-icon small color="primary">mdi-plus</v-icon>Add funds to your
          program budget
        </p>

        <div class="d-flex align-start mt-7">
          <p class="mt-2">
            Send
          </p>
          <v-text-field
            v-model="amount"
            outlined
            dense
            style="width: 60px;"
            type="number"
            class="mx-3"
            placeholder="0"
            min="0"
            step="1"
            :rules="paymentAmountRules"
            @wheel="$event.target.blur()"
          >
            <template v-slot:prepend-inner>
              <div
                style="margin-top: 4px;"
                v-if="selectedBudget && selectedBudget.awardId == 1"
              >
                $
              </div>
            </template>
            <template v-slot:append>
              <div
                style="margin-top: 4px;"
                v-if="
                  selectedBudget &&
                    selectedBudget.awardId != 1 &&
                    selectedBudget.Award
                "
              >
                {{
                  amount && amount == 1 && selectedBudget.Award.unitSingular
                    ? selectedBudget.Award.unitSingular
                    : selectedBudget &&
                      amount != 1 &&
                      selectedBudget.Award.unitPlural
                    ? selectedBudget.Award.unitPlural
                    : ""
                }}
              </div>
            </template></v-text-field
          >
          <p class="mt-2">to each participant's wallet.</p>
        </div>

        <v-textarea
          label="Optional payment memo"
          outlined
          v-model="memo"
          :rules="[
            v => !v || !!(v && v.length < 240) || 'Your note should be shorter'
          ]"
        ></v-textarea>

        <div v-if="numParticipants && selectedBudget" class="mt-0">
          <div class="d-flex justify-space-between">
            <span>Number of participants invited to this program</span>
            <span>{{ numParticipants }}</span>
          </div>
          <div class="d-flex justify-space-between font-weight-bold mt-2">
            <span>Total amount of this payment</span>
            <span
              ><span v-if="selectedBudget && selectedBudget.awardId == 1"
                >$
              </span>
              {{ formatCurrency(totalBeingSpent) }}
              <span
                v-if="
                  selectedBudget &&
                    selectedBudget.awardId != 1 &&
                    selectedBudget.Award
                "
                >{{
                  totalBeingSpent == 1 && selectedBudget.Award.unitSingular
                    ? selectedBudget.Award.unitSingular
                    : selectedBudget &&
                      totalBeingSpent != 1 &&
                      selectedBudget.Award.unitPlural
                    ? selectedBudget.Award.unitPlural
                    : ""
                }}</span
              ></span
            >
          </div>
          <v-divider class="my-2"> </v-divider>
          <div class="d-flex justify-space-between">
            <span>Budget after this payment</span>
            <span
              ><span v-if="selectedBudget && selectedBudget.awardId == 1"
                >$
              </span>
              {{ formatCurrency(budgetBalanceAfterPayment) }}
              <span
                v-if="
                  selectedBudget &&
                    selectedBudget.awardId != 1 &&
                    selectedBudget.Award
                "
                >{{
                  budgetBalanceAfterPayment == 1 &&
                  selectedBudget.Award.unitSingular
                    ? selectedBudget.Award.unitSingular
                    : selectedBudget &&
                      budgetBalanceAfterPayment != 1 &&
                      selectedBudget.Award.unitPlural
                    ? selectedBudget.Award.unitPlural
                    : ""
                }}</span
              ></span
            >
          </div>
        </div>
        <p v-if="budgetBalanceAfterPayment < 0" class="mt-5">
          <v-icon color="error">mdi-alert</v-icon>Warning: Your budget appears
          to be underfunded. Make sure to add more funds or your program might
          not behave as expected
        </p>
        <v-btn
          class="my-3"
          color="primary"
          :disabled="!validateForm"
          :outlined="!validateForm"
          @click="addRewardToProgramV2(null)"
          >Add to program</v-btn
        >
      </div>

      <!-- <v-expansion-panels class="my-5" flat>
        <v-expansion-panel
          v-for="module in visibleItems"
          :key="module.learningModuleId"
        >
          <v-expansion-panel-header class="mx-0 px-0 py-0">
            <div class="d-flex align-center">
              <v-hover v-slot="{ hover }">
                <v-icon
                  class="module-icons"
                  :class="{
                    'primary-background': hover,
                    'brand-background': !hover,
                  }"
                  color="white"
                  @click.stop.native="addToProgram(module)"
                  >{{
                    hover
                      ? "mdi-plus"
                      : module.type == "Learning"
                      ? "mdi-school"
                      : "mdi-comment-question-outline"
                  }}</v-icon
                >
              </v-hover>
              <div class="d-flex flex-column ml-4 text-overflow-clip">
                <span class="primary--text">{{ module.displayName }}</span>
                <span class="mediumGrey--text text-caption"
                  >{{ tabLanguage }} created
                  {{ module.createdAtFormatted }}</span
                >
              </div>
            </div>

            <template v-slot:actions>
              <v-icon small>$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mb-0">Chapters and cards in this {{ tabLanguage }}</p>
            <v-expansion-panels class="mt-2">
              <v-expansion-panel
                v-for="chapter in module.LearningChapters"
                :key="chapter.learningChapterId"
              >
                <v-expansion-panel-header class="mx-0 pl-0 pr-4 py-0">
                  <div class="d-flex align-center">
                    <div class="ml-4 text-overflow-clip font-weight-bold">
                      {{ chapter.displayName }}
                    </div>
                  </div>
                  <template v-slot:actions>
                    <v-icon small>$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0 px-0">
                  <div
                    v-for="card in chapter.LearningCards"
                    :key="card.learningCardId"
                    class="my-1"
                  >
                    <div class="d-flex align-center">
                      <v-icon
                        class="module-icons brand-background"
                        color="white"
                        >{{
                          ["quiz", "survey", "assessment"].includes(card.type)
                            ? "mdi-format-list-checkbox"
                            : "mdi-school"
                        }}</v-icon
                      >
                      <div class="ml-2 text-overflow-clip learning-card-row">
                        {{ card.displayName }}
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-flex justify-center mt-2">
              <v-btn
                text
                color="primary"
                class="mx-auto"
                @click="addToProgram(module)"
              >
                <v-icon small class="add-module-button mr-2" color="white"
                  >mdi-plus</v-icon
                >Add to program</v-btn
              >
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
    </div>

    <!-- Message edit warning dialog -->
    <v-dialog v-model="dialog.overwriteWarning" width="500" class="dialog">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break text-left exit-warning-text">
            You have an existing reward for this program. Do you want to
            overwrite it?
          </v-card-title>
        </div>

        <v-card-actions class="mx-12 d-flex justify-center">
          <v-btn
            class="mr-4"
            color="brandCyan"
            outlined
            width="130"
            @click="
              (dialog.overwriteWarning = false),
                (badgeToAdd = null),
                (rewardToAdd = null)
            "
            >Cancel</v-btn
          >
          <v-btn
            class="ml-4 white--text"
            color="brandCyan"
            depressed
            width="130"
            @click="addRewardToProgramV2(rewardToAdd, true)"
            >Overwrite</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Budget funding drawer -->
    <v-navigation-drawer
      v-model="dialog.fundingWidget"
      temporary
      fixed
      right
      width="500"
      disable-route-watcher
    >
      <StripePaymentMethodsManager
        v-if="dialog.fundingWidget"
        @reset="dialog.fundingWidget = false"
        @get-budgets="getBudgets"
        :destinationBudget="selectedBudget"
        :budgets="budgets"
        :clientId="clientId"
        source="PROGRAM_WIZARD"
      />
    </v-navigation-drawer>
    <!-- Used to create a new badge -->
    <v-navigation-drawer
      v-model="dialog.newBadge"
      temporary
      fixed
      right
      width="500"
    >
      <BadgeCreatorWidget
        v-if="dialog.newBadge"
        @close="dialog.newBadge = false"
        @refreshBadges="getBadges"
        :editBadgeObject="null"
        :editingBadge="false"
        :clientId="clientId"
        source="PROGRAM_WIZARD"
      />
    </v-navigation-drawer>
  </v-card>
</template>

<script>
// import LoadingDialog from "@/components/loadingDialog.vue";
// import LearningService from "../services/LearningService";
import BadgeService from "../services/BadgeService";
import BudgetService from "../services/BudgetService";

import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager.vue";
import BadgeCreatorWidget from "@/components/BadgeCreatorWidget.vue";
// import RobinAnimation from "./Robin";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import moment from "moment";
import { mapState } from "vuex";
import fz from "fuzzaldrin-plus";

export default {
  name: "ProgramRewardWidget",
  components: {
    // LoadingDialog,
    // RobinAnimation,
    StripePaymentMethodsManager,
    BadgeCreatorWidget
  },
  props: {
    clientId: Number,
    budgets: Array,
    loadingBudgets: Boolean,
    badges: Array,
    loadingBadges: Boolean,
    numParticipants: Number,
    existingReward: Object,
    editingBadge: Boolean,
    editingPayment: Boolean,
    activeProgram: Boolean
  },
  data() {
    return {
      rewardType: "Badge",
      data: {
        budgets: [],
        badges: [],
        search: null
      },
      dialog: {
        overwriteWarning: false,
        fundingWidget: false,
        newBadge: false,
        search: false
      },
      sort: {
        menu: false,
        value: "name-asc",
        options: [
          {
            label: "Name",
            key: "name",
            values: ["name-asc", "name-desc"]
          },
          {
            label: "Created",
            key: "create-date",
            values: ["create-date-asc", "create-date-desc"]
          }
        ]
      },
      loading: {
        budgets: false,
        badges: false
      },
      activeTab: 0,

      // Data used for payment form
      badgeId: null,
      budgetId: null,
      amount: null,
      memo: null,

      badgeToAdd: null,
      rewardToAdd: null
    };
  },

  created() {
    // We pass in props and fetch budgets or badges if not passed in
    if (!this.budgets) this.getBudgets();
    if (!this.badges) this.getBadges();

    if (this.editingBadge || this.editingPayment || this.existingReward) {
      console.log(this.existingReward);
      // console.log("Edit payment", this.editingPayment);
      // console.log("Edit badge", this.editingBadge);
      if (
        (this.existingReward &&
          this.existingReward.badgeId &&
          !this.editingBadge) ||
        this.editingPayment
      ) {
        // Alread selected a badge, so preload payment
        this.rewardType = "Payment";
        this.activeTab = 1;
      } else if (
        (this.existingReward &&
          this.existingReward.budgetId &&
          !this.editingPayment) ||
        this.editingBadge
      ) {
        this.activeTab = 0;
      }
      this.budgetId = this.existingReward.budgetId;
      this.amount = this.existingReward.amount;
      this.memo = this.existingReward.memo;
    }
    // Set active tab
    if (this.rewardType == "Payment") this.activeTab = 1;
  },
  mounted() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    getBadges() {
      if (this.badges) {
        console.log("Emitting to refresh badges");
        // Prop was passed in so we emit and return
        this.$emit("get-badges");
        return;
      }
      this.loading.badges = true;
      BadgeService.getBadges()
        .then(response => {
          console.log("Badges: ", response);
          var badges = response
            .filter(
              badge =>
                badge.status !== "Deleted" &&
                (badge.clientId == 0 || badge.clientId == this.clientId)
            )
            .map(x => {
              var obj = x;
              obj.id = x.badgeId.toString();
              obj.context = "Badges";
              obj.contextResourceId = x.badgeId.toString();
              obj.contextColumn = "metricValue";
              obj.displayName = obj.displayName
                ? obj.displayName
                : obj.name
                ? obj.name
                : "Unnamed badge";
              obj.icon = "mdi-trophy-variant-outline";
              obj.iconColor = "brand-dark-green";
              obj.displayGrabber = false;

              // obj.createdAtFormatted = moment(obj.createdAt)
              //   .utc()
              //   .format("MMM DD, YYYY");
              return obj;
            });
          //Adds header because this array will be used to select metrics for a payoutRule
          this.data.badges = badges;
          this.loading.badges = false;
        })
        .catch(error => {
          console.log("Error getting badges", error);
          this.loading.badges = false;
        });
    },
    getBudgets() {
      if (this.budgets) {
        this.$emit("get-budgets");
        return;
      }
      this.loading.budgets = true;
      BudgetService.getBudgets()
        .then(response => {
          var budgets = response
            .filter(
              budget =>
                budget.status !== "Deleted" &&
                budget.status !== "Expired" &&
                budget.clientId == this.clientId
            )
            .map(x => {
              var obj = x;
              obj.id = x.budgetId.toString();
              obj.context = "Budgets";
              obj.contextResourceId = x.budgetId.toString();
              obj.contextColumn = "metricValue";

              return obj;
            });
          console.log("Budgets: ", budgets);
          this.data.budgets = budgets;
          this.loading.budgets = false;
        })
        .catch(error => {
          console.log("Error getting budgets ", error);
          this.loading.budgets = false;
        });
    },
    changeTab(slide) {
      // We only do something if they click on the other option
      if (slide != this.activeTab) {
        this.activeTab = slide;

        // Here we have to reset the selected array and other settings
        // We also need to add the users back to the original array
        if (slide == 0) {
          // The user switched away from groups, so add groups back
          this.rewardType = "Badge";
        } else {
          this.rewardType = "Payment";
        }
        // this.slidePeople.selected = [];
        document.getElementsByClassName(
          "v-dialog v-dialog--active"
        )[0].scrollTop = 0;
      }
    },
    updateSortBy(option) {
      console.log("updating sortby ", option);

      var sortOption = this.sort.options.find(x =>
        x.values.includes(this.sort.value)
      );
      // If they're picking an option that's already selected, then they're just changing the sort order.
      if (sortOption == option) {
        var asc = this.sort.value.includes("asc");
        this.sort.value = option.key + "-" + (asc ? "desc" : "asc");
      } else {
        this.sort.value = option.key + "-asc";
        // this.replaceQuery(option.key + "-asc", null);
      }
    },
    close() {
      this.$emit("close");
    },
    // addBadgeToProgram(badge) {
    //   if (this.activeProgram) return;
    //   if (
    //     this.existingReward &&
    //     (this.existingReward.badgeId ||
    //       (this.existingReward.amount && this.existingReward.budgetId)) &&
    //     ((this.activeTab == 0 &&
    //       (this.existingReward.context == "reward" ||
    //         (this.existingReward.badgeId != null &&
    //           this.existingReward.badgeId !== badge.id))) ||
    //       (this.activeTab == 1 && this.existingReward.context == "Badges"))
    //   ) {
    //     this.dialog.overwriteWarning = true;
    //     this.badgeToAdd = badge;
    //     return;
    //   }
    //   this.$emit("add-to-program", badge);
    // },
    // addRewardToProgram() {
    //   if (this.activeProgram) return;
    //   let reward = {
    //     // id: new Date().toString(),
    //     // displayName:
    //     //   (this.selectedBudget.awardId == 1 ? "$ " : "") +
    //     //   this.formatCurrency(parseFloat(this.amount).toFixed(2)) +
    //     //   (this.selectedBudget.awardId != 1 && this.selectedBudget.Award
    //     //     ? this.amount == 1 && this.selectedBudget.Award.unitSingular
    //     //       ? " " + this.selectedBudget.Award.unitSingular
    //     //       : this.amount != 1 && this.selectedBudget.Award.unitPlural
    //     //       ? " " + this.selectedBudget.Award.unitPlural
    //     //       : ""
    //     //     : "") +
    //     //   " Reward Payment",
    //     context: "reward",
    //     amount: this.amount,
    //     budgetId: this.budgetId,
    //     // icon: "mdi-trophy-variant-outline",
    //     // iconColor: "brand-dark-green",
    //     // displayGrabber: false,
    //   };
    //   // if (this.existingReward && !this.editingReward) {
    //   //   this.dialog.overwriteWarning = true;
    //   //   this.rewardToAdd = reward;
    //   //   return;
    //   // }
    //   if (
    //     this.existingReward &&
    //     (this.existingReward.badgeId ||
    //       (this.existingReward.amount && this.existingReward.budgetId)) &&
    //     ((this.activeTab == 0 && this.existingReward.context == "reward") ||
    //       (this.activeTab == 1 &&
    //         (this.existingReward.context == "Badges" ||
    //           (this.existingReward.amount &&
    //             this.existingReward.amount !== this.amount) ||
    //           (this.existingReward.budgetId &&
    //             this.existingReward.budgetId !== this.budgetId))))
    //   ) {
    //     this.dialog.overwriteWarning = true;
    //     this.rewardToAdd = reward;
    //     return;
    //   }
    //   console.log("Adding reward to program ");
    //   this.$emit("add-to-program", reward);
    // },
    addRewardToProgramV2(reward = null, replace = false) {
      // TODO: check on this with editing ctive program
      console.log("add reward to program", reward);
      // if (this.activeProgram) {
      //   return;
      // }

      console.log(replace);

      if (replace == false && reward && reward.badgeId) {
        // We're adding a badge
        // Check existing reward for badge id
        if (this.existingReward.badgeId) {
          this.dialog.overwriteWarning = true;
          this.rewardToAdd = reward;
          // this.rewardToAdd = {
          //   context: "reward",
          //   amount: this.amount,
          //   budgetId: this.budgetId,
          // }
        } else {
          this.$emit("add-to-program", reward);
          this.rewardToAdd = null;
          this.dialog.overwriteWarning = false;
        }
        // if has badge id, confirm
        // else, emit
      } else if (replace == false && reward == null) {
        // Reward is null here because the reward data is stored as amount and budget id, we use that to make reward to add
        // we're adding a budget based award
        // Check existing reward for budget id
        this.rewardToAdd = {
          id: new Date().toString(),
          displayName:
            (this.selectedBudget.awardId == 1 ? "$ " : "") +
            this.formatCurrency(parseFloat(this.amount).toFixed(2)) +
            (this.selectedBudget.awardId != 1 && this.selectedBudget.Award
              ? this.amount == 1 && this.selectedBudget.Award.unitSingular
                ? " " + this.selectedBudget.Award.unitSingular
                : this.amount != 1 && this.selectedBudget.Award.unitPlural
                ? " " + this.selectedBudget.Award.unitPlural
                : ""
              : "") +
            " Reward Payment",
          context: "reward",
          amount: this.amount,
          budgetId: this.budgetId,
          icon: "mdi-trophy-variant-outline",
          iconColor: "brand-dark-green",
          displayGrabber: false,
          memo: this.memo && this.memo.trim() != "" ? this.memo : null
        };
        if (
          this.existingReward.budgetId &&
          this.existingReward.budgetId != null
        ) {
          // if had budget id, confirm
          this.dialog.overwriteWarning = true;
        } else {
          console.log("reward to add", this.rewardToAdd);
          this.$emit("add-to-program", this.rewardToAdd);
          this.rewardToAdd = null;
          this.dialog.overwriteWarning = false;
        }
      } else {
        // Replace or we don't have an existing type of that award, emit
        this.$emit("add-to-program", reward);
        this.rewardToAdd = null;
        this.dialog.overwriteWarning = false;
      }
    },
    // overwriteReward() {
    //   if (this.badgeToAdd) this.$emit("add-to-program", this.badgeToAdd);
    //   else this.$emit("add-to-program", this.rewardToAdd);

    //   this.badgeToAdd = null;
    //   this.rewardToAdd = null;
    //   this.dialog.overwriteWarning = false;
    // },
    timeDiffMilliseconds(badge) {
      // The time between now and the create date, in milliseconds
      const now = moment();
      const end = moment(badge.createdAt);
      // console.log(now, end);
      // console.log('milliseconds', end.diff(now));
      return end.diff(now);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    tabLanguage() {
      if (this.activeTab == 0) return "Badge";
      return "Payment";
    },
    visibleItems() {
      if (this.activeTab == 0) {
        var badges = this.badges ? this.badges : this.data.badges;
        // We want to sort by the value
        if (this.sort.value) {
          if (this.sort.value.includes("create-date")) {
            badges = badges
              .filter(x => x.createdAt)
              .sort((a, b) => {
                if (isNaN(this.timeDiffMilliseconds(a))) {
                  return 1;
                } else if (isNaN(this.timeDiffMilliseconds(b))) {
                  return -1;
                }
                return this.sort.value.includes("asc")
                  ? this.timeDiffMilliseconds(a) - this.timeDiffMilliseconds(b)
                  : this.timeDiffMilliseconds(b) - this.timeDiffMilliseconds(a);
              });
          } else {
            // Sorting by name
            badges = badges.sort((a, b) =>
              this.sort.value.includes("asc")
                ? a.displayName.localeCompare(b.displayName)
                : b.displayName.localeCompare(a.displayName)
            );
          }
        }

        if (!this.data.search) return badges;
        const preparedQuery = fz.prepareQuery(this.data.search);
        const scores = {};
        badges = badges
          .map(item => {
            //Programs page
            var scorableFields = [item.description, item.displayName];
            scorableFields = scorableFields.map(toScore =>
              fz.score(toScore, this.data.search, { preparedQuery })
            );

            scores[item.id] = Math.max(...scorableFields);

            return item;
          })
          .filter(item => scores[item.id] > 1)
          .sort((a, b) => scores[b.id] - scores[a.id]);
        return badges;
      }

      if (this.budgets && this.activeTab == 1) return this.budgets;
      else return this.data.budgets;
    },
    selectedBudget() {
      if (!this.budgetId) return null;
      if (this.budgets) var budgets = this.budgets;
      else budgets = this.data.budgets;
      let budget = budgets.find(x => x.budgetId == this.budgetId);
      if (budget) return budget;
      return null;
    },
    paymentAmountRules() {
      var rules = [
        v =>
          !!(v && parseFloat(v) > 0) || "Payment amount must be greater than 0"
      ];
      // if (this.slideDetails.paymentType !== "NON_CASH") {
      rules.push(
        v =>
          !!(
            v &&
            (!v.toString().includes(".") ||
              (v.toString().includes(".") &&
                v.toString().substring(v.toString().indexOf(".") + 1).length <
                  3))
          ) || `Payment amount shouldn't be longer than 2 decimals.`
      );
      // } else {
      //   rules.push(
      //     (v) =>
      //       !!(v && !v.toString().includes(".")) ||
      //       `Payment amount shouldn't contain a decimal.`
      //   );
      // }
      return rules;
    },
    totalBeingSpent() {
      if (!this.amount) return 0;
      if (!this.numParticipants) var numPeople = 0;
      else numPeople = this.numParticipants;

      return truncateNumber((this.amount * numPeople).toFixed(3), 2);
    },
    budgetBalanceAfterPayment() {
      if (!this.selectedBudget) return 0;
      return truncateNumber(
        (this.selectedBudget.budgetBalance - this.totalBeingSpent).toFixed(3),
        2
      );
    },
    validateForm() {
      if (this.activeTab == 1) {
        return (
          this.budgetId &&
          this.visibleItems.find(x => x.budgetId == this.budgetId) &&
          this.amount &&
          parseFloat(this.amount) > 0 &&
          (!this.amount.toString().includes(".") ||
            (this.amount.toString().includes(".") &&
              this.amount
                .toString()
                .substring(this.amount.toString().indexOf(".") + 1).length < 3))
        );
      }
      return true;
    }
  },
  watch: {
    "dialog.fundingWidget": function(newVal) {
      if (newVal === false) {
        // side drawer just closed so we refresh budgets in case they have funded
        this.getBudgets();
      }
    }
  }
};
</script>

<style scoped>
/* Used for the tab buttons at the top */
.tab-group-container {
  padding: 5px 5px;
  border-radius: 7px;
  width: 230px;
  background-color: var(--v-lightGrey-base);
}
.tab-button {
  width: 100px;
  text-align: center;
  text-transform: none !important;
  letter-spacing: 0em;
}
.active-tab-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}

/* Used for the table of badges */
.badge-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
}

/* Used to clip the learning module name */
.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Used to give gray background to learning cards in previews */
.learning-card-row {
  border-radius: 5px;
  background: var(--v-lightGrey-base);
  width: 100%;
  height: 35px;
  padding-top: 6px;
  padding-left: 12px;
}

/* Used for the small plus button after expanding a module to add it to the program */
.add-module-button {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: var(--v-brand-base);
}

/* Class for the boxes around each item */
.element-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  /* height: 35px; */
  padding-top: 7px;
}

/* Used to cap budget names and add ellipsis at the end if too long */
.budget-name-container {
  width: calc(100% - 120px);
}

/* Used for the small plus button for creating a new badge */
.create-badge-button {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: var(--v-brand-base);
}
</style>

<template>
  <div class="full-width">
    <v-row no-gutters>
      <v-col
        :cols="isMobile ? '12' : '6'"
        v-for="(action, index) in rewardActions.filter(
          x => !x.permission || permissions.includes(x.permission)
        )"
        :key="index"
        class="card-col"
        :class="{
          'mt-n2': index == 0 || (index == 1 && !isMobile),
          'pr-3': !isMobile && index % 2 == 0,
          'pl-3': !isMobile && index % 2 == 1,
          'px-1': isMobile
        }"
      >
        <v-card
          :style="{ height: 'fit-content', 'min-height': '100%' }"
          elevation="1"
          :to="action.to"
          @click="loadAction(action)"
        >
          <div class="pa-3 d-flex flex-column justify-center">
            <div
              class="d-flex justify-space-between align-center pt-4 pb-2 mb-2 word-break card-header"
            >
              <div class="d-flex align-center pr-2">
                <v-icon
                  large
                  color="white"
                  class="pa-2 rounded mr-4 brand-dark-green-background icon"
                  :class="action.backgroundColor"
                  >{{ action.icon }}</v-icon
                >
                <!-- <p
                    style="line-height: 22px; font-size: 1.1em;"
                    class="my-auto"
                  >
                    Send a
                    <span class="font-weight-black">one time payment</span>
                  </p> -->
                <span v-html="action.headline"></span>
              </div>
              <v-icon>mdi-chevron-right</v-icon>
            </div>
            <!-- <p class="mt-6 mb-0">
                Send a single payment to one or many people with the option to
                restrict funds for a specific purpose
              </p> -->
            <span v-html="action.body" class="mb-2 text-left"></span>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="annualRewardBlocker" width="500">
      <PopupDialog
        v-if="annualRewardBlocker"
        icon="mdi-alert"
        iconColor="error"
        :centerText="true"
        title="Looks like you don't have permission for this"
        subtitle="Only users with the 'Client Admin' role can set up and manage these rewards."
        button2Text="Ok"
        button2Width="140"
        @button2="annualRewardBlocker = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import PopupDialog from "@/components/PopupDialog";
import { mapState } from "vuex";

export default {
  name: "RewardsLibrary",
  props: {},
  components: { PopupDialog },
  data() {
    return {
      annualRewardBlocker: false,
      rewardActions: [
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Create
              <span class="font-weight-black">Team Rewards Budgets</span>
            </h5>`,
          body: `<p class="mb-0">
              Give eligible team members a budget to reward other team members (peer-to-peer or manager-to-employee)
            </p>`,
          icon: "mdi-trophy",
          backgroundColor: "brand-cyan-background",
          permission: "vue:read:budgets",
          action: "budget-wizard",
          label: "Team Rewards Budgets"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Setup
              <span class="font-weight-black">Spot Bonus Budgets</span>
            </h5>`,
          body: `<p class="mb-0">
            Fund a spot bonus budget so managers can immediately reward highly effective team members
            </p>`,
          icon: "mdi-thumb-up",
          backgroundColor: "brand-cyan-background",
          permission: "vue:read:budgets",
          action: "budget-wizard",
          label: "Spot Bonus Budgets"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Send a
              <span class="font-weight-black">New Hire Stipend</span>
            </h5>`,
          body: `<p class="mb-0">
            Make it easier for new hires to get equipment, pay for travel, and other needs with a pre-funded budget
            </p>`,
          icon: "mdi-account-multiple-plus-outline",
          backgroundColor: "brand-background",
          action: "one-time-payment"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Send a
              <span class="font-weight-black">Pre-imbursement</span>
            </h5>`,
          body: `<p class="mb-0">
            Provide an easier means for team members to make business-related purchases
            </p>`,
          icon: "mdi-cash",
          backgroundColor: "brand-background",
          action: "one-time-payment"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Manage
              <span class="font-weight-black">Birthday Rewards</span>
            </h5>`,
          body: `<p class="mb-0">
            Schedule awards for birthdays. Select amounts and offer custom notes from a manager
            </p>`,
          icon: "mdi-cake-variant",
          backgroundColor: "brand-background",
          action: "annual-reward-birthday"
        },
        {
          headline: `<h5 style="line-height: 22px; font-size: 1.1em;" class="my-auto rec-header">
              Manage 
              <span class="font-weight-black">Anniversary Rewards</span>
            </h5>`,
          body: `<p class="mb-0">
            Schedule awards for team members on each annual milestone
            </p>`,
          icon: "mdi-party-popper",
          backgroundColor: "brand-background",
          action: "annual-reward-service-anniversary"
        }
      ]
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    loadAction(item) {
      console.log("loading action ", item);
      if (
        !(this.permissions || []).find(
          x => x == "vue:read:annualrewardwizard"
        ) &&
        (item.action == "annual-reward-service-anniversary" ||
          item.action == "annual-reward-birthday")
      ) {
        console.log("Load popup blocker");
        this.annualRewardBlocker = true;
        return;
      }
      this.$emit("load-action", item);
    }
  },
  computed: {
    ...mapState(["userProfile", "permissions"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },
  watch: {}
};
</script>

<style scoped>
/* Changes the default padding of all cols that have a card in them in the payment manager div */
.card-col.col {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (max-width: 960px) {
  .icon {
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
  }

  .page-title {
    font-size: 1.2em;
  }

  .card-col.col {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.v-card {
  border-radius: 10px;
  padding-right: 12px;
  padding-left: 12px;
}

/* Hides gray background when clicking v-card */
.v-card--link:before {
  background: none;
}

.card-header >>> .rec-header {
  font-weight: normal !important;
}
</style>

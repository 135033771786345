<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="brandCyan" rounded="0" elevation="0">
      <!-- <v-toolbar-items> -->
      <v-btn dark icon @click="close">
        <v-icon large>mdi-close-circle</v-icon>
      </v-btn>
      <!-- </v-toolbar-items> -->
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-flex align-center"
        ><v-icon class="mr-2 mt-n1">mdi-party-popper</v-icon>Choose a
        celebration</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden">
        <v-btn dark text @click="close">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-text-field
      label="Search GIPHY"
      outlined
      dense
      v-model="debounceSearch"
      prepend-icon="mdi-magnify"
      class="mt-5 mx-10"
      color="brandCyan"
      hide-details
    ></v-text-field>
    <!-- <div class="giphy-search-grid mx-6 mt-5" v-if="giphy.search"></div> -->
    <div
      class="giphy-grid mx-3 mt-5"
      style="height: 70vh; overflow-y: auto;"
    ></div>
    <div class="d-flex pt-3 full-width">
      <v-img
        src="@/assets/Logo/Poweredby_640px-White_VertText.png"
        contain
        max-width="120"
      />
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

import { debounce } from "@/shared_data/functions";
import { throttle } from "throttle-debounce";
import { renderGrid } from "@giphy/js-components";
import { GiphyFetch } from "@giphy/js-fetch-api";

// create a GiphyFetch with your api key
// apply for a new Web SDK key. Use a separate key for every platform (Android, iOS, Web)
const giphyFetch = new GiphyFetch(process.env.VUE_APP_GIPHY_KEY);

export default {
  name: "GIFDrawer",
  components: {},
  props: {},
  data() {
    return {
      grid: null,
      searchGrid: null,
      search: null,
      debounceSearch: null
    };
  },
  created() {
    // this.makeGiphyGrid();
    setTimeout(() => {
      console.log("Going to make grid");

      this.grid =
        // const grid
        this.makeGiphyGrid(document.querySelector(".giphy-grid"), false);
      // const searchGrid = this.makeGiphyGrid(
      //   document.querySelector(".giphy-search-grid"),
      //   true
      // );
      console.log("Got grid ", {
        grid: this.grid
        // search: this.giphy.searchGrid
      });
      // console.log("Got search grid ", searchGrid);
    }, 0);
  },
  destroyed() {
    console.log("Celebration drawer closed (grid)");
    if (this.grid) {
      this.grid.remove();
      this.grid = null;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    makeGiphyGrid(targetEl, search = false) {
      console.log("Here we gooooo", { search, targetEl });
      // here is the @giphy/js-components import
      const render = () => {
        const el = document.querySelector(".giphy-grid");

        const width = el && el.offsetWidth ? el.offsetWidth - 40 : 400;
        console.log("Render width ", width);
        return renderGrid(
          {
            width,
            fetchGifs: search ? this.searchGifs : this.fetchGifs,
            columns: width < 300 ? 2 : 3,
            gutter: 6,
            noResultsMessage: "No results found",
            noLink: true,
            hideAttribution: true,
            onGifClick: this.clickEvent
          },
          targetEl
        );
      };
      const resizeRender = throttle(500, render);
      window.addEventListener("resize", resizeRender, false);
      const remove = render();
      return {
        remove: () => {
          console.log("Inside remove component");
          remove();
          window.removeEventListener("resize", resizeRender, false);
        }
      };
    },
    fetchGifs(offset) {
      console.log("Inside fetchGifs");
      // if (this.giphy.search) {
      //   return giphy.search(this.giphy.search, {
      //     sort: "relevant",
      //     lang: "en",
      //     offset: offset,
      //     limit: 25
      //   });
      // } else {
      //   return giphy.trending({ offset: offset, limit: 25 });
      // }
      // console.log("Inside fetchGifs", this.giphy.search);
      // if (this.giphy.search) return this.searchGifs(offset);
      // // use whatever end point you want,
      // // but be sure to pass offset to paginate correctly
      return giphyFetch.trending({ offset, limit: 25 });
    },
    searchGifs(offset) {
      console.log("Inside searchGifs");
      // use whatever end point you want,
      // but be sure to pass offset to paginate correctly
      return giphyFetch.search(this.search, {
        sort: "relevant",
        lang: "en",
        offset,
        limit: 25
      });
    },
    clickEvent(e) {
      this.$emit("select-gif", e);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"])
  },
  watch: {
    debounceSearch: debounce(function(newVal) {
      this.search = newVal;
      console.log({ newVal });
      console.log(!!newVal);
      if (this.grid) this.grid.remove();
      this.makeGiphyGrid(document.querySelector(".giphy-grid"), !!newVal);
      // }
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

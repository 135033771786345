<template>
  <v-card rounded="0" class="full-height">
    <div id="header" class="d-flex justify-start align-center" width="100%">
      <v-btn icon x-large @click="leaveCourse" class="ml-1"
        ><v-icon>mdi-chevron-left</v-icon></v-btn
      >
      <v-icon
        large
        dark
        class="pa-2 ml-3 my-2 brand-green-background border-radius brain-icon"
        >mdi-brain</v-icon
      >
      <v-card-title v-if="course" class="word-break">
        <span class="learning-header">Learning |</span>
        <b class="ml-2">{{ course.displayName }}</b></v-card-title
      >
      <v-card-title v-else>
        Learning
      </v-card-title>
    </div>
    <v-divider class="mx-4" />
    <v-row v-if="moduleNotFound" class="d-flex flex-column px-4 word-break">
      <div class="mx-auto mt-14 ">
        <lottie
          :options="lottieOptions"
          :height="100"
          :width="100"
          v-on:animCreated="handleAnimation"
        />
      </div>
      <h2>We had trouble finding your learning module</h2>
      <h3>
        Check out the
        <a @click="$router.push('/learning')">Library page</a> for more
      </h3></v-row
    >
    <v-row v-else-if="loadingCourse" class="d-flex flex-column">
      <div class="mx-auto">
        <Robin
          v-if="loadingCourse"
          :height="170"
          :width="170"
          :largeText="true"
          :showText="false"
          animation="eating"
          :loop="true"
        ></Robin>
      </div>
      <h2>Loading your learning module</h2>
      <h3>This will just take a sec!</h3>
    </v-row>
    <v-row class="my-2 ml-3 mr-2 course-div" v-if="course && !loadingCourse">
      <v-col cols="12" sm="12" md="3">
        <v-card elevation="7" class="pa-2" rounded="0">
          <div></div>
          <v-img
            v-if="course.imageUrlSigned != null"
            class="mx-auto rounded-lg"
            :src="course.imageUrlSigned"
            :aspect-ratio="16 / 9"
          />
          <v-img
            v-else
            class="mx-auto rounded-lg"
            src="@/assets/placeholder_image.png"
            :aspect-ratio="16 / 9"
            width="100%"
            height="auto"
          />
        </v-card>

        <!--Resources Tab -->
        <v-card
          elevation="7"
          class="pa-3 my-4"
          rounded="0"
          v-if="
            course.LearningAttachments.length > 0 &&
              course.percentComplete === 1
          "
          :key="course.LearningAttachments"
          @click="loadResources()"
        >
          <div class="d-flex justify-space-between align-center cursor-pointer">
            <div class="d-flex flex-column align-text-left">
              <b>Resources</b>
              <span class="mediumGrey--text text-caption"
                >{{ course.LearningAttachments.length }}
                {{
                  course.LearningAttachments.length == 1 ? "File" : "Files"
                }}</span
              >
            </div>
          </div>
        </v-card>

        <v-card
          elevation="7"
          class="pa-3 my-4"
          rounded="0"
          v-for="(chapter, index) in course.LearningChapters"
          :key="chapter.learningChapterId"
        >
          <div
            class="d-flex justify-space-between align-center"
            @click="loadChapter(index, false)"
            :class="{
              'cursor-pointer': !chapter.locked
            }"
          >
            <div class="d-flex flex-column align-text-left word-break">
              <b>
                {{ course.LearningChapters.indexOf(chapter) + 1 }}.
                {{ chapter.displayName }}</b
              >
              <!-- <b>
                {{ chapter.displayName }}</b
              > -->
              <!-- <span class="mediumGrey--text text-caption"
                >About {{ chapter.timeRemaining }}</span
              > -->
            </div>
            <!-- icon for complete chapter, not active -->
            <v-icon
              v-if="chapter.percentComplete >= 100 && !chapter.active"
              large
              color="brandGreen"
              >mdi-chevron-down-circle</v-icon
            >
            <!-- icon for in progress chapter -->
            <v-icon
              v-else-if="chapter.percentComplete > 0 && !chapter.active"
              large
              color="brandGreen"
              >mdi-chevron-down-circle-outline</v-icon
            >
            <!-- icon for active chapter -->
            <v-icon v-else-if="chapter.active" large color="brandGreen"
              >mdi-chevron-up-circle-outline</v-icon
            >
            <!-- icon for not started chapter but unlocked -->
            <v-icon v-else-if="!chapter.locked" large color="grey"
              >mdi-chevron-down</v-icon
            >
            <!-- icon for locked chapter -->
            <v-icon v-else-if="chapter.locked" large color="grey"
              >mdi-lock-outline</v-icon
            >
            <!-- <v-progress-circular
              v-if="chapter.locked"
              :rotate="270"
              :size="50"
              :width="7"
              :value="0"
              color="accent"
              class=""
            >
              <v-icon>mdi-lock</v-icon>
            </v-progress-circular>
            <v-progress-circular
              v-else
              :rotate="270"
              :size="50"
              :width="7"
              :value="chapter.percentComplete"
              color="accent"
            >
              <span class="black--text text-caption"
                >{{ Math.round(chapter.percentComplete) }}%</span
              >
            </v-progress-circular> -->
          </div>
          <div v-if="chapter.active">
            <v-divider class="my-3" />
            <div
              v-for="(lesson, index) in chapter.LearningCards"
              :key="lesson.learningModuleId"
              @click="loadLesson(index)"
            >
              <div
                class="d-flex my-1 card-lesson-box pa-1 align-center"
                :class="{
                  'cursor-pointer':
                    lesson.completed ||
                    !course.forceLinearProgress ||
                    index == 0 ||
                    (index > 0 && chapter.LearningCards[index - 1].completed)
                }"
              >
                <!-- <span
                  v-if="
                    lesson.completed ||
                      !course.forceLinearProgress ||
                      index == 0 ||
                      (index > 0 && chapter.LearningCards[index - 1].completed)
                  "
                  class="pa-3 mr-1 card-icon-box align-text-center d-flex align-center justify-center  rounded-sm white--text"
                  :class="{
                    'grey-background': lesson.completed != true,
                    'brand-green-background': lesson.completed == true,
                  }"
                  >{{ chapter.LearningCards.indexOf(lesson) + 1 }}</span
                >
                <span
                  v-else
                  class="pa-3 mr-1 card-icon-box align-text-center d-flex align-center justify-center  rounded-sm white--text"
                  :class="{
                    'grey-background': lesson.completed != true,
                    'brand-green-background': lesson.completed == true,
                  }"
                  ><v-icon color="white">mdi-lock</v-icon></span
                > -->
                <!-- Icon for completed -->
                <v-icon
                  v-if="lesson.completed"
                  small
                  color="brandGreen"
                  class="mr-2"
                  >mdi-checkbox-blank-circle</v-icon
                >
                <!-- Icon for not completed but unlocked -->
                <v-icon
                  v-else-if="
                    course.forceLinearProgress === false ||
                      (course.forceLinearProgress === true &&
                        (index == 0 ||
                          (index > 0 &&
                            chapter.LearningCards[index - 1].completed)))
                  "
                  small
                  color="brandGreen"
                  class="mr-2"
                  >mdi-checkbox-blank-circle-outline</v-icon
                >
                <!-- Icon for locked and not completed -->
                <v-icon v-else small color="grey" class="mr-2">
                  mdi-checkbox-blank-circle</v-icon
                >
                <span
                  v-if="
                    course.forceLinearProgress === false ||
                      (course.forceLinearProgress === true &&
                        (index == 0 ||
                          (index > 0 &&
                            chapter.LearningCards[index - 1].completed)))
                  "
                  class="align-text-left text-caption ml-1 font-weight-medium"
                >
                  {{ lesson.name }}</span
                >
                <span
                  v-else
                  class="align-text-left text-caption ml-1 grey--text text--darken-2 font-weight-medium"
                  >{{ lesson.name }}</span
                >
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col md="1" class="hidden-sm-and-down"></v-col>
      <v-col cols="12" sm="12" md="8">
        <!--------------- LESSON CARD ------------------>
        <v-row>
          <v-col md="10" v-if="this.viewResourcesCard !== true">
            <v-card
              rounded="0"
              id="lessonCard"
              v-if="currentLesson !== null && this.viewResourcesCard !== true"
            >
              <v-card-title class="pb-1">{{ currentLesson.name }}</v-card-title>
              <div class="d-flex grey--text text-caption ml-4 mb-8">
                <span v-if="currentLesson.updatedAtFormatted"
                  >Updated {{ currentLesson.updatedAtFormatted }}</span
                >
              </div>

              <v-img
                class="mx-auto rounded mt-5"
                v-if="
                  (currentLesson.type == 'text' ||
                    currentLesson.type == 'giving' ||
                    (currentLesson.Content &&
                      currentLesson.Content.filePath &&
                      (currentLesson.Content.filePath
                        .toLowerCase()
                        .endsWith('.jpeg') ||
                        currentLesson.Content.filePath
                          .toLowerCase()
                          .endsWith('.jpg') ||
                        currentLesson.Content.filePath
                          .toLowerCase()
                          .endsWith('.png')))) &&
                    currentLesson.contentUrlSigned != null
                "
                :src="currentLesson.contentUrlSigned"
                contain
                width="80%"
                height="auto"
              />

              <div
                v-else-if="
                  currentLesson.type == 'video' &&
                    (currentLesson.status == 'Processing' ||
                      (currentLesson.Content != undefined &&
                        currentLesson.Content != null &&
                        currentLesson.Content.status == 'Processing'))
                "
                class="pa-3 full-width"
              >
                <div class="d-flex justify-center">
                  <Robin
                    :height="170"
                    :width="170"
                    :largeText="true"
                    :showText="false"
                    animation="eating"
                    :loop="true"
                  ></Robin>
                </div>
                <h2>The video is processing</h2>
                <h3>Please check back soon!</h3>
              </div>

              <div
                v-else-if="
                  currentLesson.type == 'video' &&
                    (currentLesson.status == 'Failed' ||
                      (currentLesson.Content != undefined &&
                        currentLesson.Content != null &&
                        currentLesson.Content.status == 'Failed'))
                "
                class="pa-3 full-width"
              >
                <div class="d-flex justify-center">
                  <Robin
                    :height="170"
                    :width="170"
                    :largeText="true"
                    :showText="false"
                    animation="eating"
                    :loop="true"
                  ></Robin>
                </div>
                <h2>The video failed while processing</h2>
                <h3>Please check with your administrator!</h3>
              </div>

              <div
                v-else-if="
                  currentLesson.type == 'video' &&
                    currentLesson.status == 'Active' &&
                    currentLesson.contentUrlSigned == null &&
                    currentLesson.Content != undefined &&
                    currentLesson.Content != null
                "
                class="pa-3 full-width"
              >
                <div class="d-flex justify-center">
                  <Robin
                    :height="170"
                    :width="170"
                    :largeText="true"
                    :showText="false"
                    animation="eating"
                    :loop="true"
                  ></Robin>
                </div>
                <h2>Loading your content, please wait...</h2>
              </div>
              <div
                class="iframeContainer uploadedVideo"
                @contextmenu="rightClickHandler($event)"
                v-else-if="
                  currentLesson.type == 'video' &&
                    currentLesson.status == 'Active' &&
                    currentLesson.contentUrlSigned != null
                "
              >
                <video-player
                  ref="videoPlayer"
                  class=" vjs-custom-skin"
                  :playsinline="true"
                  :options="playerOptions"
                />
              </div>
              <div
                class="iframeContainer"
                v-else-if="
                  currentLesson.type == 'video_embed' &&
                    currentLesson.status == 'Active' &&
                    currentLesson.contentUrl != null &&
                    currentLesson.contentUrl.startsWith('https://')
                "
              >
                <iframe
                  id="ytplayer"
                  width="600"
                  height="360"
                  :src="currentLesson.contentUrl"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <!-- show transcript expander if it exists and we're on a video card -->
              <div
                v-if="
                  (currentLesson.type == 'video_embed' ||
                    currentLesson.type == 'video') &&
                    currentLesson.transcript != null
                "
                class="px-6"
              >
                <v-expansion-panels
                  multiple
                  flat
                  outline
                  class="expansion-outlined"
                >
                  <v-expansion-panel v-for="(item, i) in 1" :key="i">
                    <v-expansion-panel-header class="pl-6 pr-6 py-3">
                      Video Transcript
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-0 pr-6 pl-0">
                      <div
                        style="text-align: left;"
                        v-html="currentLesson.transcript"
                      ></div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div v-else-if="currentLesson.type == 'pdf'">
                <vue-pdf-embed :source="currentLesson.contentUrlSigned" />
                <!-- <pdf :src="currentLesson.contentUrlSigned" /> -->
              </div>
              <div
                class="pa-5 align-text-left ql-editor"
                v-html="currentLesson.content"
                v-if="
                  !['quiz', 'assessment', 'survey'].includes(currentLesson.type)
                "
              />

              <div class="flex-column d-flex">
                <QuizV2
                  v-if="
                    ['quiz', 'assessment', 'survey'].includes(
                      currentLesson.type
                    )
                  "
                  :questions="currentLesson.LearningQuizQuestions"
                  :module="course.learningModuleId"
                  :chapter="
                    course.LearningChapters[currentChapterIndex]
                      .learningChapterId
                  "
                  :card="currentLesson"
                  :completedStatus="quizCompleted"
                  :cardType="currentLesson.type"
                  @postQuizLog="postQuizLearningLog"
                  @nextCard="loadNextLesson"
                  @doneLoading="handleQuizDoneLoading"
                />
                <div v-else-if="currentLesson.type == 'giving'">
                  <v-divider class="mx-3" />
                  <StripeGivingWidget
                    class="px-2 mx-auto pt-3 pb-5"
                    style="max-width: 450px; min-height: 350px;"
                    preloadMenu="CC"
                    source="LEARNING_GIVING"
                  />
                </div>
              </div>

              <!-- Learner confidence rating - commenting out for now
                <v-row
                v-if="
                  !['quiz', 'assessment', 'survey'].includes(
                    currentLesson.type
                  ) &&
                    this.course.LearningChapters[this.currentChapterIndex]
                      .order !== -1
                "
              >
                <v-col
                  ><b
                    >How confident are you in your understanding of this
                    topic?</b
                  ></v-col
                >
              </v-row>
              <v-row
                v-if="
                  !['quiz', 'assessment', 'survey'].includes(
                    currentLesson.type
                  ) &&
                    this.course.LearningChapters[this.currentChapterIndex]
                      .order !== -1
                "
              >
                <v-col class="mb-4"
                  ><v-btn
                    @click="updateLearnerConfidence(0)"
                    :color="
                      currentLesson.learnerConfidence == 0
                        ? 'grey'
                        : 'lightGrey'
                    "
                    class="mr-2"
                    :class="$vuetify.breakpoint.xs ? 'mt-1' : ''"
                    depressed
                    large
                    >0%</v-btn
                  ><v-btn
                    @click="updateLearnerConfidence(20)"
                    :color="
                      currentLesson.learnerConfidence == 20
                        ? 'grey'
                        : 'lightGrey'
                    "
                    class="mr-2"
                    :class="$vuetify.breakpoint.xs ? 'mt-1' : ''"
                    depressed
                    large
                    >20%</v-btn
                  ><v-btn
                    @click="updateLearnerConfidence(40)"
                    :color="
                      currentLesson.learnerConfidence == 40
                        ? 'grey'
                        : 'lightGrey'
                    "
                    class="mr-2"
                    :class="$vuetify.breakpoint.xs ? 'mt-1' : ''"
                    depressed
                    large
                    >40%</v-btn
                  ><v-btn
                    @click="updateLearnerConfidence(60)"
                    :color="
                      currentLesson.learnerConfidence == 60
                        ? 'grey'
                        : 'lightGrey'
                    "
                    class="mr-2"
                    :class="$vuetify.breakpoint.xs ? 'mt-1' : ''"
                    depressed
                    large
                    >60%</v-btn
                  ><v-btn
                    @click="updateLearnerConfidence(80)"
                    :color="
                      currentLesson.learnerConfidence == 80
                        ? 'grey'
                        : 'lightGrey'
                    "
                    class="mr-2"
                    :class="$vuetify.breakpoint.xs ? 'mt-1' : ''"
                    depressed
                    large
                    >80%</v-btn
                  ><v-btn
                    @click="updateLearnerConfidence(100)"
                    :color="
                      currentLesson.learnerConfidence == 100
                        ? 'grey'
                        : 'lightGrey'
                    "
                    :class="$vuetify.breakpoint.xs ? 'mt-1' : ''"
                    depressed
                    large
                    >100%</v-btn
                  ></v-col
                >
              </v-row> -->
            </v-card>
            <!-- <div class="d-flex">
              <v-btn
                v-if="
                  !['quiz', 'assessment', 'survey'].includes(currentLesson.type)
                &&
                  ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                class="mt-5 mr-4 ml-auto"
                color="primary"
                @click="loadNextLesson()"
                :width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? '140'
                    : $vuetify.breakpoint.md
                    ? '140'
                    : '180'
                "
                :disabled="
                  course.forceLinearProgress &&
                    ['quiz', 'assessment', 'survey'].includes(
                      currentLesson.type
                    ) &&
                    currentLesson.passingRequired &&
                    (currentLesson.latestLearningLog == undefined ||
                      (currentLesson.latestLearningLog != undefined &&
                        currentLesson.latestLearningLog != null &&
                        currentLesson.latestLearningLog.score <
                          currentLesson.passingScore))
                "
                >{{ nextButtonText }}</v-btn
              >
            </div> -->
            <!-- this div is just to find the scroll to position -->
            <!-- <div id="mobile-anchor-div"><p>Hello</p></div> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="8"
            md="7"
            class="pl-4"
            v-if="this.viewResourcesCard !== true"
          >
            <!-- <div> -->
            <!--<div
              v-if="
                this.course.LearningChapters[this.currentChapterIndex].order !==
                  -1 && currentLesson.type != 'survey'
              "
              class="d-flex align-center"
            >
              <span
                class="word-break mr-3"
                v-if="
                  !['quiz', 'assessment', 'survey'].includes(currentLesson.type)
                "
                >How would you rate this
                <span
                  v-if="
                    ['quiz', 'assessment', 'survey'].includes(
                      currentLesson.type
                    )
                  "
                  >quiz</span
                ><span v-else>content</span>?</span
              >
               </div> -->
            <!-- <div
                class="d-flex"
                v-if="
                  !['quiz', 'assessment', 'survey'].includes(currentLesson.type)
                "
              >
                <div v-for="score in currentLesson.learnerRating" :key="score">
                  <v-icon
                    color="yellow"
                    large
                    @click="updateLearnerRating(score, false)"
                    >mdi-star</v-icon
                  >
                </div>

                <div
                  v-for="score in 5 - currentLesson.learnerRating"
                  :key="'A' + score"
                >
                  <v-icon
                    color="yellow"
                    large
                    @click="updateLearnerRating(score, true)"
                    >mdi-star-outline</v-icon
                  >
                </div>
              </div> 
            </div>--></v-col
          >
          <v-col sm="4" md="3" class="d-flex justify-end pr-4">
            <v-btn
              v-if="
                !['quiz', 'assessment', 'survey'].includes(
                  currentLesson.type
                ) && this.viewResourcesCard !== true
              "
              color="primary"
              @click="loadNextLesson()"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? '140'
                  : $vuetify.breakpoint.md
                  ? '140'
                  : '180'
              "
              :disabled="
                course.forceLinearProgress &&
                  ['quiz', 'assessment', 'survey'].includes(
                    currentLesson.type
                  ) &&
                  currentLesson.passingRequired &&
                  (currentLesson.latestLearningLog == undefined ||
                    (currentLesson.latestLearningLog != undefined &&
                      currentLesson.latestLearningLog != null &&
                      currentLesson.latestLearningLog.score <
                        currentLesson.passingScore))
              "
              >{{ nextButtonText }}</v-btn
            >
          </v-col>
        </v-row>
        <!-- <v-row
          v-if="
            this.course.LearningChapters[this.currentChapterIndex].order !== -1
          "
        >
        </v-row> -->
        <!-- Resources section to be displayed if Resources are attached to the module. -->
        <v-row
          v-if="
            course.LearningAttachments.length > 0 &&
              modulePercentComplete === 1 &&
              currentChapterIndex == course.LearningChapters.length - 1
          "
        >
          <v-col md="10" class="pt-0">
            <v-card class="full-width mt-6 mb-2 pa-5">
              <div class="align-text-left">
                <v-card-title class="pl-0">Helpful Resources</v-card-title>
                <p>
                  Use these additional documents as a quick way to reference
                  what you've learned
                </p>
                <v-data-table
                  :headers="learningAttachmentColumns"
                  :items="course === null ? [] : course.LearningAttachments"
                  item-key="LearningModuleAttachmentId"
                  :items-per-page="10"
                  class="elevation-0 cursor-pointer mt-6"
                  hide-default-footer
                  @click:row="downloadLearningAttachment"
                >
                  <template v-slot:[`item.fileType`]="{ item }">
                    <v-icon color="red" x-large v-if="item.fileType === 'PDF'"
                      >mdi-file-pdf-box</v-icon
                    >
                    <v-icon
                      color="blue"
                      x-large
                      v-else-if="item.fileType === 'DOCX'"
                      >mdi-file-word</v-icon
                    >
                    <v-icon
                      color="green"
                      x-large
                      v-else-if="item.fileType === 'XLSX'"
                      >mdi-file-excel</v-icon
                    >
                    <v-icon
                      color="red"
                      x-large
                      v-else-if="item.fileType === 'PPTX'"
                      >mdi-file-powerpoint</v-icon
                    >
                    <v-icon
                      color="blue"
                      x-large
                      v-else-if="item.fileType === 'PNG'"
                      >mdi-file-image</v-icon
                    >
                    <v-icon
                      color="green"
                      x-large
                      v-else-if="item.fileType === 'CSV'"
                      >mdi-file-delimited</v-icon
                    >
                    <v-icon color="blue" x-large v-else>mdi-file</v-icon>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <div class="d-flex flex-column my-3">
                      <b>{{ item.name }}</b>
                      <span class="mediumGrey--text"
                        >{{ item.fileExtension }} File |
                        {{ item.fileSize }}</span
                      >
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <span>No documents attached to this Learning Module</span>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="this.viewResourcesCard == true">
          <v-col
            cols="12"
            sm="8"
            md="7"
            class="pl-4 align-text-left"
            v-if="this.viewResourcesCard == true"
          >
            <span class="mediumGrey--text ml-3"> You're all done!</span>
            <p class="mediumGrey--text ml-3">
              Use the materials above as a quick referece
            </p>
          </v-col>
          <v-col sm="4" md="3" class="d-flex justify-end pr-4">
            <v-btn
              color="primary"
              @click="loadNextLesson()"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? '140'
                  : $vuetify.breakpoint.md
                  ? '140'
                  : '180'
              "
              >{{ nextButtonText }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Dialog used to display a badge they just earned -->
    <v-dialog
      v-model="badgeDialog"
      width="400"
      transition="dialog-bottom-transition"
      v-if="course && modulePercentComplete === 1"
    >
      <v-card>
        <!--//this section is for when there's a badge and no incentive OR a badge and an inventive-->
        <div
          class="d-flex justify-start align-center full-width flex-column py-5"
        >
          <h2 v-if="course.Badge != null">Badge awarded!</h2>
          <h2 v-else>Awesome!</h2>
          <br />
          <h4>
            {{
              course.Badge && course.Badge.displayName
                ? course.Badge.displayName
                : course.displayName
            }}
          </h4>
          <v-img
            v-if="course.Badge != null && course.Badge.imageUrl"
            :src="course.Badge.imageUrl"
            :aspect-ratio="1 / 1"
            width="90"
            class="mx-1 my-2"
          />
          <v-img
            v-else-if="course.Badge === null && course.incentiveAward === null"
            src="@/assets/Demo/Trophy_Badge.png"
            :aspect-ratio="1 / 1"
            width="90"
            class="my-2"
          />
          <br />
          <p>{{ course.Badge ? course.Badge.description : "" }}</p>
          <div
            v-if="
              course.incentiveAward && course.incentiveAward.awardId != null
            "
            class="d-flex align-center"
          >
            <v-icon class="mx-2 border-radius wallet-icon" color="success"
              >mdi-wallet</v-icon
            >

            <!--//this section is for when there's a cash-based inventive display "$ and value"-->
            <span
              v-if="course.incentiveValue !== null && course.incentiveAward"
            >
              +{{
                (course.incentiveAward && course.incentiveAward.awardId == 1
                  ? "$"
                  : "") + course.incentiveValue.toFixed(2)
              }}
            </span>
            <span
              v-if="course.incentiveAward && course.incentiveAward.awardId != 1"
              class="ml-2"
            >
              {{
                course.incentiveValue == 1
                  ? course.incentiveAward.unitSingular
                  : course.incentiveAward.unitPlural
              }}</span
            >
          </div>
          <!-- <p>
            {{
              course.incentiveValue != null &&
              course.incentiveAward.awardId != 1
                ? course.incentiveAward.awardDescription
                : ""
            }}
          </p> -->
          <span class="mt-4">For completing {{ course.displayName }}</span>
          <br />
          <v-btn color="primary" @click="badgeDialog = false">Awesome!</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import LearningService from "@/services/LearningService";
import BadgeService from "@/services/BadgeService";
import ContentService from "@/services/ContentService.js";

import { formatFileType } from "@/shared_data/functions.js";

import Robin from "@/components/Robin.vue";
import LoadingDialog from "@/components/loadingDialog.vue";
import QuizV2 from "@/components/QuizV2";
import StripeGivingWidget from "@/components/stripe/StripeGivingWidget";

import { mapState } from "vuex";
import moment from "moment";
import confetti from "canvas-confetti";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Curious.json";

export default {
  name: "LearningDetails",
  title: "Whistle | Learning",
  props: {
    learningId: {
      default: null,
      type: String
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    sourcePage: {
      default: null,
      type: String
    },
    sourcePageId: {
      default: null,
      type: String
    }
  },
  components: {
    QuizV2,
    Lottie,
    LoadingDialog,
    VuePdfEmbed,
    Robin,
    StripeGivingWidget
    // pdf,
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },
      anim: null,
      loadingCourse: false,
      course: null,
      currentChapterIndex: null,
      currentLessonIndex: null,
      currentLesson: null,
      quizCompleted: null,
      answersGiven: [],
      endStateLesson: {
        displayName: "Learning Module Complete",
        content:
          "<h2>Congrats!</h2><p>You've finished another module, well done!</p><p>Check the Learning Overview for other available learning modules."
      },
      videoUrl: "",
      content: [],
      badgeDialog: false,
      viewResourcesCard: false,
      viewConclusionCard: true,

      learningAttachmentColumns: [
        {
          text: "Type",
          align: "start",
          value: "fileType",
          sortable: false,
          width: "20%"
        },
        {
          text: "Name",
          value: "name",
          width: "30%",
          sortable: false
        },
        {
          text: "Description",
          value: "description",
          width: "50%",
          sortable: false
        }
      ],

      moduleNotFound: false
    };
  },
  created() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
    console.log("Loading learning module ", this.learningId);
    this.getModule();
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    rightClickHandler: function(e) {
      e.preventDefault();
    },
    getModule() {
      this.loadingCourse = true;
      LearningService.getModuleContent(this.learningId).then(modules => {
        if (modules.length > 0) {
          var module = JSON.parse(JSON.stringify(modules))[0];

          this.course = module;
          console.log("THIS IS THE CURRENT STATS ----------------");
          console.log(module);

          // First, we sort by order
          var chapters = this.course.LearningChapters.sort(function(a, b) {
            return new Date(a.order) - new Date(b.order);
          });

          if (chapters.length > 0 && chapters[0].order == -1) {
            // We reorder if the end card is here with an order of -1
            var tempChapter = chapters[0];
            chapters.splice(0, 1);
            chapters.push(tempChapter);
          }
          this.course.LearningChapters = chapters;

          for (var i = 0; i < this.course.LearningChapters.length; i++) {
            // Sorts cards by order
            console.log("sorting cards");
            var cards = JSON.parse(
              JSON.stringify(this.course.LearningChapters[i].LearningCards)
            );
            cards.sort(function(a, b) {
              return new Date(a.order) - new Date(b.order);
            });

            this.course.LearningChapters[i].LearningCards = cards;
          }

          console.log("map cards");
          this.course.LearningChapters.map(x => {
            var obj = x;
            obj.LearningCards.map(y => {
              var card = y;
              card.updatedAtFormatted = moment(card.updatedAt)
                .local()
                .format("MMMM Do YYYY, h:mm a");
              return card;
            });
            return obj;
          });

          console.log(this.course.LearningAttachments);
          for (var j = 0; j < this.course.LearningAttachments.length; j++) {
            //Bring name and desc out of the content object so it can be accessed by the data table
            this.course.LearningAttachments[
              j
            ].name = this.course.LearningAttachments[j].Content.name;
            this.course.LearningAttachments[
              j
            ].description = this.course.LearningAttachments[
              j
            ].Content.description;
            this.course.LearningAttachments[
              j
            ].fileSize = this.course.LearningAttachments[j].Content.fileSize;
            var filepath = this.course.LearningAttachments[j].Content.filePath;

            this.course.LearningAttachments[j].fileType = formatFileType(
              filepath
            );

            this.course.LearningAttachments[j].fileExtension =
              filepath.lastIndexOf(".") != -1
                ? filepath
                    .toUpperCase()
                    .substring(filepath.lastIndexOf(".") + 1)
                : "Unknown";
          }
          // if learning chpts > 0
          if (this.course.LearningChapters.length >= 1) {
            // if learningCards > 0
            // if (this.course.LearningChapters[0].LearningCards.length > 0) {
            //   this.currentChapterIndex = 0;
            //   this.currentLessonIndex = 0;
            //   this.currentLesson = JSON.parse(
            //     JSON.stringify(this.course.LearningChapters[0].LearningCards[0])
            //   );
            //   this.course.LearningChapters[0].active = true;
            //   this.quizCompleted = this.currentLesson.completed;
            // }

            // This boolean will flip once we parse the chapters/cards and we find the first instance of something not completed. Then we set that as our starting point.
            var foundStartCard = false;
            this.course.LearningChapters.forEach((chapter, chapterIndex) => {
              var cardCount = chapter.LearningCards.length;
              var completeCards = 0;
              chapter.locked = this.course.forceLinearProgress;
              chapter.active = false;

              chapter.LearningCards.forEach((card, cardIndex) => {
                card.learnerRating = 0;
                card.learnerConfidence = null;
                card.locked = this.course.forceLinearProgress;
                if (card.latestLearningLog) {
                  card.learnerRating =
                    card.latestLearningLog.learnerRating == null
                      ? 0
                      : card.latestLearningLog.learnerRating;
                  card.learnerConfidence =
                    card.latestLearningLog.learnerConfidence;
                }
                if (!card.completed && !foundStartCard) {
                  console.log("if card not completed");
                  console.log("ch index: " + chapterIndex);
                  console.log("card index: " + cardIndex);
                  this.currentChapterIndex = chapterIndex;
                  this.currentLessonIndex = cardIndex;
                  this.currentLesson = JSON.parse(JSON.stringify(card));
                  this.course.LearningChapters[chapterIndex].active = true;
                  this.quizCompleted = card.completed;
                  foundStartCard = true;
                }

                // Regardless of if this is the card we're loading, we increment if it's complete so we can get an accurate progress number
                if (card.completed) {
                  console.log("increment card completed");
                  completeCards++;
                }

                // Finally, we check if foundStartCard was never flipped, aka they finished the entire thing. If so, we start at the beginning
                // We only check this if we've cycled through all chapters and cards and are at the end
                if (
                  !foundStartCard &&
                  chapterIndex == this.course.LearningChapters.length - 1 &&
                  cardIndex == chapter.LearningCards.length - 1 //&&
                  //this.course.Badge == null
                ) {
                  console.log("foundStartCard check");

                  var lastChapter = this.course.LearningChapters.length - 1;
                  if (this.course.LearningAttachments.length > 0) {
                    this.viewResourcesCard = true;
                  }

                  this.currentChapterIndex = lastChapter;
                  this.course.LearningChapters[lastChapter].active = true;
                  if (
                    this.course.LearningChapters[lastChapter].LearningCards
                      .length > 0
                  ) {
                    this.currentLessonIndex = 0;
                    this.currentLesson = JSON.parse(
                      JSON.stringify(
                        this.course.LearningChapters[lastChapter]
                          .LearningCards[0]
                      )
                    );
                    this.quizCompleted = this.course.LearningChapters[
                      lastChapter
                    ].LearningCards[0].completed;
                  }
                }
              });

              if (cardCount == 0) {
                // We avoid a divide by 0 error
                chapter.percentComplete = 0;
              } else {
                chapter.percentComplete = (
                  (completeCards / cardCount) *
                  100
                ).toFixed(0);
              }

              // We get the previous chapter and if they've completed it, then we unlock it
              if (chapterIndex > 0 && this.course.forceLinearProgress) {
                var previousChapter = this.course.LearningChapters[
                  chapterIndex - 1
                ];
                if (
                  previousChapter.percentComplete != null &&
                  previousChapter.percentComplete >= 100
                ) {
                  chapter.locked = false;
                }
              } else if (chapterIndex == 0) {
                chapter.locked = false;
              }
            });
          }

          this.loadingCourse = false;
        } else {
          // this.leaveCourse();
          // Show module not found
          this.loadingCourse = false;
          this.moduleNotFound = true;
        }
      });
    },
    downloadLearningAttachment(item) {
      console.log(item);
      ContentService.downloadContent(item.contentId, item.Content.filePath);
    },
    leaveCourse() {
      if (this.sourcePage && this.sourcePageId) {
        if (this.sourcePage == "programdetails") {
          this.$router.push({
            name: "programdetails",
            params: {
              programId: this.sourcePageId
            }
          });
        } else {
          this.$router.push({
            name: "learning"
          });
        }
      } else {
        this.$router.push({
          name: "learning"
        });
      }
    },
    updateLearnerRating(starIndex, emptyStar) {
      var oldScore = this.currentLesson.learnerRating;
      if (emptyStar) {
        // We are increasing our learner rating because they clicked an empty star
        var newScore = (oldScore += starIndex);
        this.currentLesson.learnerRating = newScore;
      } else {
        // We are decreasing score since they clicked a filled star
        if (oldScore == starIndex) {
          // They clicked their current rating so we wipe it
          newScore = 0;
        } else {
          newScore = starIndex;
        }
        console.log(
          "Updating learner confidence: old - " +
            oldScore +
            ", new - " +
            newScore
        );
        this.currentLesson.learnerRating = newScore;
        this.course.LearningChapters[this.currentChapterIndex].LearningCards[
          this.currentLessonIndex
        ].learnerRating = newScore;
      }
    },
    updateLearnerConfidence(value) {
      var oldValue = this.currentLesson.learnerConfidence;
      if (value == oldValue) {
        var newValue = 0;
      } else {
        newValue = value;
      }
      console.log(
        "Updating learner confidence: old - " + oldValue + ", new - " + newValue
      );
      this.currentLesson.learnerConfidence = newValue;
      this.course.LearningChapters[this.currentChapterIndex].LearningCards[
        this.currentLessonIndex
      ].learnerConfidence = newValue;
    },
    loadChapter(index, unlockFlag, viewResources = false) {
      // Check if we are on last chapter, if we are, set viewResourcesCard to false
      if (
        this.currentChapterIndex == this.course.LearningChapters.length - 1 &&
        viewResources == false
      ) {
        this.viewResourcesCard = false;
      }

      // We only load the chapter if the chapter is unlocked
      if (!this.course.LearningChapters[index].locked || unlockFlag) {
        console.log("INDEX !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        console.log(index);
        //We have to change the active flag on the current chapter and update
        console.log(
          "Current chapter (preclick) ",
          this.course.LearningChapters[this.currentChapterIndex]
        );
        this.course.LearningChapters[this.currentChapterIndex].active = false;
        this.currentChapterIndex = index;
        this.course.LearningChapters[index].active = true;
        this.course.LearningChapters[index].locked = false;
        if (this.course.LearningChapters[index].LearningCards.length > 0) {
          this.currentLessonIndex = 0;
          this.currentLesson = JSON.parse(
            JSON.stringify(
              this.course.LearningChapters[index].LearningCards[
                this.currentLessonIndex
              ]
            )
          );
          this.quizCompleted = this.currentLesson.completed;

          console.log(this.currentLesson);
          // Check if we're loading the last chapter and if it has 1 card, then we play confetti
          if (
            this.currentChapterIndex ==
              this.course.LearningChapters.length - 1 &&
            this.course.LearningChapters[this.currentChapterIndex].LearningCards
              .length == 1
          ) {
            var mod = this.course;
            var chap = this.course.LearningChapters[this.currentChapterIndex];
            var card = chap.LearningCards[this.currentLessonIndex];
            // update completed status
            this.course.LearningChapters[
              this.currentChapterIndex
            ].LearningCards[this.currentLessonIndex].completed = true;
            chap.completed = true;
            chap.percentComplete = 100;
            mod.completedChapterCount = mod.completedChapterCount++;
            mod.percentComplete = mod.completedChapterCount / mod.chapterCount;

            //resources logic to not show confetti on resources tab page
            if (
              this.viewResourcesCard == false &&
              this.viewConclusionCard == true
            ) {
              // We're in the last chapter, play confetti
              confetti({
                particleCount: 300,
                spread: 70,
                scalar: 1.13,
                origin: { y: 0.95, x: 0.56 }
              });
              confetti({
                particleCount: 200,
                spread: 100,
                decay: 0.91,
                scalar: 0.8,
                origin: { y: 0.85, x: 0.52 }
              });
              confetti({
                particleCount: 100,
                spread: 120,
                startVelocity: 45,
                scalar: 0.8,
                origin: { y: 0.99, x: 0.54 }
              });
            }

            // If there is an incentive, then we show the popup
            if (
              (this.course.Badge != null && this.course.badgeId != null) ||
              (this.course.incentiveAward != null &&
                this.course.incentiveAward.awardId != null)
            ) {
              this.badgeDialog = true;
            } else {
              this.badgeDialog = false;
            }

            // If there is a badge attached, we create a userBadge
            if (this.course.Badge && this.course.badgeId) {
              // Then we post that they got the badge with confetti
              console.log("course data");
              console.log(this.course);

              console.log("Posting user badge assignment");
              if (this.course.badgeId) {
                var userBadge = {
                  clientId: this.userProfile.clientId,
                  userId: this.userProfile.userId,
                  badgeId: this.course.badgeId,
                  status: "Active",
                  createdBy: this.userProfile.userId,
                  updatedBy: this.userProfile.userId
                };

                BadgeService.createUserBadge(userBadge).then(userBadge => {
                  console.log("User badge response: ", userBadge);
                });
              }
            }

            // Insert learning log
            if (!["quiz", "assessment", "survey"].includes(card.type)) {
              this.postLearningLog(
                this.$auth.user.sub,
                card.learningCardId,
                card.learningCardAssociationId,
                null,
                null
              );
            }
          } else {
            this.viewResourcesCard = false;
            this.viewConclusionCard = true;
          }
        } else {
          this.currentLesson = null;
        }
      }
    },
    // starts the next lesson
    loadNextLesson() {
      console.log("loading next lesson");
      var mod = this.course;
      var chap = this.course.LearningChapters[this.currentChapterIndex];
      var card = chap.LearningCards[this.currentLessonIndex];
      var nextLesson = this.currentLessonIndex + 1;
      var totalLessonsInChap = chap.LearningCards.length;

      if (
        nextLesson > totalLessonsInChap - 1 &&
        this.currentChapterIndex == mod.LearningChapters.length - 1
      ) {
        // Logic for Resources next button
        if (this.viewResourcesCard == true) {
          this.loadChapter(0);
        } else {
          console.log("We are done with module, exit");

          this.leaveCourse();

          this.currentLessonIndex++;

          // load generic end card

          // update completed status
          card.completed = true;
          chap.completed = true;
          chap.percentComplete = 100;
          mod.completedChapterCount = mod.completedChapterCount++;
          mod.percentComplete = mod.completedChapterCount / mod.chapterCount;
        }

        // if last card in chapter
      } else if (
        nextLesson > totalLessonsInChap - 1 &&
        this.currentChapterIndex != mod.LearningChapters.length - 1
      ) {
        //calculate module completion
        mod.completedChapterCount = mod.completedChapterCount++;
        mod.percentComplete = mod.completedChapterCount / mod.chapterCount;

        console.log("update percentcomplet: " + mod.percentComplete);

        console.log("We are done with chapter");
        // We are loading the next chapter
        console.log("!!!!!!! ----- lessons in chap ----- !!!!!!");
        console.log(totalLessonsInChap);
        card.completed = true;
        chap.percentComplete = 100;
        console.log(chap);
        // If the module was a quiz, then we don't update the logs because we did that in the quiz module
        if (!["quiz", "assessment", "survey"].includes(card.type)) {
          this.postLearningLog(
            this.$auth.user.sub,
            card.learningCardId,
            card.learningCardAssociationId,
            null,
            null
          );
        }
        this.loadChapter(this.currentChapterIndex + 1, true);
        // load lesson will scroll
        this.loadLesson(0);

        // Scrolls user to the top
        if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
          window.scrollTo(0, 0);
        }
      } else {
        console.log("Loading next lesson");
        //else, load next lesson

        //update completed status and percentages
        // count completed cards in chapter
        var completedCardsInChapter = 0;
        chap.LearningCards.forEach(completedCard => {
          if (completedCard.completed == true) {
            completedCardsInChapter++;
          }
        });
        // calculate chapter prog
        chap.percentComplete =
          (completedCardsInChapter / chap.LearningCards.length).toFixed(0) *
          100;

        card = this.course.LearningChapters[this.currentChapterIndex]
          .LearningCards[this.currentLessonIndex];
        console.log("CARD ID vvvvvvvvvv");
        console.log(card.learningCardId);
        //console.log(chap.percentComplete)
        card.completed = true;

        // If the module was a quiz, then we don't update the logs because we did that in the quiz module
        if (!["quiz", "assessment", "survey"].includes(card.type)) {
          this.postLearningLog(
            this.userProfile.userId,
            card.learningCardId,
            card.learningCardAssociationId,
            null,
            null
          );
        }
        console.log(card.completed);
        // load lesson will scroll
        this.loadLesson(nextLesson);

        // Scrolls user to the top unless on sm & xs screens
        if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
          window.scrollTo(0, 0);
        }
        // else {
        //   // const cardY = document.getElementById("lessonCard");
        //   // const rect = cardY.getBoundingClientRect();
        //   // console.log(rect.top);
        //   // window.scrollTo(0, rect.top - 90);
        //   console.log('small window scrolling');
        //   const cardY = document.getElementById("lessonCard");
        //   cardY.scrollIntoView({
        //     behavior: 'smooth',
        //     block: "end",
        //   });
        //   // let y = this.computeScrollToPosition();
        //   // window.scrollTo(0, y);
        // }
      }
    },
    loadLesson(index) {
      if (
        index == 0 ||
        !this.course.forceLinearProgress ||
        this.course.LearningChapters[this.currentChapterIndex].LearningCards[
          index
        ].completed ||
        (index > 0 &&
          this.course.LearningChapters[this.currentChapterIndex].LearningCards[
            index - 1
          ].completed)
      ) {
        // We only want the user to click on unlocked cards
        console.log(`THIS IS THE LESSON INDEX: ---------- ${index}`);
        this.currentLessonIndex = index;
        this.currentLesson = JSON.parse(
          JSON.stringify(
            this.course.LearningChapters[this.currentChapterIndex]
              .LearningCards[index]
          )
        );
        // If we're loading an individual card, we can set resources to hide them
        this.viewResourcesCard = false;
        console.log(this.course.LearningChapters);
        console.log(
          "ch index:" +
            this.currentChapterIndex +
            " learning card index:" +
            index
        );
        console.log(this.currentLesson.completed);

        // calculate progress

        // var progress = (
        //   (index /
        //     this.course.LearningChapters[this.currentChapterIndex].LearningCards
        //       .length) *
        //   100
        // ).toFixed(0);
        var chap = this.course.LearningChapters[this.currentChapterIndex];
        var completedCardsInChapter = 0;
        chap.LearningCards.forEach(card => {
          if (card.completed == true) {
            completedCardsInChapter++;
          }
        });
        console.log(completedCardsInChapter);

        chap.percentComplete = (
          (completedCardsInChapter / chap.LearningCards.length) *
          100
        ).toFixed(0);

        var progress = this.course.LearningChapters[this.currentChapterIndex]
          .percentComplete;

        console.log(progress);

        if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
          window.scrollTo(0, 0);
        } else {
          this.scrollLessonIntoView();
          console.log("scrolling into view");
          // const cardY = document.getElementById("lessonCard");
          // cardY.scrollIntoView({ behavior: 'smooth', block: 'end' });
          // const rect = cardY.getBoundingClientRect();
          // console.log(rect.top);
          // window.scrollTo(0, rect.top - 90);
          // console.log('small screen scrolling');
          // let y = this.computeScrollToPosition();
          // window.scrollTo(0, y);
        }

        // if (this.currentLesson.type === "quiz") {
        //   console.log(this.currentLesson.learningCardId);

        //   LearningService.getLearningCard(this.currentLesson.learningCardId).then(
        //     (card) => {
        //       console.log("Loaded card :", card.learningCardId);
        //       console.log(card.LearningQuizQuestions);
        //       this.currentLesson = card;
        //     }
        //   );
        // }
      }
    },
    loadResources() {
      this.viewResourcesCard = true;
      // this.viewConclusionCard = false;
      this.loadChapter(this.course.LearningChapters.length - 1, true, true);
      // this.loadCard(0);
    },
    postQuizLearningLog(score, answersGiven, passingRequired, passingScore) {
      this.postLearningLog(
        this.userProfile.userId,
        this.currentLesson.learningCardId,
        this.currentLesson.learningCardAssociationId,
        score,
        answersGiven,
        passingRequired,
        passingScore
      );
    },
    postLearningLog(
      partId,
      cardId,
      learningCardAssociationId,
      quizScore = null,
      userAnswers = null,
      passingRequired = false,
      passingScore = null
    ) {
      // create completed date
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;
      var body = {
        userId: partId,
        clientId: this.userProfile.clientId,
        learningCardId: cardId,
        learningCardAssociationId: learningCardAssociationId,
        completed:
          // Doesn't mark quiz as completed if passing is required, but we still store the log
          quizScore == null || !(passingRequired && passingScore)
            ? true
            : passingRequired &&
              passingScore &&
              quizScore &&
              quizScore >= passingScore
            ? true
            : false,
        completedDate: dateTime,
        learnerRating: this.currentLesson.learnerRating
          ? this.currentLesson.learnerRating
          : null,
        learnerConfidence: this.currentLesson.learnerConfidence
          ? this.currentLesson.learnerConfidence
          : null,
        score: quizScore
      };
      if (userAnswers) {
        body.answersGiven = userAnswers.join(",");
      }
      console.log("-------------this is the body-------------");
      console.log(body);
      console.log(this.course);
      LearningService.createLearningLog(
        body,
        this.course.AssociatedPrograms || []
      );
      // Now we add this score to the course object
      this.course.LearningChapters[this.currentChapterIndex].LearningCards[
        this.currentLessonIndex
      ].latestLearningLog = body;
      // We add the learner rating and confidence at the card level too
      this.course.LearningChapters[this.currentChapterIndex].LearningCards[
        this.currentLessonIndex
      ].learnerRating = this.currentLesson.learnerRating
        ? this.currentLesson.learnerRating
        : null;
      this.course.LearningChapters[this.currentChapterIndex].LearningCards[
        this.currentLessonIndex
      ].learnerConfidence = this.currentLesson.learnerConfidence
        ? this.currentLesson.learnerConfidence
        : null;
      // Commenting out the json parser because the quiz was re-rendering everytime we did that (creating a loop)
      // var currentLesson = JSON.parse(JSON.stringify(this.currentLesson));
      this.currentLesson.latestLearningLog = body;
      // this.currentLesson = currentLesson;
    },
    scrollLessonIntoView() {
      // let target = document.getElementById('mobile-anchor-div');
      // if the lesson is not a quiz assessment or survey
      // console.log(this.currentLesson.type);
      // target.scrollIntoView({ behavior: 'smooth', block: 'end' });
      console.log("scrolling");
      // this has a timeout because if it doesnt it will scroll before the quiz is rendered and not scroll far enough
      setTimeout(() => {
        // minus 60 because that is the amount of footer
        console.log(document.documentElement.scrollHeight);
        window.scrollTo(
          0,
          document.documentElement.scrollHeight -
            document.documentElement.clientHeight -
            50
        );
      }, 0);
    },
    handleQuizDoneLoading() {
      if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
        this.scrollLessonIntoView();
      }
    }
  },

  computed: {
    ...mapState(["userProfile"]),
    nextButtonText() {
      if (
        this.currentChapterIndex == this.course.chapterCount - 1 &&
        this.currentLessonIndex ==
          this.course.LearningChapters[this.currentChapterIndex].LearningCards
            .length -
            1 &&
        this.viewResourcesCard == false
      ) {
        return "Finish";
      } else {
        return "Continue";
      }
    },
    modulePercentComplete() {
      if (
        this.course &&
        this.course.cardCount !== null &&
        this.course.cardCount !== 0
      ) {
        // Sum up all the cards to calc their progress
        var completedCards = 0;
        this.course.LearningChapters.forEach(chapter => {
          chapter.LearningCards.forEach(card => {
            if (card.completed) completedCards++;
          });
        });

        return completedCards / this.course.cardCount;
      }
      return 0;
    },
    playerOptions() {
      return {
        height: 400,

        autoplay: false,
        controls: true,
        techOrder: ["html5"],
        sourceOrder: true,
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            withCredentials: false,
            type: "video/mp4",
            src: this.currentLesson
              ? this.currentLesson.contentUrlSigned
                ? this.currentLesson.contentUrlSigned
                : null
              : null
          }
        ]
      };
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-image {
  margin: 0 0 0 12px;
}
.border-radius {
  border-radius: 10px;
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.card-icon-box {
  max-width: 35px;
  max-height: 35px;
  width: 35px;
  height: 35px;
}
.card-lesson-box {
  /* border: 1px solid; */
  /* border-color: red; */
  /* border-color: var(--v-lightGrey-base); */
}

.row + .row {
  margin-top: 0px;
}

.expansion-outlined {
  border: 1px solid #ccc;
  margin-top: 20px;
}

.expansion-outlined .v-expansion-panel--active {
  background: rgba(0, 0, 0, 0.04);
}

/* Hiding icon and learning title xs screens */
@media only screen and (max-width: 500px) {
  .brain-icon {
    display: none;
  }
  .learning-header {
    display: none;
  }
}

/* Media query to change layout to column*/
@media only screen and (max-width: 600px) {
  .course-div {
    display: flex;
    flex-direction: column;
  }
}
</style>

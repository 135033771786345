<template>
  <div
    class="d-flex pb-3 justify-center"
    style="position: relative; max-height: 100%"
  >
    <div
      class="white px-2"
      style="
        position: relative;
        max-height: 100%;
        width: 100%;
        min-height: 100%;
      "
    >
      <div
        ref="messages"
        style="position: relative;  max-height: 50vh"
        class="overflow-auto no-scroll pt-5"
      >
        <!-- <div
          :style="`width: 100%; height: 100%; max-height: 100%;`"
          class=""
          @click="scrollToBottom"
        > -->
        <v-spacer style="height: 420px;" />

        <div
          v-for="message in messages"
          :key="message.id"
          class="d-flex justify-center flex-column"
        >
          <message :text="message.text" :kind="message.kind" />
        </div>
        <!-- <message text="Yo yo" v-for="i in 0" :key="i" kind="sent" /> -->
      </div>

      <div class="no-borders" style="max-height: 5vh;display:inline;">
        <typing v-if="generating" />

        <v-divider class="mt-2"></v-divider>
        <v-textarea
          rows="3"
          no-resize
          class="no-borders overflow-auto no-border no-scroll pt-0 pr-5 mr-5"
          v-model="form.text"
          @keydown.enter.exact.prevent="e => $emit('submit', e)"
          placeholder="Enter your message"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "./Message.vue";
import Typing from "./Typing.vue";
export default {
  components: {
    Message,
    Typing
  },
  props: {
    generating: Boolean,
    form: Object,
    messages: Array
  },
  computed: {
    GapHeight() {
      const count = this.messasges && this.messages.length - 1;
      return Number(count && Math.max(0, 224 - 32 * count));
    }
  },
  methods: {
    scrollToBottom() {
      this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
    }
  },
  mounted() {
    this.$nextTick(this.scrollToBottom);
  },
  data() {
    return {};
  }
};
</script>
<style>
.no-borders *:before {
  border-width: 0 !important;
  border-color: white !important;
  border-style: none !important;
}
.no-borders *:after {
  border-width: 0 !important;
  border-color: white !important;
  border-style: none !important;
}
</style>
<style scoped>
.no-border > .v-input__control > .v-input__slot::before {
  border: none;
}
.no-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.no-scroll > * {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.no-scroll > *::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>

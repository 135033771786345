<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="#09ACEC" rounded="0">
      <v-toolbar-title>
        Delete people in bulk
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeWindow">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div v-if="activeSlide == keys.user" key="0" class="mt-10">
      <v-card-text class="d-flex flex-column text-left pl-6">
        <h2>Delete multiple accounts by uploading a CSV</h2>

        <v-form
          ref="userForm"
          v-model="userForm.valid"
          class="full-width pl-3 mt-6"
        >
          <v-autocomplete
            :items="clients"
            outlined
            dense
            item-text="formattedName"
            item-value="clientId"
            label="Client*"
            v-model="user.clientId"
            v-if="userProfile.Client.clientId === 1 && !clientId"
          ></v-autocomplete>
          <v-btn
            outlined
            color="brandCyan"
            class=" pa-2 mb-5"
            @click="downloadTemplate"
          >
            <v-avatar>
              <v-icon class="mr-1">mdi-download</v-icon>
            </v-avatar>
            <span class="pr-2">
              Download Template
            </span>
          </v-btn>

          <p class="mt-3">
            Your file should contain a single column with either email address
            or phone number of the users to delete
          </p>
          <div @drop.prevent="dragAndDropCSVFileChanged" @dragover.prevent>
            <v-file-input
              label="Select your CSV"
              class="fileInput"
              accept=".csv"
              v-model="file"
              outlined
              dense
              prepend-inner-icon="$file"
              prepend-icon=""
              color="brandCyan"
            ></v-file-input>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-8 pl-9">
        <v-btn
          color="brandCyan"
          class="white--text"
          :outlined="!file"
          depressed
          @click="uploadFile"
          :disabled="!file"
        >
          Upload file
        </v-btn>
      </v-card-actions>
    </div>
    <div
      v-else-if="activeSlide == keys.loading"
      key="2"
      class="word-break mt-10 d-flex flex-column align-center text-left"
    >
      <v-card-text class="full-width pl-6">
        <h2>
          Deleting the selected users...
        </h2>

        <span class="text--caption mt-2" v-if="file"
          >This might take a little while but we will email you once we're
          done.</span
        >
      </v-card-text>

      <Robin
        :showText="false"
        :width="140"
        :height="140"
        animation="inflatableDance"
        :loop="true"
      />
    </div>
    <!-- Success message displayed -->
    <div v-else-if="activeSlide == keys.success" key="3">
      <!-- <v-card-title class="dialog-header" color="primary">
              Add a new person
            </v-card-title> -->
      <!-- <v-divider /> -->
      <v-card-text
        class="d-flex-column align-center justify-center mt-10 word-break"
      >
        <v-card-text class="full-width pl-6">
          <h2>
            Success!!
          </h2>
        </v-card-text>
        <div class="d-flex justify-center">
          <Robin
            :showText="false"
            :width="140"
            :height="140"
            animation="backflip"
            :loop="false"
            class="mb-8"
          />
        </div>

        <v-btn
          class="ml-3 white--text"
          color="brandCyan"
          depressed
          @click="closeWindow"
        >
          Close
        </v-btn>
      </v-card-text>
    </div>
    <div
      v-else-if="activeSlide == keys.error || activeSlide == keys.csvError"
      key="4"
    >
      <v-card-text
        class="d-flex-column align-center justify-center mt-10 word-break"
      >
        <v-card-text class="full-width text-left pl-4">
          <h2>
            {{
              errorMessage
                ? errorMessage
                : "Looks like there was a problem processing your file"
            }}
          </h2>
        </v-card-text>
        <div class="justify-center">
          <p class="pl-4 full-width text-left">
            Feel free to reach out to customer service via one of the following
            methods...
          </p>
          <p class="pl-4 text-left full-width">
            Email -
            <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
          </p>
          <p class="pl-4 text-left full-width">
            Phone (Toll Free) - <b>(855) 264-3329</b>
          </p>
        </div>
        <div
          class="text-left pl-4 pt-4 top-border"
          v-if="fileFailedRequirements.length > 0"
        >
          <p v-for="row in fileFailedRequirements" :key="row.index">
            <span class="font-weight-bold">Row {{ row.index }}:</span> Missing
            {{ row.fields.join(", ") }}
          </p>
        </div>
        <v-btn outlined color="brandCyan" @click="goBackFromError" class="mt-3">
          Go back
        </v-btn>
        <v-btn
          class="ml-3 mt-3 white--text"
          color="brandCyan"
          depressed
          @click="closeWindow"
        >
          Close
        </v-btn>
      </v-card-text>
    </div>

    <!-- Dialog to show results of CSV upload for users -->
    <v-dialog v-model="showUploadResults" width="500">
      <v-card rounded="0" class="pa-6 word-break">
        <h2 class="mt-2">
          <v-icon large class="mr-2" color="error">mdi-alert</v-icon>CSV Bulk
          Deletion Results
        </h2>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <p class="my-3" v-if="fileSuccess.length > 0">
            {{ fileSuccess.length }}
            {{ fileSuccess.length == 1 ? "person" : "people" }} successfully
            deleted!
          </p>
          <p class="my-3" v-if="fileFailedRequirements.length > 0">
            Looks like some rows in your CSV were missing some required fields.
          </p>
          <p class="mb-3" v-if="fileFailedRequirements.length > 0">
            Rows: {{ fileFailedRequirements.toString() }}
          </p>

          <p class="mb-1 mt-3" v-if="fileErrors.length > 0">
            Uh oh! It looks like we had some trouble with finding or deleting
            the following users:
          </p>
          <p
            v-for="(error, index) in fileErrors"
            :key="`error-email-${index}`"
            class="errorEmailList mb-2"
          >
            <span class="font-weight-bold">{{ error }}</span
            >{{
              error.invalidReason
                ? " - " + error.invalidReason
                : error.failedReason
                ? " - " + error.failedReason
                : ""
            }}
          </p>
        </v-card-text>

        <v-card-actions class="mx-12">
          <v-spacer />
          <v-btn
            color="brandCyan"
            class="white--text"
            depressed
            @click="clearFileArrays"
            width="130"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="errorDialog.display" width="500"> -->
    <ErrorDialog
      v-if="errorDialog.display"
      :title="errorDialog.title"
      :subtitle="errorDialog.subtitle"
      :button1Text="errorDialog.button1Text"
      :button2Text="errorDialog.button2Text"
      @button2="errorDialog.display = false"
      @close="errorDialog.display = false"
    />
  </v-card>
</template>

<script>
import OnboardingService from "../services/OnboardingService";
import UserService from "../services/UserService";

import Robin from "@/components/Robin.vue";
import ErrorDialog from "@/components/ErrorDialog";

import { mapState } from "vuex";

function initialState() {
  return {
    keys: {
      user: 0,
      bulk: 1,
      loading: 2,
      success: 3,
      error: 4,
      csvError: 5
    },
    activeSlide: 0,
    emailErrorDialog: false,
    showUploadResults: false,
    file: undefined,
    fileSuccess: [],
    fileErrors: [],
    fileFailedRequirements: [],
    confirmDelete: false,
    errorMessage: null,

    userForm: {
      valid: false,
      imageUrlFileName: null,
      editing: false,
      showSuccess: false,
      transitionDirection: "topic-right"
    },

    // Used by the error component
    errorDialog: {
      display: false,
      override: false,
      title: null,
      subtitle: null,
      button1Text: null,
      button2Text: null
    }
  };
}

export default {
  name: "UserCreatorWidget",
  components: {
    Robin,
    ErrorDialog
  },
  props: {
    clientId: Number,
    source: String
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
  },
  mounted() {},
  methods: {
    downloadTemplate() {
      OnboardingService.downloadDeletionTemplate();
    },

    closeWindow() {
      this.$emit("close");
    },

    getUsers(preloadId = null) {
      this.$emit("reset-selected");
      this.$emit("get-users", preloadId);
    },

    async uploadFile() {
      console.log("uploadFile...");
      this.showBulkUploadDialog = false;
      this.errorMessage = null;
      this.activeSlide = this.keys.loading;

      await UserService.deleteUserBulkCSV(this.file, {
        clientId: this.clientId
      })
        .then(response => {
          this.activeSlide = this.keys.success;
          console.log("User CSV Upload Response: ", response);
          this.fileSuccess = response.success;
          this.fileErrors = response.error;
          //this.fileSuccess = response.result.filter(
          //   x => x.userId && !x.failed && !x.invalid
          // );
          //this.fileErrors = response.result.filter(x => x.failed || x.invalid);
          // this.fileFailedRequirements = response.data.failedReq;

          if (response.success.length == 0 && response.error.length == 0) {
            // We have to fail because nothing got processed
            throw "No success and no error";
          }
          this.showUploadResults = true;

          this.file = null;
          this.fileGroupName = null;
          this.fileGroupDisplayName = null;
          this.getUsers();
        })
        .catch(err => {
          console.log("CSV Upload error ", err);
          this.activeSlide = this.keys.csvError;
          if (err.error_code && err.error) {
            if (err.error_code == 1040003) {
              // Dealing with missing permissions of some kind
              if (
                err.error.startsWith("Required fields missing - 0 rows found")
              )
                this.errorMessage =
                  "We couldn't find any valid rows within your file";
              else {
                this.errorMessage =
                  "We found some rows in your file with missing required fields";

                if (err.missing) this.fileFailedRequirements = err.missing;
              }
            }
          }
          if (!this.errorMessage)
            this.errorMessage =
              "There was a problem processing your file upload";
          console.log("Could not upload the file!");
        });
    },

    clearFileArrays() {
      this.showUploadResults = false;
      this.fileSuccess = [];
      this.fileErrors = [];
      this.fileFailedRequirements = [];
      this.closeWindow();
    },

    goBackFromError() {
      this.errorMessage = null;
      this.showUploadResults = false;
      this.fileSuccess = [];
      this.fileErrors = [];
      this.fileFailedRequirements = [];
      this.activeSlide = this.keys.user;
    },

    dragAndDropCSVFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        console.log(e.dataTransfer.files[0]);
        if (
          e.dataTransfer.files[0].name.endsWith(".csv") ||
          e.dataTransfer.files[0].type == "text/csv"
        )
          this.file = e.dataTransfer.files[0];
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.template-buttons {
  width: 260px;
  max-width: 260px;
  min-width: 260px;
}
.image-container {
  position: relative;
  width: 80px;
  max-width: 80px;
}
.delete-image-button {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: -10px;
  padding: 0px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: 0.2s opacity;
  background-color: black;
}

.slide-button {
  background-color: white;
  background: white;
  border: 1px solid;
  border-color: var(--v-grey-base);
}

.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  /* min-width: 235px; */
  /* color: rgba(0, 0, 0, 0.38); */
}
/* Styling for divider between customer service info and failed file req */
.top-border {
  border-top: 1px solid grey;
}
</style>

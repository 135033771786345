<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :key="1"
          hide-default-header
          :headers="headers"
          :items="visibleItems"
          :items-per-page="table.itemsPerPage"
          :footer-props="table.footerProps"
          :options.sync="table.options"
          :server-items-length="table.total"
          :show-expand="false"
          :expanded="[]"
          :loading="table.loading"
          loading-text="loading"
          must-sort
          sort-by="displayName"
          sort-asc
          no-data-text="No people found"
          no-results-text="No people found"
          item-key="userId"
          id="user-table"
          class="cursor-pointer"
          @click:row="selectItem($event, false)"
        >
          <template v-slot:[`item.select`]="{ item }">
            <v-simple-checkbox
              color="brandCyan"
              @click.stop="selectItem(item, false)"
              :ripple="false"
              :value="
                selected.find(x => x.userId == item.userId) ? true : false
              "
            />
          </template>
          <template v-slot:[`item.info`]="{ item }">
            <v-btn icon @click.stop="previewItem(item)"
              ><v-icon color="grey">mdi-dots-horizontal</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.displayName`]="{ item }">
            <span class="word-break">{{ item.displayName }}</span>
          </template>
          <template v-slot:[`item.businessEmail`]="{ item }">
            <span class="word-break">{{
              item.businessEmail || item.businessPhone
            }}</span>
          </template>

          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
        </v-data-table>
        <v-bottom-sheet v-model="dialog.preview" inset width="500">
          <v-sheet class="text-center" :height="'475px'" v-if="item">
            <div class="pt-4">
              <div class="d-flex align-center justify-space-between mx-6">
                <v-btn fab color="lightGrey" small class="hidden"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
                <v-card-title class="word-break">
                  <b>
                    {{
                      item.displayName || item.firstName + " " + item.lastName
                    }}</b
                  >
                </v-card-title>
                <v-btn
                  fab
                  color="mediumGrey"
                  small
                  depressed
                  elevation="0"
                  class="ignore-click"
                  @click="dialog.preview = !dialog.preview"
                  ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                >
              </div>
              <div>
                <v-img
                  v-bind:src="item.profilePicUrl"
                  v-if="item.profilePicUrl"
                  height="110"
                  width="110"
                  max-height="110"
                  max-width="110"
                  class="rounded-circle box-border-global mx-auto"
                />
                <v-icon
                  v-else
                  x-large
                  class="rounded-circle box-border-global profile-pic-icon"
                  >mdi-account</v-icon
                >
                <p class="mb-1 mt-4">
                  {{ item.jobTitle || "Unknown Job Title" }}
                </p>
                <p class="mb-1">
                  {{ item.organization || "Unknown Organization" }}
                </p>
                <p>
                  {{ item.businessCity ? item.businessCity : ""
                  }}{{
                    item.businessCity && item.businessState
                      ? ", "
                      : !item.businessCity && !item.businessState
                      ? "Unknown Location"
                      : ""
                  }}
                  {{ item.businessState ? item.businessState : "" }}
                </p>
                <v-divider class="mx-7" />
                <div class="d-flex ml-6 mt-6">
                  <p class="darkGrey--text mb-2">
                    Email:
                    <b class="black--text">{{
                      item.businessEmail ? item.businessEmail : "-"
                    }}</b>
                  </p>
                </div>
                <div class="d-flex ml-6">
                  <p class="darkGrey--text">
                    Phone:
                    <b class="black--text">{{
                      item.businessPhone ? item.businessPhone : "-"
                    }}</b>
                  </p>
                </div>
              </div>
              <v-btn
                v-if="item && item.userId"
                color="brandCyan"
                rounded
                depressed
                width="90"
                @click="selectItem(item, true)"
                class="mt-auto white--text ignore-click"
                >Add</v-btn
              >
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserService from "@/services/UserService";

// import Robin from "@/components/Robin.vue";

import { mapState } from "vuex";

export default {
  name: "UsersTable",
  props: {
    selected: {
      type: Array
    },
    search: {
      type: String
    },
    sourcePage: {
      type: String,
      default: "programadmin"
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    },
    displayEmail: {
      type: Boolean,
      default: false
    },
    displayInfo: {
      type: Boolean,
      default: true
    },
    external: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  data() {
    return {
      whistleClientId: 1,
      loading: {
        assignments: false
      },
      dialog: {
        preview: false
      },
      item: null,
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        items: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      }
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    loadAction(item) {
      console.log("loading action ", item);
      this.$emit("load-action", item);
    },
    async getUsers(reset = false, source = null, preloadUser = null) {
      try {
        // First, if they have a preloaded Id and it's an object, we add that now just in case the api result gets dismissed
        let alreadyPreloadedUser = false;
        if (preloadUser && typeof preloadUser == "object") {
          alreadyPreloadedUser = true;
          this.selectItem(preloadUser, true);
        }
        console.log("Getting users from ", source);
        console.log({ reset, source, preloadUser });
        this.table.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let search = this.search ? this.search.replace(/'/g, "\\'") : null;
        let filter = `status != 'Deleted' && clientId = ${this.clientId}`;

        // if (search) {
        //   // If search we need to search for every field that's in the table
        //   filter += ` && (groupDisplayName.like('%${search}%') || status.like('%${search}%') || groupId.like('%${search}%'))`;
        // }
        // if (search) {
        //   // If search we need to search for every field that's in the table
        //   filter += ` && (businessEmail.like('%${search}%') || businessPhone.like('%${search}%') || firstName.like('%${search}%') || lastName.like('%${search}%') || displayName.like('%${search}%')`;

        //   // We also split the search to get the first and last word for attempted first / last name match
        //   var searchSplit = search.split(" ");
        //   if (searchSplit.length > 1) {
        //     var searchFirst = searchSplit[0];
        //     var searchLast = searchSplit[searchSplit.length - 1];

        //     filter += `|| (firstName.like('%${searchFirst}%') && lastName.like('%${searchLast}%'))`;
        //   }

        //   filter += ")";
        // }
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        let sort = "User.firstName ASC, User.lastName ASC";
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          console.log("Sort ", options);
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          if (column == "displayName")
            sort = `User.firstName ${type}, User.lastName ${type}`;
          else sort = column + " " + type;
        }
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          // filter,
          offset,
          limit,
          sort: sort + `, createDate ASC`,
          clientId: this.clientId,
          screen: this.sourcePage,
          source: this.sourcePage,
          search: this.search,
          external: this.external ? 1 : 0
        };
        let response = await UserService.getUsersV2(
          params,
          this.magicLinkToken
        );

        // we have to compare states to see if we ignore results (only if them spam a filter)
        // console.log({
        //   search: this.search != params.search,
        //   sort: sort != this.table.latestSort,
        //   page: page != this.table.options.page,
        //   limit: limit != this.table.options.itemsPerPage,
        //   clientId: this.clientId != params.clientId
        // });
        if (
          // filter != this.table.latestFilter ||
          this.search != params.search ||
          sort != this.table.latestSort ||
          page != this.table.options.page ||
          limit != this.table.options.itemsPerPage ||
          this.clientId != params.clientId
        ) {
          console.log("Ignoring user response");
          return;
        }

        console.log("Got users ", response);
        this.table.total = response.result.count;
        // if (reset || wipeArray)
        //   this.table.messages = new Array(this.table.total).fill(undefined);
        let rows = response.result.rows.map(r => {
          return r;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        // if (offset == 0)
        this.table.items = rows;
        this.table.loading = false;

        if (preloadUser) {
          if (typeof preloadUser == "object" && !alreadyPreloadedUser)
            return this.selectItem(preloadUser, true);
          const foundUser = rows.find(x => x.userId == preloadUser);
          if (foundUser) this.selectItem(foundUser, true);
        }
      } catch (err) {
        console.log("Error getting users ", err);
        this.table.loading = false;
      }
    },
    previewItem(item) {
      console.log("Previewing ", item);
      this.item = item;
      this.dialog.preview = true;
    },
    selectItem(x, preventRemoval = false) {
      console.log("Selecting item ", x);
      this.$emit("select-item", {
        ...x,
        // Added because we can only pass one param in
        dontRemove: preventRemoval ? true : undefined
      });
      this.dialog.preview = false;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "globalClientId",
      "clients",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleItems() {
      let array = this.table.items || [];

      array = array.filter(x => x.status != "Deleted");

      if (this.table.loading) return [];
      return array;
    },
    headers() {
      return [
        {
          align: "start",
          sortable: false,
          value: "select",
          width: "35px"
        },
        {
          align: "start",
          sortable: true,
          value: "displayName",
          width: this.displayName ? "50%" : "90%"
        },
        this.displayEmail
          ? {
              text: "Email / Phone",
              value: "businessEmail",
              width: "50%"
            }
          : undefined,
        this.displayInfo
          ? {
              value: "info",
              width: "100px",
              align: "end",
              sortable: false
            }
          : undefined
      ].filter(Boolean);
    }
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        // if (
        //   !reset &&
        //   oldVal.page !== newVal.page &&
        //   this.table.messages.filter(x => x !== undefined).length >
        //     (newVal.page - 1) * newVal.itemsPerPage
        // ) {
        //   console.log("We're not going to make the API call");
        //   apiCall = false;
        // }
        if (apiCall) this.getUsers(reset, "watcher");
      },
      deep: true
    },
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getUsers(true, "client watcher");
      }
    },
    search: function() {
      this.getUsers(true, "search watcher");
    }
  }
};
</script>

<style scoped>
.profile-pic-icon {
  height: 105px;
  width: 105px;
}
</style>

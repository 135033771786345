<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="#09ACEC" rounded="0" elevation="0">
      <v-toolbar-title> Export {{ formattedTableName }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeWindow">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <div class="mt-10">
      <v-card-text class="d-flex flex-column text-left pl-6">
        <h2>Select the fields you want to include in the file</h2>

        <v-form ref="userForm" v-model="validForm" class="full-width pl-3 mt-6">
          <v-autocomplete
            :items="alteredClientArr"
            outlined
            dense
            item-text="formattedName"
            item-value="clientId"
            label="Client*"
            v-model="clientId"
            v-if="userProfile.Client.clientId === 1"
          ></v-autocomplete>
          <!-- <v-autocomplete
            :items="columns"
            v-model="selectedColumns"
            item-text="label"
            item-value="value"
            outlined
            dense
            multiple
            label="Fields in file"
            color="brandCyan"
            :loading="loading.columns"
            hide-details
          ></v-autocomplete> -->
          <h3 class="font-weight-bold">
            {{ selectedColumns.length }} selected
          </h3>
          <v-btn
            color="brandCyan"
            class="white--text my-2"
            depressed
            text
            @click="selectAllColumns"
            >+ Select all fields</v-btn
          >
          <v-text-field
            label="Search"
            v-model="search"
            outlined
            dense
            hide-details
            class="mb-2"
            color="brandCyan"
            append-icon="mdi-magnify"
          />
          <v-data-table
            :search="search"
            v-model="selectedColumns"
            :items="columns"
            item-key="value"
            :loading="loading.columns"
            :headers="tableHeaders"
            show-select
            color="brandCyan"
            checkbox-color="brandCyan"
            @click:row="rowClick"
          />
        </v-form>
      </v-card-text>
      <v-card-actions
        class="pt-4 pb-8 pl-9 d-flex flex-column justify-start align-start"
      >
        <v-btn
          color="brandCyan"
          class="white--text"
          depressed
          @click="exportFile"
          :loading="loading.csv"
          :disabled="!selectedColumns.length"
        >
          Export file
        </v-btn>
        <p v-if="errorMessage" class="red--text text-left mt-3">
          {{ errorMessage }}
        </p>
        <p
          v-else-if="exportSuccess"
          class="success--text font-weight-bold text-left mt-4"
        >
          Export complete!
        </p>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import UserService from "@/services/UserService";

import { mapState } from "vuex";

function initialState() {
  return {
    errorMessage: null,
    exportSuccess: false,

    validForm: false,
    clientId: null,
    columns: [],
    selectedColumns: [],
    loading: {
      columns: false,
      csv: false
    },
    search: null,
    tableHeaders: [{ text: "Field", value: "label", width: "100%" }]
  };
}

export default {
  name: "CSVExport",
  components: {},
  props: {
    table: {
      type: String,
      default: "User"
    }
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    this.clientId = this.globalClientId || this?.userProfile?.clientId;
    this.getColumns();
  },
  mounted() {},
  methods: {
    async getColumns() {
      try {
        this.loading.columns = true;
        const res = await UserService.getExportColumns(
          this.table == "UserClient" ? "UserClient,User" : this.table
        );
        console.log(res);
        this.columns = res?.rows || res?.result?.rows || res || [];
        if (this.table == "UserClient")
          this.selectedColumns = this.columns.filter(x =>
            [
              "firstName",
              "lastName",
              "businessEmail",
              "businessPhone",
              "businessCountry",
              "userIdAtClient"
            ].includes(x.value)
          );
      } catch (e) {
        console.log("error getting columns", e);
      } finally {
        this.loading.columns = false;
      }
    },

    closeWindow() {
      this.$emit("close");
    },
    async exportFile() {
      try {
        console.log("exportFile...");
        this.loading.csv = true;
        this.errorMessage = null;
        this.exportSuccess = false;

        await UserService.exportDataV2(this.table, {
          clientId: this.clientId,
          columns: this.selectedColumns.length
            ? this.selectedColumns.map(x => x.value || x).join(",")
            : undefined
        });
        this.exportSuccess = true;
      } catch (e) {
        console.log("Error exporting file ", e);
        this.errorMessage = "Error exporting file";
      } finally {
        this.loading.csv = false;
      }
    },
    dragAndDropCSVFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        console.log(e.dataTransfer.files[0]);
        if (
          e.dataTransfer.files[0].name.endsWith(".csv") ||
          e.dataTransfer.files[0].type == "text/csv"
        )
          this.file = e.dataTransfer.files[0];
      }
    },
    selectAllColumns() {
      this.selectedColumns = this.columns; //.map(x => x.value || x);
    },
    rowClick: function(item, row) {
      row.select(!row.isSelected);
      // this.selectedId = item.name;
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "globalClientId"]),
    alteredClientArr() {
      if (this?.userProfile?.clientId != 1) return [];

      return [
        { clientId: 0, formattedName: "All clients" },
        ...this.clients.slice(1)
      ];
    },
    formattedTableName() {
      return (this.table == "UserClient" ? "User" : this.table) + "s";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<template>
  <div>
    <!-- Create toolbar container -->
    <div id="toolbar-custom">
      <div class="d-flex justify-space-between">
        <select class="ql-header quill-select">
          <!-- Note a missing, thus falsy value, is used to reset to default -->
          <option selected>Normal</option>
          <option value="1">Heading 1</option>
          <option value="2">Heading 2</option>
          <option value="3">Heading 3</option>
          <option value="4">Heading 4</option>
          <option value="5">Heading 5</option>
          <option value="6">Heading 6</option>
        </select>

        <!-- Add a bold button -->
        <div>
          <button class="ql-bold quill-button"></button>
          <button class="ql-italic quill-button"></button>
          <button class="ql-underline quill-button"></button>
          <!-- <button class="ql-script" value="sub"></button>
        <button class="ql-script" value="super"></button> -->
        </div>
        <!-- Add subscript and superscript buttons -->
        <!-- <button class="ql-script button" value="sub"></button>
      <button class="ql-script button" value="super"></button> -->

        <!-- <ß -->
        <v-btn
          icon
          small
          @click="hiddenMenu = !hiddenMenu"
          :class="{ hidden: hiddenMenu }"
          ><v-icon small>mdi-dots-horizontal</v-icon></v-btn
        >
      </div>
      <div
        v-show="hiddenMenu"
        :class="{ 'quill-hidden-menu': !hiddenMenu }"
        class="d-flex justify-space-between"
      >
        <div>
          <select class="ql-align quill-select">
            <!-- Note a missing, thus falsy value, is used to reset to default -->
            <option selected>Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
            <option value="justify">Justify</option>
          </select>
          <button class="ql-list quill-button" value="ordered"></button>
          <button class="ql-list quill-button" value="bullet"></button>
        </div>
        <div>
          <select class="ql-color quill-select"></select>
          <select class="ql-background quill-select"></select>
          <button class="ql-clean quill-button"></button>
        </div>
        <button class="ql-link quill-button"></button>
      </div>
    </div>
    <div
      id="editor-custom"
      ref="quill-editor-body"
      @click="focusOnEditor"
    ></div>
    <div
      v-if="shortcodes"
      class="d-flex justify-space-between py-2 cursor-pointer"
      @click="displayShortcodes = !displayShortcodes"
    >
      <span>Shortcodes</span
      ><v-icon>{{
        displayShortcodes ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
    </div>
    <v-expand-transition
      ><div v-if="displayShortcodes">
        <v-chip
          v-for="code in shortcodeList"
          :key="code.value"
          @click="insertShortcode(code.value)"
          class="ma-1 wizard-audience-chip black--text"
          outlined
          color="brandCyan"
          ><span class="black--text">{{ code.label }}</span></v-chip
        >
      </div></v-expand-transition
    >
    <!-- <span>{{ message }}</span> -->
  </div>
</template>

<script>
import { editorToolbar, editorOptions } from "@/shared_data/data";

export default {
  name: "TextEditorV2",
  components: {},
  props: {
    //whether or not to show shortcodes, and if we do, do we show the program related ones
    shortcodes: {
      type: Boolean,
      default: true
    },
    programRelated: Boolean,
    programHasEndDate: {
      type: Boolean,
      default: false
    },
    editorContent: String,
    largeVersion: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      scriptTag: null,
      // 2.0.2
      quillSource: "https://cdn.jsdelivr.net/npm/quill@1.3.6/dist/quill.js",
      content: null,
      customToolbar: editorToolbar,
      quillOptions: editorOptions,
      quill: null,
      textSizeOptions: [
        { value: false, text: "Normal" },
        { value: 1, text: "Heading 1" }
      ],
      headerOptions: [
        { value: false, text: "Normal" },
        { value: 1, text: "Heading 1" }
      ],
      hiddenMenu: false,
      toolbarMenu: false,
      displayShortcodes: false
    };
  },
  created() {
    console.log("Created TextEditorV2");
    // this.content = this.editorContent;

    if (!this.isScriptLoaded(this.quillSource)) {
      this.includeQuill(
        this.quillSource,
        function() {
          this.configureQuill();
        }.bind(this)
      );
    } else {
      this.configureQuill();
    }
    // const quill = new Quill("#editor", {
    //   modules: {
    //     toolbar: "#toolbar"
    //   }
    // });
  },
  destroyed() {
    // Remove Quill
    const el = document.getElementById("quill-script");
    console.log("Destroying Quill", el);
    if (el) el.parentNode.removeChild(el);
  },
  methods: {
    isScriptLoaded() {
      console.log("Scripts", document.getElementById("quill-script"));
      const res = Boolean(document.getElementById("quill-script"));
      console.log("Is script loaded? ", res);
      return res;
    },
    includeQuill(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = URL;
      object.id = "quill-script";
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            console.log("DONE LOADING");
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
      this.scriptTag = scriptTag;
    },
    configureQuill() {
      try {
        console.log("Configuring Quill");
        // eslint-disable-next-line
        this.quill = new Quill("#editor-custom", {
          ...editorOptions,
          modules: { toolbar: "#toolbar-custom" },
          theme: "snow",
          placeholder: this.placeholder
        }); //eslint-disable-line

        this.quill.on("text-change", () => {
          // this.message = this.quill.getSemanticHTML();
          this.message =
            this.getHTML() == "<p><br></p>" ? null : this.getHTML();
        });

        // eslint-disable-next-line
        Quill.register(Quill.import("attributors/style/direction"), true);
        // eslint-disable-next-line
        Quill.register(Quill.import("attributors/style/align"), true);
        // // eslint-disable-next-line
        // const Parchment = Quill.import("parchment");

        // class IndentAttributor extends Parchment.Attributor.Style {
        //   add(node, value) {
        //     if (value === 0) {
        //       this.remove(node);
        //       return true;
        //     } else {
        //       return super.add(node, `${value}em`);
        //     }
        //   }
        // }

        // let IndentStyle = new IndentAttributor("indent", "text-indent", {
        //   scope: Parchment.Scope.BLOCK,
        //   whitelist: [
        //     "1em",
        //     "2em",
        //     "3em",
        //     "4em",
        //     "5em",
        //     "6em",
        //     "7em",
        //     "8em",
        //     "9em"
        //   ]
        // });
        // // eslint-disable-next-line
        // Quill.register(IndentStyle, true);

        // Now import any text into Quill (if any)
        if (this.message) this.quill.root.innerHTML = this.message;
        //this.quill.setText(this.message);
      } catch (e) {
        console.error("Error configuring Quill", e);
      }
    },
    getHTML() {
      if (!this.quill) return;
      return this.quill.root.innerHTML;
    },
    insertShortcode(code) {
      if (!this.quill) return;
      this.quill.insertText(
        this.quill.getSelection(true).index,
        "{{{" + code + "}}}"
      );
    },
    focusOnEditor() {
      if (this.quill) this.quill.focus();
    }
  },
  computed: {
    message: {
      get: function() {
        return this.editorContent;
      },
      set: function(newValue) {
        this.$emit("update-message", newValue);
      }
    },
    shortcodeList() {
      let arr = [
        { label: "First name", value: "user.firstName" },
        { label: "Last name", value: "user.lastName" }
      ];
      if (this.programRelated)
        arr.push(
          ...[
            { label: "Program name", value: "program.name" },
            { label: "Percent complete", value: "program.progressPercent" },
            {
              label: "Percent left to go",
              value: "program.progressPercentInverse"
            },
            { label: "Progress toward", value: "program.progressToward" },
            // { label: "User's program status", value: "program.userStatus" },
            this.programHasEndDate
              ? {
                  label: "Program hours remaining (x hours)",
                  value: "program.hoursRemaining"
                }
              : undefined
          ]
        );

      return arr.filter(Boolean);
    }
  },
  watch: {
    content: function() {
      this.$emit("content-change", this.content);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "https://cdn.jsdelivr.net/npm/quill@2.0.2/dist/quill.snow.css";
/* @import "~quill/dist/quill.core.css"; */
/* @import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css"; */
.shortcodes .v-chip {
  margin: 2px;
}
.quillWrapper.editor {
  max-height: 100%;
}
.dark-blue-background {
  background-color: var(--v-brand-base);
}
.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.border-radius {
  border-radius: 100%;
}
.grey-background {
  background-color: var(--v-lightGrey-base);
}

#toolbar-custom {
  border: none !important;
  /* overflow: hidd/en !important; */
}
#editor-custom {
  /* max-height: 40vh; */
  /* overflow-y: scroll; */
  min-height: 200px;
  border: 1px solid lightgray !important;
  border-radius: 8px;
  cursor: text;
}

.quill-select {
  margin: 0 4px 0 4px;
}

.quill-button {
  margin: 0 4px 0 4px;
}
.quill-button.ql-active {
  background-color: var(--v-brandLightCyan-base) !important;
  border-radius: 4px;
}

.ql-picker-label {
  border: none !important;
}

.quill-hidden-menu {
  visibility: hidden;
  max-height: 1px;
}

.ql-background .ql-picker-label {
  padding-top: 1px !important;
}
</style>

<template>
  <div>
    <div
      class="d-flex full-width align-center "
      :class="{
        'justify-start': align == 'start',
        'justify-center': align == 'center'
      }"
    >
      <v-icon v-if="progressPercent == 100" class="mr-4" color="brandGreen"
        >mdi-check-circle</v-icon
      >
      <v-tooltip
        v-else-if="renderClock || displayClock"
        top
        :disabled="!displayClock"
      >
        <!--             v-if="
              computedDaysRemaining !== null &&
                computedDaysRemaining < 5 &&
                progressPercent != 100
            " -->
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            class="mr-4"
            color="error"
            :class="{
              hidden: !displayClock
            }"
            >mdi-clock-time-four</v-icon
          >
        </template>
        <span v-if="status == 'Active'"
          >Days remaining: <b>{{ computedDaysRemaining }}</b></span
        >
      </v-tooltip>

      <!-- <v-row no-gutters> -->
      <!-- <v-col sm="12" md="12" lg="12" class="full-width"> -->
      <v-progress-linear
        :value="progressPercent"
        :color="!displayClock ? 'brandGreen' : 'error'"
        height="22"
        class="bar"
      ></v-progress-linear>
      <!-- </v-col> -->
      <v-img
        :src="badgeImageUrl"
        height="35"
        width="35"
        min-height="35"
        contain
        max-width="35"
        class="ml-4"
        :class="{
          hidden: !badgeImageUrl
        }"
      ></v-img>
    </div>
  </div>
</template>

<script>
//The types are "Donuts" or "Bars" for now
import moment from "moment";
export default {
  name: "ProgramCardProgressV2",
  props: {
    type: String,
    status: String,
    startDate: String,
    endDate: String,
    progressPercent: Number,
    badgeImageUrl: String,
    // Used if we don't want to see the clock but its space is still taken up to even things out
    hideClock: {
      type: Boolean,
      default: false
    },
    // Used if we don't even want to render the space used by the clock UNLESS we actually need to display the clock
    // This is used on the programs page
    renderClock: {
      type: Boolean,
      default: true
    },
    align: {
      type: String,
      default: "start"
    }
  },
  data() {
    return {
      interval: {},
      progressBar: 0,
      daysRemaining: 0,
      // computedColor: null,
      formattedEndDate: null
    };
  },
  mounted() {
    // this.formattedEndDate = !this.endDate
    //   ? null
    //   : moment(this.endDate)
    //       .local()
    //       .format("MMM DD, YYYY");
  },
  methods: {
    loadProgram() {
      this.$emit("load-program", this.programId);
    }
  },
  computed: {
    displayClock: function() {
      return !(
        this.hideClock ||
        this.status !== "Active" ||
        this.computedDaysRemaining === null ||
        this.computedDaysRemaining > 5 ||
        parseFloat(this.progressPercent) >= 100
      );
    },
    computedProgress: function() {
      var progressPercent = [];
      progressPercent = this.progressPercent * 100;
      progressPercent = parseFloat(progressPercent).toFixed(0);
      return progressPercent;
    },
    computedDaysRemaining: function() {
      // To set two dates to two variables
      if (!this.endDate || this.status == "Preview") return null;
      // return 1;
      if (this.status === "Active") {
        //Measure days until end date
        var difference_In_Days = moment(this.endDate).diff(moment(), "days");
      } else if (this.status === "Scheduled") {
        //Measure days until start date
        difference_In_Days = moment(this.startDate).diff(moment(), "days");
      } else if (this.status === "Ended" || this.status === "Archived") {
        //Measure days since end date
        difference_In_Days = moment().diff(moment(this.endDate), "days");
      }

      return difference_In_Days;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-image {
  margin: 0 0 0 12px;
}
.donut-container {
  position: relative;
  /* width: 100%; */
  width: 120px;
  height: 120px;
}
.donut {
  position: absolute;
  left: 0;
  top: 0;
}
.inner-donut {
  position: absolute;
  top: 12.67px;
  left: 12px;
}
.text-by-donut {
  height: 120px;
}

.brand-color {
  background-color: var(--v-brand-base);
}
.brand-yellow-color {
  background-color: var(--v-brandYellow-base);
}
.brand-green-color {
  background-color: var(--v-brandGreen-base);
}
.brand-pink-color {
  background-color: var(--v-brandPink-base);
}
.accent-color {
  background-color: var(--v-accent-base);
}
.primary-color {
  background-color: var(--v-primary-base);
}
.error-color {
  background-color: var(--v-error-base);
}
.info-color {
  background-color: var(--v-info-base);
}
.checkbox {
  transform: scale(1.4);
  transform-origin: left;
}
.checkbox-container {
  width: 120px;
}
.amount-earned {
  font-size: 2.5em;
}
.amount-earned-container {
  max-width: 120px;
  width: 120px;
  height: 66px;
}

.bar {
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
}
</style>

<template>
  <v-card flat elevation="0">
    <v-toolbar dark color="#09ACEC" rounded="0">
      <v-toolbar-title>Upload file to create a list group </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="closeWindow">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <transition :name="form.transitionDirection" mode="out-in">
      <div v-if="activeSlide == keys.bulk" key="1" class="mt-10">
        <v-card-text class="d-flex flex-column text-left pl-6">
          <h2>Create a list group by uploading a CSV</h2>

          <v-form
            ref="form"
            v-model="form.valid"
            class="full-width pl-3 mt-6"
            onSubmit="return false;"
          >
            <v-autocomplete
              :items="clients"
              outlined
              dense
              item-text="formattedName"
              item-value="clientId"
              label="Client*"
              color="brandCyan"
              v-model="group.clientId"
              v-if="userProfile.Client.clientId === 1 && !clientId"
            ></v-autocomplete>
            <v-btn
              outlined
              color="brandCyan"
              class=" pa-2 mb-5"
              @click="downloadTemplate"
            >
              <v-avatar>
                <v-icon class="mr-1">mdi-download</v-icon>
              </v-avatar>
              <span class="pr-2">
                Download Template
              </span>
            </v-btn>
            <div @drop.prevent="dragAndDropCSVFileChanged" @dragover.prevent>
              <v-file-input
                label="Select your CSV"
                class="fileInput"
                accept=".csv"
                color="brandCyan"
                v-model="file"
                outlined
                dense
                prepend-inner-icon="$file"
                prepend-icon=""
              ></v-file-input>
            </div>
            <p class="word-break">
              Give a name to the group of people created from this list
            </p>
            <v-text-field
              v-model="group.name"
              label="Group name"
              outlined
              color="brandCyan"
              dense
              @keyup.enter="createListGroupV2"
              :rules="[
                v => !!v || 'A group name is required',
                v =>
                  !!(v && v.length < 255) || 'The group name should be shorter'
              ]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-8 pl-9">
          <v-btn
            color="brandCyan"
            class="white--text"
            :outlined="!form.valid || !file"
            depressed
            @click="createListGroupV2"
            :disabled="!form.valid || !file"
          >
            Upload file
          </v-btn>
        </v-card-actions>
      </div>
      <!-- Success message displayed -->
      <div v-else-if="activeSlide == keys.success" key="3">
        <!-- <v-card-title class="dialog-header" color="primary">
              Add a new person
            </v-card-title> -->
        <!-- <v-divider /> -->
        <v-card-text
          class="d-flex-column align-center justify-center mt-10 word-break"
        >
          <v-card-text class="full-width pl-6">
            <h2>
              Success!!
            </h2>
          </v-card-text>
          <div class="d-flex justify-center">
            <Robin
              :showText="false"
              :width="140"
              :height="140"
              animation="backflip"
              :loop="false"
              class="mb-8"
            />
          </div>
          <!-- <v-btn outlined color="primary" @click="resetForm">
            <v-icon>mdi-plus</v-icon>Add more
          </v-btn> -->
          <v-btn class="ml-3" color="primary" depressed @click="closeWindow">
            Close
          </v-btn>
        </v-card-text>
      </div>
    </transition>

    <!-- Dialog to show results of CSV upload for users -->
    <!-- <v-dialog v-model="showUploadResults" width="500">
      <v-card rounded="0" class="pa-6">
        <h2 class="mt-2">
          <v-icon large class="mr-2" color="error">mdi-alert</v-icon>CSV Upload
          Results
        </h2>
        <v-card-text class="d-flex flex-column justify-center align-center">
          <p class="my-3" v-if="fileSuccess.length > 0">
            {{ fileSuccess.length }}
            {{ fileSuccess.length == 1 ? "person" : "people" }} successfully
            created!
          </p>
          <p class="my-3" v-if="fileFailedRequirements.length > 0">
            Looks like some rows in your CSV were missing some required fields.
            Make sure to include each person's
            <b>first name, last name, work email, country, and organization.</b>
          </p>
          <p class="mb-3" v-if="fileFailedRequirements.length > 0">
            Rows: {{ fileFailedRequirements.toString() }}
          </p>

          <p class="mb-1 mt-3" v-if="fileErrors.length > 0">
            Uh oh! It looks like we had some trouble with uploading the
            following users:
          </p>
          <p
            v-for="(error, index) in fileErrors"
            :key="`error-email-${index}`"
            class="errorEmailList mb-2"
          >
            {{ error.businessEmail }}
          </p>
        </v-card-text>

        <v-card-actions class="mx-12">
          <v-spacer />
          <v-btn color="primary" @click="clearFileArrays" width="130"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- Dialog to confirm the user wants to delete the person -->
    <v-dialog v-model="errorDialog.display" width="500">
      <!-- <ErrorDialog
        v-if="errorDialog.display"
        :title="errorDialog.title"
        :subtitle="errorDialog.subtitle"
        :button1Text="errorDialog.button1Text"
        :button2Text="errorDialog.button2Text"
        @button2="errorDialog.display = false"
      /> -->
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <v-card-title class="title-text word-break full-width pl-4">{{
          errorDialog.message || "Looks like something went wrong"
        }}</v-card-title>

        <p class="pl-4 full-width align-text-left">
          If the problem continues, feel free to reach out to customer service
          via one of the following methods...
        </p>
        <p class="pl-4 align-text-left full-width">
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p class="pl-4 align-text-left full-width">
          Phone (Toll Free) - <b>(855) 264-3329</b>
        </p>
        <v-card-actions class="py-2">
          <v-btn
            color="primary"
            width="170"
            @click="errorDialog.display = false"
            class="mx-auto"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import GroupService from "../../services/GroupService";

import LoadingDialog from "@/components/loadingDialog.vue";
import Robin from "@/components/Robin.vue";

import { mapState } from "vuex";

function initialState() {
  return {
    keys: {
      bulk: 1,
      loading: 2,
      success: 3,
      error: 4
    },
    activeSlide: 1,
    showLoadingDialog: false,
    loadingHeaderMessage: null,
    loadingMessageLine1: null,
    loadingMessageLine2: null,
    form: {
      valid: false,
      editing: false,
      showSuccess: false,
      transitionDirection: "topic-right"
    },
    // New data
    group: {
      userId: null,
      name: null
    },
    // OLD DATA
    showUploadResults: false,

    file: undefined,

    // Used by the error component
    errorDialog: {
      display: false,
      message: null
    }
  };
}

export default {
  name: "GroupCSVWidget",
  components: {
    LoadingDialog,
    Robin
  },
  props: {
    clientId: Number
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    this.group.clientId = this.clientId;
  },
  mounted() {},
  beforeDestroy() {
    this.resetForm();
  },
  methods: {
    downloadTemplate() {
      GroupService.downloadTemplate();
    },
    closeWindow() {
      this.resetForm();
      this.$emit("close");
    },
    getUsers(preloadId = null) {
      this.$emit("get-users", preloadId);
    },
    createListGroupV2() {
      // If they hit enter on an invalid form, we ignore
      if (!this.file || !this.form.valid) return;
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating List Group";
      this.loadingMessageLine1 = "This could take a minute.";
      this.loadingMessageLine2 = "";
      console.log("creating List groups");
      console.log(this.group.name);
      // const body = {
      //   groupName: this.group.name,
      //   file: this.file,
      // };
      // console.log(body);
      const clientId =
        this.group.clientId != null
          ? this.group.clientId
          : this.userProfile.clientId;
      GroupService.createListGroupV2(this.group.name, this.file, clientId)
        .then(response => {
          console.log(response);
          // this.listGroupResultsSuccess = response.success;
          // this.listGroupResultsErrors = response.error;
          // this.createListGroupSuccess = true;
          this.showLoadingDialog = false;
          this.activeSlide = this.keys.success;
          this.$emit("get-groups");
          console.log(response);

          // this.getGroupsV2();
        })
        .catch(err => {
          console.log("Error with group CSV!", err);
          if (err && err.error_code == "1050003")
            this.errorDialog.message =
              "It looks like the file you uploaded is empty";
          else this.errorDialog.message = null;
          console.log(this.errorDialog.message);
          this.showLoadingDialog = false;
          this.errorDialog.display = true;
        });
    },
    dragAndDropCSVFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        console.log(e.dataTransfer.files[0]);
        if (
          e.dataTransfer.files[0].name.endsWith(".csv") ||
          e.dataTransfer.files[0].type == "text/csv"
        )
          this.file = e.dataTransfer.files[0];
      }
    },
    resetForm() {
      Object.assign(this.$data, initialState());
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

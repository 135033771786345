<template>
  <div
    class="full-height white-background mx-auto pa-4 card"
    :class="{ 'card-width': !isMobile, 'mobile-card-width': isMobile }"
  >
    <div class="d-flex full-width">
      <v-btn text color="primary" to="/activity"
        ><v-icon class="mt-n1">mdi-chevron-left</v-icon>Back</v-btn
      >
    </div>
    <v-progress-circular
      v-if="loading"
      color="brandCyan"
      indeterminate
      :size="40"
      :width="3"
    ></v-progress-circular>
    <div v-else>
      <v-card-title class="align-text-left mb-4 word-break text-xl">{{
        title
      }}</v-card-title>

      <v-card-subtitle class="align-text-left mb-4 word-break">{{
        subtitle
      }}</v-card-subtitle>

      <v-img
        :src="imageURL"
        min-width="250"
        width="60%"
        max-width="600"
        :aspect-ratio="16 / 9"
        class="mb-8 mx-auto"
        v-if="imageURL != null"
      />

      <div
        class="mt-2 align-text-left mx-auto"
        :class="{
          'ql-editor white-space-normal': !(
            computedContent &&
            computedContent.includes('If you need additional help')
          )
        }"
        style="max-width: 60%;"
        v-html="computedContent"
      ></div>
    </div>

    <v-dialog v-model="errorDialog" persistent width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-center align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break text-left exit-warning-text">
            We couldn't find the message you're trying to load
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            to="/activity"
            width="130"
            >Exit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommunicationService from "../services/CommunicationService";
// @ is an alias to /src
// import CommunicationService from "@/services/CommunicationService";

export default {
  name: "NewsArticle",
  title: "Whistle | News",
  props: {
    messageId: String,
    article: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      title: null,
      subtitle: null,
      imageURL: null,
      content: null,
      loading: false,
      errorDialog: false
    };
  },
  created() {
    console.log("Loading ", this.$route.params);
    console.log(this.article);
    if (this.article) {
      this.title = this.article.messageSubject;
      this.subtitle = this.article.messageShortBody;
      this.imageURL = this.article.imageURL;
      var trimmedMsg = this.article.messageBody;
      if (this.article.messageBody.startsWith('<h1 id="title"')) {
        trimmedMsg = this.article.messageBody.substring(
          this.article.messageBody.indexOf("</h1>") + 5
        );
        console.log(trimmedMsg);
      }

      //Subtitle is optional, so we have to check
      if (trimmedMsg.startsWith('<h2 id="subtitle"')) {
        console.log("Contains subtitle");
        trimmedMsg = trimmedMsg.substring(trimmedMsg.indexOf("</h2>") + 5);
        console.log(trimmedMsg);
      }

      //Image is optional, so we have to check
      if (trimmedMsg.startsWith('<img id="img"')) {
        console.log("Contains image");
        trimmedMsg = trimmedMsg.substring(trimmedMsg.indexOf("/>") + 2);
        console.log(trimmedMsg);
      }

      this.content = trimmedMsg;
    } else {
      this.loading = true;
      CommunicationService.getMessage(this.messageId)
        .then(message => {
          console.log("News article ", message);
          if (!message) {
            this.errorDialog = true;
          } else {
            this.title = message.messageSubject;
            this.subtitle = message.messageShortBody;
            this.imageURL = message.imageURL;
            var trimmedMsg = message.messageBody;
            if (message.messageBody.startsWith('<h1 id="title"')) {
              trimmedMsg = message.messageBody.substring(
                message.messageBody.indexOf("</h1>") + 5
              );
              console.log(trimmedMsg);
            }

            //Subtitle is optional, so we have to check
            if (trimmedMsg.startsWith('<h2 id="subtitle"')) {
              console.log("Contains subtitle");
              trimmedMsg = trimmedMsg.substring(
                trimmedMsg.indexOf("</h2>") + 5
              );
              console.log(trimmedMsg);
            }

            //Image is optional, so we have to check
            if (trimmedMsg.startsWith('<img id="img"')) {
              console.log("Contains image");
              trimmedMsg = trimmedMsg.substring(trimmedMsg.indexOf("/>") + 2);
              console.log(trimmedMsg);
            }
            this.content = trimmedMsg;
            this.loading = false;
          }
        })
        .catch(e => {
          console.log("Error getting news article ", e);
          this.errorDialog = true;
        });
    }
  },
  methods: {
    // getMessage(messageId) {
    //   CommunicationService.getMessage
    // },
  },
  computed: {
    computedContent() {
      // Used because the email formatting shows up a little oddly on web
      var temp = this.content
        ? this.content.replace(/height: 107px;/g, "height: 115px;")
        : this.content;
      return temp;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  }
};
</script>

<style scoped>
.card {
  border-radius: 2px;
  height: 100%;
  overflow: hidden;
}
.mobile-card-width {
  width: 100%;
}

.card-width {
  min-width: 300px;
  width: 50%;
  max-width: 100%;
}

.white-space-normal {
  white-space: normal;
}
</style>

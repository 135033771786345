import { main as axios, noAuth as noAuthAxios } from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_LEARNING_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-learning"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-learning" : "/learning-service"
    }`;

class LearningService {
  createLearningModule(module, version = 1) {
    return axios
      .post(baseUrl + "/learningModules", module)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  replaceLearningModuleImage(moduleId, clientId, image, version = 1) {
    let formData = new FormData();
    formData.append("clientId", clientId);
    if (image) {
      formData.append("file", image);
    }

    return axios
      .put(baseUrl + "/learningModules/image/" + moduleId, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  updateLearningModule(learningModuleId, module, version = 1) {
    return axios
      .patch(baseUrl + "/learningModules/" + learningModuleId, module)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  deleteLearningModule(moduleId) {
    return axios
      .delete(baseUrl + "/learningModules/" + moduleId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error deleting module", error);
        throw error && error.response
          ? error.response.data || error.response
          : error;
      });
  }

  createLearningChapter(chapter, version = 1) {
    return axios
      .post(baseUrl + "/learningChapters", chapter)
      .then(response => {
        console.log("chapter response:");
        console.log(response.data.chapter);
        return response.data.chapter;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }
  updateLearningChapter(learningChapterId, chapter, version = 1) {
    return axios
      .patch(baseUrl + "/learningChapters/" + learningChapterId, chapter)
      .then(response => {
        console.log(response);
        console.log(chapter);
        console.log("updated!!!!!!!!!");
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  updateLearningChapterBulk(chapters, version = 1) {
    return axios
      .patch(baseUrl + "/learningChapters/bulk", chapters)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  deleteLearningChapter(chapterId, version = 1) {
    return axios
      .delete(baseUrl + "/learningChapters/" + chapterId)
      .then(response => {
        console.log("chapter delete response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  // Creating a new card and adding to chapter
  createLearningCardInChapter(chapterId, card, version = 1) {
    console.log("create card::");
    console.log(card);
    return axios
      .post(baseUrl + "/learningChapters/" + chapterId + "/learningCards", card)
      .then(response => {
        console.log("card response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  // adding an existing card to an existing chapter (create association)
  // cards is an array of association objects
  addExistingCardsToChapter(cards) {
    return axios
      .post(baseUrl + "/learningCardAssociations/bulk", cards)
      .then(response => {
        console.log("Post card assocation response:", response.data);
        return response.data.associations;
      })
      .catch(error => {
        console.log("Error!", error);
        throw error;
      });
  }

  getLearningCard(cardId, admin = false, wToken = null, version = 1) {
    // Admin boolean is used to indicate if we query all quiz questions, regardless of random question pool
    // wToken is for passing a Whistle token from a public survey
    var query = null;
    if (admin) {
      query = "?admin=true";
    }

    //whistle token version:
    if (wToken) {
      //use the passed in whistle token as the bearer token
      return noAuthAxios
        .get(baseUrl + "/learningCards/whistleToken/" + cardId, {
          headers: {
            common: {
              authorization: "Bearer " + wToken //override the normal bearer token shiz
            }
          }
        })
        .then(response => {
          console.log("card response:", response.data);
          console.log(response.data.cards[0]);
          return response.data.cards[0];
        })
        .catch(error => {
          console.log("Error!", error);
          throw error;
        });
    } else {
      //do it the nromal way:
      return axios
        .get(baseUrl + "/learningCards/" + cardId + (query ? query : ""))
        .then(response => {
          console.log("card response: ", response.data);
          console.log(response.data.cards[0]);
          return response.data.cards[0];
        })
        .catch(error => {
          console.log("Error!", error);
          console.log("Version ", version);
          if (version == 2) throw error;
          else return error;
        });
    }
  }

  // Get all learning cards (for client or if Whistle all)
  getLearningCards(admin = false) {
    // Admin boolean is used to indicate if we query all quiz questions, regardless of random question pool
    var query = null;
    if (admin) {
      query = "?admin=true";
    }
    return axios
      .get(baseUrl + "/learningCards/" + (query ? query : ""))
      .then(response => {
        console.log("All cards response: ", response.data);
        // console.log(response.data);
        return response.data.cards;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteLearningCard(cardId) {
    return axios
      .delete(baseUrl + "/learningCards/" + cardId)
      .then(response => {
        console.log("card delete response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  updateLearningCard(cardId, card, version = 1) {
    return axios
      .patch(baseUrl + "/learningCards/" + cardId, card)
      .then(response => {
        console.log("card response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  updateLearningCardAssociationBulk(array, version = 1) {
    return axios
      .patch(baseUrl + "/learningCardAssociations/bulk", array)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  createLearningQuizQuestion(question) {
    return axios
      .post(baseUrl + "/learningQuizQuestions/", question)
      .then(response => {
        console.log("q response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  updateLearningQuizQuestion(questionId, question) {
    return axios
      .patch(baseUrl + "/learningQuizQuestions/" + questionId, question)
      .then(response => {
        console.log("q response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteLearningQuizQuestion(questionIds, version = 1) {
    var pairs = questionIds.map(function(value) {
      return "id=" + encodeURIComponent(value);
    });
    var query_string = pairs.join("&");
    return axios
      .delete(baseUrl + "/learningQuizQuestions/" + query_string)
      .then(response => {
        console.log("question response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  createLearningQuizAnswer(answer) {
    return axios
      .post(baseUrl + "/learningQuizAnswers/", answer)
      .then(response => {
        console.log("answer response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  createLearningQuizAnswerBulk(answer) {
    return axios
      .post(baseUrl + "/learningQuizAnswers/bulk", answer)
      .then(response => {
        console.log("answer response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  updateLearningQuizAnswer(answerId, answer) {
    return axios
      .patch(baseUrl + "/learningQuizAnswer/" + answerId, answer)
      .then(response => {
        console.log("answer response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  // There isn't a DELETE endpoint so we just patch the status
  deleteLearningQuizAnswer(answerId, version = 1) {
    return axios
      .patch(baseUrl + "/learningQuizAnswer/" + answerId, { status: "Deleted" })
      .then(response => {
        console.log("answer response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  // this is for the learning builder, it takes a new or existing quiz and creates, updates, or clones it
  postLearningQuiz(learningCardId, quiz, clone = false, version = 1) {
    console.log("Posting learning quiz", learningCardId);
    console.log(quiz);
    console.log("clone ", clone);
    return axios
      .post(baseUrl + "/learningQuiz/" + learningCardId, quiz, {
        params: { clone: clone }
      })
      .then(response => {
        console.log("quiz response:", response.data);

        return response.data.question;
      })
      .catch(error => {
        console.log("error: ", error);
        if (version == 2) throw error;
        else return error;
      });
  }

  getQuizQuestion(questionId) {
    return axios
      .get(baseUrl + "/learningQuizQuestions/" + questionId, {
        status: "Deleted"
      })
      .then(response => {
        console.log("answer response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  getChaptersFromModule(learningModuleId) {
    return axios
      .get(baseUrl + "/learningModules/" + learningModuleId)
      .then(response => {
        console.log(response.data.modules);
        return response.data.modules;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  getAllModules(page, size, search, filter, sort, clientIdQuery = null) {
    var query_string =
      "?page=" +
      page +
      "&size=" +
      size +
      "&filter=" +
      filter +
      "&sort=" +
      sort +
      "&search=" +
      encodeURIComponent(search) +
      "&clientId=" +
      clientIdQuery;
    console.log(query_string);
    return axios
      .get(baseUrl + "/learningIndex" + query_string)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  //this will be depricated in favor of getModulesAdmin below
  getAllModulesAdmin() {
    return axios
      .get(baseUrl + "/learningModules")
      .then(response => {
        console.log(response.data);
        return response.data.modules;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  getModulesAdmin(
    type = "learning",
    clientIdQuery = null,
    includeParent = false,
    liteFlag = true,
    version = 1,
    learningModuleId = null
  ) {
    // If a whistle user, clientIdQuery will be passed as one, otherwise it's the user's clientId
    let url = baseUrl + "/modules/" + type;
    // if (clientIdQuery !== null) {
    //   url += "?clientId=" + clientIdQuery;
    //   if (includeParent == true) {
    //     url += "&includeParent=true";
    //   }
    // } else if (includeParent == true) {
    //   url += "?includeParent=true";
    // }

    let queryString = new URLSearchParams({
      lite: liteFlag,
      includeParent,
      version
    });
    if (clientIdQuery !== null) queryString.append("clientId", clientIdQuery);
    if (learningModuleId !== null)
      queryString.append("learningModuleId", learningModuleId);
    console.log("QUERY ", queryString.toString());
    url += "?" + queryString.toString();
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        if (learningModuleId) throw error;
        return error;
      });
  }

  getModule(id) {
    return axios
      .get(`${baseUrl}/learningModule/${id}`)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        throw error;
      });
  }

  getModuleContent(moduleId) {
    return axios
      .get(baseUrl + "/learningIndex/" + moduleId)
      .then(response => {
        console.log("GETMODULECONTENT-------------LEARNING SERVICE----------");
        console.log(response.data.modules);

        return response.data.modules;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  getSurveyContent(moduleId) {
    return axios
      .get(baseUrl + "/surveyIndex/" + moduleId)
      .then(response => {
        console.log(
          "GETSURVEYCONTENT-------------LEARNING SERVICE----------",
          response.data.modules
        );

        return response.data.modules;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getPublicSurveyAndToken(moduleId, email) {
    return axios
      .get(baseUrl + "/surveyIndex/public/" + moduleId + "/" + email)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log("Error!", error.response || error);
        throw error.response || error;
      });
  }
  getSignedUrlForUpload(context, resourceId, fileName) {
    var newFileName = fileName.toLowerCase().replace(new RegExp(" ", "g"), "_");
    return axios
      .get(
        baseUrl +
          "/learning/file/signedURL/" +
          context +
          "/" +
          resourceId +
          "/" +
          newFileName
      )
      .then(response => {
        // console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getSignedUrlForViewingContent(url) {
    console.log(baseUrl + url);
    return axios
      .get(baseUrl + url)
      .then(response => {
        // console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  createLearningLog(log, associatedPrograms, wToken = null) {
    //whistle token version:
    console.log("ASSOCIATED PROGRAMS ", associatedPrograms);
    if (wToken) {
      //use the passed in whistle token as the bearer token
      return noAuthAxios
        .post(baseUrl + "/learningLogs/whistleToken", log, {
          headers: {
            authorization: "Bearer " + wToken //override the normal bearer token shiz
          }
        })
        .then(response => {
          console.log("card response:", response.data);
          return response.data.log;
        })
        .catch(error => {
          console.log("Error!", error);
          return error;
        });
    } else {
      return axios
        .post(
          baseUrl + "/learningLogs",
          associatedPrograms ? { programs: associatedPrograms, data: log } : log
        )
        .then(response => {
          console.log(response.data);
          console.log("Log sent!");
          return response.data;
        })
        .catch(error => {
          console.log("Error!", error);
          return error;
        });
    }
  }

  createQuizResponse(quizResponse, wToken = null) {
    //whistle token version:
    if (wToken) {
      //use the passed in whistle token as the bearer token
      return noAuthAxios
        .post(baseUrl + "/learningQuizResponses/whistleToken", quizResponse, {
          headers: {
            authorization: "Bearer " + wToken //override the normal bearer token shiz
          }
        })
        .then(response => {
          console.log("log sent:");
          console.log(response.data);
          return response.data.response;
        })
        .catch(error => {
          console.log("Error!", error);
          return error;
        });
    } else {
      return axios
        .post(baseUrl + "/learningQuizResponses", quizResponse)
        .then(response => {
          console.log(response.data);
          console.log("Quiz response sent!");
          return response.data;
        })
        .catch(error => {
          console.log("Error!", error);
          return error;
        });
    }
  }

  getLearningLog(logId) {
    return axios
      .post(baseUrl + "/learningLogs/" + logId)
      .then(response => {
        console.log(response.data);
        console.log("Log received!");
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteLearningCardAssociation(associationId, version = 1) {
    return axios
      .delete(baseUrl + "/learningCardAssociations/" + associationId)
      .then(response => {
        console.log("association delete response:");
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        if (version == 2) throw error;
        else return error;
      });
  }
  //Learning Attachment
  getLearningAttachment(id, filepath) {
    return axios
      .get(baseUrl + "/learningModules/attachments/id/" + id, {
        responseType: "blob"
      })
      .then(response => {
        console.log(response.data);
        var blob = new Blob([response.data], {
          type: "application/octet-stream"
        });

        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = filepath.substring(filepath.lastIndexOf("/") + 1);
        console.log(filepath);
        console.log(fileName);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  createLearningAttachment(body) {
    return axios
      .post(baseUrl + "/learningModules/attachments", body)
      .then(response => {
        console.log(response);
        return response.data.learningAttachment;
      })
      .catch(error => {
        console.log("Error!", error);
        throw error;
      });
  }
  createLearningAttachmentBulk(array, version = 1) {
    return axios
      .post(baseUrl + "/learningModules/attachments/bulk", array)
      .then(response => {
        console.log(response);
        return response.data.learningAttachment;
      })
      .catch(error => {
        console.log("Error creating bulk learning attachments: ", error);
        if (version == 2) throw error;
        else return error;
      });
  }
  deleteLearningAttachment(id, clientId) {
    // var pairs = array.map(function(value) {
    //   return "id=" + encodeURIComponent(value);
    // });
    // var query_string = pairs.join("&");
    return axios
      .delete(
        baseUrl + "/learningModules/attachments/id/" + clientId + "/" + id
      )
      .then(response => {
        return response.data.success;
      })
      .catch(error => {
        console.log("Error deleting learning attachment", error);
        throw error;
      });
  }
  putLearningModuleAttachment(moduleId, learningAttachmentArr) {
    console.log(
      "calling PUT '/learningModules/attachments/:learningModuleId' endpoint"
    );
    return axios
      .put(
        baseUrl + "/learningModules/attachments/" + moduleId,
        learningAttachmentArr
      )
      .then(response => {
        console.log(response);
        //     learningAttachmentArr.forEach((attachment) =>
        //     attachment = {
        //       learningModuleId: response.data.learningModule.learningModuleId,
        //       contentId: response.data.learningModule.slide9.selectedContent.contentId,
        //       status: response.data.learningModule.status,
        //       createdBy: response.data.learningModule.slide9.selectedContent.createdBy,
        //       updatedBy: response.data.learningModule.slide9.selectedContent.updatedBy
        // }
        // );
        return response;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  duplicateLearningModule(learningModuleId, clientId) {
    console.log("Duplicating learning module");
    console.log(learningModuleId, "for client", clientId);
    return axios
      .post(
        baseUrl +
          "/learningModules/duplicate/" +
          learningModuleId +
          "/" +
          clientId
      )
      .then(response => {
        console.log(response);
        console.log(response.data.result);
        return response.data.result;
      })
      .catch(error => {
        console.log("Error!", error);
        return error.response.data;
      });
  }

  getModuleIndex(idArray) {
    return axios
      .get(
        baseUrl +
          "/moduleIndex/" +
          (Array.isArray(idArray) ? idArray.join(",") : idArray)
      )
      .then(response => {
        return response.data.modules;
      })
      .catch(error => {
        console.log("Error getting moduleIndex!", error.response);
        throw error;
      });
  }

  // Tagging endpoints
  getAllTagDefinitions(version = 1) {
    return axios
      .get(baseUrl + "/tagDefinition")
      .then(response => {
        return response.data.tagDefinitions;
      })
      .catch(error => {
        console.log("Error!", error.response);
        if (version == 2) throw error;
        else return error;
      });
  }
  getLearningModuleTags(moduleId, version = 1) {
    console.log("calling byLearningModule tag endpoint");
    return axios
      .get(baseUrl + "/tagDefinitions/byLearningModule/" + moduleId)
      .then(response => {
        console.log("Learning module tags: ", response.data);
        var definitions = [];
        response.data.tags.forEach(function(item) {
          console.log(item);
          definitions.push(item.TagDefinition);
        });

        return definitions;
      })
      .catch(error => {
        console.log("Error!", error.response);
        if (version == 2) throw error;
        else return error;
      });
  }
  putLearningModuleTags(moduleId, tagArray, version = 1) {
    console.log("calling byLearningModuleTags/learningModuleId endpoint");
    return axios
      .put(baseUrl + "/learningModuleTags/" + moduleId, tagArray)
      .then(response => {
        // var definitions = [];
        // response.data.learningModuleTag.forEach(function(item) {
        //   console.log(item);
        //   definitions.push(item.TagDefinition);
        // });

        return response.data.learningModuleTag;
      })
      .catch(error => {
        console.log("Error!", error.response);
        if (version == 2) throw error;
        else return error;
      });
  }
  getLearningCardTags(learningCardId) {
    console.log("calling byLearningCard tag endpoint");
    console.log(learningCardId);
    return axios
      .get(baseUrl + "/tagDefinitions/byLearningCard/" + learningCardId)
      .then(response => {
        console.log(response.data);
        var definitions = [];
        response.data.tags.forEach(function(item) {
          definitions.push(item.TagDefinition);
        });
        return definitions;
      })
      .catch(error => {
        console.log("Error!", error);
        throw error;
      });
  }
  putLearningCardTags(learningCardId, tagArray) {
    console.log("calling byLearningCardTags/learningCardId endpoint");
    return axios
      .put(baseUrl + "/learningCardTags/" + learningCardId, tagArray)
      .then(response => {
        return response.data.learningCardTag;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  // V2 endpoints
  duplicateLearningModuleV2(id, query = "") {
    return axios
      .post(baseUrl + "/v2/learning-module/duplicate/" + id + query)
      .then(response => {
        console.log("Duplicated module ", response.data.result);
        return response.data.result;
      })
      .catch(error => {
        console.log("Error duplicating module ", error.response.data);
        throw error.response.data;
      });
  } //end duplicateLearningModuleV2

  replaceLearningModuleGroups(id, body, query) {
    return axios
      .put(
        baseUrl + "/v2/learning-module-group/learning-module/" + id + query,
        body
      )
      .then(response => {
        console.log("replaces module groups ", response.data.result);
        return response.data.result;
      })
      .catch(error => {
        console.log("Error replacing module groups ", error.response.data);
        throw error.response.data;
      });
  } //end replaceLearningModuleGroups

  getLearningModulesV2(params) {
    return axios
      .get(baseUrl + "/v2/learning-module", { params: params })
      .then(response => {
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error getting learning modules ", error.response);
        throw error.response;
      });
  } //end getLearningModulesV2

  getLearningCardsV2(params) {
    return axios
      .get(baseUrl + "/v2/learning-card", { params: params })
      .then(response => {
        //Prune the returned users for the data we need for the table
        return response.data;
      })
      .catch(error => {
        console.log("Error getting learning cards ", error.response);
        throw error.response;
      });
  } //end getLearningCardsV2

  getSubscriptionV2(params) {
    return axios
      .get(baseUrl + "/v2/learning-module/subscription", { params: params })
      .then(response => {
        //Prune the returned users for the data we need for the table
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting subscription info ", error.response);
        throw error.response;
      });
  } //end getLearningModulesV2
}

export default new LearningService();

<template>
  <div class="my-2 ml-3 mr-2">
    <v-card flat class="my-2 ml-3 mr-2">
      <v-row>
        <v-col sm="12" md="3">
          <h2 class="align-text-left mb-3">Robin Manager</h2>
          <v-divider></v-divider>
          <p class="align-text-left mt-3">
            Welcome to Whistle's Robin manager.
          </p>
          <p class="align-text-left">
            Here you can view the current recommendation types and categories as
            well as adjust weights.
          </p>
          <p class="align-text-left" v-if="userProfile.clientId == 1">
            For each recommendation type listed, we have a generic version
            (Client = Universal) or we also allow a weighting to be overriden on
            a per-client basis. The plus button next to each type will allow you
            to create a client override.
          </p>
          <p v-else class="text-left">
            For each recommendation type listed, Whistle has a default
            reccomendation weight. Clicking the plus button allows you to
            customize the default weighting to make Robin your own!
          </p>
        </v-col>
        <v-col sm="12" md="9">
          <div class="d-flex justify-space-between align-center mr-2 mb-3">
            <v-btn text color="primary" to="accountManagement"
              >Recommendation Types</v-btn
            >
            <v-text-field
              outlined
              placeholder="Search"
              prepend-icon="mdi-magnify"
              clearable
              single-line
              dense
              hide-details
              v-model="search"
              class="shrink"
            ></v-text-field>
          </div>
          <div>
            <v-autocomplete
              class="pa-1 client-dropdown"
              outlined
              :items="clients"
              item-text="formattedName"
              item-value="clientId"
              label="Client"
              v-model="clientId"
              v-if="userProfile.clientId === 1"
            ></v-autocomplete>
            <div
              v-for="category in types"
              :key="category.value"
              class="align-text-left"
            >
              <v-divider class="mb-1 mt-3"></v-divider>
              <h3 class="mb-2">{{ category.text }}</h3>
              <div
                v-for="type in visibleRecommendationTypes.filter(
                  x => x.type == category.value
                )"
                :key="type.recommendationTypeId"
                class="d-flex justify-space-between mr-5 mb-1"
              >
                <div class="d-flex align-center">
                  <v-tooltip top open-delay="200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        class="mr-2"
                        :class="{ hidden: type.clientId != 0 || clientId == 0 }"
                        @click="overrideRecommendationType(type)"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                    <span>Create a custom recommendation weight</span>
                  </v-tooltip>
                  <v-tooltip top open-delay="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        class="mr-2"
                        :class="{
                          hidden:
                            userProfile.clientId != 1 && type.clientId == 0
                        }"
                        @click="editRecommendationType(type)"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Edit the existing recommendation type</span>
                  </v-tooltip>
                  <span v-if="formattedSubtypes[type.subtype]">{{
                    formattedSubtypes[type.subtype]
                  }}</span>
                  <span v-else>{{
                    type.subtype ? type.subtype.toLowerCase() : ""
                  }}</span>
                </div>
                <span
                  >Weight: <b>{{ type.weightModifier }}</b></span
                >
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- Nav drawer for adding/editing rec types -->
    <v-navigation-drawer
      v-model="dialogRecTypeForm"
      temporary
      fixed
      right
      width="500"
    >
      <v-card flat>
        <v-toolbar dark color="primary" rounded="0">
          <v-toolbar-title v-if="recType.recommendationTypeId == undefined"
            >Create New Type</v-toolbar-title
          >
          <v-toolbar-title v-else>Update Type</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="resetForm">
              <span>Close</span>
            </v-btn>
            <v-btn
              dark
              text
              @click="createRecommendationType"
              :disabled="!recType.valid"
              v-if="recType.recommendationTypeId == undefined"
            >
              Create
            </v-btn>
            <v-btn
              dark
              text
              @click="updateRecommendationType"
              :disabled="!recType.valid"
              v-else
              >Update</v-btn
            >
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text>
          <v-row justify="center">
            <h3 class="my-2" v-if="recType.clientId == 0">
              <v-icon color="error">mdi-alert</v-icon>
              NOTE: You are editing a universal recommendation type. This will
              affect users across all clients.
            </h3>
            <v-col
              class="d-flex flex-column mt-3"
              cols="12"
              sm="9"
              md="9"
              lg="9"
            >
              <v-form ref="recTypeForm" v-model="recType.valid">
                <v-textarea
                  class="pa-1"
                  v-model="recType.description"
                  :rules="[
                    v => !!v || 'A description is required',
                    v =>
                      !!(v && v.length < 256) ||
                      'The description should be shorter'
                  ]"
                  label="Recommendation description (Not seen by user)*"
                  rows="2"
                ></v-textarea>
                <v-text-field
                  class="pa-1"
                  v-model="recType.friendlyHeader"
                  :rules="[
                    v => !!v || 'A header message is required',
                    v =>
                      !!(v && v.length < 256) ||
                      'The header message should be shorter'
                  ]"
                  label="Robin Header Message*"
                  required
                ></v-text-field>
                <v-textarea
                  class="pa-1"
                  v-model="recType.friendlyMessage"
                  :rules="[
                    v => !!v || 'A message is required',
                    v =>
                      !!(v && v.length < 256) || 'The message should be shorter'
                  ]"
                  label="Robin Message*"
                  rows="4"
                ></v-textarea>
                <v-text-field
                  class="pa-1"
                  v-model="recType.weightModifier"
                  :rules="[v => !!v || 'A weight is required']"
                  label="Weight*"
                  type="number"
                ></v-text-field>
                <v-checkbox
                  v-if="recType.clientId != 0"
                  :value="recType.status == 'Disabled'"
                  label="Disabled"
                  class="pa-1"
                  @click="
                    if (recType.status == 'Disabled') {
                      recType.status = 'Active';
                    } else {
                      recType.status = 'Disabled';
                    }
                  "
                />
                <v-divider class="py-3" />
                <v-select
                  class="pa-1"
                  v-model="recType.tieBreaker1"
                  label="Tie breaker 1"
                  :items="tiebreakers"
                  item-value="value"
                  item-text="text"
                  @change="wipeTieBreaker(1)"
                ></v-select>
                <v-select
                  v-if="
                    recType.tieBreaker1 &&
                      recType.tieBreaker1 != 'mandatory' &&
                      recType.tieBreaker1 != 'qualified'
                  "
                  class="pa-1"
                  v-model="recType.tieBreaker1Sort"
                  label="Tie breaker 1 Sorting"
                  :items="sortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-select
                  v-else-if="recType.tieBreaker1"
                  class="pa-1"
                  v-model="recType.tieBreaker1Sort"
                  label="Tie breaker 1 Sorting"
                  :items="booleanSortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-divider class="py-3" />
                <v-select
                  class="pa-1"
                  v-model="recType.tieBreaker2"
                  label="Tie breaker 2"
                  :items="tiebreakers"
                  item-value="value"
                  item-text="text"
                  @change="wipeTieBreaker(2)"
                ></v-select>
                <v-select
                  v-if="
                    recType.tieBreaker2 &&
                      recType.tieBreaker2 != 'mandatory' &&
                      recType.tieBreaker2 != 'qualified'
                  "
                  class="pa-1"
                  v-model="recType.tieBreaker2Sort"
                  label="Tie breaker 2 Sorting"
                  :items="sortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-select
                  v-else-if="recType.tieBreaker2"
                  class="pa-1"
                  v-model="recType.tieBreaker2Sort"
                  label="Tie breaker 2 Sorting"
                  :items="booleanSortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-divider class="py-3" />
                <v-select
                  class="pa-1"
                  v-model="recType.tieBreaker3"
                  label="Tie breaker 3"
                  :items="tiebreakers"
                  item-value="value"
                  item-text="text"
                  @change="wipeTieBreaker(3)"
                ></v-select>
                <v-select
                  v-if="
                    recType.tieBreaker3 &&
                      recType.tieBreaker3 != 'mandatory' &&
                      recType.tieBreaker3 != 'qualified'
                  "
                  class="pa-1"
                  v-model="recType.tieBreaker3Sort"
                  label="Tie breaker 3 Sorting"
                  :items="sortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-select
                  v-else-if="recType.tieBreaker3"
                  class="pa-1"
                  v-model="recType.tieBreaker3Sort"
                  label="Tie breaker 3 Sorting"
                  :items="booleanSortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-divider class="py-3" />
                <v-select
                  class="pa-1"
                  v-model="recType.tieBreaker4"
                  label="Tie breaker 4"
                  :items="tiebreakers"
                  item-value="value"
                  item-text="text"
                  @change="wipeTieBreaker(4)"
                ></v-select>
                <v-select
                  v-if="
                    recType.tieBreaker4 &&
                      recType.tieBreaker4 != 'mandatory' &&
                      recType.tieBreaker4 != 'qualified'
                  "
                  class="pa-1"
                  v-model="recType.tieBreaker4Sort"
                  label="Tie breaker 4 Sorting"
                  :items="sortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-select
                  v-else-if="recType.tieBreaker4"
                  class="pa-1"
                  v-model="recType.tieBreaker4Sort"
                  label="Tie breaker 4 Sorting"
                  :items="booleanSortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-divider class="py-3" />
                <v-select
                  class="pa-1"
                  v-model="recType.tieBreaker5"
                  label="Tie breaker 5"
                  :items="tiebreakers"
                  item-value="value"
                  item-text="text"
                  @change="wipeTieBreaker(5)"
                ></v-select>
                <v-select
                  v-if="
                    recType.tieBreaker5 &&
                      recType.tieBreaker5 != 'mandatory' &&
                      recType.tieBreaker5 != 'qualified'
                  "
                  class="pa-1"
                  v-model="recType.tieBreaker5Sort"
                  label="Tie breaker 5 Sorting"
                  :items="sortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-select
                  v-else-if="recType.tieBreaker5"
                  class="pa-1"
                  v-model="recType.tieBreaker5Sort"
                  label="Tie breaker 5 Sorting"
                  :items="booleanSortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-divider class="py-3" />
                <v-select
                  class="pa-1"
                  v-model="recType.tieBreaker6"
                  label="Tie breaker 6"
                  :items="tiebreakers"
                  item-value="value"
                  item-text="text"
                  @change="wipeTieBreaker(6)"
                ></v-select>
                <v-select
                  v-if="
                    recType.tieBreaker6 &&
                      recType.tieBreaker6 != 'mandatory' &&
                      recType.tieBreaker6 != 'qualified'
                  "
                  class="pa-1"
                  v-model="recType.tieBreaker6Sort"
                  label="Tie breaker 6 Sorting"
                  :items="sortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
                <v-select
                  v-else-if="recType.tieBreaker6"
                  class="pa-1"
                  v-model="recType.tieBreaker6Sort"
                  label="Tie breaker 6 Sorting"
                  :items="booleanSortOptions"
                  item-value="value"
                  item-text="text"
                ></v-select>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import RobinService from "@/services/RobinService";
import LoadingDialog from "@/components/loadingDialog.vue";

import { mapState } from "vuex";

export default {
  name: "RecommendationManager",
  props: {},
  components: {
    LoadingDialog
  },
  data() {
    return {
      clientId: null,
      recommendationTypes: [],
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      types: [
        {
          text: "Mandatory Programs",
          value: "PROGRAMS_MANDATORY"
        },
        {
          text: "Programs Ending Soon",
          value: "PROGRAMS_ENDING"
        },
        {
          text: "Unstarted Programs",
          value: "PROGRAMS_UNSTARTED"
        },
        {
          text: "Incomplete Programs",
          value: "PROGRAMS_INCOMPLETE"
        },
        {
          text: "Programs Nearly Completed",
          value: "PROGRAMS_NEAR_COMPLETION"
        },

        {
          text: "Wallet Suggestions",
          value: "WALLET_USE_FUNDS"
        },
        {
          text: "Profile Suggestions",
          value: "PROFILE"
        },
        {
          text: "Platform Suggestions",
          value: "PLATFORM_SUGGESTION"
        }
      ],
      formattedSubtypes: {
        UNQUALIFIED: "Unqualified users",
        QUALIFIED: "Users in program",
        HAS_NOT_SPENT: "Users that have not spent any of their funds",
        HAS_NOT_REQUESTED_CARD: "Users that have not requested a Whistle Card",
        EXPIRING: "Users that have funds expiring in their wallet",
        PROFILE_PHOTO: "Users that haven't updated their profile photo",
        MOBILE: "Users that haven't downloaded the Whistle mobile app",
        SEND_RECOGNITION: "Users should send a recognition to coworkers"
      },
      tiebreakers: [
        {
          text: "Start Date",
          value: "startDate"
        },
        {
          text: "End Date",
          value: "endDate"
        },
        {
          text: "Rolling Program Length",
          value: "rollingProgramLength"
        },
        {
          text: "Program Progress",
          value: "progressPercent"
        },
        {
          text: "Incentive Value in Program",
          value: "incentiveValue"
        },
        {
          text: "Includes Badge",
          value: "badgeId"
        },
        {
          text: "Mandatory Program",
          value: "mandatory"
        },
        {
          text: "Qualified User in Program",
          value: "qualified"
        },
        {
          text: "Last Interaction Date",
          value: "lastInteractionDate"
        }
      ],
      sortOptions: [
        {
          text: "Ascending",
          value: "ASC"
        },
        {
          text: "Descending",
          value: "DESC"
        }
      ],
      booleanSortOptions: [
        {
          text: "Preference to false",
          value: "FALSE"
        },
        {
          text: "Preference to true",
          value: "TRUE"
        }
      ],

      loading: false,
      search: null,
      dialogRecTypeForm: false,
      recType: {
        clientId: null,
        weightModifier: null,
        friendlyHeader: null,
        friendlyMessage: null,
        status: "Active",
        description: null
      }
    };
  },
  created() {
    this.getRecommendationTypes();
    this.clientId = this.userProfile.clientId;
  },
  beforeDestroy() {},
  methods: {
    getRecommendationTypes() {
      RobinService.getRecommendationTypes().then(types => {
        console.log("Rec types: ", types);
        this.recommendationTypes = types;
      });
    },
    createRecommendationType() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating Recommendation Type";
      this.loadingMessageLine1 = "Give us just a moment!";
      RobinService.createRecommendationType(this.recType).then(resp => {
        console.log("Rec type response: ", resp);
        this.showLoadingDialog = false;
        this.resetForm();
        this.getRecommendationTypes();
      });
    },
    updateRecommendationType() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Updating Recommendation Type";
      this.loadingMessageLine1 = "Give us just a moment!";
      RobinService.updateRecommendationType(
        this.recType.recommendationTypeId,
        this.recType
      ).then(resp => {
        console.log("Rec type response: ", resp);
        this.showLoadingDialog = false;
        this.resetForm();
        this.getRecommendationTypes();
      });
    },
    editRecommendationType(oldRec) {
      this.dialogRecTypeForm = true;
      this.recType = {
        clientId: oldRec.clientId,
        valid: true,
        status: oldRec.status,
        recommendationTypeId: oldRec.recommendationTypeId,
        friendlyMessage: oldRec.friendlyMessage,
        friendlyHeader: oldRec.friendlyHeader,
        description: oldRec.description,
        weightModifier: oldRec.weightModifier,
        tieBreaker1: oldRec.tieBreaker1,
        tieBreaker1Sort: oldRec.tieBreaker1Sort,
        tieBreaker2: oldRec.tieBreaker2,
        tieBreaker2Sort: oldRec.tieBreaker2Sort,
        tieBreaker3: oldRec.tieBreaker3,
        tieBreaker3Sort: oldRec.tieBreaker3Sort,
        tieBreaker4: oldRec.tieBreaker4,
        tieBreaker4Sort: oldRec.tieBreaker4Sort,
        tieBreaker5: oldRec.tieBreaker5,
        tieBreaker5Sort: oldRec.tieBreaker5Sort,
        tieBreaker6: oldRec.tieBreaker6,
        tieBreaker6Sort: oldRec.tieBreaker6Sort
      };
    },
    overrideRecommendationType(oldRec) {
      this.recType = {
        clientId: this.clientId,
        valid: true,
        status: oldRec.status,
        friendlyMessage: oldRec.friendlyMessage,
        friendlyHeader: oldRec.friendlyHeader,
        imageUrl: oldRec.imageUrl,
        description: oldRec.description,
        weightModifier: oldRec.weightModifier,
        type: oldRec.type,
        subtype: oldRec.subtype,
        tieBreaker1: oldRec.tieBreaker1,
        tieBreaker1Sort: oldRec.tieBreaker1Sort,
        tieBreaker2: oldRec.tieBreaker2,
        tieBreaker2Sort: oldRec.tieBreaker2Sort,
        tieBreaker3: oldRec.tieBreaker3,
        tieBreaker3Sort: oldRec.tieBreaker3Sort,
        tieBreaker4: oldRec.tieBreaker4,
        tieBreaker4Sort: oldRec.tieBreaker4Sort,
        tieBreaker5: oldRec.tieBreaker5,
        tieBreaker5Sort: oldRec.tieBreaker5Sort,
        tieBreaker6: oldRec.tieBreaker6,
        tieBreaker6Sort: oldRec.tieBreaker6Sort
      };
      this.dialogRecTypeForm = true;
    },
    resetForm() {
      this.recType = {
        clientId: null,
        valid: false,
        status: "Active",
        friendlyMessage: null,
        friendlyHeader: null,
        weightModifier: null,
        description: null
      };
      this.dialogRecTypeForm = false;
    },
    wipeTieBreaker(index) {
      console.log("Wiping");
      var varName = "this.recType.tieBreaker" + index + "Sort";
      eval(`${varName} = null`);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    visibleRecommendationTypes() {
      if (this.clientId !== null) {
        var arr = [];
        var clientTypes = this.recommendationTypes.filter(
          x => x.clientId == this.clientId
        );

        clientTypes.forEach(x => {
          var foundRec = arr.find(
            y => y.type == x.type && y.subtype == x.subtype
          );
          if (!foundRec) {
            arr.push(x);
          }
        });

        var universalTypes = this.recommendationTypes.filter(
          x => x.clientId == 0
        );
        universalTypes.forEach(x => {
          var foundRec = arr.find(
            y => y.type == x.type && y.subtype == x.subtype
          );
          if (!foundRec) {
            arr.push(x);
          }
        });
        arr = arr.sort((a, b) => {
          return b.subtype.localeCompare(a.subtype);
        });
        return arr;
      }
      return [];
    }
  }
};
</script>

<style scoped>
.client-dropdown {
  width: 350px;
}
</style>
<style lang="css" scoped></style>

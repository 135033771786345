<template>
  <div>
    <v-dialog
      fullscreen
      value="true"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="brand" rounded="0">
          <v-toolbar-title>Build a Program</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="
                if (hasEditedProgram && slideKey < keys.publishing) {
                  dialog.exit = true;
                } else {
                  reset();
                }
              "
            >
              Cancel
              <v-icon class="ml-2">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <h3 class="mt-3 text--darken-1" v-if="editingProgram && activeProgram">
          Note: This program is currently active. Any changes you make may
          impact existing users.
        </h3>
        <v-row
          class="d-flex justify-center full-height mt-4"
          no-gutters
          justify="center"
          height="100%"
        >
          <v-col
            cols="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
            class="full-height text-left word-break"
            :class="isMobile ? '' : 'mr-8'"
          >
            <div
              class="d-flex justify-space-between full-width mb-10"
              :class="isMobile ? 'px-4' : ''"
            >
              <v-btn
                outlined
                :color="
                  slideKey == keys.basics || slideKey >= keys.publishing
                    ? 'mediumGrey'
                    : 'primary'
                "
                @click="goToPreviousSlide"
                :disabled="
                  slideKey == keys.basics || slideKey >= keys.publishing
                "
                ><v-icon class="mr-1">mdi-chevron-left</v-icon>Back</v-btn
              >
              <v-tooltip
                v-if="isMobile"
                top
                :disabled="!disableContinueButton || slideKey !== keys.elements"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      v-if="isMobile"
                      :outlined="disableContinueButton"
                      color="primary"
                      @click="goToNextSlide"
                      :disabled="disableContinueButton"
                      >{{
                        route[route.findIndex(x => x == slideKey) + 1] ==
                        keys.review
                          ? "Review"
                          : slideKey == keys.review
                          ? program.previewMode
                            ? "Preview"
                            : activeProgram || archivedProgram
                            ? "Update"
                            : "Publish"
                          : slideKey == keys.publishing || slideKey == keys.sent
                          ? "Done"
                          : "Continue"
                      }}<v-icon class="ml-1">mdi-chevron-right</v-icon></v-btn
                    >
                  </div>
                </template>
                <div v-if="slideKey == keys.elements && !validProgramReward">
                  <span>Add a program reward to continue</span>
                </div>
                <div
                  v-else-if="
                    slideKey == keys.elements && !program.elements.length
                  "
                >
                  <span>Add program elements to continue</span>
                </div>
              </v-tooltip>
            </div>
            <!-- <p class="grey--text mt-8">Activity card preview</p> -->
            <!-- <div class="d-flex align-end word-break"> -->
            <v-card-title
              class="text-left word-break mb-0 pb-0 pl-0"
              :class="isMobile ? 'mx-4' : ''"
              >{{ slideTitle }}</v-card-title
            >
            <!-- </div> -->
            <v-divider class="mt-8" />
            <div class="my-6 full-width" :class="isMobile ? 'px-3' : ''">
              <div
                class="d-flex align-center cursor-pointer"
                @click="goToSlide(keys.basics)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': program.name,
                    'light-grey-background': !program.name
                  }"
                  color="white"
                  >mdi-star</v-icon
                >
                <div
                  class="side-nav-box ml-2 px-3"
                  :class="{ 'side-nav-bold-text': program.name }"
                >
                  {{ program.name || "Program name" }}
                </div>
              </div>
              <div
                class="d-flex align-center mt-2 cursor-pointer"
                @click="goToSlide(keys.dates)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': program.startDate,
                    'light-grey-background': !program.startDate
                  }"
                  color="white"
                  >mdi-calendar-clock</v-icon
                >
                <div
                  class="side-nav-box ml-2 px-3"
                  :class="{ 'side-nav-bold-text': program.startDate }"
                >
                  {{
                    programLength === null
                      ? formattedStartDate
                      : programLength > 0
                      ? programLength +
                        (programLength == 1
                          ? " day in program"
                          : " days in program")
                      : "Add date and time"
                  }}
                </div>
              </div>
              <div
                class="d-flex align-center mt-2 cursor-pointer"
                @click="goToSlide(keys.audience)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': program.audience.length > 0,
                    'light-grey-background': !(program.audience.length > 0)
                  }"
                  color="white"
                  >mdi-account-multiple</v-icon
                >
                <div
                  class="side-nav-box ml-2 px-3"
                  :class="{ 'side-nav-bold-text': program.audience.length > 0 }"
                >
                  {{
                    program.audience.length > 0
                      ? program.audience.length +
                        (program.audience.length == 1
                          ? " group "
                          : " groups ") +
                        (loading.groups || peopleInSelectedGroups == 0
                          ? ""
                          : "(~" +
                            peopleInSelectedGroups +
                            (peopleInSelectedGroups == 1
                              ? " person)"
                              : " people)"))
                      : preloadedProgram && loading.groups
                      ? "Loading groups"
                      : "Select who is invited"
                  }}
                  <v-progress-circular
                    v-if="preloadedProgram && loading.groups"
                    :width="2"
                    :size="16"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </div>
              <div
                class="d-flex align-start mt-2 cursor-pointer"
                @click="goToSlide(keys.elements)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': allProgramElements.length > 0,
                    'light-grey-background': !(allProgramElements.length > 0)
                  }"
                  color="white"
                  >mdi-school</v-icon
                >
                <!-- 'program-elements-height': allProgramElements.length == 0 -->
                <div
                  class="d-flex flex-column ml-2 px-3 cursor-pointer program-elements-box"
                  :class="{
                    'side-nav-bold-text': allProgramElements.length > 0,
                    'side-nav-box': !program.expandElements
                  }"
                >
                  <div class="d-flex justify-space-between">
                    {{ "Program elements (" + allProgramElements.length + ")" }}
                    <v-btn small loading icon v-if="loading.elements"></v-btn>
                    <v-icon
                      @click.stop.native="
                        program.expandElements = !program.expandElements
                      "
                      id="program-element-expander"
                      v-else-if="allProgramElements.length > 0"
                      >{{
                        program.expandElements
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon
                    >
                  </div>
                  <div v-if="program.expandElements">
                    <draggable :list="program.elements" group="elements">
                      <div
                        v-for="(item, i) in program.elements"
                        :key="`${item.id}-${i}`"
                      >
                        <v-hover v-slot="{ hover }">
                          <div class="d-flex align-center my-2">
                            <!-- <v-icon
                              v-if="item.displayGrabber"
                              small
                              class="mr-2"
                              >mdi-drag-horizontal-variant</v-icon
                            > -->
                            <!-- <v-btn
                              v-if="
                                item.context == 'MetricDefinitions' && hover
                              "
                              icon
                              small
                              @click.stop.native="loadMetricWidget(item, i)"
                            >
                              <v-icon
                                color="
                                  grey
                                "
                                small
                                >mdi-pencil</v-icon
                              >
                            </v-btn>
                            <v-btn
                              icon
                              small
                              v-if="hover"
                              class="mr-2"
                              @click.stop.native="
                                dialog.elementDeleteConfirmation = true;
                                dialog.elementDeleteIndex = i;
                              "
                            >
                              <v-icon color="red" small
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn> -->
                            <v-icon
                              class="side-nav-icons"
                              :class="`${item.iconColor}-background`"
                              color="white"
                              >{{ item.icon }}</v-icon
                            >

                            <div
                              class="d-flex justify-space-between align-center program-elements-box program-elements-height ml-2 pr-3"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-on="on"
                                    v-bind="attrs"
                                    class="ml1-n2 mb-1"
                                    small
                                    style="min-width: 16px; max-width: 16px;"
                                    :class="
                                      item.status == 'Draft' ? '' : 'hidden'
                                    "
                                    color="error"
                                    >mdi-exclamation</v-icon
                                  >
                                </template>
                                This
                                {{
                                  item.context == "LearningModules" &&
                                  item.type == "Survey"
                                    ? "survey"
                                    : "module"
                                }}
                                is in Draft mode. Until published, it will be
                                hidden from the program
                              </v-tooltip>
                              <span
                                class="program-element-label"
                                :title="item.displayName || 'Unknown element'"
                                >{{
                                  item.displayName
                                    ? item.displayName
                                    : preloadedProgram &&
                                      (loading.modules ||
                                        loading.surveys ||
                                        loading.resources ||
                                        loading.metrics)
                                    ? "Loading"
                                    : "Unknown element"
                                }}
                                <v-progress-circular
                                  v-if="
                                    !item.displayName &&
                                      preloadedProgram &&
                                      (loading.modules ||
                                        loading.surveys ||
                                        loading.resources ||
                                        loading.metrics) == true
                                  "
                                  :width="2"
                                  :size="16"
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </span>

                              <v-btn
                                v-if="
                                  item.context == 'MetricDefinitions' &&
                                    hover &&
                                    slideKey < keys.publishing
                                "
                                class="mt-n2"
                                icon
                                x-small
                                @click.stop.native="loadMetricWidget(item, i)"
                              >
                                <v-icon
                                  color="
                                  grey
                                "
                                  small
                                  >mdi-pencil</v-icon
                                >
                              </v-btn>

                              <v-btn
                                v-if="hover && slideKey < keys.publishing"
                                class="mt-n2"
                                icon
                                x-small
                                @click.stop.native="
                                  if (item.status == 'Active') {
                                    item.status = 'Disabled';
                                  } else {
                                    item.status = 'Active';
                                  }
                                "
                              >
                                <v-icon
                                  title="Use this to disable (lock) this element from being completed"
                                  color="
                                  grey
                                "
                                  small
                                  >{{
                                    item.status == "Disabled"
                                      ? "mdi-eye-off"
                                      : "mdi-eye"
                                  }}</v-icon
                                >
                              </v-btn>

                              <v-btn
                                icon
                                x-small
                                v-if="hover && slideKey < keys.publishing"
                                class="mt-n2 mr-1"
                                @click.stop.native="
                                  dialog.elementDeleteConfirmation = true;
                                  dialog.elementDeleteIndex = i;
                                "
                              >
                                <v-icon color="red" small
                                  >mdi-trash-can-outline</v-icon
                                >
                              </v-btn>
                              <v-icon
                                v-if="
                                  item.displayGrabber &&
                                    hover &&
                                    slideKey < keys.publishing
                                "
                                small
                                class="mt-n2"
                                >mdi-drag-horizontal-variant</v-icon
                              >
                            </div>
                          </div>
                        </v-hover>
                      </div>
                    </draggable>
                    <draggable :list="program.resources" group="resources">
                      <div
                        v-for="(item, i) in program.resources"
                        :key="`${item.id}-${i}`"
                      >
                        <v-hover v-slot="{ hover }">
                          <div class="d-flex align-center my-2">
                            <!-- <v-btn
                            icon
                            small
                            v-if="hover"
                            class="mr-2"
                            @click.stop.native="
                              dialog.elementDeleteConfirmation = true;
                              dialog.resourceDeleteIndex = i;
                            "
                          >
                            <v-icon color="red" small
                              >mdi-trash-can-outline</v-icon
                            >
                          </v-btn> -->
                            <v-icon
                              class="side-nav-icons"
                              :class="`${item.iconColor}-background`"
                              color="white"
                              >{{ item.icon }}</v-icon
                            >
                            <div
                              class="d-flex justify-space-between align-center program-elements-box program-elements-height ml-2 px-4"
                            >
                              <span
                                class="program-element-label"
                                :title="item.displayName || 'Unknown element'"
                                >{{
                                  item.displayName
                                    ? item.displayName
                                    : "Unknown element"
                                }}</span
                              >
                              <v-btn
                                icon
                                small
                                v-if="hover && slideKey < keys.publishing"
                                class="mt-n2"
                                @click.stop.native="
                                  dialog.elementDeleteConfirmation = true;
                                  dialog.resourceDeleteIndex = i;
                                "
                              >
                                <v-icon color="red" small
                                  >mdi-trash-can-outline</v-icon
                                >
                              </v-btn>
                              <v-icon
                                v-if="hover && slideKey < keys.publishing"
                                small
                                class="mt-n2"
                                >mdi-drag-horizontal-variant</v-icon
                              >
                            </div>
                          </div>
                        </v-hover>
                      </div>
                    </draggable>
                    <!-- Div for "Add a reward" or badge -->
                    <div v-if="program.reward.badgeId || !validProgramReward">
                      <v-hover v-slot="{ hover }">
                        <div
                          class="d-flex align-center my-2 full-width"
                          @click.stop="loadRewardWidget(true, 'badge')"
                        >
                          <v-img
                            v-if="program.reward.imageUrl"
                            :src="program.reward.imageUrl"
                            class="side-nav-icons"
                          />
                          <v-icon
                            v-else
                            class="side-nav-icons"
                            :class="
                              program.reward.iconColor
                                ? `${program.reward.iconColor}-background`
                                : 'light-grey-background'
                            "
                            color="white"
                            >{{
                              program.reward.icon ||
                                "mdi-trophy-variant-outline"
                            }}</v-icon
                          >
                          <div
                            class="d-flex justify-space-between align-center program-elements-box program-elements-height ml-2 px-4"
                          >
                            <span
                              class="program-element-label"
                              :title="
                                program.reward.badgeDisplayName
                                  ? program.reward.badgeDisplayName
                                  : validProgramReward
                                  ? loading.badges
                                    ? 'Loading badges...'
                                    : 'Unknown reward'
                                  : 'Add a reward'
                              "
                              >{{
                                program.reward.badgeDisplayName
                                  ? program.reward.badgeDisplayName
                                  : validProgramReward
                                  ? loading.badges
                                    ? "Loading badges..."
                                    : "Unknown reward"
                                  : "Add a reward"
                              }}</span
                            >
                            <v-btn
                              icon
                              small
                              v-if="hover && slideKey < keys.publishing"
                              class="mt-n2"
                              @click.stop.native="
                                loadRewardWidget(true, 'badge')
                              "
                            >
                              <v-icon color="grey" small>mdi-pencil</v-icon>
                            </v-btn>
                            <!-- Delete button if budget reward (if only one reward can't delete) -->
                            <v-btn
                              icon
                              small
                              v-if="
                                hover &&
                                  slideKey < keys.publishing &&
                                  program.reward.budgetId &&
                                  program.reward.budgetId != null
                              "
                              class="mt-n2 mr-1"
                              @click.stop.native="
                                dialog.elementDeleteConfirmation = true;
                                dialog.elementDeleteContext = 'badge';
                              "
                            >
                              <v-icon color="red" small
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </div>
                      </v-hover>
                    </div>
                    <div v-if="program.reward.budgetId">
                      <v-hover v-slot="{ hover }">
                        <div
                          class="d-flex align-center my-2 full-width"
                          @click.stop="loadRewardWidget(true, 'payment')"
                        >
                          <v-icon
                            class="side-nav-icons"
                            :class="
                              program.reward.iconColor
                                ? `${program.reward.iconColor}-background`
                                : 'light-grey-background'
                            "
                            color="white"
                            >mdi-trophy-variant-outline</v-icon
                          >
                          <div
                            class="d-flex justify-space-between align-center program-elements-box program-elements-height ml-2 px-4"
                          >
                            <span
                              class="program-element-label"
                              :title="
                                program.reward.awardDisplayName
                                  ? program.reward.awardDisplayName
                                  : validProgramReward
                                  ? loading.budget
                                    ? 'Loading budgets...'
                                    : 'Unknown reward'
                                  : 'Add a reward'
                              "
                              >{{
                                program.reward.awardDisplayName
                                  ? program.reward.awardDisplayName
                                  : validProgramReward
                                  ? loading.budgets
                                    ? "Loading budgets..."
                                    : "Unknown reward"
                                  : "Add a reward"
                              }}
                              <v-progress-circular
                                v-if="
                                  preloadedProgram &&
                                    validProgramReward &&
                                    loading.budgets
                                "
                                :width="2"
                                :size="16"
                                indeterminate
                                color="primary"
                              ></v-progress-circular
                            ></span>
                            <v-btn
                              icon
                              small
                              v-if="hover && slideKey < keys.publishing"
                              class="mt-n2"
                              @click.stop.native="
                                loadRewardWidget(true, 'payment')
                              "
                            >
                              <v-icon color="grey" small>mdi-pencil</v-icon>
                            </v-btn>
                            <!-- Delete button if badge (if only one reward can't delete) -->
                            <v-btn
                              icon
                              small
                              v-if="
                                hover &&
                                  slideKey < keys.publishing &&
                                  program.reward.badgeId &&
                                  program.reward.badgeId != null
                              "
                              class="mt-n2 mr-1"
                              @click.stop.native="
                                dialog.elementDeleteConfirmation = true;
                                dialog.elementDeleteContext = 'award';
                              "
                            >
                              <v-icon color="red" small
                                >mdi-trash-can-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </div>
                      </v-hover>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-start mt-2 cursor-pointer"
                @click="goToSlide(keys.messages)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': program.messages.length > 0,
                    'light-grey-background': !(program.messages.length > 0)
                  }"
                  color="white"
                  >mdi-email</v-icon
                >
                <div
                  class="d-flex flex-column ml-2 px-3 cursor-pointer program-elements-box"
                  :class="{
                    'side-nav-bold-text': program.messages.length > 0,
                    'side-nav-box': !program.expandMessages,
                    'program-elements-height-removed-for-default-msg-button':
                      program.messages.length == 0
                  }"
                >
                  <div class="d-flex justify-space-between">
                    {{ "Program messages (" + program.messages.length + ")" }}
                    <v-icon
                      @click.stop.native="
                        program.expandMessages = !program.expandMessages
                      "
                      id="program-element-expander"
                      v-if="program.messages.length > 0"
                      >{{
                        program.expandMessages
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}</v-icon
                    >
                  </div>
                  <div v-if="program.expandMessages">
                    <div
                      v-for="(item, i) in program.messages"
                      :key="`${item.id}-${i}`"
                      class="d-flex align-center my-2 full-width"
                      @click.stop="editProgramMessage(item)"
                    >
                      <v-icon
                        class="side-nav-icons brand-orange-background"
                        color="white"
                        >mdi-email</v-icon
                      >
                      <v-hover v-slot="{ hover }">
                        <div
                          class="d-flex justify-space-between align-center program-elements-box program-elements-height ml-2 px-3"
                        >
                          <span class="program-element-label">{{
                            item.messageSubject
                              ? item.messageSubject
                              : "Incomplete message"
                          }}</span>
                          <v-btn
                            v-if="
                              hover &&
                                item.messageId !== message.messageId &&
                                slideKey < keys.publishing
                            "
                            class="mt-n2"
                            icon
                            small
                            @click.stop.native="editProgramMessage(item)"
                          >
                            <v-icon small>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="
                              hover &&
                                item.messageId &&
                                item.messageId !== message.messageId &&
                                slideKey < keys.publishing
                            "
                            class="mt-n2"
                            small
                            icon
                            @click.stop.native="
                              dialog.elementDeleteConfirmation = true;
                              dialog.messageDeleteIndex = i;
                            "
                          >
                            <v-icon small color="red"
                              >mdi-trash-can-outline</v-icon
                            >
                          </v-btn>
                        </div>
                      </v-hover>
                    </div>
                    <v-btn
                      v-if="
                        !program.messages.find(
                          x => x.messageId == defaultMessages[0].messageId
                        ) ||
                          !program.messages.find(
                            x => x.messageId == defaultMessages[1].messageId
                          )
                      "
                      @click.stop="addDefaultProgramMessages"
                      text
                      small
                      color="brand"
                      class="mx-auto full-width px-auto mb-2"
                      >+ Add default messages</v-btn
                    >
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-center mt-2 cursor-pointer"
                @click="goToSlide(keys.review)"
              >
                <v-icon
                  class="side-nav-icons"
                  :class="{
                    'brand-background': slideKey >= keys.review,
                    'light-grey-background': !(slideKey >= keys.review)
                  }"
                  color="white"
                  >mdi-rocket-launch</v-icon
                >
                <div
                  class="side-nav-box ml-2 px-3"
                  :class="{ 'side-nav-bold-text': slideKey >= keys.review }"
                >
                  Confirm and launch
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            class=" d-flex flex-column align-start mb-6"
          >
            <div
              class="mb-10 d-flex align-center justify-end full-width"
              v-if="!isMobile"
            >
              <v-tooltip
                v-if="!isMobile"
                top
                :disabled="!disableContinueButton || slideKey !== keys.elements"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      :outlined="disableContinueButton"
                      color="primary"
                      @click="goToNextSlide"
                      :disabled="disableContinueButton"
                      >{{
                        route[route.findIndex(x => x == slideKey) + 1] ==
                        keys.review
                          ? "Review"
                          : slideKey == keys.review
                          ? program.previewMode
                            ? "Preview"
                            : activeProgram || archivedProgram
                            ? "Update"
                            : "Publish"
                          : slideKey == keys.publishing || slideKey == keys.sent
                          ? "Done"
                          : "Continue"
                      }}<v-icon class="ml-1">mdi-chevron-right</v-icon></v-btn
                    >
                  </div>
                </template>
                <div v-if="slideKey == keys.elements && !validProgramReward">
                  <span>Add a program reward to continue</span>
                </div>
                <div
                  v-else-if="
                    slideKey == keys.elements && !program.elements.length
                  "
                >
                  <span>Add program elements to continue</span>
                </div>
              </v-tooltip>
            </div>
            <transition :name="slideDirection" mode="out-in">
              <div v-if="slideKey === keys.basics" key="0" class="full-width">
                <v-card
                  class="d-flex flex-column align-start full-width pa-5"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <div></div>
                  <v-autocomplete
                    outlined
                    class="mt-2 full-width"
                    :items="clients"
                    item-text="formattedName"
                    item-value="clientId"
                    label="Client"
                    v-model="clientId"
                    v-if="userProfile.clientId === 1"
                    :disabled="editingProgram"
                    @change="updateClientId"
                  >
                  </v-autocomplete>
                  <v-text-field
                    ref="titleInput"
                    v-model="program.name"
                    class="mt-2 full-width"
                    outlined
                    label="Program Title"
                    :required="true"
                    :rules="[
                      v => !!v || 'A program name is required',
                      v =>
                        !!(v && 100 > v.length) ||
                        'We recommend making your program name shorter'
                    ]"
                  ></v-text-field>
                  <!-- <vue-editor
                    class="editor full-width"
                    v-model="program.description"
                    :editorToolbar="editorToolbar"
                    placeholder="Brief program description"
                  ></vue-editor> -->
                  <!-- 
                     !!(
                          v &&
                          formattedProgramDescription &&
                          formattedProgramDescription.length < 480
                        ) || 'Your descripti
                   -->
                  <v-textarea
                    label="Brief program description"
                    class="full-width"
                    outlined
                    v-model="program.description"
                    :rules="[v => !!v || 'A description is required']"
                  ></v-textarea>

                  <v-menu max-width="260" offset-x offset-y absolute>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="full-width image-container">
                        <v-img
                          :lazy-src="program.imageURL"
                          :src="program.imageURL"
                          :key="program.imageURL"
                          :aspect-ratio="16 / 9"
                          v-bind="attrs"
                          v-on="on"
                          width="100%"
                          max-width="100%"
                          class="cursor-pointer"
                          @drop.prevent="dragAndDropImageFileChanged"
                          @dragover.prevent
                        >
                          <template v-slot:placeholder>
                            <div
                              class="light-grey-background full-height full-width"
                            >
                              <h3 class=" pt-5 primary--text">
                                Add a banner photo
                              </h3>

                              <p class="mt-10">
                                We suggest an image size of 740 x 416 pixels (Or
                                any image with a 16 : 9 aspect ratio).
                              </p>
                              <p>
                                JPG, PNG files supported
                              </p>
                            </div>
                          </template>
                        </v-img>
                      </div>
                    </template>
                    <v-list>
                      <input
                        id="uploader"
                        ref="uploader"
                        class="d-none"
                        type="file"
                        accept="image/*"
                        @change="imageFileChanged"
                        @blur="imageFileChanged"
                      />
                      <v-list-item @click="$refs.uploader.click()">
                        <v-list-item-title class="primary--text text-left"
                          ><v-icon color="black" class="mr-2">mdi-upload</v-icon
                          >Upload from computer</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="removeImage">
                        <v-list-item-title class="primary--text text-left"
                          ><v-icon color="black" class="mr-2">mdi-delete</v-icon
                          >Remove image</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card>
              </div>
              <div v-if="slideKey === keys.dates" key="1" class="full-width">
                <v-card
                  class="d-flex flex-column align-start full-width pa-5"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                  min-height="550"
                >
                  <div></div>
                  <!-- <p class="mt-2" v-if="activeProgram">
                    This program is currently active. Contact Whistle at
                    help@wewhistle.com to change your program's schedule.
                  </p> -->
                  <p class="mb-0 mediumGrey--text">Start date</p>
                  <input
                    class="timestamp-input mt-1 full-width"
                    type="datetime-local"
                    v-model="program.startDate"
                  />
                  <v-radio-group
                    v-model="program.scheduleSelection"
                    class="mt-2 mb-2"
                    hide-details
                  >
                    <v-radio
                      value="no_end"
                      class="text-left mb-4"
                      label="No end date"
                    />
                    <v-radio value="end" class="text-left" label="End date" />
                  </v-radio-group>
                  <!-- <p class="mb-0 mt-2 mediumGrey--text">End date</p> -->
                  <input
                    :disabled="
                      program.scheduleSelection == 'rolling' ||
                        program.scheduleSelection == 'no_end'
                    "
                    class="timestamp-input mt-1 full-width mb-1"
                    :style="
                      program.scheduleSelection == 'rolling' ||
                      program.scheduleSelection == 'no_end'
                        ? 'color: lightGrey;'
                        : ''
                    "
                    type="datetime-local"
                    v-model="program.endDate"
                  />
                  <p
                    v-if="endDateRules !== true"
                    class="red--text text-caption"
                  >
                    {{ endDateRules }}
                  </p>
                  <v-radio-group
                    v-model="program.scheduleSelection"
                    hide-details
                    class="mt-3"
                  >
                    <v-radio
                      :disabled="program.procoreType"
                      value="rolling"
                      class="text-left"
                      label="Rolling program"
                      ><template v-slot:label>
                        <div class="d-flex align-center">
                          <span>Rolling program</span>
                          <v-tooltip top
                            ><template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" class="ml-3"
                                >mdi-help-circle</v-icon
                              > </template
                            ><span>
                              A rolling problem allows users to be added to the
                              program at any time, <br />and gives users X days
                              to complete from the date they are added.</span
                            ></v-tooltip
                          >
                        </div>
                      </template></v-radio
                    >
                  </v-radio-group>
                  <div class="d-flex align-start full-width mt-6">
                    <v-text-field
                      v-model="program.rollingProgramLength"
                      type="number"
                      style="width: 95px; max-width: 95px;"
                      class="rolling-program-length"
                      outlined
                      :min="0"
                      :step="1"
                      dense
                      :disabled="program.scheduleSelection != 'rolling'"
                      :rules="rollingProgramRules"
                    /><span
                      class="ml-3 mt-2"
                      :class="{
                        'grey--text': program.scheduleSelection != 'rolling'
                      }"
                      >Days to complete program</span
                    >
                  </div>
                </v-card>
              </div>
              <div v-if="slideKey === keys.audience" key="2" class="full-width">
                <v-card
                  class="d-flex flex-column align-start full-width pa-5"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text font-weight-bold"
                    >Select recipients</span
                  >
                  <div class="d-flex align-center full-width mt-5">
                    <v-text-field
                      label="Search"
                      v-model="groupTable.debounceSearch"
                      solo
                      flat
                      dense
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      class="search-field mr-3"
                    >
                    </v-text-field>
                    <v-btn
                      v-if="permissions.includes('groups:create:group')"
                      :width="116"
                      :depressed="true"
                      :outlined="true"
                      color="primary"
                      :height="38"
                      @click="dialog.groupCreator = true"
                      ><v-icon>mdi-plus</v-icon><span> Group</span></v-btn
                    >
                  </div>

                  <div class="d-flex align-center flex-wrap mt-4">
                    <div
                      v-for="(group, index) in program.audience"
                      :key="group.groupId"
                    >
                      <v-chip
                        class="ma-1"
                        close
                        @click:close="removeUserFromSelected(index)"
                        >{{
                          group.displayName ||
                            group.groupDisplayName ||
                            "Unknown group"
                        }}</v-chip
                      >
                    </div>
                  </div>
                  <div class="text-left mt-2 full-width">
                    <p class="darkGrey--text ml-4 mb-1">Directory</p>
                    <p class="darkGrey--text ml-4 text-sm">
                      Note: Programs don't support external users at this time
                    </p>
                    <v-divider class="mt-5" />
                    <GroupTable
                      ref="group-table"
                      :selected="program.audience"
                      :search="search.audience"
                      :includeEveryone="true"
                      :totalUserCount="data.userCount"
                      @select-item="addGroupToSelected($event, false)"
                    />
                  </div>
                </v-card>
              </div>
              <div v-if="slideKey === keys.elements" key="3" class="full-width">
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                  min-height="550"
                >
                  <span class="header-text font-weight-bold">{{
                    "View and edit program elements and resources"
                  }}</span>
                  <div class="mt-9 full-width">
                    <v-hover v-model="hover.module">
                      <div
                        class="d-flex align-center cursor-pointer"
                        @click="loadModuleWidget('Learning')"
                      >
                        <v-icon
                          class="side-nav-icons brand-background"
                          color="white"
                          >mdi-school</v-icon
                        >
                        <div
                          class="side-nav-box ml-2 px-3 primary--text font-weight-bold"
                          :class="{ 'brand-gradient-background': hover.module }"
                        >
                          Learning modules
                        </div>
                      </div>
                    </v-hover>
                    <v-hover v-model="hover.survey">
                      <div
                        class="d-flex align-center mt-3 cursor-pointer"
                        @click="loadModuleWidget('Survey')"
                      >
                        <v-icon
                          class="side-nav-icons brand-background"
                          color="white"
                          >mdi-comment-question-outline</v-icon
                        >
                        <div
                          class="side-nav-box ml-2 px-3 primary--text font-weight-bold"
                          :class="{ 'brand-gradient-background': hover.survey }"
                        >
                          Surveys
                        </div>
                      </div>
                    </v-hover>
                    <v-hover v-model="hover.metric">
                      <div
                        class="d-flex align-center mt-3 cursor-pointer"
                        @click="loadMetricWidget(null, null)"
                      >
                        <v-icon
                          class="side-nav-icons brand-cyan-background"
                          color="white"
                          >mdi-checkbox-marked-outline</v-icon
                        >
                        <div
                          class="side-nav-box ml-2 px-3 primary--text font-weight-bold"
                          :class="{ 'brand-gradient-background': hover.metric }"
                        >
                          Progress metric
                        </div>
                      </div>
                    </v-hover>
                    <v-hover v-model="hover.reward">
                      <div
                        class="d-flex align-center mt-3 cursor-pointer"
                        @click="loadRewardWidget(false)"
                      >
                        <v-icon
                          class="side-nav-icons brand-dark-green-background"
                          color="white"
                          >mdi-trophy-variant-outline</v-icon
                        >
                        <div
                          class="side-nav-box ml-2 px-3 primary--text font-weight-bold"
                          :class="{ 'brand-gradient-background': hover.reward }"
                        >
                          Reward badge or payment
                        </div>
                      </div>
                    </v-hover>
                    <v-hover v-model="hover.resource">
                      <div
                        class="d-flex align-center mt-3 cursor-pointer"
                        @click="loadResourceWidget"
                      >
                        <v-icon
                          class="side-nav-icons brand-background"
                          color="white"
                          >mdi-file-document-outline</v-icon
                        >
                        <div
                          class="side-nav-box ml-2 px-3 primary--text font-weight-bold"
                          :class="{
                            'brand-gradient-background': hover.resource
                          }"
                        >
                          Resource
                        </div>
                      </div>
                    </v-hover>
                    <p
                      class="red--text mt-2"
                      v-if="program.elements.length > 0 && !validProgramReward"
                    >
                      Your program needs a reward before you can continue!
                    </p>
                  </div>
                </v-card>
              </div>
              <div v-if="slideKey === keys.messages" key="4" class="full-width">
                <v-card
                  class="d-flex flex-column justify-start align-center full-width pa-5"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                  min-height="550"
                >
                  <span
                    class="header-text font-weight-bold text-left full-width d-flex"
                    >Add a program message
                    <v-btn
                      icon
                      class="ml-1"
                      @click="dialog.messageScheduleDescription = true"
                    >
                      <v-icon color="gray">mdi-help-circle</v-icon>
                    </v-btn></span
                  >
                  <v-form ref="messageForm" v-model="message.valid">
                    <div
                      class="d-flex flex-column align-center full-width mt-5"
                    >
                      <!-- <span
                        class="text-caption text-left full-width"
                        @click="dialog.messageScheduleDescription = true"
                        >How does scheduling work?</span
                      > -->
                      <div class="full-width d-flex flex-wrap">
                        <span class="mt-2">Send</span>
                        <v-text-field
                          outlined
                          class="mx-2"
                          style="min-width: 100px; width: 20%; max-width: 120px;"
                          v-model="message.scheduleValue"
                          placeholder="1 day"
                          required
                          dense
                          type="number"
                          min="0"
                          step="1"
                          :rules="[
                            v => !!(v || v == 0) || 'A value is required',
                            v =>
                              !!((v || v == 0) && parseInt(v) >= 0) ||
                              'The value must be 0 or greater',
                            v =>
                              !!(
                                (v || v == 0) &&
                                (this.programLength === null ||
                                  v <= this.programLength)
                              ) ||
                              'The value should be less than the length of your program (' +
                                this.programLength +
                                ' days)',
                            v =>
                              !!(
                                (v || v == 0) &&
                                !v.toString().includes('.')
                              ) || 'The value must be a whole number'
                          ]"
                        ></v-text-field
                        ><span class="mt-2 mr-2">days</span>
                        <v-select
                          :items="messageScheduleOptions"
                          v-model="message.scheduleOption"
                          item-value="value"
                          item-text="label"
                          placeholder="Scheduling option"
                          class=""
                          style="min-width: 140px; width: 50%; max-width: 230px;"
                          outlined
                          required
                          dense
                          :rules="[
                            v => !!v || 'A scheduling option is required'
                          ]"
                        />
                        <!-- <v-btn
                          icon
                          class="ml-1"
                          @click="dialog.messageScheduleDescription = true"
                        >
                          <v-icon color="gray">mdi-help-circle</v-icon>
                        </v-btn> -->
                      </div>
                      <!-- <v-text-field
                        outlined
                        class="full-width"
                        v-model="message.numDaysToProgramEnd"
                        placeholder="1 day"
                        required
                        dense
                        type="number"
                        min="0"
                        step="1"
                        :rules="[
                          v => !!v || 'A value is required',
                          v =>
                            !!(v && parseInt(v) >= 0) ||
                            'The value must be 0 or greater',
                          v =>
                            !!(v && v <= this.programLength) ||
                            'The value should be less than the length of your program (' +
                              this.programLength +
                              ' days)',
                          v =>
                            !!(v && !v.toString().includes('.')) ||
                            'The value must be a whole number'
                        ]"
                      ></v-text-field> -->

                      <v-text-field
                        outlined
                        dense
                        class="full-width"
                        ref="messageTitleInput"
                        v-model="message.messageSubject"
                        label="Subject"
                        :rules="[
                          v => !!v || 'Subject is required',
                          v =>
                            !!(v && v.length < 100) ||
                            'Your subject should be shorter'
                        ]"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        dense
                        class="full-width"
                        ref="messageSubtitleInput"
                        v-model="message.messageShortBody"
                        label="Subtitle"
                        :rules="[
                          v => !!(v || !v) || '',
                          v =>
                            !!(!v || (v && v.length < 100)) ||
                            'Your subtitle should be shorter'
                        ]"
                      ></v-text-field>
                      <TextEditor
                        :shortcodes="!program.procoreType"
                        :programRelated="true"
                        :programHasEndDate="
                          program.scheduleSelection !== 'no_end'
                        "
                        :largeVersion="false"
                        :editorContent="message.messageBody"
                        @update-message="message.messageBody = $event"
                      />
                      <v-btn
                        color="primary"
                        width="220"
                        depressed
                        :outlined="true"
                        class="mt-5"
                        :disabled="
                          !message.messageSubject || !message.messageBody
                        "
                        @click="previewProgramMessage"
                        >Preview message</v-btn
                      >
                      <v-btn
                        color="primary"
                        width="220"
                        depressed
                        :disabled="!message.valid || !message.messageBody"
                        :outlined="!message.valid || !message.messageBody"
                        class="mt-5"
                        @click="addProgramMessage(false)"
                        >{{
                          message.messageId ? "Update message" : "Add message"
                        }}</v-btn
                      >
                    </div>
                  </v-form>
                </v-card>
              </div>
              <div
                key="100"
                v-else-if="slideKey === keys.review"
                class="full-width"
              >
                <!-- <div class="text-left mb-5"></div> -->

                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text font-weight-bold mb-1"
                    >Review your program</span
                  >
                  <div
                    class="mb-3 d-flex align-center"
                    v-if="!activeProgram && !archivedProgram"
                  >
                    <v-switch
                      v-model="program.previewMode"
                      label="Preview mode"
                      hide-details
                      dense
                    ></v-switch>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-2 pt-4"
                          color="gray"
                          v-on="on"
                          v-bind="attrs"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span>
                        Preview mode allows you to view your new program as if
                        you were part of the audience for the program once it
                        starts. <br />Only you can see the program while in
                        preview mode.
                      </span>
                      <!-- <span>
                      Creating a rolling program gives all users X days from
                      their individual start dates to complete the award rules.
                    </span> -->
                    </v-tooltip>
                  </div>

                  <div class="mb-3 d-flex align-center" v-if="clientId != 0">
                    <v-switch
                      :disabled="clientId == 0"
                      v-model="program.displayLeaderboard"
                      label="Display leaderboard on program page"
                      hide-details
                      dense
                    />
                  </div>
                  <div class="mb-3 d-flex align-center" v-if="clientId != 0">
                    <v-switch
                      :disabled="clientId == 0"
                      v-model="program.forceLinearProgress"
                      label="Force linear progression"
                      hide-details
                      dense
                    />
                  </div>
                  <div
                    class="mb-3 d-flex align-center"
                    v-if="userProfile && userProfile.clientId == 1"
                  >
                    <v-switch
                      :disabled="clientId == 0"
                      v-model="program.procoreType"
                      label="Procore program* (Background program only)"
                      hide-details
                      dense
                    />
                  </div>
                  <div
                    class="mb-3 d-flex flex-column justify-center"
                    v-if="
                      userProfile &&
                        userProfile.clientId == 1 &&
                        program.procoreType
                    "
                  >
                    <v-autocomplete
                      label="Timezone"
                      :items="timezones"
                      v-model="program.timezone"
                    ></v-autocomplete>
                    <v-select
                      label="Rule Aggregation Frequency"
                      :items="ruleAggregation"
                      v-model="program.ruleAggregation"
                    ></v-select>
                  </div>
                  <p
                    class="red--text"
                    v-if="
                      userProfile &&
                        userProfile.clientId == 1 &&
                        program.procoreType &&
                        program.scheduleSelection == 'rolling' &&
                        program.rollingProgramLength
                    "
                  >
                    Error: Procore programs cannot be rolling.
                  </p>
                  <p
                    class="red--text"
                    v-if="
                      userProfile &&
                        userProfile.clientId == 1 &&
                        program.procoreType &&
                        program.messages.find(
                          x => x.messageBody && x.messageBody.includes('}}}')
                        )
                    "
                  >
                    Error: Procore program messages don't support shortcodes.
                  </p>

                  <p class="text-h6 mb-0 mt-6">Schedule</p>
                  <div class="d-flex justify-space-between full-width">
                    <p>
                      Start date
                    </p>
                    <p>
                      {{ formattedStartDate }}
                    </p>
                  </div>
                  <div
                    v-if="program.endDate && program.scheduleSelection == 'end'"
                    class="d-flex justify-space-between  full-width"
                  >
                    <p>
                      End date
                    </p>
                    <p>
                      {{ formattedEndDate }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-space-between  full-width"
                    v-if="programLength !== null"
                  >
                    <p>
                      Number of days in program
                    </p>
                    <p class="font-weight-bold">
                      {{
                        programLength + (programLength == 1 ? " day" : " days")
                      }}
                    </p>
                  </div>
                  <p class="text-h6 mb-0 mt-6">Elements</p>
                  <div
                    class="d-flex justify-space-between  full-width"
                    v-if="
                      program.elements.filter(
                        x =>
                          x.context == 'LearningModules' && x.type == 'Learning'
                      ).length > 0
                    "
                  >
                    <p>
                      Number of learning modules
                    </p>
                    <p>
                      {{
                        program.elements.filter(
                          x =>
                            x.context == "LearningModules" &&
                            x.type == "Learning"
                        ).length
                      }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-space-between  full-width"
                    v-if="
                      program.elements.filter(
                        x =>
                          x.context == 'LearningModules' && x.type == 'Survey'
                      ).length > 0
                    "
                  >
                    <p>
                      Number of surveys
                    </p>
                    <p>
                      {{
                        program.elements.filter(
                          x =>
                            x.context == "LearningModules" && x.type == "Survey"
                        ).length
                      }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-space-between  full-width"
                    v-if="
                      program.elements.filter(
                        x => x.context == 'MetricDefinitions'
                      ).length > 0
                    "
                  >
                    <p>
                      Number of progress metrics
                    </p>
                    <p>
                      {{
                        program.elements.filter(
                          x => x.context == "MetricDefinitions"
                        ).length
                      }}
                    </p>
                  </div>
                  <p class="text-h6 mb-0 mt-6">Reward</p>
                  <div
                    class="d-flex justify-space-between full-width"
                    v-if="program.reward && program.reward.badgeId"
                  >
                    <p>
                      Reward badge
                    </p>
                    <p>
                      {{ program.reward.badgeDisplayName || "Unnamed badge" }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-space-between  full-width"
                    v-if="program.reward && program.reward.budgetId"
                  >
                    <p>
                      Reward per person
                      <span v-if="peopleInSelectedGroups > 0"
                        >({{
                          peopleInSelectedGroups +
                            (peopleInSelectedGroups == 1
                              ? " person"
                              : " people")
                        }})</span
                      >
                    </p>
                    <p>
                      <span v-if="selectedBudget && selectedBudget.awardId == 1"
                        >$</span
                      >{{
                        formatCurrency(
                          parseFloat(program.reward.amount).toFixed(2)
                        )
                      }}
                      <span
                        v-if="
                          selectedBudget &&
                            selectedBudget.awardId != 1 &&
                            selectedBudget.Award
                        "
                        >{{
                          parseFloat(program.reward.amount) == 1 &&
                          selectedBudget.Award.unitSingular
                            ? selectedBudget.Award.unitSingular
                            : parseFloat(program.reward.amount) != 1 &&
                              selectedBudget.Award.unitPlural
                            ? selectedBudget.Award.unitPlural
                            : ""
                        }}</span
                      >
                    </p>
                  </div>

                  <div
                    class="d-flex justify-space-between full-width mb-1"
                    v-if="program.reward && program.reward.budgetId"
                  >
                    <span>Budget</span>
                    <span>
                      {{
                        selectedBudget
                          ? selectedBudget.displayName
                          : "Unknown budget"
                      }}
                    </span>
                  </div>
                  <div
                    class="full-width"
                    v-if="
                      program.reward &&
                        program.reward.context !== 'Badges' &&
                        peopleInSelectedGroups > 0
                    "
                  >
                    <v-divider></v-divider>
                  </div>

                  <div
                    class="d-flex justify-space-between full-width mt-2"
                    v-if="
                      program.reward &&
                        program.reward.context !== 'Badges' &&
                        peopleInSelectedGroups > 0
                    "
                  >
                    <p>Estimated reward total</p>
                    <p class="font-weight-bold">
                      <span v-if="selectedBudget && selectedBudget.awardId == 1"
                        >$</span
                      >
                      {{ totalBeingSpent }}
                      <span
                        v-if="
                          selectedBudget &&
                            selectedBudget.awardId != 1 &&
                            selectedBudget.Award
                        "
                        >{{
                          totalBeingSpent == 1 &&
                          selectedBudget.Award.unitSingular
                            ? selectedBudget.Award.unitSingular
                            : totalBeingSpent != 1 &&
                              selectedBudget.Award.unitPlural
                            ? selectedBudget.Award.unitPlural
                            : ""
                        }}</span
                      >
                    </p>
                  </div>
                  <!-- <div
                    v-if="
                      program.reward &&
                        program.reward.memo &&
                        program.reward.memo.trim() != ''
                    "
                  >
                    <p class="mb-2">
                      Payment memo:
                    </p>
                    <p
                      class="pt-0"
                      v-if="program.reward && program.reward.memo"
                    >
                      {{ program.reward.memo }}
                    </p>
                  </div> -->

                  <p
                    class="red--text mt-4"
                    v-if="
                      selectedBudget &&
                        selectedBudget.budgetBalance < totalBeingSpent
                    "
                  >
                    The total amount of your program is more than your selected
                    budget. Be sure to fund your budget before your program
                    starts! (Budget balance
                    <span v-if="selectedBudget && selectedBudget.awardId == 1"
                      >$</span
                    >{{
                      selectedBudget
                        ? formatCurrency(
                            parseFloat(selectedBudget.budgetBalance).toFixed(2)
                          )
                        : 0
                    }})
                  </p>
                </v-card>
              </div>
              <div
                key="101"
                v-else-if="slideKey === keys.publishing"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <div class="text-left d-flex flex-column align-center">
                    <div class="full-width">
                      <span
                        class="header-text word-break font-weight-bold mb-5"
                        >{{
                          program.previewMode
                            ? "Setting up your preview program"
                            : editingProgram
                            ? "Updating your program"
                            : duplicatingProgram
                            ? "Duplicating your program"
                            : "Creating your program"
                        }}</span
                      >

                      <p class="grey--text mt-2 full-width text-left">
                        Depending on the number of elements this might take a
                        little bit.
                      </p>
                    </div>
                    <Robin
                      :showText="false"
                      :width="160"
                      :height="160"
                      animation="inflatableDance"
                      :loop="true"
                      class="mt-2"
                    />
                  </div>
                </v-card>
              </div>

              <div
                v-else-if="slideKey == keys.sent"
                key="102"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text word-break font-weight-bold mb-5">{{
                    program.previewMode
                      ? "All done! Your program is in preview mode"
                      : editingProgram
                      ? "All done! Your program has been updated"
                      : duplicatingProgram
                      ? "All done! Your program has been duplicated"
                      : "All done! Your program has been published"
                  }}</span>
                  <p
                    class="grey--text mt-2 full-width text-left"
                    v-if="program.previewMode"
                  >
                    You are free to edit your program while it's in preview
                    mode. Once you're ready to publish, come back to this page
                    and toggle preview mode off.
                  </p>

                  <p
                    class="grey--text mt-2 full-width text-left"
                    v-else-if="!activeProgram && !archivedProgram"
                  >
                    Once your program begins, you can edit anything but it may
                    affect users already in the program. If you have any
                    question about program changes please contact your Whistle
                    CSM.
                  </p>

                  <div class="mx-auto">
                    <Robin
                      :showText="false"
                      :width="160"
                      :height="160"
                      animation="backflip"
                      :loop="false"
                      class="mb-4"
                    />
                  </div>
                  <!-- 
              <v-btn
                depressed
                color="primary"
                class="my-5 mx-auto"
                @click="reset"
                >Done<v-icon class="ml-1">mdi-chevron-right</v-icon></v-btn
              > -->
                </v-card>
              </div>
              <div
                v-else-if="slideKey == keys.error"
                key="103"
                class="full-width"
              >
                <v-card
                  class="d-flex flex-column align-start full-width pa-5 text-left"
                  :class="isMobile ? '' : 'main-content-card'"
                  rounded="0"
                  elevation="3"
                  width="100%"
                >
                  <span class="header-text word-break font-weight-bold mb-5"
                    >Looks like we had some trouble
                    {{
                      duplicatingProgram
                        ? "duplicating"
                        : program.previewMode
                        ? "setting up"
                        : activeProgram || editingProgram || archivedProgram
                        ? "updating"
                        : "publishing"
                    }}
                    your program. Please try again and see if that solves the
                    problem.</span
                  >
                  <p class="full-width">
                    If this continues, feel free to reach out to customer
                    service via one of the following methods...
                  </p>
                  <p class="pl-4 full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>

                  <v-btn
                    depressed
                    color="primary"
                    class="my-5 mx-auto"
                    @click="slideKey = keys.review"
                    ><v-icon class="mr-1">mdi-chevron-left</v-icon>Go
                    Back</v-btn
                  >
                </v-card>
              </div>
            </transition>
          </v-col>
        </v-row>

        <!-- Group creator widget -->
        <v-navigation-drawer
          v-model="dialog.groupCreator"
          temporary
          fixed
          right
          width="500"
        >
          <GroupCreatorWidget
            v-if="dialog.groupCreator"
            @get-groups="
              e => {
                getGroups(e, true);
                // getGroupsV2(true);
                // preloadGroup(e);
              }
            "
            @close="dialog.groupCreator = false"
            @push-to-groups="pushToGroups"
            :clientId="clientId"
            :version="3"
            source="PROGRAM_WIZARD"
          />
        </v-navigation-drawer>
        <!-- Learning module selection widget -->
        <v-navigation-drawer
          v-model="dialog.moduleWidget"
          temporary
          fixed
          right
          width="500"
        >
          <ProgramLearningModuleWidget
            v-if="dialog.moduleWidget"
            @close="dialog.moduleWidget = false"
            @add-to-program="addProgramElement"
            @get-modules="getLearningModules"
            @get-surveys="getSurveyModules"
            :clientId="clientId"
            :modules="data.modules"
            :surveys="data.surveys"
            :type="dialog.moduleWidgetType"
            :loadingModules="loading.modules"
            :loadingSurveys="loading.surveys"
            :activeProgram="activeProgram"
          />
        </v-navigation-drawer>
        <!-- Metric selection widget -->
        <v-navigation-drawer
          v-model="dialog.metricWidget"
          temporary
          fixed
          right
          width="500"
        >
          <ProgramMetricWidget
            v-if="dialog.metricWidget"
            @close="
              (dialog.metricWidget = false),
                (dialog.editMetric = null),
                (dialog.editMetricIndex = null)
            "
            @add-to-program="addProgramElement"
            @update-element="updateProgramElement"
            @update-metric="updateMetric"
            @update-metrics-array="data.metrics = $event"
            :clientId="clientId"
            :metrics="data.metrics"
            :preloadMetric="dialog.editMetric"
            :preloadMetricIndex="dialog.editMetricIndex"
            :loadingMetrics="loading.metrics"
            :activeProgram="activeProgram"
            :metricsInProgram="metricsInProgram"
          />
        </v-navigation-drawer>
        <!-- Resource selection widget -->
        <v-navigation-drawer
          v-model="dialog.resourceWidget"
          temporary
          fixed
          right
          width="500"
        >
          <ProgramResourceWidget
            v-if="dialog.resourceWidget"
            @close="dialog.resourceWidget = false"
            @add-to-program="addProgramElement"
            @get-content="getContent"
            :clientId="clientId"
            :resources="data.resources"
            :loadingResources="loading.resources"
            :activeProgram="activeProgram"
          />
        </v-navigation-drawer>
        <!-- Reward selection widget -->
        <v-navigation-drawer
          v-model="dialog.rewardWidget"
          temporary
          fixed
          right
          width="500"
        >
          <ProgramRewardWidget
            v-if="dialog.rewardWidget"
            @close="dialog.rewardWidget = false"
            @add-to-program="addProgramElement"
            @get-badges="getBadges"
            @get-budgets="getBudgets"
            :clientId="clientId"
            :badges="data.badges"
            :budgets="data.budgets"
            :numParticipants="peopleInSelectedGroups"
            :existingReward="program.reward"
            :editingBadge="dialog.editRewardBadge"
            :editingPayment="dialog.editRewardPayment"
            :loadingBudgets="loading.budgets"
            :loadingBadges="loading.badges"
            :activeProgram="activeProgram"
          />
        </v-navigation-drawer>
        <v-navigation-drawer
          v-model="dialog.messageScheduleDescription"
          temporary
          fixed
          right
          width="500"
        >
          <v-card
            v-if="dialog.messageScheduleDescription"
            elevation="0"
            rounded="0"
            class="text-left"
          >
            <v-toolbar dark flat color="brandCyan" rounded="0" width="100%">
              <v-toolbar-title>Message Scheduling</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  dark
                  text
                  @click="dialog.messageScheduleDescription = false"
                >
                  <span class="mt-1">Close</span>
                  <v-icon class="ml-2">mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-row
              no-gutters
              justify="start"
              full-width
              no-wrap
              class="mt-5 px-3"
            >
              <p>
                Program messages scheduled here are time-based and set around
                the program's start or end date. You can schedule messages to go
                out X days after the program starts, or before the program ends.
              </p>
              <p class="font-weight-bold">
                Examples:
                <!-- To send a
                welcome email on the first day of the program, you would enter
                '0' and select 'days after program start' -->
              </p>
              <p>
                To send a welcome email on the first day of the program, you
                would enter '0' and select 'days after program start'.
              </p>
              <p>
                To send a reminder email halfway through the program, you would
                take the total number of days in the program (visible in the
                left side bar) and divide that by 2. Enter that value and select
                'days after program start'.
              </p>
              <p>
                To send a reminder the day before the program ends, you would
                enter '1' and select 'days before program end'.
              </p>
              <p class="font-weight-bold">
                Notes:
              </p>
              <p>
                The time of day messages are sent is dependent upon the time of
                day the program starts or ends for messages based on program
                start and program end, respectively. For example, if the program
                starts at 1pm, your message for 2 days after program start will
                be sent at 1pm, 48 hours after the start.
              </p>
              <p>
                For rolling programs, the timing is based on the start or end
                date for each user in the program, which means users may receive
                messages at different times!
              </p>
            </v-row>
            <!-- <v-row
              no-gutters
              justify="start"
              full-width
              no-wrap
              class="mt-2 px-3"
            >
              <p>
                <span class="font-weight-bold">Assessment: </span>A more formal
                test of a participant's knowledge with correct & incorrect
                answers. They can only be taken once.
              </p>
            </v-row>
            <v-row
              no-gutters
              justify="start"
              full-width
              no-wrap
              class="mt-2 px-3"
            >
              <p>
                <span class="font-weight-bold">Survey: </span>A way to collect
                feedback and information. There are no correct answers and can
                be customized to allow users to retake the survey or not.
              </p>
            </v-row> -->
          </v-card>
        </v-navigation-drawer>
        <!-- Message preview dialog -->
        <v-dialog v-model="dialog.messagePreview" width="800">
          <v-card rounded="0" class="py-4">
            <div
              :class="{
                'ql-editor':
                  message.preview &&
                  !message.preview.includes('If you need additional help')
              }"
              v-html="message.preview"
            />
          </v-card>
        </v-dialog>
        <!-- Message edit warning dialog -->
        <v-dialog
          v-model="dialog.messageEditWarning"
          width="500"
          class="dialog"
        >
          <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
            <div class="d-flex justify-space-between align-center mx-2 mb-5">
              <v-icon color="error" x-large class="exit-warning-icon mr-4"
                >mdi-alert</v-icon
              >
              <v-card-title class="word-break text-left exit-warning-text">
                You are currently editing a message. Do you want to save your
                changes?
              </v-card-title>
            </div>

            <p>{{ errorText }}</p>

            <v-card-actions
              class="mx-12 d-flex justify-center"
              :class="isMobile ? 'flex-column' : ''"
            >
              <v-btn
                color="primary"
                outlined
                width="130"
                @click="
                  if (message.pageToRoute !== null) {
                    goToSlide(message.pageToRoute, true);
                    dialog.messageEditWarning = false;
                    clearProgramMessage();
                  } else {
                    editProgramMessage(message.messageToLoad, true);
                    dialog.messageEditWarning = false;
                  }
                "
                >Don't save</v-btn
              >
              <v-btn
                :class="isMobile ? 'mt-4 ml-0' : 'ml-8'"
                color="primary"
                width="130"
                @click="
                  addProgramMessage(true), (dialog.messageEditWarning = false)
                "
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Incomplete message warning dialog -->
        <v-dialog v-model="dialog.incompleteMessage" width="500" class="dialog">
          <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
            <div class="d-flex justify-space-between align-center mx-2 mb-5">
              <v-icon color="error" x-large class="exit-warning-icon mr-4"
                >mdi-alert</v-icon
              >
              <v-card-title class="word-break text-left exit-warning-text">
                You have incomplete or invalid messages that should be completed
                before saving.
              </v-card-title>
            </div>

            <p>{{ errorText }}</p>

            <v-card-actions class="mx-12 d-flex justify-center">
              <v-btn
                color="primary"
                width="130"
                @click="
                  editProgramMessage(
                    program.messages.find(
                      x =>
                        (!x.scheduleValue && x.scheduleValue != 0) ||
                        !x.messageSubject ||
                        !x.messageBody ||
                        !x.scheduleOption ||
                        (program.scheduleSelection == 'no_end' &&
                          x.scheduleOption == 'BEFORE_END') ||
                        x.messageSubject.length >= 100 ||
                        parseInt(x.scheduleValue) != x.scheduleValue ||
                        parseInt(x.scheduleValue) < 0 ||
                        (programLength !== null &&
                          parseInt(x.scheduleValue) > programLength) ||
                        (x.messageShortBody && x.messageShortBody.length >= 100)
                    )
                  )
                "
                >Ok</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Element deletion dialog -->
        <v-dialog
          v-model="dialog.elementDeleteConfirmation"
          width="500"
          class="dialog"
        >
          <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
            <div class="d-flex justify-space-between align-center mx-2 mb-5">
              <v-icon color="error" x-large class="exit-warning-icon mr-4"
                >mdi-alert</v-icon
              >
              <v-card-title
                v-if="
                  dialog.messageDeleteIndex !== null &&
                    dialog.elementDeleteContext == null
                "
                class="word-break text-left exit-warning-text"
              >
                Are you sure you want to delete this program message?
              </v-card-title>
              <v-card-title
                v-else-if="dialog.elementDeleteContext == null"
                class="word-break text-left exit-warning-text"
              >
                Are you sure you want to delete this program element?
              </v-card-title>
              <v-card-title
                v-else
                class="word-break text-left exit-warning-text"
              >
                Are you sure you want to delete this program reward?
              </v-card-title>
            </div>

            <v-card-actions class="mx-12 d-flex justify-center">
              <v-btn
                class="mr-4"
                color="primary"
                outlined
                width="130"
                @click="
                  (dialog.elementDeleteConfirmation = false),
                    (dialog.elementDeleteIndex = null),
                    (dialog.resourceDeleteIndex = null),
                    (dialog.messageDeleteIndex = null),
                    (dialog.elementDeleteContext = null)
                "
                >Cancel</v-btn
              >
              <v-btn
                class="ml-4"
                color="primary"
                width="130"
                @click="deleteProgramElement"
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Complex program dialog -->
        <v-dialog v-model="dialog.complexProgram" persistent width="500">
          <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
            <div class="d-flex justify-space-between align-center mx-2 mb-5">
              <v-icon color="error" x-large class="exit-warning-icon mr-4"
                >mdi-alert</v-icon
              >
              <v-card-title
                v-if="dialog.complexProgramMessage"
                class="word-break text-left exit-warning-text"
              >
                {{ dialog.complexProgramMessage }}
              </v-card-title>
              <v-card-title
                v-else
                class="word-break text-left exit-warning-text"
              >
                This program is too complex for this program builder. We
                recommend contacting Whistle at
                <a href="help@wewhistle.com">help@wewhistle.com</a> if you wish
                to use this program.
              </v-card-title>
            </div>

            <v-card-actions class="mx-12 d-flex justify-center">
              <!-- <v-btn
                class="mr-4"
                color="primary"
                outlined
                width="130"
                @click="
                  (dialog.elementDeleteConfirmation = false),
                    (dialog.elementDeleteIndex = null),
                    (dialog.resourceDeleteIndex = null),
                    (dialog.messageDeleteIndex = null)
                "
                >Cancel</v-btn
              > -->
              <v-btn color="primary" width="130" depressed @click="reset"
                >Ok</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-overlay
          v-if="
            dialog.groupCreator ||
              dialog.moduleWidget ||
              dialog.metricWidget ||
              dialog.resourceWidget ||
              dialog.rewardWidget ||
              dialog.messageScheduleDescription
          "
        />
      </v-card>
    </v-dialog>
    <!-- Image Cropping dialog -->
    <v-dialog persistent v-model="dialog.imageCropper" width="600">
      <v-card rounded="0" class="px-12">
        <div class="d-flex align-center">
          <v-icon class="mr-2">mdi-camera</v-icon>
          <v-card-title class="word-break px-0 mx-0">
            Please crop the image below to a 16 : 9 aspect ratio.
          </v-card-title>
        </div>
        <cropper
          ref="programCropper"
          :src="program.imageURLToCrop"
          :stencil-props="{
            aspectRatio: 16 / 9
          }"
        />
        <v-card-actions class="pt-4">
          <v-spacer />
          <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="closeImageCropDialog(true)"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Unsaved changes dialog -->
    <v-dialog v-model="dialog.exit" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Do you want to save any changes you made before you go?
          </v-card-title>
        </div>

        <v-card-actions
          class="mx-12"
          :class="isMobile ? 'd-flex flex-column' : ''"
        >
          <v-btn color="primary" @click="reset" outlined width="130"
            >Don't save</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            @click="createProgram(true)"
            width="130"
            :class="isMobile ? 'mt-4' : ''"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used while waiting on API response -->
    <v-dialog
      v-if="dialog.loadingAPI"
      v-model="dialog.loadingAPI"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center align-center flex-column py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-center">
            {{
              loading.apiError
                ? "Error loading program"
                : "Loading your program..."
            }}
          </v-card-title>
        </div>
        <div v-if="loading.apiError" class="text-left mx-6 my-6">
          There was an issue loading your program. Please try again or contact
          us if you need further assistance.
        </div>
        <div v-else>
          <v-progress-circular
            :size="60"
            :width="5"
            color="brandCyan"
            indeterminate
            class="my-5"
          ></v-progress-circular>
          <p class="py-2 mx-10 text-left">
            Please wait while we load your program.
          </p>
        </div>
        <!-- Buttons -->
        <div v-if="loading.apiError">
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              outlined
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              @click="
                () => {
                  $router.push({
                    name: 'programadmin'
                  });
                }
              "
              >Exit</v-btn
            >
            <v-btn
              color="brandCyan"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              depressed
              @click="loadBuilder"
              class="white--text"
              >Retry</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LearningService from "@/services/LearningService";
import ProgramService from "@/services/ProgramService";
import ContentService from "@/services/ContentService";
import UserService from "@/services/UserService";
import BudgetService from "@/services/BudgetService";
import RulesService from "@/services/RulesService";
import BadgeService from "@/services/BadgeService";
import TriggerService from "@/services/TriggerService";
import GroupService from "@/services/GroupService";

import {
  truncateNumber,
  numberWithCommas,
  formatEmailTemplate,
  formatFileType,
  getMessageBodyFromTemplate,
  debounce
} from "@/shared_data/functions";
import { emailTemplate } from "@/shared_data/template_v2.js";
import { editorToolbar } from "@/shared_data/data";

import GroupCreatorWidget from "@/components/groups/GroupTypeSelector.vue";
import ProgramLearningModuleWidget from "@/components/ProgramLearningModuleWidget.vue";
import ProgramMetricWidget from "@/components/ProgramMetricWidget.vue";
import ProgramResourceWidget from "@/components/ProgramResourceWidget.vue";
import ProgramRewardWidget from "@/components/ProgramRewardWidget.vue";
import Robin from "@/components/Robin";
import TextEditor from "@/components/TextEditor.vue";
import GroupTable from "@/components/data-table/Groups.vue";

// import momentTz from "moment-timezone";
import moment from "moment";
import { mapState } from "vuex";
import draggable from "vuedraggable";
import currency from "currency.js";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

function initialState() {
  return {
    slideKey: 1,
    dialogConfirmation: false,
    dialogError: false,
    errorText: null,
    slideDirection: "topic-left",
    editorToolbar: editorToolbar,
    preloadedProgram: null,
    timezones: [
      "US/Eastern",
      "US/Central",
      "US/Mountain",
      "US/Pacific",
      "US/Alaska",
      "US/Hawaii"
    ],
    ruleAggregation: ["DAILY", "WEEKLY", "MONTHLY"],
    keys: {
      basics: 1,
      dates: 2,
      audience: 3,
      elements: 4,
      messages: 5,
      // details: 2,
      // amount: 5,
      // expiration: 9,
      // classify: 10,
      review: 100,
      publishing: 101,
      sent: 102,
      error: 103
    },
    program: {
      programId: null,
      name: null,
      description: null,
      imageURL: null,
      image: null,
      imageURLToCrop: null,
      imageURLFileName: null,
      procoreType: false,
      timezone: "US/Central",
      ruleAggregation: "DAILY",
      startDate: moment()
        // .add(1, "day")
        .format("YYYY-MM-DDTHH:mm"),
      endDate: moment()
        .add(3, "month")
        .format("YYYY-MM-DDTHH:mm"),
      rollingProgramToggle: false,
      scheduleSelection: "no_end", //no_end, end, rolling
      rollingProgramLength: null,
      audience: [],
      elements: [],
      reward: {
        context: "Badges",
        // displayName: null,
        awardDisplayName: null, // if budgetId
        badgeDisplayName: null, // if badgeId
        amount: null,
        budgetId: null,
        badgeId: null
      },
      resources: [],
      expandElements: true,
      expandMessages: true,

      messages: [],
      previewMode: false,
      previewModeUser: null,
      displayLeaderboard: true,
      forceLinearProgress: false,
      originalResources: []
    },
    dialog: {
      loadingAPI: false,
      exit: false,
      complexProgram: false,
      complexProgramMessage: null,
      imageCropper: false,
      groupInfoSheet: false,
      groupCreator: false,
      moduleWidget: false,
      moduleWidgetType: "Learning",
      metricWidget: false,
      // Used to load a metric in the metric widget to edit
      editMetric: null,
      editMetricIndex: null,
      rewardWidget: false,
      // Boolean used to tell reward widget if we're editing an existing reward
      editRewardBadge: false,
      editRewardPayment: false,
      resourceWidget: false,
      messagePreview: false,
      // Used to warn when user is editing a message but they click to edit another message or try to leave the page
      messageEditWarning: false,
      incompleteMessage: false,
      elementDeleteConfirmation: false,
      elementDeleteIndex: null,
      elementDeleteContext: null,
      resourceDeleteIndex: null,
      messageDeleteIndex: null,
      messageScheduleDescription: false
    },
    loading: {
      groups: false,
      assignments: false,
      users: false,
      elements: false,
      badges: false,
      budgets: false,
      resources: false,
      surveys: false,
      modules: false,
      metrics: false,
      apiError: false
    },
    data: {
      groups: [],
      infoSheetItem: null,
      modules: [],
      surveys: [],
      metrics: [],
      resources: [],
      badges: [],
      userCount: null,
      budgets: [],
      messageScheduleOptions: [
        { value: "AFTER_START", label: "after program start" },
        { value: "BEFORE_END", label: "before program end" }
      ]
    },
    search: {
      audience: null,
      audiencePage: 1
    },
    hover: {
      module: false,
      survey: false,
      metric: false,
      reward: false,
      resource: false
    },
    // Used to store local variables for editing / creating a message
    message: {
      messageId: null,
      scheduleValue: null,
      scheduleOption: "AFTER_START",
      messageSubject: null,
      messageShortBody: null,
      messageBody: null,
      valid: true,
      preview: null,
      // If the user is trying to leave the page, then we warn them and store the index of the page they're trying to route to
      pageToRoute: null,
      messageToLoad: null
    },
    preloadedMessage: {
      messageBody:
        "<p>'{{{program.name}}}' program check-in! It's been 30 days since this program launched. If you've been putting it off, now is a good time to dive in!</p>",
      messageShortBody: "30 day check-in for the '{{{program.name}}}' program!",
      messageSubject: "It's not too late!",
      messageId: -2,

      messageToLoad: null,
      newMessage: true,
      scheduleValue: 30,
      scheduleOption: "AFTER_START",
      pageToRoute: null,
      preview: null,
      valid: true,
      defaultIndex: 1
    },
    defaultMessages: [
      {
        messageBody:
          "<p>Welcome to the program {{{user.firstName}}}! We're glad you're here! Let's get started!</p>",
        messageShortBody: "Get pumped!",
        messageSubject: "Welcome to the program!",
        messageId: -3,

        messageToLoad: null,
        newMessage: true,
        scheduleValue: 0,
        scheduleOption: "AFTER_START",
        pageToRoute: null,
        preview: null,
        valid: true,
        defaultIndex: 2
      },
      {
        messageBody:
          "<p>'{{{program.name}}}' program check-in! It's been 30 days since this program launched. If you've been putting it off, now is a good time to dive in!</p>",
        messageShortBody:
          "30 day check-in for the '{{{program.name}}}' program!",
        messageSubject: "It's not too late!",
        messageId: -2,

        messageToLoad: null,
        newMessage: true,
        scheduleValue: 30,
        scheduleOption: "AFTER_START",
        pageToRoute: null,
        preview: null,
        valid: true,
        defaultIndex: 1
      }
      // {
      //   messageBody:
      //     "<p>Don't wait! Your program only has {{{program.hoursRemaining}}} left to go! If you haven't finished the program yet, now is your chance!</p>",
      //   messageShortBody: "Last chance!",
      //   messageSubject: "Your program is about to end!",
      //   messageId: -1,
      //   messageToLoad: null,
      //   newMessage: true,
      //   scheduleValue: 2,
      //   scheduleOption: "BEFORE_END",
      //   pageToRoute: null,
      //   preview: null,
      //   valid: true,
      //   defaultIndex: 0
      // }
    ],
    groupTable: {
      itemsPerPage: 10,
      page: 1,
      // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
      awaitingPage: 1,
      search: null,
      debounceSearch: null,
      loading: false,
      total: 0,
      selected: [],
      options: {},
      footerProps: {
        "items-per-page-options": [10, 25, 50, 100]
      },
      latestFilter: null,
      latestSort: null,
      columns: [
        {
          text: "Group ID",
          align: "center",
          sortable: true,
          value: "groupId",
          width: "12%"
        },
        { text: "Group", value: "groupName", width: "25%" },
        { text: "", value: "add" }
      ]
    },
    values: {}
  };
}

export default {
  name: "ProgramWizard",
  title: "Whistle | Program Wizard",
  components: {
    Robin,
    GroupCreatorWidget,
    // StripePaymentMethodsManager,
    ProgramLearningModuleWidget,
    ProgramMetricWidget,
    ProgramResourceWidget,
    ProgramRewardWidget,
    draggable,
    TextEditor,
    Cropper,
    GroupTable
  },
  props: {
    sourcePage: {
      type: String,
      default: null
    },
    budgets: Array,
    loadingBudgets: Boolean,
    // preloadedProgram: Object,
    // editingProgram: Boolean,
    preloadedStep: String
  },
  data() {
    return initialState();
  },
  created() {
    console.log("Created");
    // if (
    //   this.$route.query.clientId !== undefined &&
    //   this.$route.query.clientId !== null
    // ) {
    //   console.log("Preloading client");
    //   this.clientId = Number(this.$route.query.clientId);
    // }

    // this.clientId = this.userProfile.clientId;
    this.createPlatformActivity("OPENED_PROGRAM_WIZARD");
    // ERASE
    // this.getGroups([0]);
    // this.getGroups();
    // this.getUsers();

    if (this.$route.query.programId) {
      return this.loadBuilder();
    }

    // if (this.preloadedProgram == null) {
    this.getUserCount();
    // this.getGroups();
    this.getBudgets();
    this.getBadges();
    this.getLearningModules();
    this.getSurveyModules();
    this.getMetrics();
    this.getContent();
    // this.program.startDate = moment()
    //   .add(1, "day")
    //   .format("YYYY-MM-DDTHH:mm");
    // this.program.endDate = moment()
    //   .add(3, "month")
    //   .format("YYYY-MM-DDTHH:mm");
    // } else {
    //   // Duplicating: this.preloadedProgram && !this.editingProgram
    //   console.log("Preloading program", this.preloadedProgram);
    //   if (
    //     this.duplicatingProgram &&
    //     (this.userProfile.clientId != 1 || this.preloadedProgram.clientId == 0)
    //   ) {
    //     this.clientId = this.userProfile.clientId;
    //   } else {
    //     this.clientId = this.preloadedProgram.clientId;
    //   }
    //   this.program.programId = this.preloadedProgram.programId;
    //   let programCache = this.preloadedProgram.ProgramCaches.filter(
    //     x => x.status != "Deleted"
    //   );
    //   this.program.previewModeUser =
    //     this.preloadedProgram.status != "Preview"
    //       ? null
    //       : programCache.length == 1
    //       ? programCache[0].userId
    //       : null;
    //   this.program.name = this.preloadedProgram.name;
    //   this.program.description = this.preloadedProgram.description;
    //   this.program.imageURL = this.preloadedProgram.imageURL;

    //   this.program.previewMode = this.preloadedProgram.status == "Preview";
    //   this.program.displayLeaderboard =
    //     this.clientId == 0
    //       ? false
    //       : this.preloadedProgram.displayLeaderboard != undefined
    //       ? this.preloadedProgram.displayLeaderboard
    //       : true;
    //   this.program.forceLinearProgress =
    //     this.clientId == 0 ||
    //     this.preloadedProgram.forceLinearProgress == undefined
    //       ? false
    //       : this.preloadedProgram.forceLinearProgress;
    //   this.program.originalResources = this.preloadedProgram.ProgramAttachments;

    //   this.getLearningModules();
    //   this.getSurveyModules();
    //   this.getMetrics();
    //   this.getContent();

    //   //commented out to test slow loading - make sure to uncomment the below

    //   if (
    //     this.preloadedProgram.audience &&
    //     this.preloadedProgram.audience.groups
    //   )
    //     this.getGroups(
    //       this.preloadedProgram.audience &&
    //         Array.isArray(this.preloadedProgram.audience.groups)
    //         ? this.preloadedProgram.audience.groups
    //         : [],
    //       false
    //     );

    //   // Start date logic
    //   if (moment(this.preloadedProgram.startDate).isValid()) {
    //     this.program.startDate = moment(
    //       moment.utc(this.preloadedProgram.startDate).toDate()
    //     )
    //       .local()
    //       .format("YYYY-MM-DDTHH:mm");
    //   }
    //   // else {
    //   //   this.program.startDate = moment()
    //   //     .add(1, "day")
    //   //     .format("YYYY-MM-DDTHH:mm");
    //   // }

    //   // End date logic
    //   if (this.preloadedProgram.rollingProgramLength) {
    //     this.program.rollingProgramLength = this.preloadedProgram.rollingProgramLength;
    //     this.program.rollingProgramToggle = true;
    //   } else if (moment(this.preloadedProgram.endDate).isValid()) {
    //     this.program.endDate = moment(
    //       moment.utc(this.preloadedProgram.endDate).toDate()
    //     )
    //       .local()
    //       .format("YYYY-MM-DDTHH:mm");
    //   }
    //   //  else {
    //   //   this.program.endDate = moment()
    //   //     .add(1, "day")
    //   //     .format("YYYY-MM-DDTHH:mm");
    //   // }

    //   // Message logic
    //   if (this.preloadedProgram.MessageDefinitions) {
    //     this.program.messages = this.preloadedProgram.MessageDefinitions.map(
    //       x => {
    //         x.id = x.messageId;
    //         x.newMessage = false;
    //         // x.schedule = x.numDaysToProgramEnd;
    //         x.messageBody = getMessageBodyFromTemplate(x.messageBody);
    //         return x;
    //       }
    //     );
    //   }

    //   // Resource logic
    //   if (this.preloadedProgram.ProgramAttachments) {
    //     this.program.resources = this.formatContent(
    //       this.preloadedProgram.ProgramAttachments.filter(x => x.Content).map(
    //         x => x.Content
    //       )
    //     );
    //   }

    //   // We check if there are any payout rules. If so, fetch those rule groups so the rules are formatted for us
    //   var awardRules = this.preloadedProgram.RuleGroups.filter(
    //     x => x.type == "PAYOUT_RULE" && x.status != "Deleted" && x.PayoutRules
    //   );
    //   if (awardRules.length == 0) {
    //     var badgeId = null;
    //     var rewardAmount = null;
    //     var rewardMemo = null;
    //   } else {
    //     console.log("award rules");
    //     console.log(awardRules[0]);
    //     if (awardRules[0].PayoutRules.length > 0) {
    //       badgeId = awardRules[0].PayoutRules[0].badgeId;

    //       // Now format the program reward
    //       // We load in a temp reward so that the computed selectedBudget can be calculated.
    //       // Then after getBudgets() and getBadges() we format the program reward with their responses.
    //       this.program.reward = {
    //         id: awardRules[0].PayoutRules[0].id,
    //         amount: awardRules[0].PayoutRules[0].amount,
    //         memo: awardRules[0].PayoutRules[0].memo,
    //         budgetId:
    //           this.duplicatingProgram && this.preloadedProgram.clientId == 0
    //             ? null
    //             : this.preloadedProgram.budgetId,
    //         badgeId: null,
    //         context: "reward",
    //         icon: "mdi-trophy-variant-outline",
    //         iconColor: "brand-dark-green"
    //       };
    //       console.log("Program reward ", this.program.reward);
    //       rewardAmount = awardRules[0].PayoutRules[0].amount;
    //       rewardMemo = awardRules[0].PayoutRules[0].memo;
    //     }

    //     this.getRuleGroup(awardRules[0].ruleGroupId);
    //   }
    //   // this.getBudgets(
    //   //   badgeId ? null : this.preloadedProgram.budgetId, //don't send a budgetId if there is a badge
    //   //   rewardAmount
    //   // );
    //   this.getBudgets(
    //     this.preloadedProgram.budgetId ? this.preloadedProgram.budgetId : null,
    //     rewardAmount,
    //     rewardMemo
    //   );
    //   this.getBadges(badgeId);
    //   if (this.preloadedStep) {
    //     // Step to load builder in on
    //     if (this.preloadedStep == "participants") {
    //       this.slideKey = this.keys.audience;
    //     }
    //   }
    // }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
    // ERASE
    // this.program.reward = {
    //   id: 1,
    //   displayName: "$7.10 Reward Payment",
    //   context: "reward",
    //   amount: "7.1",
    //   budgetId: 99,
    //   icon: "mdi-trophy-variant-outline",
    //   iconColor: "brand-dark-green",
    // };
    // this.program.name = "Barista training";
    // this.program.description = "Learning to barista";
    // this.program.imageURL =
    //   "https://storage.googleapis.com/whistle-dev-pictures/Programs/9/Images/7N0A4996_(Edited_v1).jpg";
    // this.program.startDate = "2022-04-28T17:23";
    // this.program.endDate = "2022-04-29T18:23";

    if (
      this.preloadedProgram !== undefined &&
      this.preloadedProgram !== null &&
      this.preloadedProgram.complexProgram
    ) {
      this.dialog.complexProgram = true;
      this.dialog.complexProgramMessage = this.preloadedProgram.complexProgramWarning;
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  beforeDestroy() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    async loadBuilder() {
      this.loading.apiError = false;
      let clientId =
        this.$route.query.clientId !== undefined &&
        this.$route.query.clientId !== null
          ? this.$route.query.clientId
          : this.clientId;
      try {
        setTimeout(() => {
          if (!this.preloadedProgram) {
            this.dialog.loadingAPI = true;
          }
        }, 250);
        let query = `?clientId=${clientId}&programId=${this.$route.query.programId}`;
        console.log("Going to fetch program for builder!", query);
        let programResponse = await ProgramService.getProgramForWizardV2(
          this.$route.query.programId,
          clientId
        );
        console.log("Program res ", programResponse);

        if (
          programResponse &&
          programResponse.rows &&
          programResponse.rows.length
        )
          this.preloadedProgram = programResponse.rows[0];
        else {
          this.loading.apiError = true;
        }
      } catch (err) {
        console.log("Error getting program! ", err);
        this.loading.apiError = true;
      }

      // IF we had an error, then we keep the popup showing, otherwise we hide the dialog
      if (this.loading.apiError) return;
      else {
        this.dialog.loadingAPI = false;
      }

      if (
        this.duplicatingProgram &&
        (this.userProfile.clientId != 1 || this.preloadedProgram.clientId == 0)
      ) {
        this.clientId = this.userProfile.clientId;
      } else {
        this.clientId = this.preloadedProgram.clientId;
      }
      this.program.programId = this.preloadedProgram.programId;
      // let programCache = this.preloadedProgram.ProgramCaches.filter(
      //   x => x.status != "Deleted"
      // );
      // this.program.previewModeUser =
      //   this.preloadedProgram.status != "Preview"
      //     ? null
      //     : programCache.length == 1
      //     ? programCache[0].userId
      //     : null;
      this.program.name = this.preloadedProgram.name;
      this.program.description = this.preloadedProgram.description;
      this.program.imageURL = this.preloadedProgram.imageURL;

      this.program.previewMode = this.preloadedProgram.status == "Preview";
      this.program.procoreType = this.preloadedProgram.type == "procore";
      this.program.timezone = this.preloadedProgram.timezone;
      this.program.ruleAggregation = this.preloadedProgram.ruleAggregation;
      this.program.displayLeaderboard =
        this.clientId == 0
          ? false
          : this.preloadedProgram.displayLeaderboard != undefined
          ? this.preloadedProgram.displayLeaderboard
          : true;
      this.program.forceLinearProgress =
        this.clientId == 0 ||
        this.preloadedProgram.forceLinearProgress == undefined
          ? false
          : this.preloadedProgram.forceLinearProgress;
      this.program.originalResources = this.preloadedProgram.ProgramAttachments;

      this.getUserCount();
      this.getLearningModules();
      this.getSurveyModules();
      this.getMetrics();
      this.getContent();

      // Before we get groups, we preload the ones we're currently using
      this.program.audience = this.preloadedProgram.ProgramGroups.filter(
        x =>
          // Either is group 0
          // or
          // We're preloading a universal program from client 0 that we're duplicating
          (x.GroupRule &&
            !(
              this.preloadedProgram.clientId == 0 && this.duplicatingProgram
            )) ||
          x.groupId == 0
      ).map(x =>
        x.groupId == 0 ? { groupId: 0, displayName: "Everyone" } : x.GroupRule
      );

      let groupsOtherThanEveryone = this.program.audience.filter(
        x => x.groupId != 0
      );
      if (groupsOtherThanEveryone.length) {
        const groupFilter = `status != 'Deleted' && groupId.isIn(${JSON.stringify(
          groupsOtherThanEveryone.map(x => x.groupId)
        )})`;
        this.loading.groups = true;
        let groupUserCounts = await GroupService.getGroupAssignmentV2({
          groupBy: "groupId",
          extract: "groupId",
          filter: groupFilter,
          limit: groupsOtherThanEveryone.length,
          offset: 0
        });
        console.log("Got group user count from loadBuilder: ", groupUserCounts);
        groupUserCounts.result.count.forEach(g => {
          const foundGroup = this.program.audience.find(
            x => x && x.groupId == g.groupId
          );
          if (foundGroup) foundGroup.numUsers = g.count;
        });
        this.program.audience.forEach(g => {
          if (g.numUsers === undefined) g.numUsers = 0;
        });
        this.loading.groups = false;
      }
      // this.getGroups(
      //   this.preloadedProgram.ProgramGroups
      //     ? this.preloadedProgram.ProgramGroups.map(x => x.groupId)
      //     : [],
      //   false
      // );

      // Start date logic
      if (moment(this.preloadedProgram.startDate).isValid()) {
        this.program.startDate = moment(
          moment.utc(this.preloadedProgram.startDate).toDate()
        )
          .local()
          .format("YYYY-MM-DDTHH:mm");
      }

      // End date logic
      if (this.preloadedProgram.rollingProgramLength) {
        this.program.rollingProgramLength = this.preloadedProgram.rollingProgramLength;
        this.program.scheduleSelection = "rolling";
      } else if (!this.preloadedProgram.endDate) {
        this.program.scheduleSelection = "no_end";
      } else if (moment(this.preloadedProgram.endDate).isValid()) {
        this.program.scheduleSelection = "end";
        this.program.endDate = moment(
          moment.utc(this.preloadedProgram.endDate).toDate()
        )
          .local()
          .format("YYYY-MM-DDTHH:mm");
      }

      // Message logic
      if (this.preloadedProgram.MessageDefinitions) {
        this.program.messages = this.preloadedProgram.MessageDefinitions.map(
          x => {
            x.id = x.messageId;
            x.newMessage = false;
            x.scheduleValue =
              x.numDaysToProgramEnd !== null
                ? x.numDaysToProgramEnd
                : x.numDaysAfterProgramStart;
            x.scheduleOption =
              x.numDaysToProgramEnd !== null ? "BEFORE_END" : "AFTER_START";
            x.messageBody = getMessageBodyFromTemplate(x.messageBody);
            return x;
          }
        );
      }

      // Resource logic
      if (this.preloadedProgram.ProgramAttachments) {
        this.program.resources = this.formatContent(
          this.preloadedProgram.ProgramAttachments.filter(x => x.Content).map(
            x => x.Content
          )
        );
      }

      var elements = this.preloadedProgram.ProgramElements || [];
      this.program.elements = elements;
      console.log("program elements to format", this.program.elements);
      this.formatProgramElements();
      this.getClientProgramElements(this.clientId);

      // We check if there are any payout rules. If so, fetch those rule groups so the rules are formatted for us
      const budgetId =
        this.duplicatingProgram && this.preloadedProgram.clientId == 0
          ? null
          : this.preloadedProgram.budgetId;
      const badgeId = this.preloadedProgram.badgeId;
      const rewardAmount = this.preloadedProgram.payoutAmount;
      const rewardMemo = this.preloadedProgram.payoutMemo;

      // Now format the program reward
      // We load in a temp reward so that the computed selectedBudget can be calculated.
      // Then after getBudgets() and getBadges() we format the program reward with their responses.
      this.program.reward = {
        id: new Date().toString(),
        amount: rewardAmount,
        memo: rewardMemo,
        budgetId,
        badgeId,
        context: "reward",
        icon: "mdi-trophy-variant-outline",
        iconColor: "brand-dark-green"
      };
      console.log("Program reward ", this.program.reward);
      // }

      this.getBudgets(budgetId, rewardAmount, rewardMemo);
      this.getBadges(badgeId);
      if (this.preloadedStep) {
        // Step to load builder in on
        if (this.preloadedStep == "participants") {
          this.slideKey = this.keys.audience;
        }
      }
    },
    reset() {
      // this.resetAllData();
      // this.$emit("reset");
      if (this.sourcePage) this.$router.push({ name: this.sourcePage });
      else this.$router.push({ name: "programadmin" });
      // this.$emit("get-budgets");
    },
    routeToProgramBuilder() {
      this.$router.push({
        name: "programbuilder",
        params: {
          preloadedProgram: this.preloadedProgram,
          editingProgram: this.editingProgram,
          activeProgram: this.activeProgram
        }
      });
    },
    getBudgets(rewardBudgetId = null, rewardAmount = null, rewardMemo = null) {
      this.loading.budgets = true;
      BudgetService.getBudgets({ lite: 1 })
        .then(response => {
          var budgets = response
            .filter(
              budget =>
                budget.status !== "Deleted" &&
                budget.status !== "Expired" &&
                budget.clientId == this.clientId
            )
            .map(x => {
              var obj = x;
              obj.id = x.budgetId.toString();
              obj.context = "Budgets";
              obj.contextResourceId = x.budgetId.toString();
              obj.contextColumn = "metricValue";
              obj.displayName = x.budgetDisplayName || x.budgetName;

              obj.displayNameWithBalance = `${obj.displayName} - ${currency(
                x.budgetBalance
              ).format({ symbol: obj.awardId == 1 ? "$" : "" })}`;

              return obj;
            });
          console.log("Budgets: ", budgets);
          this.data.budgets = budgets;

          this.loading.budgets = false;

          if (rewardBudgetId !== null) {
            // We're autofilling a badge so now that we have badge data, we grab that badge and format it as our reward
            let foundBudget = budgets.find(x => x.budgetId == rewardBudgetId);
            if (foundBudget) {
              // console.log("format program reward");
              // console.log(foundBudget);
              // console.log(rewardBudgetId);
              this.formatProgramReward({
                budgetId: rewardBudgetId,
                amount: rewardAmount,
                context: "reward",
                memo: rewardMemo
              });
            }
            // else {
            //     // console.log("removing reward");
            //     this.program.reward.amount = null;
            //     this.program.reward.awardDisplayName = null;
            //     this.program.reward.budgetId = null;
            //   }
          }
        })
        .catch(error => {
          console.log("Error getting budgets ", error);
          this.loading.budgets = false;
        });
    },
    getRuleGroup(ruleGroupId) {
      this.loading.elements = true;
      RulesService.getRuleGroups(ruleGroupId)
        .then(rule => {
          // console.log("Rule Groups: ", response);
          console.log("Rule group: ", rule);
          if (
            rule &&
            rule.status !== "Deleted" &&
            (rule.clientId == this.clientId || !this.editingProgram)
          ) {
            if (rule.complexRuleGroup) {
              this.dialog.complexProgram = true;
              this.dialog.complexProgramWarning = rule.complexRuleGroupWarning;
              return;
            }

            if (rule.name == null) {
              rule.name = "Award Rule";
            }

            // obj.rules.name = obj.name;
            // obj.rules.description = obj.description;
            rule.rules.primaryRuleGroup = rule.primaryRuleGroup;
            if (
              rule.rules.group.length == 1 &&
              rule.rules.group[0].contextColumn == "participantId" &&
              rule.rules.group[0].value == -11111
            ) {
              rule.rules.group = [];
            }

            // This is called when we're preloading a program and we need their elements
            if (rule.rules) {
              var elements = [];
              console.log(rule.rules);
              elements = rule.rules.group;
              this.program.elements = elements;
              // this.$set(this.program, 'elements', elements);
              console.log("program elements to format");
              console.log(this.program.elements);
              this.formatProgramElements();
              this.getClientProgramElements(this.clientId);
            }
          }

          this.loading.elements = false;
        })
        .catch(error => {
          console.log("Error getting rule groups ", error);
          this.loading.elements = false;
        });
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    goToSlide(index, forceRoute = false) {
      // Figures out what step we're going into so we can insert the proper platform activity
      var platformActivityEvent = null;
      if (this.slideKey == this.keys.basics)
        platformActivityEvent = "ADDED_PROGRAM_DETAILS";
      else if (this.slideKey == this.keys.dates)
        platformActivityEvent = "SELECTED_PROGRAM_DATES";
      else if (this.slideKey == this.keys.audience)
        platformActivityEvent = "SELECTED_PROGRAM_AUDIENCE";
      else if (this.slideKey == this.keys.elements)
        platformActivityEvent = "ADDED_PROGRAM_ELEMENTS";
      else if (
        this.slideKey == this.keys.messages &&
        this.program.messages.length > 0
      )
        platformActivityEvent = "ADDED_PROGRAM_MESSAGES";
      // else if (this.slideKey == this.keys.review)
      // platformActivityEvent = "SELECTED_PAYMENT_EXPIRATION_DATE";
      // this.disableContinueButton

      this.message.pageToRoute = null;
      // We have to block if the user has already sent payment
      if (this.slideKey >= 1 && this.slideKey < this.keys.publishing) {
        if (index < this.slideKey) {
          // We have to check if we're on the message slide and if we need to prompt them to save the message
          if (
            index !== this.keys.messages &&
            this.slideKey == this.keys.messages &&
            this.isEditingMessage &&
            !forceRoute
          ) {
            this.message.pageToRoute = index;
            this.dialog.messageEditWarning = true;
            return;
          }
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse || forceRoute) {
          // We have to check if we're on the message slide and if we need to prompt them to save the message
          if (
            index != this.keys.messages &&
            this.slideKey == this.keys.messages &&
            this.isEditingMessage &&
            !forceRoute
          ) {
            this.message.pageToRoute = index;
            this.dialog.messageEditWarning = true;
            return;
          }
          this.scrollToTop();
          this.slideKey = index;

          if (platformActivityEvent)
            this.createPlatformActivity(platformActivityEvent);
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();

      if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        // We have to check if any incomplete messages. if so, we show a dialog to have them finish editing
        if (
          this.program.messages.find(
            x =>
              (!x.scheduleValue && x.scheduleValue != 0) ||
              !x.messageSubject ||
              !x.messageBody ||
              !x.scheduleOption ||
              (this.program.scheduleSelection == "no_end" &&
                x.scheduleOption == "BEFORE_END") ||
              x.messageSubject.length >= 100 ||
              parseInt(x.scheduleValue) != x.scheduleValue ||
              parseInt(x.scheduleValue) < 0 ||
              (this.programLength !== null &&
                parseInt(x.scheduleValue) > this.programLength) ||
              (x.messageShortBody && x.messageShortBody.length >= 100)
          )
        ) {
          this.dialog.incompleteMessage = true;
          return;
        }
        this.createProgram();
      } else if (this.slideKey == this.keys.sent) {
        // If we're in preview mode then we take the user to that program
        if (
          this.program.previewMode &&
          this.program.programId
          // &&
          // this.program.previewModeUser == this.userProfile.userId
        )
          this.$router.push({
            name: "programdetails",
            params: {
              programId: this.program.programId.toString(),
              sourcePage: "programwizard"
            }
          });
        else this.reset();
      } else {
        // Figures out what step we're going into so we can insert the proper platform activity
        var platformActivityEvent = null;
        if (this.slideKey == this.keys.basics)
          platformActivityEvent = "ADDED_PROGRAM_DETAILS";
        else if (this.slideKey == this.keys.dates)
          platformActivityEvent = "SELECTED_PROGRAM_DATES";
        else if (this.slideKey == this.keys.audience)
          platformActivityEvent = "SELECTED_PROGRAM_AUDIENCE";
        else if (this.slideKey == this.keys.elements)
          platformActivityEvent = "ADDED_PROGRAM_ELEMENTS";
        else if (
          this.slideKey == this.keys.messages &&
          this.program.messages.length > 0
        )
          platformActivityEvent = "ADDED_PROGRAM_MESSAGES";

        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // We have to check if we're on the message slide and if we need to prompt them to save the message
        if (this.slideKey == this.keys.messages && this.isEditingMessage) {
          this.message.pageToRoute = this.route[routeIndex + 1];
          this.dialog.messageEditWarning = true;
          return;
        }
        this.slideKey = this.route[routeIndex + 1];

        if (platformActivityEvent)
          this.createPlatformActivity(platformActivityEvent);
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      // We have to check if we're on the message slide and if we need to prompt them to save the message
      if (this.slideKey == this.keys.messages && this.isEditingMessage) {
        this.message.pageToRoute = this.route[routeIndex - counter];
        this.dialog.messageEditWarning = true;
        return;
      }
      this.slideKey = this.route[routeIndex - counter];
    },
    focusOnTitle() {
      this.$refs.titleInput.focus();
    },
    async getUserCount() {
      // Used to just get a total number of users in the client
      this.loading.users = true;
      const userRes = await UserService.getUsersV2({
        filter: `status = 'Active' && clientId = ${this.clientId}`,
        limit: 1,
        extract: "userId"
      });
      console.log("total number of users ", userRes);
      this.data.userCount = userRes.result.count;
      this.loading.users = false;
    },
    preloadGroup(groupIds) {
      console.log("Calling preload group", groupIds);

      if (groupIds.length == 1 && groupIds[0].groupId) {
        console.log("Adding group to selected after group creator");
        return this.addGroupToSelected(groupIds[0], true);
      }
      // if (groupIds.includes(0)) {
      //   var group = this.data.groups.find((x) => x.groupId == 0);
      //   if (group) {
      //     this.program.audience = [group];
      //     this.addGroupToSelected(group);
      //   }
      // } else {

      var preloadedGroups = this.data.groups.filter(x =>
        groupIds.includes(x.groupId)
      );

      preloadedGroups.forEach(group => {
        this.addGroupToSelected(group, true);
      });

      this.dialog.groupCreator = false;
      // }
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.search.audience = undefined;
        this.groupTable.debounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - program wizard",
            groupId
          );
      });
    },
    getLearningModules() {
      this.loading.modules = true;
      LearningService.getModulesAdmin("Learning", this.clientId, true)
        .then(response => {
          console.log("Learning Modules: ", response);
          var modules = response.modules
            .filter(
              module =>
                // module.status !== "Deleted" &&
                // (module.clientId == 0 || module.clientId == this.clientId)
                module.status !== "Deleted"
            )
            .map(x => {
              var obj = x;
              obj.id = x.learningModuleId.toString();
              obj.context = "LearningModules";
              obj.contextResourceId = x.learningModuleId.toString();
              obj.contextColumn = "metricValue";
              obj.label =
                "Module Progress" +
                (x.displayName
                  ? x.displayName.substring(0, 30)
                  : x.name
                  ? x.name.substring(0, 30)
                  : "Unnamed module");
              obj.dataType = "INTEGER";
              obj.showNumberOptions = true;
              obj.createdAtFormatted = moment(obj.createdAt)
                .utc()
                .format("MMM DD, YYYY");
              obj.icon = "mdi-school";
              obj.iconColor = "brand";
              obj.displayGrabber = true;
              return obj;
            });
          //Adds header because this array will be used to select metrics for a payoutRule
          this.data.modules = modules;

          this.loading.modules = false;
          //ERASE
          // this.addProgramElement(modules[0]);

          this.formatProgramElements("LearningModules");
        })
        .catch(error => {
          console.log("Error getting learning modules", error);
          this.loading.modules = false;
        });
    },
    getSurveyModules() {
      this.loading.surveys = true;
      LearningService.getModulesAdmin("Survey", this.clientId, true)
        .then(response => {
          console.log("Surveys: ", response);
          var modules = response.modules
            .filter(
              module =>
                // module.status !== "Deleted" &&
                // (module.clientId == 0 || module.clientId == this.clientId)
                module.status !== "Deleted"
            )
            .map(x => {
              var obj = x;
              obj.id = x.learningModuleId.toString();
              obj.context = "LearningModules";
              obj.contextResourceId = x.learningModuleId.toString();
              obj.contextColumn = "metricValue";
              obj.label =
                "Survey Progress: " +
                (x.displayName
                  ? x.displayName.substring(0, 30)
                  : x.name
                  ? x.name.substring(0, 30)
                  : "Unnamed module");
              obj.dataType = "INTEGER";
              obj.showNumberOptions = true;
              obj.createdAtFormatted = moment(obj.createdAt)
                .utc()
                .format("MMM DD, YYYY");
              obj.icon = "mdi-comment-question-outline";
              obj.iconColor = "brand";
              obj.displayGrabber = true;
              return obj;
            });
          //Adds header because this array will be used to select metrics for a payoutRule
          this.data.surveys = modules;
          this.loading.surveys = false;

          //ERASE
          // this.addProgramElement(modules[0]);

          this.formatProgramElements("LearningModules");
        })
        .catch(error => {
          console.log("Error get surveys ", error);
          this.loading.surveys = false;
        });
    },
    getMetrics() {
      this.loading.metrics = true;
      ProgramService.getMetrics()
        .then(response => {
          console.log("Metrics: ", response);
          var metrics = response
            .filter(
              metric =>
                metric.status !== "Deleted" &&
                (metric.clientId == 0 || metric.clientId == this.clientId)
            )
            .map(x => {
              var obj = x;
              obj.id = x.metricId.toString();
              obj.displayName = x.metricName
                ? x.metricName
                : "Untitled progress metric";
              obj.context = "MetricDefinitions";
              obj.contextResourceId = x.metricId.toString();
              obj.contextColumn = "metricValue";
              obj.label = x.metricName;
              obj.dataType = x.dataType;
              obj.showNumberOptions =
                x.calculateProgress && x.dataType !== "BOOLEAN";
              obj.icon =
                x.dataType == "BOOLEAN"
                  ? "mdi-checkbox-marked-outline"
                  : "mdi-finance";
              obj.iconColor = "brand-cyan";
              obj.displayGrabber = true;
              return obj;
            });
          this.data.metrics = metrics;

          this.loading.metrics = false;

          this.formatProgramElements("MetricDefinitions");
        })
        .catch(error => {
          console.log("Error getting metrics ", error);
          this.loading.metrics = false;
        });
    },
    getContent(preloadContentId = null) {
      this.loading.resources = true;
      ContentService.getAllContent()
        .then(response => {
          var resources = this.formatContent(
            response.filter(
              resource =>
                resource.status !== "Deleted" &&
                (resource.clientId == 0 ||
                  resource.clientId == this.clientId) &&
                ![
                  "LEARNING_MODULE",
                  "LEARNING_CARD",
                  "LEARNING_CARD_CONTENT",
                  "SURVEY",
                  "badge",
                  "BADGE"
                ].includes(resource.category)
            )
          );

          console.log("Content: ", resources);
          this.data.resources = resources;

          this.loading.resources = false;

          if (preloadContentId && typeof preloadContentId == "number") {
            const foundContent = resources.find(
              x => x.contentId == preloadContentId
            );
            if (foundContent) this.addProgramElement(foundContent);
          }
        })
        .catch(error => {
          console.log("Error getting resources ", error);
          this.loading.resources = false;
        });
    },
    formatContent(arr) {
      return arr.map(x => {
        var obj = x;
        obj.id = x.contentId.toString();
        obj.displayName = x.name;
        obj.context = "Content";
        obj.contextResourceId = x.contentId.toString();
        obj.contextColumn = "metricValue";
        obj.fileType = formatFileType(x.filePath);
        obj.label = x.name;
        obj.icon =
          obj.fileType == "PDF"
            ? "mdi-file-pdf-box"
            : obj.fileType == "DOCX"
            ? "mdi-file-word"
            : obj.fileType == "XLSX"
            ? "mdi-file-excel"
            : obj.fileType == "PPTX"
            ? "mdi-file-powerpoint"
            : obj.fileType == "PNG"
            ? "mdi-file-image"
            : obj.fileType == "CSV"
            ? "mdi-file-delimited"
            : "mdi-file";
        obj.iconColor = "brand";
        obj.displayGrabber = false;

        obj.createdAtFormatted = moment(obj.createdAt)
          .utc()
          .format("MMM DD, YYYY");
        return obj;
      });
    },
    getBadges(rewardBadgeId = null) {
      // console.log("Getting badges");
      // console.log(rewardBadgeId);
      this.loading.badges = true;
      BadgeService.getBadges()
        .then(response => {
          console.log("Badges: ", response);
          var badges = response
            .filter(
              badge =>
                badge.status !== "Deleted" &&
                (badge.clientId == 0 || badge.clientId == this.clientId)
            )
            .map(x => {
              var obj = x;
              obj.id = x.badgeId.toString();
              obj.context = "Badges";
              obj.contextResourceId = x.badgeId.toString();
              obj.contextColumn = "metricValue";
              obj.displayName = obj.displayName
                ? obj.displayName
                : obj.name
                ? obj.name
                : "Unnamed badge";
              obj.icon = "mdi-trophy-variant-outline";
              obj.iconColor = "brand-dark-green";
              obj.displayGrabber = false;

              // obj.createdAtFormatted = moment(obj.createdAt)
              //   .utc()
              //   .format("MMM DD, YYYY");
              return obj;
            });
          //Adds header because this array will be used to select metrics for a payoutRule
          this.data.badges = badges;

          this.loading.badges = false;
          // ERASE
          // this.addProgramElement(badges[0]);

          if (rewardBadgeId !== null) {
            // We're autofilling a badge so now that we have badge data, we grab that badge and format it as our reward
            let foundBadge = badges.find(x => x.badgeId == rewardBadgeId);
            // console.log("found badge");
            // console.log(foundBadge);
            if (foundBadge) {
              this.formatProgramReward(foundBadge);
            } else {
              // console.log("removing badge");
              this.program.reward.badgeId = null;
              this.program.reward.imageUrl = null;
              this.program.reward.externalBadgeId = null;
              this.program.reward.badgeDisplayName = null;
            }
          }
        })
        .catch(error => {
          console.log("Error getting badges", error);
          this.loading.badges = false;
        });
    },
    removeUserFromSelected(index) {
      // var user = this.program.audience[index];
      this.program.audience.splice(index, 1);
    },
    addGroupToSelected(group, dontRemove = false) {
      // If they're adding the same group twice, we want to replace
      // This is because when loadBuilder is called, we temporarily put ProgramGroups as the audience
      // But they don't have a name. So once getGroups returns, we replace with groups with names
      let existingGroupIndex = this.program.audience.findIndex(
        x => x.groupId == group.groupId
      );
      if (existingGroupIndex != -1 && dontRemove)
        this.program.audience[existingGroupIndex] = group;
      else if (existingGroupIndex != -1)
        this.removeUserFromSelected(existingGroupIndex);
      else this.program.audience.push(group);
      this.dialog.groupInfoSheet = false;
    },
    async createProgram(savingIncomplete = false) {
      // Display loading screen
      this.slideKey = this.keys.publishing;
      this.dialog.exit = false;

      // Prep the start date and convert it to UTC
      var startDate = null;
      if (this.program.startDate && moment(this.program.startDate).isValid()) {
        startDate = moment(this.program.startDate)
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ");
      }

      // Prep the end date and convert it to UTC
      var endDate = null;
      if (
        this.program.endDate &&
        moment(this.program.endDate).isValid() &&
        this.program.scheduleSelection == "end"
      ) {
        endDate = moment(this.program.endDate)
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ");
      }

      // var ruleGroups = [];
      // // if (this.payoutRule && this.programRules) {
      // ruleGroups.push({
      //   payoutRule: this.payoutRule,
      //   rules: this.programRules
      // });
      // }

      var messages = this.program.messages
        .filter(
          x =>
            x.messageSubject &&
            x.messageBody &&
            (x.scheduleValue || x.scheduleValue == 0) &&
            x.scheduleOption &&
            parseInt(x.scheduleValue) >= 0 &&
            (this.programLength === null ||
              parseInt(x.scheduleValue) <= this.programLength)
        )
        .map(message => {
          message.clientId = this.clientId;
          message.imageURL = null;
          message.numDaysToProgramEnd =
            message.scheduleOption == "BEFORE_END"
              ? Number(message.scheduleValue)
              : null;
          message.numDaysAfterProgramStart =
            message.scheduleOption == "AFTER_START"
              ? Number(message.scheduleValue)
              : null;
          message.displayImageInCardPreview = false;
          message.displayOnPlatform = true;
          message.sendDashboard = true;
          message.sendEmail = true;
          message.sendSMS = true;
          message.sendPush = true;
          message.sendDate = null;
          message.createdBy = this.userProfile.userId;
          message.updatedBy = this.userProfile.userId;
          return message;
        });

      // If the user had an image but is now exiting without an image, then we delete.
      if (
        this.preloadedProgram &&
        this.editingProgram &&
        !this.program.image &&
        !this.program.imageURL &&
        this.preloadedProgram.imageURL &&
        this.preloadedProgram.imageURL.startsWith(
          "https://storage.googleapis.com/whistle"
        )
      ) {
        console.log("Deleting program image and it's replaced with nothing");
        var deleteImageResp = await ProgramService.deleteProgramImage(
          this.preloadedProgram.programId,
          this.clientId
        );
        console.log(deleteImageResp);
      }

      // Duplicate if user is not uploading their own image, not editing, a program was preloaded, and the original program's image came from GCS
      var duplicateImageFlag =
        this.duplicatingProgram &&
        !this.program.image &&
        this.program.imageURL == this.preloadedProgram.imageURL &&
        this.preloadedProgram.imageURL.startsWith(
          "https://storage.googleapis.com/whistle"
        );

      var content = this.program.resources.map(x => {
        return { contentId: x.contentId };
      });

      // if (!this.activeProgram) {
      var returnObj = {
        clientId: this.clientId,
        externalId: null,
        name: this.program.name,
        displayName: this.program.name,
        description: this.program.description,
        type: this.program.procoreType == true ? "procore" : null,
        timezone: this.program.timezone,
        ruleAggregation: this.program.ruleAggregation,
        status:
          this.clientId == 0
            ? "Incomplete"
            : savingIncomplete && (this.activeProgram || this.archivedProgram)
            ? "Active"
            : savingIncomplete
            ? "Incomplete"
            : this.program.previewMode
            ? "Preview"
            : "Scheduled",
        // audience: {
        //   groups: this.program.audience.map(x => x.groupId)
        // },
        // for budget id, will need to find reward with budget id, maybe a computed
        budgetId: this.program.reward.budgetId
          ? this.program.reward.budgetId
          : null,
        payoutAmount: this.program.reward.budgetId
          ? parseFloat(this.program.reward.amount || 0)
          : null,
        payoutMemo:
          this.program.reward.memo && this.program.reward.memo.trim() != ""
            ? this.program.reward.memo
            : null,
        badgeId: this.program.reward.badgeId
          ? this.program.reward.badgeId
          : null,
        // payoutFrequency: this.preloadedProgram
        //   ? this.preloadedProgram.payoutFrequency
        //   : "ONGOING",
        mandatory: this.preloadedProgram
          ? this.preloadedProgram.mandatory
          : false,
        startDate: startDate,
        endDate: endDate,
        rollingProgramLength:
          this.program.scheduleSelection == "rolling" &&
          this.program.rollingProgramLength
            ? Number(this.program.rollingProgramLength)
            : null,
        newUsersAfterProgramStart: this.preloadedProgram
          ? this.preloadedProgram.newUsersAfterProgramStart
          : true,
        displayLeaderboard:
          this.clientId == 0 ? false : this.program.displayLeaderboard,
        forceLinearProgress:
          this.clientId == 0 ? false : this.program.forceLinearProgress,
        // rules: {
        //   qualifiers: null,
        //   payoutRules: ruleGroups
        // },
        ProgramElements: this.programRules,
        ProgramGroups: this.program.audience.map(x => {
          return { groupId: x.groupId };
        }),
        MessageDefinitions: messages,
        ProgramAttachments: content
      };

      try {
        if (!this.editingProgram) {
          console.log("Creating a program", returnObj);
          var programResponse = await ProgramService.createProgramV2(returnObj);
          console.log("Got response from creating program ", programResponse);
          var programId = programResponse.programId;
          this.program.programId = programId;
        } else {
          programId = this.preloadedProgram.programId;
          console.log("Updating program ", returnObj);
          programResponse = await ProgramService.updateProgramV2(
            programId,
            returnObj
          );
          console.log("Got response from updating program ", programResponse);
        }
        // Set up the preview mode user so we know to route to that user once they click done
        // If we're editing a program and there wasn't a preview program user before, this means it was probably incomplete and now is in preview
        // if (programResponse.status == "Preview" && !this.program.previewModeUser)
        //   this.program.previewModeUser = programResponse.createdBy;

        if (this.program.image && !duplicateImageFlag) {
          var imageResponse = await ProgramService.uploadProgramImage(
            programId,
            this.program.image,
            this.clientId
          );
          console.log("Uploaded program image ", imageResponse);
        } else if (duplicateImageFlag) {
          imageResponse = await ProgramService.duplicateProgramImage(
            this.preloadedProgram.programId, //old id
            programId, //new id
            null,
            this.clientId
          );
          console.log("Duplicated program image ", imageResponse);
        }

        // Now we're done so we can advance to the final slide
        if (savingIncomplete) this.reset();
        else this.slideKey = this.keys.sent;
      } catch (err) {
        console.log("Error processing program", err);
        this.slideKey = this.keys.error;
      }

      // WalletService.sendPaymentBulk(object)
      //   .then((x) => {
      //     console.log(x);
      //     if (typeof x == "string") {
      //       if (x.startsWith("The sum of all payouts")) {
      //         this.dialogError = true;
      //         this.errorText =
      //           "The total payment sum is more than the selected budget's balance.";
      //       }
      //     } else if (x.error) {
      //       this.slideKey = this.keys.error;
      //     } else {
      //       // Display success screen
      //       this.slideKey = this.keys.sent;
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("Error sending payment! ", error);
      //     this.dialogError = true;
      //     this.errorText =
      //       "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      //   });
    },
    updateClientId(clientId) {
      // this.resetAllData();
      const initialStateObj = initialState();

      this.program.audience = [];
      this.data = initialStateObj.data;
      this.clientId = clientId;
      this.program.elements = this.getClientProgramElements(clientId);
      this.getLearningModules();
      this.getSurveyModules();
      this.getMetrics();
      this.getContent();
      // this.getGroupsV2(true);
      this.getUserCount();
      const budgetId =
        this.preloadedProgram && this.preloadedProgram.clientId == this.clientId
          ? this.program.reward.budgetId
          : null;
      if (budgetId == null) {
        this.$set(this.program, "reward", {
          ...this.program.reward,
          budgetId: null,
          amount: null,
          awardDisplayName: null
        });
      }
      this.getBudgets(
        budgetId,
        budgetId != null ? this.program.reward.amount : null,
        budgetId != null ? this.program.reward.memo : null
      );
      this.getBadges(
        this.program.reward.badgeId != undefined
          ? this.program.reward.badgeId
          : null
      );
    },
    // Returns an array of elements that are either client id or 0
    getClientProgramElements(clientId) {
      const clientIdsToFind = [clientId, 0];
      let elementsToKeep = [];
      if (this.program.elements.length > 0) {
        this.program.elements.forEach(element => {
          if (clientIdsToFind.includes(element.clientId)) {
            elementsToKeep.push(element);
          }
        });
      }
      return elementsToKeep;
    },
    loadPaymentsConsole() {
      this.$emit("payments-console");
    },
    createPlatformActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "PROGRAM_WIZARD",
        category: "PROGRAMS",
        date: new Date()
      };
      TriggerService.createActivity(obj)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    // Image selection handlers after the user exits from the image dropdown
    imageFileChanged(e) {
      if (e.target.files.length > 0) {
        // Load file into temp variable for cropping
        this.program.imageURLToCrop = URL.createObjectURL(e.target.files[0]);
        this.program.imageURLFileName = e.target.files[0].name;
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.dialog.imageCropper = true;
      }
    },
    dragAndDropImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // Load file into temp variable for cropping
        if (
          e.dataTransfer.files[0].type &&
          !(
            e.dataTransfer.files[0].type.includes("image/") &&
            (e.dataTransfer.files[0].type == "image/png" ||
              e.dataTransfer.files[0].type == "image/jpeg")
          )
        )
          return;
        this.program.imageURLToCrop = URL.createObjectURL(
          e.dataTransfer.files[0]
        );
        this.program.imageURLFileName = e.dataTransfer.files[0].name;

        this.dialog.imageCropper = true;
      }
    },
    removeImage() {
      this.program.image = null;
      this.program.imageURL = null;
    },
    // Called after cropping the image
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false

      this.dialog.imageCropper = false;
      if (submitPressed) {
        const { canvas } = this.$refs.programCropper.getResult();
        this.program.imageURL = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.program.imageURLFileName);
          this.program.image = file;

          //Regardless, we close dialog
          this.program.imageURLToCrop = null;
          this.program.imageURLFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.program.imageURLToCrop = null;
        this.program.imageURLFileName = null;
      }
    },
    // Loads module/survey widget when on program elements
    loadModuleWidget(type = "Learning") {
      this.dialog.moduleWidget = true;
      this.dialog.moduleWidgetType = type;
    },
    // Loads metric widget when on program elements
    loadMetricWidget(editMetric = null, editMetricIndex = null) {
      this.dialog.metricWidget = true;
      this.dialog.editMetric = JSON.parse(JSON.stringify(editMetric));
      this.dialog.editMetricIndex = JSON.parse(JSON.stringify(editMetricIndex));
    },
    // Loads resource widget when on program elements
    loadResourceWidget() {
      this.dialog.resourceWidget = true;
    },
    // Loads reward widget
    loadRewardWidget(editReward = false, type = null) {
      console.log("Editing reward? ", editReward);
      this.dialog.rewardWidget = true;
      if (editReward && type == "badge") {
        this.dialog.editRewardBadge = true;
        this.dialog.editRewardPayment = false;
      } else if (editReward && type == "payment") {
        this.dialog.editRewardBadge = false;
        this.dialog.editRewardPayment = true;
      } else {
        this.dialog.editRewardBadge = false;
        this.dialog.editRewardPayment = false;
      }
    },
    formatProgramReward(el) {
      // console.log("program reward");
      // console.log(this.program.reward);
      // console.log('el reward');
      // console.log(el);
      el.id = new Date().toString();
      el.icon = "mdi-trophy-variant-outline";
      el.iconColor = "brand-dark-green";
      el.displayGrabber = false;
      el.badgeId = el.badgeId ? el.badgeId : null;
      // if (el.budgetId && el.amount) {
      //   this.program.reward = el;
      // }
      if (el.budgetId) {
        var budget = this.data.budgets.find(x => x.budgetId == el.budgetId);
        // if budget is undefined, the budget was not in client's budgets, remove reward
        if (budget == undefined && !this.editingProgram) {
          this.program.reward = [
            {
              context: "Badges",
              displayName: null,
              amount: null,
              budgetId: null,
              badgeId: null
            }
          ];
        }
      }

      if (
        !el.displayName &&
        el.amount &&
        el.context &&
        el.context.toLowerCase() == "reward"
      )
        el.displayName =
          (!budget ? "" : budget.awardId == 1 ? "$ " : "") +
          this.formatCurrency(parseFloat(el.amount).toFixed(2)) +
          (!budget
            ? ""
            : budget.awardId != 1 && budget.Award
            ? el.amount == 1 && budget.Award.unitSingular
              ? " " + budget.Award.unitSingular
              : el.amount != 1 && budget.Award.unitPlural
              ? " " + budget.Award.unitPlural
              : ""
            : "") +
          " Reward Payment";
      else if (el.budgetId && !el.awardDisplayName) {
        // We are adding a badge and a budget to the program
        el.awardDisplayName =
          (!budget ? "" : budget.awardId == 1 ? "$ " : "") +
          this.formatCurrency(parseFloat(el.amount).toFixed(2)) +
          (!budget
            ? ""
            : budget.awardId != 1 && budget.Award
            ? el.amount == 1 && budget.Award.unitSingular
              ? " " + budget.Award.unitSingular
              : el.amount != 1 && budget.Award.unitPlural
              ? " " + budget.Award.unitPlural
              : ""
            : "") +
          " Reward Payment";
      }
      // (this.selectedBudget.awardId != 1 && this.selectedBudget.Award
      //   ? this.amount == 1 && this.selectedBudget.Award.unitSingular
      //     ? " " + this.selectedBudget.Award.unitSingular
      //     : this.amount != 1 && this.selectedBudget.Award.unitPlural
      //     ? " " + this.selectedBudget.Award.unitPlural
      //     : ""
      //   : "") + " Reward Payment";
      if (el.badgeId) {
        // if this.program.reward.badgeId, replace
        // Else, attach badge id
        this.$set(this.program, "reward", {
          ...this.program.reward,
          badgeDisplayName: el.displayName,
          badgeId: el.badgeId,
          externalBadgeId: el.externalBadgeId ? el.externalBadgeId : null,
          // displayName: this.program.reward.displayName
          //   ? this.program.reward.displayName
          //   : el.displayName,
          icon: el.icon,
          iconColor: el.iconColor,
          id: el.id,
          imageUrl: el.imageUrl ? el.imageUrl : null
        });
        // this.program.reward.badgeDisplayName = el.displayName;
        // this.program.reward.badgeId = el.badgeId;
        // if (el.externalBadgeId) {
        //   this.program.reward.externalBadgeId = el.externalBadgeId;
        // }
        // if (!this.program.reward.displayName) {
        //   this.program.reward.displayName = el.displayName;
        // }
        // this.program.reward.icon = el.icon;
        // this.program.reward.iconColor = el.iconColor;
        // this.program.reward.id = el.id;
        // if (el.imageUrl) {
        //   this.program.reward.imageUrl = el.imageUrl;
        // }
        this.dialog.rewardWidget = false;
      }
      if (el.budgetId) {
        // console.log("setting program reward");
        // If this.program.reward.budgetId, replace
        // Else, attach budget id
        this.$set(this.program, "reward", {
          ...this.program.reward,
          budgetId: el.budgetId,
          awardDisplayName: el.awardDisplayName
            ? el.awardDisplayName
            : el.displayName,
          amount: el.amount,
          // displayName: this.program.reward.displayName ? this.program.reward.displayName : el.displayName,
          icon: el.icon,
          iconColor: el.iconColor,
          id: el.id,
          memo: el.memo ? el.memo : null
        });
        // this.program.reward.budgetId = el.budgetId;
        // this.program.reward.awardDisplayName = el.awardDisplayName
        //   ? el.awardDisplayName
        //   : el.displayName;
        // this.program.reward.amount = el.amount;
        // if (!this.program.reward.displayName) {
        //   this.program.reward.displayName = el.displayName;
        // }
        // this.program.reward.icon = el.icon;
        // this.program.reward.iconColor = el.iconColor;
        // this.program.reward.id = el.id;
        this.dialog.rewardWidget = false;
      }
      // this.program.reward = el;
    },
    addProgramElement(el) {
      // console.log(el);
      // console.log("Adding program element ", el.context);
      // Push the element and close all dialogs (?)
      if ((el.badgeId || el.budgetId) && !el.learningModuleId) {
        this.formatProgramReward(el);
        this.dialog.rewardWidget = false;
      } else if (
        el.context &&
        ["resource", "resources", "content"].includes(el.context.toLowerCase())
      ) {
        this.program.resources.push(el);
      } else this.program.elements.push(el);
    },
    // Used the same as addProgramElement but we assume an index was specified
    updateProgramElement(el) {
      if (el.index === undefined || el.index === null) {
        this.program.elements.push(el);
        return;
      }
      this.program.elements[el.index] = JSON.parse(JSON.stringify(el));
    },
    updateMetric(metric) {
      // If we update a metric's name, we want to update it's element
      // console.log("updating metric");
      // console.log(metric);
      // metric has a context resouce id that corresponds to a context resouce id in the program elements
      // find and update
      const metricIndex = this.program.elements.findIndex(
        x =>
          x.contextResourceId == metric.contextResourceId &&
          x.context == "MetricDefinitions"
      );
      if (metricIndex) {
        this.program.elements[metricIndex] = {
          ...this.program.elements[metricIndex],
          displayName: metric.displayName
            ? metric.displayName
            : metric.metricName
        };
      }
    },
    addDefaultProgramMessages() {
      this.defaultMessages.forEach(msg => {
        const existing = this.program.messages.find(
          x => x.messageId == msg.messageId
        );
        if (!existing) this.program.messages.push(msg);
      });

      this.updateDefaultProgramMessageSchedule(this.programLength);
      // this.program.messages = this.program.messages.concat(
      //   this.defaultMessages
      // );
    },
    addProgramMessage(navigateToVariable = false) {
      console.log("Adding program message ", this.message);

      if (this.message.messageId) {
        // We're updating a message
        let messageIndex = this.program.messages.findIndex(
          x => x.messageId == this.message.messageId
        );
        if (messageIndex != -1)
          this.program.messages[messageIndex] = JSON.parse(
            JSON.stringify(this.message)
          );
      } else {
        this.message.messageId = new Date().getTime();
        this.message.newMessage = true;
        this.program.messages.push(JSON.parse(JSON.stringify(this.message)));
      }
      console.log("Nav after msg? ", navigateToVariable);

      // Now we either load a new message or route the page after saving
      if (navigateToVariable === true) {
        console.log("Route ", this.message.pageToRoute);
        console.log("Message ", this.message.messageToLoad);
        if (this.message.messageToLoad)
          this.editProgramMessage(this.message.messageToLoad, true);
        else if (this.message.pageToRoute !== null) {
          this.goToSlide(this.message.pageToRoute, true);
          this.clearProgramMessage();
        }
      } else {
        this.clearProgramMessage();
      }
    },
    editProgramMessage(message, forceLoad = false) {
      if (this.slideKey >= this.keys.publishing) return;
      // If they're clicking on the current active message then we do nothing
      if (this.message.messageId == message.messageId) return;
      // We have to check if we're on the message slide and if we need to prompt them to save the message
      if (
        this.slideKey == this.keys.messages &&
        this.isEditingMessage &&
        !forceLoad
      ) {
        this.message.messageToLoad = JSON.parse(JSON.stringify(message));
        this.dialog.messageEditWarning = true;
        return;
      }
      console.log("Editing program message ", message);

      this.message = JSON.parse(JSON.stringify(message));
      this.message.valid = true;
      this.slideKey = this.keys.messages;

      this.dialog.incompleteMessage = false;
      this.message.pageToRoute = null;
      this.message.messageToLoad = null;
      this.scrollToTop();

      // Then validate the form so it shows any bad values
      // Set a timeout so the form has aa moment to set itself up and then we validate
      setTimeout(() => {
        if (this.$refs.messageForm) this.$refs.messageForm.validate();
      }, 500);
    },
    deleteProgramMessage(message) {
      // We have to check if we're on the message slide and if we need to prompt them to save the message
      if (this.slideKey == this.keys.messages && this.isEditingMessage) {
        this.message.messageToLoad = JSON.parse(JSON.stringify(message));
        this.dialog.messageEditWarning = true;
        return;
      }
      console.log("Editing program message ", this.message);

      this.message = JSON.parse(JSON.stringify(message));
      this.slideKey = this.keys.messages;
    },
    clearProgramMessage() {
      if (this.$refs.messageForm) this.$refs.messageForm.reset();
      setTimeout(() => {
        this.message.messageBody = null;
        this.message.messageId = null;
        this.message.pageToRoute = null;
        this.message.messageToLoad = null;
        this.message.scheduleValue = null;
        this.message.scheduleOption = "AFTER_START";
        this.message.id = null;
      }, 100);
    },
    previewProgramMessage() {
      if (this.message.messageSubject && this.message.messageBody) {
        this.message.preview = formatEmailTemplate(
          emailTemplate,
          "programs/{{programId}}",
          "programs",
          "star",
          this.message.messageSubject,
          this.message.messageShortBody ? this.message.messageShortBody : "",
          this.message.messageBody,
          true
        );
        this.dialog.messagePreview = true;
      } else {
        this.message.preview = null;
      }
    },
    updateDefaultProgramMessageSchedule(programLength) {
      console.log("new program length = " + programLength);
      //we don't need to mess with default0 since it's value won't change (unless we have a program < 2 days...)

      //default1 gets set to halfway point
      // We need to compare to the original and see if anything has changed ONLY if the program is less than 30 days
      var default1 = this.program.messages.find(x => x.defaultIndex == 1);
      if (default1) {
        const schedule =
          programLength == null || programLength > 30
            ? 30
            : Math.floor(programLength / 2);
        if (default1.scheduleValue == this.preloadedMessage.scheduleValue)
          default1.scheduleValue = this.preloadedMessage.scheduleValue = schedule;
        if (
          default1.messageShortBody == this.preloadedMessage.messageShortBody
        ) {
          console.log("Rewriting shortbody");
          default1.messageShortBody = this.preloadedMessage.messageShortBody =
            schedule +
            " " +
            default1.messageShortBody.substring(
              default1.messageShortBody.indexOf("day")
            );
        }
        if (default1.messageBody == this.preloadedMessage.messageBody) {
          console.log("Rewriting body");
          default1.messageBody = this.preloadedMessage.messageBody = `${default1.messageBody.substring(
            0,
            default1.messageBody.indexOf("been") + 4
          )} ${schedule} ${
            schedule == 1 ? "day" : "days"
          } ${default1.messageBody.substring(
            default1.messageBody.indexOf("since")
          )}`;
        }
      }
      //default2 gets set to programLength day (first day of program)
      // var default2 = this.program.messages.find(x => x.defaultIndex == 2);
      // if (default2) {
      //   default2.scheduleValue = newVal;
      // }
    },
    deleteProgramElement() {
      if (this.dialog.elementDeleteContext != null) {
        if (this.dialog.elementDeleteContext == "award") {
          this.program.reward.amount = null;
          this.program.reward.awardDisplayName = null;
          this.program.reward.budgetId = null;
          // this.program.reward.displayName = this.program.reward.badgeDisplayName;
          this.program.reward.memo = null;
        } else if (this.dialog.elementDeleteContext == "badge") {
          this.program.reward.badgeId = null;
          this.program.reward.badgeDisplayName = null;
          // this.program.reward.displayName = this.program.reward.awardDisplayName;
          this.program.reward.icon = "mdi-trophy-variant-outline";
          this.program.reward.iconColor = "brand-dark-green";
        }
      } else if (this.dialog.messageDeleteIndex !== null) {
        console.log(
          "Deleting program message at index ",
          this.dialog.messageDeleteIndex
        );

        this.program.messages.splice(this.dialog.messageDeleteIndex, 1);
      } else if (this.dialog.resourceDeleteIndex !== null) {
        console.log(
          "Deleting program resource at index ",
          this.dialog.resourceDeleteIndex
        );

        this.program.resources.splice(this.dialog.resourceDeleteIndex, 1);
      } else {
        console.log(
          "Deleting program element at index ",
          this.dialog.elementDeleteIndex
        );

        this.program.elements.splice(this.dialog.elementDeleteIndex, 1);
      }

      this.dialog.elementDeleteConfirmation = false;
      this.dialog.elementDeleteIndex = null;
      this.dialog.resourceDeleteIndex = null;
      this.dialog.messageDeleteIndex = null;
    },
    formatProgramElements(type = null) {
      // This is used when preloading a program so we have their rule context Id's and then we independently fetch their learning and metrics
      // Cycles through given a context and id and grabs the actual data for that
      for (let i = 0; i < this.program.elements.length; i++) {
        const el = this.program.elements[i];
        console.log("format program elements type:", type);
        console.log(el);

        if (
          (!type || type == "MetricDefinitions") &&
          ((el.context && el.context.toLowerCase() == "metricdefinitions") ||
            el.metricId)
        ) {
          if (!el.id) el.id = el.programElementId;
          el.context = "MetricDefinitions";
          let metric = this.data.metrics.find(
            x => x.contextResourceId == el.metricId
          );

          if (metric) {
            el.displayName = metric.displayName;
            el.dataType = metric.dataType;
            el.clientId = metric.clientId;
          } else {
            el.dataType = "STRING";
          }
          el.contextColumn = "metricValue";
          el.icon =
            metric && metric.dataType == "BOOLEAN"
              ? "mdi-checkbox-marked-outline"
              : "mdi-finance";
          el.iconColor = "brand-cyan";
          el.displayGrabber = true;
        } else if (
          (!type || type == "LearningModules") &&
          ((el.context && el.context.toLowerCase() == "learningmodules") ||
            el.learningModuleId)
        ) {
          if (!el.id) el.id = el.programElementId;
          el.context = "LearningModules";
          console.log("Gotta find a learning module ", el);
          let learning = this.data.modules.find(
            x => x.contextResourceId == el.learningModuleId
          );
          var survey = null;
          if (!learning) {
            survey = this.data.surveys.find(
              x => x.contextResourceId == el.learningModuleId
            );
          }

          if (learning || survey) {
            // el.displayName = learning
            //   ? learning.displayName
            //   : survey.displayName;
            const name = learning ? learning.displayName : survey.displayName;
            this.$set(this.program.elements[i], "displayName", name);
            el.clientId = learning ? learning.clientId : survey.clientId;
            el.status = learning ? learning.status : survey.status;
            el.type = learning ? "Learning" : survey ? "Survey" : null;
          }
          // console.log(learning);
          // console.log(survey);
          el.contextColumn = "metricValue";
          el.icon = survey ? "mdi-comment-question-outline" : "mdi-school";
          el.iconColor = "brand";
          el.displayGrabber = true;
        }
      }
    },
    // Emitted by the GroupCreatorWidget, saves program and pushes to groups page
    async pushToGroups() {
      try {
        this.dialog.groupCreator = false;
        await this.createProgram(true);
        console.log("saved program");
        this.$router.push({ name: "groups" });
      } catch (error) {
        console.log("error saving program", error);
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    slideTitle() {
      let titles = {
        1: "Start with the basics of your program. A title, description, and image.",
        2: "Select the date you want your program to start and when you want it to end.",
        3: "Who is invited to join this program?",
        4: "Add the program elements - there are lots to choose from...",
        5: "Schedule any messages you want to send to remind people about your program.",
        100: "Double check the details of your program.",
        101: "You're almost done!"
      };
      return titles[this.slideKey];
    },
    formattedProgramDescription() {
      // Formats v-textarea with html tags when they enter into a new line
      if (!this.program.description) return null;
      let arr = [];
      if (this.program.description) {
        this.program.description
          .split("\n")
          .forEach(item => arr.push(`<p>${item.trim()}</p>`));
      }
      return arr.join("");
    },
    rollingProgramRules() {
      var rules = [
        this.program.rollingProgramLength ? true : "Program length is required",
        this.program.rollingProgramLength &&
        parseInt(this.program.rollingProgramLength) > 0 &&
        parseInt(this.program.rollingProgramLength) < 1000
          ? true
          : `Program length should be between 1 and 999`
      ];

      return rules;
    },
    endDateRules() {
      if (
        !this.program.startDate ||
        this.program.scheduleSelection != "end" ||
        !this.program.endDate
      )
        return true;

      // Validation above does check to make sure start and dates are defined. Now we make sure end date is after start date
      if (!moment(this.program.endDate).isValid())
        return "The end date must be a valid date";
      else if (
        moment(this.program.startDate).isSameOrAfter(
          moment(this.program.endDate)
        )
      )
        return "The end date must be after the start date";

      return true;
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    peopleInSelectedGroups() {
      // We have to get distinct users in these groups
      if (this.loading.groups) return 0;
      if (
        this.program.audience.find(x => x && x.groupId == 0) &&
        this.data.userCount !== null
      )
        return this.data.userCount;

      // var users = [];
      let sum = 0;
      for (var i = 0; i < this.program.audience.length; i++) {
        // users = users.concat(
        // (this.program.audience[i].GroupAssignments || []).map(x => x.userId)
        // );
        sum += this.program.audience[i].numUsers || 0;
      }
      // return [...new Set(users)].length;
      return sum;
    },
    allProgramElements() {
      // console.log(this.program.elements);
      // console.log(this.program.resources);
      // console.log(this.program.reward);
      var arr = JSON.parse(JSON.stringify(this.program.elements));
      // Add the reward and resources
      arr = arr.concat(this.program.resources);
      if (this.validProgramReward) arr.push(this.program.reward);

      return arr;
    },
    selectedBudget() {
      // find index that has a budget id and return budget
      if (this.program.reward && this.program.reward.budgetId)
        return this.data.budgets.find(
          x => x.budgetId == this.program.reward.budgetId
        );
      return null;
    },
    route: {
      cache: false,
      get: function() {
        // We establish the route depending on what payment type
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        // if (this.activeProgram && this.slideKey != this.keys.dates)
        //   // If it's an active program, then we skip over the date slide
        //   return [
        //     this.keys.basics,
        //     this.keys.audience,
        //     this.keys.elements,
        //     this.keys.messages,
        //     this.keys.review,
        //     this.keys.publishing,
        //     this.keys.sent
        //   ];
        return [
          this.keys.basics,
          this.keys.dates,
          this.keys.audience,
          this.keys.elements,
          this.keys.messages,
          this.keys.review,
          this.keys.publishing,
          this.keys.sent
        ];
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (
            page == this.keys.basics &&
            this.program.name &&
            this.program.description &&
            this.program.imageURL
          ) {
            array.push(true);
          } else if (
            page == this.keys.dates &&
            this.program.startDate &&
            this.endDateRules === true &&
            (this.program.scheduleSelection == "no_end" ||
              (this.program.endDate &&
                this.program.scheduleSelection == "end") ||
              (this.program.scheduleSelection == "rolling" &&
                this.program.rollingProgramLength &&
                this.rollingProgramRules.filter(x => x !== true).length == 0))
          ) {
            array.push(true);
          } else if (
            page == this.keys.audience &&
            this.program.audience.length > 0
            // &&
            // this.peopleInSelectedGroups > 0
          ) {
            array.push(true);
          } else if (
            page == this.keys.elements &&
            this.program.elements.length > 0 &&
            this.program.reward && // just look at index of 0 to make sure there's at least one
            this.validProgramReward
          ) {
            array.push(true);
          } else if (page == this.keys.messages) {
            array.push(true);
          } else if (
            // if they somehow skipped ahead, we want to block them if no elements, no reward, no name, or no groups
            page == this.keys.review &&
            this.program.elements.length > 0 &&
            this.program.reward &&
            this.validProgramReward &&
            this.program.audience.length > 0 &&
            this.endDateRules === true &&
            // this.peopleInSelectedGroups > 0 &&
            this.program.name &&
            this.program.description &&
            this.program.imageURL
          ) {
            array.push(true);
          } else if (page == this.keys.publishing) {
            array.push(false);
          } else if (page == this.keys.sent) {
            // console.log("sent");
            array.push(true);
          } else {
            // console.log("ELSE");
            array.push(false);
          }
          // console.log(array[array.length - 1]);
        });

        // console.log(array);
        return array;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isEditingMessage() {
      // Used so we know when to prompt them to save a message
      return (
        // !this.activeProgram &&
        this.message.scheduleValue ||
        this.message.scheduleValue == 0 ||
        this.message.messageSubject ||
        this.message.messageShortBody ||
        this.message.messageBody
      );
    },
    hasIncompleteMessages() {
      return (
        this.program.messages.filter(
          x =>
            // !this.activeProgram &&
            (!x.scheduleValue && x.scheduleValue != 0) ||
            !x.scheduleOption ||
            (this.program.scheduleSelection == "no_end" &&
              x.scheduleOption == "BEFORE_END") ||
            !x.messageSubject ||
            !x.messageBody
        ).length > 0
      );
    },
    messageScheduleOptions() {
      return this.data.messageScheduleOptions.filter(
        x =>
          x.value != "BEFORE_END" || this.program.scheduleSelection !== "no_end"
      );
    },
    formattedStartDate() {
      if (!this.program.startDate || !moment(this.program.startDate).isValid())
        return null;
      return moment(this.program.startDate).format("MMM Do, YYYY @ h:mm A");
    },
    formattedEndDate() {
      if (!this.program.endDate || !moment(this.program.endDate).isValid())
        return null;
      return moment(this.program.endDate).format("MMM Do, YYYY @ h:mm A");
    },
    programLength() {
      if (
        !this.program.startDate ||
        !(this.program.endDate || this.program.rollingProgramLength)
      )
        return 0;
      // Only return null if the program has no end date
      if (this.program.startDate && this.program.scheduleSelection == "no_end")
        return null;
      if (
        this.program.rollingProgramLength &&
        this.program.scheduleSelection == "rolling"
      )
        return Number(this.program.rollingProgramLength);
      return (
        moment(this.program.endDate).diff(
          moment(this.program.startDate),
          "days"
        ) + 1
      );
    },

    totalBeingSpent() {
      if (!this.program.reward || !this.program.reward.amount) return 0; // look at index of reward that has budgetid
      if (!this.peopleInSelectedGroups) var numPeople = 0;
      else numPeople = this.peopleInSelectedGroups;

      return currency(this.program.reward.amount).multiply(numPeople).value;
    },

    activeProgram() {
      return (
        this.preloadedProgram &&
        this.preloadedProgram.status == "Active" &&
        !this.duplicatingProgram
      );
    },
    archivedProgram() {
      return (
        this.preloadedProgram &&
        this.preloadedProgram.status == "Archived" &&
        !this.duplicatingProgram
      );
    },
    editingProgram() {
      // return this.preloadedProgram && !this.editingProgram;
      console.log(this.$route.query.duplicate);
      return !!(this.preloadedProgram && !this.duplicatingProgram);
    },
    duplicatingProgram() {
      // return this.preloadedProgram && !this.editingProgram;
      console.log(this.$route.query.duplicate);
      return !!(
        this.preloadedProgram &&
        this.$route.query.duplicate &&
        this.$route.query.duplicate.toString().toLowerCase() == "true"
      );
    },
    hasEditedProgram() {
      return (
        this.program.name ||
        this.program.description ||
        this.program.imageURL ||
        this.program.image ||
        this.program.elements.length ||
        this.program.resources.length ||
        this.program.audience.length ||
        this.editingProgram
      );
    },

    validProgramReward: {
      cache: false,
      get: function() {
        // This is so we can consolidate the displayName logic and all that with preloading new rewards and creating new rewards.
        var reward = this.program.reward;

        if (reward.badgeId || (reward.budgetId && reward.amount)) {
          return true;
        } else {
          return false;
        }
      }
    },

    // Computed properties to construct the rules
    // payoutRule() {
    //   if (!this.program.reward || !this.validProgramReward) return null;
    //   // We don't support pay per payout rules in the wizard
    //   return {
    //     type: "HIT_AND_WIN",
    //     amount: this.program.reward.amount,
    //     badgeId: this.program.reward.badgeId,
    //     memo:
    //       this.program.reward.memo && this.program.reward.memo.trim() != ""
    //         ? this.program.reward.memo
    //         : null
    //   };
    // },
    programRules() {
      return this.program.elements.map(el => {
        return {
          metricId:
            el.context == "MetricDefinitions" ? el.metricId || el.id : null,
          learningModuleId:
            el.context == "LearningModules"
              ? el.learningModuleId || el.id
              : null,
          // context: el.context,
          // contextColumn: el.contextColumn,
          // contextResourceId: el.contextResourceId
          //   ? el.contextResourceId
          //   : el.id,
          operator:
            el.context == "LearningModules"
              ? "GREATER_THAN_EQUAL"
              : el.operator
              ? el.operator
              : el.defaultOperator,
          value: el.context == "LearningModules" ? "100" : el.value,
          status: el.status == "Disabled" ? "Disabled" : "Active"
        };
      });
      // return {
      //   name: "Program Elements",
      //   description:
      //     "Program elements group containing " +
      //     this.program.elements.length +
      //     (this.program.elements.length == 1 ? " item." : " items."),
      //   rule: false,
      //   primaryRuleGroup: true,
      //   operator: "AND",
      //   id: 1,
      //   depth: 1,
      //   lastInGroup: true,
      //   group: this.program.elements.map((el, i) => {
      //     return {
      //       rule: true,
      //       id: Date.now() * Math.random(),
      //       // selectedContext: el.context,
      //       context: el.context,
      //       contextColumn: el.contextColumn,
      //       contextResourceId: el.contextResourceId
      //         ? el.contextResourceId
      //         : el.id,
      //       operator:
      //         el.context == "LearningModules"
      //           ? "GREATER_THAN_EQUAL"
      //           : el.operator
      //           ? el.operator
      //           : el.defaultOperator,
      //       value: el.context == "LearningModules" ? "100" : el.value,
      //       lastInGroup: i == this.program.elements.length - 1,
      //       status: el.status == "Disabled" ? "Disabled" : "Active"
      //     };
      //   })
      // };
    },
    editedResources() {
      // This will compare the OG resrouces to the program's resources
      // If they're not the same, return true and they can save the program
      // If length doesn't match - return true
      // Else, sort, loop and compare
      let verdict = false;
      if (
        this.program.originalResources.length != this.program.resources.length
      ) {
        verdict = true;
      } else {
        const og = [...this.program.originalResources];
        const sortedOG = og.sort((a, b) => {
          return a.contentId - b.contentId;
        });
        const programResouces = [...this.program.resources];
        const sortedProgramResouces = programResouces.sort((a, b) => {
          return a.contentId - b.contentId;
        });
        console.log(sortedOG);
        console.log(sortedProgramResouces);
        for (let i = 0; i < programResouces.length; i++) {
          if (sortedOG[i].contentId != sortedProgramResouces[i].contentId) {
            verdict = true;
          }
        }
      }
      return verdict;
    },
    metricsInProgram() {
      return this.program.elements.filter(
        x => x.context == "MetricDefinitions"
      );
    }
  },
  watch: {
    // budgets: function(newVal, oldVal) {
    //   if (oldVal.length == 0) {
    //     console.log("Got budget watcher", oldVal);
    //     // Preload the master budget
    //     var acctBalance = this.budgets.find(
    //       (x) => x.masterBudget && x.clientId == this.clientId
    //     );
    //     if (acctBalance) {
    //       //  console.log("Found acct balance ", acctBalance);
    //       this.slideAmount.budgetId = acctBalance.budgetId;
    //     }
    //   }
    // },
    "dialog.elementDeleteConfirmation": function(newVal) {
      if (newVal === false) {
        console.log("Watcher for delete box triggered");
        // They closed the delete confirmation so we have to clear the variables so something else doesn't get accidentally deleted
        this.dialog.elementDeleteIndex = null;
        this.dialog.resourceDeleteIndex = null;
        this.dialog.messageDeleteIndex = null;
      }
    },
    programLength: {
      handler: function(newVal) {
        this.updateDefaultProgramMessageSchedule(newVal);
      },
      immediate: true
    },
    "groupTable.debounceSearch": debounce(function(newVal) {
      this.search.audience = newVal;
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.header-text {
  font-size: x-large;
}

.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

/* Light grey used when hovering over program elements */
.brand-gradient-background {
  background-color: #eef0f8;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Adds grey background to search box */
.search-field >>> .v-input__slot {
  /* border-style: none !important;
  border-radius: 2px !important; */
  background: var(--v-lightGrey-base) !important;
}

.amount-text-field >>> .v-input__slot {
  max-width: 125px;
  width: 125px;
}

.amount-text-field >>> .v-text-field__details {
  padding-left: 4px;
}

.amount-table {
  padding-top: 1px;
  border: solid 1px;
  border-color: lightgray;
  border-radius: 4px;
}

.amount-table >>> .v-data-table-header {
  background-color: white;
}

#note {
  background: var(--v-lightGrey-base);
  padding: 10px 10px;
  margin-left: 24px;
  width: calc(100% - 24px);
  border-radius: 6px;
}

/* Classes used for elements in left side nav */
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
.side-nav-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
  padding-top: 7px;
}
.side-nav-bold-text {
  color: var(--v-brand-base);
  font-weight: bold;
}

/* CSS for inner program element boxes because they have grabber icons */
.program-elements-box {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  /* height: 35px; */
  padding-top: 7px;
}
.program-elements-height {
  height: 35px;
}

/* Used to cap the text for the display names for elements if they're too long */
.program-element-label {
  width: calc(100%);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Id for icon to expand the list of program elements in the left bar */
#program-element-expander {
  width: 22px;
  height: 22px;
}

/* Used for dialog popup boxes that  */
.dialog {
  z-index: 999;
}

/* Used to extend the width of the form validation for rolling program length because the field is so short */
.rolling-program-length >>> .v-text-field__details {
  min-width: 200px;
  padding-left: 4px;
}

/* Used to cap the height of the main content card so it scrolls after */
.main-content-card {
  /* overflow-y: scroll;
  min-height: 500px;
  max-height: 80vh; */
}
</style>

<template>
  <div>
    <v-card-title>
      <v-row>
        <v-col
          class="d-flex flex-column align-start ml-2"
          sm="6"
          md="6"
          :class="{ hidden: currentSlide > 1 }"
        >
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1 ml-1"
            @click="previousSlide"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title>
    <transition :name="slideDirection" mode="out-in">
      <v-row
        v-if="currentSlide == 0"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Let's get some transaction details</h2>

        <v-form ref="form" v-model="validAddressForm" class="full-width px-4">
          <v-radio-group v-model="type">
            <v-radio
              label="Authorization"
              value="authorization"
              class="mb-4"
            ></v-radio>
            <v-radio label="Clearing" value="clearing"></v-radio>
          </v-radio-group>
          <v-text-field label="Merchant Mid" v-model="mid" type="number" />

          <v-select
            v-if="type == 'authorization'"
            label="Card"
            :items="filteredCards"
            v-model="cardToken"
            item-value="card_token"
            item-text="type"
          />
          <v-select
            v-if="type == 'clearing'"
            label="Authorized Transactions"
            :items="authorizations"
            v-model="originalToken"
            item-value="cardActivityToken"
            item-text="name"
          />
          <v-text-field
            label="Amount"
            v-model="amount"
            v-if="type == 'authorization'"
            type="number"
          />
        </v-form>
      </v-row>
      <v-row
        v-else-if="currentSlide == 1"
        key="0"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Now we need some merchant information</h2>
        <v-form ref="form" v-model="validMerchantForm" class="full-width px-4">
          <v-text-field label="Merchant Name" v-model="name" class="mt-4" />
          <v-text-field
            ref="address"
            label="Address"
            v-model="address"
            class="mt-4"
          />

          <v-text-field label="City" v-model="city" class="mt-4" />
          <v-select
            label="State"
            :items="states"
            v-model="state"
            item-value="abbreviation"
            item-text="name"
          />
          <v-text-field label="Zip Code" v-model="postalCode" class="mt-4" />
        </v-form>
      </v-row>

      <v-row
        v-else-if="currentSlide == 2"
        key="3"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Processing...</h2>
        <caption class="grey--text mt-2">
          This should only take a few seconds.
        </caption>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == 3"
        key="4"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>ALL DONE!</h2>
        <caption class="grey--text mt-2">
          The simulation was successfully sent. Wait a few seconds and refresh
          the Wallet page to see your updated transactions.
        </caption>
        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="backflip"
          :loop="false"
        />
      </v-row>
    </transition>
    <v-row
      class="mb-10"
      align="center"
      justify="center"
      v-if="currentSlide != 2"
    >
      <v-col>
        <v-btn
          outlined
          rounded
          :disabled="
            (currentSlide == 1 && !validMerchantForm) ||
              (currentSlide == 0 &&
                (!type ||
                  (type == 'authorization' && (!cardToken || !amount)) ||
                  (type == 'clearing' && !originalToken) ||
                  !mid))
          "
          class="py-5"
          @click="nextSlide"
          ><v-icon class="mr-1" color="brandGreen"
            >mdi-arrow-right-drop-circle</v-icon
          ><span class="mt-1">{{
            currentSlide != 3 ? "Next" : "Done"
          }}</span></v-btn
        ></v-col
      >
    </v-row>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import Robin from "@/components/Robin";
import { states, countries, countryCodes } from "@/shared_data/data.js";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "MarqetaSimulateTransaction",
  props: {},
  components: {
    Robin,
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      validMerchantForm: false,
      validAddressForm: false,
      validIdForm: false,
      states: states,
      countries: countries,
      countryCodes: countryCodes,

      cardToken: null,
      originalToken: null,
      type: null,
      mid: null,
      amount: null,
      name: null,
      address: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
      otherCountry: null,
    };
  },
  created() {
    console.log("Created");
    // this.getMarqetaUser();
  },
  beforeDestroy() {},
  methods: {
    resetForm() {
      this.name = null;
      this.address = null;
      this.city = null;
      this.state = null;
      this.country = null;
      this.otherCountry = null;
      this.postalCode = null;
      this.type = null;
      this.amount = null;
      this.mid = null;
      this.cardToken = null;
      this.originalToken = null;
      if (this.currentSlide <= 1) {
        this.$refs.form.reset();
      }

      this.$emit("get-cards");

      this.$emit("scrollToTop");
      this.$emit("reset");
      this.currentSlide = 0;

      // We wait 3 seconds to hope that the webhook is done.
      setTimeout(() => {
        this.$store.dispatch("setUser", this.userProfile.userId);
      }, 5000);
    },
    previousSlide() {
      this.$emit("scrollToTop");
      if (this.currentSlide == 0) {
        this.resetForm();
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      this.$emit("scrollToTop");
      this.currentSlide += 1;
      if (
        this.currentSlide == 2 ||
        (this.currentSlide == 1 && this.type == "clearing")
      ) {
        // We are submitting api call
        this.currentSlide = 2;
        this.submitTransaction();
      } else if (this.currentSlide == 4) {
        this.resetForm();
      }
    },
    submitTransaction() {
      var startTime = new Date();
      if (this.type == "clearing") {
        var tx = this.userProfile.CardActivities.find(
          (x) => x.cardActivityToken == this.originalToken
        );
        var body = {
          mid: this.mid,
          amount: tx ? tx.amount : null,
          force_post: true,
          original_transaction_token: this.originalToken,
          card_acceptor: {
            name: tx ? tx.merchantName : null,
            address: tx ? tx.merchantAddress : null,
            city: tx ? tx.merchantCity : null,
            state: tx ? tx.merchantState : null,
            zip: tx ? tx.merchantZip : null,
            country: tx ? tx.merchantCountry : null,
          },
        };
      } else {
        body = {
          mid: this.mid,
          amount: this.amount,
          card_token: this.cardToken,
          card_acceptor: {
            name: this.name,
            address: this.address,
            city: this.city,
            state: this.state,
            zip: this.postalCode,
            country: this.country != "00" ? this.country : this.otherCountry,
          },
        };
      }

      console.log(body);
      MarqetaService.simulateTransaction(this.type, body)
        .then((response) => {
          console.log("Transaction response: ", response);

          // We check the time elapsed since sending the request. If the user has been waiting less than 1.5 seconds, we show the animation so it doesn't flash on the screen
          var timeDiff = moment().diff(moment(startTime));
          if (response.error_code && response.error_code == 1190022) {
            this.dialogEmailError = true;
          } else {
            if (timeDiff < 1500) {
              setTimeout(() => {
                this.currentSlide++;
              }, 1500 - timeDiff);
            } else {
              this.currentSlide++;
            }
          }
        })
        .catch((error) => {
          console.log("There was an error submitting card request: ", error);
        });
    },
  },
  computed: {
    ...mapState([
      "userProfile",
      "displayWalletMenu",
      "walletMenuScreen",
      "marqeta",
    ]),
    authorizations() {
      var auth = this.userProfile.CardActivities.filter(
        (x) => x.type == "authorization"
      );
      auth.forEach((a) => {
        a.name = a.merchantName + " - " + a.amount;
      });
      auth.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1));
      // auth = [];
      return auth;
    },
    filteredCards() {
      return this.marqeta.cards.filter((x) => x.state != "UNACTIVATED");
    },
  },
  watch: {
    displayWalletMenu(val) {
      console.log(val);
      if (val && this.walletMenuScreen == "update-user") {
        this.getMarqetaUser();
      }
    },
  },
};
</script>

<style scoped>
.card-text {
  font-size: 1.2em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}
</style>

<template>
  <div>
    <v-card-title>
      <v-row>
        <v-col
          class="d-flex flex-column align-start ml-2"
          cols="6"
          sm="6"
          md="6"
          v-if="!token"
        >
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1"
            @click="resetForm"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title>
    <h1 v-if="!loading" class="mb-5">Card Activation</h1>
    <h1 v-else>Loading the Card Activation...</h1>
    <span v-if="loading" class="grey--text mt-2">
      This should only take a few seconds.
    </span>
    <Robin
      v-if="loading"
      :showText="false"
      :width="140"
      :height="140"
      animation="babyDance"
      :loop="true"
      class="d-flex justify-center"
    />
    <!-- <iframe
      v-else
      :src="url"
      :application_id="application_id"
      :one_time_token="one_time_token"
      :user_token="user_token"
    /> -->
    <iframe
      v-else
      :src="iframeUrl"
      width="425"
      height="325"
      frameBorder="0"
      class="border mb-6"
    ></iframe>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import Robin from "@/components/Robin";

import { mapState } from "vuex";

export default {
  name: "MarqetaActivateCard",
  props: { token: String },
  components: {
    Robin
  },
  data() {
    return {
      loading: true,
      url: null,
      application_id: null,
      one_time_token: null,
      user_token: null
    };
  },
  created() {
    this.getWidgetData();
  },
  beforeDestroy() {},
  methods: {
    getWidgetData() {
      MarqetaService.getCardActivationWidget(
        this.token,
        this.magicLinkToken
      ).then(x => {
        console.log("Marqeta card activation response: ", x);
        this.url = x.url;
        this.application_id = x.application_id;
        this.user_token = x.user_token;
        this.one_time_token = x.one_time_token;
        this.loading = false;
      });
    },
    resetForm() {
      this.$emit("scrollToTop");
      this.$emit("reset");
      this.$emit("get-cards");
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "walletMenuScreen",
      "displayWalletMenu",
      "magicLinkToken"
    ]),
    iframeUrl() {
      console.log("Rerunning");

      if (
        this.url != null &&
        this.application_id != null &&
        this.user_token != null &&
        this.one_time_token != null
      ) {
        var url = `${this.url}?one_time_token=${
          this.one_time_token
        }&user_token=${this.user_token}&application_id=${
          this.application_id
        }&success_url=https://${
          process.env.VUE_APP_ENVIRONMENT == "dev"
            ? "dev"
            : process.env.VUE_APP_ENVIRONMENT == "test"
            ? "test"
            : "app"
        }.wewhistle.com/wallet/activate/success&display_headers=false`;
        console.log("URL :", url);
        return url;
      }
      return null;
    }
  },
  watch: {
    displayWalletMenu(val) {
      if (val && this.walletMenuScreen == "card-activation") {
        this.getWidgetData();
      }
    }
  }
};
</script>

<style scoped>
.card-text {
  font-size: 1.2em;
}
.border {
  border: 1px solid;
  border-color: black;
}
</style>

import { render, staticRenderFns } from "./HelpWidget.vue?vue&type=template&id=fe4774ca&scoped=true"
import script from "./HelpWidget.vue?vue&type=script&lang=js"
export * from "./HelpWidget.vue?vue&type=script&lang=js"
import style0 from "./HelpWidget.vue?vue&type=style&index=0&id=fe4774ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe4774ca",
  null
  
)

export default component.exports
<template>
  <div>
    <div id="stripe-script"></div>
    <div id="stripe-script1"></div>
    <div id="stripe-token-script"></div>
    <transition :name="slideDirection" mode="out-in">
      <v-form
        v-if="currentSlide == 0"
        ref="form"
        v-model="validForm"
        class="full-width px-4"
      >
        <div class="d-flex">
          <span class="pt-6">$0.</span>
          <v-text-field
            ref="amount2"
            label="Verification Amount 1"
            :placeholder="displayDevPlaceholderAmount ? '32' : '00'"
            v-model="amount1"
            type="number"
            color="brandCyan"
            class="mt-2"
            :rules="[
              v => !!v || 'Amount is required',
              v =>
                !!(v && !v.includes('.')) ||
                'Amount should not contain a decimal',
              v => !!(v && parseFloat(v) >= 1) || 'Amount should be positive',
              v =>
                !!(v && v.length < 3) ||
                `Amount shouldn't be longer than 2 decimals.`
            ]"
          />
        </div>
        <div class="d-flex">
          <span class="pt-6">$0.</span>
          <v-text-field
            ref="amount2"
            label="Verification Amount 2"
            :placeholder="displayDevPlaceholderAmount ? '45' : '00'"
            v-model="amount2"
            type="number"
            color="brandCyan"
            class="mt-2"
            :rules="[
              v => !!v || 'Amount is required',
              v =>
                !!(v && !v.includes('.')) ||
                'Amount should not contain a decimal',
              v => !!(v && parseFloat(v) >= 1) || 'Amount should be positive',
              v =>
                !!(v && v.length < 3) ||
                `Amount shouldn't be longer than 2 decimals.`
            ]"
          />
        </div>

        <v-btn
          color="brandCyan"
          class="mb-8 white--text"
          depressed
          width="140"
          :disabled="!validForm"
          @click="verifyBankAccount"
          >Submit</v-btn
        >
      </v-form>
      <v-row
        v-else-if="currentSlide == 1"
        key="1"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Processing...</h2>
        <caption class="grey--text mt-2">
          This should only take a few seconds.
        </caption>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == 2"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>All done!</h2>
        <caption class="grey--text mt-2">
          Your Bank account has been verified!
        </caption>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="backflip"
          :loop="false"
        />

        <v-btn
          color="brandCyan"
          class="mt-4 white--text"
          depressed
          width="140"
          @click="resetForm"
          >Done</v-btn
        >
      </v-row>
      <v-row
        v-else-if="currentSlide == 3"
        key="8"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
      >
        <v-card-title class="title-text word-break full-width">{{
          errorMessage
            ? errorMessage
            : "There was an error verifying your bank account"
        }}</v-card-title>

        <p class="pl-4 full-width align-text-left">
          If you continue to have trouble, feel free to reach out to customer
          service via one of the following methods...
        </p>
        <p class="pl-4 align-text-left full-width">
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p class="pl-4 align-text-left full-width">
          Phone (Toll Free) - <b>(855) 264-3329</b>
        </p>
        <div class="d-flex mt-4">
          <v-btn
            color="brandCyan"
            class="mr-4 white--text"
            width="150"
            depressed
            @click="(currentSlide = 0), (errorMessage = null)"
            >Update Details</v-btn
          >
          <v-btn
            color="brandCyan"
            class="white--text"
            width="150"
            depressed
            @click="resetForm"
            >Done</v-btn
          >
        </div>
      </v-row>
    </transition>
  </div>
</template>

<script>
import PaymentService from "@/services/PaymentService";
// import { countries, countryCodes } from "@/shared_data/data.js";
import Robin from "@/components/Robin";

// import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "StripeVerifyBank",
  props: {
    bankId: String,
    customerId: String
  },
  components: {
    Robin
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      validForm: null,
      amount1: null,
      amount2: null,
      authorization: false,
      errorMessage: null
    };
  },
  created() {
    console.log("Created stripe bank widget");
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    verifyBankAccount() {
      this.currentSlide++;
      console.log("Creating bank source");
      PaymentService.verifyStripeBankAccount(
        this.bankId,
        this.customerId,
        [this.amount1, this.amount2],
        this.magicLinkToken
      ).then(resp => {
        console.log("Got bank resp: ", resp);
        if (resp.error) {
          this.currentSlide += 2;
          console.log(resp.error.raw);
          console.log(resp.error.raw.message);
          if (resp.error.raw.message.startsWith("The amounts provided"))
            this.errorMessage =
              "The amounts provided do not match what were sent to the account";
          else if (resp.error.code == "bank_account_verification_failed")
            this.errorMessage = "The account verification failed";
        } else {
          this.currentSlide++;
        }
      });
    },
    onSuccess(public_token, metadata) {
      console.log("Success: ", public_token);
      PaymentService.sendPlaidPublicToken(public_token).then(publicRes => {
        console.log("Got public access token response: ", publicRes);
        console.log("Public Token: " + public_token);
        switch (metadata.accounts.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            console.log(
              "Customer-selected account ID: " + metadata.accounts[0].id
            );
            break;
          default:
            // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
            break;
        }
      });
    },
    resetForm() {
      this.$emit("scrollToTop");
      this.$emit("reset");
      // this.$emit("get-cards");
    },
    previousSlide() {
      // this.$emit("scrollToTop");
      this.resetForm();
    }
  },
  computed: {
    ...mapState(["userProfile", "magicLinkToken"]),
    displayDevPlaceholderAmount() {
      if (process.env.VUE_APP_ENVIRONMENT !== "prod") {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped id="styles">
/* Code for transitions between slides */

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}
</style>

<template>
  <v-card>
    <div class="d-flex justify-end pt-4 pb-3 pr-4">
      <v-btn icon large @click="closeWindow"><v-icon>mdi-close</v-icon></v-btn>
    </div>
    <v-card-text class="align-text-center">
      <v-img
        src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
        max-width="250"
        class="mx-auto"
      />
      <h2 class="my-6">
        <b v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          >Looks like you're on mobile</b
        ><b v-else>Looking for more Whistle?</b>
      </h2>

      <h3>
        Download the Whistle Systems mobile app to access everything on the go!
      </h3>
      <h4 class="my-4">Available on both Android and iOS</h4>
      <v-img
        src="@/assets/AppStoreBadges/app-store-badge.svg"
        max-width="175"
        @click="goToAppStore"
        class="cursor-pointer mx-auto"
      />
      <v-img
        src="@/assets/AppStoreBadges/google-play-badge.png"
        max-width="200"
        @click="goToGooglePlay"
        class="cursor-pointer mx-auto"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppStorePopup",
  components: {},
  props: {},
  data() {
    return {
      newProgramForm: {
        menuStartDate: false,
        menuEndDate: false,
        valid: false,
        forceValidForm: false,
        editing: false,
      },
      file: undefined,
    };
  },
  mounted() {},
  methods: {
    closeWindow() {
      this.$emit("close");
    },
    goToAppStore() {
      window.open(process.env.VUE_APP_APPLE_STORE_URL, "_blank");
      this.closeWindow();
    },
    goToGooglePlay() {
      window.open(process.env.VUE_APP_GOOGLE_STORE_URL, "_blank");
      this.closeWindow();
    },
  },
  computed: {
    ...mapState(["postItArray", "userProfile"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

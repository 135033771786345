<template>
  <v-card>
    <v-card-title class="dialog-header" color="primary">
      Upload Program Metrics
    </v-card-title>

    <v-divider />
    <v-container>
      <v-card-text>
        <v-form ref="newProgramForm" v-model="newProgramForm.valid">
          <v-autocomplete
            v-if="userProfile.clientId == 1"
            class="pa-1"
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client*"
            v-model="clientId"
            @change="refreshClientId"
          ></v-autocomplete>
          <v-select
            class="pa-1"
            :items="visibleMetrics"
            item-text="metricName"
            item-value="metricId"
            label="Metric To Upload*"
            v-model="metricUploadMetricId"
            :rules="[v => !!v || 'Metric is required']"
          ></v-select>
          <!-- <v-select
                class="pa-1"
                :items="programs"
                item-text="displayName"
                item-value="programId"
                label="Program To Upload To (optional)"
                v-model="metricUploadProgramId"
              ></v-select> -->
          <v-chip
            v-ripple
            color="primary"
            label
            class="mr-4"
            v-if="metricUploadMetricId"
            @click="downloadTemplateFile"
          >
            <v-avatar>
              <v-icon class="mr-1">mdi-download</v-icon>
            </v-avatar>
            Download Template
          </v-chip>
          <v-file-input
            label="Select your CSV"
            class="fileInput"
            accept=".csv"
            v-model="file"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="clearMetricsFileUploadForm">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!file || !newProgramForm.valid"
          @click="uploadMetricsFile"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-container>

    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import moment from "moment";
import momentTz from "moment-timezone";
import ProgramService from "../services/ProgramService.js";
import LoadingDialog from "../components/loadingDialog.vue";

import { mapState } from "vuex";

export default {
  name: "MetricUpload",
  components: {
    LoadingDialog
  },
  props: {
    metrics: Array
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      // clientId: null,
      metricUploadMetricId: null,
      newProgramForm: {
        menuStartDate: false,
        menuEndDate: false,
        valid: false,
        forceValidForm: false,
        editing: false
      },
      file: undefined
    };
  },
  mounted() {
    this.getMetrics();
    // this.clientId = this.userProfile.clientId;
  },
  methods: {
    getMetrics() {
      if (this.metrics !== null) {
        var metricsPruned = [];
        for (var i = 0; i < this.metrics.length; i++) {
          var metric = this.metrics[i];
          if (metric.status === "Active") {
            metric.uploadType =
              metric.uploadType.charAt(0).toUpperCase() +
              metric.uploadType.slice(1).toLowerCase();
            metric.formattedCreateDate = moment(metric.createDate)
              .local()
              .format("MMMM Do YYYY, h:mm:ss a");
            metricsPruned.push(metric);
          }
        }
        this.metricsCardFormat = metricsPruned;
      }
    },
    loadNewMetricForm() {
      this.metricDialog = true;
    },
    async uploadMetricsFile() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Uploading File";
      this.loadingMessageLine1 = "Processing metrics";
      this.loadingMessageLine2 = "This could take a minute.";

      //We have to append timezone so that we can convert to utc to store in ProgramMetrics
      var timezone = momentTz.tz.guess();
      console.log("Timezone: ", timezone);

      await ProgramService.uploadMetricCSV(
        this.file,
        this.metricUploadMetricId,
        timezone,
        this.clientId
      )
        .then(response => {
          this.showLoadingDialog = false;
          console.log(response.data);
          this.fileSuccess = response.data.success;
          this.fileErrors = response.data.error;
          this.fileFailedRequirements = response.data.failedReq;
          this.showUploadResults = true;

          this.clearMetricsFileUploadForm();
          this.$emit("close");
        })
        .catch(() => {
          this.showLoadingDialog = false;
          console.log("Could not upload the file!");
        });
    },
    clearMetricsFileUploadForm() {
      // this.metricUploadMetricId = null;
      this.file = null;
      // this.clientId = null;
      // We temporarily store the clientId since we reset the form
      let clientId = this.clientId;
      this.$refs.newProgramForm.reset();
      this.clientId = clientId;

      this.$emit("close");
    },
    downloadTemplateFile() {
      ProgramService.downloadMetricTemplateFile(this.metricUploadMetricId);
    },
    refreshClientId() {
      this.$emit("update-client");
    }
  },
  computed: {
    ...mapState(["clients", "userProfile", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    visibleMetrics() {
      return this.metrics.filter(
        x => x.clientId == this.clientId || x.clientId == 0
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-image {
  margin: 0 0 0 12px;
}
</style>

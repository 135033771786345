<template functional>
  <div class="py-1 align-self-end" v-if="props.kind === 'sent'">
    <div
      class="blue white--text text-left overflow-wrap rounded-xxl body-1 pa-3"
    >
      {{ props.text }}
    </div>
  </div>
  <div class="py-1 align-self-start" v-else-if="props.kind === 'received'">
    <div
      style="background-color: #e6e6e6"
      class="black--text text-left overflow-wrap rounded-xxl body-1 pa-3"
    >
      {{ props.text }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    kind: String,
  },
};
</script>
<style scoped>
.overflow-wrap {
  overflow-wrap: anywhere;
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 flex-column d-flex"},[_c('v-card',{staticClass:"quiz-card",attrs:{"rounded":"0","width":"100%","elevation":"0"}},[_c('v-card-title',{staticClass:"word-break px-0 font-weight-bold brand--text"},[_vm._v(" "+_vm._s(_vm.question.content)+" ")]),_c('p',{staticClass:"grey--text mb-1 font-italic"},[_vm._v(" Choose a response from the answers below ")]),_c('v-divider'),_c('v-radio-group',{class:_vm.answerSubmitted ? 'radio-group-disabled' : '',model:{value:(_vm.checkedAnswerId),callback:function ($$v) {_vm.checkedAnswerId=$$v},expression:"checkedAnswerId"}},_vm._l((_vm.question.LearningQuizAnswers),function(answer){return _c('div',{key:answer.learningQuizAnswerId},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"answer-box d-flex align-center cursor-pointer my-1",class:{
              'light-grey-background': hover && !_vm.answerSubmitted,
              'light-red-background':
                _vm.answerSubmitted &&
                answer.correctness <= 0 &&
                answer.learningQuizAnswerId == _vm.checkedAnswerId,
              'brand-pale-green-background':
                _vm.answerSubmitted && answer.correctness > 0,
            },on:{"click":function($event){_vm.checkedAnswerId = answer.learningQuizAnswerId}}},[(_vm.answerSubmitted && answer.correctness > 0)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{staticClass:"correct-icon",attrs:{"color":"brandGreen"}},[_vm._v("mdi-check-circle")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(answer.content))])],1):_c('v-radio',{attrs:{"label":answer.content,"value":answer.learningQuizAnswerId,"color":_vm.answerSubmitted ? 'grey' : 'brandCyan',"readonly":_vm.answerSubmitted,"disabled":_vm.answerSubmitted}})],1)]}}],null,true)})],1)}),0),_c('v-divider'),_c('div',{staticClass:"d-flex justify-center mt-10"},[(_vm.answerSubmitted == false)?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":"","disabled":_vm.checkedAnswerId == null},on:{"click":_vm.checkQuestion}},[_vm._v(" "+_vm._s(_vm.computedSubmitButton)+" ")]):_vm._e(),(_vm.questionIndex !== _vm.questionCount - 1 && _vm.answerSubmitted == true)?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":""},on:{"click":_vm.goToNextQuestion}},[_vm._v(" Next ")]):(
          _vm.questionIndex == _vm.questionCount - 1 &&
            _vm.answerSubmitted == true &&
            !_vm.isSurveyQuestion
        )?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":""},on:{"click":_vm.completeQuiz}},[_vm._v(" Complete ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
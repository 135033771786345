<template>
  <div class="d-flex justify-space-between align-center mx-1 my-2">
    <v-menu
      offset-y
      offset-x
      :top="top"
      :bottom="bottom"
      :left="left"
      :right="right"
      absolute
      :close-on-content-click="false"
      v-model="showMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :icon="!placeholderEmoji"
          :text="!!placeholderEmoji"
          :small="!!placeholderEmoji"
          :width="buttonWidth"
          :color="buttonColor"
          v-on="on"
          v-bind="attrs"
          ><span v-if="placeholderEmoji" style="font-size: 40px;">{{
            placeholderEmoji
          }}</span>
          <v-icon v-else>{{ placeholderIcon }}</v-icon>
        </v-btn>
      </template>
      <v-card v-if="showMenu">
        <Picker
          :data="emojiIndex"
          :native="true"
          :showSearch="true"
          :showPreview="false"
          @select="selectEmoji"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import data from "emoji-mart-vue-fast/data/all.json";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import "emoji-mart-vue-fast/css/emoji-mart.css";
// Create emoji data index.
// We can change it (for example, filter by category) before passing to the component.
let emojiIndex = new EmojiIndex(data);

export default {
  name: "EmojiPicker",
  props: {
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: true
    },
    placeholderEmoji: {
      type: String,
      default: null
    },
    placeholderIcon: {
      type: String,
      default: "mdi-emoticon-outline"
    },
    buttonWidth: {
      type: Number,
      default: undefined
    },
    buttonColor: {
      type: String,
      default: "grey"
    }
  },
  components: {
    Picker
  },
  data() {
    return {
      emojiIndex,
      showMenu: false,
      emojiSearch: null
    };
  },
  mounted() {},
  methods: {
    selectEmoji(e) {
      this.$emit("select", e.native);
      this.showMenu = false;
    }
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

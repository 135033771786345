<template>
  <div class="mt-4 flex-column d-flex">
    <v-card rounded="0" width="100%" elevation="0" class="quiz-card">
      <v-card-title class="word-break px-0 font-weight-bold brand--text">
        <!-- #{{ questionIndex + 1 }} - -->
        {{ question.content }}
      </v-card-title>
      <p class="grey--text mb-1 font-italic">
        Choose a response on the slider below
      </p>
      <v-divider />
      <div class="d-flex flex-column align-center text-center my-4 mx-10">
        <span class="brand--text slider-value-text font-weight-bold mb-4">{{
          sliderValue
        }}</span>
        <v-slider
          v-model="sliderValue"
          class="slider"
          color="brandCyan"
          :min="this.question.lowerBound"
          :max="this.question.upperBound"
          ticks
          :disabled="answered"
        >
        </v-slider>
      </div>

      <!-- <div
        class="rounded-card d-flex px-5 py-4 align-center"
        v-if="answered == true && correctAnswer"
        :class="{
          'cursor-pointer': !answered,
          'brand-pale-green-background':
            answered &&
            ((correctAnswer && correctAnswer.content == sliderValue) ||
              noCorrectAnswersMarked),
          'light-red-background':
            answered &&
            !noCorrectAnswersMarked &&
            correctAnswer &&
            correctAnswer.content != sliderValue,
        }"
      >
        <div
          class="letter-circle d-flex align-center justify-center light-grey-background circle-border-radius mr-4"
          :class="{
            'brand-green-background':
              answered &&
              ((correctAnswer && correctAnswer.content == sliderValue) ||
                noCorrectAnswersMarked),
            'red-background':
              answered &&
              !noCorrectAnswersMarked &&
              correctAnswer &&
              correctAnswer.content != sliderValue,
          }"
        >
          <b class="white--text">{{ alphabet[0] }}</b>
        </div>
        {{ correctAnswer.content }}
      </div> -->
      <div
        v-if="answered == true && correctAnswer"
        class="answer-box d-flex justify-center align-center cursor-pointer mb-6 mx-auto"
        :class="{
          'brand-pale-green-background':
            answered &&
            ((correctAnswer && correctAnswer.content == sliderValue) ||
              noCorrectAnswersMarked),
          'light-red-background':
            answered &&
            !noCorrectAnswersMarked &&
            correctAnswer &&
            correctAnswer.content != sliderValue
        }"
      >
        <!-- -->
        <div class="d-flex justify-center">
          <v-icon
            color="brandGreen"
            class="answer-icon"
            v-if="
              answered && correctAnswer && correctAnswer.content == sliderValue
            "
            >mdi-check-circle</v-icon
          >
          <v-icon color="red" class="answer-icon" v-else>mdi-close</v-icon>
          <span class="ml-2">{{ correctAnswer.content }}</span>
        </div>
      </div>

      <v-divider />

      <div class="d-flex justify-center mt-10">
        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          width="120"
          depressed
          @click="checkQuestion"
          v-if="answered == false"
        >
          {{ computedSubmitButton }}
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          width="120"
          depressed
          @click="goToNextQuestion"
          v-else-if="questionIndex !== questionCount - 1 && answered == true"
        >
          Next
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          width="120"
          depressed
          @click="completeQuiz"
          v-else-if="
            questionIndex == questionCount - 1 &&
              answered == true &&
              !isSurveyQuestion
          "
        >
          Complete
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { alphabetLowerCase } from "@/shared_data/data";
import LearningService from "@/services/LearningService";

export default {
  name: "QuizQuestionScaleV2",
  props: {
    question: Object,
    questionIndex: Number,
    questionCount: Number,
    completedStatus: Boolean,
    learningCardAssociationId: Number,
    isSurveyQuestion: Boolean,
    surveyClientId: Number,
    whistleToken: String,
    enteredEmail: String,
    context: String
  },
  components: {},
  data() {
    return {
      alphabet: alphabetLowerCase,
      answerKey: null,
      chapterList: null,
      currentQuestion: null,
      lowerBound: null,
      upperBound: null,
      sliderValue: null,
      value: null,
      incorrectAnswers: [],
      incorrectCount: 0,
      correctCount: 0,
      answered: false
    };
  },
  created() {
    console.log("Created");
  },
  updated() {},
  mounted() {},

  beforeMount() {},
  methods: {
    checkQuestion() {
      // check this question's answer
      // Switched from find to filter in case there are multiple correct answers
      var answerId = null;
      this.answered = true;
      // compare correct answer with user's answer
      console.log("Correct answers: ", this.correctAnswer);
      if (
        (this.correctAnswer &&
          this.correctAnswer.content == this.sliderValue) ||
        !this.correctAnswer
      ) {
        if (this.correctAnswer)
          answerId = this.correctAnswer.learningQuizAnswerId;

        console.log("Correct", this.correctCount);
        this.correctCount++;
      } else {
        console.log("Wrong!", this.correctCount);
        this.incorrectCount++;
      }

      // //build question response
      console.log("build question response...");
      var quizQuestionResponse = {
        clientId:
          this.userProfile && this.userProfile.clientId != null
            ? this.userProfile.clientId
            : this.surveyClientId,
        learningCardId: this.question.learningCardId,
        learningQuizQuestionId: this.question.learningQuizQuestionId,
        learningCardAssociationId: this.learningCardAssociationId,
        learningQuizAnswerId: answerId,
        value: this.sliderValue,
        correctness: this.isSurveyQuestion
          ? null
          : answerId != null
          ? 1
          : this.correctAnswer
          ? 0
          : null,
        userId: this.whistleToken != null ? null : this.$auth.user.sub
      };

      if (this.whistleToken !== null) {
        quizQuestionResponse.externalUserId = this.enteredEmail;
      }

      console.log("Quiz Response Object: ", quizQuestionResponse);
      // console.log(this.sliderValue);

      LearningService.createQuizResponse(
        [quizQuestionResponse],
        this.whistleToken
      );

      //if this is a survey question, we don't want to have to click Submit, and then Next, one button should do both
      //so we call the function for next question or complte quiz inside of the submit button
      if (this.isSurveyQuestion == true) {
        this.goToNextQuestion();
      } else if (!this.correctAnswer) {
        // We auto move on if there isn't an answer marked
        this.goToNextQuestion();
      }

      console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(this.sliderValue);
      console.log(this.question);

      // If there is no correct answer marked, then we continue to the next question (or finish the quiz if we're at the end)
      //:::::::::::::
      //:::::::::::::
      //Commented out the below in favor of the above, because I think we're already handling questions without correct answers in a way that's consistent with mobile?

      // if (
      //   this.questionIndex !== this.questionCount - 1 &&
      //   correctAnswers.length == 0
      // ) {
      //   this.goToNextQuestion();
      // } else if (
      //   this.questionIndex == this.questionCount - 1 &&
      //   correctAnswers.length == 0
      // ) {
      //   this.completeQuiz();
      // }
    },

    // answerQuestion() {
    //   if (this.answered) {
    //     this.sliderValue;
    //     console.log("answer");
    //   }
    // },
    // answerQuestion(answer) {
    //   if (!this.answered) {
    //     this.checkedAnswer = answer;
    //   }
    // },
    goToNextQuestion() {
      // If we're not on the last question, move on and reset the flags for answering a question
      if (this.questionIndex !== this.questionCount - 1) {
        this.answered = false;
        this.sliderValue = null;
        this.$emit("next-question", this.correctCount);
      } else {
        this.completeQuiz();
      }
    },
    completeQuiz() {
      // Emits a notification so the quiz can submit
      this.$emit("complete-quiz", this.correctCount);
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    noCorrectAnswersMarked() {
      if (
        this.question &&
        this.question.LearningQuizAnswers.filter(ans => ans.correctness > 0)
          .length == 0
      ) {
        return true;
      }
      return false;
    },
    correctAnswer() {
      if (!this.question) return null;
      let correct = this.question.LearningQuizAnswers.filter(
        ans => ans.correctness > 0 && ans.status == "Active"
      );
      if (correct.length) return correct[0];
      return null;
    },
    computedSubmitButton: function() {
      var buttonText = "Submit";
      if (this.isSurveyQuestion) {
        buttonText = "Next";
      }
      return buttonText;
    },
    inProgram() {
      // used to change styling if we're in learning or surveys versus in programs
      return !(this.context == "survey" || this.context == "learning");
    }
  },
  watch: {
    question: function(newVal) {
      if (newVal !== null) {
        this.correctCount = 0;
        this.answered = false;
        this.sliderValue = null;
      }
    }
  }
};
</script>

<style scoped>
.rounded-card {
  margin: 10px;
  border-radius: 50px;
  border: 1px solid;
  border-color: lightgrey;
}

.grey-background {
  background-color: var(--v-grey-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.brand-pale-green-background {
  background-color: var(--v-brandPaleGreen-base);
}
.red-background {
  background-color: #f44336;
}
.light-red-background {
  background-color: #fcdeda;
}
.purple-background {
  background-color: #818ade;
}
.light-purple-background {
  background-color: #e9e9f5;
}
.accent-background {
  background-color: var(--v-accent-base);
}

.selected-background {
  background-color: var(--v-blue-base);
}
.correct-background {
  color: var(--v-green-base);
}

.nextQ {
  padding-top: 10px;
}

.v-progress-linear {
  display: block;
  width: 100px;
  margin: 0 auto;
  text-align: justify-center;
}

.retakeButten {
  margin-bottom: 15px;
}
.wrong {
  background-color: red;
}
.correct {
  background-color: green;
}

.letter-circle {
  width: 35px;
  height: 35px;
}

.quiz-card .v-card__title {
  word-break: normal;
}

.slider {
  width: 100%;
}

.slider-value-text {
  font-size: 1.5em;
}

/* Container for individual answer box */
.answer-box {
  min-height: 60px;
  height: 60px;
  width: 200px;
  padding: 0 20px 0 20px;
}

/* Increases size of icon next to answer */
.answer-icon {
  transform: scale(1.1);
}
</style>

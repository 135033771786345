<template>
  <v-dialog
    fullscreen
    value="true"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card
      ><v-toolbar dark color="primary" rounded="0">
        <v-toolbar-title>Build a New Survey</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogExitMessage = true">
            Cancel
            <v-icon class="ml-2">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <h3
        class="mt-3 grey--text text--darken-1"
        v-if="learningModule.learningModuleId"
      >
        Note: When editing, surveys are hidden until published again.
      </h3>
      <div
        class="d-flex justify-center full-height"
        justify="center"
        height="100%"
      >
        <v-col md="3" lg="3" class="d-flex flex-column align-start full-height">
          <v-btn
            text
            color="primary"
            v-if="slideKey > 1 && slideKey !== 8"
            @click="goBack"
          >
            <v-icon>mdi-chevron-left</v-icon>Back</v-btn
          >

          <v-btn
            text
            color="primary"
            v-else-if="slideKey == 8"
            @click="goBackQuiz"
          >
            <v-icon>mdi-chevron-left</v-icon>Back</v-btn
          >

          <b class="grey--text mt-8 mb-4">Survey preview</b>
          <ActivityFeedCard
            class="full-width"
            icon="mdi-comment-question"
            iconColor="brand-green"
            :title="
              learningModule.name === null
                ? 'Survey Title'
                : learningModule.name
            "
            :date="computedActivityCardDate"
            messageTitle=""
            :image="learningModule.imageUrlSigned"
            :imageFlag="true"
            :imagePlaceholderFlag="true"
            :messageTitleFlag="false"
            :messageSubtitleFlag="false"
            :linkButtonFlag="false"
            :actionFlag="false"
            :disabled="true"
          />
          <v-btn
            v-if="slideKey > 1"
            text
            color="primary"
            class="mt-2 mx-auto"
            @click="goToSlide(1)"
          >
            Edit Survey Details
          </v-btn>

          <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
          >
            <div class="d-flex justify-space-between align-center mr-2">
              <div class="d-flex align-center">
                <span
                  class="py-2 ma-2 rounded-lg white--text card-letters"
                  :class="{
                    'light-grey-background': slide2.selectedGroups.length == 0,
                    'primary-background': slide2.selectedGroups.length > 0
                  }"
                  ><b>G</b></span
                >
                <b
                  class="word-break text-h6 "
                  :class="{
                    'grey--text': slide2.selectedGroups.length == 0,
                    'black--text': slide2.selectedGroups.length > 0
                  }"
                >
                  GROUP
                </b>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="30"
                  :width="4"
                  class="ml-2"
                  v-if="slide2.loadingGroups"
                />
              </div>
              <v-btn icon v-if="slideKey > 2" @click="goToSlide(2)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div
              v-if="slide2.selectedGroups.length > 0"
              class="d-flex flex-column align-start mx-4 my-4"
            >
              <div
                v-for="(group, i) in slide2.selectedGroups"
                :key="i"
                class="d-flex align-center justify-space-between full-width"
              >
                <span
                  v-if="
                    !group.groupDisplayName ||
                      group.groupDisplayName.length < 32
                  "
                  class="align-text-left"
                  >{{ group.groupDisplayName }}</span
                >
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="align-text-left">{{
                      group.groupDisplayName.substring(0, 32) + ".."
                    }}</span>
                  </template>
                  <span>{{ group.groupDisplayName }}</span>
                </v-tooltip>
                <div
                  class="d-flex align-center"
                  v-if="group.numUsers !== undefined && group.numUsers !== null"
                >
                  <v-icon small class="mr-1">mdi-account-group</v-icon>
                  <b>{{ group.numUsers }}</b>
                </div>
                <!-- <div
                  class="d-flex align-center"
                  v-else-if="
                    group.GroupAssignments !== undefined &&
                      group.GroupAssignments !== null
                  "
                >
                  <v-icon small class="mr-1">mdi-account-group</v-icon>
                  <b>{{ group.GroupAssignments.length }}</b>
                </div> -->
              </div>
            </div>
          </v-card>
          <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
            v-if="isSurveyPrivate"
          >
            <div class="d-flex justify-space-between align-center mr-2">
              <div class="d-flex align-center">
                <span
                  class="py-2 ma-2 rounded-lg white--text card-letters"
                  :class="{
                    'light-grey-background': slide3.selectedBadges.length == 0,
                    'brand-green-background': slide3.selectedBadges.length > 0
                  }"
                  ><b>B</b></span
                >
                <b
                  class="word-break text-h6 "
                  :class="{
                    'grey--text': slide3.selectedBadges.length == 0,
                    'black--text': slide3.selectedBadges.length > 0
                  }"
                >
                  BADGE
                </b>
              </div>
              <v-btn icon v-if="slideKey > 3" @click="goToSlide(3)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div
              v-if="slide3.selectedBadges.length > 0"
              class="d-flex justify-space-between mx-4 my-4"
            >
              <span
                v-if="slide3.selectedBadges[0].displayName.length < 32"
                class="align-text-left"
                >{{ slide3.selectedBadges[0].displayName }}</span
              >
              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="align-text-left">{{
                    slide3.selectedBadges[0].displayName.substring(0, 32) + ".."
                  }}</span>
                </template>
                <span>{{ slide3.selectedBadges[0].displayName }}</span>
              </v-tooltip>

              <v-img
                :src="slide3.selectedBadges[0].imageUrl"
                max-height="32"
                max-width="32"
              />
            </div>
          </v-card>
          <!-- <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
            v-if="isSurveyPrivate"
          >
            <div class="d-flex justify-space-between align-center mr-2">
              <div class="d-flex align-center">
                <span
                  class="py-2 ma-2 rounded-lg white--text card-letters"
                  :class="{
                    'light-grey-background': !slide4.incentModule,
                    'purple-background': slide4.incentModule
                  }"
                  ><b>I</b></span
                >
                <b
                  class="word-break text-h6 "
                  :class="{
                    'grey--text': !slide4.incentModule,
                    'black--text': slide4.incentModule
                  }"
                >
                  INCENTIVE
                </b>
              </div>
              <v-btn icon v-if="slideKey > 4" @click="goToSlide(4)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div
              v-if="slide4.awards && slide4.awardId && slide4.awardValue"
              class="d-flex justify-space-between mx-4 my-4"
            >
              <span v-if="!incentiveAward" class="align-text-left"
                >Incentive
              </span>
              <span
                v-else-if="incentiveAward.awardDisplayName.length < 28"
                class="align-text-left"
                >{{ slide4.awardId == 1 ? "$" : ""
                }}{{ formatCurrency(truncateNum(slide4.awardValue, 2)) }}
                {{ incentiveAward.awardDisplayName }}</span
              >
              <v-tooltip top v-else>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="align-text-left"
                    >{{ slide4.awardId == 1 ? "$" : ""
                    }}{{ formatCurrency(truncateNum(slide4.awardValue, 2)) }}
                    {{
                      incentiveAward.awardDisplayName.substring(0, 28) + ".."
                    }}</span
                  >
                </template>
                <span
                  >{{ slide4.awardId == 1 ? "$" : ""
                  }}{{ formatCurrency(truncateNum(slide4.awardValue, 2)) }}
                  {{ incentiveAward.awardDisplayName }}</span
                >
              </v-tooltip>
            </div>
          </v-card> -->

          <v-card
            rounded="0"
            elevation="4"
            class="mt-4"
            width="100%"
            min-height="70"
          >
            <div class="d-flex justify-space-between align-center mr-2">
              <div class="d-flex align-center">
                <span
                  class="py-2 ma-2 rounded-lg white--text card-letters"
                  :class="{
                    'light-grey-background': !learningModule.learningChapters[0]
                      .learningCards[0].learningCardQuestions,
                    'brand-green-background':
                      learningModule.learningChapters[0].learningCards[0]
                        .learningCardQuestions
                  }"
                  ><b>S</b></span
                >
                <b
                  class="word-break text-h6 "
                  :class="{
                    'grey--text': !learningModule.learningChapters[0]
                      .learningCards[0].learningCardQuestions,
                    'black--text':
                      learningCardQuestions ||
                      learningModule.learningChapters[0].learningCards[0]
                        .learningCardQuestions
                  }"
                >
                  SURVEY
                </b>
              </div>
              <v-btn
                icon
                v-if="
                  learningCardQuestions ||
                    learningModule.learningChapters[0].learningCards[0]
                      .learningCardQuestions
                "
                @click="goToQuizEditor()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div
              v-if="slideKey == 8"
              class="d-flex justify-space-between mx-4 my-4"
            >
              <span class="align-text-left"
                >{{ learningCardQuestions.length }}
                {{
                  learningCardQuestions.length == 1 ? "Question" : "Questions"
                }}</span
              >
            </div>
            <div
              v-else-if="
                learningModule.learningChapters[0].learningCards[0]
                  .learningCardQuestions
              "
              class="d-flex justify-space-between mx-4 my-4"
            >
              <span class="align-text-left"
                >{{
                  learningModule.learningChapters[0].learningCards[0]
                    .learningCardQuestions.length
                }}
                {{
                  learningModule.learningChapters[0].learningCards[0]
                    .learningCardQuestions.length == 1
                    ? "Question"
                    : "Questions"
                }}</span
              >
            </div>
          </v-card>

          <!-- <b class="grey--text mt-4">Learning elements</b> -->
        </v-col>
        <v-col md="7" lg="7" class=" ml-6 d-flex flex-column align-start">
          <v-container class="mb-2 d-flex align-center justify-space-between">
            <div class="d-flex"></div>

            <v-tooltip
              top
              v-if="slideKey <= 3"
              :disabled="!disableContinueButton || slideKey > 3"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    :loading="savingChanges"
                    @click="saveLearningModule"
                    :disabled="disableContinueButton"
                    >Continue</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 1">
                <span
                  v-if="
                    learningModule.name == null ||
                      (learningModule.name != null &&
                        learningModule.name.length == 0)
                  "
                  >A name is required for this survey!</span
                >
                <span
                  v-else-if="
                    learningModule.description == null ||
                      (learningModule.description != null &&
                        learningModule.description.length == 0)
                  "
                  >A brief description is required for this survey!</span
                >
                <span
                  v-else-if="
                    learningModule.imageUrl == null ||
                      (learningModule.imageUrl != null &&
                        learningModule.imageUrl.length == 0)
                  "
                  >A banner image is required for this survey!</span
                >
                <!-- <span v-else-if="dateValidation"
                  >The end date must be later than the start date!</span
                > -->
              </div>
              <div v-if="slideKey == 2">
                <span v-if="slide2.selectedGroups.length == 0"
                  >An option must be selected for the groups that can view this
                  survey!</span
                >
              </div>
              <div v-if="slideKey == 3">
                <span v-if="slide3.selectedBadges.length == 0"
                  >An option must be selected regarding badges for this
                  survey!</span
                >
              </div>
            </v-tooltip>
            <!-- <v-tooltip
              top
              v-if="slideKey == 4"
              :disabled="!disableContinueButton"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    :loading="savingChanges"
                    @click="saveLearningProgram"
                    :disabled="disableContinueButton"
                    >Continue</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 4">
                <span v-if="slide4.incentModule && !slide4.awardId"
                  >An award is required for this incentive!</span
                >
                <span v-else-if="slide4.incentModule && !slide4.awardValue"
                  >An award value is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.awardValue &&
                      slide4.awardValue.toString().includes('.') &&
                      slide4.awardValue
                        .toString()
                        .substring(
                          slide4.awardValue.toString().indexOf('.') + 1
                        ).length > 2
                  "
                  >The award value should not be longer than 2 decimals</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.awardValue &&
                      parseFloat(slide4.awardValue) <= 0
                  "
                  >The award value should be a number greater than 0 for this
                  incentive!</span
                >
                <span v-else-if="slide4.incentModule && !slide4.budgetId"
                  >A budget is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.budgetId &&
                      slide4.awardValue &&
                      incentiveBudget &&
                      incentiveBudget.budgetBalance &&
                      parseFloat(incentiveBudget.budgetBalance) > 0 &&
                      parseFloat(incentiveBudget.budgetBalance) <
                        parseFloat(slide4.awardValue)
                  "
                  >The award value should be less than the budget balance for
                  this incentive!</span
                >
                <span
                  v-else-if="slide4.incentModule && !slide4.dateOptionSelector"
                  >A timeframe must be chosen for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      (!slide4.startDate ||
                        !slide4.startTime.hours ||
                        !slide4.startTime.minutes ||
                        !slide4.startTime.amPm ||
                        !slide4.startTime.timezone)
                  "
                  >A start date & time is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule && dateValidationComparedToToday
                  "
                  >The start date must be set for the future!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'date' &&
                      (!slide4.endDate ||
                        !slide4.endTime.hours ||
                        !slide4.endTime.minutes ||
                        !slide4.endTime.amPm)
                  "
                  >An end date & time is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'date' &&
                      dateValidationIncentive
                  "
                  >The end date must be later than the start date!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'rolling' &&
                      !slide4.rollingProgramLength
                  "
                  >A rolling program length is required for this
                  incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'rolling' &&
                      slide4.rollingProgramLength &&
                      parseInt(slide4.rollingProgramLength) <= 0
                  "
                  >A rolling program length must be greater than 0 for this
                  incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'rolling' &&
                      slide4.rollingProgramLength &&
                      slide4.rollingProgramLength.toString().includes('.')
                  "
                  >A rolling program length must be a whole number for this
                  incentive!</span
                >
              </div>
            </v-tooltip> -->
            <v-tooltip
              top
              v-if="slideKey == 5"
              :disabled="
                !(
                  JSON.stringify(learningModule).includes(
                    `&quot;learningCards&quot;:[]`
                  ) || learningModule.learningChapters.length <= 1
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    @click="dialogPublishMessage = true"
                    :disabled="
                      JSON.stringify(learningModule).includes(
                        `&quot;learningCards&quot;:[]`
                      ) || learningModule.learningChapters.length <= 1
                    "
                    v-if="slideKey == 5"
                    :loading="savingChanges"
                    >Publish Module</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 5">
                <span v-if="learningModule.learningChapters.length <= 1"
                  >Be sure to add some chapters to this survey before
                  publishing!</span
                >
                <span
                  v-else-if="
                    JSON.stringify(learningModule).includes(
                      `&quot;learningCards&quot;:[]`
                    )
                  "
                  >Make sure there aren't any empty chapters! Add some learning
                  cards or surveys to publish.</span
                >
              </div>
            </v-tooltip>
            <v-tooltip
              top
              v-if="slideKey == 7"
              :disabled="!disableContinueButton"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    @click="saveLearningCard"
                    :disabled="disableContinueButton"
                    v-if="slideKey == 7"
                    :loading="savingChanges"
                    ><span>Save & Publish</span></v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 7">
                <span
                  v-if="
                    learningCard.name == null ||
                      (learningCard.name !== null &&
                        learningCard.name.length == 0)
                  "
                  >A name for this card is required!</span
                >
                <span
                  v-else-if="
                    learningCard.description == null ||
                      (learningCard.description !== null &&
                        learningCard.description.length == 0)
                  "
                  >A description for this card is required!</span
                >
              </div>
            </v-tooltip>
            <v-tooltip
              top
              v-if="slideKey == 8"
              :disabled="
                !(checkIfQuizIsValid || learningCardQuestions.length == 0)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    @click="saveLearningQuiz"
                    :disabled="
                      checkIfQuizIsValid || learningCardQuestions.length == 0
                    "
                    v-if="slideKey == 8"
                    :loading="savingChanges"
                    >Save Survey</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 8">
                <span v-if="learningCardQuestions.length == 0"
                  >Survey questions are required to save this survey!</span
                >
                <span v-else-if="checkIfQuizIsValid"
                  >Make sure your survey questions and answers are filled
                  out!</span
                >
              </div>
            </v-tooltip>
          </v-container>

          <div v-if="slideKey === 1" class="full-width">
            <div class="align-text-left mb-5">
              <span class="header-text"
                >Start by giving your survey a name, description, and a
                timeframe the survey will be active</span
              >
            </div>
            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="4"
            >
              <v-form
                ref="learningModuleForm"
                v-model="learningModule.valid"
                class="full-width"
              >
                <v-autocomplete
                  :items="clients"
                  outlined
                  label="Client"
                  item-text="formattedName"
                  item-value="clientId"
                  v-model="clientId"
                  class="mt-5"
                  v-if="userProfile.clientId == 1"
                  :disabled="learningModule.learningModuleId ? true : false"
                  @change="updateClientId"
                  attach
                  :menu-props="{ offsetY: true }"
                ></v-autocomplete>
                <div class="d-flex align-center">
                  <v-text-field
                    ref="titleInput"
                    v-model="learningModule.name"
                    class="mt-5 mb-2"
                    outlined
                    label="Survey Name*"
                    :required="true"
                    :rules="[
                      v => !!v || 'A name is required!',
                      v =>
                        !!(v && 100 > v.length) ||
                        'Try and make your name shorter!'
                    ]"
                  ></v-text-field>

                  <v-icon
                    dark
                    tabindex="-1"
                    @click="focusOnTitle"
                    class="circle-border-radius pa-2 ml-4 mb-5 cursor-pointer "
                    :class="{
                      'accent-background':
                        learningModule.name !== null &&
                        learningModule.name.length > 0,
                      'grey-background':
                        learningModule.name === null ||
                        (learningModule.name !== null &&
                          learningModule.name.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <div class="d-flex align-start mt-2">
                  <!-- <div class="d-flex flex-column"> -->
                  <!-- <vue-editor
                    ref="descriptionInput"
                    class="editor full-width"
                    v-model="learningModule.description"
                    :editorToolbar="customToolbar"
                    placeholder="Brief survey description*"
                  ></vue-editor> -->
                  <v-textarea
                    ref="descriptionInput"
                    label="Brief survey description"
                    class="full-width"
                    outlined
                    v-model="learningModule.description"
                    :rules="[
                      v => !!v || 'A description is required',
                      v =>
                        !!(
                          v &&
                          formattedModuleDescription &&
                          formattedModuleDescription.length < 240
                        ) || 'Your description should be shorter'
                    ]"
                  ></v-textarea>

                  <!-- </div> -->
                  <v-icon
                    dark
                    tabindex="-1"
                    class="circle-border-radius pa-2 ml-4 cursor-pointer"
                    :class="{
                      'accent-background':
                        learningModule.description !== null &&
                        learningModule.description.length > 0,
                      'grey-background':
                        learningModule.description === null ||
                        (learningModule.description !== null &&
                          learningModule.description.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <p
                  class="text--caption-lg error--text align-text-left mt-1 ml-3"
                  v-show="
                    learningModule.description != null &&
                      learningModule.description.length == 0
                  "
                >
                  A description is required!
                </p>
                <v-menu max-width="260" offset-x offset-y absolute>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="d-flex align-center justify-space-between full-width image-container mt-5"
                    >
                      <v-img
                        :lazy-src="learningModule.imageUrlSigned"
                        :src="learningModule.imageUrlSigned"
                        :key="learningModule.imageUrlSigned"
                        :aspect-ratio="16 / 9"
                        v-bind="attrs"
                        v-on="on"
                        :width="calculatedImageWidth"
                        class="cursor-pointer"
                        @drop.prevent="dragAndDropImageFileChanged"
                        @dragover.prevent
                      >
                        <template v-slot:placeholder>
                          <div
                            class="light-grey-background full-height full-width"
                          >
                            <h3 class=" pt-5 primary--text">
                              Add a banner photo
                            </h3>

                            <p class="mt-10">
                              We suggest an image size of 740 x 416 pixels (Or
                              any image with a 16 : 9 aspect ratio).
                            </p>
                            <p>
                              JPG, PNG files supported
                            </p>
                          </div>
                        </template>
                      </v-img>
                      <v-icon
                        dark
                        tabindex="-1"
                        v-bind="attrs"
                        v-on="on"
                        class="circle-border-radius pa-2 ml-4 mb-5 cursor-pointer"
                        :class="{
                          'accent-background': learningModule.imageUrl !== null,
                          'grey-background': learningModule.imageUrl === null
                        }"
                        >mdi-pencil</v-icon
                      >
                    </div>
                  </template>
                  <v-list>
                    <input
                      id="uploader"
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      @change="imageFileChanged"
                      @blur="imageFileChanged"
                    />
                    <v-list-item @click="uploadImage('learningModule')">
                      <v-list-item-title class="primary--text align-text-left"
                        ><v-icon color="black" class="mr-2">mdi-upload</v-icon
                        >Upload from computer</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="learningModule.dialogImageUrl = true">
                      <v-list-item-title class="primary--text align-text-left"
                        ><v-icon color="black" class="mr-2">mdi-link</v-icon>By
                        URL</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="removeModuleImage">
                      <v-list-item-title class="primary--text align-text-left"
                        ><v-icon color="black" class="mr-2">mdi-delete</v-icon
                        >Remove file</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- <div class="d-flex align-center"> -->
                <!-- <v-switch
                  ref="forceLinearProgress"
                  v-model="learningModule.forceLinearProgress"
                  class="mt-7 mb-2 mr-9"
                  outlined
                  label="Force participant to progress through survey linearly"
                  hide-details
                ></v-switch> -->
                <!-- </div> -->
                <!-- <v-switch
                  v-model="learningModule.displayDate"
                  class="mt-7 mb-2 mr-9"
                  outlined
                  label="Set start and end date for survey?"
                  hide-details
                  @change="displayDateToggled"
                ></v-switch> -->
                <!-- <div
                  class="d-flex align-start mt-2"
                  v-if="learningModule.displayDate"
                >
                  <v-row>
                    <v-col sm="8" md="6" lg="6" class="align-text-left">
                      <span>Survey <b>start</b> date and time</span>
                      <v-menu
                        v-model="learningModule.menuStartDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        attach
                        top
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="learningModule.startDate"
                            label="Start Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mt-4"
                            :rules="startDateRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="learningModule.startDate"
                          @input="learningModule.menuStartDate = false"
                        ></v-date-picker>
                      </v-menu>

                      <div class="d-flex mt-2">
                        <v-combobox
                          label="Hour"
                          v-model="learningModule.startTime.hours"
                          :items="hours"
                          outlined
                          class="hour-picker mr-2"
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="Minutes"
                          v-model="learningModule.startTime.minutes"
                          :items="minutes"
                          outlined
                          class="minutes-picker mr-2"
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="AM/PM"
                          v-model="learningModule.startTime.amPm"
                          :items="amPm"
                          outlined
                          class="am-pm-picker"
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                      </div>
                      <v-combobox
                        label="Timezone"
                        v-model="learningModule.startTime.timezone"
                        :items="calculatedTimezones"
                        item-value="value"
                        item-text="value"
                        outlined
                        class="full-width"
                        attach
                        :menu-props="{ top: true, offsetY: true }"
                      ></v-combobox>
                      <p v-if="dateValidation" class="red--text">
                        Start date must be earlier than the end date!
                      </p>
                    </v-col>
                    <v-col sm="8" md="6" lg="6" class="align-text-left">
                      <span>Survey <b>end</b> date and time</span>
                      <v-menu
                        v-model="learningModule.menuEndDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        attach
                        top
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="learningModule.endDate"
                            label="End Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mt-4"
                            :rules="endDateRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="learningModule.endDate"
                          @input="learningModule.menuEndDate = false"
                        ></v-date-picker>
                      </v-menu>

                      <div class="d-flex mt-2">
                        <v-combobox
                          label="Hour"
                          v-model="learningModule.endTime.hours"
                          :items="hours"
                          outlined
                          class="hour-picker mr-2"
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="Minutes"
                          v-model="learningModule.endTime.minutes"
                          :items="minutes"
                          outlined
                          class="minutes-picker mr-2"
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="AM/PM"
                          v-model="learningModule.endTime.amPm"
                          :items="amPm"
                          outlined
                          class="am-pm-picker"
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                      </div>
                    </v-col>
                  </v-row>
                  <v-icon tabindex="-1" class="pa-2 ml-4 mb-5 hidden"
                    >mdi-pencil</v-icon
                  >
                </div> -->
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === 2"
            class="d-flex flex-column align-start full-width"
          >
            <span class="header-text align-text-left mb-10"
              >Who should have access to this learning content?</span
            >
            <v-radio-group v-model="slide2.visibility">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-radio
                    label="Public"
                    value="PUBLIC"
                    @click="
                      groupSelector = false;
                      selectPublicAudience(true);
                    "
                    :disabled="disableChangeVisibility"
                    v-on="on"
                    v-bind="attrs"
                  ></v-radio>
                </template>
                <span>Available to anyone with a link</span>
              </v-tooltip>
              <!-- <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-radio
                    label="Semi-public"
                    value="EMAIL_LIST"
                    @click="
                      groupSelector = false;
                      selectPublicAudience();
                    "
                    disabled="disableChangeVisibility"
                    v-on="on"
                    v-bind="attrs"
                  ></v-radio>
                </template>
                <span>Available to anyone with an email invite</span>
              </v-tooltip> -->
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-radio
                    label="Internal"
                    value="PRIVATE"
                    @click="
                      clearSelectedGroups();
                      groupSelector = null;
                    "
                    v-on="on"
                    v-bind="attrs"
                  ></v-radio>
                </template>
                <span>Available to Whistle users only</span>
              </v-tooltip>
              <!-- Render these if internal is selected so they can select groups -->
              <v-radio-group
                v-if="slide2.visibility === 'PRIVATE'"
                class="ml-8 mt-0"
                v-model="groupSelector"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-radio
                      label="Everyone"
                      :value="false"
                      @click="selectEveryone()"
                      v-on="on"
                      v-bind="attrs"
                    ></v-radio>
                  </template>
                  <span>Available to all Whistle users</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-radio
                      label="Select groups only"
                      :value="true"
                      @click="selectGroup()"
                      v-on="on"
                      v-bind="attrs"
                    ></v-radio>
                  </template>
                  <span>Available to specified Whistle groups only</span>
                </v-tooltip>
              </v-radio-group>
            </v-radio-group>
            <!-- Switch to make survey retakeable -->
            <div class="repeatable-switch-div mb-10">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height:min-content;" v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="
                        learningModule.learningChapters[
                          computedSurveyChapterIndex
                        ].learningCards[computedSurveyCardIndex].repeatable
                      "
                      class="ma-0"
                      label="Allow survey to be retaken?"
                      @change="toggleRepeatable"
                    >
                    </v-switch>
                  </div>
                </template>
                <span
                  >Toggle to limit to one submission or unlimited
                  submission</span
                >
              </v-tooltip>
              <!-- <v-switch
                v-model="learningModule.learningChapters[computedSurveyChapterIndex].learningCards[computedSurveyCardIndex].repeatable"
                class="ma-0"
                @change="toggleRepeatable"
              >
                <template v-slot:label>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        Allow survey to be retaken?
                      </span>
                    </template>
                    <span v-if="learningModule.learningChapters[computedSurveyChapterIndex].learningCards[computedSurveyCardIndex].repeatable == true">Toggle to limit to one submission</span>
                    <span v-else>Toggle to allow for multiple submissions</span>
                  </v-tooltip>
                </template>
              </v-switch> -->
            </div>
            <v-card rounded="0" width="100%" v-if="groupSelector">
              <p class="darkGrey--text ml-4 mt-2 text-left">
                Note: Surveys don't support external users at this time
              </p>
              <div class="my-4 d-flex justify-space-between mr-3">
                <v-text-field
                  outlined
                  placeholder="Search existing groups"
                  single-line
                  dense
                  hide-details
                  v-model="slide2.groupTableDebounceSearch"
                  class="shrink ml-8"
                ></v-text-field>
                <v-btn
                  v-if="permissions.includes('groups:create:group')"
                  text
                  color="primary"
                  @click="slide2.createGroupWidget = !slide2.createGroupWidget"
                  >Create a New Group</v-btn
                >
              </div>
              <GroupTable
                ref="group-table"
                :selected="slide2.selectedGroups"
                :search="slide2.groupTableSearch"
                @select-item="addGroupToSelected($event, false)"
                @update-item="updateSelectedGroup"
              />
            </v-card>
            <v-dialog
              v-model="slide2.changeVisibilityConfirmation.dialog"
              width="500"
            >
              <v-card
                rounded="0"
                class="d-flex justify-center flex-column pa-6"
              >
                <div
                  class="d-flex justify-space-between align-center mx-2 mb-2"
                >
                  <v-icon color="error" x-large class="exit-warning-icon mr-2"
                    >mdi-alert</v-icon
                  >
                  <v-card-title
                    class="word-break align-text-left exit-warning-text"
                  >
                    Changing to a public survey will clear the incentives and
                    badges selected, are you sure you want to make this survey
                    public?
                  </v-card-title>
                </div>
                <!-- <v-card-subtitle class="word-break mb-3"
                  >This deletes all cards, media, and quizzes associated with
                  it</v-card-subtitle
                > -->

                <v-card-actions class="mx-8">
                  <v-btn
                    color="primary"
                    @click="
                      slide2.changeVisibilityConfirmation.dialog = false;
                      slide2.visibility = 'PRIVATE';
                    "
                    outlined
                    width="170"
                    >Keep private</v-btn
                  >
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="confirmChangeVisibility"
                    width="170"
                    >Make public</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div
            v-else-if="slideKey === 3"
            class="d-flex flex-column align-start full-width"
          >
            <span class="header-text align-text-left mb-10"
              >Do you want to assign a badge with the completion of this
              survey?</span
            >
            <v-radio-group v-model="badgeSelector">
              <v-radio
                label="No Badge"
                :value="false"
                @click="selectNoBadge()"
              ></v-radio>
              <v-radio
                label="Selected Badge"
                :value="true"
                @click="selectBadge()"
              ></v-radio>
            </v-radio-group>
            <v-card rounded="0" width="100%" v-if="badgeSelector">
              <div class="my-4 d-flex justify-space-between mr-3">
                <v-text-field
                  outlined
                  placeholder="Search existing badges"
                  single-line
                  dense
                  hide-details
                  v-model="slide3.badgeSearch"
                  class="shrink ml-8"
                ></v-text-field>
                <v-btn
                  v-if="permissions.includes('badges:create:badge')"
                  text
                  color="primary"
                  @click="slide3.createBadgeWidget = !slide3.createBadgeWidget"
                  >Create a New Badge</v-btn
                >
              </div>
              <v-data-table
                v-model="slide3.selectedBadges"
                :headers="slide3.badgeColumns"
                :items="slide3.badges"
                :search="slide3.badgeTableSearch"
                item-key="badgeId"
                show-select
                :single-select="true"
                :items-per-page="10"
                class="elevation-1 full-width"
                :loading="slide3.loadingBadgeTable"
                loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.imageUrl`]="{ item }">
                  <v-img
                    :src="item.imageUrl"
                    class="selectable-blue-text cursor-pointer"
                    max-height="32"
                    max-width="32"
                  >
                  </v-img>
                </template>
              </v-data-table>
            </v-card>
          </div>
          <!-- <div
            v-else-if="slideKey === 4"
            class="d-flex flex-column align-start full-width"
          >
            <span
              class="header-text align-text-left mb-10"
              v-if="
                !slide4.preloadedProgram &&
                  permissions.includes('programs:create:program')
              "
              >Do you want to incentivize this survey?</span
            >
            <span
              class="header-text align-text-left mb-10"
              v-else-if="
                !slide4.preloadedProgram &&
                  !permissions.includes('programs:create:program')
              "
              >No incentivize has been created for this survey</span
            >
            <span class="header-text align-text-left mb-10" v-else
              >Below is your incentive that was previously created</span
            >
            <v-radio-group
              v-model="slide4.incentModule"
              v-if="
                !slide4.preloadedProgram &&
                  permissions.includes('programs:create:program')
              "
            >
              <v-radio
                label="No"
                :value="false"
                @click="removeIncentive()"
              ></v-radio>
              <v-radio
                label="Yes"
                :value="true"
                @click="createIncentive()"
              ></v-radio>
            </v-radio-group>
            <div
              class="d-flex flex-column align-start mt-5"
              v-if="slide4.incentModule"
            >
              <div class="d-flex flex-column mt-3">
                <div class="d-flex">
                  <v-select
                    v-model="slide4.awardId"
                    :items="slide4.awards"
                    item-value="awardId"
                    item-text="awardDisplayName"
                    label="Award"
                    outlined
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    class="mr-3 award-selector"
                    :rules="[v => !!v || 'An award is required!']"
                    @change="
                      () => {
                        this.slide4.budgetId = null;
                      }
                    "
                    :disabled="
                      slide4.preloadedProgram ||
                        !permissions.includes('programs:create:program')
                    "
                  />
                  <v-text-field
                    v-model="slide4.awardValue"
                    :label="incentiveAwardValueLabel"
                    outlined
                    type="number"
                    :rules="[
                      v => !!v || 'An award amount is required!',
                      v => !!(v && v > 0) || 'Must be a number greater than 0',
                      v =>
                        !!(
                          v &&
                          (!v.toString().includes('.') ||
                            (v.toString().includes('.') &&
                              v
                                .toString()
                                .substring(v.toString().indexOf('.') + 1)
                                .length < 3))
                        ) ||
                        'The award amount should not be longer than 2 decimals'
                    ]"
                    :disabled="
                      slide4.preloadedProgram ||
                        !permissions.includes('programs:create:program')
                    "
                  />
                </div>
                <v-btn
                  text
                  color="primary"
                  max-width="200"
                  @click="slide4.createAwardWidget = true"
                  v-if="
                    !slide4.preloadedProgram &&
                      permissions.includes('awards:create:award')
                  "
                  >+ Create a new award</v-btn
                >
                <span
                  class="red--text align-text-left"
                  v-if="
                    !slide4.preloadedProgram &&
                      permissions.includes('programs:create:program') &&
                      slide4.awardValue &&
                      slide4.budgetId &&
                      incentiveBudget &&
                      incentiveBudget.budgetBalance &&
                      parseFloat(incentiveBudget.budgetBalance) > 0 &&
                      parseFloat(incentiveBudget.budgetBalance) <
                        parseFloat(slide4.awardValue)
                  "
                  >The award value should be less than the budget balance! ({{
                    (incentiveBudget.awardId == 1 ? "$" : "") +
                      formatCurrency(
                        truncateNum(incentiveBudget.budgetBalance, 2)
                      )
                  }})</span
                >
                <div class="d-flex align-start">
                  <v-select
                    v-if="slide4.awardId"
                    v-model="slide4.budgetId"
                    :items="filteredBudgets"
                    item-value="budgetId"
                    item-text="displayNameWithBalance"
                    label="Budget"
                    outlined
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    class="award-selector mt-3"
                    :rules="[v => !!v || 'A budget is required!']"
                    :disabled="
                      slide4.preloadedProgram ||
                        !permissions.includes('programs:create:program')
                    "
                  >
                    <template v-slot:item="{ item }">
                      <div
                        class="d-flex align-center justify-space-between full-width"
                      >
                        {{ item.displayNameWithBalance }}

                        <v-tooltip
                          top
                          v-if="
                            !item.budgetBalance ||
                              parseFloat(item.budgetBalance) == 0
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="ml-1"
                              color="error"
                              v-on="on"
                              v-bind="attrs"
                              >mdi-alert</v-icon
                            >
                          </template>
                          <span>This budget is not funded</span>
                        </v-tooltip>
                      </div>
                    </template></v-select
                  >
                  <v-tooltip top v-if="slide4.awardId">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" class="ml-5 mt-7"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >A budget is allows you to create a pool of awards that
                      can be given out for completing the learning module.
                      <br />
                      This can be useful if you have X dollars to award, you
                      won't accidentally award more than that.</span
                    >
                  </v-tooltip>
                </div>
                <v-btn
                  v-if="
                    slide4.awardId &&
                      !slide4.preloadedProgram &&
                      permissions.includes('budgets:create:budget')
                  "
                  text
                  color="primary"
                  max-width="200"
                  @click="slide4.createBudgetWidget = true"
                  >+ Create a new budget</v-btn
                >
              </div>
              <p
                class="red--text align-text-left"
                v-if="
                  incentiveBudget &&
                    ((incentiveBudget.budgetBalance &&
                      incentiveBudget.budgetBalance == 0) ||
                      !incentiveBudget.budgetBalance)
                "
              >
                NOTE: This budget is not funded. Your program can be affected if
                the budget remains unfunded. <br />Go to Budgets to fund this
                before the program starts.
              </p>
              <div
                class="d-flex flex-column"
                v-if="
                  slide4.awardId &&
                    slide4.budgetId &&
                    slide4.awardValue &&
                    (!slide4.awardValue.toString().includes('.') ||
                      (slide4.awardValue.toString().includes('.') &&
                        slide4.awardValue
                          .toString()
                          .substring(
                            slide4.awardValue.toString().indexOf('.') + 1
                          ).length < 3)) &&
                    parseFloat(slide4.awardValue) > 0 &&
                    incentiveBudget
                "
              >
                <div class="d-flex align-center my-2">
                  <span
                    class="large-text align-text-left"
                    v-if="
                      !slide4.preloadedProgram &&
                        permissions.includes('programs:create:program')
                    "
                    >Set a timeframe for this incentive</span
                  >
                  <span class="large-text align-text-left" v-else
                    >The timeframe for this incentive
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="ml-2" color="gray" v-on="on" v-bind="attrs"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <p>
                      Defining a start and end date means everyone has to
                      complete the survey within the same timeframe.
                    </p>
                    <span>
                      Creating a rolling program gives all users X days from
                      their individual start dates to complete the survey.
                    </span>
                  </v-tooltip>
                </div>
                <v-radio-group
                  v-model="slide4.dateOptionSelector"
                  :disabled="
                    slide4.preloadedProgram ||
                      !permissions.includes('programs:create:program')
                  "
                >
                  <v-radio
                    label="Defined Start & End Date"
                    value="date"
                    @click="changeIncentiveDateOption('date')"
                  ></v-radio>
                  <v-radio
                    label="Rolling Program"
                    value="rolling"
                    @click="changeIncentiveDateOption('rolling')"
                  ></v-radio>
                </v-radio-group>
                <div
                  class="d-flex align-start mt-2"
                  v-if="slide4.dateOptionSelector"
                >
                  <v-row>
                    <v-col sm="8" md="6" lg="6" class="align-text-left">
                      <span>Program <b>start</b> date and time</span>
                      <v-menu
                        v-model="slide4.menuStartDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="
                          slide4.preloadedProgram ||
                            !permissions.includes('programs:create:program')
                        "
                        attach
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="slide4.startDate"
                            label="Start Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mt-4"
                            :rules="[v => !!v || 'Start date is required']"
                            :disabled="
                              slide4.preloadedProgram ||
                                !permissions.includes('programs:create:program')
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="slide4.startDate"
                          @input="slide4.menuStartDate = false"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                        ></v-date-picker>
                      </v-menu>

                      <div class="d-flex mt-2">
                        <v-combobox
                          label="Hour"
                          v-model="slide4.startTime.hours"
                          :items="slide4.hours"
                          outlined
                          class="hour-picker mr-2"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="Minutes"
                          v-model="slide4.startTime.minutes"
                          :items="slide4.minutes"
                          outlined
                          class="minutes-picker mr-2"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="AM/PM"
                          v-model="slide4.startTime.amPm"
                          :items="slide4.amPm"
                          outlined
                          class="am-pm-picker"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                      </div>
                      <v-combobox
                        label="Timezone"
                        v-model="slide4.startTime.timezone"
                        :items="calculatedTimezones"
                        item-value="value"
                        item-text="value"
                        outlined
                        class="full-width"
                        :disabled="
                          slide4.preloadedProgram ||
                            !permissions.includes('programs:create:program')
                        "
                        attach
                        :menu-props="{ top: true, offsetY: true }"
                      ></v-combobox>
                      <p v-if="dateValidationIncentive" class="red--text">
                        The program start date must be earlier than the end
                        date!
                      </p>
                      <p
                        v-if="
                          dateValidationComparedToToday &&
                            !slide4.preloadedProgram &&
                            permissions.includes('programs:create:program')
                        "
                        class="red--text mt-3"
                      >
                        The program start date must be in the future!
                      </p>
                    </v-col>
                    <v-col sm="8" md="6" lg="6" class="align-text-left">
                      <div class="d-flex align-center justify-space-between">
                        <span>Program <b>end</b> date and time</span>
                      </div>

                      <v-menu
                        v-if="slide4.dateOptionSelector == 'date'"
                        v-model="slide4.menuEndDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="
                          slide4.preloadedProgram ||
                            !permissions.includes('programs:create:program')
                        "
                        attach
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="slide4.endDate"
                            label="End Date"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mt-4"
                            :rules="[v => !!v || 'End date is required']"
                            :disabled="
                              slide4.preloadedProgram ||
                                !permissions.includes('programs:create:program')
                            "
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="slide4.endDate"
                          @input="slide4.menuEndDate = false"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                        ></v-date-picker>
                      </v-menu>

                      <div
                        class="d-flex mt-2"
                        v-if="slide4.dateOptionSelector == 'date'"
                      >
                        <v-combobox
                          label="Hour"
                          v-model="slide4.endTime.hours"
                          :items="slide4.hours"
                          outlined
                          class="hour-picker mr-2"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="Minutes"
                          v-model="slide4.endTime.minutes"
                          :items="slide4.minutes"
                          outlined
                          class="minutes-picker mr-2"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                        <v-combobox
                          label="AM/PM"
                          v-model="slide4.endTime.amPm"
                          :items="slide4.amPm"
                          outlined
                          class="am-pm-picker"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                          attach
                          :menu-props="{ top: true, offsetY: true }"
                        ></v-combobox>
                      </div>
                      <div v-else-if="slide4.dateOptionSelector == 'rolling'">
                        <v-text-field
                          outlined
                          v-model="slide4.rollingProgramLength"
                          label="Days in rolling program"
                          type="number"
                          class="mt-4"
                          :rules="[
                            v => !!v || 'Number of days is required',
                            v =>
                              !!(v && parseInt(v) > 0) ||
                              'Must be a positive number',
                            v =>
                              !!(v && !v.toString().includes('.')) ||
                              'Must be a whole number'
                          ]"
                          :disabled="
                            slide4.preloadedProgram ||
                              !permissions.includes('programs:create:program')
                          "
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                  <v-icon tabindex="-1" class="pa-2 ml-4 mb-5 hidden"
                    >mdi-pencil</v-icon
                  >
                </div>
                <span class="large-text align-text-left my-2"
                  >Is this program mandatory?</span
                >
                <v-radio-group
                  v-model="slide4.mandatory"
                  :disabled="
                    slide4.preloadedProgram ||
                      !permissions.includes('programs:create:program')
                  "
                >
                  <v-radio label="No" :value="false"></v-radio>
                  <v-radio label="Yes" :value="true"></v-radio>
                </v-radio-group>
              </div>

              <span class="mt-2"
                ><v-icon color="error" class="mr-2">mdi-alert</v-icon>NOTE: You
                will not be able to edit incentive settings once you continue to
                the next page
              </span>
            </div>
          </div> -->
          <div
            v-else-if="slideKey === 6"
            class="d-flex flex-column align-start full-width"
          >
            <div class="align-text-left mb-5">
              <span class="header-text"
                >Create a chapter for your survey. A chapter can house learning
                cards, learning checks, and assessments.</span
              >
            </div>
            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="4"
            >
              <v-form
                ref="learningChapterForm"
                v-model="learningChapter.valid"
                class="full-width"
              >
                <div class="d-flex align-center">
                  <v-text-field
                    ref="titleInput"
                    v-model="learningChapter.name"
                    class="mt-5 mb-2"
                    outlined
                    label="Learning Chapter Title"
                    :required="true"
                    :rules="[
                      v => !!v || 'A title is required!',
                      v =>
                        !!(v && 100 > v.length) ||
                        'Try and make your title shorter!'
                    ]"
                  ></v-text-field>

                  <v-icon
                    dark
                    tabindex="-1"
                    @click="focusOnTitle"
                    class="circle-border-radius pa-2 ml-4 mb-5 cursor-pointer "
                    :class="{
                      'accent-background':
                        learningChapter.name !== null &&
                        learningChapter.name.length > 0,
                      'grey-background':
                        learningChapter.name === null ||
                        (learningChapter.name !== null &&
                          learningChapter.name.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <div class="d-flex align-start mt-2">
                  <vue-editor
                    class="editor full-width"
                    v-model="learningChapter.description"
                    :editorToolbar="customToolbar"
                    :editorOptions="quillOptions"
                    placeholder="Brief chapter description"
                  ></vue-editor>
                  <v-icon
                    dark
                    tabindex="-1"
                    class="circle-border-radius pa-2 ml-4 cursor-pointer"
                    :class="{
                      'accent-background':
                        learningChapter.description !== null &&
                        learningChapter.description.length > 0,
                      'grey-background':
                        learningChapter.description === null ||
                        (learningChapter.description !== null &&
                          learningChapter.description.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <b
                  class="red--text"
                  v-if="
                    learningChapter.description != null &&
                      learningChapter.description.length > 256
                  "
                  >Please shorten your description!</b
                >
              </v-form>
            </v-card>
          </div>
          <div
            v-else-if="slideKey === 7"
            class="d-flex flex-column align-start full-width"
          >
            <div class="align-text-left mb-5">
              <span class="header-text"
                >Edit the learning card that will be displayed after people
                complete the survey.</span
              >
            </div>
            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="4"
            >
              <v-form
                ref="learningCardForm"
                v-model="learningCard.valid"
                class="full-width"
              >
                <div class="d-flex align-center">
                  <v-text-field
                    ref="titleInput"
                    v-model="learningCard.name"
                    class="mt-5 mb-2"
                    outlined
                    label="Learning Card Title"
                    :required="true"
                    :rules="[
                      v => !!v || 'A title is required!',
                      v =>
                        !!(v && 100 > v.length) ||
                        'Try and make your title shorter!'
                    ]"
                  ></v-text-field>
                  <v-icon
                    dark
                    tabindex="-1"
                    @click="focusOnTitle"
                    class="circle-border-radius pa-2 ml-4 mb-5 cursor-pointer "
                    :class="{
                      'accent-background':
                        learningCard.name !== null &&
                        learningCard.name.length > 0,
                      'grey-background':
                        learningCard.name === null ||
                        (learningCard.name !== null &&
                          learningCard.name.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <div class="d-flex align-center">
                  <v-text-field
                    ref="descriptionInput"
                    v-model="learningCard.description"
                    class="mt-5 mb-2"
                    outlined
                    label="Learning Card Description"
                    :required="true"
                    :rules="[
                      v => !!v || 'A description is required!',
                      v =>
                        !!(v && 255 > v.length) ||
                        'Try and make your description shorter!'
                    ]"
                  ></v-text-field>
                  <v-icon
                    dark
                    tabindex="-1"
                    @click="focusOnDescription"
                    class="circle-border-radius pa-2 ml-4 mb-5 cursor-pointer "
                    :class="{
                      'accent-background':
                        learningCard.description !== null &&
                        learningCard.description.length > 0,
                      'grey-background':
                        learningCard.description === null ||
                        (learningCard.description !== null &&
                          learningCard.description.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <div class="d-flex align-center">
                  <v-select
                    v-if="
                      learningCard.type != 'quiz' &&
                        learningCard.type != 'assessment' &&
                        learningCard.type != 'survey'
                    "
                    ref="typeInput"
                    v-model="learningCard.type"
                    class="mt-5 mb-2"
                    outlined
                    label="Learning Card Type"
                    :items="learningCard.typeItems"
                    item-text="label"
                    item-value="value"
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    @change="removeCardImage"
                  ></v-select>
                  <v-select
                    v-else
                    ref="typeInput"
                    v-model="learningCard.type"
                    class="mt-5 mb-2"
                    outlined
                    label="Learning Card Type"
                    :items="learningCard.quizItems"
                    item-text="label"
                    item-value="value"
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    @change="removeCardImage"
                  ></v-select>
                  <v-icon
                    dark
                    tabindex="-1"
                    @click="focusOnTypeSelect"
                    class="circle-border-radius pa-2 ml-4 mb-5 cursor-pointer "
                    :class="{
                      'accent-background':
                        learningCard.name !== null &&
                        learningCard.name.length > 0,
                      'grey-background':
                        learningCard.name === null ||
                        (learningCard.name !== null &&
                          learningCard.name.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <div
                  class="d-flex align-center"
                  v-if="
                    learningCard.type == 'quiz' ||
                      learningCard.type == 'assessment' ||
                      learningCard.type == 'survey'
                  "
                >
                  <v-switch
                    ref="passingRequiredInput"
                    v-model="learningCard.passingRequired"
                    type="number"
                    class="mt-7 mb-2 mr-9 number-field passing-required"
                    outlined
                    label="Passing score required to unlock future cards"
                    suffix="%"
                    :required="false"
                  ></v-switch>

                  <v-text-field
                    v-if="learningCard.passingRequired"
                    ref="passingScoreInput"
                    v-model="learningCard.passingScore"
                    type="number"
                    class="mt-5 mb-2 number-field"
                    outlined
                    label="Passing Score"
                    suffix="%"
                    :rules="[
                      v => !!v || 'A passing score value is required!',
                      v =>
                        !!(v && v > -1) || 'Must be a number between 1 and 100',
                      v =>
                        !!(v && v < 101) || 'Must be a number between 1 and 100'
                    ]"
                  ></v-text-field>
                </div>
                <div
                  class="d-flex align-center"
                  v-if="
                    learningCard.type == 'quiz' ||
                      learningCard.type == 'assessment' ||
                      learningCard.type == 'survey'
                  "
                >
                  <v-switch
                    ref="randomQuestionPoolToggleInput"
                    v-model="learningCard.randomPool"
                    type="number"
                    class="mt-7 mb-2 mr-9 number-field passing-required"
                    outlined
                    label="Populate Survey with random pool?"
                    suffix="%"
                    :required="false"
                  ></v-switch>

                  <v-text-field
                    v-if="learningCard.randomPool"
                    ref="randomQuestionPoolInput"
                    v-model="learningCard.numRandomQuestions"
                    type="number"
                    class="mt-5 mb-2 number-field pool-count"
                    outlined
                    label="Question Pool Count"
                    :required="learningCard.randomPool"
                    :rules="[
                      v => !!v || 'A question pool number is required!',
                      v =>
                        !!(v && v > -1) || 'Must be a number between 1 and 100',
                      v =>
                        !!(v && !v.toString().includes('.')) ||
                        'Must be a whole number'
                    ]"
                  ></v-text-field>
                </div>

                <div class="d-flex align-start mt-2">
                  <vue-editor
                    class="editor full-width"
                    v-model="learningCard.content"
                    :editorToolbar="customToolbarWithVideo"
                    placeholder="Card content"
                  ></vue-editor>
                  <v-icon
                    dark
                    tabindex="-1"
                    class="circle-border-radius pa-2 ml-4 cursor-pointer"
                    :class="{
                      'accent-background':
                        learningCard.content !== null &&
                        learningCard.content.length > 0,
                      'grey-background':
                        learningCard.content === null ||
                        (learningCard.content !== null &&
                          learningCard.content.length === 0)
                    }"
                    >mdi-pencil</v-icon
                  >
                </div>
                <!-- {{ learningCard.content }} -->
                <v-menu max-width="260" offset-x offset-y absolute>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="d-flex align-start justify-space-between full-width image-container mt-5"
                    >
                      <v-img
                        :lazy-src="learningCard.contentUrlSigned"
                        :src="learningCard.contentUrlSigned"
                        :key="learningCard.contentUrlSigned"
                        :aspect-ratio="16 / 9"
                        v-bind="attrs"
                        v-on="on"
                        :width="calculatedImageWidth"
                        class="cursor-pointer"
                        v-if="
                          learningCard.type == 'text' ||
                            learningCard.type == 'quiz' ||
                            learningCard.type == 'assessment' ||
                            learningCard.type == 'survey'
                        "
                        @drop.prevent="dragAndDropImageFileChangedCard"
                        @dragover.prevent
                      >
                        <template v-slot:placeholder>
                          <div
                            class="light-grey-background full-height full-width"
                          >
                            <h3 class=" pt-5 primary--text">
                              Add a banner photo
                            </h3>

                            <p class="mt-10">
                              We suggest an image size of 740 x 416 pixels (Or
                              any image with a 16 : 9 aspect ratio).
                            </p>
                            <p>
                              JPG, PNG files supported
                            </p>
                          </div>
                        </template>
                      </v-img>
                      <div
                        class="light-grey-background full-width cursor-pointer pb-5"
                        v-else-if="learningCard.type == 'audio'"
                        v-bind="attrs"
                        v-on="on"
                        @drop.prevent="dragAndDropImageFileChangedCard"
                        @dragover.prevent
                      >
                        <h3 class=" pt-5 primary--text">
                          Add an audio file
                        </h3>

                        <p class="mt-10">
                          Audio File attached:
                        </p>
                        <p v-if="learningCard.contentUrl == null">
                          No file
                        </p>
                        <p v-else-if="learningCard.image != null">
                          <b> {{ learningCard.image.name }}</b>
                        </p>
                        <p v-else><b>Audio file previously uploaded</b></p>
                      </div>
                      <div
                        class="light-grey-background full-width cursor-pointer pb-5"
                        v-else-if="learningCard.type == 'pdf'"
                        v-bind="attrs"
                        v-on="on"
                        @drop.prevent="dragAndDropImageFileChangedCard"
                        @dragover.prevent
                      >
                        <h3 class=" pt-5 primary--text">
                          Add a PDF
                        </h3>

                        <p class="mt-10">
                          PDF attached:
                        </p>
                        <p v-if="learningCard.contentUrl == null">
                          No file
                        </p>
                        <p v-else-if="learningCard.image != null">
                          <b> {{ learningCard.image.name }}</b>
                        </p>
                        <p v-else><b>PDF previously uploaded</b></p>
                      </div>
                      <div
                        class="d-flex flex-column full-width"
                        v-else
                        @drop.prevent="dragAndDropImageFileChangedCard"
                        @dragover.prevent
                      >
                        <div
                          class="light-grey-background full-width cursor-pointer pb-5"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <h3 class=" pt-5 primary--text">
                            Add a video
                          </h3>

                          <p class="mt-10">
                            We suggest a video of 5 minutes or less, formatted
                            as an .mp4
                          </p>
                          <p v-if="learningCard.contentUrl == null">
                            No video
                          </p>
                          <p v-else-if="learningCard.image != null">
                            <b> {{ learningCard.image.name }}</b>
                          </p>
                          <p v-else>
                            <b>{{
                              learningCard.contentUrl.substring(
                                learningCard.contentUrl.lastIndexOf("/") + 1
                              )
                            }}</b>
                          </p>
                        </div>
                        <h3 v-if="learningCard.image != null" class="mt-4 mb-2">
                          Video preview:
                        </h3>
                        <div
                          class="mt-5"
                          v-if="
                            learningCard.contentUrlSigned != null &&
                              (learningCard.image != null ||
                                learningCard.contentUrlSigned.startsWith(
                                  'blob:'
                                ) ||
                                (learningCard.Content != undefined &&
                                  learningCard.Content != null &&
                                  learningCard.Content.status != 'Processing'))
                          "
                        >
                          <!-- <iframe
                            id="ytplayer"
                            width="600"
                            height="360"
                            :src="learningCard.contentUrlSigned"
                          ></iframe> -->
                          <video-player
                            ref="videoPlayer"
                            class=" vjs-custom-skin"
                            :playsinline="true"
                            :options="playerOptions"
                          />
                        </div>
                        <div
                          class="mt-5"
                          v-else-if="
                            learningCard.Content != undefined &&
                              learningCard.Content != null &&
                              learningCard.Content.status == 'Processing' &&
                              learningCard.image == null
                          "
                        >
                          <h3>
                            This video is currently processing so a preview is
                            unavailable
                          </h3>
                          <lottie
                            :options="lottieOptions"
                            :height="170"
                            :width="170"
                            v-on:animCreated="handleAnimation"
                          />
                        </div>
                      </div>

                      <!-- <v-img
                        :lazy-src="learningCard.contentUrl"
                        :src="learningCard.contentUrl"
                        :aspect-ratio="16 / 9"
                        v-bind="attrs"
                        v-on="on"
                        :width="calculatedImageWidth"
                        class="cursor-pointer"
                        v-else-if="learningCard.type == 'audio'"
                      >
                        <template v-slot:placeholder>
                          <div
                            class="light-grey-background full-height full-width"
                          >
                            <h3 class=" pt-5 primary--text">
                              Add an audio file
                            </h3>

                            <p class="mt-10">
                              We suggest an image size of 740 x 416 pixels (Or
                              any image with a 16 : 9 aspect ratio).
                            </p>
                            <p>
                              JPG, PNG files supported
                            </p>
                          </div>
                        </template>
                      </v-img> -->
                      <!-- <v-img
                        :lazy-src="learningCard.contentUrl"
                        :src="learningCard.contentUrl"
                        :aspect-ratio="16 / 9"
                        v-bind="attrs"
                        v-on="on"
                        :width="calculatedImageWidth"
                        class="cursor-pointer"
                        v-else-if="learningCard.type == 'pdf'"
                      >
                        <template v-slot:placeholder>
                          <div
                            class="light-grey-background full-height full-width"
                          >
                            <h3 class=" pt-5 primary--text">
                              Add a PDF
                            </h3>

                            <p class="mt-10">
                              We suggest an image size of 740 x 416 pixels (Or
                              any image with a 16 : 9 aspect ratio).
                            </p>
                            <p>
                              JPG, PNG files supported
                            </p>
                          </div>
                        </template>
                      </v-img>
                      <v-img
                        :lazy-src="learningCard.contentUrl"
                        :src="learningCard.contentUrl"
                        :aspect-ratio="16 / 9"
                        v-bind="attrs"
                        v-on="on"
                        :width="calculatedImageWidth"
                        class="cursor-pointer"
                        v-else
                      >
                        <template v-slot:placeholder>
                          <div
                            class="light-grey-background full-height full-width"
                          >
                            <h3 class=" pt-5 primary--text">
                              Add a video
                            </h3>

                            <p class="mt-10">
                              We suggest a video of 5 minutes or less, saved in
                              MPEG 4 format
                            </p>
                          </div>
                        </template>
                      </v-img> -->
                      <v-icon
                        dark
                        tabindex="-1"
                        v-bind="attrs"
                        v-on="on"
                        class="circle-border-radius pa-2 ml-4 mb-5 cursor-pointer"
                        :class="{
                          'accent-background': learningCard.contentUrl !== null,
                          'grey-background': learningCard.contentUrl === null
                        }"
                        >mdi-pencil</v-icon
                      >
                    </div>
                  </template>
                  <v-list>
                    <input
                      id="learningCardUploader"
                      ref="learningCardUploader"
                      class="d-none"
                      :class="{
                        'image/*': learningCard.type != 'video',
                        'video/*': learningCard.type == 'video'
                      }"
                      type="file"
                      :accept="{
                        'image/*': learningCard.type != 'video',
                        'video/*': learningCard.type == 'video'
                      }"
                      @change="imageFileChangedCard"
                      @blur="imageFileChangedCard"
                    />
                    <v-list-item @click="uploadImage('learningCard')">
                      <v-list-item-title class="primary--text align-text-left"
                        ><v-icon color="black" class="mr-2">mdi-upload</v-icon
                        >Upload from computer</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="learningModule.dialogImageUrl = true">
                      <v-list-item-title class="primary--text align-text-left"
                        ><v-icon color="black" class="mr-2">mdi-link</v-icon>By
                        URL</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="removeCardImage">
                      <v-list-item-title class="primary--text align-text-left"
                        ><v-icon color="black" class="mr-2">mdi-delete</v-icon
                        >Remove file</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-form>
            </v-card>
          </div>
          <!-- Question Builder -->
          <div
            v-else-if="slideKey === 8"
            class="d-flex flex-column align-start full-width"
          >
            <span class="header-text"
              >Add questions and answers to your survey!</span
            >
            <v-card
              class="d-flex flex-column align-start full-width pa-5"
              rounded="0"
              elevation="4"
            >
              <v-form
                ref="learningCardForm"
                v-model="learningCard.valid"
                class="full-width"
              >
                <v-container>
                  <v-row
                    v-for="(question, i) in learningCardQuestions"
                    :key="i"
                  >
                    <v-container>
                      <v-row>
                        <v-col lg="3" md="4" sm="12" cols="12" class="d-flex">
                          <v-btn
                            icon
                            small
                            @click="removeQuestion(i)"
                            class="mt-2 mr-2"
                            ><v-icon color="red">mdi-close</v-icon></v-btn
                          >
                          <v-btn outlined large class="question-button px-3"
                            >Question
                            <div
                              class="circle-border-radius purple-background ml-3 question-counter-bubble d-flex align-center"
                            >
                              <span
                                class="question-counter cursor-pointer pt-1"
                                >{{ i + 1 }}</span
                              >
                            </div>
                          </v-btn>
                        </v-col>

                        <v-col
                          xl="9"
                          lg="8"
                          md="8"
                          sm="12"
                          cols="12"
                          offset-lg="1"
                          offset-xl="0"
                        >
                          <v-textarea
                            ref="questionInput"
                            v-model="question.content"
                            class=""
                            outlined
                            hide-details="auto"
                            label="Question Content"
                            height="100"
                            :required="true"
                            :rules="[
                              v => !!v || 'content is required!',
                              v =>
                                !!(v && 751 > v.length) ||
                                'Try and make your content shorter!'
                            ]"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="d-flex" cols="12" sm="6">
                          <!-- <v-checkbox
                            v-model="question.hasProgramMetric"
                            label="
                              Will the responses to this question be measured in a program?
                            "
                            v-if="isSurveyPrivate"
                          ></v-checkbox> -->
                        </v-col>
                        <v-col class="d-flex mt-2" cols="12" sm="6">
                          <v-select
                            :items="questionTypes"
                            v-model="question.type"
                            @change="resetAnswers(i, question.type)"
                            label="Question Type"
                            dense
                            outlined
                            attach
                            :menu-props="{ offsetY: true }"
                          ></v-select>
                        </v-col>
                      </v-row>

                      <v-row
                        v-if="
                          question.type == 'MULTIPLE_CHOICE' ||
                            question.type == 'SELECT_ALL'
                        "
                      >
                        <div
                          v-for="(answer, index) in question.answers"
                          :key="answer.learningQuizAnswerId"
                          class="d-flex full-width"
                        >
                          <v-col
                            md="4"
                            xl="4"
                            class="answer-counter circle-border-radius text-right mt-3"
                          >
                            <v-btn
                              icon
                              small
                              @click="removeAnswer(i, index)"
                              class="mb-1 mr-3"
                              ><v-icon color="red">mdi-close</v-icon></v-btn
                            >
                            <span> {{ i + 1 }}{{ alphabet[index] }} </span>
                          </v-col>
                          <v-col xl="7" lg="7" md="7">
                            <v-text-field
                              ref="answerInput"
                              v-model="answer.content"
                              class="mb-2"
                              outlined
                              hide-details="auto"
                              label="Answer"
                              height="50"
                              :required="false"
                              :rules="[
                                v =>
                                  !!(v && 256 > v.length) ||
                                  'Try and make your answer shorter!'
                              ]"
                            ></v-text-field>
                          </v-col>
                          <!-- <v-col md="1">
                            <v-checkbox v-model="answer.correctness">
                            </v-checkbox>
                          </v-col> -->
                        </div>
                        <v-btn
                          outlined
                          large
                          class="question-button py-3 mx-auto mb-6"
                          @click="addAnswer(i)"
                          ><v-icon
                            color="white"
                            class="circle-border-radius cursor-pointer accent-background mr-2"
                            >mdi-plus</v-icon
                          >
                          Answer</v-btn
                        >
                      </v-row>

                      <v-row v-else-if="question.type == 'SCALE'">
                        <v-container>
                          <v-row>
                            <div class="d-flex full-width">
                              <v-col
                                md="4"
                                xl="4"
                                class="answer-counter circle-border-radius text-right mt-3"
                              >
                              </v-col>
                              <v-col xl="7" lg="7" md="7">
                                <v-text-field
                                  ref="lowerBound"
                                  v-model="question.lowerBound"
                                  class="mb-2"
                                  outlined
                                  hide-details="auto"
                                  label="Lower Bound"
                                  height="50"
                                  type="number"
                                  :required="false"
                                  :rules="[
                                    v => !!v || 'A lower bound is required!',
                                    v =>
                                      !!(v && !v.toString().includes('.')) ||
                                      'Must be a whole number',
                                    v =>
                                      !!(
                                        v &&
                                        (question.upperBound == null ||
                                          parseInt(v) <
                                            parseInt(question.upperBound))
                                      ) ||
                                      'Must be a number less than the upper bound',
                                    v =>
                                      !!(
                                        v &&
                                        (question.upperBound == null ||
                                          parseInt(question.upperBound) -
                                            parseInt(v) <=
                                            1000)
                                      ) ||
                                      'The range should be less than 1,000 apart'
                                  ]"
                                ></v-text-field>
                              </v-col></div
                          ></v-row>

                          <v-row>
                            <div class="d-flex full-width">
                              <v-col
                                md="4"
                                xl="4"
                                class="answer-counter circle-border-radius text-right mt-3"
                              >
                              </v-col>
                              <v-col xl="7" lg="7" md="7">
                                <v-text-field
                                  ref="upperBound"
                                  v-model="question.upperBound"
                                  class="mb-2"
                                  outlined
                                  hide-details="auto"
                                  label="Upper Bound"
                                  height="50"
                                  type="number"
                                  :required="false"
                                  :rules="[
                                    v => !!v || 'An upper bound is required!',
                                    v =>
                                      !!(v && !v.toString().includes('.')) ||
                                      'Must be a whole number',
                                    v =>
                                      !!(
                                        v &&
                                        parseInt(v) >
                                          parseInt(question.lowerBound)
                                      ) ||
                                      'Must be a number greater than the lower bound',
                                    v =>
                                      !!(
                                        v &&
                                        (question.lowerBound == null ||
                                          parseInt(v) -
                                            parseInt(question.lowerBound) <=
                                            1000)
                                      ) ||
                                      'The range should be less than 1,000 apart'
                                  ]"
                                ></v-text-field>
                              </v-col>
                            </div>
                          </v-row>
                        </v-container>
                      </v-row>
                      <v-divider class="pb-3" />
                    </v-container>
                  </v-row>

                  <v-btn
                    outlined
                    large
                    class="question-button py-3"
                    @click="newQuestion()"
                    ><v-icon
                      color="white"
                      class="circle-border-radius cursor-pointer purple-background mr-2 "
                      >mdi-plus</v-icon
                    >
                    Question</v-btn
                  >
                </v-container>
              </v-form>
            </v-card>
          </div>
          <v-container class="mt-4 d-flex align-center justify-space-between">
            <div class="d-flex"></div>
            <v-tooltip
              top
              v-if="slideKey <= 3"
              :disabled="!disableContinueButton || slideKey > 3"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    :loading="savingChanges"
                    @click="saveLearningModule"
                    :disabled="disableContinueButton"
                    >Continue</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 1">
                <span
                  v-if="
                    learningModule.name == null ||
                      (learningModule.name != null &&
                        learningModule.name.length == 0)
                  "
                  >A name is required for this survey!</span
                >
                <span
                  v-else-if="
                    learningModule.description == null ||
                      (learningModule.description != null &&
                        learningModule.description.length == 0)
                  "
                  >A brief description is required for this survey!</span
                >
                <span
                  v-else-if="
                    learningModule.imageUrl == null ||
                      (learningModule.imageUrl != null &&
                        learningModule.imageUrl.length == 0)
                  "
                  >A banner image is required for this survey!</span
                >
              </div>
              <div v-if="slideKey == 2">
                <span v-if="slide2.selectedGroups.length == 0"
                  >An option must be selected for the groups that can view this
                  survey!</span
                >
              </div>
              <div v-if="slideKey == 3">
                <span v-if="slide3.selectedBadges.length == 0"
                  >An option must be selected regarding badges for this
                  survey!</span
                >
              </div>
            </v-tooltip>
            <!-- <v-tooltip
              top
              v-if="slideKey == 4"
              :disabled="!disableContinueButton"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    :loading="savingChanges"
                    @click="saveLearningProgram"
                    :disabled="disableContinueButton"
                    >Continue</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 4">
                <span v-if="slide4.incentModule && !slide4.awardId"
                  >An award is required for this incentive!</span
                >
                <span v-else-if="slide4.incentModule && !slide4.awardValue"
                  >An award value is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.awardValue &&
                      slide4.awardValue.toString().includes('.') &&
                      slide4.awardValue
                        .toString()
                        .substring(
                          slide4.awardValue.toString().indexOf('.') + 1
                        ).length > 2
                  "
                  >The award value should not be longer than 2 decimals</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.awardValue &&
                      parseFloat(slide4.awardValue) <= 0
                  "
                  >The award value should be a number greater than 0 for this
                  incentive!</span
                >
                <span v-else-if="slide4.incentModule && !slide4.budgetId"
                  >A budget is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.budgetId &&
                      slide4.awardValue &&
                      incentiveBudget &&
                      incentiveBudget.budgetBalance &&
                      parseFloat(incentiveBudget.budgetBalance) > 0 &&
                      parseFloat(incentiveBudget.budgetBalance) <
                        parseFloat(slide4.awardValue)
                  "
                  >The award value should be less than the budget balance for
                  this incentive!</span
                >
                <span
                  v-else-if="slide4.incentModule && !slide4.dateOptionSelector"
                  >A timeframe must be chosen for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      (!slide4.startDate ||
                        !slide4.startTime.hours ||
                        !slide4.startTime.minutes ||
                        !slide4.startTime.amPm ||
                        !slide4.startTime.timezone)
                  "
                  >A start date & time is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule && dateValidationComparedToToday
                  "
                  >The start date must be set for the future!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'date' &&
                      (!slide4.endDate ||
                        !slide4.endTime.hours ||
                        !slide4.endTime.minutes ||
                        !slide4.endTime.amPm)
                  "
                  >An end date & time is required for this incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'date' &&
                      dateValidationIncentive
                  "
                  >The end date must be later than the start date!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'rolling' &&
                      !slide4.rollingProgramLength
                  "
                  >A rolling program length is required for this
                  incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'rolling' &&
                      slide4.rollingProgramLength &&
                      parseInt(slide4.rollingProgramLength) <= 0
                  "
                  >A rolling program length must be greater than 0 for this
                  incentive!</span
                >
                <span
                  v-else-if="
                    slide4.incentModule &&
                      slide4.dateOptionSelector == 'rolling' &&
                      slide4.rollingProgramLength &&
                      slide4.rollingProgramLength.toString().includes('.')
                  "
                  >A rolling program length must be a whole number for this
                  incentive!</span
                >
              </div>
            </v-tooltip> -->
            <v-tooltip
              top
              v-if="slideKey == 5"
              :disabled="
                !(
                  JSON.stringify(learningModule).includes(
                    `&quot;learningCards&quot;:[]`
                  ) || learningModule.learningChapters.length <= 1
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    @click="dialogPublishMessage = true"
                    :disabled="
                      JSON.stringify(learningModule).includes(
                        `&quot;learningCards&quot;:[]`
                      ) || learningModule.learningChapters.length <= 1
                    "
                    v-if="slideKey == 5"
                    :loading="savingChanges"
                    >Publish Module</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 5">
                <span v-if="learningModule.learningChapters.length <= 1"
                  >Be sure to add some chapters to this survey before
                  publishing!</span
                >
                <span
                  v-else-if="
                    JSON.stringify(learningModule).includes(
                      `&quot;learningCards&quot;:[]`
                    )
                  "
                  >Make sure there aren't any empty chapters! Add some learning
                  cards or surveys to publish.</span
                >
              </div>
            </v-tooltip>
            <v-tooltip
              top
              v-if="slideKey == 7"
              :disabled="!disableContinueButton"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    @click="saveLearningCard"
                    :disabled="disableContinueButton"
                    v-if="slideKey == 7"
                    :loading="savingChanges"
                    ><span>Save & Publish</span></v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 7">
                <span
                  v-if="
                    learningCard.name == null ||
                      (learningCard.name !== null &&
                        learningCard.name.length == 0)
                  "
                  >A name for this card is required!</span
                >
                <span
                  v-else-if="
                    learningCard.description == null ||
                      (learningCard.description !== null &&
                        learningCard.description.length == 0)
                  "
                  >A description for this card is required!</span
                >
              </div>
            </v-tooltip>
            <v-tooltip
              top
              v-if="slideKey == 8"
              :disabled="
                !(checkIfQuizIsValid || learningCardQuestions.length == 0)
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                    color="primary"
                    @click="saveLearningQuiz"
                    :disabled="
                      checkIfQuizIsValid || learningCardQuestions.length == 0
                    "
                    v-if="slideKey == 8"
                    :loading="savingChanges"
                    >Save Survey</v-btn
                  >
                </div>
              </template>
              <div v-if="slideKey == 8">
                <span v-if="learningCardQuestions.length == 0"
                  >Survey questions are required to save this survey!</span
                >
                <span v-else-if="checkIfQuizIsValid"
                  >Make sure your survey questions and answers are filled
                  out!</span
                >
              </div>
            </v-tooltip>
          </v-container>
        </v-col>
      </div>
      <!-- Image URL dialog -->
      <v-dialog persistent v-model="learningModule.dialogImageUrl" width="400">
        <v-card rounded="0">
          <v-card-title class="word-break">
            Please enter the URL of the image
          </v-card-title>
          <v-text-field
            v-model="learningModule.imageUrlTemp"
            class="my-4 mx-6"
            outlined
            dense
            label="Image URL"
          ></v-text-field>
          <v-card-actions v-if="slideKey == 1">
            <v-spacer />
            <v-btn text @click="closeImageUrlDialog(false)">Cancel</v-btn>
            <v-btn text color="primary" @click="closeImageUrlDialog(true)"
              >Submit</v-btn
            >
          </v-card-actions>
          <v-card-actions v-else>
            <v-spacer />
            <v-btn text @click="closeCardImageUrlDialog(false)">Cancel</v-btn>
            <v-btn text color="primary" @click="closeCardImageUrlDialog(true)"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Module Image Cropping dialog -->
      <v-dialog
        persistent
        v-model="learningModule.dialogImageCropper"
        width="600"
      >
        <v-card rounded="0" class="px-12">
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-camera</v-icon>
            <v-card-title class="word-break px-0 mx-0">
              Please crop the image below to a 16 : 9 aspect ratio.
            </v-card-title>
          </div>
          <cropper
            ref="cropper"
            :src="learningModule.imageUrlToCrop"
            :stencil-props="{
              aspectRatio: 16 / 9
            }"
          />
          <v-card-actions class="pt-4">
            <v-spacer />
            <v-btn text @click="closeImageCropDialog(false)">Cancel</v-btn>
            <v-btn text color="primary" @click="closeImageCropDialog(true)"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Learning Card Image Cropping dialog -->
      <v-dialog persistent v-model="dialogImageCropperCard" width="600">
        <v-card rounded="0" class="px-12">
          <v-card-title class="word-break px-0 mx-0">
            <v-icon class="mr-2">mdi-camera</v-icon>Feel free to crop the image
            before uploading.
          </v-card-title>
          <cropper ref="cardCropper" :src="learningCard.contentUrlToCrop" />
          <v-card-actions class="pt-4">
            <v-spacer />
            <v-btn text @click="closeCardImageCropDialog(false)">Cancel</v-btn>
            <v-btn text color="primary" @click="closeCardImageCropDialog(true)"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Card delete dialog -->
      <v-dialog v-model="deleteCardConfirmation.dialog" width="500">
        <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
          <div class="d-flex justify-space-between align-center mx-2 mb-2">
            <v-icon color="error" x-large class="exit-warning-icon mr-2"
              >mdi-alert</v-icon
            >
            <v-card-title class="word-break align-text-left exit-warning-text">
              Are you sure you want to delete this card?
            </v-card-title>
          </div>
          <v-card-subtitle class="word-break mb-3"
            >This deletes any media or surveys associated with
            it</v-card-subtitle
          >
          <v-card-actions class="mx-8">
            <v-btn
              color="primary"
              @click="
                deleteCardConfirmation = {
                  dialog: false,
                  chapterIndex: null,
                  cardIndex: null
                }
              "
              outlined
              width="170"
              >Cancel</v-btn
            >
            <v-spacer />
            <v-btn color="primary" @click="removeCard" width="170"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Chapter delete dialog -->
      <v-dialog v-model="deleteChapterConfirmation.dialog" width="500">
        <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
          <div class="d-flex justify-space-between align-center mx-2 mb-2">
            <v-icon color="error" x-large class="exit-warning-icon mr-2"
              >mdi-alert</v-icon
            >
            <v-card-title class="word-break align-text-left exit-warning-text">
              Are you sure you want to delete this chapter?
            </v-card-title>
          </div>
          <!-- <v-card-subtitle class="word-break mb-3"
            >This deletes all cards, media, and quizzes associated with
            it</v-card-subtitle
          > -->

          <v-card-actions class="mx-8">
            <v-btn
              color="primary"
              @click="
                deleteChapterConfirmation = {
                  dialog: false,
                  chapterIndex: null
                }
              "
              outlined
              width="170"
              >Cancel</v-btn
            >
            <v-spacer />
            <v-btn color="primary" @click="removeChapter" width="170"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Publish dialog -->
      <v-dialog v-model="dialogPublishMessage" width="500">
        <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
          <div class="d-flex justify-space-between align-center mx-2 mb-5">
            <v-icon color="error" x-large class="exit-warning-icon mr-2"
              >mdi-alert</v-icon
            >
            <v-card-title class="word-break align-text-left exit-warning-text">
              Are you sure you're ready to publish?
            </v-card-title>
          </div>

          <v-card-actions class="mx-8">
            <v-btn
              color="primary"
              @click="dialogPublishMessage = false"
              outlined
              width="170"
              >Make Edits</v-btn
            >
            <v-spacer />
            <v-btn color="primary" @click="publishLearningModule" width="170"
              >Publish and exit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Confirmation dialog -->
      <v-dialog v-model="dialogExitMessage" width="500">
        <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
          <div class="d-flex justify-space-between align-center mx-2 mb-5">
            <v-icon color="error" x-large class="exit-warning-icon mr-4"
              >mdi-alert</v-icon
            >
            <v-card-title class="word-break align-text-left exit-warning-text">
              Note: Any changes that have not been saved will be lost!
            </v-card-title>
          </div>

          <v-card-actions class="mx-12">
            <v-btn
              color="primary"
              @click="dialogExitMessage = false"
              outlined
              width="130"
              >Don't exit</v-btn
            >
            <v-spacer />
            <v-btn color="primary" @click="closeLearningBuilder" width="130"
              >Exit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- copy publick URL V-Dialog -->
      <v-dialog v-model="showUrlDialog" persistent width="500">
        <v-card class="py-4">
          <v-card-text
            class="d-flex flex-column justify-center align-center"
            id="urlDialog"
          >
            <h2>Your survey is now live at this URL:</h2>

            <v-btn
              rounded
              color="teal"
              class="ma-2 mt-4 white--text"
              @click="copyToClip"
            >
              <span>{{ computedUrl }}</span>
              <v-icon right dark>
                mdi-content-copy
              </v-icon>
            </v-btn>

            <lottie
              :options="lottieOptions2"
              :height="170"
              :width="170"
              v-on:animCreated="handleAnimation"
            />

            <v-btn color="primary" @click="closeLearningBuilder"
              ><span>Finish</span></v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>

      <!--learning card Exit V-Dialog-->
      <v-dialog v-model="dialogExitQuizMessage" width="500">
        <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
          <div class="d-flex justify-space-between align-center mx-2 mb-5">
            <v-icon color="error" x-large class="exit-warning-icon mr-4"
              >mdi-alert</v-icon
            >
            <v-card-title class="word-break align-text-left exit-warning-text">
              Note: Any changes that have not been saved for this survey will be
              lost!
            </v-card-title>
          </div>

          <v-card-actions class="mx-12">
            <v-btn
              color="primary"
              @click="dialogExitQuizMessage = false"
              outlined
              width="140"
              >Don't Exit</v-btn
            >
            <v-spacer />
            <v-btn color="primary" @click="goBack" width="130">Exit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer
        v-model="slide2.createGroupWidget"
        temporary
        fixed
        right
        width="500"
      >
        <GroupCreatorWidget
          v-if="slide2.createGroupWidget"
          @get-groups="getGroups($event, true)"
          @close="slide2.createGroupWidget = false"
          :clientId="clientId"
          :version="3"
          source="SURVEYS"
        />
      </v-navigation-drawer>

      <v-navigation-drawer
        v-model="slide3.createBadgeWidget"
        temporary
        fixed
        right
        width="500"
      >
        <BadgeCreatorWidget
          v-if="slide3.createBadgeWidget"
          @close="closeBadgeCreatorWidget"
          @refreshBadges="getBadges"
          :clientId="clientId"
          source="SURVEYS"
        />
      </v-navigation-drawer>

      <v-navigation-drawer
        v-model="slide4.createAwardWidget"
        temporary
        fixed
        right
        width="500"
      >
        <AwardCreatorWidget
          v-if="slide4.createAwardWidget"
          @close="slide4.createAwardWidget = false"
          @refreshAwards="getAwards"
          @getPartners="getAwardFulfillmentPartners"
          :editingAward="false"
          :clientId="clientId"
          :awardFulfillmentPartners="slide4.awardFulfillmentPartners"
          source="SURVEYS"
        />
      </v-navigation-drawer>

      <v-navigation-drawer
        v-model="slide4.createBudgetWidget"
        temporary
        fixed
        right
        width="500"
      >
        <BudgetCreatorWidget
          v-if="slide4.createBudgetWidget"
          @close="slide4.createBudgetWidget = false"
          @get-budgets="getBudgets"
          @getPartners="getAwardFulfillmentPartners"
          :showBudgetWarning="false"
          :clientId="clientId"
          :editingBudget="false"
          :budgets="slide4.budgets"
          :awardFulfillmentPartners="slide4.awardFulfillmentPartners"
          :preloadAwardId="slide4.awardId"
          source="SURVEYS"
        />
      </v-navigation-drawer>

      <v-overlay
        :value="
          slide2.createGroupWidget ||
            slide4.createAwardWidget ||
            slide3.createBadgeWidget ||
            slide4.createBudgetWidget
        "
      ></v-overlay>
    </v-card>
    <!-- Dialog used to block user with low subscription -->
    <v-dialog
      v-if="subscriptionDialog"
      v-model="subscriptionDialog"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center flex-column text-left py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center mx-n4">
          <v-card-title class="word-break text-left">
            Time to upgrade your Whistle subscription!
          </v-card-title>
        </div>
        <p class="py-2 text-left" key="0">
          Looks like your current subscription level,
          {{ subscription.name }}, has a limit of
          {{ subscription.limit }}
          {{ subscription.limit == 1 ? "survey" : "surveys" }}.<br />Please
          contact us if you're interested in upgrading your plan!
        </p>
        <p>
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p>Phone (Toll Free) - <b>(855) 264-3329</b></p>
        <!-- Buttons -->
        <div>
          <v-card-actions class="mx-8">
            <v-btn
              color="primary"
              width="170"
              class="mx-auto"
              @click="
                () => {
                  $router.push({
                    name: 'programadmin',
                    params: {
                      preload: 'surveys'
                    }
                  });
                }
              "
              >Close</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- Dialog used while loading in the learning module -->
    <v-dialog
      v-if="loadingModule"
      v-model="loadingModule"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center align-center flex-column py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-center">
            {{
              loadingModuleError
                ? "We had trouble loading your survey"
                : "Loading your survey"
            }}
          </v-card-title>
        </div>
        <div v-if="loadingModuleError" class="text-left mx-6 my-6">
          We couldn't find the survey you are trying to edit. Please try again
          or contact us if you need further assistance.
        </div>
        <div v-else>
          <v-progress-circular
            :size="60"
            :width="5"
            color="brandCyan"
            indeterminate
            class="my-5"
          ></v-progress-circular>
          <p class="py-2 mx-10 text-left">
            Please wait while we load your survey into the editor...
          </p>
        </div>
        <!-- Buttons -->
        <div v-if="loadingModuleError">
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              outlined
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              @click="
                () => {
                  $router.push({
                    name: 'programadmin',
                    params: {
                      preload: 'surveys'
                    }
                  });
                }
              "
              >Exit</v-btn
            >
            <v-btn
              color="brandCyan"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              depressed
              @click="loadBuilder"
              class="white--text"
              >Retry</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      :multi-line="true"
      :v-html="snackbarText"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
  <!-- The end of the whole full page modal. Don't put stuff past this -->
</template>

<script>
import ActivityFeedCard from "@/components/ActivityFeedCard.vue";
import GroupService from "../services/GroupService";
import BadgeService from "../services/BadgeService";
import LearningService from "../services/LearningService";
import ContentService from "../services/ContentService";
import BudgetService from "../services/BudgetService";
import ProgramService from "../services/ProgramService";

import GroupCreatorWidget from "@/components/groups/GroupTypeSelector.vue";
import BadgeCreatorWidget from "@/components/BadgeCreatorWidget.vue";
import AwardCreatorWidget from "@/components/AwardCreatorWidget.vue";
import BudgetCreatorWidget from "@/components/BudgetCreatorWidget.vue";
import GroupTable from "@/components/data-table/Groups.vue";

import { debounce } from "@/shared_data/functions";

import momentTz from "moment-timezone";
import { mapState } from "vuex";

import { VueEditor, Quill } from "vue2-editor";
Quill.register(Quill.import("attributors/style/direction"), true);
Quill.register(Quill.import("attributors/style/align"), true);

import LoadingDialog from "../components/loadingDialog.vue";
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Eating.json";
import * as danceAnimationData from "@/assets/robin/Egypt.json";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import {
  // formatEmailTemplate,
  truncateNumber,
  numberWithCommas
} from "@/shared_data/functions.js";
// import { emailTemplate } from "@/shared_data/template_v2.js";
import {
  hours,
  amPm,
  minutes,
  alphabetUpperCase,
  editorToolbar,
  editorToolbarWithImages,
  editorOptions
} from "@/shared_data/data";

export default {
  name: "SurveyBuilder",
  title: "Whistle | Survey Builder",
  components: {
    ActivityFeedCard,
    VueEditor,
    GroupCreatorWidget,
    BadgeCreatorWidget,
    AwardCreatorWidget,
    BudgetCreatorWidget,
    Lottie,
    LoadingDialog,
    Cropper,
    GroupTable
  },
  props: {
    // preloadedModule: Object,
    editingLearning: Boolean,
    preloadedClientId: Number,
    learningModuleId: Number,
    // Used so we can navigate back to program admin if that's where we came from
    previousRoute: {
      type: String,
      default: null
    },
    previousRouteProps: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },
      lottieOptions2: {
        animationData: danceAnimationData.default,
        loop: true,
        autoplay: true
      },
      anim: null,
      showLoadingDialog: false,
      showUrlDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,

      snackbar: false,
      snackbarTimeout: 6000,
      snackbarText: "Copied URL to clipboard",

      slideKey: 1,
      subscription: {},
      subscriptionDialog: false,
      dialogExitMessage: false,
      loadingModule: false,
      loadingModuleError: false,
      dialogExitQuizMessage: false,
      dialogPublishMessage: false,
      dialogImageCropperCard: false,
      groupSelector: null,
      badgeSelector: null,
      alphabet: alphabetUpperCase,
      customToolbar: editorToolbar,
      customToolbarWithVideo: editorToolbarWithImages,
      quillOptions: editorOptions,
      savingChanges: false,
      hours: hours,
      minutes: minutes,
      amPm: amPm,
      learningModule: {
        learningModuleId: null,
        learningChapters: [
          {
            valid: false,
            learningChapterId: null,
            name: "Survey",
            displayName: "Survey",
            description: null,
            imageUrl: null,
            learningModuleId: null,
            image: null,
            order: 0,
            selectingImageFlag: false,
            learningCards: [
              {
                valid: false,
                learningChapterId: null,
                learningCardId: null,
                type: "survey",
                name: "Survey Card",
                displayName: "Survey Card",
                description: "This is the survey",
                content: "<p>Survey Content</p>",
                contentUrl: null,
                contentUrlFileName: null,
                contentUrlSigned: null,
                image: null,
                selectingImageFlag: false,
                passingScore: null,
                passingRequired: null,
                randomPool: null,
                numRandomQuestions: null,
                learningCardQuestions: [],
                repeatable: false
              }
            ]
          },
          {
            valid: false,
            learningChapterId: null,
            name: "Conclusion",
            displayName: "Conclusion",
            description: null,
            imageUrl: null,
            learningModuleId: null,
            image: null,
            order: -1,
            selectingImageFlag: false,
            learningCards: [
              {
                valid: false,
                learningChapterId: null,
                learningCardId: null,
                type: "text",
                name: "You've finished the survey!",
                displayName: "You've finished the survey!",
                description: "This will appear at the end of the survey.",
                content:
                  "<p>You've finished the survey, well done!</p><p>Check out the Programs page for other available surveys and learning modules.</p>",
                contentUrl: null,
                contentUrlFileName: null,
                contentUrlSigned: null,
                image: null,
                selectingImageFlag: false,
                passingScore: null,
                passingRequired: null,
                randomPool: null,
                numRandomQuestions: null,
                learningCardQuestions: []
              }
            ]
          }
        ],
        name: null,
        valid: false,
        status: "Draft",
        // for computed disableChangeVisibility variable, the module's status outside of the builder
        moduleStatus: null,
        dialogImageUrl: false,
        imageUrlTemp: null,
        image: null,
        imageUrl: null,
        imageUrlFileName: null,
        imageUrlToCrop: null,
        imageUrlSigned: null,
        includeImageInCard: false,
        selectingImageFlag: false,
        description: null,
        bannerContentId: null,
        // startDate: null,
        menuStartDate: false,
        forceLinearProgress: false,
        // displayDate: false,
        // startTime: {
        //   hours: null,
        //   minutes: null,
        //   timezone: null,
        //   amPm: null
        // },
        // endDate: null,
        // menuEndDate: false,
        // endTime: {
        //   hours: null,
        //   minutes: null,
        //   amPm: null
        // },

        visibilityType: null
      },
      learningChapter: {
        valid: false,
        learningChapterId: null,
        name: null,
        displayName: null,
        description: null,
        imageUrl: null,
        learningModuleId: null,
        image: null,
        selectingImageFlag: false,
        learningCards: Array()
      },
      learningCard: {
        valid: false,
        learningChapterId: null,
        learningChapterIndex: null,
        learningCardIndex: null,
        learningCardId: null,
        type: "text",
        typeItems: [
          { label: "Text/Image", value: "text" },
          { label: "Video", value: "video" },
          { label: "Audio", value: "audio" },
          { label: "PDF", value: "pdf" }
        ],
        quizItems: [
          { label: "Quiz", value: "quiz" },
          { label: "Assessment", value: "assessment" },
          { label: "Survey", value: "survey" }
        ],
        name: null,
        displayName: null,
        description: null,
        content: null,
        contentUrl: null,
        contentUrlFileName: null,
        contentUrlSigned: null,
        image: null,
        selectingImageFlag: false,
        passingScore: null,
        passingRequired: null,
        randomPool: null,
        repeatable: false
      },
      learningCardQuestions: Array(),
      questionTypes: [
        { value: "MULTIPLE_CHOICE", text: "Multiple Choice" },
        { value: "SELECT_ALL", text: "Select All That Apply" },
        { value: "SCALE", text: "Scale" },
        { value: "LIKERT", text: "Likert Scale" },
        { value: "SHORT_ANSWER", text: "Short Answer" }
      ],
      learningCardQuestionsToDelete: Array(),
      learningCardAnswersToDelete: Array(),
      deleteCardConfirmation: {
        dialog: false,
        chapterIndex: null,
        cardIndex: null
      },
      deleteChapterConfirmation: {
        dialog: false,
        chapterIndex: null
      },
      slide2: {
        groupColumns: [
          {
            text: "Group ID",
            align: "start",
            sortable: true,
            value: "groupId",
            width: "10%"
          },
          { text: "Group", value: "groupName", width: "40%" },
          { text: "Group Type", value: "groupType", width: "20%" },
          {
            text: "Participants",
            value: "numInGroup",
            width: "20%"
          }
        ],
        visibility: null,
        selectedGroups: [],
        loadingGroups: false,
        groups: [],
        groupTableDebounceSearch: null,
        groupTableSearch: null,
        createGroupWidget: false,
        changeVisibilityConfirmation: {
          dialog: false
        }
      },
      slide3: {
        badgeColumns: [
          { text: "Image", value: "imageUrl", width: "10%" },
          { text: "Name", value: "displayName", width: "45%" },
          { text: "Description", value: "description", width: "45%" }
          // { text: "Type", value: "type", width: "20%" },
          // { text: "Status", value: "status", width: "20%" },
        ],
        selectedBadges: [],
        noBadges: false,
        loadingBadgeTable: false,
        badges: [],
        badgeTableSearch: null,
        createBadgeWidget: false
      },
      slide4: {
        preloadedProgram: false,
        createAwardWidget: false,
        createBudgetWidget: false,
        incentModule: false,
        dateOptionSelector: null,
        awards: [],
        awardFulfillmentPartners: [],
        budgets: [],
        awardId: null,
        awardValue: null,
        budgetId: null,
        hours: hours,
        minutes: minutes,
        amPm: amPm,
        startDate: null,
        menuStartDate: false,

        startTime: {
          hours: null,
          minutes: null,
          timezone: null,
          amPm: null
        },
        endDate: null,
        rollingProgramLength: null,
        mandatory: false,
        menuEndDate: false,
        endTime: {
          hours: null,
          minutes: null,
          amPm: null
        }
      },
      toggleRepeat: false
    };
  },
  created() {
    this.getAwards(null);
    this.getBudgets(null);
    this.getAwardFulfillmentPartners();

    let id = this.learningModuleId || this.$route.query.learningModuleId;
    if (!id) {
      this.clientId =
        this.preloadedClientId !== undefined && this.preloadedClientId !== null
          ? this.preloadedClientId
          : this.userProfile.clientId;
      this.getBadges(null);

      //Preload current date/time and end date 1 year from now
      this.learningModule.displayDate = false;
      // We have to fetch learning modules for the client and check if their subscription is ok
      this.getLearningModules();
    } else {
      //We have to preload a survey
      // For preloading a survey, we take them to the overview
      this.loadBuilder();

      // console.log("Preloading survey: ", this.preloadedModule);
      // this.clientId = this.preloadedModule.clientId;
      // var learningModule = this.preloadedModule;
      // this.learningModule.learningModuleId = learningModule.learningModuleId;

      // this.learningModule.name = learningModule.name;
      // this.learningModule.description = learningModule.description;
      // this.learningModule.Content = learningModule.Content;
      // this.learningModule.imageUrl = learningModule.imageUrl;
      // this.learningModule.imageUrlSigned = learningModule.imageUrl;
      // this.learningModule.forceLinearProgress =
      //   learningModule.forceLinearProgress;
      // this.learningModule.bannerContentId = learningModule.bannerContentId;

      // // Sorts chapters by order
      // var chapters = learningModule.LearningChapters.sort(function(a, b) {
      //   return new Date(a.order) - new Date(b.order);
      // });
      // if (chapters[0].order == -1) {
      //   // If -1, then it's the end card so we move to the end
      //   var tempChapter = chapters[0];
      //   chapters.splice(0, 1);
      //   chapters.push(tempChapter);
      // }
      // this.learningModule.learningChapters = chapters;

      // for (var i = 0; i < this.learningModule.learningChapters.length; i++) {
      //   // Sorts cards by order
      //   var cards = JSON.parse(
      //     JSON.stringify(
      //       this.learningModule.learningChapters[i].LearningCards != undefined
      //         ? this.learningModule.learningChapters[i].LearningCards
      //         : []
      //     )
      //   );
      //   // console.log(cards);
      //   cards.sort(function(a, b) {
      //     return new Date(a.order) - new Date(b.order);
      //   });
      //   this.learningModule.learningChapters[i].learningCards = cards;
      // }
      // // Assinging visibility type if it is set on preloaded module
      // this.learningModule.visibilityType = learningModule.visibilityType;
      // this.slide2.visibility = learningModule.visibilityType;
      // this.learningModule.moduleStatus = learningModule.status;
      // if (learningModule.LearningModuleGroups.find(x => x.groupId == 0)) {
      //   if (
      //     learningModule.visibilityType === "PUBLIC" ||
      //     learningModule.visibilityType === "EMAIL_LIST"
      //   ) {
      //     this.selectPublicAudience(false);
      //   } else {
      //     console.log("Everyone is selected for audience");
      //     this.groupSelector = false;
      //     this.selectEveryone();
      //   }
      // } else {
      //   this.selectGroup();
      // }

      // this.getGroups(
      //   learningModule.LearningModuleGroups.map(x => x.groupId),
      //   false
      // );

      // if (learningModule.badgeId == null) {
      //   this.badgeSelector = false;
      //   this.selectNoBadge();
      // } else {
      //   this.selectBadge();
      // }
      // this.getBadges(learningModule.badgeId);

      // if (learningModule.startDate) {
      //   //Preload start date so it auto fills with the old send date
      //   this.learningModule.displayDate = true;
      //   this.learningModule.startDate = new momentTz(learningModule.startDate)
      //     .local()
      //     .format("YYYY-MM-DD");

      //   var hours = new momentTz(learningModule.startDate).local().format("HH");
      //   if (parseInt(hours) >= 12) {
      //     var amPm = "PM";
      //   } else {
      //     amPm = "AM";
      //   }

      //   this.learningModule.startTime = {
      //     hours: new momentTz(learningModule.startDate).local().format("h"),
      //     minutes: new momentTz(learningModule.startDate).local().format("mm"),
      //     amPm: amPm,
      //     timezone: { value: momentTz.tz.guess() }
      //   };

      //   if (learningModule.endDate) {
      //     this.learningModule.endDate = new momentTz(learningModule.endDate)
      //       .local()
      //       .format("YYYY-MM-DD");

      //     hours = new momentTz(learningModule.endDate).local().format("HH");
      //     if (parseInt(hours) >= 12) {
      //       amPm = "PM";
      //     } else {
      //       amPm = "AM";
      //     }

      //     this.learningModule.endTime = {
      //       hours: new momentTz(learningModule.endDate).local().format("h"),
      //       minutes: new momentTz(learningModule.endDate).local().format("mm"),
      //       amPm: amPm,
      //       timezone: { value: momentTz.tz.guess() }
      //     };
      //   }
      // } else {
      //   // If there is no start date, then we hide the date fields
      //   this.learningModule.displayDate = false;
      // }

      // //we have to fetch any quiz cards and the questions/answers
      // var quizCards = [];
      // for (var j = 0; j < this.learningModule.learningChapters.length; j++) {
      //   quizCards = quizCards.concat(
      //     this.learningModule.learningChapters[j].learningCards.filter(
      //       x =>
      //         x.type == "quiz" || x.type == "assessment" || x.type == "survey"
      //     )
      //   );
      // }

      // if (quizCards.length > 0) {
      //   // var self = this;
      //   this.fetchQuizData(quizCards);
      // }

      // // Then, we cycle through all cards and fetch contentUrl's
      // // This array will hold all in-line images we will need to fetch with info to place them back into their original card
      // var masterImageArray = [];
      // this.learningModule.learningChapters.forEach((chapter, chapterIndex) => {
      //   chapter.learningCards.forEach((card, cardIndex) => {
      //     if (card.contentUrl != null) {
      //       // This checks if we are looking at something in the public storage bucket so we don't ignore Richard's FetchFind videos
      //       card.contentUrlSigned = card.contentUrl;
      //     } else if (card.Content != null) {
      //       // Preload the banner image
      //       if (card.type == "video") {
      //         var url = card.Content.filePath + "/encoded/hd.mp4";
      //       } else {
      //         url = card.Content.filePath;
      //       }
      //       ContentService.getSignedUrlForViewingContent(url).then(
      //         signedResponse => {
      //           console.log(
      //             "Learning card content " + card.Content.contentId + " url ",
      //             signedResponse.url
      //           );
      //           card.contentUrlSigned = signedResponse.url;
      //         }
      //       );
      //     }
      //     // Defines contentUrl regardless if it's null, a url, or something we created
      //     card.contentUrl =
      //       card.Content != null ? card.Content.filePath : card.contentUrl;

      //     // Then we search for any in-line images
      //     var imageIndex =
      //       card.content != null ? card.content.indexOf("<img-custom") : -1;
      //     // fetchImageLoop(0);
      //     // function fetchImageLoop(index) {
      //     //   if (imageIndex != -1) {
      //     while (imageIndex != -1) {
      //       var closingTag = card.content.indexOf(">", imageIndex);
      //       var contentId = card.content.substring(imageIndex + 12, closingTag);
      //       console.log("ContentId to load: ", contentId);
      //       masterImageArray.push({
      //         contentId: contentId,
      //         chapterIndex: chapterIndex,
      //         cardIndex: cardIndex
      //       });

      //       card.content = card.content.replace(
      //         "<img-custom " + contentId + ">",
      //         "<img-temp " + contentId + ">"
      //       );
      //       imageIndex = card.content.indexOf("<img-custom");
      //     }
      //     console.log(card.content);
      //   });
      // });

      // masterImageArray.forEach(img => {
      //   ContentService.getSignedUrlBasedOnContentId(img.contentId).then(
      //     contentResponse => {
      //       if (contentResponse.url) {
      //         this.learningModule.learningChapters[
      //           img.chapterIndex
      //         ].learningCards[
      //           img.cardIndex
      //         ].content = this.learningModule.learningChapters[
      //           img.chapterIndex
      //         ].learningCards[img.cardIndex].content.replace(
      //           "<img-temp " + img.contentId + ">",
      //           `<img id="contentId-${img.contentId}" src="${contentResponse.url}"`
      //         );
      //       } else {
      //         // There was some error so no url was returned, so we remove the img tag
      //         console.log("Removing tag for content " + img.contentId);
      //         this.learningModule.learningChapters[
      //           img.chapterIndex
      //         ].learningCards[
      //           img.cardIndex
      //         ].content = this.learningModule.learningChapters[
      //           img.chapterIndex
      //         ].learningCards[img.cardIndex].content.replace(
      //           "<img-temp " + img.contentId + ">",
      //           ""
      //         );
      //       }
      //     }
      //   );
      // });

      // // Now we preload the program (if any)
      // if (learningModule.Programs && learningModule.Programs.length > 0) {
      //   this.slide4.preloadedProgram = true;
      //   this.slide4.incentModule = true;
      //   var program = learningModule.Programs[0];
      //   this.slide4.budgetId = program.budgetId;
      //   this.slide4.mandatory = program.mandatory;
      //   this.slide4.awardId = program.Budget ? program.Budget.awardId : null;
      //   this.slide4.rollingProgramLength = program.rollingProgramLength;
      //   this.slide4.dateOptionSelector = program.rollingProgramLength
      //     ? "rolling"
      //     : "date";

      //   if (program.startDate) {
      //     //Preload start date so it auto fills with the old send date
      //     this.slide4.startDate = new momentTz(program.startDate)
      //       .local()
      //       .format("YYYY-MM-DD");

      //     hours = new momentTz(program.startDate).local().format("HH");
      //     if (parseInt(hours) >= 12) {
      //       amPm = "PM";
      //     } else {
      //       amPm = "AM";
      //     }

      //     this.slide4.startTime = {
      //       hours: new momentTz(program.startDate).local().format("h"),
      //       minutes: new momentTz(program.startDate).local().format("mm"),
      //       amPm: amPm,
      //       timezone: { value: momentTz.tz.guess() }
      //     };

      //     if (program.endDate) {
      //       this.slide4.endDate = new momentTz(program.endDate)
      //         .local()
      //         .format("YYYY-MM-DD");

      //       hours = new momentTz(program.endDate).local().format("HH");
      //       if (parseInt(hours) >= 12) {
      //         amPm = "PM";
      //       } else {
      //         amPm = "AM";
      //       }

      //       this.slide4.endTime = {
      //         hours: new momentTz(program.endDate).local().format("h"),
      //         minutes: new momentTz(program.endDate).local().format("mm"),
      //         amPm: amPm,
      //         timezone: { value: momentTz.tz.guess() }
      //       };
      //     }
      //   }

      //   var payoutRules = program.RuleGroups.filter(
      //     x => x.type == "PAYOUT_RULE"
      //   );
      //   console.log("Payout rule groups: ", program.RuleGroups);
      //   if (payoutRules.length > 0) {
      //     // We sort to get the oldest rule group and make sure that it's a hit and win
      //     var sorted = payoutRules
      //       .filter(
      //         x =>
      //           x.PayoutRules.length > 0 &&
      //           x.PayoutRules[0].type == "HIT_AND_WIN"
      //       )
      //       .sort(function(a, b) {
      //         return a.ruleGroupId - b.ruleGroupId;
      //       });
      //     var rule = sorted[0];
      //     this.slide4.awardValue = rule.PayoutRules[0].amount;
      //   }
      // }

      // if (this.preloadedModule.LearningModuleGroups.length == 0) {
      //   this.slideKey = 2;
      // }
    }
  },
  beforeDestroy() {},
  methods: {
    exists(e) {
      return e !== undefined && e !== null;
    },
    async loadBuilder() {
      //We have to preload a learning module
      let module;
      this.loadingModuleError = null;

      try {
        setTimeout(() => {
          this.loadingModule = true;
        }, 250);

        console.log("Going to fetch survey for builder!");
        let moduleResponse = await LearningService.getModulesAdmin(
          "survey",
          this.exists(this.preloadedClientId)
            ? this.preloadedClientId
            : this.exists(this.$route.query.clientId)
            ? this.$route.query.clientId
            : this.userProfile.clientId,
          false,
          false,
          1,
          this.learningModuleId || this.$route.query.learningModuleId
        );
        console.log("Module res ", moduleResponse);
        if (moduleResponse.modules && moduleResponse.modules.length == 1)
          module = moduleResponse.modules[0];
        else this.loadingModuleError = true;
      } catch (err) {
        console.log("Error getting learning module! ", err);
        this.loadingModuleError = true;
      }

      // IF we had an error, then we keep the popup showing, otherwise we hide the dialog
      if (this.loadingModuleError) return;
      // else this.loadingModule = false;

      // if (module.LearningModuleGroups.length == 0) {
      //   this.slideKey = 2;
      // } else {
      //   this.slideKey = 8;
      // }
      console.log("Preloading survey: ", module);
      this.clientId = module.clientId;
      var learningModule = module;
      this.learningModule.learningModuleId = learningModule.learningModuleId;
      this.learningModule.name = learningModule.name;
      this.learningModule.description = learningModule.description;
      this.learningModule.Content = learningModule.Content;
      this.learningModule.imageUrl = learningModule.imageUrl;
      this.learningModule.imageUrlSigned = learningModule.imageUrl;
      this.learningModule.forceLinearProgress =
        learningModule.forceLinearProgress;
      this.learningModule.bannerContentId = learningModule.bannerContentId;
      this.learningModule.visibilityType = learningModule.visibilityType;
      this.slide2.visibility = learningModule.visibilityType;
      this.learningModule.moduleStatus = learningModule.status;

      // //fetch existing tags for this module
      // console.log("fetch existing tags");
      // console.log(this.learningModule.learningModuleId);
      // this.preloadTagDefinitionsByModule(this.learningModule.learningModuleId);

      // if (learningModule.Content != null) {
      //   console.log(
      //     "Formatted URL for module: ",
      //     learningModule.Content.filePath
      //   );
      //   ContentService.getSignedUrlForViewingContent(
      //     learningModule.Content.filePath
      //   ).then((signedResponse) => {
      //     console.log("Learning module url ", signedResponse.url);
      //     this.learningModule.imageUrlSigned = signedResponse.url;
      //   });
      // } else {
      //   // It will either be null or a URL defined by the user
      //   this.learningModule.imageUrlSigned = learningModule.imageUrl;
      // }

      // Sorts chapters by order
      var chapters = learningModule.LearningChapters.sort(function(a, b) {
        return new Date(a.order) - new Date(b.order);
      });
      // delete this.learningModule.LearningChapters;
      if (chapters.length > 0 && chapters[0].order == -1) {
        // If -1, then it's the end card so we move to the end
        var tempChapter = chapters[0];
        chapters.splice(0, 1);
        chapters.push(tempChapter);
      }
      if (chapters.length == 0) {
        // If there are no chapters, fill in the conclusion
        chapters = [
          {
            valid: false,
            learningChapterId: null,
            name: "Conclusion",
            displayName: "Conclusion",
            description: null,
            imageUrl: null,
            learningModuleId: null,
            image: null,
            order: -1,
            learningCards: [
              {
                valid: false,
                learningChapterId: null,
                learningCardId: null,
                type: "text",
                name: "Great Job!",
                displayName: "Great Job!",
                description: "This will appear at the end of the survey.",
                content: "<p>You've finished another survey, well done!</p>",
                contentUrl: null,
                contentUrlFileName: null,
                contentUrlSigned: null,
                image: null,
                passingScore: null,
                passingRequired: null,
                randomPool: null,
                numRandomQuestions: null
              }
            ]
          }
        ];
      }

      this.learningModule.learningChapters = chapters;

      for (var i = 0; i < this.learningModule.learningChapters.length; i++) {
        // Sorts cards by order
        var cards = JSON.parse(
          JSON.stringify(
            this.learningModule.learningChapters[i].LearningCards != undefined
              ? this.learningModule.learningChapters[i].LearningCards
              : []
          )
        );

        cards.sort(function(a, b) {
          return new Date(a.order) - new Date(b.order);
        });
        this.learningModule.learningChapters[i].learningCards = cards;
        // delete this.learningModule.learningChapters[i].LearningCards;
      }
      if (learningModule.LearningModuleGroups.find(x => x.groupId == 0)) {
        // this.groupSelector = false;
        // this.selectEveryone();
        if (
          learningModule.visibilityType === "PUBLIC" ||
          learningModule.visibilityType === "EMAIL_LIST"
        ) {
          this.selectPublicAudience(false);
        } else {
          console.log("Everyone is selected for audience");
          this.groupSelector = false;
          this.selectEveryone();
        }
      } else {
        this.selectGroup();

        // Now let's fetch any groups
        if (learningModule.LearningModuleGroups.length) {
          try {
            const groupFilter = `status != 'Deleted' && clientId = ${
              this.clientId
            } && groupId.isIn(${JSON.stringify(
              learningModule.LearningModuleGroups.map(x => x.groupId)
            )})`;
            this.slide2.loadingGroups = true;
            let preloadedGroups = await GroupService.getGroupsV2({
              filter: groupFilter,
              limit: learningModule.LearningModuleGroups.length,
              offset: 0,
              extract: "groupDisplayName,clientId,groupId,groupType",
              expand: "null"
            });
            console.log("Got groups to preload: ", preloadedGroups);
            this.slide2.selectedGroups = preloadedGroups.result.rows;
            if (this.slide2.selectedGroups.length) this.groupSelector = true;
          } catch (e) {
            console.log("Error preloading groups for survey", e);
          } finally {
            this.slide2.loadingGroups = false;
          }
        }
      }
      // this.getGroups(
      //   learningModule.LearningModuleGroups.map(x => x.groupId),
      //   false
      // );

      if (learningModule.badgeId == null) {
        this.badgeSelector = false;
        this.selectNoBadge();
      } else {
        this.selectBadge();
      }
      this.getBadges(learningModule.badgeId);

      // if (learningModule.startDate) {
      //   //Preload start date so it auto fills with the old send date
      //   this.learningModule.displayDate = true;
      //   this.learningModule.startDate = new momentTz(learningModule.startDate)
      //     .local()
      //     .format("YYYY-MM-DD");

      //   var hours = new momentTz(learningModule.startDate).local().format("HH");
      //   if (parseInt(hours) >= 12) {
      //     var amPm = "PM";
      //   } else {
      //     amPm = "AM";
      //   }

      //   this.learningModule.startTime = {
      //     hours: new momentTz(learningModule.startDate).local().format("h"),
      //     minutes: new momentTz(learningModule.startDate).local().format("mm"),
      //     amPm: amPm,
      //     timezone: { value: momentTz.tz.guess() }
      //   };

      //   if (learningModule.endDate) {
      //     this.learningModule.endDate = new momentTz(learningModule.endDate)
      //       .local()
      //       .format("YYYY-MM-DD");

      //     hours = new momentTz(learningModule.endDate).local().format("HH");
      //     if (parseInt(hours) >= 12) {
      //       amPm = "PM";
      //     } else {
      //       amPm = "AM";
      //     }

      //     this.learningModule.endTime = {
      //       hours: new momentTz(learningModule.endDate).local().format("h"),
      //       minutes: new momentTz(learningModule.endDate).local().format("mm"),
      //       amPm: amPm,
      //       timezone: { value: momentTz.tz.guess() }
      //     };
      //   }
      // } else {
      //   // If there is no start date, then we hide the date fields
      //   this.learningModule.displayDate = false;
      // }

      //we have to fetch any quiz cards and the questions/answers
      var quizCards = [];
      for (var j = 0; j < this.learningModule.learningChapters.length; j++) {
        quizCards = quizCards.concat(
          this.learningModule.learningChapters[j].learningCards.filter(
            x =>
              x.type == "quiz" || x.type == "assessment" || x.type == "survey"
          )
        );
      }

      if (quizCards.length > 0) {
        // var self = this;
        this.fetchQuizData(
          quizCards,
          learningModule.LearningModuleGroups.length == 0
        );
      } else this.loadingModule = false;

      // Then, we cycle through all cards and fetch contentUrl's
      // This array will hold all in-line images we will need to fetch with info to place them back into their original card
      var masterImageArray = [];
      this.learningModule.learningChapters.forEach((chapter, chapterIndex) => {
        chapter.learningCards.forEach((card, cardIndex) => {
          if (card.contentUrl != null) {
            // This checks if we are looking at something in the public storage bucket so we don't ignore Richard's FetchFind videos
            card.contentUrlSigned = card.contentUrl;
          } else if (card.Content != null) {
            // Preload the banner image
            if (card.type == "video") {
              var url = card.Content.filePath + "/encoded/hd.mp4";
            } else {
              url = card.Content.filePath;
            }
            ContentService.getSignedUrlForViewingContent(url).then(
              signedResponse => {
                console.log(
                  "Learning card content " + card.Content.contentId + " url ",
                  signedResponse.url
                );
                card.contentUrlSigned = signedResponse.url;
              }
            );
          }
          // Defines contentUrl regardless if it's null, a url, or something we created
          card.contentUrl =
            card.Content != null ? card.Content.filePath : card.contentUrl;

          // Then we search for any in-line images
          var imageIndex =
            card.content != null ? card.content.indexOf("<img-custom") : -1;
          // fetchImageLoop(0);
          // function fetchImageLoop(index) {
          //   if (imageIndex != -1) {
          while (imageIndex != -1) {
            var closingTag = card.content.indexOf(">", imageIndex);
            var contentId = card.content.substring(imageIndex + 12, closingTag);
            console.log("ContentId to load: ", contentId);
            masterImageArray.push({
              contentId: contentId,
              chapterIndex: chapterIndex,
              cardIndex: cardIndex
            });

            card.content = card.content.replace(
              "<img-custom " + contentId + ">",
              "<img-temp " + contentId + ">"
            );
            imageIndex = card.content.indexOf("<img-custom");
          }
          console.log(card.content);
        });
      });

      masterImageArray.forEach(img => {
        ContentService.getSignedUrlBasedOnContentId(img.contentId).then(
          contentResponse => {
            if (contentResponse.url) {
              this.learningModule.learningChapters[
                img.chapterIndex
              ].learningCards[
                img.cardIndex
              ].content = this.learningModule.learningChapters[
                img.chapterIndex
              ].learningCards[img.cardIndex].content.replace(
                "<img-temp " + img.contentId + ">",
                `<img id="contentId-${img.contentId}" src="${contentResponse.url}">`
              );
            } else {
              // There was some error so no url was returned, so we remove the img tag
              console.log("Removing tag for content " + img.contentId);
              this.learningModule.learningChapters[
                img.chapterIndex
              ].learningCards[
                img.cardIndex
              ].content = this.learningModule.learningChapters[
                img.chapterIndex
              ].learningCards[img.cardIndex].content.replace(
                "<img-temp " + img.contentId + ">",
                ""
              );
            }
          }
        );
      });

      // Now we preload the program (if any)
      if (learningModule.Programs && learningModule.Programs.length > 0) {
        this.slide4.preloadedProgram = true;
        this.slide4.incentModule = true;
        var program = learningModule.Programs[0];
        this.slide4.budgetId = program.budgetId;
        this.slide4.mandatory = program.mandatory;
        this.slide4.awardId = program.Budget ? program.Budget.awardId : null;
        this.slide4.rollingProgramLength = program.rollingProgramLength;
        this.slide4.dateOptionSelector = program.rollingProgramLength
          ? "rolling"
          : "date";

        if (program.startDate) {
          //Preload start date so it auto fills with the old send date
          this.slide4.startDate = new momentTz(program.startDate)
            .local()
            .format("YYYY-MM-DD");

          hours = new momentTz(program.startDate).local().format("HH");
          if (parseInt(hours) >= 12) {
            amPm = "PM";
          } else {
            amPm = "AM";
          }

          this.slide4.startTime = {
            hours: new momentTz(program.startDate).local().format("h"),
            minutes: new momentTz(program.startDate).local().format("mm"),
            amPm: amPm,
            timezone: { value: momentTz.tz.guess() }
          };

          if (program.endDate) {
            this.slide4.endDate = new momentTz(program.endDate)
              .local()
              .format("YYYY-MM-DD");

            hours = new momentTz(program.endDate).local().format("HH");
            if (parseInt(hours) >= 12) {
              amPm = "PM";
            } else {
              amPm = "AM";
            }

            this.slide4.endTime = {
              hours: new momentTz(program.endDate).local().format("h"),
              minutes: new momentTz(program.endDate).local().format("mm"),
              amPm: amPm,
              timezone: { value: momentTz.tz.guess() }
            };
          }
          //  else {
          //   this.slide4.endDateNull = true;
          // }
        }

        var payoutRules = program.RuleGroups.filter(
          x => x.type == "PAYOUT_RULE"
        );
        console.log("Payout rule groups: ", program.RuleGroups);
        if (payoutRules.length > 0) {
          // We sort to get the oldest rule group and make sure that it's a hit and win
          var sorted = payoutRules
            .filter(
              x =>
                x.PayoutRules.length > 0 &&
                x.PayoutRules[0].type == "HIT_AND_WIN"
            )
            .sort(function(a, b) {
              return a.ruleGroupId - b.ruleGroupId;
            });
          var rule = sorted[0];
          this.slide4.awardValue = rule.PayoutRules[0].amount;
        }
      }

      if (
        learningModule.LearningModuleGroups.length == 0 &&
        !quizCards.length
      ) {
        this.slideKey = 2;
      }
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    closeLearningBuilder() {
      if (this.previousRoute) {
        this.$router.push({
          name: this.previousRoute,
          params: this.previousRouteProps
        });
      } else {
        this.$router.push({
          name: "programadmin",
          params: {
            preload: "surveys"
          }
        });
      }
    },
    goToSlide(index) {
      console.log("go to slide " + index);

      var validArray = [false, false, false, false, false, false, false];
      if (index <= this.slideKey) {
        // TODO: might need to disable going back here for public surveys
        //We're navigating backwards so we allow it
        this.slideKey = index;
        console.log("Scrolling to top");
        document.getElementsByClassName(
          "v-dialog v-dialog--active"
        )[0].scrollTop = 0;
      } else {
        console.log(this.learningModule.name);
        console.log(this.learningModule.description);
        if (
          index >= 1 &&
          this.learningModule.name !== null &&
          this.learningModule.name.length > 0 &&
          this.learningModule.description !== null &&
          this.learningModule.description.length > 0 &&
          this.learningModule.imageUrl !== null
          // &&
          // (this.learningModule.startDate == null ||
          //   (this.learningModule.startDate !== null &&
          //     this.learningModule.startTime.hours !== null &&
          //     this.learningModule.startTime.minutes !== null &&
          //     this.learningModule.startTime.timezone !== null &&
          //     this.learningModule.startTime.amPm !== null)) &&
          // (this.learningModule.endDate == null ||
          //   (this.learningModule.endDate !== null &&
          //     this.learningModule.endTime.hours !== null &&
          //     this.learningModule.endTime.minutes !== null &&
          //     this.learningModule.endTime.timezone !== null &&
          //     this.learningModule.endTime.amPm !== null))
        ) {
          // if (this.learningModule.startDate) {
          //   var startHours = parseInt(this.learningModule.startTime.hours);
          //   if (this.learningModule.startTime.amPm == "PM") {
          //     if (startHours != 12) {
          //       startHours = parseInt(startHours) + 12;
          //     }
          //   } else {
          //     if (startHours == 12) {
          //       //Should be 0
          //       startHours = 0;
          //     }
          //   }

          //   if (startHours.toString().length == 1) {
          //     startHours = "0" + startHours.toString();
          //   }
          //   var formattedStartDate = new momentTz(
          //     this.learningModule.startDate.concat(
          //       " ",
          //       startHours,
          //       ":",
          //       this.learningModule.startTime.minutes
          //     )
          //   )
          //     .local()
          //     .format("YYYY-MM-DD HH:mm");
          // }

          // if (this.learningModule.endDate) {
          //   var endHours = parseInt(this.learningModule.endTime.hours);
          //   if (this.learningModule.endTime.amPm == "PM") {
          //     if (endHours != 12) {
          //       endHours = parseInt(endHours) + 12;
          //     }
          //   } else {
          //     if (endHours == 12) {
          //       //Should be 0
          //       endHours = 0;
          //     }
          //   }

          //   if (endHours.toString().length == 1) {
          //     endHours = "0" + endHours.toString();
          //   }
          //   var formattedEndDate = new momentTz(
          //     this.learningModule.endDate.concat(
          //       " ",
          //       endHours,
          //       ":",
          //       this.learningModule.endTime.minutes
          //     )
          //   )
          //     .local()
          //     .format("YYYY-MM-DD HH:mm");
          // }

          // if (
          //   this.learningModule.startDate == null ||
          //   this.learningModule.endDate == null
          // ) {
          validArray[0] = true;
          //   console.log("no start/end");
          // } else if (formattedEndDate >= formattedStartDate) {
          //   validArray[0] = true;
          //   console.log("valid start/end");
          // } else {
          //   console.log("invalid start end?");
          //   console.log(formattedEndDate >= formattedStartDate);
          // }
        }
        console.log(this.slide2.selectedGroups);
        if (index >= 2 && this.slide2.selectedGroups.length > 0) {
          validArray[1] = true;
        }

        if (index >= 3) {
          validArray[2] = true;
        }
        if (index >= 4) {
          validArray[3] = true;
        }

        if (index >= 5) {
          validArray[4] = true;
        }
        if (index >= 6) {
          validArray[5] = true;
        }

        if (index >= 7) {
          validArray[6] = true;
        }

        //We've run validation for all slides, now we check to make sure anything prior to what they're click is true
        var falseBool = false;
        console.log("check if we can go to slide");
        console.log(validArray);
        for (var i = 0; i < index - 1; i++) {
          if (!validArray[i]) {
            //Something isn't valid, so we don't allow them to proceed
            falseBool = true;
          }
        }
        console.log(validArray);
        if (!falseBool) {
          this.slideKey = index;
          console.log("Scrolling to top");
          document.getElementsByClassName(
            "v-dialog v-dialog--active"
          )[0].scrollTop = 0;
        }
      }
    },
    goToNextSlide() {
      var i = this.slideKey + 1;
      // Removes the incentive page
      if (i == 4) i = 5;
      this.slideKey = i;
      console.log("Scrolling to top");
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    goToPreviousSlide() {
      var i = this.slideKey - 1;
      if (i == 4) i = 3;
      this.slideKey = i;
    },
    goBack() {
      this.dialogExitQuizMessage = false;
      if (this.slideKey == 2 || this.slideKey == 3 || this.slideKey == 4) {
        this.goToPreviousSlide();
      } else if (this.slideKey == 8) {
        // If we're editing a quiz (slideKey 8), then going back takes the user back to the incentive page
        // We are going to check if the survey is public and skip back to slide 2 if so (skip incentive and badges)
        // if (this.slide2.visibility !== "PRIVATE") {
        this.goToSlide(2);
        // } else {
        //   this.goToSlide(4);
        // }
      } else if (this.slideKey == 7) {
        // If we're editing the conclusion card, then we go back to the quiz card so we call editCard
        this.editCard(
          this.learningModule.learningChapters[0].learningCards[0],
          0,
          0
        );
      }
    },
    goBackQuiz() {
      if (this.learningCardQuestions.length == 0) {
        this.goBack();
      } else {
        this.dialogExitQuizMessage = true;
      }
    },
    goToQuizEditor() {
      this.editCard(
        this.learningModule.learningChapters[0].learningCards[0],
        0,
        0
      );
    },
    selectEveryone() {
      var everyoneGroup = {
        clientId: 0,
        groupId: 0,
        groupName: "Everyone",
        groupDisplayName: "Everyone"
      };
      this.slide2.selectedGroups = [everyoneGroup];
      this.learningModule.audience = 0;
      // this.selectEveryoneGroupFlag = true;
      // this.selectOneGroupFlag = false;
    },
    selectGroup() {
      console.log("Select group clicked");
      this.slide2.selectedGroups = Array();
      // this.selectOneGroupFlag = true;
      // this.selectEveryoneGroupFlag = false;
    },
    selectPublicAudience(changeContent = false) {
      // Selecting a public survey will clear the incentives, let's check first and confirm
      if (this.slide3.selectedBadges.length > 0 || this.slide4.incentModule) {
        // confirmChangeVisibility();
        this.slide2.changeVisibilityConfirmation.dialog = true;
      } else {
        // In case they selected internal first and assigned a group
        this.clearSelectedGroups();
        const publicGroup = {
          groupId: 0,
          groupName: "Public Group",
          groupDisplayName: "Public Group"
        };
        this.slide2.selectedGroups = [publicGroup];
        this.learningModule.audience = 0;
        this.groupSelector = null;
        // change conclusion message if public
        if (this.slide2.visibility === "PUBLIC" && changeContent) {
          // find conclusion chapter
          let conclusionIndex = this.learningModule.learningChapters.findIndex(
            chapter => chapter.order === -1
          );
          // console.log('found conclusion at index', conclusionIndex);
          // console.log(this.learningModule.learningChapters[conclusionIndex].learningCards[0]);
          this.learningModule.learningChapters[
            conclusionIndex
          ].learningCards[0].content =
            "<p>You've finished the survey, well done!</p>";
          // console.log(this.learningModule.learningChapters[conclusionIndex].learningCards[0]);
        }
      }
    },
    confirmChangeVisibility() {
      // If they want to change from private to public we clear the badges, incentives, and groups
      this.slide2.changeVisibilityConfirmation.dialog = false;
      this.selectNoBadge();
      this.removeIncentive();
      this.clearSelectedGroups();
      const publicGroup = {
        groupId: 0,
        groupName: "Public Group",
        groupDisplayName: "Public Group"
      };
      this.slide2.selectedGroups = [publicGroup];
      this.learningModule.audience = 0;
      this.groupSelector = null;
      // change conclusion message if public
      if (this.slide2.visibility === "PUBLIC") {
        // find conclusion chapter
        let conclusionIndex = this.learningModule.learningChapters.findIndex(
          chapter => chapter.order === -1
        );
        // console.log('found conclusion at index', conclusionIndex);
        // console.log(this.learningModule.learningChapters[conclusionIndex].learningCards[0]);
        this.learningModule.learningChapters[
          conclusionIndex
        ].learningCards[0].content =
          "<p>You've finished the survey, well done!</p>";
        // console.log(this.learningModule.learningChapters[conclusionIndex].learningCards[0]);
      }
    },
    // When a public survey is selected, in case they selected internal first and assigned a group
    clearSelectedGroups() {
      // Check to make sure they've selected a public survey
      // if groupSelector is null it means they haven't clicked on either sub radio button yet
      // if (this.slide2.visibility === 'EMAIL_LIST' || this.slide2.visibility === 'PUBLIC' && this.groupSelector === null) {
      //   this.slide2.selectedGroups = [];
      // }
      this.slide2.selectedGroups = [];
    },
    addGroupToSelected(group, preventRemoval = false) {
      console.log("Adding user/group to selected array", group);
      // Added because we can only pass one param in from the groups table
      if (group.dontRemove) preventRemoval = true;
      let idx = this.slide2.selectedGroups.findIndex(
        x => x.groupId == group.groupId
      );
      if (idx == -1) this.slide2.selectedGroups.push(group);
      else if (!preventRemoval) this.slide2.selectedGroups.splice(idx, 1);
    },
    updateSelectedGroup(e) {
      if (!e.groupId) return console.log("no group id");

      this.slide2.selectedGroups.forEach(o => {
        if (o.groupId == e.groupId) {
          console.log("UPDATING SELECTED NUM USERS ", e.numUsers);
          o.numUsers = e.numUsers || 0;
        }
      });
    },
    selectNoBadge() {
      this.slide3.selectedBadges = [];
      this.slide3.noBadges = true;
    },
    selectBadge() {
      this.slide3.noBadges = false;
      this.slide3.selectedBadges = Array();
    },
    removeIncentive() {
      this.slide4.budgetId = null;
      this.slide4.awardId = null;
      this.slide4.awardValue = null;
      // this.slide4.endDateNull = true;
      this.slide4.mandatory = false;
      this.slide4.startDate = null;
      this.slide4.startTime = {
        hours: null,
        amPm: null,
        minutes: null,
        timezone: null
      };
      this.slide4.endDate = null;
      // this.slide4.endDateNull = false;
      this.slide4.dateOptionSelector = false;
      this.slide4.rollingProgramLength = null;
      this.slide4.endTime = {
        hours: null,
        amPm: null,
        minutes: null
      };
    },
    createIncentive() {
      // Preload the date
      this.slide4.startDate = momentTz()
        .local()
        .add(1, "hour")
        .format("YYYY-MM-DD");

      var hours = momentTz()
        .local()
        .add(1, "hours")
        .format("HH");
      if (parseInt(hours) >= 12) {
        var amPm = "PM";
      } else {
        amPm = "AM";
      }

      this.slide4.startTime.hours = momentTz()
        .local()
        .add(1, "hours")
        .format("h");

      this.slide4.startTime.amPm = amPm;
      this.slide4.startTime.minutes = momentTz()
        .local()
        .format("mm");
      this.slide4.startTime.timezone = { value: momentTz.tz.guess() };
      this.slide4.endDate = momentTz()
        .local()
        .add(1, "Y")
        .format("YYYY-MM-DD");
      this.slide4.endTime.hours = momentTz()
        .local()
        .add(1, "hour")
        .format("h");
      this.slide4.endTime.amPm = amPm;
      this.slide4.endTime.minutes = momentTz()
        .local()
        .format("mm");
      // this.slide4.endDateNull = false;
      this.slide4.dateOptionSelector = "date";
      this.slide4.rollingProgramLength = null;
    },
    changeIncentiveDateOption(selectedOption) {
      // Preload the date if we select the first option
      // otherwise hide the date and show the rolling program option
      if (selectedOption == "date") {
        this.slide4.startDate = momentTz()
          .local()
          .add(1, "hour")
          .format("YYYY-MM-DD");

        var hours = momentTz()
          .local()
          .add(1, "hours")
          .format("HH");
        if (parseInt(hours) >= 12) {
          var amPm = "PM";
        } else {
          amPm = "AM";
        }

        this.slide4.startTime.hours = momentTz()
          .local()
          .add(1, "hours")
          .format("h");

        this.slide4.startTime.amPm = amPm;
        this.slide4.startTime.minutes = momentTz()
          .local()
          .format("mm");
        this.slide4.startTime.timezone = { value: momentTz.tz.guess() };
        this.slide4.endDate = momentTz()
          .local()
          .add(3, "M")
          .format("YYYY-MM-DD");
        this.slide4.endTime.hours = momentTz()
          .local()
          .add(1, "hour")
          .format("h");
        this.slide4.endTime.amPm = amPm;
        this.slide4.endTime.minutes = momentTz()
          .local()
          .format("mm");

        this.slide4.rollingProgramLength = null;
      } else {
        this.slide4.startDate = momentTz()
          .local()
          .add(1, "hour")
          .format("YYYY-MM-DD");

        hours = momentTz()
          .local()
          .add(1, "hours")
          .format("HH");
        if (parseInt(hours) >= 12) {
          amPm = "PM";
        } else {
          amPm = "AM";
        }

        this.slide4.startTime.hours = momentTz()
          .local()
          .add(1, "hours")
          .format("h");

        this.slide4.startTime.amPm = amPm;
        this.slide4.startTime.minutes = momentTz()
          .local()
          .format("mm");
        this.slide4.startTime.timezone = { value: momentTz.tz.guess() };

        this.slide4.endDate = null;

        this.slide4.endTime = {
          hours: null,
          amPm: null,
          minutes: null
        };

        this.slide4.rollingProgramLength = null;
      }
    },
    // displayDateToggled(newVal) {
    //   // If the new value it false (they switched it off), then we null everything out.
    //   // Otherwise, we prep it with a start date in an hour from now, and an end date of a year from now
    //   console.log("Display date toggle switched: ", newVal);
    //   if (!newVal) {
    //     this.learningModule.startDate = null;
    //     this.learningModule.startTime = {
    //       hours: null,
    //       amPm: null,
    //       minutes: null,
    //       timezone: null
    //     };
    //     this.learningModule.endDate = null;
    //     this.learningModule.endTime = {
    //       hours: null,
    //       amPm: null,
    //       minutes: null
    //     };
    //   } else {
    //     this.learningModule.startDate = momentTz()
    //       .local()
    //       .add(1, "hour")
    //       .format("YYYY-MM-DD");

    //     var hours = momentTz()
    //       .local()
    //       .add(1, "hours")
    //       .format("HH");
    //     if (parseInt(hours) >= 12) {
    //       var amPm = "PM";
    //     } else {
    //       amPm = "AM";
    //     }

    //     this.learningModule.startTime.hours = momentTz()
    //       .local()
    //       .add(1, "hours")
    //       .format("h");

    //     this.learningModule.startTime.amPm = amPm;
    //     this.learningModule.startTime.minutes = momentTz()
    //       .local()
    //       .format("mm");
    //     this.learningModule.startTime.timezone = { value: momentTz.tz.guess() };
    //     this.learningModule.endDate = momentTz()
    //       .local()
    //       .add(1, "Y")
    //       .format("YYYY-MM-DD");
    //     this.learningModule.endTime.hours = momentTz()
    //       .local()
    //       .format("h");
    //     this.learningModule.endTime.amPm = amPm;
    //     this.learningModule.endTime.minutes = momentTz()
    //       .local()
    //       .format("mm");
    //   }
    // },
    publishLearningModule() {
      this.learningModule.status = "Active";
      this.saveLearningModule("yes");
    },
    async saveLearningModule(publishFlag = "no") {
      // If we are creating a survey for the first time, we are starting from scratch and need to save the end cap learning chapter & learning card too
      var newModule = JSON.parse(JSON.stringify(this.learningModule));
      newModule = {
        clientId: this.clientId,
        name: newModule.name,
        displayName: newModule.name,
        description: newModule.description,
        // imageUrl: newModule.imageUrl,
        visibilityType: this.slide2.visibility,
        type: "Survey",
        forceLinearProgress: false,
        updatedBy: this.userProfile.userId
      };
      if (publishFlag == "yes") {
        newModule.status = "Active";
      }

      //if they used the group selector, load that into the newModule object
      if (this.slide2.selectedGroups.length > 0) {
        // this.learningModule.audience = this.slide2.selectedGroups[0].groupId;
        newModule.LearningModuleGroups = this.slide2.selectedGroups.map(
          x => x.groupId
        );
      }
      if (this.slide2.visibility == "PUBLIC")
        newModule.LearningModuleGroups = [0];

      //if they used the badge selector, load that into the newModule object
      if (this.slide3.selectedBadges.length > 0) {
        newModule.badgeId = this.slide3.selectedBadges[0].badgeId;
      }

      this.savingChanges = true;
      // If there is a new image or a bannerContentId, then we set imageUrl to null;
      // Otherwise, we don't touch it because they either removed the image or it's a defined url that we don't touch.
      if (this.learningModule.image !== null) {
        console.log("Setting module image url to null");
        newModule.imageUrl = null;
      }
      // Regardless, newModule is never stored anywhere, so we set the imageUrlSigned to null so the api doesn't produce error
      newModule.imageUrlSigned = null;
      console.log("Creating/Updating module: ", newModule);

      //if pre-existing survey, update, else create
      if (this.learningModule.learningModuleId) {
        var moduleResp = await LearningService.updateLearningModule(
          this.learningModule.learningModuleId,
          newModule
        );
        console.log("Updated module ", moduleResp);
      } else {
        moduleResp = await LearningService.createLearningModule(newModule);
        console.log("Created module ", moduleResp.module.learningModuleId);
        this.learningModule.learningModuleId =
          moduleResp.module.learningModuleId;
        //  We also copy over the conclusion chapter and card
        this.learningModule.learningChapters = JSON.parse(
          JSON.stringify(moduleResp.module.LearningChapters)
        );
      }

      if (this.learningModule.image !== null) {
        console.log(
          "Uploading a new image for this survey ",
          this.learningModule.image
        );
        // var attachment = {
        //   name: "Survey Banner",
        //   description: "Content used within a survey.",
        //   category: "SURVEY",
        //   file: this.learningModule.image,
        //   type: "module",
        //   clientId: this.clientId,
        //   fileName: this.learningModule.image.name,
        //   fileSize: this.learningModule.image.size,
        //   generateSignedUrl: false,
        // };

        // var contentResp = await ContentService.createContent(attachment);
        var updateResp = await LearningService.replaceLearningModuleImage(
          this.learningModule.learningModuleId,
          this.clientId,
          this.learningModule.image
          // {
          //   bannerContentId: contentResp.contentId,
          //   clientId: this.clientId,
          // }
        );
        console.log("Learning module image response: ", updateResp);

        // console.log("Content upload response: ", contentResp);

        // Updates the Content object so we have it if they create an incentive program
        // this.learningModule.Content = contentResp;
        this.learningModule.image = null;
        this.learningModule.imageUrl = updateResp.imageUrl;
        // this.learningModule.bannerContentId = contentResp.contentId;
      }

      this.savingChanges = false;
      // Updates the Content object so we have it if they create an incentive program
      if (publishFlag == "yes") {
        if (this.slide2.visibility == "PUBLIC") {
          //if public survey, display URL modal
          this.showUrlDialog = true;
        } else {
          //else, close the builder
          this.closeLearningBuilder();
        }
      } else {
        // if they have toggled the repeat switch we need to update the card
        if (this.toggleRepeat == true && this.slideKey == 2) {
          console.log("saving learning card");
          let surveyCard = this.learningModule.learningChapters[
            this.computedSurveyChapterIndex
          ].learningCards[this.computedSurveyCardIndex];
          var cardResponse = await LearningService.updateLearningCard(
            surveyCard.learningCardId,
            surveyCard
          );

          console.log("updated survey card ", cardResponse);
        }

        if (this.slide2.selectedGroups.length == 0) {
          this.goToSlide(2);
        } else if (
          // Check here for public surveys, skip if so
          this.slide3.selectedBadges.length == 0 &&
          this.slide3.noBadges == false &&
          this.slide2.visibility == "PRIVATE"
        ) {
          this.goToSlide(3);
        }
        //  else if (
        //   !this.slide4.incentModule &&
        //   this.slide2.visibility == "PRIVATE"
        // ) {
        //   // Check here for public surveys, skip if so
        //   this.goToSlide(4);
        // }
        else {
          console.log("go to main");
          //this.goToSlide(5);
          this.editCard(
            this.learningModule.learningChapters[
              this.computedSurveyChapterIndex
            ].learningCards[this.computedSurveyCardIndex],
            this.computedSurveyCardIndex,
            this.computedSurveyChapterIndex
          );
        }
      }
    },
    newChapter() {
      this.learningChapter = {
        valid: false,
        learningChapterId: null,
        name: null,
        displayName: null,
        description: null,
        imageUrl: null,
        image: null,
        learningModuleId: this.learningModule.learningModuleId,
        clientId: this.clientId
      };

      this.goToSlide(6);
    },
    editChapter(chapter) {
      this.learningChapter = JSON.parse(JSON.stringify(chapter));

      this.goToSlide(6);
    },
    removeChapterDialog(chapterIndex) {
      this.deleteChapterConfirmation = {
        dialog: true,
        chapterIndex: chapterIndex
      };
    },
    removeChapter() {
      // For now, this just deletes the chapter and the card association. The card and media remain active
      var chapterId = this.learningModule.learningChapters[
        this.deleteChapterConfirmation.chapterIndex
      ].learningChapterId;

      console.log("Deleting chapter ", chapterId);

      LearningService.deleteLearningChapter(chapterId)
        .then(response => {
          console.log("deleted chapter " + chapterId);
          console.log(response);

          // This answer was never saved to the database so we can slice
          console.log("Removing chapter from array");

          this.learningModule.learningChapters.splice(
            this.deleteChapterConfirmation.chapterIndex,
            1
          );

          this.deleteChapterConfirmation = {
            dialog: false,
            chapterIndex: null
          };
        })
        .catch(error => {
          console.log(error);
        });
    },
    newCard(chapterId, index) {
      console.log(chapterId);

      //this was my fix for the editing bug. during editing, we set learningCard to the edited card object.
      //making sure it gets fully reset, because something was hanging around.
      this.learningCard = {
        clientId: this.clientId,
        valid: false,
        learningChapterId: chapterId,
        learningChapterIndex: index,
        learningCardIndex: null,
        learningCardId: null,
        type: "text",
        typeItems: [
          { label: "Text/Image", value: "text" },
          { label: "Video", value: "video" },
          { label: "Audio", value: "audio" },
          { label: "PDF", value: "pdf" }
        ],
        quizItems: [
          { label: "Quiz", value: "quiz" },
          { label: "Assessment", value: "assessment" },
          { label: "Survey", value: "survey" }
        ],
        name: null,
        displayName: null,
        description: null,
        content: null,
        contentUrl: null,
        contentUrlFileName: null,
        contentUrlSigned: null,
        image: null,
        selectingImageFlag: false,
        bannerContentId: null
      };
      // this.learningCard.learningCardIndex = null;

      // this.learningCard.learningCardId = null;
      // this.learningCard.valid = false;
      // this.learningCard.name = null;
      // this.learningCard.displayName = null;
      // this.learningCard.description = null;
      // this.learningCard.content = null;
      // this.learningCard.contentUrl = null;
      // this.learningCard.type = "text";
      this.goToSlide(7);
    },
    editCard(card, cardIndex, chapterIndex) {
      console.log("Editing card", card);
      console.log(cardIndex);
      console.log(chapterIndex);
      this.learningCard = JSON.parse(JSON.stringify(card));
      this.learningCard.learningChapterId = card.learningChapterId;
      this.learningCard.learningCardIndex = cardIndex;
      this.learningCard.learningChapterIndex = chapterIndex;
      this.learningCard.image = null;
      this.learningCard.typeItems = [
        { label: "Text/Image", value: "text" },
        { label: "Video", value: "video" },
        { label: "Audio", value: "audio" },
        { label: "PDF", value: "pdf" }
      ];
      this.learningCard.quizItems = [
        { label: "Quiz", value: "quiz" },
        { label: "Assessment", value: "assessment" },
        { label: "Survey", value: "survey" }
      ];

      // We add a check to see if the contentUrlSigned is null, if it is, we will fetch a signedURL
      if (
        this.learningCard.contentUrl != null &&
        this.learningCard.contentUrlSigned == null
      ) {
        console.log("no signed url");
        if (
          !this.learningCard.contentUrl.startsWith(
            process.env.VUE_APP_CONTENT_URL_PREFIX
          )
        ) {
          console.log("image outside of whistle");
          // We have a normal URL (not hosted by Whistle), so we just link
          this.learningCard.contentUrlSigned = this.learningCard.contentUrl;
        } else {
          console.log("getting hosted image");
          // We have to get a signed url
          if (card.type == "video") {
            var shortUrl = card.contentUrl.substring(
              card.contentUrl.toLowerCase().indexOf("cards")
            );
            var formattedUrl =
              "learning/file/signedURL/" + shortUrl + "/encoded/hd.mp4";
          } else {
            formattedUrl = card.contentUrl.replace(
              process.env.VUE_APP_CONTENT_URL_PREFIX,
              ""
            );
          }

          console.log("Formatted URL: ", formattedUrl);
          LearningService.getSignedUrlForViewingContent(formattedUrl).then(
            signedResponse => {
              console.log(signedResponse.url);
              this.learningCard.contentUrlSigned = signedResponse.url;
            }
          );
        }
      }

      // this.learningCard.learningCardId = card.learningCardId;
      // this.learningCard.valid = card.valid;
      // this.learningCard.name = card.name;
      // this.learningCard.displayName = card.displayName;
      // this.learningCard.description = card.description;
      // this.learningCard.content = card.content;
      // this.learningCard.contentUrl = card.contentUrl;
      // this.learningCard.type = card.type;

      // We also preload the quiz data if it applies
      console.log("the data in question");
      console.log(card);
      console.log(card.name);
      console.log(card.learningCardQuestions);
      if (["quiz", "assessment", "survey"].includes(card.type)) {
        this.learningCardQuestions = JSON.parse(
          JSON.stringify(card.learningCardQuestions)
        );
        this.learningCardAnswersToDelete = Array();
        this.learningCard.learningCardQuestions = JSON.parse(
          JSON.stringify(card.learningCardQuestions)
        );
      }
      if (["quiz", "assessment", "survey"].includes(card.type)) {
        this.goToSlide(8);
      } else {
        this.goToSlide(7);
      }
    },
    removeCardDialog(chapterIndex, cardIndex) {
      console.log(chapterIndex + ", " + cardIndex);
      this.deleteCardConfirmation = {
        dialog: true,
        chapterIndex: chapterIndex,
        cardIndex: cardIndex
      };
    },
    removeCard() {
      // For now, this removes the card, media, and quizzes. Eventually this should just delete the card association so the user can reuse the cards
      var cardId = this.learningModule.learningChapters[
        this.deleteCardConfirmation.chapterIndex
      ].learningCards[this.deleteCardConfirmation.cardIndex].learningCardId;

      console.log("Deleting card ", cardId);

      LearningService.deleteLearningCard(cardId)
        .then(response => {
          console.log("deleted card " + cardId);
          console.log(response);

          // This answer was never saved to the database so we can slice
          console.log("Removing card from array");

          this.learningModule.learningChapters[
            this.deleteCardConfirmation.chapterIndex
          ].learningCards.splice(this.deleteCardConfirmation.cardIndex, 1);

          this.deleteCardConfirmation = {
            dialog: false,
            chapterIndex: null,
            cardIndex: null
          };
        })
        .catch(error => {
          console.log(error);
        });
    },
    newQuiz(chapterId, index) {
      console.log(chapterId);
      this.learningCard.learningChapterId = chapterId;
      this.learningCard.learningChapterIndex = index;
      this.learningCard.learningCardIndex = null;

      this.learningCard.learningCardId = null;
      this.learningCard.valid = false;
      this.learningCard.name = null;
      this.learningCard.displayName = null;
      this.learningCard.description = null;
      this.learningCard.contentUrl = null;
      this.learningCard.contentUrlSigned = null;
      this.learningCard.content = null;
      this.learningCard.type = "quiz";
      this.learningCardQuestions = Array();
      this.learningCardAnswersToDelete = Array();
      this.newQuestion();
      this.goToSlide(7);
    },
    newQuestion() {
      var newQuestion = {
        clientId: this.clientId,
        learningCardId: this.learningCard.learningCardId,
        type: "MULTIPLE_CHOICE",
        content: null,
        answers: [
          {
            clientId: this.clientId,
            learningQuizQuestionId: null,
            type: "text",
            content: null,
            correctness: 0
          }
        ]
      };
      this.learningCardQuestions.push(newQuestion);
    },
    removeQuestion(questionIndex) {
      if (
        this.learningCardQuestions[questionIndex].learningQuizQuestionId != null
      ) {
        // This question was never saved to the database so we can slice
        console.log("Adding question to array to delete from database");
        this.learningCardQuestionsToDelete.push(
          this.learningCardQuestions[questionIndex]
        );

        // We also check the answers delete array and remove anything there, because deleting a question automatically deletes the answers
        this.learningCardAnswersToDelete = this.learningCardAnswersToDelete.filter(
          x =>
            !this.learningCardQuestions[questionIndex]
              .learningQuizQuestionId === x.learningQuizQuestionId
        );
      }

      this.learningCardQuestions.splice(questionIndex, 1);
    },
    addAnswer(questionIndex) {
      this.learningCardQuestions[questionIndex].answers.push({
        clientId: this.clientId,
        learningQuizQuestionId: null,
        type: "text",
        content: null,
        correctness: 0
      });
    },
    removeAnswer(questionIndex, answerIndex) {
      if (
        this.learningCardQuestions[questionIndex].answers[answerIndex]
          .learningQuizAnswerId != null
      ) {
        // This answer was never saved to the database so we can slice
        console.log("Adding answer to array to delete from database");
        this.learningCardAnswersToDelete.push(
          this.learningCardQuestions[questionIndex].answers[answerIndex]
        );
      }
      this.learningCardQuestions[questionIndex].answers.splice(answerIndex, 1);
    },
    updateLearningChapterOrder() {
      var chapters = this.learningModule.learningChapters.map((x, index) => {
        return {
          clientId: this.clientId,
          learningChapterId: x.learningChapterId,
          learningModuleId: x.learningModuleId,
          order: index,
          updatedBy: this.userProfile.userId
        };
      });
      // Update the last chapter to be -1 since it's the end card
      chapters[chapters.length - 1].order = -1;
      console.log("update chapter order ", chapters);

      LearningService.updateLearningChapterBulk(chapters)
        .then(response => {
          console.log("updated " + response);
          this.savingChanges = false;
        })
        .catch(error => {
          console.log(error);
        });
    },
    saveLearningCard() {
      // Saving this learning card and then we publish the module
      // We have to loop through the content and find any img tags
      this.savingChanges = true;
      var contentWithCustomTags = this.learningCard.content;
      var imgArray = [];
      while (
        contentWithCustomTags != null &&
        contentWithCustomTags.indexOf('<img src="data:') != -1
      ) {
        // We search for an img tag
        var index = contentWithCustomTags.indexOf('<img src="data:');

        // Then we find the img's closing tag
        var endIndex = contentWithCustomTags.indexOf(">", index + 1);

        // String manipulation to get just the img tag (to splice out later) and the raw img data
        var imgTag = contentWithCustomTags.substring(index, endIndex + 1);
        var imgData = imgTag.substring(
          imgTag.indexOf('="') + 2,
          imgTag.length - 2
        );

        var fileExtension = imgData.substring(
          imgData.indexOf(":image/") + 7,
          imgData.indexOf(";")
        );

        var arr = imgData.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var imgFile = new File(
          [u8arr],
          "learning_card_image." + fileExtension,
          {
            type: mime
          }
        );

        // Generate a random ID to tie to it
        var randomId = Math.floor(Math.random() * Date.now());
        imgArray.push({ data: imgFile, id: randomId });

        // Then we just replace that with a custom img tag to replace later
        var newContent = contentWithCustomTags.replace(
          imgTag,
          `<img-custom ${randomId}>`
        );
        contentWithCustomTags = newContent;
      }
      var contentWithSignedUrl = contentWithCustomTags;

      // Before we go and create the other content, we have to replace any previously entered signedUrl's with the custom tags
      // We search for an img tag

      var signedUrlIndex =
        contentWithCustomTags == null
          ? -1
          : contentWithCustomTags.indexOf('<img id="contentId-');
      while (signedUrlIndex != -1) {
        var endTag = contentWithCustomTags.indexOf('"', signedUrlIndex + 10);
        var closingTag = contentWithCustomTags.indexOf(">", signedUrlIndex);
        var customImageTag = contentWithCustomTags.substring(
          signedUrlIndex,
          closingTag + 1
        );
        var contentId = contentWithCustomTags.substring(
          signedUrlIndex + 19,
          endTag
        );
        console.log("Found signed URL for " + contentId);
        contentWithCustomTags = contentWithCustomTags.replace(
          customImageTag,
          `<img-custom ${contentId}>`
        );
        signedUrlIndex = contentWithCustomTags.indexOf('<img id="contentId-');
      }

      var self = this;
      contentUploadLoop(0);
      function contentUploadLoop(index) {
        // We loop through and upload all content within the html
        if (index < imgArray.length) {
          // Upload an image
          var attachment = {
            name: "Learning Card Content",
            description: "Image used within the content of a learning card.",
            category: "LEARNING_CARD_CONTENT",
            clientId: self.clientId,
            file: imgArray[index].data,
            generateSignedUrl: true,
            type: "image"
          };
          ContentService.createContent(attachment).then(imageResponse => {
            console.log(imageResponse);
            var contentId = imageResponse.contentId;
            // Then we replace
            contentWithCustomTags = contentWithCustomTags.replace(
              `<img-custom ${imgArray[index].id}>`,
              `<img-custom ${contentId}>`
            );
            contentWithSignedUrl = contentWithSignedUrl.replace(
              `<img-custom ${imgArray[index].id}>`,
              `<img id="contentId-${contentId}" src="${imageResponse.contentUrlSigned}">`
            );
            console.log(contentWithCustomTags);
            var temp = index + 1;
            contentUploadLoop(temp);
          });
        } else {
          console.log(self.learningModule);
          var newCard = JSON.parse(JSON.stringify(self.learningCard));
          var originalContentUrl = newCard.contentUrl;
          newCard.displayName = self.learningCard.name;
          newCard.learningModuleId = self.learningModule.learningModuleId;
          newCard.updatedBy = self.userProfile.userId;
          newCard.clientId = self.clientId;

          newCard.content = contentWithCustomTags;

          // If there is a new image or a bannerContentId, then we set imageUrl to null;
          // Otherwise, we don't touch it because they either removed the image or it's a defined url that we don't touch.
          if (newCard.image !== null || newCard.bannerContentId !== null) {
            console.log("Setting module image url to null");
            newCard.contentUrl = null;
          }

          newCard.contentUrlSigned = null;

          //if pre-existing module, update, else create
          if (self.learningCard.learningCardId != null) {
            LearningService.updateLearningCard(
              self.learningCard.learningCardId,
              newCard
            )
              .then(response => {
                console.log("updated " + response.card);
                if (self.learningCard.image !== null) {
                  //We are editing a completely fresh image
                  // Even if we're editing a learning survey, it deletes any old images first
                  console.log(
                    "Uploading a new image for this card",
                    self.learningCard.image
                  );
                  console.log(newCard.learningCardId);
                  console.log(self.learningCard.image);
                  // If the file is less than 8MB, then we use the endpoint, otherwise we get a signed URL to upload directly to GCS
                  // if (this.learningCard.image.size < 8000000) {
                  var content = {
                    name: "Learning Content " + newCard.learningCardId,
                    description: "Content used within a learning card.",
                    category: "LEARNING_CARD",
                    type: self.learningCard.type,
                    file: self.learningCard.image,
                    fileName: self.learningCard.image.name,
                    fileSize: self.learningCard.image.size,
                    clientId: self.clientId,
                    generateSignedUrl: false
                  };
                  ContentService.createContent(content)
                    .then(imageResponse => {
                      self.savingChanges = false;
                      console.log(imageResponse);
                      if (imageResponse.signedUrl != null) {
                        // We have to initiate an upload since the file was large
                        if (self.learningCard.type == "video") {
                          self.showLoadingDialog = true;
                          self.loadingHeaderMessage = "Uploading video";
                          self.loadingMessageLine1 =
                            "Depending on the size of your video,";
                          self.loadingMessageLine2 =
                            "this might take a little while";
                        }
                        ContentService.uploadContentWithSignedUrl(
                          imageResponse.signedUrl,
                          self.learningCard.image
                        ).then(resp => {
                          console.log("Response from uploading content ", resp);
                          ContentService.encodeFile(
                            imageResponse.filePath,
                            newCard.type
                          ).then(encodeResponse => {
                            // The video has begun encoding and the jobId is stored in the database
                            console.log("Encoding response: ", encodeResponse);
                            // Finally, we update the db object
                            LearningService.updateLearningCard(
                              newCard.learningCardId,
                              {
                                bannerContentId: imageResponse.contentId,
                                clientId: self.clientId
                              }
                            ).then(response => {
                              console.log(response);
                              self.savingChanges = false;
                              self.showLoadingDialog = false;
                              self.learningCard.contentUrl =
                                process.env.VUE_APP_CONTENT_URL_PREFIX +
                                imageResponse.filePath;
                              // We save the image to the contentUrlSigned so we can play it back locally during this session
                              var tempCard = JSON.parse(
                                JSON.stringify(self.learningCard)
                              );
                              // We locally store the content with the pre-signed url's. In the database we store with the custom tags that we swap later
                              tempCard.content = contentWithSignedUrl;
                              self.learningCard.contentUrlSigned = originalContentUrl;
                              self.learningCard.image = null;
                              self.learningCard.bannerContentId =
                                imageResponse.contentId;
                              self.learningModule.learningChapters[
                                self.learningCard.learningChapterIndex
                              ].learningCards[
                                self.learningCard.learningCardIndex
                              ] = tempCard;
                              console.log(self.learningModule);
                              //if this is not a quiz, or if it is a quiz, but we're done adding questions, take us back to slide 4
                              // We've saved the card, now we publish
                              self.saveLearningModule("yes");
                              // if (
                              //   !["quiz", "assessment", "survey"].includes(
                              //     newCard.type
                              //   )
                              // ) {
                              //   self.goToSlide(5);
                              // } else {
                              //   if (self.learningCardQuestions.length == 0) {
                              //     self.newQuestion();
                              //   }
                              //   self.goToSlide(8);
                              // }
                            });
                          });
                        });
                      } else {
                        LearningService.updateLearningCard(
                          newCard.learningCardId,
                          {
                            bannerContentId: imageResponse.contentId,
                            clientId: self.clientId
                          }
                        ).then(response => {
                          console.log(response);
                          self.savingChanges = false;
                          self.learningCard.contentUrl =
                            process.env.VUE_APP_CONTENT_URL_PREFIX +
                            imageResponse.filePath;
                          // We save the image to the contentUrlSigned so we can play it back locally during this session
                          self.learningCard.contentUrlSigned = originalContentUrl;
                          self.learningCard.image = null;
                          self.learningCard.bannerContentId =
                            imageResponse.contentId;
                          self.learningModule.learningChapters[
                            self.learningCard.learningChapterIndex
                          ].learningCards[
                            self.learningCard.learningCardIndex
                          ] = JSON.parse(JSON.stringify(self.learningCard));
                          console.log(self.learningModule);

                          // We've saved the card, now we publish
                          self.saveLearningModule("yes");
                        });
                      }
                    })
                    .catch(error => {
                      console.log(error);
                    });
                } else {
                  //no image to upload
                  self.savingChanges = false;
                  var tempCard = JSON.parse(JSON.stringify(self.learningCard));
                  tempCard.content = contentWithSignedUrl;
                  console.log(self.learningModule);
                  self.learningModule.learningChapters[
                    self.learningCard.learningChapterIndex
                  ].learningCards[
                    self.learningCard.learningCardIndex
                  ] = tempCard;
                  console.log(self.learningModule);
                  // We've saved the card, now we publish
                  self.saveLearningModule("yes");
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else {
            console.log("creating new card");
            newCard.createdBy = self.userProfile.userId;
            newCard.order =
              self.learningModule.learningChapters[
                self.learningCard.learningChapterIndex
              ].learningCards.length;
            console.log(newCard);
            newCard.clientId = this.clientId;
            LearningService.createLearningCardInChapter(
              newCard.learningChapterId,
              newCard
            )
              .then(response => {
                console.log(response);
                //console.log("created " + response.chapter.learningCardId);
                console.log(self.learningCard);
                self.learningCard.learningCardId = response.card.learningCardId;
                self.learningCard.learningCardAssociationId =
                  response.association.learningCardAssociationId;
                console.log("push to chapter:");
                console.log(newCard.learningChapterIndex);
                //Ben here, trying something to stop from uploading empty object on edit
                response.card.image = null;
                if (self.learningCard.image !== null) {
                  //We are editing a completely fresh image
                  console.log(
                    "Uploading a new image for this card ",
                    self.learningCard.image
                  );
                  var attachment = {
                    name: "Learning Card Banner",
                    description: "Content used within a learning card.",
                    category: "LEARNING_CARD",
                    file: self.learningCard.image,
                    type: self.learningCard.type,
                    fileName: self.learningCard.image.name,
                    fileSize: self.learningCard.image.size,
                    clientId: self.clientId,
                    generateSignedUrl: false
                  };
                  ContentService.createContent(attachment)
                    .then(imageResponse => {
                      console.log("Image response ", imageResponse);

                      if (imageResponse.signedUrl != null) {
                        // We have to initiate an upload since the file was large
                        if (self.learningCard.type == "video") {
                          self.showLoadingDialog = true;
                          self.loadingHeaderMessage = "Uploading video";
                          self.loadingMessageLine1 =
                            "Depending on the size of your video,";
                          self.loadingMessageLine2 =
                            "this might take a little while";
                        }
                        ContentService.uploadContentWithSignedUrl(
                          imageResponse.signedUrl,
                          self.learningCard.image
                        ).then(resp => {
                          console.log("Response from uploading content ", resp);
                          ContentService.encodeFile(
                            imageResponse.filePath,
                            self.learningCard.type
                          ).then(encodeResponse => {
                            console.log("Encoding response: ", encodeResponse);
                            // Finally, we update the db object
                            LearningService.updateLearningCard(
                              response.card.learningCardId,
                              {
                                bannerContentId: imageResponse.contentId,
                                clientId: self.clientId
                              }
                            ).then(response => {
                              console.log(response);
                              self.savingChanges = false;
                              self.showLoadingDialog = false;
                              self.learningCard.contentUrl =
                                process.env.VUE_APP_CONTENT_URL_PREFIX +
                                imageResponse.filePath;
                              // We save the image to the contentUrlSigned so we can play it back locally during this session
                              var tempCard = JSON.parse(
                                JSON.stringify(self.learningCard)
                              );
                              // We locally store the content with the pre-signed url's. In the database we store with the custom tags that we swap later
                              tempCard.content = contentWithSignedUrl;
                              self.learningCard.contentUrlSigned = originalContentUrl;
                              self.learningCard.bannerContentId =
                                imageResponse.contentId;
                              self.learningCard.image = null;

                              console.log(self.learningModule);
                              //add the temp card to local content:
                              self.learningModule.learningChapters[
                                newCard.learningChapterIndex
                              ].learningCards.push(tempCard);
                              // Update the learning card index
                              self.learningCard.learningCardIndex =
                                self.learningModule.learningChapters[
                                  newCard.learningChapterIndex
                                ].learningCards.length - 1;
                              // We've saved the card, now we publish
                              self.saveLearningModule("yes");
                            });
                          });
                        });
                      } else {
                        LearningService.updateLearningCard(
                          response.card.learningCardId,
                          {
                            bannerContentId: imageResponse.contentId,
                            clientId: self.clientId
                          }
                        ).then(resp => {
                          console.log(resp);

                          var card = response.card;
                          card.contentUrlSigned = originalContentUrl;
                          card.contentUrl =
                            process.env.VUE_APP_CONTENT_URL_PREFIX +
                            imageResponse.filePath;
                          card.bannerContentId = imageResponse.contentId;
                          card.image = null;
                          card.learningCardAssociationId =
                            response.association.learningCardAssociationId;
                          card.content = contentWithSignedUrl;
                          // Now we just have to save the new imageURL to the learningModule object
                          self.learningModule.learningChapters[
                            newCard.learningChapterIndex
                          ].learningCards.push(card);
                          // Update the learning card index
                          self.learningCard.learningCardIndex =
                            self.learningModule.learningChapters[
                              newCard.learningChapterIndex
                            ].learningCards.length - 1;
                          self.savingChanges = false;
                          // We've saved the card, now we publish
                          self.saveLearningModule("yes");
                        });
                      }
                      // LearningService.updateLearningCard(
                      //   response.card.learningCardId,
                      //   { bannerContentId: imageResponse.contentId }
                      // )
                      //   .then((updateResponse) => {
                      //     console.log(updateResponse);
                      //     var card = response.card;
                      //     card.contentUrlSigned =
                      //       originalContentUrl;
                      //     card.contentUrl = imageResponse.filePath;
                      //     card.bannerContentId = imageResponse.contentId;
                      //     card.image = null;
                      //     card.learningCardAssociationId =
                      //       response.association.learningCardAssociationId;
                      //     card.content = contentWithSignedUrl;
                      //     // Now we just have to save the new imageURL to the learningModule object
                      //     self.learningModule.learningChapters[
                      //       newCard.learningChapterIndex
                      //     ].learningCards.push(card);
                      //     self.savingChanges = false;
                      //     //if this is not a quiz, or if it is a quiz, but we're done adding questions, take us back to slide 3
                      //     if (
                      //       !["quiz", "assessment", "survey"].includes(
                      //         newCard.type
                      //       )
                      //     ) {
                      //       self.goToSlide(3);
                      //     } else {
                      //       if (self.learningCardQuestions.length == 0) {
                      //         self.newQuestion();
                      //       }
                      //       self.goToSlide(6);
                      //     }
                      //   })
                      //   .catch((error) => {
                      //     console.log(error);
                      //   });
                    })
                    .catch(error => {
                      console.log(error);
                    });
                } else {
                  //didn't have an image to upload
                  var card = response.card;
                  card.learningCardAssociationId =
                    response.association.learningCardAssociationId;
                  card.content = contentWithSignedUrl;
                  self.learningModule.learningChapters[
                    newCard.learningChapterIndex
                  ].learningCards.push(card);
                  // Update the learning card index
                  self.learningCard.learningCardIndex =
                    self.learningModule.learningChapters[
                      newCard.learningChapterIndex
                    ].learningCards.length - 1;
                  self.savingChanges = false;
                  if (
                    !["quiz", "assessment", "survey"].includes(newCard.type)
                  ) {
                    self.goToSlide(5);
                  } else {
                    if (self.learningCardQuestions.length == 0) {
                      self.newQuestion();
                    }
                    self.goToSlide(8);
                  }
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        }
      }
    },
    saveLearningQuiz() {
      console.log(this.learningCard);
      var currentCard = JSON.parse(JSON.stringify(this.learningCard));
      this.savingChanges = true;

      var self = this;
      // var clientId = this.clientId;

      // We save each update/create result so we can have an up-to-date local version with the right IDs
      var finalArray = [];
      questionLoop(0);
      function questionLoop(questionIndex) {
        var tempQuestionObject = null;
        var tempAnswersArray = [];

        if (questionIndex < self.learningCardQuestions.length) {
          var question = self.learningCardQuestions[questionIndex];
          //get the card ID of the parent learning card
          question.learningCardId = currentCard.learningCardId;

          if (question.hasProgramMetric == true && !question.metricId) {
            question.metricId = -1;
          }
          //save the question
          if (question.learningQuizQuestionId != null) {
            // We need to edit the quiz question
            console.log(
              "EDITING QUIZ QUESTION ",
              question.learningQuizQuestionId
            );
            LearningService.updateLearningQuizQuestion(
              question.learningQuizQuestionId,
              question
            ).then(response => {
              console.log(response);

              tempQuestionObject = question;
              // if (question.type == "SCALE") {
              //   // A scale shouldn't have any answers so we alter the first one in the array
              //   if (question.answers.length == 0) {
              //     question.answers = [
              //       {
              //         clientId: clientId,
              //         learningQuizQuestionId: question.learningQuizQuestionId,
              //         learningQuizAnswerId: question.correctAnswerId,
              //         type: "text",
              //         content: question.correctAnswer,
              //         correctness: 1,
              //       },
              //     ];
              //   } else {
              //     question.answers[0] = {
              //       clientId: clientId,
              //       learningQuizQuestionId: question.learningQuizQuestionId,
              //       learningQuizAnswerId: question.correctAnswerId,
              //       type: "text",
              //       content: question.correctAnswer,
              //       correctness: 1,
              //     };
              //   }
              // }

              answerLoop(0);
              function answerLoop(answerIndex) {
                if (answerIndex < question.answers.length) {
                  var answer = question.answers[answerIndex];
                  if (answer.learningQuizAnswerId != null) {
                    // Then we edit an existing quiz answer
                    // We check if the content is filled out AND we're dealing with multiple choice.
                    // If it's scale, we only want one answer, so we delete any besides the first
                    if (
                      answer.content != null &&
                      answer.content != "" &&
                      // Either multiple choice or if it's a scale then we check if we're looking at the first answer
                      ((question.type != "SCALE" &&
                        question.type != "LIKERT" &&
                        question.type != "SHORT_ANSWER") ||
                        (question.type == "SCALE" && answerIndex == 0))
                    ) {
                      console.log(
                        "Updating answer " +
                          answer.learningQuizAnswerId +
                          " for quiz " +
                          question.learningQuizQuestionId
                      );

                      answer.learningQuizQuestionId =
                        question.learningQuizQuestionId;

                      console.log(answer);

                      tempAnswersArray.push(answer);

                      LearningService.updateLearningQuizAnswer(
                        answer.learningQuizAnswerId,
                        answer
                      ).then(response => {
                        console.log(response);

                        var tempA = answerIndex + 1;
                        answerLoop(tempA);
                      });

                      //blank answers - still need to check if we're finished
                    } else {
                      console.log(
                        "Deleting answer " +
                          answer.learningQuizAnswerId +
                          " for quiz " +
                          question.learningQuizQuestionId
                      );
                      // We are editing and the content is blank, so we delete
                      LearningService.deleteLearningQuizAnswer(
                        answer.learningQuizAnswerId
                      ).then(response => {
                        console.log(response);

                        //if this is the last answer of the last question, then we're done
                        var tempA = answerIndex + 1;
                        answerLoop(tempA);
                      });
                    }
                  } else {
                    if (answer.content != null && answer.content != "") {
                      console.log(
                        "Creating answer for question " +
                          question.learningQuizQuestionId
                      );
                      answer.learningQuizQuestionId =
                        question.learningQuizQuestionId;

                      console.log(answer);

                      LearningService.createLearningQuizAnswer(answer).then(
                        response => {
                          console.log(response);
                          tempAnswersArray.push(response.answer);

                          //if this is the last answer of the last question, then we're done
                          var tempA = answerIndex + 1;
                          answerLoop(tempA);
                        }
                      );

                      //blank answers - still need to check if we're finished
                    } else {
                      //if this is the last answer of the last question, then we're done
                      var tempA = answerIndex + 1;
                      answerLoop(tempA);
                    }
                  }
                } else {
                  // Before continuing, we push the answers to the question object and add it to our final array
                  tempQuestionObject.answers = tempAnswersArray;
                  finalArray.push(tempQuestionObject);
                  var tempQ = questionIndex + 1;
                  questionLoop(tempQ);
                }
              }
            });
          } else {
            // We are creating a new quiz question
            console.log("Creating quiz question and answers");
            // if (question.type == "SCALE") {
            //   // A scale shouldn't have any answers so we alter the first one in the array
            //   if (question.answers.length == 0) {
            //     question.answers = [
            //       {
            //         clientId: clientId,
            //         learningQuizQuestionId: question.learningQuizQuestionId,
            //         learningQuizAnswerId: question.correctAnswerId,
            //         type: "text",
            //         content: question.correctAnswer,
            //         correctness: 1,
            //       },
            //     ];
            //   } else {
            //     question.answers[0] = {
            //       clientId: clientId,
            //       learningQuizQuestionId: question.learningQuizQuestionId,
            //       learningQuizAnswerId: question.correctAnswerId,
            //       type: "text",
            //       content: question.correctAnswer,
            //       correctness: 1,
            //     };
            //   }
            // }
            LearningService.createLearningQuizQuestion(question).then(
              response => {
                tempQuestionObject = response.question;
                answerLoop(0);
                function answerLoop(answerIndex) {
                  if (answerIndex < question.answers.length) {
                    var answer = question.answers[answerIndex];
                    //only save answers that have content
                    if (answer.content != null && answer.content != "") {
                      answer.learningQuizQuestionId =
                        response.question.learningQuizQuestionId;

                      console.log(answer);

                      LearningService.createLearningQuizAnswer(answer).then(
                        response => {
                          console.log(response);

                          tempAnswersArray.push(response.answer);

                          //if this is the last answer of the last question, then we're done
                          var tempA = answerIndex + 1;
                          answerLoop(tempA);
                        }
                      );

                      //blank answers - still need to check if we're finished
                    } else {
                      //if this is the last answer of the last question, then we're done
                      var tempA = answerIndex + 1;
                      answerLoop(tempA);
                    }
                  } else {
                    // Before continuing, we push the answers to the question object and add it to our final array
                    tempQuestionObject.answers = tempAnswersArray;
                    finalArray.push(tempQuestionObject);

                    var tempQ = questionIndex + 1;
                    questionLoop(tempQ);
                  }
                }
              }
            );
          }
        } else {
          // After updating questions and answers, we remove any answers that got deleted
          self
            .handleQuizQuestionDeletion(self.learningCardQuestionsToDelete)
            .then(resp => {
              console.log("Returned from quiz question deletion", resp);
              self
                .handleQuizAnswerDeletion(self.learningCardAnswersToDelete)
                .then(deleteResponse => {
                  console.log(
                    "Returned from deleting quiz answers",
                    deleteResponse
                  );

                  console.log(self.learningCard);
                  console.log(
                    "Chapter",
                    self.learningModule.learningChapters[
                      self.learningCard.learningChapterIndex
                    ]
                  );
                  var cardIndex = self.learningModule.learningChapters[
                    self.learningCard.learningChapterIndex
                  ].learningCards.findIndex(
                    x => x.learningCardId == self.learningCard.learningCardId
                  );
                  console.log("CARD INDEX ", cardIndex);
                  if (cardIndex !== -1) {
                    self.learningModule.learningChapters[
                      self.learningCard.learningChapterIndex
                    ].learningCards[
                      cardIndex
                    ].learningCardQuestions = finalArray;
                  }
                  console.log("done with questions and answers");
                  self.learningCardQuestions = Array();
                  self.savingChanges = false;
                  self.editCard(
                    self.learningModule.learningChapters[1].learningCards[0],
                    0,
                    0
                  );
                });
            });
        }
      }
    },
    handleQuizQuestionDeletion(array) {
      if (array.length == 0) {
        return Promise.resolve([]);
      } else {
        var idArray = array.map(x => x.learningQuizQuestionId);
        return LearningService.deleteLearningQuizQuestion(idArray).then(
          deleteResponse => {
            console.log(deleteResponse);
            return Promise.resolve(deleteResponse);
          }
        );
      }
    },
    handleQuizAnswerDeletion(array) {
      if (array.length == 0) {
        return Promise.resolve();
      } else {
        var answers = array.map(x => {
          var obj = x;
          obj.status = "Deleted";
          return obj;
        });
        return LearningService.createLearningQuizAnswerBulk(answers).then(
          deleteResponse => {
            console.log(deleteResponse);
            return deleteResponse;
          }
        );
      }
    },
    resetAnswers(index, type) {
      for (
        var i = this.learningCardQuestions[index].answers.length - 1;
        i >= 0;
        i--
      ) {
        this.removeAnswer(index, i);
      }
      if (type == "SCALE") {
        // If type is flipped to scale, then we add all of the current answers to remove
        // this.learningCardQuestions[index].correctAnswer = null;
        // this.learningCardQuestions[index].correctAnswerId = null;
      } else if (type == "MULTIPLE_CHOICE" || type == "SELECT_ALL") {
        // this.learningCardQuestions[index].correctAnswer = null;
        // this.learningCardQuestions[index].correctAnswerId = null;
        this.learningCardQuestions[index].lowerBound = null;
        this.learningCardQuestions[index].upperBound = null;
        this.learningCardQuestions[index].answers = [
          {
            clientId: this.clientId,
            learningQuizQuestionId: null,
            type: "text",
            content: null,
            correctness: 0
          }
        ];
      } else if (type == "LIKERT" || type == "SHORT_ANSWER") {
        // this.learningCardQuestions[index].correctAnswer = null;
        // this.learningCardQuestions[index].correctAnswerId = null;
        this.learningCardQuestions[index].lowerBound = null;
        this.learningCardQuestions[index].upperBound = null;
      }
    },
    //this creates the incentive program in the background
    saveLearningProgram() {
      if (
        !this.slide4.incentModule ||
        this.slide4.preloadedProgram ||
        !this.permissions.includes("programs:create:program")
      ) {
        // If we're not incenting or editing a survey, then we continue on
        //this.goToSlide(5);

        if (this.learningModule.learningChapters[0].learningCards[0]) {
          this.editCard(
            this.learningModule.learningChapters[0].learningCards[0],
            0,
            0
          );
        } else {
          //create an empty survey here
        }
      } else {
        var program = {
          clientId: this.clientId,
          externalId: null,
          name: this.learningModule.name,
          displayName: this.learningModule.name,
          description: this.learningModule.description,
          imageURL: this.learningModule.imageUrl,
          type: "learning",
          status: "Scheduled",
          audience: {
            groups: this.slide2.selectedGroups.map(x => x.groupId)
          },
          budgetId: this.slide4.budgetId,
          payoutFrequency: "ONGOING",
          mandatory: this.slide4.mandatory,
          startDate: startDate,
          endDate: endDate,
          rollingProgramLength: this.slide4.rollingProgramLength,
          newUsersAfterProgramStart: true,
          rules: {
            qualifiers: null,
            payoutRules: [
              {
                payoutRule: {
                  metricId: null,
                  type: "HIT_AND_WIN",
                  amount: this.slide4.awardValue,
                  thresholdMin: null,
                  thresholdMinInclusive: false,
                  thresholdMax: null,
                  thresholdMaxInclusive: false,
                  badgeId: null
                },
                rules: {
                  id: 10,
                  rule: false,
                  depth: 1,
                  operator: "AND",
                  group: [
                    {
                      id: 11,
                      rule: true,
                      context: "LearningModules",
                      contextColumn: "metricValue",
                      contextResourceId: this.learningModule.learningModuleId,
                      operator: "GREATER_THAN_EQUAL",
                      value: "100",
                      lastInGroup: true
                    }
                  ],
                  name: "Completing " + this.learningModule.name,
                  description:
                    "Complete the " + this.learningModule.name + " survey.",
                  lastInGroup: true,
                  primaryRuleGroup: true
                }
              }
            ]
          },
          messages: [],
          sourceLearningModuleId: this.learningModule.learningModuleId,
          updatedBy: this.userProfile.userId,
          createdBy: this.userProfile.userId
        };

        //We convert the start date/time into the time zone and then UTC
        var startDate = null;
        var endDate = null;

        if (this.slide4.startDate) {
          var startHours = this.slide4.startTime.hours;
          if (this.slide4.startTime.amPm == "PM") {
            if (startHours != 12) {
              startHours = parseInt(startHours) + 12;
            }
          } else {
            if (startHours == 12) {
              //Should be 0
              startHours = 0;
            }
          }

          if (startHours.toString().length == 1) {
            startHours = "0" + startHours.toString();
          }

          var startDateString = this.slide4.startDate
            .toString()
            .concat(" ", startHours, ":", this.slide4.startTime.minutes);
          startDate = momentTz.tz(
            startDateString,
            this.slide4.startTime.timezone.value
          );

          console.log("UTC Start Date: ", startDate.utc().format());
          startDate = startDate.utc().format();
        }

        program.startDate = startDate;

        if (this.slide4.endDate) {
          //We convert the expiration date/time into the time zone and then UTC
          var endHours = this.slide4.endTime.hours;
          if (this.slide4.endTime.amPm == "PM" && endHours !== null) {
            if (endHours != 12) {
              endHours = parseInt(endHours) + 12;
            }
          } else if (endHours !== null) {
            if (endHours == 12) {
              //Should be 0
              endHours = 0;
            }
          }

          if (endHours.toString().length == 1 && endHours !== null) {
            endHours = "0" + endHours.toString();
          }

          endDate = null;
          if (
            this.slide4.endDate !== null &&
            endHours !== null &&
            this.slide4.startTime.timezone.value !== null &&
            this.slide4.endTime.minutes !== null
          ) {
            var endDateString = this.slide4.endDate
              .toString()
              .concat(" ", endHours, ":", this.slide4.endTime.minutes);
            endDate = momentTz.tz(
              endDateString,
              this.slide4.startTime.timezone.value
            );
            console.log("UTC End Date: ", endDate.utc().format());
            endDate = endDate.utc().format();
          }
        }

        program.endDate = endDate;

        if (this.slide4.rollingProgramLength) {
          // We're dealing with a rolling program
          var programLength = this.slide4.rollingProgramLength;
        } else {
          // We're dealing with a normal program
          programLength = momentTz(this.slide4.endDate).diff(
            momentTz(this.slide4.startDate),
            "days"
          );
        }

        // Now that we have the program length in days, we can insert the messages
        var msgContent = [
          {
            subject:
              "You have been invited to join a new Whistle program that is starting now!",
            subtitle: "Follow the link to find out more info.",
            body: `This program will last about ${programLength} days with the opportunity to earn an award at the end!`,
            daysToEnd: programLength
          }
        ];

        if (programLength > 2) {
          // We only add other messages if the program is longer than 2 days so we don't spam them.
          var longerProgramMessages = [
            {
              subject: `The Whistle ${this.learningModule.name} program is halfway done!`,
              subtitle: "Follow the link to track your progress.",
              body: `The program is almost over! Keep up the great work!`,
              daysToEnd: (programLength / 2).toFixed(0)
            },
            {
              subject: `The Whistle ${this.learningModule.name} program ends tomorrow!`,
              subtitle: "Follow the link to track your progress.",
              body: `With just one day left, it's not too late to make progress towards your goal! See what's left by logging into Whistle.`,
              daysToEnd: 1
            }
          ];
          msgContent = msgContent.concat(longerProgramMessages);
        }

        program.messages = msgContent.map(x => {
          return {
            messageSubject: x.subject,
            messageShortBody: x.subtitle,
            messageBody: x.body,
            // messageBody: formatEmailTemplate(
            //   emailTemplate,
            //   "programs/{{programId}}",
            //   "programs",
            //   "star",
            //   x.subject,
            //   x.subtitle,
            //   x.body,
            //   false
            // ),
            imageURL: null,
            displayImageInCardPreview: false,
            sendDate: null,
            expirationDate: null,
            numDaysToProgramEnd: x.daysToEnd,
            displayOnPlatform: true,
            sendEmail: true,
            sendSMS: true,
            sendPush: true,
            sendDashboard: true,
            createdBy: this.userProfile.userId,
            updatedBy: this.userProfile.userId
          };
        });

        this.savingChanges = true;

        console.log(program);

        // creating a new program!
        ProgramService.createProgram(program)
          .then(response => {
            console.log("created incentive program " + response.programId);

            // THe Program Image duplication endpoint can handle both duplicating an old program image as well as a learning survey image
            if (this.learningModule.Content) {
              console.log("Duplicating survey image for new program");
              ProgramService.duplicateProgramImage(
                null,
                response.programId,
                this.learningModule.Content.filePath,
                this.clientId
              )
                .then(imageResponse => {
                  console.log(imageResponse);
                  this.savingChanges = false;

                  //this.goToNextSlide();
                  this.editCard(
                    this.learningModule.learningChapters[0].learningCards[0],
                    0,
                    0
                  );
                })
                .catch(error => {
                  console.log(error);
                });
            } else {
              this.savingChanges = false;

              //his.goToNextSlide();
              this.editCard(
                this.learningModule.learningChapters[0].learningCards[0],
                0,
                0
              );
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    focusOnTitle() {
      this.$refs.titleInput.focus();
    },
    focusOnDescription() {
      this.$refs.descriptionInput.focus();
    },

    focusOnTypeSelect() {
      this.$refs.typeInput.focus();
    },
    closeImageUrlDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      var url = this.learningModule.imageUrlTemp;
      if (submitPressed) {
        if (url == "" || url == null || url.replace(/\s+/g, "").length == 0) {
          this.learningModule.imageUrlTemp = null;
        } else {
          this.learningModule.imageUrl = url;
          this.learningModule.imageUrlSigned = url;
          this.learningModule.image = null;
          this.learningModule.bannerContentId = null;
        }
      }
      //Regardless, we close dialog
      this.learningModule.dialogImageUrl = false;
      this.learningModule.imageUrlTemp = null;
    },
    closeCardImageUrlDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      var url = this.learningModule.imageUrlTemp;
      if (submitPressed) {
        if (url == "" || url == null || url.replace(/\s+/g, "").length == 0) {
          this.learningModule.imageUrlTemp = null;
        } else {
          this.learningCard.contentUrl = url;
          this.learningCard.contentUrlSigned = url;
          this.learningCard.image = null;
          this.learningCard.bannerContentId = null;
        }
      }
      //Regardless, we close dialog
      this.learningModule.dialogImageUrl = false;
      this.learningModule.imageUrlTemp = null;
    },
    closeImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cropper.getResult();
        this.learningModule.imageUrl = canvas.toDataURL();
        this.learningModule.imageUrlSigned = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.learningModule.imageUrlFileName);
          this.learningModule.image = file;

          //Regardless, we close dialog
          this.learningModule.dialogImageCropper = false;
          this.learningModule.imageUrlToCrop = null;
          this.learningModule.imageUrlFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.learningModule.dialogImageCropper = false;
        this.learningModule.imageUrlToCrop = null;
        this.learningModule.imageUrlFileName = null;
      }
    },
    closeCardImageCropDialog(submitPressed) {
      //submitPressed is so we can use one function for both dialog buttons
      //If they clicked submit, submitPressed is true
      //If they clicked cancel, submitPressed is false
      if (submitPressed) {
        const { canvas } = this.$refs.cardCropper.getResult();
        this.learningCard.contentUrl = canvas.toDataURL();
        this.learningCard.contentUrlSigned = canvas.toDataURL();
        canvas.toBlob(blob => {
          // Do something with blob: upload to a server, download and etc.
          var file = new File([blob], this.learningCard.contentUrlFileName);
          this.learningCard.image = file;

          //Regardless, we close dialog
          this.dialogImageCropperCard = false;
          this.learningCard.contentUrlToCrop = null;
          this.learningCard.contentUrlFileName = null;
        });
      } else {
        //Regardless, we close dialog
        this.dialogImageCropperCard = false;
        this.learningCard.contentUrlToCrop = null;
        this.learningCard.contentUrlFileName = null;
      }
    },
    uploadImage(resource = "learningModule") {
      if (resource == "learningModule") {
        this.learningModule.selectingImageFlag = true;
        window.addEventListener(
          "focus",
          () => {
            this.learningModule.selectingImageFlag = false;
          },
          { once: true }
        );

        this.$refs.uploader.click();
      } else if (resource == "learningChapter") {
        this.learningChapter.selectingImageFlag = true;
        window.addEventListener(
          "focus",
          () => {
            this.learningChapter.selectingImageFlag = false;
          },
          { once: true }
        );

        this.$refs.learningChapterUploader.click();
      } else {
        this.learningCard.selectingImageFlag = true;
        window.addEventListener(
          "focus",
          () => {
            this.learningModule.selectingImageFlag = false;
          },
          { once: true }
        );

        this.$refs.learningCardUploader.click();
      }
    },
    imageFileChanged(e) {
      if (e.target.files.length > 0) {
        console.log("Changing file: ", e.target.files[0]);
        // this.learningModule.image = e.target.files[0];
        this.learningModule.imageUrlToCrop = URL.createObjectURL(
          e.target.files[0]
        );
        this.learningModule.imageUrlFileName = e.target.files[0].name;
        // this.learningModule.imageUrlSigned = URL.createObjectURL(
        //   e.target.files[0]
        // );
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#uploader").value = "";
        this.learningModule.dialogImageCropper = true;
      }
    },
    dragAndDropImageFileChanged(e) {
      if (e.dataTransfer.files.length > 0) {
        // this.learningModule.image = e.dataTransfer.files[0];
        this.learningModule.imageUrlToCrop = URL.createObjectURL(
          e.dataTransfer.files[0]
        );
        this.learningModule.imageUrlFileName = e.dataTransfer.files[0].name;
        // this.learningModule.imageUrlSigned = URL.createObjectURL(
        //   e.dataTransfer.files[0]
        // );
        this.learningModule.dialogImageCropper = true;
      }
    },
    imageFileChangedCard(e) {
      if (e.target.files.length > 0) {
        console.log(e.target.files);
        // this.learningCard.image = e.target.files[0];
        // this.learningCard.contentUrlToCrop = URL.createObjectURL(
        //   e.target.files[0]
        // );
        // this.learningCard.contentUrlFileName = e.target.files[0].name;
        // this.learningCard.contentUrlSigned = URL.createObjectURL(
        //   e.target.files[0]
        // );
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        // document.querySelector("#learningCardUploader").value = "";
        // this.dialogImageCropperCard = true;

        if (
          this.learningCard.type == "text" ||
          this.learningCard.type == "quiz" ||
          this.learningCard.type == "assessment" ||
          this.learningCard.type == "survey"
        ) {
          //
          this.learningCard.contentUrlToCrop = URL.createObjectURL(
            e.target.files[0]
          );
          this.learningCard.contentUrlFileName = e.target.files[0].name;

          this.dialogImageCropperCard = true;
        } else {
          this.learningCard.image = e.target.files[0];
          this.learningCard.contentUrl = URL.createObjectURL(e.target.files[0]);
          this.learningCard.contentUrlSigned = URL.createObjectURL(
            e.target.files[0]
          );
        }

        document.querySelector("#learningCardUploader").value = "";
      }
    },
    dragAndDropImageFileChangedCard(e) {
      if (e.dataTransfer.files.length > 0) {
        console.log(e.dataTransfer.files);

        if (
          this.learningCard.type == "text" ||
          this.learningCard.type == "quiz" ||
          this.learningCard.type == "assessment" ||
          this.learningCard.type == "survey"
        ) {
          //
          this.learningCard.contentUrlToCrop = URL.createObjectURL(
            e.dataTransfer.files[0]
          );
          this.learningCard.contentUrlFileName = e.dataTransfer.files[0].name;

          this.dialogImageCropperCard = true;
        } else {
          this.learningCard.image = e.dataTransfer.files[0];
          this.learningCard.contentUrl = URL.createObjectURL(
            e.dataTransfer.files[0]
          );
          this.learningCard.contentUrlSigned = URL.createObjectURL(
            e.dataTransfer.files[0]
          );
        }
      }
    },
    imageFileChangedChapter(e) {
      if (e.target.files.length > 0) {
        this.learningChapter.image = e.target.files[0];
        this.learningChapter.imageUrl = URL.createObjectURL(e.target.files[0]);
        this.learningChapter.imageUrlSigned = URL.createObjectURL(
          e.target.files[0]
        );
        //This wipes the files in the uploader component so it detects a change even if you select the same image twice
        document.querySelector("#learningChapterUploader").value = "";
      }
    },
    dragAndDropImageFileChangedChapter(e) {
      if (e.dataTransfer.files.length > 0) {
        this.learningChapter.image = e.dataTransfer.files[0];
        this.learningChapter.imageUrl = URL.createObjectURL(
          e.dataTransfer.files[0]
        );
        this.learningChapter.imageUrlSigned = URL.createObjectURL(
          e.dataTransfer.files[0]
        );
      }
    },
    removeModuleImage() {
      this.learningModule.image = null;
      this.learningModule.imageUrl = null;
      this.learningModule.imageUrlSigned = null;
      this.learningModule.bannerContentId = null;
    },
    removeCardImage() {
      this.learningCard.image = null;
      this.learningCard.contentUrl = null;
      this.learningCard.contentUrlSigned = null;
      this.learningCard.bannerContentId = null;
    },
    removeChapterImage() {
      this.learningChapter.image = null;
      this.learningChapter.imageUrl = null;
      this.learningChapter.imageUrlSigned = null;
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.slide2.groupTableSearch = undefined;
        this.slide2.groupTableDebounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - survey builder",
            groupId
          );
      });
    },
    // getGroups(preloadedGroupId, advanceToNextSlide = true) {
    //   console.log("Group preloading IDs ", preloadedGroupId);
    //   this.slide2.loadingGroups = true;
    //   GroupService.getGroups(
    //     false,
    //     preloadedGroupId ? undefined : this.clientId
    //   )
    //     .then(response => {
    //       console.log("Groups: ", response);
    //       var groupsArray = [];
    //       groupsArray = response.filter(
    //         group =>
    //           group.status !== "Deleted" && group.clientId == this.clientId
    //       );

    //       // var everyoneGroup = {
    //       //   clientId: 0,
    //       //   groupId: -1,
    //       //   groupName: "Everyone",
    //       //   groupDisplayName: "Everyone"
    //       // }

    //       // groupsArray.unshift(everyoneGroup);

    //       this.slide2.groups = groupsArray;
    //       this.slide2.loadingGroups = false;
    //       //We pass this in when creating a complex group from the widget
    //       //Because we have to emit the getGroups action so we don't know when it's done and safe to push that group to the selected array
    //       if (preloadedGroupId !== null && preloadedGroupId != "0") {
    //         if (
    //           Array.isArray(preloadedGroupId) &&
    //           preloadedGroupId.find(x => x == 0)
    //         )
    //           return;
    //         console.log("Preloading group");
    //         this.preloadGroup(preloadedGroupId, advanceToNextSlide);
    //       }
    //     })
    //     .catch(error => {
    //       console.log("Error!", error);
    //     });
    // },
    getLearningModules() {
      LearningService.getModulesAdmin(
        "Survey",
        this.clientId !== null ? this.clientId : this.userProfile.clientId
      )
        .then(response => {
          this.subscription = response.metadata.subscription;
          if (!this.subscription.createMore && this.clientId != 1) {
            this.subscriptionDialog = true;
          }
        })
        .catch(error => {
          console.log("Error!", error);
        });
    },
    getBadges(preloadBadge) {
      BadgeService.getBadges()
        .then(response => {
          console.log("Badges: ", response);
          //Prune the returned budgets for the data we need for the table
          var badgesPruned = [];
          var clientIdArray = [0, this.clientId];
          response.forEach(function(badge) {
            if (
              badge.status !== "Deleted" &&
              clientIdArray.includes(badge.clientId)
            ) {
              badgesPruned.push({
                badgeId: badge.badgeId,
                name: badge.name,
                displayName: badge.displayName || badge.name,
                description: badge.description,
                type: badge.type,
                status: badge.status,
                imageUrl: badge.imageUrl,
                signedCertificateUrl: badge.signedCertificateUrl
              });
            }
          });
          this.slide3.badges = badgesPruned;
          if (preloadBadge != null) {
            var badge = badgesPruned.find(x => x.badgeId == preloadBadge);
            if (badge != undefined) {
              this.slide3.noBadges = false;
              this.badgeSelector = true;
              this.slide3.selectedBadges = [badge];
            }
          }
          this.slide3.loadingBadgeTable = false;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    getAwards(preloadAward) {
      BudgetService.getAwards()
        .then(response => {
          console.log("Awards: ", response);
          //Prune the returned budgets for the data we need for the table
          var awards = response;
          awards
            .filter(x => x.status != "Deleted" && x.clientId == this.clientId)
            .map(x => {
              x.awardDisplayName = x.awardDisplayName || x.awardName;
              return x;
            });
          this.slide4.awards = awards;
          if (preloadAward != null) {
            var award = awards.find(x => x.awardId == preloadAward);
            if (award) {
              this.slide4.incentModule = true;
              this.slide4.awardId = preloadAward;
            } else {
              this.slide4.awardId = null;
            }
          }
          this.slide3.loadingBadgeTable = false;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    getBudgets(preloadBudget) {
      BudgetService.getBudgets()
        .then(response => {
          //Prune the returned budgets for the data we need for the table
          var budgetsPruned = [];

          budgetsPruned = response.filter(
            x =>
              x.status !== "Deleted" &&
              x.permissions &&
              (x.permissions.admin || x.permissions.owner)
          );
          budgetsPruned.forEach(budget => {
            // var clientIdArray = [0, this.clientId];
            var formattedStartDate = null;
            var formattedEndDate = null;
            if (budget.startDate) {
              formattedStartDate = momentTz(
                budget.startDate,
                "YYYY-MM-DD hh:mm:ss"
              ).format("MMM Do YYYY");
            }
            if (budget.endDate) {
              formattedEndDate = momentTz(
                budget.endDate,
                "YYYY-MM-DD hh:mm:ss"
              ).format("MMM Do YYYY");
            }

            budget.displayName = budget.budgetDisplayName
              ? budget.budgetDisplayName
              : budget.budgetName;
            budget.budgetDisplayName = budget.budgetDisplayName
              ? budget.budgetDisplayName
              : budget.budgetName;
            budget.startDate = formattedStartDate;
            budget.endDate = formattedEndDate;
            budget.formattedBudgetBalance = budget.budgetBalance;
            budget.displayNameWithBalance =
              (budget.budgetDisplayName || budget.budgetName) +
              (budget.awardId == 1 ? " - $" : " - ") +
              this.formatCurrency(
                truncateNumber(budget.formattedBudgetBalance, 2)
              );
          });
          this.slide4.budgets = budgetsPruned;
          console.log("Budgets: ", this.slide4.budgets);
          console.log("Preloaded budget: ", preloadBudget);
          if (preloadBudget != null) {
            var budget = budgetsPruned.find(x => x.budgetId == preloadBudget);
            if (budget) {
              this.slide4.budgetId = preloadBudget;
            } else {
              this.slide4.budgetId = null;
            }
          }
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    getAwardFulfillmentPartners() {
      console.log("Getting new award fulfillment partners");
      BudgetService.getAwardFulfillmentPartners()
        .then(response => {
          console.log("Award Fulfillment Partners: ", response);
          var partners = response.filter(
            x => x.status != "Deleted" && x.clientId == this.clientId
          );
          // We use $set because Vue wasn't updating this array that we passed as a prop to the Award Creator component
          this.$set(this.slide4, "awardFulfillmentPartners", partners);
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    closeBadgeCreatorWidget() {
      this.slide3.createBadgeWidget = false;
    },
    // preloadGroup(groupIds, advanceToNextSlide) {
    //   if (!Array.isArray(groupIds)) groupIds = [groupIds];

    //   console.log("Preloading groups ", groupIds);

    //   groupIds.forEach(groupId => {
    //     var preloadedGroup = this.slide2.groups.find(x => x.groupId == groupId);

    //     if (preloadedGroup !== undefined) {
    //       this.slide2.selectedGroups.push(preloadedGroup);
    //       if (advanceToNextSlide) {
    //         this.slideKey += 1;
    //       }
    //     }
    //   });

    //   if (this.slide2.selectedGroups.length > 0) this.groupSelector = true;
    //   this.slide2.createGroupWidget = false;
    // },
    updateClientId() {
      // if (this.preloadedModule == null) {
      this.slide2.selectedGroups = [];
      this.learningModule.clientId = this.clientId;
      this.getAwards(null);
      // this.getBudgets(null);
      this.getAwardFulfillmentPartners();
      this.getBadges(null);
      this.getLearningModules();
      // }
    },
    async copyToClip() {
      var content = this.computedUrl;
      await navigator.clipboard.writeText(content);
      // const el = document.createElement("textarea");
      // el.value = content;
      // el.setAttribute("readonly", "");
      // el.setAttribute("id", "urlCopyEl");
      // el.style.position = "fixed";
      // el.style.left = "0px";
      // el.style.top = "0px";
      // el.style.zIndex = "-400";
      // const dialog = document.getElementById("urlDialog");

      // dialog.appendChild(el);
      // // const selected =
      // //   document.getSelection().rangeCount > 0
      // //     ? document.getSelection().getRangeAt(0)
      // //     : false;
      // const input1 = document.getElementById("urlCopyEl");
      // input1.focus();
      // input1.select();
      // document.execCommand("copy");
      // dialog.removeChild(input1);
      // if (selected) {
      //   document.getSelection().removeAllRanges();
      //   document.getSelection().addRange(selected);
      // }
      this.snackbarText = "Copied URL to clipboard: " + content;
      this.snackbar = true;
    },
    fetchQuizData(cardsToFetch, loadGroupsPage) {
      var self = this;
      fetchQuizLoop(0);
      function fetchQuizLoop(index) {
        if (index < cardsToFetch.length) {
          LearningService.getLearningCard(
            cardsToFetch[index].learningCardId,
            true
          ).then(card => {
            console.log(card);
            var newCardFormat = card.LearningQuizQuestions.map(x => {
              var obj = x;
              obj.answers = x.LearningQuizAnswers;
              delete obj.LearningQuizAnswers;
              return obj;
            });

            console.log(newCardFormat);
            for (
              var i = 0;
              i < self.learningModule.learningChapters.length;
              i++
            ) {
              for (
                var j = 0;
                j <
                self.learningModule.learningChapters[i].learningCards.length;
                j++
              ) {
                if (
                  self.learningModule.learningChapters[i].learningCards[j]
                    .learningCardId == cardsToFetch[index].learningCardId
                ) {
                  console.log("Found questions");
                  self.learningModule.learningChapters[i].learningCards[
                    j
                  ].learningCardQuestions = newCardFormat;
                }
              }
            }
            var temp = index + 1;
            fetchQuizLoop(temp);
          });
        } else {
          console.log("done fetching quiz cards");
          self.loadingModule = false;
          if (loadGroupsPage) {
            self.slideKey = 2;
          } else {
            console.log("Calling edit card");
            self.editCard(
              self.learningModule.learningChapters[0].learningCards[0],
              0,
              0
            );
            console.log("go to slide 7");
          }
        }
      }
    },
    toggleRepeatable() {
      // we only want the survey card to be repeatable
      // check if new card (card doesnt have id)
      // if not new, update card, else, let builder handle it when saving module
      // if (this.learningModule[this.computedSurveyChapterIndex].learningCards[this.computedSurveyCardIndex].learningCardId !== null) {
      //   LearningService
      // }
      this.toggleRepeat = true;
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    computedUrl() {
      return (
        window.location.origin +
        "/survey/public/" +
        this.learningModule.learningModuleId
      );
    },
    formattedModuleDescription() {
      // Formats v-textarea with html tags when they enter into a new line
      if (!this.learningModule.description) return null;
      let arr = [];
      if (this.learningModule.description) {
        this.learningModule.description
          .split("\n")
          .forEach(item => arr.push(`<p>${item.trim()}</p>`));
      }
      return arr.join("");
    },
    computedActivityCardDate() {
      // if (
      //   this.learningModule.startDate === null ||
      //   this.learningModule.startTime.hours === null ||
      //   this.learningModule.startTime.minutes === null ||
      //   this.learningModule.startTime.amPm === null
      // ) {
      return null;
      // } else {
      //   return this.learningModule.startDate
      //     .toString()
      //     .concat(
      //       " ",
      //       this.learningModule.startTime.hours,
      //       ":",
      //       this.learningModule.startTime.minutes,
      //       " ",
      //       this.learningModule.startTime.amPm.toLowerCase()
      //     );
      // }
    },

    disableContinueButton() {
      if (
        this.slideKey === 1 &&
        this.learningModule.valid &&
        this.learningModule.description !== null &&
        this.learningModule.description.length > 0 &&
        this.learningModule.imageUrl !== null
        //  &&
        // (this.learningModule.startDate == null ||
        //   (this.learningModule.startDate !== null &&
        //     this.learningModule.startTime.hours !== null &&
        //     this.learningModule.startTime.minutes !== null &&
        //     this.learningModule.startTime.timezone !== null &&
        //     this.learningModule.startTime.amPm !== null)) &&
        // (this.learningModule.endDate == null ||
        //   (this.learningModule.endDate !== null &&
        //     this.learningModule.endTime.hours !== null &&
        //     this.learningModule.endTime.minutes !== null &&
        //     this.learningModule.endTime.timezone !== null &&
        //     this.learningModule.endTime.amPm !== null))
      ) {
        // if (this.learningModule.startDate) {
        //   var startHours = parseInt(this.learningModule.startTime.hours);
        //   if (this.learningModule.startTime.amPm == "PM") {
        //     if (startHours != 12) {
        //       startHours = parseInt(startHours) + 12;
        //     }
        //   } else {
        //     if (startHours == 12) {
        //       //Should be 0
        //       startHours = 0;
        //     }
        //   }

        //   if (startHours.toString().length == 1) {
        //     startHours = "0" + startHours.toString();
        //   }
        //   var formattedStartDate = new momentTz(
        //     this.learningModule.startDate.concat(
        //       " ",
        //       startHours,
        //       ":",
        //       this.learningModule.startTime.minutes
        //     )
        //   )
        //     .local()
        //     .format("YYYY-MM-DD HH:mm");
        // }

        // if (this.learningModule.endDate) {
        //   var endHours = parseInt(this.learningModule.endTime.hours);
        //   if (this.learningModule.endTime.amPm == "PM") {
        //     if (endHours != 12) {
        //       endHours = parseInt(endHours) + 12;
        //     }
        //   } else {
        //     if (endHours == 12) {
        //       //Should be 0
        //       endHours = 0;
        //     }
        //   }

        //   if (endHours.toString().length == 1) {
        //     endHours = "0" + endHours.toString();
        //   }
        //   var formattedEndDate = new momentTz(
        //     this.learningModule.endDate.concat(
        //       " ",
        //       endHours,
        //       ":",
        //       this.learningModule.endTime.minutes
        //     )
        //   )
        //     .local()
        //     .format("YYYY-MM-DD HH:mm");
        // }

        // if (
        // this.learningModule.startDate &&
        // this.learningModule.endDate &&
        // formattedEndDate < formattedStartDate ||
        //   this.learningModule.imageUrl == null
        // ) {
        //   return true;
        // } else {
        return false;
        // }
      } else if (
        (this.slideKey === 2 &&
          this.slide2.visibility === "PRIVATE" &&
          this.slide2.selectedGroups.length > 0) ||
        (this.slideKey === 2 && this.slide2.visibility === "PUBLIC") ||
        (this.slideKey === 2 && this.slide2.visibility === "EMAIL_LIST")
      ) {
        return false;
      } else if (
        this.slideKey === 3 &&
        (this.slide3.selectedBadges.length > 0 || this.slide3.noBadges == true)
      ) {
        return false;
      }
      //  else if (
      //   this.slideKey === 4 &&
      //   (!this.slide4.incentModule ||
      //     this.slide4.preloadedProgram ||
      //     !this.permissions.includes("programs:create:program"))
      // ) {
      //   return false;
      // }
      // else if (
      //   this.slideKey === 4 &&
      //   this.slide4.awardId &&
      //   this.slide4.budgetId &&
      //   this.slide4.awardValue &&
      //   (!this.slide4.awardValue.toString().includes(".") ||
      //     (this.slide4.awardValue.toString().includes(".") &&
      //       this.slide4.awardValue
      //         .toString()
      //         .substring(this.slide4.awardValue.toString().indexOf(".") + 1)
      //         .length < 3)) &&
      //   parseFloat(this.slide4.awardValue) > 0 &&
      //   this.incentiveBudget &&
      //   this.slide4.startDate &&
      //   this.slide4.startTime.hours &&
      //   this.slide4.startTime.minutes &&
      //   this.slide4.startTime.timezone &&
      //   this.slide4.startTime.amPm &&
      //   ((this.slide4.dateOptionSelector == "rolling" &&
      //     this.slide4.rollingProgramLength &&
      //     !isNaN(this.slide4.rollingProgramLength) &&
      //     !this.slide4.rollingProgramLength.toString().includes(".") &&
      //     parseInt(this.slide4.rollingProgramLength) > 0) ||
      //     (this.slide4.dateOptionSelector == "date" &&
      //       this.slide4.endDate !== null &&
      //       this.slide4.endTime.hours !== null &&
      //       this.slide4.endTime.minutes !== null &&
      //       this.slide4.endTime.timezone !== null &&
      //       this.slide4.endTime.amPm !== null))
      // ) {
      //   var startHours = parseInt(this.slide4.startTime.hours);
      //   if (this.slide4.startTime.amPm == "PM") {
      //     if (startHours != 12) {
      //       startHours = parseInt(startHours) + 12;
      //     }
      //   } else {
      //     if (startHours == 12) {
      //       //Should be 0
      //       startHours = 0;
      //     }
      //   }

      //   if (startHours.toString().length == 1) {
      //     startHours = "0" + startHours.toString();
      //   }
      //   var formattedStartDate = new momentTz(
      //     this.slide4.startDate.concat(
      //       " ",
      //       startHours,
      //       ":",
      //       this.slide4.startTime.minutes
      //     )
      //   )
      //     .local()
      //     .format("YYYY-MM-DD HH:mm");

      //   if (this.slide4.dateOptionSelector == "date") {
      //     var endHours = parseInt(this.slide4.endTime.hours);
      //     if (this.slide4.endTime.amPm == "PM") {
      //       if (endHours != 12) {
      //         endHours = parseInt(endHours) + 12;
      //       }
      //     } else {
      //       if (endHours == 12) {
      //         //Should be 0
      //         endHours = 0;
      //       }
      //     }

      //     if (endHours.toString().length == 1) {
      //       endHours = "0" + endHours.toString();
      //     }
      //     var formattedEndDate = new momentTz(
      //       this.slide4.endDate.concat(
      //         " ",
      //         endHours,
      //         ":",
      //         this.slide4.endTime.minutes
      //       )
      //     )
      //       .local()
      //       .format("YYYY-MM-DD HH:mm");
      //   }
      //   var today = new momentTz().local().format("YYYY-MM-DD HH:mm");
      //   if (
      //     (this.slide4.dateOptionSelector == "date" &&
      //       formattedEndDate < formattedStartDate) ||
      //     today > formattedStartDate
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      else if (this.slideKey === 5) {
        return false;
      } else if (
        this.slideKey === 6 &&
        this.learningChapter.valid &&
        (this.learningChapter.description == null ||
          (this.learningChapter.description !== null &&
            this.learningChapter.description.length < 256))
      ) {
        return false;
      } else if (this.slideKey === 7 && this.learningCard.valid) {
        return false;
      } else {
        return true;
      }
    },
    checkIfQuizIsValid() {
      var errorBool = false;
      // This is the disableContinueButton validation for quizzes
      // We cycle through each question and check its answers
      for (var i = 0; i < this.learningCardQuestions.length; i++) {
        var question = this.learningCardQuestions[i];

        // We check if each question has a title (content field)
        if (!question.content || question.content.length > 750) {
          errorBool = true;
        }

        if (
          question.type == "MULTIPLE_CHOICE" ||
          question.type == "SELECT_ALL"
        ) {
          // Then if we're looking at multiple choice we cycle through answers and check that each answer has a label
          // var validQuizAnswer = false;

          for (var j = 0; j < question.answers.length; j++) {
            //console.log("answer length", question.answers[j].content.length);
            // if (
            //   question.answers[j].content != null &&
            //   question.answers[j].content.length > 0 &&
            //   question.answers[j].content.length < 256
            // ) {
            //   // console.log("Problem with multiple choice answer");
            //   console.log("valid answer");
            //   validQuizAnswer = true;
            // }
            // A single valid answer will enable the continue button, so we want to look for invalid answers
            if (
              question.answers[j].content == null ||
              question.answers[j].content.length < 0 ||
              question.answers[j].content.length > 255
            ) {
              console.log("Flipping error boolean");
              errorBool = true;
            }
          }
          // If we didn't find a valid quiz answer (not empty), then we report it as invalid
          // if (!validQuizAnswer) {
          //   errorBool = true;
          // }
        } else if (question.type == "SCALE") {
          if (
            question.lowerBound == null ||
            question.upperBound == null ||
            question.lowerBound.toString().includes(".") ||
            question.upperBound.toString().includes(".") ||
            parseInt(question.upperBound) <= parseInt(question.lowerBound) ||
            parseInt(question.upperBound) - parseInt(question.lowerBound) > 1000
          ) {
            console.log("Problem with scale question");
            errorBool = true;
          }
        }
      }
      return errorBool;
    },
    calculatedTimezones() {
      return momentTz.tz.zonesForCountry("US").map(x => {
        return {
          value: x
        };
      });
    },
    // startDateRules() {
    //   var formattedStartDate = new momentTz(this.learningModule.startDate)
    //     .local()
    //     .format("YYYY-MM-DD");
    //   var formattedEndDate = new momentTz(this.learningModule.endDate)
    //     .local()
    //     .format("YYYY-MM-DD");

    //   return [
    //     () =>
    //       !!(formattedStartDate <= formattedEndDate) ||
    //       "Start date must be earlier than end date"
    //   ];
    // },
    // endDateRules() {
    //   var today = new momentTz().local().format("YYYY-MM-DD");
    //   var formattedStartDate = new momentTz(this.learningModule.startDate)
    //     .local()
    //     .format("YYYY-MM-DD");
    //   var formattedEndDate = new momentTz(this.learningModule.endDate)
    //     .local()
    //     .format("YYYY-MM-DD");
    //   return [
    //     () =>
    //       !!(formattedStartDate <= formattedEndDate) ||
    //       "End date must be later than the start date",
    //     () =>
    //       !!(today <= formattedEndDate) || "End date should not be in the past"
    //   ];
    //   // return [false];
    // },
    // dateValidation() {
    //   if (
    //     this.learningModule.startDate !== null &&
    //     this.learningModule.startTime.hours !== null &&
    //     this.learningModule.startTime.minutes !== null &&
    //     this.learningModule.startTime.timezone !== null &&
    //     this.learningModule.startTime.amPm !== null &&
    //     this.learningModule.endDate !== null &&
    //     this.learningModule.endTime.hours !== null &&
    //     this.learningModule.endTime.minutes !== null &&
    //     this.learningModule.endTime.timezone !== null &&
    //     this.learningModule.endTime.amPm !== null
    //   ) {
    //     var startHours = parseInt(this.learningModule.startTime.hours);
    //     if (this.learningModule.startTime.amPm == "PM") {
    //       if (startHours != 12) {
    //         startHours = parseInt(startHours) + 12;
    //       }
    //     } else {
    //       if (startHours == 12) {
    //         //Should be 0
    //         startHours = 0;
    //       }
    //     }

    //     if (startHours.toString().length == 1) {
    //       startHours = "0" + startHours.toString();
    //     }
    //     var formattedStartDate = new momentTz(
    //       this.learningModule.startDate.concat(
    //         " ",
    //         startHours,
    //         ":",
    //         this.learningModule.startTime.minutes
    //       )
    //     )
    //       .local()
    //       .format("YYYY-MM-DD HH:mm");

    //     var endHours = parseInt(this.learningModule.endTime.hours);
    //     if (this.learningModule.endTime.amPm == "PM") {
    //       if (endHours != 12) {
    //         endHours = parseInt(endHours) + 12;
    //       }
    //     } else {
    //       if (endHours == 12) {
    //         //Should be 0
    //         endHours = 0;
    //       }
    //     }

    //     if (endHours.toString().length == 1) {
    //       endHours = "0" + endHours.toString();
    //     }
    //     var formattedEndDate = new momentTz(
    //       this.learningModule.endDate.concat(
    //         " ",
    //         endHours,
    //         ":",
    //         this.learningModule.endTime.minutes
    //       )
    //     )
    //       .local()
    //       .format("YYYY-MM-DD HH:mm");
    //     if (formattedStartDate <= formattedEndDate) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // },
    dateValidationIncentive() {
      if (
        this.slide4.startDate !== null &&
        this.slide4.startTime.hours !== null &&
        this.slide4.startTime.minutes !== null &&
        this.slide4.startTime.timezone !== null &&
        this.slide4.startTime.amPm !== null &&
        this.slide4.endDate !== null &&
        this.slide4.endTime.hours !== null &&
        this.slide4.endTime.minutes !== null &&
        this.slide4.endTime.timezone !== null &&
        this.slide4.endTime.amPm !== null
      ) {
        var startHours = parseInt(this.slide4.startTime.hours);
        if (this.slide4.startTime.amPm == "PM") {
          if (startHours != 12) {
            startHours = parseInt(startHours) + 12;
          }
        } else {
          if (startHours == 12) {
            //Should be 0
            startHours = 0;
          }
        }

        if (startHours.toString().length == 1) {
          startHours = "0" + startHours.toString();
        }
        var formattedStartDate = new momentTz(
          this.slide4.startDate.concat(
            " ",
            startHours,
            ":",
            this.slide4.startTime.minutes
          )
        )
          .local()
          .format("YYYY-MM-DD HH:mm");

        var endHours = parseInt(this.slide4.endTime.hours);
        if (this.slide4.endTime.amPm == "PM") {
          if (endHours != 12) {
            endHours = parseInt(endHours) + 12;
          }
        } else {
          if (endHours == 12) {
            //Should be 0
            endHours = 0;
          }
        }

        if (endHours.toString().length == 1) {
          endHours = "0" + endHours.toString();
        }
        var formattedEndDate = new momentTz(
          this.slide4.endDate.concat(
            " ",
            endHours,
            ":",
            this.slide4.endTime.minutes
          )
        )
          .local()
          .format("YYYY-MM-DD HH:mm");
        if (formattedStartDate <= formattedEndDate) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    dateValidationComparedToToday() {
      var today = new momentTz().local().format("YYYY-MM-DD HH:mm");
      if (
        this.slide4.startDate &&
        this.slide4.startTime.hours &&
        this.slide4.startTime.minutes &&
        this.slide4.startTime.timezone &&
        this.slide4.startTime.amPm
      ) {
        var startHours = parseInt(this.slide4.startTime.hours);
        if (this.slide4.startTime.amPm == "PM") {
          if (startHours != 12) {
            startHours = parseInt(startHours) + 12;
          }
        } else {
          if (startHours == 12) {
            //Should be 0
            startHours = 0;
          }
        }

        if (startHours.toString().length == 1) {
          startHours = "0" + startHours.toString();
        }
        var formattedStartDate = new momentTz(
          this.slide4.startDate.concat(
            " ",
            startHours,
            ":",
            this.slide4.startTime.minutes
          )
        )
          .local()
          .format("YYYY-MM-DD HH:mm");
        if (today <= formattedStartDate) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    calculatedImageWidth() {
      // This is used for the image width because some images want to expand outside of the container, so I get that width and subtract the width of the edit icon
      // if (!this.mounted) {
      //   console.log("not mounted");
      //   return;
      // }
      if (document.getElementsByClassName("image-container").length > 0) {
        return (
          document.getElementsByClassName("image-container")[0].offsetWidth - 60
        );
      } else {
        return 600;
      }
    },
    computedChapters() {
      return this.learningModule.learningChapters.slice(
        0,
        this.learningModule.learningChapters.length - 1
      );
    },
    playerOptions() {
      return {
        height: 400,

        autoplay: false,
        controls: true,
        techOrder: ["html5"],
        sourceOrder: true,
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            withCredentials: false,
            type: "video/mp4",
            src: this.learningCard
              ? this.learningCard.contentUrlSigned
                ? this.learningCard.contentUrlSigned
                : null
              : null
          }
        ]
      };
    },
    filteredBudgets() {
      if (!this.slide4.awardId) {
        return [];
      } else {
        return this.slide4.budgets.filter(
          x =>
            x.awardId == this.slide4.awardId &&
            (x.clientId == this.clientId || x.clientId == 0)
        );
      }
    },
    incentiveAward() {
      if (this.slide4.awardId) {
        var award = this.slide4.awards.find(
          x => x.awardId == this.slide4.awardId
        );
        if (award) {
          return award;
        }
      }
      return null;
    },
    incentiveBudget() {
      if (this.slide4.budgetId) {
        var budget = this.slide4.budgets.find(
          x => x.budgetId == this.slide4.budgetId
        );
        if (budget) {
          return budget;
        }
      }
      return null;
    },
    incentiveAwardValueLabel() {
      //Assumes cash is awardId 1 for all clients
      if (this.slide4.awardId === 1) {
        if (
          this.slide4.awardValue == null ||
          this.slide4.awardValue.length == 0
        ) {
          return "Amount*";
        } else if (
          isNaN(this.slide4.awardValue) ||
          parseFloat(this.slide4.awardValue) <= 0
        ) {
          var dollarAmount = "0";
        } else {
          dollarAmount = this.formatCurrency(
            this.truncateNum(parseFloat(this.slide4.awardValue), 2)
          );
        }
        return "Amount $" + dollarAmount;
      } else {
        return "Amount*";
      }
    },
    // To hide incentive and badge stuff for public surveys
    isSurveyPrivate() {
      if (this.slide2.visibility) {
        // Don't want to incentivize a public survey
        if (
          this.slide2.visibility === "PUBLIC" ||
          this.slide2.visibility === "EMAIL_LIST"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // If the survey is active and private already we can't change visibility
    disableChangeVisibility() {
      if (
        this.learningModule.visibilityType === "PRIVATE" &&
        this.learningModule.moduleStatus === "Active"
      ) {
        return true;
      } else {
        return false;
      }
    },
    computedSurveyChapterIndex() {
      // this.learningModule.learningChapters[name: Survey].learningCards[name: Survey Card]
      let index = this.learningModule.learningChapters.findIndex(chapter => {
        return chapter.name === "Survey";
      });
      return index;
    },
    computedSurveyCardIndex() {
      let chIndex = this.learningModule.learningChapters.findIndex(chapter => {
        return chapter.name === "Survey";
      });
      let cardIndex = this.learningModule.learningChapters[
        chIndex
      ].learningCards.findIndex(card => {
        return card.name === "Survey Card";
      });
      return cardIndex;
    }
  },
  watch: {
    "slide2.groupTableDebounceSearch": debounce(function(newVal) {
      this.slide2.groupTableSearch = newVal;
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chapter {
  width: 100%;
  border: 2px dashed #888;
  border-radius: 8px;
  padding: 12px;
  background: #fefefe;
  overflow: auto;
}
.chapter.capstone {
  border: 2px solid #888;
}
.chapter.empty {
  background: #ddd;
}
.card {
  border: 1px solid #bbb;
  background: #fff;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  margin-bottom: 4px;
  padding: 4px 4px;
}
.card-icon {
  height: 40px;
  width: 40px;
  border: 1px solid #97dffc;
  background: #f5fcff;
  border-radius: 6px;
  float: left;
}
.quiz-icon {
  color: #fff;
  font-size: 1.5em;
  line-height: 1.7em;
  font-weight: bold;
}
.card-title {
  font-weight: bold;
  font-size: 0.8em;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 6px;
}

.question-button {
  border-radius: 30px;
  height: 50px;
  padding: 10px;
}
.question-counter {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  width: 28px;
  height: 28px;
}
.question-counter-bubble {
  width: 28px;
  height: 28px;
}

.number-field {
  max-width: 150px;
}
.number-field.pool-count {
  max-width: 300px;
}
.passing-required {
  max-width: 370px;
  height: 83px;
}
.purple-background {
  background: #858ae3;
}
.purple-outline {
  border: 1px solid #858ae3;
}
.answer-counter {
  color: #858ae3;
  font-size: 1em;
  font-weight: bold;
  width: 22px;
  height: 22px;
  width: 100%;
}

.add-button {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #bbbbbb;
}
.quillWrapper.editor {
  max-height: 100%;
}
.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.error-background {
  background-color: var(--v-error-base);
}
.brand-background {
  background-color: var(--v-brand-base);
}
.purple-background {
  background-color: var(--v-brandPurple-base);
}
.primary-background {
  background-color: var(--v-primary-base);
}
.pink-background {
  background-color: var(--v-brandPink-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.primary-text {
  color: var(--v-primary-base);
}
.border-radius {
  border-radius: 5px;
}
.header-text {
  font-size: x-large;
}
.large-text {
  font-size: large;
}
.small-text {
  font-size: small;
}
.editor {
  max-height: 35vh;
}
.hour-picker {
  width: 30%;
}
.minutes-picker {
  width: 30%;
}
.am-pm-picker {
  width: 30%;
}
.confirmation-page-card {
  width: 90%;
}
.card-letters {
  font-size: 25px;
  min-width: 50px;
}
.budget-confirmation-number {
  width: 30%;
}
.budget-confirmation-text {
  width: 70%;
}
.confirmation-page-card {
  width: 90%;
}
.exit-warning-icon {
  transform: scale(1.25);
  transform-origin: left;
}
.exit-warning-text {
  line-height: 1.3em;
}

.award-selector {
  width: 350px;
  max-width: 350px;
}

/* hides the space for the v-messages below the repeatable v-switch */
.repeatable-switch-div >>> .v-messages {
  display: none;
}
/* removes bottom margin on v-input v-switch so that tooltip is centered */
.repeatable-switch-div >>> .v-input__slot {
  margin-bottom: 0px;
}
</style>

<template>
  <div>
    <v-dialog v-model="display" :width="width">
      <v-card>
        <v-card-title class="dialog-header" color="primary">
          Balances
        </v-card-title>
        <v-divider />
        <v-container>
          <v-card-text>
            <div class="d-flex justify-space-between my-1">
              <span>Ledger Default</span>
              <div class="ellipsis-fill"></div>
              <v-progress-circular
                indeterminate
                v-if="loadingLedger"
                color="brandCyan"
                size="20"
                width="2"
              ></v-progress-circular>
              <span v-else class="font-weight-bold">{{ ledgerDefault }}</span>
            </div>
            <div class="d-flex justify-space-between my-1">
              <span>Ledger Provisional</span>
              <div class="ellipsis-fill"></div>
              <v-progress-circular
                indeterminate
                v-if="loadingLedger"
                color="brandCyan"
                size="20"
                width="2"
              ></v-progress-circular>
              <span v-else class="font-weight-bold">{{
                ledgerProvisional
              }}</span>
            </div>
            <div class="d-flex justify-space-between my-1">
              <span>Marqeta</span>
              <div class="ellipsis-fill"></div>
              <v-progress-circular
                indeterminate
                v-if="loadingMarqeta"
                color="brandCyan"
                size="20"
                width="2"
              ></v-progress-circular>
              <span v-else class="font-weight-bold">{{
                marqetaDNE ? "DNE" : marqeta
              }}</span>
            </div>
            <p class="text-left mt-4">
              Note: The ledger balances should add up to the Marqeta balance
            </p>
            <p v-if="error" class="text-left mt-4 error--text">
              {{ error }}
            </p>
            <p v-if="balanceMismatch" class="text-right mt-4 error--text">
              <v-icon color="error">mdi-alert</v-icon>Balance is off by
              {{ balanceMismatch }}
            </p>
            <v-card-title class="ml-0 pl-0 pb-1">
              Cards ({{ cards.length }})
              <v-progress-circular
                indeterminate
                v-if="loadingCards"
                color="brandCyan"
                size="20"
                width="2"
                class="ml-2"
              ></v-progress-circular>
            </v-card-title>
            <div
              v-for="card in cards"
              :key="card.token"
              class="d-flex justify-space-between my-1"
            >
              <span
                >{{ card.type }} - {{ card.state }} -
                {{ card.pin_is_set ? "Has PIN" : "No PIN" }}</span
              >
              <v-btn
                small
                icon
                title="Freeze / Lock card"
                v-if="card.can_lock"
                @click="loadLockedCardDialog(card.token)"
                ><v-icon>mdi-lock</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Close
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCardLock" width="400">
      <v-card class="pa-8">
        <v-form v-model="cardLockForm" @submit.prevent="lockCard">
          <v-text-field
            outlined
            color="brandCyan"
            label="Reason for locking"
            v-model="cardLockReason"
            :rules="formRules.stringRequired(255)"
          />
          <p v-if="cardLockError" class="text-left mt-4 error--text">
            {{ cardLockError }}
          </p>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="brandCyan" text @click="resetLockedCardDialog">
              Cancel
            </v-btn>
            <v-btn
              color="brandCyan"
              class="white--text"
              depressed
              :loading="cardLockLoading"
              :disabled="!cardLockForm"
              type="submit"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WalletService from "@/services/WalletService.js";
import MarqetaService from "@/services/MarqetaService.js";

import { formRules } from "@/shared_data/data.js";

import currency from "currency.js";
import { mapState } from "vuex";

function initState() {
  return {
    formRules,
    display: true,

    // Loading
    loadingLedger: false,
    loadingMarqeta: false,
    loadingCards: false,

    // Balances / Data
    ledgerDefault: "$0",
    ledgerProvisional: "$0",
    marqeta: "$0",
    marqetaDNE: false,
    balanceMismatch: null,
    error: null,
    cards: [],

    // locking state
    showCardLock: false,
    cardLockToken: null,
    cardLockLoading: false,
    cardLockError: null,
    cardLockReason: null,
    cardLockForm: false
  };
}
export default {
  name: "CSMWalletManager",
  components: {},
  props: {
    value: Boolean,
    width: {
      type: Number,
      default: 500
    },
    clientId: {
      type: Number
    },
    userId: {
      type: String
    }
    // url: {
    //   type: String
    // }
  },
  data() {
    return initState();
  },
  created() {
    this.getBalances();
  },
  mounted() {
    // this.display = true;
  },
  methods: {
    close() {
      // if (!save)
      return this.reset();
    },
    reset() {
      this.display = false;
      this.$emit("close");
    },
    async getBalances() {
      Object.assign(this.$data, initState());
      const userId = this.userId;
      this.display = true;
      this.loadingLedger = true;
      this.loadingMarqeta = true;
      this.loadingCards = true;

      let ledgerDefault;
      let ledgerProvisional;
      let mqBalance;
      try {
        const ledgerRes = await WalletService.checkUserBalanceV2(userId, null, {
          awardId: 1
        });
        console.log({ ledgerRes });
        ledgerDefault = currency(
          ledgerRes.find(x => x.awardId == 1 && x.type == "DEFAULT")?.balance ||
            0
        );
        this.ledgerDefault = ledgerDefault.format();
        ledgerProvisional = currency(
          ledgerRes.find(x => x.awardId == 1 && x.type == "PROVISIONAL")
            ?.balance || 0
        );
        this.ledgerProvisional = ledgerProvisional.format();
        this.loadingLedger = false;

        const marqetaRes = await MarqetaService.getBalance(
          null,
          {
            userId
          },
          true
        ).catch(e => {
          console.log(e);
          if (e?.error_code === 1190004) return { dne: true };
          else this.error = "Error getting Marqeta user. Please try again";
        });
        console.log({ marqetaRes });
        if (marqetaRes?.dne) this.marqetaDNE = true;
        else if (marqetaRes !== undefined) {
          mqBalance = currency(marqetaRes?.available_balance || 0);
          this.marqeta = mqBalance.format();
          const diff = mqBalance
            .subtract(ledgerProvisional)
            .subtract(ledgerDefault);
          if (diff.value != 0) this.balanceMismatch = diff.format();
        }
        this.loadingMarqeta = false;

        if (!this.marqetaDNE) {
          await this.getCards();
          this.loadingCards = false;
        }
      } catch (e) {
        console.log("Error getting balances", e);
        if (!this.error) this.error = "Error getting balances";
      } finally {
        this.loadingLedger = false;
        this.loadingMarqeta = false;
        this.loadingCards = false;
      }
    },
    async getCards() {
      try {
        this.loadingCards = true;
        const cardRes = await MarqetaService.getCards(
          null,
          this.userId,
          this.clientId,
          true
        );
        console.log({ cardRes });
        this.cards = cardRes?.state?.cards || [];
      } catch (e) {
        console.log("Error getting balances", e);
        throw e;
      } finally {
        this.loadingCards = false;
      }
    },
    loadLockedCardDialog(token) {
      this.showCardLock = true;
      this.cardLockToken = token;
    },
    resetLockedCardDialog() {
      this.showCardLock = false;
      this.cardLockToken = null;
      this.cardLockError = null;
      this.cardLockLoading = false;
      this.cardLockReason = null;
    },
    async lockCard() {
      try {
        this.cardLockLoading = true;
        const token = this.cardLockToken;
        await MarqetaService.lockCard(token, {
          reason: this.cardLockReason
        });

        await this.getCards(true);
        this.showCardLock = false;
      } catch (e) {
        console.log("Error in lockCard", e);
        this.cardLockError = "Error locking card";
      } finally {
        this.cardLockLoading = false;
      }
    }
  },

  computed: {
    ...mapState(["clients", "userProfile"])
  },
  watch: {
    showCardLock: function(newVal) {
      if (!newVal) this.resetLockedCardDialog();
    },
    display: function(newVal) {
      if (!newVal) this.$emit("close");
    }
  }
};
</script>

<style></style>

import axios from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_TOKEN_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-whistle-token"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE
        ? "/microservice-whistle-token"
        : "whistle-token-service"
    }`;

class TokenService {
  createToken(token) {
    return axios
      .get(baseUrl + "/tokens", token)
      .then(response => {
        return response.data.token;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateToken(token) {
    return axios
      .patch(baseUrl + "/tokens", token, {
        headers: {
          authorization: "Bearer " + token
        }
      })
      .then(response => {
        return response.data.token;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getToken(token) {
    return axios
      .get(baseUrl + "/tokens/single", {
        headers: {
          authorization: "Bearer " + token
        }
      })
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error! " + error);
        return error;
      });
  }
}

export default new TokenService();

<template>
  <v-card flat>
    <!-- <v-toolbar dark color="brand" rounded="0">
      <v-btn dark icon @click="closeWindow">
        <v-icon large>mdi-close-circle</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>New Group</v-toolbar-title>
      <v-spacer></v-spacer> -->
    <!-- <v-toolbar-items>
       
        <v-btn
          dark
          text
          @click="nextButtonClicked"
          v-if="!displaySimpleGroupResults"
          :disabled="selectedUserColumn === null"
        >
          Submit
        </v-btn>
        <v-btn
          dark
          text
          @click="createSimpleGroup"
          v-else
          :disabled="attributesForNewGroups.length == 0"
        >
          Select
        </v-btn>
      </v-toolbar-items> -->
    <!-- </v-toolbar> -->
    <v-divider />
    <v-card-text>
      <!-- <div class="d-flex flex-column full-width"> -->
      <h2 class="text-left mb-4 mx-4 mt-2">
        Create a simple group based on an attribute
      </h2>
      <p class="text-left mx-4 mt-3">
        Select a category from the drop down menu below and a list of attributes
        that can be used to define a group will be displayed
      </p>
      <p class="text-left mx-4 mt-3" v-if="source != 'groups'">
        If you need to create a different type of group, go to the
        <a @click="loadGroupsPage">Groups page</a>
      </p>
      <v-row justify="start" class="ml-4 mt-3" v-if="hasSubClients">
        <v-switch
          v-model="includeSubClients"
          :label="`Include Sub-Clients`"
          color="brandCyan"
          class="mb-2"
          @change="nextButtonClicked"
          hide-details
          :disabled="showRobinAnimation"
        ></v-switch>
        <p>
          Note: Groups that include sub-clients don't support external users
        </p></v-row
      >
      <v-row justify="center">
        <!-- <v-col class="d-flex flex-column mt-3" cols="12" sm="9" md="9" lg="9"> -->

        <v-select
          flat
          outlined
          v-model="selectedUserColumn"
          :items="userColumns"
          item-text="displayName"
          item-value="columnName"
          label="Category"
          solo
          class="mt-2 mx-6"
          @change="nextButtonClicked"
          color="brandCyan"
          dense
          :disabled="showRobinAnimation"
        ></v-select>
      </v-row>
    </v-card-text>

    <v-card-text v-if="displaySimpleGroupResults">
      <v-container fluid>
        <v-row class="d-flex flex-column align-center">
          <Robin
            v-if="showRobinAnimation"
            :height="110"
            :width="140"
            :loop="true"
            animation="eating"
          />
          <v-data-table
            v-if="showAttributeTable"
            v-model="attributesForNewGroups"
            :headers="groupColumns"
            :items="columnValues"
            item-key="value"
            show-select
            :single-select="true"
            :items-per-page="10"
            class="elevation-0 full-width table"
            loading-text="Loading groups... Please wait"
            color="brandCyan"
            @click:row="rowClick"
          >
            <template v-slot:[`item.value`]="{ item }">
              <span>{{ item.valuePretty || item.value }}</span>
              <!-- <span v-else
                >No
                {{
                  userColumns
                    .find(x => x.columnName == selectedUserColumn)
                    .displayName.toLowerCase()
                }}
                defined</span
              > -->
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="pb-8 d-flex justify-center">
      <v-btn
        color="brandCyan"
        class="white--text"
        :outlined="attributesForNewGroups.length == 0"
        depressed
        rounded
        width="190"
        @click="createSimpleGroup"
        :disabled="attributesForNewGroups.length == 0"
      >
        Add new group
      </v-btn>
    </v-card-actions>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import GroupService from "../../services/GroupService";
import UserService from "../../services/UserService";

import LoadingDialog from "@/components/loadingDialog.vue";
import Robin from "@/components/Robin";

import { mapState } from "vuex";

export default {
  name: "GroupCreatorWidget",
  components: {
    LoadingDialog,
    Robin
  },
  props: { clientId: Number, source: String, version: Number },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      userColumns: [
        { columnName: "status", displayName: "Status" },
        { columnName: "jobTitle", displayName: "Job Title" },
        { columnName: "businessCity", displayName: "Business City" },
        { columnName: "businessState", displayName: "Business State" },
        { columnName: "organization", displayName: "Organization" },
        { columnName: "clientId", displayName: "Client Name" },
        { columnName: "language", displayName: "Language" }
      ],
      groupColumns: [
        { text: "Attributes in this category", value: "value", width: "80%" },
        {
          text: "Participants",
          value: "participantCount",
          width: "20%",
          sortable: false,
          align: "center"
        }
      ],
      hasSubClients: false,
      includeSubClients: false,
      selectedUserColumn: null,
      displaySimpleGroupResults: false,
      showRobinAnimation: false,
      showAttributeTable: false,
      columnValues: [],
      metrics: [],
      modules: [],
      attributesForNewGroups: []
    };
  },
  created() {
    if (!this.magicLinkToken)
      UserService.getClientsByClient(this.clientId)
        .then(response => {
          if (response.length > 0) {
            this.hasSubClients = true;
          }
        })
        .catch(error => {
          console.log("Error getting subclients", error.response || error);
        });
  },
  mounted() {},
  methods: {
    rowClick: function(item, row) {
      row.select(!row.isSelected);
      // this.selectedId = item.name;
    },
    getSimpleGroupColumnValues() {
      this.displaySimpleGroupResults = true;
      this.showRobinAnimation = true;
      this.showAttributeTable = false;
      this.attributesForNewGroups = [];

      GroupService.getSimpleGroupValues(
        this.selectedUserColumn,
        this.clientId,
        this.includeSubClients.toString(),
        this.magicLinkToken
      )
        .then(response => {
          console.log(response);

          this.columnValues = response;
          this.showRobinAnimation = false;
          this.showAttributeTable = true;
        })
        .catch(error => {
          console.log("Error!" + error.response);
          this.showLoadingDialog = false;
        });
    },
    async createSimpleGroup() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating your group";
      this.loadingMessageLine1 = "Doing the complex part so you don't have to.";
      this.loadingMessageLine2 = "This should just take a minute.";
      console.log(
        "Creating " + this.attributesForNewGroups.length + " simple groups"
      );
      console.log(this.attributesForNewGroups);
      var groups = this.attributesForNewGroups.map(x => {
        // let name =
        //   "Simple Group: " +
        //   this.userColumns
        //     .find(x => x.columnName == this.selectedUserColumn)
        //     .displayName.toLowerCase() +
        //   " is " +
        //   (x.value == null ? "empty" : x.valuePretty || x.value);
        const name = x.groupName;
        return {
          clientId: this.clientId,
          status: "Active",
          groupName: name,
          groupDisplayName: name,
          displayName: name,
          groupType: "Simple",
          enrollmentRules: x.enrollmentRules, //this.selectedUserColumn + ":" + x.value,
          hierarchyRules: null,
          includeSubClients: this.includeSubClients
        };
      });

      // Moved to v2 of API. Need to update this for all uses of this widget
      // if (this.version == 3) {
      try {
        const response = await GroupService.createGroupV2(
          groups,
          "simple",
          {},
          this.magicLinkToken
        );
        console.log("Group res ", response);
        let id = response?.result?.[0]?.groupId;
        this.getGroups(id);
        // this.getGroups(response?.result?.[0]);
      } catch (e) {
        console.log("Error creating simple group", e);
      } finally {
        this.showLoadingDialog = false;
        this.closeWindow();
      }
      return;
    },

    nextButtonClicked() {
      if (!this.selectedUserColumn) return;

      //We create a simple group so we have to fetch values
      this.getSimpleGroupColumnValues();
    },
    closeWindow() {
      this.selectedUserColumn = null;

      // if (this.displaySimpleGroupResults) {
      //   this.complexGroup.groupDisplayName = null;
      // }

      this.displaySimpleGroupResults = false;
      this.$emit("close");
    },
    getGroups(prop) {
      this.$emit("get-groups", prop);
    },
    loadGroupsPage() {
      if (this.source == "PROGRAM_WIZARD" || this.source == "LEARNING_WIZARD") {
        this.$emit("push-to-groups");
      } else {
        this.$router.push({ name: "groups" });
      }
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "magicLinkToken"]),
    computedColumnDisplayName() {
      if (this.selectedUserColumn !== null) {
        return this.userColumns.find(
          x => x.columnName == this.selectedUserColumn
        ).displayName;
      } else {
        return null;
      }
    }
  },
  watch: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.table >>> .v-data-table-header {
  background-color: white;
}
</style>

<template>
  <v-card elevation="0" rounded="0">
    <MarqetaCreatePIN
      v-if="token && cardType"
      :token="token"
      :card="cardType"
    />
    <div v-else-if="invalidCardType" class="error--text">
      Invalid card type
    </div>
    <div v-else class="error--text">
      Missing token or card type query parameter
    </div>
  </v-card>
</template>

<script>
import MarqetaCreatePIN from "@/components/wallet/MarqetaCreatePIN.vue";

export default {
  name: "MarqetaCreatePINHosted",

  props: {},
  components: {
    MarqetaCreatePIN
  },
  data() {
    return {
      token: null,
      cardType: null,
      invalidCardType: false
    };
  },
  created() {
    this.token = this.$route.query.token;
    if (this.$route.query.cardType) {
      this.cardType = this.$route.query.cardType;
      if (
        this.cardType &&
        !["VIRTUAL", "PHYSICAL"].includes(this.cardType.toUpperCase())
      ) {
        this.invalidCardType = true;
      }
    }

    window.divNode = window.document.createElement("div");
    window.divNode.setAttribute("id", "tempFreshworks");
    window.divNode.innerHTML =
      "<style>#launcher-frame { right: -105px !important; }</style>";
    window.document.body.appendChild(window.divNode);
  },
  destroyed() {
    window.divNode.parentNode.removeChild(window.divNode);
  },
  updated() {},

  methods: {},
  computed: {}
};
</script>

<style scoped></style>

var emailTemplate = `<html>
  <head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <style type="text/css">
      /* FONTS */
      @media screen {
        @font-face {
          font-family: ProximaNova;
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot);
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot?#iefix)
              format("embedded-opentype"),
            url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.woff)
              format("woff");
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: ProximaNova;
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot);
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot?#iefix)
              format("embedded-opentype"),
            url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.woff)
              format("woff");
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: ProximaNova;
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot);
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot?#iefix)
              format("embedded-opentype"),
            url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.woff)
              format("woff");
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: ProximaNova;
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot);
          src: url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.eot?#iefix)
              format("embedded-opentype"),
            url(https://cdn.auth0.com/fonts/proxima-nova/proximanova-regular-webfont-webfont.woff)
              format("woff");
          font-weight: 400;
          font-style: normal;
        }
      } /*
      CLIENT-SPECIFIC STYLES */
      body,
      table,
      td,
      a {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      table,
      td {
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }
      img {
        -ms-interpolation-mode: bicubic;
      } /* RESET
      STYLES */
      img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
      }
      table {
        border-collapse: collapse !important;
      }
      body {
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
      } /* iOS BLUE LINKS */
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      } /* MOBILE STYLES */
      @media screen and (max-width: 600px) {
        h1 {
          font-size: 32px !important;
          line-height: 32px !important;
        }
      } /*
      ANDROID CENTER FIX */
      div[style*="margin: 16px 0;"] {
        margin: 0 !important;
      }
    </style>
  </head>
  <body
    style="
      background-color: #f4f4f4;
      margin: 0 !important;
      padding: 0 !important;
    "
  >
    <!-- HIDDEN PREHEADER TEXT -->
    <div
      style="
        display: none;
        font-size: 1px;
        color: #fefefe;
        line-height: 1px;
        font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
        max-height: 0px;
        max-width: 0px;
        opacity: 0;
        overflow: hidden;
      "
    >
      {{subtitle}}
    </div>

    <table border="0" cellpadding="0" cellspacing="0" width="100%">
      <!-- LOGO -->
      <tr>
        <td bgcolor="white" align="center">
          <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]-->
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="max-width: 600px"
          >
            <tr>
              <td
                align="center"
                valign="top"
                style="padding: 40px 10px 40px 10px"
              >
                <a href="http://litmus.com" target="_blank">
                  <img
                    alt="Logo"
                    src="https://storage.googleapis.com/whistle-dev-pictures/Logo/whistle-logo-gradient-clean.png"
                    width="150"
                    height="70"
                    style="
                      display: block;
                      width: 150px;
                      max-width: 150px;
                      min-width: 150px;
                      font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                      color: #ffffff;
                      font-size: 18px;
                    "
                    border="0"
                  />
                </a>
              </td>
            </tr>
          </table>

          <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
        </td>
      </tr>
      <!-- HERO -->
      <tr>
        <td bgcolor="white" align="center" style="padding: 0px 10px 0px 10px">
          <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]-->
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="
              max-width: 600px;
              border-style: solid;
              border-width: 2px 2px 0 2px;
              border-color: darkgray;
            "
          >
            <tr>
              <td
                bgcolor="#ffffff"
                align="center"
                valign="top"
                style="
                  padding: 40px 20px 20px 20px;
                  border-radius: 4px 4px 0px 0px;
                  color: #111111;
                  font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                  font-size: 48px;
                  font-weight: 400;
                  letter-spacing: 2px;
                  line-height: 40px;
                "
              >
                <h1
                  style="
                    font-size: 26px;
                    font-weight: 400;
                    margin: 0;
                    color: gray;
                  "
                >
                  {{title}}
                </h1>
              </td>
            </tr>
          </table>

          <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
        </td>
      </tr>
      <!-- COPY BLOCK -->
      <tr>
        <td bgcolor="white" align="center" style="padding: 0px 10px 0px 10px">
          <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]-->
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="
              max-width: 600px;
              border-style: solid;
              border-width: 0 2px 0 2px;
              border-color: darkgray;
            "
          >
            <!-- ICON -->
            <tr>
              <td
                bgcolor="#ffffff"
                align="center"
                style="
                  padding: 20px 30px 40px 30px;
                  color: #666666;
                  font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 25px;
                "
              >
                <!--                   <a href="http://litmus.com" target="_blank" style> -->
                <div
                  style="
                    background-color: #97dffc;
                    border-radius: 900px;
                    width: 115px;
                    height: 107px;
                    padding-top: 8px;
                  "
                >
                  <div
                    style="
                      background-color: #09acec;
                      border-radius: 1000px;
                      width: 99px;
                      height: 99px;
                    "
                  >
                    <img
                      alt="Logo"
                      src="https://storage.googleapis.com/whistle-dev-pictures/Icons/{{icon}}.png"
                      width="50"
                      height="50"
                      style="
                        display: block;
                        font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                        color: #ffffff;
                        padding-top: 25px;
                      "
                      border="0"
                    />
                  </div>
                </div>
                <!--                         </a> -->
              </td>
            </tr>
            <!-- COPY -->
            <tr>
              <td
                bgcolor="#ffffff"
                align="center"
                style="
                  padding: 10px 30px 30px 30px;
                  color: #666666;
                  font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 25px;
                "
                id="custom-body"
              >
                <p style="margin: 0">{{body}}</p>
              </td>
            </tr>
            <!-- BULLETPROOF BUTTON -->
            <tr>
              <td bgcolor="#ffffff" align="left">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tr>
                    <td
                      bgcolor="#ffffff"
                      align="center"
                      style="padding: 20px 30px 40px 30px"
                    >
                      <table border="0" cellspacing="0" cellpadding="0">
                        <tr>
                          <td
                            align="center"
                            style="border-radius: 3px"
                            bgcolor="#2d64d3"
                          >
                            <a
                              href="https://{{env}}.wewhistle.com/{{url}}"
                              target="_blank"
                              style="
                                font-size: 14px;
                                font-family: 'ProximaNova', Helvetica, Arial,
                                  sans-serif;
                                color: #ffffff;
                                text-decoration: none;
                                color: #ffffff;
                                text-decoration: none;
                                padding: 10px 25px;
                                border-radius: 5px;
                                display: inline-block;
                              "
                              >Go to {{button}}</a
                            >
                          </td>
                        </tr>
                      </table>

                      <span
                        style="
                          margin-bottom: 0px;
                          position: relative;
                          top: 10px;
                          color: #888888;
                          font-size: 12px;
                          font-family: 'ProximaNova', Helvetica, Arial,
                            sans-serif;
                        "
                        >Or follow this link
                      </span>
                      <br /><a
                        href="https://{{env}}.wewhistle.com/{{url}}"
                        style="
                          text-decoration: none;
                          color: #888888;
                          font-size: 12px;
                          font-family: 'ProximaNova', Helvetica, Arial,
                            sans-serif;
                          position: relative;
                          top: 10px;
                        "
                      >
                        https://{{env}}.wewhistle.com/{{url}}</a
                      >
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <!-- Divider -->
            <tr>
              <td>
                <table
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  width="100%"
                  style="max-width: 600px;"
                >
                  <hr
                    style="
                      border-style: solid;
                      border: 2px solid darkgray;
                      border-bottom: 0;
                      margin: 0px 20px 30px 20px;
                    "
                  />
                </table>
              </td>
            </tr>
            <tr>
              <td
                bgcolor="#ffffff"
                align="center"
                style="
                  color: grey;
                  font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 25px;
                "
              >
                <p style="margin: 0">
                  If you need additional help,
                  <a
                    href="mailto:help@wewhistle.com"
                    style="text-decoration: none"
                    >contact us</a
                  >
                </p>
              </td>
            </tr>
            <!-- COPY -->
            <tr>
              <td
                bgcolor="#ffffff"
                align="center"
                style="
                  padding: 0px 30px 20px 30px;
                  color: grey;
                  font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 25px;
                "
              >
                <p style="margin: 0">
                  If you think this email was sent in error, you can ignore it,
                  send us a note, or
                  <a
                    href="https://{{env}}.wewhistle.com/unsubscribe/email"
                    style="text-decoration: none"
                    >unsubscribe</a
                  >
                  from all emails.
                </p>
              </td>
            </tr>
            <!-- COPY -->
            <!--    <tr>
                <td bgcolor="#ffffff" align="left" style="padding: 0px 30px 40px 30px; border-radius: 0px 0px 4px 4px; color: #666666; font-family: 'ProximaNova', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;" >
                  <p style="margin: 0;">Cheers,<br>The Ceej Team</p>
                </td>
              </tr> -->
          </table>

          <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
        </td>
      </tr>
      <!-- Gradient background -->
      <tr>
        <td
          align="center"
          style="
            padding: 0px 10px 0px 10px;
            background: rgb(161, 215, 110);
            background: linear-gradient(
              90deg,
              rgba(161, 215, 110, 1) 35%,
              rgba(10, 172, 235, 1) 100%
            );
          "
        >
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="
              max-width: 600px;
              border-style: solid;
              border-width: 0 2px 2px 2px;
              border-color: darkgray;
            "
          >
            <tr>
              <td
                bgcolor="#ffffff"
                align="center"
                valign="top"
                style="
                  padding: 40px 20px 20px 20px;
                  border-radius: 0px 0px 4px 4px;
                  color: #111111;
                  font-family: 'ProximaNova', Helvetica, Arial, sans-serif;
                  font-size: 48px;
                  font-weight: 400;
                  letter-spacing: 4px;
                  line-height: 48px;
                "
              >
                <h1
                  style="
                    font-size: 26px;
                    font-weight: 400;
                    margin: 0;
                    color: gray;
                  "
                ></h1>
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <!-- FOOTER -->
      <!--  <tr>
        <td bgcolor="#f4f4f4" align="center" style="padding: 0px 10px 0px 10px;">
            [if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]
            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;" >
              NAVIGATION
              <tr>
                <td bgcolor="#f4f4f4" align="left" style="padding: 30px 30px 30px 30px; color: #666666; font-family: 'ProximaNova', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; line-height: 18px;" >
                  <p style="margin: 0;">
                    <a href="http://litmus.com" target="_blank" style="color: #111111; font-weight: 700;">Dashboard</a> -
                    <a href="http://litmus.com" target="_blank" style="color: #111111; font-weight: 700;">Billing</a> -
                    <a href="http://litmus.com" target="_blank" style="color: #111111; font-weight: 700;">Help</a>
                  </p>
                </td>
              </tr>
              PERMISSION REMINDER
              <tr>
                <td bgcolor="#f4f4f4" align="left" style="padding: 0px 30px 30px 30px; color: #666666; font-family: 'ProximaNova', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; line-height: 18px;" >
                  <p style="margin: 0;">You received this email because you just signed up for a new account. If it looks weird, <a href="http://litmus.com" target="_blank" style="color: #111111; font-weight: 700;">view it in your browser</a>.</p>
                </td>
              </tr>
              UNSUBSCRIBE
              <tr>
                <td bgcolor="#f4f4f4" align="left" style="padding: 0px 30px 30px 30px; color: #666666; font-family: 'ProximaNova', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; line-height: 18px;" >
                  <p style="margin: 0;">If these emails get annoying, please feel free to <a href="http://litmus.com" target="_blank" style="color: #111111; font-weight: 700;">unsubscribe</a>.</p>
                </td>
              </tr>
              ADDRESS
              <tr>
                <td bgcolor="#f4f4f4" align="left" style="padding: 0px 30px 30px 30px; color: #666666; font-family: 'ProximaNova', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; line-height: 18px;" >
                  <p style="margin: 0;">Ceej - 1234 Main Street - Anywhere, MA - 56789</p>
                </td>
              </tr>
            </table>
            [if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]
        </td>
    </tr> -->
      <!-- LOGO -->
      <tr>
        <td
          style="
            background: rgb(161, 215, 110);
            background: linear-gradient(
              90deg,
              rgba(161, 215, 110, 1) 35%,
              rgba(10, 172, 235, 1) 100%
            );
          "
          align="center"
        >
          <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
            <tr>
            <td align="center" valign="top" width="600">
            <![endif]-->
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="100%"
            style="max-width: 600px"
          >
            <tr>
              <td
                align="center"
                valign="top"
                style="padding: 40px 10px 40px 10px"
              >
                <!--   <a href="http://litmus.com" target="_blank">
                            <img alt="Logo" src="http://litmuswww.s3.amazonaws.com/community/template-gallery/ceej/logo.png" width="40" height="40" style="display: block; width: 40px; max-width: 40px; min-width: 40px; font-family: 'ProximaNova', Helvetica, Arial, sans-serif; color: #ffffff; font-size: 18px;" border="0">
                        </a> -->
              </td>
            </tr>
          </table>

          <!--[if (gte mso 9)|(IE)]>
            </td>
            </tr>
            </table>
            <![endif]-->
        </td>
      </tr>
    </table>
  </body>
</html>
`;
export { emailTemplate };

<template>
  <div>
    <v-card-title>
      <v-row>
        <v-col
          class="d-flex flex-column align-start ml-2"
          cols="6"
          sm="6"
          md="6"
        >
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1"
            @click="previousSlide"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title>
    <transition :name="slideDirection" mode="out-in">
      <v-row
        v-if="currentSlide == 0"
        key="0"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Manage your fulfillment partners for physical awards</h2>
        <v-data-table
          :headers="columns"
          :items="fulfillmentPartners"
          item-key="awardFulfillmentPartnerId"
          :items-per-page="10"
          :search="search"
          class="elevation-0 mt-2 partner-table"
          loading-text="Loading... Please wait"
          no-data-text="No partners found"
        >
          <template v-slot:[`item.icons`]="{ item }">
            <div class="d-flex justify-end">
              <v-btn
                v-if="permissions.includes('awards:create:award')"
                icon
                fab
                small
                color="white"
                class="ml-4 my-2 accent-background py-2"
                @click="editFulfillmentPartner(item)"
              >
                <v-icon color="white">mdi-pencil </v-icon>
              </v-btn>
              <v-btn
                v-if="permissions.includes('awards:delete:award')"
                icon
                fab
                small
                color="white"
                class="ml-3 my-2 accent-background py-2"
                @click="
                  deleteFulfillmentPartner(item.awardFulfillmentPartnerId)
                "
              >
                <v-icon color="white">mdi-trash-can-outline </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-row>
      <v-row
        v-if="currentSlide == 1"
        key="1"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Let's get all the details for your fulfillment partner</h2>

        <v-form
          ref="form"
          v-model="validForm"
          class="full-width px-4 align-text-left"
        >
          <v-text-field
            ref="email"
            label="Nickname"
            v-model="name"
            class="mt-4"
            :rules="[
              (v) => !!v || 'Nickname is required',
              (v) => !!(v && v.length < 255) || 'Nickname should be shorter',
            ]"
          />
          <v-text-field
            ref="email"
            label="Email"
            v-model="email"
            class="mt-4"
            :rules="[
              (v) => !!v || 'Email is required',
              (v) => !!(v && v.length < 255) || 'Email should be shorter',
              (v) => (v && emailRegex.test(v)) || 'Email must be valid',
            ]"
          />
          <span
            >How often do you want to receive an email with orders (in
            days)</span
          >
          <v-text-field
            ref="sendEveryXDays"
            label="Email frequency"
            v-model="sendEveryXDays"
            type="number"
            class="mt-4"
            :rules="[
              (v) => !!v || 'An email frequency is required',
              (v) =>
                !!(v && parseInt(v) > 0) ||
                'An email frequency should be greater than 0',
              (v) =>
                !!(v && !v.toString().includes('.')) ||
                'An email frequency should be a whole number',
            ]"
          />
        </v-form>
      </v-row>
    </transition>
    <v-row
      class="mb-10"
      align="center"
      justify="center"
      v-if="currentSlide != 3"
    >
      <v-col>
        <v-btn
          outlined
          rounded
          v-if="
            currentSlide == 0 && permissions.includes('awards:create:award')
          "
          class="py-5"
          @click="newFulfillmentPartner"
          :loading="waitingForService"
          ><v-icon class="mr-1" color="brandGreen">mdi-plus</v-icon
          ><span class="mt-1">New</span></v-btn
        >
        <v-btn
          outlined
          rounded
          :disabled="!validForm"
          v-else-if="currentSlide == 1"
          class="py-5"
          @click="saveFulfillmentPartner"
          :loading="waitingForService"
          ><v-icon class="mr-1" color="brandGreen">mdi-content-save</v-icon
          ><span class="mt-1">Save</span></v-btn
        ></v-col
      >
    </v-row>
  </div>
</template>

<script>
import BudgetService from "@/services/BudgetService";
import { mapState } from "vuex";
import { emailRegex } from "@/shared_data/data.js";

export default {
  name: "AwardRedemption",
  props: {
    fulfillmentPartners: Array,
    clientId: Number,
  },
  components: {},
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      validOrderForm: false,
      validForm: false,
      validAddressForm: false,
      search: null,
      columns: [
        { text: "Name", value: "name", width: "70%" },
        {
          text: "",
          value: "icons",
          width: "30%",
          sortable: false,
          align: "end",
        },
      ],

      editing: false,
      awardFulfillmentPartnerId: null,
      name: null,
      email: null,
      sendEveryXDays: null,
      waitingForService: false,
      emailRegex,
    };
  },
  created() {},
  beforeDestroy() {},
  methods: {
    resetForm(emit) {
      this.name = null;
      this.email = null;
      this.sendEveryXDays = null;
      this.awardFulfillmentPartnerId = null;
      this.editing = false;
      if (this.currentSlide == 1) {
        this.$refs.form.reset();
      }
      if (emit) {
        this.$emit("reset");
      }

      this.currentSlide = 0;
    },
    previousSlide() {
      this.$emit("scrollToTop");
      if (this.currentSlide == 0) {
        this.resetForm(true);
      } else {
        this.currentSlide -= 1;
      }
    },
    newFulfillmentPartner() {
      this.editing = false;
      this.awardFulfillmentPartnerId = null;
      this.email = null;
      this.name = null;
      this.sendEveryXDays = null;
      this.currentSlide = 1;
    },
    editFulfillmentPartner(partner) {
      this.editing = true;
      this.awardFulfillmentPartnerId = partner.awardFulfillmentPartnerId;
      this.clientId = partner.clientId;
      this.email = partner.email;
      this.name = partner.name;
      this.sendEveryXDays = partner.sendEveryXDays;
      this.currentSlide = 1;
    },
    saveFulfillmentPartner() {
      var clientId = this.userProfile.clientId;
      if (this.clientId) {
        clientId = this.clientId;
      }
      var obj = {
        clientId: clientId,
        email: this.email,
        name: this.name,
        sendEveryXDays: this.sendEveryXDays,
        createdBy: this.userProfile.userId,
        updatedBy: this.userProfile.userId,
      };
      this.waitingForService = true;
      if (this.editing) {
        BudgetService.updateAwardFulfillmentPartner(
          this.awardFulfillmentPartnerId,
          obj
        ).then((x) => {
          console.log(x);
          this.resetForm(false);
          this.$emit("get-partners");
          this.waitingForService = false;
        });
      } else {
        BudgetService.createAwardFulfillmentPartner(obj).then((x) => {
          console.log(x);
          this.resetForm(false);
          this.$emit("get-partners");
          this.waitingForService = false;
        });
      }
    },
    deleteFulfillmentPartner(id) {
      BudgetService.deleteAwardFulfillmentPartner(id).then((x) => {
        console.log(x);
        this.$emit("get-partners");
      });
    },
  },
  computed: {
    ...mapState(["userProfile", "permissions"]),
  },
};
</script>

<style scoped>
.card-text {
  font-size: 1.2em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.accent-background {
  background-color: var(--v-accent-base);
}

.partner-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.partner-table >>> .v-data-table-header {
  background-color: white;
}
</style>

import axios from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_PROGRAM_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-programs"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-programs" : "/program-service"
    }`;

class ProgramService {
  // getPrograms(queryString = "") {
  //   return axios
  //     .get(baseUrl + "/programs" + queryString)
  //     .then(response => {
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log("Error!", error.response);
  //       return error;
  //     });
  // }
  createProgram(program) {
    return axios
      .post(baseUrl + "/programs", program)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getProgram(programId) {
    return axios
      .get(baseUrl + "/programs/" + programId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getProgramCacheByProgram(programId) {
    return axios
      .get(baseUrl + "/programs/cache/program/" + programId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getProgramLeaderboard(programId) {
    return axios
      .get(baseUrl + "/programs/leaderboard/" + programId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  // THIS ENDPOINT IS NO LONGER IN USE OR FUNCTIONAL
  // getProgramBudgets() {
  //   return axios
  //     .get(baseUrl + "/programs/budgets/balance")
  //     .then(response => {
  //       console.log(response.data.budgets);
  //       return response.data.budgets;
  //     })
  //     .catch(error => {
  //       console.log("Error!", error.response);
  //       return error;
  //     });
  // }
  getProgramsByUser(userId, programId, includeSurveys = true) {
    var endpointString = "/programs/byUser/" + userId;
    if (programId !== null) endpointString += "/" + programId;
    console.log(endpointString);
    return axios
      .get(baseUrl + endpointString + "?surveys=" + includeSurveys)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        throw error;
      });
  }
  // getArchivedProgramsByUser(userId) {
  //   return axios
  //     .get(baseUrl + "/programs/results/user/" + userId)
  //     .then(response => {
  //       return response.data;
  //     })
  //     .catch(error => {
  //       console.log("Error!", error.response);
  //       return error;
  //     });
  // }
  getProgramLearningModulesForProgram(programId) {
    return axios
      .get(baseUrl + "/programs/learningModule/program/" + programId)
      .then(response => {
        console.log(response.data.programLearningModule);
        return response.data.programLearningModule;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  downloadProgramCache(programId) {
    return axios
      .get(baseUrl + "/programs/cache/download/" + programId)
      .then(response => {
        console.log("Downloading program " + programId + " results file");
        let blob = new Blob([response.data], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Program_Results.csv");
        document.body.appendChild(link);
        link.click();
      });
  }
  updateProgram(programId, program) {
    return axios
      .patch(baseUrl + "/programs/" + programId, program)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteProgram(programId) {
    return axios
      .delete(baseUrl + "/programs/" + programId)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e && e.response ? e.response.data || e.response : e;
      });
  }

  getMetrics(queryString = "") {
    return axios
      .get(baseUrl + "/metrics" + queryString)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  // Takes an array of user ids (users) and an array of metric ids (metrics)
  getLatestMetricValues(body) {
    return axios
      .post(baseUrl + "/programMetrics/recent", body)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createMetric(metric) {
    return axios
      .post(baseUrl + "/metrics", metric)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getMetric(metricId) {
    return axios
      .get(baseUrl + "/metrics/" + metricId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateMetric(metricId, metric) {
    return axios
      .patch(baseUrl + "/metrics/" + metricId, metric)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteMetric(metricId) {
    return axios
      .delete(baseUrl + "/metrics/" + metricId)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getProgramAttachment(id, filepath) {
    return axios
      .get(baseUrl + "/programs/attachments/id/" + id, {
        responseType: "blob"
      })
      .then(response => {
        console.log(response.data);
        var blob = new Blob([response.data], {
          type: "application/octet-stream"
        });

        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = filepath.substring(filepath.lastIndexOf("/") + 1);
        console.log(filepath);
        console.log(fileName);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  uploadMetricCSV(file, programId, timezone, clientId) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("timezone", timezone);
    formData.append("clientId", clientId);
    return axios
      .post(baseUrl + "/programMetrics/csv/" + programId, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }
  downloadMetricTemplateFile(metricId) {
    return axios
      .get(baseUrl + "/programMetrics/template/" + metricId)
      .then(response => {
        console.log("Downloading template file");
        let blob = new Blob([response.data], { type: "text/csv" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "Program_Metric_Template.csv");
        document.body.appendChild(link);
        link.click();
      });
  }
  postMetric(metricId, body) {
    return axios
      .post(baseUrl + "/programMetrics/" + metricId, body)
      .then(response => {
        console.log(response);
        return response;
      })
      .catch(error => {
        console.log(error);
      });
  }
  postMetricBulk(body) {
    return axios
      .post(baseUrl + "/programMetrics/bulk", body)
      .then(response => {
        console.log(response);
        return response;
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  }
  postSelfAssessMetric(metricId, body, programId = null) {
    const q = programId ? `?programId=${programId}` : "";
    return axios
      .post(`${baseUrl}/programMetrics/selfAssess/${metricId}${q}`, body)
      .then(response => {
        console.log(response);
        return response;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
  createProgramAttachment(body) {
    return axios
      .post(baseUrl + "/programs/attachments", body)
      .then(response => {
        console.log(response);
        return response.data.programAttachment;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  createProgramAttachmentBulk(array) {
    return axios
      .post(baseUrl + "/programs/attachments/bulk", array)
      .then(response => {
        console.log(response);
        return response.data.programAttachment;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteProgramAttachments(array, clientId) {
    var pairs = array.map(function(value) {
      return "id=" + encodeURIComponent(value);
    });
    var query_string = pairs.join("&");
    return axios
      .delete(
        baseUrl + "/programs/attachments/" + clientId + "/" + query_string
      )
      .then(response => {
        console.log(response);
        return response.data.programAttachment;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  uploadProgramImage(programId, attachment, clientId) {
    let formData = new FormData();

    formData.append("file", attachment);
    formData.append("clientId", clientId);
    return axios
      .post(baseUrl + "/programs/image/" + programId, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  duplicateProgramImage(oldId, newId, oldFilePath, clientId) {
    var body = {
      newProgramId: newId,
      clientId: clientId
    };

    // If we're duplicating an old program, we use oldProgramId
    // If we're duplicating an image from a learning module, we send the content filepath
    if (oldId) {
      body.oldProgramId = oldId;
    } else if (oldFilePath) {
      body.oldFilePath = oldFilePath;
    }
    return axios
      .post(baseUrl + "/programs/image/duplicate", body)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteProgramImage(programId, clientId) {
    return axios
      .delete(baseUrl + "/programs/image/" + clientId + "/" + programId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  createProgramRulesBulk(rules) {
    return axios
      .post(baseUrl + "/rules/bulk", rules)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteProgramRulesByProgram(programId) {
    return axios
      .delete(baseUrl + "/rules/program/" + programId)
      .then(response => {
        console.log(response.data);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  runProgramCRON() {
    return axios
      .get(baseUrl + "/programs/cron/daily")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  // V2
  getProgramsV2(params) {
    return axios
      .get(baseUrl + "/v2/program", { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting programs for admin page ", error.response);
        throw error.response;
      });
  } // end getProgramsV2

  getProgramsAdminV2(context, clientId) {
    return axios
      .get(
        `${baseUrl}/v2/program/admin?clientId=${clientId}&context=${context}`
      )
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting program (v2)", error.response);
        throw error;
      });
  } // end getProgramsAdminV2

  getProgramForWizardV2(programId, clientId) {
    return axios
      .get(`${baseUrl}/v2/program/admin/${programId}?clientId=${clientId}`)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting program for wizard", error.response);
        throw error;
      });
  } // end getProgramForWizardV2

  getProgramsByUserV2(programId, includeSurveys = true) {
    var endpointString = "/v2/program/user";
    if (programId !== null) endpointString += "/" + programId;
    console.log(endpointString);
    return axios
      .get(baseUrl + endpointString + "?surveys=" + includeSurveys)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting programs for user ", error.response);
        throw error;
      });
  } // end getProgramsByUserV2

  getProgressForProgramElement(elementId) {
    return axios
      .get(`${baseUrl}/v2/program-element/search/progress/${elementId}`)
      .then(response => {
        return response.data.result.rows;
      })
      .catch(error => {
        console.log("Error getting program element progress", error.response);
        throw error;
      });
  } // end getProgressForProgramElement

  createProgramV2(body) {
    return axios
      .post(baseUrl + "/v2/program", body)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error creating program ", error.response);
        throw error.response;
      });
  } // end createProgramV2

  updateProgramV2(id, body) {
    return axios
      .patch(`${baseUrl}/v2/program/${id}`, body)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating program ", error.response);
        throw error.response;
      });
  } // end updateProgramV2

  deleteMetricV2(id) {
    return axios
      .delete(`${baseUrl}/v2/metric-definition/${id}`)
      .then(response => {
        return response.data.result;
      })
      .catch(e => {
        console.log("Error deleting metric ", e.response);
        throw e && e.response ? e.response.data || e.response : e;
      });
  } // end updateProgramV2
}

export default new ProgramService();

<template>
  <v-card flat>
    <v-toolbar dark color="primary" rounded="0" elevation="0">
      <v-toolbar-title v-if="!editingAward" color="primary">
        Add a new Award
      </v-toolbar-title>
      <v-toolbar-title v-else color="primary">
        Update an Award
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="awardForm.page == 0">
        <v-btn dark text @click="resetForm">
          <span>Cancel</span>
        </v-btn>
        <v-btn
          color="white"
          text
          @click="createNewAward"
          :disabled="!awardForm.valid"
          v-if="!editingAward"
        >
          Create Award
        </v-btn>
        <v-btn
          color="white"
          text
          @click="updateAward"
          :disabled="!awardForm.valid || award.clientId === 0"
          v-else
        >
          Update Award
        </v-btn>
      </v-toolbar-items>
      <div class="d-flex align-center" v-else-if="awardForm.page == 1">
        <v-btn color="white" text dark @click="closePhysicalAwardForm">
          Cancel
        </v-btn>

        <v-tooltip bottom :disabled="!physicalAwardValidator" open-delay="200">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                color="white"
                text
                dark
                @click="savePhysicalAwardForm"
                :disabled="physicalAwardValidator"
              >
                Save
              </v-btn>
            </div>
          </template>
          <span>Saving requires all items have names and SKUs</span>
        </v-tooltip>
      </div>
    </v-toolbar>
    <v-divider />

    <v-divider />
    <v-container v-if="awardForm.page == 0">
      <v-card-text>
        <v-form ref="awardForm" v-model="awardForm.valid">
          <v-autocomplete
            class="pa-1 full-width"
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client*"
            v-model="award.clientId"
            :disabled="editingAward"
            v-if="userProfile.clientId == 1 && !clientId"
          ></v-autocomplete>
          <!-- <v-text-field
            class="pa-1"
            v-model="award.awardName"
            :rules="[(v) => !!v || 'Name is required']"
            label="Name*"
            required
          ></v-text-field> -->
          <v-text-field
            class="pa-1"
            v-model="award.awardDisplayName"
            :rules="[
              v => !!v || 'Award name is required',
              v => !!(v && v.length < 255) || 'Award name should be shorter'
            ]"
            label="Name*"
          ></v-text-field>
          <v-text-field
            class="pa-1"
            v-model="award.awardDescription"
            label="Description"
            :rules="[
              v => !!(!v || v.length < 255) || 'Description should be shorter'
            ]"
          ></v-text-field>
          <!-- <v-text-field
            class="pa-1"
            v-model="award.awardValue"
            type="number"
            :rules="[
              (v) => !!v || 'Award value is required',
              (v) => !!(v && v > 0) || 'Award value must be a positive number',
              (v) =>
                !!(v && !v.toString().includes('.')) ||
                'Award value must be a whole number',
            ]"
            label="Award value*"
          ></v-text-field> -->
          <v-text-field
            class="pa-1"
            v-model="award.unitSingular"
            :rules="[
              v => !!v || 'Unit singular is required',
              v => !!(v && v.length < 40) || 'Units should be shorter'
            ]"
            label="Unit Singular*"
          ></v-text-field>
          <v-text-field
            class="pa-1"
            v-model="award.unitPlural"
            :rules="[
              v => !!v || 'Unit plural is required',
              v => !!(v && v.length < 40) || 'Units should be shorter'
            ]"
            label="Unit Plural*"
          ></v-text-field>
          <!-- <v-select
            class="pa-1"
            :items="categories"
            item-text="category"
            item-value="id"
            label="Category*"
            v-model="award.category"
            :rules="[v => !!v || 'Category is required']"
          ></v-select> -->
          <!-- <v-menu
            v-model="awardForm.menuExpirationDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="v-flex flex-row">
                <v-text-field
                  v-if="!awardForm.expDateCheckBox"
                  v-model="award.expirationDate"
                  label="Award Expiration Date*"
                  :rules="[(v) => !!v || 'Expiration date is required']"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
                <v-checkbox
                  class="checkBoxes mt-0 pt-0"
                  v-model="awardForm.expDateCheckBox"
                  @change="checkBoxUpdated()"
                  label="No end date"
                >
                </v-checkbox>
              </div>
            </template>
            <v-date-picker
              v-model="award.expirationDate"
              @input="awardForm.menuExpirationDate = false"
              :disabled="awardForm.expDateCheckBox"
            ></v-date-picker>
          </v-menu> -->
        </v-form>
        <!-- <v-btn
          color="brandCyan"
          class="white--text"
          depressed
          @click="loadPhysicalAwardForm"
          v-if="award.clientId != 0"
          ><span v-if="award.physicalAwards.length == 0"
            >Add Physical Awards</span
          ><span v-else>View/Edit Physical Awards</span></v-btn
        > -->
      </v-card-text>
      <v-card-actions>
        <v-tooltip
          top
          :disabled="!disableDelete"
          v-if="editingAward && permissions.includes('awards:delete:award')"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                v-if="
                  editingAward && permissions.includes('awards:delete:award')
                "
                color="red"
                text
                @click="deleteAward"
                :disabled="disableDelete"
              >
                Delete
              </v-btn>
            </div>
          </template>
          <span>A budget is currently tied to this award</span>
        </v-tooltip>
        <v-btn color="blue darken-1" text @click="resetForm">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="createNewAward"
          :disabled="!awardForm.valid"
          v-if="!editingAward"
        >
          Create
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="updateAward"
          :disabled="!awardForm.valid || award.clientId === 0"
          v-else
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-container>
    <v-container
      v-else-if="awardForm.page == 1"
      class="d-flex flex-column justify-space-between"
    >
      <v-card-text>
        <div class="my-4 d-flex justify-end mr-3">
          <v-btn text color="primary" @click="awardForm.page = 2"
            >Manage Fulfillment Partners</v-btn
          >
        </div>
        <div class="my-4 d-flex justify-end mr-3">
          <v-btn text color="primary" @click="addPhysicalAward"
            >+ Add Item</v-btn
          >
        </div>
        <v-expansion-panels flat v-model="awardForm.physicalAwardPanel">
          <v-expansion-panel
            v-for="(item, i) in award.physicalAwardsTemp"
            :key="i"
            class="outlined"
            :class="{
              'outlined-bottom':
                i == award.physicalAwardsTemp.length - 1 ||
                i == awardForm.physicalAwardPanel ||
                i == awardForm.physicalAwardPanel - 1
            }"
          >
            <v-expansion-panel-header>
              <div class="d-flex justify-space-between align-center">
                <span>{{ item.name || "Unnamed Item" }}</span>
                <div class="d-flex align-center mr-5">
                  <v-btn icon @click.native.stop="deletePhysicalAward(i)"
                    ><v-icon color="red">mdi-trash-can-outline</v-icon></v-btn
                  >
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0">
              <div class=" mx-3">
                <v-select
                  outlined
                  class="pa-1 mx-2"
                  :items="clientFilteredFulfillmentPartners"
                  item-text="name"
                  item-value="awardFulfillmentPartnerId"
                  label="Fulfillment*"
                  v-model="item.awardFulfillmentPartnerId"
                ></v-select>
                <v-text-field
                  outlined
                  class="pa-1 mx-2"
                  v-model="item.sku"
                  required
                  label="SKU*"
                  :rules="[v => !!v || 'A SKU is required']"
                ></v-text-field>
                <v-text-field
                  outlined
                  class="pa-1 mx-2"
                  v-model="item.name"
                  required
                  label="Name*"
                  :rules="[v => !!v || 'A name is required']"
                ></v-text-field>
                <v-textarea
                  outlined
                  rows="3"
                  class="pa-1 mx-2"
                  v-model="item.description"
                  label="Description"
                ></v-textarea>
                <v-text-field
                  outlined
                  class="pa-1 mx-2"
                  v-model="item.image"
                  label="Image URL"
                ></v-text-field>
                <h3 class="mb-3" v-if="item.image">Image Preview:</h3>
                <v-img
                  v-if="item.image"
                  :src="item.image"
                  :key="item.image"
                  :aspect-ratio="1 / 1"
                  height="200"
                  width="200"
                  class="mx-auto mb-3"
                ></v-img>
                <h3>Shipping</h3>
                <v-switch
                  v-model="item.requireShippingInfo"
                  label="Require shipping information"
                ></v-switch>
                <h3 class="mb-1">Item Options:</h3>
                <p>
                  e.g. shirt sizes (S, M, L)
                </p>
                <div
                  v-for="(option, optionIndex) in item.options"
                  :key="option.id"
                  class="d-flex"
                >
                  <v-text-field
                    outlined
                    ref="physicalAwardOption"
                    class="pa-1 mx-2"
                    v-model="option.name"
                    required
                    label="Option Label*"
                    :rules="[v => !!v || 'An option label is required']"
                  ></v-text-field>
                  <v-btn
                    icon
                    class="mt-3"
                    @click.native.stop="
                      deletePhysicalAwardOption(i, optionIndex)
                    "
                    ><v-icon color="red">mdi-trash-can-outline</v-icon></v-btn
                  >
                </div>
                <v-btn text color="primary" @click="addPhysicalAwardOption(i)"
                  >+ Add Option</v-btn
                >
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions class="mt-3">
        <!-- <v-spacer></v-spacer> -->
        <v-btn color="blue darken-1" text @click="closePhysicalAwardForm">
          Cancel
        </v-btn>

        <v-tooltip top :disabled="!physicalAwardValidator" open-delay="200">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                color="blue darken-1"
                text
                @click="savePhysicalAwardForm"
                :disabled="physicalAwardValidator"
              >
                Save
              </v-btn>
            </div>
          </template>
          <span>Saving requires all items have names and SKUs</span>
        </v-tooltip>
      </v-card-actions>
    </v-container>
    <v-container
      v-else-if="awardForm.page == 2"
      class="d-flex flex-column justify-space-between"
    >
      <FulfillmentPartnerManager
        v-if="awardForm.page == 2"
        :fulfillmentPartners="clientFilteredFulfillmentPartners"
        :clientId="award.clientId"
        @get-partners="getAwardFulfillmentPartners"
        @reset="awardForm.page = 1"
      />
      <v-card-actions class="mt-3" v-if="awardForm.page != 2">
        <!-- <v-spacer></v-spacer> -->
        <v-btn color="blue darken-1" text @click="closePhysicalAwardForm">
          Cancel
        </v-btn>

        <v-tooltip top :disabled="!physicalAwardValidator" open-delay="200">
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                color="blue darken-1"
                text
                @click="savePhysicalAwardForm"
                :disabled="physicalAwardValidator"
              >
                Save
              </v-btn>
            </div>
          </template>
          <span
            >Saving requires all items have names, SKUs, and fulfillment
            partners</span
          >
        </v-tooltip>
      </v-card-actions>
    </v-container>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </v-card>
</template>

<script>
import LoadingDialog from "@/components/loadingDialog.vue";
import { mapState } from "vuex";
import BudgetService from "../services/BudgetService.js";
import FulfillmentPartnerManager from "@/components/FulfillmentPartnerManager.vue";

export default {
  name: "AwardCreatorWidget",
  components: {
    LoadingDialog,
    FulfillmentPartnerManager
  },
  props: {
    editingAward: Boolean,
    editAwardObject: Object,
    budgets: Array,
    awardFulfillmentPartners: Array,
    clientId: Number,
    source: String
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingAwardTable: true,
      awardTableSearch: null,
      selected: [],
      awards: [],
      parentBudgets: [],
      type: [
        { label: "Client", value: "CLIENT" },
        { label: "Platform", value: "PLATFORM" },
        { label: "Certification", value: "CERTIFICATION" }
      ],
      context: [
        { label: "Learning", value: "LEARNING" },
        { label: "Programs", value: "PROGRAM" },
        { label: "Other", value: "OTHER" }
      ],
      categories: ["Cash", "Travel", "Physical Award", "Other"],
      awardForm: {
        menuStartDate: false,
        // menuExpirationDate: false,
        // expDateCheckBox: true,
        valid: false,
        page: 0,
        physicalAwardPanel: null,
        fulfillmentPartnerPanel: null
      },
      award: {
        clientId: null,
        awardId: null,
        // awardName: null,
        awardDisplayName: null,
        awardDescription: null,
        // awardValue: null,
        unitSingular: null,
        unitPlural: null,
        category: null,
        // expirationDate: null,
        physicalAwards: [],
        physicalAwardsTemp: []
      }
    };
  },
  mounted() {},
  created() {
    console.log("get awards");
    if (this.editingAward) {
      // We only care about budgets if we're editing an award so we can block them from deleting

      this.award = JSON.parse(JSON.stringify(this.editAwardObject));
      this.getParentBudgets();
    } else if (this.clientId) {
      console.log("Setting clientId to predefined");
      this.award.clientId = this.clientId;
    } else {
      console.log("Setting clientId to user clientId");
      this.award.clientId = this.userProfile.clientId;
    }
  },
  beforeDestroy() {
    //Clears the search in case they go to a page without search
    // this.$store.dispatch("setSearchArray", []);
    // this.unwatch();
  },
  methods: {
    async createNewAward() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Creating a new award";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";

      //set up award object
      var returnObj = {
        awardName: this.award.awardDisplayName,
        awardDisplayName: this.award.awardDisplayName,
        awardDescription: this.award.awardDescription,
        // awardValue: this.award.awardValue,
        unitSingular: this.award.unitSingular,
        unitPlural: this.award.unitPlural,
        category: this.award.category,
        // expirationDate: this.award.expirationDate,
        supplementAwardInfo: {
          physicalItems: this.award.physicalAwards
        }
      };
      if (this.award.clientId !== null) {
        returnObj.clientId = this.award.clientId;
      }

      if (this.source) returnObj.source = this.source;

      console.log("Award create: ", returnObj);
      BudgetService.createAward(returnObj)
        .then(async response => {
          console.log(response);

          if (response && response.awardId) {
            console.log("award created");
            this.showLoadingDialog = false;
            this.resetForm();
            this.$emit("refreshAwards", response.awardId);
          } else {
            console.log("Error creating award");
            this.showLoadingDialog = false;
            this.resetForm();
            this.$emit("refreshAwards", null);
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    async updateAward() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Updating award";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";

      //set up the updated award object
      var returnObj = {
        awardId: this.award.awardId,
        awardName: this.award.awardDisplayName,
        awardDisplayName: this.award.awardDisplayName,
        awardDescription: this.award.awardDescription,
        // awardValue: this.award.awardValue,
        unitSingular: this.award.unitSingular,
        unitPlural: this.award.unitPlural,
        category: this.award.category,
        // expirationDate: this.award.expirationDate,
        supplementAwardInfo: {
          physicalItems: this.award.physicalAwards
        }
      };

      if (this.award.clientId !== null) {
        returnObj.clientId = this.award.clientId;
      }

      console.log("Award update, ", returnObj);
      BudgetService.updateAward(this.award.awardId, returnObj)
        .then(async response => {
          console.log(response);

          if (response) {
            console.log("Award updated");
            console.log(this.editAwardObject);
            this.showLoadingDialog = false;
            this.resetForm();
            this.$emit("refreshAwards", null);
          } else {
            console.log("Error updating award");
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    deleteAward() {
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting award";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      BudgetService.deleteAward(this.award.awardId)
        .then(response => {
          console.log(response);
          this.showLoadingDialog = false;
          if (response) {
            console.log("Award deleted");
            this.resetForm();
            this.$emit("refreshAwards", null);
          } else {
            console.log("Error updating award");
          }
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
        });
    },
    async getParentBudgets() {
      // If parent budgets weren't passed in from the parent, then we fetch them here
      if (!this.budgets || this.budgets.length == 0) {
        var response = await BudgetService.getParentBudgets({
          awardId: this.award.awardId,
          lite: 1
        });
        console.log("Got parent budgets");
        response = response.filter(x => x.status != "Deleted");
        this.parentBudgets = response;
      } else {
        this.parentBudgets = this.budgets;
      }
      //  Used for the award creator component
    },
    resetForm() {
      console.log("closing award form");
      this.$emit("close");
    },
    // checkBoxUpdated() {
    //   if (this.awardForm.expDateCheckBox) {
    //     this.award.expirationDate = null;
    //   }
    // },
    addPhysicalAward() {
      this.award.physicalAwardsTemp.push({
        name: null,
        description: null,
        image: null,
        sku: null,
        awardFulfillmentPartnerId: null,
        requireShippingInfo: false,
        options: []
      });
      this.awardForm.physicalAwardPanel =
        this.award.physicalAwardsTemp.length - 1;
    },
    addPhysicalAwardOption(index) {
      this.award.physicalAwardsTemp[index].options.push({
        name: null,
        id: Math.floor(Math.random() * Date.now())
      });
      // Sets a small timeout before focusing on the new element because Vue needs a second to initialize the component on the DOM
      setTimeout(
        () =>
          this.$refs.physicalAwardOption[
            this.award.physicalAwardsTemp[index].options.length - 1
          ].focus(),
        50
      );
    },
    deletePhysicalAwardOption(awardIndex, optionIndex) {
      this.award.physicalAwardsTemp[awardIndex].options.splice(optionIndex, 1);
    },
    deletePhysicalAward(index) {
      this.award.physicalAwardsTemp.splice(index, 1);
    },
    loadPhysicalAwardForm() {
      this.awardForm.page = 1;
      this.$set(
        this.award,
        "physicalAwardsTemp",
        JSON.parse(JSON.stringify(this.award.physicalAwards))
      );
      if (this.award.physicalAwardsTemp.length > 0) {
        this.awardForm.physicalAwardPanel = 0;
      } else {
        this.awardForm.physicalAwardPanel = null;
      }
    },
    closePhysicalAwardForm() {
      this.awardForm.page = 0;
      this.award.physicalAwardsTemp = [];
    },
    savePhysicalAwardForm() {
      this.awardForm.page = 0;
      this.award.physicalAwards = JSON.parse(
        JSON.stringify(this.award.physicalAwardsTemp)
      );
    },
    loadFulfillmentPartnersWidget() {
      this.displayFulfillmentPartnersWidget = true;
    },
    getAwardFulfillmentPartners() {
      this.$emit("getPartners");
    },
    updatePhysicalItemImage(newVal, index) {
      // This is called when a user updates the image field because Vue doesn't re-render the image preview
      console.log(newVal);
      console.log(index);
      var item = JSON.parse(
        JSON.stringify(this.award.physicalAwardsTemp[index])
      );
      item.image = newVal;

      this.$set(this.award.physicalAwardsTemp, index, item);
    }
  },
  computed: {
    ...mapState(["clients", "userProfile", "permissions"]),
    disableDelete() {
      if (!this.editingAward) return false;
      var currentAward = this.award.awardId;
      var index = this.parentBudgets.findIndex(
        x =>
          x.awardId === currentAward &&
          (this.clientId ? this.clientId : this.userProfile.clientId) ==
            x.clientId
      );
      if (index !== -1 || this.award.clientId === 0) {
        return true;
      } else {
        return false;
      }
    },
    physicalAwardValidator() {
      // Returns true if there is an error
      var error = false;
      for (var i = 0; i < this.award.physicalAwardsTemp.length; i++) {
        var award = this.award.physicalAwardsTemp[i];
        console.log(award);
        if (!award.name || !award.sku || !award.awardFulfillmentPartnerId) {
          error = true;
        }

        for (var j = 0; j < award.options.length; j++) {
          if (!award.options[j].name) {
            error = true;
          }
        }
      }
      return error;
    },
    clientFilteredFulfillmentPartners() {
      if (this.award.clientId) {
        return this.awardFulfillmentPartners.filter(
          x => x.clientId == this.award.clientId
        );
      } else {
        return this.awardFulfillmentPartners.filter(
          x => x.clientId == this.userProfile.clientId
        );
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.outlined {
  border: 1px solid;
  /* border-left: 4px; */
  border-width: 1px 1px 0px 1px;
  border-color: var(--v-grey-base);
}
.outlined-bottom {
  border-width: 1px 1px 1px 1px;
}
</style>

<template>
  <div :class="card.loading ? 'full-height' : ''">
    <div
      v-if="card.loading"
      class="d-flex justify-center align-center full-height"
    >
      <v-progress-circular indeterminate color="brand" size="40" width="3" />
    </div>
    <p class="text-h5 mx-8">{{ card.displayName }}</p>
    <v-img
      v-if="card.displayImage"
      :src="card.contentUrlSigned"
      contain
      width="100%"
      height="auto"
      class="mx-auto mt-4"
    />
    <div v-else-if="card.displayPDF && card.contentUrlSigned">
      <vue-pdf-embed :source="card.contentUrlSigned" />
      <!-- <pdf :src="currentLesson.contentUrlSigned" /> -->
    </div>
    <div
      class="iframeContainer uploadedvideo"
      v-else-if="card.displayVideo"
      @contextmenu="rightClickHandler($event)"
    >
      <video-player
        ref="videoPlayer"
        class=" vjs-custom-skin"
        :playsinline="true"
        :options="videoPlayerOptions"
      />
    </div>
    <div
      class="px-6 d-flex justify-center align-center "
      v-else-if="card.displayVideoProcessing"
    >
      <v-progress-circular
        indeterminate
        color="brandCyan"
        size="35"
        width="3"
        class="mr-3"
      />
      The uploaded video is processing... please check back later!
    </div>
    <div
      class="px-6 d-flex justify-center align-center "
      v-else-if="card.displayVideoFailed"
    >
      <v-icon color="red" class="mr-2">mdi-close</v-icon>
      The video processing failed... please try again or contact Whistle for
      more details
    </div>
    <div
      class="iframeContainer d-flex justify-center"
      v-else-if="card.displayEmbedVideo"
    >
      <iframe
        id="ytplayer"
        width="600"
        height="360"
        :src="card.contentUrlSigned"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div
      v-if="(card.displayVideo || card.displayEmbedVideo) && card.transcript"
      class="px-6"
    >
      <v-expansion-panels multiple flat outline class="expansion-outlined">
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header class="pl-6 pr-6 py-3">
            Video Transcript
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-0 pr-6 pl-0">
            <div style="text-align: left;" v-html="card.transcript"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div
      class="mx-8 pa-0 mt-6 text-left ql-editor"
      v-html="card.content"
      v-if="
        card.content &&
          (!card.type ||
            !['quiz', 'assessment', 'survey', 'learning_resources'].includes(
              card.type
            ))
      "
    />
    <QuizV3
      v-else-if="['quiz', 'assessment', 'survey'].includes(card.type)"
      :questions="card.LearningQuizQuestions"
      :card="card"
      :cardType="card.type"
      @nextCard="loadNextCard"
      @postLearningLog="postLearningLog"
      @lockNextCard="lockQuiz"
      @unlockNextCard="unlockQuiz"
    />
    <div v-if="card.type == 'giving'">
      <v-divider class="mx-3 mt-6" />
      <StripeGivingWidget
        class="mx-auto pt-3 pb-5"
        style="max-width: 450px; min-height: 350px;"
        preloadMenu="CC"
        source="LEARNING_GIVING"
      />
    </div>
    <div v-else-if="card.type == 'learning_resources'" class="mx-8 mt-6">
      <v-data-table
        :headers="[
          {
            text: '',
            align: 'start',
            value: 'icon',
            sortable: false,
            width: '7%'
          },

          {
            text: 'Name',
            value: 'Content.name',
            width: '30%',
            sortable: false
          },
          {
            text: 'Description',
            value: 'Content.description',
            width: '50%',
            sortable: false
          }
        ]"
        :items="!card.LearningAttachments ? [] : card.LearningAttachments"
        item-key="learningModuleAttachmentId"
        :items-per-page="10"
        class="elevation-0 cursor-pointer mt-6 box-border rounded-0 resource-table"
        hide-default-footer
        @click:row="downloadResource"
      >
        <template v-slot:[`item.icon`]="{ item }">
          <v-progress-circular
            indeterminate
            color="brand"
            size="23"
            width="2"
            v-if="downloadResources.includes(item.learningModuleAttachmentId)"
          />
          <v-icon v-else color="brand" @click="downloadResource(item)"
            >mdi-download</v-icon
          >
        </template>
        <template v-slot:[`item.fileType`]="{ item }">
          <v-icon color="red" x-large v-if="item.Content.fileType === 'PDF'"
            >mdi-file-pdf-box</v-icon
          >
          <v-icon
            color="blue"
            x-large
            v-else-if="item.Content.fileType === 'DOCX'"
            >mdi-file-word</v-icon
          >
          <v-icon
            color="green"
            x-large
            v-else-if="item.Content.fileType === 'XLSX'"
            >mdi-file-excel</v-icon
          >
          <v-icon
            color="red"
            x-large
            v-else-if="item.Content.fileType === 'PPTX'"
            >mdi-file-powerpoint</v-icon
          >
          <v-icon
            color="blue"
            x-large
            v-else-if="item.Content.fileType === 'PNG'"
            >mdi-file-image</v-icon
          >
          <v-icon
            color="green"
            x-large
            v-else-if="item.Content.fileType === 'CSV'"
            >mdi-file-delimited</v-icon
          >
          <v-icon color="blue" x-large v-else>mdi-file</v-icon>
        </template>
        <template v-slot:[`item.Content.name`]="{ item }">
          <div class="d-flex flex-column my-3">
            <b>{{ item.Content.name }}</b>
            <span class="mediumGrey--text"
              >{{ item.Content.fileExtension }} File |
              {{ item.Content.fileSize }}</span
            >
          </div>
        </template>
        <template v-slot:no-data>
          <span>No documents attached to this Learning Module</span>
        </template>
      </v-data-table>
    </div>
    <!-- <div
      v-if="
        card.type &&
          !['quiz', 'assessment', 'survey'].includes(card.type) &&
          card.calculateProgress != false
      "
      class="d-flex justify-center mt-8"
    >
      <div class="box-border d-flex align-center px-3 py-1 learner-rating">
        <span class="mr-3">
          How would you rate this content?
        </span>
        <div v-if="card.learnerRating" class="d-flex">
          <div v-for="score in card.learnerRating" :key="score">
            <v-icon color="yellow" @click="updateLearnerRating(score, false)"
              >mdi-star</v-icon
            >
          </div>
        </div>
        <div
          v-for="score in 5 - (card.learnerRating ? card.learnerRating : 0)"
          :key="'A' + score"
        >
          <v-icon color="grey" @click="updateLearnerRating(score, true)"
            >mdi-star</v-icon
          >
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
// import moment from "moment";

import ContentService from "@/services/ContentService";

import StripeGivingWidget from "@/components/stripe/StripeGivingWidget";
import QuizV3 from "@/components/QuizV3";

import { formatFileType } from "@/shared_data/functions.js";

export default {
  name: "ProgramDetailsLearningCard",
  props: {
    nextCard: Object,
    currentCard: Object,
    previousCard: Object
  },
  components: { QuizV3, VuePdfEmbed, StripeGivingWidget },
  data() {
    return {
      selectedAnswer: null,
      answerKey: null,
      chapterList: null,
      currentQuestion: null,
      lowerBound: 1,
      upperBound: 5,
      value: null,
      selectedRadio: false,
      column: 5,
      downloadResources: []
    };
  },
  created() {
    // if (!this.card.learnerRating) this.card.learnerRating = 1;
    this.refreshCard();
  },
  updated() {},
  mounted() {},

  beforeMount() {},
  methods: {
    rightClickHandler: function(e) {
      e.preventDefault();
    },
    goToNextQuestion() {
      // If we're not on the last question, move on and reset the flags for answering a question
      if (this.questionIndex !== this.questionCount - 1) {
        this.value = null;
        this.selectedRadio = false;
        this.$emit("next-question", true);
      }
    },
    completeQuiz() {
      // Emits a notification so the quiz can submit
      this.$emit("complete-quiz", true);
    },
    lockQuiz() {
      this.$emit("lockQuiz");
    },
    unlockQuiz() {
      this.$emit("unlockQuiz");
    },
    updateLearnerRating(starIndex, emptyStar) {
      console.log(starIndex);
      console.log(emptyStar);
      var oldScore = this.card.learnerRating || 0;
      if (emptyStar) {
        // We are increasing our learner rating because they clicked an empty star
        var newScore = (oldScore += starIndex);
        console.log(
          "Storing new learner confidence: old - " +
            oldScore +
            ", new - " +
            newScore
        );
      } else {
        // We are decreasing score since they clicked a filled star
        if (oldScore == starIndex) {
          // They clicked their current rating so we wipe it
          newScore = 0;
        } else {
          newScore = starIndex;
        }
        console.log(
          "Updating learner confidence: old - " +
            oldScore +
            ", new - " +
            newScore
        );
      }
      this.card.learnerRating = newScore;
    },
    loadNextCard(postLog) {
      this.$emit("nextCard", postLog);
    },
    postLearningLog(quizScore = null, userAnswers = null) {
      this.$emit("postLearningLog", quizScore, userAnswers);
      // We have to figure out if this is the last card for the module
      // var payoutFlag = false;
      // if (this.modulePercentComplete == 1 && this.payoutFlagCounter == 0) {
      //   console.log("POSTING LOG FOR FINAL CARD");
      //   payoutFlag = true;
      //   this.payoutFlagCounter++;
      // }

      // var body = {
      //   userId: this.userProfile.userId,
      //   clientId: this.userProfile.clientId,
      //   learningCardId: this.card.learningCardId,
      //   learningCardAssociationId: this.card.learningCardAssociationId,
      //   completed:
      //     // If quiz score isn't set, OR they didn't define both a passing score and set passing required to true, we auto set completed to true
      //     quizScore == null ||
      //     !(this.card.passingRequired && this.card.passingScore)
      //       ? true
      //       : this.card.passingRequired &&
      //         this.card.passingScore &&
      //         quizScore &&
      //         quizScore >= this.card.passingScore
      //       ? true
      //       : false,
      //   completedDate: moment()
      //     .utc()
      //     .format("YYYY-MM-DD HH:mm:ssZ"),
      //   learnerRating: this.card.learnerRating ? this.card.learnerRating : null,
      //   learnerConfidence: this.card.learnerConfidence
      //     ? this.card.learnerConfidence
      //     : null,
      //   score: quizScore,
      // };
      // if (userAnswers) {
      //   body.answersGiven = userAnswers.join(",");
      // }
      // console.log("-------------this is the body-------------");
      // console.log(body);
      // LearningService.createLearningLog(body, payoutFlag);
      // this.card.latestLearningLog = body;
      // if (body.completed) {
      //   this.card.progressPercent = 100;
      //   // this.nextCard.locked = false;
      // }
      // Now we add this score to the course object
      // this.course.LearningChapters[this.currentChapterIndex].LearningCards[
      //   this.currentLessonIndex
      // ].latestLearningLog = body;
      // // We add the learner rating and confidence at the card level too
      // this.course.LearningChapters[this.currentChapterIndex].LearningCards[
      //   this.currentLessonIndex
      // ].learnerRating = this.currentLesson.learnerRating
      //   ? this.currentLesson.learnerRating
      //   : null;
      // this.course.LearningChapters[this.currentChapterIndex].LearningCards[
      //   this.currentLessonIndex
      // ].learnerConfidence = this.currentLesson.learnerConfidence
      //   ? this.currentLesson.learnerConfidence
      //   : null;
      // var currentLesson = JSON.parse(JSON.stringify(this.currentLesson));
      // currentLesson.latestLearningLog = body;
      // this.currentLesson = currentLesson;
    },
    async downloadResource(item) {
      console.log(item);
      this.downloadResources.push(item.learningModuleAttachmentId);
      await ContentService.downloadContent(
        item.contentId,
        item.Content.filePath
      );
      let index = this.downloadResources.findIndex(
        x => x == item.learningModuleAttachmentId
      );
      if (index != -1) this.downloadResources.splice(index, 1);
    },
    refreshCard() {
      // This gets run in place of created() because sometimes the component doesn't get refreshed / re-rendered if we switch from learning card to learning card
      if (
        this.card &&
        this.card.LearningAttachments &&
        this.card.LearningAttachments.length
      ) {
        this.card.LearningAttachments.map(x => {
          var filepath = x.Content.filePath;
          x.Content.fileType = formatFileType(filepath);
          x.Content.fileExtension =
            filepath.lastIndexOf(".") != -1
              ? filepath.toUpperCase().substring(filepath.lastIndexOf(".") + 1)
              : "Unknown";
        });
      }
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    card: {
      get: function() {
        return this.currentCard;
      },
      set: function(newVal) {
        this.$emit("update-card", newVal);
      }
    },
    videoPlayerOptions() {
      return {
        height: 400,

        autoplay: false,
        controls: true,
        techOrder: ["html5"],
        sourceOrder: true,
        html5: { hls: { withCredentials: false } },
        sources: [
          {
            withCredentials: false,
            type: "video/mp4",
            src: this.card
              ? this.card.contentUrlSigned
                ? this.card.contentUrlSigned
                : null
              : null
          }
        ]
      };
    }
  },
  watch: {
    currentCard: function() {
      this.refreshCard();
    }
  }
};
</script>

<style scoped>
p {
  font-size: 12px;
}

/* Used for the border around the learner rating */
.box-border {
  border: solid 1px;
  border-color: lightgray;
}

/* Shrinks text of learner box */
.learner-rating {
  font-size: 14px;
}

.resource-table >>> .v-data-table-header {
  background-color: white;
}

.expansion-outlined {
  border: 1px solid #ccc;
  margin-top: 20px;
}

.expansion-outlined .v-expansion-panel--active {
  background: rgba(0, 0, 0, 0.04);
}
</style>

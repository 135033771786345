<template>
  <v-card elevation="0" rounded="0">
    <MarqetaActivateCard :token="token" />
  </v-card>
</template>

<script>
import MarqetaActivateCard from "@/components/wallet/MarqetaActivateCard.vue";

export default {
  name: "MarqetaActivateCardHosted",

  props: {},
  components: {
    MarqetaActivateCard
  },
  data() {
    return {
      token: null
    };
  },
  created() {
    this.token = this.$route.query.token;

    window.divNode = window.document.createElement("div");
    window.divNode.setAttribute("id", "tempFreshworks");
    window.divNode.innerHTML =
      "<style>#launcher-frame { right: -105px !important; }</style>";
    window.document.body.appendChild(window.divNode);
  },
  destroyed() {
    window.divNode.parentNode.removeChild(window.divNode);
  },
  updated() {},

  methods: {},
  computed: {}
};
</script>

<style scoped></style>

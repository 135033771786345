var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex full-width align-center",class:{
      'justify-start': _vm.align == 'start',
      'justify-center': _vm.align == 'center'
    }},[(_vm.progressPercent == 100)?_c('v-icon',{staticClass:"mr-4",attrs:{"color":"brandGreen"}},[_vm._v("mdi-check-circle")]):(_vm.renderClock || _vm.displayClock)?_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.displayClock},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",class:{
            hidden: !_vm.displayClock
          },attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-clock-time-four")])]}}])},[(_vm.status == 'Active')?_c('span',[_vm._v("Days remaining: "),_c('b',[_vm._v(_vm._s(_vm.computedDaysRemaining))])]):_vm._e()]):_vm._e(),_c('v-progress-linear',{staticClass:"bar",attrs:{"value":_vm.progressPercent,"color":!_vm.displayClock ? 'brandGreen' : 'error',"height":"22"}}),_c('v-img',{staticClass:"ml-4",class:{
        hidden: !_vm.badgeImageUrl
      },attrs:{"src":_vm.badgeImageUrl,"height":"35","width":"35","min-height":"35","contain":"","max-width":"35"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="my-2 ml-3 mr-2">
    <v-card flat class="my-2 ml-3 mr-2">
      <v-row>
        <v-col sm="12" md="3">
          <h2 class="align-text-left mb-3">Integrations Manager</h2>
          <v-divider></v-divider>
          <div class="align-text-left mt-3">
            <p>
              Whistle can integrate with your HRIS provider to automate user
              onboarding and offboarding, and sync census data and user changes.
            </p>

            <p>
              We utilize Finch to securely connect to your provider and access
              employee census data. You can add or remove these provider
              connections at any time.
            </p>
          </div>
        </v-col>
        <v-col sm="12" md="9">
          <!-- v-model="selected"
            :headers="columns"
            :items="hierarchies"
            item-key="hierarchyId"
            show-select
            :items-per-page="10"
            class="elevation-0 row-pointer hierarchy-table"
            :loading="loadingHierarchyTable"
            loading-text="Loading... Please wait"
            :search="tableSearch"
            @click:row="editHierarchy" -->
          <div class="d-flex justify-end pb-2">
            <v-btn
              @click="finchConnect"
              color="brandCyan"
              depressed
              class="white--text"
              >Connect HRIS</v-btn
            >
          </div>
          <v-data-table
            :loading="loading"
            :key="state"
            :items="providers"
            :headers="columns"
            no-data-text="No integration connections found"
            class="table"
          >
            <template v-slot:item.remove="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="doFinchDisconnect(item)"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close-thick</v-icon>
                  </v-btn>
                </template>
                <span>Disconnect</span>
              </v-tooltip>
            </template>
            <template v-slot:item.refresh="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="finchResync(item)"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Re-Sync Users</span>
              </v-tooltip>
            </template>
            <!--  -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
// import RobinService from "@/services/RobinService";
import LoadingDialog from "@/components/loadingDialog.vue";

import { mapState } from "vuex";
import PROVIDERS from "./fixtures/providers";
export default {
  name: "IntegrationsManager",
  props: {
    finchConnect: Function,
    getProviders: Function,
    finchDisconnect: Function,
    alert: Function,
    finchResync: Function
  },
  components: {
    LoadingDialog
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loading: true,
      state: 0,

      providers: [],
      integrations: PROVIDERS.map(p => ({
        ...p,
        username: "webmaster@gabedev.tech"
      })),
      columns: [
        {
          text: "Provider",
          align: "start",
          // sortable: true,
          value: "provider.display_name"
          // width: "70%",
        },

        { text: "Refresh", value: "refresh", width: "7%", sortable: false },
        { text: "Remove", value: "remove", width: "7%", sortable: false }

        // {
        //   text: "Status",
        //   value: "statusInitial",
        //   align: "center",
        //   width: "12%",
        // },
        // {
        //   text: "Quantity",
        //   value: "HierarchyRelationships.length",
        //   width: "15%",
        // },
      ]
    };
  },
  created() {
    // this.getRecommendationTypes();
    this.clientId = this.userProfile.clientId;
    this.doGetProviders();
  },
  beforeDestroy() {},
  methods: {
    // async finchConnected(result) {

    // },
    async doFinchDisconnect(item) {
      this.alert({
        title: "HRIS Connection",
        body: `Are you sure you want to remove ${item.provider.display_name}?`,
        okColor: "red",
        okAction: () => {
          this.continueFinchDisconnect(item);
        },
        cancellable: true
      });
    },
    async continueFinchDisconnect(item) {
      const disconnected = await this.finchDisconnect(item);
      if (disconnected) {
        this.providers.splice(
          this.providers.findIndex(
            i => i.clientSupplementalId === item.clientSupplementalId
          ),
          1
        );
        this.alert({
          title: "HRIS Connection",
          body: "Disconnection succesful."
        });
      } else {
        this.alert({
          title: "HRIS Connection",
          body: "Unable to disconnect. Please try again later."
        });
      }
    },
    doGetProviders() {
      this.getProviders()
        .then(providers => {
          this.providers = providers.filter(p => !p.provider.error);
          console.log("providers:", this.providers);
        })
        .finally(() => {
          this.state = this.state + (1 % 1024);
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "token"]),
    Providers() {
      if (!this.providers) {
        this.doGetProviders();
      }
      return this.providers;
    }
  }
};
</script>

<style scoped>
.client-dropdown {
  width: 350px;
}
</style>
<style lang="css" scoped>
.table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.table >>> .v-data-table-header {
  background-color: white;
}
</style>

import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_COMMUNICATIONS_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-communications"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE
        ? "/microservice-communications"
        : "/communications-service"
    }`;

class CommunicationService {
  uploadImage(image) {
    var formData = new FormData();
    formData.append("image", image);
    return axios
      .post(baseUrl + "/message/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  duplicateImage(imageId) {
    return axios
      .post(baseUrl + "/message/image/duplicate/" + imageId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  deleteImage(imageURL) {
    return axios
      .delete(baseUrl + "/message/image/" + imageURL)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getMessagesByContext(context) {
    return axios
      .get(baseUrl + "/messages/byContext/" + context)
      .then(response => {
        return response.data.message;
      })
      .catch(error => {
        console.log("Error!", error.response);
        throw error;
      });
  }
  getMessage(id) {
    return axios
      .get(baseUrl + "/messages/" + id)
      .then(response => {
        return response.data.message;
      })
      .catch(error => {
        console.log("Error!", error.response);
        throw error;
      });
  }
  getMessagesForAdmin() {
    return axios
      .get(baseUrl + "/messages/admin")
      .then(response => {
        return response.data.message;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateMessage(messageId, message) {
    return axios
      .patch(baseUrl + "/message/" + messageId, message)
      .then(response => {
        return response.data.message;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  deleteMessage(messageId) {
    return axios
      .delete(baseUrl + "/message/" + messageId)
      .then(response => {
        return response.data.message;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  sendMessageCRON() {
    console.log("Running CRON");
    return axios
      .get(baseUrl + "/messages/cron/send")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateEmailActivityCRON() {
    console.log("Running Email Activity CRON");
    return axios
      .get(baseUrl + "/messages/cron/activity")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateEmailBounceCRON() {
    console.log("Running Email Bounce CRON");
    return axios
      .get(baseUrl + "/messages/cron/bounces")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  updateEmailBlockCRON() {
    console.log("Running Email Blocks CRON");
    return axios
      .get(baseUrl + "/messages/cron/blocks")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  updateEmailSpamCRON() {
    console.log("Running Email Spam CRON");
    return axios
      .get(baseUrl + "/messages/cron/spam")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }

  // V2 Methods
  getMessagesV2(params, filterString, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/message/activity/" + filterString, { params: params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("error ", error.response);
        throw error.response;
      });
  }
  getUnreadMessagesV2(magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/message/activity/unread", {})
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("error ", error.response);
        throw error.response;
      });
  }
  sendMessageV2(message) {
    return axios
      .post(baseUrl + "/v2/message-definition", message)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error sending message ", error);
        throw error;
      });
  } // sendMessageV2
  updateMessageV2(id, message) {
    return axios
      .patch(baseUrl + "/v2/message-definition/" + id, message)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating message ", error);
        throw error;
      });
  } // updateMessageV2
  getMessageDefinitionsV2(params, messageId) {
    return axios
      .get(
        baseUrl + "/v2/message-definition" + (messageId ? `/${messageId}` : ``),
        { params }
      )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error getting admin messages ", error.response);
        throw error.response;
      });
  }
  updateMessageLogV2(id, body) {
    return axios
      .patch(`${baseUrl}/v2/message-log/${id}`, body)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating message ", error);
        throw error;
      });
  }
  createMessageComment(message, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/v2/message-comment", message)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error creating comment ", error);
        throw error;
      });
  }
  deleteMessageComment(commentId, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .delete(`${baseUrl}/v2/message-comment/${commentId}`)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error deleting comment ", error);
        throw error;
      });
  }
  createMessageReaction(message, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(baseUrl + "/v2/message-reaction", message)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error creating reaction ", error);
        throw error;
      });
  }
  removeMessageReaction(id, message, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(`${baseUrl}/v2/message-reaction/remove/${id}`, message)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error removing reaction ", error);
        throw error;
      });
  }
  getPopupMessages(params, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .get(baseUrl + "/v2/message/activity/popup", { params })
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("error getting popup messages ", error.response);
        throw error.response;
      });
  }
  updatePopupMessageAsRead(id, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .patch(`${baseUrl}/v2/message-log/popup/read/${id}`)
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating popup as read ", error);
        throw error;
      });
  }
  updateMessageLogAsReadV2(messageLogId, timestamp, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .patch(`${baseUrl}/v2/message-log/read`, { messageLogId, timestamp })
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error updating messages as read ", error);
        throw error;
      });
  }
  sendSMSInviteText(userId) {
    return axios
      .post(
        `${baseUrl}/v2/message-definition/onboarding/sms`,
        { userId },
        { params: { override: 1 } }
      )
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error sending sms invite ", error);
        throw error;
      });
  } // sendSMSInviteText

  previewMessage(method, body, deliverMessage = false) {
    return axios
      .post(
        `${baseUrl}/v2/message-definition/preview/${
          deliverMessage ? "deliver/" : ""
        }${method}`,
        body
        // { params: { override: 1 } }
      )
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error previewing message ", error);
        throw error;
      });
  } // previewMessage

  getMessageAnalytics(messageId, params = {}) {
    return axios
      .get(`${baseUrl}/v2/message-definition/analytics/${messageId}`, {
        params
      })
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error getting message analytics ", error);
        throw error;
      });
  } // getMessageAnalytics

  deleteMessageV2(messageId, params = {}) {
    return axios
      .delete(`${baseUrl}/v2/message-definition/${messageId}`, {
        params
      })
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log("Error deleting message ", error);
        throw error;
      });
  } // deleteMessageV2
}

export default new CommunicationService();

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-start ml-2",class:{ hidden: _vm.currentSlide > 2 },attrs:{"sm":"6","md":"6"}},[_c('v-btn',{staticClass:"pa-2 mb-1 ml-1",attrs:{"fab":"","dark":"","small":"","elevation":"0"},on:{"click":_vm.previousSlide}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('span',[_vm._v("Back")])],1)],1)],1),_c('transition',{attrs:{"name":_vm.slideDirection,"mode":"out-in"}},[(_vm.currentSlide == 0)?_c('v-row',{staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Loading your info...")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" This should only take a few seconds. ")]),_c('Robin',{attrs:{"showText":false,"width":140,"height":140,"animation":"flying","loop":true}})],1):_vm._e(),(_vm.currentSlide == 1)?_c('v-row',{key:"0",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Let's update some information")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" What email and phone number should be used? ")]),_c('v-form',{ref:"form",staticClass:"full-width px-4",model:{value:(_vm.validNameForm),callback:function ($$v) {_vm.validNameForm=$$v},expression:"validNameForm"}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Email*","rules":[
            v => !!v || 'Email is required',
            v => !!(v && v.length < 255) || 'Email should be shorter',
            v =>
              /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(v) ||
              'Email must be valid'
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('vue-tel-input-vuetify',{staticClass:"mt-4",attrs:{"dense":"","value":_vm.phone,"label":"Phone number (xxx-xxx-xxxx)","placeholder":"xxx-xxx-xxxx","preferredCountries":['US'],"defaultCountry":_vm.countryCode.code,"disabledFetchingCountry":true},on:{"country-changed":_vm.countryCodeChanged,"validate":_vm.phoneValidation},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1):(_vm.currentSlide == 2)?_c('v-row',{key:"2",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("We need to make sure we have all the details right")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" Let's get your address ")]),_c('v-form',{ref:"form",staticClass:"full-width px-4",model:{value:(_vm.validAddressForm),callback:function ($$v) {_vm.validAddressForm=$$v},expression:"validAddressForm"}},[_c('v-text-field',{ref:"address1",staticClass:"mt-4",attrs:{"label":"Address","required":"","rules":[
            v => !!v || 'Address is required',
            v => !!(v && v.length < 256) || 'Address should be shorter'
          ]},model:{value:(_vm.address1),callback:function ($$v) {_vm.address1=$$v},expression:"address1"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Apt, suite, unit, etc."},model:{value:(_vm.address2),callback:function ($$v) {_vm.address2=$$v},expression:"address2"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"City","required":"","rules":[
            v => !!v || 'City is required',
            v => !!(v && v.length < 40) || 'City should be shorter'
          ]},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),(!_vm.country || _vm.country == 'US')?_c('v-select',{attrs:{"label":"State","items":_vm.states,"item-value":"abbreviation","item-text":"name","rules":[v => !!v || 'State is required']},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}):_vm._e(),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Zip Code","required":"","rules":[
            v => !!v || 'Zip code is required',
            v => !!(v && v.length < 10) || 'Zip code should be shorter'
          ]},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}}),_c('v-select',{attrs:{"label":"Country","items":_vm.allCountries,"item-value":"code","item-text":"name","rules":[v => !!v || 'Country is required']},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1):(_vm.currentSlide == 3)?_c('v-row',{key:"3",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Processing...")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" This should only take a few seconds. ")]),_c('Robin',{attrs:{"showText":false,"width":140,"height":140,"animation":"flying","loop":true}})],1):(_vm.currentSlide == 4)?_c('v-row',{key:"4",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("ALL DONE!")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" Your information has been updated! ")]),_c('Robin',{attrs:{"showText":false,"width":140,"height":140,"animation":"backflip","loop":false}})],1):_vm._e()],1),(_vm.currentSlide != 3 && _vm.currentSlide != 0)?_c('v-row',{staticClass:"mb-10",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-btn',{staticClass:"py-5",attrs:{"outlined":"","rounded":"","disabled":(_vm.currentSlide == 1 && (!_vm.validNameForm || !_vm.phoneNumberValid)) ||
            (_vm.currentSlide == 2 && !_vm.validAddressForm)},on:{"click":_vm.nextSlide}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"brandGreen"}},[_vm._v("mdi-arrow-right-drop-circle")]),_c('span',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.currentSlide != 4 ? "Next" : "Done"))])],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogEmailError),callback:function ($$v) {_vm.dialogEmailError=$$v},expression:"dialogEmailError"}},[_c('v-card',{staticClass:"d-flex justify-center flex-column pa-6",attrs:{"rounded":"0"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mx-2 mb-5"},[_c('v-icon',{staticClass:"exit-warning-icon mr-4",attrs:{"color":"error","x-large":""}},[_vm._v("mdi-alert")]),_c('v-card-title',{staticClass:"word-break align-text-left exit-warning-text"},[_vm._v(" The email you submitted is already in use. Please try a different email. ")])],1),_c('v-card-actions',{staticClass:"mx-12"},[_c('v-btn',{staticClass:"mx-auto",attrs:{"color":"primary","width":"130"},on:{"click":function($event){(_vm.currentSlide = 1), (_vm.dialogEmailError = false)}}},[_vm._v("Edit Email")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="full-width">
    <v-toolbar
      dark
      color="primary"
      rounded="0"
      v-if="!givingMode && source == 'WALLET_GIVING'"
    >
      <v-toolbar-title>Donations</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="reset(false)">
          Close
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div v-if="!givingMode" class="text-left px-5">
      <h3 class="mb-4 mt-3">
        Uh oh, we can't load the donation page because your company is not set
        up for it.
      </h3>
      <p class="full-width align-text-left">
        Please reach out to Whistle customer service via one of the following
        methods if this is incorrect.
      </p>
      <p class="align-text-left full-width">
        Email -
        <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
      </p>
      <p class="align-text-left full-width">
        Phone (Toll Free) - <b>(855) 264-3329</b>
      </p>
    </div>
    <StripeManageCC
      v-else-if="screen == 'checkout'"
      @reset="reset()"
      :fundingLimit="undefined"
      :source="source"
      :givingMode="true"
      :displayHeaders="source == 'WALLET_GIVING'"
    />
  </div>
</template>

<script>
import BudgetService from "@/services/BudgetService.js";

import StripeManageCC from "@/components/stripe/StripeManageCC.vue";

import { mapState } from "vuex";

export default {
  name: "StripeGivingManager",
  props: {
    preloadMenu: String,
    clientId: Number,
    fundingLimit: Object,
    source: String
  },
  components: {
    StripeManageCC
  },
  data() {
    return {
      screen: "home",
      budgetFundingLoading: false,
      showBudgetFundMenu: false,
      amount: null,
      fundForm: false,
      fundingArray: []
    };
  },
  created() {
    if (this.preloadMenu) {
      if (this.preloadMenu == "ACH") this.screen = "ach";
      else if (this.preloadMenu == "CC") this.screen = "checkout";
    }
    // if (!this.fundingLimit) {
    //   this.getBudgetFundingStatuses();
    // }
  },
  beforeDestroy() {},
  methods: {
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },
    reset(success = false) {
      this.$emit("reset", success);
    },
    getBudgetFundingStatuses() {
      // this is used to get the client's balance on how much they have funded if they're using Whistle's KYB status
      // So we can cap them at 1,000
      this.loadingBudgetTable = true;
      BudgetService.getBudgetFundingStatuses()
        .then(response => {
          console.log("KYB funding response ", response);

          this.fundingArray = response;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "permissions",
      "walletMenuScreen",
      "displayWalletMenu"
    ]),
    givingMode() {
      // Indicates if the client is set up to support Giving cards
      return (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom1 == "GIVING"
      );
    },
    walletScreen: {
      get: function() {
        return this.walletMenuScreen;
      },
      set: function(newValue) {
        this.$store.dispatch("setWalletMenuScreen", newValue);
      }
    }
    // computedFundingLimit() {
    //   if (this.fundingLimit) return this.fundingLimit;

    //   return this.fundingArray.find(
    //     x =>
    //       x.clientId ==
    //       (this.clientId ? this.clientId : this.userProfile.clientId)
    //   );
    // }
  },
  watch: {}
};
</script>

<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}
.ach-background {
  background: #80e5ad;
}
.cc-background {
  background: #977de0;
}
.wire-background {
  background: #294b89;
}
.budget-transfer-background {
  background: #75d5e4;
}
.wallet-icon {
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}
.error-background {
  background: var(--v-error-base);
}
.white-background {
  background: white;
  background-color: white;
  transform: scale(2);
}
.per-diem-alert {
  border-radius: 50px;
  width: 31px;
  height: 29px;
}
</style>

<template>
  <v-container
    fluid
    class="full-height max-width pa-0"
    v-if="hierarchy !== null"
  >
    <div class="d-flex justify-end" :style="{ width: '100%' }">
      <div class="px-4 ml-7 d-flex align-baseline" v-if="showVisibility">
        <p class="pr-2">Rule visibility:</p>
        <v-select
          class="pt-0 mt-0"
          hide-details
          :items="visibilityOptions"
          item-text="label"
          item-value="value"
          label="Rule visibility"
          single-line
          :style="{ width: '200px' }"
          v-model="visibilityOption"
        ></v-select>
      </div>
      <div
        class="d-flex justify-end mr-4 mb-4"
        v-if="showExitButton || showSaveButton"
      >
        <v-btn text color="primary" @click="closeDialog" v-if="showExitButton"
          ><v-icon class="mr-2">mdi-close</v-icon>Exit</v-btn
        >
        <v-btn
          text
          color="primary"
          @click="saveRules"
          v-if="showSaveButton"
          :disabled="disableSaveButton"
          ><v-icon class="mr-2">mdi-content-save</v-icon>Save</v-btn
        >
      </div>
    </div>
    <div class="d-flex align-center mb-4 mx-3" v-if="!hideTextFields">
      <v-text-field
        outlined
        hide-details
        class="pa-1"
        ref="nameInput"
        v-model="ruleName"
        :disabled="readOnly"
        :label="
          context == 'Programs'
            ? 'New qualifying rule name'
            : 'New complex group rule name'
        "
      ></v-text-field>
      <v-icon
        dark
        tabindex="-1"
        @click="focusOnName"
        class="circle-border-radius pa-2 ml-4 cursor-pointer"
        :class="{
          'accent-background': ruleName,
          'grey-background': !ruleName
        }"
        >mdi-pencil</v-icon
      >
    </div>
    <div class="d-flex align-center mb-4 mx-3" v-if="!hideTextFields">
      <v-textarea
        outlined
        hide-details
        rows="3"
        class="pa-1"
        ref="descriptionInput"
        :disabled="readOnly"
        v-model="ruleDescription"
        :label="
          context == 'Programs'
            ? 'A brief description of how to participate in this program'
            : 'A brief description of who is in this complex group'
        "
      ></v-textarea>
      <v-icon
        dark
        tabindex="-1"
        @click="focusOnDescription"
        class="circle-border-radius pa-2 ml-4 cursor-pointer"
        :class="{
          'accent-background': ruleDescription,
          'grey-background': !ruleDescription
        }"
        >mdi-pencil</v-icon
      >
    </div>
    <RuleComponentV2
      :item="hierarchy"
      :logicalOperators="logicalOperators"
      :contextOptions="contextOptions"
      :contexts="contextArray"
      :operators="operators"
      :context="context"
      :hideChip="true"
      :readOnly="readOnly"
      :clientId="clientId"
      :includeSubClients="includeSubClients"
    ></RuleComponentV2>
    <!-- <p>{{ hierarchy }}</p> -->
    <span
      >Estimated Participants:
      <b>{{ estimatedParticipants === null ? "-" : estimatedParticipants }}</b>
      <v-tooltip top
        ><template v-slot:activator="{ on, attrs }"
          ><v-btn
            small
            depressed
            icon
            :loading="loadingEstimatedParticipants"
            :disabled="disableEstimatedParticipants"
            class="ml-2 mb-1"
            v-on="on"
            v-bind="attrs"
            @click="refreshEstimate"
            ><v-icon>mdi-refresh</v-icon></v-btn
          ></template
        ><span>Refresh estimate</span></v-tooltip
      >
    </span>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import RuleComponentV2 from "./RuleComponentV2.vue";

export default {
  name: "RuleBuilder",
  components: { RuleComponentV2 },
  props: {
    useMetrics: Boolean,
    useUserAttributes: Boolean,
    showSaveButton: Boolean,
    showExitButton: Boolean,
    readOnly: Boolean,
    trigger: Boolean,
    rules: Object,
    metrics: Array,
    modules: Array,
    name: String,
    hideTextFields: {
      type: Boolean,
      default: false
    },
    description: String,
    estimatedParticipants: Number,
    loadingEstimatedParticipants: {
      type: Boolean,
      default: false
    },
    disableEstimatedParticipants: {
      type: Boolean,
      default: false
    },
    context: String,
    clientId: Number,
    includeSubClients: Boolean,
    showVisibility: Boolean,
    visibility: String
  },
  data() {
    return {
      userAttributes: [
        {
          id: "q",
          context: "Users",
          contextResourceId: "q",
          contextColumn: "clientId",
          label: "Client",
          dataType: "SELECTOR"
        },
        {
          id: "a",
          context: "Users",
          contextResourceId: "a",
          contextColumn: "status",
          label: "Status",
          dataType: "SELECTOR"
        },
        {
          id: "b",
          context: "Users",
          contextResourceId: "b",
          contextColumn: "jobTitle",
          label: "Job Title",
          dataType: "SELECTOR"
        },
        {
          id: "j",
          context: "Users",
          contextResourceId: "j",
          contextColumn: "jobStartDate",
          label: "Days Since Beginning Job",
          dataType: "INTEGER"
        },
        {
          id: "k",
          context: "Users",
          contextResourceId: "k",
          contextColumn: "roleStartDate",
          label: "Days Since Beginning Current Role",
          dataType: "INTEGER"
        },
        {
          id: "c",
          context: "Users",
          contextResourceId: "c",
          contextColumn: "businessCity",
          label: "Work City",
          dataType: "SELECTOR"
        },
        {
          id: "d",
          context: "Users",
          contextResourceId: "d",
          contextColumn: "businessState",
          label: "Work State",
          dataType: "SELECTOR"
        },
        {
          id: "e",
          context: "Users",
          contextResourceId: "e",
          contextColumn: "businessCountry",
          label: "Work Country",
          dataType: "SELECTOR"
        },
        {
          id: "f",
          context: "Users",
          contextResourceId: "f",
          contextColumn: "homeCity",
          label: "Home City",
          dataType: "SELECTOR"
        },
        {
          id: "g",
          context: "Users",
          contextResourceId: "g",
          contextColumn: "homeState",
          label: "Home State",
          dataType: "SELECTOR"
        },
        {
          id: "h",
          context: "Users",
          contextResourceId: "h",
          contextColumn: "homeCountry",
          label: "Home Country",
          dataType: "SELECTOR"
        },
        {
          id: "i",
          context: "Users",
          contextResourceId: "i",
          contextColumn: "organization",
          label: "Organization",
          dataType: "SELECTOR"
        },
        {
          id: "m",
          context: "Users",
          contextResourceId: "m",
          contextColumn: "token1",
          label: "Custom Field 1",
          dataType: "SELECTOR"
        },
        {
          id: "n",
          context: "Users",
          contextResourceId: "n",
          contextColumn: "token2",
          label: "Custom Field 2",
          dataType: "SELECTOR"
        },
        {
          id: "o",
          context: "Users",
          contextResourceId: "o",
          contextColumn: "token3",
          label: "Custom Field 3",
          dataType: "SELECTOR"
        }
      ],
      logicalOperators: [
        { label: "All", value: "AND" },
        { label: "Any", value: "OR" }
      ],
      operators: [
        { label: "Is", value: "EQUAL", number: false },
        { label: "Is not", value: "NOT_EQUAL", number: false },
        { label: "Is more than", value: "GREATER_THAN", number: true },
        {
          label: "Is more than or equal to",
          value: "GREATER_THAN_EQUAL",
          number: true
        },
        { label: "Is less than", value: "LESS_THAN", number: true },
        {
          label: "Is less than or equal to",
          value: "LESS_THAN_EQUAL",
          number: true
        }
      ],
      visibilityOptions: [
        {
          label: "Hidden",
          value: "Hidden"
        },
        {
          label: "Disabled",
          value: "Disabled"
        },
        {
          label: "Visible",
          value: "Active"
        }
      ]
    };
  },
  created() {
    if (this.userProfile.clientId == 1) {
      this.operators = this.operators.concat([
        { label: "Is null", value: "IS_NULL", number: false },
        { label: "Is not null", value: "IS_NOT_NULL", number: false }
      ]);
      this.userAttributes = this.userAttributes.concat([
        {
          id: "l",
          context: "Users",
          contextResourceId: "l",
          contextColumn: "externalSource",
          label: "External Source",
          dataType: "SELECTOR"
        }
      ]);
    }
  },
  beforeDestroy() {},
  methods: {
    focusOnName() {
      this.$refs.nameInput.focus();
    },
    focusOnDescription() {
      this.$refs.descriptionInput.focus();
    },
    closeDialog() {
      this.$emit("close-dialog", null);
    },
    saveRules() {
      this.$emit("close-dialog", this.rules);
    },
    addRule() {
      //We have to find the group with the id passed to this function. Then we append a rule.
      console.log(this.hierarchy);
      // this.hierarchy.findIndex((x) => x.id === id);
      this.hierarchy.group.push({
        rule: true,
        id: Date.now(),
        // selectedContext: null,
        context: null,
        contextColumn: null,
        value: null
      });
    },
    addGroup() {
      this.hierarchy.group.push({
        rule: false,
        depth: 2,
        id: Date.now(),
        operator: "AND",
        group: []
      });
    },
    groupsInsideGroup(groupId) {
      console.log("Running");
      var returnGroups = this.groups.filter(x => x.parentGroup == groupId);
      console.log(returnGroups);
      return returnGroups;
    },
    submit() {
      this.$emit("close-dialog");
    },
    refreshEstimate() {
      this.$emit("refresh-estimate");
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    hierarchy: {
      get: function() {
        return this.rules;
      },
      set: function(newValue) {
        this.$emit("update-rules", newValue);
      }
    },
    ruleName: {
      get: function() {
        return this.name;
      },
      set: function(newValue) {
        this.$emit("update-name", newValue);
      }
    },
    ruleDescription: {
      get: function() {
        return this.description;
      },
      set: function(newValue) {
        this.$emit("update-description", newValue);
      }
    },
    visibilityOption: {
      get: function() {
        return this.visibility;
      },
      set: function(newValue) {
        this.$emit("update-visibility", newValue);
      }
    },
    contextOptions() {
      var array = [];
      if (this.modules.length > 0) {
        array = array.concat(this.modules);
      }
      if (this.useUserAttributes) {
        array = array.concat(this.userAttributes);
      }
      if (this.useMetrics && this.metrics.length > 0) {
        array = array.concat(this.metrics);
      }

      return array;
    },
    contextArray() {
      var arr = [];
      if (this.modules.length > 0) {
        arr.push({ label: "Learning", value: "LearningModules" });
      }
      if (this.useUserAttributes) {
        arr.push({ label: "User Attributes", value: "Users" });
      }
      if (this.useMetrics && this.metrics.length > 0) {
        arr.push({ label: "Metrics", value: "MetricDefinitions" });
      }
      return arr;
    },
    disableSaveButton() {
      if (this.showSaveButton && this.hierarchy !== null) {
        if (
          !(
            JSON.stringify(this.hierarchy).includes('"group":[]') ||
            JSON.stringify(this.hierarchy).includes('"contextColumn":null') ||
            JSON.stringify(this.hierarchy).includes('"operator":null') ||
            JSON.stringify(this.hierarchy).includes('"value":null') ||
            JSON.stringify(this.hierarchy).includes('"value":""') ||
            !this.ruleName ||
            !this.ruleDescription
          )
        ) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.and-or-selector {
  max-width: 100px;
}

.context-selector {
  width: 400px;
}

.operator-selector {
  width: 200px;
}

.value-field {
  width: 300px;
}

.grey-background {
  background-color: var(--v-grey-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
</style>

import axios from "../axios";

const baseUrl = process.env.VUE_APP_DEV_LOCAL
  ? process.env.VUE_APP_BADGE_URL
  : process.env.VUE_APP_GKE == "2"
  ? process.env.VUE_APP_WHISTLE_API.replace(
      "MICROSERVICE",
      "microservice-badges"
    )
  : `${process.env.VUE_APP_WHISTLE_API}${
      process.env.VUE_APP_GKE ? "/microservice-badges" : "/badge-service"
    }`;

class BadgeService {
  getBadges() {
    return axios
      .get(baseUrl + "/badges")
      .then(response => {
        return response.data.badges;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  getBadgesByUser() {
    return axios
      .get(baseUrl + "/badgesByUser")
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  createBadge(badge) {
    return axios
      .post(baseUrl + "/badges", badge)
      .then(response => {
        console.log(response.data);
        return response.data.badges;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  getBadge(badgeId) {
    return axios
      .get(baseUrl + "/badges/" + badgeId)
      .then(response => {
        console.log(response.data.badges);
        return response.data.badges;
      })
      .catch(error => {
        console.log("Error!", error.response);
        return error;
      });
  }
  updateBadge(badgeId, badge) {
    return axios
      .patch(baseUrl + "/badges/" + badgeId, badge)
      .then(response => {
        console.log(response.data.badges);
        return response.data.badges;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
  deleteBadge(badgeId) {
    return axios
      .delete(baseUrl + "/badges/" + badgeId)
      .then(response => {
        console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  uploadBadgeImage(badgeId, fileType, clientId, file) {
    let formData = new FormData();
    formData.append("clientId", clientId);
    formData.append("file", file);

    console.log("File: ", file);
    return axios
      .post(baseUrl + "/badges/" + fileType + "/" + badgeId, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        console.log(response.data);
        return response.data.filePath;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  deleteBadgeImage(badgeId, imageType, clientId) {
    return axios
      .delete(baseUrl + "/badges/" + imageType + "/" + badgeId, {
        data: {
          clientId: clientId
        }
      })
      .then(response => {
        console.log(response.data);
        return response.data.success;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }

  createUserBadge(userBadge) {
    return axios
      .post(baseUrl + "/userBadges", userBadge)
      .then(response => {
        console.log(response);
        return response.data.badge;
      })
      .catch(error => {
        console.log("Error!", error);
        return error;
      });
  }
}

export default new BadgeService();

<template>
  <div class="full-height gradient-background">
    <div class="d-flex flex-column justify-space-between full-height">
      <v-row justify="center" align="center" class="full-width" no-gutters>
        <v-col cols="12" sm="10" md="8" lg="8" xl="8">
          <div
            class="white-background form-card pa-5"
            :class="{
              rounded: !$vuetify.breakpoint.xs,
              'my-6': !$vuetify.breakpoint.xs
            }"
          >
            <div class="d-flex align-center mb-4">
              <v-img
                src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                max-width="165"
              />
              <span class="px-5 text-h5 text-left">
                Photo Search
              </span>
            </div>
            <div class="py-6">
              <!-- <div v-if="!companyId">
                Please load this page from within Procore
              </div> -->
              <div v-if="missingProcoreCompanyError">
                Your Whistle company is not configured for Procore. Please
                contact Whistle for assistance at
                <a href="mailto:help@wewhistle.com">help@wewhistle.com</a>
              </div>
              <div v-else-if="genericError">
                An unknown error occurred. Please contact Whistle for assistance
                at
                <a href="mailto:help@wewhistle.com">help@wewhistle.com</a>
              </div>
              <div
                v-else-if="loadingInitialState"
                class="d-flex flex-column align-center"
              >
                Loading...please wait
                <v-progress-circular
                  indeterminate
                  color="brandCyan"
                  width="4"
                  size="55"
                  class="mt-6"
                ></v-progress-circular>
              </div>
              <div v-else-if="!clientId" class="mx-10">
                <p>
                  Before you can search your photos, Whistle will need to
                  connect to Procore and process the images. This can take a few
                  minutes depending on how many images you have.
                </p>
                <v-btn
                  @click="linkCompany"
                  color="brandCyan"
                  class="white--text"
                  depressed
                  :loading="loadingClientSetup"
                  >Connect</v-btn
                >
              </div>
              <div v-else-if="unlinkedClient || pendingClient" class="mx-2 text-left">
                <h3>
                  Welcome to Whistle Photo Search!
                </h3>
                <p class="mt-1">
                  Whistle's proprietary photo search is powered by AI, allowing
                  you to search among daily log and site photos across projects
                  using simple text-based queries.
                </p>
                <v-row v-if="pendingClient" justify="center">
                  <v-col cols="12" sm="12" md="6" lg="5" xl="5">
                    <h4 class="mb-3">Congratulations! Your photos are currently syncing</h4>
                    <p class="px-4">We'll send an email to let you know when the sync is complete, or check back on this page.</p>
                    <v-form v-model="validEmailAddress" @submit.prevent="addEmailForSyncNotification">
                      <v-text-field 
                        label="Email to notify" 
                        v-model="emailNotification"
                        outlined
                        dense
                        style="max-width: 325px;"
                        class="mx-auto mt-3"
                        color="brandCyan"
                        :rules="formRules.emailRequired(255)"
                      ></v-text-field>
                    </v-form>
                    <div class="d-flex justify-center">
                      <v-btn
                        @click="addEmailForSyncNotification"
                        color="brandCyan"
                        class="white--text"
                        depressed
                        width="180"
                        :loading="loadingEmail"
                        :disabled="!validEmailAddress"
                        >Save
                      </v-btn>
                     
                    </div>
                    <div v-if="emailError" class="text-center mt-2">
                        An error occurred while saving the email. Please try again or contact Whistle for assistance
                        at
                        <a href="mailto:help@wewhistle.com">help@wewhistle.com</a>
                    </div>
                    <div v-else-if="emailSuccess" class="text-center mt-2 success--text">Done!</div>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <h4>How it works</h4>
                    <p>
                      Our semantic search indexing allows you to search for any
                      photo using just text
                    </p>
                    <p>
                      Try searching for:
                      <ul>
                        <li>a specific tool</li>
                        <li>equipment</li>
                        <li>activity</li>
                        <li>setting</li>
                      </ul>
                    </p>
                    <p>For example, you could search for "hard hats" or "cement truck at night". You can also upload an image that contains the item you're looking for.</p>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">  
                    <h4>Get started</h4>
                    <p>
                      To begin, just click "Connect" below and we'll start indexing your existing photos. Moving forward, new photos will be indexed automatically.
                    </p>
                    <p>
                      By default, we'll index the last year of photos. <br/><a @click="displayIndexStartDate = true">Need to go back further?</a>
                    </p>
                    <div v-if="displayIndexStartDate" class="mb-4"> 
                      <DatePicker
                        v-model="indexStartDate"
                        color="brandCyan"
                        label="Index images starting from:"
                        :outlined="true"
                        :dense="true"
                        appendIcon="mdi-calendar"
                        :prependIcon="null"
                        :clearable="false"
                        :hideDetails="true"
                      />
                    </div>
                    <div class="d-flex justify-center">
                      <v-btn
                        @click="syncCompany"
                        color="brandCyan"
                        class="white--text"
                        depressed
                        width="180"
                        :loading="loadingSync"
                        >Connect
                      </v-btn>
                      <div v-if="syncError" class="text-center mt-2">
                        An error occurred while making the connection. Please contact Whistle for assistance
                        at
                        <a href="mailto:help@wewhistle.com">help@wewhistle.com</a>
                      </div>
                    </div>
                  </v-col>
                </v-row>
               
              </div>
              <div v-else :class="{'mx-15': !isMobile, 'mx-2': isMobile}">
                <div class="d-flex align-center justify-center" :class="{'mx-8': !isMobile}">
                  <v-text-field
                    v-if="!displayImageUpload"
                    outlined
                    hide-details
                    color="brandCyan"
                    label="Search for an object or situation..."
                    class="search-field search"
                    clearable
                    v-model="search"
                    @keyup.enter="searchImages(true)"
                    ><template v-slot:append-outer>
                      <!-- <v-btn color="brandCyan" depressed>
                        <v-icon color="white">
                          mdi-magnify
                        </v-icon>
                      </v-btn> -->
                    </template>
                  </v-text-field>
                  <div
                    v-else
                    @drop.prevent="imageFileChanged($event, true)"
                    @dragover.prevent
                    class="full-width"
                  >
                    <v-file-input
                      v-model="imageFile"
                      label="Drag an image file, or click here to upload"
                      outlined
                      clearable
                      hide-details
                      chips
                      accept="image/*"
                      color="brandCyan"
                      class="search-field search"
                      ><template v-slot:label>
                        <p>
                          Drag an image file, or click here to upload<v-icon
                            class="ml-2"
                            >mdi-upload</v-icon
                          >
                        </p>
                      </template></v-file-input
                    >
                  </div>
                  <v-btn
                    depressed
                    class="search camera-button"
                    :disabled="loadingQuery"
                    @click="toggleFileSearch"
                  >
                    <v-icon color="darkGrey">
                      {{
                        displayImageUpload
                          ? "mdi-text-search"
                          : "mdi-camera-plus"
                      }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    depressed
                    class="search search-button white--text"
                    @click="searchImages(true)"
                    :loading="loadingQuery"
                  >
                    <v-icon color="white">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </div>
                <p v-if="queryError" class="error--text mt-4">
                  {{ queryError }}
                </p>
                <div class="d-flex justify-end align-center mt-2 pb-4" :class="{'mx-7': !isMobile}">
                  <div
                    class="d-flex cursor-pointer"
                    @click="displayFilters = !displayFilters"
                  >
                    <v-icon color="brandCyan" small class="mr-1"
                      >mdi-filter</v-icon
                    ><span class="brandCyan--text">Advanced Search</span
                    ><v-icon color="brandCyan">{{
                      displayFilters ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}</v-icon>
                  </div>
                </div>
                <v-expand-transition
                  ><div v-if="displayFilters" class="d-flex" :class="{'mx-8': !isMobile}">
                    <v-row
                      justify="center"
                      align="start"
                      class="full-width advanced-filters-box pa-3"
                      no-gutters
                    >
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="5"
                        xl="5"
                        class="px-1 text-left"
                      >
                        <p>Date Range:</p>
                        <DatePicker
                          v-model="startDate"
                          color="brandCyan"
                          label="Start date"
                          :outlined="true"
                          :dense="true"
                          appendIcon="mdi-calendar"
                          :prependIcon="null"
                          :clearable="true"
                          :hideDetails="true"
                          textFieldStyle="max-width: 260px;"
                          textFieldClass="mb-4"
                        />
                        <DatePicker
                          v-model="endDate"
                          color="brandCyan"
                          label="End date"
                          :outlined="true"
                          :dense="true"
                          appendIcon="mdi-calendar"
                          :prependIcon="null"
                          :clearable="true"
                          :hideDetails="true"
                          textFieldStyle="max-width: 260px;"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="5"
                        xl="5"
                        class="px-1"
                      >
                        <p class="hidden">Date Range:</p>
                        <v-autocomplete
                          v-model="projectId"
                          :items="projects"
                          :loading="loadingProjects"
                          item-value="id"
                          item-text="name"
                          dense
                          outlined
                          hide-details
                          color="brandCyan"
                          label="Project name"
                          class="filter-field append-outer-padding"
                          no-data-text="No projects found"
                        >
                          <template v-slot:append-outer>
                            <v-btn
                              icon
                              :disabled="loadingProjects"
                              @click="getCompanyProjects(true)"
                              ><v-icon>mdi-refresh</v-icon></v-btn
                            >
                          </template></v-autocomplete
                        >
                        <v-text-field
                          v-model="uploaderName"
                          dense
                          outlined
                          hide-details
                          color="brandCyan"
                          label="Uploader name"
                          class="filter-field mt-3"
                        />
                        <v-text-field
                          v-if="devEnvironment"
                          v-model="queryThreshold"
                          dense
                          outlined 
                          hide-details
                          type="number"
                          step="0.01"
                          color="brandCyan"
                          label="Query Threshold (0.349)"
                          class="filter-field mt-3"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        class="mt-2"
                      >
                        <v-btn
                          color="brandCyan"
                          depressed
                          class="white--text"
                          width="120"
                          :loading="loadingQuery"
                          @click="searchImages(true)"
                          >Apply</v-btn
                        ></v-col
                      >
                    </v-row>
                  </div></v-expand-transition
                >
                <!-- <div class="image-container mt-8 d-flex flex-wrap">
                  <div v-for="(image, i) in results" :key="i">
                    <v-img :src="image.thumbnail_path" width="100" height="100">
                      <template v-slot:placeholder>
                        <span class="">
                          Blocked image
                        </span>
                      </template></v-img
                    >
                  </div>
                </div> -->
              </div>
            </div>
          </div></v-col
        >
      </v-row>
      <v-row
        justify="center"
        align="start"
        class="full-width"
        no-gutters
        v-if="results.length || displayNoResults"
      >
        <v-col cols="12" sm="10" md="8" lg="8" xl="8">
          <div
            class="white-background form-card pa-5"
            :class="{
              rounded: !$vuetify.breakpoint.xs,
              'mb-6': !$vuetify.breakpoint.xs
            }"
          >
            <div class="py-6">
              <div class="d-flex justify-space-between align-center mx-4">
                <p class="text-left mb-0" v-if="totalCount !== null">
                  Results: {{ totalCount }}
                </p>
                <v-btn
                v-if="!displayNoResults"
                  color="brandCyan"
                  depressed
                  class="white--text"
                  :disabled="!selected.length"
                  :loading="loadingDownload"
                  @click="downloadImages(false)"
                  >Download selected ({{ selected.length }})</v-btn
                >
              </div>
              <!-- <v-overlay /> -->
              <div class="">
                <div
                  class="image-container mt-8 d-flex justify-center flex-wrap"
                >
                <p v-if="displayNoResults">No results found. <a @click="searchImages(true, true)">Do you want to broaden the search?</a></p>
                  <div
                    v-for="(image, i) in results"
                    :key="i"
                    class="ma-1 cursor-pointer"
                    @click="loadImagePopup(image)"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="0"
                        :class="{ 'on-hover': hover }"
                        class="image-card"
                      >
                        <v-img
                          :src="image.thumbnail_path_full"
                          width="150"
                          height="150"
                          class="rounded"
                          :class="{ dim: hover }"
                        >
                          <template v-slot:placeholder>
                            <v-img
                              src="@/assets/placeholder_image.png"
                              class="box-border-global"
                            /> </template
                        ></v-img>

                        <div
                          style="position: absolute; top: 10px; left: 10px;"
                          class="image-button"
                          :class="{ 'show-image-button': hover }"
                        >
                          <p class="mb-1 text-left mr-2">
                            {{
                              toLocalDate(
                                image.takenDate || image.uploadDate,
                                true
                              )
                            }}
                          </p>
                          <p
                            class="mb-1 text-left mr-2"
                            v-if="image.procoreUserName"
                          >
                            {{ image.procoreUserName }}
                          </p>
                        </div>
                        <div style="position:absolute;bottom:10px;left:10px;">
                          <v-simple-checkbox
                            small
                            :value="selected.includes(image.content_id)"
                            @click.stop="selectImage(image.content_id)"
                            color="brandCyan"
                            class="image-button ma-0 pa-0"
                            hide-details
                            :class="{
                              'show-image-button':
                                hover || selected.includes(image.content_id)
                            }"
                          >
                          </v-simple-checkbox>
                        </div>
                      </v-card>
                    </v-hover>
                  </div>
                </div>
                <v-btn
                  v-if="searchAfterValue"
                  depressed
                  color="brandCyan"
                  class="white--text mt-4"
                  :loading="loadingQuery"
                  @click="searchImages(false)"
                  >+ Load more</v-btn
                >
              </div>
            </div>
          </div></v-col
        >
      </v-row>
    </div>

    <v-dialog v-model="displayImagePopup" width="600">
      <v-card v-if="displayImagePopup" class="pa-4">
        <div class="d-flex justify-space-between">
          <div class="d-flex flex-column mt-2 text-left word-break">
            <p class="mb-1">
              {{ toLocalDate(popup.takenDate || popup.uploadDate, true) }}
            </p>
            <p class="mb-1">
              {{ popup.projectName }} - Project {{ popup.projectId }}
            </p>
          </div>
          <!-- !popup.failed && popup.raw_path_full && popup.content_id -->
          <div class="d-flex flex-column">
            <v-btn
              color="brandCyan"
              text
              v-if="popup.raw_path_full && popup.content_id"
              :loading="loadingDownload"
              @click="downloadImages(true)"
              >Download<v-icon>mdi-download</v-icon></v-btn
            >
            <p v-if="downloadError" class="error--text mt-2 word-break">
              {{ downloadError }}
            </p>
          </div>
        </div>
        <v-img
          v-if="popup.failed"
          src="@/assets/placeholder_image.png"
          width="90%"
          contain
          class="mx-auto mt-4"
        >
        </v-img>
        <v-img
          v-else
          :src="popup.raw_path_full"
          width="90%"
          contain
          class="mx-auto mt-4"
          v-on:error="failedToLoadImage"
        ></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import ProcoreSemanticSearchService from "@/services/ProcoreSemanticSearchService";

import DatePicker from "@/components/DatePicker";

import { formRules } from "@/shared_data/data";

// import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "ProcoreSemanticSearch",
  title: "Procore | Whistle",
  components: { DatePicker },
  props: {},
  data() {
    return {
      // Errors
      genericError: false,
      missingProcoreCompanyError: false,
      queryError: null,
      downloadError: null,
      syncError: false,
      emailError: false,

      // Success
      emailSuccess: false,
      

      // State config vars
      unlinkedClient: false,
      pendingClient: false,

      // Loading
      loadingInitialState: true,
      loadingClientSetup: false,
      loadingSync: false,
      loadingEmail: false,
      loadingQuery: false,
      loadingProjects: false,
      loadingDownload: false,

      // Displays
      displayNoResults: false,
      displayFilters: false,
      displayImageUpload: false,
      displayImagePopup: false,
      displayIndexStartDate: false,

      // Data / Values
      // clientId: null,
      companyId: null,
      search: null,
      imageFile: null,
      results: [],
      projects: [],
      searchAfterValue: null,
      loadMoreResultsButton: false,
      queryPage: 0,
      totalCount: null,
      selected: [],
      indexStartDate: moment().subtract(1, 'year').format("YYYY-MM-DD"),
      queryThreshold: 0.349,
      emailNotification: null,

      // Filters
      startDate: null,
      endDate: null,
      projectId: null,
      uploaderName: null,

      // Popup
      popup: null,

      // v2
      tab: 0,
      keys: {
        myRewards: 0,
        rewardsSent: 1
      },
      display: {
        search: false,
        admin: false,
        popups: false
      },
      popups: [],

      cookieRefreshTimeout: null,

      formRules,
      validEmailAddress: false,
    };
  },
  created() {
    //  First we have to check if a client is already hooked up
    if (!this.$auth.isAuthenticated) return this.$router.push("/login");
    this.checkCompanyState();

    if (this.userProfile?.businessEmail) this.emailNotification = this.userProfile?.businessEmail;
  },
  mounted() {},
  destroyed() {},
  beforeDestroy() {
    if (this.cookieRefreshTimeout) clearInterval(this.cookieRefreshTimeout);
    this.cookieRefreshTimeout = null;
  },
  methods: {
    async checkCompanyState() {
      if (!this.clientId) return;

      this.loadingInitialState = true;
      try {
        const res = await ProcoreSemanticSearchService.getCompanyClientMapping(
          this.clientId
        );
        console.log("Company output", res);
        this.companyId = res?.result?.value;
        if (!res?.result?.clientId) 
          this.unlinkedClient = true;
        else if (res?.result?.status === "Pending") this.pendingClient = true;
        else if (!this.companyId) this.missingProcoreCompanyError = true;
        this.getCompanyProjects();
        this.getSignedCookie();
      } catch (e) {
        console.error("Error checking companyId", e?.response);
        if (e?.response?.data?.message == "No procore configuration found")
          this.missingProcoreCompanyError = true;
        else this.genericError = true;
      } finally {
        this.loadingInitialState = false;
      }
    },
    // Old public route
    async linkCompany() {
      if (!this.companyId) return;

      this.loadingClientSetup = true;
      try {
        const res = await ProcoreSemanticSearchService.createCompanyClientMapping(
          this.companyId,
          {
            clientName: this.companyName
          }
        );
        console.log("Setup output", res);
        this.clientId = res?.result?.clientId;
      } catch (e) {
        console.error("Error setting up company", e);
        this.genericError = true;
      } finally {
        this.loadingClientSetup = false;
      }
    },
    async syncCompany() {
      if (!this.companyId) return;

      this.syncError = false;
      this.loadingSync = true;
      try {
        const res = await ProcoreSemanticSearchService.createConnectionAndSync(
          this.clientId,
          {
            startDate: this.indexStartDate
          }
        );
        console.log("Sync output", res);
        // if (res?.result?.status === "Active") this.unlinkedClient = false;
        // else 
        this.pendingClient = true;
      } catch (e) {
        console.error("Error syncing company", e);
        this.syncError = true;
      } finally {
        this.loadingSync = false;
      }
    },
    async addEmailForSyncNotification() {
      if (!this.companyId || !this.validEmailAddress) return console.log("Ignoring");
      this.emailError = false;
      this.emailSuccess = false;

      this.loadingEmail = true;
      try {
        const res = await ProcoreSemanticSearchService.addEmailForSyncNotification(
          this.clientId,
          {
            email: this.emailNotification
          }
        );
        console.log("Sync output", res);
        this.emailSuccess = true;
      } catch (e) {
        console.error("Error syncing company", e);
        this.emailError = true;
      } finally {
        this.loadingEmail = false;
      }
    },
    async getCompanyProjects(manualRefresh = false) {
      if (!this.companyId) return;

      this.loadingProjects = true;
      try {
        const res = await ProcoreSemanticSearchService.getCompanyProjects(
          this.clientId,
          {
            refresh: manualRefresh ? 1 : 0
          }
        );
        console.log("Project output", res);
        this.projects = res?.result || [];
      } catch (e) {
        console.error("Error getting projects", e);
      } finally {
        this.loadingProjects = false;
      }
    },
    async searchImages(freshLoad = false, lowerThreshold = false) {
      const imageUpload = !!this.displayImageUpload;
      if (
        !this.clientId ||
        (!this.displayImageUpload && !this.search) ||
        (this.displayImageUpload && !this.imageFile) ||
        this.loadingQuery
      )
        return console.log("Ignoring query");
      if (freshLoad) {
        this.searchAfterValue = null;
        this.loadMoreResultsButton = false;
        this.queryPage = 1;
        this.selected = [];
      } else this.queryPage++;

      this.queryError = null;
      this.loadingQuery = true;

      let body = {
        query: this.search,
        page: this.queryPage,
        search_after: freshLoad ? null : this.searchAfterValue
      };
      let formData = new FormData();
      if (imageUpload) {
        formData.append("file", this.imageFile);
        formData.append("page", this.queryPage);
        if (!freshLoad) formData.append("search_after", this.searchAfterValue);
      }
      if (this.displayFilters) {
        if (this.startDate)
          imageUpload
            ? formData.append("startDate", this.startDate)
            : (body.startDate = this.startDate);
        if (this.endDate)
          imageUpload
            ? formData.append("endDate", this.endDate)
            : (body.endDate = this.endDate);
        if (this.uploaderName)
          imageUpload
            ? formData.append("procoreUserName", this.uploaderName)
            : (body.procoreUserName = this.uploaderName);
        if (this.projectId)
          imageUpload
            ? formData.append("projectId", this.projectId)
            : (body.projectId = this.projectId);
      }

      // Broaden the search
      let params, threshold = this.queryThreshold;
      if (lowerThreshold) {
        threshold = (Number(this.queryThreshold) - 0.05);
        if (threshold < 0.001) threshold = 0.001;
        threshold = parseFloat(threshold).toFixed(5);
        this.queryThreshold = threshold;
      }
      if (this.devEnvironment || lowerThreshold) 
        params = { threshold };
      try {
        console.log("New query", { body, params });
        const res = imageUpload
          ? await ProcoreSemanticSearchService.searchImagesByImage(
              this.clientId,
              formData,
              params
            )
          : await ProcoreSemanticSearchService.searchImages(
              this.clientId,
              body,
              params
            );
        console.log("Query results", res);
        if (freshLoad) this.results = res.result;
        else this.results.push(...(res.result || []));
        this.loadMoreResultsButton = !!this.results?.length;
        this.searchAfterValue = res?.next_search_after;
        this.totalCount = res?.count;

        this.displayNoResults = freshLoad && res?.result?.length === 0;
      } catch (e) {
        console.error("Error setting up company", e);
        this.queryError = "There was an issue performing this search.";
      } finally {
        this.loadingQuery = false;
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleFileSearch() {
      this.displayImageUpload = !this.displayImageUpload;
      this.imageFile = null;
      this.search = null;
      this.results = [];
      this.queryPage = 1;
    },
    loadImagePopup(e) {
      this.popup = e;
      this.displayImagePopup = true;
      console.log("Popup loaded", e);
    },
    imageFileChanged(e, dragAndDrop = false) {
      console.log({ e, dragAndDrop });
      let file;
      if (dragAndDrop && e?.dataTransfer?.files?.length)
        file = e?.dataTransfer?.files[0];
      else if (!dragAndDrop && e?.target?.files?.length)
        file = e?.target?.files[0];
      console.log("File", file);
      if (file) {
        if (!file?.type.startsWith("image/"))
          return console.log("Invalid image file type ", file?.type);
        this.imageFile = file;
        // this.searchImages(true);
      }
    },
    toLocalDate(date, format = false, formatValue = null) {
      if (!date) return;

      const d = moment(date).local();
      if (!format) return d;
      return d.format(formatValue || "MM-DD-YYYY h:mma");
    },
    failedToLoadImage(e) {
      console.log("Failed to load image", e);
      this.$set(this.popup, "failed", true);
      // this.popup.failed = true;
    },
    async getSignedCookie() {
      const cdnKey = "Cloud-CDN-Cookie";
      if (!this.clientId) return;

      try {
        const res = await ProcoreSemanticSearchService.getSignedCookie(
          this.clientId
        );
        console.log("COOKIE RES", res);
        const cookie = res?.cookie;
        const [key] = cookie.split("=");
        const rest = cookie
          .split("=")
          .slice(1)
          .join("=");
        const cookieVal = rest.split(";");
        console.log({ cookieVal });
        // this.cookie = cookie;
        const keyValue = rest.split(":");
        const domain = res?.domain;
        const expirationMinutes = res?.expiration || 27;
        console.log("value", keyValue);

        var inTwentySevenMinutes = new Date(
          new Date().getTime() + expirationMinutes * 60 * 1000
        );

        let cook = cookie + `; Expires=${inTwentySevenMinutes};`;
        console.log("COOK", cook);
        document.cookie = cookie; //+ `; Expires=${inTwentySevenMinutes};`;
        // document.cookie = `${cdnKey}=${keyValue[0]}`;
        let value = document.cookie.match("(^|;)?" + key + "=([^;]*)(;|$)");
        console.log("Document.cookie", value ? unescape(value[2]) : null);
        // alert("Your Cookie : " + document.cookie);
        // Refresh the cookie every 13 minutes (780000 ms)
        this.cookieRefreshTimeout = setInterval(() => {
          this.getSignedCookie();
        }, 1000 * 60 * expirationMinutes);
      } catch (e) {
        console.error("Error checking cookie", e);
        // this.genericError = true;
      } finally {
        console.log("Done with cookies");
      }
    },
    async downloadImages(popup = false) {
      if (
        !this.clientId ||
        (popup && !this.popup?.content_id) ||
        (!popup && !this.selected?.length) ||
        this.loadingDownload
      )
        return console.log("Ignoring download press");

      this.downloadError = null;
      this.loadingDownload = true;

      let body = {
        ids: popup ? [this.popup.content_id] : this.selected
      };

      try {
        console.log("New query", body);
        const res = await ProcoreSemanticSearchService.downloadImages(
          this.clientId,
          body
        );
        console.log("Download results", res);
        const url = res?.result;
        if (!url || typeof url !== "string") throw "Missing url";

        let link = document.createElement("a");
        link.href = url; //window.URL.createObjectURL(blob);
        link.setAttribute("download", "whistle_images.zip");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error("Error setting up company", e);
        this.downloadError = `There was an issue downloading the image${
          popup ? "" : "s"
        }.`;
      } finally {
        this.loadingDownload = false;
      }
    },
    selectImage(contentId) {
      console.log("selectImage", contentId);
      const idx = this.selected.findIndex(x => x == contentId);
      if (idx === -1) this.selected.push(contentId);
      else this.selected.splice(idx, 1);
    }
  },
  computed: {
    ...mapState(["userProfile", "roles", "permissions", "magicLinkToken"]),
    clientId() {
      return this?.userProfile?.clientId == 1 && this.$route.query.clientId
        ? Number(this.$route.query.clientId)
        : this?.userProfile?.clientId;
    },
    // companyId() {
    //   return this.$route.query.companyId;
    // },
    companyName() {
      return this.$route.query.companyName;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isTest() {
      return process.env.VUE_APP_ENVIRONMENT == "test";
    },
    computedPopupImage() {
      if (this.popup.failed) return "./placeholder_image.png";
      return this.popup?.raw_path;
    },
    displayOtherRewardsCheckbox() {
      let adminRoles = ["30FinanceAdminV2", "40ClientAdminV2"];
      return this.roles.find(x => adminRoles.includes(x));
    },
    devEnvironment() {
      return process.env.VUE_APP_ENVIRONMENT == 'dev' || process.env.VUE_APP_ENVIRONMENT == 'test';
    }
  },
  watch: {
    // Don't let them load more because they changed the search so it has to be a fresh search / load
    imageFile: function() {
      this.searchAfterValue = null;
      this.loadMoreResultsButton = false;
    },
    search: function() {
      this.searchAfterValue = null;
      this.loadMoreResultsButton = false;
    }
  }
};
</script>

<style scoped>
.gradient-background {
  /* background-image: url(https://storage.googleapis.com/whistle-dev-pictures/Logo/gradient.png); */
  background: linear-gradient(
      45deg,
      rgba(161, 215, 110, 1) 1%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}
/* change flex to make footer margin-top auto work to push to bottom */
.v-tabs {
  flex: 0 1 auto;
}

.balance {
  font-size: 1.35em;
}
.balance.in-process {
  color: #999;
}
.border-radius {
  border-radius: 5px;
}

.dispute-button {
  width: 36px;
}

.txn-table {
  /* border: solid 1px;
  border-color: lightgray; */
  border-radius: 20px;
  /* border-radius: 0px; */
}

.txn-table >>> .v-data-table__wrapper {
  border-radius: 20px 0 0 0;
  padding-top: 10px;
}

.txn-table >>> .v-data-table-header {
  background-color: white;
}

.budget-select {
  font-size: 0.875rem;
  min-width: 160px;
}

.robin-animation >>> .robin {
  justify-content: center;
}

.search {
  max-height: 56px !important;
  min-height: 56px !important;
}

.search-field {
  border-radius: 4px 0 0 4px !important;
}

.search-button {
  border-radius: 0 4px 4px 0;
  background-color: var(--v-brandCyan-base) !important;
  border-style: solid !important;
  border-color: #9b9b9b !important;
  border-width: 1px 1px 1px 0px;
}

.camera-button {
  border-radius: 0 0 0 0;
  background-color: #bdbdbd !important;
  border-style: solid !important;
  border-color: #9b9b9b !important;
  border-width: 1px 1px 1px 0px;
  /* border-left-width: 0px; */
}

.filter-field {
  max-width: 260px;
}

.advanced-filters-box {
  border-radius: 3px;
  border: 1px solid lightgray;
}

.append-outer-padding >>> .v-input__append-outer {
  margin-top: 4px !important;
  margin-left: 1px !important;
}

.image-card {
  border-radius: 4px !important;
}

.image-button {
  opacity: 0;
  /* background-color: #fff !important; */
  /* color: #000 !important; */
  color: white !important;
  transition: opacity 0.4s ease-in-out;
}

.show-image-button {
  opacity: 1;
  background-color: transparent !important;
}

.image-button .v-icon {
  background-color: white;
  width: 21px;
  height: 21px;
  border-radius: 2px;
}

.dim {
  filter: brightness(50%);
}
</style>

<template>
  <v-card width="100%" flat class="mt-auto">
    <v-divider />
    <div
      class="py-4 flex-wrap"
      :class="{ 'flex-column': column, 'd-flex': column }"
    >
      Useful Documents:
      <a class="mx-2" @click="downloadFile('cardholder_agreement.pdf')"
        >Cardholder Agreement</a
      >
      <span v-if="!column">|</span>
      <a class="mx-2" @click="downloadFile('issuing_bank_privacy_policy.pdf')"
        >Bank Privacy Policy</a
      >
      <span v-if="!column">|</span>
      <a class="mx-2" @click="downloadFile('faq.pdf')">FAQ</a>
    </div>
  </v-card>
</template>

<script>
import UserService from "@/services/UserService";

import { mapState } from "vuex";

export default {
  name: "MarqetaFooter",
  props: {
    column: Boolean
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    downloadFile(fileName) {
      UserService.downloadCardProductFile(fileName, this.magicLinkToken);
    }
  },
  computed: {
    ...mapState(["userProfile", "magicLinkToken"])
  },
  watch: {}
};
</script>

<style scoped id="styles"></style>

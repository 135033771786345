var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-start ml-2",class:{ hidden: _vm.currentSlide >= 3 },attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-btn',{staticClass:"pa-2 mb-1",attrs:{"fab":"","dark":"","small":"","elevation":"0"},on:{"click":_vm.previousSlide}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('span',[_vm._v("Back")])],1)],1)],1),_c('transition',{attrs:{"name":_vm.slideDirection,"mode":"out-in"}},[(_vm.currentSlide == 0)?_c('v-row',{key:"0",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("There are a few choices to make about your award")]),_c('v-form',{ref:"form",staticClass:"full-width px-4",model:{value:(_vm.validOrderForm),callback:function ($$v) {_vm.validOrderForm=$$v},expression:"validOrderForm"}},[_c('h3',{staticClass:"align-text-left mt-3"},[_vm._v(" "+_vm._s(_vm.award ? "Award: " + _vm.award.displayName : null)+" ")]),_c('v-select',{attrs:{"label":"Item Selection","items":_vm.award.supplementAwardInfo.physicalItems,"item-value":"sku","item-text":"name","rules":[v => !!v || 'Item selection is required']},model:{value:(_vm.sku),callback:function ($$v) {_vm.sku=$$v},expression:"sku"}}),_c('v-select',{attrs:{"label":"Quantity","items":_vm.quantityArray,"rules":[v => !!v || 'A quantity is required']},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),(_vm.selectedSku.length > 0)?_c('v-select',{attrs:{"label":"Item Selection","items":_vm.selectedSku,"item-value":"sku","item-text":"name","rules":[v => !!v || 'Item selection is required']},model:{value:(_vm.itemOption),callback:function ($$v) {_vm.itemOption=$$v},expression:"itemOption"}}):_vm._e(),_c('h3',{staticClass:"align-text-left mt-3"},[_vm._v("Available Items:")]),(
            _vm.award &&
              _vm.award.supplementAwardInfo &&
              _vm.award.supplementAwardInfo.physicalItems
          )?_c('div',_vm._l((_vm.award.supplementAwardInfo.physicalItems),function(item){return _c('div',{key:item.sku,staticClass:"d-flex mt-4"},[(item.image)?_c('v-img',{staticClass:"image-border",attrs:{"src":item.image,"aspect-ratio":1 / 1,"width":"150","max-width":"150","height":"150"}}):_c('v-img',{staticClass:"image-border",attrs:{"src":require("@/assets/placeholder_image.png"),"aspect-ratio":1 / 1,"width":"150","max-width":"150","height":"150"}}),_c('div',{staticClass:"d-flex flex-column ml-4 align-start"},[_c('h3',[_vm._v(_vm._s(item.name))]),_c('caption',{staticClass:"grey--text"},[_vm._v(" SKU "+_vm._s(item.sku)+" ")]),_c('p',{staticClass:"mt-4"},[_vm._v(_vm._s(item.description))])])],1)}),0):_vm._e()],1)],1):_vm._e(),(_vm.currentSlide == 1)?_c('v-row',{key:"1",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Let's get all the details for your award")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" We'll need your name and contact info ")]),_c('v-form',{ref:"form",staticClass:"full-width px-4",model:{value:(_vm.validNameForm),callback:function ($$v) {_vm.validNameForm=$$v},expression:"validNameForm"}},[_c('v-text-field',{ref:"firstName",staticClass:"mt-4",attrs:{"label":"First name","rules":[
            v => !!v || 'First name is required',
            v => !!(v && v.length < 255) || 'First name should be shorter'
          ]},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('v-text-field',{ref:"lastName",staticClass:"mt-4",attrs:{"label":"Last name","rules":[
            v => !!v || 'Last name is required',
            v => !!(v && v.length < 255) || 'Last name should be shorter'
          ]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('v-text-field',{ref:"lastName",staticClass:"mt-4",attrs:{"label":"Contact email","rules":[
            v => !!v || 'Email is required',
            v => !!(v && v.length < 255) || 'Email should be shorter',
            v => (v && _vm.emailRegex.test(v)) || 'Email should be valid'
          ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('vue-tel-input-vuetify',{staticClass:"mt-4",attrs:{"value":_vm.phone,"label":"Phone number (xxx-xxx-xxxx)","placeholder":"xxx-xxx-xxxx","preferredCountries":['US'],"defaultCountry":_vm.phoneCountryCode.code,"disabledFetchingCountry":true},on:{"country-changed":_vm.phoneCountryCodeChanged,"validate":_vm.phoneValidation},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1):(_vm.currentSlide == 2)?_c('v-row',{key:"2",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Where are we sending your award?")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" Let's get your address ")]),_c('v-form',{ref:"form",staticClass:"full-width px-4",model:{value:(_vm.validAddressForm),callback:function ($$v) {_vm.validAddressForm=$$v},expression:"validAddressForm"}},[_c('v-text-field',{ref:"address1",staticClass:"mt-4",attrs:{"label":"Address","required":"","rules":[
            v => !!v || 'Address is required',
            v => !!(v && v.length < 256) || 'Address should be shorter'
          ]},model:{value:(_vm.address1),callback:function ($$v) {_vm.address1=$$v},expression:"address1"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Apt, suite, unit, etc."},model:{value:(_vm.address2),callback:function ($$v) {_vm.address2=$$v},expression:"address2"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"City","required":"","rules":[
            v => !!v || 'City is required',
            v => !!(v && v.length < 40) || 'City should be shorter'
          ]},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('v-select',{attrs:{"label":"State","items":_vm.states,"item-value":"abbreviation","item-text":"name","rules":[v => !!v || 'State is required']},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}}),_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Zip Code","required":"","rules":[
            v => !!v || 'Zip code is required',
            v => !!(v && v.length < 10) || 'Zip code should be shorter'
          ]},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}}),_c('v-select',{attrs:{"label":"Country","items":_vm.countries,"item-value":"code","item-text":"name","rules":[v => !!v || 'Country is required']},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),(_vm.country == '00')?_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Other country (2 digit code)","required":"","rules":[
            v => !!v || 'Country is required',
            v =>
              !!(v && v.length == 2) || 'Country code should be 2 characters',
            v =>
              !!(v && _vm.countryCodes.includes(v.toUpperCase())) ||
              'Country code must be a valid country'
          ]},model:{value:(_vm.otherCountry),callback:function ($$v) {_vm.otherCountry=$$v},expression:"otherCountry"}}):_vm._e()],1)],1):(_vm.currentSlide == 3)?_c('v-row',{key:"3",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("Processing...")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" This should only take a few seconds. ")]),_c('lottie',{staticClass:"mt-6",attrs:{"options":_vm.lottie.lottieOptions,"height":140,"width":140},on:{"animCreated":_vm.handleLottieAnimation}})],1):(_vm.currentSlide == 4)?_c('v-row',{key:"4",staticClass:"py-3 word-break px-5 d-flex flex-column align-center mb-8",attrs:{"no-gutters":""}},[_c('h2',[_vm._v("CONGRATULATIONS!")]),_c('caption',{staticClass:"grey--text mt-2"},[_vm._v(" Your new order has been placed and will be shipping soon! ")]),_c('lottie',{staticClass:"mt-6",attrs:{"options":_vm.lottie.lottieOptions,"height":140,"width":140},on:{"animCreated":_vm.handleLottieAnimation}})],1):_vm._e()],1),(_vm.currentSlide != 3)?_c('v-row',{staticClass:"mb-10",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-btn',{staticClass:"py-5",attrs:{"outlined":"","rounded":"","disabled":(_vm.currentSlide == 1 && (!_vm.validNameForm || !_vm.phoneNumberValid)) ||
            (_vm.currentSlide == 2 && !_vm.validAddressForm) ||
            (_vm.currentSlide == 0 &&
              (!_vm.quantity || !_vm.sku || (_vm.selectedSku.length > 0 && !_vm.itemOption)))},on:{"click":_vm.nextSlide}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"brandGreen"}},[_vm._v("mdi-arrow-right-drop-circle")]),_c('span',{staticClass:"mt-1"},[_vm._v(_vm._s(_vm.currentSlide != 4 ? "Next" : "Done"))])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
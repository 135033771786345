<template>
  <!-- <div class="full-height white-background"> -->
  <v-dialog
    fullscreen
    value="true"
    persistent
    transition="dialog-bottom-transition"
  >
    <div width="100%" rounded="0" class="full-height">
      <!-- <v-card rounded="0" width="" -->
      <v-row
        v-if="loading && !programNotFound"
        class="d-flex flex-column align-center white-background full-height"
        no-gutters
      >
        <Robin
          class="mt-4"
          :showText="false"
          :width="140"
          :height="140"
          animation="egypt"
          :loop="true"
        />
        <h2>Loading your program</h2>
      </v-row>
      <!-- Program not found message -->
      <v-row
        v-else-if="programNotFound"
        class="d-flex flex-column align-center white-background full-height"
        no-gutters
      >
        <Robin
          class="mt-4"
          :showText="false"
          :width="100"
          :height="100"
          animation="curious"
          :loop="true"
        />
        <h2>We had trouble finding your program</h2>
        <h3>
          You can <a @click="getProgramsByUser">try again</a> or check out the
          <a @click="$router.push('/programs')">Program page</a> for more.
        </h3>
      </v-row>
      <!-- Program content -->
      <v-row v-else-if="program" class="full-height" no-gutters>
        <v-col
          v-if="displayMobileOverview || !isMobile"
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          class="my-0 py-0 white-background side-nav"
        >
          <div class="side-nav-header">
            <v-toolbar
              dark
              color="brand"
              rounded="0"
              class="d-flex align-center"
            >
              <v-btn icon color="white" @click="leaveProgram"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <b class="ml-1">
                Save progress and close
              </b>
            </v-toolbar>
            <!-- Program title & header box -->
            <div
              class="box-border rounded mx-7 my-6 d-flex flex-column justify-center"
            >
              <p class="text-h6 mt-3">
                {{ program.displayName }}
              </p>
              <div class="d-flex justify-center mb-5 full-width">
                <ProgramCardProgress
                  type="Bars"
                  :status="program.status"
                  :startDate="program.startDate"
                  :endDate="program.endDate"
                  :progressPercent="cumulativeProgramProgress"
                  class="progress-bar"
                  :hideClock="false"
                  :badgeImageUrl="programBadge.badgeSignedUrl || null"
                />
              </div>
              <p
                class="font-weight-bold brand--text"
                v-if="timeUntilProgramStart"
              >
                Your progress may not be accurately reflected until this program
                begins in {{ timeUntilProgramStart }}.
              </p>
              <p
                class="font-weight-bold brand--text"
                v-else-if="timeSinceProgramEnd"
              >
                This program has ended. You can still view its elements, but no
                further progress will be recorded.
              </p>
            </div>
          </div>

          <div
            class="my-5 mx-7 side-nav-col"
            :style="
              `width: calc(100% - 56px);overflow: scroll;height: calc(100% - ${sideNav.headerHeight}px);`
            "
          >
            <transition-group name="list-complete" mode="out-in">
              <div
                v-for="(item, itemIndex) in programElementsWithReward"
                :key="`${item.context}-${item.id}-${itemIndex}`"
                @click="
                  (item.context == 'LearningModules' && !item.locked) ||
                  item.context == 'Program'
                    ? item.type == 'Survey'
                      ? loadSurvey(itemIndex, true)
                      : sideNav.openItemIndex == itemIndex
                      ? (sideNav.openItemIndex = null)
                      : (sideNav.openItemIndex = itemIndex)
                    : !item.locked
                    ? loadCard(itemIndex, undefined, undefined, true)
                    : () => {}
                "
                class="mb-2 list-complete-item full-width"
                :class="{ 'cursor-pointer': !item.locked }"
              >
                <v-tooltip top :disabled="!item.locked">
                  <template v-slot:activator="{ on, attrs }">
                    <!-- Skeleton while we fetch learning modules -->
                    <div v-if="item.loading" class="d-flex full-width">
                      <v-skeleton-loader
                        min-width="35"
                        width="35"
                        max-width="35"
                        min-height="35"
                        height="35"
                        max-height="35"
                        type="card"
                        style="margin-top: 1px;"
                      ></v-skeleton-loader>
                      <div
                        class="d-flex flex-column box-border px-3 ml-2 rounded full-width justify-center"
                      >
                        <v-skeleton-loader
                          width="100%"
                          min-height="20"
                          height="20"
                          type="text"
                          style="margin-top: 6px;"
                        ></v-skeleton-loader>
                      </div>
                    </div>
                    <div
                      class="d-flex full-width"
                      v-else
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        class="side-nav-icons"
                        :class="`${item.iconColor}-background`"
                        style="margin-top: 1px;"
                        color="white"
                        >{{ item.icon }}</v-icon
                      >
                      <div
                        class="d-flex flex-column box-border px-3 ml-2 rounded full-width"
                        :class="{
                          'brand-background':
                            sideNav.openItemIndex == itemIndex &&
                            [
                              'MetricDefinitions',
                              'Users',
                              'Reward',
                              'Badges'
                            ].includes(item.context)
                        }"
                      >
                        <div
                          class="d-flex justify-space-between align-center side-nav-box text-left"
                          :class="{
                            'side-nav-box-height':
                              sideNav.openItemIndex !== itemIndex
                          }"
                        >
                          <span
                            :style="`width: ${sideNav.textWidth}px;`"
                            class="text-overflow-clip"
                            :class="{
                              'font-weight-bold':
                                sideNav.openItemIndex == itemIndex,
                              'white--text':
                                sideNav.openItemIndex == itemIndex &&
                                [
                                  'MetricDefinitions',
                                  'Users',
                                  'Reward',
                                  'Badges'
                                ].includes(item.context)
                            }"
                          >
                            {{ item.displayName
                            }}{{
                              item.context == "Badges" &&
                              item.displayName &&
                              !item.displayName.toLowerCase().includes("badge")
                                ? " badge"
                                : ""
                            }}
                          </span>

                          <div v-if="item.calculateProgress == false" />
                          <v-icon
                            v-else-if="
                              (item.locked &&
                                cumulativeProgramProgress < 100) ||
                                (['Badges', 'Reward'].includes(item.context) &&
                                  program.status == 'Scheduled')
                            "
                            color="grey"
                            >mdi-lock</v-icon
                          >
                          <v-progress-circular
                            v-else-if="
                              item.progressPercent < 100 &&
                                !['Badges', 'Reward'].includes(item.context)
                            "
                            :color="
                              sideNav.openItemIndex == itemIndex &&
                              [
                                'MetricDefinitions',
                                'Users',
                                'Reward',
                                'Badges'
                              ].includes(item.context)
                                ? 'white'
                                : 'brand'
                            "
                            :rotate="270"
                            :size="20"
                            :width="4"
                            :value="item.progressPercent"
                            style="margin-right:2px;"
                          />
                          <v-icon v-else color="brandGreen"
                            >mdi-check-circle-outline</v-icon
                          >
                        </div>
                        <div
                          v-if="
                            itemIndex == sideNav.openItemIndex &&
                              item.type != 'Survey' &&
                              item.LearningChapters &&
                              item.status != 'Disabled'
                          "
                          class="px-3 py-2"
                        >
                          <div
                            v-for="(chapter,
                            chapterIndex) in item.LearningChapters"
                            :key="chapter.learningChapterId"
                            class="text-left mt-4"
                          >
                            <span
                              class="text-left brand--text"
                              v-if="chapter.displayName"
                            >
                              <!-- Chapter {{ chapterIndex + 1 }}: -->
                              {{ chapter.displayName }}</span
                            >
                            <!-- <v-divider class="mb-4" /> -->
                            <div
                              v-for="(card, cardIndex) in chapter.LearningCards"
                              :key="card.learningCardAssociationId"
                              class="d-flex align-center my-2 px-2 box-border rounded side-nav-box-height"
                              @click.stop="
                                card.locked
                                  ? () => {}
                                  : loadCard(
                                      itemIndex,
                                      chapterIndex,
                                      cardIndex,
                                      true
                                    )
                              "
                              :class="
                                card == currentCard
                                  ? 'white--text brand-background'
                                  : ''
                              "
                            >
                              <div
                                class="d-flex align-center justify-space-between full-width"
                              >
                                <div
                                  class="ml-2 text-overflow-clip learning-card-row"
                                  :class="{
                                    'mediumGrey--text': card.locked,
                                    'font-weight-bold':
                                      card.progressPercent >= 100,
                                    'brand--text':
                                      card.progressPercent >= 100 &&
                                      card != currentCard
                                  }"
                                  :style="`width: ${sideNav.textWidth - 48}px;`"
                                >
                                  {{ card.displayName }}
                                </div>
                                <div
                                  v-if="card.calculateProgress == false"
                                ></div>
                                <v-icon v-else-if="card.locked" color="grey"
                                  >mdi-lock</v-icon
                                >
                                <!-- <v-icon
                                  v-else-if="card.progressPercent >= 100"
                                  color="grey"
                                  >mdi-check</v-icon
                                > -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <span
                    v-if="
                      ['Badges', 'Reward'].includes(item.context) &&
                        timeUntilProgramStart
                    "
                    >This reward is currently locked until the program
                    starts.</span
                  >
                  <!-- <span
                    v-if="
                      ['Badges', 'Reward'].includes(item.context) &&
                        timeSinceProgramEnd
                    "
                    >This reward is currently locked because the program has
                    ended.</span
                  > -->
                  <span v-else-if="['Badges', 'Reward'].includes(item.context)"
                    >This reward is currently locked. To earn it, complete all
                    items in the program.</span
                  >
                  <span v-else-if="item.status == 'Disabled'"
                    >This element is currently locked by an administrator.</span
                  >
                  <span v-else
                    >This element is currently locked. To unlock it, complete
                    all items prior to this one.</span
                  >
                </v-tooltip>
              </div>
            </transition-group>
          </div>
        </v-col>
        <v-col
          v-if="!displayMobileOverview || !isMobile"
          cols="12"
          sm="12"
          md="8"
          lg="8"
          xl="8"
          class="light-grey-background main-col d-flex flex-column justify-space-between"
        >
          <div>
            <v-toolbar
              v-if="isMobile"
              dark
              color="brand"
              rounded="0"
              class="d-flex align-center"
            >
              <v-btn icon color="white" @click="changePageOnMobile(true, 'a')"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              <b class="ml-1">
                Back to overview
              </b>
            </v-toolbar>
            <div
              v-if="previousCard"
              class="previous-card d-flex flex-column justify-center"
              :class="{
                'cursor-pointer': !previousCardIsLocked
              }"
              @click="
                if (previousCardIsLocked) {
                  () => {};
                } else {
                  loadPreviousCard();
                }
              "
            >
              <div>
                Previous card
                <v-icon class="ml-2">{{
                  previousCardIsLocked ? "mdi-lock" : "mdi-chevron-up"
                }}</v-icon>
              </div>
            </div>
          </div>
          <transition :name="transitionDirection" mode="out-in">
            <v-card
              v-if="currentCard"
              :key="currentCard.id"
              class="main-card text-left"
              elevation="0"
              rounded="2"
            >
              <div class="main-card-content">
                <div
                  class="mx-8"
                  v-if="currentElement && currentElement.status == 'Disabled'"
                >
                  <p class="text-h5 mb-15">{{ currentElement.displayName }}</p>
                  <v-divider />
                  <div
                    class="my-8 d-flex flex-column justify-center align-center input-field mx-auto"
                  >
                    This
                    {{
                      currentElement.context == "LearningModules"
                        ? currentElement.type == "Learning"
                          ? "learning module"
                          : "survey"
                        : currentElement.context == "MetricDefinitions"
                        ? "progress metric"
                        : "item"
                    }}
                    is currently locked by an administrator.
                  </div>
                  <v-divider />
                </div>
                <ProgramDetailsLearningCard
                  v-else-if="
                    ['Learning', 'LearningModule', 'LearningModules'].includes(
                      currentCard.context
                    )
                  "
                  :currentCard="currentCard"
                  :nextCard="nextCard"
                  :previousCard="previousCard"
                  @update-card="currentCard = $event"
                  @nextCard="loadNextCard"
                  @postLearningLog="postLearningLog"
                  @lockQuiz="lockQuiz"
                  @unlockQuiz="unlockQuiz"
                />
                <ProgramDetailsMetricCard
                  v-else-if="
                    ['MetricDefinitions', 'Users'].includes(currentCard.context)
                  "
                  :programId="program.programId"
                  :currentCard="currentCard"
                  :nextCard="nextCard"
                  :nextCardIsLocked="nextCardIsLocked"
                  @update-card="currentCard = $event"
                  @nextCard="loadNextCard"
                />
                <ProgramDetailsRewardCard
                  v-else-if="['Badges', 'Reward'].includes(currentCard.context)"
                  :currentCard="currentCard"
                  :nextCard="nextCard"
                  @update-card="currentCard = $event"
                  @nextCard="loadNextCard"
                  @leaveProgram="leaveProgram"
                />
                <ProgramDetailsOverviewCard
                  v-else-if="['Program'].includes(currentCard.context)"
                  :currentCard="currentCard"
                  :programId="program.programId.toString()"
                  @update-card="currentCard = $event"
                  @nextCard="loadNextCard"
                />
              </div>
            </v-card>
          </transition>
          <div
            v-if="nextCard"
            class="next-card d-flex flex-column justify-center"
            @click="
              if (nextCardIsLocked) {
                () => {};
              } else {
                loadNextCard();
              }
            "
            :class="{
              'cursor-pointer': !nextCardIsLocked
            }"
          >
            <!--  (!nextCard.locked && !lockedQuiz) ||
                (['Badges', 'Reward'].includes(nextCard.context) &&
                  forceUnlockReward), -->
            <div>
              Next card
              <v-icon class="ml-2">{{
                nextCardIsLocked ? "mdi-lock" : "mdi-chevron-down"
              }}</v-icon>
              <!-- <v-icon class="ml-2">{{
                !lockedQuiz &&
                ["Badges", "Reward"].includes(nextCard.context) &&
                forceUnlockReward
                  ? "mdi-chevron-up"
                  : "mdi-lock"
              }}</v-icon> -->
            </div>
          </div>
          <!-- We want a div here becase we're on the last card and we use justify-space-between so we need the main card centered  -->
          <div v-else></div>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";

import ProgramCardProgress from "@/components/ProgramCardProgressV2";
import Robin from "@/components/Robin";
import ProgramDetailsLearningCard from "@/components/ProgramDetailsLearningCard";
import ProgramDetailsMetricCard from "@/components/ProgramDetailsMetricCard";
import ProgramDetailsRewardCard from "@/components/ProgramDetailsRewardCard";
import ProgramDetailsOverviewCard from "@/components/ProgramDetailsOverviewCard";

import ProgramService from "@/services/ProgramService.js";
import LearningService from "../services/LearningService";
import ContentService from "@/services/ContentService.js";
import BadgeService from "@/services/BadgeService.js";

import confetti from "canvas-confetti";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions.js";

export default {
  name: "ProgramDetailsV2",
  title: "Whistle | Programs",
  props: {
    programPreloaded: {
      default: null,
      type: Object
    },
    programId: {
      default: null,
      type: String
    },
    sourcePage: {
      default: null,
      type: String
    }
  },
  components: {
    ProgramCardProgress,
    Robin,
    ProgramDetailsLearningCard,
    ProgramDetailsMetricCard,
    ProgramDetailsRewardCard,
    ProgramDetailsOverviewCard
  },
  data() {
    return {
      loading: false,
      program: null,
      tab: 0,
      transitionDirection: "slide-up",
      leaderboard: {
        loading: true,
        programCache: []
      },
      modules: [],
      loadingModules: false,
      currentCardIndex: 0,
      sideNav: {
        textWidth: 225,
        openItemIndex: 0,
        headerHeight: 300
      },
      displayMobileOverview: true,
      preloadRuleIndex: null, //Used if we need to preload a module for the user but have to wait for module response
      preloadRuleContext: null,

      rules: {
        integer: value =>
          (value && Number.isInteger(Number(value))) || "Must be whole number",
        emptyString: value =>
          (value && value.trim() !== "") || "Cannot be empty",
        longString: value =>
          (value && value.length < 256) || "Value is too long"
      },
      programNotFound: false,
      lockedQuiz: false //used to manually lock prev/next buttons if in a quiz
    };
  },
  created() {
    this.getProgramsByUser();
  },
  mounted() {},
  destroyed() {
    window.removeEventListener("resize", this.refreshSideNavTextWidth);
  },
  methods: {
    async getProgramsByUser() {
      // this.getMessages();
      // this.getModules();
      // if (this.programPreloaded === null) {
      console.log("Need to fetch " + this.programId);

      // Adds listener for window size in case it changes so we can recalculate the width of the side nav
      window.addEventListener("resize", this.refreshSideNavTextWidth);
      this.loading = true;
      this.programNotFound = false;
      ProgramService.getProgramsByUserV2(this.programId, false)
        .then(async response => {
          console.log("got program!", response);
          this.loading = false;
          // var program = programs.find((x) => x.programId == this.programId);
          var program = response.rows[0];
          console.log(program);
          let moduleArr = program.ProgramRules.filter(
            x => x.context == "LearningModules"
          ).map(x => x.learningModuleId);
          if (moduleArr.length) this.getModules(moduleArr);
          //Push to programs if the user isn't in this program
          if (
            !program ||
            (!program.qualified && program.status != "Scheduled")
          ) {
            // We route them back if they're not qualified
            console.log("INside");
            this.programNotFound = true;
          } else {
            console.log("PROGRAM: ", program);
            this.program = program;

            // Now let's parse rules and check if we can preload a metric
            var preloadElement = null;

            var unfinishedCards = this.program.ProgramRules.find(
              x => x.progressPercent < 100
            );
            console.log("unfinished cards?");
            console.log(unfinishedCards);

            // We use this as an override. If the user has no progress in anything, we leave them on the overview
            var displayOverview = true;
            // TO DO: 1/26/23 commenting out the preloading so we can actually go to prod. Will investigate how to fix bug
            // Appears if the user hasn't done the first card, the index doesn't change, so that watcher only gets called while the card is loading
            // var displayOverview = !this.program.programRules.find(
            //   x => x.progressPercent > 0 && x.status != "Disabled"
            // );
            // if (!displayOverview) {
            //   this.program.programRules.forEach((el, elIndex) => {
            //     // We must handle disabled entries differently
            //     // If we haven't found an element to preload but we hit a disabled rule, then we just show overview
            //     if (
            //       preloadElement == null &&
            //       el.status == "Disabled" &&
            //       this.program.forceLinearProgress
            //     )
            //       displayOverview = true;
            //     else if (
            //       // If we haven't found anything and have something incomplete
            //       preloadElement == null &&
            //       el.status != "Disabled" &&
            //       parseFloat(el.progressPercent) < 100 &&
            //       !displayOverview
            //     ) {
            //       console.log("Found element to preload ", elIndex);
            //       // If module then we have to store the index so we can load after fetching modules
            //       // if (el.context == "LearningModules")
            //       this.preloadRuleIndex = elIndex;
            //       this.preloadRuleContext = el.context;
            //       preloadElement = elIndex;
            //       // }
            //     }
            //   });
            // }

            //was trying to go straight to last cards, but had issues with learning loading in after and jumping it back
            //taking it out for now

            // if (!unfinishedCards) {
            //   console.log("this fool is done");
            //   this.loadCard(5, undefined, undefined, true);
            // } else if (!displayOverview && preloadElement != null) {
            //   // Preload metric if available
            //   this.loadCard(preloadElement + 1, undefined, undefined);
            // }

            if (!displayOverview && preloadElement != null) {
              // Preload metric if available
              this.loadCard(preloadElement + 1, undefined, undefined);
            }
          }
        })
        .catch(err => {
          console.log("Error getting program ", err);
          this.programNotFound = true;
        });
    },
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    leaveProgram() {
      console.log("leaveProgram()", this.sourcePage);
      if (this.sourcePage) {
        if (this.sourcePage == "robin") {
          this.$router.push({
            name: "robin"
          });
        } else if (this.sourcePage == "activity") {
          this.$router.push({
            name: "activity"
          });
        } else if (this.sourcePage == "programs") {
          // Added this so Router keeps previous query params if coming from programs
          this.$router.back();
        } else if (this.sourcePage == "programwizard") {
          // console.log("pushing to program wizard");
          // console.log(this.program);
          this.$router.push({
            name: "programadmin"
          });
        } else {
          this.$router.push({
            name: "programs"
          });
        }
      } else {
        this.$router.push({
          name: "programs"
        });
      }
    },
    changePageOnMobile(newVal) {
      if (this.isMobile) {
        this.displayMobileOverview = newVal;
        // alert("displayMobileOverview " + newVal + " " + caller);
      }
    },
    downloadProgramAttachment(item) {
      ContentService.downloadContent(item.contentId, item.Content.filePath);
    },
    getModules(idArray) {
      console.log("Getting program learning modules ", idArray);
      this.loadingModules = true;
      LearningService.getModuleIndex(idArray)
        .then(modules => {
          console.log("Program Learning Modules: ", modules);
          // For each module, we have to find if they were meant to be a qualifier or payout rule
          this.modules = modules.map(x => {
            x.loading = false;
            x.id = x.learningModuleId;
            x.context = "LearningModules";
            x.progressPercent = x.progressPercent * 100;
            // x.icon =
            //   x.type == "Learning"
            //     ? "mdi-school"
            //     : "mdi-comment-question-outline";
            // x.iconColor = "brand";
            // x.displayName = x.moduleName;
            let foundModule = this.program.ProgramRules.find(
              x => x.learningModuleId == x.learningModuleId
            );
            if (foundModule) x.programType = foundModule.type;
            else x.programType = "PAYOUT_RULE";
            // x.programType = null;

            // We want to add a card for learning resources if any
            if (
              x.type == "Learning" &&
              x.LearningAttachments &&
              x.LearningAttachments.length
            ) {
              let id = Math.random() * 100;
              x.LearningChapters[
                x.LearningChapters.length - 1
              ].LearningCards.push({
                id: id,
                learningCardId: id,
                type: "learning_resources",
                calculateProgress: false,
                displayName: "Additional resources",
                LearningAttachments: x.LearningAttachments
              });
            }
            return x;
          });

          // setTimeout(() => {
          this.loadingModules = false;

          this.$nextTick(() => {
            this.refreshSideNavTextWidth();
          });

          // Now preload module/chapter/card if that's next for the user
          var moduleIndex = null;
          var chapterIndex = null;
          var cardIndex = null;
          if (this.preloadRuleIndex != null) {
            // If it's a metric then we intentionally call loadCard again because the current card index won't change, but the number of cards will change now that we have modules
            if (this.preloadRuleContext != "LearningModules")
              return this.loadCard(
                this.preloadRuleIndex + 1,
                undefined,
                undefined
              );
            // find module
            let rule = this.program.ProgramRules[this.preloadRuleIndex];
            if (rule) {
              let module = this.modules.find(
                x => x.learningModuleId == rule.learningModuleId
              );
              if (module) {
                // Parse module until we find
                module.LearningChapters.forEach((chapter, chIndex) => {
                  chapter.LearningCards.forEach((card, cIndex) => {
                    if (moduleIndex == null && card.progressPercent < 100) {
                      moduleIndex = this.preloadRuleIndex;
                      chapterIndex = chIndex;
                      cardIndex = cIndex;
                    }
                  });
                });
              }
            }
          }

          if (moduleIndex != null) {
            // Preload metric if available
            console.log("Preloading module at index ", moduleIndex);
            this.loadCard(moduleIndex + 1, chapterIndex, cardIndex);
          }
          // }, 1500);
          // }
        })
        .catch(err => {
          console.log("Error getting modules ", err);
          this.loadingModules = false;
        });
    },
    loadLearningModule(moduleId) {
      this.$router.push({
        name: "learningdetails",
        params: {
          learningId: moduleId.toString(),
          sourcePage: "programdetails",
          sourcePageId: this.programId.toString()
        }
      });
    },
    loadSurveyModule(moduleId) {
      this.$router.push({
        name: "surveyPrivate", //we know it's a private survey because public surveys can't be in programs
        params: {
          surveyId: moduleId.toString(),
          sourcePage: "programdetails",
          sourcePageId: this.programId.toString()
        }
      });
    },
    formatRules() {
      // this.loadingMetricValues = true;
      // Array of metric ids for the program
      const ids = [];
      // if MetricDefinition !== null it's a metric!
      this.program.ProgramRules.forEach(rule => {
        if (rule.MetricDefinition) {
          // Attach a metricValue property to hold the new value
          // rule.metricValue = null;
          this.$set(rule, "metricValue", null);
          ids.push(rule.MetricDefinition.metricId);
        }
      });
      const body = {
        metrics: ids,
        users: [this.userProfile.userId]
      };
      ProgramService.getLatestMetricValues(body)
        .then(res => {
          console.log("got latest metric values ", res);
          const values = res.programMetric[this.userProfile.userId];
          console.log("values ", values);
          for (let i = 0; i < this.program.ProgramRules.length; i++) {
            const currentRule = this.program.ProgramRules[i];
            // If the current rule has an id that's in the programMetrics/recent resposne
            // Attach the last updated date
            if (
              currentRule.MetricDefinition &&
              values[currentRule.MetricDefinition.metricId]
            ) {
              // const metricValue =
              //   values[currentRule.MetricDefinition.metricId].metricValue;
              // const latestMetricValue =
              //   values[currentRule.MetricDefinition.metricId].metricValue;
              const lastUpdated = moment(
                values[currentRule.MetricDefinition.metricId].date
              ).format("MM/DD/YYYY");
              // this.$set(
              //   this.program.ProgramCache[i].programRules[j],
              //   "metricValue",
              //   metricValue
              // );
              // this.$set(
              //   this.program.ProgramCache[i].programRules[j],
              //   "latestMetricValue",
              //   latestMetricValue
              // );
              this.$set(
                this.program.ProgramRules[i],
                "lastUpdated",
                lastUpdated
              );
            }
          }
          console.log("program!!!!", this.program);
          // this.loadingMetricValues = false;
        })
        .catch(error => {
          console.log("error getting latest metric values", error);
          // this.loadingMetricValues = false;
        });
    },
    refreshSideNavTextWidth() {
      // This is used to cap the width of the text in the left side nav
      // The text width gets weird when combining the button on the right side, padding, and the text ellipsis
      setTimeout(() => {
        if (document.getElementsByClassName("side-nav-box").length > 0) {
          this.sideNav.textWidth =
            document.getElementsByClassName("side-nav-box")[0].offsetWidth - 40;
        } else {
          this.sideNav.textWidth = 225;
        }

        if (document.getElementsByClassName("side-nav-header").length > 0) {
          this.sideNav.headerHeight =
            document.getElementsByClassName("side-nav-header")[0].offsetHeight +
            30;
        } else {
          this.sideNav.headerHeight = 225;
        }

        // we also reset the mobile display variable when screen size changes;
        // this.displayMobileOverview = true;
        // this.changePageOnMobile(true, "d");
      }, 30);
    },
    loadPreviousCard() {
      if (this.previousCard && (this.previousCard.locked || this.lockedQuiz)) {
        console.log(
          "Returning early. Not loading previous card becuase it's locked"
        );
        return;
      }
      this.transitionDirection = "slide-down";
      this.currentCardIndex--;
      if (
        this.programCards[this.currentCardIndex] &&
        this.programCards[this.currentCardIndex].elementIndex !=
          this.sideNav.openItemIndex
      )
        this.sideNav.openItemIndex = this.programCards[
          this.currentCardIndex
        ].elementIndex;

      this.scrollToTop();
      // We unlock just in case whenever the user changes cards
      this.unlockQuiz();
    },
    loadNextCard(routeToLoad = null) {
      if (
        this.nextCard &&
        // The next card is locked
        // this.nextCard.locked &&
        // And either it's a quiz that hasn't been passed
        (this.lockedQuiz ||
          // OR it's a reward and they're not on the last card
          (["Badges", "Reward"].includes(this.nextCard.context) &&
            !this.forceUnlockReward))
      ) {
        console.log(
          "Returning early. Not loading next card becuase it's locked or it's a quiz"
        );
        return;
      }

      // This is so we can unlock the next button for the last card in an element just so they can submit a learning log, but it leaves the element locked
      const leaveCardLocked =
        (this.nextCard &&
          this.currentCard.elementIndex != this.nextCard.elementIndex &&
          this.nextElement &&
          this.nextElement.status == "Disabled") ||
        this.nextCardIsLocked;

      console.log("Leave card locked? ", leaveCardLocked);

      // If we need to post a learning log, we do so here without waiting
      // if (
      //   ["LearningModules", "Survey"].includes(this.currentCard.context) &&
      //   (!this.currentCard.type ||
      //     (this.currentCard.type &&
      //       !["quiz", "survey", "assessment", "learning_resources"].includes(
      //         this.currentCard.type
      //       )))
      // ) {
      //   this.postLearningLog(null, null, leaveCardLocked);
      // }

      // Before we continue, we want to check the progress of the current program element
      // IF it's learning or survey, it has a badge, and we haven't issued that badge already, then we post
      const elIndex = this.currentCard.elementIndex;
      if (
        ["LearningModules", "Survey"].includes(this.currentCard.context) &&
        this.programElements[elIndex].progressPercent == 100 &&
        this.programElements[elIndex].badgeId &&
        (!this.programElements[elIndex].UserBadges ||
          this.programElements[elIndex].UserBadges.length == 0)
      ) {
        // && this.programElements[this.currentCard.elementIndex]
        var userBadge = {
          clientId: this.userProfile.clientId,
          userId: this.userProfile.userId,
          badgeId: this.programElements[elIndex].badgeId,
          status: "Active",
          createdBy: this.userProfile.userId,
          updatedBy: this.userProfile.userId
        };

        BadgeService.createUserBadge(userBadge).then(userBadge => {
          console.log("User badge response: ", userBadge);
          this.programElements[elIndex].UserBadges = [userBadge];
        });
      }

      console.log("Loading next card ");
      //do confetti checks now
      //we actually want to look at where we're going, not where we are, hence the var below
      var loadingCardIndex = this.currentCardIndex + 1;
      console.log(this.currentCardIndex + " +1 = " + loadingCardIndex);

      //have to +1 because index vs length, you know the drill
      // Play confetti for final card in program
      if (loadingCardIndex + 1 == this.programCards.length) {
        this.playConfetti("special");
        // Play confetti for finishing a module
      } else if (
        this.programCards[loadingCardIndex] &&
        this.programCards[loadingCardIndex].context == "LearningModules" &&
        this.programCards[loadingCardIndex].type !== "learning_resources" &&
        this.programCards[loadingCardIndex].moduleCurrentChapterIndex + 1 ==
          this.programCards[loadingCardIndex].moduleTotalChapters
      ) {
        // console.log("YES confeti");
        // console.log(this.currentCardIndex + " +1");
        // console.log(
        //   this.programCards[loadingCardIndex].moduleCurrentChapterIndex +
        //     " +1 should == " +
        //     this.programCards[loadingCardIndex].moduleTotalChapters
        // );
        //console.log(this.programCards[loadingCardIndex].context);
        this.playConfetti();
      } else {
        // console.log("NO confeti");
        // console.log(this.currentCardIndex);
        // console.log(loadingCardIndex);
        // console.log(
        //   this.programCards[loadingCardIndex].moduleCurrentChapterIndex +
        //     " +1 should == " +
        //     this.programCards[loadingCardIndex].moduleTotalChapters
        // );
        // console.log(this.programCards[loadingCardIndex].context);
      }

      this.transitionDirection = "slide-up";
      this.currentCardIndex++;
      console.log("Current index ", this.currentCardIndex);
      // We're at the end
      if (this.currentCardIndex >= this.programCards.length) {
        console.log("We're at the end ", routeToLoad);
        // if (typeof routeToLoad == "string" && routeToLoad == "wallet")
        //   this.$router.push("/wallet");
        // else
        this.leaveProgram();
        return;
      }
      // If we're loading next card, open it up
      else if (
        this.programCards[this.currentCardIndex] &&
        this.programCards[this.currentCardIndex].elementIndex !=
          this.sideNav.openItemIndex
      ) {
        this.sideNav.openItemIndex = this.programCards[
          this.currentCardIndex
        ].elementIndex;
        console.log("open next card up");
      }

      // Unlock the current card if it's locked
      if (
        !leaveCardLocked &&
        this.currentCard &&
        this.nextCard &&
        this.currentCard.elementIndex != this.nextCard.elementIndex &&
        this.programCards[this.currentCardIndex] &&
        this.programCards[this.currentCardIndex].locked &&
        // Must not be a reward OR if it is, then they have to be on the last card
        (!["Badges", "Reward"].includes(
          this.programCards[this.currentCardIndex].context
        ) ||
          this.forceUnlockReward)
      )
        this.programCards[this.currentCardIndex].locked = false;

      this.scrollToTop();

      // We unlock just in case whenever the user changes cards
      this.unlockQuiz();
    },
    loadCard(
      elementIndex,
      chapterIndex,
      cardIndex,
      wipePreloadContext = false
    ) {
      console.log({ elementIndex, chapterIndex, cardIndex });
      let index = this.programCards.findIndex(
        x =>
          x.elementIndex == elementIndex &&
          x.chapterIndex == chapterIndex &&
          x.cardIndex == cardIndex
      );
      console.log(this.programCards);
      console.log("element index", elementIndex);
      console.log("CARD INDEX ", index);
      console.log(this.programCards[index]);
      if (index != -1) {
        this.currentCardIndex = index;
        // We unlock just in case whenever the user changes cards
        this.unlockQuiz();
      }
      this.sideNav.openItemIndex = elementIndex;
      this.scrollToTop();
      this.changePageOnMobile(false, "b");

      //play special confetti if end of program

      // if (this.currentCardIndex + 1 == this.programCards.length) {
      //   this.playConfetti("special");
      // } else if (
      //   this.programCards[index].moduleCurrentChapterIndex + 1 ==
      //     this.programCards[index].moduleTotalChapters &&
      //   this.programCards[index].context == "LearningModules"
      // ) {
      //   this.playConfetti();
      // }

      if (wipePreloadContext) {
        console.log("WIPING PRELOAD");
        this.preloadRuleContext = null;
        this.preloadRuleIndex = null;
      }
    },
    loadSurvey(elementIndex, wipePreloadContext) {
      let index = this.programCards.findIndex(
        x => x.elementIndex == elementIndex
      );
      console.log("CARD INDEX ", index);
      if (index != -1) {
        this.currentCardIndex = index;
        // We unlock just in case whenever the user changes cards
        this.unlockQuiz();
      }
      this.sideNav.openItemIndex = elementIndex;
      this.scrollToTop();
      this.changePageOnMobile(false, "c");

      if (wipePreloadContext) {
        console.log("WIPING PRELOAD");
        this.preloadRuleContext = null;
        this.preloadRuleIndex = null;
      }
    },
    scrollToTop() {
      // TO DO!!!
      // if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
      //   document.getElementsByClassName(
      //     "v-dialog v-dialog--active"
      //   )[0].scrollTop = 0;
      // } else {
      //   const col = document.getElementsByClassName("main-col");
      //   console.log(col.length);
      //   if (col.length > 0) {
      //     console.log("Scrolling");
      //     const rect = col[0].getBoundingClientRect();
      //     console.log(rect.top);
      //     window.scrollTo(0, rect.top);
      //   }
      // }
    },
    async playConfetti(type = "normal") {
      console.log("play confetti! type:" + type);
      // We're in the last chapter, play confetti

      const confettiColors = Array();
      //blues
      confettiColors[0] = ["#0dace9", "#0394da", "#56c4f9", "#3fe6ff"];
      //greens
      confettiColors[1] = ["#a1d86f", "#69c69d", "#65c733", "#0fe283"];

      //purples
      confettiColors[2] = ["#720fe2", "#9440f2", "#8822df", "#6a029f"];

      //pinks
      confettiColors[3] = ["#c101f1", "#e627f7", "#c500a2", "#ec4ed0"];

      //yellow/orange
      confettiColors[4] = ["#f1cc00", "#ffda6b", "#fac129", "#ffdc1b"];

      //rainbow
      confettiColors[5] = [
        "#f1cc00",
        "#e627f7",
        "#6a029f",
        "#a1d86f",
        "#3fe6ff"
      ];

      if (type != "special") {
        var rando = Math.floor(Math.random() * (confettiColors.length - 2));
        console.log(rando);
        var randColor = confettiColors[rando];
      } else {
        randColor = confettiColors[5];
      }

      confetti({
        particleCount: 300,
        zIndex: 999,
        spread: 70,
        scalar: 1.13,
        colors: randColor,
        origin: { y: 0.9, x: 0.56 }
      });
      confetti({
        particleCount: 120,
        spread: 200,
        decay: 0.91,
        scalar: 0.8,
        zIndex: 999,
        colors: randColor,
        origin: { y: 0.8, x: 0.52 }
      });
      confetti({
        particleCount: 150,
        spread: 120,
        startVelocity: 45,
        scalar: 0.8,
        zIndex: 999,
        colors: randColor,
        origin: { y: 0.6, x: 0.54 }
      });

      if (type == "special") {
        await new Promise(resolve => setTimeout(resolve, 300));

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[0],
          origin: { y: 0.3, x: 0.2 }
        });

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[1],
          origin: { y: 0.2, x: 0.8 }
        });
        await new Promise(resolve => setTimeout(resolve, 200));

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[1],
          origin: { y: 0.74, x: 0.2 }
        });

        confetti({
          particleCount: 80,
          spread: 120,
          startVelocity: 25,
          scalar: 0.7,
          zIndex: 999,
          colors: confettiColors[0],
          origin: { y: 0.74, x: 0.8 }
        });
      }
    },
    postLearningLog(
      quizScore = null,
      userAnswers = null,
      leaveCardLocked = false
    ) {
      console.log("quiz score", quizScore);
      var body = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        learningCardId: this.currentCard.learningCardId,
        learningCardAssociationId: this.currentCard.learningCardAssociationId,
        completed:
          // If quiz score isn't set, OR they didn't define both a passing score and set passing required to true, we auto set completed to true
          quizScore == null ||
          !(this.currentCard.passingRequired && this.currentCard.passingScore)
            ? true
            : this.currentCard.passingRequired &&
              this.currentCard.passingScore &&
              quizScore &&
              quizScore >= this.currentCard.passingScore
            ? true
            : false,
        completedDate: moment()
          .utc()
          .format("YYYY-MM-DD HH:mm:ssZ"),
        learnerRating: this.currentCard.learnerRating
          ? this.currentCard.learnerRating
          : null,
        learnerConfidence: this.currentCard.learnerConfidence
          ? this.currentCard.learnerConfidence
          : null,
        score: quizScore,
        answersGiven: userAnswers ? userAnswers.join(",") : undefined
      };

      // We have to figure out if this is the last card for the module
      // If they're taking a survey, we try and also log the next card
      // We require that they complete the survey card
      var conclusionLog = null;
      if (
        body.completed &&
        this.currentElement &&
        this.currentElement.type == "Survey" &&
        this.currentCard &&
        this.nextCard &&
        this.currentCard.type == "survey" &&
        this.currentCard.elementIndex == this.nextCard.elementIndex
      ) {
        conclusionLog = {
          userId: this.userProfile.userId,
          clientId: this.userProfile.clientId,
          learningCardId: this.nextCard.learningCardId,
          learningCardAssociationId: this.nextCard.learningCardAssociationId,
          completed:
            // If quiz score isn't set, OR they didn't define both a passing score and set passing required to true, we auto set completed to true
            true,
          completedDate: moment()
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ"),
          learnerRating: this.nextCard.learnerRating
            ? this.nextCard.learnerRating
            : null,
          learnerConfidence: this.nextCard.learnerConfidence
            ? this.nextCard.learnerConfidence
            : null,
          score: null,
          answersGiven: null
        };

        // backup in case so we don't ruin the good quiz log above
        if (
          !conclusionLog.learningCardAssociationId ||
          !conclusionLog.learningCardId
        )
          conclusionLog = null;
      }

      console.log("-------------this is the body-------------");
      console.log(body);
      if (body.completed) {
        this.currentCard.progressPercent = 100;
        if (conclusionLog) this.nextCard.progressPercent = 100;
        this.recalculateElementProgress(this.currentCard.elementIndex);
        if (this.nextCard && !leaveCardLocked) this.nextCard.locked = false;
      }
      LearningService.createLearningLog(
        conclusionLog ? [body, conclusionLog] : body,
        (this.currentElement && this.currentElement.AssociatedPrograms) || []
      );
      this.currentCard.latestLearningLog = body;

      if (conclusionLog) {
        console.log("Storing conclusion log for survey", conclusionLog);
        this.nextCard.latestLearningLog = conclusionLog;
      }
    },
    recalculateElementProgress(elIndex) {
      var el = this.programElementsWithReward[elIndex];
      if (el && el.LearningChapters) {
        var completedCards = 0;
        var numCards = 0;
        el.LearningChapters.forEach(chapter => {
          chapter.LearningCards.forEach(card => {
            if (card.calculateProgress != false) {
              numCards++;
              if (card.progressPercent == 100) completedCards++;
            }
          });
        });

        if (numCards == 0) el.progressPercent = 0;
        else el.progressPercent = (completedCards / numCards) * 100;

        console.log("Recalculating progress ", el.progressPercent);
      }
    },
    lockQuiz() {
      console.log("Locking buttons due to quiz");
      this.lockedQuiz = true;
    },
    unlockQuiz() {
      console.log("Unlocking buttons due to quiz");
      this.lockedQuiz = false;
    }
  },
  computed: {
    ...mapState(["userProfile"]),
    computedNextStep() {
      if (
        this.modules.length == 0 ||
        !this.program ||
        this.program.status == "Archived"
      ) {
        return null;
      }

      let targetModule = null;

      for (let i = 0; i < this.modules.length; i++) {
        //if module not complete but started display

        if (
          this.modules[i].progressPercent >= 0 &&
          this.modules[i].progressPercent < 100 &&
          !targetModule
        ) {
          targetModule = this.modules[i];

          break;
        }
      }
      return targetModule;
    },
    cumulativeProgramProgress() {
      var avgProgress = 0;
      var len = this.programElements.filter(x => x.calculateProgress != false)
        .length;
      this.programElements.forEach(rule => {
        if (
          rule.progressPercent &&
          rule.calculateProgress != false &&
          rule.status != "Disabled"
        ) {
          avgProgress += parseFloat(rule.progressPercent);
        }
      });
      console.log("Valid Program elements len ", len);
      if (len == 0) {
        return 0;
      } else {
        return currency(avgProgress / len).value;
      }
      // return "0";
    },
    forceUnlockReward() {
      // This is used to unlock rewards if they're on the last card
      // If the program has any disabled elements, we know we can't unlock the card
      if (this.programElements.find(x => x.status == "Disabled")) return false;
      // If the program hasn't started, we also lock it
      else if (this.timeUntilProgramStart) return false;
      // else if (this.timeSinceProgramEnd) return false;
      var numCompleted = this.programCards.filter(
        x =>
          !["Badges", "Reward", "Program"].includes(x.context) &&
          x.progressPercent >= 100
      ).length;

      var totalNum = this.programCards.filter(
        x => !["Badges", "Reward", "Program"].includes(x.context)
      ).length;

      console.log("Force unlock reward?");
      console.log("Total num of cards ", totalNum);
      console.log("Num completed ", numCompleted);

      // If we have one last card and it's the one right before a reward
      if (this.cumulativeProgramProgress >= 100) return true;
      else if (
        // Must have 1 or 0 cards left
        totalNum - numCompleted <= 1 &&
        this.currentCard &&
        //
        // this.currentCard.progressPercent < 100 &&
        this.nextCard &&
        // Next card must be a reward
        ["Badges", "Reward"].includes(this.nextCard.context) &&
        // The user must be on a learning card (because they can't complete the program without hitting the next button)
        ((this.currentCard.context != "MetricDefinitions" &&
          this.currentCard.progressPercent < 100) ||
          // Or if they are on metrics, we can only unlock if they've literally done everything
          (this.currentCard.context == "MetricDefinitions" &&
            this.currentCard.progressPercent == 100 &&
            totalNum - numCompleted == 0))
      )
        return true;
      return false;
      //  return totalNum - numCompleted;
    },
    // computedLearningModules() {
    //   if (!this.program) return [];
    //   if (
    //     !this.program.qualified &&
    //     this.modules.filter(x => x.programType == "QUALIFIERS").length > 0
    //   ) {
    //     return this.modules.filter(x => x.programType == "QUALIFIERS");
    //   }
    //   return this.modules.filter(x => x.programType == "PAYOUT_RULE");
    // },
    computedRulesToDisplay: {
      cache: false,
      get: function() {
        if (!this.program || !this.program.ProgramRules) {
          return [];
        } else {
          // if (!this.program.qualified) {
          //   return this.program.ProgramRules.filter(x => {
          //     return (
          //       ["Active", "Disabled"].includes(x.ruleGroupVisibility) &&
          //       (x.context == "Users" || x.context == "MetricDefinitions")
          //     );
          //   });
          // } else {
          return this.program.ProgramRules.filter(x => {
            return (
              ["Active", "Disabled"].includes(x.status) &&
              (x.context == "Users" || x.context == "MetricDefinitions")
            );
          });
          // }
        }
      }
    },
    // computedAwardsToDisplay() {
    //   let cash = [];
    //   let cashPayPer = [];
    //   let noncash = [];
    //   let noncashPayPer = [];
    //   let badge = [];
    //   // let payPer = [];
    //   if (this.program == null) {
    //     return null;
    //   } else {
    //     this.program.ProgramCache.forEach(cache => {
    //       // If PAYOUT_RULE && badge
    //       // TO DO: simplify awards to display and remove ruleGroupVisibility
    //       if (
    //         cache.type == "PAYOUT_RULE" &&
    //         cache.badgeId !== null &&
    //         cache.badgeName &&
    //         cache.ruleGroupVisibility != "Hidden"
    //       ) {
    //         badge.push(cache);
    //         // PAYOUT_RULE && CASH
    //       }
    //       if (
    //         cache.type == "PAYOUT_RULE" &&
    //         cache.payoutAwardId == 1 &&
    //         cache.ruleGroupVisibility != "Hidden"
    //       ) {
    //         if (cache.payoutRuleType == "PAY_PER") {
    //           cashPayPer.push(cache);
    //         } else {
    //           cash.push(cache);
    //         }
    //         // Other PAYOUT_RULE
    //       } else if (
    //         cache.type == "PAYOUT_RULE" &&
    //         cache.badgeId == null &&
    //         cache.ruleGroupVisibility != "Hidden"
    //       ) {
    //         if (cache.payoutRuleType == "PAY_PER") {
    //           noncashPayPer.push(cache);
    //         } else {
    //           noncash.push(cache);
    //         }
    //       }
    //     });
    //   }
    //   // merge arrays so pay per awards are last
    //   if (cashPayPer.length > 0) {
    //     cash.push(...cashPayPer);
    //   }
    //   if (noncashPayPer.length > 0) {
    //     noncash.push(...noncashPayPer);
    //   }
    //   // Check if each array is emtpy
    //   let award = {};
    //   if (cash.length > 0) {
    //     award.Cash = cash;
    //     // Additionally, add up sum of cash
    //     // Moving to computed
    //     // award.cashSum = cash.reduce((prev, curr) => {
    //     //   if (curr.payoutRuleType == "HIT_AND_WIN") {
    //     //     return prev + curr.payoutRuleAmount;
    //     //   }
    //     // }, 0);
    //   }
    //   if (noncash.length > 0) {
    //     // award.Noncash = noncash;
    //     // Iterate over noncash, seperate into smaller arrays by payoutAwardName
    //     const sortedNoncash = {};
    //     for (let i = 0; i < noncash.length; i++) {
    //       // SortedNonCash does not have a noncash[i].payoutAwardName (ex. Boaty Boats)
    //       if (sortedNoncash[noncash[i].payoutAwardName] == undefined) {
    //         // Initialize property and empty array
    //         sortedNoncash[noncash[i].payoutAwardName] = [];
    //       }
    //       // Always push the award, because we either created the array or it exists
    //       sortedNoncash[noncash[i].payoutAwardName].push(noncash[i]);
    //     }
    //     console.log("sortedNonCash object", sortedNoncash);
    //     // Once our loop is over, attach each award array in sortedNonCash
    //     // To the final award object
    //     for (let noncashAward in sortedNoncash) {
    //       award[noncashAward] = sortedNoncash[noncashAward];
    //     }
    //   }
    //   if (badge.length > 0) {
    //     award.Badge = badge;
    //   }
    //   // if (payPer.length > 0) {
    //   //   award.PayPer = payPer;
    //   // }
    //   if (Object.keys(award).length == 0) {
    //     return null;
    //   } else {
    //     console.log(award);
    //     return award;
    //   }
    // },
    programElements() {
      var arr = [];
      var rules =
        this.program && this.program.ProgramRules
          ? this.program.ProgramRules
          : [];
      if (!this.program) return [];
      // if (this.program && this.program.programRules) {
      //   rules = this.program.programRules;
      // }

      // First we add the program info elements
      let infoCards = [
        {
          context: "Program",
          id: 0,
          learningCardId: 0,
          displayName: "Summary",
          calculateProgress: false,
          description: this.program.description,
          displayImage: this.program.imageURL ? true : false,
          imageURL: this.program.imageURL,
          startDate: this.program.startDate,
          endDate: this.program.endDate
        }
      ];
      if (this.program.displayLeaderboard)
        infoCards.push({
          context: "Program",
          id: 1,
          learningCardId: 1,
          displayName: "Leaderboard",
          calculateProgress: false,
          displayImage: false,
          displayLeaderboard: true,
          displayResources: false
        });
      if (
        this.program.ProgramAttachments &&
        this.program.ProgramAttachments.length
      )
        infoCards.push({
          context: "Program",
          id: 2,
          learningCardId: 2,
          displayName: "Resources",
          calculateProgress: false,
          displayImage: false,
          displayLeaderboard: false,
          displayResources: true,
          ProgramAttachments: this.program.ProgramAttachments
        });
      arr.push({
        id: -1,
        context: "Program",
        icon: "mdi-information-variant",
        iconColor: "brand",
        displayName: "Program Overview",
        calculateProgress: false,
        LearningChapters: [{ learningChapterId: 0, LearningCards: infoCards }]
      });

      // Must figure out the lock state of each element
      var firstUnlockedElementIndex = null;
      var blockUserWithDisabledElement = false;
      rules.forEach((rule, index) => {
        // This is used so that if linear progression is enabled, then we must block the user from everything after
        if (rule.status == "Disabled" && this.program.forceLinearProgress)
          blockUserWithDisabledElement = true;

        if (rule.context == "LearningModules" && !this.loadingModules) {
          var module = this.modules.find(
            x => x.learningModuleId == rule.learningModuleId
          );

          if (module) {
            module.icon = rule.icon;
            module.iconColor = rule.iconColor;

            if (rule.status == "Disabled") module.status = "Disabled";
            // We lock the module if they haven't started it yet and it's not the next one they have to do
            module.locked =
              (this.program.forceLinearProgress &&
                // module.progressPercent < 100 &&
                firstUnlockedElementIndex != null) ||
              module.status == "Disabled" ||
              blockUserWithDisabledElement;

            // Order is important here. We want to figure out the locked state first before assigning the index var or else it will lock every element
            // We look for the first item that the user has started but not finished
            if (
              (this.program.forceLinearProgress &&
                firstUnlockedElementIndex == null &&
                module.progressPercent != 100) ||
              module.status == "Disabled"
            ) {
              console.log("Found first element to unlock ", index);
              firstUnlockedElementIndex = index;
            }
            arr.push(module);
          }
        } else if (rule.context == "LearningModules") {
          // We're loading modules, so we show a filler
          arr.push({ loading: true, id: rule.id, context: "LearningModules" });
        } else if (
          rule.context == "MetricDefinitions" ||
          rule.context == "Users"
        ) {
          var metric = this.computedRulesToDisplay.find(
            x => x.id == rule.id //x.metricId == rule.metricId
          );
          if (metric) {
            // We lock the metric if they haven't started it yet and it's not the next one they have to do
            metric.locked =
              (this.program.forceLinearProgress &&
                // metric.progressPercent < 100 &&
                firstUnlockedElementIndex != null) ||
              rule.status == "Disabled" ||
              blockUserWithDisabledElement ||
              // Lock while we're fetching modules so the user can't accidentally click something that will be locked once modules load
              (this.program.forceLinearProgress && this.loadingModules);

            // Order is important here. We want to figure out the locked state first before assigning the index var or else it will lock every element
            // We look for the first item that the user has not finished
            if (
              (this.program.forceLinearProgress &&
                firstUnlockedElementIndex == null &&
                metric.progressPercent != 100) ||
              rule.status == "Disabled"
            ) {
              console.log("Found first element to unlock ", index);
              firstUnlockedElementIndex = index;
            }

            arr.push(metric);
          }
        }
      });
      // if (this.computedLearningModules) {
      //   this.computedLearningModules.forEach((module) => {
      //     module.LearningChapters.forEach((chapter) => {
      //       if (chapter.LearningCards) arr.push(...chapter.LearningCards);
      //     });
      //   });
      // }

      return arr;
    },
    programElementsWithReward() {
      // We have to keep this separately because cumulativeProgramProgress uses programElements
      // The reward logic relies on cumulativeProgramProgress, so if that was in programElements, it would be nested and a stack size err occurs
      let arr = this.programElements;
      // Now add program reward
      if (
        this.program &&
        this.program.ProgramAwards &&
        this.program.ProgramAwards.length
      ) {
        arr = arr.concat(
          this.program.ProgramAwards.map(x => {
            // x.iconColor = "brand-dark-green";
            x.locked =
              this.cumulativeProgramProgress < 100 ||
              this.timeUntilProgramStart;
            return x;
          })
        );
      }
      return arr;
    },
    programCards() {
      var arr = [];

      if (this.programElementsWithReward) {
        this.programElementsWithReward.forEach((item, elIndex) => {
          if (item.LearningChapters) {
            var chapterLength = item.LearningChapters.length;
            var currentChapterIndex = 0;
            console.log("cjapter lenth:" + chapterLength);

            item.LearningChapters.forEach((chapter, chapterIndex) => {
              if (chapter.LearningCards) {
                arr.push(
                  ...chapter.LearningCards.map((x, cardIndex) => {
                    x.context = item.context; //"LearningModules";
                    x.id = x.learningCardId;
                    x.elementIndex = elIndex;
                    x.chapterIndex = chapterIndex;
                    x.cardIndex = cardIndex;
                    x.moduleTotalChapters = chapterLength;
                    x.moduleCurrentChapterIndex = currentChapterIndex;
                    return x;
                  })
                );
              }
              currentChapterIndex++;
            });
          } else {
            item.elementIndex = elIndex;
            arr.push(item);
          }
        });
      }
      return arr;
    },
    previousCard() {
      if (
        this.currentCardIndex > 0 &&
        this.programCards[this.currentCardIndex - 1]
      )
        return this.programCards[this.currentCardIndex - 1];
      return null;
    },
    currentCard() {
      if (this.programCards[this.currentCardIndex])
        return this.programCards[this.currentCardIndex];
      return null;
    },
    nextCard() {
      if (this.programCards[this.currentCardIndex + 1]) {
        return this.programCards[this.currentCardIndex + 1];
      } else {
        return null;
      }
    },
    previousElement() {
      if (
        this.currentCard &&
        this.currentCard.elementIndex > 0 &&
        this.programElementsWithReward[this.currentCard.elementIndex - 1]
      )
        return this.programElementsWithReward[
          this.currentCard.elementIndex - 1
        ];
      return null;
    },
    currentElement() {
      if (this.currentCard)
        return this.programElementsWithReward[this.currentCard.elementIndex];
      return null;
    },
    nextElement() {
      if (
        this.currentCard &&
        this.programElementsWithReward[this.currentCard.elementIndex + 1]
      )
        return this.programElementsWithReward[
          this.currentCard.elementIndex + 1
        ];
      return null;
    },
    nextCardIsLocked() {
      if (!this.nextCard || !this.currentCard) return true;
      return (
        this.lockedQuiz ||
        // Lock the button if we're on a learning module and it's disabled so they can't proceed into the module
        (this.currentElement &&
          this.currentElement.context == "LearningModules" &&
          this.currentElement.status == "Disabled") ||
        // If the next card isn't a part of this same element
        (this.currentCard.elementIndex != this.nextCard.elementIndex &&
          this.nextElement &&
          // Unlock card if the next card is unlock or it is locked and they're on the last card of the module
          ((this.program.forceLinearProgress &&
            this.nextElement.locked &&
            !this.onFinalCardOfModule) ||
            // OR if the next element is disabled and either it isn't a module or they're on the last card of a module
            (this.nextElement.status == "Disabled" &&
              (!this.onFinalCardOfModule ||
                this.currentElement.context != "LearningModules")))) ||
        (["Badges", "Reward"].includes(this.nextCard.context) &&
          !this.forceUnlockReward)
      );
    },
    previousCardIsLocked() {
      if (!this.currentCard || !this.previousCard) return true;
      return (
        this.lockedQuiz ||
        (this.previousElement && this.previousElement.status == "Disabled") ||
        this.previousCard.locked ||
        (this.currentCard.elementIndex != this.previousCard.elementIndex &&
          this.previousElement &&
          this.previousElement.locked)
      );
    },
    onFinalCardOfModule() {
      // If not a module or survey then we just unlock it
      if (!this.currentCard) return true;
      else if (this.currentCard.context != "LearningModules") return false;

      let currentModuleCards = this.programCards.filter(
        x => x.elementIndex == this.currentCard.elementIndex
      );
      // This is used to unlock rewards if they're on the last card
      var numCompleted = currentModuleCards.filter(
        x =>
          !["Badges", "Reward", "Program"].includes(x.context) &&
          x.progressPercent >= 100
      ).length;

      var totalNum = currentModuleCards.filter(
        x => !["Badges", "Reward", "Program"].includes(x.context)
      ).length;

      console.log("On final card of module?");
      console.log("Total num of cards ", totalNum);
      console.log("Num completed ", numCompleted);

      // If we have one last card and it's the one right before a reward
      if (this.cumulativeProgramProgress >= 100) return true;
      else if (
        // Must have 1 or 0 cards left
        totalNum - numCompleted <=
        1
      )
        return true;
      return false;
      //  return totalNum - numCompleted;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    programBadge() {
      // Used by program progress bar to show a badge image
      var obj = {};
      if (!this.program || !this.program.ProgramAwards) return obj;
      let badge = this.program.ProgramAwards.find(a => a.context == "Badges");
      if (badge) obj = badge;
      // Object.keys(this.program.programAwards).forEach(award => {
      //   if (award == "badge") {
      //     console.log(this.program.programAwards[award]);
      //     let badge = this.program.programAwards[award].find(
      //       x => x.badgeSignedUrl
      //     );
      //     if (badge) {
      //       obj = badge;
      //     }
      //   }
      // });
      return obj;
    },
    timeUntilProgramStart() {
      if (!this.program || !this.program.startDate) return null;
      // If the start date is in the past, hide
      else if (moment(this.program.startDate).isBefore(moment())) return null;
      var dur = moment.duration(moment(this.program.startDate).diff(moment()));
      let years = dur.years();
      let months = dur.months();
      let days = dur.days();
      let hours = dur.hours();
      let minutes = dur.minutes();

      var num = null;
      var unit = null;
      if (years) {
        num = years;
        unit = " year";
      } else if (months) {
        num = months;
        unit = " month";
      } else if (days) {
        num = days;
        unit = " day";
      } else if (hours) {
        num = hours;
        unit = " hour";
      } else if (minutes) {
        num = minutes;
        unit = " minute";
      }
      if (num > 0) return num + unit + (num == 1 ? "" : "s");
      return null;
    },
    timeSinceProgramEnd() {
      // console.log("PROGRAM END DATE ", this.program.endDate);
      if (!this.program || !this.program.endDate) return null;
      // If the start date is in the past, hide
      else if (
        moment(this.program.endDate)
          .utc()
          .isAfter(moment())
      )
        return null;
      var dur = moment.duration(
        moment(moment()).diff(moment(this.program.endDate))
      );
      let years = dur.years();
      let months = dur.months();
      let days = dur.days();
      let hours = dur.hours();
      let minutes = dur.minutes();
      console.log("We are here ");
      var num = null;
      var unit = null;
      if (years) {
        num = years;
        unit = " year";
      } else if (months) {
        num = months;
        unit = " month";
      } else if (days) {
        num = days;
        unit = " day";
      } else if (hours) {
        num = hours;
        unit = " hour";
      } else if (minutes) {
        num = minutes;
        unit = " minute";
      }
      console.log({ num, unit });
      if (num > 0) return num + unit + (num == 1 ? "" : "s");
      return null;
    }
  },
  watch: {
    isMobile: function(newVal) {
      // If isMobile changes, then we want to run changePageOnMobile
      if (newVal == true) this.changePageOnMobile(true, "e");
    },
    currentCardIndex: function(
      newVal // newVal
    ) {
      console.log(`currentCardIndex watcher ${newVal}`, this.currentCard);
      // We lean on the side of over-reporting learning logs
      // So every time the currentCardindex changes, as long as it's a learning / survey context and NOT a quiz, we create a log

      if (
        this.currentCard &&
        !this.currentCard.loading &&
        this.currentCard.context &&
        ["LearningModules", "Survey"].includes(this.currentCard.context) &&
        (!this.currentCard.type ||
          (this.currentCard.type &&
            !["quiz", "survey", "assessment", "learning_resources"].includes(
              this.currentCard.type
            )))
      ) {
        const leaveCardLocked =
          this.nextCardIsLocked ||
          (this.nextCard &&
            this.currentCard.elementIndex != this.nextCard.elementIndex &&
            this.nextElement &&
            this.nextElement.status == "Disabled");
        console.log(
          "Yeah let's store a learning log. Leave next card locked? ",
          leaveCardLocked
        );
        this.postLearningLog(null, null, leaveCardLocked);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.light-grey-background {
  background: #e8e8e8;
}
/* Used by program title and progress box on nav page */
.box-border {
  border: solid 1px;
  border-color: lightgray;
}
/* Used to cap the width of the program progress bar */
.progress-bar {
  min-width: 100px;
  max-width: 450px;
  width: calc(100% - 30px);
  border-radius: 20px;
}

/* Classes used for elements in left side nav */
.side-nav-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
}
/* CSS for left side nav boxes */
.side-nav-box {
  width: calc(100%);
  max-width: calc(100%);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* border: 1px solid lightgray; */
  border-radius: 5px;
  /* height: 37px; */
  min-height: 35px;
  padding-top: 3px;
}
/* Used to cap height of the learning cards */
.side-nav-box-height {
  height: 35px;
}
/* Used to cap the text for the display names for elements if they're too long */
.program-element-label {
  width: calc(100%);
  overflow-x: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Transition classes for the transition-group for side nav */
.list-complete-item {
  transition: all 0.3s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}

/* Controls styling for the 3 card components in 
the main container. Shows content card, and next/prev 
cards peeking from the edges */
.main-card {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px 10px 10px 10px !important;
}
.previous-card {
  background-color: white;
  margin-left: 20px;
  margin-right: 20px;
  min-height: 60px;
  height: 60px;
  border-radius: 0 0 10px 10px !important;
}
.next-card {
  background-color: white;
  margin-left: 20px;
  margin-right: 20px;
  min-height: 60px;
  height: 60px;
  border-radius: 10px 10px 0 0 !important;
}

/* Inner content for main card so it has smaller margins when on mobile*/
.main-card-content {
  min-width: 60%;
  height: 100%;
  min-height: 60vh;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (max-width: 959px) {
  .main-card-content {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (min-width: 960px) {
  .main-card-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}

/* Used to control the scroll behavior for the 2 main 
columns on screen so they virtually scroll, rather 
than being a long page */
.side-nav {
  height: 100%;
  overflow-y: hidden;
}
.main-col {
  float: left;
  height: 100%;
  overflow: scroll;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

/* Code for transitions between cards */
.slide-up-enter {
  opacity: 0;
  transform: translateY(100%);
}

.slide-down-enter {
  opacity: 0;
  transform: translateY(-100%);
}

.slide-up-enter-active,
.slide-down-enter-active {
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
</style>

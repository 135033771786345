<template>
  <div class="full-width d-flex flex-column align-start">
    <v-data-table
      :headers="columns"
      :hide-default-header="false"
      :hide-default-footer="false"
      :items="payments"
      item-key="parentPaymentRuleId"
      :items-per-page="table.itemsPerPage"
      :footer-props="table.footerProps"
      :options.sync="table.options"
      :server-items-length="table.total"
      :loading="loading.payments"
      must-sort
      :sort-desc="true"
      sort-by="description"
      class="full-width elevation-2 payment-table"
      loading-text="Loading... Please wait"
      no-data-text="No rewards found"
      no-results-text="No rewards found"
      :item-class="
        () => {
          return 'cursor-pointer';
        }
      "
      @click:row="loadTransactionDrawer"
    >
      <template v-slot:[`item.amount`]="{ item }">
        <span class="success--text font-weight-bold">{{
          formatCurrency(
            item.amount,
            !(item.Budget && item.Budget.awardId != 1),
            (item.Budget &&
              item.Budget.Award &&
              item.Budget.Award.unitSingular) ||
              "unit",
            (item.Budget &&
              item.Budget.Award &&
              item.Budget.Award.unitPlural) ||
              "units"
          )
        }}</span>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <div class="d-flex flex-column word-break my-1">
          <span>{{ item.titleSummary || "Payment" }}</span>
          <span class="grey--text">{{ item.formattedDate }}</span>
        </div>
      </template>
    </v-data-table>
    <v-navigation-drawer
      v-model="display.payment"
      temporary
      fixed
      right
      width="500"
    >
      <div v-if="display.payment">
        <v-toolbar dark color="brandDarkCyan" rounded="0">
          <v-btn dark icon large @click="closeTransactionDrawer" class="ml-1">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-toolbar-title class="d-flex align-center font-weight-bold"
            >Reward details</v-toolbar-title
          >
        </v-toolbar>
        <div class="text-left pa-4 word-break">
          <h2>{{ display.paymentHeader }}</h2>
          <p class="grey--text">
            {{ display.date
            }}<span v-if="userProfile.clientId == 1">
              (ID: {{ display.id }})</span
            >
          </p>
          <p
            v-if="display.frequency != null && display.frequency != 'ONE_TIME'"
          >
            {{
              display.frequency == "MONTHLY"
                ? "Montly payment"
                : display.frequency == "WEEKLY"
                ? "Weekly payment"
                : "Daily payment"
            }}
            <span v-if="formattedStartDate">
              from {{ formattedStartDate }}</span
            >
            <span v-if="formattedEndDate"> to {{ formattedEndDate }}</span>
          </p>
          <p class="font-italic" v-if="formattedDescription">
            "{{ formattedDescription }}"
          </p>
          <v-divider />
          <v-data-table
            :headers="paymentRuleColumns"
            :hide-default-header="false"
            :hide-default-footer="false"
            mobile-breakpoint="0"
            :items="rules"
            item-key="paymentRuleId"
            :items-per-page="ruleTable.itemsPerPage"
            :footer-props="ruleTable.footerProps"
            :options.sync="ruleTable.options"
            :server-items-length="ruleTable.total"
            :loading="loading.payment"
            must-sort
            :sort-desc="true"
            sort-by="displayName"
            class="full-width payment-table"
            loading-text="Loading... Please wait"
            no-data-text="No payments found"
            no-results-text="No payments found"
          >
            <template v-slot:[`item.amount`]="{ item }">
              <span class="font-weight-bold">{{
                formatCurrency(
                  item.amount,
                  display.awardId == 1,
                  display.unitSingular,
                  display.unitPlural
                )
              }}</span>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              <span>{{ item.formattedDate }}</span>
            </template>
            <template v-slot:[`item.displayName`]="{ item }">
              <div class="d-flex flex-column word-break my-1">
                <span>{{
                  item.User ? item.User.displayName : "Unknown user"
                }}</span>
                <span class="grey--text">{{ item.formattedDate }}</span>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
// import MarqetaService from "@/services/MarqetaService";
import WalletService from "@/services/WalletService";

import { debounce } from "@/shared_data/functions";

import moment from "moment";
import currency from "currency.js";
import { mapState } from "vuex";

export default {
  name: "RewardsSentLog",
  props: {
    isolate: String,
    showVirtualCard: Boolean,
    search: String,
    adminView: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      loading: {
        balance: false,
        payments: false,
        payment: false
      },
      display: {
        admin: false,
        payment: false,
        id: null,
        parentPaymentRuleId: null,
        awardId: null,
        unitSingular: null,
        unitPlural: null,
        paymentHeader: null,
        date: null,
        description: null,
        frequency: null,
        startDate: null,
        endDate: null
      },
      data: {
        payments: [],
        rules: []
      },
      transactionColumns: [
        {
          text: "Description    ",
          value: "description",
          width: "50%",
          sortable: true,
          align: "start"
        },
        {
          text: "    Amount",
          value: "amount",
          width: "15%",
          align: "end",
          sortable: true
        }
      ],
      paymentRuleColumns: [
        {
          text: "Name  ",
          value: "displayName",
          width: "50%",
          sortable: true,
          align: "start"
        },
        {
          text: "       Amount",
          value: "amount",
          width: "20%",
          align: "end",
          sortable: true
        }
      ],
      table: {
        itemsPerPage: 10,
        page: 1,
        search: null,
        total: 0,
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      },
      ruleTable: {
        itemsPerPage: 10,
        page: 1,
        search: null,
        total: 0,
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      }
    };
  },
  created() {
    // this.getParentPaymentRules();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    formatCurrency(
      amount,
      cash = true,
      unitSingular = null,
      unitPlural = null
    ) {
      return (
        currency(amount, { symbol: cash ? "$" : "" }).format() +
        (cash ? "" : amount == 1 ? ` ${unitSingular}` : ` ${unitPlural}`)
      );
    },
    loadWallet() {
      this.$emit("load-wallet");
    },
    customSort(items, index, isDesc) {
      // console.log({ isDesc });
      items.sort((a, b) => {
        if (index.length && index[0] === "description") {
          // console.log("Parsing date sort", b.date);
          if (isDesc.length && !isDesc[0]) {
            return moment(b.date).isBefore(a.date) ? 1 : -1;
          } else {
            return moment(a.date).isBefore(b.date) ? 1 : -1;
          }
        }
      });
      return items;
    },
    filterTransactions(value, query, item) {
      // console.log({ value, query, item });
      // We have a custom filter because we want to search merchant name, but it's combined with date in one column
      // That column's value is date so that it can naturally be sorted in descending order

      return (
        query != null &&
        ((item &&
          item.titleSummary != null &&
          typeof item.titleSummary === "string" &&
          item.titleSummary
            .toString()
            .toLowerCase()
            .indexOf(query.toLowerCase()) !== -1) ||
          (value != null &&
            typeof value == "string" &&
            value
              .toString()
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1))
      );
    },
    async getParentPaymentRules(reset = false) {
      try {
        console.log("Getting parent rules ", { reset });
        this.loading.payments = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = options.page || 1;
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        let filter = `status != 'Deleted' && status != 'Failed'`;
        if (!this.adminView)
          filter += ` && createdBy = '${this.userProfile.userId}'`;
        if (this.userProfile.clientId != 1)
          filter += ` && clientId = '${this.userProfile.clientId}'`;
        let search =
          this.table.search || this.search
            ? (this.table.search || this.search).replace(/'/g, "\\'")
            : null;
        if (search)
          filter += ` && (description.like('%${search}%') || titleSummary.like('%${search}%') || amount.like('%${search}%') || parentPaymentRuleId = '${search}')`;
        let sort = "startDate DESC";
        if (options.sortBy && options.sortBy.length) {
          let col = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";
          if (col == "description") col = "startDate";
          sort = `${col} ${type}`;
        }
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          filter,
          offset,
          limit,
          sort,
          expand: "Budget.Award"
        };
        console.log(params);
        let results = await WalletService.getParentPaymentRulesV2(
          params,
          this.magicLinkToken
        );

        if (
          this.table.latestFilter != filter ||
          this.table.latestSort != sort
        ) {
          console.log("Returning early due to change");
          return;
        }
        console.log("Got payments sent ", results.result.rows);
        this.data.payments = results.result.rows.map(x => {
          x.formattedDate = moment(x.startDate)
            .local()
            .format("MMM D, YYYY");
          return x;
        });

        this.table.total = results.result.count;
      } catch (err) {
        console.log("Error with parent payment rules ", err);
      } finally {
        this.loading.payments = false;
      }
    },
    async getPaymentRules(reset = false) {
      try {
        console.log("Getting payment rules ", { reset });
        this.loading.payment = true;
        if (reset) this.ruleTable.options.page = 1;
        let options = this.ruleTable.options;
        let page = options.page || 1;
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        // clientId == ${this.userProfile.clientId} &&
        let filter = `status != 'Deleted' && parentPaymentRuleId = ${this.display.id}`;
        // let search = this.table.search
        //   ? this.table.search.replace(/'/g, "\\'")
        //   : null;
        // if (search)
        //   filter += ` && (description.like('%${search}%') || titleSummary.like('%${search}%') || amount.like('%${search}%') || parentPaymentRuleId = '${search}')`;
        console.log(options.sortBy);
        let sort = "startDate DESC";
        if (options.sortBy && options.sortBy.length) {
          let col = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";
          if (col == "displayName") col = "startDate";
          else sort = `${col} ${type}`;
        }
        this.ruleTable.latestSort = sort;
        this.ruleTable.latestFilter = filter;
        let params = {
          filter,
          offset,
          limit,
          expand: "User",
          sort
        };
        console.log(params);
        let results = await WalletService.getPaymentRulesV2(
          params,
          this.magicLinkToken
        );

        if (
          this.ruleTable.latestFilter != filter ||
          this.ruleTable.latestSort != sort
        ) {
          console.log("Returning early due to change");
          return;
        }
        console.log("Got user payments ", results.result.rows);
        this.data.rules = results.result.rows.map(x => {
          x.formattedDate = moment(x.startDate)
            .local()
            .format("MMM D, YYYY");
          return x;
        });

        this.ruleTable.total = results.result.count;
      } catch (err) {
        console.log("Error with payment rules ", err);
      } finally {
        this.loading.payment = false;
      }
    },
    loadTransactionDrawer(row) {
      this.data.rules = [];
      this.ruleTable.options = { page: 1 };
      this.display.payment = true;
      this.display.parentPaymentRuleId = row.parentPaymentRuleId || row;
      this.display.paymentHeader = row.titleSummary;
      this.display.date = row.formattedDate;
      this.display.id = row.parentPaymentRuleId;
      this.display.description = row.description;
      this.display.frequency = row.frequency;
      this.display.startDate = row.startDate;
      this.display.endDate = row.endDate;
      this.display.awardId = (row.Budget && row.Budget.awardId) || 1;
      this.display.unitSingular =
        (row.Budget && row.Budget.Award && row.Budget.Award.unitSingular) ||
        "unit";
      this.display.unitPlural =
        (row.Budget && row.Budget.Award && row.Budget.Award.unitPlural) ||
        "units";
    },
    closeTransactionDrawer() {
      this.display.payment = false;
      this.display.parentPaymentRuleId = null;
      this.display.paymentHeader = null;
      this.display.date = null;
      this.display.id = null;
      this.display.description = null;
      this.display.frequency = null;
      this.display.startDate = null;
      this.display.endDate = null;
      this.display.awardId = null;
      this.display.unitSingular = null;
      this.display.unitPlural = null;
      this.data.rules = [];
      this.ruleTable.options = { page: 1 };
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "balances",
      "cashBalance",
      "confettiPlayed",
      "magicLinkToken"
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    columns() {
      let col = this.transactionColumns;
      // if (this.isMobile) col[0].align = "end";
      // else col[0].align = "start";
      // else if (this.isMobile) return col.filter(x => x.mobile !== false);
      return col;
    },
    payments() {
      if (this.loading.payments) return [];

      return this.data.payments;
    },
    rules() {
      if (this.loading.payment) return [];

      return this.data.rules;
    },
    searchValue() {
      // if (this.search)
      return this.search;
      // return this.search.transactions;
    },
    formattedStartDate() {
      if (!this.display.startDate) return null;
      return moment(this.display.startDate).format("MMM D, YYYY");
    },
    formattedEndDate() {
      if (!this.display.endDate) return null;
      return moment(this.display.endDate).format("MMM D, YYYY");
    },
    formattedDescription() {
      if (
        !this.display.description ||
        typeof this.display.description !== "string"
      )
        return null;
      return this.display.description.trim();
    }
  },
  watch: {
    search: debounce(function(newVal) {
      this.table.search = newVal;
      this.getParentPaymentRules(true);
    }, 500),
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        // var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }

        // if (apiCall)
        this.getParentPaymentRules(reset, "watcher");
      },
      deep: true
    },
    "ruleTable.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        // var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }

        // if (apiCall)
        this.getPaymentRules(reset, "watcher");
      },
      deep: true
    },
    adminView: {
      handler(newVal, oldVal) {
        console.log("Admin view new val ", newVal);
        console.log("Admin view old val ", oldVal);

        this.getParentPaymentRules(true, "adminView watcher");
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.payment-table {
  /* border: solid 1px;
  border-color: lightgray; */
  border-radius: 20px;
  /* border-radius: 0px; */
}

.payment-table >>> .v-data-table__wrapper {
  border-radius: 20px 0 0 0;
  padding-top: 10px;
}

.payment-table >>> .v-data-table-header {
  background-color: white;
}

/* Removes header on mobile to sort columns */
.payment-table-mobile >>> .v-data-table__wrapper > table > thead {
  display: none;
}
</style>

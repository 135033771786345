var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"robin d-flex align-start"},[_c('div',[(_vm.displayRobin)?_c('lottie',{attrs:{"options":_vm.lottieOptions,"height":_vm.height,"width":_vm.width},on:{"animCreated":_vm.handleAnimation}}):_vm._e()],1),(_vm.showText)?_c('div',{staticClass:"d-flex flex-column"},[(_vm.header)?_c('b',{staticClass:"robinSpeech no-border align-text-left ml-2 primary--text header-text d-flex align-center"},[_vm._v(_vm._s(_vm.header))]):_vm._e(),_c('span',{staticClass:"robinSpeech align-text-left ml-2 d-flex align-center",class:{
        'white-background': _vm.color === 'white',
        'brand-pale-blue-background': _vm.color === 'brandPaleBlue',
        'brand-light-green-background': _vm.color === 'brandLightGreen',
        'accent-border': _vm.borderColor === 'accent',
        'brand-green-border': _vm.borderColor === 'brandGreen',
        'no-border': !_vm.borderColor,
        'large-text': _vm.largeText,
        'small-text': !_vm.largeText,
      },attrs:{"color":"blue"}},[_vm._v(_vm._s(_vm.message))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
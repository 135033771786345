<template>
  <v-card
    v-if="loading || ranked.length > 0"
    elevation="0"
    class="align-text-left"
  >
    <span class="mediumGrey--text mb-2" v-if="version != 2">Leaderboard</span>
    <div v-if="loading" class="d-flex justify-center">
      <lottie
        :options="lottieOptions"
        :height="170"
        :width="170"
        v-on:animCreated="handleAnimation"
      />
    </div>
    <div v-else>
      <v-virtual-scroll
        :bench="10"
        :items="ranked"
        :height="virtualScrollHeight"
        item-height="44"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.programCacheId" class=" ma-0 pa-0">
            <div
              v-if="item.userId == userProfile.userId"
              class="active-row d-flex align-center justify-space-between pa-1 full-width ma-0"
            >
              <div class="d-flex align-center">
                <div
                  class="active-rank text-align-center d-flex align-center justify-center"
                >
                  <b>{{ item.rank ? item.rank : "-" }}</b>
                </div>
                <b class="ml-2">{{ item.name }}</b>
              </div>
              <b class="mr-4" v-if="item.progressPercent == null">
                -
              </b>
              <b class="mr-5" v-else>{{ item.progressPercent }}%</b>
            </div>
            <div
              v-else
              class="not-active-row d-flex flex-row align-center mr-3"
            >
              <div
                class="mr-2 not-active-rank text-align-center d-flex align-center justify-center grey--text"
              >
                <span>{{ item.rank ? item.rank : "-" }}</span>
              </div>
              <div
                class="pl-2 d-flex justify-space-between align-center light-grey-background full-width pa-1"
              >
                <span>{{ item.name }}</span>
                <span class="mr-4" v-if="item.progressPercent == null">
                  -
                </span>
                <span class="mr-2" v-else>{{ item.progressPercent }}%</span>
              </div>
            </div>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </div>
  </v-card>
</template>

<script>
import ProgramService from "../services/ProgramService.js";
import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Eating.json";
import { mapState } from "vuex";
export default {
  name: "Leaderboard",
  components: { Lottie },
  props: {
    programId: String,
    smallVersion: Boolean,
    version: Number,
  },
  data() {
    return {
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1,
      loading: true,
      programCache: [],
      ranked: [],
    };
  },
  created() {
    if (this.programId !== undefined && this.programId !== null) {
      this.getProgramCache();
    }
  },
  mounted() {},
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    getProgramCache() {
      // Used by the leaderboard to get everyone
      this.loading = true;
      ProgramService.getProgramLeaderboard(this.programId).then(
        (programCache) => {
          this.loading = false;
          console.log("Program Results for Leaderboard: ", programCache);
          this.programCache = programCache;
          // The api handles the ranking & sorting now.
          this.ranked = programCache;

          // // First we check if we have a primary progress rule
          // var resultsToRank = programCache
          //   .filter((x) => x.primaryRuleGroup)
          //   .sort((a, b) => a.rank - b.rank);
          // // This sorts rank by descending order but if rank is null, it puts those at the top, so we have to cycle through and append at the end
          // var arrayWithoutNull = resultsToRank.filter((x) => x.rank);
          // var nullArray = resultsToRank.filter((x) => !x.rank);
          // arrayWithoutNull = arrayWithoutNull.concat(nullArray);

          // console.log("Results after primary flag", arrayWithoutNull);
          // this.ranked = arrayWithoutNull;
        }
      );
    },
  },
  computed: {
    ...mapState(["userProfile"]),
    virtualScrollHeight() {
      // The leaderboard shows 5 ppl before having to scroll, but if we have less than that, then we want to shrink the leaderboard so there's not white space
      if (!this.loading && this.ranked.length < 5) {
        return this.ranked.length * 50;
      }
      return 250;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.active-row {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.active-rank {
  background-color: var(--v-accent-base);
  width: 30px;
  height: 30px;
}
.not-active-row {
  width: 94%;
}
.not-active-rank {
  width: 30px;
  height: 30px;
}
.light-grey-background {
  /* background-color: var(--v-lightGrey-base); */
  background-color: #e8e8e8;
}
.v-list__tile {
  padding: 0;
}
</style>

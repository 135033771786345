<template>
  <div class="mx-3 my-3">
    <v-card width="100%" height="500">
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col>This feature coming soon.</v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
//import axios from "axios";

// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "ComingSoon",
  title: "Whistle | Coming Soon",
  data() {
    return {
      userProfile: "",
      permissions: [],
    };
  },
};
</script>

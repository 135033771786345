import { main as axios, oauth as oauthAxios } from "../axios";

const baseUrl =
  process.env.VUE_APP_DEV_LOCAL && process.env.VUE_APP_AI_URL
    ? process.env.VUE_APP_AI_URL
    : `${process.env.VUE_APP_WHISTLE_API}/ai-service`;

class AiService {
  evaluateRecognition(body, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(`${baseUrl}/evaluate-recognition`, body)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log("Error evaluating rec", error.response || error);
        throw error.response ? error.response.data || error.response : error;
      });
  } //evaluateRecognition

  suggestRecognition(data, magicLinkToken = null) {
    return (magicLinkToken ? oauthAxios : axios)
      .post(`${baseUrl}/evaluate-recognition/recommendation`, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(
          "Error getting rec recommendation ",
          error.response || error
        );
        throw error.response ? error.response.data || error.response : error;
      });
  } //suggestRecognition
}

export default new AiService();

<template>
  <div>
    <div
      class="cursor-pointer rounded box-border box-border-green py-8 px-6 mb-5 hover-box"
      :class="{
        'box-border-green': !gifSelector,
        'box-border-cyan': gifSelector
      }"
      @click="drawer = true"
    >
      <div
        v-if="!hasReward && !hasGIF"
        class="d-flex align-center word-break clear-fab-container"
      >
        <v-icon
          :color="gifSelector ? 'brandCyan' : 'brandDarkGreen'"
          x-large
          class="mr-4"
          >{{ gifSelector ? "mdi-party-popper" : "mdi-gift-outline" }}</v-icon
        >
        <h2 class="mt-1">
          {{ gifSelector ? "Add a celebration" : "Add a reward" }}
        </h2>
        <div class="clear-fab">
          <div class="lottie-container">
            <div v-if="lottie.poof" class="lottie-poof-effect">
              <LottiePlayer
                animation="poof"
                :height="150"
                :width="150"
                :loop="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex align-center justify-space-between clear-fab-container"
      >
        <v-card-title class="pl-0 word-break"
          >{{
            gifSelector ? "Selected celebration" : "Selected reward"
          }}:</v-card-title
        >
        <div class="d-flex justify-start align-center lottie-container">
          <div
            v-if="lottie.star"
            :class="{
              'lottie-reward-star-effect': !gifSelector,
              'lottie-gif-star-effect': gifSelector
            }"
          >
            <LottiePlayer
              animation="stars"
              :height="250"
              :width="250"
              :speed="1.8"
              :loop="false"
            />
          </div>

          <span v-if="!gifSelector" class="preset-emoji mr-2">{{ emoji }}</span>
          <h3 v-if="!gifSelector" class="">
            {{ formatCurrency(amount, cashAward) }}
          </h3>
          <div>
            <v-img v-if="gif" :src="gif" max-width="100" contain></v-img>
          </div>
        </div>
        <v-btn
          fab
          color="grey"
          x-small
          dark
          class="elevation-0 clear-fab"
          @click.stop="clearElement"
          ><v-icon>mdi-trash-can-outline</v-icon></v-btn
        >
      </div>
    </div>
    <v-navigation-drawer v-model="drawer" temporary fixed right width="500">
      <RewardDrawer
        v-if="drawer && !gifSelector"
        :loading="loading"
        :cashAward="cashAward"
        :presets="presets"
        :customEmoji="customEmoji"
        :customAmount="customAmount"
        @close="drawer = false"
        @select-reward="selectReward"
        @select-custom-reward="selectCustomReward"
        @update-custom-amount="updateCustomAmount"
        @update-custom-emoji="updateCustomEmoji"
      />
      <GIFDrawer
        v-else-if="drawer"
        @close="drawer = false"
        @select-gif="selectGIF"
      />
    </v-navigation-drawer>
    <v-overlay v-if="drawer" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import RewardDrawer from "@/components/recognition/RewardDrawer.vue";
import GIFDrawer from "@/components/recognition/GIFDrawer.vue";
import LottiePlayer from "@/components/LottiePlayer.vue";

import currency from "currency.js";

export default {
  name: "RewardSelector",
  components: { RewardDrawer, GIFDrawer, LottiePlayer },
  props: {
    type: {
      type: String,
      default: "reward"
    },
    reviewPage: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    cashAward: {
      type: Boolean,
      default: true
    },
    presets: {
      type: Array,
      default: () => []
    },
    gif: {
      type: String,
      default: null
    },
    emoji: {
      type: String,
      default: null
    },
    amount: {
      type: Number,
      default: null
    },
    customEmoji: {
      type: String,
      default: null
    },
    customAmount: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      drawer: false,
      lottie: {
        star: false,
        poof: false
      }
    };
  },
  created() {},
  destroyed() {},
  methods: {
    close() {
      this.$emit("close");
    },
    selectReward(e) {
      this.playStarAnimation();
      this.$emit("select-reward", e);
      this.drawer = false;
    },
    selectGIF(e) {
      console.log("Gif clicked ", e);
      const gif = e.images["fixed_height"];
      let url = null;
      if (gif) url = gif.url;
      this.playStarAnimation();
      this.$emit("select-gif", url);
      this.drawer = false;
    },
    selectCustomReward() {
      this.playStarAnimation();
      this.$emit("select-custom-reward");
      this.drawer = false;
    },
    updateCustomAmount(e) {
      this.$emit("update-custom-amount", e);
    },
    updateCustomEmoji(e) {
      this.$emit("update-custom-emoji", e);
    },
    clearElement() {
      if (!this.reviewPage) this.playPoofAnimation();
      this.$emit("clear");
    },
    formatCurrency(amount, cash = true) {
      return currency(amount).format({ symbol: cash ? "$" : "" });
    },
    playStarAnimation() {
      this.lottie.star = true;
      setTimeout(() => {
        this.lottie.star = false;
      }, 1000);
    },
    playPoofAnimation() {
      this.lottie.poof = true;
      setTimeout(() => {
        this.lottie.poof = false;
      }, 1000);
    }
  },
  computed: {
    ...mapState(["userProfile", "clients", "permissions"]),
    hasReward() {
      return !!(this.emoji && this.amount);
    },
    hasGIF() {
      return !!this.gif;
    },
    gifSelector() {
      return this.type != "reward";
    }
  },
  watch: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.preset:hover {
  background-color: #eeeeee;
}

.preset-emoji {
  font-size: 40px;
}

.box-border {
  border: 1px solid lightgray;
}
.box-border-green {
  border-color: var(--v-brandDarkGreen-base);
}
.box-border-cyan {
  border-color: var(--v-brandCyan-base);
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-ms-keyframes wiggle {
  0% {
    -ms-transform: rotate(10deg);
  }
  25% {
    -ms-transform: rotate(-10deg);
  }
  50% {
    -ms-transform: rotate(5deg);
  }
  75% {
    -ms-transform: rotate(-5deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}
@keyframes wiggle {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
  /* 0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  } */
}
.wiggle {
  display: inline-block;
  animation-name: wiggle;
  -webkit-animation-name: wiggle;
  -ms-animation-name: wiggle;
  animation-duration: 1s;
  -ms-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  -ms-animation-iteration-count: 1;
}

/* Positioning of trash button */
.clear-fab-container {
  position: relative;
}
.clear-fab {
  position: absolute;
  /* top: calc(-4px * 8 + -16px);
  right: calc(-4px * 6 + -16px); */
  top: calc(-4px * 8 + 6px);
  right: calc(-4px * 6 + 6px);
}

.lottie-container {
  position: relative;
}

.lottie-reward-star-effect {
  position: absolute;
  top: -105px;
  left: -105px;
  /* z-index: 1; */
  pointer-events: none;
}

.lottie-gif-star-effect {
  position: absolute;
  top: -75px;
  right: -75px;
  /* z-index: 1; */
  pointer-events: none;
}

.lottie-poof-effect {
  position: absolute;
  top: -60px;
  right: -60px;
  /* z-index: 1; */
  pointer-events: none;
}

.hover-box:hover {
  background-color: #eeeeee;
}
</style>

<template>
  <div class="mt-4 flex-column d-flex">
    <v-card rounded="0" width="100%" elevation="0" class="quiz-card">
      <v-card-title class="word-break px-0 font-weight-bold brand--text">
        <!-- #{{ questionIndex + 1 }} - -->
        {{ question.content }}
      </v-card-title>
      <p class="grey--text mb-1 font-italic">
        Choose a response from the answers below
      </p>
      <v-divider />

      <v-radio-group
        v-model="checkedAnswerId"
        :class="answerSubmitted ? 'radio-group-disabled' : ''"
      >
        <div
          v-for="answer in question.LearningQuizAnswers"
          :key="answer.learningQuizAnswerId"
        >
          <v-hover v-slot="{ hover }">
            <div
              class="answer-box d-flex align-center cursor-pointer my-1"
              :class="{
                'light-grey-background': hover && !answerSubmitted,
                'light-red-background':
                  answerSubmitted &&
                  answer.correctness <= 0 &&
                  answer.learningQuizAnswerId == checkedAnswerId,
                'brand-pale-green-background':
                  answerSubmitted && answer.correctness > 0,
              }"
              @click="checkedAnswerId = answer.learningQuizAnswerId"
            >
              <!-- -->
              <div
                class="d-flex justify-center"
                v-if="answerSubmitted && answer.correctness > 0"
              >
                <v-icon color="brandGreen" class="correct-icon"
                  >mdi-check-circle</v-icon
                >
                <span class="ml-2">{{ answer.content }}</span>
              </div>
              <v-radio
                v-else
                :label="answer.content"
                :value="answer.learningQuizAnswerId"
                :color="answerSubmitted ? 'grey' : 'brandCyan'"
                :readonly="answerSubmitted"
                :disabled="answerSubmitted"
              ></v-radio>
            </div>
          </v-hover>
        </div>
      </v-radio-group>
      <v-divider />
      <!-- {{ answer.content }} -->
      <!-- <div
          :for="answer.learningQuizAnswerId"
          class="rounded-card d-flex px-5 py-4 align-center"
          @click="answerQuestion(answer)"
          :class="{
            'cursor-pointer': !answered,
            'light-purple-background':
              (!answered &&
                checkedAnswer &&
                checkedAnswer.learningQuizAnswerId ==
                  answer.learningQuizAnswerId) ||
              (answered &&
                noCorrectAnswersMarked &&
                checkedAnswer &&
                checkedAnswer.learningQuizAnswerId ==
                  answer.learningQuizAnswerId),
            'brand-pale-green-background': answered && answer.correctness > 0,
            'light-red-background':
              answered &&
              !noCorrectAnswersMarked &&
              answer.correctness <= 0 &&
              checkedAnswer.learningQuizAnswerId == answer.learningQuizAnswerId,
          }"
        >
          <div
            class="letter-circle d-flex align-center justify-center light-grey-background circle-border-radius mr-4"
            :class="{
              'light-grey-background': !answered,
              'purple-background':
                (!answered &&
                  checkedAnswer &&
                  checkedAnswer.learningQuizAnswerId ==
                    answer.learningQuizAnswerId) ||
                (answered &&
                  noCorrectAnswersMarked &&
                  checkedAnswer &&
                  checkedAnswer.learningQuizAnswerId ==
                    answer.learningQuizAnswerId),
              'brand-green-background': answered && answer.correctness > 0,
              'red-background':
                answered &&
                !noCorrectAnswersMarked &&
                answer.correctness <= 0 &&
                checkedAnswer.learningQuizAnswerId ==
                  answer.learningQuizAnswerId,
            }"
          >
            <b class="white--text">{{ alphabet[index] }}</b>
          </div>
          {{ answer.content }}
        </div> -->
      <!-- </div> -->

      <div class="d-flex justify-center mt-10">
        <!-- <v-col justify="center"> -->
        <v-btn
          v-if="answerSubmitted == false"
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          width="120"
          depressed
          @click="checkQuestion"
          :disabled="checkedAnswerId == null"
        >
          {{ computedSubmitButton }}
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          @click="goToNextQuestion"
          width="120"
          depressed
          v-if="questionIndex !== questionCount - 1 && answerSubmitted == true"
        >
          Next
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          @click="completeQuiz"
          width="120"
          depressed
          v-else-if="
            questionIndex == questionCount - 1 &&
              answerSubmitted == true &&
              !isSurveyQuestion
          "
        >
          Complete
        </v-btn>
        <!-- </v-col> -->
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LearningService from "@/services/LearningService";

export default {
  name: "QuizQuestionMultipleChoiceV2",
  props: {
    question: Object,
    questionIndex: Number,
    questionCount: Number,
    completedStatus: Boolean,
    learningCardAssociationId: Number,
    isSurveyQuestion: Boolean,
    surveyClientId: Number,
    whistleToken: String,
    enteredEmail: String,
    context: String,
  },
  components: {},
  data() {
    return {
      selected: "",
      answerKey: null,
      chapterList: null,
      currentQuestion: null,
      incorrectAnswers: [],
      incorrectCount: 0,
      correctCount: 0,
      checkedAnswerId: null,
      answerSubmitted: false,
    };
  },
  created() {},
  updated() {},
  mounted() {},
  beforeMount() {},
  methods: {
    clickAnswer(x) {
      if (this.answerSubmitted) return;
      console.log(x);
      this.checkedAnswerId = x;
    },
    checkQuestion() {
      // check this question's answer
      // Switched from find to filter in case there are multiple correct answers
      var correctAnswers = this.question.LearningQuizAnswers.filter(
        (ans) => ans.correctness > 0
      );
      correctAnswers = correctAnswers.map((x) => x.learningQuizAnswerId);
      //console.log(answer);

      // compare correct answer with user's answer
      this.answerSubmitted = true;

      if (
        correctAnswers.includes(this.checkedAnswerId) ||
        correctAnswers.length == 0
      ) {
        console.log("Correct", this.correctCount);
        this.correctCount++;
      } else {
        console.log("Wrong!", this.correctCount);
        this.incorrectCount++;
      }

      //build question response
      console.log("build question response...");
      var quizQuestionResponse = {
        clientId:
          this.userProfile && this.userProfile.clientId != null
            ? this.userProfile.clientId
            : this.surveyClientId,
        learningCardId: this.question.learningCardId,
        learningQuizQuestionId: this.question.learningQuizQuestionId,
        learningCardAssociationId: this.learningCardAssociationId,
        learningQuizAnswerId: "[" + this.checkedAnswerId + "]",
        value: this.checkedAnswer.content,
        correctness: this.isSurveyQuestion
          ? null
          : this.checkedAnswer.correctness,
        userId: this.whistleToken != null ? null : this.$auth.user.sub,
      };

      if (this.whistleToken !== null) {
        quizQuestionResponse.externalUserId = this.enteredEmail;
      }

      console.log(quizQuestionResponse);
      console.log(this.checkedAnswer);

      LearningService.createQuizResponse(
        [quizQuestionResponse],
        this.whistleToken
      );
      //if this is a survey question, we don't want to have to click Submit, and then Next, one button should do both
      //so we call the function for next question or complte quiz inside of the submit button
      // If no correct answers are marked, we treat it the same way
      if (this.isSurveyQuestion == true || this.noCorrectAnswersMarked) {
        this.goToNextQuestion();
      }

      console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(this.checkedAnswer);
    },
    // answerQuestion(answer) {
    //   if (!this.answerSubmitted) {
    //     this.checkedAnswer = answer;
    //   }
    // },
    goToNextQuestion() {
      // If we're not on the last question, move on and reset the flags for answering a question
      if (this.questionIndex !== this.questionCount - 1) {
        this.answerSubmitted = false;
        this.checkedAnswerId = null;
        this.$emit("next-question", this.correctCount);
        this.correctCount = 0;
      } else {
        this.completeQuiz();
      }
    },
    completeQuiz() {
      // Emits a notification so the quiz can submit
      this.$emit("complete-quiz", this.correctCount);
    },
  },
  computed: {
    ...mapState(["userProfile"]),
    noCorrectAnswersMarked() {
      return (
        this.question &&
        this.question.LearningQuizAnswers.filter((ans) => ans.correctness > 0)
          .length == 0
      );
    },
    computedSubmitButton: function() {
      return this.isSurveyQuestion ? "Next" : "Submit";
    },
    checkedAnswer() {
      if (this.checkedAnswerId == null) return null;

      return this.question.LearningQuizAnswers.find(
        (x) => x.learningQuizAnswerId == this.checkedAnswerId
      );
    },
    inProgram() {
      // used to change styling if we're in learning or surveys versus in programs
      return !(this.context == "survey" || this.context == "learning");
    },
  },
};
</script>

<style scoped>
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

.light-red-background {
  background-color: #fcdeda;
}

.brand-pale-green-background {
  background-color: var(--v-brandPaleGreen-base);
}

.quiz-card .v-card__title {
  word-break: normal;
}

/* Container for individual answer box */
.answer-box {
  min-height: 60px;
  /* height: 60px; */
  width: 100%;
  padding: 0 20px 0 20px;
}

.correct-icon {
  transform: scale(1.1);
}

.radio-group-disabled {
  pointer-events: none !important;
}
</style>

<template>
  <div>
    <v-card-title>
      <v-row>
        <v-col
          class="d-flex flex-column align-start ml-2"
          sm="6"
          md="6"
          :class="{ hidden: currentSlide > 2 }"
        >
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="pa-2 mb-1 ml-1"
            @click="previousSlide"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span>Back</span>
        </v-col>
      </v-row>
    </v-card-title>
    <transition :name="slideDirection" mode="out-in">
      <v-row
        v-if="currentSlide == 0"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Loading your info...</h2>
        <caption class="grey--text mt-2">
          This should only take a few seconds.
        </caption>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-if="currentSlide == 1"
        key="0"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Let's update some information</h2>
        <caption class="grey--text mt-2">
          What email and phone number should be used?
        </caption>
        <v-form ref="form" v-model="validNameForm" class="full-width px-4">
          <v-text-field
            class="mt-4"
            v-model="email"
            label="Email*"
            :rules="[
              v => !!v || 'Email is required',
              v => !!(v && v.length < 255) || 'Email should be shorter',
              v =>
                /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(v) ||
                'Email must be valid'
            ]"
          ></v-text-field>
          <vue-tel-input-vuetify
            dense
            class="mt-4"
            v-model="phone"
            :value="phone"
            label="Phone number (xxx-xxx-xxxx)"
            placeholder="xxx-xxx-xxxx"
            :preferredCountries="['US']"
            :defaultCountry="countryCode.code"
            :disabledFetchingCountry="true"
            v-on:country-changed="countryCodeChanged"
            @validate="phoneValidation"
          ></vue-tel-input-vuetify>
        </v-form>
      </v-row>
      <v-row
        v-else-if="currentSlide == 2"
        key="2"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>We need to make sure we have all the details right</h2>
        <caption class="grey--text mt-2">
          Let's get your address
        </caption>

        <v-form ref="form" v-model="validAddressForm" class="full-width px-4">
          <v-text-field
            ref="address1"
            label="Address"
            v-model="address1"
            class="mt-4"
            required
            :rules="[
              v => !!v || 'Address is required',
              v => !!(v && v.length < 256) || 'Address should be shorter'
            ]"
          />
          <v-text-field
            label="Apt, suite, unit, etc."
            v-model="address2"
            class="mt-4"
          />
          <v-text-field
            label="City"
            v-model="city"
            class="mt-4"
            required
            :rules="[
              v => !!v || 'City is required',
              v => !!(v && v.length < 40) || 'City should be shorter'
            ]"
          />
          <v-select
            v-if="!country || country == 'US'"
            label="State"
            :items="states"
            v-model="state"
            item-value="abbreviation"
            item-text="name"
            :rules="[v => !!v || 'State is required']"
          />
          <v-text-field
            label="Zip Code"
            v-model="postalCode"
            class="mt-4"
            required
            :rules="[
              v => !!v || 'Zip code is required',
              v => !!(v && v.length < 10) || 'Zip code should be shorter'
            ]"
          />
          <v-select
            label="Country"
            :items="allCountries"
            v-model="country"
            item-value="code"
            item-text="name"
            :rules="[v => !!v || 'Country is required']"
          />
          <!-- 
          <v-text-field
            v-if="country == '00'"
            label="Other country (2 digit code)"
            v-model="otherCountry"
            class="mt-4"
            required
            :rules="[
              (v) => !!v || 'Country is required',
              (v) =>
                !!(v && v.length == 2) || 'Country code should be 2 characters',
              (v) =>
                !!(v && countryCodes.includes(v.toUpperCase())) ||
                'Country code must be a valid country',
            ]"
          /> -->
        </v-form>
      </v-row>
      <v-row
        v-else-if="currentSlide == 3"
        key="3"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>Processing...</h2>
        <caption class="grey--text mt-2">
          This should only take a few seconds.
        </caption>

        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="flying"
          :loop="true"
        />
      </v-row>
      <v-row
        v-else-if="currentSlide == 4"
        key="4"
        no-gutters
        class="py-3 word-break px-5 d-flex flex-column align-center mb-8"
      >
        <h2>ALL DONE!</h2>
        <caption class="grey--text mt-2">
          Your information has been updated!
        </caption>
        <Robin
          :showText="false"
          :width="140"
          :height="140"
          animation="backflip"
          :loop="false"
        />
      </v-row>
    </transition>
    <v-row
      class="mb-10"
      align="center"
      justify="center"
      v-if="currentSlide != 3 && currentSlide != 0"
    >
      <v-col>
        <v-btn
          outlined
          rounded
          :disabled="
            (currentSlide == 1 && (!validNameForm || !phoneNumberValid)) ||
              (currentSlide == 2 && !validAddressForm)
          "
          class="py-5"
          @click="nextSlide"
          ><v-icon class="mr-1" color="brandGreen"
            >mdi-arrow-right-drop-circle</v-icon
          ><span class="mt-1">{{
            currentSlide != 4 ? "Next" : "Done"
          }}</span></v-btn
        ></v-col
      >
    </v-row>
    <v-dialog v-model="dialogEmailError" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            The email you submitted is already in use. Please try a different
            email.
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto"
            color="primary"
            @click="(currentSlide = 1), (dialogEmailError = false)"
            width="130"
            >Edit Email</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import Robin from "@/components/Robin";
import {
  states,
  countries,
  allCountries,
  countryCodes
} from "@/shared_data/data.js";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "MarqetaEditUser",
  props: {},
  components: {
    Robin
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      dialogEmailError: false,
      validNameForm: false,
      validAddressForm: false,
      validIdForm: false,
      states: states,
      countries: countries,
      allCountries: allCountries,
      countryCodes: countryCodes,

      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      formattedPhone: null,
      phoneNumberValid: false,
      countryCode: { code: null, value: null },
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
      country: "US",
      // otherCountry: null,
      identification: null
    };
  },
  created() {
    console.log("Created");
    this.getMarqetaUser();
  },
  beforeDestroy() {},
  methods: {
    getMarqetaUser() {
      console.log("Getting user info");
      this.currentSlide = 0;
      var startTime = new Date();
      MarqetaService.getUser(null, null, this.magicLinkToken)
        .then(user => {
          console.log("User response: ", user);
          // this.currentSlide++;
          // We check the time elapsed since sending the request. If the user has been waiting less than 1.5 seconds, we show the animation so it doesn't flash on the screen
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff < 1500) {
            setTimeout(() => {
              this.currentSlide = 1;
            }, 1500 - timeDiff);
          } else {
            this.currentSlide = 1;
          }
          this.email = user.email;
          // if (user.phone && user.phone.includes("+")) {
          //   var phone = user.phone.substring(user.phone.indexOf("-") + 1);
          // } else {
          //   phone = user.phone;
          // }
          this.phone = user.phone;
          if (user.country == null || user.country == "USA") {
            this.countryCode.code = "US";
          } else {
            this.countryCode.code = user.country;
          }

          this.address1 = user.address1;
          this.address2 = user.address2;
          this.city = user.city;
          this.state = user.state;
          this.postalCode = user.postal_code;
          if (user.country) this.country = user.country;
          // } else {
          //   this.country = "00";
          //   this.otherCountry = user.country;
          // }
        })
        .catch(error => {
          console.log("There was an error getting user: ", error);
          // this.currentSlide++;
        });
    },
    resetForm() {
      this.firstName = null;
      this.lastName = null;
      this.phone = null;
      this.address1 = null;
      this.address2 = null;
      this.city = null;
      this.state = null;
      // this.country = null;
      // this.otherCountry = null;
      this.postalCode = null;
      this.identification = null;
      if (this.currentSlide <= 3) {
        this.$refs.form.reset();
      }

      this.$emit("get-cards");
      this.$emit("scrollToTop");
      this.$emit("reset");
      this.currentSlide = 0;
    },
    previousSlide() {
      this.$emit("scrollToTop");
      if (this.currentSlide == 0 || this.currentSlide == 1) {
        this.resetForm();
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      this.$emit("scrollToTop");
      this.currentSlide += 1;
      if (this.currentSlide == 3) {
        // We are submitting api call
        this.submitUserUpdate();
      } else if (this.currentSlide == 5) {
        this.resetForm();
      }
    },
    submitUserUpdate() {
      var startTime = new Date();
      var body = {
        email: this.email,
        phone: this.formattedPhone,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.country != "US" ? null : this.state,
        postal_code: this.postalCode,
        country: this.country,
        // country: "US", //this.country != "00" ? this.country : this.otherCountry,
        metadata: {
          clientId: this.userProfile.clientId,
          clientName:
            this.userProfile && this.userProfile.Client
              ? this.userProfile.Client.clientName
              : "Unknown",
          userId: this.userProfile.userId
        }
      };
      MarqetaService.updateUser(body, this.magicLinkToken)
        .then(response => {
          console.log("Issue card response: ", response);

          // We check the time elapsed since sending the request. If the user has been waiting less than 1.5 seconds, we show the animation so it doesn't flash on the screen
          var timeDiff = moment().diff(moment(startTime));
          if (response.error_code && response.error_code == 1190022) {
            this.dialogEmailError = true;
          } else {
            if (timeDiff < 1500) {
              setTimeout(() => {
                this.currentSlide++;
              }, 1500 - timeDiff);
            } else {
              this.currentSlide++;
            }
          }
        })
        .catch(error => {
          console.log("There was an error submitting card request: ", error);
        });
    },
    countryCodeChanged(country) {
      this.countryCode.code = country.iso2;
      this.countryCode.value = country.dialCode;
    },
    phoneValidation: function({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log(number);
      if (isValid) {
        this.formattedPhone = number.e164;
        this.phoneNumberValid = true;
      } else {
        this.phoneNumberValid = false;
      }
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "displayWalletMenu",
      "walletMenuScreen",
      "magicLinkToken"
    ])
  },
  watch: {
    displayWalletMenu(val) {
      console.log(val);
      if (val && this.walletMenuScreen == "update-user") {
        this.getMarqetaUser();
      }
    }
  }
};
</script>

<style scoped>
.card-text {
  font-size: 1.2em;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}
</style>

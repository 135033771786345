<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-calculator-variant</v-icon
        >
        <v-card-title> {{ computedPageHeader }} </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-row class="my-2 ml-3 mr-2">
        <v-col sm="12" md="3">
          <div
            class="my-4 align-text-right d-flex flex-column align-end justify-center"
          >
            <v-autocomplete
              outlined
              dense
              class="pa-1 mb-2 full-width"
              hide-details
              :items="clients"
              item-text="formattedName"
              item-value="clientId"
              label="Client"
              v-model="clientId"
              v-if="userProfile.clientId === 1 && clients.length > 0"
            ></v-autocomplete>
            <span class="mr-3 mb-1" v-if="selected.length === 1"
              >{{ selected.length }} Badge Selected</span
            >
            <span class="mr-3 mb-1" v-else
              >{{ selected.length }} Badges Selected</span
            >
          </div>
          <v-divider></v-divider>
          <v-card
            rounded="0"
            elevation="2"
            class="mt-4"
            color="brandPaleBlue"
            v-show="!postItArray.includes('badges-1')"
          >
            <v-card-title class="d-flex justify-space-between">
              <v-icon color="grey">mdi-lightbulb-outline</v-icon>
              <v-btn icon
                ><v-icon
                  color="grey"
                  @click="$store.dispatch('setPostItArray', 'badges-1')"
                  >mdi-close</v-icon
                ></v-btn
              >
            </v-card-title>
            <v-card-text class="align-text-left">
              <span
                >Badges can be given to users instead of, or in addition to
                Awards. They can be given for completing certain tasks, like
                learning
              </span>
              <br />
              <span
                >Some awards can be used as certifications to prove someone is
                qualified for a certain task.</span
              >
              <br />
              <br />
              <span
                >Learn more about the budgets and awards in the
                <a @click="openFAQ">Whistle FAQ.</a></span
              >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="9">
          <div class="d-flex justify-space-between align-center mr-2 mb-3">
            <div class="d-flex">
              <div
                class="budget-button"
                v-if="permissions.includes('vue:read:budgets')"
              >
                <v-btn
                  text
                  color="primary"
                  class="mr-2"
                  to="/budgets"
                  active-class="no-active"
                  >Budgets</v-btn
                >
              </div>
              <v-btn
                v-if="permissions.includes('vue:read:awards')"
                text
                class="mr-2"
                color="primary"
                to="/budgets/awards"
                active-class="no-active"
                >Awards</v-btn
              >
              <v-btn
                text
                color="primary"
                to="/budgets/badges"
                active-class="no-active"
                >Badges</v-btn
              >
            </div>

            <v-text-field
              outlined
              placeholder="Search"
              prepend-icon="mdi-magnify"
              clearable
              single-line
              dense
              hide-details
              v-model="badgeTableSearch"
              class="shrink"
            ></v-text-field>
            <v-btn
              color="brandCyan"
              class="white--text mr-3"
              depressed
              @click="loadBadgeCreatorWidget"
              v-if="permissions.includes('badges:create:badge')"
              >Add Badge</v-btn
            >
          </div>
          <div>
            <v-data-table
              v-model="selected"
              :headers="computedColumns"
              :items="visibleBadges"
              item-key="badgeId"
              :items-per-page="10"
              :search="badgeTableSearch"
              class="elevation-0 badge-table"
              :item-class="rowClass"
              :loading="loadingBadgeTable"
              loading-text="Loading... Please wait"
              @click:row="loadEditBadgeForm"
            >
              <template v-slot:[`item.displayName`]="{ item }">
                <span class="selectable-blue-text">{{ item.displayName }}</span>
              </template>
              <template v-slot:[`item.imageUrl`]="{ item }">
                <v-img
                  :src="item.imageUrl"
                  class="selectable-blue-text"
                  max-width="32"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-navigation-drawer
      v-model="showBadgeDialog"
      temporary
      fixed
      right
      width="500"
    >
      <BadgeCreatorWidget
        v-if="showBadgeDialog"
        @close="closeBadgeCreatorWidget"
        @refreshBadges="getBadges"
        :editBadgeObject="newBadge"
        :editingBadge="editingBadge"
        :clientId="clientId"
        source="BADGES"
      />
    </v-navigation-drawer>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import BadgeService from "../services/BadgeService.js";
import LoadingDialog from "@/components/loadingDialog.vue";
import BadgeCreatorWidget from "@/components/BadgeCreatorWidget.vue";

//import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "Badges",
  title: "Whistle | Badges",
  components: {
    LoadingDialog,
    BadgeCreatorWidget
  },
  data() {
    return {
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingBadgeTable: true,
      badgeTableSearch: null,
      selected: [],
      badges: [],
      columns: [
        { text: "Image", value: "imageUrl", width: "10%" },
        { text: "Name", value: "displayName", width: "30%" },
        { text: "Description", value: "description", width: "30%" },
        { text: "Type", value: "type", width: "20%" },
        { text: "Status", value: "status", width: "20%" }
      ],
      categories: ["Cash", "Travel", "Other"],
      showBadgeDialog: false,
      newBadgeForm: {
        menuStartDate: false,
        menuExpirationDate: false,
        expDateDisabled: false,
        expDateCheckBox: false,
        valid: false,
        forceValidForm: false,
        editing: false
      },
      newBadge: {
        clientId: null,
        badgeId: null,
        name: null,
        displayName: null,
        description: null,
        type: null,
        context: null,
        signedUrl: null
      },
      editingBadge: false
    };
  },

  created() {
    if (this.$auth) {
      console.log("get badges");
      this.getBadges();
    }

    // this.clientId = this.userProfile.clientId;
  },
  methods: {
    getBadges() {
      BadgeService.getBadges()
        .then(response => {
          //Prune the returned badges for the data we need for the table
          // var badgesPruned = [];
          response
            .filter(x => x.status != "Deleted")
            .map(x => {
              x.displayName = x.displayName || x.name;
            });
          this.badges = response.reverse();
          console.log("Badges response: ", response);

          this.loadingBadgeTable = false;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    loadBadgeCreatorWidget() {
      this.showBadgeDialog = true;
      this.editingBadge = false;
    },
    closeBadgeCreatorWidget() {
      this.showBadgeDialog = false;
      // Wipes the image variables in form
      this.newBadge.imageFile = null;
      this.newBadge.certificateImageFile = null;
      this.newBadge.imageUrl = null;
      this.newBadge.certificateImageUrl = null;
    },

    loadEditBadgeForm(badgeId) {
      if (
        !this.permissions.includes("badges:create:badge") ||
        (this.userProfile.clientId != 1 &&
          badgeId.clientId != undefined &&
          badgeId.clientId != null &&
          badgeId.clientId == 0)
      ) {
        console.log("returning");
        // Cant edit badges or trying to edit universal badge
        return;
      }
      console.log("Editing ", badgeId);
      if (typeof badgeId == "object") {
        badgeId = badgeId.badgeId;
      }
      this.newBadgeForm.editing = true;
      this.editingBadge = true;
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Loading the selected badge";
      this.loadingMessageLine1 = "This should just take a minute.";
      this.loadingMessageLine2 = "";
      BadgeService.getBadge(badgeId)
        .then(badge => {
          console.log("Fetched badge", badge);

          this.newBadge.clientId = badge.clientId;
          this.newBadge.badgeId = badge.badgeId;
          this.newBadge.name = badge.name;
          this.newBadge.displayName = badge.displayName;

          this.newBadge.description = badge.description;
          this.newBadge.type = badge.type;
          this.newBadge.context = badge.context;
          this.newBadge.signedUrl = badge.signedUrl;
          this.newBadge.signedCertificateUrl = badge.signedCertificateUrl;

          this.newBadge.imageUrl = badge.imageUrl;
          this.newBadge.certificateImageUrl = badge.certificateImageUrl;

          this.showBadgeDialog = true;
          // this.newBadgeForm.valid = true;

          this.showLoadingDialog = false;
          // console.log(this.newBadgeForm.editing);
        })
        .catch(error => {
          this.showLoadingDialog = false;
          // this.resetBadgeForm();
          console.log("Error fetching badge! ", error);
        });
    },
    openFAQ() {
      // Opens up FreskDesk in a new tab
      window.open("https://whistle.freshdesk.com/support/home", "_blank");
    },
    rowClass(item) {
      // console.log(item);
      if (item.clientId == 0 && this.userProfile.clientId != 1) {
        return "cursor-default";
      } else if (
        (this.userProfile.clientId == 1 && item.clientId == 0) ||
        this.permissions.includes("badges:create:badge")
      ) {
        // console.log('pointer cursor');
        return "cursor-pointer";
      }
    }
  },
  computed: {
    ...mapState([
      "clients",
      "userProfile",
      "postItArray",
      "permissions",
      "globalClientId"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    visibleBadges() {
      if (this.clientId !== null) {
        return this.badges.filter(x => {
          return x.clientId == this.clientId || x.clientId == 0;
        });
      }
      return [];
    },
    computedPageHeader() {
      var title = "";
      if (this.permissions.includes("vue:read:budgets")) {
        title = title.concat("Budgets, ");
      }
      if (this.permissions.includes("vue:read:awards")) {
        title = title.concat("Awards, ");
      }
      if (this.permissions.includes("vue:read:badges")) {
        title = title.concat("Badges, ");
      }
      if (title.endsWith(", ")) {
        title = title.substring(0, title.length - 2);
      }
      return title;
    },
    computedColumns() {
      if (this.userProfile.clientId == 1) {
        return [
          { text: "Image", value: "imageUrl", width: "20%" },
          { text: "Name", value: "displayName", width: "30%" },
          { text: "Description", value: "description", width: "30%" },
          { text: "Type", value: "type", width: "20%" }
        ];
      } else {
        return [
          { text: "Image", value: "imageUrl", width: "20%" },
          { text: "Name", value: "displayName", width: "40%" },
          { text: "Description", value: "description", width: "40%" }
        ];
      }
    }
  }
};
</script>

<style scoped>
.v-data-table-header {
  background-color: var(--v-lightGrey-base);
}

#template-download-button {
  margin-right: 3%;
  /* margin-bottom: 3%; */
}
.v-stepper__step__step.primary {
  background-color: red !important;
  border-color: red !important;
}
.dialog {
  z-index: inherit;
}

.budget-button .v-btn--active.no-active::before {
  opacity: 0 !important;
}

.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}

.content-upload {
  margin: 0 auto 0;
  padding: 3px;
}

.badge-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

.badge-table >>> .v-data-table-header {
  background-color: white;
}
</style>

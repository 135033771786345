<template>
  <v-card elevation="0" rounded="0">
    <v-toolbar dark flat color="brandCyan" rounded="0" width="100%">
      <div>
        <v-toolbar-title>Add a progress metric</v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close">
          <span class="mt-1">Close</span>
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row no-gutters justify="center" full-width no-wrap class="mb-2">
      <v-col cols="10" class="d-flex flex-column text-left mx-0 my-0">
        <div class="mt-10 d-flex justify-space-between align-center">
          <p class="mb-0 mediumGrey--text">Available Metrics</p>
          <v-btn icon color="primary" @click="showHideSearch"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
        </div>
        <v-text-field
          v-if="dialog.search"
          ref="search"
          outlined
          placeholder="Search"
          prepend-icon="mdi-magnify"
          single-line
          dense
          hide-details
          v-model="data.search"
          class="mb-5 mt-3"
        ></v-text-field>
        <div class="d-flex justify-center my-3">
          <v-btn
            text
            color="primary"
            class="mx-auto"
            @click="dialog.newMetric = true"
          >
            <v-icon small class="create-metric-button mr-2" color="white"
              >mdi-plus</v-icon
            >Add a New Metric</v-btn
          >
        </div>
        <!-- <div class="tab-group-container text-center mt-2">
          <v-btn
            depressed
            @click="changeTab(0)"
            :color="activeTab == 0 ? 'white' : 'lightGrey'"
            class="px-5 mr-1 tab-button"
            :class="{
              'active-tab-button': activeTab == 0,
            }"
            label
          >
            Learning
          </v-btn>
          <v-btn
            depressed
            @click="changeTab(1)"
            :color="activeTab == 1 ? 'white' : 'lightGrey'"
            class="px-5 ml-1 tab-button"
            :class="{
              'active-tab-button': activeTab == 1,
            }"
            label
          >
            Surveys
          </v-btn>
        </div> -->
      </v-col>
    </v-row>
    <div class="mt-2 mx-12 text-left pb-4">
      <div
        class="d-flex justify-center"
        v-if="loadingMetrics || loading.metrics"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="mx-auto"
        ></v-progress-circular>
      </div>

      <div v-for="metric in visibleMetrics" :key="metric.id">
        <v-hover v-slot="{ hover }">
          <div
            class="mx-0 px-0 py-1 cursor-pointer"
            @click="showValueDialog(metric)"
          >
            <!-- <v-divider /> -->
            <div class="d-flex align-center">
              <v-icon
                class="metric-icons"
                :class="
                  hover
                    ? 'primary-background'
                    : `${metric.iconColor}-background`
                "
                color="white"
                >{{ hover ? "mdi-plus" : metric.icon }}</v-icon
              >

              <div
                class="ml-2 px-3 metric-box d-flex justify-space-between align-center"
              >
                <span class="metric-label">{{ metric.displayName }}</span>
                <v-btn
                  icon
                  small
                  @click.stop.native="editMetric(metric)"
                  v-if="
                    hover && (metric.clientId != 0 || userProfile.clientId == 1)
                  "
                  class="my-0"
                >
                  <v-icon
                    color="
                    grey
                  "
                    small
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </div>
            </div>

            <!-- <template v-slot:actions>
            <v-icon small>$expand</v-icon>
          </template> -->
          </div>
        </v-hover>
      </div>
    </div>
    <!-- Metric value dialog -->
    <v-dialog v-model="dialog.value" width="500" class="dialog">
      <v-card
        rounded="0"
        class="d-flex justify-center flex-column pa-6"
        v-if="metric"
      >
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <!-- <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          > -->
          <v-card-title class="word-break text-left exit-warning-text">
            Enter the value for this progress metric to consider it "achieved"
          </v-card-title>
        </div>
        <v-form v-model="valid" onSubmit="return false;">
          <v-card-text class="pt-0 full-width">
            <v-text-field
              v-if="metric.dataType === 'STRING'"
              label="Value"
              dense
              outlined
              :rules="[rules.empty, rules.longString]"
              v-model="metricValue"
              class="input-box mx-auto"
            ></v-text-field>
            <v-text-field
              v-else-if="
                metric.dataType === 'INTEGER' || metric.dataType === 'DECIMAL'
              "
              label="Number"
              type="number"
              dense
              outlined
              :rules="
                metric.dataType === 'INTEGER'
                  ? [rules.integer, rules.empty]
                  : [rules.empty]
              "
              v-model="metricValue"
              class="input-box mx-auto"
            ></v-text-field>
            <div
              v-else-if="metric.dataType === 'BOOLEAN'"
              class="d-flex justify-center"
            >
              <v-checkbox v-model="metricValue" label="Value (Y/N)" />
            </div>
            <div
              v-else-if="
                metric.dataType === 'DATE' || metric.dataType === 'TIMESTAMP'
              "
              class="input-box d-flex flex-column align-center mx-auto"
            >
              <p class="mb-n2 ml-2 text-caption text-left full-width">
                <span v-if="metric.dataType == 'DATE'">Select a date</span>
                <span v-else>Select a date/time</span>
              </p>
              <input
                class="timestamp-input"
                type="date"
                v-model="metricValue"
                v-if="metric.dataType === 'DATE'"
                :rules="[rules.empty]"
              />
              <input
                class="timestamp-input"
                type="datetime-local"
                v-model="metricValue"
                v-else-if="metric.dataType === 'TIMESTAMP'"
                :rules="[rules.empty]"
              />
            </div>
          </v-card-text>
        </v-form>

        <v-card-actions class="mx-12 d-flex justify-center">
          <v-btn
            class="mr-4"
            color="primary"
            outlined
            width="130"
            @click="
              (dialog.value = false),
                (metric = null),
                (metricValue = null),
                (editingMetric = false)
            "
            >Cancel</v-btn
          >
          <v-btn
            class="ml-4"
            color="primary"
            width="130"
            @click="addToProgram"
            :disabled="!valid || metricValue == null"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-model="dialog.newMetric"
      temporary
      fixed
      right
      width="500"
    >
      <MetricCreatorWidget
        @close="closeMetricCreator"
        @get-metrics="getMetrics"
        source="programMetricWidget"
        :preloadedMetric="metric"
        :clientId="clientId"
        v-if="dialog.newMetric == true"
      />
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import ProgramService from "@/services/ProgramService";
import MetricCreatorWidget from "@/components/MetricCreatorWidget.vue";

// import RobinAnimation from "./Robin";

import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "ProgramMetricWidget",
  components: {
    // LoadingDialog,
    // RobinAnimation,
    MetricCreatorWidget
  },
  props: {
    clientId: Number,
    metrics: Array,
    preloadMetric: Object,
    preloadMetricIndex: Number,
    loadingMetrics: Boolean,
    activeProgram: Boolean,
    metricsInProgram: Array
  },
  data() {
    return {
      data: {
        metrics: [],
        search: null
      },
      dialog: {
        value: false,
        newMetric: false,
        search: false
      },
      loading: {
        metrics: false
      },
      activeTab: 0,

      valid: false,
      metricValue: null,
      metric: null,
      editingMetric: false,

      rules: {
        integer: value =>
          (value && Number.isInteger(Number(value))) || "Must be whole number",
        empty: value => (value && value.trim() !== "") || "A value is required",
        longString: value =>
          (value && value.length < 256) || "Value is too long"
      }
    };
  },

  created() {
    // We pass in props and fetch metrics if not passed in
    if (!this.metrics) this.getMetrics();
    if (this.preloadMetric) {
      this.metric = this.preloadMetric;
      if (this.preloadMetric.dataType == "BOOLEAN") {
        if (this.preloadMetric.value == 0) this.metricValue = false;
        else this.metricValue = true;
      } else this.metricValue = this.preloadMetric.value;
      this.dialog.value = true;
      this.editingMetric = true;
    }
    if (this.metrics) {
      this.data.metrics = this.metrics;
    }
  },
  mounted() {},
  methods: {
    getMetrics(metric = null, updating = false) {
      // console.log("get metrics");
      console.log(metric);
      // console.log("updating", updating);
      this.loading.metrics = true;
      ProgramService.getMetrics()
        .then(response => {
          console.log("Metrics: ", response);
          var metrics = response
            .filter(
              metric =>
                metric.status !== "Deleted" &&
                (metric.clientId == 0 || metric.clientId == this.clientId)
            )
            .map(x => {
              var obj = x;
              obj.id = x.metricId.toString();
              obj.displayName = x.metricName
                ? x.metricName
                : "Untitled progress metric";
              obj.context = "MetricDefinitions";
              obj.contextResourceId = x.metricId.toString();
              obj.contextColumn = "metricValue";
              obj.label = x.metricName;
              obj.dataType = x.dataType;
              obj.showNumberOptions =
                x.calculateProgress && x.dataType !== "BOOLEAN";
              obj.icon =
                x.dataType == "BOOLEAN"
                  ? "mdi-checkbox-marked-outline"
                  : "mdi-finance";
              obj.iconColor = "brand-cyan";
              obj.displayGrabber = true;
              return obj;
            });
          this.data.metrics = metrics;
          // if (refresh) {
          //   this.metrics = metrics;
          // }
          // Now we emit to update if the metrics were passed in from parents
          this.$emit("update-metrics-array", metrics);

          this.loading.metrics = false;
          if (metric && updating == false) {
            // The method was called from the metric creator widget, add metric to program
            this.showValueDialog(metric);
          } else if (metric && updating) {
            // Called from within the metric creator so we update the metric if it exists in the program
            this.updateMetric(metric);
          }
        })
        .catch(error => {
          console.log("Error getting metrics ", error);
          this.loading.metrics = false;
        });
    },
    close() {
      this.$emit("close");
    },
    addToProgram() {
      let metric = JSON.parse(JSON.stringify(this.metric));
      if (this.metric.dataType == "TIMESTAMP") {
        metric.value = moment(this.metricValue).format("YYYY-MM-DD HH:mm:ss");
      } else if (this.metric.dataType == "BOOLEAN") {
        metric.value = this.metricValue === true ? "1" : "0";
      } else {
        metric.value = this.metricValue;
      }

      if (this.editingMetric && this.preloadMetricIndex !== null) {
        console.log("Updating metric in program ", metric);
        metric.index = this.preloadMetricIndex;
        this.$emit("update-element", metric);
        this.close();
      } else {
        console.log("Adding metric to program ", metric);
        this.$emit("add-to-program", metric);
      }

      this.dialog.value = false;
      this.metric = null;
      this.metricValue = null;
      this.editingMetric = false;
    },
    showValueDialog(metric) {
      // console.log("Show value dialog");
      // console.log(metric);
      // If boolean then we don't need to ask the user for a value
      if (metric.dataType == "BOOLEAN") {
        metric.value = "1";
        this.$emit("add-to-program", metric);
        return;
      }
      console.log("Showing value dialog for metric ", metric);
      this.metric = metric;
      this.dialog.value = true;
      // this.$emit("add-to-program", metric);
    },
    editMetric(metric) {
      // console.log("Editing metric");
      // console.log(metric);
      this.metric = metric;
      this.dialog.newMetric = true;
    },
    updateMetric(metric) {
      // console.log(metric);
      // Find metric in element list and update the info
      const foundMetric = this.metricsInProgram.find(
        x => x.contextResourceId == metric.contextResourceId
      );
      console.log("update metric, metric found? ", foundMetric);
      if (foundMetric) {
        // if the metric they're editing is in the program, update
        // console.log("UPDATE METRIC");
        this.$emit("update-metric", metric);
      }
      // else if (metric.metricId || metric.contextResourceId) {
      //   // else, show value dialog
      //   this.showValueDialog(metric);
      // }

      // We had set metric to the metric we're editing, clear it
      this.metric = null;
    },
    closeMetricCreator() {
      this.dialog.newMetric = false;
      this.metric = null;
    },
    showHideSearch() {
      this.dialog.search = !this.dialog.search;

      this.$nextTick(() => {
        if (this.$refs.search) this.$refs.search.focus();
      });
    }
  },
  computed: {
    ...mapState(["userProfile", "clients"]),
    visibleMetrics() {
      // if (this.metrics) return this.metrics;
      // else
      if (!this.data.search) return this.data.metrics;
      return this.data.metrics.filter(
        x =>
          x.displayName &&
          x.displayName.toLowerCase().includes(this.data.search.toLowerCase())
      );
    }
  }
};
</script>

<style scoped>
/* Used for the table of metrics */
.metric-icons {
  border-radius: 5px;
  width: 35px;
  min-width: 35px;
  height: 35px;
}

/* Class for the boxes around each metric */
.metric-box {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 35px;
}

.input-box {
  width: 60%;
  max-width: 300px;
}

/* Used for the small plus button for creating a new metric */
.create-metric-button {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: var(--v-brand-base);
}

/* Used to cap the text for the display names for elements if they're too long */
.metric-label {
  width: calc(100%);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 7px;
}
</style>

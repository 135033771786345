<template>
  <div>
    <v-dialog
      fullscreen
      value="true"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="brandOrange" rounded="0" elevation="0">
          <v-btn dark icon large @click="warnBeforeExiting" class="ml-1">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-flex align-center font-weight-bold"
            ><v-icon v-if="!isMobile" class="mr-3"
              >mdi-newspaper-variant-outline</v-icon
            >
            Send a message or article
            {{
              devEnvironment
                ? `Editing: ${editingMessage}, Duplicating: ${duplicatingMessage}, Template: ${templateMode}`
                : ""
            }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            icon
            large
            @click="reset"
            class="ml-1 hidden"
            v-if="userProfile.clientId != 1"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-autocomplete
            v-else
            outlined
            dense
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            style="max-width: 250px"
            v-model="clientId"
            @change="refreshClientId"
            :disabled="slideKey > keys.review || editingMessage"
          ></v-autocomplete>
        </v-toolbar>
        <v-row
          class="full-height mt-6 pb-6 container-row"
          justify="center"
          align="center"
          height="100%"
          no-gutters
        >
          <v-col
            cols="11"
            sm="11"
            md="6"
            lg="5"
            xl="5"
            class="main-card rounded-lg px-5 d-flex flex-column justify-space-between"
          >
            <div>
              <div class="d-flex justify-space-between align-center mt-10">
                <pill-nav
                  :steps="pillNavSteps"
                  width="100%"
                  :height="40"
                  :step="slideKey"
                  :value="slideKey > keys.review ? progressBarValue : undefined"
                  :color="'brandOrange'"
                  @input="goToSlide"
                >
                </pill-nav>
                <div class="lottie-container">
                  <v-btn
                    :width="isMobile ? 90 : 110"
                    rounded
                    depressed
                    color="brandCyan"
                    class="white--text font-weight-bold"
                    :class="{ hidden: slideKey == keys.review }"
                    @click="goToNextSlide"
                    :disabled="disableContinueButton"
                  >
                    <v-icon
                      color="white"
                      class="mr-1"
                      v-if="slideKey == keys.sent"
                      >mdi-checkbox-marked-circle</v-icon
                    >
                    <v-icon
                      color="white"
                      class="mr-1"
                      v-else-if="!isMobile && slideKey != keys.review"
                      >mdi-arrow-right-circle</v-icon
                    >
                    <span
                      v-if="slideKey !== keys.sent"
                      style="margin-top: 2px"
                      >{{
                        slideKey == keys.sent
                          ? "Close"
                          : slideKey == keys.review
                          ? "Send"
                          : "Next"
                      }}</span
                    >
                  </v-btn>
                  <div v-if="lottie.particle" class="lottie-particle-effect">
                    <LottiePlayer
                      animation="particles"
                      :height="250"
                      :width="250"
                      :speed="1.5"
                      :loop="false"
                    />
                  </div>
                </div>
              </div>

              <!-- </div> -->
              <transition :name="slideDirection" mode="out-in">
                <div
                  v-if="slideKey === keys.recipient"
                  :key="keys.recipient"
                  class="full-width text-left"
                >
                  <p class="text-h6 font-weight-bold mt-12" v-if="pageTitle">
                    {{ pageTitle }}
                  </p>
                  <div class="d-flex align-center flex-wrap mt-4">
                    <div
                      v-for="(user, index) in values.selected"
                      :key="user.userId"
                      :style="{ 'max-width': '100%' }"
                    >
                      <v-chip
                        class="ma-1 selected-audience-chip"
                        close
                        outlined
                        color="brandCyan"
                        @click:close="removeUserFromSelected(index)"
                      >
                        <v-icon
                          @click.stop.native="loadQrCode(user.groupId)"
                          class="mr-1 cursor-pointer"
                          color="grey"
                          v-if="user.groupId && user.groupType === 'List'"
                          >mdi-qrcode</v-icon
                        >
                        <span class="text-truncate black--text">
                          {{ user.displayName }}</span
                        >
                      </v-chip>
                    </div>
                  </div>

                  <div class="d-flex justify-space-between align-center mt-10">
                    <v-text-field
                      :label="
                        programMode
                          ? 'Start typing a program name'
                          : 'Start typing a name, email, or phone'
                      "
                      v-model="data.debounceSearch"
                      outlined
                      flat
                      dense
                      hide-details
                      color="brandCyan"
                      class="search-field mr-3"
                    >
                    </v-text-field>
                    <v-btn
                      v-if="
                        ((data.activeAudienceTab == 2 && canCreateGroup) ||
                          (canCreateUser && data.activeAudienceTab != 2)) &&
                          !programMode
                      "
                      fab
                      depressed
                      small
                      color="brandCyan"
                      @click="
                        if (data.activeAudienceTab == 2) {
                          displayGroupTypeSelector();
                        } else {
                          dialog.createUser = true;
                        }
                      "
                      ><v-icon color="white">{{
                        data.activeAudienceTab == 2
                          ? "mdi-account-multiple-plus"
                          : "mdi-account-plus"
                      }}</v-icon></v-btn
                    >
                  </div>

                  <div
                    v-if="
                      (permissions || []).includes('vue:read:groups') &&
                        !programMode
                    "
                    class="d-flex justify-space-between align-center mt-6"
                    :class="{ 'mx-14': !isMobile }"
                  >
                    <!-- <span
                      @click="changeAudienceTab(0)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 0
                      }"
                      >Recent</span
                    > -->
                    <span
                      @click="changeAudienceTab(1)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 1
                      }"
                      >People</span
                    >
                    <span
                      @click="changeAudienceTab(2)"
                      class="rounded-pill audience-tab cursor-pointer"
                      :class="{
                        'active-audience-tab': data.activeAudienceTab == 2
                      }"
                      >Groups</span
                    >
                  </div>
                  <div class="text-left mt-2">
                    <v-divider class="mt-5" />
                    <!-- Table for people -->
                    <div v-if="programMode">
                      <ProgramTable
                        ref="program-table"
                        :selected="values.selected"
                        :search="data.search"
                        :hideDefaultHeader="true"
                        :displayInfo="true"
                        sourcePage="communicationwizard"
                        @select-item="selectProgram"
                      />
                    </div>
                    <div v-else-if="data.activeAudienceTab <= 1">
                      <UserTable
                        ref="user-table"
                        :selected="selectedUsers"
                        :search="data.search"
                        sourcePage="communicationwizard"
                        @select-item="addUserToSelected($event, false)"
                      />
                    </div>
                    <div v-else>
                      <GroupTable
                        ref="group-table"
                        :selected="values.selected"
                        :search="data.search"
                        :includeEveryone="true"
                        :totalUserCount="data.userCount"
                        sourcePage="communicationwizard"
                        @select-item="addUserToSelected($event, false)"
                        @update-item="updateSelectedItem"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.distribution"
                  :key="keys.distribution"
                  class="full-width text-left"
                >
                  <p
                    class="text-h6 font-weight-bold mb-5 mt-12"
                    v-if="pageTitle"
                  >
                    {{ pageTitle }}
                  </p>
                  <div class="d-flex flex-column mx-3">
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="
                        values.displayOnPlatform = !values.displayOnPlatform
                      "
                    >
                      <!-- <v-checkbox
                        v-model="values.displayOnPlatform"
                        color="brandCyan"
                      /> -->
                      <div>
                        <v-btn
                          fab
                          small
                          :color="
                            values.displayOnPlatform ? 'brandCyan' : 'grey'
                          "
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white">mdi-monitor</v-icon></v-btn
                        >
                        <span>Activity page</span>
                      </div>
                      <v-checkbox
                        v-model="values.displayOnPlatform"
                        color="brandCyan"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="values.sendEmail = !values.sendEmail"
                    >
                      <!-- <v-checkbox
                        v-model="values.displayOnPlatform"
                        color="brandCyan"
                      /> -->
                      <div>
                        <v-btn
                          fab
                          small
                          :color="values.sendEmail ? 'brandCyan' : 'grey'"
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white">mdi-email</v-icon></v-btn
                        >
                        <span>Email</span>
                      </div>
                      <v-checkbox
                        v-model="values.sendEmail"
                        color="brandCyan"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="values.sendSMS = !values.sendSMS"
                    >
                      <!-- <v-checkbox
                        v-model="values.displayOnPlatform"
                        color="brandCyan"
                      /> -->
                      <div>
                        <v-btn
                          fab
                          small
                          :color="values.sendSMS ? 'brandCyan' : 'grey'"
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white"
                            >mdi-comment-text</v-icon
                          ></v-btn
                        >
                        <span>Text message</span>
                      </div>
                      <v-checkbox
                        v-model="values.sendSMS"
                        color="brandCyan"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="values.sendPush = !values.sendPush"
                    >
                      <!-- <v-checkbox
                        v-model="values.displayOnPlatform"
                        color="brandCyan"
                      /> -->
                      <div>
                        <v-btn
                          fab
                          small
                          :color="values.sendPush ? 'brandCyan' : 'grey'"
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white">mdi-cellphone</v-icon></v-btn
                        >
                        <span>Mobile app notification</span>
                      </div>
                      <v-checkbox
                        v-model="values.sendPush"
                        color="brandCyan"
                        class="ml-2"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                  </div>
                  <!-- <div class="d-flex flex-wrap full-width justify-center">
                    <div
                      class="box-border-global rounded distribution-card-chip"
                      :class="{
                        'distribution-card-chip-selected opacity-40':
                          values.displayOnPlatform
                      }"
                      @click="
                        values.displayOnPlatform = !values.displayOnPlatform
                      "
                    >
                      <v-icon
                        class="mr-2"
                        :color="values.displayOnPlatform ? 'white' : undefined"
                        >mdi-monitor</v-icon
                      >
                      Activity
                    </div>
                    <div
                      class="box-border-global rounded distribution-card-chip"
                      :class="{
                        'distribution-card-chip-selected opacity-40':
                          values.sendEmail
                      }"
                      @click="values.sendEmail = !values.sendEmail"
                    >
                      <v-icon
                        class="mr-2"
                        :color="values.sendEmail ? 'white' : undefined"
                        >mdi-email</v-icon
                      >
                      Email
                    </div>
                    <div
                      class="box-border-global rounded distribution-card-chip"
                      :class="{
                        'distribution-card-chip-selected': values.sendSMS
                      }"
                      @click="values.sendSMS = !values.sendSMS"
                    >
                      <v-icon
                        class="mr-2"
                        :color="values.sendSMS ? 'white' : undefined"
                        >mdi-comment-text</v-icon
                      >
                      Text
                    </div>
                    <div
                      class="box-border-global rounded distribution-card-chip"
                      :class="{
                        'distribution-card-chip-selected opacity-40':
                          values.sendPush
                      }"
                      @click="values.sendPush = !values.sendPush"
                    >
                      <v-icon
                        class="mr-2"
                        :color="values.sendPush ? 'white' : undefined"
                        >mdi-cellphone</v-icon
                      >
                      App
                    </div>
                  </div> -->
                  <div
                    v-if="!templateMode"
                    class="d-flex justify-space-between align-center full-width mt-12"
                  >
                    <p
                      class="review-page-text mb-0"
                      :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                    >
                      Time of delivery
                    </p>
                    <input
                      ref="sendDatePicker"
                      class="timestamp-input review-page-field"
                      type="datetime-local"
                      v-model="values.sendDate"
                    />
                  </div>
                  <p
                    v-if="!sendDateIsValid"
                    class="error--text text-right mt-2"
                  >
                    Invalid date
                  </p>
                  <div
                    v-if="!programMode"
                    class="d-flex justify-space-between align-center full-width mt-8"
                  >
                    <p
                      class="review-page-text mb-0"
                      :class="{ 'text-xs': $vuetify.breakpoint.xs }"
                    >
                      Type of message
                    </p>
                    <v-select
                      outlined
                      attach
                      dense
                      hide-details
                      :menu-props="{ top: true, offsetY: true }"
                      v-model="values.context"
                      :items="data.messageContexts"
                      item-text="label"
                      item-value="value"
                      color="brandCyan"
                      class="review-page-field"
                    />
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.program"
                  :key="keys.program"
                  class="full-width text-left"
                >
                  <p
                    class="text-h6 font-weight-bold mb-5 mt-12"
                    v-if="pageTitle"
                  >
                    {{ pageTitle }}
                  </p>
                  <div class="d-flex flex-column mx-3">
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="
                        (values.programAudience.everyone = !values
                          .programAudience.everyone),
                          (values.programAudience.started = false),
                          (values.programAudience.notStarted = false),
                          (values.programAudience.complete = false)
                      "
                    >
                      <div class="d-flex align-center">
                        <div
                          class="program-audience-fab"
                          :class="{
                            'brand-cyan-background':
                              values.programAudience.everyone,
                            'css-default-grey-background': !values
                              .programAudience.everyone
                          }"
                        >
                          {{ programAudienceEveryone?.length }}
                        </div>
                        <!-- <v-btn
                          fab
                          small
                          :color="
                            values.programAudience.everyone
                              ? 'brandCyan'
                              : 'grey'
                          "
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white">mdi-monitor</v-icon></v-btn
                        > -->

                        <span>Everyone</span>
                      </div>
                      <v-checkbox
                        v-model="values.programAudience.everyone"
                        color="brandCyan"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="
                        (values.programAudience.notStarted = !values
                          .programAudience.notStarted),
                          (values.programAudience.everyone = false)
                      "
                    >
                      <div class="d-flex align-center">
                        <div
                          class="program-audience-fab"
                          :class="{
                            'brand-cyan-background':
                              values.programAudience.notStarted,
                            'css-default-grey-background': !values
                              .programAudience.notStarted
                          }"
                        >
                          {{ programAudienceNotStarted?.length }}
                        </div>
                        <!-- <v-btn
                          fab
                          small
                          :color="
                            values.programAudience.notStarted
                              ? 'brandCyan'
                              : 'grey'
                          "
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white">mdi-email</v-icon></v-btn
                        > -->
                        <span>Not started</span>
                      </div>
                      <v-checkbox
                        v-model="values.programAudience.notStarted"
                        color="brandCyan"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="
                        (values.programAudience.started = !values
                          .programAudience.started),
                          (values.programAudience.everyone = false)
                      "
                    >
                      <div class="d-flex align-center">
                        <div
                          class="program-audience-fab"
                          :class="{
                            'brand-cyan-background':
                              values.programAudience.started,
                            'css-default-grey-background': !values
                              .programAudience.started
                          }"
                        >
                          {{ programAudienceStarted?.length }}
                        </div>
                        <!-- <v-btn
                          fab
                          small
                          :color="
                            values.programAudience.started
                              ? 'brandCyan'
                              : 'grey'
                          "
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white"
                            >mdi-comment-text</v-icon
                          ></v-btn
                        > -->
                        <span>Started</span>
                      </div>
                      <v-checkbox
                        v-model="values.programAudience.started"
                        color="brandCyan"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                    <div
                      class="d-flex align-center justify-space-between distribution-button"
                      @click="
                        (values.programAudience.complete = !values
                          .programAudience.complete),
                          (values.programAudience.everyone = false)
                      "
                    >
                      <div class="d-flex align-center">
                        <div
                          class="program-audience-fab"
                          :class="{
                            'brand-cyan-background':
                              values.programAudience.complete,
                            'css-default-grey-background': !values
                              .programAudience.complete
                          }"
                        >
                          {{ programAudienceComplete?.length }}
                        </div>
                        <!-- <v-btn
                          fab
                          small
                          :color="
                            values.programAudience.complete
                              ? 'brandCyan'
                              : 'grey'
                          "
                          class="mx-2"
                          elevation="0"
                          ><v-icon color="white">mdi-cellphone</v-icon></v-btn
                        > -->
                        <span>Complete</span>
                      </div>
                      <v-checkbox
                        v-model="values.programAudience.complete"
                        color="brandCyan"
                        class="ml-2"
                        @click.native="$event.stopPropagation()"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey === keys.message"
                  :key="keys.message"
                  class="full-width text-left"
                >
                  <div
                    class="d-flex align-center justify-space-between mb-5 mt-12"
                  >
                    <p class="text-h6 font-weight-bold mb-0" v-if="pageTitle">
                      {{ pageTitle }}
                    </p>
                    <div class="d-flex">
                      <v-btn
                        v-if="values.displayOnPlatform"
                        @click="previewMessage('activity')"
                        fab
                        small
                        color="brandCyan"
                        class="mx-1"
                        elevation="0"
                        ><v-icon color="white">mdi-monitor</v-icon></v-btn
                      >
                      <v-btn
                        v-if="values.sendEmail"
                        @click="previewMessage('email')"
                        fab
                        small
                        color="brandCyan"
                        class="mx-1"
                        elevation="0"
                        ><v-icon color="white">mdi-email</v-icon></v-btn
                      >
                      <v-btn
                        v-if="values.sendSMS"
                        @click="previewMessage('sms')"
                        fab
                        small
                        color="brandCyan"
                        class="mx-1"
                        elevation="0"
                        ><v-icon color="white">mdi-comment-text</v-icon></v-btn
                      >
                      <v-btn
                        v-if="values.sendPush"
                        @click="previewMessage('push')"
                        fab
                        small
                        color="brandCyan"
                        class="mx-1"
                        elevation="0"
                        ><v-icon color="white">mdi-cellphone</v-icon></v-btn
                      >
                    </div>
                  </div>
                  <div class="pt-5">
                    <v-form
                      v-model="form.validMessage"
                      onSubmit="return false;"
                    >
                      <v-text-field
                        label="Title / Subject line"
                        outlined
                        dense
                        color="brandCyan"
                        :rules="formRules.stringRequired(100)"
                        v-model="values.messageSubject"
                      ></v-text-field>
                      <ImageSelector
                        v-if="values.sendEmail || values.displayOnPlatform"
                        :value="values.imageURL"
                        :aspectRatioValue="16 / 9"
                        aspectRatioText="16 : 9"
                        class="mb-5"
                        @update-url="values.imageURL = $event"
                        @update-file="values.imageFile = $event"
                      />

                      <TextEditor
                        placeholder="Add text for your message"
                        :largeVersion="false"
                        :shortcodes="true"
                        :programRelated="programMode"
                        :programHasEndDate="
                          programMode && values.selected?.[0]?.endDate
                            ? true
                            : false
                        "
                        :editorContent="values.messageBody"
                        @update-message="values.messageBody = $event"
                        class="mt-2"
                      />
                      <div class="d-flex align-start mt-4">
                        <v-text-field
                          label="Abbreviated message (optional)"
                          outlined
                          dense
                          color="brandCyan"
                          :rules="formRules.stringOptional(255)"
                          v-model="values.messageShortBody"
                        ></v-text-field>
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-on="on" v-bind="attrs" class="mt-2 ml-2"
                              >mdi-help-circle</v-icon
                            ></template
                          ><span
                            >We display this when there are content length
                            limitations, such as with text message and app
                            notifications.</span
                          ></v-tooltip
                        >
                      </div>
                    </v-form>
                  </div>
                </div>

                <div
                  v-else-if="slideKey === keys.review"
                  :key="keys.review"
                  class="full-width text-left pb-4"
                >
                  <div>
                    <div
                      class="d-flex align-center justify-space-between mb-5 mt-12"
                    >
                      <p class="text-h6 font-weight-bold mb-0" v-if="pageTitle">
                        {{ pageTitle }}
                      </p>
                      <div class="d-flex">
                        <v-btn
                          v-if="values.displayOnPlatform"
                          @click="previewMessage('activity')"
                          fab
                          small
                          color="brandCyan"
                          class="mx-1"
                          elevation="0"
                          ><v-icon color="white">mdi-monitor</v-icon></v-btn
                        >
                        <v-btn
                          v-if="values.sendEmail"
                          @click="previewMessage('email')"
                          fab
                          small
                          color="brandCyan"
                          class="mx-1"
                          elevation="0"
                          ><v-icon color="white">mdi-email</v-icon></v-btn
                        >
                        <v-btn
                          v-if="values.sendSMS"
                          @click="previewMessage('sms')"
                          fab
                          small
                          color="brandCyan"
                          class="mx-1"
                          elevation="0"
                          ><v-icon color="white"
                            >mdi-comment-text</v-icon
                          ></v-btn
                        >
                        <v-btn
                          v-if="values.sendPush"
                          @click="previewMessage('push')"
                          fab
                          small
                          color="brandCyan"
                          class="mx-1"
                          elevation="0"
                          ><v-icon color="white">mdi-cellphone</v-icon></v-btn
                        >
                      </div>
                    </div>
                    <p class="pt-4 mb-0" v-if="!templateMode">
                      This will be sent to
                      <strong
                        class="cursor-pointer"
                        @click="goToSlide(keys.recipient)"
                        >{{ audienceCount }}
                        {{ audienceCount == 1 ? "person" : "people" }}</strong
                      >
                      <strong
                        class="cursor-pointer"
                        @click="goToSlide(keys.distribution)"
                      >
                        {{ formattedSendDate }}</strong
                      >.
                    </p>
                    <p class="pt-4 mb-0">
                      Use the blue preview buttons above to review your message.
                      If you are done editing, click below to save{{
                        templateMode
                          ? " your template."
                          : `, or save and ${messageSchedulingLanguage}.`
                      }}
                    </p>
                    <div
                      class="my-13 d-flex justify-center align-center flex-wrap"
                    >
                      <v-btn
                        color="brandCyan"
                        class="white--text px-7"
                        depressed
                        rounded
                        @click="createMessage(true)"
                        >Save</v-btn
                      >
                      <span v-if="!templateMode" class="mx-4">or</span>
                      <v-btn
                        v-if="!templateMode"
                        color="brandCyan"
                        class="white--text px-7"
                        depressed
                        rounded
                        @click="createMessage(false)"
                        :disabled="disableContinueButton"
                        >Save and {{ messageSchedulingLanguage }}</v-btn
                      >
                    </div>
                    <p v-if="messageBatchWarning" class="mb-8 font-weight-bold">
                      {{ messageBatchWarning }}
                    </p>
                    <div
                      v-if="
                        (values.sendEmail && userProfile.businessEmail) ||
                          (values.sendSMS && userProfile.businessPhone)
                      "
                    >
                      <v-divider />
                      <p class="text-h6 font-weight-bold mt-6">
                        Send a preview
                      </p>
                      <v-form
                        v-if="values.sendEmail && userProfile.businessEmail"
                        v-model="form.emailPreview"
                        @submit.prevent="previewMessage('email', true)"
                      >
                        <div class="d-flex justify-space-between">
                          <v-text-field
                            name="Email field"
                            dense
                            outlined
                            label="Send preview email"
                            color="brandCyan"
                            required
                            disabled
                            v-model="values.previewEmail"
                          ></v-text-field>
                          <div class="lottie-container">
                            <v-btn
                              color="brandCyan"
                              class="white--text ml-4"
                              depressed
                              rounded
                              type="submit"
                              :loading="loading.emailPreview"
                              ><v-icon
                                small
                                color="brandCyan"
                                class="white-background pa-1 circle-border-radius mr-2 ml-n2"
                                >mdi-email</v-icon
                              >Send</v-btn
                            >
                            <div
                              v-if="lottie.particleEmail"
                              class="lottie-particle-effect"
                            >
                              <LottiePlayer
                                animation="particles"
                                :height="250"
                                :width="250"
                                :speed="1.5"
                                :loop="false"
                              />
                            </div>
                          </div>
                        </div>
                      </v-form>
                      <p class="error--text" v-if="error.emailPreview">
                        We encountered an error sending your email preview.
                        Please try again or contact Whistle.
                      </p>
                      <v-form
                        v-if="values.sendSMS && userProfile.businessPhone"
                        v-model="form.textPreview"
                        @submit.prevent="previewMessage('sms', true)"
                      >
                        <div class="d-flex justify-space-between">
                          <v-text-field
                            dense
                            outlined
                            label="Send preview text"
                            color="brandCyan"
                            required
                            disabled
                            v-model="values.previewText"
                          ></v-text-field>
                          <div class="lottie-container">
                            <v-btn
                              color="brandCyan"
                              class="white--text ml-4"
                              depressed
                              rounded
                              type="submit"
                              :loading="loading.textPreview"
                              ><v-icon
                                small
                                color="brandCyan"
                                class="white-background pa-1 circle-border-radius mr-2 ml-n2"
                                >mdi-comment-text</v-icon
                              >Send</v-btn
                            >
                            <div
                              v-if="lottie.particleText"
                              class="lottie-particle-effect"
                            >
                              <LottiePlayer
                                animation="particles"
                                :height="250"
                                :width="250"
                                :speed="1.5"
                                :loop="false"
                              />
                            </div>
                          </div>
                        </div>
                      </v-form>
                      <p class="error--text" v-if="error.textPreview">
                        We encountered an error sending your text preview.
                        Please try again or contact Whistle.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.submitting"
                  :key="keys.submitting"
                  class="full-width text-left"
                >
                  <div>
                    <p class="text-h6 font-weight-bold mb-5 mt-12">
                      Processing...
                    </p>
                    <div class="d-flex flex-column align-center"></div>
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.sent"
                  :key="keys.sent"
                  class="full-width text-center pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    Congrats, your message was {{ messageSuccessLanguage }}!
                  </p>
                  <div class="d-flex justify-center mt-6">
                    <Robin
                      :height="120"
                      :width="140"
                      class=""
                      :loop="false"
                      animation="backflip"
                    />
                  </div>
                  <div class="d-flex justify-center mt-12 mb-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text"
                      @click="reset"
                      width="95"
                      ><span>Close</span></v-btn
                    >
                  </div>
                </div>
                <div
                  v-else-if="slideKey == keys.error"
                  :key="keys.error"
                  class="full-width text-left pt-12"
                >
                  <p class="text-h6 word-break font-weight-bold mb-3">
                    We had some trouble saving your message. <br />Please try
                    again later.
                  </p>
                  <p class="full-width">
                    If this problem continues, feel free to reach out to
                    customer service via one of the following methods...
                  </p>
                  <p class="pl-4 full-width">
                    Email -
                    <b
                      ><a href="mailto:help@wewhistle.com"
                        >help@wewhistle.com</a
                      ></b
                    >
                  </p>
                  <p class="pl-4 full-width">
                    Phone (Toll Free) - <b>(855) 264-3329</b>
                  </p>

                  <div class="d-flex justify-center my-5">
                    <v-btn
                      depressed
                      rounded
                      color="brandCyan"
                      class="white--text mt-4"
                      @click="(slideKey = keys.review), (progressBarValue = 0)"
                      ><v-icon class="mr-1">mdi-chevron-left</v-icon
                      ><span style="margin-top: 1px">Go back</span></v-btn
                    >
                  </div>
                </div>
              </transition>
            </div>
          </v-col>
        </v-row>
        <v-overlay
          v-if="
            dialog.showGroupTypeSelector ||
              dialog.createUser ||
              messagePreview.display ||
              loading.init ||
              error.init
          "
        />
        <!-- User creator widget -->
        <v-navigation-drawer
          v-model="dialog.createUser"
          temporary
          fixed
          right
          width="500"
        >
          <UserCreatorWidgetSimple
            v-if="dialog.createUser"
            @close="dialog.createUser = false"
            @get-users="getUsers"
            :clientId="clientId"
            source="COMMUNICATION_WIZARD"
          ></UserCreatorWidgetSimple>
        </v-navigation-drawer>
        <!-- Group creator widget -->
        <v-navigation-drawer
          v-model="dialog.showGroupTypeSelector"
          temporary
          fixed
          right
          width="500"
        >
          <GroupTypeSelector
            v-if="dialog.showGroupTypeSelector"
            source="COMMUNICATION_WIZARD"
            :version="3"
            :clientId="clientId"
            @close="closeGroupCreator"
            @get-groups="getGroups($event, true)"
          />
        </v-navigation-drawer>

        <MessagePreviewDrawer
          v-if="messagePreview.display"
          :method="messagePreview.method"
          :messageSubject="values.messageSubject || 'Sample title'"
          :messageShortBody="values.messageShortBody"
          :messageBody="values.messageBody"
          :context="values.context"
          :subcontext="values.subcontext"
          :contextResourceId="values.contextResourceId"
          :imageURL="values.imageURL"
          :clientId="clientId"
          @close="messagePreview.display = false"
        />
      </v-card>
    </v-dialog>

    <!-- Error dialog -->
    <v-dialog v-model="dialogError" persistent width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-4">
        <div class="d-flex justify-center align-center mx-0 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title
            class="word-break text-left exit-warning-text mx-0 px-0"
          >
            {{ errorHeader || "There was an error saving your message" }}
          </v-card-title>
        </div>

        <p v-if="errorText">{{ errorText }}</p>

        <v-card-actions class="mx-12">
          <v-btn
            class="mx-auto white--text"
            depressed
            rounded
            color="brandCyan"
            @click="
              (slideKey = keys.review),
                (dialogError = false),
                (progressBarValue = 0)
            "
            width="130"
            >Go Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <QRViewer
      v-if="dialog.qr"
      :groupId="qr.groupId"
      :clientId="qr.clientId"
      @close="dialog.qr = false"
    />
    <!-- Dialog used while waiting on API response -->
    <v-dialog
      v-if="loading.init || error.init"
      :value="loading.init || error.init"
      persistent
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center align-center flex-column py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center">
          <v-card-title class="word-break text-center">
            {{
              error.init ? "Error loading message" : "Loading your message..."
            }}
          </v-card-title>
        </div>
        <div v-if="error.init" class="text-left mx-6 my-6">
          There was an issue loading your message. Please try again or contact
          us if you need further assistance.
        </div>
        <div v-else>
          <v-progress-circular
            :size="60"
            :width="5"
            color="brandCyan"
            indeterminate
            class="my-5"
          ></v-progress-circular>
          <p class="py-2 mx-10 text-left">
            Please wait while we load your message.
          </p>
        </div>
        <!-- Buttons -->
        <div v-if="error.init">
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              outlined
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              @click="
                () => {
                  $router.push({
                    name: 'communications'
                  });
                }
              "
              >Exit</v-btn
            >
            <v-btn
              color="brandCyan"
              :width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 170
              "
              depressed
              @click="loadWizard"
              class="white--text"
              >Retry</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- Exit warning -->
    <v-dialog v-model="dialog.exit" width="500">
      <PopupDialog
        v-if="dialog.exit"
        icon="mdi-alert"
        iconColor="error"
        :centerText="true"
        title="Do you want to save your changes before you go?"
        button1Text="Don't save"
        button1Width="140"
        button2Text="Save"
        button2Width="140"
        @button1="reset"
        @button2="createMessage(true)"
      />
    </v-dialog>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import GroupService from "@/services/GroupService";
import ProgramService from "@/services/ProgramService";
import CommunicationService from "@/services/CommunicationService";

import PillNav from "@/components/PillNav";
import Robin from "@/components/Robin";
import UserCreatorWidgetSimple from "@/components/UserCreatorWidgetSimpleV2.vue";
import QRViewer from "@/components/QR.vue";

import GroupTypeSelector from "@/components/groups/GroupTypeSelector.vue";
import GroupTable from "@/components/data-table/Groups.vue";
import UserTable from "@/components/data-table/Users.vue";
import ProgramTable from "@/components/data-table/Programs.vue";
import LottiePlayer from "@/components/LottiePlayer.vue";
import TextEditor from "@/components/TextEditorV2.vue";
import PopupDialog from "@/components/PopupDialog.vue";
// import TextEditorV1 from "@/components/TextEditor.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import MessagePreviewDrawer from "@/components/communications-admin/MessagePreviewDrawer.vue";

import moment from "moment";
import { mapState } from "vuex";
import { debounce } from "@/shared_data/functions";
import { formRules } from "@/shared_data/data";

function initialState() {
  return {
    slideKey: 1,
    dialogError: false,
    errorText: null,
    errorHeader: null,
    slideDirection: "topic-left",
    keys: {
      recipient: 1,
      program: 2,
      distribution: 3,
      message: 4,
      review: 5,
      submitting: 6,
      sent: 102,
      error: 103
    },
    loading: {
      init: false,
      submitting: false,
      users: false,
      groups: false,
      program: false,
      distinctUsers: false,
      recent: false,
      emailPreview: false,
      textPreview: false
    },
    error: { emailPreview: false, textPreview: false, init: false },
    lottie: {
      particle: false,
      particleEmail: false,
      particleText: false
    },
    form: {
      validMessage: false,
      emailPreview: false,
      textPreview: false
    },
    data: {
      search: null,
      debounceSearch: null,
      activeAudienceTab: 1,
      users: [],
      groups: [],
      messageContexts: [
        { label: "General / Spot Message", value: "general" },
        { label: "News", value: "news" },
        { label: "Alert", value: "alerts" }
      ],
      userCount: null
    },
    dialog: {
      createGroup: false,
      createUser: false,
      showGroupTypeSelector: false,
      qr: false,
      exit: false
    },
    values: {
      program: null,
      selected: [],
      distinctUsers: 0,
      preset: null,
      classification: null,
      name: null,
      visibility: null, // "PUBLIC",
      sendDate: null, //moment().format("YYYY-MM-DDTHH:mm"),
      sendDateNow: true,
      endDate: null,
      senderName: null,
      suggestUse: false,
      frequency: null,
      sendEmail: true,
      sendPush: true,
      sendSMS: true,
      displayOnPlatform: true,
      context: "general",
      messageSubject: null,
      messageShortBody: null,
      messageBody: null,
      imageURL: null,
      imageFile: null,
      previewEmail: null,
      previewText: null,
      preventEdit: false,
      programAudience: {
        everyone: false,
        complete: false,
        notStarted: false,
        started: false
      }
    },
    formRules,
    progressBarValue: 0,
    qr: {
      groupId: null,
      clientId: null
    },
    messagePreview: {
      display: false,
      method: null
    }
  };
}

export default {
  name: "CommunicationWizard",
  title: "Communication Wizard | Whistle",
  components: {
    Robin,
    UserCreatorWidgetSimple,
    GroupTypeSelector,
    GroupTable,
    UserTable,
    ProgramTable,
    LottiePlayer,
    PillNav,
    QRViewer,
    MessagePreviewDrawer,
    TextEditor,
    // TextEditorV1,
    ImageSelector,
    PopupDialog
  },
  props: {
    previousRoute: {
      type: String,
      default: "activity"
    },
    previousRouteProps: {
      type: Object,
      default: undefined
    },
    userId: {
      type: Array,
      default: undefined
    },
    groupId: {
      type: Array,
      default: undefined
    },
    programId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return initialState();
  },
  created() {
    this.slideKey = this.route[0];
    this.getUserCount();
    this.values.sendDate = moment().format("YYYY-MM-DDTHH:mm");
    this.values.previewEmail = this.userProfile?.businessEmail;
    this.values.previewText = this.userProfile?.businessPhone;

    if (this.$route.query.messageId) this.loadWizard();
    if (this.$route.query.group == 1) this.data.activeAudienceTab = 2;
    if (this.$route.query.context)
      this.values.context = this.$route.query.context;
    if (this.$route.query.userId || this.userId)
      this.preloadUsers(this.$route.query?.userId?.split(",") || this.userId);
    if (this.$route.query.groupId || this.groupId)
      this.preloadGroups(
        this.$route.query?.groupId?.split(",") || this.groupId
      );
    if (this.$route.query.programId || this.programId) {
      this.preloadProgram(this.$route.query?.programId || this.programId);
      if (this.$route.query.programFilter)
        this.values.programAudience[this.$route.query.programFilter] = true;
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  beforeDestroy() {},
  methods: {
    async loadWizard() {
      const id = this.$route.query.messageId;
      if (!id) return;
      console.info("loadWizard");
      try {
        this.loading.init = true;
        this.error.init = false;

        let params = {
          clientId: this.clientId,
          expand: "MessageDefinitionAudience"
        };
        let response = await CommunicationService.getMessageDefinitionsV2(
          params,
          id,
          this.magicLinkToken
        );
        const message = response?.result;
        console.log("loadWizard", message);
        if (!message) throw "No module";

        const {
          clientId,
          messageBody,
          messageShortBody,
          messageSubject,
          context,
          subcontext,
          contextResourceId,
          sendEmail,
          sendSMS,
          sendPush,
          displayOnPlatform,
          imageURL,
          sendDate,
          MessageDefinitionAudiences,
          status,
          messageMetadata
        } = message;
        this.values = {
          ...this.values,
          messageBody,
          messageShortBody,
          messageSubject,
          context,
          subcontext,
          sendEmail,
          sendSMS,
          sendPush,
          displayOnPlatform,
          imageURL,
          sendDate: moment(
            this.duplicatingMessage &&
              sendDate &&
              moment(sendDate).isBefore(moment())
              ? undefined
              : sendDate || undefined
          ).format("YYYY-MM-DDTHH:mm"),
          // If they somehow load a message in that isn't a draft, we want to make sure we don't hit the edit endpoint
          preventEdit:
            (status !== "Draft" &&
              status !== "Template" &&
              status !== "Scheduled") ||
            (clientId === 0 && this.userProfile?.clientId !== 1),
          originalStatus: status
        };

        if (context == "program" && messageMetadata) {
          try {
            const parsedMetadata = JSON.parse(messageMetadata);
            if (parsedMetadata?.programAudience)
              this.values.programAudience = parsedMetadata?.programAudience;
          } catch (e) {
            console.log("Error parsing msg/pgm metadata", { id, e });
          }
        }

        // Get users
        const users = MessageDefinitionAudiences?.filter(x => !!x.userId);
        const groups = MessageDefinitionAudiences?.filter(
          x => x.groupId !== null && x.groupId !== 0
        );
        const clients = MessageDefinitionAudiences?.filter(
          x => x.groupId === 0
        );

        if (context === "program" && contextResourceId) {
          await this.preloadProgram(contextResourceId);
        } else {
          if (clients.find(c => c.clientId == this.clientId))
            this.addUserToSelected(
              {
                groupId: 0,
                displayName: "Everyone",
                groupDisplayName: "Everyone"
              },
              true
            );
          if (users.length) this.preloadUsers(users);
          if (groups.length) this.preloadGroups(groups);
        }
      } catch (e) {
        console.error("Failed to load message for wizard");
        this.error.init = true;
      } finally {
        this.loading.init = false;
      }
    },
    closeGroupCreator() {
      this.dialog.createGroup = false;
      this.dialog.showGroupTypeSelector = false;
    },
    displayGroupTypeSelector() {
      this.dialog.createGroup = true;
      this.dialog.showGroupTypeSelector = true;
    },
    resetAllData() {
      // Used to reset the state when the user clicks out of the payments console.
      Object.assign(this.$data, initialState());
    },
    reset() {
      if (this.$route.query.previousRoute)
        this.$router.push({
          name: this.$route.query.previousRoute,
          params: this.previousRouteProps || {},
          query: { tab: this.templateMode ? 5 : this.values.context }
        });
      else if (this.previousRoute)
        this.$router.push({
          name: this.previousRoute,
          params: this.previousRouteProps || {},
          query: { tab: this.templateMode ? 5 : this.values.context }
        });
      else {
        this.resetAllData();
        this.$router.push("/communications");

        // this.$emit("reset");
      }
    },
    warnBeforeExiting() {
      if (
        !(
          this.values.messageSubject ||
          this.values.messageShortBody ||
          this.values.messageBody ||
          this.values.imageURL ||
          this.values.selected?.length
        ) ||
        this.slideKey >= this.keys.submitting
      )
        return this.reset();
      this.dialog.exit = true;
    },
    scrollToTop() {
      document.getElementsByClassName(
        "v-dialog v-dialog--active"
      )[0].scrollTop = 0;
    },

    goToSlide(index) {
      // Figures out what step we're going into so we can insert the proper platform activity
      const oldIndex = this.slideKey;
      // We have to block if the user has already sent payment
      if (this.slideKey >= 1 && this.slideKey < this.keys.submitting) {
        if (index < this.slideKey) {
          this.slideDirection = "topic-right";
          this.scrollToTop();
          return (this.slideKey = index);
        } else {
          this.slideDirection = "topic-left";
        }

        // We route depending on true's and false's in the computed array
        var foundFalse = false;
        // Index is relative to all. We have to equate it to the index in our current route;
        var routeIndex = this.route.findIndex(x => x == index);
        console.log({ index, routeIndex, validation: this.routeValidation });

        // When using goToSlide to jump around, we have to make sure the user has completed all steps prior to the step that they're trying to route to.
        for (var i = 0; i < routeIndex; i++) {
          if (!this.routeValidation[i]) {
            foundFalse = true;
          }
        }

        // If we only found true values in the array, then we didn't find a false and therefore can continue
        if (!foundFalse) {
          this.scrollToTop();
          this.slideKey = index;

          if (oldIndex == this.keys.recipient)
            this.getDistinctUsersFromGroups();
        }
      }
    },
    goToNextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      const oldIndex = this.slideKey;

      if (this.slideKey === this.keys.review) {
        //We're on the final slide so show confirmation
        //Or we are editing and the message has already sent, so they can only update the message body
        this.playParticleEffect();
        this.createMessage(false);
      } else if (this.slideKey == this.keys.sent) {
        this.reset();
      } else {
        // The route has been established. Now we have to check if they can proceed
        var routeIndex = this.route.findIndex(x => x == this.slideKey);

        // if (
        //   this.route[routeIndex + 1] == this.keys.mccCustom &&
        //   this.slideProvisional.selectedPresets.length > 0
        // ) {
        //   // We can skip custom MCC page
        //   routeIndex++;
        // }
        this.playParticleEffect();
        console.log("ROUTE", {
          key: this.slideKey,
          route: this.route,
          routeIndex
        });
        this.slideKey = this.route[routeIndex + 1];

        if (oldIndex == this.keys.recipient) this.getDistinctUsersFromGroups();
      }
    },
    goToPreviousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      // The route has been established. Now we have to check if they can proceed
      var routeIndex = this.route.findIndex(x => x == this.slideKey);
      var counter = 1;

      this.slideKey = this.route[routeIndex - counter];
    },
    loadBarSleeper(incr, dur, override = false) {
      setTimeout(() => {
        console.log("Incrementing progress", this.progressBarValue);
        if (!this.loading.submitting || override) {
          // We just want to skip to the end
          this.progressBarValue = 100;
        } else {
          this.progressBarValue += incr;
          // We loop IF no error has occurred
          if (
            this.progressBarValue < 90 &&
            this.slideKey != this.keys.error &&
            !this.dialogError
          )
            this.loadBarSleeper(incr, dur);
        }
      }, dur);
    },
    refreshClientId(clientId) {
      this.clientId = clientId;

      this.slideKey = this.keys.recipient;
      this.values.selected = [];
      this.getUserCount();
    },
    changeAudienceTab(newIndex) {
      if (newIndex == this.data.activeAudienceTab) return;
      // const oldTab = this.data.activeAudienceTab;
      // const oldSearchValue = this.data.search;
      this.$set(this.data, "activeAudienceTab", newIndex);
      // this.data.activeAudienceTab = newIndex;

      this.data.debounceSearch = null;
      this.data.search = null;

      // If we're leaving users and there was a search value, we refetch users
      // if (oldTab == 1 && oldSearchValue) this.getUsersV2New(true);
    },
    async preloadUsers(user) {
      try {
        let arr = (Array.isArray(user) ? user : [user]).map(
          x => x.userId || x.id || x
        );
        const options = {
          limit: arr.length,
          offset: 0,
          clientId: this.clientId,
          screen: "communicationwizard"
        };

        let response = await UserService.searchUsersV2(
          { userId: arr },
          options,
          this.magicLinkToken
        );
        console.log("GOT RESPONSE AFTER FETCHING NEW USER ", response);
        response.result.rows.forEach(user => {
          this.addUserToSelected(user);
        });
      } catch (error) {
        console.log("Error preloadUsers", error);
      }
    },
    async preloadGroups(ids) {
      if (!ids?.length) return;
      try {
        const groupFilter = `status != 'Deleted' && clientId = ${
          this.clientId
        } && groupId.isIn(${JSON.stringify(ids.map(x => x.groupId || x))})`;
        this.loading.groups = true;
        let preloadedGroups = await GroupService.getGroupsV2({
          filter: groupFilter,
          limit: ids.length,
          offset: 0,
          // extract: "groupDisplayName,clientId,groupId,groupType",
          expand: "null"
        });
        console.log("Got groups to preload: ", preloadedGroups);
        preloadedGroups.result.rows.forEach(group => {
          this.addUserToSelected(group, true);
        });
      } catch (e) {
        console.log("Error preloading groups", e);
      } finally {
        this.loading.groups = false;
      }
    },
    async preloadProgram(id) {
      if (!id) return;
      try {
        const filter = `status != 'Deleted' && clientId = ${this.clientId} && programId = ${id}`;
        this.loading.program = true;
        let response = await ProgramService.getProgramsV2({
          filter,
          limit: 1,
          offset: 0,
          screen: "communicationwizard",
          expand: "ProgramCache"
        });
        console.log("Got program to preload: ", response);
        response.result.rows.forEach(x => {
          this.selectProgram(x);
        });
      } catch (e) {
        console.log("Error preloading ", e);
      } finally {
        this.loading.program = false;
      }
    },
    getGroups(groupId, wipeSearch = false) {
      // Uses the group table widget
      console.log("getGroups", { groupId, wipeSearch });
      if (wipeSearch) {
        this.data.search = undefined;
        this.data.debounceSearch = undefined;
      }
      this.$nextTick(() => {
        if (this.$refs["group-table"])
          this.$refs["group-table"].getGroups(
            true,
            "getGroups - communication wizard",
            groupId
          );
      });
    },
    getUsers(userId) {
      // Uses the group table widget
      if (this.$refs["user-table"])
        this.$refs["user-table"].getUsers(
          true,
          "getUsers - communication wizard",
          userId
        );
    },
    updateSelectedItem(e) {
      if (!e.groupId) return console.log("no group id");

      this.values.selected.forEach(o => {
        if (o.groupId == e.groupId) {
          console.log("UPDATING SELECTED NUM USERS ", e.numUsers);
          o.numUsers = e.numUsers || 0;
          // this.getDistinctUsersFromGroups();
        }
      });
    },
    async getDistinctUsersFromGroups() {
      console.log("getDistinctUsersFromGroups()");
      // assign to slidePeople.selectedItem.GroupAssignments
      const groups = this.values.selected
        .filter(x => x.groupId)
        .map(x => x.groupId);
      if (!groups.length || this.values.selected.find(x => x.groupId === 0))
        return;
      const users = this.values.selected
        .filter(x => x.userId)
        .map(x => x.userId);
      this.loading.distinctUsers = true;
      try {
        const response = await GroupService.getDistinctUsersFromGroupsV2(
          groups,
          users,
          this.magicLinkToken
        );

        this.values.distinctUsers = response;
        console.log("New distinct user count", this.values.distinctUsers);
      } catch (error) {
        console.log("Error getting distinct number of users ", error);
        this.values.distinctUsers = 0;
      } finally {
        this.loading.distinctUsers = false;
      }
    },
    async addUserToSelected(user, dontRemove = false) {
      console.log("Adding user/group to selected array", user);
      // Added because we can only pass one param in from the groups table
      if (user.dontRemove) dontRemove = true;
      if (user.userId) {
        let idx = this.values.selected.findIndex(x => x.userId == user.userId);
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);
      } else {
        let idx = this.values.selected.findIndex(
          x => x.groupId == user.groupId
        );
        if (idx == -1) this.values.selected.push(user);
        else if (!dontRemove) this.removeUserFromSelected(idx);

        // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
        this.values.distinctUsers = 0;
      }
    },
    removeUserFromSelected(index) {
      // var user = this.values.selected[index];
      this.values.selected.splice(index, 1);

      // Wipe distinct user count so we have to manually calculate it again rather than using the cached API value
      this.values.distinctUsers = 0;
    },
    selectProgram(program) {
      this.values.selected = [program];
    },
    async createMessage(draft = false) {
      // Show loading bar
      const status = draft
        ? this.templateMode
          ? "Template"
          : "Draft"
        : "Active";
      // We store this so we know what verb to show the user (saved, sent, scheduled)
      this.values.newStatus = status;
      this.errorText = this.errorHeader = null;
      // Display loading screen
      this.slideKey = this.keys.submitting;
      this.dialog.exit = false;
      this.loading.submitting = true;
      const progressIncrement = 2;
      const sleepDur = 125;
      this.loadBarSleeper(progressIncrement, sleepDur, false);

      try {
        const {
          messageSubject,
          messageShortBody,
          messageBody,
          context,
          subcontext,
          sendEmail,
          sendPush,
          sendSMS,
          displayOnPlatform,
          imageURL
        } = this.values;
        var object = {
          clientId: this.clientId,
          audience: this.selectedUsers.map(x => x.userId),
          groups: this.selectedGroups.map(x => x.groupId),
          clients: this.values.selected.find(x => x.groupId === 0)
            ? [this.clientId]
            : undefined,
          status,
          messageSubject,
          messageShortBody,
          messageBody,
          context,
          subcontext: context === "program" ? "dashboard" : subcontext,
          contextResourceId:
            context === "program"
              ? this.values.selected?.[0]?.programId
              : undefined,
          sendEmail,
          sendPush,
          sendSMS,
          displayOnPlatform,
          sendDashboard: displayOnPlatform,
          imageURL,
          messageMetadata:
            this.values.context !== "program"
              ? null
              : JSON.stringify({ programAudience: this.values.programAudience })
        };

        if (status !== "Template")
          object.sendDate = moment(this.values.sendDate)
            .utc()
            .format("YYYY-MM-DD HH:mm:ssZ");

        console.log("Upserting message", object);

        // If they're not sending an email or activity message, null out the image
        // if (!this.values.sendEmail && !this.values.displayOnPlatform)
        //   object.imageURL = null;
        // else
        if (
          this.values.imageFile &&
          (this.values.sendEmail || this.values.displayOnPlatform)
        )
          object.imageURL = await CommunicationService.uploadImage(
            this.values.imageFile
          );
        // else if (
        //   !this.values.imageFile &&
        //   (this.editingMessage || this.duplicatingMessage)
        // )
        //   object.imageURL = imageURL;
        // var response;
        var response = await (this.editingMessage
          ? CommunicationService.updateMessageV2(
              this.$route.query.messageId,
              object
            )
          : CommunicationService.sendMessageV2(object));
        this.loading.submitting = false;
        this.loadBarSleeper(progressIncrement, sleepDur, true);
        console.log(response);
        if (response.error) {
          this.slideKey = this.keys.error;
        } else {
          // Display success screen
          this.slideKey = this.keys.sent;
        }
        // })
      } catch (error) {
        console.log("Error sending message! ", error);
        if (error && error.error) {
          this.slideKey = this.keys.error;
          return;
        }
        this.dialogError = true;
        this.errorText =
          "An unknown error occurred. Please try again and contact your Whistle representative if this issue persists.";
      }
    },
    playParticleEffect(key = "particle") {
      this.lottie[key] = true;
      setTimeout(() => {
        this.lottie[key] = false;
      }, 1000);
    },
    async loadQrCode(groupId) {
      const clientId = Number(
        this.clientId !== undefined && this.clientId !== null
          ? this.clientId
          : this?.userProfile?.clientId
      );
      this.qr = {
        groupId,
        clientId
      };
      this.dialog.qr = true;
    },
    async previewMessage(method, sendMessage = false) {
      if (!sendMessage) {
        this.messagePreview.method = method;
        this.messagePreview.display = true;
        return;
      }

      const startTime = new Date();
      const key = method == "email" ? "emailPreview" : "textPreview";
      console.log({
        method,
        key,
        form: this.form[key],
        loading: this.loading[key]
      });
      if (method == "email" && (!this.form[key] || this.loading[key])) return;
      else if (method == "sms" && (!this.form[key] || this.loading[key]))
        return;

      // Valid to send
      console.log("Sending preview ", method);
      this.loading[key] = true;
      this.error[key] = false;
      try {
        let imageURL;
        if (this.values.imageFile && method === "email") {
          console.log("Uploading image early for preview");
          imageURL = await CommunicationService.uploadImage(
            this.values.imageFile
          );
          this.values.imageFile = null;
          this.values.imageURL = imageURL;
        } else if (!this.values.imageFile) imageURL = this.values.imageURL;

        let body = {
          messageSubject: this.values.messageSubject,
          messageShortBody: this.values.messageShortBody,
          messageBody: this.values.messageBody,
          contextResourceId: this.values.contextResourceId,
          context: this.values.context,
          subcontext: this.values.subcontext,
          clientId: this.clientId,
          imageURL: this.values.imageURL,
          email: this.values.previewEmail,
          phone: this.values.previewText
        };
        await CommunicationService.previewMessage(method, body, true);
      } catch (e) {
        console.log("Error sending preview", e);
        this.error[key] = true;
      } finally {
        let timeDiff = moment().diff(moment(startTime));
        const duration = 3000;
        console.log("Time diff", timeDiff);
        if (timeDiff >= duration) {
          timeDiff = duration;
        }
        setTimeout(() => {
          this.loading[key] = false;
          this.playParticleEffect(
            method === "email" ? "particleEmail" : "particleText"
          );
        }, duration - timeDiff);
      }
    },
    async getUserCount() {
      // Used to just get a total number of users in the client
      const userRes = await UserService.getUsersV2({
        // filter: `status = 'Active' && clientId = ${this.clientId}`,
        limit: 1,
        extract: "userId",
        screen: "communicationwizard",
        external: 1,
        clientId: this.clientId
      });
      console.log("total number of users ", userRes);
      this.data.userCount = userRes.result.count;
    },
    validateForm(value, rules = []) {
      return !rules
        .map(rule => {
          return rule(value);
        })
        .find(r => typeof r === "string");
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "permissions",
      "roles",
      "globalClientId",
      "magicLinkToken"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    disableContinueButton: {
      cache: false,
      get: function() {
        // Compare to route validation
        // We check the index within the route that we're in and use routeValidation to see if we've satisfied the requirements
        var routeIndex = this.route.findIndex(x => x == this.slideKey);
        return !this.routeValidation[routeIndex];
      }
    },
    pillNavSteps() {
      return [
        this.templateMode
          ? undefined
          : {
              step: this.keys.recipient,
              icon: this.programMode ? "star" : "account-multiple"
            },
        this.programMode
          ? {
              step: this.keys.program,
              icon: "account-multiple"
            }
          : undefined,
        {
          step: this.keys.distribution,
          icon: "arrow-right-circle"
        },
        {
          step: this.keys.message,
          icon: "newspaper-variant-outline"
        },

        {
          step: this.keys.review,
          icon: "rocket-launch"
        }
      ].filter(Boolean);
    },
    route: {
      cache: false,
      get: function() {
        // This dynamic array of keys will be used by routeValidation, disableContinuButton, etc
        return [
          this.templateMode ? undefined : this.keys.recipient,
          this.programMode ? this.keys.program : undefined,
          this.keys.distribution,
          this.keys.message,
          this.keys.review,
          this.keys.submitting,
          this.keys.sent
        ].filter(Boolean);
      }
    },
    routeValidation: {
      cache: false,
      get: function() {
        var array = [];

        // This uses this.route and compiles an array of true/false values for their entire route to tell us if they've completed everything that they need to
        // Used by disableContinueButton and goToSlide
        this.route.forEach(page => {
          // Page 1
          // console.log(page);
          if (page == this.keys.recipient && this.values.selected.length) {
            array.push(true);
          } else if (
            page == this.keys.message &&
            // this.form.validMessage &&
            this.validateForm(
              this.values.messageSubject,
              formRules.stringRequired(100)
            ) &&
            this.validateForm(
              this.values.messageShortBody,
              formRules.stringOptional(255)
            ) &&
            this.values.messageBody?.length
          ) {
            array.push(true);
          } else if (page === this.keys.program && this.audienceCount) {
            array.push(true);
          } else if (
            page == this.keys.distribution &&
            (this.values.sendEmail ||
              this.values.sendPush ||
              this.values.sendSMS ||
              this.values.displayOnPlatform) &&
            this.values.sendDate &&
            this.sendDateIsValid &&
            this.values.context &&
            (this.data.messageContexts.find(
              x => x.value == this.values.context
            ) ||
              this.programMode)
          ) {
            array.push(true);
          } else if (
            page == this.keys.review &&
            // Added so they don't accidentally submit if they're over budget
            !this.loading.distinctUsers &&
            // Must have someone in the group
            (this.templateMode || this.audienceCount) &&
            this.sendDateIsValid
          ) {
            array.push(true);
          } else if (page == this.keys.submitting) {
            array.push(false);
          } else if (page == this.keys.sent) {
            array.push(true);
          } else {
            array.push(false);
          }
        });

        return array;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    audienceCount() {
      if (
        this.loading.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      )
        return 0;
      // They're selecting everyone so we just make sure they're not blocked
      if (this.values.selected.find(x => x.groupId === 0))
        return this.data.userCount || 1;
      if (
        this.values.distinctUsers &&
        this.values.selected.find(x => x.groupId)
      ) {
        console.log("Returning distinct users ", this.values.distinctUsers);
        return this.values.distinctUsers;
      }
      let count = 0;
      var arr = [];

      if (this.programMode) {
        // Must de-dupe the user count from the program audience
        // The logic has been moved to the selected users
        arr = this.selectedUsers;
      } else {
        this.values.selected.forEach(item => {
          if (item.groupId && item.numUsers) count += item.numUsers;
          else if (item.groupId && item.GroupAssignments)
            arr = arr.concat(item.GroupAssignments.map(x => x.userId));
          else if (item.userId) arr.push(item.userId);
        });
      }

      if (count != 0) return count;
      return [...new Set(arr)].length;
    },
    selectedGroups: {
      get: function() {
        return this.values.selected.filter(x => x.groupId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    },
    selectedUsers: {
      get: function() {
        if (this.programMode) {
          let arr = [];
          if (this.values.programAudience.everyone)
            arr = this.programAudienceEveryone;
          else {
            if (this.values.programAudience.complete)
              arr = arr.concat(this.programAudienceComplete);
            if (this.values.programAudience.notStarted)
              arr = arr.concat(this.programAudienceNotStarted);
            if (this.values.programAudience.started)
              arr = arr.concat(this.programAudienceStarted);
          }
          return [...new Set(arr)];
        }

        return this.values.selected.filter(x => x.userId);
      },
      set: function() {
        // console.log("Adding el ", o);
      }
    },
    programAudienceEveryone() {
      if (!this.programMode || !this.values.selected?.[0]) return [];

      return (
        this.values.selected?.[0]?.ProgramCaches?.filter(
          x => x?.status !== "Deleted"
        ) || []
      );
    },
    programAudienceStarted() {
      if (!this.programMode || !this.values.selected?.[0]) return [];

      return (
        this.values.selected?.[0]?.ProgramCaches?.filter(
          x =>
            x?.status !== "Deleted" &&
            x?.progressPercent > 0 &&
            x?.progressPercent < 100
        ) || []
      );
    },
    programAudienceNotStarted() {
      if (!this.programMode || !this.values.selected?.[0]) return [];

      return (
        this.values.selected?.[0]?.ProgramCaches?.filter(
          x => x?.status !== "Deleted" && x?.progressPercent == 0
        ) || []
      );
    },
    programAudienceComplete() {
      if (!this.programMode || !this.values.selected?.[0]) return [];

      return (
        this.values.selected?.[0]?.ProgramCaches?.filter(
          x => x?.status !== "Deleted" && x?.progressPercent >= 100
        ) || []
      );
    },
    canCreateUser() {
      return (this.permissions || []).find(x => x == "users:create:user");
    },
    canCreateGroup() {
      return (this.permissions || []).find(x => x == "groups:create:group");
    },
    devEnvironment() {
      return !(
        process.env.VUE_APP_ENVIRONMENT === "prod" ||
        process.env.VUE_APP_ENVIRONMENT === "production"
      );
    },
    pageTitle() {
      let pageKey = this.slideKey;
      let title;
      console.log("Page key", pageKey);
      console.log(this.keys);
      switch (pageKey) {
        case this.keys.recipient:
          title = this.programMode
            ? "What program do you want to message?"
            : "Who is the audience?";
          break;
        case this.keys.program:
          title = "Who in the program is the audience?";
          break;
        case this.keys.message:
          title = "Edit and preview";
          break;
        case this.keys.distribution:
          title = "How do you want to send this?";
          break;
        case this.keys.review:
          title = this.templateMode
            ? "Save your template"
            : "Save and " + this.messageSchedulingLanguage;
          break;
      }
      return title;
    },
    editingMessage() {
      return !!(
        this.$route.query.messageId &&
        !this.duplicatingMessage &&
        !this.values.preventEdit
      );
    },
    duplicatingMessage() {
      return !!(
        this.$route.query.messageId &&
        Boolean(Number(this.$route.query.duplicate))
      );
    },
    templateMode() {
      return Boolean(Number(this.$route.query.template));
    },
    programMode() {
      return this.values.context === "program";
    },
    messageBatchWarning() {
      const limit = Number(process.env.VUE_APP_MESSAGE_BATCH_LIMIT || 500);
      if (this.audienceCount < limit) return null;
      return "Due to the size of the recipient list, this message may take some extra time to be delivered.";
    },
    formattedSendDate() {
      if (!this.values.sendDate) return;
      const time = moment(this.values.sendDate);
      if (time.isBefore(moment())) return "now";

      return "on " + time.format("MMM DD, YYYY [at] h:mm a");
    },
    sendDateIsInFuture() {
      return !!(
        this.values.sendDate && moment().isBefore(this.values.sendDate)
      );
    },
    messageSchedulingLanguage() {
      if (this.sendDateIsInFuture) return "schedule";
      return "send";
    },
    messageSuccessLanguage() {
      if (this.values.newStatus === "Active")
        return this.sendDateIsInFuture ? "scheduled" : "sent";
      return "saved";
    },
    sendDateIsValid() {
      if (!this.values.sendDate) return true;
      return moment(this.values.sendDate).isValid();
    }
  },
  watch: {
    "data.debounceSearch": debounce(function(newVal) {
      this.data.search = newVal;
    }, 500)
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.error-background {
  background-color: var(--v-error-base);
}

.header-text {
  font-size: x-large;
}

.light-grey-background {
  background-color: var(--v-lightGrey-base);
}

.css-default-grey-background {
  background-color: grey;
}

.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.timestamp-input {
  margin-top: 0px;
  margin-bottom: 0px;
}
.rounded-border {
  border: 1px solid lightgray;
  border-radius: 5px;
}
.selected-people-content >>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
.input-error {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
  border: 2px solid var(--v-error-base) !important;
}

/* outline used for the main content card */
.main-card {
  border: solid 1px;
  border-color: lightgray;
  min-height: calc(100vh - 120px);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hides extra padding that for some reason exists in v-row */
.container-row {
  overflow-x: hidden;
}

/* Provides spacing for each audience tab */
.audience-tab {
  padding: 8px 20px 8px 20px;
}

/* Bkgnd color for selected audience tab */
.active-audience-tab {
  background-color: #e8e8e8 !important;
}

/* Provides feedback when you hover over an audience tab */
.audience-tab:hover {
  background: lightgray;
}

/* Changes the close icon's color for selected audience chips */
.selected-audience-chip >>> .v-chip__close {
  color: lightgray;
}

.review-page-field {
  width: 65% !important;
  min-width: 170px !important;
  max-width: 400px !important;
  margin-left: 12px;
}
.review-page-text {
  width: 35% !important;
}
.box-border {
  border: 1px solid lightgray;
}
.box-border-green {
  border-color: var(--v-brandDarkGreen-base);
}
.box-border-cyan {
  border-color: var(--v-brandCyan-base);
}

/* Thickens the border on audience chips */
.selected-audience-chip.v-chip.v-chip--outlined {
  border-width: 2px !important;
}
.lottie-container {
  position: relative;
}
.lottie-particle-effect {
  position: absolute;
  top: -105px;
  left: -77px;
  /* z-index: 1; */
  pointer-events: none;
}

/* Tile / card for daily/weekly/monthly */
.distribution-card {
  width: 130px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 10px;
  padding: 0px 6px 0px 6px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.distribution-card-chip {
  min-width: 160px;
  max-width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 10px 10px 10px;
  padding: 0px 6px 0px 6px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.distribution-card-chip-selected {
  background-color: var(--v-brandCyan-base);
  color: white;
}

.distribution-button:hover {
  background-color: #eeeeee;
  border-radius: 8px;
}

.distribution-card-chip-selected:hover {
  background-color: var(--v-brandDarkCyan-base);
  color: white;
}

.advanced-settings-box {
  border-radius: 3px;
  border: 1px solid lightgray;
}

.distribution-button {
  user-select: none;
  cursor: pointer;
}

.program-audience-fab {
  margin: 0px 8px 0px 8px;
  padding: 4px 0 0 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-height gradient-background",attrs:{"rounded":"0"}},[_c('div',{staticClass:"d-flex flex-column justify-space-between full-height pt-6"},[_c('v-row',{staticClass:"full-width",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5","lg":"4","xl":"4"}},[_c('div',{staticClass:"box-outline rounded-lg py-10 px-2",class:{ 'mx-3': _vm.$vuetify.breakpoint.xs }},[_c('transition',{attrs:{"name":_vm.slideDirection,"mode":"out-in"}},[(_vm.currentSlide == _vm.keys.agreements)?_c('div',{key:_vm.keys.agreements,staticClass:"text-center"},[_c('p',{staticClass:"text-h6 word-break title-text mt-7"},[_vm._v(" First and last step! ")]),_c('p',{staticClass:"pt-3"},[_vm._v(" Who doesn't love terms and conditions... ")]),_c('div',{staticClass:"d-flex flex-column align-start mb-8 mt-6 checkboxes mx-auto"},[_c('div',{staticClass:"d-flex align-center text-left my-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"brandCyan","hide-details":""},model:{value:(_vm.termsCheckbox),callback:function ($$v) {_vm.termsCheckbox=$$v},expression:"termsCheckbox"}}),_c('span',[_vm._v("I agree to the "),_c('a',{style:(_vm.filesDownloading.includes('privacy_gdpr')
                          ? 'cursor: wait !important;'
                          : ''),on:{"click":function($event){return _vm.downloadFile('privacy_gdpr')}}},[_vm._v("privacy policy")]),_vm._v(" and "),_c('a',{style:(_vm.filesDownloading.includes('terms')
                          ? 'cursor: wait !important;'
                          : ''),on:{"click":function($event){return _vm.downloadFile('terms')}}},[_vm._v("terms and conditions")])])],1),_c('div',{staticClass:"d-flex align-center text-left my-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"brandCyan","hide-details":""},model:{value:(_vm.chaCheckbox),callback:function ($$v) {_vm.chaCheckbox=$$v},expression:"chaCheckbox"}}),_c('span',[_vm._v("I agree to the "),_c('a',{style:(_vm.filesDownloading.includes('cardholder_agreement.pdf')
                          ? 'cursor: wait !important;'
                          : ''),on:{"click":function($event){return _vm.downloadWhistleCardFile('cardholder_agreement.pdf')}}},[_vm._v("Cardholder Agreement")])])],1),_c('div',{staticClass:"d-flex align-center text-left my-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"brandCyan","hide-details":""},model:{value:(_vm.issuerPolicyCheckbox),callback:function ($$v) {_vm.issuerPolicyCheckbox=$$v},expression:"issuerPolicyCheckbox"}}),_c('span',[_vm._v("I agree to the "),_c('a',{style:(_vm.filesDownloading.includes(
                          'issuing_bank_privacy_policy.pdf'
                        )
                          ? 'cursor: wait !important;'
                          : ''),on:{"click":function($event){return _vm.downloadWhistleCardFile(
                          'issuing_bank_privacy_policy.pdf'
                        )}}},[_vm._v("Bank Privacy Policy")])])],1),_c('div',{staticClass:"d-flex align-center text-left my-2"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"brandCyan","hide-details":""},model:{value:(_vm.esignCheckbox),callback:function ($$v) {_vm.esignCheckbox=$$v},expression:"esignCheckbox"}}),_c('span',[_vm._v("I agree to the "),_c('a',{style:(_vm.filesDownloading.includes('esign_agreement.pdf')
                          ? 'cursor: wait !important;'
                          : ''),on:{"click":function($event){return _vm.downloadWhistleCardFile('esign_agreement.pdf')}}},[_vm._v("eSign Agreement")])])],1)]),_c('v-form',{ref:"nameForm",attrs:{"onSubmit":"return false;"},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-text-field',{ref:"firstName",staticClass:"mt-4 name-field mx-auto",attrs:{"label":"First name","outlined":"","dense":"","rules":[
                    v => !!v || 'First name is required',
                    v =>
                      !!(v && v.length < 40) || 'First name should be shorter'
                  ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.createUserAndCard(false)}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('v-text-field',{ref:"lastName",staticClass:"mt-1 name-field mx-auto",attrs:{"label":"Last name","outlined":"","dense":"","rules":[
                    v => !!v || 'Last name is required',
                    v =>
                      !!(v && v.length < 40) || 'Last name should be shorter'
                  ]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.createUserAndCard(false)}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('v-btn',{staticClass:"pa-3 mt-8 white--text",attrs:{"color":"brandCyan","rounded":"","depressed":"","disabled":!(
                    _vm.termsCheckbox &&
                    _vm.chaCheckbox &&
                    _vm.issuerPolicyCheckbox &&
                    _vm.esignCheckbox &&
                    _vm.validForm
                  ),"width":"170"},on:{"click":function($event){return _vm.createUserAndCard(false)}}},[_c('span',[_vm._v(_vm._s("Get rewarded"))])]),_c('v-divider',{staticClass:"mx-12 mt-10"}),_c('p',{staticClass:"mt-6 mx-14"},[_vm._v(" If you need additional help, "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"mailto:help@wewhistle.com"}},[_vm._v("contact us")]),_vm._v(" or visit our "),_c('a',{style:(_vm.filesDownloading.includes('faq.pdf')
                      ? 'cursor: wait !important;'
                      : ''),on:{"click":function($event){return _vm.downloadWhistleCardFile('faq.pdf')}}},[_vm._v("Frequently Asked Questions")])])],1):(_vm.currentSlide == _vm.keys.loading)?_c('div',{key:_vm.keys.loading,staticClass:"text-center d-flex flex-column justify-space-between align-center loading-box"},[_c('v-progress-linear',{staticClass:"progress-bar rounded-pill mt-7",attrs:{"value":_vm.loadingProgress,"color":"brandDarkGreen","height":"32"}},[_c('strong',{staticClass:"white--text text-left"},[_vm._v(_vm._s(_vm.loadingText))])]),_c('div',[_c('v-btn',{staticClass:"pa-3 mt-8 white--text",attrs:{"color":"brandGreen","rounded":"","depressed":"","width":"170"},on:{"click":function($event){if (_vm.receivedAPIResponse) {
                      _vm.loadWhistleApp();
                    }}}},[_c('span',[_vm._v(_vm._s(!_vm.receivedAPIResponse ? "Processing" : "Done!"))])]),_c('v-divider',{staticClass:"mx-12 mt-10"}),_c('p',{staticClass:"mt-6 mx-14"},[_vm._v(" If you need additional help, "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"mailto:help@wewhistle.com"}},[_vm._v("contact us")]),_vm._v(" or visit our "),_c('a',{style:(_vm.filesDownloading.includes('faq.pdf')
                        ? 'cursor: wait !important;'
                        : ''),on:{"click":function($event){return _vm.downloadWhistleCardFile('faq.pdf')}}},[_vm._v("Frequently Asked Questions")])])],1)],1):(_vm.currentSlide == _vm.keys.error)?_c('div',{key:_vm.keys.error,staticClass:"text-center d-flex flex-column justify-space-between align-center loading-box"},[_c('div',[_c('p',{staticClass:"text-h6 word-break title-text mt-7"},[_vm._v(" Uh oh, we ran into some trouble! ")]),(_vm.allowUserToContinueAfterError)?_c('p',{staticClass:"pt-3"},[_vm._v(" Despite the problem, you can still continue to Whistle! ")]):_c('p',{staticClass:"pt-3"},[_vm._v(" Feel free to "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"mailto:help@wewhistle.com"}},[_vm._v("contact us")]),_vm._v(" if the problem continues. ")])]),_c('div',[_c('v-btn',{staticClass:"pa-3 mt-8 white--text",attrs:{"color":"brandGreen","rounded":"","depressed":"","width":"170"},on:{"click":function($event){if (_vm.allowUserToContinueAfterError) {
                      _vm.loadWhistleApp();
                    } else {
                      _vm.createUserAndCard(true);
                    }}}},[_c('span',[_vm._v(_vm._s(_vm.allowUserToContinueAfterError ? "Go to Whistle" : "Try Again"))])]),_c('v-divider',{staticClass:"mx-12 mt-10"}),_c('p',{staticClass:"mt-6 mx-14"},[_vm._v(" If you need additional help, "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"mailto:help@wewhistle.com"}},[_vm._v("contact us")]),_vm._v(" or visit our "),_c('a',{style:(_vm.filesDownloading.includes('faq.pdf')
                        ? 'cursor: wait !important;'
                        : ''),on:{"click":function($event){return _vm.downloadWhistleCardFile('faq.pdf')}}},[_vm._v("Frequently Asked Questions")])])],1)]):_vm._e()])],1)])],1),_c('v-row',{staticClass:"full-width mt-3",attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-col',[(_vm.$auth && _vm.$auth.isAuthenticated)?_c('p',{staticClass:"white--text font-weight-bold cursor-pointer",on:{"click":_vm.loginOrLogout}},[_vm._v(" Log out ")]):_vm._e(),_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v("Whistle Systems, Inc.")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
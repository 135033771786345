<template>
  <v-card rounded="0" flat class="word-break py-5">
    <h2>Your card has been activated!</h2>
    <span class="grey--text mt-2">
      Your new card is ready for use.
    </span>
    <Robin
      :showText="false"
      :width="140"
      :height="140"
      animation="train"
      :loop="true"
      class="d-flex justify-center"
    />
  </v-card>
</template>

<script>
import Robin from "@/components/Robin.vue";

export default {
  name: "CardActivationSuccess",

  props: {},
  components: {
    Robin
  },
  data() {
    return {};
  },
  created() {
    window.divNode = window.document.createElement("div");
    window.divNode.setAttribute("id", "tempFreshworks");
    window.divNode.innerHTML =
      "<style>#launcher-frame { right: -105px !important; }</style>";
    window.document.body.appendChild(window.divNode);
  },
  destroyed() {
    window.divNode.parentNode.removeChild(window.divNode);
  },
  updated() {},

  methods: {},
  computed: {}
};
</script>

<style scoped></style>

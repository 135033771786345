<template>
  <div flat rounded="0" class="full-height hide-overflow" height="100%">
    <v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title v-if="!alreadyRegistered"
        >Payment Account Setup</v-toolbar-title
      >
      <v-toolbar-title v-else>Request a Card</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetForm(false)">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div
      class="d-flex flex-column justify-space-between full-height view-height"
    >
      <v-row justify="center" class="full-width" no-gutters
        ><v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          class="align-text-left darkGrey--text"
        >
          <div
            class="d-flex justify-center align-center mb-5 mt-10 hidden"
            v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
          >
            <v-btn small fab elevation="0" color="mediumGrey"
              ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
            >
            <ProgressBar :progress="progress" class="progress-bar mx-12" />
            <v-btn small fab elevation="0" color="mediumGrey"
              ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <div class="d-flex justify-center">
            <v-img
              v-if="cobrandedCardImage"
              :src="cobrandedCardImage"
              :width="$vuetify.breakpoint.xs ? '95%' : '100%'"
              max-width="500"
              class="my-4 mx-2 rounded-xl border"
            />
            <v-img
              v-else
              src="@/assets/Wallet/whistle-card-default.png"
              :width="$vuetify.breakpoint.xs ? '95%' : '100%'"
              max-width="500"
              class="my-4 mx-2"
            />
          </div>
          <!-- <v-card
            class="ma-4 py-4 px-6 align-text-left mx-auto rounded-xl border"
            elevation="0"
            max-width="500"
          >
            <div class="d-flex justify-space-between align-center mb-4 mt-2">
              <v-img
                src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                :max-width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 160
                "
              />
              <v-img
                src="@/assets/Wallet/nfc.png"
                :max-width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 35 : 50
                "
                contain
              />
            </div>
            <v-img
              src="@/assets/Wallet/chip.png"
              :max-width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 60
              "
              contain
              class="ml-3"
            />
            <p class="brand--text mt-3 mb-4 ml-2 card-text">
              <b>4000 1234 5678 9010</b>
            </p>
            <div class="d-flex align-center ml-10 pl-10">
              <div class="d-flex flex-column">
                <strong class="brand--text card-text-small">VALID</strong>
                <strong class="brand--text card-text-small">THRU</strong>
              </div>
              <span class="brand--text ml-2 mr-auto card-text">
                <b>00/00</b>
              </span>

              <div class="brand--text card-text align-end">
                <b>DEBIT</b>
              </div>
            </div>
            <div class="d-flex justify-space-between mb-2">
              <div>
                <strong class="card-text brand--text ml-2">
                  {{ "YOUR NAME HERE" }}
                </strong>
              </div>
              <v-img
                src="@/assets/Wallet/visa.png"
                :max-width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 70 : 85
                "
                contain
              />
            </div>
          </v-card> -->
          <div class="mx-6">
            <span class="issuer-agreement word-break"
              >This card is issued by Sutton Bank, Member FDIC, pursuant to a
              license from Visa U.S.A. Inc. The Whistle card is powered by
              Marqeta.</span
            >
          </div>
          <div v-if="currentSlide == 0">
            <v-card-title class="title-text word-break ml-0 full-width">
              What is the Whistle Card?</v-card-title
            >
            <p class="mx-4 mt-2">
              The Whistle Card is a debit card that is your personal card.
              Setting up a card does not affect your credit. The balance on your
              card is divided up between personal funds and funds temporarily
              provided by your company.
            </p>
          </div>

          <div v-else-if="currentSlide == 1">
            <v-card-title class="title-text word-break ml-0 full-width">
              Why are we asking for this?</v-card-title
            >
            <p class="mx-4 mt-2">
              We're required to collect your address when setting up your card.
              This card will remain yours, so we recommend using a personal
              address for your billing information. You can always update this
              later from the Wallet page.
            </p>
          </div>

          <div v-if="currentSlide == 0">
            <v-card-title
              class="title-text word-break ml-0 full-width"
              v-if="currentSlide == 0"
            >
              What do we do with this data?</v-card-title
            >
            <p class="mx-4 mt-2">
              Basic information is required by the banking industry to reduce
              the risk of money laundering or other types of money transfers
              that support illegal business activities.
            </p>
            <p class="mt-4 mx-4">
              This information is not stored by Whistle and is only provided to
              our financial processing and banking partners for the purposes of
              supporting your Whistle payment account.
            </p>
          </div>

          <!-- <Robin
            v-if="computedRobinMessage"
            :showText="true"
            :message="computedRobinMessage"
            :width="60"
            :height="60"
            borderColor="accent"
            color="brandPaleBlue"
            animation="blinking"
            :loop="true"
            class="my-6"
          /> -->
        </v-col>
        <v-col cols="12" sm="12" md="5" lg="5" xl="5">
          <div class="d-flex justify-center align-center mb-5 mt-10">
            <v-btn
              small
              fab
              elevation="0"
              color="mediumGrey"
              @click="previousSlide"
              :class="{
                hidden:
                  currentSlide == 0 ||
                  currentSlide == 3 ||
                  currentSlide == 4 ||
                  (currentSlide == 5 &&
                    !displayPhysicalCardOption &&
                    displayVirtualCardOption) ||
                  currentSlide == 7 ||
                  currentSlide == 8
              }"
              ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
            >
            <ProgressBar
              :progress="progress"
              class="progress-bar mx-12 progress-bar-scroll"
              id="progress-bar"
            />
            <v-btn
              small
              fab
              elevation="0"
              color="mediumGrey"
              @click="nextSlide"
              :disabled="disableNextButton"
              :class="{ hidden: currentSlide == 6 }"
              ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <transition :name="slideDirection" mode="out-in">
            <v-row
              v-if="currentSlide == 0"
              key="0"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >What's your legal name and date of birth?</v-card-title
              >
              <v-form
                ref="form"
                v-model="validNameForm"
                class="full-width px-4"
              >
                <v-text-field
                  ref="firstName"
                  label="First name"
                  v-model="firstName"
                  class="mt-4"
                  :rules="[
                    v => !!v || 'First name is required',
                    v =>
                      !!(v && v.length < 40) || 'First name should be shorter'
                  ]"
                />
                <v-text-field
                  ref="lastName"
                  label="Last name"
                  v-model="lastName"
                  class="mt-4"
                  :rules="[
                    v => !!v || 'Last name is required',
                    v => !!(v && v.length < 40) || 'Last name should be shorter'
                  ]"
                />
                <v-text-field
                  ref="email"
                  label="Email"
                  v-model="email"
                  class="mt-4"
                  :rules="[
                    v => !!v || 'Email is required',
                    v => !!(v && v.length < 255) || 'Email should be shorter',
                    v =>
                      /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(
                        v
                      ) || 'Email must be valid'
                  ]"
                />
                <v-menu
                  ref="birthdayMenu"
                  v-model="birthdayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  top
                  attach
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthday"
                      label="Date of birth"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Date of birth is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="birthdayPicker"
                    v-model="birthday"
                    :max="new Date().toISOString().substr(0, 10)"
                    @change="saveBirthday"
                  ></v-date-picker>
                </v-menu>
                <vue-tel-input-vuetify
                  dense
                  class="mt-4"
                  v-model="phone"
                  :value="phone"
                  label="Phone number (xxx-xxx-xxxx)"
                  placeholder="xxx-xxx-xxxx"
                  :preferredCountries="['US']"
                  :defaultCountry="countryCode.code"
                  :disabledFetchingCountry="true"
                  v-on:country-changed="countryCodeChanged"
                  @validate="phoneValidation"
                ></vue-tel-input-vuetify>
              </v-form>
            </v-row>
            <v-row
              v-else-if="currentSlide == 1"
              key="1"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Let's get your address</v-card-title
              >
              <v-form
                ref="form"
                v-model="validAddressForm"
                class="full-width px-4"
              >
                <v-text-field
                  ref="address1"
                  label="Address"
                  v-model="address1"
                  class="mt-4"
                  required
                  :rules="[
                    v => !!v || 'Address is required',
                    v => !!(v && v.length < 256) || 'Address should be shorter'
                  ]"
                />
                <v-text-field
                  label="Apt, suite, unit, etc."
                  v-model="address2"
                  class="mt-4"
                />
                <v-text-field
                  label="City"
                  v-model="city"
                  class="mt-4"
                  required
                  :rules="[
                    v => !!v || 'City is required',
                    v => !!(v && v.length < 40) || 'City should be shorter'
                  ]"
                />
                <v-select
                  label="State"
                  class="mt-4"
                  :items="states"
                  v-model="state"
                  attach
                  :menu-props="{ top: true, offsetY: true }"
                  item-value="abbreviation"
                  item-text="name"
                  :rules="[v => !!v || 'State is required']"
                />
                <v-text-field
                  label="Zip Code"
                  v-model="postalCode"
                  class="mt-4"
                  required
                  :rules="[
                    v => !!v || 'Zip code is required',
                    v => !!(v && v.length < 10) || 'Zip code should be shorter'
                  ]"
                />
                <!-- <v-select
                  label="Country"
                  class="mt-4"
                  :items="countries"
                  v-model="country"
                  item-value="code"
                  item-text="name"
                  :rules="[(v) => !!v || 'Country is required']"
                />
                <v-text-field
                  v-if="country == '00'"
                  label="Other country (2 digit code)"
                  v-model="otherCountry"
                  class="mt-4"
                  required
                  :rules="[
                    (v) => !!v || 'Country is required',
                    (v) =>
                      !!(v && v.length == 2) ||
                      'Country code should be 2 characters',
                    (v) =>
                      !!(v && countryCodes.includes(v.toUpperCase())) ||
                      'Country code must be a valid country',
                  ]"
                /> -->
              </v-form>
            </v-row>
            <v-row
              v-else-if="currentSlide == 2"
              key="2"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Let's confirm all of the details. Does this look
                right?</v-card-title
              >
              <div class="align-text-left full-width px-4">
                <p class="mt-3">
                  First name: <b>{{ firstName }}</b>
                </p>
                <p class="mt-3">
                  Last name: <b>{{ lastName }}</b>
                </p>
                <p class="mt-3">
                  Email: <b>{{ email }}</b>
                </p>
                <p class="mt-3">
                  Birthday: <b>{{ birthday }}</b>
                </p>
                <p class="my-3">
                  Phone #: <b>{{ phone }}</b>
                </p>
                <v-btn color="primary" class="mb-3" @click="currentSlide = 0"
                  ><v-icon class="mr-2">mdi-pencil</v-icon>Edit</v-btn
                >
                <!-- <v-divider />

                <p class="mt-3">
                  Address 1: <b>{{ address1 }}</b>
                </p>
                <p class="mt-3">
                  Address 2: <b>{{ address2 }}</b>
                </p>
                <p class="mt-3">
                  City: <b>{{ city }}</b>
                </p>
                <p class="mt-3">
                  State: <b>{{ state }}</b>
                </p>
                <p class="mt-3">
                  Zip Code: <b>{{ postalCode }}</b>
                </p>
                <p class="mt-3">
                  Country:
                  <b>US</b>
   
                </p>
                <v-btn color="primary" class="mb-3" @click="currentSlide = 1"
                  ><v-icon class="mr-2">mdi-pencil</v-icon>Edit</v-btn
                > -->
              </div>
            </v-row>
            <v-row
              v-else-if="currentSlide == 3"
              key="3"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Creating your account...</v-card-title
              >

              <Robin
                :showText="false"
                :width="140"
                :height="140"
                animation="inflatableDance"
                :loop="true"
              />
            </v-row>
            <v-row
              v-if="currentSlide == 4"
              key="4"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title
                class="title-text word-break full-width"
                v-if="!displayVirtualCardOption && !displayPhysicalCardOption"
                >Looks like you've already set up your card</v-card-title
              >
              <v-card-title
                class="title-text word-break full-width"
                v-if="displayVirtualCardOption && displayPhysicalCardOption"
                >What type of card are you looking for?</v-card-title
              >
              <v-card-title
                class="title-text word-break full-width"
                v-else-if="
                  !displayVirtualCardOption && displayPhysicalCardOption
                "
                >Where do you want your physical card shipped?</v-card-title
              >
              <span
                class="grey--text mt-2 pl-4 full-width"
                v-if="displayVirtualCardOption && displayPhysicalCardOption"
              >
                We offer a virtual card for $0
              </span>
              <span
                class="grey--text pl-4 full-width"
                v-if="displayVirtualCardOption && displayPhysicalCardOption"
              >
                Or a physical card for a one-time fee of ${{
                  userProfile.Client.physicalCardFee
                }}
              </span>
              <span
                class="grey--text pl-4 full-width"
                v-else-if="
                  !displayVirtualCardOption && displayPhysicalCardOption
                "
              >
                A physical card has a one-time fee of ${{
                  userProfile.Client.physicalCardFee
                }}
              </span>
              <v-form
                ref="form"
                v-model="validNameForm"
                class="full-width px-4 align-text-left"
              >
                <v-radio-group
                  v-model="cardType"
                  v-if="displayVirtualCardOption && displayPhysicalCardOption"
                  hide-details
                >
                  <v-radio
                    label="Virtual Card - $0"
                    value="virtual"
                    class="mb-5 mt-4"
                  ></v-radio>
                  <v-radio
                    :label="
                      `Physical Card - $${userProfile.Client.physicalCardFee}`
                    "
                    value="physical"
                  ></v-radio>
                </v-radio-group>
                <v-radio-group
                  v-model="physicalCardAddress"
                  v-if="computedCardType == 'physical'"
                  class="align-text-left"
                >
                  <caption
                    class="full-width align-text-left mb-4"
                    v-if="displayVirtualCardOption && displayPhysicalCardOption"
                  >
                    Where do you want your card shipped?
                  </caption>
                  <v-radio
                    v-if="
                      address1 &&
                        city &&
                        state &&
                        postalCode &&
                        (!country || country == 'US')
                    "
                    label="Use previously entered address"
                    value="virtual"
                    class="mb-5"
                  ></v-radio>
                  <div
                    class="ml-8"
                    v-if="
                      !loadingUser &&
                        address1 &&
                        city &&
                        state &&
                        postalCode &&
                        (!country || country == 'US')
                    "
                  >
                    <p>{{ address1 }}</p>
                    <p v-if="address2">{{ address2 }}</p>
                    <p>
                      {{ city }}, {{ state }} {{ postalCode }} {{ country }}
                    </p>
                  </div>
                  <v-progress-circular
                    v-else-if="loadingUser"
                    indeterminate
                    color="primary"
                    class="ml-8 mb-4"
                  ></v-progress-circular>
                  <v-radio label="New address" value="new"></v-radio>
                </v-radio-group>
                <v-form
                  ref="form"
                  v-model="validPhysicalCardAddressForm"
                  class="full-width px-4"
                  v-if="
                    computedCardType == 'physical' &&
                      physicalCardAddress == 'new'
                  "
                >
                  <v-text-field
                    ref="address1"
                    label="Address"
                    v-model="cardAddress.address1"
                    class="mt-4"
                    required
                    :rules="[
                      v => !!v || 'Address is required',
                      v =>
                        !!(v && v.length < 256) || 'Address should be shorter'
                    ]"
                  />
                  <v-text-field
                    label="Apt, suite, unit, etc."
                    v-model="cardAddress.address2"
                    class="mt-4"
                  />
                  <v-text-field
                    label="City"
                    v-model="cardAddress.city"
                    class="mt-4"
                    required
                    :rules="[
                      v => !!v || 'City is required',
                      v => !!(v && v.length < 40) || 'City should be shorter'
                    ]"
                  />
                  <v-select
                    v-if="!cardAddress.country || cardAddress.country == 'US'"
                    label="State"
                    class="mt-4"
                    :items="states"
                    v-model="cardAddress.state"
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    item-value="abbreviation"
                    item-text="name"
                    :rules="[v => !!v || 'State is required']"
                  />
                  <v-text-field
                    label="Zip Code"
                    v-model="cardAddress.postalCode"
                    class="mt-4"
                    required
                    :rules="[
                      v => !!v || 'Zip code is required',
                      v =>
                        !!(v && v.length < 10) || 'Zip code should be shorter'
                    ]"
                  />
                  <v-select
                    label="Country"
                    class="mt-4"
                    :items="allCountries"
                    v-model="cardAddress.country"
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    item-value="code"
                    item-text="name"
                    :rules="[v => !!v || 'Country is required']"
                  />
                  <p
                    v-if="cardAddress.country && cardAddress.country != 'US'"
                    class="red--text"
                  >
                    Unfortunately, we can only ship physical cards to the United
                    States. We apologize for the inconvenience.
                  </p>
                  <!-- <v-text-field
                    v-if="country == '00'"
                    label="Other country (2 digit code)"
                    v-model="cardAddress.otherCountry"
                    class="mt-4"
                    required
                    :rules="[
                      (v) => !!v || 'Country is required',
                      (v) =>
                        !!(v && v.length == 2) ||
                        'Country code should be 2 characters',
                      (v) =>
                        !!(v && countryCodes.includes(v.toUpperCase())) ||
                        'Country code must be a valid country',
                    ]"
                  /> -->
                </v-form>
              </v-form>
            </v-row>
            <v-row
              v-if="currentSlide == 5"
              key="5"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title
                class="title-text word-break full-width"
                v-if="
                  this.displayVirtualCardOption &&
                    !this.displayPhysicalCardOption
                "
                >Before we request your virtual card, there are a few things to
                review</v-card-title
              >
              <v-card-title class="title-text word-break full-width" v-else
                >A few things to review before we submit</v-card-title
              >
              <v-form
                ref="form"
                v-model="validNameForm"
                class="full-width px-4"
              >
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="cardholderAgreement"
                    class="mt-4 mb-0"
                    :rules="[v => !!v || '']"
                  />
                  <span
                    >I agree to the
                    <a @click="downloadFile('cardholder_agreement.pdf')"
                      >Cardholder Agreement</a
                    ></span
                  >
                </div>
                <v-divider />

                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="issuerPrivacyPolicy"
                    class="mt-4 mb-0"
                    :rules="[v => !!v || '']"
                  />
                  <span
                    >I agree to the
                    <a @click="downloadFile('issuing_bank_privacy_policy.pdf')"
                      >Issuer Privacy Policy</a
                    ></span
                  >
                </div>
                <v-divider />
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="eSignAgreement"
                    class="mt-4 mb-0"
                    :rules="[v => !!v || '']"
                  />
                  <span
                    >I agree to the
                    <a @click="downloadFile('esign_agreement.pdf')"
                      >eSign Agreement</a
                    ></span
                  >
                </div>
              </v-form>
            </v-row>
            <v-row
              v-else-if="currentSlide == 6"
              key="6"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Processing your request...</v-card-title
              >
              <caption class="grey--text mt-2 full-width align-text-left pl-4">
                This should only take a few seconds.
              </caption>

              <Robin
                class="mt-4"
                :showText="false"
                :width="140"
                :height="140"
                animation="discoPointing"
                :loop="true"
              />
            </v-row>
            <v-row
              v-else-if="currentSlide == 7"
              key="7"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Congratulations!</v-card-title
              >
              <caption
                class="grey--text mt-2 pl-4 full-width align-text-left"
                v-if="computedCardType == 'physical'"
              >
                Your new card will be shipping soon!
              </caption>
              <caption
                class="grey--text mt-2 pl-4 full-width align-text-left"
                v-else
              >
                Your new card is ready!
              </caption>

              <Robin
                :showText="false"
                :width="140"
                :height="140"
                animation="backflip"
                :loop="false"
              />
            </v-row>
            <v-row
              v-else-if="currentSlide == 8"
              key="8"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Looks like your account needs some extra
                attention</v-card-title
              >

              <p class="pl-4 full-width align-text-left">
                Feel free to reach out to customer service via one of the
                following methods...
              </p>
              <p class="pl-4 align-text-left full-width">
                Email -
                <b
                  ><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b
                >
              </p>
              <p class="pl-4 align-text-left full-width">
                Phone (Toll Free) - <b>(855) 264-3329</b>
              </p>
            </v-row>
          </transition>
          <v-row
            class="mb-10"
            align="center"
            justify="center"
            v-if="currentSlide != 6 && currentSlide != 3"
          >
            <v-col>
              <v-btn
                v-if="
                  !displayPhysicalCardOption &&
                    !displayPhysicalCardOption &&
                    currentSlide == 4
                "
                color="primary"
                class="pa-5"
                width="120"
                @click="resetForm"
                ><span class="mt-1">Exit</span></v-btn
              >
              <v-btn
                v-else
                color="primary"
                :disabled="
                  (currentSlide == 0 &&
                    (!validNameForm || !phoneNumberValid)) ||
                    (currentSlide == 1 && !validAddressForm) ||
                    (currentSlide == 4 &&
                      (!computedCardType ||
                        (computedCardType == 'physical' &&
                          (!physicalCardAddress ||
                            (physicalCardAddress == 'new' &&
                              (!validPhysicalCardAddressForm ||
                                cardAddress.country != 'US')))))) ||
                    (currentSlide == 5 &&
                      (!cardholderAgreement ||
                        !eSignAgreement ||
                        !issuerPrivacyPolicy))
                "
                class="pa-5"
                @click="nextSlide"
                ><span class="mt-1">{{
                  currentSlide == 2
                    ? "Confirm"
                    : currentSlide >= 6
                    ? computedCardType == "virtual" && currentSlide == 7
                      ? "Take me to my card"
                      : "Done"
                    : "Continue"
                }}</span></v-btn
              ></v-col
            >
          </v-row></v-col
        >
      </v-row>
      <MarqetaFooter :column="false" />
    </div>
    <v-dialog v-model="dialogEmailError" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            The email you submitted is already in use. Please try a different
            email.
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <!-- <v-btn
            color="primary"
            @click="closeProgramBuilder"
            outlined
            width="130"
            >Don't save</v-btn
          > -->
          <v-btn
            class="mx-auto"
            color="primary"
            @click="(currentSlide = 0), (dialogEmailError = false)"
            width="130"
            >Edit Email</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import UserService from "@/services/UserService";
import {
  states,
  countries,
  allCountries,
  countryCodes
} from "@/shared_data/data.js";
import Robin from "@/components/Robin.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import MarqetaFooter from "@/components/wallet/MarqetaFooter.vue";

import moment from "moment";

import { mapState } from "vuex";

export default {
  name: "MarqetaCardRequest",
  props: {
    alreadyRegistered: Boolean,
    cards: Array
  },
  components: {
    Robin,
    ProgressBar,
    MarqetaFooter
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      dialogEmailError: false,
      validNameForm: false,
      validAddressForm: false,
      validIdForm: false,
      validPhysicalCardAddressForm: false,
      states: states,
      countries: countries,
      allCountries: allCountries,
      countryCodes: countryCodes,
      birthdayMenu: false,
      loadingUser: false,

      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      formattedPhone: null,
      phoneNumberValid: false,
      countryCode: { code: null, value: null },
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
      // otherCountry: null,
      identification: null,
      birthday: null,
      cardType: null,
      physicalCardAddress: null,
      cardAddress: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        country: "US"
        // otherCountry: null,
      },
      cardholderAgreement: false,
      issuerPrivacyPolicy: false,
      eSignAgreement: false,
      chaSupplementalId: null,
      issuerSupplementalId: null,
      esignSupplementalId: null
    };
  },
  created() {
    console.log("CREATED");
    this.preloadForm();
  },
  beforeDestroy() {},
  methods: {
    resetForm(loadVirtualCard = false) {
      this.firstName = null;
      this.lastName = null;
      this.phone = null;
      this.address1 = null;
      this.address2 = null;
      this.city = null;
      this.state = null;
      this.country = null;
      // this.otherCountry = null;
      this.postalCode = null;
      this.identification = null;
      this.birthday = null;
      this.email = null;
      this.cardType = null;
      this.physicalCardAddress = null;
      this.cardAddress = {
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        country: "US"
        // otherCountry: null,
      };
      if (this.currentSlide <= 3 && this.$refs.form) {
        this.$refs.form.reset();
      }

      this.$emit("get-cards", "marqetaCardRequest");
      this.scrollToTop();
      console.log("Resetting: ", loadVirtualCard);
      this.$emit("reset", loadVirtualCard);
      this.currentSlide = 0;
    },
    preloadForm() {
      console.log("Already registered", this.alreadyRegistered);
      // We have to check if this is a Whistle user running through account activation. If so, we force the full route to show
      if (
        this.alreadyRegistered &&
        !(this.userProfile.clientId == 1 && this.userProfile.firstLogin)
      ) {
        // If the user already has a card, then we can skip the account creation step
        // If the user already has a physical card but not a virtual, then skip to slide 5
        if (this.displayVirtualCardOption && !this.displayPhysicalCardOption) {
          this.currentSlide = 5;
        } else {
          this.currentSlide = 4;
        }

        // If we're showing the fourth slide for a physical card, then we get their marqeta user
        if (this.displayPhysicalCardOption) {
          this.getUser();
        }
      } else {
        this.firstName = this.userProfile.firstName;
        this.lastName = this.userProfile.lastName;
        this.email = this.userProfile.personalEmail
          ? this.userProfile.personalEmail
          : this.userProfile.businessEmail;
        // if (
        //   this.userProfile.personalPhone &&
        //   this.userProfile.personalPhone.includes("+")
        // ) {
        //   var phone = this.userProfile.personalPhone.substring(
        //     this.userProfile.personalPhone.indexOf("-") + 1
        //   );
        // } else if (
        //   this.userProfile.businessPhone &&
        //   this.userProfile.businessPhone.includes("+")
        // ) {
        //   phone = this.userProfile.businessPhone.substring(
        //     this.userProfile.businessPhone.indexOf("-") + 1
        //   );
        // } else {
        //   phone = this.userProfile.personalPhone;
        // }
        this.phone =
          this.userProfile.personalPhone || this.userProfile.businessPhone;
        if (
          this.userProfile.businessCountry == null ||
          this.userProfile.businessCountry == "USA"
        ) {
          this.countryCode.code = "US";
        } else {
          this.countryCode.code = this.userProfile.businessCountry;
        }

        this.birthday = this.userProfile.birthday;

        this.address1 = this.userProfile.businessAddress
          ? this.userProfile.businessAddress
          : this.userProfile.homeAddress;
        this.city = this.userProfile.businessCity
          ? this.userProfile.businessCity
          : this.userProfile.homeCity;
        this.state = this.userProfile.businessState
          ? this.userProfile.businessState
          : this.userProfile.homeState;
        if (this.state && this.state.length > 2) this.state = null;
        this.postalCode = this.userProfile.businessPostalCode
          ? this.userProfile.businessPostalCode
          : this.userProfile.homePostalCode;
        if (this.userProfile.businessCountry)
          this.country = this.userProfile.businessCountry;
        //   this.country = "00";
        //   this.otherCountry = this.userProfile.businessCountry;
        // }
      }

      // Finally we preload the supplement Id's for when they sign agreements
      if (this.userProfile && this.userProfile.UserSupplementals) {
        var userSupp = this.userProfile.UserSupplementals;
        var cha = userSupp.find(x => x.key === "Cardholder Agreement");
        this.chaSupplementalId = cha ? cha.userSupplementalId : null;

        var issuerPrivacy = userSupp.find(
          x => x.key === "Issuer Privacy Policy Agreement"
        );
        this.issuerSupplementalId = issuerPrivacy
          ? issuerPrivacy.userSupplementalId
          : null;

        var esign = userSupp.find(x => x.key === "eSign Agreement");
        this.esignSupplementalId = esign ? esign.userSupplementalId : null;
      }
    },
    scrollToTop() {
      if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
        document.getElementsByClassName(
          "v-dialog v-dialog--active"
        )[0].scrollTop = 0;
      } else {
        console.log("Scrolling on mobile");
        const cardY = document.getElementById("progress-bar");
        const rect = cardY.getBoundingClientRect();
        console.log(rect.top);
        window.scrollTo(0, rect.top - 90);
      }
    },
    previousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();
      if (this.currentSlide == 0) {
        this.resetForm(false);
      } else if (this.currentSlide == 2) {
        // Skip address
        this.currentSlide = 0;
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      this.currentSlide += 1;

      if (this.currentSlide == 1) {
        // Skip address
        this.currentSlide = 2;
      } else if (this.currentSlide == 3) {
        this.createUser();
      } else if (this.currentSlide == 6) {
        // We are submitting api call
        this.submitCardRequest();
      } else if (this.currentSlide == 8 && this.computedCardType == "virtual") {
        // If they got the success screen and requested a virtual card, then we take them to the virtual card page.
        this.resetForm(true);
      } else if (this.currentSlide == 8 || this.currentSlide == 9) {
        this.resetForm(false);
      }
    },
    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date);
    },
    getUser() {
      this.loadingUser = true;
      console.log("Getting user info");
      MarqetaService.getUser(null, null, this.magicLinkToken)
        .then(user => {
          console.log("User response: ", user);
          this.firstName = user.first_name;
          this.lastName = user.last_name;
          this.address1 = user.address1;
          this.address2 = user.address2;
          this.city = user.city;
          this.state = user.state;
          this.postalCode = user.postal_code;
          this.country = user.country;
          this.loadingUser = false;
        })
        .catch(error => {
          console.log("There was an error getting user: ", error);
          // this.currentSlide++;
        });
    },
    updateUser(obj) {
      // This is used to update the user in the database
      obj.updatedBy = this.userProfile.userId;
      return UserService.updateUser(
        this.userProfile.userId,
        obj,
        this.magicLinkToken
      )
        .then(response => {
          console.log("Response from updating user ", response);
          return response;
        })
        .catch(error => {
          console.log("There was an error updating the user: ", error);
        });
    },
    createUser() {
      var body = {
        email: this.email,
        phone: this.formattedPhone,
        // address1: this.address1,
        // address2: this.address2,
        // city: this.city,
        // state: this.state,
        // postal_code: this.postalCode,
        // country: "US", //this.country != "00" ? this.country : this.otherCountry,
        first_name: this.firstName,
        last_name: this.lastName,
        birth_date: this.birthday,
        metadata: {
          clientId: this.userProfile.clientId,
          clientName:
            this.userProfile && this.userProfile.Client
              ? this.userProfile.Client.clientName
              : "Unknown",
          userId: this.userProfile.userId
        }
        // identifications: [{ type: "SSN", value: this.identification }],
      };
      console.log("Submitting user: ", body);
      MarqetaService.createUser(body, false, this.magicLinkToken)
        .then(response => {
          console.log("User response: ", response);
          // If there was an error with the API, we show the error page
          if (response.error) {
            // If user put in an email that already exists, send them back to slide 1
            if (response.error_code == 1190022) {
              this.dialogEmailError = true;
            } else {
              this.currentSlide = 8;
            }
          } else if (response.status == "ACTIVE") {
            // The user passed KYC or already existed
            // Since the user is active, then we show them the next slide, unless they can only request a virtual card, in which case we proceed
            if (
              this.displayVirtualCardOption &&
              !this.displayPhysicalCardOption
            ) {
              this.currentSlide += 2;
            } else {
              this.currentSlide++;
            }
            // this.updateUser({
            //   platformStatus:
            //     this.cashBalance > 0
            //       ? "FUNDS_ISSUED_MARQETA_CREATED_NO_CARD"
            //       : "MARQETA_CREATED_NO_CARD",
            //   platformStatusUpdateDate: moment().utc(),
            // });
            // this.currentSlide = 7;
          } else {
            // The user didn't pass KYC so we show the customer service page
            this.currentSlide = 8;
            // this.currentSlide++;
          }
        })
        .catch(error => {
          console.log("There was an error submitting card request: ", error);

          this.currentSlide = 8;
          // this.currentSlide++;
        });
    },
    submitCardRequest() {
      console.log("Submitting " + this.computedCardType + " card request");
      var body = {
        address: null
      };
      if (this.computedCardType == "physical") {
        if (this.physicalCardAddress == "new") {
          body = {
            address: {
              address1: this.cardAddress.address1,
              address2: this.cardAddress.address2,
              city: this.cardAddress.city,
              state:
                this.cardAddress.country != "US"
                  ? null
                  : this.cardAddress.state,
              postal_code: this.cardAddress.postalCode,
              country: this.cardAddress.country
              // country: "US"
              // this.cardAddress.country != "00"
              //   ? this.cardAddress.country
              //   : this.cardAddress.otherCountry,
            }
          };
        }
      }

      MarqetaService.issueCard(this.computedCardType, body, this.magicLinkToken)
        .then(response => {
          console.log("Issue card response: ", response);
          if (!response.error) {
            this.currentSlide++;
            let userSuppArray = [
              {
                userSupplementalId: this.chaSupplementalId,
                userId: this.userProfile.userId,
                key: "Cardholder Agreement",
                value: moment().utc(),
                updatedBy: this.userProfile.userId
              },
              {
                userSupplementalId: this.issuerSupplementalId,
                userId: this.userProfile.userId,
                key: "Issuer Privacy Policy Agreement",
                value: moment().utc(),
                updatedBy: this.userProfile.userId
              },
              {
                userSupplementalId: this.esignSupplementalId,
                userId: this.userProfile.userId,
                key: "eSign Agreement",
                value: moment().utc(),
                updatedBy: this.userProfile.userId
              }
            ];
            console.log("Updating/Creating User Supplemental: ", userSuppArray);
            UserService.bulkUpdateUserSupplemental(
              userSuppArray,
              this.magicLinkToken
            );
          } else {
            this.currentSlide = 8;
          }
        })
        .catch(error => {
          console.log("There was an error submitting card request: ", error);
          this.currentSlide = 8;
        });
    },
    countryCodeChanged(country) {
      this.countryCode.code = country.iso2;
      this.countryCode.value = country.dialCode;
    },
    phoneValidation: function({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log(number);
      if (isValid) {
        this.formattedPhone = number.e164;
        this.phoneNumberValid = true;
      } else {
        this.phoneNumberValid = false;
      }
    },
    downloadFile(fileName) {
      UserService.downloadCardProductFile(fileName, this.magicLinkToken);
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "walletMenuScreen",
      "displayWalletMenu",
      "cashBalance",
      "magicLinkToken"
    ]),
    progress() {
      // We have to check if this is a Whistle user running through account activation. If so, we force the full route to show
      if (
        this.alreadyRegistered &&
        !(this.userProfile.clientId == 1 && this.userProfile.firstLogin)
      ) {
        // If the user already has a card, then we can skip the account creation step
        // If the user already has a physical card but not a virtual, then skip to slide 5
        if (this.displayVirtualCardOption && !this.displayPhysicalCardOption) {
          return (((this.currentSlide + 1 - 5) / 2) * 100).toString();
        } else {
          console.log(((this.currentSlide + 1 - 4) / 3) * 100);
          return (((this.currentSlide + 1 - 4) / 3) * 100).toString();
        }
      } else {
        var slide = this.currentSlide;
        if (slide == 2) {
          slide = 1;
        }
        return (((slide + 1) / 5) * 100).toString();
      }
    },
    disableNextButton() {
      return (
        (this.currentSlide == 0 &&
          (!this.validNameForm || !this.phoneNumberValid)) ||
        (this.currentSlide == 1 && !this.validAddressForm) ||
        (this.currentSlide == 4 &&
          (!this.computedCardType ||
            (this.computedCardType == "physical" &&
              (!this.physicalCardAddress ||
                (this.physicalCardAddress == "new" &&
                  (!this.validPhysicalCardAddressForm ||
                    this.cardAddress.country != "US")))))) ||
        (this.currentSlide == 5 &&
          (!this.cardholderAgreement ||
            !this.eSignAgreement ||
            !this.issuerPrivacyPolicy))
      );
    },
    displayPhysicalCardOption() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      if (
        this.cashBalance < this.userProfile.Client.physicalCardFee ||
        this.cards.find(
          x =>
            x.type == "physical" &&
            (x.state == "UNACTIVATED" || x.state == "ACTIVE")
        )
      ) {
        console.log("Found physical card. Won't display this option");
        return false;
      } else {
        return true;
      }
    },
    displayVirtualCardOption() {
      if (this.userProfile.clientId == 1 && this.userProfile.firstLogin) {
        return true;
      }
      if (
        this.cards.find(
          x =>
            x.type == "virtual" &&
            (x.state == "UNACTIVATED" || x.state == "ACTIVE")
        )
      ) {
        console.log("Found virtual card. Won't display this option");
        return false;
      }
      return true;
    },
    computedCardType() {
      if (this.cardType) {
        return this.cardType;
      } else if (
        !this.displayVirtualCardOption &&
        this.displayPhysicalCardOption
      ) {
        return "physical";
      } else if (
        this.displayVirtualCardOption &&
        !this.displayPhysicalCardOption
      ) {
        return "virtual";
      } else {
        return null;
      }
    },
    computedRobinMessage() {
      if (this.currentSlide == 0) {
        return "Because this card is your property, we need some contact information to link you to your new card";
      } else if (this.currentSlide == 1) {
        return "Make sure to use your home address! The money on your card is still useful if you leave or change jobs.";
      } else if (this.currentSlide == 2) {
        return "Double check that the information entered is correct before we set up your card";
      } else if (
        this.currentSlide == 4 &&
        this.displayPhysicalCardOption &&
        this.displayVirtualCardOption
      ) {
        return "A physical card has to be shipped to you but can be used anywhere Visa is accepted. A virtual card is $0 and can be used today!";
      } else if (
        this.currentSlide == 4 &&
        this.displayPhysicalCardOption &&
        !this.displayVirtualCardOption
      ) {
        return "Before you request your physical card, double check your shipping address before completing the set up!";
      } else if (
        this.currentSlide == 4 &&
        !this.displayPhysicalCardOption &&
        this.displayVirtualCardOption
      ) {
        return "Getting a virtual card is easy and quick! You're so close to being able to use yours!";
      } else if (
        this.currentSlide == 4 &&
        !this.displayPhysicalCardOption &&
        !this.displayVirtualCardOption
      ) {
        return "Sorry! You can only request 1 physical and 1 virtual card at a time.";
      } else if (this.currentSlide == 5) {
        return "You're almost there! This is the last step to getting your card";
      } else {
        return null;
      }
    },
    cobrandedCardImage() {
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom2 &&
        this.userProfile.Client.internalCustom2.startsWith("https")
      )
        return this.userProfile.Client.internalCustom2;
      return null;
    }
  },
  watch: {
    birthdayMenu(val) {
      val &&
        setTimeout(() => {
          this.$refs.birthdayPicker.activePicker = "YEAR";
        });
    },
    displayWalletMenu(val) {
      console.log(val);
      if (val && this.walletMenuScreen == "card-request") {
        console.log("Calling preload form");
        this.preloadForm();
      }
    }
  }
};
</script>

<style scoped>
.title-text {
  font-size: 1.5em;
}
.card-text {
  font-size: 1.3em;
}
.card-text-small {
  font-size: 0.5em;
}
.issuer-agreement {
  font-size: 12px;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.progress-bar {
  width: calc(50% - 60px);
}
.view-height {
  min-height: calc(100vh - 40px);
}
.hide-overflow {
  overflow: hidden;
}

.border {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}
</style>

<template>
  <div flat rounded="0" class="full-height hide-overflow" height="100%">
    <v-toolbar dark color="primary" rounded="0">
      <v-toolbar-title>Card Replacement</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="resetForm">
          Cancel
          <v-icon class="ml-2">mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <div
      class="d-flex flex-column justify-space-between full-height view-height"
    >
      <v-row justify="center" class="full-width" no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="4 "
          lg="4"
          xl="4"
          class="align-text-left darkGrey--text"
        >
          <div
            class="d-flex justify-center align-center mb-5 mt-10 hidden"
            v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
          >
            <v-btn small fab elevation="0" color="mediumGrey"
              ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
            >
            <ProgressBar :progress="progress" class="progress-bar mx-12" />
            <v-btn small fab elevation="0" color="mediumGrey"
              ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <div class="d-flex justify-center">
            <v-img
              v-if="cobrandedCardImage"
              :src="cobrandedCardImage"
              :width="$vuetify.breakpoint.xs ? '95%' : '100%'"
              max-width="500"
              class="my-4 mx-2 rounded-xl border"
            />
            <v-img
              v-else
              src="@/assets/Wallet/whistle-card-default.png"
              :width="$vuetify.breakpoint.xs ? '95%' : '100%'"
              max-width="500"
              class="my-4 mx-2"
            />
          </div>
          <!-- <v-card
            class="ma-4 py-4 px-6 align-text-left mx-auto rounded-xl"
            elevation="2"
            max-width="500"
          >
            <div class="d-flex justify-space-between align-center mb-4 mt-2">
              <v-img
                src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
                :max-width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 130 : 160
                "
              />
              <v-img
                src="@/assets/Wallet/nfc.png"
                :max-width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 35 : 50
                "
                contain
              />
            </div>
            <v-img
              src="@/assets/Wallet/chip.png"
              :max-width="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 60
              "
              contain
              class="ml-3"
            />
            <p class="brand--text mt-3 mb-4 ml-2 card-text">
              <b>4000 1234 5678 9010</b>
            </p>
            <div class="d-flex align-end justify-space-between ml-10 pl-10">
              <div class="d-flex flex-column">
                <strong class="brand--text card-text-small">VALID</strong>
                <strong class="brand--text card-text-small">THRU</strong>
              </div>
              <span class="brand--text ml-2 mr-auto card-text">
                <b>00/00</b>
              </span>

              <div class="brand--text card-text align-end">
                <b>DEBIT</b>
              </div>
            </div>

            <div class="d-flex justify-space-between mb-2">
              <div>
                <strong class="card-text brand--text ml-2">
                  {{ "YOUR NAME HERE" }}
                </strong>
              </div>

              <v-img
                src="@/assets/Wallet/visa.png"
                :max-width="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 70 : 85
                "
                contain
              />
            </div>
          </v-card> -->
          <div class="mx-6">
            <span class="issuer-agreement word-break"
              >This card is issued by Sutton Bank, Member FDIC, pursuant to a
              license from Visa U.S.A. Inc. The Whistle card is powered by
              Marqeta.</span
            >
          </div>

          <v-card-title
            class="title-text word-break ml-0 full-width"
            v-if="currentSlide <= 2"
          >
            We need a few details for your replacement card</v-card-title
          >

          <p
            class="mx-4 mt-5"
            v-if="currentSlide <= 2 && userProfile.Client.physicalCardFee > 0"
          >
            A virtual card has a $0 replacement fee while a physical card is ${{
              userProfile.Client.physicalCardFee
            }}. If you need to replace a physical card and you don't have ${{
              userProfile.Client.physicalCardFee
            }}
            in your wallet, you have the option to cancel your phsyical card.
            This will not affect your virtual card.
          </p>
          <p class="mx-4 mt-5" v-else-if="currentSlide <= 2">
            Both the virtual and physical cards have a $0 replacement fee.
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="5" lg="5" xl="5">
          <div class="d-flex justify-center align-center mb-5 mt-10">
            <v-btn
              small
              fab
              elevation="0"
              color="mediumGrey"
              @click="previousSlide"
              :class="{
                hidden:
                  currentSlide == 0 ||
                  currentSlide == 2 ||
                  currentSlide == 3 ||
                  currentSlide == 4
              }"
              ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
            >
            <ProgressBar
              :progress="progress"
              class="progress-bar mx-12"
              id="progress-bar"
            />
            <v-btn
              small
              fab
              elevation="0"
              color="mediumGrey"
              @click="nextSlide"
              :disabled="disableNextButton"
              :class="{ hidden: currentSlide == 2 }"
              ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <transition :name="slideDirection" mode="out-in">
            <v-row
              v-if="currentSlide == 0"
              key="4"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title
                class="title-text word-break full-width"
                v-if="displayVirtualCardOption && displayPhysicalCardOption"
                >Which type of card do you need to cancel or
                replace?</v-card-title
              >
              <v-card-title class="title-text word-break full-width" v-else
                >Do you need to cancel or replace your card?</v-card-title
              >
              <v-form
                ref="form"
                v-model="validNameForm"
                class="full-width px-4 align-text-left"
              >
                <v-radio-group
                  v-model="cardType"
                  v-if="displayVirtualCardOption && displayPhysicalCardOption"
                  @change="resetRadioGroups"
                >
                  <v-radio
                    label="Virtual Card"
                    value="virtual"
                    class="mb-5 mt-4"
                  ></v-radio>
                  <v-radio label="Physical Card" value="physical"></v-radio>
                </v-radio-group>

                <v-radio-group
                  v-model="cancelOrReplace"
                  v-if="computedCardType"
                >
                  <caption
                    v-if="
                      computedCardType == 'physical' &&
                        displayVirtualCardOption &&
                        !userCanRequestPhysicalCard
                    "
                    class="full-width align-text-left"
                  >
                    You have less than ${{
                      userProfile.Client.physicalCardFee
                    }}
                    in your wallet, so we recommend cancelling your card and
                    using your virtual card.
                  </caption>
                  <caption
                    v-else-if="
                      computedCardType == 'physical' &&
                        !displayVirtualCardOption &&
                        !userCanRequestPhysicalCard
                    "
                    class="full-width align-text-left"
                  >
                    You have less than ${{
                      userProfile.Client.physicalCardFee
                    }}
                    in your wallet, so we recommend cancelling your card and
                    requesting a virtual card for $0.
                  </caption>
                  <caption class="full-width align-text-left" v-else>
                    Are you replacing or cancelling your card?
                  </caption>
                  <v-radio
                    :disabled="
                      computedCardType == 'physical' &&
                        !userCanRequestPhysicalCard
                    "
                    :label="
                      computedCardType == 'virtual'
                        ? 'Replace'
                        : 'Replace - $' + userProfile.Client.physicalCardFee
                    "
                    value="replace"
                    class="mb-5 mt-4"
                  ></v-radio>
                  <v-radio label="Cancel" value="cancel"></v-radio>
                </v-radio-group>
                <v-radio-group
                  v-model="physicalCardAddress"
                  v-if="
                    computedCardType == 'physical' &&
                      cancelOrReplace == 'replace'
                  "
                  class="align-text-left"
                >
                  <caption
                    class="full-width align-text-left"
                    v-if="displayVirtualCardOption && displayPhysicalCardOption"
                  >
                    Where do you want your card shipped?
                  </caption>
                  <v-radio
                    v-if="
                      address1 &&
                        city &&
                        state &&
                        postalCode &&
                        (!country || country == 'US')
                    "
                    label="Use address on file"
                    value="virtual"
                    class="mb-5 mt-4"
                  ></v-radio>
                  <div
                    class="ml-8"
                    v-if="
                      !loadingUser &&
                        address1 &&
                        city &&
                        state &&
                        postalCode &&
                        (!country || country == 'US')
                    "
                  >
                    <p>{{ address1 }}</p>
                    <p v-if="address2">{{ address2 }}</p>
                    <p>
                      {{ city }}, {{ state }} {{ postalCode }} {{ country }}
                    </p>
                  </div>
                  <v-progress-circular
                    v-else-if="loadingUser"
                    indeterminate
                    color="primary"
                    class="ml-8 mb-4"
                  ></v-progress-circular>
                  <v-radio label="New address" value="new"></v-radio>
                </v-radio-group>

                <v-form
                  ref="form"
                  v-model="validPhysicalCardAddressForm"
                  class="full-width px-4"
                  v-if="
                    computedCardType == 'physical' &&
                      physicalCardAddress == 'new' &&
                      cancelOrReplace == 'replace'
                  "
                >
                  <v-text-field
                    ref="address1"
                    label="Address"
                    v-model="cardAddress.address1"
                    class="mt-4"
                    required
                    :rules="[
                      v => !!v || 'Address is required',
                      v =>
                        !!(v && v.length < 256) || 'Address should be shorter'
                    ]"
                  />
                  <v-text-field
                    label="Apt, suite, unit, etc."
                    v-model="cardAddress.address2"
                    class="mt-4"
                  />
                  <v-text-field
                    label="City"
                    v-model="cardAddress.city"
                    class="mt-4"
                    required
                    :rules="[
                      v => !!v || 'City is required',
                      v => !!(v && v.length < 40) || 'City should be shorter'
                    ]"
                  />
                  <v-select
                    v-if="!cardAddress.country || cardAddress.country == 'US'"
                    label="State"
                    class="mt-4"
                    :items="states"
                    v-model="cardAddress.state"
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    item-value="abbreviation"
                    item-text="name"
                    :rules="[v => !!v || 'State is required']"
                  />
                  <v-text-field
                    label="Zip Code"
                    v-model="cardAddress.postalCode"
                    class="mt-4"
                    required
                    :rules="[
                      v => !!v || 'Zip code is required',
                      v =>
                        !!(v && v.length < 10) || 'Zip code should be shorter'
                    ]"
                  />
                  <v-select
                    label="Country"
                    class="mt-4"
                    :items="allCountries"
                    v-model="cardAddress.country"
                    attach
                    :menu-props="{ top: true, offsetY: true }"
                    item-value="code"
                    item-text="name"
                    :rules="[v => !!v || 'Country is required']"
                  />
                  <p
                    v-if="cardAddress.country && cardAddress.country != 'US'"
                    class="red--text"
                  >
                    Unfortunately, we can only ship physical cards to the United
                    States. We apologize for the inconvenience.
                  </p>
                  <!-- <v-text-field
                    label="Other country"
                    v-model="cardAddress.country"
                    class="mt-4"
                    required
                    :rules="[
                      v => !!v || 'Country is required',
                      v => !!(v && v.length < 40) || 'Country should be shorter'
                    ]"
                  /> -->
                </v-form>
              </v-form>
            </v-row>
            <v-row
              v-if="currentSlide == 1"
              key="5"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Why are you
                {{ cancelOrReplace == "cancel" ? "cancelling" : "replacing" }}
                your card?</v-card-title
              >

              <v-form
                ref="form"
                v-model="validNameForm"
                class="full-width px-4 align-text-left"
              >
                <v-radio-group v-model="replacementType">
                  <v-radio
                    label="Lost"
                    value="lost"
                    class="mt-4 mb-5"
                    v-if="canSuspendCard"
                  ></v-radio>
                  <v-radio label="Stolen" value="stolen" class="mb-5"></v-radio>
                  <v-radio
                    label="Damaged"
                    value="damaged"
                    v-if="cancelOrReplace == 'replace' && canReplaceDamagedCard"
                  ></v-radio>
                </v-radio-group>
              </v-form>
            </v-row>
            <v-row
              v-else-if="currentSlide == 2"
              key="6"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Processing your request...</v-card-title
              >
              <caption class="grey--text mt-2 full-width align-text-left pl-4">
                This should only take a few seconds.
              </caption>

              <Robin
                class="mt-4"
                :showText="false"
                :width="140"
                :height="140"
                animation="egypt"
                :loop="true"
              />
            </v-row>
            <v-row
              v-else-if="currentSlide == 3"
              key="7"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >All done!</v-card-title
              >
              <caption
                class="grey--text mt-2 pl-4 full-width align-text-left"
                v-if="cancelOrReplace == 'cancel'"
              >
                Your card has been cancelled.
              </caption>
              <caption
                class="grey--text mt-2 pl-4 full-width align-text-left"
                v-else-if="computedCardType == 'physical'"
              >
                Your replacement card will be shipping soon!
              </caption>
              <caption
                class="grey--text mt-2 pl-4 full-width align-text-left"
                v-else
              >
                Your replacement card is ready!
              </caption>

              <Robin
                :showText="false"
                :width="140"
                :height="140"
                animation="backflip"
                :loop="false"
              />
            </v-row>
            <v-row
              v-else-if="currentSlide == 4"
              key="8"
              no-gutters
              class="py-3 word-break px-5 d-flex flex-column align-center mb-8 align-text-left"
            >
              <v-card-title class="title-text word-break full-width"
                >Looks like your account needs some extra
                attention</v-card-title
              >

              <p class="pl-4 align-text-left full-width">
                Feel free to reach out to customer service via one of the
                following methods...
              </p>
              <p class="pl-4 align-text-left full-width">
                Email -
                <b
                  ><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b
                >
              </p>
              <p class="pl-4 align-text-left full-width">
                Phone (Toll Free) - <b>(855) 264-3329</b>
              </p>
            </v-row>
          </transition>
          <v-row
            class="mb-10"
            align="center"
            justify="center"
            v-if="currentSlide != 2"
          >
            <v-col>
              <v-btn
                color="primary"
                :disabled="
                  (currentSlide == 0 &&
                    (!computedCardType ||
                      !cancelOrReplace ||
                      (computedCardType == 'physical' &&
                        cancelOrReplace == 'replace' &&
                        (!physicalCardAddress ||
                          (physicalCardAddress == 'new' &&
                            (!validPhysicalCardAddressForm ||
                              cardAddress.country != 'US')))))) ||
                    (currentSlide == 1 && !replacementType)
                "
                class="pa-5"
                @click="nextSlide"
                ><span class="mt-1">{{
                  currentSlide >= 2 ? "Done" : "Continue"
                }}</span></v-btn
              ></v-col
            >
          </v-row></v-col
        >
      </v-row>
      <MarqetaFooter :column="false" />
    </div>
  </div>
</template>

<script>
import MarqetaService from "@/services/MarqetaService";
import UserService from "@/services/UserService";
import {
  states,
  countries,
  countryCodes,
  allCountries
} from "@/shared_data/data.js";
import Robin from "@/components/Robin.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import MarqetaFooter from "@/components/wallet/MarqetaFooter.vue";

import { mapState } from "vuex";

export default {
  name: "MarqetaCardReplacement",
  props: {
    alreadyRegistered: Boolean,
    cards: Array
  },
  components: {
    Robin,
    ProgressBar,
    MarqetaFooter
  },
  data() {
    return {
      currentSlide: 0,
      slideDirection: "topic-left",
      validNameForm: false,
      validAddressForm: false,
      validIdForm: false,
      validPhysicalCardAddressForm: false,
      states: states,
      countries: countries,
      allCountries: allCountries,
      countryCodes: countryCodes,
      loadingUser: false,
      address1: null,
      address2: null,
      city: null,
      state: null,
      postalCode: null,
      country: null,
      cardType: null,
      cancelOrReplace: null,
      replacementType: null,
      physicalCardAddress: null,
      cardAddress: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        country: "US"
        // otherCountry: null
      },
      cardholderAgreement: false,
      issuerPrivacyPolicy: false,
      eSignAgreement: false
    };
  },
  created() {
    this.getMarqetaUser();
    // We do some quick checks to see what their balance is and if we need to auto advance them to the next screen
    // if (this.displayVirtualCardOption && !this.displayPhysicalCardOption) {
    //   // if the user just has a virtual, then we don't ask them about address
    //   this.currentSlide = 1;
    // } else if (
    //   this.displayPhysicalCardOption &&
    //   !this.displayVirtualCardOption &&
    //   !this.userCanRequestPhysicalCard
    // ) {
    //   // The user just has a physical card but not enough money, so we show them the next screen with a special message
    //   this.currentSlide = 1;
    // }
  },
  beforeDestroy() {},
  methods: {
    resetForm() {
      this.cardType = null;
      this.cancelOrReplace = null;
      this.replacementType = null;
      this.physicalCardAddress = null;
      this.cardAddress = {
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        country: "US"
        // otherCountry: null
      };
      // if (this.currentSlide <= 3) {
      //   this.$refs.form.reset();
      // }

      this.$emit("get-cards", "marqetaCardReplacement");
      this.scrollToTop();
      this.$emit("reset");
      this.currentSlide = 0;
    },
    resetRadioGroups() {
      // Called if the user selects physical or virtual. Then we reset things
      this.cancelOrReplace = null;
      this.physicalCardAddress = null;
      this.cardAddress = {
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        country: "US"
        // otherCountry: null
      };
    },
    scrollToTop() {
      if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
        document.getElementsByClassName(
          "v-dialog v-dialog--active"
        )[0].scrollTop = 0;
      } else {
        console.log("Scrolling on mobile");
        const cardY = document.getElementById("progress-bar");
        const rect = cardY.getBoundingClientRect();
        console.log(rect.top);
        window.scrollTo(0, rect.top - 90);
      }
    },
    previousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();
      if (this.currentSlide == 0) {
        this.resetForm();
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide() {
      this.slideDirection = "topic-left";
      this.scrollToTop();
      this.currentSlide += 1;
      if (this.currentSlide == 2) {
        // We are submitting api call
        if (this.cancelOrReplace == "cancel") {
          this.cancelCard();
        } else {
          this.replaceCard();
        }
      } else if (this.currentSlide == 4 || this.currentSlide == 5) {
        this.resetForm();
      }
    },
    getMarqetaUser() {
      this.loadingUser = true;
      console.log("Getting user info");
      MarqetaService.getUser(null, null, this.magicLinkToken)
        .then(user => {
          console.log("User response: ", user);
          this.address1 = user.address1;
          this.address2 = user.address2;
          this.city = user.city;
          this.state = user.state;
          this.postalCode = user.postal_code;
          this.country = user.country;
          this.loadingUser = false;
        })
        .catch(error => {
          console.log("There was an error getting user: ", error);
        });
    },
    replaceCard() {
      console.log("Submitting " + this.computedCardType + " card request");
      var body = {
        address: null
      };
      if (this.computedCardType == "physical") {
        if (this.physicalCardAddress == "new") {
          body = {
            address: {
              address1: this.cardAddress.address1,
              address2: this.cardAddress.address2,
              city: this.cardAddress.city,
              state:
                this.cardAddress.country != "US"
                  ? null
                  : this.cardAddress.state,
              postal_code: this.cardAddress.postalCode,
              // country: "US"
              country: this.cardAddress.country
              // != "00"
              //   ? this.cardAddress.country
              //   : this.cardAddress.otherCountry
            }
          };
        }
      }

      MarqetaService.replaceCard(
        this.computedCardType,
        this.replacementType,
        body,
        this.magicLinkToken
      )
        .then(response => {
          console.log("Card replacement response: ", response);
          if (response.error_code) {
            this.currentSlide = 4;
          } else {
            this.currentSlide++;
          }
        })
        .catch(error => {
          console.log(
            "There was an error submitting card replacement: ",
            error
          );
          this.currentSlide = 4;
        });
    },
    cancelCard() {
      console.log("Submitting " + this.computedCardType + " card request");

      MarqetaService.cancelCard(
        this.computedCardType,
        this.replacementType,
        this.magicLinkToken
      )
        .then(response => {
          console.log("Card cancel response: ", response);
          if (response.error_code) {
            this.currentSlide = 4;
          } else {
            this.currentSlide++;
          }
        })
        .catch(error => {
          console.log(
            "There was an error submitting card cancellation: ",
            error
          );
          this.currentSlide = 4;
        });
    },
    countryCodeChanged(country) {
      this.countryCode.code = country.iso2;
      this.countryCode.value = country.dialCode;
    },
    phoneValidation: function({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log(number);
      if (isValid) {
        this.formattedPhone = number.e164;
        this.phoneNumberValid = true;
      } else {
        this.phoneNumberValid = false;
      }
    },
    downloadFile(fileName) {
      UserService.downloadCardProductFile(fileName, this.magicLinkToken);
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "walletMenuScreen",
      "displayWalletMenu",
      "cashBalance",
      "magicLinkToken"
    ]),
    progress() {
      // If the user already has a card, then we can skip the account creation step
      // If the user already has a physical card but not a virtual, then skip to slide 5
      // if (!this.displayVirtualCardOption && this.displayPhysicalCardOption) {
      //   return (((this.currentSlide + 1) / 2) * 100).toString();
      // } else {
      return (((this.currentSlide + 1) / 3) * 100).toString();
      // }
    },
    disableNextButton() {
      return (
        (this.currentSlide == 0 &&
          (!this.computedCardType ||
            !this.cancelOrReplace ||
            (this.computedCardType == "physical" &&
              this.cancelOrReplace == "replace" &&
              (!this.physicalCardAddress ||
                (this.physicalCardAddress == "new" &&
                  (!this.validPhysicalCardAddressForm ||
                    this.cardAddress.country != "US")))))) ||
        (this.currentSlide == 1 && !this.replacementType)
      );
    },
    userCanRequestPhysicalCard() {
      // This will tell us if the user has enough funds to request a new card
      return this.cashBalance >= this.userProfile.Client.physicalCardFee;
      // return false;
    },
    canReplaceDamagedCard() {
      // Marqeta's API doesn't like us replacing a damaged card if it's unactivated, so we hide this option if it's unactivated
      if (this.computedCardType) {
        return !this.cards.find(
          x => x.type == this.computedCardType && x.state == "UNACTIVATED"
        );
      } else {
        return false;
      }
    },
    canSuspendCard() {
      // Marqeta's API doesn't like us suspending a lost card if it's unactivated, so we hide the option
      if (this.computedCardType) {
        return !this.cards.find(
          x => x.type == this.computedCardType && x.state == "UNACTIVATED"
        );
      } else {
        return false;
      }
    },
    displayPhysicalCardOption() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      if (
        this.cards.find(
          x =>
            x.type == "physical" &&
            (x.state == "UNACTIVATED" || x.state == "ACTIVE")
        )
      ) {
        console.log("Found physical card.");
        return true;
      } else {
        return false;
      }
    },
    displayVirtualCardOption() {
      // if (this.userProfile.clientId == 1) {
      //   return true;
      // }
      if (
        this.cards.find(
          x =>
            x.type == "virtual" &&
            (x.state == "UNACTIVATED" || x.state == "ACTIVE")
        )
      ) {
        console.log("Found virtual card.");
        return true;
      }
      return false;
    },
    computedCardType() {
      if (this.cardType) {
        return this.cardType;
      } else if (
        !this.displayVirtualCardOption &&
        this.displayPhysicalCardOption
      ) {
        return "physical";
      } else if (
        this.displayVirtualCardOption &&
        !this.displayPhysicalCardOption
      ) {
        return "virtual";
      } else {
        return null;
      }
    },
    cobrandedCardImage() {
      if (
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.internalCustom2 &&
        this.userProfile.Client.internalCustom2.startsWith("https")
      )
        return this.userProfile.Client.internalCustom2;
      return null;
    }
  },
  watch: {}
};
</script>

<style scoped>
.title-text {
  font-size: 1.5em;
}
.card-text {
  font-size: 1.3em;
}
.card-text-small {
  font-size: 0.5em;
}
.issuer-agreement {
  font-size: 12px;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.progress-bar {
  width: calc(50% - 60px);
}
.view-height {
  min-height: calc(100vh - 40px);
}

.hide-overflow {
  overflow: hidden;
}

.border {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}
</style>

<template>
  <div class="full-height gradient-background" rounded="0">
    <div
      class="d-flex flex-column justify-space-between full-height container wide-container pb-10"
    >
      <v-row justify="center" class="full-width" no-gutters
        ><v-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
          class="align-text-left darkGrey--text justify-space-between d-flex flex-column"
        >
          <div>
            <div
              class="d-flex justify-center align-center mb-5 mt-10 hidden"
              v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >
              <v-btn small fab elevation="0" color="mediumGrey"
                ><v-icon large color="white">mdi-chevron-left</v-icon></v-btn
              >
              <ProgressBar :progress="progress" class="progress-bar mx-12" />
              <v-btn small fab elevation="0" color="mediumGrey"
                ><v-icon large color="white">mdi-chevron-right</v-icon></v-btn
              >
            </div>

            <v-img
              src="@/assets/Logo/whistle-logo-gradient-clean-v2.png"
              height="90"
              contain
            />

            <div
              v-if="currentSlide < keys.emailVerified"
              style="min-height: 200px;"
            >
              <div class="d-flex align-start mx-4 mt-8 word-break">
                <v-icon
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <span>
                  <span class="font-weight-bold">Easy</span> to set up and
                  manage for companies large and small
                </span>
              </div>
              <div class="d-flex align-start mx-4 mt-5 word-break">
                <v-icon
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <span>
                  <span class="font-weight-bold">Free</span> to use without
                  hidden fees or breakage
                </span>
              </div>
              <div class="d-flex align-start mx-4 mt-5 word-break">
                <v-icon
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <span>
                  <span class="font-weight-bold">Better</span> technology and
                  experience for everyone
                </span>
              </div>
            </div>
            <div v-else>
              <div class="d-flex align-start mx-4 mt-8 word-break">
                <v-icon
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <span class="font-weight-bold">
                  Sign up and confirm email
                </span>
              </div>
              <div class="d-flex align-start mx-4 mt-7 word-break">
                <v-icon
                  v-if="
                    currentSlide > keys.agreements ||
                      userProfile.Client.completedMSA
                  "
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <v-icon
                  v-else
                  color="white"
                  x-small
                  class="medium-grey-background circle-border-radius mini-icon mr-2"
                  >fa-times</v-icon
                >
                <span class="font-weight-bold">
                  Sign Agreement
                </span>
              </div>
              <!-- <div class="d-flex align-start mx-4 mt-7 word-break">
                <v-icon
                  v-if="KYBStatus == 'Complete'"
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <v-icon
                  v-else
                  color="white"
                  x-small
                  class="medium-grey-background circle-border-radius mini-icon mr-2"
                  >fa-times</v-icon
                >
                <span class="font-weight-bold">
                  Business Verification
                </span>
              </div> -->
              <div class="d-flex align-start mx-4 mt-7 word-break">
                <v-icon
                  v-if="hasFundedBudget"
                  color="white"
                  x-small
                  class="brand-green-background circle-border-radius mini-icon mr-2"
                  >fa-check</v-icon
                >
                <v-icon
                  v-else
                  color="white"
                  x-small
                  class="medium-grey-background circle-border-radius mini-icon mr-2"
                  >fa-times</v-icon
                >
                <span class="font-weight-bold">
                  Fund your account
                </span>
              </div>
            </div>
          </div>
          <div v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
            <p
              class="white--text cursor-pointer font-weight-bold ml-10 mb-1"
              @click="downloadFile('terms')"
            >
              Whistle Terms
            </p>
            <p
              v-if="!$auth.isAuthenticated"
              class="white--text cursor-pointer font-weight-bold ml-10"
              @click="loginOrLogout"
            >
              Already have an account?
            </p>
            <p
              v-else
              class="white--text cursor-pointer font-weight-bold ml-10"
              @click="loginOrLogout"
            >
              Log out
            </p>
          </div>
        </v-col>
        <v-col cols="0" sm="0" md="1" lg="1" xl="1"></v-col>
        <v-col cols="12" sm="12" md="5" lg="5" xl="5">
          <div class="d-flex justify-center align-center my-3">
            <v-select
              class="pa-1 language-selector hidden"
              dense
              prepend-icon="mdi-earth"
              :items="languages"
              item-text="name"
              item-value="code"
              label="Language"
              hide-details
              v-model="language"
            ></v-select>
          </div>
          <div class="box-outline form-card">
            <transition :name="slideDirection" mode="out-in">
              <v-row
                v-if="currentSlide == keys.user"
                key="0"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-5 align-text-left"
              >
                <v-card-title class="title-text word-break full-width">{{
                  slackLanguage
                    ? "Create your Whistle account & connect to Slack"
                    : "Create your Whistle account"
                }}</v-card-title>
                <v-form ref="form" v-model="validForm1" class="full-width px-4">
                  <v-text-field
                    ref="clientName"
                    label="Business Name"
                    v-model="clientName"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'The business name is required',
                      v =>
                        !!(v && v.length < 40) ||
                        'The business name should be shorter'
                    ]"
                  />
                  <v-text-field
                    ref="firstName"
                    label="First Name"
                    v-model="firstName"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'Your first name is required',
                      v =>
                        !!(v && v.length < 40) ||
                        'Your first name should be shorter'
                    ]"
                  />
                  <v-text-field
                    ref="lastName"
                    label="Last Name"
                    v-model="lastName"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'Your last name is required',
                      v =>
                        !!(v && v.length < 40) ||
                        'Your last name should be shorter'
                    ]"
                  />
                  <v-text-field
                    ref="email"
                    label="Business Email"
                    v-model="email"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'Your email is required',
                      v =>
                        !!(v && v.length < 255) ||
                        'Your email should be shorter',
                      v =>
                        /^\w+([&.+\-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/.test(
                          v
                        ) || 'Your email does not appear valid'
                    ]"
                  />
                  <vue-tel-input-vuetify
                    dense
                    class="mt-4"
                    v-model="phone"
                    :value="phone"
                    label="Phone number (xxx-xxx-xxxx)"
                    placeholder="xxx-xxx-xxxx"
                    :preferredCountries="['US']"
                    :defaultCountry="countryCode.code"
                    :disabledFetchingCountry="true"
                    v-on:country-changed="countryCodeChanged"
                    @validate="phoneValidation"
                    @input="updatePhone"
                  ></vue-tel-input-vuetify>
                  <v-select
                    label="Business Country HQ"
                    class="mt-4"
                    :items="countries"
                    v-model="country"
                    item-value="code"
                    item-text="name"
                    :rules="[v => !!v || 'Business country is required']"
                  />
                  <v-text-field
                    v-if="country === '00'"
                    v-model="otherCountry"
                    class="mt-4"
                    :rules="[
                      v => !!v || 'Country code is required',
                      v =>
                        !!(v && v.length == 2) ||
                        'Country code should be 2 letters'
                    ]"
                    label="Business Country HQ (2 digit code)*"
                  ></v-text-field>
                </v-form>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.userLoading"
                key="1"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-5 align-text-left"
              >
                <v-card-title class="title-text word-break full-width"
                  >Creating your account...</v-card-title
                >

                <Robin
                  :showText="false"
                  :width="140"
                  :height="140"
                  animation="inflatableDance"
                  :loop="true"
                />
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.userSuccess"
                key="2"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-space-around form-card"
              >
                <div class="d-flex flex-column align-center">
                  <v-card-title class="title-text word-break mt-8"
                    >You're on your way!</v-card-title
                  >
                  <div
                    class="accent-background circle-border-radius icon-outer-ring mt-10"
                  >
                    <v-icon
                      color="white"
                      x-large
                      class="icon-inner-ring accent-dark-background circle-border-radius"
                      >mdi-email-check-outline</v-icon
                    >
                  </div>
                  <caption class="mt-12">
                    We sent a verification email to
                    <br />
                    {{
                      email
                    }}
                  </caption>

                  <p class="font-weight-bold mt-8">
                    Check your inbox and click on the link inside to get
                    started!
                  </p>
                </div>
                <p
                  class="cursor-pointer primary--text font-weight-bold"
                  @click="
                    (currentSlide = keys.userError),
                      (resetEmailError = true),
                      (errorMessage =
                        'If you continue to not receive your Whistle email invitation, please contact us')
                  "
                >
                  Email did not arrive?
                </p>

                <!-- <v-btn
                  @click="sendResetEmail"
                  text
                  color="primary"
                  :loading="sendingResetEmail"
                  >Email did not arrive?</v-btn
                > -->
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.userError"
                key="3"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-5"
              >
                <v-card-title class="title-text word-break" v-if="!errorMessage"
                  >Looks like your account needs some extra
                  attention</v-card-title
                >
                <v-card-title class="title-text word-break" v-else>{{
                  errorMessage
                }}</v-card-title>

                <p class="pl-4 full-width mt-8">
                  Feel free to reach out to customer service via one of the
                  following methods...
                </p>
                <p>
                  Email -
                  <b
                    ><a href="mailto:help@wewhistle.com"
                      >help@wewhistle.com</a
                    ></b
                  >
                </p>
                <p>Phone (Toll Free) - <b>(855) 264-3329</b></p>

                <div class="d-flex mt-12">
                  <v-btn
                    color="primary"
                    class="pa-5"
                    width="160"
                    v-if="resetEmailError"
                    :loading="sendingResetEmail"
                    @click="sendResetEmail"
                    >Resend Email</v-btn
                  >
                  <div v-else-if="duplicateEmailError">
                    <v-btn
                      color="primary"
                      class="pa-5 mr-2"
                      width="160"
                      @click="goToSlide(0)"
                      >Go Back</v-btn
                    >
                    <v-btn
                      color="primary"
                      class="pa-5"
                      width="160"
                      @click="loginOrLogout"
                      >Log In</v-btn
                    >
                  </div>
                  <v-btn
                    color="primary"
                    class="pa-5"
                    width="160"
                    v-else
                    @click="currentSlide = keys.user"
                    >Edit Details</v-btn
                  >
                </div>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.blacklist"
                key="13"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-5"
              >
                <v-card-title class="title-text word-break"
                  >Your account is under review - a Whistle rep will be in
                  touch!</v-card-title
                >

                <p class="pl-4 full-width mt-8">
                  Or feel free to reach out to customer service via one of the
                  following methods...
                </p>
                <p>
                  Email -
                  <b
                    ><a href="mailto:help@wewhistle.com"
                      >help@wewhistle.com</a
                    ></b
                  >
                </p>
                <p>Phone (Toll Free) - <b>(855) 264-3329</b></p>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.emailVerified"
                key="4"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
                <!-- <div class="d-flex flex-column align-center"> -->
                <v-card-title class="title-text word-break mt-8"
                  >Email verified!</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >mdi-email-check-outline</v-icon
                  >
                </div>
                <caption class="mt-12">
                  Great, let's finish setting up your account.
                </caption>
              </v-row>
              <v-row
                v-if="currentSlide == keys.agreements"
                key="5"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-5"
              >
                <v-card-title class="title-text word-break mt-8"
                  >Confirm Acceptance of Services Agreement</v-card-title
                >
                <v-form
                  ref="form"
                  v-model="validAgreementForm"
                  class="full-width px-4"
                >
                  <v-text-field
                    label="Title at Business"
                    v-model="msa.title"
                    class="mt-4 mb-2"
                    :rules="[
                      v => !!v || 'Title is required',
                      v =>
                        !!(v && v.length < 128) || 'The title should be shorter'
                    ]"
                  />
                  <!-- <v-select
                    label="Industry"
                    class="mt-4 "
                    :items="industries"
                    v-model="industry"
                    item-value="value"
                    item-text="name"
                  /> -->
                  <!-- <v-select
                    label="Primary Use Case for Whistle"
                    class="mt-4"
                    :items="useCases"
                    v-model="primaryUseCase"
                    item-value="value"
                    item-text="name"
                    :rules="[(v) => !!v || 'Your primary use case is required']"
                  /> -->
                  <div
                    class="d-flex flex-column full-width text-left"
                    v-if="userProfile.Client.onboardingLanguage == 'UNKNOWN'"
                  >
                    <span>What is your primary use case for Whistle?</span>
                    <v-radio-group v-model="primaryUseCase">
                      <v-radio
                        label="Payments"
                        value="PAYMENTS"
                        class="mb-4"
                      ></v-radio>
                      <v-radio label="Learning" value="LEARNING"></v-radio>
                    </v-radio-group>
                  </div>

                  <div
                    class="d-flex flex-column full-width text-left"
                    v-if="
                      userProfile.Client.onboardingLanguage == 'PAYMENTS' ||
                        primaryUseCase == 'PAYMENTS'
                    "
                  >
                    <v-combobox
                      v-model="paymentUseCases"
                      :items="paymentUseCaseItems"
                      :item-text="item => item.label"
                      :item-value="item => item.value"
                      label="Select the use cases you're most interested in"
                      multiple
                      class="mb-2"
                    ></v-combobox>
                  </div>
                  <!-- <div
                    @click="downloadFile('msa_v1')"
                    class="d-flex align-center text-left cursor-pointer primary--text"
                  >
                    <v-icon color="primary" class="mr-2 download-icon"
                      >mdi-download</v-icon
                    >
                    DOWNLOAD MSA
                  </div>
                  <v-checkbox
                    v-model="msaCheckbox"
                    @change="updateMsaDate"
                    class="mt-4 mb-0"
                    label="I agree to the Master Services Agreement"
                    :rules="[(v) => !!v || '']"
                  /> -->

                  <!-- <v-divider /> -->

                  <v-card flat id="agreements" class=" pa-4 grey lighten-2">
                    <v-text-field
                      label="Legal Business Name"
                      v-model="msa.businessName"
                      dense
                      class="mt-3 pb-4 small-input"
                      :rules="[
                        v => !!v || 'The business name is required',
                        v =>
                          !!(v && v.length < 40) ||
                          'The business name should be shorter'
                      ]"
                    />
                    <v-text-field
                      label="Full Name"
                      v-model="msa.name"
                      dense
                      class="mt-2 small-input"
                      :rules="[
                        v => !!v || 'The business name is required',
                        v =>
                          !!(v && v.length < 40) ||
                          'The full name should be shorter'
                      ]"
                    />
                    <div class="d-flex align-center text-left small-checkbox">
                      <v-checkbox
                        v-model="msaCheckbox"
                        @change="updateMsaDate"
                        shrink
                        class="mt-0 mb-0"
                        :rules="[v => !!v || '']"
                      />
                      <span
                        >I agree to the
                        <a @click="downloadFile('msa_v1')"
                          >Master Services Agreement</a
                        >
                      </span>
                    </div>
                    <div class=" d-flex align-center text-left small-checkbox">
                      <v-checkbox
                        v-model="termsCheckbox"
                        class="mt-0 mb-0"
                        :rules="[v => !!v || '']"
                      />
                      <span
                        >I agree to the
                        <a @click="downloadFile('privacy_gdpr')"
                          >privacy policy</a
                        >
                        and
                        <a @click="downloadFile('terms')"
                          >terms and conditions</a
                        ></span
                      >
                    </div>
                  </v-card>

                  <!-- <div
                    class="d-flex align-center text-left cursor-pointer primary--text mt-5"
                    @click="downloadFile('terms')"
                  >
                    <v-icon color="primary" class="mr-2 download-icon"
                      >mdi-download</v-icon
                    >
                    DOWNLOAD TERMS AND CONDITIONS
                  </div>
                  <v-checkbox
                    v-model="termsCheckbox"
                    class="mt-4 mb-0"
                    label="I agree to the terms and conditions"
                    :rules="[(v) => !!v || '']"
                  />
                  <v-divider />
                  <div
                    class="d-flex align-center text-left cursor-pointer primary--text mt-5"
                    @click="downloadFile('privacy_gdpr')"
                  >
                    <v-icon color="primary" class="mr-2 download-icon"
                      >mdi-download</v-icon
                    >DOWNLOAD PRIVACY POLICY
                  </div>
                  <v-checkbox
                    v-model="privacyCheckbox"
                    class="mt-4"
                    label="I agree to the privacy policy"
                    :rules="[(v) => !!v || '']"
                  /> -->
                </v-form>
              </v-row>
              <!-- <v-row
                v-else-if="currentSlide == keys.kyb"
                key="7"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
                <v-card-title class="title-text word-break mt-8"
                  >Verify your business</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >mdi-domain</v-icon
                  >
                </div>
                <caption class="mt-12">
                  Before you can send funds to anyone within your company, we
                  have to gather some information.
                </caption>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.kybPending"
                key="8"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
                <v-card-title class="title-text word-break mt-8"
                  >Your business is being verified</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >mdi-account-clock</v-icon
                  >
                </div>
                <caption class="mt-12">
                  In the meantime, you can fund your account
                </caption>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.kybSuccess"
                key="9"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
          
                <v-card-title class="title-text word-break mt-8"
                  >Your business has been verified!</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >fa-check</v-icon
                  >
                </div>
                <caption class="mt-12">
                  Now it's time to fund your account
                </caption>
              </v-row> -->
              <v-row
                v-else-if="currentSlide == keys.funding"
                key="10"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
                <v-card-title class="title-text word-break mt-8"
                  >Fund your account</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >mdi-bank</v-icon
                  >
                </div>
                <caption class="mt-12" v-if="!hasFundedBudget">
                  This step can also be done at a later date from the budgets
                  page.
                </caption>
                <caption class="mt-12" v-else>
                  Congrats! You've checked all of the boxes and are ready to
                  start using Whistle
                </caption>
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.loading"
                key="11"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center mb-5 align-text-left"
              >
                <v-card-title class="title-text word-break full-width"
                  >Finalizing the setup process</v-card-title
                >
                <caption
                  class="grey--text mt-2 full-width align-text-left pl-4"
                >
                  This should only take a few seconds.
                </caption>

                <Robin
                  class="mt-4"
                  :showText="false"
                  :width="140"
                  :height="140"
                  animation="discoPointing"
                  :loop="true"
                />
              </v-row>
              <v-row
                v-else-if="currentSlide == keys.success"
                key="12"
                no-gutters
                class="py-3 word-break px-5 d-flex flex-column align-center justify-start"
              >
                <!-- <div class="d-flex flex-column align-center"> -->
                <v-card-title class="title-text word-break mt-8"
                  >Congratulations!</v-card-title
                >
                <div
                  class="brand-light-green-background circle-border-radius icon-outer-ring mt-10"
                >
                  <v-icon
                    color="white"
                    x-large
                    class="icon-inner-ring brand-green-background circle-border-radius"
                    >fa-check</v-icon
                  >
                </div>
                <caption class="mt-12">
                  {{
                    slackLanguage
                      ? "You have successfully connected Slack to your Whistle account."
                      : "You are all set to begin using Whistle"
                  }}
                </caption>
              </v-row>
            </transition>
            <v-row
              class="mb-6"
              align="center"
              justify="center"
              v-if="
                currentSlide != keys.userLoading &&
                  currentSlide != keys.loading &&
                  currentSlide != keys.userSuccess &&
                  currentSlide != keys.userError &&
                  currentSlide != keys.blacklist
              "
            >
              <v-col>
                <v-btn
                  color="primary"
                  v-if="currentSlide != keys.funding"
                  :disabled="
                    (currentSlide == keys.user &&
                      (!validForm1 || !validPhone || !formattedPhone)) ||
                      (currentSlide == keys.agreements && !validAgreementForm)
                  "
                  width="140"
                  class="pa-5 mt-8"
                  @click="nextSlide"
                  ><span>Continue</span></v-btn
                >
                <v-btn
                  color="primary"
                  v-else
                  :outlined="hasFundedBudget"
                  width="140"
                  class="pa-5 mt-8"
                  @click="dialogFund = true"
                  ><span>Add Funds</span></v-btn
                >
                <v-btn
                  color="primary"
                  :outlined="!hasFundedBudget"
                  width="140"
                  v-if="currentSlide == keys.funding"
                  class="pa-5 mt-8 ml-4"
                  @click="nextSlide(true)"
                  ><span>{{ hasFundedBudget ? "Finish" : "Skip" }}</span></v-btn
                ></v-col
              >
            </v-row>
          </div>
          <div
            v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            class="pt-3"
          >
            <p
              class="white--text cursor-pointer font-weight-bold mb-1"
              @click="downloadFile('terms')"
            >
              Whistle Terms
            </p>
            <p
              v-if="!$auth.isAuthenticated"
              class="white--text cursor-pointer font-weight-bold"
              @click="loginOrLogout"
            >
              Already have an account?
            </p>
            <p
              v-else
              class="white--text cursor-pointer font-weight-bold"
              @click="loginOrLogout"
            >
              Log out
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- <v-dialog
      fullscreen
      v-model="dialogKYB"
      transition="dialog-bottom-transition"
      class="z-index-front"
    >
      <v-card>
        <MarqetaBusinessCreation
          v-if="dialogKYB"
          @reset="closeKYB"
        ></MarqetaBusinessCreation>
      </v-card>
    </v-dialog> -->
    <v-navigation-drawer v-model="dialogFund" temporary fixed right width="500">
      <StripePaymentMethodsManager
        v-if="dialogFund"
        :destinationBudget="budget"
        :budgets="[]"
        :requestFunds="false"
        source="CLIENT_SELF_SERVICE"
        @reset="dialogFund = false"
      />
    </v-navigation-drawer>
    <Plaid v-if="displayPlaid" :hideButton="true" />
  </div>
</template>

<script>
import {
  states,
  countries,
  countryCodes,
  languages
} from "@/shared_data/data.js";

import Robin from "@/components/Robin.vue";
import ProgressBar from "@/components/ProgressBar.vue";
// import MarqetaBusinessCreation from "@/components/MarqetaBusinessCreation";
// import BudgetCreatorWidget from "@/components/BudgetCreatorWidget";
import StripePaymentMethodsManager from "@/components/stripe/StripePaymentMethodsManager";
import Plaid from "@/components/Plaid";

import moment from "moment";
import { mapState } from "vuex";

import UserService from "@/services/UserService";
import TriggerService from "@/services/TriggerService";
import OnboardingService from "@/services/OnboardingService";
import BudgetService from "@/services/BudgetService";
import SlackService from "@/services/SlackService";

import { industries } from "@/shared_data/data";

export default {
  name: "ClientActivation",
  title: "Whistle | Setup",
  props: {
    alreadyRegistered: Boolean,
    cards: Array
  },
  components: {
    Robin,
    ProgressBar,
    // MarqetaBusinessCreation,
    // BudgetCreatorWidget,
    StripePaymentMethodsManager,
    Plaid
  },
  data() {
    return {
      currentSlide: 0,
      keys: {
        user: 0,
        userLoading: 1,
        userSuccess: 2,
        userError: 3,
        blacklist: 4,
        emailVerified: 5,
        agreements: 6,
        loading: 7,
        // kyb: 8,
        // kybPending: 9,
        // kybSuccess: 10,
        funding: 8,
        success: 9,
        error: 10
      },
      slideDirection: "topic-left",
      resetEmailError: false,
      duplicateEmailError: false,
      errorMessage: null,
      validForm1: false,
      validAgreementForm: false,

      states: states,
      countries: countries,
      countryCodes: countryCodes,

      industries: industries,
      useCases: [
        { name: "Payments", value: "PAYMENTS" },
        { name: "Learning", value: "LEARNING" }
      ],

      languages: languages,
      language: null,
      clientName: null,
      firstName: null,
      lastName: null,
      email: null,
      countryCode: { code: null, value: null },
      phone: null,
      validPhone: false,
      formattedPhone: null,
      country: null,
      otherCountry: null,
      sendingResetEmail: false,
      clientId: null,

      msa: {
        title: null,
        name: null,
        businessName: null,
        date: null
      },
      msaCheckbox: false,
      termsCheckbox: false,
      termsSupplementalId: null,
      privacyCheckbox: false,
      privacySupplementalId: null,
      primaryUseCase: null,
      industry: null,
      source: null,

      // dialogKYB: false,
      // loadingKYB: false,

      dialogBudget: false,
      budgets: [],
      budget: null,

      dialogFund: false,
      displayPlaid: false,
      paymentUseCases: [],
      paymentUseCaseItems: [
        { value: "bonuses", label: "Sending bonuses, or buying my team lunch" },
        { value: "perks", label: "Setting up recurring perks" },
        { value: "bulk", label: "Processing bulk payments" },
        { value: "chat", label: "Integrating with Slack or Teams" }
      ]
    };
  },
  created() {
    this.language = "eng";
    // Used for Plaid OAuth redirect
    let query = Object.assign({}, this.$route.query);
    if (
      this.$route.params.type &&
      this.$route.params.type.toUpperCase() == "ACH" &&
      query.oauth_state_id
    ) {
      this.displayPlaid = true;
    }

    console.log("CLIENT ACTIVATION ATUH ", this.$auth.isAuthenticated);
    if (this.$auth.isAuthenticated) {
      // We prefill their useCase with either their use case or their onboarding language
      if (
        this.userProfile.Client.primaryUseCase ||
        this.userProfile.Client.onboardingLanguage
      ) {
        this.primaryUseCase = this.userProfile.Client.primaryUseCase
          ? this.userProfile.Client.primaryUseCase
          : this.userProfile.Client.onboardingLanguage;
      }
      // If their onboarding status is null or marked as completed, then we take them to a screen to log out
      if (
        this.userProfile.Client.onboardingStatus === null ||
        this.userProfile.Client.onboardingStatus === "COMPLETE"
      ) {
        console.log("User has already completed so we route them home");
        // if (this.userProfile.Client.initialRouteOnLoad != null) {
        //   var route = this.userProfile.Client.initialRouteOnLoad;
        // } else {
        //   route = "activity";
        // }

        // if (this.permissions.includes("vue:read:" + route)) {
        //   this.$router.push({ name: route });
        // } else {
        // If they're logged in already but have a correlationId, then post that directly to slack correlation
        this.storeSlackAssociation(true);
        this.currentSlide = this.keys.success;

        // }
      }
      // If client_creation then they've verified their email and successfully logged in
      if (this.userProfile.Client.onboardingStatus == "CLIENT_CREATION") {
        this.currentSlide = this.keys.agreements;
        this.msa.name =
          this.userProfile.firstName + " " + this.userProfile.lastName;
        this.msa.businessName = this.userProfile.Client.clientName;
        this.updateClientStatus({ onboardingStatus: "EMAIL_VERIFIED" });
        this.insertActivity("FIRST_LOGIN");
      } else if (this.userProfile.Client.onboardingStatus == "BLACKLIST") {
        this.currentSlide = this.keys.blacklist;
      } else if (this.userProfile.Client.onboardingStatus == "EMAIL_VERIFIED") {
        // Same logic as client_creation but we don't need to update their status
        this.currentSlide = this.keys.agreements;
        this.msa.name =
          this.userProfile.firstName + " " + this.userProfile.lastName;
        this.msa.businessName = this.userProfile.Client.clientName;
        // this.updateClientStatus("EMAIL_VERIFIED");
      } else if (this.userProfile.Client.onboardingStatus == "AGREEMENTS") {
        // They've signed MSA so show funding
        // this.refreshKYB();
        this.currentSlide = this.keys.funding;
        // } else if (this.userProfile.Client.onboardingStatus == "KYB_PENDING") {
        //   // Pending on KYB
        //   this.refreshKYB();
        //   this.currentSlide = this.keys.kybPending;
        // } else if (this.userProfile.Client.onboardingStatus == "KYB_SUCCESS") {
        //   // They've completed KYB
        //   this.currentSlide = this.keys.kybSuccess;
      }

      // Preload terms and privacy supplemental data
      if (this.userProfile && this.userProfile.UserSupplementals) {
        var userSupp = this.userProfile.UserSupplementals;
        var terms = userSupp.find(
          x => x.key === "Terms and Conditions Agreement"
        );
        if (terms) {
          this.termsSupplementalId = terms.userSupplementalId;
        } else {
          this.termsSupplementalId = null;
        }

        var privacy = userSupp.find(x => x.key === "Privacy Policy Agreement");
        if (privacy) {
          this.privacySupplementalId = privacy.userSupplementalId;
        } else {
          this.privacySupplementalId = null;
        }
      }
    } else {
      this.country = "US";
    }
  },
  mounted() {
    console.log("reefferrer");
    console.log(document.referrer);
    if (this.$route.query.source) {
      this.source = this.$route.query.source;
    } else {
      this.source = document.referrer;
    }
    if (this.userProfile && this.$auth.isAuthenticated) {
      this.getBudgets();
    }
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    // window.divNode.parentNode.removeChild(window.divNode);
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    loginOrLogout() {
      if (!this.$auth.isAuthenticated) {
        // We have to store the correlationId if it exists
        if (this.$route.name == "slacksetup" && this.$route.query.correlationId)
          this.$store.dispatch(
            "setSlackWorkspaceCorrelationId",
            this.$route.query.correlationId
          );
        //Logs in
        this.$auth.loginWithEmbedded({
          connection: process.env.VUE_APP_ENVIRONMENT
        });
      } else {
        //Logs out
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    },
    insertActivity(event) {
      var obj = {
        userId: this.userProfile.userId,
        clientId: this.userProfile.clientId,
        event: event,
        source: "USER_ONBOARDING",
        category: "PLATFORM",
        date: new Date()
      };
      TriggerService.createActivity(obj)
        .then(response => {
          console.log("Response from inserting activity ", response);
        })
        .catch(error => {
          console.log("There was an error inserting activity ", error);
        });
    },
    resetForm(loadVirtualCard = false) {
      this.firstName = null;
      this.lastName = null;
      this.phone = null;
      this.address1 = null;
      this.address2 = null;
      this.city = null;
      this.state = null;
      // this.country = null;
      // this.otherCountry = null;
      this.postalCode = null;
      this.identification = null;
      this.birthday = null;
      this.email = null;
      this.cardType = null;
      this.physicalCardAddress = null;
      this.cardAddress = {
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null
        // country: null,
        // otherCountry: null,
      };
      if (this.currentSlide <= 3) {
        this.$refs.form.reset();
      }

      this.$emit("get-cards");
      this.scrollToTop();
      console.log("Resetting: ", loadVirtualCard);
      this.$emit("reset", loadVirtualCard);
      this.currentSlide = 0;
    },
    scrollToTop() {
      // if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
      //   document.getElementsByClassName(
      //     "v-dialog v-dialog--active"
      //   )[0].scrollTop = 0;
      // } else {
      //   console.log("Scrolling on mobile");
      //   const cardY = document.getElementById("progress-bar");
      //   const rect = cardY.getBoundingClientRect();
      //   console.log(rect.top);
      //   window.scrollTo(0, rect.top - 90);
      // }
    },

    goToSlide(target) {
      this.slideDirection = "topic-right";
      this.scrollToTop();

      this.currentSlide = target;
    },
    previousSlide() {
      this.slideDirection = "topic-right";
      this.scrollToTop();
      if (this.currentSlide == 0) {
        this.resetForm(false);
      } else {
        this.currentSlide -= 1;
      }
    },
    nextSlide(exit = false) {
      this.slideDirection = "topic-left";
      // if (this.currentSlide == this.keys.kyb) {
      //   // open up the dialog window
      //   this.loadingKYB = true;
      //   this.dialogKYB = true;
      // } else
      if (exit === true) {
        this.completeOnboardingFlow();
      } else {
        this.scrollToTop();
        this.currentSlide += 1;
        if (this.currentSlide == this.keys.userLoading) {
          this.createUser();
        } else if (this.currentSlide == this.keys.loading) {
          // We are submitting agreements
          this.saveAgreements();
          this.updateClient({
            updatedBy: this.userProfile.userId,
            industry: this.industry,
            primaryUseCase: this.primaryUseCase
          });
          this.storeSlackAssociation();

          // if (this.primaryUseCase == "LEARNING") this.completeOnboardingFlow();
        }
        // else if (this.currentSlide == this.keys.kybSuccess) {
        //   //  If the user was on pending and moves to success, then they're really trying to go to funding
        //   this.currentSlide = this.keys.funding;
        // }
        else if (this.currentSlide == this.keys.success + 1) {
          console.log("All done");
          this.completeOnboardingFlow();
        }
      }
    },
    createUser() {
      let product = this.$route.query.product
        ? this.$route.query.product.toUpperCase().includes("PAYMENT")
          ? "PAYMENTS"
          : "LEARNING"
        : "UNKNOWN";
      // onboardingLanguage:
      //             this.primaryUseCase == "LEARNING" ? "LEARNING" : "PAYMENTS",
      //           initialRouteOnLoad:
      //             this.primaryUseCase == "LEARNING" ? "learning" : "activity",
      var startTime = new Date();
      var body = {
        clientName: this.clientName,
        firstName: this.firstName,
        lastName: this.lastName,
        displayName: this.firstName + " " + this.lastName,
        businessEmail: this.email,
        businessPhone: this.formattedPhone,
        businessCountry:
          this.country != "00" ? this.country : this.otherCountry.toUpperCase(),
        source: this.slackLanguage
          ? "slack"
          : this.source
          ? this.source
          : "wewhistle.com - Payments",
        initialRouteOnLoad: "activity",
        language: this.language,
        onboardingLanguage: product,
        primaryUseCase: product
      };
      if (this.$route.query.correlationId && this.slackLanguage)
        body.internalCustom1 = this.$route.query.correlationId;

      console.log("Submitting new client request: ", body);
      OnboardingService.setupClientAndUser(body)
        .then(response => {
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff > 2000) {
            timeDiff = 2000;
          }

          setTimeout(() => {
            console.log("User/Client response: ", response);
            if (response.client.onboardingStatus == "BLACKLIST") {
              this.currentSlide = this.keys.blacklist;
            } else {
              this.clientId = response.client.clientId;
              this.currentSlide = this.keys.userSuccess;
            }
          }, 2000 - timeDiff);
        })
        .catch(error => {
          console.log("There was an error creating the user & client: ", error);

          if (
            error &&
            error.error_code &&
            (error.error_code.endsWith("0022") ||
              error.error_code.endsWith("0165") ||
              error.error_code.endsWith("0166"))
          ) {
            // Duplicate user/client error so we return
            this.errorMessage = `We found an account matching the ${
              error.error_code.endsWith("0166") ? "phone number" : "email"
            } you entered.`;
            this.duplicateEmailError = true;
          } else if (error.error) {
            if (error.error.startsWith("Failed to find anyone in Auth0")) {
              this.errorMessage =
                "Looks like the email entered already exists within Whistle";
            }
          }
          this.currentSlide = this.keys.userError;
        });
    },
    updateClientStatus(obj) {
      obj.updatedBy = this.userProfile.userId;
      return UserService.updateClient(this.userProfile.clientId, obj)
        .then(response => {
          console.log("Response from updating client ", response);
          return response;
        })
        .catch(error => {
          console.log("There was an error updating the client: ", error);
        });
    },
    storeSlackAssociation(passQueryParam = false) {
      if (
        this.slackLanguage &&
        process.env.VUE_APP_SLACK_URL &&
        ((this.userProfile.Client && this.userProfile.Client.internalCustom1) ||
          (passQueryParam && this.$route.query.correlationId))
      ) {
        if (this.userProfile.Client && this.userProfile.Client.internalCustom1)
          console.log("Storing slack assocation with Client");
        else
          console.log(
            "Storing slack assocation with Client (passing query param)"
          );
        SlackService.storeSlackWorkspaceAssociation(
          this.userProfile.Client && this.userProfile.Client.internalCustom1
            ? this.userProfile.Client.internalCustom1
            : this.$route.query.correlationId
        );
        if (this.primaryUseCase == "LEARNING") this.completeOnboardingFlow();
      }
    },
    saveAgreements() {
      var startTime = new Date();
      var acceptanceString =
        "I, " +
        this.msa.name +
        ", " +
        this.msa.title +
        ", of " +
        this.msa.businessName +
        " accept Accepted Whistle_Payments_Agreement_v1_12.3.21.pdf on " +
        this.msa.date;
      var jsonMSA = {
        full_name: this.msa.name,
        title: this.msa.title,
        legal_business_name: this.msa.businessName,
        acceptanceDateTime: this.msa.date,
        agreementVersion: "Whistle_Payments_Agreement_v1_12.3.21.pdf",
        digitalSignature: acceptanceString
      };

      var userSuppArray = [
        {
          userId: this.userProfile.userId,
          key: "Whistle_Payments_Agreement",
          value: JSON.stringify(jsonMSA),
          updatedBy: this.userProfile.userId
        },
        {
          userId: this.userProfile.userId,
          key: "Terms and Conditions Agreement",
          value: moment().utc(),
          updatedBy: this.userProfile.userId
        },
        {
          userId: this.userProfile.userId,
          key: "Privacy Policy Agreement",
          value: moment().utc(),
          updatedBy: this.userProfile.userId
        }
      ];
      if (this.termsSupplementalId !== null) {
        userSuppArray[1].userSupplementalId = this.termsSupplementalId;
      }
      if (this.privacySupplementalId !== null) {
        userSuppArray[2].userSupplementalId = this.privacySupplementalId;
      }

      console.log("Updating/Creating User Supplemental: ", userSuppArray);
      UserService.bulkUpdateUserSupplemental(userSuppArray)
        .then(response => {
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff > 2000) {
            timeDiff = 2000;
          }
          console.log("TIME DIFF", timeDiff);
          setTimeout(() => {
            console.log("User Supplemental response: ", response);
            // If there was an error with the API, we show the error page
            if (response.error) {
              this.currentSlide = this.keys.error;
            } else {
              this.updateClientStatus({
                onboardingStatus: "AGREEMENTS",
                completedMSA: true,
                onboardingLanguage:
                  this.primaryUseCase == "LEARNING" ? "LEARNING" : "PAYMENTS",
                initialRouteOnLoad:
                  this.primaryUseCase == "LEARNING" ? "learning" : "activity"
              });
              // Manually update the Vuex store
              this.$store.state.userProfile.Client.completedMSA = true;

              if (this.primaryUseCase == "LEARNING")
                this.completeOnboardingFlow();
              else this.currentSlide = this.keys.funding;
            }
          }, 2000 - timeDiff);
        })
        .catch(error => {
          console.log("There was an error saving user supplemental: ", error);
          this.currentSlide = this.keys.error;
        });
    },
    sendResetEmail() {
      this.sendingResetEmail = true;
      UserService.resetPassword(this.email).then(x => {
        console.log("Password reset email ", x);
        this.sendingResetEmail = false;
      });
    },
    downloadFile(name) {
      OnboardingService.downloadLegalDoc(name);
    },
    updateMsaDate() {
      this.msa.date = new Date();
    },
    // closeKYB(success) {
    //   this.dialogKYB = false;

    //   if (success) {
    //     this.updateClientStatus({ onboardingStatus: "KYB_SUCCESS" });
    //     this.currentSlide = this.keys.kybSuccess;
    //   } else {
    //     this.loadingKYB = true;
    //   }
    //   this.$store.dispatch("setMarqeta").then((x) => {
    //     if (x.processingKYB && !x.canRegister) {
    //       this.currentSlide = this.keys.kybPending;
    //       this.updateClientStatus({ onboardingStatus: "KYB_PENDING" });
    //     } else if (!x.processingKYB && x.canRegister) {
    //       this.currentSlide = this.keys.kybSuccess;
    //       this.updateClientStatus({ onboardingStatus: "KYB_SUCCESS" });
    //     }
    //     this.loadingKYB = false;
    //   });
    // },
    // refreshKYB() {
    //   this.loadingKYB = true;
    //   this.$store.dispatch("setMarqeta").then((x) => {
    //     if (x.processingKYB && !x.canRegister) {
    //       this.currentSlide = this.keys.kybPending;
    //       this.updateClientStatus({ onboardingStatus: "KYB_PENDING" });
    //     } else if (!x.processingKYB && x.canRegister) {
    //       this.currentSlide = this.keys.kybSuccess;
    //       this.updateClientStatus({ onboardingStatus: "KYB_SUCCESS" });
    //     }
    //     this.loadingKYB = false;
    //   });
    // },
    async updateClient(obj) {
      var updateReponse = await UserService.updateClient(
        this.userProfile.clientId,
        obj
      );
      console.log("Update client response: ", updateReponse);
    },
    completeOnboardingFlow() {
      this.currentSlide = this.keys.loading;
      // We have to mark them as having logged in
      // Completed the onboarding setup
      // Then push them to People
      var startTime = new Date();
      UserService.updateUser(this.userProfile.userId, {
        firstLogin: false
      }).then(user => {
        console.log("User update response: ", user);

        this.updateClientStatus({ onboardingStatus: "COMPLETE" }).then(() => {
          // this.$store.dispatch("setUser", this.userProfile.userId).then((x) => {
          // console.log("Done with getting user", x);
          var timeDiff = moment().diff(moment(startTime));
          if (timeDiff > 2000) {
            timeDiff = 2000;
          }
          setTimeout(() => {
            // Route to budgets if the user hasn't funded
            // if (this.hasFundedBudget) {
            //   if (this.$route.name != "people")
            //     this.$router.push({ name: "people" });
            // } else {
            //   if (this.$route.name != "budgets")
            //     this.$router.push({ name: "budgets" });
            // }
            this.$router.push({ name: "setup-complete" });
            this.$store.state.userProfile.Client.onboardingStatus = "COMPLETE";
            this.$store.state.userProfile.firstLogin = false;
          }, 2000 - timeDiff);
          // });
        });
      });
    },
    getBudgets() {
      console.log("Getting budgets");
      BudgetService.getBudgets()
        .then(response => {
          response = response.filter(x => x.status != "Deleted");
          response.forEach(budget => {
            budget.formattedBudgetBalance = budget.budgetBalance;
          });
          this.budgets = response;
          this.budget = response.find(x => x.masterBudget);
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    phoneValidation: function({ number, isValid }) {
      // Do stuff with the arguments passed by the vue-tel-input component
      console.log(number);
      if (isValid) {
        this.formattedPhone = number.e164; //rfc3966.replace("tel:", "");
        this.validPhone = true;
      } else {
        this.validPhone = false;
      }
    },
    updatePhone: function() {
      if (!this.phone || !this.validPhone) {
        this.formattedPhone = null;
        this.validPhone = false;
      }
    },
    countryCodeChanged(country) {
      this.countryCode.code = country.iso2;
      this.countryCode.value = country.dialCode;
    }
  },
  computed: {
    ...mapState(["userProfile", "marqeta", "permissions"]),
    slackLanguage() {
      if (
        this.$route.name == "slacksetup" ||
        (this.userProfile &&
          this.userProfile.Client &&
          this.userProfile.Client.source &&
          this.userProfile.Client.source.toLowerCase().includes("slack"))
      )
        return true;
      return false;
    },
    progress() {
      // if (this.alreadyRegistered) {
      //   // If the user already has a card, then we can skip the account creation step
      //   // If the user already has a physical card but not a virtual, then skip to slide 5
      //   if (this.displayVirtualCardOption && !this.displayPhysicalCardOption) {
      //     return (((this.currentSlide + 1 - 5) / 2) * 100).toString();
      //   } else {
      //     console.log(((this.currentSlide + 1 - 4) / 3) * 100);
      //     return (((this.currentSlide + 1 - 4) / 3) * 100).toString();
      //   }
      // } else {
      //   return (((this.currentSlide + 1) / 6) * 100).toString();
      // }
      return "0";
    },
    disableNextButton() {
      return (
        (this.currentSlide == this.keys.user && !this.validForm1) ||
        (this.currentSlide == this.keys.agreements &&
          (!this.cardholderAgreement ||
            !this.eSignAgreement ||
            !this.issuerPrivacyPolicy))
      );
    },
    // KYBStatus() {
    //   if (
    //     this.marqeta &&
    //     !this.marqeta.processingKYB &&
    //     this.marqeta.canRegister
    //   ) {
    //     return "Complete";
    //   } else if (
    //     this.marqeta &&
    //     this.marqeta.processingKYB &&
    //     !this.marqeta.canRegister
    //   ) {
    //     return "Pending";
    //   }
    //   return "Incomplete";
    // },
    hasFundedBudget() {
      if (
        this.budgets &&
        this.budgets.find(x => x.budgetBalance && x.budgetBalance > 0)
      ) {
        return true;
      } else if (
        this.budget &&
        this.budget.BudgetActivities &&
        this.budget.BudgetActivities.length > 0
      ) {
        return true;
      } else return false;
    }
  },

  watch: {
    dialogFund: function(newVal) {
      if (newVal === false) {
        // side drawer just closed so we refresh budgets in case they have funded
        console.log("Refreshing budgets");
        this.getBudgets();
      }
    }
  }
};
</script>

<style>
#agreements .small-checkbox i::before,
#agreements .v-input--selection-controls__ripple {
  font-size: 0.75em !important;
  margin-top: 12px;
}
</style>

<style scoped>
.gradient-background {
  background: linear-gradient(
      90deg,
      rgba(161, 215, 110, 1) 35%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 120px, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}

.box-outline {
  border: solid;
  border-color: lightgray;
  border-width: 2px;
  background-color: white;
}

.form-card {
  /* min-height: 550px; */
  /* min-height: 80vh; */
  min-height: calc(100% - 100px);
}

.icon-outer-ring {
  width: 110px;
  height: 110px;
}

.large-icon {
  transform: scale(1.5);
}

.icon-inner-ring {
  width: 94px;
  margin: 8px;
  height: 94px;
}

.language-selector {
  max-width: 250px;
}

.title-text {
  font-size: 1.5em;
}

.wide-container {
  max-width: 1185px;
}

#agreements {
  font-size: 0.75em !important;
  background-color: #e8e8e8 !important;
}

#agreements label,
#agreements input,
#agreements .small-input,
i.fa-quare::before,
.v-icon.fa-square {
  font-size: 1em !important;
}

/* Code for transitions between slides */
.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.progress-bar {
  width: calc(50% - 80px);
}

.mini-icon {
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
}
.download-icon {
  min-width: 24px;
}
</style>

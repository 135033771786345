var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 flex-column d-flex"},[_c('v-card',{staticClass:"quiz-card",attrs:{"rounded":"0","width":"100%","elevation":"0"}},[_c('v-card-title',{staticClass:"word-break px-0 font-weight-bold brand--text"},[_vm._v(" "+_vm._s(_vm.question.content)+" ")]),_c('p',{staticClass:"grey--text mb-1 font-italic"},[_vm._v(" Choose a response on the slider below ")]),_c('v-divider'),_c('div',{staticClass:"d-flex flex-column align-center text-center my-4 mx-10"},[_c('span',{staticClass:"brand--text slider-value-text font-weight-bold mb-4"},[_vm._v(_vm._s(_vm.sliderValue))]),_c('v-slider',{staticClass:"slider",attrs:{"color":"brandCyan","min":this.question.lowerBound,"max":this.question.upperBound,"ticks":"","disabled":_vm.answered},model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}})],1),(_vm.answered == true && _vm.correctAnswer)?_c('div',{staticClass:"answer-box d-flex justify-center align-center cursor-pointer mb-6 mx-auto",class:{
        'brand-pale-green-background':
          _vm.answered &&
          ((_vm.correctAnswer && _vm.correctAnswer.content == _vm.sliderValue) ||
            _vm.noCorrectAnswersMarked),
        'light-red-background':
          _vm.answered &&
          !_vm.noCorrectAnswersMarked &&
          _vm.correctAnswer &&
          _vm.correctAnswer.content != _vm.sliderValue
      }},[_c('div',{staticClass:"d-flex justify-center"},[(
            _vm.answered && _vm.correctAnswer && _vm.correctAnswer.content == _vm.sliderValue
          )?_c('v-icon',{staticClass:"answer-icon",attrs:{"color":"brandGreen"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{staticClass:"answer-icon",attrs:{"color":"red"}},[_vm._v("mdi-close")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.correctAnswer.content))])],1)]):_vm._e(),_c('v-divider'),_c('div',{staticClass:"d-flex justify-center mt-10"},[(_vm.answered == false)?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":""},on:{"click":_vm.checkQuestion}},[_vm._v(" "+_vm._s(_vm.computedSubmitButton)+" ")]):(_vm.questionIndex !== _vm.questionCount - 1 && _vm.answered == true)?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":""},on:{"click":_vm.goToNextQuestion}},[_vm._v(" Next ")]):(
          _vm.questionIndex == _vm.questionCount - 1 &&
            _vm.answered == true &&
            !_vm.isSurveyQuestion
        )?_c('v-btn',{staticClass:"white--text",attrs:{"color":!_vm.inProgram ? 'brandCyan' : 'brand',"rounded":!_vm.inProgram,"width":"120","depressed":""},on:{"click":_vm.completeQuiz}},[_vm._v(" Complete ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
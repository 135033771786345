<template>
  <div class="mt-4 flex-column d-flex">
    <v-card rounded="0" width="100%" elevation="0" class="quiz-card">
      <!-- Question no and content -->
      <v-card-title class="word-break px-0 font-weight-bold brand--text">
        {{ question.content }}
      </v-card-title>
      <!-- Text area input -->
      <v-divider />
      <v-textarea
        solo
        flat
        name="short-answer-input"
        label="Add your answer"
        v-model.lazy="shortAnswer"
        class="d-flex pa-3 short-answer-input"
      ></v-textarea>
      <v-divider />
      <!-- Div for buttons, if answered = false: submit, if answered = true: next question or complete -->
      <div class="d-flex justify-center mt-10">
        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          @click="answerQuestion"
          width="120"
          depressed
          v-if="answered == false"
          :disabled="!shortAnswer"
        >
          {{ computedSubmitButton }}
        </v-btn>

        <v-btn
          :color="!inProgram ? 'brandCyan' : 'brand'"
          :rounded="!inProgram"
          class="white--text"
          @click="goToNextQuestion"
          width="120"
          depressed
          v-if="questionIndex !== questionCount - 1 && answered == true"
        >
          Next
        </v-btn>

        <v-btn
          color="brand"
          class="white--text"
          @click="completeQuiz"
          width="120"
          depressed
          v-else-if="
            questionIndex == questionCount - 1 &&
              answered == true &&
              !isSurveyQuestion
          "
        >
          Complete
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import LearningService from "../services/LearningService";
import { mapState } from "vuex";
export default {
  name: "QuizQuestionShortAnswerV2",
  props: {
    question: Object,
    questionIndex: Number,
    questionCount: Number,
    completedStatus: Boolean,
    learningCardAssociationId: Number,
    isSurveyQuestion: Boolean,
    surveyClientId: Number,
    whistleToken: String,
    enteredEmail: String,
    context: String,
  },
  data() {
    return {
      // answer: null,
      answered: false,
      currentQuestion: null,
      // TODO: chapter list
      chapterList: null,
      shortAnswer: null,

      // alphabet: alphabetLowerCase,
      // selected: "",
      // answerKey: null,
      // incorrectAnswers: [],
      // incorrectCount: 0,
      // correctCount: 0,
    };
  },
  methods: {
    answerQuestion() {
      if (!this.answered) {
        this.answered = true;
      }
      this.submitAnswer();
    },
    submitAnswer() {
      //build question response
      console.log("build question response... ... ...");
      var quizQuestionResponse = {
        clientId:
          this.userProfile && this.userProfile.clientId != null
            ? this.userProfile.clientId
            : this.surveyClientId,
        learningCardId: this.question.learningCardId,
        learningQuizQuestionId: this.question.learningQuizQuestionId,
        learningCardAssociationId: this.learningCardAssociationId,
        learningQuizAnswerId: null,
        value: this.shortAnswer,
        correctness: null,
        userId: this.whistleToken != null ? null : this.$auth.user.sub,
      };

      if (this.whistleToken !== null) {
        quizQuestionResponse.externalUserId = this.enteredEmail;
      }

      console.log(quizQuestionResponse);
      console.log(this.checkedAnswer);

      LearningService.createQuizResponse(
        [quizQuestionResponse],
        this.whistleToken
      );
      //if this is a survey question, we don't want to have to click Submit, and then Next, one button should do both
      //so we call the function for next question or complte quiz inside of the submit button
      // if (this.isSurveyQuestion == true) {
      //   if (this.questionIndex !== this.questionCount - 1) {
      this.goToNextQuestion();
      //   } else {
      //     this.completeQuiz();
      //   }
      // } else {
      //   this.goToNextQuestion();
      // }

      console.log("!!!!!!!!!!!!!!!!!!!!!!!!");
      console.log(this.checkedAnswer);
    },
    goToNextQuestion() {
      // If we're not on the last question, move on and reset the flags for answering a question
      if (this.questionIndex !== this.questionCount - 1) {
        this.answered = false;
        this.shortAnswer = null;
        // True is passed so the question is marked correct
        this.$emit("next-question", true);
      } else {
        this.completeQuiz();
      }
    },
    completeQuiz() {
      // Emits a notification so the quiz can submit
      // True is passed so the question is marked correct
      this.$emit("complete-quiz", true);
    },
  },
  computed: {
    ...mapState(["userProfile"]),
    computedSubmitButton: function() {
      var buttonText = "Submit";
      if (this.isSurveyQuestion) {
        buttonText = "Next";
      }
      return buttonText;
    },
    inProgram() {
      // used to change styling if we're in learning or surveys versus in programs
      return !(this.context == "survey" || this.context == "learning");
    },
  },
};
</script>

<style scoped>
.short-answer-input {
  max-width: 90%;
  margin: 0 auto;
}
@media only screen and (max-width: 700px) {
  .short-answer-input {
    max-width: 100%;
    padding: 0 !important;
  }
}
</style>
